var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { SubdomainStatus, State } from 'lib/enums';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { Link as LinkIcon } from '../icons';
const SubdomainSection = ({ activeOrganization, donationProfile, campaignDomain, setCampaignDomain, setShowSubdomainModal, domainError, setDomainError }) => {
    const [inputShadow, setInputShadow] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            setDomainError(false);
            if (campaignDomain && campaignDomain !== donationProfile.data().domain) {
                const query = yield Firebase.firestore()
                    .collection(Collections.donationProfiles)
                    .where('domain', '==', campaignDomain)
                    .get();
                if (query.docs.length)
                    setDomainError(true);
                else
                    setDomainError(false);
            }
            else
                setDomainError(false);
        }), 1000);
        return () => clearTimeout(timer);
    }, [campaignDomain]);
    const handleDomainEnter = (value) => {
        // eslint-disable-next-line no-useless-escape
        if (/^[0-9a-zA-z-_\.~]*$/.test(value))
            setCampaignDomain(value.toLowerCase());
    };
    const state = State.by_value(activeOrganization.data().state);
    return (React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex flex-col w-2/6" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Campaign domain*"),
            React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "This is where supporters can find your campaign on the web.")),
        React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-4/6" },
            donationProfile.data().subdomainStatus ===
                SubdomainStatus.live.value && (React.createElement("div", { className: "mt-1 flex rounded-md shadow-sm w-4/6" },
                React.createElement("span", { className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50" }, LinkIcon),
                React.createElement("input", { className: "form-input flex-1 block px-3 py-2 rounded-none text-gray-700 rounded-r-md sm:text-sm sm:leading-5", value: `${donationProfile.data().subdomain}.column.us`, disabled: true }))),
            React.createElement("div", { className: `mt-3 flex rounded-md shadow-sm w-4/6 tooltip ${domainError
                    ? 'shadow-outline-red border border-red-300'
                    : inputShadow && 'shadow-outline-blue border border-indigo-300'}` },
                React.createElement("span", { className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-700 sm:text-sm" }, state === null || state === void 0 ? void 0 :
                    state.donation_subdomain,
                    ".column.us/"),
                React.createElement("input", { id: "domain", className: "form-input flex-1 block px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5 focus:shadow-none focus:border", value: campaignDomain, onChange: e => handleDomainEnter(e.target.value), required: true, onFocus: () => setInputShadow(true), onBlur: () => setInputShadow(false) }),
                React.createElement("span", { className: "tooltiptext right-0", style: { left: 'unset', top: '-45px', width: '100%' } }, "This is the URL web address specific to your campaign. You will be able to share this link with potential donors.")),
            domainError && (React.createElement("span", { className: "text-xs text-red-600 ml-1" }, "This domain is taken. Please choose a different one.")),
            donationProfile.data().subdomainStatus !==
                SubdomainStatus.live.value && (React.createElement("div", { className: "flex items-center mt-3" },
                React.createElement("div", { className: "font-medium text-sm text-indigo-400 cursor-pointer", onClick: () => donationProfile.data().subdomainStatus !==
                        SubdomainStatus.pending.value && setShowSubdomainModal(true) }, "Request custom subdomain"),
                donationProfile.data().subdomainStatus ===
                    SubdomainStatus.pending.value && (React.createElement("div", { className: "rounded-lg font-semibold text-xs text-gray-700 bg-gray-200 px-2 py-1 ml-4" }, "Request Pending")))))));
};
export default SubdomainSection;
