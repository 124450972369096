var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { OccupationType } from 'lib/enums';
export const canPublisherUserSeeNewspaperSelect = (user, isInitialPlacementFlow) => {
    const { occupation, allowedOrganizations } = user.data();
    /**
     * Any publisher in multiple newspapers should be able to
     * switch newspapers in the placement flow. However, only
     * publishers with the LaunchDarkly flag should have this
     * ability in an edit flow.
     */
    const publisherCanSwitchNewspapers = isInitialPlacementFlow ||
        getBooleanFlag(LaunchDarklyFlags.ENABLE_NEWSPAPER_SELECTION_FOR_PUBLISHERS);
    return (occupation === OccupationType.publishing.value &&
        publisherCanSwitchNewspapers &&
        allowedOrganizations &&
        allowedOrganizations.length > 1 // Only show the dropdown if the user can access more than one paper
    );
};
/**
 * The reason for this helper is to allow delay mocking during testing.
 * See: https://github.com/jfairbank/redux-saga-test-plan/issues/257
 *
 * The following are the same:
 *  - yield call(delayMs, 500)
 *  - yield delay(500)
 */
export const delayMs = (ms) => __awaiter(void 0, void 0, void 0, function* () {
    yield new Promise(res => setTimeout(res, ms));
});
