var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import Firebase, { Collections } from 'EnoticeFirebase';
import { getRedirect } from 'utils/urls';
import { OrganizationType, GovernmentType } from 'lib/enums';
import api from 'api';
import { isAddressHasAnyField, organizationAlreadyExists } from 'routes/register/helpers';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import ConfirmToast from 'components/modals/ConfirmToast';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import SelectDropdown from '../../../placeScroll/SelectDropdown';
import RegisterOrganizationAddressForm, { EMPTY_ADDRESS } from '../RegisterOrganizationAddressForm';
import RegisterOrganizationAlreadyExists from '../RegisterOrganizationAlreadyExists';
const RegisterOrganizationGovt = ({ userAuth, authActions, anonPlacement, user, push, stepComplete, setPreventDismissingInfo }) => {
    var _a;
    // Form fields
    const [type, setType] = useState('');
    const [address, setAddress] = useState(EMPTY_ADDRESS);
    const [loading, setLoading] = useState(false);
    const [alreadyExists, setAlreadyExists] = useState(false);
    const [open, setOpen] = useState(false);
    const allFieldsValid = !!type && address.validation.valid;
    const disable = !allFieldsValid;
    useEffect(() => {
        if (user.organization) {
            push(getRedirect());
        }
    }, [(_a = user.organization) === null || _a === void 0 ? void 0 : _a.id]);
    useEffect(() => {
        stepComplete(allFieldsValid);
    }, [allFieldsValid]);
    useEffect(() => {
        if (isAddressHasAnyField(address) || !!type) {
            setPreventDismissingInfo(true);
        }
        else {
            setPreventDismissingInfo(false);
        }
    }, [isAddressHasAnyField(address), type]);
    const onBackClick = (e) => {
        e.preventDefault();
        if (isAddressHasAnyField(address) || !!type) {
            setOpen(true);
        }
        else {
            goBackLink();
        }
    };
    const goBackLink = () => {
        push('/register/occupations');
    };
    const nextButtonClicked = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        yield handleSubmit();
    });
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!allFieldsValid) {
            return;
        }
        setLoading(true);
        // Check if org already exists
        const orgAlreadyExists = yield organizationAlreadyExists(OrganizationType.government.value, address.name);
        if (orgAlreadyExists) {
            setAlreadyExists(true);
            setLoading(false);
            return;
        }
        // Create stripe customer id for organization
        const createCustomer = getCreateCustomerFn();
        const result = yield createCustomer({
            name: user.name,
            email: user.email,
            firestoreUID: userAuth.uid
        });
        // Add organization, update user's organization ref
        const created = yield Firebase.firestore()
            .collection(Collections.organizations)
            .add({
            organizationType: OrganizationType.government.value,
            governmentType: type,
            name: address.name,
            address: address.addressLine1,
            addressLine2: address.addressLine2 || '',
            city: address.city,
            state: address.state.id,
            zipCode: address.zipCode,
            phone: address.phone,
            createdBy: userAuth.uid,
            stripeId: result.data.stripeId
        });
        yield api.post('users/link-filer-to-org', {
            organizationId: created.id,
            uid: userAuth.uid
        });
        authActions.register();
        if (anonPlacement) {
            yield created.update({
                postRegistrationComplete: true
            });
        }
        if (user.organization) {
            push(getRedirect());
        }
    });
    const renderSelect = (props) => (React.createElement(SelectDropdown, { id: props.id, onChange: (selected) => {
            if (selected) {
                props.onChange(selected.id);
            }
        }, placeholder: props.placeholder, options: props.options.map((item) => ({
            id: item.value,
            label: item.label
        })), value: props.options.filter((item) => {
            return item.value === parseInt(props.value, 10)
                ? { id: item.value, label: item.label }
                : '';
        }), selected: props.options.filter((item) => {
            return item.value === parseInt(props.value, 10)
                ? { id: item.value, label: item.label }
                : '';
        }), placeholderText: '#a0aec0', borderColor: '#e2e8f0', borderRadius: props.radius, required: true, noShadow: true, maxHeight: props.maxHeight, dropdownHeight: "large", className: "z-30" }));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col lg:w-3/5 md:w-3/5 sm:w-auto bg-white rounded-md px-8 pt-8 pb-10 shadow-2 border" },
            React.createElement("div", { className: "flex flex-col w-full" },
                React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(RegisterOrganizationAddressForm, { nameFieldText: 'Name of government organization', handleInputChanged: setAddress, namePlaceholderText: "Name of government" })),
                    React.createElement("div", { className: "flex flex-col mt-3" },
                        React.createElement("div", null,
                            React.createElement("p", { className: "text-gray-800 font-medium text-sm leading-5 mb-2" }, "Select organization type *")),
                        renderSelect({
                            id: 'government-type',
                            onChange: setType,
                            options: GovernmentType.items(),
                            value: type,
                            placeholder: 'Government type or position',
                            required: true,
                            withIcon: true,
                            radius: 4,
                            maxHeight: 150
                        }))))),
        React.createElement(AdvertiserRegistrationFooter, { id: "submit-government", backButtonText: "Go back", nextButtonText: "Create organization", onBackButtonClick: onBackClick, onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: disable }),
        open && (React.createElement(ConfirmToast, { headerText: 'Are you sure?', bodyText: `If you go back now, your progress will be lost. Please click "Yes, continue" to confirm.`, leftButtonText: 'Cancel', rightButtonText: `Yes, continue`, onLeftClick: () => setOpen(false), onRightClick: () => goBackLink() })),
        alreadyExists && (React.createElement(RegisterOrganizationAlreadyExists, { name: address.name, onBackClicked: () => setAlreadyExists(false) }))));
};
export default RegisterOrganizationGovt;
