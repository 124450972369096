import React from 'react';
/**
 * White-background card component with padding
 */
export default function TemplateSettingsUpdateCard({ subheader, children, header }) {
    return (React.createElement("div", { className: "pt-8 pb-0 flex-1 flex justify-center overflow-scroll bg-gray-100" },
        React.createElement("div", { className: "p-4 md:p-8 mx-2 md:mx-0 md:w-2/3 inline-block bg-white border border-grey-100 rounded-md", style: { boxShadow: '0px 2px 24px rgba(18, 33, 46, 0.04' } },
            React.createElement("div", { className: "pb-10" },
                React.createElement("div", { className: "font-semibold text-lg text-gray-850" }, header),
                React.createElement("div", { className: "pt-2 font-medium text-sm text-gray-650" }, subheader)),
            React.createElement("div", { className: "grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-6" }, children))));
}
