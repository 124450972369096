import React from 'react';
export function EditorErrorMessage({ newspaper, continueWithLargeFile, postWithoutFormatting, isPublisher }) {
    var _a;
    if (((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.displayOnlyAds) &&
        !continueWithLargeFile &&
        !postWithoutFormatting) {
        return (React.createElement("div", null,
            React.createElement("p", { className: "mt-46 mb-10 text-center text-gray-600 text-3xl leading-normal" },
                React.createElement("span", null, "This newspaper only accepts display ads.")),
            React.createElement("p", { className: "text-center text-gray-600" }, "Please upload a notice file by clicking on the Upload File button above.")));
    }
    if (!continueWithLargeFile) {
        return (React.createElement("p", { className: "mt-46 mb-10 text-center text-gray-600 text-3xl leading-normal" }, isPublisher ? (React.createElement("span", null, "This notice was submitted without auto-formatting.")) : (React.createElement("span", null,
            "Notice formatting is disabled.",
            React.createElement("br", null),
            "The newspaper will format your file."))));
    }
    return null;
}
