import moment from 'moment';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { getNewspaperPublishedOnDate } from 'lib/utils/deadlines';
/**
 * Takes in a file URL and returns the file name. It explicitly handles:
 * 1) URLs from Imgix
 *    https://enotice-production.imgix.net/affidavit_automation/e-edition-uploads/dog.pdf
 *    => dog.pdf
 * 2) URLs from cloudinary
 *    https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/affidavit_automation/e-edition-uploads/dog.pdf?invalidate=true
 *    => dog.pdf
 * 3) URLs from exavault
 *    https://pod-sjc1-api.exavault.com/v1.2/download?access_token=1f34c6a54e3c17b79addd250b7dbc6d7e4556190b18907e0f39ebf5e1d1bf358&filePaths=%2Faffidavit_automation%2FS8fqyy2TfAOkN7xjZEts_Grand_Junction%2FGJDS_20230103_A02.pdf
 *    => GJDS_20230103_A02.pdf
 * @param url
 * @returns file name
 */
export const affidavitUploadURLToFileName = (url) => {
    const filePath = url.split('/').pop();
    const exavaultHandledFile = filePath.split('%2F').pop();
    const cloudinaryHandledFile = exavaultHandledFile.replace('?invalidate=true', '');
    const firebaseStorageHandledFile = cloudinaryHandledFile.split('?alt=media&token')[0];
    const manualPrefixHandledFile = firebaseStorageHandledFile.startsWith('manual_')
        ? firebaseStorageHandledFile.replace('manual_', '')
        : firebaseStorageHandledFile;
    const emailPrefixHandledFile = manualPrefixHandledFile.startsWith('email_')
        ? manualPrefixHandledFile.replace('email_', '')
        : manualPrefixHandledFile;
    return emailPrefixHandledFile;
};
const MANUAL_UPLOAD = 'Manual';
const EMAIL_UPLOAD = 'Email';
const FTP_UPLOAD = 'FTP';
/**
 * Returns the upload method for a given PDF URL
 * @param url URL of the uploaded PDF
 * @returns upload method for the PDF
 */
export const affidavitUploadURLToUploadMethod = (url) => {
    if (url.includes('manual_'))
        return MANUAL_UPLOAD;
    if (url.includes('email_'))
        return EMAIL_UPLOAD;
    return FTP_UPLOAD;
};
/**
 * Takes in an upload method and returns a description of how the file was uploaded
 * @param uploadMethod
 * @returns description of how the file was uploaded
 */
export const uploadMethodToUploadMethodDescription = (uploadMethod) => {
    return {
        [MANUAL_UPLOAD]: 'manually',
        [EMAIL_UPLOAD]: 'via email',
        [FTP_UPLOAD]: 'via FTP'
    }[uploadMethod];
};
const UPLOAD_CACHE_DATE_FORMAT = 'MM/DD/YYYY';
const isNonEmpty = (arr) => {
    return arr.length > 0;
};
/**
 * Given a set of upload days, returns the first and last upload day. This requires a non-empty list.
 * @param uploadDays
 * @returns { firstUploadDay: Date, lastUploadDay: Date} start and end of the range
 */
const getFirstAndLastPublishingDayFromUploadDays = (uploadDays) => {
    const { firstUploadDay, lastUploadDay } = uploadDays.reduce((acc, uploadDay) => {
        const { publishingDate } = uploadDay;
        if (!acc.firstUploadDay || publishingDate < acc.firstUploadDay) {
            acc.firstUploadDay = publishingDate;
        }
        if (!acc.lastUploadDay || publishingDate > acc.lastUploadDay) {
            acc.lastUploadDay = publishingDate;
        }
        return acc;
    }, {
        firstUploadDay: null,
        lastUploadDay: null
    });
    if (!lastUploadDay || !firstUploadDay) {
        throw new Error('Cannot get first and last upload day from empty set of upload days. Inputs to getFirstAndLastPublishingDayFromUploadDays must be non-empty and should be guarded with isNonEmpty.');
    }
    return { firstUploadDay, lastUploadDay };
};
/**
 * Fills in all of the upload days that are currently missing from the upload cache.
 * This is used by publishers to see where they are missing FTP uploads.
 * The upload cache is a map of dates to AffidavitUploadDay objects, which is modified in place.
 * @param activeOrganization organization
 * @param uploadCache upload day cache
 */
const addMissingUploadDaysToUploadDays = (activeOrganization, uploadDays) => {
    if (!isNonEmpty(uploadDays))
        return uploadDays;
    const { lastUploadDay, firstUploadDay } = getFirstAndLastPublishingDayFromUploadDays(uploadDays);
    if (!lastUploadDay || !firstUploadDay) {
        return uploadDays;
    }
    const uploadDaysWithMissing = [...uploadDays];
    for (let potentialPublishingDate = moment(firstUploadDay); moment(potentialPublishingDate).isBefore(lastUploadDay); potentialPublishingDate.add(1, 'day')) {
        const potentialPublishingDateStr = potentialPublishingDate.format(UPLOAD_CACHE_DATE_FORMAT);
        if (!getNewspaperPublishedOnDate(activeOrganization, potentialPublishingDate.toDate()))
            continue;
        if (!uploadDays.find(uploadDay => uploadDay.publishingDateString === potentialPublishingDateStr)) {
            uploadDaysWithMissing.push({
                publishingDateString: potentialPublishingDateStr,
                noticesNotValidated: [],
                noticesValidated: [],
                publishingDate: potentialPublishingDate.toDate(),
                pdfsUploaded: []
            });
        }
    }
    return uploadDaysWithMissing;
};
/**
 * Takes in a set of affidavit upload events and collapses all of the snapshots on the same day into a single object
 * @param affidavitUploadEvents Set of affidavit upload events
 * @returns {AffidavitUploadDay} Array of AffidavitUploadDay objects
 */
export const affidavitUploadEventsToAffidavitUploadDays = (activeOrganization, affidavitUploadEvents) => {
    const uploadCache = {};
    affidavitUploadEvents.forEach(event => {
        const { createdAt, data: { noticesNotValidated, noticesValidated, runDate, pdfUrl } } = event.data();
        const runDateMoment = moment.utc(firestoreTimestampOrDateToDate(runDate));
        const uploadDateString = runDateMoment.format(UPLOAD_CACHE_DATE_FORMAT);
        if (!uploadCache[uploadDateString]) {
            uploadCache[uploadDateString] = {
                publishingDateString: uploadDateString,
                noticesNotValidated: [],
                noticesValidated: [],
                publishingDate: runDateMoment.toDate(),
                pdfsUploaded: []
            };
        }
        const currentCacheValue = uploadCache[uploadDateString];
        uploadCache[uploadDateString] = Object.assign(Object.assign({}, currentCacheValue), { noticesNotValidated: currentCacheValue.noticesNotValidated.concat(noticesNotValidated), noticesValidated: currentCacheValue.noticesValidated.concat(noticesValidated), pdfsUploaded: currentCacheValue.pdfsUploaded.concat({
                uploadMethod: affidavitUploadURLToUploadMethod(pdfUrl),
                uploadDate: firestoreTimestampOrDateToDate(createdAt),
                pdfName: affidavitUploadURLToFileName(pdfUrl),
                pdfUrl
            }) });
    });
    const allUploadDays = Object.values(uploadCache).map(uploadDay => {
        const dedupedValidatedNoticeIDs = new Set(uploadDay.noticesValidated.map(notice => notice.id));
        const dedupedNotValidatedNoticeIDs = new Set(uploadDay.noticesNotValidated
            .map(notice => notice.id)
            .filter(noticeID => !dedupedValidatedNoticeIDs.has(noticeID)));
        const dedupedPdfs = uploadDay.pdfsUploaded.reduce((acc, pdf) => {
            if (!acc.find(p => p.pdfName === pdf.pdfName)) {
                acc.push(pdf);
            }
            return acc;
        }, []);
        return Object.assign(Object.assign({}, uploadDay), { pdfsUploaded: dedupedPdfs, noticesNotValidated: [...dedupedNotValidatedNoticeIDs.values()].map(id => getFirebaseContext().userNoticesRef().doc(id)), noticesValidated: [...dedupedValidatedNoticeIDs.values()].map(id => getFirebaseContext().userNoticesRef().doc(id)) });
    });
    // find the range of uploads containing notices
    const uploadDaysMatchingNotices = allUploadDays.filter(uploadDay => {
        // don't show uploads with no notices associated
        if (!uploadDay.noticesValidated.length &&
            !uploadDay.noticesNotValidated.length)
            return false;
        return true;
    });
    if (!isNonEmpty(uploadDaysMatchingNotices)) {
        return [];
    }
    const validUploadDayRange = getFirstAndLastPublishingDayFromUploadDays(uploadDaysMatchingNotices);
    // filter out upload days that are outside of the valid range
    const validUploadDays = allUploadDays.filter(uploadDay => {
        const uploadDayMoment = moment(uploadDay.publishingDate);
        return (uploadDayMoment.isSameOrAfter(validUploadDayRange.firstUploadDay) &&
            uploadDayMoment.isSameOrBefore(validUploadDayRange.lastUploadDay));
    });
    const uploadDaysWithMissing = addMissingUploadDaysToUploadDays(activeOrganization, validUploadDays);
    return uploadDaysWithMissing;
};
export const ALL_VERIFICATION_STATUSES_VERIFICATION_STATUS = 'Show all';
export const ALL_NOTICES_VERIFIED_VERIFICATION_STATUS = 'Publication days with no unverified notices';
export const MISSING_NOTICE_VERIFICATIONS_UPLOAD_STATUS = 'Publication days with unverified notices';
export const MISSING_E_EDITION_UPLOAD_STATUS = 'Publication days without e-edition';
export const VERIFICATION_STATUSES = [
    ALL_VERIFICATION_STATUSES_VERIFICATION_STATUS,
    ALL_NOTICES_VERIFIED_VERIFICATION_STATUS,
    MISSING_NOTICE_VERIFICATIONS_UPLOAD_STATUS,
    MISSING_E_EDITION_UPLOAD_STATUS
];
export const ALL_PUBLICATION_DATES = 'All publication dates';
export const DEFAULT_AFFIDAVIT_UPLOAD_DAY_FILTER = {
    verificationStatus: ALL_VERIFICATION_STATUSES_VERIFICATION_STATUS,
    dateRange: ALL_PUBLICATION_DATES,
    search: ''
};
/**
 * Filters out the set of affidavit upload days based on the search criteria
 * @param affidavitUploadDays days to consider
 * @param uploadFilter current filter state
 * @returns upload days that pass the criteria of filtering
 */
export const filterAndSortAffidavitUploadDays = (affidavitUploadDays, uploadFilter) => {
    return affidavitUploadDays
        .filter(uploadDay => {
        // filter out using the search string. Importantly, all strings in JS vacuously match ""
        const datesMatchSearch = uploadDay.publishingDateString.includes(uploadFilter.search);
        const pdfsMatchSearch = uploadDay.pdfsUploaded.some(pdf => pdf.pdfName.toLowerCase().includes(uploadFilter.search.toLowerCase()));
        const validNoticesMatchSearch = uploadDay.noticesValidated.some(notice => notice.id.includes(uploadFilter.search));
        const invalidNoticesMatchSearch = uploadDay.noticesNotValidated.some(notice => notice.id.includes(uploadFilter.search));
        if (!datesMatchSearch &&
            !pdfsMatchSearch &&
            !validNoticesMatchSearch &&
            !invalidNoticesMatchSearch)
            return false;
        // filter out base on verification status
        if (uploadFilter.verificationStatus ===
            ALL_NOTICES_VERIFIED_VERIFICATION_STATUS &&
            uploadDay.noticesNotValidated.length > 0)
            return false;
        if (uploadFilter.verificationStatus ===
            MISSING_NOTICE_VERIFICATIONS_UPLOAD_STATUS &&
            uploadDay.noticesNotValidated.length === 0)
            return false;
        if (uploadFilter.verificationStatus === MISSING_E_EDITION_UPLOAD_STATUS &&
            uploadDay.pdfsUploaded.length > 0)
            return false;
        // filter out based on date range
        if (uploadFilter.dateRange !== ALL_PUBLICATION_DATES) {
            const uploadDate = uploadDay.publishingDate;
            if (uploadDate < uploadFilter.dateRange.start)
                return false;
            if (uploadDate > uploadFilter.dateRange.end)
                return false;
        }
        return true;
    })
        .sort((a, b) => b.publishingDate.getTime() - a.publishingDate.getTime());
};
