var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BuildingIcon } from 'icons';
import { RoleType } from 'lib/enums';
import React, { useState } from 'react';
import { getInviteRoleOptions } from 'lib/utils/invites';
import { getOrganizationIconColors } from '../modals/JoinOrganizationModals/helpers';
import ColumnSelect from '../select/ColumnSelect';
import { ColumnButton } from '../ColumnButton';
import VerticalDivider from '../VerticalDivider';
const InviteActionCard = ({ invite, request, onAcceptClick, onDeclineClick, index, className, type, isPublisherOrg, updateUserRole }) => {
    const [acceptingInviteLoading, setAcceptingInviteLoading] = useState(false);
    const [decliningInviteLoading, setDecliningInviteLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const iconStyles = getOrganizationIconColors(index);
    return (React.createElement("div", { style: {
            boxShadow: '0px 2px 16px rgba(18, 33, 46, 0.04)'
        }, className: `${className} border-gray-300 p-5 bg-white space-y-6`, key: `${index}-invites-row` },
        React.createElement("div", { key: `${index}-inviter-avatar`, className: "relative flex items-center space-x-4" },
            React.createElement("div", { className: "flex-shrink-0" },
                React.createElement("div", { className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` },
                    React.createElement(BuildingIcon, { style: {
                            margin: 'auto',
                            transform: 'translateY(40%)'
                        }, stroke: iconStyles.stroke }))),
            React.createElement("div", { className: "min-w-0 flex-1" },
                type === 'invite' && invite && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "text-left text-sm font-medium text-gray-650" },
                        "You have received an invite",
                        invite.userName ? (React.createElement(React.Fragment, null,
                            React.createElement("span", null, " from "),
                            React.createElement("span", { className: "font-semibold text-grey-500" }, invite.userName))) : (''),
                        React.createElement("span", null, " to join "),
                        React.createElement("span", { className: "font-semibold text-grey-500" }, invite.userInvite.data().organizationName)))),
                type === 'request' && request && (React.createElement("div", null,
                    React.createElement("div", { className: "text-left text-sm font-medium text-gray-650" },
                        "You've received a request",
                        request.userName ? (React.createElement(React.Fragment, null,
                            React.createElement("span", null, " from "),
                            React.createElement("span", { className: "font-semibold text-grey-500" }, request.userName))) : (''),
                        React.createElement("span", null, " to join "),
                        React.createElement("span", { className: "font-semibold text-grey-500" }, request.organizationName),
                        React.createElement("span", { className: "pl-1" },
                            "with the role",
                            React.createElement(ColumnSelect, { options: getInviteRoleOptions(isPublisherOrg).map(role => {
                                    return { label: role.label, value: role.label };
                                }), onChange: value => {
                                    setUserRole(RoleType.by_label(value).label);
                                    updateUserRole && updateUserRole(value);
                                }, value: userRole, className: "pl-1 w-40 inline-block", inlineTextColor: 'text-primary-500', itemsListDescription: "Choose user's role", inline: true }))))))),
        React.createElement("div", { key: `${index}-invite-actions`, className: "flex w-full justify-end mt-4" },
            React.createElement(ColumnButton, { size: "sm", buttonText: 'Decline', loading: decliningInviteLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setDecliningInviteLoading(true);
                    yield onDeclineClick(invite);
                    setDecliningInviteLoading(false);
                }) }),
            React.createElement(VerticalDivider, { key: `${index}-vertical-divider` }),
            React.createElement(ColumnButton, { size: "sm", secondary: true, buttonText: 'Accept', loading: acceptingInviteLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setAcceptingInviteLoading(true);
                    yield onAcceptClick(invite);
                    setAcceptingInviteLoading(false);
                }) }))));
};
export default InviteActionCard;
