import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, Box } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import WeekdaySelector, { getSelectedWeekdaysEnums } from './WeekdaySelector';
import AdvertisingDeadlinesTable from './AdvertisingDeadlinesTable';
const styles = (theme) => createStyles({
    gridRow: {
        marginRight: theme.spacing(2),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    helpIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem'
    },
    switch: {
        paddingRight: theme.spacing(2)
    },
    toolTip: {
        wordWrap: 'break-word',
        paddingRight: '2px',
        width: theme.spacing(32)
    }
});
const AdvertisingDeadlinesForm = ({ deadlines, setDeadlines, advertiserEditingEnabled, setAdvertiserEditingEnabled, classes }) => {
    const [selectedDayEnumValues, setSelectedDayEnumValues] = useState(getSelectedWeekdaysEnums(deadlines));
    useEffect(() => {
        const newDeadlines = [...deadlines];
        newDeadlines.forEach(deadline => {
            if (selectedDayEnumValues.includes(deadline.dayEnum)) {
                // eslint-disable-next-line no-param-reassign
                deadline.publish = true;
            }
            if (!selectedDayEnumValues.includes(deadline.dayEnum)) {
                // eslint-disable-next-line no-param-reassign
                deadline.publish = false;
            }
        });
        setDeadlines(newDeadlines);
    }, [selectedDayEnumValues]);
    return (React.createElement(Grid, { container: true, direction: "column" },
        React.createElement("div", { className: "flex items-center flex-col justify-center w-full lg:block border-b border-gray-200 pb-4 pt-.5" },
            React.createElement("div", { className: "lg:pl-4 py-4 inline-block" },
                React.createElement(WeekdaySelector, { selectedDayEnumValues: getSelectedWeekdaysEnums(deadlines), setSelectedDayEnumValues: setSelectedDayEnumValues })),
            React.createElement("div", { className: "flex flex-row items-center lg:mr-7 pt-4 pr-2 pb-2 flex-left inline-block float-right lg:block" },
                ' ',
                React.createElement(Grid, { item: true },
                    React.createElement("div", { className: "flex items-center lg:items-center lg:flex-row lg:float-none" },
                        React.createElement(Box, null),
                        React.createElement(Tooltip, { classes: { tooltip: classes.toolTip }, title: `When enabled, your clients will be able to edit their notices
             up until ad deadline if no invoice has been created. You will be notified of edits.` },
                            React.createElement(Help, { color: "disabled", className: classes.helpIcon })),
                        React.createElement("p", { className: "lg:text-md lg:opacity-75 lg:text-gray-800 lg:font-medium lg:justify-center", style: { color: 'rgba(42, 57, 74, 0.67)' } }, "Allow customers to edit notices?"))),
                React.createElement("div", { className: "items-center justify-center flex ml-2 lg:content-end lg:justify-end lg:ml-0 lg:mt-2", onClick: () => setAdvertiserEditingEnabled &&
                        setAdvertiserEditingEnabled(!advertiserEditingEnabled) },
                    React.createElement("span", { role: "checkbox", "aria-checked": advertiserEditingEnabled, tabIndex: 0, className: `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`, style: {
                            backgroundColor: advertiserEditingEnabled
                                ? 'rgb(47, 128, 237)'
                                : '#edf2f7'
                        } },
                        React.createElement("span", { "aria-hidden": "true", className: `${advertiserEditingEnabled ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200` }))))),
        React.createElement("div", { className: "w-full overflow-x-scroll" },
            React.createElement(AdvertisingDeadlinesTable, { deadlines: deadlines, setDeadlines: setDeadlines, setSelectedDayEnumValues: setSelectedDayEnumValues }))));
};
export default withStyles(styles, { withTheme: true })(AdvertisingDeadlinesForm);
