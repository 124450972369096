import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControlLabel, Grid } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { State as States } from 'lib/enums';
import { exists } from 'lib/types';
import { isMemberOfOrganization } from 'lib/utils/users';
import ScrollStep from './ScrollStep';
import SelectDropdown from './SelectDropdown';
const styles = () => createStyles({});
const InvoiceRecipientStep = ({ id, activeStepId, placementActions, user, isPublisher, classes, next, previous, onDisabledStepClick }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const invoiceRecipient = useSelector((state) => state.placement.invoiceRecipient);
    const invoiceRecipientEmail = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'email' ? invoiceRecipient : undefined;
    const invoiceRecipientMail = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'mail' ? invoiceRecipient : undefined;
    const defaultAddress = invoiceRecipientMail === null || invoiceRecipientMail === void 0 ? void 0 : invoiceRecipientMail.address;
    const [sendToSomeoneElse, setSendToSomeoneElse] = useState(!!invoiceRecipient);
    const [sendToEmail, setSendToEmail] = useState(!((invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'mail'));
    const [firstName, setFirstName] = useState((_a = invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.firstName) !== null && _a !== void 0 ? _a : '');
    const [lastName, setLastName] = useState((_b = invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.lastName) !== null && _b !== void 0 ? _b : '');
    const [organizationName, setOrganizationName] = useState((_c = invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.organizationName) !== null && _c !== void 0 ? _c : '');
    const [email, setEmail] = useState((_d = invoiceRecipientEmail === null || invoiceRecipientEmail === void 0 ? void 0 : invoiceRecipientEmail.email) !== null && _d !== void 0 ? _d : '');
    const [recipientEmailFocused, setRecipientEmailFocused] = useState(false);
    const [addressLine1, setAddressLine1] = useState((_e = defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_line1) !== null && _e !== void 0 ? _e : '');
    const [addressLine2, setAddressLine2] = useState((_f = defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_line2) !== null && _f !== void 0 ? _f : '');
    const [city, setCity] = useState((_g = defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_city) !== null && _g !== void 0 ? _g : '');
    const [state, setState] = useState(typeof (defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_state) === 'string'
        ? defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_state
        : '');
    const [zip, setZip] = useState((_j = (_h = invoiceRecipientMail === null || invoiceRecipientMail === void 0 ? void 0 : invoiceRecipientMail.address) === null || _h === void 0 ? void 0 : _h.address_zip) !== null && _j !== void 0 ? _j : '');
    const [recipientMailingFocused, setRecipientMailingFocused] = useState(false);
    const textInputClasses = 'form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5';
    const checkboxInputClasses = 'form-checkbox cursor-pointer h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3';
    const errorMessageClasses = 'text-xs text-red-600 font-medium';
    const isAdvertiserOrgMember = user && exists(user) && isMemberOfOrganization(user);
    const onExit = () => {
        if (!sendToSomeoneElse) {
            const data = {
                invoiceRecipient: null
            };
            placementActions.confirmInvoiceRecipient(data);
            return;
        }
        const basicInfo = {
            firstName,
            lastName,
            organizationName
        };
        if (sendToEmail) {
            const invoiceRecipient = Object.assign({ type: 'email', email }, basicInfo);
            const data = {
                invoiceRecipient
            };
            placementActions.confirmInvoiceRecipient(data);
        }
        else {
            const invoiceRecipient = Object.assign({ type: 'mail', address: {
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    address_city: city,
                    address_state: state,
                    address_zip: zip
                } }, basicInfo);
            const data = {
                invoiceRecipient
            };
            placementActions.confirmInvoiceRecipient(data);
        }
    };
    const notEmpty = (value) => {
        return !!(value && value.length > 0);
    };
    const sendToMailingAddress = sendToSomeoneElse && !sendToEmail;
    const recipientInfoValid = [firstName, lastName].every(notEmpty);
    const emailRe = /.+?@.+?\..+/;
    const recipientEmailValid = !sendToEmail || (notEmpty(email) && emailRe.test(email));
    const recipientEmailError = notEmpty(email) && !recipientEmailValid
        ? 'Please enter a valid email address'
        : '';
    const zipRe = /^[0-9]{5}$/;
    const zipValid = zipRe.test(zip.trim());
    const recipientMailingValid = !sendToMailingAddress ||
        ([addressLine1, city, state, zip].every(notEmpty) && zipValid);
    const recipientMailingError = notEmpty(zip) && !zipValid ? 'Please enter a valid zip code' : '';
    const isStepComplete = !sendToSomeoneElse ||
        (recipientInfoValid && recipientEmailValid && recipientMailingValid);
    const renderRecipientInfoForm = () => {
        return (React.createElement(Grid, { className: "py-2", container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement("input", { id: "field-first-name", className: textInputClasses, type: "text", autoComplete: "given-name", placeholder: "First name *", value: firstName, onChange: e => setFirstName(e.target.value) })),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement("input", { id: "field-last-name", className: textInputClasses, type: "text", autoComplete: "family-name", placeholder: "Last name *", value: lastName, onChange: e => setLastName(e.target.value) })),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement("input", { id: "field-organization", className: textInputClasses, type: "text", placeholder: "Organization", value: organizationName, onChange: e => setOrganizationName(e.target.value) }))));
    };
    const renderRecipientEmailForm = () => {
        return (React.createElement(Grid, { id: "form-recipient-email", container: true, spacing: 1, className: "pt-2" },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement("input", { id: "field-email", className: textInputClasses, type: "text", autoComplete: "email", placeholder: "Email address *", value: email, onFocus: () => setRecipientEmailFocused(true), onBlur: () => setRecipientEmailFocused(false), onChange: e => setEmail(e.target.value) }),
                recipientEmailError && !recipientEmailFocused && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement("p", { className: `mt-1 ${errorMessageClasses}` }, recipientEmailError))))));
    };
    const renderRecipientMailingForm = () => {
        return (React.createElement(Grid, { id: "form-recipient-mailing", container: true, spacing: 1, className: "pt-2", onFocus: () => setRecipientMailingFocused(true), onBlur: () => setRecipientMailingFocused(false) },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement("input", { id: "field-address-line-1", className: textInputClasses, type: "text", autoComplete: "address-line1", placeholder: "Address line 1 *", value: addressLine1, onChange: e => setAddressLine1(e.target.value) })),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement("input", { id: "field-address-line-2", className: textInputClasses, type: "text", autoComplete: "address-line2", placeholder: "Address line 2", value: addressLine2, onChange: e => setAddressLine2(e.target.value) })),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement("input", { id: "field-city", className: textInputClasses, type: "text", autoComplete: "address-level2", placeholder: "City *", value: city, onChange: e => setCity(e.target.value) })),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(SelectDropdown, { id: "field-state", className: "state-select", placeholder: "State *", onChange: (selected) => {
                        setState(selected.label);
                    }, value: state && {
                        id: States.by_label(state),
                        label: state
                    }, selected: state && {
                        id: States.by_label(state),
                        label: state
                    }, options: States.items().map((state) => ({
                        id: state.value,
                        label: state.label
                    })), placeholderText: '#6B7280' })),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement("input", { id: "field-zip", className: textInputClasses, type: "text", autoComplete: "postal-code", placeholder: "Zip code *", value: zip, onChange: e => setZip(e.target.value.substring(0, 5)) })),
            recipientMailingError && !recipientMailingFocused && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement("p", { className: errorMessageClasses }, recipientMailingError)))));
    };
    const renderSendToSomeoneElseForm = () => {
        return (React.createElement(Box, { id: "form-someone-else" },
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement("div", { className: "mt-4 font-normal text-sm text-gray-700 uppercase" }, "Recipient Info"),
                renderRecipientInfoForm(),
                React.createElement("div", { className: "mt-4 font-normal text-sm text-gray-700 uppercase" }, "Recipient Address"),
                !isPublisher && (React.createElement(Grid, { container: true, spacing: 1, className: "pt-2" },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: sendToEmail, onChange: () => setSendToEmail(!sendToEmail), id: "checkbox-email", type: "checkbox", className: checkboxInputClasses }), label: "Email" })),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: !sendToEmail, onChange: () => setSendToEmail(!sendToEmail), id: "checkbox-mailing-address", type: "checkbox", className: checkboxInputClasses }), label: "Mailing address" })))),
                sendToEmail && renderRecipientEmailForm(),
                !sendToEmail && renderRecipientMailingForm())));
    };
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, complete: isStepComplete, next: () => {
            onExit();
            next();
        }, previous: () => {
            onExit();
            previous();
        }, onDisabledStepClick: onDisabledStepClick, title: "Invoice Information", caption: isPublisher
            ? 'Where should the invoice be sent?'
            : 'Who should be billed for this notice?' },
        React.createElement(Grid, { className: classes.content },
            isPublisher && (React.createElement(Box, null,
                React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: sendToEmail, type: "checkbox", className: checkboxInputClasses, onChange: () => {
                            setSendToSomeoneElse(!sendToSomeoneElse);
                            setSendToEmail(sendToSomeoneElse);
                        } }), label: 'Send the invoice to the customer by email' }))),
            isPublisher && (React.createElement(Box, null,
                React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: sendToSomeoneElse, type: "checkbox", className: checkboxInputClasses, onChange: () => {
                            setSendToSomeoneElse(!sendToSomeoneElse);
                            setSendToEmail(sendToSomeoneElse);
                        } }), label: 'Send the invoice to the customer by mail' }))),
            !isPublisher && (React.createElement(Box, null,
                React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: !sendToSomeoneElse, onChange: () => setSendToSomeoneElse(!sendToSomeoneElse), id: "checkbox-send-to-me", type: "checkbox", className: checkboxInputClasses }), label: isAdvertiserOrgMember
                        ? 'Send the invoice to my organization'
                        : 'Send the invoice to me' }))),
            !isPublisher && (React.createElement(Box, null,
                React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: sendToSomeoneElse, onChange: () => setSendToSomeoneElse(!sendToSomeoneElse), id: "checkbox-send-to-someone-else", type: "checkbox", className: checkboxInputClasses }), label: "Send the invoice to someone else" }))),
            React.createElement(Box, { className: "pl-8" }, sendToSomeoneElse && renderSendToSomeoneElseForm()))));
};
export default withStyles(styles)(InvoiceRecipientStep);
