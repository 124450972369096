import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Handlebars from 'handlebars';
import { MadlibInput } from 'routes/madlib/elements/MadlibInput';
import { MadlibDateInput } from 'routes/madlib/elements/MadlibDateInput';
import { MadlibHint } from 'routes/madlib/elements/MadlibHint';
import { MadlibMultipleChoiceInput } from 'routes/madlib//elements/MadlibMultipleChoiceInput';
import { safeStringify } from 'lib/utils/stringify';
import { templateStylesToReactStyles } from 'utils/styles';
import { registerHandlebarsHelpers } from '../helpers/handlebars';
// Define custom elements
if (!customElements.get(MadlibInput.TAG_NAME)) {
    customElements.define(MadlibInput.TAG_NAME, MadlibInput);
}
if (!customElements.get(MadlibDateInput.TAG_NAME)) {
    customElements.define(MadlibDateInput.TAG_NAME, MadlibDateInput);
}
if (!customElements.get(MadlibHint.TAG_NAME)) {
    customElements.define(MadlibHint.TAG_NAME, MadlibHint);
}
if (!customElements.get(MadlibMultipleChoiceInput.TAG_NAME)) {
    customElements.define(MadlibMultipleChoiceInput.TAG_NAME, MadlibMultipleChoiceInput);
}
const MadlibEditor = ({ rawTemplate, questionTemplateData, templateStyles, columns, onStateChange }) => {
    const [innerHtml, setInnerHtml] = useState('');
    const [state, setState] = useState({
        fields: [],
        templateData: {},
        renderedHtml: ''
    });
    const css = Object.assign(Object.assign({}, (templateStyles
        ? templateStylesToReactStyles(templateStyles, columns, true)
        : {})), { 
        // Hyphens
        wordWrap: 'break-word', wordBreak: 'break-word', hyphens: 'auto', 
        // Zoom
        transform: 'scale(2.0)', transformOrigin: '0 0', 
        // Padding at the bottom to give space for hints
        paddingTop: '0.5em', paddingBottom: '2em' });
    const template = useMemo(() => {
        return Handlebars.compile(rawTemplate);
    }, [rawTemplate]);
    const getMadlibState = () => {
        const els = [
            ...document.querySelectorAll('madlib-input'),
            ...document.querySelectorAll('madlib-multiple-choice-input'),
            ...document.querySelectorAll('madlib-date-input')
        ];
        const newTemplateData = {};
        const fields = [];
        for (const el of els) {
            const { key, hint, placeholder, value } = el;
            if (!key) {
                console.warn('madlib-input without key', el);
                continue;
            }
            fields.push({
                varName: key,
                value,
                hint: hint || '',
                placeholder: placeholder || ''
            });
            newTemplateData[key] = value;
        }
        const previousTemplateData = {};
        for (const [key, value] of Object.entries(state.templateData)) {
            if (value) {
                previousTemplateData[key] = value;
            }
        }
        return {
            fields,
            templateData: Object.assign(Object.assign({}, previousTemplateData), newTemplateData)
        };
    };
    const onMadlibEditorUpdate = useCallback(() => {
        const partialState = getMadlibState();
        const data = Object.assign(Object.assign({}, questionTemplateData), partialState.templateData);
        const renderedHtml = template(data, {
            data: {
                editMode: false
            }
        });
        setState(Object.assign(Object.assign({}, partialState), { renderedHtml }));
    }, [template, questionTemplateData]);
    useEffect(() => {
        registerHandlebarsHelpers();
    }, []);
    useEffect(() => {
        window.addEventListener('template-updated', onMadlibEditorUpdate);
        return () => {
            window.removeEventListener('template-updated', onMadlibEditorUpdate);
        };
    }, [onMadlibEditorUpdate]);
    useEffect(() => {
        const data = Object.assign(Object.assign({}, questionTemplateData), state.templateData);
        // Compile the madlibs (non-rendered) HTML
        const html = template(data, {
            data: {
                editMode: true
            }
        });
        setInnerHtml(html);
    }, [rawTemplate, safeStringify(questionTemplateData)]);
    useEffect(() => {
        // Trigger an editor update
        onMadlibEditorUpdate();
    }, [innerHtml]);
    useEffect(() => {
        onStateChange(state);
    }, [safeStringify(state)]);
    return (React.createElement("div", { id: "madlib", style: css, dangerouslySetInnerHTML: { __html: innerHtml } }));
};
export default MadlibEditor;
