import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { ColumnButton } from 'components/ColumnButton';
import { ExclamationCircle } from 'icons';
import { NoticeType } from 'lib/enums';
import React from 'react';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
export const EditCustomerModalSettings = ({ changedFields, setChangedFields, customerData, rates, linerRate, setLinerRate, displayRate, setDisplayRate, activeOrganization, internalID, setInternalID, setRequireUpfrontPayment, requireUpfrontPaymentId, setRequireUpfrontPaymentId, affidavitsBeforePayment, setAffidavitsBeforePayment, allowBulkInvoicing, setAllowBulkInvoicing, invoicedOutsideColumn, setInvoicedOutsideColumn, hideDigitalAffidavits, allowAffidavitEmail, setAllowAffidavitEmail, onClose, onSave, disableSave }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const DEFAULT_SETTING = 0;
    const ALWAYS_REQUIRE = 1;
    const NEVER_REQUIRE = 2;
    const REQUIRE_UPFRONT_PAYMENT_OPTIONS = [
        { id: DEFAULT_SETTING, description: 'Default' },
        { id: ALWAYS_REQUIRE, description: 'Always require' },
        { id: NEVER_REQUIRE, description: 'Never require' }
    ];
    return (React.createElement("div", { className: "pt-6 pb-8 flex justify-center" },
        React.createElement("div", { className: "w-2/3 bg-white border border-grey-100 rounded-md", style: { boxShadow: '0px 2px 24px rgba(18, 33, 46, 0.04' } },
            React.createElement("div", { className: "px-8" },
                React.createElement("div", { className: "pt-8 font-semibold text-lg text-gray-850" }, "Set up your customer's preferences."),
                React.createElement("div", { className: "pt-2 font-medium text-sm text-gray-650" }, "Configure how you want this customer to interact with your publication."),
                React.createElement("div", { className: "pt-10 font-medium text-sm leading-6" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "w-full inline-block" },
                            React.createElement("div", { className: "bg-yellow-100 text-yellow-700 rounded-md flex align-middle" },
                                React.createElement("div", { className: "pt-2" },
                                    React.createElement(ExclamationCircle, { className: "w-10 h-10", style: { paddingLeft: '18.5px' } })),
                                React.createElement("div", { className: "py-3.5 px-4" }, "Customer liner and display rates are set to your organization's default settings. You should only make changes if you want a different rate for this customer.")),
                            React.createElement("div", { className: "w-1/2 pt-8 pr-4 inline-block" },
                                React.createElement("div", null,
                                    React.createElement("div", { className: "flex items-center" },
                                        React.createElement("div", { className: "text-blue-800" }, "Liner rate"))),
                                React.createElement(SelectDropdown, { id: '', placeholder: "Liner Rates", onChange: (e) => {
                                        var _a;
                                        const selectedRate = rates === null || rates === void 0 ? void 0 : rates.find(r => r.id === e.id);
                                        if (selectedRate) {
                                            setLinerRate(selectedRate);
                                            if (selectedRate.id ===
                                                ((_a = customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().linerRate) === null || _a === void 0 ? void 0 : _a.id)) {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { linerRate: false }));
                                            }
                                            else {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { linerRate: true }));
                                            }
                                        }
                                    }, options: rates === null || rates === void 0 ? void 0 : rates.filter(rate => {
                                        var _a;
                                        return ((_a = rate.data()) === null || _a === void 0 ? void 0 : _a.noticeType) !==
                                            NoticeType.display_ad.value;
                                    }).map(rate => {
                                        var _a;
                                        return ({
                                            id: rate.id,
                                            label: (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description
                                        });
                                    }), selected: {
                                        id: customerData === null || customerData === void 0 ? void 0 : customerData.defaultLinerRate.id,
                                        label: (_a = customerData === null || customerData === void 0 ? void 0 : customerData.defaultLinerRate.data()) === null || _a === void 0 ? void 0 : _a.description
                                    }, value: {
                                        id: linerRate.id,
                                        label: (_b = linerRate.data()) === null || _b === void 0 ? void 0 : _b.description
                                    }, dropdownHeight: 'large' })),
                            React.createElement("div", { className: "w-1/2 pt-8 pl-4 inline-block" },
                                React.createElement("div", null,
                                    React.createElement("div", { className: "flex items-center" },
                                        React.createElement("div", { className: "text-blue-800" }, "Display rate"))),
                                React.createElement(SelectDropdown, { id: '', placeholder: "Display Rates", onChange: (e) => {
                                        var _a;
                                        const selectedRate = rates === null || rates === void 0 ? void 0 : rates.find(r => r.id === e.id);
                                        if (selectedRate) {
                                            setDisplayRate(selectedRate);
                                            if (selectedRate.id ===
                                                ((_a = customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().displayRate) === null || _a === void 0 ? void 0 : _a.id)) {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { displayRate: false }));
                                            }
                                            else {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { displayRate: true }));
                                            }
                                        }
                                    }, options: rates === null || rates === void 0 ? void 0 : rates.filter(rate => {
                                        var _a;
                                        return rate.data().supportsDisplay ||
                                            rate.id ===
                                                ((_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.defaultDisplayRate.id) ||
                                            rate.data().noticeType === NoticeType.display_ad.value;
                                    }).map(rate => ({
                                        id: rate.id,
                                        label: rate.data().description
                                    })), selected: {
                                        id: customerData === null || customerData === void 0 ? void 0 : customerData.defaultDisplayRate.id,
                                        label: (_c = customerData === null || customerData === void 0 ? void 0 : customerData.defaultDisplayRate.data()) === null || _c === void 0 ? void 0 : _c.description
                                    }, value: {
                                        id: displayRate.id,
                                        label: (_d = displayRate.data()) === null || _d === void 0 ? void 0 : _d.description
                                    }, dropdownHeight: 'large' })))),
                    React.createElement("div", { className: "flex items-center pt-6" },
                        React.createElement("div", { className: "w-1/2 pr-4 inline-block" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: "text-blue-800" }, "Internal ID"))),
                            React.createElement("input", { id: "address-line-2", className: "form-input focus:outline-none focus:border-blue-900 focus:shadow-outline-blue hover:border-blue-900 hover:shadow-outline-blue appearance-none rounded-md relative w-full bg-white px-4 py-3 mb-0 border border-grey-200 placeholder-gray-500 text-blue-800 font-medium text-sm leading-6", type: "text", value: internalID || '', onChange: e => {
                                    setInternalID(e.target.value);
                                    if (e.target.value ===
                                        (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().internalID)) {
                                        setChangedFields(Object.assign(Object.assign({}, changedFields), { internalID: false }));
                                    }
                                    else {
                                        setChangedFields(Object.assign(Object.assign({}, changedFields), { internalID: true }));
                                    }
                                } })),
                        React.createElement("div", { className: "w-1/2 pl-4 inline-block" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: "text-blue-800" }, "Do you require upfront payment for this customer?"))),
                            React.createElement(SelectDropdown, { id: '', placeholder: "Require upfront payment?", onChange: (e) => {
                                    if (e.id === 0) {
                                        setRequireUpfrontPayment(null);
                                        setRequireUpfrontPaymentId(DEFAULT_SETTING);
                                        if ((customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().requireUpfrontPayment) ===
                                            null) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: true }));
                                        }
                                    }
                                    else if (e.id === 1) {
                                        setRequireUpfrontPayment(true);
                                        setRequireUpfrontPaymentId(ALWAYS_REQUIRE);
                                        if (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().requireUpfrontPayment) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: true }));
                                        }
                                    }
                                    else {
                                        setRequireUpfrontPayment(false);
                                        setRequireUpfrontPaymentId(NEVER_REQUIRE);
                                        if ((customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().requireUpfrontPayment) ===
                                            false) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: true }));
                                        }
                                    }
                                }, options: REQUIRE_UPFRONT_PAYMENT_OPTIONS.map(option => ({
                                    id: option.id,
                                    label: option.description
                                })), selected: {
                                    id: customerData === null || customerData === void 0 ? void 0 : customerData.requireUpfrontPayment,
                                    label: ((_e = REQUIRE_UPFRONT_PAYMENT_OPTIONS.find(item => item.id === requireUpfrontPaymentId)) === null || _e === void 0 ? void 0 : _e.description) || ''
                                }, value: {
                                    id: requireUpfrontPaymentId,
                                    label: ((_f = REQUIRE_UPFRONT_PAYMENT_OPTIONS.find(item => item.id === requireUpfrontPaymentId)) === null || _f === void 0 ? void 0 : _f.description) || ''
                                }, disableAlphabetization: true, dropdownHeight: 'large' }))),
                    React.createElement("div", { className: "flex items-center pt-6" },
                        React.createElement("div", { className: classNames('inline-block', {
                                'w-1/2 pr-4': (_g = activeOrganization.data()) === null || _g === void 0 ? void 0 : _g.bulkPaymentEnabled,
                                'w-full': !((_h = activeOrganization.data()) === null || _h === void 0 ? void 0 : _h.bulkPaymentEnabled)
                            }) },
                            React.createElement("div", { className: "border border-grey-100 rounded-md" },
                                React.createElement("div", { className: "pt-4 pl-4 flex items-center" },
                                    React.createElement(Switch, { checked: !!affidavitsBeforePayment, onChange: () => {
                                            setAffidavitsBeforePayment(!affidavitsBeforePayment);
                                            if (!affidavitsBeforePayment ===
                                                Boolean(customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().enableAffidavitsBeforePayment)) {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { affidavitsBeforePayment: false }));
                                            }
                                            else {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { affidavitsBeforePayment: true }));
                                            }
                                        }, className: classNames(affidavitsBeforePayment ? 'bg-blue-900' : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                            width: '36px',
                                            height: '20px'
                                        } },
                                        React.createElement("span", { className: "sr-only" }, "Use setting"),
                                        React.createElement("span", { "aria-hidden": "true", className: classNames(affidavitsBeforePayment
                                                ? 'translate-x-4'
                                                : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })),
                                    React.createElement("div", { className: "text-blue-800 pl-3 font-medium text-sm leading-6 inline-block" }, "Allow affidavits before payment?")),
                                React.createElement("div", { className: "pt-2 pl-16 pr-4 pb-4 font-medium text-sm text-gray-650" }, "Customers will be allowed to download affidavits before paying invoices."))),
                        ((_j = activeOrganization.data()) === null || _j === void 0 ? void 0 : _j.bulkPaymentEnabled) && (React.createElement("div", { className: "w-1/2 pl-4 inline-block", "data-testid": "bulkInvoicing" },
                            React.createElement("div", { className: "border border-grey-100 rounded-md" },
                                React.createElement("div", { className: "pt-4 pl-4 flex items-center" },
                                    React.createElement(Switch, { checked: !!allowBulkInvoicing, onChange: () => {
                                            setAllowBulkInvoicing(!allowBulkInvoicing);
                                            if (!allowBulkInvoicing ===
                                                Boolean(customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().bulkPaymentEnabled)) {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { allowBulkInvoicing: false }));
                                            }
                                            else {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { allowBulkInvoicing: true }));
                                            }
                                        }, className: classNames(allowBulkInvoicing ? 'bg-blue-900' : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                            width: '36px',
                                            height: '20px'
                                        } },
                                        React.createElement("span", { className: "sr-only" }, "Use setting"),
                                        React.createElement("span", { "aria-hidden": "true", className: classNames(allowBulkInvoicing
                                                ? 'translate-x-4'
                                                : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })),
                                    React.createElement("div", { className: "text-blue-800 pl-3 font-medium text-sm leading-6 inline-block" }, "Allow bulk invoicing?")),
                                React.createElement("div", { className: "pt-2 pl-16 pr-4 pb-4 font-medium text-sm text-gray-650" }, "Toggle this on if you want to create one monthly invoice for this customer."))))),
                    (((_k = activeOrganization.data()) === null || _k === void 0 ? void 0 : _k.allowInvoiceOutsideColumn) ||
                        hideDigitalAffidavits) && (React.createElement("div", { className: "flex items-center pt-6" },
                        ((_l = activeOrganization.data()) === null || _l === void 0 ? void 0 : _l.allowInvoiceOutsideColumn) && (React.createElement("div", { "data-testid": "invoiceOutsideColumn", className: classNames('inline-block', {
                                'w-full': !hideDigitalAffidavits,
                                'w-1/2 pr-4': hideDigitalAffidavits
                            }) },
                            React.createElement("div", { className: "border border-grey-100 rounded-md" },
                                React.createElement("div", { className: "pt-4 pl-4 flex items-center" },
                                    React.createElement(Switch, { checked: !!invoicedOutsideColumn, onChange: () => {
                                            setInvoicedOutsideColumn(!invoicedOutsideColumn);
                                            if (!invoicedOutsideColumn ===
                                                Boolean(customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().invoicedOutsideColumn)) {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { invoicedOutsideColumn: false }));
                                            }
                                            else {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { invoicedOutsideColumn: true }));
                                            }
                                        }, className: classNames(invoicedOutsideColumn
                                            ? 'bg-blue-900'
                                            : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                            width: '36px',
                                            height: '20px'
                                        } },
                                        React.createElement("span", { className: "sr-only" }, "Use setting"),
                                        React.createElement("span", { "aria-hidden": "true", className: classNames(invoicedOutsideColumn
                                                ? 'translate-x-4'
                                                : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })),
                                    React.createElement("div", { className: "text-blue-800 pl-3 font-medium text-sm leading-6 inline-block" }, "Invoice outside Column?")),
                                React.createElement("div", { className: "pt-2 pl-16 pr-4 pb-4 font-medium text-sm text-gray-650" }, "By default, invoices created for this customer will be billed outside of Column.")))),
                        hideDigitalAffidavits && (React.createElement("div", { className: classNames('inline-block', {
                                'w-1/2 pl-4': (_m = activeOrganization.data()) === null || _m === void 0 ? void 0 : _m.allowInvoiceOutsideColumn,
                                'w-full': !((_o = activeOrganization.data()) === null || _o === void 0 ? void 0 : _o.allowInvoiceOutsideColumn)
                            }), "data-testid": "allowEmailAffidavit" },
                            React.createElement("div", { className: "border border-grey-100 rounded-md" },
                                React.createElement("div", { className: "pt-4 pl-4 flex items-center" },
                                    React.createElement(Switch, { checked: !!allowAffidavitEmail, onChange: () => {
                                            setAllowAffidavitEmail(!allowAffidavitEmail);
                                            if (!allowAffidavitEmail ===
                                                Boolean(customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().allowAffidavitEmail)) {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { allowAffidavitEmail: false }));
                                            }
                                            else {
                                                setChangedFields(Object.assign(Object.assign({}, changedFields), { allowAffidavitEmail: true }));
                                            }
                                        }, className: classNames(allowAffidavitEmail ? 'bg-blue-900' : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                            width: '36px',
                                            height: '20px'
                                        } },
                                        React.createElement("span", { className: "sr-only" }, "Use setting"),
                                        React.createElement("span", { "aria-hidden": "true", className: classNames(allowAffidavitEmail
                                                ? 'translate-x-4'
                                                : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })),
                                    React.createElement("div", { className: "text-blue-800 pl-3 font-medium text-sm leading-6 inline-block" }, "Allow electronic affidavits?")),
                                React.createElement("div", { className: "pt-2 pl-16 pr-4 pb-4 font-medium text-sm text-gray-650" }, "Toggle this on if you want to allow this customer to request affidavits by email.")))))),
                    React.createElement("div", { className: "flex justify-between items-center py-10" },
                        React.createElement(ColumnButton, { buttonText: "Cancel", size: "lg", onClick: onClose }),
                        React.createElement(ColumnButton, { primary: true, buttonText: "Save", size: "lg", onClick: () => {
                                void onSave();
                            }, disabled: disableSave })))))));
};
