var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFirebaseContext } from 'utils/firebase';
export const getUserSnap = ({ uid }) => {
    const ctx = getFirebaseContext();
    if (uid) {
        return ctx.usersRef().doc(uid).get();
    }
    return null;
};
export const getOrganizationSnap = ({ orgRef }) => {
    return orgRef.get();
};
export const getFiler = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const userSnap = yield getUserSnap({ uid: notice.userId });
    const user = userSnap === null || userSnap === void 0 ? void 0 : userSnap.data();
    if (!user) {
        console.error(`Unable to find user with ID: ${notice.userId}`);
        return '';
    }
    const userName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    if (notice.filedBy) {
        const organizationSnap = yield getOrganizationSnap({
            orgRef: notice.filedBy
        });
        const organization = organizationSnap.data();
        return organization ? `${userName} - ${organization.name}` : userName;
    }
    if (!user.organizationName) {
        return userName;
    }
    if (user.organizationName) {
        return `${userName} - ${user.organizationName}`;
    }
});
