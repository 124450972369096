import { ELAVON_TOKENIZER_URL } from '../../../../../../../constants';
export function elavonCardTokenizerInjector() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = ELAVON_TOKENIZER_URL;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
}
