var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import lobApi from 'lob';
import api from 'api';
import { useSelector } from 'react-redux';
import { Grid, IconButton, Box, FormControlLabel, Button } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { exists } from 'lib/types';
import { State as States, CourtHouse as CourtHousesList } from 'lib/enums';
import { getNoticeTypeData } from 'lib/publishers';
import { safeStringify } from 'lib/utils/stringify';
import { AddIcon, DeleteIcon } from 'icons';
import { LOB_CONFIG, ENV, PROD } from '../../constants';
import ScrollStep from './ScrollStep';
import SelectDropdown from './SelectDropdown';
import { CONFIRM_PROOF } from './helpers';
const styles = (theme) => createStyles({
    headingText: {
        color: theme.palette.grey.scrollFormHeading
    },
    checkIcon: {
        color: theme.palette.grey.scrollFormHeading
    },
    checkBox: {
        transition: 'color 0s'
    },
    content: {
        width: '100%'
    },
    iconInput: {
        width: '100%'
    },
    subHeading: {
        color: theme.palette.grey.scrollFormHeading,
        fontWeight: 600
    },
    deleteButton: {
        color: 'rgba(0, 0, 0, 0.4)',
        position: 'absolute'
    },
    radioRow: {
        height: 32
    }
});
const DEFAULT_MAILING = {
    address: {
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_state: '',
        address_zip: ''
    },
    copies: 1,
    description: '',
    name: '',
    isCourthouse: false
};
const DEFAULT_COURTHOUSE = Object.assign(Object.assign({}, DEFAULT_MAILING), { isCourthouse: true });
const DEFAULT_ERROR = Array(5).fill('');
const AffidavitRecipients = ({ id, activeStepId, placementActions, classes, isPublisher, next, previous, onDisabledStepClick, editing, newspaper, filer, draft }) => {
    const [checked, setChecked] = useState(false);
    const [mail, setMail] = useState([DEFAULT_MAILING]);
    const [error, setError] = useState(DEFAULT_ERROR);
    const [courthouseError, setCourtHouseError] = useState(DEFAULT_ERROR);
    const [courtHouse, setCourtHouse] = useState([DEFAULT_COURTHOUSE]);
    const [courthouseChecked, setCourthouseChecked] = useState(false);
    const [sendEmailChecked, setSendEmailChecked] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [defaultCourthouse, setDefaultCourtHouse] = useState();
    const [notarizeChecked, setNotarizeChecked] = useState();
    const [sendEmailCheckEdited, setSendEmailCheckEdited] = useState(false);
    const placementNoticeType = useSelector((state) => state.placement.noticeType);
    const placementMail = useSelector((state) => state.placement.mail);
    const placementCustomer = useSelector((state) => state.placement.customer);
    const placementRequireEmailAffidavit = useSelector((state) => state.placement.requireEmailAffidavit);
    const placementFilingState = useSelector((state) => state.placement.filingState);
    const MAX_MAIL_COPIES = 5;
    const MAX_MAIL_ADDRESSES = 5;
    const MAX_COURT_HOUSES = 5;
    const zipRegex = /^(([0-9]{5})|([0-9]{5}-[0-9]{4}))$/g;
    const affidavitNotarization = exists(newspaper)
        ? Boolean(newspaper.data().customNotarizedAffidavit)
        : false;
    // We only show courthosue selection in Kansas
    const showCourthouseSelection = placementFilingState === States.kansas.value;
    const mailAffidavitsOutsideColumn = !!(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().mailAffidavitsOutsideColumn);
    const physicalAffidavit = !!(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().physicalAffidavit);
    const hideEmailAffidavit = physicalAffidavit || !!(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().hideDigitalAffidavits);
    const loadFromSaved = () => {
        if (!exists(filer))
            return [];
        if (!editing)
            return [];
        const { savedInfo } = filer.data();
        const savedMailAddresses = savedInfo === null || savedInfo === void 0 ? void 0 : savedInfo.mails;
        if (savedMailAddresses === null || savedMailAddresses === void 0 ? void 0 : savedMailAddresses.length) {
            return savedMailAddresses.map(savedMailAddress => (Object.assign({ description: '' }, savedMailAddress)));
        }
        return [];
    };
    const loadPlacementMail = () => {
        if (!(placementMail === null || placementMail === void 0 ? void 0 : placementMail.length))
            return [];
        const nonDefaultAddresses = placementMail.filter(item => !item.isNoticeTypeDefault);
        if (nonDefaultAddresses.length) {
            return nonDefaultAddresses;
        }
        return [];
    };
    const loadNewspaperMail = () => {
        var _a;
        if (exists(newspaper)) {
            const { courthouseId, physicalAffidavit } = newspaper.data();
            let noticeType;
            try {
                noticeType = getNoticeTypeData(placementNoticeType, newspaper);
            }
            catch (err) {
                logAndCaptureException(err, 'Error on getNoticeTypeData', {
                    newspaperId: newspaper.id
                });
            }
            let newspaperAddresses;
            if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultMailingAddresses) {
                newspaperAddresses = noticeType.defaultMailingAddresses.map(mail => (Object.assign(Object.assign({}, mail), { address: Object.assign(Object.assign({}, mail.address), { address_state: typeof mail.address.address_state === 'string'
                            ? States.by_label(mail.address.address_state).value
                            : mail.address.address_state }), isNoticeTypeDefault: true })));
            }
            else {
                newspaperAddresses = [];
            }
            const defaultCourt = (_a = CourtHousesList.find(c => c.id === courthouseId)) !== null && _a !== void 0 ? _a : null;
            if (defaultCourt && !defaultCourthouse) {
                setDefaultCourtHouse(defaultCourt);
            }
            if (physicalAffidavit) {
                setChecked(true);
            }
            return newspaperAddresses;
        }
        return [];
    };
    const loadAffidavitsRecipients = () => {
        let mailAddresses = [];
        if (activeStepId === CONFIRM_PROOF || editing) {
            if (placementMail) {
                mailAddresses = placementMail;
            }
        }
        else {
            const savedAddresses = loadFromSaved();
            const placementAddresses = loadPlacementMail();
            const newspaperAddresses = loadNewspaperMail();
            if (newspaperAddresses.length) {
                mailAddresses = newspaperAddresses;
            }
            else if (placementAddresses.length) {
                mailAddresses = placementAddresses;
            }
            else {
                mailAddresses = savedAddresses;
            }
        }
        const courthouseAddresses = mailAddresses.filter(item => item.isCourthouse);
        if (courthouseAddresses.length) {
            setCourtHouse(courthouseAddresses);
            setCourthouseChecked(true);
        }
        mailAddresses = mailAddresses.filter(item => !item.isCourthouse);
        if (mailAddresses.length) {
            setMail(mailAddresses);
            setChecked(true);
        }
        else {
            setMail([DEFAULT_MAILING]);
        }
        if (notarizeChecked === null &&
            affidavitNotarization &&
            draft.data().noticeStatus &&
            !draft.data().customAffidavit) {
            setNotarizeChecked(true);
        }
    };
    useEffect(() => {
        if (checked) {
            loadAffidavitsRecipients();
        }
        else {
            setMail([DEFAULT_MAILING]);
        }
    }, [checked]);
    // TODO: Can/should this state be part of PlaceNoticeState
    useEffect(() => {
        // We rely on the customer from placement to avoid creating a duplicate customer
        // when the newspaper and filer are set
        if (!(placementCustomer === null || placementCustomer === void 0 ? void 0 : placementCustomer.id)) {
            setCustomer(null);
            return;
        }
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            const customerSnap = yield (placementCustomer === null || placementCustomer === void 0 ? void 0 : placementCustomer.get());
            if (exists(customerSnap)) {
                setCustomer(customerSnap);
            }
            else {
                setCustomer(null);
            }
        }))();
    }, [placementCustomer === null || placementCustomer === void 0 ? void 0 : placementCustomer.id]);
    useEffect(() => {
        if (editing && !sendEmailCheckEdited) {
            setSendEmailChecked(placementRequireEmailAffidavit);
            setSendEmailCheckEdited(true);
        }
    }, [placementRequireEmailAffidavit]);
    const assignDefaultCourtHouse = () => {
        if (!courthouseChecked || !defaultCourthouse)
            return;
        setCourtHouse(courtHouse.map(c => c.name === ''
            ? Object.assign(Object.assign({}, c), { courtHouse: defaultCourthouse.id, name: defaultCourthouse.name, address: {
                    address_line1: defaultCourthouse.address,
                    address_line2: '',
                    address_city: defaultCourthouse.city,
                    address_state: placementFilingState,
                    state: States.kansas.value,
                    address_zip: defaultCourthouse.address.slice(defaultCourthouse.address.length - 5)
                }, isCourthouse: true, copies: 1 }) : c));
    };
    const validateMail = () => {
        var _a;
        const newError = [...DEFAULT_ERROR];
        if (checked) {
            for (const [i, m] of mail.entries()) {
                if (!m.name)
                    newError[i] = 'Recipient name is missing';
                else if (m.name.length > 40)
                    newError[i] = 'Recipient name must be less than 40 characters';
                else if (!((_a = m.address) === null || _a === void 0 ? void 0 : _a.address_line1))
                    newError[i] = 'Address line 1 is missing';
                else if (!m.copies)
                    newError[i] = 'Number of copies is missing';
                else if (m.copies > MAX_MAIL_COPIES || m.copies < 1)
                    newError[i] = 'Mailing must have between one and five copies';
                else if (!m.address.address_city)
                    newError[i] = 'City is missing';
                else if (!m.address.address_state)
                    newError[i] = 'State is missing';
                else if (!m.address.address_zip)
                    newError[i] = 'Zip code is missing';
                else if (!m.address.address_zip.match(zipRegex))
                    newError[i] = 'Zip code is invalid';
                else
                    newError[i] = '';
            }
        }
        setError([...newError]);
    };
    const validateCourtHouse = () => {
        const newError = [...DEFAULT_ERROR];
        if (courthouseChecked) {
            for (const [i, m] of courtHouse.entries()) {
                if (!m.courtHouse)
                    newError[i] = 'Courthouse is missing';
                else if (!m.copies)
                    newError[i] = 'Number of copies is missing';
                else if (m.copies > MAX_MAIL_COPIES || m.copies < 1)
                    newError[i] = 'Mailing must have copies between one and five copies';
            }
        }
        setCourtHouseError([...newError]);
    };
    useEffect(() => {
        if (!courthouseChecked) {
            setCourtHouse([DEFAULT_COURTHOUSE]);
        }
        else {
            validateCourtHouse();
            assignDefaultCourtHouse();
        }
    }, [courthouseChecked]);
    // Unfortunately, it's important that this useEffect happens after
    // the one above it (setting courthosue defaults). So don't move this!
    useEffect(() => {
        setChecked(false);
        loadAffidavitsRecipients();
    }, [filer === null || filer === void 0 ? void 0 : filer.id, newspaper === null || newspaper === void 0 ? void 0 : newspaper.id, placementNoticeType]);
    const verifyAddresses = (m) => __awaiter(void 0, void 0, void 0, function* () {
        const deliverability = yield new Promise((resolve, reject) => 
        // See: https://docs.lob.com/#tag/US-Verifications
        lobApi(LOB_CONFIG[ENV].key).usVerifications.verify({
            primary_line: ENV === PROD
                ? m.address.address_line1
                : LOB_CONFIG[ENV].address.primary_line,
            secondary_line: m.address.address_line2,
            city: m.address.address_city,
            state: m.address.address_state,
            zip_code: m.address.address_zip
        }, (err, res) => {
            var _a, _b;
            const originalId = (_a = draft === null || draft === void 0 ? void 0 : draft.data().original) === null || _a === void 0 ? void 0 : _a.id;
            if (err) {
                logAndCaptureException(err, 'Failed to verify address', {
                    noticeId: originalId
                });
                reject();
                return;
            }
            const deliverability = res === null || res === void 0 ? void 0 : res.deliverability;
            if (deliverability === 'undeliverable' && originalId) {
                void api.post('notifications/slack', {
                    message: `Wrong address was submitted for notice: ${originalId}. 
                address line 1: ${m.address.address_line1}, 
                address line 2: ${m.address.address_line2}, 
                city: ${m.address.address_city}, 
                state: ${(_b = States.by_value(m.address.address_state)) === null || _b === void 0 ? void 0 : _b.label}, 
                zip code: ${m.address.address_zip}`
                });
            }
            resolve(deliverability);
        }));
        return deliverability === 'deliverable';
    });
    const onExit = () => __awaiter(void 0, void 0, void 0, function* () {
        const doesNotRequireEmailAffidavit = !(isPublisher ||
            !hideEmailAffidavit ||
            !!(customer === null || customer === void 0 ? void 0 : customer.data().allowAffidavitEmail));
        const data = {
            mailAffidavitsOutsideColumn,
            requireEmailAffidavit: doesNotRequireEmailAffidavit
                ? false
                : sendEmailChecked
        };
        if (checked && error.some(e => !!e)) {
            setShowErrors(true);
            return { success: false, data };
        }
        if (courthouseChecked && courthouseError.some(e => !!e)) {
            setShowErrors(true);
            return { success: false, data };
        }
        if (affidavitNotarization && exists(newspaper) && notarizeChecked) {
            data.customAffidavit = newspaper.data().customNotarizedAffidavit;
        }
        // TODO: We should probably use a non-stringification method of equality
        const isMail = safeStringify(mail) !== safeStringify([DEFAULT_MAILING]);
        const isCourtHouse = safeStringify(courtHouse) !== safeStringify([DEFAULT_COURTHOUSE]);
        const newMail = [];
        if (isMail) {
            newMail.push(...mail);
        }
        if (isCourtHouse) {
            newMail.push(...courtHouse);
        }
        data.mail = newMail.length > 0 ? newMail : null;
        if (checked) {
            // TODO: What is the point of this verification if it does not block anything?
            // Also note that we sabotage all verifications when not in production
            const addressVerifications = yield Promise.all(mail.map(verifyAddresses));
            addressVerifications.forEach((v, i) => {
                if (!v) {
                    console.warn(`Address is not deliverable`, mail[i]);
                }
            });
        }
        return { success: true, data };
    });
    useEffect(() => {
        validateMail();
    }, [mail]);
    useEffect(() => {
        validateCourtHouse();
    }, [courtHouse]);
    useEffect(() => {
        if (!checked) {
            setError(DEFAULT_ERROR);
        }
    }, [activeStepId]);
    const isStepComplete = () => {
        if (checked && mail.length === 1 && mail[0] === DEFAULT_MAILING)
            return false;
        if (activeStepId !== id)
            return false;
        if (showErrors) {
            if (checked) {
                return error.every(e => !e);
            }
            if (courthouseChecked) {
                return courthouseError.every(e => !e);
            }
        }
        return true;
    };
    const addMailAddress = () => {
        if (mail && mail.length >= MAX_MAIL_ADDRESSES)
            return;
        setMail(mail.concat(DEFAULT_MAILING));
    };
    const addMoreCourthouse = () => {
        if (courtHouse && courtHouse.length >= MAX_COURT_HOUSES)
            return;
        setCourtHouse(courtHouse.concat(DEFAULT_COURTHOUSE));
    };
    const renderCourthouses = () => (React.createElement(Box, { ml: 5, mt: 1 },
        courtHouse.map((a, i) => {
            var _a, _b, _c, _d;
            return (React.createElement(Box, { key: `${i}-courthouse-container`, mb: 1 },
                React.createElement("div", { key: `${i}-courthouse-item`, className: "grid grid-cols-12 gap-2" },
                    React.createElement("div", { className: "col-span-8 mt-1" },
                        React.createElement(SelectDropdown, { id: `courtHouses${i}`, className: "courthouse-select", placeholder: "Select Courthouse", onChange: (selected) => {
                                if (selected) {
                                    setCourtHouse(courtHouse.map((a2, j) => i === j
                                        ? Object.assign(Object.assign({}, a2), { courtHouse: selected.id, name: selected.data.name, address: {
                                                address_line1: selected.data.address,
                                                address_line2: '',
                                                address_state: placementFilingState,
                                                address_zip: selected.data.zip,
                                                address_city: selected.data.city
                                            }, isCourthouse: true }) : Object.assign(Object.assign({}, a2), { isCourthouse: true })));
                                }
                            }, selected: i === 0
                                ? ((_a = a.address) === null || _a === void 0 ? void 0 : _a.address_state)
                                    ? {
                                        id: a.courtHouse,
                                        data: a,
                                        label: a.name
                                    }
                                    : defaultCourthouse
                                        ? {
                                            id: defaultCourthouse.id,
                                            data: defaultCourthouse,
                                            label: defaultCourthouse.name
                                        }
                                        : null
                                : ((_b = a.address) === null || _b === void 0 ? void 0 : _b.address_state)
                                    ? {
                                        id: a.courtHouse,
                                        data: a,
                                        label: a.name
                                    }
                                    : null, value: i === 0
                                ? ((_c = a.address) === null || _c === void 0 ? void 0 : _c.address_state)
                                    ? {
                                        id: a.courtHouse,
                                        data: a,
                                        label: a.name
                                    }
                                    : defaultCourthouse
                                        ? {
                                            id: defaultCourthouse.id,
                                            data: defaultCourthouse,
                                            label: defaultCourthouse.name
                                        }
                                        : null
                                : ((_d = a.address) === null || _d === void 0 ? void 0 : _d.address_state)
                                    ? {
                                        id: a.courtHouse,
                                        data: a,
                                        label: a.name
                                    }
                                    : null, options: CourtHousesList.map((court) => ({
                                id: court.id,
                                data: court,
                                label: court.name
                            })), placeholderText: '#6B7280' })),
                    React.createElement("div", { className: "col-span-3" },
                        React.createElement("input", { id: `copies${i}`, type: "number", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", placeholder: "Copies", value: i === 0 ? (defaultCourthouse ? 1 : a.copies) : a.copies, onChange: (e) => {
                                setCourtHouse(courtHouse.map((a2, j) => i === j
                                    ? Object.assign(Object.assign({}, a2), { copies: e.target.value
                                            ? Math.max(0, e.target.value)
                                            : e.target.value }) : a2));
                            } })),
                    i > 0 && (React.createElement("div", { className: "col-span-1" },
                        React.createElement(IconButton, { id: `delete${i}`, "aria-label": "Delete courthouse", className: classes.deleteButton, onClick: () => {
                                const newCourthouse = courtHouse
                                    .slice(0, i)
                                    .concat(courtHouse.slice(i + 1, courtHouse.length));
                                setCourtHouse(newCourthouse);
                            } },
                            React.createElement(DeleteIcon, { className: "w-5 h-5 text-grey-700" }))))),
                showErrors && courthouseError[i] && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, null,
                        React.createElement("div", { className: "justify-start items-start text-red-600 text-sm font-normal pt-2" }, courthouseError[i]))))));
        }),
        React.createElement(Button, { id: "addMoreCourthouses", onClick: addMoreCourthouse },
            React.createElement("div", null,
                React.createElement(AddIcon, { className: "w-5 h-5 text-grey-700" })),
            React.createElement(Box, { ml: 1 },
                React.createElement("div", { className: "font-medium text-sm text-gray-700 ml-1 normal-case" }, "Add More Courthouses")))));
    const renderMails = () => (React.createElement(Box, { ml: 5, mt: 1 },
        mail.map((a, i) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return (React.createElement(Box, { key: `${i}-mail-container`, mb: 1 },
                React.createElement("div", { key: `${i}-mail-item`, className: "grid grid-cols-12 gap-2" },
                    React.createElement("div", { className: "col-span-8" },
                        React.createElement("input", { id: `name${i}`, className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", placeholder: "Name", value: a.name, onChange: (e) => setMail(mail.map((a2, j) => i === j
                                ? Object.assign(Object.assign({}, a2), { name: e.target.value, isNoticeTypeDefault: false }) : a2)), autoComplete: "off" })),
                    React.createElement("div", { className: "col-span-3" },
                        React.createElement("input", { id: `copies${i}`, type: "number", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", placeholder: "Copies", value: a.copies, onChange: (e) => {
                                setMail(mail.map((a2, j) => i === j
                                    ? Object.assign(Object.assign({}, a2), { copies: e.target.value
                                            ? Math.max(0, e.target.value)
                                            : e.target.value, isNoticeTypeDefault: false }) : a2));
                            } })),
                    i > 0 && (React.createElement("div", { className: "col-span-1" },
                        React.createElement(IconButton, { id: `delete${i}`, "aria-label": "Delete address", className: classes.deleteButton, onClick: () => {
                                const newMail = mail
                                    .slice(0, i)
                                    .concat(mail.slice(i + 1, mail.length));
                                setMail(newMail);
                            } },
                            React.createElement(DeleteIcon, { className: "w-5 h-5 text-grey-700" }))))),
                React.createElement("div", { key: `${i}-container-1`, className: "grid grid-cols-12 gap-2" },
                    React.createElement("div", { className: "col-span-8" },
                        React.createElement("input", { id: `addressLine1${i}`, className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", placeholder: "Address Line 1", value: (_a = a.address) === null || _a === void 0 ? void 0 : _a.address_line1, onChange: (e) => setMail(mail.map((a2, j) => i === j
                                ? Object.assign(Object.assign({}, a2), { address: Object.assign(Object.assign({}, a2.address), { address_line1: e.target.value }), isNoticeTypeDefault: false }) : a2)), autoComplete: "off" })),
                    React.createElement("div", { className: "col-span-3" },
                        React.createElement("input", { id: `addressLine2${i}`, value: (_b = a.address) === null || _b === void 0 ? void 0 : _b.address_line2, placeholder: "Address Line 2", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                setMail(mail.map((a2, j) => i === j
                                    ? Object.assign(Object.assign({}, a2), { address: Object.assign(Object.assign({}, a2.address), { address_line2: e.target.value }), isNoticeTypeDefault: false }) : a2));
                            }, autoComplete: "off" }))),
                React.createElement("div", { key: `${i}-container-2`, className: "grid grid-cols-12 gap-2" },
                    React.createElement("div", { className: "col-span-4" },
                        React.createElement("input", { id: `city${i}`, value: (_c = a.address) === null || _c === void 0 ? void 0 : _c.address_city, placeholder: "City", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                setMail(mail.map((a2, j) => i === j
                                    ? Object.assign(Object.assign({}, a2), { address: Object.assign(Object.assign({}, a2.address), { address_city: e.target.value }), isNoticeTypeDefault: false }) : a2));
                            }, autoComplete: "off" })),
                    React.createElement("div", { className: "col-span-4 mt-1" },
                        React.createElement(SelectDropdown, { id: `state${i}`, className: "state-select", placeholder: "State", onChange: (selected) => {
                                if (selected) {
                                    setMail(mail.map((a2, j) => i === j
                                        ? Object.assign(Object.assign({}, a2), { address: Object.assign(Object.assign({}, a2.address), { address_state: selected.id }), isNoticeTypeDefault: false }) : a2));
                                }
                            }, value: ((_d = a.address) === null || _d === void 0 ? void 0 : _d.address_state)
                                ? {
                                    id: `${a.address.address_state}`,
                                    label: (_e = States.by_value(a.address.address_state)) === null || _e === void 0 ? void 0 : _e.label
                                }
                                : null, selected: ((_f = a.address) === null || _f === void 0 ? void 0 : _f.address_state)
                                ? {
                                    id: `${a.address.address_state}`,
                                    label: (_g = States.by_value(a.address.address_state)) === null || _g === void 0 ? void 0 : _g.label
                                }
                                : null, options: States.items().map((state) => ({
                                id: state.value,
                                label: state.label
                            })), placeholderText: '#6B7280' })),
                    React.createElement("div", { className: "col-span-3" },
                        React.createElement("input", { id: `zipcode${i}`, value: (_h = a.address) === null || _h === void 0 ? void 0 : _h.address_zip, placeholder: "Zipcode", className: "mt-1 form-input block w-full \u2248border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                const regex = /^(([0-9]{0,5})|([0-9]{5}-[0-9]{0,4}))$/g;
                                if (e.target.value.match(regex))
                                    setMail(mail.map((a2, j) => i === j
                                        ? Object.assign(Object.assign({}, a2), { address: Object.assign(Object.assign({}, a2.address), { address_zip: e.target.value }) }) : a2));
                            }, autoComplete: "off" }))),
                showErrors && error[i] && (React.createElement(Grid, null,
                    React.createElement("div", { className: "justify-start items-start text-red-600 text-sm font-normal pt-2" }, error[i])))));
        }),
        React.createElement(Button, { id: "addMoreRecipients", onClick: addMailAddress },
            React.createElement("div", null,
                React.createElement(AddIcon, { className: "w-5 h-5 text-grey-700" })),
            React.createElement(Box, { ml: 1 },
                React.createElement("div", { className: "font-medium text-sm text-gray-700 ml-1 normal-case" }, "Add More Recipients")))));
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, complete: isStepComplete(), next: () => __awaiter(void 0, void 0, void 0, function* () {
            const { success, data } = yield onExit();
            placementActions.confirmAffidavitRecipients(data);
            if (success) {
                next();
            }
        }), previous: () => __awaiter(void 0, void 0, void 0, function* () {
            // TODO: Do we really want to call 'onExit' when someone goes to the previous step?
            const { data } = yield onExit();
            placementActions.confirmAffidavitRecipients(data);
            previous();
        }), onDisabledStepClick: onDisabledStepClick, title: editing
            ? 'Edit Affidavits'
            : affidavitNotarization
                ? 'Proof of Publication'
                : 'Request Mail Affidavits', caption: `${affidavitNotarization ? 'Proof of publication' : 'Affidavits'} will be stored digitally and available through ${isPublisher ? "the customer's" : 'your'} Column account.` },
        React.createElement(Grid, { className: classes.content },
            React.createElement(Grid, { container: true, direction: "column" },
                (isPublisher ||
                    !hideEmailAffidavit ||
                    (customer && customer.data().allowAffidavitEmail)) && (React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { id: "sendEmailCheckBox", className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3", checked: sendEmailChecked, type: "checkbox", onChange: () => {
                            setSendEmailChecked(hideEmailAffidavit ? !sendEmailChecked : true);
                        }, value: "checkedA" }), label: `Send ${affidavitNotarization ? 'proof of publication' : 'affidavits'} to ${isPublisher ? "the customer's" : 'my'} email address` })),
                showCourthouseSelection && (React.createElement(React.Fragment, null,
                    React.createElement(Box, null,
                        React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { id: "sendMailCourthouse", className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3", checked: courthouseChecked, type: "checkbox", onChange: () => setCourthouseChecked(!courthouseChecked), value: "checkedA" }), label: `Send ${affidavitNotarization
                                ? 'proof of publication'
                                : 'affidavits'} to courthouse by mail` })),
                    React.createElement(React.Fragment, null, courthouseChecked && renderCourthouses()))),
                React.createElement(Box, null,
                    React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { checked: checked, onChange: physicalAffidavit ? () => { } : () => setChecked(!checked), value: "checkedA", id: "sendMailCheckBox", type: "checkbox", className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3" }), label: `Send ${!physicalAffidavit ? 'additional' : ''} ${affidavitNotarization ? 'proof of publication' : 'affidavit'} by mail` })),
                checked && mail && renderMails(),
                affidavitNotarization && (React.createElement(Box, { mb: 1 },
                    React.createElement(FormControlLabel, { className: classes.radioRow, control: React.createElement("input", { id: "notarizeProofCheckBox", className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3", checked: notarizeChecked, type: "checkbox", onChange: () => setNotarizeChecked(!notarizeChecked), value: "checkedA" }), label: `Notarize proof of publication` })))))));
};
export default withStyles(styles)(AffidavitRecipients);
