import React from 'react';
import { isColumnUser } from 'lib/helpers';
import OldRateSettings from './old';
import RatesSettings from './RatesSettings';
/**
 * Redirect column users to the new rate settings pages
 */
export default function RateSettings({ activeOrganization, user }) {
    if (isColumnUser(user) && window.location.search.includes('new')) {
        return React.createElement(RatesSettings, { activeOrganization: activeOrganization });
    }
    return React.createElement(OldRateSettings, { activeOrganization: activeOrganization });
}
