import { exists } from '../types';
import { OrganizationType } from '../enums';
export const PUBLISHER_ORGANIZATIONS = [
    OrganizationType.newspaper.value,
    OrganizationType.holding_company.value,
    OrganizationType.press_association.value
];
export const ADVERTISER_ORGANIZATIONS = [
    OrganizationType.government.value,
    OrganizationType.law_firm.value,
    OrganizationType.other_organization.value
];
/**
 * Check if an organization is a publisher. If not, it's an advertiser.
 */
export const isPublisherOrganization = (org) => {
    if (!exists(org)) {
        return false;
    }
    return PUBLISHER_ORGANIZATIONS.includes(org.data().organizationType);
};
