import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
const styles = (theme) => createStyles({
    container: {
        padding: theme.spacing(2)
    }
});
const SettingsWrapper = ({ classes, children }) => (React.createElement(Grid, { item: true, xs: 10 },
    React.createElement(Paper, { className: classes.container }, children)));
export default withStyles(styles)(SettingsWrapper);
