var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import { safeStringify } from 'lib/utils/stringify';
/**
 * Modal to confirm before deleting a fee
 * */
export default function RemoveFeeModal({ activeOrganization, feeToRemove, onClose }) {
    function onSubmit() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const newFees = (_a = activeOrganization
                .data()
                .additionalFees) === null || _a === void 0 ? void 0 : _a.filter(fee => safeStringify(fee) !== safeStringify(feeToRemove));
            yield activeOrganization.ref.update({ additionalFees: newFees || [] });
            onClose();
        });
    }
    return (React.createElement(CancelOrSubmitModal, { tertiaryButtonText: "Cancel", primaryButtonText: "Remove", onSubmit: onSubmit, setOpen: onClose, header: `Remove Fee: ${feeToRemove.description}`, destructive: true },
        React.createElement("div", { className: "py-3" },
            React.createElement("p", null, "Once removed, fees can no longer be recovered in the app. However, the Column team will still be able to recover your settings."))));
}
