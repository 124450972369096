import React, { useState, useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Paper, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { NoticeType } from 'lib/enums';
import RatesTable from './RatesTable';
const styles = (theme) => createStyles({
    tabRow: {
        marginBottom: theme.spacing(1)
    },
    loadingContainer: {
        height: '60vh'
    },
    loadingGrid: {
        height: '100%'
    }
});
const views = {
    liners: 'Liner Rates',
    displays: 'Display Rates'
};
const tabIndexViewMap = {
    0: 'liners',
    1: 'displays'
};
const TabPanel = (props) => {
    const { children, value, tabName } = props;
    return React.createElement("div", { hidden: value !== tabName },
        " ",
        children);
};
const RatesTables = ({ activeOrganization, classes }) => {
    const [view, setView] = useState(views.liners);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [requireUpfrontPayment, setRequireUpfrontPayment] = useState();
    useEffect(() => {
        if (activeOrganization) {
            setRequireUpfrontPayment(activeOrganization.data().requireUpfrontPayment);
        }
    }, [activeOrganization]);
    const handleChange = (e, index) => {
        setSelectedTabIndex(index);
        setView(views[tabIndexViewMap[index]]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, { square: true, className: "flex justify-between items-center" },
            React.createElement(Tabs, { value: selectedTabIndex, onChange: handleChange, "aria-label": "simple tabs example", indicatorColor: "primary", textColor: "primary", className: classes.tabRow },
                React.createElement(Tab, { label: `${views.liners}` }),
                React.createElement(Tab, { label: `${views.displays}` })),
            React.createElement("div", { className: "flex items-center pr-2" },
                React.createElement(Tooltip, { classes: { tooltip: classes.toolTip }, title: `When enabled, upfront payment will be required such that invoices will have a due date one day prior the deadline` },
                    React.createElement(Help, { color: "disabled", style: { width: '1rem' } })),
                React.createElement("div", { className: "lg:text-md lg:opacity-50 lg:text-gray-800 lg:font-medium lg:justify-center mx-2" }, "Default to requiring upfront payment"),
                React.createElement("button", { type: "button", id: "require-upfront-payment", className: `flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${requireUpfrontPayment
                        ? 'bg-blue-650 bg-opacity-75'
                        : 'bg-gray-200'}`, onClick: () => {
                        setRequireUpfrontPayment(!requireUpfrontPayment);
                        void activeOrganization.ref.update({
                            requireUpfrontPayment: !requireUpfrontPayment
                        });
                    } },
                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-400 ${requireUpfrontPayment && 'bg-blue-650 bg-opacity-75'}` }),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${requireUpfrontPayment && 'translate-x-5'}` })))),
        React.createElement(TabPanel, { value: view, tabName: views.liners },
            React.createElement(RatesTable, { activeOrganization: activeOrganization, noticeType: null })),
        React.createElement(TabPanel, { value: view, tabName: views.displays },
            React.createElement(RatesTable, { activeOrganization: activeOrganization, noticeType: NoticeType.display_ad.value }))));
};
export default withStyles(styles)(RatesTables);
