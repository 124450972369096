var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Firestore } from 'EnoticeFirebase';
import { exists } from 'lib/types';
import { isPublisher } from 'lib/utils/users';
import { Collections } from 'lib/constants';
import { removeUndefinedFields } from 'lib/helpers';
import { getPlacementFlowFieldsFromNotice } from './dataCleaning';
import { getFirebaseContext } from './firebase';
export const duplicateNotice = (originalNoticeId, user) => __awaiter(void 0, void 0, void 0, function* () {
    const ctx = getFirebaseContext();
    const noticeSnap = yield ctx.userNoticesRef().doc(originalNoticeId).get();
    if (!exists(noticeSnap)) {
        throw Error(`Cannot duplicate notice ${originalNoticeId} because it does not exist.`);
    }
    const newNoticeRef = ctx.userNoticesRef().doc();
    const newDraftRef = ctx.userDraftsRef().doc();
    const { duplicatedNotice, duplicatedDraftNotice } = yield duplicateNoticeInner(user, noticeSnap, newNoticeRef, newDraftRef);
    yield newDraftRef.set(duplicatedDraftNotice);
    yield newNoticeRef.set(duplicatedNotice);
    yield duplicateNoticeMail(noticeSnap.ref, newDraftRef);
    return {
        newNoticeRef,
        newDraftRef
    };
});
/**
 * THIS FUNCTION IS ONLY EXPORTED FOR TESTING.
 */
export const duplicateNoticeInner = (user, noticeSnap, newNoticeRef, newDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeData = noticeSnap.data();
    const userIsPublisher = isPublisher(user);
    // For advertisers we make the following changes:
    //  1) Clear publication dates that have already passed
    //  2) Set the 'filer' to the current user
    const publicationDates = userIsPublisher
        ? noticeData.publicationDates
        : noticeData.publicationDates.filter(d => d.toMillis() > new Date().getTime());
    const filer = userIsPublisher ? noticeData.filer : user.ref;
    // We select only the fields from the existing notice that would have been
    // set client-side during the placement flow. This makes the duplication
    // operation much more like a whole-form copy-paste than actually duplicating
    // the whole database object and leaves behind invoice, affidavits, etc.
    const commonFields = Object.assign(Object.assign({}, getPlacementFlowFieldsFromNotice(noticeData)), { referenceId: `Copy ${noticeSnap.data().referenceId}`, isArchived: false, filer, userId: filer.id, user: user.ref, createdBy: user.ref, editedAt: Firestore.FieldValue.serverTimestamp(), createTime: Firestore.FieldValue.serverTimestamp() });
    // Only add publicationDates if at least one exists
    if (publicationDates && publicationDates.length > 0) {
        commonFields.publicationDates = publicationDates;
    }
    else {
        delete commonFields.publicationDates;
    }
    const duplicatedDraftNotice = Object.assign(Object.assign({}, commonFields), { owner: user.ref, original: newNoticeRef });
    const duplicatedNotice = Object.assign(Object.assign({}, commonFields), { drafts: [newDraftRef] });
    return {
        duplicatedNotice,
        duplicatedDraftNotice
    };
});
/**
 * THIS FUNCTION IS ONLY EXPORTED FOR TESTING.
 */
export const duplicateNoticeMail = (originalNoticeRef, newDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeMailCollectionRef = originalNoticeRef.collection(Collections.mail);
    const newDraftMailCollectionRef = newDraftRef.collection(Collections.mail);
    const noticeMailQuerySnap = yield noticeMailCollectionRef.get();
    for (const doc of noticeMailQuerySnap.docs) {
        // We want to copy all of the mail fields besides the status and delivery date
        const mailData = removeUndefinedFields(Object.assign(Object.assign({}, doc.data()), { expected_delivery_date: undefined, mailStatus: undefined }));
        // eslint-disable-next-line no-await-in-loop
        yield newDraftMailCollectionRef.add(mailData);
    }
});
