var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import { getSubdomain } from 'utils/urls';
import { State } from 'lib/enums';
import { setupBeacon } from 'utils/beacon';
import { setErrorReporter } from 'lib/utils/errors';
import { setLaunchDarklyContext } from 'lib/utils/flags';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { getBooleanFlag } from 'utils/flags';
import { ENV, PROD, DEMO, DEV, LOGROCKET_KEY, SENTRY_DSN, PENDO_KEY, SHOULD_RUN_PENDO, IS_LOCALHOST, RECORD, REACT_APP_SENTRY_VERSION, TEST } from '../constants';
export default function* root() {
    if (ENV === PROD || ENV === DEMO) {
        Sentry.init({
            release: REACT_APP_SENTRY_VERSION,
            dsn: SENTRY_DSN,
            environment: ENV,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'Non-Error promise rejection captured',
                'JSONP request to https://admin.typeform.com/app/'
            ]
        });
    }
    // Set a global error reporter for use by code in 'lib'
    setErrorReporter({
        logAndCaptureError: logAndCaptureException,
        logAndCaptureWarning: logAndCaptureMessage
    });
    // Set a global launch darkly context for use by code in 'lib'
    setLaunchDarklyContext({
        getBooleanFeatureFlag: (flag, options) => __awaiter(this, void 0, void 0, function* () {
            return getBooleanFlag(flag, !!(options === null || options === void 0 ? void 0 : options.defaultValue));
        })
    });
    const sanitizeLogRocketRequests = (request) => {
        if (request.url.toLowerCase().indexOf('register') !== -1) {
            request.body = null;
        }
        return request;
    };
    if (ENV === PROD ||
        ENV === DEMO ||
        (ENV === TEST && !IS_LOCALHOST) ||
        (ENV === DEV && !IS_LOCALHOST && RECORD)) {
        LogRocket.init(LOGROCKET_KEY, {
            network: {
                requestSanitizer: sanitizeLogRocketRequests
            }
        });
        try {
            LogRocket.getSessionURL(sessionURL => {
                var _a;
                Sentry.configureScope(scope => {
                    scope.setExtra('sessionURL', sessionURL);
                });
                (_a = window.pendo) === null || _a === void 0 ? void 0 : _a.track('Session Replay Created', {
                    sessionURL
                });
            });
        }
        catch (e) {
            console.error('Setup: Unable to link LogRocket session to Sentry', e);
        }
    }
    if (SHOULD_RUN_PENDO) {
        try {
            (function (apiKey) {
                (function (p, e, n, d, o) {
                    let v;
                    let w;
                    let x;
                    let y;
                    let z;
                    // eslint-disable-next-line
                    o = p[d] = p[d] || {};
                    // eslint-disable-next-line
                    o._q = o._q || [];
                    // eslint-disable-next-line
                    v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
                    for (w = 0, x = v.length; w < x; ++w)
                        (function (m) {
                            // eslint-disable-next-line
                            o[m] =
                                o[m] ||
                                    function () {
                                        o._q[m === v[0] ? 'unshift' : 'push'](
                                        // eslint-disable-next-line
                                        [m].concat([].slice.call(arguments, 0)));
                                    };
                        })(v[w]);
                    // eslint-disable-next-line
                    y = e.createElement(n);
                    // eslint-disable-next-line
                    y.async = !0;
                    y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
                    // eslint-disable-next-line
                    z = e.getElementsByTagName(n)[0];
                    z.parentNode.insertBefore(y, z);
                })(window, document, 'script', 'pendo', {});
            })(PENDO_KEY);
        }
        catch (err) {
            console.error(`Failed to load pendo: ${err}`);
        }
    }
    const shouldDisplayChat = ENV === PROD || ENV === DEMO;
    if (shouldDisplayChat) {
        if (window.location.href.indexOf('/search') === -1 &&
            !State.by_key(getSubdomain())) {
            (function () {
                setupBeacon();
            })();
        }
    }
    return true;
}
