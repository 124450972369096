/* eslint-disable no-unneeded-ternary */
import React, { useRef } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Listbox, Transition } from '@headlessui/react';
const SelectDropdown = ({ id, className, placeholder, options, selected, onChange, value, borderColor, borderRadius, placeholderText, required, noShadow, notSearchable, isDisabled, maxHeight, disableAlphabetization, dropdownHeight, loadOptions, isLoading }) => {
    const selectRef = useRef();
    const asyncSelectRef = useRef();
    const customStyles = {
        control: (base) => (Object.assign(Object.assign({}, base), { borderRadius: borderRadius ? borderRadius : 6, borderColor: borderColor ? borderColor : '#cbd5e0', '&:hover': {
                borderColor: borderColor ? borderColor : '#cbd5e0'
            }, boxShadow: !noShadow && '0 1px 0 rgba(0, 0, 0, 0.06)', height: dropdownHeight === 'large' ? '50px' : base.height })),
        option: (styles, { isFocused }) => {
            return Object.assign(Object.assign({}, styles), { fontWeight: 'normal', backgroundColor: isFocused ? '#4299e1' : 'transparent', ':active': {
                    backgroundColor: '#4299e1',
                    fontWeight: 'normal'
                } });
        },
        menu: (base) => (Object.assign(Object.assign({}, base), { zIndex: 100, wordWrap: 'break-word' })),
        placeholder: (styles) => (Object.assign(Object.assign({}, styles), { fontSize: 14, marginLeft: 4, fontWeight: 'normal', color: placeholderText && placeholderText })),
        indicatorsContainer: (styles) => (Object.assign(Object.assign({}, styles), { marginLeft: 8 // Allow for non-overlapping ellipses after truncation,
         }))
    };
    const alphabetisation = (options) => {
        let option = [];
        option =
            options &&
                options.sort((a, b) => { var _a, _b; return ((_a = a.label) === null || _a === void 0 ? void 0 : _a.replace('The ', '')) > ((_b = b.label) === null || _b === void 0 ? void 0 : _b.replace('The ', '')) ? 1 : -1; });
        return option;
    };
    const DropdownIndicator = (props) => {
        return (components.DropdownIndicator && (React.createElement(components.DropdownIndicator, Object.assign({}, props),
            React.createElement("span", { className: "absolute inset-y-0 right-0 flex items-center pr-1 md:pr-2 pointer-events-none" },
                React.createElement("svg", { className: "h3 h-5 w-5 text-gray-400", viewBox: "0 0 20 20", fill: "none", stroke: "currentColor" },
                    React.createElement("path", { d: "M7 7l3-3 3 3m0 6l-3 3-3-3", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }))))));
    };
    const Option = (props) => {
        return (components.Option && (React.createElement(components.Option, Object.assign({}, props),
            React.createElement("div", { className: `${props.isFocused ? 'text-white' : 'text-gray-900'} cursor-pointer sm:text-sm sm:leading-5 font-normal show-scrollbar`, "data-testid": `${props.children}` }, props.children))));
    };
    const SingleValue = (props) => {
        return (components.SingleValue && (React.createElement(components.SingleValue, Object.assign({}, props),
            React.createElement("span", { className: "text-gray-900 font-medium leading-6 sm:text-sm sm:leading-5" }, props.data.label))));
    };
    const getValue = () => {
        if (value !== undefined)
            return value;
        return value || '';
    };
    return (React.createElement("div", { id: id || '', className: `${className || ''} w-full flex items-center justify-center text-sm md:text-base`, "aria-label": "Select", "aria-labelledby": "drop-down menu" },
        notSearchable ? (React.createElement("div", { className: "w-full mx-auto" },
            React.createElement(Listbox, { as: "div", className: "space-y-1", value: selected, onChange: selectedValue => {
                    onChange(selectedValue);
                } }, ({ open }) => (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "relative" },
                    React.createElement("span", { className: "inline-block w-full rounded-md shadow-sm" },
                        React.createElement(Listbox.Button, { className: "cursor-default relative w-full h-auto rounded-md border border-gray-400 bg-white pl-3 pr-10 py-2 text-left transition ease-in-out duration-150 sm:text-sm sm:leading-5" },
                            !selected || (selected.map && !selected.length) ? (React.createElement("span", { className: "block truncate text-gray-700" }, placeholder)) : (React.createElement("span", { className: "block truncate" }, selected.label)),
                            React.createElement("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" },
                                React.createElement("svg", { className: "h-5 w-5 text-gray-400", viewBox: "0 0 20 20", fill: "none", stroke: "currentColor" },
                                    React.createElement("path", { d: "M7 7l3-3 3 3m0 6l-3 3-3-3", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }))))),
                    React.createElement(Transition, { show: open, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", className: `z-10 absolute mt-1 w-full ${options.length > 3 ? `h-56 overflow-y-scroll` : ''} rounded-md bg-white shadow-lg` },
                        React.createElement(Listbox.Options, { static: true, className: "h-auto rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 dropdown-options show-scrollbar" }, options &&
                            options
                                .sort((a, b) => {
                                var _a, _b;
                                return ((_a = a.label) === null || _a === void 0 ? void 0 : _a.replace('The ', '')) >
                                    ((_b = b.label) === null || _b === void 0 ? void 0 : _b.replace('The ', ''))
                                    ? 1
                                    : -1;
                            })
                                .map((option) => (React.createElement(Listbox.Option, { key: option.id, disabled: option.isDisabled || false, value: option }, ({ active, selected }) => {
                                return (React.createElement("div", { className: `${active
                                        ? 'text-white bg-blue-900'
                                        : 'text-gray-900'} cursor-pointer select-none relative py-2 pl-3 pr-4` },
                                    React.createElement("span", { className: `${selected
                                            ? 'font-semibold'
                                            : 'font-normal'} block truncate` }, option.label),
                                    selected && (React.createElement("span", { className: `${active
                                            ? 'text-white'
                                            : 'text-blue-600'} absolute inset-y-0 left-0 flex items-center pl-2` },
                                        React.createElement("svg", { className: "h-5 w-5", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "currentColor" },
                                            React.createElement("path", { fillRule: "evenodd", d: "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z", clipRule: "evenodd" }))))));
                            }))))))))))) : loadOptions ? (React.createElement("div", { className: "w-full mx-auto" },
            React.createElement(AsyncSelect, { placeholder: placeholder, "aria-label": placeholder, ref: asyncSelectRef, isDisabled: isDisabled, className: className, defaultOptions: disableAlphabetization ? options : alphabetisation(options), loadOptions: (searchTerm) => loadOptions(searchTerm), isLoading: !!isLoading, selected: selected, onChange: onChange, components: {
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option,
                    SingleValue
                }, value: value, styles: customStyles, maxMenuHeight: maxHeight, required: required }),
            React.createElement("input", { tabIndex: -1, autoComplete: "off", style: {
                    opacity: 0,
                    height: 0,
                    position: 'absolute',
                    borderWidth: 2,
                    fontWeight: 'normal'
                }, value: getValue(), onChange: newValue => {
                    if (!newValue)
                        return;
                    getValue();
                }, onFocus: () => { var _a; return (_a = asyncSelectRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, required: required, "aria-label": placeholder }))) : (React.createElement("div", { className: "w-full mx-auto" },
            React.createElement(Select, { placeholder: placeholder, "aria-label": placeholder, ref: selectRef, isDisabled: isDisabled, className: className, options: disableAlphabetization ? options : alphabetisation(options), selected: selected, onChange: onChange, components: {
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option,
                    SingleValue
                }, value: value, styles: customStyles, onInputChange: () => {
                    var _a;
                    if ((_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.select) {
                        selectRef.current.select.getNextFocusedOption = () => false;
                    }
                }, maxMenuHeight: maxHeight, required: required }),
            React.createElement("input", { tabIndex: -1, autoComplete: "off", style: {
                    opacity: 0,
                    height: 0,
                    position: 'absolute',
                    borderWidth: 2,
                    fontWeight: 'normal'
                }, value: getValue(), onChange: newValue => {
                    if (!newValue)
                        return;
                    getValue();
                }, onFocus: () => { var _a; return (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, required: required, "aria-label": placeholder }))),
        React.createElement("style", null, `
        .dropdown-options {
          max-height: auto
          z-index: 100
        }
      `)));
};
export default SelectDropdown;
