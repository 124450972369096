var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EChars, ZWS } from '../constants';
import { exists } from '../types';
import { EHandlebars } from '../headers_footers/shared';
import { getLaunchDarklyContext } from '../utils/flags';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
const splitArrayIntoChunksOfNLength = (array, n) => {
    const groups = array
        .map(function (_, i) {
        return i % n === 0 ? array.slice(i, i + n) : [];
    })
        .filter(e => e);
    return groups;
};
export const documentPagesFromImages = (img_array, columns) => {
    const columnsWideColumnsPerPageMap = {
        1: 3,
        2: 2,
        3: 1
    };
    const groups = splitArrayIntoChunksOfNLength(img_array, columnsWideColumnsPerPageMap[columns] || 1);
    return groups
        .map(group => {
        return {
            imgs: group,
            emptyFrames: groups[0].length - group.length
        };
    })
        .filter(group => group.imgs.length);
};
const getColRelWidthsFrmPx = (tableNode) => {
    const table = tableNode.querySelector('tr');
    if (!table)
        return;
    const firstRowCells = table.children;
    const arr = Array.prototype.slice.call(firstRowCells);
    const totalWidth = arr.reduce((acc, node) => {
        const width = node.getAttribute('computed-width');
        return acc + parseFloat(width);
    }, 0);
    const relWidths = arr.map((node, i) => {
        const width = node.getAttribute('computed-width');
        return { i, val: (parseFloat(width) / totalWidth) * 100 };
    });
    relWidths.sort((a, b) => a.val - b.val);
    const MIN_WIDTH_PCT = 9;
    for (const w of relWidths) {
        if (w.val < MIN_WIDTH_PCT) {
            const diff = MIN_WIDTH_PCT - w.val;
            w.val += diff;
            relWidths[relWidths.length - 1].val -= diff;
        }
        else
            break;
    }
    return relWidths.sort((a, b) => a.i - b.i).map(w => w.val);
};
const htmlToIndesignCellAlignerMap = {
    left: 'LeftAlign',
    right: 'RightAlign',
    center: 'CenterAlign'
};
const addColGroupsToTables = (fakeDOM) => {
    const div = fakeDOM.body;
    const tables = div.querySelectorAll('table');
    for (let i = 0; i < tables.length; i++) {
        const ogTable = tables[i];
        const relWidths = getColRelWidthsFrmPx(ogTable);
        const colGroup = fakeDOM.createElement('colgroup');
        relWidths.forEach(widthPct => {
            const col = fakeDOM.createElement('col');
            col.setAttribute('width', `${widthPct}%`);
            colGroup.appendChild(col);
        });
        ogTable.insertBefore(colGroup, ogTable.firstChild);
        // add aligner divs inside cells
        const cells = ogTable.querySelectorAll('td');
        for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
            const cell = cells[cellIndex];
            const alignment = cell.style['text-align'];
            const currentStyleTag = cell.getAttribute('style');
            if (currentStyleTag) {
                cell.setAttribute('style', currentStyleTag.replace(/text-align: (\w*);/g, ''));
            }
            const idCellStyle = htmlToIndesignCellAlignerMap[alignment] ||
                htmlToIndesignCellAlignerMap.left;
            cell.innerHTML = `<div custom-style=${idCellStyle}> ${cell.innerHTML} </div>`;
        }
    }
    return fakeDOM;
};
const addHeadingStyle = (fakeDOM) => {
    const firstParagraph = fakeDOM.body.querySelector('p');
    if (firstParagraph) {
        firstParagraph.innerHTML = `<span custom-style="Heading">${firstParagraph.textContent}</span>`;
    }
};
export const headers = {
    addXML: (str) => `<?xml version="1.0" encoding="UTF-8"?><dynamic-header xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${str}</dynamic-header>`,
    removeXML: (str) => {
        const match = str.match(new RegExp(/<dynamic-header.*?>(.+)<\/dynamic-header>/));
        return Array.isArray(match) ? match[1] : '';
    }
};
export const MCEChars = {
    tab: '<span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span>'
};
/**
 * Determines whether or not we should be introducing discretionary hyphens on a newspaper.
 * @param newspaper Newspaper to check if we are breaking long sequences on
 * @returns {boolean} whether or not to preserve long sequences. True means don't split.
 */
export const shouldPreserveLongSequencesForNewspaper = (newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(newspaper))
        return true;
    const flag = yield getLaunchDarklyContext().getBooleanFeatureFlag(LaunchDarklyFlags.ENABLE_ADVANCED_HYPHENATION, {
        type: 'organization',
        snapshot: newspaper,
        defaultValue: false
    });
    if (flag) {
        return false;
    }
    return true;
});
/**
 * Determines whether or not we should be introducing discretionary hyphens on notices.
 * Flagged to only show up on named newspapers.
 * @param notice Notice to potentially split
 * @returns {boolean} whether or not to preserve long sequences. True means don't split.
 */
export const shouldPreserveLongSequencesForNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const newspaper = yield ((_a = notice.data()) === null || _a === void 0 ? void 0 : _a.newspaper.get());
    return shouldPreserveLongSequencesForNewspaper(newspaper);
});
/**
 * Recursively break up long words in text nodes.
 */
const breakLongTextSequencesInNode = (dom, node) => {
    const text = node.textContent;
    if (node.nodeType === dom.TEXT_NODE && text) {
        // First split the text into words
        const words = text.split(' ');
        // Break up any very long words
        const brokenWords = words.map(w => {
            // Don't break up special characters
            if (w.indexOf(EChars.tab) >= 0) {
                return w;
            }
            // For every run of 12-20 characters, add one zero width space after
            // the tenth character.
            let result = '';
            let i = 0;
            while (i < w.length) {
                const substring = w.substring(i, i + 20);
                if (substring.length > 12) {
                    result += `${substring.slice(0, 10)}${ZWS}${substring.slice(10)}`;
                }
                else {
                    result += substring;
                }
                i += 20;
            }
            return result;
        });
        // eslint-disable-next-line no-param-reassign
        node.textContent = brokenWords.join(' ');
    }
    for (const child of node.childNodes) {
        breakLongTextSequencesInNode(dom, child);
    }
};
/**
 * Adds in a discretionary linebreak or Zero Width Space (ZWSP) to prevent long
 * sequences of characters from breaking across lines
 */
export const breakLongSequences = (html, DOMparser) => {
    const dom = new DOMparser().parseFromString(html, 'text/html');
    for (const node of dom.childNodes) {
        breakLongTextSequencesInNode(dom, node);
    }
    return dom.body.innerHTML;
};
/**
 * Takes in HTML and prepares it for Indesign Server by running a series of cleaning transformations
 * @param html
 * @returns {string} cleaned HTML
 */
export const cleanHtmlForIndesignServer = (html) => {
    return (html
        .replace(/data-custom-style/g, 'custom-style')
        /*
        handle both alignment attributes of the form
        style="text-align: center;" and style="text-align:center"
        both are valid CSS, and different packages we rely on generate the two types
      */
        .replace(/(?:margin-left|margin-right):[^;]*;/gi, '')
        .replace(/style="\s?text-align:\s?(\w*)\s?;?"/g, 'custom-style="align-$1"')
        .replace(/<\/p>/g, '</div>')
        .replace(/<p>/g, '<div>')
        .replace(/<p /g, '<div ')
        .replace(/text-decoration:underline/g, 'text-decoration: underline')
        .replace(/<span><strong>(.|\n)*?<\/strong>.{0,1}<\/span>/g, res => {
        const withoutStartOrEndTags = res
            .replace('<span><strong>', '')
            .replace('</strong>', '')
            .replace('</span>', '');
        return `<strong><span>${withoutStartOrEndTags}</span></strong>`;
    })
        .replace(/<br>/g, '<div><br></div>')
        .replace(/_{10,}/g, (match) => {
        let str = `<span custom-style="Underline">`;
        for (let i = 0; i < match.length; i++) {
            str += '&nbsp;';
        }
        str += '</span>';
        return str;
    })
        .replace(/<span style="text-decoration:\s?underline;?" data-mce-style="text-decoration:\s?underline;?">/g, '<span custom-style="Underline">')
        .replace(/<u>/g, '<span custom-style="Underline">')
        .replace(new RegExp(MCEChars.tab, 'g'), EChars.tab)
        // eslint-disable-next-line no-useless-escape
        .replace(/<\/u>/g, '</span>')
        .replace(/<figure>.*?<\/figure>/g, EChars.tab));
};
/**
 * @param {string} html
 * @param {object} DOMParser
 * @param {object} options
 */
export const htmlToIndesignHtml = (html, DOMparser, { isFirstPHeading, preserveLongSequences }, template) => {
    const fakeDOM = new DOMparser().parseFromString(html, 'text/html');
    addColGroupsToTables(fakeDOM);
    if (isFirstPHeading)
        addHeadingStyle(fakeDOM);
    const transformed = fakeDOM.body.innerHTML;
    const replaced = cleanHtmlForIndesignServer(transformed);
    const compiled = EHandlebars.compile(replaced);
    let result = compiled(template);
    if (!preserveLongSequences) {
        result = breakLongSequences(result, DOMparser);
    }
    return result;
};
export const displayParamsFromNoticeAndPageParams = (crop, pageParams, selectedColumns) => {
    const minColumns = 1;
    const columns = selectedColumns
        ? Math.max(minColumns, selectedColumns)
        : minColumns;
    const scaledWidth = pageParams.columnWidth * columns + (columns - 1) * pageParams.columnGutter;
    const scaledHeight = (scaledWidth / crop.absWidth) * crop.absHeight +
        pageParams.headerHeight +
        pageParams.footerHeight;
    return {
        height: scaledHeight,
        width: scaledWidth,
        area: scaledWidth * scaledHeight,
        columns: selectedColumns || minColumns,
        minColumns
    };
};
