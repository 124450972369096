var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, SvgIcon, Divider, CircularProgress } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Firebase from 'EnoticeFirebase';
import moment from 'moment';
import { InvoiceStatus } from 'lib/enums';
import api from 'api';
const styles = (theme) => createStyles({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    checkIcon: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        marginBottom: theme.spacing(1)
    },
    initiatedText: {
        color: '#697386',
        marginTop: theme.spacing(1)
    },
    paidText: {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#4FA376',
        textTransform: 'uppercase'
    },
    pastDue: {
        backgroundColor: '#FFC400',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1), inset 0px 1px 6px rgba(0, 0, 0, 0.1)',
        color: '#502B06',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '28px',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    pdfButton: {
        border: '0.5px solid #DADADA',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        fontSize: '13px',
        height: theme.spacing(4),
        textTransform: 'none'
    },
    voidedText: {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#E25950',
        textTransform: 'uppercase'
    },
    voidedSubText: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#697386'
    }
});
const formatDate = (timestamp) => {
    return moment(timestamp).format('MMMM DD, YYYY');
};
const isPastDue = (due_date) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const dueDate = moment(due_date * 1000).format('YYYY-MM-DD');
    return moment(dueDate).isBefore(currentDate);
};
function PaidStatus({ paidDate, netTotal, invoice, currency, classes }) {
    const [loading, setLoading] = useState(false);
    const [receipt, setReceipt] = useState();
    const handleReceiptDownload = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        if (receipt || invoice.data().receipt_pdf) {
            setLoading(false);
            window.open(yield Firebase.storage()
                .ref('')
                .child(`/${receipt || invoice.data().receipt_pdf}`)
                .getDownloadURL());
        }
        else {
            const { receiptUrl } = yield api.post('payments/create-receipt-pdf', {
                invoiceId: invoice.id
            });
            setReceipt(receiptUrl);
            setLoading(false);
            window.open(yield Firebase.storage()
                .ref('')
                .child(`/${receiptUrl}`)
                .getDownloadURL());
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { mt: 3, className: classes.flex },
            React.createElement(CheckCircleRoundedIcon, { className: classes.checkIcon, style: { fill: '#4FA376' } }),
            React.createElement(Typography, { className: classes.paidText }, " Invoice paid"),
            React.createElement(Box, { mt: 3 },
                React.createElement(Typography, { style: { fontSize: 18 }, align: "center" },
                    currency.symbol,
                    (netTotal / 100).toFixed(2),
                    " ",
                    currency.label_abbrev,
                    " paid",
                    ' ',
                    paidDate)),
            React.createElement(Box, { mt: 2, mb: 3 },
                React.createElement(Button, { className: classes.pdfButton, onClick: handleReceiptDownload, disabled: loading, startIcon: loading ? (React.createElement(Box, { ml: 1 },
                        React.createElement(CircularProgress, { size: 15 }))) : (React.createElement(SvgIcon, { fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: "currentColor", style: { fill: 'none' } },
                        React.createElement("path", { d: "M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" }))) }, "Download Receipt")))));
}
function VoidedStatus({ voidedDate, classes }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { mt: 3, className: classes.flex },
            React.createElement(NotInterestedIcon, { className: classes.checkIcon, style: { fill: '#E25950' } }),
            React.createElement(Typography, { className: classes.voidedText }, " Invoice voided"),
            React.createElement(Box, { mt: 3 },
                React.createElement(Typography, { style: { fontSize: 18 }, align: "center" },
                    "Invoice voided on ",
                    voidedDate)),
            React.createElement(Box, { mt: 1, mb: 3, ml: 7, mr: 7 },
                React.createElement(Typography, { className: classes.voidedSubText }, "If you\u2019re expecting an updated invoice, please check your Column account or reach out to help@column.us")))));
}
function PendingStatus({ initiatedDate, totalAmount, currency, classes }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { mt: 3, className: classes.flex },
            React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM10 14H6V18H10V14ZM26 14H22V18H26V14ZM14 14H18V18H14V14Z", fill: "#F2C94C" })),
            React.createElement(Typography, { className: classes.initiatedText }, "Payment initiated"),
            React.createElement(Box, { mt: 3, mb: 3 },
                React.createElement(Typography, { style: { fontSize: 20 }, align: "center" },
                    currency.symbol,
                    (totalAmount / 100).toFixed(2),
                    " initiated ",
                    initiatedDate)))));
}
function UnpaidStatus({ netTotal, currency, dueDate }) {
    return (React.createElement(Box, { mt: 4 },
        React.createElement(Typography, { style: { fontSize: 18 }, align: "center" },
            currency.symbol,
            netTotal && (netTotal / 100).toFixed(2),
            " ",
            currency.label_abbrev,
            " due",
            ' ',
            formatDate(dueDate * 1000))));
}
function InvoiceStatusInfo({ invoice, invoicePricingData, classes }) {
    const invoiceData = invoice.data();
    // Do not get the netTotal from the invoiceData object because the pricing data has a more reliable netTotal
    const { status, finalized_at, due_date } = invoiceData;
    const voidedAt = 'voided_at' in invoiceData ? invoiceData.voided_at : undefined;
    const { currency, totalAmount, netTotal } = invoicePricingData;
    return (React.createElement(React.Fragment, null,
        (status === InvoiceStatus.unpaid.value ||
            status === InvoiceStatus.payment_failed.value) &&
            !voidedAt &&
            isPastDue(due_date) && React.createElement(Box, { className: classes.pastDue }, "past due"),
        React.createElement(Divider, null),
        voidedAt && (React.createElement(VoidedStatus, { classes: classes, voidedDate: formatDate(voidedAt.toMillis()) })),
        ([
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(status) ||
            totalAmount === 0) && (React.createElement(PaidStatus, { paidDate: formatDate(finalized_at === null || finalized_at === void 0 ? void 0 : finalized_at.toMillis()), invoice: invoice, netTotal: netTotal || 0, currency: currency, classes: classes })),
        status === InvoiceStatus.initiated.value && (React.createElement(PendingStatus, { classes: classes, initiatedDate: formatDate(finalized_at.toMillis()), totalAmount: totalAmount, currency: currency })),
        (status === InvoiceStatus.unpaid.value ||
            status === InvoiceStatus.payment_failed.value) && (React.createElement(UnpaidStatus, { netTotal: netTotal || 0, currency: currency, dueDate: due_date }))));
}
export default withStyles(styles)(InvoiceStatusInfo);
