import React, { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { isMemberOfOrganization } from 'lib/utils/users';
import NotificationSettings from './NotificationSettings';
import SettingsForm from './SettingsForm';
import LoadingState from '../../components/LoadingState';
import UserSettingsBankAccounts from './userSettingsBankAccounts';
import { getLocationParams } from '../../utils/urls';
import { HomeIcon, Bell, Dollar, DocumentText } from './icons';
import UserSettingsDocuments from './userSettingsDocuments';
export const BASIC_INFO = 'basic-info';
export const BANK_ACCOUNTS = 'bank-accounts';
export const NOTIFICATIONS = 'notifications';
export const DOCUMENTS = 'documents';
const styles = (theme) => createStyles({
    content: {
        flexGrow: 1,
        padding: theme.spacing(0, 2)
    }
});
const Settings = ({ user, userAuth, isPublisher, activeOrganization, classes }) => {
    const [view, setView] = useState(getLocationParams().get('tab') || BASIC_INFO);
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/?tab=${view}`);
        setView(view);
    };
    // Bank accounts are only shown in the user settings if the user is an advertiser
    // and is NOT part of any organization.
    const userInOrganization = user && isMemberOfOrganization(user);
    const showBankAccountsTab = !isPublisher && !userInOrganization;
    const renderTab = (tabName, tabLabel, icon) => (React.createElement("div", { className: `flex rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tabName && 'bg-gray-200'}`, onClick: () => updateView(tabName), id: tabName },
        React.createElement("div", { className: "flex justify-center w-3/12" }, icon),
        React.createElement("div", { className: "w-9/12" }, tabLabel)));
    const renderSideBar = () => (React.createElement("div", { className: "bg-white rounded border border-gray-300 pt-3 px-4", style: {
            height: 'calc(100vh - 150px)'
        } },
        renderTab(BASIC_INFO, 'User Info', HomeIcon),
        renderTab(NOTIFICATIONS, 'Notification Settings', Bell),
        showBankAccountsTab && renderTab(BANK_ACCOUNTS, 'Bank Accounts', Dollar),
        !isPublisher && renderTab(DOCUMENTS, 'Documents', DocumentText)));
    if (!user)
        return React.createElement(LoadingState, null);
    const tabs = {
        [BASIC_INFO]: (React.createElement(SettingsForm, { isPublisher: isPublisher, userAuth: userAuth, user: user })),
        [NOTIFICATIONS]: (React.createElement(NotificationSettings, { userAuth: userAuth, user: user, activeOrganization: activeOrganization, isPublisher: isPublisher })),
        [DOCUMENTS]: React.createElement(UserSettingsDocuments, null)
    };
    if (showBankAccountsTab) {
        tabs[BANK_ACCOUNTS] = React.createElement(UserSettingsBankAccounts, { user: user });
    }
    // If the user somehow gets a link to a tab they can't access, redirect.
    if (!Object.keys(tabs).includes(view)) {
        if (view === BANK_ACCOUNTS && userInOrganization) {
            // The user may have been linked to the wrong place for their bank accounts
            // Note: all of my attempts to use react-router for this redirect failed, but
            // this should be an uncommon operation so a full page refresh is OK.
            window.location.href = '/settings/organization/?tab=bank-accounts';
        }
        else {
            // Bounce back to basic info
            updateView(BASIC_INFO);
        }
    }
    return (React.createElement("div", { className: "flex" },
        renderSideBar(),
        React.createElement("main", { className: classes.content }, tabs[view])));
};
export default withStyles(styles)(Settings);
