import { RoleType } from '../enums';
export var Permissions;
(function (Permissions) {
    /** View and update organization bank accounts */
    Permissions["BANK_ACCOUNTS_WRITE"] = "bank_accounts.write";
    /** Create an invoice from the notice details screen */
    Permissions["INVOICES_CREATE"] = "invoices.create";
    /** Invite / delete users to the organization */
    Permissions["ORGANIZATION_MODIFY_MEMBERS"] = "organizations.modify_members";
    /** View the report at /payments */
    Permissions["PAYMENTS_REPORT"] = "payments.report";
    /** View and use the Newspaper/Organization Info settings tab */
    Permissions["SETTINGS_ORGANIZATION_INFO"] = "settings.organization_info";
    /** View and use the Rates settings tab */
    Permissions["SETTINGS_RATES"] = "settings.rates";
    /** View and use the Advertising Deadlines settings tab */
    Permissions["SETTINGS_DEADLINES"] = "settings.deadlines";
    /** View and use the Affidavits settings tab */
    Permissions["SETTINGS_AFFIDAVITS"] = "settings.affidavits";
    /** View and use the Payments settings tab */
    Permissions["SETTINGS_PAYMENT"] = "settings.payment";
    /** View and use the Invoice Reminders settings tab */
    Permissions["SETTINGS_INVOICE_REMINDERS"] = "settings.invoice_reminders";
    /** View and use the Customers settings tab */
    Permissions["SETTINGS_CUSTOMERS"] = "settings.customers";
    /** View and use the Bulk Invoices settings tab */
    Permissions["SETTINGS_BULK_INVOICES"] = "settings.bulk_invoices";
    /** View and use the Custom Branding settings tab */
    Permissions["SETTINGS_CUSTOM_BRANDING"] = "settings.custom_branding";
    /** View and use the Templates settings tab */
    Permissions["SETTINGS_TEMPLATES"] = "settings.templates";
})(Permissions || (Permissions = {}));
export const ROLE_SUPER = {
    role: RoleType.super.value,
    permissions: [Permissions.SETTINGS_CUSTOM_BRANDING],
    inheritedRoles: [RoleType.admin.value]
};
export const ROLE_ADMIN = {
    role: RoleType.admin.value,
    permissions: [
        Permissions.ORGANIZATION_MODIFY_MEMBERS,
        Permissions.SETTINGS_ORGANIZATION_INFO,
        Permissions.SETTINGS_AFFIDAVITS,
        Permissions.SETTINGS_DEADLINES,
        Permissions.SETTINGS_TEMPLATES
    ],
    inheritedRoles: [RoleType.billing.value]
};
export const ROLE_BILLING = {
    role: RoleType.billing.value,
    permissions: [
        Permissions.BANK_ACCOUNTS_WRITE,
        Permissions.INVOICES_CREATE,
        Permissions.PAYMENTS_REPORT,
        Permissions.SETTINGS_RATES,
        Permissions.SETTINGS_PAYMENT,
        Permissions.SETTINGS_INVOICE_REMINDERS,
        Permissions.SETTINGS_CUSTOMERS,
        Permissions.SETTINGS_BULK_INVOICES
    ],
    inheritedRoles: [RoleType.user.value]
};
export const ROLE_RATES = {
    role: RoleType.rates.value,
    permissions: [
        Permissions.SETTINGS_RATES,
        Permissions.SETTINGS_DEADLINES,
        Permissions.SETTINGS_CUSTOMERS
    ],
    inheritedRoles: [RoleType.user.value]
};
export const ROLE_USER = {
    role: RoleType.user.value,
    permissions: [],
    inheritedRoles: []
};
export const ROLE_EMPTY = {
    role: 0,
    permissions: [],
    inheritedRoles: []
};
const ROLES_MAP = {
    [RoleType.super.value]: ROLE_SUPER,
    [RoleType.admin.value]: ROLE_ADMIN,
    [RoleType.billing.value]: ROLE_BILLING,
    [RoleType.user.value]: ROLE_USER,
    [RoleType.rates.value]: ROLE_RATES,
    0: ROLE_EMPTY
};
export const roleHasPermission = (role, permission) => {
    var _a;
    const rolePermissions = (_a = ROLES_MAP[role]) !== null && _a !== void 0 ? _a : ROLE_EMPTY;
    // Check if the role has the permission explicitly
    if (rolePermissions.permissions.includes(permission)) {
        return true;
    }
    // Recursively check all inherited roles
    return rolePermissions.inheritedRoles.some(r => roleHasPermission(r, permission));
};
