import React from 'react';
import classNames from 'classnames';
import { CheckCircle, XIcon } from 'icons';
export function Toast({ headerText, bodyText, position = 'left', close, status }) {
    const wrapperClasses = classNames('z-100 absolute bottom-0 flex bg-white rounded-lg shadow-xl m-8 p-6 max-w-lg', {
        'left-0': position === 'left',
        'right-0': position === 'right'
    });
    const iconClasses = classNames('p-3 rounded-full self-start', {
        'bg-green-50 text-green-500': status === 'success',
        'bg-red-50 text-red-600': status === 'error',
        'bg-yellow-50 text-yellow-600': status === 'warning',
        'bg-primary-100 text-primary-500': status === 'info',
        'bg-gray-100 text-gray-600': !status
    });
    return (React.createElement("div", { className: wrapperClasses, role: "dialog", "aria-modal": "true", "aria-labelledby": "toast-headline" },
        React.createElement("div", { className: iconClasses },
            React.createElement(CheckCircle, { className: "h-6 w-6" })),
        React.createElement("div", { className: "mx-4 font-medium" },
            React.createElement("p", { id: "toast-headline", className: "text-base leading-6 text-gray-800" }, headerText),
            React.createElement("p", { className: "text-sm text-gray-700 py-2" }, bodyText)),
        React.createElement("button", { onClick: () => close(), className: "self-start hover:text-grey-500" },
            React.createElement("span", { className: "sr-only" }, "Close"),
            React.createElement(XIcon, { className: "h-6 w-6" }))));
}
