import { exists } from 'lib/types';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
export function useFirestoreListener(docRef) {
    const [existingSnapshot, setExistingSnapshot] = useState(null);
    useEffect(() => {
        if (!existingSnapshot) {
            return;
        }
        const ref = docRef !== null && docRef !== void 0 ? docRef : existingSnapshot.ref;
        const unsubscribe = ref.onSnapshot(nextSnap => {
            if (!exists(nextSnap)) {
                setExistingSnapshot(null);
                return;
            }
            if (!isEqual(nextSnap.data(), existingSnapshot.data())) {
                setExistingSnapshot(nextSnap);
            }
        });
        return unsubscribe;
    }, [existingSnapshot]);
    return [existingSnapshot, setExistingSnapshot];
}
