import React from 'react';
import { InvoiceStatus } from 'lib/enums';
export function LatePaymentWarning({ requireUpfrontPayment, preventLatePayment, invoiceStatus }) {
    const showMissedUpfrontPaymentNotification = preventLatePayment &&
        invoiceStatus &&
        ![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(invoiceStatus);
    const showRequiredUpfrontPaymentWarning = requireUpfrontPayment &&
        !preventLatePayment &&
        invoiceStatus &&
        ![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(invoiceStatus);
    return (React.createElement(React.Fragment, null,
        showMissedUpfrontPaymentNotification && (React.createElement("div", { className: "relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800" },
            React.createElement("div", { className: "flex items-center" },
                React.createElement("div", { className: "w-2 h-16 bg-blue-500 mr-4" }),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("b", { className: "mr-1" }, "Deadline has passed."),
                    React.createElement("div", null, "Upfront payment was required for this notice. Please contact the newspaper or Column Support to reschedule your notice for a future date."))))),
        showRequiredUpfrontPaymentWarning && (React.createElement("div", { className: "relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800" },
            React.createElement("div", { className: "flex items-center" },
                React.createElement("div", { className: "w-2 h-16 bg-blue-500 mr-4" }),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("b", { className: "mr-1" }, "Upfront payment required."),
                    React.createElement("div", null, "If you don\u2019t pay your invoice on time, your notice might not be published.")))))));
}
