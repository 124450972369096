var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useState } from 'react';
import { makeCsvContent, noticeID, noticeName, preview } from 'lib/utils/csv';
import { ArrowUpCircle, CheckOutlinedIcon, DocumentDownloadIcon } from 'icons';
import { downloadFileContentsInBrowser } from 'utils';
import { exists } from 'lib/types';
/**
 * Wrapper around items in the dropdown menu
 */
function DropdownItem({ children, onClick, id }) {
    return (React.createElement("a", { href: "#", id: id, className: "flex items-center py-3 px-4 font-medium text-md text-gray-750 hover:bg-gray-100 hover:text-gray-900", role: "menuitem", onClick: e => {
            e.preventDefault();
            onClick();
        } }, children));
}
/**
 * Converts the set of upload days currently linked to by the paper to a CSV file
 * that can be downloaded by the publisher.
 * @param uploadDays
 * @returns {Promise<string>} CSV file
 */
const createVerificationCSV = (uploadDays) => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * Runs a memoized implementation of converting a ref to a snapshot, as many
     * notices appear across multiple upload days. This function:
     * 1. Checks to see if the notice has already been pulled. If so, returns it.
     * 2. If not, pulls the notice from the database and caches it.
     * 3. Returns the notice data
     * 4. Stores a "preview" of a sanitized version of the HTML of the notice
     * @param noticeRef
     * @returns
     */
    const _noticeDataCache = {};
    const getNoticeSnapshotFromNoticeRef = (noticeRef) => __awaiter(void 0, void 0, void 0, function* () {
        if (!_noticeDataCache[noticeRef.id]) {
            const noticeSnapshot = yield noticeRef.get();
            if (!exists(noticeSnapshot))
                throw new Error('Missing data');
            _noticeDataCache[noticeRef.id] = {
                noticeSnapshot,
                preview: preview(noticeSnapshot)
            };
        }
        return _noticeDataCache[noticeRef.id];
    });
    /**
     * Extracts the data needed for a CSV row from the info returned by getNoticeSnapshotFromNoticeRef
     * @param notice
     * @param verified
     * @returns {Promise<string[]>}
     */
    const noticeRefToNoticeRow = (uploadDay, notice, verified) => __awaiter(void 0, void 0, void 0, function* () {
        const { noticeSnapshot, preview } = yield getNoticeSnapshotFromNoticeRef(notice);
        return [
            // internal ID of the notice
            noticeID(noticeSnapshot),
            // notice name
            noticeName(noticeSnapshot),
            // upload date we are examining
            uploadDay.publishingDateString,
            // whether or not the notice was verified
            verified ? 'True' : 'False',
            // links to all e-edition PDFs
            `"${uploadDay.pdfsUploaded.map(pdf => pdf.pdfUrl).join(', ')}"`,
            // link to the notice in Column
            `https://www.column.us/notice/${notice.id}`,
            // preview of the text in the notice
            preview
        ];
    });
    const uploadData = yield Promise.all(uploadDays.map((uploadDay) => __awaiter(void 0, void 0, void 0, function* () {
        // create rows for valid and non-validated notices
        const validatedNoticeData = yield Promise.all(uploadDay.noticesValidated.map((notice) => __awaiter(void 0, void 0, void 0, function* () { return noticeRefToNoticeRow(uploadDay, notice, true); })));
        const invalidNoticeData = yield Promise.all(uploadDay.noticesNotValidated.map((notice) => __awaiter(void 0, void 0, void 0, function* () { return noticeRefToNoticeRow(uploadDay, notice, false); })));
        return [...validatedNoticeData, ...invalidNoticeData];
    })));
    // returns the CSV content
    return makeCsvContent([
        'Notice ID',
        'Notice Name',
        'Run Date',
        'Verified',
        'E-Edition',
        'Column URL',
        'Notice Preview'
    ], uploadData.flat());
});
/**
 * Enables additional actions like downloading a CSV of the current table data.
 */
export default function MoreAffidavitActions({ setShowEEditionUploadModal, setShowVerifyNoticesPublicationModal, activeOrganization, setSuccessMessage, uploadDays }) {
    var _a;
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    /**
     * Downloads a CSV file containing verification attempts currently visible within
     * the table.
     */
    const downloadAffidavitReport = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        // create the CSV
        const verificationCSV = yield createVerificationCSV(uploadDays);
        // download the CSV
        const csvName = `${activeOrganization.data().name}_upload_validation_${new Date().toDateString()}.csv`;
        downloadFileContentsInBrowser(csvName, verificationCSV, 'text/csv');
        setLoading(false);
        setSuccessMessage({
            header: 'Report downloaded',
            body: 'Your affidavit report was generated successfully and downloaded to your computer.'
        });
    });
    return (React.createElement("div", { className: "relative inline-block text-left mt-1 ml-2", id: "affidavit-upload-filter-dialog" },
        React.createElement("div", { onClick: () => setOpen(!open), className: "cursor-pointer" },
            React.createElement("div", { className: "items-center inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" },
                React.createElement("span", { className: "mx-1" }, "More"),
                React.createElement(KeyboardArrowDown, null))),
        open && (React.createElement("div", { className: "absolute right-0 z-10 mt-2 w-80 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-5 px-4" },
            React.createElement("p", { className: "uppercase text-gray-500 text-xs font-bold mb-3" }, "More Actions"),
            loading ? (React.createElement("div", { className: "flex justify-center align-center mt-8" },
                React.createElement("div", { className: "border-4 border-t-4 ease-linear h-6 loader rounded-full w-6" }))) : (React.createElement(React.Fragment, null,
                React.createElement(DropdownItem, { id: "download-verification-report", onClick: () => setShowEEditionUploadModal({
                        uploadDay: null,
                        show: true
                    }) },
                    React.createElement(ArrowUpCircle, { className: "h-4 w-4 inline-block mr-2" }),
                    "Upload e-edition"),
                React.createElement(DropdownItem, { id: "download-verification-report", onClick: () => downloadAffidavitReport() },
                    React.createElement(DocumentDownloadIcon, { className: "w-5 h-5 -ml-0.5 mr-1.5", strokeWidth: "1.25" }),
                    "Download verification report"),
                ((_a = activeOrganization.data().affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.checkboxVerificationEnabled) && (React.createElement(DropdownItem, { id: "show-notices-publication-verification-modal", onClick: () => setShowVerifyNoticesPublicationModal(true) },
                    React.createElement(CheckOutlinedIcon, { className: "w-5 h-5 -ml-0.5 mr-1.5", strokeWidth: "1.25" }),
                    "Verify notices"))))))));
}
