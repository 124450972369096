var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { Firestore } from 'EnoticeFirebase';
import { getRedirect } from 'utils/urls';
import { OccupationType, InviteStatus } from 'lib/enums';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import api from 'api';
import { getFirebaseContext } from 'utils/firebase';
import AdvertiserRegistrationSuccess from 'routes/register/AdvertiserRegistrationSuccess';
import StepsCounter from 'routes/register/StepsCounter';
import StateSelectDropdown from 'components/StateSelectDropdown';
import { logAndCaptureException } from 'utils';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import RegisterOrganizationInput from '../RegisterOrganizationInput';
import * as validators from '../validators';
const RegisterIndividual = ({ userAuth, authActions, anonPlacement, user, push }) => {
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState({
        id: 0,
        label: ''
    });
    const [zipCode, setZipCode] = useState('');
    const [phone, setPhone] = useState('');
    const isZipValid = !zipCode || validators.zipAdvertiser(zipCode);
    const isPhoneValid = !phone || validators.phone(phone);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    const ctx = getFirebaseContext();
    const step = !selectedOrganizations.length ? '3' : '4';
    useEffect(() => {
        if (!sessionStorage.getItem('selectedOrganizations'))
            return;
        const { selectedOrganizations } = sessionStorage;
        setSelectedOrganizations(JSON.parse(selectedOrganizations));
    }, []);
    /* registration done */
    if (user.data().organization)
        push('/');
    const disable = !addressLine1 || !city || !state.label || !zipCode || !phone;
    const onBackClick = (e) => {
        e.preventDefault();
        push('/register/occupations');
    };
    const notifyOrganizationAdminUsers = () => __awaiter(void 0, void 0, void 0, function* () {
        const userRequests = yield getFirebaseContext()
            .joinRequestsRef()
            .where('email', '==', user.data().email)
            .get();
        if (!userRequests.docs.length)
            return;
        try {
            yield api.post('users/send-requests');
        }
        catch (err) {
            logAndCaptureException(err, 'Error on sending joining requests email to admin users', {
                userId: user.id
            });
        }
    });
    const onConfirm = () => {
        if (anonPlacement) {
            push(`/?redirect=${encodeURIComponent(anonPlacement)}`);
        }
        push(getRedirect());
    };
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (disable) {
            return;
        }
        setLoading(true);
        // Create stripe customer id for individual
        const createCustomer = getCreateCustomerFn();
        // If a newspaper has already invoiced this user pre-registration,
        // the user will have an existing stripe ID
        let result;
        let newStripeId;
        if (!user.data().stripeId) {
            result = yield createCustomer({
                name: user.data().name,
                email: user.data().email,
                firestoreUID: userAuth.uid
            });
            if (!result) {
                console.error('Unable to create new Stripe customer ID');
                return;
            }
            newStripeId = result.data.stripeId;
        }
        const userUpdateObject = {
            occupation: OccupationType.individual.value,
            address: addressLine1,
            addressLine2: addressLine2 || '',
            city,
            state: state.id,
            zipCode,
            phone,
            stripeId: user.data().stripeId || newStripeId
        };
        yield ctx.usersRef().doc(userAuth.uid).update(userUpdateObject);
        authActions.register();
        if (selectedOrganizations.length) {
            for (const item of selectedOrganizations) {
                const requestObj = {
                    createdAt: Firestore.FieldValue.serverTimestamp(),
                    email: user.data().email,
                    userId: user.id,
                    organization: getFirebaseContext().organizationsRef().doc(item.value),
                    status: InviteStatus.pending.value
                };
                // eslint-disable-next-line no-await-in-loop
                yield ctx.joinRequestsRef().add(requestObj);
            }
            sessionStorage.removeItem('selectedOrganizations');
        }
        yield notifyOrganizationAdminUsers();
        setSuccess(true);
    });
    const nextButtonClicked = (e) => __awaiter(void 0, void 0, void 0, function* () {
        yield handleSubmit(e);
    });
    if (success)
        return (React.createElement(AdvertiserRegistrationSuccess, { userName: user.data().firstName, subHeaderText: !selectedOrganizations.length
                ? 'Your account is all set to place and manage notices.'
                : `Your account is all set to place notices as an individual. If you
              need to place notices on behalf of
              ${selectedOrganizations.length > 1
                    ? 'an organization'
                    : `${selectedOrganizations[0].label}`}, please wait until your request is accepted.`, onConfirmSuccess: onConfirm }));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "-mt-12 flex flex-col justify-center items-center h-screen" },
            React.createElement(StepsCounter, { maxStep: step, stepCount: step, isStepDone: !disable }),
            React.createElement("div", { className: "mt-6 text-center" },
                React.createElement("p", { className: "text-gray-900 font-semibold leading-8 text-2xl" }, !selectedOrganizations.length
                    ? 'Tell us a bit about you'
                    : 'Create an individual account'),
                React.createElement("p", { className: "p-3 mt-2 text-gray-600 font-medium text-sm leading-6 w-100" }, !selectedOrganizations.length
                    ? 'Just one more step to create an individual account.'
                    : 'Just one more step. Please create an individual account while your request is pending.')),
            React.createElement("div", { className: "mt-8 flex flex-col bg-white rounded-md px-8 py-8 shadow-2 border lg:w-3/6 md:w-3/6 sm:w-auto" },
                React.createElement("div", { className: "flex flex-col w-full" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "flex space-x-1" },
                            React.createElement(RegisterOrganizationInput, { id: 'address-line-1', required: true, autoComplete: 'address-line1', value: addressLine1, placeholder: 'Address line 1', styleProps: {
                                    borderConflict: 'b',
                                    rounded: 't'
                                }, onChange: setAddressLine1, fieldName: "Address line 1" }),
                            React.createElement("div", { className: "w-8" }),
                            React.createElement(RegisterOrganizationInput, { id: 'address-line-2', autoComplete: 'address-line2', value: addressLine2, placeholder: 'Address Line 2', styleProps: {
                                    rounded: 'b'
                                }, onChange: setAddressLine2, fieldName: "Address line 2" })),
                        React.createElement("div", { className: "flex mt-3 space-x-1" },
                            React.createElement(RegisterOrganizationInput, { id: 'city', required: true, autoComplete: 'address-level2', value: city, placeholder: 'City', styleProps: {
                                    borderConflict: 'b',
                                    rounded: 't'
                                }, onChange: setCity, fieldName: "City" }),
                            React.createElement("div", { className: "w-4" }),
                            React.createElement("div", { className: "w-full block w-6/12" },
                                React.createElement("div", null,
                                    React.createElement("p", { className: "text-gray-800 font-medium text-sm leading-5 mb-2" }, "State *")),
                                React.createElement(StateSelectDropdown, { onChange: value => setState(value), value: state }))),
                        React.createElement("div", { className: "flex mt-3 space-x-1" },
                            React.createElement(RegisterOrganizationInput, { id: 'phone', required: true, autoComplete: 'tel', value: phone, placeholder: 'Phone', onChange: val => {
                                    if (val.length <= validators.MAX_PHONE_LENGTH) {
                                        setPhone(val);
                                    }
                                }, valid: isPhoneValid, errorMessage: 'Phone number must be 10 digits.', fieldName: "Phone number" }),
                            React.createElement("div", { className: "w-4" }),
                            React.createElement(RegisterOrganizationInput, { id: 'zipCode', required: true, autoComplete: 'postal-code', value: zipCode, placeholder: 'Zip', onChange: val => {
                                    if (val.length <= validators.MAX_ZIP_LENGTH) {
                                        setZipCode(val);
                                    }
                                }, valid: isZipValid, errorMessage: 'Zip must be 5 digits.', fieldName: "Zip code" })))))),
        React.createElement(AdvertiserRegistrationFooter, { id: "submit-individual", backButtonText: "Go back", nextButtonText: "Create account", onBackButtonClick: onBackClick, onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: disable || loading, hideBackButton: !!selectedOrganizations.length })));
};
export default RegisterIndividual;
