import PasswordField from 'components/PasswordField';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormError from 'routes/errors/FormError';
import { SmilingFaceWithSmilingEyes } from 'emojis';
import CancelOrSubmitModal from './CancelOrSubmitModal';
const PlacementLoginModal = ({ showModal, email, authActions }) => {
    const [password, setPassword] = useState('');
    const authError = useSelector((state) => state.auth.error);
    useEffect(() => {
        if (!authError)
            return;
        authActions.setAuthError('');
    }, [email, password]);
    return (React.createElement(CancelOrSubmitModal, { setOpen: showModal, overrideTertiaryClose: () => showModal(false), onSubmit: () => {
            authActions.login(email, password, false);
        }, disablePrimaryButton: !password.length, primaryButtonText: 'Login', tertiaryButtonText: 'Back' },
        React.createElement("div", { className: "mx-10 mb-8 text-center z-20", id: "placement-password-reset-modal-header" },
            React.createElement("div", { className: "font-semibold text-xl m-auto", id: "pw-reset-heading" },
                "We found an account with this email",
                React.createElement(SmilingFaceWithSmilingEyes, null)),
            React.createElement("div", { className: "mt-4 font-medium text-sm text-grey-400", id: "pw-reset-subheading" },
                "Enter your Column password for",
                ' ',
                React.createElement("span", { className: "text-blue-500" }, email),
                " to login and complete the notice.")),
        React.createElement("div", { className: "mb-12", id: "placement-password-reset-modal-body" },
            React.createElement("div", { className: "flex justify-between font-medium text-sm leading-6 mb-2" },
                React.createElement("label", { htmlFor: "placement-password-reset-new-password", className: "text-grey-500" },
                    "Password ",
                    React.createElement("span", { className: "text-grey-400" }, "*")),
                React.createElement("a", { className: 'text-blue-500', href: "/forgot-password", target: "_blank", rel: "noreferrer" }, "Forgot password?")),
            React.createElement("div", null,
                React.createElement(PasswordField, { id: "placement-password-reset-new-password", className: 'flex items-center appearance-none rounded-md relative block w-full h-12 px-3 py-2 border border-blue-500 placeholder-gray-500 text-grey-400 sm:text-sm sm:leading-5', value: password, onValueChange: (e) => {
                        setPassword(e.target.value);
                    } })),
            authError && React.createElement(FormError, { error: authError }))));
};
export default PlacementLoginModal;
