import React from 'react';
import classNames from 'classnames';
import { XIcon } from 'icons';
const Drawer = ({ disableDarkenBackground, closeDrawer, className, children, header, open }) => {
    return (React.createElement(DrawerWrapper, { open: open, disableDarkenBackground: disableDarkenBackground, className: className },
        React.createElement(DrawerHeader, { header: header, closeDrawer: closeDrawer }),
        children));
};
function DrawerHeader({ header, closeDrawer }) {
    return (React.createElement("div", { className: "flex items-center justify-between px-6 py-3 border-b" },
        header,
        React.createElement("div", { className: "border border-grey-200 rounded p-1.5 text-gray-600 hover:bg-gray-100 cursor-pointer", onClick: () => closeDrawer() },
            React.createElement(XIcon, { className: "h-6 w-6" }))));
}
function DrawerWrapper({ disableDarkenBackground, className, children, open }) {
    const mainDrawerClass = classNames('fixed overflow-hidden z-20 inset-0 transform ease-in-out', {
        'transition-opacity opacity-100 duration-500 translate-x-0': open,
        'transition-all delay-500 opacity-0 translate-x-full': !open,
        'bg-gray-900 bg-opacity-25': !disableDarkenBackground
    });
    const sectionDrawerClass = classNames('w-screen max-w-xl right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform', {
        'translate-x-0': open,
        'translate-x-full': !open
    }, className);
    return (React.createElement("div", { className: mainDrawerClass },
        React.createElement("section", { className: sectionDrawerClass }, children)));
}
export default Drawer;
