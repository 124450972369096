import React from 'react';
import classNames from 'classnames';
import { DeleteIcon, InformationCircle } from 'icons';
import Tooltip from 'components/Tooltip';
import Validator from 'validator';
import ColumnSelect from 'components/select/ColumnSelect';
import { RoleType } from 'lib/enums';
const AdvertisersInviteInputRow = ({ index, value, onValueChange, onRoleSelect, onRemoveClick, error, loading }) => {
    var _a;
    const isEmailInvalid = !(value.email ? Validator.isEmail(value.email) : true);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex w-full gap-x-5 items-center mt-6" },
            React.createElement("div", { className: "flex flex-col w-7/12" },
                React.createElement("p", { className: "text-gray-800 font-medium text-sm leading-5 mb-2" }, "Email address *"),
                React.createElement("input", { required: true, autoComplete: "none", id: "email", placeholder: "user@email.com", name: "email", value: value.email || '', onChange: event => onValueChange(event), disabled: loading, className: classNames('form-input transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-400 h-12', {
                        'border-red-500 text-xs': isEmailInvalid
                    }) })),
            React.createElement("div", { className: "flex flex-col w-5/12" },
                React.createElement("p", { className: "text-gray-800 font-medium text-sm leading-5 mb-2" }, "Role *"),
                React.createElement(ColumnSelect, { options: RoleType.items()
                        .filter(role => role.value !== RoleType.super.value)
                        .map(role => {
                        return { label: role.label, value: role.label };
                    }), onChange: value => onRoleSelect(value), value: ((_a = RoleType.by_value(value.role)) === null || _a === void 0 ? void 0 : _a.label) || '', className: "rounded-md", inlineTextColor: 'text-blue-800', itemsListDescription: "Choose user's role", size: "large" })),
            React.createElement("div", { className: "flex items-center justify-center w-2 mt-7 text-gray-700" }, index !== 0 ? (React.createElement("button", { type: "button", onClick: () => onRemoveClick(), disabled: loading },
                React.createElement(DeleteIcon, null))) : (React.createElement(Tooltip, { helpText: "Roles determine which parts of your Column organization members can edit. If you are unsure, choose User", position: "topLeft" },
                React.createElement(InformationCircle, null))))),
        error && (React.createElement("div", { className: "flex w-full justify-start text-red-500" },
            React.createElement("div", { className: "flex justify-center" }, error)))));
};
export default AdvertisersInviteInputRow;
