var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import b64toBlob from 'b64-to-blob';
import { getCloudConvertOnCallFn } from './callableFunctions';
const CONVERTAPI_SECRET = 'O4rC9fDAHuRAsr8G';
const convertFile = getCloudConvertOnCallFn();
export const cloudConvertFile = (storageId, input, output, opts = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const convertedUrl = yield convertFile({ storageId, input, output, opts });
    const data = (yield fetch(convertedUrl.data)).arrayBuffer();
    return data;
});
export const convertToPdf = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `https://v2.convertapi.com/convert/${file.name
        .split('.')
        .pop()}/to/pdf?Secret=${CONVERTAPI_SECRET}`;
    const formData = new FormData();
    formData.append('File', file, file.name);
    const conversion = yield fetch(url, {
        method: 'POST',
        body: formData
    });
    const result = yield conversion.json();
    const { FileData } = result.Files[0];
    // return FileData;
    return b64toBlob(FileData, 'application/pdf');
});
export const convertToJPG = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `https://v2.convertapi.com/convert/${file.name
        .split('.')
        .pop()}/to/jpg?Secret=${CONVERTAPI_SECRET}`;
    const formData = new FormData();
    formData.append('File', file, file.name);
    const conversion = yield fetch(url, {
        method: 'POST',
        body: formData
    });
    const result = yield conversion.json();
    const { FileData } = result.Files[0];
    return b64toBlob(FileData, 'image/jpg');
});
export const rtfToHtml = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `https://v2.convertapi.com/convert/${file.name
        .split('.')
        .pop()}/to/txt?Secret=${CONVERTAPI_SECRET}`;
    const formData = new FormData();
    formData.append('File', file, file.name);
    const conversion = yield fetch(url, {
        method: 'POST',
        body: formData
    });
    const result = yield conversion.json();
    const { FileData } = result.Files[0];
    const html = atob(FileData).replace('\n', '<br />');
    return html;
});
