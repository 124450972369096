import React from 'react';
import { State } from 'lib/enums';
import { CustomerDrawerRow } from './CustomerDrawerRow';
export const CustomerDrawerDetailsTab = ({ userName, address, addressLine2, phone, city, state, zipCode, internalID, customerData, showOrganizationName, organizationName, isCustomerOrganization }) => {
    var _a, _b;
    return (React.createElement("div", { className: "p-4 flex flex-col items-start text-sm leading-6 font-medium" },
        React.createElement(CustomerDrawerRow, { label: "Name", value: userName }),
        isCustomerOrganization && (React.createElement(CustomerDrawerRow, { label: "ID", value: internalID })),
        !isCustomerOrganization && (React.createElement("div", { className: "flex w-full border-b border-grey-50" },
            React.createElement("div", { className: "flex items-center" },
                React.createElement("div", { className: "pl-4 py-3.5 text-grey-400" }, "Email address"),
                React.createElement("a", { href: `mailto:${customerData === null || customerData === void 0 ? void 0 : customerData.user.data().email}`, className: "absolute right-0 pr-8 text-blue-500" }, customerData === null || customerData === void 0 ? void 0 : customerData.user.data().email)))),
        React.createElement("div", { className: "flex w-full border-b border-grey-50" },
            React.createElement("div", { className: "flex items-center" },
                React.createElement("div", { className: "pl-4 py-3.5 text-grey-400" }, "Phone number"),
                phone && (React.createElement("a", { href: `tel:${phone.replace(/\D/g, '')}`, className: "absolute right-0 pr-8 text-blue-500", id: "phone" }, phone)),
                !phone && (React.createElement("div", { className: "absolute right-0 pr-8 text-grey-500" }, "--")))),
        React.createElement(CustomerDrawerRow, { label: "Address", value: address }),
        React.createElement(CustomerDrawerRow, { label: "Address line 2", value: addressLine2 }),
        React.createElement(CustomerDrawerRow, { label: "City", value: city }),
        React.createElement(CustomerDrawerRow, { label: "State", value: (_b = (_a = State.by_value(state)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '--' }),
        React.createElement(CustomerDrawerRow, { label: "Zip Code", value: zipCode, isLastRow: isCustomerOrganization && !showOrganizationName }),
        showOrganizationName && (React.createElement(CustomerDrawerRow, { label: "Organization Name", value: organizationName, isLastRow: isCustomerOrganization })),
        !isCustomerOrganization && (React.createElement(CustomerDrawerRow, { label: "Internal ID", value: internalID, isLastRow: true }))));
};
