/**
 * The default error reporter just uses console logs in case it is somehow unset.
 */
let _ERROR_REPORTER = {
    logAndCaptureWarning: (msg, tags) => {
        console.warn(msg, tags);
    },
    logAndCaptureError: (err, msg, tags) => {
        console.error(msg, err, tags);
    }
};
export const setErrorReporter = (reporter) => {
    _ERROR_REPORTER = reporter;
};
export const getErrorReporter = () => {
    return _ERROR_REPORTER;
};
