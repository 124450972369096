var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { logAndCaptureMessage } from 'utils';
const getPaymentMethodFromInvoicePaymentMethod = (invoicePaymentMethod) => {
    switch (invoicePaymentMethod) {
        case 'saved-ach':
            return 'ach';
        case 'saved-bank':
            return 'ach';
        case 'saved-card':
            return 'card';
        case 'card':
            return 'card';
        case 'check':
            return 'check';
    }
};
export const processPaymentWithCard = (paymentMethodId, invoicePaymentMethod, payInvoiceData, invoicePricingData, saveCreditCard) => __awaiter(void 0, void 0, void 0, function* () {
    const { advertiser, invoice } = payInvoiceData;
    const customer_email = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data().email;
    if (!customer_email) {
        logAndCaptureMessage('Customer email missing in processPaymentWithCard!', {
            invoiceId: invoice.id,
            advertiserId: advertiser === null || advertiser === void 0 ? void 0 : advertiser.id
        });
        return;
    }
    const paymentMethod = getPaymentMethodFromInvoicePaymentMethod(invoicePaymentMethod);
    const req = {
        amount: invoicePricingData.totalAmount,
        customer_email,
        invoiceId: invoice.id,
        paymentMethod,
        paymentMethodId,
        saveCreditCard
    };
    const result = yield api.post('payments/pay-invoice', req);
    if (!result.success) {
        throw result.error;
    }
});
export const processPaymentWithSavedMethod = (invoicePaymentMethod, selectedSavedMethodId, payInvoiceData, invoicePricingData, user) => __awaiter(void 0, void 0, void 0, function* () {
    if (!invoicePaymentMethod.startsWith('saved-') || !user)
        return;
    const paymentMethod = getPaymentMethodFromInvoicePaymentMethod(invoicePaymentMethod);
    const { advertiser, invoice } = payInvoiceData;
    const customer_email = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data().email;
    if (!customer_email) {
        logAndCaptureMessage('Customer email missing in processPaymentWithSavedMethod!', {
            invoiceId: invoice.id,
            advertiserId: advertiser === null || advertiser === void 0 ? void 0 : advertiser.id
        });
        return;
    }
    let req;
    const initialRequest = {
        amount: invoicePricingData.totalAmount,
        customer_email,
        invoiceId: invoice.id,
        paymentMethod
    };
    if (invoicePaymentMethod === 'saved-ach') {
        req = Object.assign(Object.assign({}, initialRequest), { userId: user === null || user === void 0 ? void 0 : user.id, stripeBankAccountSourceId: selectedSavedMethodId });
    }
    else {
        // TODO: Review logic. 'saved-bank' goes through here but we're setting saveCreditCard: true
        req = Object.assign(Object.assign({}, initialRequest), { paymentMethodId: selectedSavedMethodId, saveCreditCard: true });
    }
    const result = yield api.post('payments/pay-invoice', req);
    if (!result.success) {
        throw result.error;
    }
});
