import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Avatar, Grid, IconButton } from '@material-ui/core';
import { Day } from 'lib/enums';
const styles = () => createStyles({
    daySelected: {
        backgroundColor: '#4285F4'
    },
    day: {
        backgroundColor: '#B2B8BE',
        '&:hover': {
            backgroundColor: '#4285F4',
            transition: 'background-color 0.3s ease'
        }
    },
    iconButton: {
        height: 'max-content',
        marginRight: 8
    }
});
export const getSelectedWeekdaysEnums = (deadlines) => {
    return deadlines
        .map(deadline => {
        if (deadline.publish)
            return deadline.dayEnum;
        return false;
    })
        .filter(Boolean);
};
const WeekdaySelector = ({ setSelectedDayEnumValues, selectedDayEnumValues, classes }) => {
    const allWeekDays = Day.items();
    return (React.createElement(Grid, { container: true }, allWeekDays.map((dayEnum, i) => {
        const weekdayLetter = dayEnum.label[0];
        const isSelected = selectedDayEnumValues.includes(dayEnum.value);
        return (React.createElement(IconButton, { id: `day${i}`, className: classes.iconButton, key: dayEnum.value, size: "small", onClick: () => {
                let newEnums = [...selectedDayEnumValues];
                if (!isSelected)
                    newEnums.push(dayEnum.value);
                if (isSelected) {
                    newEnums = newEnums.filter(enumValue => enumValue !== dayEnum.value);
                }
                setSelectedDayEnumValues(newEnums);
            } },
            React.createElement(Avatar, { className: isSelected ? classes.daySelected : classes.day }, weekdayLetter)));
    })));
};
export default withStyles(styles, { withTheme: true })(WeekdaySelector);
