import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { Badge } from 'components/Badge';
import { isDefaultRate } from '../ratesTableSettingsUtils';
/**
 * Header component for the e-edition drawer
 */
export default function RateDrawerHeader({ activeOrganization, rate }) {
    const [localRate, setLocalRate] = useState(rate);
    useEffect(() => {
        const unsub = rate.ref.onSnapshot(newSnapshot => {
            if (exists(newSnapshot))
                setLocalRate(newSnapshot);
        });
        return () => unsub();
    }, [rate.id]);
    return (React.createElement("div", { className: "flex items-center text-gray-800 font-semibold text-xl" },
        React.createElement("div", { className: "mr-2" }, localRate.data().description),
        isDefaultRate(activeOrganization, rate) && (React.createElement(Badge, { status: "success" }, "Default Rate"))));
}
