export const NOTICE_SYNC_EVENT = 'notices.sync';
export const NOTICE_SYNC_FAILURE_EVENT = 'notices.sync_failed';
export const NOTICE_SYNC_FAILURE_DETAIL_EVENT = 'notices.sync_failed.detail';
export const NOTICE_SYNC_RESPONSE = 'notices.sync_response';
export const NOTICE_SUBMITTED_EVENT = 'notices.submitted';
export const INVOICE_MAIL_SENT_EVENT = 'invoices.mail_sent';
export const NOTICE_AT_DEADLINE = 'notices.at_deadline';
export const NOTICE_CREATED = 'notices.created';
export const NOTICE_DATA_EXTRACTED = 'notices.data.extracted';
export const MANUAL_SYNC_REQUEST = 'notices.manual_sync_request';
export const MANUAL_BUILD_AD_REQUEST = 'notices.manual_build_ad_request';
export const NOTICE_NEWSPAPER_REQUESTED = 'notices.newspaper.requested';
export const NOTICE_CONFIRMED = 'notices.confirmed';
export const NOTICE_EDITED = 'notices.edited';
export const NOTICE_CANCELLED = 'notices.cancelled';
export const AFFIDAVIT_UPLOADED = 'notices.affidavit.uploaded';
export const CUSTOM_ID_UPDATED = 'notices.custom_id.updated';
export const AFFIDAVIT_RE_UPLOADED = 'notices.affidavit.reuploaded';
export const NOTICE_TRANSFERRED = 'notices.transferred';
export const NOTICE_AFFILIATE_CLICKED = 'notices.affiliate.clicked';
export const INVOICE_CREATED = 'invoices.created';
export const INVOICE_CANCELLED = 'invoices.cancelled';
export const INVOICE_REFUNDED = 'invoices.refunded';
export const INVOICE_RE_CREATED = 'invoices.recreated';
export const INVOICE_REMINDER_SENT = 'invoices.reminder.sent';
export const INVOICE_PAYMENT_PROCESSED = 'invoice.paid';
export const INVOICE_PAYMENT_INITIATED = 'invoice.initiated';
export const INVOICE_PAYMENT_PROCESSED_MANUAL = 'invoice.paid.manual';
export const INVOICE_PAYMENT_NOTE = 'invoice.paid.note';
export const INVOICE_PAID_OUTSIDE = 'invoice.paid_outside';
export const INVOICE_CREATION_INITIATED = 'invoice.creation.initiated';
export const INVOICE_CREATION_FAILED = 'invoice.creation.failed';
export const INVOICE_UPFRONT_PAYMENT_WAIVER = 'invoice.upfront_payment_waiver';
export const NOTICE_CANCELLED_AND_REFUNDED = 'invoices.cancelled.refunded';
export const NEWSPAPER_AT_DEADLINE = 'newspaper.at_deadline';
export const NEWSPAPER_EXTERNAL_UPLOAD = 'newspaper.external_upload';
export const DEADLINE_OVERRIDE_EVENT = 'newspaper.deadline_overriden';
export const DEADLINE_SYNC_STARTED = 'newspaper.deadline_sync_event.started';
export const DEADLINE_SYNC_FINISHED = 'newspaper.deadline_sync_event.finished';
export const AFFIDAVIT_VERIFICATION_INITIATED = 'affidavit.verification.initiated';
export const AFFIDAVIT_VERIFICATION_SUCCESS = 'affidavit.verification.success';
export const AFFIDAVIT_VERIFICATION_FAILED = 'affidavit.verification.failed';
export const AFFIDAVIT_VERIFICATION_UPLOAD_SUCCESS = 'affidavit.verification.upload.success';
export const AFFIDAVIT_VERIFICATION_UPLOAD_FAILURE = 'affidavit.verification.upload.failure';
export const AFFIDAVIT_NOTARIZATION_INITIATED = 'affidavit.notarization.initiated';
export const AFFIDAVIT_NOTARIZATION_SUCCESS = 'affidavit.notarization.success';
export const AFFIDAVIT_NOTARIZATION_FAILED = 'affidavit.notarization.failed';
export const DISPLAY_SITE_UPLOAD = 'display_site.upload';
export const SYNC_TRIGGER_EVENT_TYPES = [
    NOTICE_CONFIRMED,
    NOTICE_EDITED,
    CUSTOM_ID_UPDATED,
    NOTICE_CANCELLED,
    NOTICE_AT_DEADLINE,
    INVOICE_CREATED,
    INVOICE_PAYMENT_INITIATED,
    INVOICE_PAYMENT_PROCESSED,
    INVOICE_PAYMENT_PROCESSED_MANUAL,
    INVOICE_PAID_OUTSIDE,
    NOTICE_CREATED,
    INVOICE_UPFRONT_PAYMENT_WAIVER,
    MANUAL_SYNC_REQUEST,
    MANUAL_BUILD_AD_REQUEST
];
export const NOTICE_CONFIRMED_SYNC = `${NOTICE_CONFIRMED}.sync`;
export const NOTICE_EDITED_SYNC = `${NOTICE_EDITED}.sync`;
export const CUSTOM_ID_UPDATED_SYNC = `${CUSTOM_ID_UPDATED}.sync`;
export const NOTICE_CANCELLED_SYNC = `${NOTICE_CANCELLED}.sync`;
export const NOTICE_AT_DEADLINE_SYNC = `${NOTICE_AT_DEADLINE}.sync`;
export const INVOICE_CREATED_SYNC = `${INVOICE_CREATED}.sync`;
export const INVOICE_PAYMENT_INITIATED_SYNC = `${INVOICE_PAYMENT_INITIATED}.sync`;
export const INVOICE_PAYMENT_PROCESSED_SYNC = `${INVOICE_PAYMENT_PROCESSED}.sync`;
export const INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC = `${INVOICE_PAYMENT_PROCESSED_MANUAL}.sync`;
export const INVOICE_PAID_OUTSIDE_SYNC = `${INVOICE_PAID_OUTSIDE}.sync`;
export const NOTICE_CREATED_SYNC = `${NOTICE_CREATED}.sync`;
export const INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC = `${INVOICE_UPFRONT_PAYMENT_WAIVER}.sync`;
export const MANUAL_SYNC_REQUEST_SYNC = `${MANUAL_SYNC_REQUEST}.sync`;
export const MANUAL_BUILD_AD_REQUEST_SYNC = `${MANUAL_BUILD_AD_REQUEST}.sync`;
export const SYNC_EVENT_TYPES = [
    NOTICE_CONFIRMED_SYNC,
    NOTICE_EDITED_SYNC,
    CUSTOM_ID_UPDATED_SYNC,
    NOTICE_CANCELLED_SYNC,
    NOTICE_AT_DEADLINE_SYNC,
    INVOICE_CREATED_SYNC,
    INVOICE_PAYMENT_INITIATED_SYNC,
    INVOICE_PAYMENT_PROCESSED_SYNC,
    INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC,
    INVOICE_PAID_OUTSIDE_SYNC,
    NOTICE_CREATED_SYNC,
    INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC,
    MANUAL_SYNC_REQUEST_SYNC,
    MANUAL_BUILD_AD_REQUEST_SYNC
];
