var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { FormControl } from '@material-ui/core';
import InputMask from 'react-input-mask';
import SubmitButton from 'components/SubmitButton';
import { TimezoneType, State } from '../../lib/enums';
import SettingsHeader from './SettingsHeader';
import SelectDropdown from '../placeScroll/SelectDropdown';
const NewspaperSettingsInfo = ({ activeOrganization }) => {
    const [orgData, setOrgData] = useState({});
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isZipValid, setIsZipValid] = useState(true);
    const [saving, setSaving] = useState(false);
    const updateField = (field) => (e) => {
        if (field === 'zipCode' && e.target.value.length > 5)
            return;
        setOrgData(Object.assign(Object.assign({}, orgData), { [field]: e.target.value }));
    };
    useEffect(() => setOrgData(activeOrganization.data()), [activeOrganization]);
    useEffect(() => {
        if (orgData.phone && !/[0-9]/.test(orgData.phone))
            setOrgData(Object.assign(Object.assign({}, orgData), { phone: '' }));
        setIsPhoneValid(orgData.phone ? !orgData.phone.includes('_') : false);
    }, [orgData.phone]);
    useEffect(() => {
        const zipRegex = /[0-9]{5}/;
        setIsZipValid(zipRegex.test(orgData.zipCode));
    }, [orgData.zipCode]);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                e.preventDefault();
                if (!isPhoneValid || !isZipValid)
                    return;
                setSaving(true);
                yield activeOrganization.ref.update(orgData);
                setSaving(false);
            }) },
            React.createElement(SettingsHeader, { header: "Newspaper Info", description: "Tell us a bit more about your publication." }),
            React.createElement("div", { className: "px-4 py-5 sm:p-6" },
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "flex-auto w-12/12" },
                        React.createElement(FormControl, { required: true, fullWidth: true },
                            React.createElement("input", { className: "appearance-none rounded relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "name", required: true, autoComplete: "none", value: orgData.name, onChange: updateField('name'), placeholder: "Name of newspaper *" }))),
                    React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement("input", { min: "0", className: "hide-arrows appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "circulationSize", "data-testid": "circulation-size", type: "number", required: true, autoComplete: "none", value: orgData.circulationSize || '', onChange: updateField('circulationSize'), placeholder: "Circulation size *" })),
                        React.createElement("div", { className: "w-3 flex-grow-0" }),
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement("input", { className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "county", required: true, autoComplete: "none", value: orgData.county, onChange: updateField('county'), placeholder: "County *" }))),
                    React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement("input", { className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "address", required: true, autoComplete: "none", value: orgData.address, onChange: updateField('address'), placeholder: "Address *" })),
                        React.createElement("div", { className: "w-3 flex-grow-0" }),
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement("input", { className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "city", required: true, autoComplete: "none", value: orgData.city, onChange: updateField('city'), placeholder: "City *" }))),
                    React.createElement("div", { className: "flex w-12/12 mt-3" },
                        React.createElement("div", { className: "flex-grow flex-1" },
                            React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                                    if (selected) {
                                        setOrgData(Object.assign(Object.assign({}, orgData), { state: selected.id }));
                                    }
                                }, placeholder: "State *", options: State.items().map((state) => ({
                                    id: state.value,
                                    label: state.label
                                })), selected: State.items().filter(item => {
                                    return item.value === orgData.state
                                        ? { id: item.value, label: item.label }
                                        : '';
                                }), value: State.items().filter(item => {
                                    return item.value === orgData.state
                                        ? { id: item.value, label: item.label }
                                        : '';
                                }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', noShadow: true, required: true })),
                        React.createElement("div", { className: "w-3 flex-grow-0" }),
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement("input", { className: `relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none ${isZipValid
                                    ? 'focus:shadow-outline-blue focus:border-blue-300'
                                    : 'border-red-400'} focus:z-10 sm:text-sm sm:leading-5`, id: "zipCode", required: true, value: orgData.zipCode, name: "zipCode", autoComplete: "", onChange: updateField('zipCode'), placeholder: "Zip *" }))),
                    React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement(SelectDropdown, { id: "timezone", onChange: (selected) => {
                                    if (selected) {
                                        setOrgData(Object.assign(Object.assign({}, orgData), { iana_timezone: selected.id }));
                                    }
                                }, placeholder: "Timezone *", options: TimezoneType.items().map(tz => ({
                                    id: tz.logical,
                                    label: tz.label
                                })), selected: TimezoneType.items().filter(item => {
                                    return item.logical === orgData.iana_timezone
                                        ? { id: item.logical, label: item.label }
                                        : '';
                                }), value: TimezoneType.items().filter(item => {
                                    return item.logical === orgData.iana_timezone
                                        ? { id: item.logical, label: item.label }
                                        : '';
                                }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', noShadow: true, required: true })),
                        React.createElement("div", { className: "w-3 flex-grow-0" }),
                        React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                            React.createElement(InputMask, { mask: !/[0-9]/.test(orgData.phone) ? '' : '(999) 999-9999', alwaysShowMask: false, className: `appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5 
            ${isPhoneValid
                                    ? 'focus:shadow-outline-blue focus:border-blue-300'
                                    : 'border-red-400'}
            `, id: "phone", required: true, autoComplete: "none", value: orgData.phone, name: "phone", onChange: updateField('phone'), placeholder: "Phone *" }))))),
            React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
                React.createElement(SubmitButton, { disabled: saving || !isPhoneValid || !isZipValid, loading: saving, text: "Save", type: "submit", id: "submit" })))));
};
export default NewspaperSettingsInfo;
