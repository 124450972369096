import React from 'react';
import { AddImageIcon } from 'icons';
import { Alert } from 'components/Alert';
import { ColumnButton } from 'components/ColumnButton';
import { getFileExtension } from 'lib/helpers';
import { useDropzone } from 'react-dropzone';
import { useFirebaseStorageUpload } from 'utils/firebaseStorageHooks';
export default function FileUpload({ id, multiple = true, disabled = false, uploadFolder, acceptFileTypes, onFileUpload }) {
    const { uploadState, uploadFiles, failedUploads } = useFirebaseStorageUpload({
        uploadFolder,
        onFileUpload
    });
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: acceptFileTypes,
        onDrop: uploadFiles,
        multiple,
        disabled
    });
    const invalidFileTypeRejections = fileRejections.filter(rejection => rejection.errors.some(error => error.code === 'file-invalid-type'));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", Object.assign({}, getRootProps()),
            React.createElement(ColumnButton, { id: `${id}-button`, secondary: true, fullWidth: true, dropzone: true, disabled: disabled, loading: uploadState === 'uploading', startIcon: React.createElement(UploadIconContainer, null), buttonText: React.createElement(ButtonText, null) }),
            React.createElement("input", Object.assign({ id: id, "aria-labelledby": `${id}-button`, disabled: disabled }, getInputProps()))),
        invalidFileTypeRejections && invalidFileTypeRejections.length > 0 && (React.createElement(UnsupportedFileTypeAlert, { acceptFileTypes: acceptFileTypes, rejectedFileTypes: invalidFileTypeRejections.map(rejection => `.${getFileExtension(rejection.file.name)}`) })),
        failedUploads && failedUploads.length > 0 && (React.createElement("div", { className: "my-2" },
            React.createElement(Alert, { id: "upload-file-error", status: "error", title: `There was an error uploading ${failedUploads.length} file(s).`, description: "Please try again." })))));
}
function ButtonText() {
    return (React.createElement(React.Fragment, null,
        "Click to upload",
        React.createElement("span", { className: "text-grey-400" }, " or drag and drop")));
}
function UploadIconContainer() {
    return (React.createElement("div", { className: "rounded-full bg-white p-2 mr-2" },
        React.createElement(AddImageIcon, { className: "h-5 w-5 text-primary-400" })));
}
function UnsupportedFileTypeAlert({ acceptFileTypes, rejectedFileTypes }) {
    const acceptedFilesList = acceptFileTypes
        .replace(/\s+/g, '')
        .split(',')
        .filter(ext => ext.startsWith('.'))
        .join(', ');
    const rejectedFilesList = rejectedFileTypes.join(', ');
    return (React.createElement("div", { className: "my-2" },
        React.createElement(Alert, { id: "unsupported-file-type", status: "error", title: "File type not accepted", description: `This form does not accept these file types: ${rejectedFilesList}. Please upload one of the following file types: ${acceptedFilesList}` })));
}
