var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { logAndCaptureException } from 'utils';
import { BankAccountVerification } from 'lib/enums';
import { XIcon, DeleteIcon } from 'icons';
import api from 'api';
import TailwindModal from 'components/TailwindModal';
import { TextField, InputAdornment, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { AccountBalance, Check, Clock, ErrorOutline } from '../icons';
const CentsFormatter = props => {
    const { inputRef, onChange } = props, other = __rest(props, ["inputRef", "onChange"]);
    return (React.createElement(NumberFormat, Object.assign({}, other, { style: { cursor: 'default' }, getInputRef: inputRef, onChange: e => {
            let value = e.target.value.substr(0, 2);
            while (value.length < 2)
                value = value.concat('0');
            value = value.replace(' ', '0');
            onChange({
                target: {
                    value
                }
            });
        }, onFocus: e => {
            e.preventDefault();
            e.target.setSelectionRange(0, 0);
        }, format: "##" })));
};
const ManageDwollaAccounts = ({ closeModal, bankAccounts, isPendingTransaction, activeOrganization, showToastOnVerifyMicroDeposits }) => {
    const [showDeleteModal, setShowDeleteModal] = useState('');
    const [showSupportModal, setShowSupportModal] = useState('');
    const [loading, setLoading] = useState(false);
    // microdeposits for dwolla funding source
    const [microdeposits, setMicrodeposits] = useState([
        '00.00',
        '00.00'
    ]);
    // TODO: This type is probably a bug since we try to access ?.id on the value
    const [showConfirmDepositsModal, setShowConfirmDepositsModal] = useState();
    const [error, setError] = useState();
    const handleDelete = (account) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const result = yield api.post('dwolla/delete-funding-source', {
                bankAccountId: account.id,
                organizationId: activeOrganization.id
            });
            if (result.success) {
                setShowDeleteModal('');
            }
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to delete Dwolla funding source', {
                activeOrgId: activeOrganization.id,
                bankAccountId: account.id
            });
        }
        setLoading(false);
    });
    const handleContactSupport = (account) => window.open(`mailto:help@column.us?subject=Request to delete Dwolla funding source #${account.id}`);
    const renderStatus = (status) => {
        const statusValue = status;
        const bg = {
            yellow: 'bg-yellow-350 bg-opacity-40',
            gray: 'bg-gray-200',
            red: 'bg-red-400',
            blue: 'bg-blue-500 bg-opacity-25'
        };
        const icons = {
            check: React.createElement(Check, null),
            waiting: React.createElement(Clock, null),
            failed: React.createElement(ErrorOutline, null)
        };
        const text = {
            grayDark: 'text-gray-900',
            gray: 'text-gray-800',
            white: 'text-white',
            blue: 'text-blue-600'
        };
        let statusText;
        let statusIcon;
        let chipColor = bg.gray;
        let textColor = text.gray;
        switch (statusValue.toLowerCase()) {
            case BankAccountVerification.microdepositsPending.label.toLowerCase():
                statusText = BankAccountVerification.microdepositsPending.label;
                statusIcon = null;
                chipColor = bg.gray;
                textColor = text.gray;
                break;
            case BankAccountVerification.actionRequired.label.toLowerCase():
                statusText = BankAccountVerification.actionRequired.label;
                statusIcon = icons.waiting;
                chipColor = bg.yellow;
                textColor = text.grayDark;
                break;
            case BankAccountVerification.verified.label.toLowerCase():
                statusText = BankAccountVerification.verified.label;
                statusIcon = icons.check;
                chipColor = bg.blue;
                textColor = text.blue;
                break;
            default:
                statusText = BankAccountVerification.verificationFailed.label;
                statusIcon = icons.failed;
                chipColor = bg.red;
                textColor = text.white;
                break;
        }
        return (React.createElement("span", { className: `inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}` },
            statusIcon && React.createElement("span", { className: "mr-2" }, statusIcon),
            statusText));
    };
    const clearData = () => {
        setMicrodeposits(['00', '00']);
        setError('');
    };
    return (React.createElement("div", { className: "fixed bottom-0 md:inset-x-0 inset-0 flex items-center justify-center" },
        React.createElement("div", { className: "fixed inset-0 transition-opacity" },
            React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75" })),
        React.createElement("div", { className: `flex bg-white rounded-lg pl-10 pr-5 py-8 overflow-hidden shadow-xl transform transition-all`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement("div", { className: "font-semibold text-lg text-gray-900 mb-4 flex justify-between" }, 'Manage Account'),
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "font-normal text-sm text-gray-700 mb-8 mr-4" },
                        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: "bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, ' '),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "account nickname"),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "status"),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "bank name"),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "actions"))),
                            React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, bankAccounts &&
                                bankAccounts.map((bankAccount, index) => {
                                    return (React.createElement("tr", { key: index, onClick: () => {
                                            bankAccount.status ===
                                                BankAccountVerification.unverified.label.toLowerCase() &&
                                                setShowConfirmDepositsModal(bankAccount);
                                        }, className: bankAccount.status ===
                                            BankAccountVerification.unverified.label.toLowerCase()
                                            ? 'hover:bg-gray-100 cursor-pointer'
                                            : '' },
                                        React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                            React.createElement("div", { className: "flex items-center justify-center bg-yellow-250 w-10 h-10 rounded-circle" },
                                                React.createElement(AccountBalance, null))),
                                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, bankAccount.name || 'N/A'),
                                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                            React.createElement(React.Fragment, null, renderStatus(bankAccount.status ===
                                                BankAccountVerification.unverified.label.toLowerCase()
                                                ? 'Microdeposits Pending'
                                                : bankAccount.status))),
                                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, bankAccount.bankName),
                                        React.createElement("td", { className: "flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900" },
                                            React.createElement("div", { onClick: e => {
                                                    e.stopPropagation();
                                                    isPendingTransaction
                                                        ? setShowSupportModal(bankAccount)
                                                        : setShowDeleteModal(bankAccount);
                                                }, className: "cursor-pointer ml-4", id: `delete-${index}` },
                                                React.createElement(DeleteIcon, null)))));
                                }))),
                        !bankAccounts && (React.createElement("div", { className: "h-12 flex items-center justify-center bg-white rounded-b" }, "No records to display"))))),
            React.createElement("div", null,
                React.createElement("span", { id: "close-modal", className: "cursor-pointer", onClick: () => closeModal() },
                    React.createElement(XIcon, { className: "h-5 w-5" })))),
        showDeleteModal && (React.createElement(TailwindModal, { header: "Are you sure you want to delete this bank account?", body: 'If you click "Confirm," this bank account will be deleted from your saved payment methods on Column. Until you add a new bank account, you will not be able to see past payouts, and future payments will be made by check.', close: () => setShowDeleteModal(''), buttonText: "Confirm", onButtonClick: () => handleDelete(showDeleteModal), loading: loading, noExitOutsideModal: true })),
        showSupportModal && (React.createElement(TailwindModal, { header: "Contact Support", body: "There are pending transactions associated with this bank account. To delete this bank account from your payment methods, please email help@column.us or click the button below.", close: () => setShowSupportModal(''), buttonText: "Contact Support", onButtonClick: () => handleContactSupport(showSupportModal), loading: loading, noExitOutsideModal: true })),
        showConfirmDepositsModal && (React.createElement(TailwindModal, { header: "Confirm Microdeposits", close: () => {
                setShowConfirmDepositsModal(false);
                clearData();
            }, noExitOutsideModal: true },
            React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    setError('');
                    setLoading(true);
                    e.preventDefault();
                    const response = yield api.post('dwolla/verify-micro-deposits-funding-source', {
                        fundingSourceId: showConfirmDepositsModal === null || showConfirmDepositsModal === void 0 ? void 0 : showConfirmDepositsModal.id,
                        amounts: microdeposits.map(deposit => Number(deposit))
                    });
                    if (response.success) {
                        setShowConfirmDepositsModal(false);
                        closeModal();
                        if (showToastOnVerifyMicroDeposits) {
                            showToastOnVerifyMicroDeposits();
                        }
                    }
                    else {
                        setError(response.message);
                    }
                    setLoading(false);
                }) },
                React.createElement("div", { className: "mb-3 text-sm" }, "Two microdeposits should appear in your account within 1-3 business days of initial authorization. Please verify those here."),
                React.createElement("div", { className: "flex justify-between mb-2" },
                    React.createElement(TextField, { id: "deposit-1", style: {
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: '#4A5568',
                            width: '48%'
                        }, value: microdeposits[0], onChange: e => setMicrodeposits([e.target.value, microdeposits[1]]), InputProps: {
                            inputComponent: CentsFormatter,
                            startAdornment: (React.createElement(InputAdornment, { position: "start", style: {
                                    fontSize: '16px',
                                    lineHeight: '18px',
                                    marginTop: -1,
                                    marginRight: -0.5
                                } },
                                React.createElement(Typography, { style: { color: 'black' } }, "$0.")))
                        }, required: true, variant: "outlined", margin: "dense" }),
                    React.createElement(TextField, { id: "deposit-2", style: {
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: '#4A5568',
                            width: '48%'
                        }, value: microdeposits[1], onChange: e => setMicrodeposits([microdeposits[0], e.target.value]), InputProps: {
                            inputComponent: CentsFormatter,
                            startAdornment: (React.createElement(InputAdornment, { position: "start", style: {
                                    fontSize: '16px',
                                    lineHeight: '18px',
                                    marginTop: -1,
                                    marginRight: -0.5
                                } },
                                React.createElement(Typography, { style: { color: 'black' } }, "$0.")))
                        }, required: true, variant: "outlined", margin: "dense" })),
                React.createElement("button", { className: `rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, type: "submit", id: "verify-micro-deposits", disabled: loading },
                    React.createElement("span", { className: "flex" },
                        loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                        "Verify")),
                error && React.createElement("div", { className: "text-red-700 text-sm" }, error))))));
};
export default ManageDwollaAccounts;
