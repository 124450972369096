var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import api from 'api';
import { InvoiceStatus } from 'lib/enums';
import { exists } from 'lib/types';
import { SearchIcon, ArrowRight, ArrowLeft } from 'icons';
import LoadingState from 'components/LoadingState';
import ProcessPaymentModal from 'routes/notice/ProcessPaymentModal';
import ProcessCheckModal from 'routes/notice/ProcessCheckModal';
import EToast from 'components/EToast';
import SendReminderModal from 'routes/notice/SendReminderModal';
import ReactPaginate from 'react-paginate';
import { getFirebaseContext } from 'utils/firebase';
import { MoreIcon } from './icons';
import SettingsHeader from './SettingsHeader';
const PAGE_SIZE = 10;
const InvoiceTable = ({ pageNumber, rowsData, activeOrganization, setPageNumber, pageCount }) => {
    const [value, setValue] = useState('');
    const [actionsMenuOpenIndex, setActionsMenuOpenIndex] = useState(-1);
    const [showProcessPaymentModal, setShowProcessPaymentModal] = useState();
    const [showProcessCheckModal, setShowProcessCheckModal] = useState();
    const [showSendReminderModal, setShowSendReminderModal] = useState();
    const [toastMessage, setToastMessage] = useState('');
    const [filteredData, setFilteredData] = useState(rowsData);
    const { t } = useTranslation();
    useEffect(() => {
        if (value) {
            const results = rowsData.filter(r => {
                let match = false;
                match = match || r.advertiser.name.toLowerCase().includes(value);
                match = match || r.advertiser.orgName.toLowerCase().includes(value);
                match = match || (r.amount / 100).toFixed(2).includes(value);
                match = match || r.dueDate.toLowerCase().includes(value);
                match = match || r.initiated.toLowerCase().includes(value);
                match = match || r.status.toLowerCase().includes(value);
                return match;
            });
            setFilteredData(results);
            setPageNumber(0);
        }
        else {
            setFilteredData(rowsData);
        }
    }, [value, rowsData]);
    const getInvoiceChipColor = (status, due_date, index) => {
        if (!status) {
            return '';
        }
        // in case of invoice has no line items or amount is 0
        if (!filteredData[index].amount) {
            return 'green';
        }
        if (status === InvoiceStatus.initiated.value) {
            return 'yellow';
        }
        if (status === InvoiceStatus.unpaid.value &&
            new Date().getTime() / 1000 > due_date) {
            return 'red';
        }
        if (status === InvoiceStatus.unpaid.value) {
            return 'yellow';
        }
        if ([
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(status)) {
            return 'green';
        }
        return 'red';
    };
    const getInvoiceStatus = (status, due_date, index) => {
        var _a;
        // in case of invoice has no line items or amount is 0
        if (filteredData && filteredData.length && !filteredData[index].amount)
            return InvoiceStatus.paid.label;
        if (![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(status) &&
            new Date().getTime() / 1000 > due_date)
            return 'Past Due';
        return (_a = InvoiceStatus.by_value(status)) === null || _a === void 0 ? void 0 : _a.label;
    };
    const downloadInvoice = (e, invoiceId, filerName, organizationName) => __awaiter(void 0, void 0, void 0, function* () {
        e.stopPropagation();
        const response = yield api.post(`documents/bulk-invoice/download`, {
            invoiceId,
            filerName,
            organizationName
        });
        response.url && window.open(response.url);
    });
    if (!filteredData) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white border box-border rounded-lg" },
            React.createElement(SettingsHeader, { header: t('bulk-invoice-settings.title'), description: t('bulk-invoice-settings.subtitle') },
                React.createElement("div", { className: 'relative w-1/2 mb-2' },
                    React.createElement("div", { className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" },
                        React.createElement(SearchIcon, null)),
                    React.createElement("input", { type: "text", id: "search", value: value || '', onChange: e => {
                            setValue(e.target.value);
                        }, placeholder: `Search Invoices...`, className: "block border border-gray-300 pl-10 py-2 rounded-md w-full" }))),
            React.createElement("div", { className: `relative rounded-md flex justify-end` }),
            React.createElement("div", { className: "shadow border-b border-gray-200 sm:rounded-lg" },
                React.createElement("table", { id: "invoices-table", className: "min-w-full divide-y divide-gray-200" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Advertiser Name"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Advertiser Organization"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Initiated"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Amount"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Status"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Due Date"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Download"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, ' '))),
                    React.createElement("tbody", { className: "bg-white divide-y divide-gray-200" }, filteredData &&
                        filteredData
                            .slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE < filteredData.length
                            ? (pageNumber + 1) * PAGE_SIZE
                            : filteredData.length)
                            .map((rowData, index) => (React.createElement("tr", { key: `row-${index}`, className: "" },
                            React.createElement("td", { className: "px-6 py-4 text-left whitespace-no-wrap text-sm leading-5 text-gray-700" }, rowData.advertiser.name),
                            React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, rowData.advertiser.orgName),
                            React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, rowData.initiated),
                            React.createElement("td", { id: `invoice-amount-${index}`, className: "px-6 py-4 text-left whitespace-no-wrap text-sm leading-5 text-gray-700" }, `$${((rowData.amount -
                                (rowData.invoice.data().appliedBalance || 0)) /
                                100).toFixed(2)}`),
                            React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" },
                                React.createElement("span", { className: `text-uppercase inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-${getInvoiceChipColor(rowData.invoice.data().status, rowData.invoice.data().due_date, index)}-100 text-${getInvoiceChipColor(rowData.invoice.data().status, rowData.invoice.data().due_date, index)}-800` }, getInvoiceStatus(rowData.invoice.data().status, rowData.invoice.data().due_date, index))),
                            React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, rowData.dueDate),
                            React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" },
                                React.createElement(Tooltip, { title: rowData.amount === 0
                                        ? `The notice(s) in this invoice was voided.`
                                        : '' },
                                    React.createElement("svg", { className: `download-invoice fill-current w-4 h-4 ml-8 ${rowData.amount === 0
                                            ? 'cursor-not-allowed'
                                            : 'hover:text-gray-500 cursor-pointer'}`, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", onClick: e => rowData.amount !== 0 &&
                                            downloadInvoice(e, rowData.invoice.id, rowData.advertiser.name, rowData.advertiser.orgName) },
                                        React.createElement("path", { d: "M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" })))),
                            React.createElement("td", { className: "flex justify-center px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, ![
                                InvoiceStatus.paid.value,
                                InvoiceStatus.partially_refunded.value
                            ].includes(rowData.invoice.data().status) &&
                                rowData.amount !== 0 ? (React.createElement("div", { className: "relative cursor-pointer hover:text-gray-500", onClick: () => {
                                    if (actionsMenuOpenIndex === index)
                                        setActionsMenuOpenIndex(-1);
                                    else
                                        setActionsMenuOpenIndex(index);
                                } },
                                MoreIcon,
                                React.createElement("div", { className: `${actionsMenuOpenIndex !== index && 'hidden'} flex flex-col z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow`, role: "menu", "aria-orientation": "vertical", "aria-labelledby": "user-menu", onClick: e => e.stopPropagation() },
                                    React.createElement("div", { className: "p-3 items-center inline-flex rounded-md transition ease-in-out duration-150 cursor-pointer hover:bg-gray-50", onClick: () => {
                                            setShowSendReminderModal(rowData.invoice);
                                            setActionsMenuOpenIndex(-1);
                                        } },
                                        React.createElement("svg", { className: "w-6 h-6 mr-2 inline-block", fill: "none", stroke: "#4A5568", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" })),
                                        React.createElement("div", { className: "text-base mt-0 font-medium text-gray-700 hover:text-gray-800 leading-none" }, "Send Reminder")),
                                    React.createElement("div", { className: `cursor-pointer p-3 items-center inline-flex rounded-md transition ease-in-out duration-150 hover:bg-gray-50`, onClick: () => {
                                            setShowProcessPaymentModal(rowData.invoice);
                                            setActionsMenuOpenIndex(-1);
                                        } },
                                        React.createElement("svg", { className: "w-6 h-6 mr-2 inline-block", fill: "none", stroke: "#4A5568", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" })),
                                        React.createElement("div", { className: "text-base mt-0 font-medium text-gray-700 hover:text-gray-800 leading-none" }, "Process Payment"))))) : (''))))))),
                React.createElement("nav", { className: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" },
                    React.createElement("div", { className: "hidden sm:block" },
                        React.createElement("p", { className: "text-sm leading-5 text-gray-700" },
                            "Showing",
                            React.createElement("span", { className: "font-medium mx-1" }, filteredData.length > 0 ? pageNumber * PAGE_SIZE + 1 : 0),
                            "to",
                            React.createElement("span", { className: "font-medium mx-1" }, (pageNumber + 1) * PAGE_SIZE < filteredData.length
                                ? (pageNumber + 1) * PAGE_SIZE
                                : filteredData.length),
                            "of",
                            React.createElement("span", { className: "font-medium mx-1" }, filteredData.length),
                            "invoices")),
                    React.createElement("div", { className: "-mt-px w-0 flex-1 flex justify-end" },
                        React.createElement(ReactPaginate, { previousLabel: React.createElement(ArrowRight, null), previousClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", nextLabel: React.createElement(ArrowLeft, null), nextClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", breakLabel: '...', initialPage: pageNumber, breakClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", pageClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300", activeLinkClassName: "focus:outline-none outline-none text-blue-500 border-blue-500", pageLinkClassName: "px-4 text-sm", pageCount: filteredData
                                ? Math.ceil(filteredData.length / PAGE_SIZE)
                                : pageCount, marginPagesDisplayed: 2, pageRangeDisplayed: PAGE_SIZE, onPageChange: pageTo => {
                                setPageNumber(pageTo.selected);
                            }, containerClassName: 'pagination flex', activeClassName: 'text-blue-500 border-blue-500 outline-none' }))))),
        exists(showProcessPaymentModal) && (React.createElement(ProcessPaymentModal, { setOpen: setShowProcessPaymentModal, setProcessCheck: () => setShowProcessCheckModal(showProcessPaymentModal), invoice: showProcessPaymentModal, newspaper: activeOrganization })),
        exists(showProcessCheckModal) && (React.createElement(ProcessCheckModal, { setOpen: setShowProcessCheckModal, setToastMessage: setToastMessage, invoice: showProcessCheckModal, newspaper: activeOrganization })),
        showSendReminderModal && (React.createElement(SendReminderModal, { invoice: showSendReminderModal, newspaper: activeOrganization, setOpen: setShowSendReminderModal, setToastMessage: setToastMessage })),
        toastMessage && (React.createElement(EToast, { id: "success-toast", message: toastMessage, type: "success", close: () => {
                setToastMessage('');
            } }))));
};
const BulkInvoicesSettings = ({ activeOrganization }) => {
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rowsData, setRowsData] = useState();
    const sortRows = (row1, row2) => {
        if (row1.initiatedTimestamp < row2.initiatedTimestamp)
            return 1;
        if (row1.initiatedTimestamp > row2.initiatedTimestamp)
            return -1;
        return 0;
    };
    const loadInvoices = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setPageNumber(0);
        try {
            const query = getFirebaseContext()
                .invoicesRef()
                .where('isBulkInvoice', '==', true)
                .where('organization', '==', activeOrganization.ref);
            const invoiceSnaps = (yield query.get())
                .docs;
            let rows = [];
            // re calculate bulk invoice amount to handle any changes happened as invoices cancellations or invoices re-creation
            yield Promise.all(invoiceSnaps.map((bulkInvoice) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                const advertiserSnap = yield bulkInvoice.data().advertiser.get();
                if (!exists(advertiserSnap))
                    return;
                const { organization, organizationName } = advertiserSnap.data();
                let userOrg = null;
                if (organization) {
                    const userOrgSnap = yield organization.get();
                    if (exists(userOrgSnap)) {
                        userOrg = userOrgSnap;
                    }
                }
                let total = 0;
                for (const lineItem of bulkInvoice.data().inAppLineItems) {
                    const itemAmount = lineItem.refund
                        ? -1 * lineItem.amount
                        : lineItem.amount;
                    total += itemAmount;
                }
                const row = {
                    advertiser: {
                        name: advertiserSnap.data().name,
                        orgName: organizationName || ((_a = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _a === void 0 ? void 0 : _a.name) || ''
                    },
                    initiated: moment(bulkInvoice.data().created.toMillis())
                        .utc()
                        .format('M/D/YYYY'),
                    amount: total,
                    dueDate: moment(bulkInvoice.data().due_date * 1000)
                        .utc()
                        .format('M/D/YYYY'),
                    status: InvoiceStatus.by_value(bulkInvoice.data().status).label,
                    invoice: bulkInvoice,
                    initiatedTimestamp: bulkInvoice.data()
                        .created.toMillis()
                };
                rows.push(row);
            })));
            rows = rows.sort(sortRows);
            setRowsData(rows);
            setPageCount(Math.ceil(rows.length / PAGE_SIZE));
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to load invoices', {
                orgId: activeOrganization.id
            });
        }
        setLoading(false);
    });
    useEffect(() => {
        void loadInvoices();
    }, []);
    return (React.createElement(React.Fragment, null, loading || !rowsData ? (React.createElement(React.Fragment, null)) : (React.createElement(InvoiceTable, { activeOrganization: activeOrganization, rowsData: rowsData, pageNumber: pageNumber, pageCount: pageCount, setPageNumber: setPageNumber }))));
};
export default BulkInvoicesSettings;
