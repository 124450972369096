var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
/**
 * Helper to get a list of all bank accounts that a user can access.
 *
 * @param user the active user.
 * @param organization if specified this overrides the user's active organization
 * as the organization to use for locating bank accounts.
 */
export const getUserPaymentMethods = (user, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const org = organization !== null && organization !== void 0 ? organization : user.data().activeOrganization;
    const req = org
        ? { type: 'organization', organizationId: org.id }
        : { type: 'individual', userId: user.id };
    const res = yield api.post('payments/list-customer-payment-methods', req);
    if (res.success !== true) {
        throw new Error(res.error);
    }
    /**
     * TODO: IT-4394
     * Make a gateway agnostic type for bank accounts and consolidate old vs. new Stripe bank accounts
     * */
    return {
        cards: res.cards,
        bankAccounts: res.bank_accounts_sources,
        bankAccountsPaymentMethods: res.bank_accounts_payment_methods
    };
});
