var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import NumberFormat from 'react-number-format';
const PercentFormatter = (_a) => {
    var { inputRef, onChange } = _a, other = __rest(_a, ["inputRef", "onChange"]);
    return (React.createElement(NumberFormat, Object.assign({}, other, { getInputRef: inputRef, onValueChange: values => {
            onChange({
                target: {
                    value: values.value
                }
            });
        }, thousandSeparator: true, decimalScale: 2, suffix: " %", fixedDecimalScale: true, isAllowed: values => {
            const { formattedValue, floatValue } = values;
            return formattedValue === '' || floatValue <= 100;
        } })));
};
export default PercentFormatter;
