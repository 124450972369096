import React, { useState } from 'react';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
const VerifyMicrodepositsModal = ({ onVerifyClicked, CentsFormatter, loading }) => {
    const [microdeposits, setMicrodeposits] = useState(['00', '00']);
    return (React.createElement("div", null,
        React.createElement("div", { className: "mb-3 text-sm" }, "Two microdeposits should appear in your account within 1-2 business days of initial authorization. Please verify those here."),
        React.createElement("div", { className: "flex justify-between mb-2" },
            React.createElement(TextField, { id: "deposit-1", style: {
                    fontSize: '16px',
                    lineHeight: '18px',
                    color: '#4A5568',
                    width: '48%'
                }, value: microdeposits[0], onChange: e => setMicrodeposits([e.target.value, microdeposits[1]]), InputProps: {
                    inputComponent: CentsFormatter,
                    startAdornment: (React.createElement(InputAdornment, { position: "start", style: {
                            fontSize: '16px',
                            lineHeight: '18px',
                            marginTop: -1,
                            marginRight: -0.5
                        } },
                        React.createElement(Typography, { style: { color: 'black' } }, "$0.")))
                }, required: true, variant: "outlined", margin: "dense" }),
            React.createElement(TextField, { id: "deposit-2", style: {
                    fontSize: '16px',
                    lineHeight: '18px',
                    color: '#4A5568',
                    width: '48%'
                }, value: microdeposits[1], onChange: e => setMicrodeposits([microdeposits[0], e.target.value]), InputProps: {
                    inputComponent: CentsFormatter,
                    startAdornment: (React.createElement(InputAdornment, { position: "start", style: {
                            fontSize: '16px',
                            lineHeight: '18px',
                            marginTop: -1,
                            marginRight: -0.5
                        } },
                        React.createElement(Typography, { style: { color: 'black' } }, "$0.")))
                }, required: true, variant: "outlined", margin: "dense" })),
        React.createElement("button", { className: `rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, type: "submit", id: "verify-micro-deposits", disabled: loading, onClick: onVerifyClicked(microdeposits) },
            React.createElement("span", { className: "flex" },
                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                "Verify"))));
};
export default VerifyMicrodepositsModal;
