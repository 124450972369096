import moment from 'moment-timezone';
export const nDaysAgo = (n) => {
    const now = new Date();
    const pastDate = now.getDate() - n;
    now.setDate(pastDate);
    return now;
};
export const endOfDay = () => {
    const m = moment().endOf('day');
    m.hour(17);
    return m.utc().toDate();
};
/**
 * Add weekdays to a date by skipping Saturdays and Sundays.
 * This is a rough approximation of business days although it does not
 * account for bank holidays.
 */
export const addWeekdays = (date, num) => {
    const m = moment(date);
    let daysToAdd = num;
    while (daysToAdd > 0) {
        m.add(1, 'd');
        // 0 is Sunday, 6 is Saturday
        if (m.day() !== 0 && m.day() !== 6) {
            daysToAdd--;
        }
    }
    return m.toDate();
};
export const dateTimeLikeToDate = (d) => {
    if (!d)
        return null;
    if (d['.sv'])
        return null;
    return d ? (d.toDate ? d.toDate() : d) : null;
};
export const dateTimeLikeToTimestamp = (d) => {
    if (!d)
        return null;
    if (d.toDate)
        return d;
    return { toDate: () => d };
};
