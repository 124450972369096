export const getBeacon = () => {
    return window.Beacon;
};
export const setupBeacon = () => {
    var _a, _b;
    const scriptTag0 = document.createElement('script');
    scriptTag0.type = 'text/javascript';
    scriptTag0.async = true;
    scriptTag0.innerText =
        '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';
    const scriptTag1 = document.createElement('script');
    scriptTag1.type = 'text/javascript';
    scriptTag1.async = true;
    scriptTag1.innerText =
        "window.Beacon('init', 'de7afdd6-c3c9-4825-a4df-10fcd06f92e8')";
    const s = document.getElementsByTagName('script')[0];
    (_a = s.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(scriptTag0, s);
    (_b = s.parentNode) === null || _b === void 0 ? void 0 : _b.insertBefore(scriptTag1, s);
};
