import React, { useCallback } from 'react';
import { dispatchValidationEvent } from '../helpers/inputValidation';
export function Form({ id, children, nativeValidate = false, onSubmit }) {
    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        const formElement = event.target;
        const valid = nativeValidate ? true : formElement.checkValidity();
        valid
            ? onSubmit(event)
            : dispatchValidationEvent({ toForm: formElement });
    }, [onSubmit]);
    return (React.createElement("form", { id: id, noValidate: !nativeValidate, onSubmit: handleSubmit }, children));
}
