var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { CalendarIcon, ExclamationCircle, SquareUpRight, DeleteIcon } from 'icons';
import { getClosestPastPublishingDayForNewspaper } from 'lib/utils/deadlines';
import { getDatesAvailableForAffidavitUpload } from 'lib/utils/affidavits';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import { guidGenerator, logAndCaptureException, sanitize } from 'utils';
import Firebase from 'EnoticeFirebase';
import api from 'api';
import CTooltip from 'components/Tooltip';
import { getFirebaseContext } from 'utils/firebase';
const UPLOAD_FILE_MESSAGE = 'Uploading...';
const DELETE_FILE_MESSAGE = 'Deleting...';
function EEditionUploader({ setShowEUploadSuccessModal, activeOrganization, fixedUploadDate, setOpen, id }) {
    const [fileToUpload, setFileToUpload] = useState();
    const [fileLoadingMessage, setFileLoadingMessage] = useState('');
    const [error, setError] = useState('');
    const [uploadedSnapshot, setUploadedSnapshot] = useState();
    const [snapshotUrl, setSnapshotUrl] = useState('');
    const [publicationDate, setPublicationDate] = useState(fixedUploadDate);
    const [availablePubDates, setAvailablePubDates] = useState();
    const dropzoneRef = useRef();
    const { iana_timezone } = activeOrganization.data();
    const shouldDisableDate = (date) => {
        const dateHasOutstandingNotices = (availablePubDates === null || availablePubDates === void 0 ? void 0 : availablePubDates.length) &&
            availablePubDates.some(pd => moment(pd).isSame(moment(date), 'day'));
        const dateIsInFuture = moment(date).startOf('day').isAfter(moment());
        return !dateHasOutstandingNotices || dateIsInFuture;
    };
    const uploadFile = (file) => __awaiter(this, void 0, void 0, function* () {
        if (!file)
            return;
        setFileLoadingMessage(UPLOAD_FILE_MESSAGE);
        const uniqueFileNameComponents = file.name.split('.');
        // Add a unique identifier to this upload
        uniqueFileNameComponents.splice(-1, 0, guidGenerator().slice(0, 8));
        const uniqueFileName = uniqueFileNameComponents.join('.');
        let fileSnapshot;
        try {
            fileSnapshot = yield Firebase.storage()
                .ref()
                .child(`e-editions/${activeOrganization.id}/manual_${sanitize(uniqueFileName)}`)
                .put(file);
        }
        catch (err) {
            setError('We are unable to upload your file at this time. Please try again.');
            logAndCaptureException(err, 'Unable to upload e-edition', {
                newspaperId: activeOrganization.id,
                fileName: uniqueFileName
            });
            /* If the file is not in the process of being deleted
            (if the user has not hit the delete button before the
            file finished uploading), then we should remove
            the current loading message. If, however, the file
            is in the process of being deleted, we should keep
            the deletion message in the UI until the deletion
            is complete. */
            if (fileLoadingMessage !== DELETE_FILE_MESSAGE) {
                setFileLoadingMessage('');
            }
            return;
        }
        let downloadUrl;
        try {
            downloadUrl = yield fileSnapshot.ref.getDownloadURL();
            setSnapshotUrl(downloadUrl);
        }
        catch (err) {
            logAndCaptureException(err, 'Unable to get download URL for e-edition', {
                newspaperId: activeOrganization.id,
                fileName: fileSnapshot.ref.name
            });
        }
        setFileLoadingMessage('');
        setUploadedSnapshot(fileSnapshot);
    });
    const handleFileDrop = (files) => __awaiter(this, void 0, void 0, function* () {
        const fileToUse = files[0];
        setFileToUpload(fileToUse);
        yield uploadFile(fileToUse);
    });
    const handleDeleteFile = () => __awaiter(this, void 0, void 0, function* () {
        if (!fileToUpload && !uploadedSnapshot && !fileLoadingMessage)
            return;
        setFileLoadingMessage(DELETE_FILE_MESSAGE);
        setFileToUpload(undefined);
        setError('');
        const previousUploadedSnapshot = uploadedSnapshot;
        /* We have to set the uploadedSnapshot state variable to undefined
        before actually deleting the ref so that we know throughout
        the rest of the component to treat the file as deleted */
        setUploadedSnapshot(undefined);
        if (previousUploadedSnapshot) {
            try {
                yield previousUploadedSnapshot.ref.delete();
            }
            catch (err) {
                logAndCaptureException(err, 'Unable to delete e-edition', {
                    newspaperId: activeOrganization.id,
                    fileName: previousUploadedSnapshot.ref.name
                });
            }
        }
        setFileLoadingMessage('');
    });
    const handlePopOut = () => __awaiter(this, void 0, void 0, function* () {
        if (!uploadedSnapshot) {
            setError('We are unable to find the uploaded e-edition. Please try uploading your file again.');
            return;
        }
        let url;
        try {
            if (snapshotUrl) {
                url = snapshotUrl;
            }
            else {
                url = yield uploadedSnapshot.ref.getDownloadURL();
            }
        }
        catch (err) {
            setError('We are unable to load your uploaded e-edition. Please try uploading your file again.');
            return;
        }
        window.open(url);
    });
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        const formattedDate = moment(publicationDate).format('MM/DD/YYYY');
        try {
            const response = yield api.post('affidavit-automation/initiate-stak-verification', {
                newspaperId: activeOrganization.id,
                eEditionURL: snapshotUrl,
                date: formattedDate
            });
            if (!response.success) {
                logAndCaptureException(new Error('Received error from Stak verification API endpoint'), 'Received error from Stak verification API endpoint', {
                    newspaperId: activeOrganization.id,
                    eEditionURL: snapshotUrl,
                    date: formattedDate
                });
                setError('We could not initiate affidavit automation. Please confirm that all notices for the selected publication date have invoices, and try again.');
                return;
            }
        }
        catch (err) {
            logAndCaptureException(err, 'Unable to initiate Stak verification', {
                newspaperId: activeOrganization.id,
                eEditionURL: snapshotUrl,
                date: formattedDate
            });
            setError('We could not initiate affidavit automation. Please confirm that all notices for the selected publication date have invoices, and try again.');
            return;
        }
        setOpen(false);
        setShowEUploadSuccessModal(true);
    });
    /**
     * If the user has not selected a publication date, we should
     * set the publication date to the most recent publication date
     */
    useEffect(() => {
        // If the user has already selected a publication date, we should not change it
        if (fixedUploadDate)
            return;
        const setDatesWithOutstandingNotices = () => __awaiter(this, void 0, void 0, function* () {
            const allowedDates = yield getDatesAvailableForAffidavitUpload(getFirebaseContext(), activeOrganization);
            setAvailablePubDates(allowedDates);
        });
        void setDatesWithOutstandingNotices();
        const mostRecentPublicationDate = getClosestPastPublishingDayForNewspaper(activeOrganization);
        if (mostRecentPublicationDate) {
            setPublicationDate(mostRecentPublicationDate);
        }
    }, [activeOrganization]);
    return (React.createElement(CancelOrSubmitModal, { setOpen: setOpen, body: "We will start processing affidavits once you submit your e-edition and a publication date.", header: `Upload E-edition for ${activeOrganization.data().name}`, width: "md:max-w-xl", id: id, primaryButtonText: "Submit e-edition", tertiaryButtonText: "Cancel", onSubmit: handleSubmit, disablePrimaryButton: !publicationDate || !uploadedSnapshot || !fileToUpload || !!error, showLoadingSpinner: true },
        React.createElement("div", { className: "flex-col space-y-6 py-6" },
            React.createElement(Dropzone, { accept: '.pdf', onDropAccepted: (file) => __awaiter(this, void 0, void 0, function* () {
                    setError('');
                    yield handleFileDrop(file);
                }), onDropRejected: () => setError('The file type you selected is not accepted. Please upload a PDF.') }, ({ getRootProps, getInputProps }) => (React.createElement("div", Object.assign({ className: "w-full" }, getRootProps(), { onClick: () => {
                    var _a;
                    dropzoneRef && ((_a = dropzoneRef.current) === null || _a === void 0 ? void 0 : _a.click());
                } }),
                React.createElement("div", { className: "flex justify-center items-center px-6 pt-5 pb-6 border border-dashed rounded-md h-36 bg-blue-50 cursor-pointer", style: { borderColor: '#6CB9E6' } },
                    React.createElement("div", { className: "flex flex-col items-center text-center" },
                        React.createElement("svg", { className: "mx-auto my-3 h-16 w-16 text-blue-400 rounded-full bg-white p-3", stroke: "currentColor", fill: "none", viewBox: "0 0 72 72" },
                            React.createElement("path", { d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02", strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round", transform: "translate(12, 14)" })),
                        React.createElement("input", Object.assign({ id: "e-edition-input" }, getInputProps(), { ref: dropzoneRef })),
                        React.createElement("p", { className: "text-xs" },
                            React.createElement("span", { className: "text-blue-500" }, "Click to upload"),
                            " or drag and drop")))))),
            error && (React.createElement("div", { className: "text-sm" },
                React.createElement("div", { className: "flex flex-row mt-2 space-x-2" },
                    React.createElement("div", { className: "flex flex-row items-center rounded-md bg-red-50 align-middle min-h-11 w-full space-x-2 py-1.5 pr-3" },
                        React.createElement("div", { className: "flex flex-row items-center" },
                            React.createElement(ExclamationCircle, { className: "text-red-600 h-8 w-8 pl-3" })),
                        React.createElement("div", { className: "text-red-600 flex items-center" }, error))))),
            fileToUpload && (React.createElement("div", { className: "text-sm" },
                React.createElement("div", { className: "text-grey-300" }, "E-EDITION"),
                fileLoadingMessage && (React.createElement("div", { className: "flex flex-row items-center mt-2 space-x-2" },
                    React.createElement("div", { className: "flex flex-row rounded-md border border-grey-100 bg-gray-50 align-middle min-h-11 w-full space-x-2 py-3" },
                        React.createElement("div", { className: "flex pl-3 items-center justify-center rounded-b" },
                            React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5" })),
                        React.createElement("div", { className: "text-grey-400 flex items-center" }, fileLoadingMessage)),
                    React.createElement(DeleteIcon, { className: "bg-red-50 h-11 w-12 rounded-md text-red-600 p-2 cursor-pointer", onClick: handleDeleteFile }))),
                !fileLoadingMessage && uploadedSnapshot && (React.createElement("div", { className: "flex flex-row items-center mt-2 space-x-2" },
                    React.createElement("div", { className: "border border-grey-100 align-middle rounded-md bg-gray-50 text-grey-400 flex items-center pl-3.5 min-h-11 w-full py-3" }, fileToUpload.name),
                    React.createElement(SquareUpRight, { className: "bg-blue-50 h-11 w-12 rounded-md text-blue-500 p-2 cursor-pointer", onClick: handlePopOut }),
                    React.createElement(DeleteIcon, { className: "bg-red-50 h-11 w-12 rounded-md text-red-600 p-2 cursor-pointer", onClick: handleDeleteFile }))))),
            React.createElement("div", { className: "text-sm" },
                React.createElement("div", null, "Publication date"),
                React.createElement(CTooltip, { helpText: !fixedUploadDate && (!uploadedSnapshot || !fileToUpload)
                        ? `Please upload your e-edition before selecting a publication date.`
                        : '', id: "e-edition-uploader-datepicker-tooltip" },
                    React.createElement("div", { className: "border border-grey-200 rounded-md mt-2" },
                        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                            React.createElement(DatePicker, { label: "", value: publicationDate || moment().tz(iana_timezone).toDate(), placeholder: "MMM dd, YYYY", format: "MMM dd, YYYY", className: 'text-xs w-full', InputProps: {
                                    disableUnderline: true,
                                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(CalendarIcon, { className: "text-grey-300" }))),
                                    className: 'px-3.5 py-1.5',
                                    style: {
                                        fontSize: '14px'
                                    }
                                }, onChange: date => {
                                    setError('');
                                    if (iana_timezone && date) {
                                        setPublicationDate(moment(date.getTime()).tz(iana_timezone).toDate());
                                    }
                                }, shouldDisableDate: shouldDisableDate, disabled: Boolean(fixedUploadDate) ||
                                    !uploadedSnapshot ||
                                    !fileToUpload }))))))));
}
export default EEditionUploader;
