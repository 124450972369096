import React from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import CurrencyFormatter from 'components/CurrencyFormatter';
import { InvoiceFormCell } from './InvoiceFormCell';
export function InvoiceFormLineItemRow({ lineItem, index, currency, handleChange, total }) {
    const { amount, date, description } = lineItem;
    const descriptionValue = description || moment(date.toString()).format('MM/DD/YY');
    const defaultSum = `${currency}0.00`;
    return (React.createElement("tr", null,
        React.createElement(InvoiceFormCell, null, "1"),
        React.createElement(InvoiceFormCell, null, descriptionValue),
        React.createElement(InvoiceFormCell, null,
            React.createElement(TextField, { value: amount || defaultSum, onChange: handleChange(index), id: "formatted-numberformat-input", InputProps: {
                    style: {
                        fontSize: '14px',
                        width: '70px'
                    },
                    inputComponent: CurrencyFormatter
                } })),
        React.createElement(InvoiceFormCell, null,
            React.createElement(NumberFormat, { placeholder: defaultSum, value: total || defaultSum, thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: 'text' }))));
}
