import urlRegex from 'url-regex';
import moment from 'moment';
export const phoneRegex = new RegExp(/^((\+[0-9]{1,2}\s)?\(?[0-9]{3}\)?[\s.-][0-9]{3}[\s.-][0-9]{4}|[0-9]{10})$/);
export const zipRegex = /^([0-9]{5})$/;
export const websiteRegex = urlRegex({ exact: true, strict: false });
export const numeric = /^[0-9]*$/;
export const MAX_ZIP_LENGTH = 5;
export const MAX_PHONE_LENGTH = 10;
export const creditCard = (newValue) => newValue.length <= 16 && numeric.test(newValue);
export const cvc = (newValue) => newValue.length <= 4 && numeric.test(newValue);
export const zip = (newValue) => newValue.length <= MAX_ZIP_LENGTH && numeric.test(newValue);
export const zipAdvertiser = (newValue) => newValue.length <= MAX_ZIP_LENGTH && zipRegex.test(newValue);
export const phone = (newValue) => newValue.length <= MAX_PHONE_LENGTH && phoneRegex.test(newValue);
export const expirationDate = (newValue) => {
    // Input date string is in format MMYY
    // Credit cards expire at the end of the month
    const currDate = moment().toDate().getTime();
    const expDate = moment(newValue, 'MMYY').endOf('month').toDate().getTime();
    return expDate >= currDate;
};
export const circulation = (newValue) => numeric.test(newValue);
