import { AddImageIcon, FilledCircleIcon } from 'icons';
import React, { useRef } from 'react';
import Dropzone from 'react-dropzone';
const PdfSection = ({ pdfUploadLoading, setPdfFile, pdfFile, orgPdfUrl, pdfError }) => {
    const dropzoneRef = useRef();
    return (React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex flex-col w-2/6" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Newspaper PDF upload*"),
            React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Upload a PDF file of your newspaper. This will be used for the header image of your public campaign site.")),
        React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-4/6" },
            pdfUploadLoading ? (React.createElement("div", { className: "flex justify-center items-center w-4/6 px-6 border-2 border-gray-300 border-dashed rounded-md h-32" },
                React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }))) : (React.createElement(Dropzone, { onDrop: files => {
                    setPdfFile(files[0]);
                }, accept: '.pdf' }, ({ getRootProps, getInputProps }) => (React.createElement("div", Object.assign({ className: "w-4/6" }, getRootProps(), { onClick: () => {
                    var _a;
                    dropzoneRef && ((_a = dropzoneRef.current) === null || _a === void 0 ? void 0 : _a.click());
                } }),
                React.createElement("div", { className: `flex justify-center items-center px-6 border-2 border-dashed rounded-md h-32 ${!pdfFile && !orgPdfUrl && pdfError
                        ? 'border-red-600'
                        : 'border-gray-300'}` },
                    React.createElement("div", { className: "flex flex-col items-center text-center" },
                        pdfFile || orgPdfUrl ? (React.createElement(FilledCircleIcon, { className: "mb-2 text-blue-150" })) : (React.createElement(AddImageIcon, { className: "mx-auto h-12 w-12 text-gray-400" })),
                        React.createElement(React.Fragment, null,
                            React.createElement("p", { className: "mt-1 mr-1 text-sm text-gray-600" },
                                React.createElement("button", { type: "button", className: "mr-1 font-medium text-indigo-400 focus:outline-none focus:underline transition duration-150 ease-in-out" },
                                    "Upload ",
                                    pdfFile || orgPdfUrl ? 'another ' : 'a '),
                                "PDF file or drag and drop")),
                        React.createElement("input", Object.assign({ id: "header-image-upload" }, getInputProps(), { ref: dropzoneRef })))))))),
            !pdfFile && !orgPdfUrl && pdfError && (React.createElement("span", { className: "text-xs text-red-600 ml-1" }, "Please upload a Pdf file of your newspaper.")))));
};
export default PdfSection;
