import React from 'react';
const CButton = ({ id, children, className, disabled, onClick, onMouseLeave, startIcon, endIcon, startClasses, middleClasses, endClasses, title }) => {
    return (React.createElement("button", { id: id, "data-testid": id, className: className, disabled: !!disabled, title: title, onClick: onClick
            ? (e) => onClick(e)
            : () => { }, onMouseLeave: onMouseLeave ? () => onMouseLeave() : () => { } },
        React.createElement("section", { className: "grid grid-cols-12" },
            React.createElement("article", { className: startClasses || 'col-span-2' }, startIcon),
            React.createElement("article", { className: middleClasses || 'col-span-8' }, children),
            React.createElement("article", { className: endClasses || 'col-span-2' }, endIcon))));
};
export default CButton;
