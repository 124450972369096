var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import SubmitButton from 'components/SubmitButton';
import LoadingState from 'components/LoadingState';
import SettingsHeader from '../../SettingsHeader';
import AdvertisingDeadlinesForm from './AdvertisingDeadlinesForm';
import DeadlineOverrides from './DeadlineOverrides';
const styles = (theme) => createStyles({
    header: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(2)
    },
    saveButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%'
    }
});
const AdvertisingDeadlinesSettings = ({ activeOrganization }) => {
    const [loading, setLoading] = useState(true);
    const [deadlines, setDeadlines] = useState(activeOrganization.data().deadlines);
    const [advertiserEditingEnabled, setAdvertiserEditingEnabled] = useState(!!activeOrganization.data().advertiserEditingEnabled);
    const [saving, setSaving] = useState(false);
    const init = () => {
        setDeadlines(activeOrganization.data().deadlines);
        setAdvertiserEditingEnabled(!!activeOrganization.data().advertiserEditingEnabled);
        setLoading(false);
    };
    useEffect(() => {
        init();
    }, [activeOrganization]);
    const areDeadlinesValid = () => deadlines === null || deadlines === void 0 ? void 0 : deadlines.reduce((acc, cur) => acc || cur.publish, false);
    const handleSave = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (!areDeadlinesValid())
            return;
        setSaving(true);
        yield activeOrganization.ref.update({
            deadlines,
            advertiserEditingEnabled
        });
        setSaving(false);
    });
    if (loading)
        return React.createElement(LoadingState, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
            React.createElement(SettingsHeader, { header: "Publication Deadlines", description: "Select your newspaper publication days and ad deadlines." }),
            React.createElement("div", { className: "w-full" },
                React.createElement("form", { onSubmit: handleSave },
                    React.createElement("div", { className: "w-full" },
                        React.createElement(AdvertisingDeadlinesForm, { deadlines: deadlines, advertiserEditingEnabled: advertiserEditingEnabled, setAdvertiserEditingEnabled: setAdvertiserEditingEnabled, setDeadlines: setDeadlines })),
                    React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
                        React.createElement(SubmitButton, { disabled: saving || !areDeadlinesValid(), loading: saving, text: "Save", type: "submit", id: "submit" }))))),
        React.createElement(DeadlineOverrides, { newspaper: activeOrganization })));
};
export default withStyles(styles, { withTheme: true })(AdvertisingDeadlinesSettings);
