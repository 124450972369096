import React from 'react';
export const SmilingFaceWithSmilingEyes = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Smiling face with smiling eyes" }, "\uD83D\uDE0A"));
};
export const PartyPopper = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Celebrate" }, "\uD83C\uDF89"));
};
export const MoneyWithWings = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Money with wings" }, "\uD83D\uDCB8"));
};
export const Rocket = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Rocket" }, "\uD83D\uDE80"));
};
export const AlarmClock = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Alarm Clock" }, "\u23F0"));
};
export const CancelledAndNoEntry = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Cancel" }, "\u26D4"));
};
export const PageFacingUp = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Page facing up" }, "\uD83D\uDCC4"));
};
export const MoneyBag = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Money bag" }, "\uD83D\uDCB0"));
};
export const Memo = () => {
    return (React.createElement("span", { className: "ml-1", role: "img", "aria-label": "Edit" }, "\uD83D\uDCDD"));
};
export const OpenMailboxWithLoweredFlag = () => {
    return (React.createElement("span", { role: "img", "aria-label": 'Open mailbox with lowered flag' }, "\uD83D\uDCED"));
};
