var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { push } from 'connected-react-router';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { AccessTime } from '@material-ui/icons';
import { Paper, Avatar, Typography, Divider, Box, Button, SvgIcon, CircularProgress, Tooltip } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getOrganizationGateway } from 'lib/billing/gateways';
import { exists } from 'lib/types';
import { InvoiceStatus, CurrencyType } from 'lib/enums';
import { cdnIfy } from 'lib/helpers';
import TailwindModal from 'components/TailwindModal';
import LoadingState from 'components/LoadingState';
import CTooltip from 'components/Tooltip';
import api from 'api';
import { getUserPaymentMethods } from 'utils/sources';
import { getRecipientName } from 'lib/utils/invoices';
import { getFirebaseContext } from 'utils/firebase';
import { getInvoiceTotalSubtotalAndFees } from 'lib/pricing';
import { logAndCaptureMessage, logAndCaptureException } from 'utils';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PAYWAY, STRIPE } from 'lib/constants';
import { BROWSER, BROWSERS, STRIPE_VARS } from '../../../../constants';
import { PaidState, VoidedState, PendingState } from './InvoiceStates';
import StripeCheckoutForm from './StripeCheckoutForm';
import PaywayCheckoutForm from './PaywayCheckoutForm';
import InvoiceTable from './InvoiceTable';
import { reload } from '../../../../utils/reload';
import NewPayInvoice from '../payInvoice/PayInvoice';
// loading stripe
const stripePromise = loadStripe(STRIPE_VARS.key);
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
const mapStateToProps = (state) => ({
    user: state.auth.user
});
const styles = (theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(12),
        [theme.breakpoints.up(300 + theme.spacing(6))]: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    container: {
        display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        backgroundColor: '#F9FAFB' // background color of pay invoice screen
    },
    paper: {
        flexGrow: 6
    },
    leftBigBox: {
        position: 'absolute',
        width: theme.spacing(30),
        height: theme.spacing(40),
        background: '#EBF5FB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(30),
        left: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    leftSmallBox: {
        position: 'absolute',
        width: theme.spacing(25),
        height: theme.spacing(35),
        background: '#2D9BDB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(40),
        left: theme.spacing(5),
        opacity: '0.1',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    rightBigBox: {
        position: 'absolute',
        width: theme.spacing(30),
        height: theme.spacing(40),
        background: '#EBF5FB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(10),
        right: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    rightSmallBox: {
        position: 'absolute',
        width: theme.spacing(25),
        height: theme.spacing(35),
        background: '#2D9BDB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(20),
        right: '0px',
        opacity: '0.1',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    avatar: {
        margin: theme.spacing(1),
        position: 'absolute',
        marginTop: theme.spacing(-4),
        width: theme.spacing(8),
        height: theme.spacing(8),
        boxShadow: '4',
        '& img': {
            height: '40px',
            width: '45px'
        }
    },
    invoiceStateSection: {
        width: '100%',
        backgroundColor: '#FAFCFE' // adds a light gray to where we have our payment methods listed
    },
    flex: {
        display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    linkAccount: {
        backgroundColor: 'white',
        color: '#4A5568',
        width: '100%',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f2f2f2' // changes the background to a slight gray when hovering over
        }
    },
    linkAccountDisabled: {
        backgroundColor: '#c7c7c7',
        color: '#4A5568',
        width: '100%',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f2f2f2' // changes the background to a slight gray when hovering over
        },
        cursor: 'not-allowed'
    },
    AwaitingBankAccount: {
        backgroundColor: '#FFCC40',
        color: '#4A5568',
        width: '100%',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ffcf4d' // changes to a slighly different yellkow
        }
    },
    cardButton: {
        backgroundColor: 'white',
        textTransform: 'none',
        paddingRight: '92px',
        marginRight: '7px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400
    },
    cardButtonDisabled: {
        backgroundColor: '#c7c7c7',
        textTransform: 'none',
        paddingRight: '92px',
        marginRight: '7px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400,
        cursor: 'not-allowed'
    },
    checkButton: {
        backgroundColor: 'white',
        textTransform: 'none',
        paddingRight: '92px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400
    },
    checkButtonDisabled: {
        backgroundColor: '#c7c7c7',
        textTransform: 'none',
        paddingRight: '92px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400,
        cursor: 'not-allowed'
    },
    payInvoice: {
        backgroundColor: '#093377',
        textTransform: 'none',
        width: '100%',
        color: 'white',
        border: '1px solid #4285F4',
        marginTop: '13px',
        '&:hover': {
            backgroundColor: '#0b3d8e' // gives the pay invoice button a lighter dark blue background than above
        },
        '&:disabled': {
            color: '#FFFFFF' // makes the pay invoice button white if it is disabled
        }
    },
    checkBox: {
        background: '#FFFFFF',
        border: '1px solid #DADADA',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 3px rgba(230, 235, 241, 0.25)',
        borderRadius: '4px',
        width: '100%'
    },
    checkText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: '#3C4257' // gives the check box dark gray text
    },
    footerText: {
        fontSize: '14px',
        color: '#697386' // gives the footer text a dark gray text
    },
    pastDue: {
        backgroundColor: '#FFC400',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1), inset 0px 1px 6px rgba(0, 0, 0, 0.1)',
        color: '#502B06',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '28px',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    bankAccountBox: {
        border: '1px solid #DADADA',
        boxSizing: 'border-box',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        backgroundColor: 'white',
        justifyContent: 'space-between'
    },
    achBadge: {
        fontWeight: 'bold',
        fontSize: '9px',
        lineHeight: '20px',
        textAlign: 'center',
        alignSelf: 'center',
        color: 'white',
        textTransform: 'uppercase',
        background: '#4A5568',
        borderRadius: '8px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    bankText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '28px',
        textAlign: 'center',
        color: '#4A5568' // makes the text color  dark gray
    },
    bold: {
        fontWeight: 'bold'
    },
    gotoText: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#697386' // gives the go to my text a dark gray color
    },
    modalButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
});
const PayInvoice = ({ classes, push, user }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [withCard, setWithCard] = useState(false);
    const [withCheck, setWithCheck] = useState(false);
    const [invoice, setInvoice] = useState();
    const [advertiser, setAdvertiser] = useState();
    const [billingName, setBillingName] = useState();
    const [customerToSaveCardOnName, setCustomerToSaveCardOnName] = useState();
    const [totalAmount, setTotalAmount] = useState();
    // TODO: This type is likely a bug, the types of these line items don't perfectly overlap.
    const [inAppLineItems, setInAppLineItems] = useState();
    const [processingFee, setProcessingFee] = useState(0);
    const [taxFee, setTaxFee] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [appliedBalance, setAppliedBalance] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [selectedStripeSourceId, setSelectedStripeSourceId] = useState();
    const [savedStripePaymentMethodSelected, setSavedStripePaymentMethodSelected] = useState();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const [showAwaitingModal, setShowAwaitingModal] = useState();
    const [showLoginModal, setShowLoginModal] = useState();
    const [userAllowedToPayACHAndSavedCard, setUserAllowedToPayACHAndSavedCard] = useState();
    const [requireUpfrontPayment, setRequireUpfrontPayment] = useState();
    const [shouldPreventLatePay, setShouldPreventLatePay] = useState();
    const [invoiceRecipient, setInvoiceRecipient] = useState();
    const [gateway, setGateway] = useState(STRIPE);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [creditCards, setCreditCards] = useState([]);
    const [bankAccountsPaymentMethods, setBankAccountsPaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(undefined);
    const disableNewPayInvoiceFE = getBooleanFlag(LaunchDarklyFlags.DISABLE_NEW_PAYINVOICE_FE, true);
    // If the invoice is billed to an organization but the user has a different
    // activeOrganization. In this case we do not show bank accounts.
    const activeOrganizationMismatch = ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.advertiserOrganization) &&
        ((_c = (_b = user === null || user === void 0 ? void 0 : user.data()) === null || _b === void 0 ? void 0 : _b.activeOrganization) === null || _c === void 0 ? void 0 : _c.id) !==
            ((_e = (_d = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _d === void 0 ? void 0 : _d.advertiserOrganization) === null || _e === void 0 ? void 0 : _e.id);
    /**
     * If the invoice does not have an advertiser organization but the advertiser
     * on the invoice is part of an organization, we do not show saved credit cards
     * or provide the option to save a credit card to the Stripe customer.
     */
    const individualFilerAndOrgFilerMismatch = !((_f = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _f === void 0 ? void 0 : _f.advertiserOrganization) &&
        ((_g = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data()) === null || _g === void 0 ? void 0 : _g.activeOrganization);
    const allowOptionToSaveCreditCard = userAllowedToPayACHAndSavedCard && !individualFilerAndOrgFilerMismatch;
    const allAccountsAwaiting = Boolean(bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.length) &&
        bankAccounts.every(acc => acc.status !== 'verified');
    useEffect(() => {
        if (!exists(user) || !exists(invoice)) {
            return;
        }
        const loadBankAccountsAndCreditCards = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            setLoading(true);
            try {
                const res = yield getUserPaymentMethods(user, (_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.advertiserOrganization);
                setBankAccounts(res.bankAccounts);
                setCreditCards(res.cards);
                setBankAccountsPaymentMethods(res.bankAccountsPaymentMethods);
            }
            catch (e) {
                console.warn('Failed to load bank accounts and credit cards', e);
            }
            finally {
                setLoading(false);
            }
        });
        void loadBankAccountsAndCreditCards();
    }, [user === null || user === void 0 ? void 0 : user.id, (_j = (_h = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _h === void 0 ? void 0 : _h.advertiserOrganization) === null || _j === void 0 ? void 0 : _j.id]);
    useEffect(() => {
        const fetchInvoice = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f;
            const invoiceId = window.location.href.split('/')[4];
            const invoiceSnap = yield getFirebaseContext()
                .invoicesRef()
                .doc(invoiceId)
                .get();
            if (exists(invoiceSnap)) {
                setInvoice(invoiceSnap);
                let fetchedInvoiceRecipient;
                try {
                    const data = yield api.get(`notices/${invoiceSnap.data().noticeId}/invoice-recipient`);
                    if (data === null || data === void 0 ? void 0 : data.invoiceRecipient) {
                        setInvoiceRecipient(data.invoiceRecipient);
                        fetchedInvoiceRecipient = data === null || data === void 0 ? void 0 : data.invoiceRecipient;
                    }
                }
                catch (err) {
                    console.error(err);
                }
                const advertiserSnap = yield getFirebaseContext()
                    .usersRef()
                    .doc(invoiceSnap.data().advertiser.id)
                    .get();
                if (exists(advertiserSnap)) {
                    setAdvertiser(advertiserSnap);
                }
                const newspaper = yield ((_a = invoiceSnap.data().organization) === null || _a === void 0 ? void 0 : _a.get());
                const gateway = yield getOrganizationGateway(newspaper);
                setGateway(gateway);
                // "Billed To" in order of preference:
                //  1) The name of the manually specified invoice recipient
                //  2) The name of the advertiserOrganization on the invoice object
                //  3) The advertiser's name
                if (fetchedInvoiceRecipient) {
                    setBillingName(getRecipientName(fetchedInvoiceRecipient));
                }
                if (invoiceSnap.data().advertiserOrganization) {
                    const invoiceAdvertiserOrg = yield ((_b = invoiceSnap
                        .data()
                        .advertiserOrganization) === null || _b === void 0 ? void 0 : _b.get());
                    if (!fetchedInvoiceRecipient) {
                        setBillingName((_c = invoiceAdvertiserOrg === null || invoiceAdvertiserOrg === void 0 ? void 0 : invoiceAdvertiserOrg.data()) === null || _c === void 0 ? void 0 : _c.name);
                    }
                    setCustomerToSaveCardOnName((_d = invoiceAdvertiserOrg === null || invoiceAdvertiserOrg === void 0 ? void 0 : invoiceAdvertiserOrg.data()) === null || _d === void 0 ? void 0 : _d.name);
                }
                else {
                    if (!fetchedInvoiceRecipient) {
                        setBillingName((_e = advertiserSnap.data()) === null || _e === void 0 ? void 0 : _e.name);
                    }
                    setCustomerToSaveCardOnName((_f = advertiserSnap.data()) === null || _f === void 0 ? void 0 : _f.name);
                }
                const { noticeId } = invoiceSnap.data();
                // getting notice relevant data from here
                try {
                    const data = yield api.get(`notices/${noticeId}/should-prevent-late-pay`);
                    if (data === null || data === void 0 ? void 0 : data.shouldPreventLatePay) {
                        setShouldPreventLatePay(true);
                    }
                }
                catch (err) {
                    logAndCaptureException(err, 'Failed to determine if we should prevent late prepay on notice.', { noticeId });
                }
                try {
                    const data = yield api.get(`notices/${invoiceSnap.data().noticeId}/require-upfront-payment`);
                    if (data === null || data === void 0 ? void 0 : data.requireUpfrontPayment) {
                        setRequireUpfrontPayment(data === null || data === void 0 ? void 0 : data.requireUpfrontPayment);
                    }
                }
                catch (err) {
                    console.error(err);
                }
            }
            else {
                logAndCaptureMessage('User is trying to access a payment url for an invoice that does not exist', { invoiceId, userId: (user === null || user === void 0 ? void 0 : user.id) || '' });
                push('/');
            }
        });
        void fetchInvoice();
    }, []);
    useEffect(() => {
        if (!invoice) {
            return;
        }
        const fetchStripePricing = () => __awaiter(void 0, void 0, void 0, function* () {
            // For Payway, Elavon, etc we don't do this call.
            const isStripeCheckout = gateway === STRIPE;
            if (!isStripeCheckout) {
                return undefined;
            }
            const stripePricingRes = yield api.get(`payments/${invoice.id}/stripe-pricing`);
            if (!stripePricingRes.success) {
                return undefined;
            }
            return stripePricingRes.details;
        });
        const fetchPricingForSingleInvoice = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const pricingRes = yield api.get(`payments/${invoice.id}/invoice-to-pricing`);
            if (!pricingRes.success) {
                return;
            }
            const { DBPricingObject } = pricingRes;
            const invoiceNetTotal = (_a = invoice.data().netTotal) !== null && _a !== void 0 ? _a : DBPricingObject.total;
            // When an invoice is paid in Stripe we the user will always be charged amount_due even
            // if that does not match up with our calculations. So that is what we display.
            // See: ONCALL-1576
            const stripePricing = yield fetchStripePricing();
            const stripeTotal = (stripePricing === null || stripePricing === void 0 ? void 0 : stripePricing.amount_due) || invoiceNetTotal;
            // Temporary logging to see how often we have a mismatch
            if (invoiceNetTotal !== stripeTotal) {
                logAndCaptureMessage('Stripe and invoice totals do not match', {
                    invoiceId: invoice.id,
                    stripeTotal: `${stripeTotal}`,
                    invoiceNetTotal: `${invoiceNetTotal}`
                });
            }
            const { feesInCents } = getInvoiceTotalSubtotalAndFees(invoice);
            setProcessingFee(DBPricingObject.distributed ? 0 : feesInCents);
            setTaxFee(DBPricingObject.taxAmt);
            setTotalAmount(DBPricingObject.total);
            setNetTotal(stripeTotal);
            setSubtotal(DBPricingObject.subtotal);
            setInAppLineItems(DBPricingObject.lineItems);
        });
        const fetchPricingForBulkInvoice = () => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            setProcessingFee(0);
            setTaxFee(0);
            let invoiceTotal = 0;
            for (const lineItem of invoice.data().inAppLineItems) {
                const itemAmount = lineItem.refund
                    ? -1 * lineItem.amount
                    : lineItem.amount;
                invoiceTotal += itemAmount;
            }
            const invoiceNetTotal = (_b = invoice.data().netTotal) !== null && _b !== void 0 ? _b : invoiceTotal;
            // When an invoice is paid in Stripe we the user will always be charged amount_due even
            // if that does not match up with our calculations. So that is what we display.
            // See: ONCALL-1576
            const stripePricing = yield fetchStripePricing();
            const stripeTotal = (stripePricing === null || stripePricing === void 0 ? void 0 : stripePricing.amount_due) || invoiceNetTotal;
            // Temporary logging to see how often we have a mismatch
            if (stripeTotal !== invoiceNetTotal) {
                logAndCaptureMessage('Stripe and invoice totals do not match', {
                    invoiceId: invoice.id,
                    stripeTotal: `${stripeTotal}`,
                    invoiceNetTotal: `${invoiceNetTotal}`
                });
            }
            setInAppLineItems(invoice.data().inAppLineItems);
            setNetTotal(stripeTotal);
            setTotalAmount(invoiceTotal);
            setSubtotal(invoiceTotal);
        });
        setAppliedBalance(invoice.data().appliedBalance || 0);
        if (invoice.data().isBulkInvoice) {
            void fetchPricingForBulkInvoice();
        }
        else {
            void fetchPricingForSingleInvoice();
        }
    }, [invoice === null || invoice === void 0 ? void 0 : invoice.id, gateway]);
    // useEffect can't depend on an array of changing length so instead
    // we hash all the bank accounts together and credit cards together
    // to get something that will change whenever the accounts do.
    const bankAccountsHash = bankAccounts.map(ba => ba.fingerprint).join(',');
    const bankAccountsPaymentMethodsHash = bankAccountsPaymentMethods
        .map(ba => ba.us_bank_account.fingerprint)
        .join(',');
    const creditCardsHash = creditCards
        .map(card => card.card.fingerprint)
        .join(',');
    useEffect(() => {
        setSelectedStripeSourceId(undefined);
        setSavedStripePaymentMethodSelected(false);
        setErr('');
    }, [bankAccountsHash, bankAccountsPaymentMethodsHash]);
    useEffect(() => {
        setSelectedStripeSourceId(undefined);
        setSavedStripePaymentMethodSelected(false);
        setErr('');
    }, [creditCardsHash]);
    useEffect(() => {
        // A user can pay with ACH if:
        // 1) The invoice was billed to an advertiserOrganization and the user
        //    has the right role within the organization.
        // 2) The invoice was billed to the user directly (no organization).
        const canUserPayWithACH = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!(exists(user) && invoice)) {
                setUserAllowedToPayACHAndSavedCard(false);
                return;
            }
            if (individualFilerAndOrgFilerMismatch) {
                setUserAllowedToPayACHAndSavedCard(false);
                return;
            }
            const { advertiser, advertiserOrganization } = invoice.data();
            if (advertiserOrganization) {
                const sameOrg = advertiserOrganization.id === ((_a = user.data().activeOrganization) === null || _a === void 0 ? void 0 : _a.id);
                setUserAllowedToPayACHAndSavedCard(sameOrg);
            }
            else {
                setUserAllowedToPayACHAndSavedCard(advertiser.id === user.id);
            }
        });
        if (exists(user) && invoice) {
            void canUserPayWithACH();
        }
    }, [invoice === null || invoice === void 0 ? void 0 : invoice.id, user === null || user === void 0 ? void 0 : user.id]);
    // Doing this at the react router didn't work. It loaded the component before loading LD.
    if (disableNewPayInvoiceFE === false) {
        return React.createElement(NewPayInvoice, null);
    }
    const payInvoice = (isACH) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const customer_email = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data().email;
        if (!exists(invoice)) {
            console.error('Invoice data not populated when initiating payment');
            return;
        }
        const invoiceId = invoice === null || invoice === void 0 ? void 0 : invoice.id;
        if (!customer_email) {
            console.error('Customer email has incorrect value');
            return;
        }
        if (!invoiceId) {
            console.error('invoice Id has incorrect value');
            return;
        }
        if (totalAmount === undefined) {
            console.error('totalAmount is undefined');
            return;
        }
        const initialRequest = Object.assign({ amount: totalAmount, customer_email,
            invoiceId }, (paymentMethod ? { paymentMethod } : {}));
        let req;
        if (isACH) {
            req = Object.assign(Object.assign({}, initialRequest), { userId: user === null || user === void 0 ? void 0 : user.id, stripeBankAccountSourceId: selectedStripeSourceId });
        }
        else {
            req = Object.assign(Object.assign({}, initialRequest), { paymentMethodId: selectedStripeSourceId, saveCreditCard: true });
        }
        const result = yield api.post('payments/pay-invoice', req);
        if (result.success) {
            setLoading(false);
            reload(false);
        }
        else {
            setErr(result.error || '');
            setLoading(false);
        }
    });
    const formatDate = (timestamp) => {
        return moment(timestamp).format('MMMM DD, YYYY');
    };
    const currency = (_k = CurrencyType.by_key(invoice === null || invoice === void 0 ? void 0 : invoice.data().currency)) !== null && _k !== void 0 ? _k : CurrencyType.usd;
    const isPastDue = (due_date) => {
        const currentDate = moment().format('YYYY-MM-DD');
        const dueDate = moment(due_date * 1000).format('YYYY-MM-DD');
        return moment(dueDate).isBefore(currentDate);
    };
    const BasicState = () => {
        var _a;
        if (!exists(invoice))
            return null;
        const { due_date } = invoice.data();
        let disableACH;
        let disableSavedCard;
        if (invoice) {
            disableACH = invoice.data().disableACH;
        }
        // When the invoice is sent to someone else we disable ACH and saved credit cards
        // so that the other person can't use the advertiser's saved payment information.
        // we also want to disable using saved credit cards in Stripe if the newspaper uses payway
        if ((invoiceRecipient && !userAllowedToPayACHAndSavedCard) ||
            gateway !== STRIPE) {
            disableACH = true;
            disableSavedCard = true;
        }
        if (individualFilerAndOrgFilerMismatch) {
            disableSavedCard = true;
        }
        const disableCheckPayment = requireUpfrontPayment || shouldPreventLatePay;
        const displayStripeBankAccountPaymentMethods = !disableACH &&
            !activeOrganizationMismatch &&
            bankAccountsPaymentMethods &&
            bankAccountsPaymentMethods.length > 0;
        return (React.createElement(React.Fragment, null,
            React.createElement(Box, { mt: 4 },
                React.createElement(Typography, { style: { fontSize: 18 }, align: "center" },
                    currency.symbol,
                    netTotal && (netTotal / 100).toFixed(2),
                    " ",
                    currency.label_abbrev,
                    ' ',
                    "due ",
                    formatDate(due_date * 1000))),
            React.createElement(Typography, { variant: "caption", align: "center", color: "textSecondary" }, "Choose how you'd like to pay."),
            React.createElement(Box, { mt: 3, mb: 2, width: 345 },
                allAccountsAwaiting && !disableACH && (React.createElement(Button, { className: classes.AwaitingBankAccount, onClick: () => setShowAwaitingModal(true), startIcon: React.createElement(AccessTime, { fontSize: "large" }) }, "Awaiting bank account verification...")),
                React.createElement(Box, null,
                    invoice && (React.createElement(Button, { className: shouldPreventLatePay
                            ? classes.cardButtonDisabled
                            : classes.cardButton, variant: "outlined", id: "stripe-card", onClick: () => {
                            setSelectedStripeSourceId(undefined);
                            setSavedStripePaymentMethodSelected(false);
                            setWithCard(!withCard);
                            setWithCheck(false);
                            setPaymentMethod('card');
                        }, startIcon: React.createElement(SvgIcon, { fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: withCard ? '#4285F4' : 'currentColor', style: { fill: 'none' } },
                            React.createElement("path", { d: "M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" })), style: {
                            color: withCard ? '#4285F4' : '#4A5568',
                            border: withCard ? '1px solid #4285F4' : '1px solid #DADADA' //  blue border if selected, gray otherwise
                        }, disabled: shouldPreventLatePay }, "Card")),
                    React.createElement(CTooltip, { helpText: requireUpfrontPayment
                            ? 'Because payment is required before your notice can be published, checks cannot be processed in time.'
                            : '', classes: "contents" },
                        React.createElement(Button, { className: disableCheckPayment
                                ? classes.checkButtonDisabled
                                : classes.checkButton, variant: "outlined", onClick: () => {
                                setSelectedStripeSourceId(undefined);
                                setSavedStripePaymentMethodSelected(false);
                                setWithCard(false);
                                setWithCheck(!withCheck);
                            }, startIcon: React.createElement(SvgIcon, { fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: withCheck ? '#4285F4' : 'currentColor', style: {
                                    fill: 'none',
                                    marginLeft: '0'
                                } },
                                React.createElement("path", { d: "M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" })), style: {
                                color: withCheck ? '#4285F4' : '#4A5568',
                                border: withCheck ? '1px solid #4285F4' : '1px solid #DADADA',
                                width: 'auto'
                            }, disabled: disableCheckPayment }, "Check"))),
                React.createElement(Tooltip, { title: !userAllowedToPayACHAndSavedCard
                        ? `Linked bank accounts can only be used to pay for notices within your organization.`
                        : '' },
                    React.createElement(Box, { style: userAllowedToPayACHAndSavedCard
                            ? { opacity: '100%', cursor: 'pointer', marginTop: '8px' }
                            : { opacity: '35%', cursor: 'not-allowed', marginTop: '8px' } }, !disableACH &&
                        !activeOrganizationMismatch &&
                        bankAccounts &&
                        bankAccounts.length > 0 &&
                        bankAccounts.map((bankAccount, index) => bankAccount.status === 'verified' && (React.createElement(Box, { id: `bank-account${index}`, className: classes.bankAccountBox, key: index, onClick: () => {
                                if (userAllowedToPayACHAndSavedCard)
                                    if (bankAccount.id === selectedStripeSourceId) {
                                        setSelectedStripeSourceId(undefined);
                                        setSavedStripePaymentMethodSelected(false);
                                    }
                                    else {
                                        setSelectedStripeSourceId(bankAccount.id);
                                        setSavedStripePaymentMethodSelected(false);
                                        setWithCard(false);
                                        setWithCheck(false);
                                        setPaymentMethod('ach');
                                    }
                            }, style: {
                                border: bankAccount.id === selectedStripeSourceId
                                    ? '1px solid #4285F4'
                                    : '1px solid #DADADA' //  blue border if selected, gray otherwise
                            } },
                            React.createElement(Box, { style: { display: 'flex' } },
                                React.createElement(Box, { className: classes.achBadge, style: {
                                        background: bankAccount.id === selectedStripeSourceId
                                            ? '#4285F4'
                                            : '#4A5568' //  blue payment method badge if selected, gray otherwise
                                    } }, "ach"),
                                React.createElement(Box, { className: classes.flex, style: { alignItems: 'flex-start' } },
                                    React.createElement(Box, { className: `${classes.bankText} ${classes.bold}` }, bankAccount.bank_name),
                                    React.createElement(Box, { className: classes.bankText }, `**** **** **** ${bankAccount.last4}`)))))))),
                (!bankAccounts || bankAccounts.length === 0) && !disableACH && (React.createElement(Button, { className: shouldPreventLatePay
                        ? classes.linkAccountDisabled
                        : classes.linkAccount, variant: "outlined", id: "stipe-ach", onClick: () => user
                        ? window.open(`${window.location.origin}/settings/?tab=bank-accounts`)
                        : setShowLoginModal('In order to link your bank account, and in order to pay by bank or ACH transfer, you will need to log into Column.'), startIcon: React.createElement("svg", { className: "w-5 h-5", fill: "none", stroke: "currentColor", viewBox: "0 0 24 24" },
                        React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" })), disabled: shouldPreventLatePay }, "Link bank account (ACH)")),
                React.createElement(Box, { style: { opacity: '100%', cursor: 'pointer', marginTop: '8px' } }, displayStripeBankAccountPaymentMethods &&
                    bankAccountsPaymentMethods.map((bankAccount) => (React.createElement(Box, { id: `bank-account-payment-method${bankAccount.id}`, className: classes.bankAccountBox, key: bankAccount.id, onClick: () => {
                            if (bankAccount.id === selectedStripeSourceId) {
                                setSelectedStripeSourceId(undefined);
                                setSavedStripePaymentMethodSelected(false);
                            }
                            else {
                                setSelectedStripeSourceId(bankAccount.id);
                                setSavedStripePaymentMethodSelected(true);
                                setWithCard(false);
                                setWithCheck(false);
                                setPaymentMethod('ach');
                            }
                        }, style: {
                            border: bankAccount.id === selectedStripeSourceId
                                ? '1px solid #4285F4'
                                : '1px solid #DADADA' //  blue border if selected, gray otherwise
                        } },
                        React.createElement(Box, { style: { display: 'flex' } },
                            React.createElement(Box, { className: classes.achBadge, style: {
                                    background: bankAccount.id === selectedStripeSourceId
                                        ? '#4285F4'
                                        : '#4A5568' //  blue payment method badge if selected, gray otherwise
                                } }, "bank account"),
                            React.createElement(Box, { className: classes.flex, style: { alignItems: 'flex-start' } },
                                React.createElement(Box, { className: `${classes.bankText} ${classes.bold}` }, bankAccount.us_bank_account.bank_name.toUpperCase()),
                                React.createElement(Box, { className: classes.bankText }, `**** **** **** ${bankAccount.us_bank_account.last4}`))))))),
                React.createElement(Box, { style: { opacity: '100%', cursor: 'pointer', marginTop: '8px' } }, !disableSavedCard &&
                    creditCards &&
                    creditCards.length > 0 &&
                    creditCards.map((creditCard, index) => (React.createElement(Box, { id: `credit-card${creditCard.id}`, className: classes.bankAccountBox, key: index, onClick: () => {
                            if (creditCard.id === selectedStripeSourceId) {
                                setSelectedStripeSourceId(undefined);
                                setSavedStripePaymentMethodSelected(false);
                            }
                            else {
                                setSelectedStripeSourceId(creditCard.id);
                                setSavedStripePaymentMethodSelected(true);
                                setWithCard(false);
                                setWithCheck(false);
                                setPaymentMethod('card');
                            }
                        }, style: {
                            border: creditCard.id === selectedStripeSourceId
                                ? '1px solid #4285F4'
                                : '1px solid #DADADA' //  blue border if selected, gray otherwise
                        } },
                        React.createElement(Box, { style: { display: 'flex' } },
                            React.createElement(Box, { className: classes.achBadge, style: {
                                    background: creditCard.id === selectedStripeSourceId
                                        ? '#4285F4'
                                        : '#4A5568' //  blue payment method badge if selected, gray otherwise
                                } }, "credit card"),
                            React.createElement(Box, { className: classes.flex, style: { alignItems: 'flex-start' } },
                                React.createElement(Box, { className: `${classes.bankText} ${classes.bold}` }, creditCard.card.brand.toUpperCase()),
                                React.createElement(Box, { className: classes.bankText }, `**** **** **** ${creditCard.card.last4}`))))))),
                selectedStripeSourceId && (React.createElement(Box, { className: classes.flex },
                    React.createElement(Button, { onClick: () => {
                            void payInvoice(!savedStripePaymentMethodSelected);
                        }, id: "pay-invoice-stripe", className: classes.payInvoice, disabled: loading, startIcon: loading && (React.createElement(CircularProgress, { size: 20, className: classes.progress, style: { color: 'white' } })) }, "Pay invoice"))),
                (withCard || withCheck) && (React.createElement(Box, { mt: 4 },
                    withCard && totalAmount !== undefined && (React.createElement(React.Fragment, null,
                        gateway === STRIPE && advertiser && invoice && (React.createElement(Elements, { stripe: stripePromise },
                            React.createElement(StripeCheckoutForm, { classes: classes, advertiser: advertiser, amount: totalAmount, invoiceId: invoice.id, customerToSaveCardOnName: customerToSaveCardOnName || '', allowSavedCreditCards: !!allowOptionToSaveCreditCard }))),
                        gateway === PAYWAY && invoice && (React.createElement(PaywayCheckoutForm, { invoiceId: invoice.id, noticeId: invoice.data().noticeId, classes: classes, amount: totalAmount })))),
                    withCheck && (React.createElement(Box, { className: `${classes.checkBox} ${classes.flex}` },
                        React.createElement(Box, { m: 2 },
                            React.createElement(Typography, { className: classes.checkText },
                                "If you would like to pay by check, please write a check made out to \"Column Software PBC\" with",
                                ' ',
                                React.createElement("b", null, ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.invoice_number) || ''),
                                " in the memo line ",
                                invoice ? ', include a printed copy of the ' : '',
                                invoice ? (React.createElement("a", { onClick: () => {
                                        var _a;
                                        const url = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.invoice_pdf;
                                        if (url) {
                                            window.open(`${cdnIfy(url, {
                                                useImgix: true
                                            })}`);
                                        }
                                    }, style: { color: '#5469D4', cursor: 'pointer' } }, "Invoice PDF")) : (''),
                                `, and mail to:`,
                                React.createElement("br", null),
                                " ",
                                React.createElement("br", null),
                                "Column Software PBC ",
                                React.createElement("br", null),
                                " PO Box 208098 ",
                                React.createElement("br", null),
                                " Dallas, TX 75320-8098 ",
                                React.createElement("br", null),
                                " ",
                                React.createElement("br", null))))))),
                user && (React.createElement(Box, { className: classes.gotoText, mt: 1 },
                    "Go to my",
                    React.createElement("span", { style: { color: '#5469D4', cursor: 'pointer' }, onClick: () => window.open(`${window.location.origin}/settings/?tab=bank-accounts`) }, ` Payment Methods.`))),
                !disableACH && activeOrganizationMismatch && (React.createElement(Box, { mt: 2, mx: 2, className: "text-center" },
                    React.createElement(Typography, { variant: "caption", align: "center", color: "textSecondary" },
                        React.createElement("span", null,
                            "Don't see your bank accounts? Log into \"",
                            billingName,
                            "\" to pay via ACH.")))),
                err && (React.createElement(Box, { mt: 1, className: classes.flex },
                    React.createElement(Typography, { color: "error", variant: "caption" }, err))),
                showAwaitingModal && (React.createElement(TailwindModal, { header: "Awaiting Verification", body: "In order to complete your bank account verification, you need to confirm microdeposits in your account. Please note it can take 3-5 days from initiation for the microdeposits to appear in your bank account.", close: () => setShowAwaitingModal(false), buttonText: "Go to bank accounts settings", onButtonClick: () => window.open(`${window.location.origin}/settings/?tab=bank-accounts`) })),
                showLoginModal && (React.createElement(TailwindModal, { header: "You must be logged in", body: showLoginModal, close: () => setShowLoginModal(undefined), buttonText: "Log into Column", onButtonClick: () => {
                        if (invoice)
                            push(`/login/?redirect=/invoices/${invoice.id}/pay`);
                    } })))));
    };
    if (!invoice ||
        !advertiser ||
        (exists(user) && bankAccounts === undefined) ||
        totalAmount === undefined ||
        (exists(user) && userAllowedToPayACHAndSavedCard === undefined)) {
        return React.createElement(LoadingState, { context: { location: 'PayInvoice' } });
    }
    const { invoice_number, status, due_date } = invoice.data();
    const { finalized_at } = invoice.data();
    let voided_at = null;
    if (invoice)
        voided_at = invoice.data().voided_at;
    const renderState = () => {
        if (voided_at)
            return (React.createElement(VoidedState, { classes: classes, voidedDate: formatDate(voided_at.toMillis()) }));
        if ([
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(status) ||
            totalAmount === 0)
            return (React.createElement(PaidState, { classes: classes, paidDate: formatDate(finalized_at === null || finalized_at === void 0 ? void 0 : finalized_at.toMillis()), netTotal: netTotal, invoice: invoice, currency: currency }));
        if (status === InvoiceStatus.initiated.value)
            return (React.createElement(PendingState, { classes: classes, initiatedDate: formatDate(finalized_at.toMillis()), totalAmount: totalAmount, currency: currency }));
        return React.createElement(BasicState, null);
    };
    return (React.createElement(Box, { className: classes.container },
        React.createElement(Box, { className: classes.flex },
            React.createElement(Box, { className: classes.leftBigBox }),
            React.createElement(Box, { className: classes.leftSmallBox })),
        React.createElement(Box, { className: classes.flex },
            React.createElement(Box, { className: classes.rightBigBox }),
            React.createElement(Box, { className: classes.rightSmallBox })),
        React.createElement("main", { className: classes.main },
            shouldPreventLatePay &&
                status &&
                ![
                    InvoiceStatus.paid.value,
                    InvoiceStatus.partially_refunded.value
                ].includes(status) && (React.createElement("div", { className: "relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("div", { className: "w-2 h-16 bg-blue-500 mr-4" }),
                    React.createElement("div", { className: "flex flex-col" },
                        React.createElement("b", { className: "mr-1" }, "Deadline has passed."),
                        React.createElement("div", null, "Upfront payment was required for this notice. Please contact the newspaper or Column Support to reschedule your notice for a future date."))))),
            requireUpfrontPayment &&
                !shouldPreventLatePay &&
                status &&
                ![
                    InvoiceStatus.paid.value,
                    InvoiceStatus.partially_refunded.value
                ].includes(status) && (React.createElement("div", { className: "relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("div", { className: "w-2 h-16 bg-blue-500 mr-4" }),
                    React.createElement("div", { className: "flex flex-col" },
                        React.createElement("b", { className: "mr-1" }, "Upfront payment required."),
                        React.createElement("div", null, "If you don\u2019t pay your invoice on time, your notice might not be published."))))),
            React.createElement(Paper, { className: `${classes.paper} ${classes.flex}` },
                React.createElement(Avatar, { className: classes.avatar, style: { boxShadow: '0.5px 0.5px 3px', background: 'white' }, src: "/android-chrome-512x512.png" }),
                React.createElement(Box, { ml: 1 },
                    React.createElement(Box, { mt: 4 },
                        React.createElement(Typography, { style: { fontSize: 20 }, align: "center" }, "Invoice from Column")),
                    React.createElement(Typography, { variant: "caption", align: "center", display: "block", color: "textSecondary" },
                        "Billed to ",
                        billingName),
                    React.createElement(Typography, { variant: "caption", align: "center", display: "block", color: "textSecondary" },
                        "Invoice #",
                        invoice_number ? invoice_number.toUpperCase() : 'NaN')),
                React.createElement(Box, { mt: 4, mb: 2, className: classes.invoiceStateSection },
                    (status === InvoiceStatus.unpaid.value ||
                        status === InvoiceStatus.payment_failed.value) &&
                        !voided_at &&
                        isPastDue(due_date) && (React.createElement(Box, { className: classes.pastDue }, "past due")),
                    React.createElement(Divider, null),
                    React.createElement(Box, { mb: 2, className: classes.flex }, renderState()),
                    React.createElement(Divider, null)),
                React.createElement(InvoiceTable, { classes: classes, appliedBalance: appliedBalance, netTotal: netTotal, processingFee: processingFee, taxFee: taxFee, subtotal: subtotal, invoice: invoice, lineItems: inAppLineItems, currency: currency }),
                React.createElement(Divider, { style: { width: '100%' } }),
                React.createElement(Box, { ml: 4, mr: 4, mt: 2, mb: 2, className: classes.flex },
                    React.createElement(Typography, { className: classes.footerText, align: "center" },
                        "If you have any questions, contact Column at",
                        ' ',
                        React.createElement("span", { style: { color: '#5469D4' } }, "help@column.us"),
                        "."))))));
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PayInvoice));
