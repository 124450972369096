var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/label-has-associated-control */
import api from 'api';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { invoiceDataToDBPricingObject } from 'lib/pricing';
import { exists } from 'lib/types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { logAndCaptureException } from 'utils';
const ProcessCheckModal = ({ setOpen, setToastMessage, notice, invoice, newspaper, user }) => {
    const [loading, setLoading] = useState(false);
    const [paymentNote, setPaymentNote] = useState('');
    const processPayment = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (!user) {
            console.error('User does not exist');
            return;
        }
        const advertiser = yield invoice.data().advertiser.get();
        if (!exists(advertiser)) {
            throw new Error(`Could not get advertiser ${invoice.data().advertiser.id} for invoice ${invoice.id}`);
        }
        const rate = yield ((_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.rate.get());
        const { inAppLineItems, convenienceFeePct, affidavitFeeInCents, inAppTaxPct, disableMinimumConvenieceFee } = invoice.data();
        // Ignore the newspaper's distributeEnoticeFee property in order to get the convenience fee
        const DBPricingObject = invoiceDataToDBPricingObject(inAppLineItems, convenienceFeePct, affidavitFeeInCents, inAppTaxPct, undefined, disableMinimumConvenieceFee, (_b = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _b === void 0 ? void 0 : _b.finalLineItemPricing, (_c = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _c === void 0 ? void 0 : _c.rateType);
        const advertiserEmail = advertiser.data().email;
        if (!advertiserEmail) {
            console.error('User email is undefined');
            return;
        }
        const req = {
            amount: DBPricingObject.total,
            customer_email: advertiserEmail,
            invoiceId: invoice.id,
            outsideStripe: true,
            manualPaymentDetails: {
                userId: user.id,
                note: paymentNote
            }
        };
        yield api.post('payments/pay-invoice', req);
    });
    const handleMarkAsPaid = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield processPayment();
            setToastMessage(`You've successfully processed payment for this notice.`);
            setOpen(false);
        }
        catch (e) {
            logAndCaptureException(e, 'Failed to process payment by check', {
                noticeId: notice === null || notice === void 0 ? void 0 : notice.id,
                invoiceId: invoice.id
            });
        }
        setLoading(false);
    });
    return (React.createElement(FreeformCModal, { id: "process-check-modal", setOpen: setOpen, header: "Accept a direct payment", body: "" },
        React.createElement("section", null,
            React.createElement("div", { className: "text-sm leading-5 text-gray-700" },
                React.createElement("p", null,
                    "If you click ",
                    React.createElement("b", null, "Mark as Paid"),
                    ", you confirm that",
                    ' ',
                    newspaper.data().name,
                    ":"),
                React.createElement("div", { className: "mt-4 ml-5" },
                    React.createElement("div", { className: "flex items-start" },
                        React.createElement("input", { id: "outside_payment", type: "checkbox", disabled: true, checked: true, className: "mt-1 form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out" }),
                        React.createElement("label", { htmlFor: "outside_payment", "aria-controls": "outside_payment", className: "ml-3 block text-sm leading-5 text-gray-700" }, "Has received payment for this notice outside the Column platform.")),
                    React.createElement("div", { className: "flex items-start mt-1" },
                        React.createElement("input", { id: "authorize_deduction", type: "checkbox", disabled: true, checked: true, className: "mt-1 form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out" }),
                        React.createElement("label", { htmlFor: "authorize_deduction", "aria-controls": "authorize_deduction", className: "ml-3 block text-sm leading-5 text-gray-700" }, "Authorizes Column to collect the processing fee for this notice."))),
                React.createElement("br", null),
                React.createElement("p", { className: "text-sm leading-5 text-gray-700" },
                    "Upon clicking ",
                    React.createElement("b", null, "Mark as Paid"),
                    ", the customer will receive a notification.")),
            React.createElement("div", { className: "mt-3" },
                React.createElement("label", { id: "payment-note-label", htmlFor: "payment-note", className: "w-full uppercase text-xs font-normal text-gray-700" },
                    "Payment Note",
                    React.createElement("textarea", { id: "payment-note", name: "payment-note", placeholder: "How did you receive payment for this notice?", maxLength: 300, className: "w-full h-10 inline-block border border-gray-300 text-gray-900 rounded-md shadow-sm px-2 pb-1 pt-2 text-sm min-h-1/2 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out", value: paymentNote, onChange: e => setPaymentNote(e.target.value) }))),
            React.createElement("div", { className: "flex justify-center md:justify-start" },
                React.createElement(CButton, { id: `paid-by-check`, className: `bg-blue-200 w-32 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition`, onClick: handleMarkAsPaid, startClasses: " ", middleClasses: "col-span-12", endClasses: " ", startIcon: loading ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })) : (React.createElement(React.Fragment, null)) }, "Mark as Paid")))));
};
const mapStateToProps = (state) => ({
    user: state.auth.user
});
export default connect(mapStateToProps)(ProcessCheckModal);
