import React from 'react';
import classNames from 'classnames';
/**
 * A simple text-only select item.
 */
const ColumnSelectItem = ({ option, active, description }) => {
    return (React.createElement("div", { className: classNames({
            'bg-blue-50': active,
            'text-blue-500': active
        }, 'cursor-pointer block w-full text-left rounded p-3 hover:bg-blue-50 hover:text-blue-500 focus:outline-none transition ease-in duration-100') },
        React.createElement("span", null, option.label),
        description && React.createElement("span", { className: "opacity-75" },
            " - ",
            description)));
};
export default ColumnSelectItem;
