var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import api from 'api';
import Firebase from 'EnoticeFirebase';
import { exists } from 'lib/types';
import { BankIcon } from 'icons';
import LoadingState from 'components/LoadingState';
import { InvoiceStatus } from 'lib/enums';
import { Collections } from 'lib/constants';
import CButton from 'components/CButton';
import { getFirebaseContext } from 'utils/firebase';
import { shouldLoadBulkNoticesAndInvoices } from './Notices';
const PAGE_SIZE = 10;
const InvoiceTable = ({ previousPage, pageNumber, nextPage, hasMore, invoices, invoicesAmounts }) => {
    const getInvoiceChipColor = (status, due_date, index) => {
        if (!status) {
            return '';
        }
        // in case of invoice has no line items or amount is 0
        if (invoicesAmounts && invoicesAmounts.length && !invoicesAmounts[index]) {
            return 'green';
        }
        if (status === InvoiceStatus.initiated.value) {
            return 'yellow';
        }
        if (status === InvoiceStatus.unpaid.value &&
            new Date().getTime() / 1000 > due_date) {
            return 'red';
        }
        if (status === InvoiceStatus.unpaid.value) {
            return 'yellow';
        }
        if (
        // TODO: Should this include InvoiceStatus.initiated.value https://columnpbc.atlassian.net/browse/IT-4424
        [
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(status)) {
            return 'green';
        }
        return 'red';
    };
    const getInvoiceStatus = (status, due_date, index) => {
        var _a;
        // in case of invoice has no line items or amount is 0
        if (invoicesAmounts && invoicesAmounts.length && !invoicesAmounts[index])
            return InvoiceStatus.paid.label;
        if (
        // TODO: Should this include InvoiceStatus.initiated.value https://columnpbc.atlassian.net/browse/IT-4424
        ![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(status) &&
            new Date().getTime() / 1000 > due_date)
            return 'Past Due';
        return (_a = InvoiceStatus.by_value(status)) === null || _a === void 0 ? void 0 : _a.label;
    };
    const downloadInvoice = (e, invoice) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        e.stopPropagation();
        const invoiceId = invoice.id;
        const organization = yield ((_a = invoice.data().organization) === null || _a === void 0 ? void 0 : _a.get());
        const organizationName = (_b = organization === null || organization === void 0 ? void 0 : organization.data()) === null || _b === void 0 ? void 0 : _b.name;
        const response = yield api.post(`documents/bulk-invoice/download`, {
            invoiceId,
            organizationName
        });
        response.url && window.open(response.url);
    });
    const invoicesNetTotal = invoices.map((invoice, index) => invoicesAmounts[index] - (invoice.data().appliedBalance || 0));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mt-4 py-2 align-middle inline-block min-w-full" },
            React.createElement("div", { className: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" },
                React.createElement("table", { id: "invoices-table", className: "min-w-full divide-y divide-gray-200" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Initiated"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Amount"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Status"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Due Date"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Download"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Actions"))),
                    React.createElement("tbody", { className: "bg-white divide-y divide-gray-200" }, invoices.map((invoice, index) => (React.createElement("tr", { key: invoice.data().created.toMillis(), className: "cursor-pointer hover:bg-indigo-100", onClick: () => window.open(`${window.location.origin}/invoices/${invoice.id}/pay`) },
                        React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, moment(invoice.data().created.toMillis())
                            .utc()
                            .format('M/D/YYYY')),
                        React.createElement("td", { id: `invoice-amount-${index}`, className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, invoicesNetTotal && invoicesNetTotal.length
                            ? `$${(invoicesNetTotal[index] / 100).toFixed(2)}`
                            : ''),
                        React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" },
                            React.createElement("span", { className: `text-uppercase inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-${getInvoiceChipColor(invoice.data().status, invoice.data().due_date, index)}-100 text-${getInvoiceChipColor(invoice.data().status, invoice.data().due_date, index)}-800` }, getInvoiceStatus(invoice.data().status, invoice.data().due_date, index))),
                        React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, moment(invoice.data().due_date * 1000)
                            .utc()
                            .format('M/D/YYYY')),
                        React.createElement("td", { className: `${invoicesAmounts[index] === 0
                                ? 'cursor-not-allowed'
                                : 'hover:text-gray-500'} flex justify-center px-6 pt-8 pb-7 whitespace-no-wrap text-sm leading-5 text-gray-700` },
                            React.createElement(Tooltip, { title: invoicesAmounts[index] === 0
                                    ? `The notice(s) in this invoice was voided.`
                                    : '' },
                                React.createElement("div", { onClick: (e) => invoicesAmounts[index] !== 0
                                        ? downloadInvoice(e, invoice)
                                        : e.stopPropagation() },
                                    React.createElement("svg", { className: "fill-current w-4 h-4 mr-2", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" },
                                        React.createElement("path", { d: "M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" }))))),
                        React.createElement("td", { className: "px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-700" }, ![
                            InvoiceStatus.paid.value,
                            InvoiceStatus.partially_refunded.value
                        ].includes(invoice.data().status) &&
                            invoicesAmounts[index] !== 0 && (React.createElement(CButton, { id: "pay-bulk-invoice", className: "ml-0 bg-white md:bg-blue-200 w-auto border border-blue-200 md:border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-full md:w-32", middleClasses: "col-span-12", onClick: (e) => {
                                e.stopPropagation();
                                window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
                            } }, "Pay Invoice")))))))),
                React.createElement("nav", { className: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" },
                    React.createElement("div", { className: "hidden sm:block" },
                        React.createElement("p", { className: "text-sm leading-5 text-gray-700" },
                            "Showing results",
                            React.createElement("span", { className: "font-medium mx-1" }, pageNumber * PAGE_SIZE + 1),
                            "to",
                            React.createElement("span", { className: "font-medium mx-1" }, pageNumber * PAGE_SIZE + invoices.length))),
                    React.createElement("div", { className: "flex-1 flex justify-between sm:justify-end" },
                        React.createElement("div", { className: `cursor-${pageNumber > 0 ? 'pointer' : 'not-allowed'} relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => pageNumber > 0 && previousPage(invoices[0]) }, "Previous"),
                        React.createElement("div", { className: `cursor-${hasMore ? 'pointer' : 'not-allowed'} ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => hasMore && nextPage(invoices[invoices.length - 1]) }, "Next")))))));
};
const EmptyInvoices = () => (React.createElement("div", { className: "flex w-full h-full flex-col" },
    React.createElement("div", { className: "w-1/2 mx-auto" },
        React.createElement(BankIcon, null)),
    React.createElement("div", { className: "text-center text-xl text-medium -mt-5" }, "Invoices will appear here after your first month of bulk payments activity")));
const Invoices = ({ user, organization }) => {
    const [pageNumber, setPageNumber] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [invoicesAmounts, setInvoicesAmounts] = useState();
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const loadInvoices = (startAt, endAt) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setLoading(true);
        const currPageNumber = startAt
            ? pageNumber + 1
            : endAt
                ? pageNumber - 1
                : pageNumber;
        try {
            let query;
            const shadowUserId = user.data().shadowUser;
            if (exists(organization)) {
                query = Firebase.firestore()
                    .collection(Collections.invoices)
                    .where('isBulkInvoice', '==', true)
                    .where('advertiserOrganization', '==', organization.ref)
                    .orderBy('created', 'desc');
            }
            // User is being shadowed
            else if (shadowUserId) {
                const shadowUserRef = Firebase.firestore()
                    .collection(Collections.users)
                    .doc(shadowUserId);
                query = getFirebaseContext()
                    .invoicesRef()
                    .where('isBulkInvoice', '==', true)
                    .where('advertiser', '==', shadowUserRef)
                    .orderBy('created', 'desc');
            }
            else {
                query = getFirebaseContext()
                    .invoicesRef()
                    .where('isBulkInvoice', '==', true)
                    .where('advertiser', '==', user.ref)
                    .orderBy('created', 'desc');
            }
            const numberOfInvoices = (yield query.get()).docs.length;
            let invoiceSnaps = [];
            if (!startAt && !endAt)
                invoiceSnaps = (yield query.limit(PAGE_SIZE).get())
                    .docs;
            else if (startAt)
                invoiceSnaps = (yield query.startAfter(startAt.data().created).limit(PAGE_SIZE).get()).docs;
            else if (endAt) {
                invoiceSnaps = (yield query.endBefore(endAt.data().created).limit(PAGE_SIZE).get()).docs;
            }
            setInvoices(invoiceSnaps);
            setHasMore(numberOfInvoices - (currPageNumber + 1) * PAGE_SIZE > 0);
            const amounts = [];
            // re calculate bulk invoice amount to handle any changes happened as invoices cancellations or invoices re-creation
            for (const bulkInvoice of invoiceSnaps) {
                let total = 0;
                for (const lineItem of bulkInvoice.data().inAppLineItems) {
                    const itemAmount = lineItem.refund
                        ? -1 * lineItem.amount
                        : lineItem.amount;
                    total += itemAmount;
                }
                amounts.push(total);
            }
            setInvoicesAmounts(amounts);
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to load invoices', {
                userEmail: user.data().email,
                organizationName: ((_a = organization === null || organization === void 0 ? void 0 : organization.data()) === null || _a === void 0 ? void 0 : _a.name) || ''
            });
        }
        setLoading(false);
        setPageNumber(currPageNumber);
    });
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (yield shouldLoadBulkNoticesAndInvoices(user, organization)) {
                yield loadInvoices();
            }
        }))();
    }, [user, organization]);
    return (React.createElement(React.Fragment, null,
        loading && invoices && React.createElement(React.Fragment, null),
        invoicesAmounts ? (invoicesAmounts.length ? (React.createElement(InvoiceTable, { invoices: invoices, invoicesAmounts: invoicesAmounts, pageNumber: pageNumber, hasMore: hasMore, nextPage: (lastDocInPage) => __awaiter(void 0, void 0, void 0, function* () {
                yield loadInvoices(lastDocInPage);
            }), previousPage: (firstDocInPage) => __awaiter(void 0, void 0, void 0, function* () {
                yield loadInvoices(undefined, firstDocInPage);
            }) })) : (React.createElement(EmptyInvoices, null))) : (React.createElement(LoadingState, null))));
};
export default Invoices;
