var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { dateToDateString, cdnIfy } from 'lib/helpers';
import Firebase, { Firestore } from 'EnoticeFirebase';
import { State } from 'lib/enums';
import { affidavitsAreManagedByColumn } from '../affidavitSettingsUtils';
// Default values for affidavit documents
const DEFAULT_COLUMN_NOTARIZED_AFFIDAVIT_URL = 'https://enotice-production.imgix.net/custom-documents/permalink/d499.f7cad-98ca.949ce-nogalesinternational-dec16.html';
const DEFAULT_AFFIDAVIT_URL = 'https://enotice-production.imgix.net/custom-documents/permalink/08e2.af9a2-affidavit_template.html';
/**
 * Pulls the affidavit URL for the paper from the organization's settings. If the paper is managing its own affidavits, this will be the custom affidavit. If the paper is using Column's affidavit templates, this will be the default template URL
 * @param activeOrganization
 * @returns {string} URL to access the affidavit
 */
export const getNewspaperAffidavitURLFromActiveOrganization = (activeOrganization) => {
    const { customAffidavit } = activeOrganization.data();
    if (customAffidavit) {
        return cdnIfy(customAffidavit);
    }
    return DEFAULT_AFFIDAVIT_URL;
};
/**
 * Pulls the affidavit HTML for the paper from the organization's settings. If the paper is managing its own affidavits, this will be the custom affidavit. If the paper is using Column's affidavit templates, this will be the default template URL
 * @param activeOrganization
 * @returns {string} HTML for the affidavit
 */
export const getNewspaperAffidavitHTMLFromActiveOrganization = (activeOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getNewspaperAffidavitURLFromActiveOrganization(activeOrganization);
    const response = yield fetch(url);
    const html = yield response.text();
    return html;
});
/**
 * Determines the URL associated with the affidavit associated with the paper, taking into account:
 * 1) Custom affidavits
 * 2) Affidavit templates for Column affidavits
 * @param activeOrganization
 * @returns {string} URL associated with the custom affidavit
 */
export const getColumnNotarizedAffidavitURLFromActiveOrganization = (activeOrganization) => {
    var _a;
    if (!affidavitsAreManagedByColumn(activeOrganization)) {
        throw new Error('Cannot get affidavit URL for non-column-managed affidavits');
    }
    const customColumnNotarizeTemplate = (_a = activeOrganization.data()
        .affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.managedAffidavitTemplateStoragePath;
    if (customColumnNotarizeTemplate) {
        return cdnIfy(customColumnNotarizeTemplate);
    }
    return DEFAULT_COLUMN_NOTARIZED_AFFIDAVIT_URL;
};
/**
 * Returns the HTML for the affidavit associated with the paper, taking into account:
 * 1) Custom affidavits
 * 2) Affidavit templates for Column affidavits
 * @param activeOrganization
 * @returns {string} HTML for the affidavit
 */
export const getColumnNotarizedAffidavitHTMLFromActiveOrganization = (activeOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getColumnNotarizedAffidavitURLFromActiveOrganization(activeOrganization);
    const response = yield fetch(url);
    const html = yield response.text();
    return html;
});
const htmlToStoragePath = (activeOrganization, affidavitHTML) => __awaiter(void 0, void 0, void 0, function* () {
    // upload the new custom affidavit
    const uploadPath = `direct-ftp-upload/${activeOrganization.id}/custom_affidavits/${moment().format()}_affidavit.html`;
    yield Firebase.storage()
        .ref()
        .child(uploadPath)
        .put(new Blob([affidavitHTML], { type: 'text/html' }));
    return uploadPath;
});
/**
 * Updates the affidavit linked to the organization with the affidavit currently held in memory
 */
export const updateCustomAffidavit = (activeOrganization, affidavitHTML) => __awaiter(void 0, void 0, void 0, function* () {
    const uploadPath = yield htmlToStoragePath(activeOrganization, affidavitHTML);
    yield activeOrganization.ref.update({
        customAffidavit: uploadPath
    });
});
/**
 * Updates the affidavit linked to the organization with the affidavit currently held in memory
 */
export const updateColumnNotarizedAffidavit = (activeOrganization, columnNotarizedAffidavitHTML) => __awaiter(void 0, void 0, void 0, function* () {
    const { affidavitReconciliationSettings } = activeOrganization.data();
    if (!affidavitsAreManagedByColumn(activeOrganization) ||
        !affidavitReconciliationSettings) {
        throw new Error('Cannot update affidavit for non-column-managed affidavits');
    }
    const uploadPath = yield htmlToStoragePath(activeOrganization, columnNotarizedAffidavitHTML);
    yield activeOrganization.ref.update({
        affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { managedAffidavitTemplateStoragePath: uploadPath })
    });
});
/**
 * Clears the custom affidavit currently linked to the paper. This can only be run for papers managing their own affidavits
 */
export const removeCustomAffidavit = (activeOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    if (affidavitsAreManagedByColumn(activeOrganization)) {
        throw new Error('Cannot remove custom affidavit for column-managed affidavits');
    }
    else {
        yield activeOrganization.ref.update({
            customAffidavit: Firestore.FieldValue.delete()
        });
    }
});
/**
 * Checks for errors that appear in ALL affidavits, those signed by the paper and those signed by Column
 * @param html
 * @returns {AffidavitError[]} list of errors
 */
export const parseErrorsFromOrganizationManagedAffidavitHTML = (html) => {
    const errors = [];
    if (!/{{\s{0,2}PUBLICATION_DATES\s{0,2}}}/.test(html)) {
        errors.push({
            message: 'Missing {{PUBLICATION_DATES}} tag'
        });
    }
    if (!/{{\s{0,2}NOTICE_ID\s{0,2}}}/.test(html)) {
        errors.push({
            message: 'Missing {{NOTICE_ID}} tag'
        });
    }
    if (!/{{\s{0,2}PUBLICATION_FEE\s{0,2}}}/.test(html)) {
        errors.push({
            message: 'Missing {{PUBLICATION_FEE}} tag'
        });
    }
    if (!/{{\s{0,2}NOTICE_NAME\s{0,2}}}/.test(html)) {
        errors.push({
            message: 'Missing {{NOTICE_NAME}} tag'
        });
    }
    return errors;
};
/**
 * Determines errors in affidavit HTML that will be notarized by Column
 * @param html custom HTML of the affidavit
 * @returns {AffidavitError[]} list of errors
 */
export const parseErrorsFromColumnManagedAffidavitHTML = (html) => {
    // parse out the default errors
    const errors = parseErrorsFromOrganizationManagedAffidavitHTML(html);
    // add in the custom affidavit specific errors
    // check for presence of white-text tagged variables
    if (!/\[\$signersig\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$signersig] tag'
        });
    }
    if (!/\[\$notarysig\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$notarysig] tag'
        });
    }
    if (!/\[\$disclosure\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$disclosure] tag'
        });
    }
    if (!/\[\$seal\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$seal] tag'
        });
    }
    if (!/\[\$date\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$date] tag'
        });
    }
    if (!/\[\$state\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$state] tag'
        });
    }
    if (!/\[\$county\s{0,}\]/.test(html)) {
        errors.push({
            message: 'Missing [$county] tag'
        });
    }
    // check that white-text tagged variables are defined:
    if (!html.includes('[def:$signersig|sig|req|signer1]')) {
        errors.push({
            message: 'Missing [def:$signersig|sig|req|signer1] declaration'
        });
    }
    if (!html.includes('[def:$notarysig|sig|req|notary]')) {
        errors.push({
            message: 'Missing [def:$notarysig|sig|req|notary] declaration'
        });
    }
    if (!html.includes('[def:$disclosure|disclosure|req|notary]')) {
        errors.push({
            message: 'Missing [def:$disclosure|disclosure|req|notary] declaration'
        });
    }
    if (!html.includes('[def:$seal|seal|req|notary]')) {
        errors.push({
            message: 'Missing [def:$seal|seal|req|notary] declaration'
        });
    }
    if (!html.includes('[def:$date|date|req|notary]')) {
        errors.push({
            message: 'Missing [def:$date|date|req|notary] declaration'
        });
    }
    return errors;
};
/**
 * Takes in the HTML of an affidavit and returns an error if it is missing any of the required tags
 * or is configured incorrectly
 * @param html
 * @returns
 */
export const parseErrorsFromAffidavitHTML = (html, settings) => {
    if (!html) {
        return [];
    }
    if (settings.isColumnManaged) {
        return parseErrorsFromColumnManagedAffidavitHTML(html);
    }
    return parseErrorsFromOrganizationManagedAffidavitHTML(html);
};
/**
 * Pulls out the default set of handlebars used in affidavits for rendering the
 * preview in the layout settings page
 * @param activeOrganization Organization we are pulling settings from
 * @returns
 */
export const getAffidavitHandlebarsForActiveOrganization = (activeOrganization) => {
    var _a, _b;
    return {
        PUBLICATION_ADDRESS: activeOrganization.data().address,
        PUBLICATION_NUMBER: activeOrganization.data().phone,
        PUBLICATION_DATES: [
            `<div class="margin-bottom-small">
        ${dateToDateString(moment().toDate())}
      </div>`
        ],
        PUBLICATION_NAME: activeOrganization.data().name,
        AGENT_NAME: activeOrganization.data().signatoryName,
        AGENT_ROLE: activeOrganization.data().signatoryRole,
        NOTICE_ID: 'NOTICE_ID',
        CITY: activeOrganization.data().city,
        STATE: (_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.label,
        PUBLICATION_FEE: 'X.XX',
        STATE_UPPER: (_b = State.by_value(activeOrganization.data().state)) === null || _b === void 0 ? void 0 : _b.label.toUpperCase(),
        COUNTY_UPPER: activeOrganization.data().county.toUpperCase(),
        COMMISSION_EXPIRATION_DATE: activeOrganization.data()
            .commissionExpirationDate
    };
};
