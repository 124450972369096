import React from 'react';
import { RateType } from 'lib/enums';
import { prettyFormatNumber } from '../ratesTableSettingsUtils';
import RatePropertiesRow from './RatePropertiesRow';
import FormSwitch from '../../../FormSwitch';
/**
 * Edit core properties on the rate
 */
export default function BaseRatePropertiesTab({ rate }) {
    var _a;
    return (React.createElement("div", null,
        React.createElement(RatePropertiesRow, { label: "Rate Name", value: rate.data().description }),
        React.createElement(RatePropertiesRow, { label: "Rate Type", value: (_a = RateType.by_value(rate.data().rateType)) === null || _a === void 0 ? void 0 : _a.label }),
        React.createElement(RatePropertiesRow, { label: "Supports Display Ads", value: React.createElement(FormSwitch, { disabled: true, checked: !!rate.data().supportsDisplay }) }),
        React.createElement(RatePropertiesRow, { label: "Run Based Rate", value: React.createElement(FormSwitch, { disabled: true, checked: !!rate.data().runBased }) }),
        React.createElement(RatePropertiesRow, { label: "Round Off", value: rate.data().roundOff }),
        React.createElement(RatePropertiesRow, { label: "1 Run Rate", value: `$${prettyFormatNumber(rate.data().rate_0 / 100)}` }),
        React.createElement(RatePropertiesRow, { label: "2 Run Rate", value: `$${prettyFormatNumber(rate.data().rate_1 / 100)}` }),
        React.createElement(RatePropertiesRow, { label: "3 Run Rate", value: `$${prettyFormatNumber(rate.data().rate_2 / 100)}` }),
        React.createElement(RatePropertiesRow, { label: "Minimum", value: `$${prettyFormatNumber(rate.data().minimum / 100)}` })));
}
