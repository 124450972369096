import React, { useEffect, useState } from 'react';
import Drawer from 'components/Drawer';
import { exists } from 'lib/types';
import TemplateSettingsDrawerHeader from './TemplateSettingsDrawerHeader';
import TemplateSettingsDrawerBody from './TemplateSettingsDrawerBody';
/**
 * Drawer component for detail view of a template
 */
export default function TemplateSettingsDrawer({ activeOrganization, setEditedTemplate, drawerTemplate, closeDrawer }) {
    const [template, setTemplate] = useState(drawerTemplate);
    /** Set the template on snapshot updates */
    useEffect(() => {
        const unsub = template.ref.onSnapshot(snapshot => {
            if (exists(snapshot))
                setTemplate(snapshot);
        });
        return () => unsub && unsub();
    }, [template === null || template === void 0 ? void 0 : template.id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { closeDrawer: () => {
                closeDrawer();
            }, header: React.createElement(TemplateSettingsDrawerHeader, { activeOrganization: activeOrganization, template: template }), open: true },
            React.createElement(TemplateSettingsDrawerBody, { activeOrganization: activeOrganization, setEditedTemplate: setEditedTemplate, template: template }))));
}
