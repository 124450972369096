var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { getCustomerOrganization } from 'lib/notice/customer';
import classNames from 'classnames';
import { DotIcon } from 'icons';
import HorizontalDivider from 'components/HorizontalDivider';
import { Badge } from 'components/Badge';
import { getFirebaseContext } from '../../utils/firebase';
import Drawer from '../Drawer';
import { CustomerDrawerHeader } from './CustomerDrawerHeader';
import { CustomerDrawerDetailsTab } from './CustomerDrawerDetailsTab';
import { CustomerDrawerOrganizationTab } from './CustomerDrawerOrganizationsTab';
import { CustomerDrawerSettingsTab } from './CustomerDrawerSettingsTab';
import { CustomerDrawerFooter } from './CustomerDrawerFooter';
import { useSyncCustomerData } from './useSyncCustomerData';
const CustomerDrawer = ({ customerData, activeOrganization, closeDrawer, setShowCustomerOrganizationDrawer, setCustomerOrganization, setShowEditCustomerModal }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    useSyncCustomerData({ customerData });
    const customerRow = customerData.customer.data();
    const [title, setTitle] = useState('Details');
    const [isAccountOnHold, setIsAccountOnHold] = useState(customerRow.isAccountOnHold);
    const DEFAULT_SETTING = 0;
    const ALWAYS_REQUIRE = 1;
    const NEVER_REQUIRE = 2;
    const requireUpfrontPaymentId = customerRow.requireUpfrontPayment === null ||
        customerRow.requireUpfrontPayment === undefined
        ? DEFAULT_SETTING
        : customerRow.requireUpfrontPayment
            ? ALWAYS_REQUIRE
            : NEVER_REQUIRE;
    const REQUIRE_UPFRONT_PAYMENT_OPTIONS_ARRAY = [
        'Default',
        'Always require',
        'Never require'
    ];
    const [loadingCustomerOrganizations, setLoadingCustomerOrganizations] = useState(false);
    const [customerOrganizations, setCustomerOrganizations] = useState();
    const [allowedOrganizationNames, setAllowedOrganizationNames] = useState([]);
    const [membersInAllowedOrganization, setMembersInAllowedOrganization] = useState([]);
    const { hideDigitalAffidavits } = activeOrganization.data() || {};
    const ctx = getFirebaseContext();
    useEffect(() => {
        const getCustomerOrganizations = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoadingCustomerOrganizations(true);
            const allowedOrganizations = customerData.user.data().allowedOrganizations || [];
            let organizationsAffiliatedWithNewspaper = yield Promise.all(allowedOrganizations.map((organization) => __awaiter(void 0, void 0, void 0, function* () {
                const organizationSnap = yield organization.get();
                const customerOrgSnap = yield getCustomerOrganization(ctx, organizationSnap, activeOrganization);
                return customerOrgSnap;
            })));
            organizationsAffiliatedWithNewspaper = organizationsAffiliatedWithNewspaper.filter(organization => organization !== null);
            const orgNames = yield Promise.all(allowedOrganizations.map((organization) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                const organizationSnap = yield organization.get();
                return (_a = organizationSnap.data()) === null || _a === void 0 ? void 0 : _a.name;
            })));
            const numberOfOrgMembers = yield Promise.all(allowedOrganizations.map((organization) => __awaiter(void 0, void 0, void 0, function* () {
                const usersInOrg = yield ctx
                    .usersRef()
                    .where('allowedOrganizations', 'array-contains', organization)
                    .get();
                const userSnapshots = usersInOrg.docs;
                const customerFromCustomerOrg = yield Promise.all(userSnapshots.map((user) => __awaiter(void 0, void 0, void 0, function* () {
                    const customer = yield ctx
                        .customersRef()
                        .where('user', '==', user.ref)
                        .where('organization', '==', activeOrganization.ref)
                        .where('verified', '==', true)
                        .get();
                    if (customer.docs.length) {
                        return customer.docs[0];
                    }
                    return null;
                })));
                const filteredCustomerFromCustomerOrg = customerFromCustomerOrg.filter(customer => {
                    return customer !== null;
                });
                return filteredCustomerFromCustomerOrg.length;
            })));
            setCustomerOrganizations(organizationsAffiliatedWithNewspaper);
            setAllowedOrganizationNames(orgNames);
            setMembersInAllowedOrganization(numberOfOrgMembers);
            setLoadingCustomerOrganizations(false);
        });
        void getCustomerOrganizations();
    }, [
        safeStringify(customerData.customer.data()),
        safeStringify(customerData.user.data()),
        safeStringify((_a = customerData.organization) === null || _a === void 0 ? void 0 : _a.data())
    ]);
    useEffect(() => {
        void customerData.customer.ref.update({
            isAccountOnHold: !!isAccountOnHold
        });
    }, [isAccountOnHold]);
    const REGISTERED = 'Registered';
    const NOT_REGISTERED = 'Not registered';
    const header = (React.createElement("div", { className: "text-gray-800 font-semibold text-xl" }, customerRow.userName || '--'));
    const subheader = (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "text-xs text-grey-400 font-medium" },
            customerData.user.data().email,
            React.createElement("span", { className: "mx-3" }, "|")),
        React.createElement(Badge, { status: customerData.user.data().lastSignInTime ? 'success' : 'critical' },
            React.createElement("div", { className: "flex items-center" },
                React.createElement(DotIcon, { className: classNames({
                        'text-red-700': !customerData.user.data().lastSignInTime,
                        'text-green-625': !!customerData.user.data().lastSignInTime
                    }) }),
                React.createElement("div", { className: "pl-1" }, customerData.user.data().lastSignInTime
                    ? REGISTERED
                    : NOT_REGISTERED)))));
    return (React.createElement(Drawer, { open: true, closeDrawer: closeDrawer, header: React.createElement("div", null,
            header,
            subheader) },
        React.createElement(CustomerDrawerHeader, { title: title, setTitle: setTitle, customerData: customerData }),
        React.createElement(HorizontalDivider, null),
        React.createElement("div", null,
            title === 'Details' && (React.createElement(CustomerDrawerDetailsTab, { userName: (_b = customerRow.userName) !== null && _b !== void 0 ? _b : undefined, address: (_c = customerRow.address) !== null && _c !== void 0 ? _c : undefined, addressLine2: (_d = customerRow.addressLine2) !== null && _d !== void 0 ? _d : undefined, phone: (_e = customerRow.phone) !== null && _e !== void 0 ? _e : undefined, city: (_f = customerRow.city) !== null && _f !== void 0 ? _f : undefined, state: (_g = customerRow.state) !== null && _g !== void 0 ? _g : undefined, zipCode: (_h = customerRow.zipCode) !== null && _h !== void 0 ? _h : undefined, internalID: (_j = customerRow.internalID) !== null && _j !== void 0 ? _j : undefined, showOrganizationName: !((_m = (_l = (_k = customerData === null || customerData === void 0 ? void 0 : customerData.user) === null || _k === void 0 ? void 0 : _k.data()) === null || _l === void 0 ? void 0 : _l.allowedOrganizations) === null || _m === void 0 ? void 0 : _m.length), organizationName: customerRow.organizationName, customerData: customerData })),
            title === 'Organization' && (React.createElement(CustomerDrawerOrganizationTab, { customerOrganizations: customerOrganizations, loadingCustomerOrganizations: loadingCustomerOrganizations, setShowCustomerOrganizationDrawer: () => {
                    setShowCustomerOrganizationDrawer(true);
                    closeDrawer();
                }, setCustomerOrganizationToDisplay: setCustomerOrganization, allowedOrganizationNames: allowedOrganizationNames, membersInAllowedOrganization: membersInAllowedOrganization })),
            title === 'Settings' && (React.createElement(CustomerDrawerSettingsTab, { linerRateDescription: (_o = customerData.defaultLinerRate.data()) === null || _o === void 0 ? void 0 : _o.description, displayRateDescription: (_p = customerData.defaultDisplayRate.data()) === null || _p === void 0 ? void 0 : _p.description, upfrontPayment: REQUIRE_UPFRONT_PAYMENT_OPTIONS_ARRAY[requireUpfrontPaymentId], affidavitsBeforePayment: customerRow.enableAffidavitsBeforePayment, activeOrganization: activeOrganization, allowBulkInvoicing: customerRow.bulkPaymentEnabled, invoicedOutsideColumn: customerRow.invoicedOutsideColumn, hideDigitalAffidavits: hideDigitalAffidavits, allowAffidavitEmail: customerRow.allowAffidavitEmail })),
            React.createElement(CustomerDrawerFooter, { isAccountOnHold: isAccountOnHold, setIsAccountOnHold: setIsAccountOnHold, setShowEditCustomerModal: setShowEditCustomerModal }))));
};
export default CustomerDrawer;
