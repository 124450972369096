var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CTooltip from 'components/Tooltip';
import { exists } from 'lib/types';
import { getUserPaymentMethods } from 'utils/sources';
import { logAndCaptureException } from 'utils';
import PayWithCheckButton from './buttons/PayWithCheckButton';
import PayWithCreditCardButton from './buttons/PayWithCreditCardButton';
import SavedPaymentMethods from './SavedPaymentMethods';
export function PayInvoicePaymentMethods({ payInvoiceData, savedPaymentsAccess, selectedPaymentMethod, selectedSavedMethodId, user, selectPaymentClickHandler, setShowLoginModal }) {
    var _a;
    const { invoice, requireUpfrontPayment, preventLatePayment } = payInvoiceData;
    const [userPaymentMethods, setUserPaymentMethods] = useState();
    const disableCheckPayment = requireUpfrontPayment || preventLatePayment;
    const loadSavedPaymentData = () => __awaiter(this, void 0, void 0, function* () {
        var _b;
        if (!exists(user))
            return;
        if (savedPaymentsAccess.disableACH && savedPaymentsAccess.disableSavedCards)
            return;
        try {
            const res = yield getUserPaymentMethods(user, (_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.advertiserOrganization);
            setUserPaymentMethods(res);
        }
        catch (e) {
            logAndCaptureException(e, 'Failed to load bank accounts and credit cards', { invoice: invoice.id, user: user.id });
        }
    });
    useEffect(() => {
        void loadSavedPaymentData();
    }, [(_a = invoice.data().advertiserOrganization) === null || _a === void 0 ? void 0 : _a.id, user === null || user === void 0 ? void 0 : user.id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(PayWithCreditCardButton, { disabled: preventLatePayment, selected: selectedPaymentMethod === 'card', clickHandler: selectPaymentClickHandler }),
            React.createElement(CTooltip, { helpText: requireUpfrontPayment
                    ? 'Because payment is required before your notice can be published, checks cannot be processed in time.'
                    : '', classes: "contents" },
                React.createElement(PayWithCheckButton, { disabled: disableCheckPayment, selected: selectedPaymentMethod === 'check', clickHandler: selectPaymentClickHandler }))),
        React.createElement(SavedPaymentMethods, { payInvoiceData: payInvoiceData, userPaymentMethods: userPaymentMethods, savedPaymentsAccess: savedPaymentsAccess, user: user, selectPaymentClickHandler: selectPaymentClickHandler, selectedSavedMethodId: selectedSavedMethodId, setShowLoginModal: setShowLoginModal })));
}
