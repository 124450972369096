var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { OccupationType } from 'lib/enums';
import { includeSearchParams } from 'utils/urls';
import api from 'api';
import { exists } from 'lib/types';
import { PUBLISHER_CAN_REGISTER } from '../../../constants';
import AuthActions from '../../../redux/auth';
import government from './government.svg';
import lawyer from './lawyer.svg';
import individual from './individual.svg';
import otherOrganization from './otherOrganization.svg';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import StepsCounter from '../StepsCounter';
import backgroundImg from '../backgroundImg.svg';
import RegistrationHeader from '../RegistrationHeader';
const mapStateToProps = (state) => ({
    user: state.auth.user ? state.auth.user : null
});
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
const Occupation = ({ user, push }) => {
    const [occupationType, setOccupationType] = useState();
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [anonPlacement, setAnonPlacement] = useState('');
    const law = OccupationType.lawyer.value;
    const gov = OccupationType.government_official.value;
    const ind = OccupationType.individual.value;
    const otherOrg = OccupationType.other_organization.value;
    const occupationTextStyle = 'text-center mb-1 font-medium leading-4';
    useEffect(() => {
        if (occupationType)
            setDisableSubmit(false);
    }, [occupationType]);
    useEffect(() => {
        if (!sessionStorage.getItem('placementRedirectUrl'))
            return;
        const { placementRedirectUrl } = sessionStorage;
        setAnonPlacement(placementRedirectUrl);
    }, []);
    useEffect(() => {
        if (!sessionStorage.getItem('selectedOrganizations'))
            return;
        push('/register/organization');
    }, []);
    const onBackClick = () => {
        push(includeSearchParams('/register/welcome'));
    };
    const nextButtonClicked = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (occupationType !== null) {
            yield completeBasicSignup();
        }
    });
    const completeBasicSignup = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!exists(user))
            return;
        setLoading(true);
        const response = yield api.post('users/assign-occupation', {
            email: user.data().email,
            occupation: occupationType
        });
        if (response.success) {
            // Artifical delay before navigating to avoid showing a glimp of previous screen
            setTimeout(() => {
                push('/register/organization');
            }, 500);
        }
        else {
            setLoading(false);
            console.error(response.error);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-gray-50", style: {
                backgroundImage: `url(${backgroundImg})`,
                width: '100vw',
                height: '100vh'
            } },
            React.createElement(RegistrationHeader, null),
            React.createElement("div", null,
                React.createElement("div", { className: "min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 -mt-12" },
                    React.createElement("div", { className: "max-w-3xl w-full" },
                        React.createElement("div", null,
                            React.createElement(StepsCounter, { maxStep: '3', stepCount: '2', isStepDone: !disableSubmit }),
                            React.createElement("p", { className: "mt-8 text-center text-2xl leading-8 text-gray-900 font-semibold" }, PUBLISHER_CAN_REGISTER
                                ? 'What best describes your work?'
                                : 'Please select your occupation.'),
                            React.createElement("p", { className: "mt-4 text-center text-sm font-medium text-gray-600" }, "Almost there! Choose a role that best describes your work in relation to placing a notice.")),
                        React.createElement("div", null,
                            React.createElement("div", { className: "mt-10 grid grid-cols-1 gap-y-9 gap-x-2 sm:grid-cols-8" },
                                React.createElement("label", { role: "presentation", tabIndex: 0, onKeyDown: e => {
                                        if (e.key === 'Enter')
                                            setOccupationType(gov);
                                    }, htmlFor: "government", className: `h-44 outline-none focus:border-blue-500 mt-1 rounded-md shadow-sm sm:col-span-2 bg-white border p-4 cursor-pointer
              ${occupationType === gov ? 'border-blue-500' : ''}
              ` },
                                    React.createElement("img", { src: government, alt: "government", className: `m-auto`, style: { minHeight: 100 } }),
                                    React.createElement("input", { id: "government", type: "checkbox", className: `hidden`, name: "occupation", onClick: () => {
                                            setOccupationType(gov);
                                        } }),
                                    React.createElement("p", { className: `${occupationTextStyle} pt-3` }, "Government")),
                                React.createElement("label", { htmlFor: "lawyer", role: "presentation", tabIndex: 0, onKeyDown: e => {
                                        if (e.key === 'Enter')
                                            setOccupationType(law);
                                    }, className: `h-44 outline-none focus:border-blue-500 mt-1 rounded-md shadow-sm sm:col-span-2 bg-white border p-4 cursor-pointer
              ${occupationType === law ? 'border-blue-500' : ''}
              ` },
                                    React.createElement("img", { src: lawyer, alt: "lawyer", className: `m-auto`, style: { minHeight: 100 } }),
                                    React.createElement("input", { id: "lawyer", type: "checkbox", className: `hidden`, name: "occupation", onClick: () => {
                                            setOccupationType(law);
                                        } }),
                                    React.createElement("p", { className: `${occupationTextStyle} pt-3` }, "Legal Services")),
                                React.createElement("label", { htmlFor: "otherOrganization", role: "presentation", tabIndex: 0, onKeyDown: e => {
                                        if (e.key === 'Enter')
                                            setOccupationType(otherOrg);
                                    }, className: `h-44 outline-none focus:border-blue-500 mt-1 rounded-md shadow-sm sm:col-span-2 bg-white border pl-4 pr-4 pb-4 cursor-pointer
              ${occupationType === otherOrg ? 'border-blue-500' : ''}
              ` },
                                    React.createElement("img", { src: otherOrganization, alt: "otherOrganization", className: `m-auto`, style: { minHeight: 100 } }),
                                    React.createElement("input", { id: "otherOrganization", type: "checkbox", className: `hidden`, name: "occupation", onClick: () => {
                                            setOccupationType(otherOrg);
                                        } }),
                                    React.createElement("p", { className: `${occupationTextStyle} pt-1` }, "Other Organization")),
                                React.createElement("label", { htmlFor: "individual", role: "presentation", tabIndex: 0, onKeyDown: e => {
                                        if (e.key === 'Enter')
                                            setOccupationType(ind);
                                    }, className: `h-44 outline-none focus:border-blue-500 mt-1 rounded-md shadow-sm sm:col-span-2 bg-white border p-4 cursor-pointer
              ${occupationType === ind ? 'border-blue-500' : ''}
              ` },
                                    React.createElement("img", { src: individual, alt: "individual", className: `m-auto`, style: { minHeight: 100 } }),
                                    React.createElement("input", { id: "individual", type: "checkbox", className: `hidden`, name: "occupation", onClick: () => {
                                            setOccupationType(ind);
                                        } }),
                                    React.createElement("p", { className: `${occupationTextStyle} pt-3` }, "Individual")))))))),
        React.createElement(AdvertiserRegistrationFooter, { id: "occupation-submit", backButtonText: "Go back", nextButtonText: "Continue", onBackButtonClick: onBackClick, onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: disableSubmit, hideBackButton: !(PUBLISHER_CAN_REGISTER && !anonPlacement) })));
};
export default connect(mapStateToProps, mapDispatchToProps)(Occupation);
