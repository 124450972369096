import React from 'react';
import { useTranslation } from 'react-i18next';
import FreeformCModal from 'components/modals/FreeFormCModal';
const ProcessPaymentModal = ({ setOpen, setProcessCheck, invoice }) => {
    const handleProcessCheck = () => {
        setProcessCheck(true);
        setOpen(false);
    };
    const handleProcessCard = () => {
        window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
        setOpen(false);
    };
    const { t } = useTranslation();
    return (React.createElement(FreeformCModal, { id: "process-payment-modal", setOpen: setOpen, header: "Accept a direct payment", body: "" },
        React.createElement("section", null,
            React.createElement("div", { className: "text-sm leading-5 text-gray-700" },
                React.createElement("p", null,
                    "If you have received ",
                    t('process-payment.payment'),
                    " for this notice, click ",
                    React.createElement("b", null, t('process-payment.paid-by-outside-payment')),
                    " to mark this as paid."),
                React.createElement("br", null),
                React.createElement("p", null,
                    "If you want to process a card payment for this notice, click",
                    ' ',
                    React.createElement("b", null, "Process Card"),
                    " to open the customer's invoice and input their card information.")),
            React.createElement("div", { className: "flex flex-col md:inline-block" },
                React.createElement("button", { id: `process-check`, onClick: handleProcessCheck, className: "bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-full md:w-40" }, t('process-payment.paid-by-outside-payment')),
                React.createElement("button", { id: `process-card`, onClick: handleProcessCard, className: "ml-0 md:ml-4 bg-white md:bg-blue-200 w-auto border border-blue-200 md:border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-full md:w-40" }, "Process Card")))));
};
export default ProcessPaymentModal;
