var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper, Grid } from '@material-ui/core';
import { getMemoText } from 'lib/publishers/index';
import { requestDisplayParameters } from 'lib/helpers';
import { getRelevantRateString, getApplicableRate, dbToUICurrencyString } from 'lib/pricing';
import { RateType, ELabels, NoticeType } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { INDESIGN_URL } from '../../../constants';
const styles = (theme) => createStyles({
    iconContainer: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    memo: {
        padding: theme.spacing(1)
    }
});
const InvoiceFormMemo = ({ noticeSnap, terms, classes, customMemo, setCustomMemo, newspaper, currency }) => {
    var _a;
    const [displayParams, setDisplayParams] = useState(noticeSnap.data().displayParams);
    const [rate, setRate] = useState();
    const [columns, setColumns] = useState(1);
    const fetchDisplayParams = () => __awaiter(void 0, void 0, void 0, function* () {
        let displayParameters;
        if (noticeSnap.data().noticeType !== NoticeType.display_ad.value) {
            displayParameters = yield requestDisplayParameters(getFirebaseContext(), noticeSnap, {
                format: 'jpg',
                type: 'DISPLAY_PARAMETERS',
                url: INDESIGN_URL
            }, window.DOMParser);
        }
        else {
            displayParameters = noticeSnap.data().displayParams;
        }
        setDisplayParams(displayParameters);
    });
    // END OF updateNoticeRefereceId
    const fetchColumns = () => __awaiter(void 0, void 0, void 0, function* () {
        setColumns(noticeSnap.data().columns);
    });
    const fetchRate = () => __awaiter(void 0, void 0, void 0, function* () {
        const dbRate = yield noticeSnap.data().rate.get();
        setRate(dbRate.data());
    });
    const ordinalSuffix = (num) => {
        const j = num % 10;
        const k = num % 100;
        if (j === 1 && k !== 11) {
            return 'st';
        }
        if (j === 2 && k !== 12) {
            return 'nd';
        }
        if (j === 3 && k !== 13) {
            return 'rd';
        }
        return 'th';
    };
    const lineDesc = () => {
        var _a;
        if (!rate)
            return '';
        const publishNum = noticeSnap.data().publicationDates.length;
        let ratesDesc = '';
        for (let i = 1; i <= publishNum; i++) {
            ratesDesc += `${currency}${dbToUICurrencyString(getApplicableRate(noticeSnap.data().publicationDates.length, rate, i))} / ${(_a = RateType.by_value(rate.rateType)) === null || _a === void 0 ? void 0 : _a.singular} for ${i}${ordinalSuffix(i)} run${i !== publishNum ? ', ' : ''}`;
        }
        return ratesDesc;
    };
    const boldRates = () => {
        if (!(rate === null || rate === void 0 ? void 0 : rate.bold_words) && !(rate === null || rate === void 0 ? void 0 : rate.line_with_bold_words))
            return;
        if (rate === null || rate === void 0 ? void 0 : rate.bold_words)
            return `Bolded Words: ${displayParams === null || displayParams === void 0 ? void 0 : displayParams.boldWords}`;
        if (rate === null || rate === void 0 ? void 0 : rate.line_with_bold_words)
            return `Bolded Lines: ${displayParams === null || displayParams === void 0 ? void 0 : displayParams.nonTableBoldedLines}`;
    };
    const boldStyling = () => {
        if (rate === null || rate === void 0 ? void 0 : rate.line_with_bold_words)
            return `. Bolded Styling -
      ${currency}${dbToUICurrencyString(rate.line_with_bold_words)} / Line with bolding.`;
        if (rate === null || rate === void 0 ? void 0 : rate.bold_words)
            return `. Bolded Styling -
      ${currency}${dbToUICurrencyString(rate.bold_words)} / Words.`;
    };
    useEffect(() => {
        void fetchRate();
        void fetchDisplayParams();
        void fetchColumns();
    }, []);
    if (!displayParams || !rate || !columns)
        return (React.createElement(Paper, { className: classes.memo },
            React.createElement(Grid, { container: true, direction: "row", alignItems: "center", justify: "center" },
                React.createElement(Grid, { item: true },
                    React.createElement(CircularProgress, { size: 30 })))));
    const MAX_CHARS_ALLOWED = 500;
    const NUMBER_OF_CURRENT_CHARS = getMemoText(customMemo, (_a = noticeSnap.data()) === null || _a === void 0 ? void 0 : _a.referenceId, newspaper.accountId).length;
    const remainingCharacters = MAX_CHARS_ALLOWED - NUMBER_OF_CURRENT_CHARS;
    const rateType = RateType.by_value(rate.rateType);
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-white shadow overflow-hidden sm:rounded-lg" },
            React.createElement("div", { className: "px-4 py-5 sm:p-0" },
                React.createElement("dl", null,
                    React.createElement("div", { className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, ELabels.reference_id.label),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, noticeSnap.data().referenceId)),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Custom Memo"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            React.createElement("textarea", { rows: 3, value: customMemo, onChange: e => setCustomMemo(e.target.value.slice(0, remainingCharacters)), className: "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" }))),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Terms"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: terms } }))),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Rate"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            rate.description,
                            " ",
                            ' - ',
                            ' ',
                            (rateType === null || rateType === void 0 ? void 0 : rateType.long_description)
                                ? rateType === null || rateType === void 0 ? void 0 : rateType.long_description
                                : rate.runBased
                                    ? lineDesc()
                                    : `${currency}${dbToUICurrencyString(getApplicableRate(noticeSnap.data().publicationDates.length, rate, 1))} / ${rateType === null || rateType === void 0 ? void 0 : rateType.singular}`,
                            ((rate === null || rate === void 0 ? void 0 : rate.bold_words) || (rate === null || rate === void 0 ? void 0 : rate.line_with_bold_words)) &&
                                boldStyling(),
                            React.createElement("br", null),
                            getRelevantRateString(rate, displayParams, columns),
                            ' / ',
                            React.createElement("br", null),
                            boldRates())))))));
};
export default withStyles(styles)(InvoiceFormMemo);
