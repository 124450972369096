import React from 'react';
export const BrandingIcon = (React.createElement("svg", { width: "21", height: "20", viewBox: "0 0 21 20", fill: "none" },
    React.createElement("path", { d: "M5.70833 18.25C4.69167 18.25 3.71665 17.8637 2.99776 17.1761C2.27887 16.4884 1.875 15.5558 1.875 14.5833V3.58333C1.875 3.0971 2.07693 2.63079 2.43638 2.28697C2.79582 1.94315 3.28334 1.75 3.79167 1.75H7.625C8.13333 1.75 8.62084 1.94315 8.98029 2.28697C9.33973 2.63079 9.54167 3.0971 9.54167 3.58333V14.5833C9.54167 15.5558 9.1378 16.4884 8.41891 17.1761M5.70833 18.25C6.725 18.25 7.70002 17.8637 8.41891 17.1761M5.70833 18.25H17.2083C17.7167 18.25 18.2042 18.0568 18.5636 17.713C18.9231 17.3692 19.125 16.9029 19.125 16.4167V12.75C19.125 12.2638 18.9231 11.7975 18.5636 11.4536C18.2042 11.1098 17.7167 10.9167 17.2083 10.9167H14.963M8.41891 17.1761L12.4849 13.2869L16.5509 9.39775C16.9102 9.05395 17.1121 8.58772 17.1121 8.10158C17.1121 7.61545 16.9102 7.14922 16.5509 6.80542L13.8398 4.21217C13.4804 3.86847 12.9929 3.67539 12.4847 3.67539C11.9765 3.67539 11.4891 3.86847 11.1296 4.21217L9.54167 5.73108M5.70833 14.5833H5.71792", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const LightBulb = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M7.663 15H12.336H7.663ZM10 1V2V1ZM16.364 3.636L15.657 4.343L16.364 3.636ZM19 10H18H19ZM2 10H1H2ZM4.343 4.343L3.636 3.636L4.343 4.343ZM6.464 13.536C5.76487 12.8367 5.2888 11.9458 5.09598 10.9759C4.90316 10.006 5.00225 9.00076 5.38073 8.08721C5.75921 7.17366 6.40007 6.39284 7.22229 5.84349C8.0445 5.29414 9.01115 5.00093 10 5.00093C10.9889 5.00093 11.9555 5.29414 12.7777 5.84349C13.5999 6.39284 14.2408 7.17366 14.6193 8.08721C14.9977 9.00076 15.0968 10.006 14.904 10.9759C14.7112 11.9458 14.2351 12.8367 13.536 13.536L12.988 14.083C12.6747 14.3963 12.4262 14.7683 12.2567 15.1777C12.0872 15.5871 11.9999 16.0259 12 16.469V17C12 17.5304 11.7893 18.0391 11.4142 18.4142C11.0391 18.7893 10.5304 19 10 19C9.46957 19 8.96086 18.7893 8.58579 18.4142C8.21071 18.0391 8 17.5304 8 17V16.469C8 15.574 7.644 14.715 7.012 14.083L6.464 13.536Z", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const DashboardIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M7 17V11C7 10.4696 6.78929 9.96086 6.41421 9.58579C6.03914 9.21071 5.53043 9 5 9H3C2.46957 9 1.96086 9.21071 1.58579 9.58579C1.21071 9.96086 1 10.4696 1 11V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H5C5.53043 19 6.03914 18.7893 6.41421 18.4142C6.78929 18.0391 7 17.5304 7 17ZM7 17V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5H11C11.5304 5 12.0391 5.21071 12.4142 5.58579C12.7893 5.96086 13 6.46957 13 7V17M7 17C7 17.5304 7.21071 18.0391 7.58579 18.4142C7.96086 18.7893 8.46957 19 9 19H11C11.5304 19 12.0391 18.7893 12.4142 18.4142C12.7893 18.0391 13 17.5304 13 17M13 17V3C13 2.46957 13.2107 1.96086 13.5858 1.58579C13.9609 1.21071 14.4696 1 15 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H15C14.4696 19 13.9609 18.7893 13.5858 18.4142C13.2107 18.0391 13 17.5304 13 17Z", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const Link = (React.createElement("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none" },
    React.createElement("path", { d: "M9.94717 7.05286C9.35333 6.4592 8.54802 6.1257 7.70833 6.1257C6.86865 6.1257 6.06334 6.4592 5.4695 7.05286L2.30283 10.2195C2.00039 10.5116 1.75914 10.8611 1.59318 11.2474C1.42722 11.6338 1.33986 12.0493 1.33621 12.4698C1.33255 12.8902 1.41268 13.3072 1.5719 13.6964C1.73112 14.0856 1.96626 14.4391 2.26358 14.7364C2.56091 15.0338 2.91447 15.2689 3.30365 15.4281C3.69282 15.5874 4.1098 15.6675 4.53027 15.6638C4.95074 15.6602 5.36627 15.5728 5.75262 15.4068C6.13896 15.2409 6.48839 14.9996 6.7805 14.6972L7.65292 13.8256M7.05283 9.94719C7.64667 10.5409 8.45198 10.8744 9.29167 10.8744C10.1314 10.8744 10.9367 10.5409 11.5305 9.94719L14.6972 6.78053C15.274 6.18329 15.5932 5.38338 15.586 4.55309C15.5788 3.7228 15.2457 2.92856 14.6586 2.34144C14.0715 1.75431 13.2772 1.42128 12.4469 1.41406C11.6166 1.40685 10.8167 1.72603 10.2195 2.30286L9.34867 3.17369", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const Social = (React.createElement("svg", { width: "18", height: "14", viewBox: "0 0 18 14", fill: "none" },
    React.createElement("path", { d: "M12.75 13H16.5V11.5C16.5 11.0324 16.3542 10.5764 16.0831 10.1955C15.812 9.81451 15.4289 9.52749 14.9871 9.37431C14.5453 9.22114 14.0667 9.20941 13.6179 9.34076C13.1692 9.47212 12.7725 9.74003 12.483 10.1072M12.75 13H5.25M12.75 13V11.5C12.75 11.008 12.6555 10.5377 12.483 10.1072M12.483 10.1072C12.2045 9.41122 11.7238 8.8146 11.103 8.39434C10.4822 7.97409 9.74969 7.74947 9 7.74947C8.25031 7.74947 7.51782 7.97409 6.897 8.39434C6.27618 8.8146 5.79552 9.41122 5.517 10.1072M5.25 13H1.5V11.5C1.50003 11.0324 1.64575 10.5764 1.9169 10.1955C2.18804 9.81451 2.57114 9.52749 3.01294 9.37431C3.45474 9.22114 3.93328 9.20941 4.38205 9.34076C4.83082 9.47212 5.22752 9.74003 5.517 10.1072M5.25 13V11.5C5.25 11.008 5.3445 10.5377 5.517 10.1072M11.25 3.25C11.25 3.84674 11.0129 4.41903 10.591 4.84099C10.169 5.26295 9.59674 5.5 9 5.5C8.40326 5.5 7.83097 5.26295 7.40901 4.84099C6.98705 4.41903 6.75 3.84674 6.75 3.25C6.75 2.65326 6.98705 2.08097 7.40901 1.65901C7.83097 1.23705 8.40326 1 9 1C9.59674 1 10.169 1.23705 10.591 1.65901C11.0129 2.08097 11.25 2.65326 11.25 3.25ZM15.75 5.5C15.75 5.89782 15.592 6.27936 15.3107 6.56066C15.0294 6.84196 14.6478 7 14.25 7C13.8522 7 13.4706 6.84196 13.1893 6.56066C12.908 6.27936 12.75 5.89782 12.75 5.5C12.75 5.10218 12.908 4.72064 13.1893 4.43934C13.4706 4.15804 13.8522 4 14.25 4C14.6478 4 15.0294 4.15804 15.3107 4.43934C15.592 4.72064 15.75 5.10218 15.75 5.5ZM5.25 5.5C5.25 5.89782 5.09196 6.27936 4.81066 6.56066C4.52936 6.84196 4.14782 7 3.75 7C3.35218 7 2.97064 6.84196 2.68934 6.56066C2.40804 6.27936 2.25 5.89782 2.25 5.5C2.25 5.10218 2.40804 4.72064 2.68934 4.43934C2.97064 4.15804 3.35218 4 3.75 4C4.14782 4 4.52936 4.15804 4.81066 4.43934C5.09196 4.72064 5.25 5.10218 5.25 5.5Z", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const OpenLinkIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none" },
    React.createElement("path", { d: "M6.33334 3.00001H3.00001C2.55798 3.00001 2.13406 3.1756 1.8215 3.48817C1.50894 3.80073 1.33334 4.22465 1.33334 4.66668V13C1.33334 13.442 1.50894 13.866 1.8215 14.1785C2.13406 14.4911 2.55798 14.6667 3.00001 14.6667H11.3333C11.7754 14.6667 12.1993 14.4911 12.5119 14.1785C12.8244 13.866 13 13.442 13 13V9.66668M9.66668 1.33334H14.6667M14.6667 1.33334V6.33334M14.6667 1.33334L6.33334 9.66668", stroke: "#2F80ED", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const Arrow = (React.createElement("svg", { width: "10", height: "7", viewBox: "0 0 10 7", fill: "none" },
    React.createElement("path", { d: "M8.2 0.75713C8.39257 0.612701 8.63078 0.542582 8.87089 0.559646C9.111 0.57671 9.3369 0.679811 9.50711 0.850023C9.67732 1.02023 9.78042 1.24613 9.79749 1.48624C9.81455 1.72635 9.74443 1.96456 9.6 2.15713L5.6 6.15713C5.41307 6.34036 5.16175 6.44299 4.9 6.44299C4.63825 6.44299 4.38693 6.34036 4.2 6.15713L0.200002 2.15713C0.0555725 1.96456 -0.014547 1.72635 0.00251711 1.48624C0.0195812 1.24613 0.122683 1.02023 0.292895 0.850023C0.463106 0.679811 0.689003 0.57671 0.929112 0.559646C1.16922 0.542582 1.40743 0.612701 1.6 0.75713L4.9 4.04713L8.2 0.74713V0.75713Z", fill: "#2F80ED" })));
export const Clipboard = (React.createElement("svg", { width: "16", height: "20", viewBox: "0 0 16 20", fill: "none" },
    React.createElement("path", { d: "M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M8 10H11M8 14H11M5 10H5.01M5 14H5.01", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const DocumentDuplicate = (React.createElement("svg", { width: "18", height: "20", viewBox: "0 0 18 20", fill: "none" },
    React.createElement("path", { d: "M5 5V13C5 13.5304 5.21071 14.0391 5.58579 14.4142C5.96086 14.7893 6.46957 15 7 15H13M5 5V3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H11.586C11.8512 1.00006 12.1055 1.10545 12.293 1.293L16.707 5.707C16.8946 5.89449 16.9999 6.1488 17 6.414V13C17 13.5304 16.7893 14.0391 16.4142 14.4142C16.0391 14.7893 15.5304 15 15 15H13M5 5H3C2.46957 5 1.96086 5.21071 1.58579 5.58579C1.21071 5.96086 1 6.46957 1 7V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H11C11.5304 19 12.0391 18.7893 12.4142 18.4142C12.7893 18.0391 13 17.5304 13 17V15", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const Download = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none" },
    React.createElement("path", { d: "M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
export const Close = (React.createElement("svg", { width: "9", height: "9", viewBox: "0 0 9 9", fill: "none" },
    React.createElement("path", { d: "M8.65734 8.69108C8.4422 8.88558 8.1504 8.99484 7.84614 8.99484C7.54189 8.99484 7.25009 8.88558 7.03494 8.69108L4.50048 6.0722L1.96601 8.69022C1.8598 8.78782 1.73326 8.86544 1.5937 8.91861C1.45414 8.97178 1.30432 8.99943 1.15288 8.99999C1.00143 9.00055 0.851366 8.97399 0.711331 8.92186C0.571296 8.86972 0.444065 8.79303 0.336978 8.69622C0.22989 8.5994 0.145064 8.48438 0.0873936 8.35778C0.0297231 8.23118 0.000348819 8.09551 0.000965484 7.95859C0.00158215 7.82168 0.0321774 7.68623 0.0909864 7.56006C0.149795 7.43389 0.235655 7.31949 0.343611 7.22347L2.98037 4.50086L0.342655 1.77653C0.234699 1.68051 0.148839 1.56611 0.0900304 1.43994C0.0312214 1.31377 0.000626177 1.17832 9.51161e-06 1.04141C-0.000607153 0.904492 0.0287671 0.768822 0.0864377 0.642222C0.144108 0.515622 0.228934 0.400598 0.336022 0.303784C0.443109 0.20697 0.57034 0.130283 0.710375 0.0781449C0.85041 0.0260072 1.00048 -0.000548903 1.15192 8.59907e-06C1.30336 0.000566102 1.45319 0.028226 1.59275 0.081393C1.73231 0.13456 1.85884 0.212182 1.96506 0.309781L4.50048 2.92953L7.03494 0.309781C7.14116 0.212182 7.26769 0.13456 7.40725 0.081393C7.54681 0.028226 7.69664 0.000566102 7.84808 8.59907e-06C7.99952 -0.000548903 8.14959 0.0260072 8.28963 0.0781449C8.42966 0.130283 8.55689 0.20697 8.66398 0.303784C8.77107 0.400598 8.85589 0.515622 8.91356 0.642222C8.97123 0.768822 9.00061 0.904492 8.99999 1.04141C8.99937 1.17832 8.96878 1.31377 8.90997 1.43994C8.85116 1.56611 8.7653 1.68051 8.65734 1.77653L6.02058 4.50086L8.65734 7.22347C8.76401 7.3198 8.84863 7.43419 8.90637 7.5601C8.9641 7.68601 8.99382 7.82098 8.99382 7.95728C8.99382 8.09358 8.9641 8.22854 8.90637 8.35446C8.84863 8.48037 8.76401 8.59476 8.65734 8.69108Z", fill: "#4285F4" })));
