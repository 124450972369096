var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { TemplateTypes } from 'lib/types/templates';
import { ColumnButton } from 'components/ColumnButton';
import { SearchIcon } from 'icons';
import { TextField } from 'components/TextField';
import { DEFAULT_TEMPLATE_ID } from 'lib/constants';
/*
 * Displays the header for the template settings page
 */
export default function TemplateSettingsHeader({ activeOrganization, setTemplatesFilter, setEditedTemplate, templatesFilter }) {
    /** Adds a new template to the database for the user */
    const addTemplate = () => __awaiter(this, void 0, void 0, function* () {
        const defaultTemplate = yield getFirebaseContext()
            .adTemplatesRef()
            .doc(DEFAULT_TEMPLATE_ID)
            .get();
        if (!exists(defaultTemplate)) {
            throw new Error(`Default template does not exist with ID ${DEFAULT_TEMPLATE_ID}`);
        }
        const newTemplateRef = yield getFirebaseContext().adTemplatesRef().add({
            organization: activeOrganization.ref,
            downloadUrl: defaultTemplate.data().downloadUrl,
            name: 'New Template',
            type: TemplateTypes.legal,
            columnsWide: 1
        });
        const newTemplate = yield newTemplateRef.get();
        if (!exists(newTemplate)) {
            throw new Error(`New template does not exist with ID ${newTemplateRef.id}`);
        }
        setEditedTemplate(newTemplate);
    });
    return (React.createElement("div", { className: "flex pl-7 pr-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "w-full font-medium" },
            React.createElement("p", { className: "text-xl text-blue-800" }, "Template Settings"),
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" }, "Edit the InDesign template used to generate your notices.")),
        React.createElement("div", { className: "flex w-1/2 items-center" },
            React.createElement("div", { className: "flex-1 mr-5" },
                React.createElement(TextField, { id: 'templates-search', labelText: '', placeholder: "Search", size: "small", value: templatesFilter, onChange: setTemplatesFilter, prefix: React.createElement(SearchIcon, { className: "w-5 text-grey-300", "aria-hidden": "true" }) })),
            React.createElement(ColumnButton, { buttonText: "Add Template", onClick: addTemplate, primary: true, size: "md" }))));
}
