var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { buildingStyles, extractCustomerOrganizationDataFromOrganization } from 'routes/settings/publisher/Customers';
import { exists } from 'lib/types';
import { BuildingIcon } from 'icons';
import CustomerTableLoader from './CustomerTableLoader';
export const CustomerDrawerOrganizationTab = ({ customerOrganizations, loadingCustomerOrganizations, setShowCustomerOrganizationDrawer, setCustomerOrganizationToDisplay, allowedOrganizationNames, membersInAllowedOrganization }) => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "pt-4 pb-16 h-75vh max-h-full overflow-y-scroll hide-scrollbar text-sm leading-6 font-medium" },
            loadingCustomerOrganizations && React.createElement(CustomerTableLoader, null),
            !loadingCustomerOrganizations &&
                (customerOrganizations === null || customerOrganizations === void 0 ? void 0 : customerOrganizations.map((customerOrganization, index) => {
                    return (React.createElement("div", { key: index, className: "flex w-full hover:bg-blue-50 cursor-pointer", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            if (exists(customerOrganization)) {
                                const customerOrganizationTableData = yield extractCustomerOrganizationDataFromOrganization(customerOrganization);
                                setShowCustomerOrganizationDrawer();
                                setCustomerOrganizationToDisplay(customerOrganizationTableData);
                            }
                        }) },
                        React.createElement("div", { className: "px-4 flex w-full border-b border-grey-50" },
                            React.createElement("div", { className: "flex w-full items-center justify-between pl-4 pb-4 pt-4" },
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: `${buildingStyles[index % buildingStyles.length]} w-10 h-10 rounded-full flex items-center justify-center` },
                                        React.createElement(BuildingIcon, { className: "w-5 h-5" })),
                                    React.createElement("div", { className: "inline-block align-middle" },
                                        React.createElement("div", { className: "pl-4 text-grey-500" }, (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().name) ||
                                            allowedOrganizationNames[index] ||
                                            ''),
                                        React.createElement("div", { className: "pl-4 mt-1 text-grey-400" },
                                            membersInAllowedOrganization[index],
                                            " members"))),
                                React.createElement("div", { className: "pl-4 mt-1 text-grey-400" }, (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().internalID) || '--')))));
                })))));
};
