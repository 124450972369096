var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { cdnIfy, DEFAULT_DPI, removeUndefinedFields } from 'lib/helpers';
import Firebase from 'EnoticeFirebase';
import { wordToHtml } from 'utils/word';
import { cloudConvertFile, convertToPdf, rtfToHtml } from 'utils/convertapi';
import { FileType } from 'lib/types/upload';
import { csvToHtml } from 'utils/csv';
import moment from 'moment';
import { exists } from 'lib/types';
import { OrganizationType, RoleType, NoticeType, State } from 'lib/enums';
import { disableNonPublishingDays, publishingDayEnumValuesFromDeadlines, getIsAfterPublishingDeadline } from 'lib/utils/deadlines';
import { getRole } from 'utils/permissions';
import * as EmailValidator from 'email-validator';
import api from 'api';
import { getAccountNumberForNotice } from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { customerFetchMethods, customerValidationState } from 'types/customers';
import { DEFAULT_ACCEPT } from 'lib/constants';
import { logAndCaptureException } from 'utils';
import { getLocationParams } from 'utils/urls';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { NoticeFileTypes } from 'lib/types/notice';
export const CONFIRM_NEWSPAPER = 'newspaper-select';
export const CONFIRM_NOTICE_TYPE = 'notice-type-select';
export const CONTENT_PREPARED = 'content-prepared';
export const CONFIRM_AD = 'confirm-ad';
export const CONFIRM_FILER = 'confirm-filer';
export const CONFIRM_SCHEDULE = 'schedule-decision';
export const MAILED_AFFIDAVIT = 'mailed-affidavit';
export const REFERENCE_ID = 'reference-id';
export const CONFIRM_PROOF = 'confirm-proof';
export const INVOICE_RECIPIENT = 'invoice-recipient';
export const ACCOUNT_INFO = 'account-info';
export const BIDDY_PLANROOM = 'biddy-planroom';
export const NOTICE_BY_MAIL = 'notice-by-mail';
export const PUBLISHER_UPLOAD_TYPEFORM_ORDER = [
    CONTENT_PREPARED,
    CONFIRM_FILER,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const PUBLISHER_UPLOAD_NO_TYPEFORM_ORDER = [
    CONFIRM_FILER,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const PUBLISHER_UPLOAD_NOTICE_TYPE_SELECT = [
    CONFIRM_NOTICE_TYPE,
    CONFIRM_FILER,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const PUBLISHER_EDIT_NOTICE_FLOW_NOTICE_TYPE_SELECT = [
    CONFIRM_NOTICE_TYPE,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const PUBLISHER_EDIT_NOTICE_FLOW_NO_NOTICE_TYPES = [
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const FILER_NEWSPAPER_PLACE_LINK_TYPEFORM_ORDER = [
    CONTENT_PREPARED,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const FILER_NEWSPAPER_PLACE_LINK_NOTICE_TYPE_SELECT = [
    CONFIRM_NOTICE_TYPE,
    CONFIRM_SCHEDULE,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const FILER_NEWSPAPER_PLACE_LINK_NOTICE_TYPE_SELECT_CUSTOM_DRAFT = [
    CONFIRM_NOTICE_TYPE,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const FILER_NEWSPAPER_PLACE_LINK_NO_TYPEFORM_ORDER = [
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const NATIONAL_SITE_TYPEFORM_ORDER = [
    CONFIRM_NEWSPAPER,
    CONTENT_PREPARED,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const NATIONAL_SITE_NO_TYPEFORM_ORDER = [
    CONFIRM_NEWSPAPER,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const FILER_NATIONAL_SITE_NOTICE_TYPE_SELECT = [
    CONFIRM_NEWSPAPER,
    CONFIRM_NOTICE_TYPE,
    CONFIRM_SCHEDULE,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const FILER_NATIONAL_SITE_NOTICE_TYPE_SELECT_CUSTOM_DRAFT = [
    CONFIRM_NEWSPAPER,
    CONFIRM_NOTICE_TYPE,
    CONFIRM_SCHEDULE,
    CONFIRM_AD,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
// added for filer when select notice type
export const FILER_NATIONAL_SITE_NOTICE_TYPE_SELECT_EDIT = [
    CONFIRM_SCHEDULE,
    REFERENCE_ID,
    INVOICE_RECIPIENT,
    MAILED_AFFIDAVIT,
    CONFIRM_PROOF
];
export const clientSideSmash = (snapshot, grayscale) => __awaiter(void 0, void 0, void 0, function* () {
    const MAX_PAGES = 30;
    const width = 1500;
    const canvas = document.createElement('canvas');
    canvas.height = 0;
    canvas.width = width;
    const context = canvas.getContext('2d');
    if (context) {
        context.globalAlpha = 1;
    }
    let currentY = 0;
    const images = [];
    for (let page = 0; page < MAX_PAGES; page++) {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        const src = cdnIfy(snapshot.ref.fullPath, {
            cloudinaryTransformations: `dn_${DEFAULT_DPI}/f_png,pg_${page + 1},w_${width}/e_trim/${grayscale ? 'e_grayscale/' : ''}bo_5px_solid_red/q_100/c_crop,g_south,x_0,y_0,w_w_sub_10,h_h_sub_5/bo_5px_solid_red/c_crop,g_north,x_0,y_0,w_w_sub_10,h_h_sub_5/e_trim`
        });
        img.src = src;
        // eslint-disable-next-line no-await-in-loop
        const keepAdding = yield new Promise(resolve => {
            img.addEventListener('load', () => resolve(true));
            img.addEventListener('error', () => resolve(false));
        });
        if (keepAdding) {
            images.push(img);
            canvas.height += img.height;
        }
        else {
            break;
        }
    }
    images.forEach(img => {
        if (context) {
            context.drawImage(img, 0, currentY);
            currentY += img.height;
        }
    });
    const smashedImg = canvas.toDataURL('image/png');
    const smashedSnapshot = yield Firebase.storage()
        .ref()
        .child(`${snapshot.ref.fullPath}_smashed.png`)
        .putString(smashedImg, 'data_url', {
        contentType: 'image/png'
    });
    return smashedSnapshot;
});
export const getPdfFileChanges = (convertedUpload, upload, fileProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const formattedUpload = yield clientSideSmash(convertedUpload, fileProperties.shouldGrayscalePDFDisplays);
    // TODO (APP-36): This should initially be type DISPLAY_AD_COMPONENT
    return {
        noticeType: NoticeType.display_ad.value,
        file: {
            type: NoticeFileTypes.finalized_display_ad,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            firebaseStoragePath: formattedUpload.ref.fullPath,
            originalFileName: fileProperties.originalName,
            fileFormat: fileProperties.originalFormat,
            sanitizedFileName: fileProperties.sanitizedName
        },
        processedDisplay: true
    };
});
export const getTextFileChanges = (file, upload, filerProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const html = yield new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => resolve(reader.result);
    });
    return {
        noticeType: NoticeType.custom.value,
        text: html,
        file: {
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            firebaseStoragePath: upload.ref.fullPath,
            sanitizedFileName: filerProperties.sanitizedName,
            fileFormat: filerProperties.originalFormat,
            originalFileName: filerProperties.sanitizedName
        }
    };
});
export const getWordFileChanges = (file, newspaper, upload, fileProperties) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!newspaper)
        return null;
    const wordContent = (yield new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => resolve(reader.result);
    }));
    const { html, hasImage } = yield wordToHtml(upload.ref.fullPath, (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.cleanVariant);
    if (wordContent.indexOf('word/media/image') !== -1) {
        const input = file.name.split('.').pop();
        if (!input) {
            console.error('Input file format must be specified');
            return null;
        }
        const pdf = yield cloudConvertFile(upload.ref.fullPath, input, FileType.PDF, {
            pages_fit_wide: 1
        });
        const displayUpload = yield Firebase.storage()
            .ref()
            .child(`${upload.ref.fullPath}.pdf`)
            .put(pdf);
        const formattedUpload = yield clientSideSmash(displayUpload, true);
        // TODO (APP-36): This should initially be type DISPLAY_AD_COMPONENT
        return {
            unusedConfirmedHtml: html || '',
            noticeType: NoticeType.display_ad.value,
            file: {
                type: NoticeFileTypes.finalized_display_ad,
                firebaseStoragePath: formattedUpload.ref.fullPath,
                linkToUploadedFile: yield upload.ref.getDownloadURL(),
                originalFileName: fileProperties.sanitizedName,
                fileFormat: fileProperties.originalFormat,
                sanitizedFileName: fileProperties.sanitizedName
            },
            processedDisplay: true
        };
    }
    if (hasImage) {
        const input = file.name.split('.').pop();
        if (!input) {
            console.error('Input file format must be specified');
            return null;
        }
        const pdf = yield cloudConvertFile(upload.ref.fullPath, input, FileType.PDF, {
            pages_fit_wide: 1
        });
        const displayUpload = yield Firebase.storage()
            .ref()
            .child(`${upload.ref.fullPath}.pdf`)
            .put(pdf);
        const formattedUpload = yield clientSideSmash(displayUpload, true);
        // TODO (APP-36): This should initially be type DISPLAY_AD_COMPONENT
        return {
            unusedConfirmedHtml: html || '',
            noticeType: NoticeType.display_ad.value,
            file: {
                type: NoticeFileTypes.finalized_display_ad,
                firebaseStoragePath: formattedUpload.ref.fullPath,
                linkToUploadedFile: yield upload.ref.getDownloadURL(),
                originalFileName: fileProperties.originalName,
                fileFormat: fileProperties.originalFormat,
                sanitizedFileName: fileProperties.sanitizedName
            },
            processedDisplay: true
        };
    }
    return {
        noticeType: NoticeType.custom.value,
        text: html,
        file: {
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            firebaseStoragePath: upload.ref.fullPath,
            originalFileName: fileProperties.sanitizedName,
            fileFormat: fileProperties.originalFormat,
            sanitizedFileName: fileProperties.sanitizedName
        }
    };
});
export const getExcelFileChanges = (html, upload, fileProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const pdf = yield cloudConvertFile(upload.ref.fullPath, 'xlsx', FileType.PDF, {
        pages_fit_wide: 1
    });
    const displayUpload = yield Firebase.storage()
        .ref()
        .child(`${upload.ref.fullPath}.pdf`)
        .put(pdf);
    const formattedUpload = yield clientSideSmash(displayUpload, true);
    // TODO (APP-36): This should initially be type DISPLAY_AD_COMPONENT
    return {
        unusedConfirmedHtml: html || '',
        noticeType: NoticeType.display_ad.value,
        file: {
            type: NoticeFileTypes.finalized_display_ad,
            firebaseStoragePath: formattedUpload.ref.fullPath,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            originalFileName: fileProperties.originalName,
            fileFormat: fileProperties.originalFormat,
            sanitizedFileName: fileProperties.sanitizedName
        },
        processedDisplay: true
    };
});
export const getCsvFileChanges = (file, upload, fileProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const html = yield csvToHtml(file);
    const pdf = yield convertToPdf(file);
    const displayUpload = yield Firebase.storage()
        .ref()
        .child(`${upload.ref.fullPath}.pdf`)
        .put(pdf);
    // TODO (APP-36): This should initially be type DISPLAY_AD_COMPONENT
    return {
        unusedConfirmedHtml: html,
        noticeType: NoticeType.display_ad.value,
        file: {
            type: NoticeFileTypes.finalized_display_ad,
            firebaseStoragePath: displayUpload.ref.fullPath,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            fileFormat: fileProperties.originalFormat,
            originalFileName: fileProperties.originalName,
            sanitizedFileName: fileProperties.sanitizedName
        },
        processedDisplay: true
    };
});
export const getRtfChanges = (file, upload, fileProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const html = yield rtfToHtml(file);
    return {
        noticeType: NoticeType.custom.value,
        text: html,
        squashable: false,
        file: {
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            firebaseStoragePath: upload.ref.fullPath,
            originalFileName: fileProperties.originalName,
            fileFormat: fileProperties.originalFormat,
            sanitizedFileName: fileProperties.sanitizedName
        }
    };
});
export const getOtherFileChanges = (upload, fileProperties) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        noticeType: NoticeType.custom.value,
        text: '',
        file: {
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: yield upload.ref.getDownloadURL(),
            firebaseStoragePath: upload.ref.fullPath,
            originalFileName: fileProperties.originalName,
            fileFormat: fileProperties.originalFormat,
            sanitizedFileName: fileProperties.sanitizedName
        }
    };
});
export const SATURDAY_DAY_INDEX = 6;
export const SUNDAY_DAY_INDEX = 0;
/**
 * removeDatesFromArray helper function deletes dates from an array of dates
 * @param pubDates array of dates
 * @param itemsToRemove array of items to remove from pubDates
 * @returns a new array with items removed
 */
export const removeDatesFromArray = (pubDates, itemsToRemove) => pubDates.filter(date => !itemsToRemove.includes(date));
/**
 * removeRowClickForWeekendEdition deletes dates from an array of publication dates and makes sure weekend dates are always deleted together
 * (ex: deleting Saturday deletes Sunday as well)
 *
 * @param publicationDates array containing publication dates
 * @param i row clicked in app, the index of the element in publicationDates array
 * @returns a new array removing weekends from publicationDates
 */
export const removeRowClickForWeekendEdition = (publicationDates, i) => {
    // Remove both weekend days if publication date clicked is a Sunday
    if (moment(publicationDates[i]).day() === SATURDAY_DAY_INDEX) {
        return removeDatesFromArray(publicationDates, [
            publicationDates[i],
            publicationDates[i + 1]
        ]);
        // Remove both weekend days if publication date clicked is a Sunday
    }
    if (moment(publicationDates[i]).day() === SUNDAY_DAY_INDEX) {
        return removeDatesFromArray(publicationDates, [
            publicationDates[i - 1],
            publicationDates[i]
        ]);
        // Remove the weekday
    }
    return removeDatesFromArray(publicationDates, [publicationDates[i]]);
};
/**
 * addPublicationDates adds dates to an array of publication dates ensuring weekends are added together
 * (ex. adding SAturday adds Sunday as well)
 *
 * @param selectedPublicationDate date selected in date picker
 * @param publicationDates array containing publication dates
 * @returns a new array removing weekends from publicationDates
 */
export const addPublicationDates = (selectedPublicationDate, publicationDates) => {
    if (moment(selectedPublicationDate).day() === SATURDAY_DAY_INDEX) {
        const sunday = moment(selectedPublicationDate).day(7).toDate();
        return [...publicationDates, selectedPublicationDate, sunday];
    }
    if (moment(selectedPublicationDate).day() === SUNDAY_DAY_INDEX) {
        const saturday = moment(selectedPublicationDate).day(-1).toDate();
        return [...publicationDates, saturday, selectedPublicationDate];
    }
    return [...publicationDates, selectedPublicationDate];
};
/**
 * removeDuplicateDates deletes duplicate dates from an array
 * @param publicationDates an array of dates
 * @returns a new array of unique dates
 */
export const removeDuplicateDates = (publicationDates) => {
    const datesAsStrings = [];
    const uniqueDates = [];
    for (const date of publicationDates) {
        if (!datesAsStrings.includes(date.toString())) {
            datesAsStrings.push(date.toString());
            uniqueDates.push(date);
        }
    }
    return uniqueDates;
};
/**
 * handlePubDateChangeForWeekendEdition when weekend edition enabled:
 * 1. removes the clicked date, the row clicked in app
 * 2. adds a date chosen form the date picker
 * 3. deletes duplicate dates from the array
 *
 * @param selectedPublicationDate date selected in date picker
 * @param publicationDates array containing publication dates
 * @param i row clicked in app, the index of the element in publicationDates array
 * @returns a new array removing publicationDates[i], adding selectedPublicationDate
 */
export const handlePubDateChangeForWeekendEdition = (selectedPublicationDate, publicationDates, i) => {
    let newPubDates = removeRowClickForWeekendEdition(publicationDates, i);
    newPubDates = addPublicationDates(selectedPublicationDate, newPubDates);
    newPubDates = removeDuplicateDates(newPubDates);
    return newPubDates;
};
// Checks if the given date should be disabled in the confirm schedule step
export const shouldDisableDate = (day, newspaper, user, notice, editing, noticeType, isPublisher) => {
    var _a;
    if (exists(user) && getRole(user) === RoleType.super.value)
        return false;
    if (!exists(newspaper))
        return false;
    if (editing && isPublisher)
        return false;
    const { deadlines, iana_timezone } = newspaper.data();
    if (!deadlines || !day || !iana_timezone)
        return true;
    const isNonPublishingDay = disableNonPublishingDays(day, publishingDayEnumValuesFromDeadlines(deadlines), newspaper.data().deadlineOverrides);
    day.setHours(day.getHours() + 1);
    const isAfterDeadline = getIsAfterPublishingDeadline(day, deadlines, iana_timezone, notice, newspaper);
    if (isAfterDeadline && (noticeType === null || noticeType === void 0 ? void 0 : noticeType.upFrontPayment) && !isPublisher) {
        return true;
    }
    // allow for restricting the publication days for a particular notice type to
    // only be a set of specific days
    if (((_a = noticeType === null || noticeType === void 0 ? void 0 : noticeType.restrictedPublicationDays) === null || _a === void 0 ? void 0 : _a.length) && !isPublisher) {
        return !(noticeType === null || noticeType === void 0 ? void 0 : noticeType.restrictedPublicationDays.includes(day.getDay()));
    }
    return isNonPublishingDay || (!isPublisher && isAfterDeadline);
};
export const fetchPublisherCustomer = ({ accountNumber, verifiedAccountNumber, customerInfo, newspaperSnap }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(newspaperSnap) || (!accountNumber && !(customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.email))) {
        return {
            success: false,
            reason: customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN
        };
    }
    const currentAccountNumberIsVerified = !!accountNumber && accountNumber === verifiedAccountNumber;
    if (currentAccountNumberIsVerified) {
        return {
            success: true,
            method: customerFetchMethods.ALREADY_VERIFIED
        };
    }
    const shouldFindCustomerWithInfo = !accountNumber &&
        !!(customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.email) &&
        !!EmailValidator.validate(customerInfo.email) &&
        !!(customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.firstName) &&
        !!(customerInfo === null || customerInfo === void 0 ? void 0 : customerInfo.lastName);
    const shouldFindCustomerWithAccountNumber = !!accountNumber;
    if (shouldFindCustomerWithInfo) {
        try {
            const resp = yield api.post(`integrations/customers/${newspaperSnap.id}/find`, customerInfo);
            if (resp.success) {
                return {
                    success: true,
                    method: customerFetchMethods.FROM_CUSTOMER_INFO,
                    customer: resp.customerData
                };
            }
            return {
                success: false,
                reason: customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
            };
        }
        catch (err) {
            logAndCaptureException(err, 'failed to find publisher customer by customer info', Object.assign(Object.assign({}, removeUndefinedFields(customerInfo)), { state: `${customerInfo.state}`, newspaperId: newspaperSnap.id }));
            return {
                success: false,
                reason: customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
            };
        }
    }
    if (shouldFindCustomerWithAccountNumber) {
        try {
            const resp = yield api.get(`integrations/customers/${newspaperSnap.id}/find/${accountNumber}`);
            if (resp.success) {
                return {
                    success: true,
                    method: customerFetchMethods.FROM_ACCOUNT_NUMBER,
                    customer: resp.customerData
                };
            }
            return {
                success: false,
                reason: customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID
            };
        }
        catch (err) {
            logAndCaptureException(err, 'failed to find publisher customer by account number', {
                newspaperId: newspaperSnap.id,
                accountNumber
            });
            return {
                success: false,
                reason: customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID
            };
        }
    }
    return {
        success: false,
        reason: customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN
    };
});
export const fetchAccountNumber = ({ noticeSnap, filerRef }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(noticeSnap)) {
        return {
            success: false,
            reason: 'Notice does not exist'
        };
    }
    const filerSnap = yield (filerRef === null || filerRef === void 0 ? void 0 : filerRef.get());
    const typeSafeFiler = exists(filerSnap) ? filerSnap : undefined;
    const fetchResults = yield getAccountNumberForNotice(getFirebaseContext(), noticeSnap, typeSafeFiler);
    const fetchedAccountNumber = fetchResults === null || fetchResults === void 0 ? void 0 : fetchResults.id;
    if (fetchedAccountNumber) {
        return {
            success: true,
            accountNumber: fetchedAccountNumber
        };
    }
    return {
        success: false,
        reason: 'No account number found'
    };
});
export const getPublisherOrder = ({ user, hasInvoiceMailing, hasNoticeTypeSelect, hasTypeform, isEditing }) => {
    let publisherOrder = [];
    if (isEditing) {
        publisherOrder = hasNoticeTypeSelect
            ? [...PUBLISHER_EDIT_NOTICE_FLOW_NOTICE_TYPE_SELECT]
            : [...PUBLISHER_EDIT_NOTICE_FLOW_NO_NOTICE_TYPES];
    }
    else if (hasNoticeTypeSelect) {
        publisherOrder = [...PUBLISHER_UPLOAD_NOTICE_TYPE_SELECT];
    }
    else if (hasTypeform) {
        publisherOrder = [...PUBLISHER_UPLOAD_TYPEFORM_ORDER];
    }
    else if (!hasTypeform) {
        publisherOrder = [...PUBLISHER_UPLOAD_NO_TYPEFORM_ORDER];
    }
    if (hasInvoiceMailing) {
        const affidavitRecipientIndex = publisherOrder.indexOf(MAILED_AFFIDAVIT);
        if (affidavitRecipientIndex >= 0) {
            publisherOrder = [
                ...publisherOrder.slice(0, affidavitRecipientIndex),
                INVOICE_RECIPIENT,
                ...publisherOrder.slice(affidavitRecipientIndex)
            ];
        }
        else {
            publisherOrder.push(INVOICE_RECIPIENT);
        }
    }
    if (exists(user) && canPublisherUserSeeNewspaperSelect(user, !isEditing)) {
        publisherOrder.unshift(CONFIRM_NEWSPAPER);
    }
    return publisherOrder;
};
/**
 * If we get to the point where this helper is called,
 * the user (can be publisher or advertiser) is logged out
 * and going through placement.
 * This helper adds the customer info step at the appropriate
 * step in placement depending on the existing placement order
 *
 * Insertion hierarchy:
 * 1. At beginning if no newspaper/notice type step
 * 2. Right after the notice type step if exists
 * 3. Right after the content prepared step if exists
 * 4. Right after the newspaper select step if exists
 */
export const addCustomerInfoStepToAdvertiserFlow = (order) => {
    // Return early if the order already has the confirm filer step
    if (order.indexOf(CONFIRM_FILER) !== -1)
        return order;
    const insertAt = 0;
    // We add one to both of the following because we want to insert after the index
    const noticeTypeIndex = order.indexOf(CONFIRM_NOTICE_TYPE) + 1;
    const contentPreparedIndex = order.indexOf(CONTENT_PREPARED) + 1;
    const newspaperIndex = order.indexOf(CONFIRM_NEWSPAPER) + 1;
    order.splice(Math.max(insertAt, noticeTypeIndex, contentPreparedIndex, newspaperIndex), 0, CONFIRM_FILER);
    return order;
};
export const getAdvertiserOrder = ({ isNewspaperPlaceLink, hasNoticeTypeSelect, hasTypeform, isCustomNoticeType, isEditing, showAccountInfoStep, user }) => {
    var _a;
    let advertiserOrder = [];
    if (isNewspaperPlaceLink && hasNoticeTypeSelect && isCustomNoticeType) {
        advertiserOrder = [
            ...FILER_NEWSPAPER_PLACE_LINK_NOTICE_TYPE_SELECT_CUSTOM_DRAFT
        ];
    }
    else if (isNewspaperPlaceLink && hasNoticeTypeSelect) {
        advertiserOrder = [...FILER_NEWSPAPER_PLACE_LINK_NOTICE_TYPE_SELECT];
    }
    else if (isNewspaperPlaceLink && hasTypeform) {
        advertiserOrder = [...FILER_NEWSPAPER_PLACE_LINK_TYPEFORM_ORDER];
    }
    else if (isNewspaperPlaceLink && !hasTypeform) {
        advertiserOrder = [...FILER_NEWSPAPER_PLACE_LINK_NO_TYPEFORM_ORDER];
    }
    else if (hasNoticeTypeSelect && isCustomNoticeType) {
        advertiserOrder = [...FILER_NATIONAL_SITE_NOTICE_TYPE_SELECT_CUSTOM_DRAFT];
    }
    else if (hasNoticeTypeSelect && !isCustomNoticeType && isEditing) {
        advertiserOrder = [...FILER_NATIONAL_SITE_NOTICE_TYPE_SELECT_EDIT];
    }
    else if (hasNoticeTypeSelect && !isCustomNoticeType) {
        advertiserOrder = [...FILER_NATIONAL_SITE_NOTICE_TYPE_SELECT];
    }
    else if (hasTypeform) {
        advertiserOrder = [...NATIONAL_SITE_TYPEFORM_ORDER];
    }
    else {
        advertiserOrder = [...NATIONAL_SITE_NO_TYPEFORM_ORDER];
    }
    const startedFromAnonymousFlow = sessionStorage.getItem('startedFromAnonymousFlow') === 'true';
    const disableNewAnonymousPlacement = getBooleanFlag(LaunchDarklyFlags.DISABLE_NEW_ANONYMOUS_PLACEMENT, true);
    if ((isAnonymousFlow() ||
        startedFromAnonymousFlow ||
        (exists(user) && (((_a = user.data().allowedOrganizations) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1)) &&
        !disableNewAnonymousPlacement) {
        advertiserOrder = addCustomerInfoStepToAdvertiserFlow(advertiserOrder);
    }
    if (showAccountInfoStep) {
        // We want the account info step to go just before the confirm proof step
        advertiserOrder.splice(-1, 0, ACCOUNT_INFO);
    }
    return advertiserOrder;
};
export const getMaskedEmail = (email) => {
    if (!email)
        return '';
    const emailPartsSplitByAmpersand = email.toLowerCase().split('@');
    const username = emailPartsSplitByAmpersand[0];
    // eslint-disable-next-line no-useless-escape
    const [domain, tld] = emailPartsSplitByAmpersand[1].split(/\.(?=[^\.]+$)/);
    return `${username.length > 1 ? username.substring(0, 2) : username[0]}****@${domain[0]}***.${tld}`;
};
// TODO (APP-36): This function should return an array of paths
export const getFirebaseStoragePath = (placement) => {
    var _a;
    if (!placement ||
        !placement.filesToAttach ||
        !placement.filesToAttach.length) {
        return '';
    }
    const { filesToAttach } = placement;
    const storagePath = (_a = filesToAttach === null || filesToAttach === void 0 ? void 0 : filesToAttach[0]) === null || _a === void 0 ? void 0 : _a.firebaseStoragePath;
    // TODO: Confirm if this check covers all scenarios
    if (storagePath && storagePath.includes('_smashed')) {
        return storagePath.split('_smashed')[0];
    }
    return storagePath;
};
// TODO (APP-36): This function should return an array of URLs
export const getUploadedFileURL = (placement) => {
    var _a;
    if (!placement || !placement.filesToAttach || !placement.filesToAttach.length)
        return '';
    const firstFileWithUrl = (_a = placement.filesToAttach) === null || _a === void 0 ? void 0 : _a.find(file => {
        if (file.linkToUploadedFile) {
            return true;
        }
        return false;
    });
    return (firstFileWithUrl === null || firstFileWithUrl === void 0 ? void 0 : firstFileWithUrl.linkToUploadedFile) || '';
};
// TODO (APP-36): This function should return an array of file names
export const getOriginalFileName = (placement) => {
    var _a;
    if (!placement ||
        !placement.filesToAttach ||
        !placement.filesToAttach.length) {
        return null;
    }
    const { filesToAttach } = placement;
    return ((_a = filesToAttach === null || filesToAttach === void 0 ? void 0 : filesToAttach[0]) === null || _a === void 0 ? void 0 : _a.originalFileName) || null;
};
const LINER_ACCEPT = '.pdf,.docx,.doc,.txt,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/plain';
const DISPLAY_ACCEPT = '.rtf,.jpg,.jpeg,.png,.pdf,.csv,.xls,.xlsx,application/rtf,text/rtf,image/jpeg,image/png,application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export function getAcceptedFiles(newspaper) {
    var _a;
    // default behavior
    if (!exists(newspaper))
        return DEFAULT_ACCEPT;
    const { customAccept } = newspaper.data();
    // go to custom values if set
    if (customAccept)
        return customAccept;
    // restrict behavior for only display ads
    if ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.displayOnlyAds)
        return DISPLAY_ACCEPT;
    // restrict behavior for disable display
    if (newspaper.data().disableDisplay || newspaper.data().allowedNotices)
        return LINER_ACCEPT;
    // fall through to default
    return DEFAULT_ACCEPT;
}
export const isAnonymousFlow = () => { var _a; return !!((_a = Firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.isAnonymous); };
/* Finds which papers the user can place in. For this we have the following cases:
 * 1- If the user is a publisher, they are restricted to place in the papers they have access to only.
 * 2- If the placement URL has `restrictedPapers` param, these are the papers, the user can only place in.
 * 3- If the user has the property `enabledNewspapers`, these are the papers, the user can only place in.
 * 4- Otherwise, use can place in any valid paper
 * */
const getRestrictedPaperIds = (user, isPublisher, availableOrganizations) => {
    var _a;
    let paperIds = [];
    // Publishers are allowed only to place in their papers
    if (isPublisher) {
        paperIds = availableOrganizations.map(org => org.id);
    }
    // We can restrict placement in specific papers by having paper ids in the URL
    const restrictedPapersParam = getLocationParams().get('restrictedPapers');
    const restrictedPapers = restrictedPapersParam
        ? restrictedPapersParam.split(',')
        : null;
    if (restrictedPapers) {
        paperIds = restrictedPapers;
    }
    // We can restrict placement in specific papers by having paper ids on the user document
    if (exists(user) && user.data().enabledNewspapers) {
        paperIds = ((_a = user.data().enabledNewspapers) === null || _a === void 0 ? void 0 : _a.map(n => n.id)) || [];
    }
    return paperIds;
};
// Fetches all available papers and states to be used in the confirm newspaper step
export const searchOrganizations = (isPublisher, availableOrganizations, user, filingState, filingCounty, search) => __awaiter(void 0, void 0, void 0, function* () {
    // Find allowed papers to place in
    const restrictedPaperIds = getRestrictedPaperIds(user, isPublisher, availableOrganizations);
    // Construct the Elastic filters
    const filters = [];
    if (filingState) {
        filters.push({
            state: [filingState]
        });
    }
    if (restrictedPaperIds.length) {
        filters.push({ id: restrictedPaperIds });
    }
    // Only publishers can place in a disabled paper
    if (!isPublisher) {
        filters.push({ disabled: [0] });
    }
    if (filingCounty) {
        filters.push({ county: [filingCounty] });
    }
    filters.push({ type: [OrganizationType.newspaper.value] });
    filters.push({ isvalidpaper: [1] });
    const postBody = {
        search,
        filters
    };
    try {
        const { results, error } = yield api.post('search/organizations', postBody);
        if (error) {
            logAndCaptureException(new Error('Unable to search organizations'), `Placement: Error in searchOrganizations. Filters: ${JSON.stringify(filters)}`);
        }
        const states = new Set(results.map(paper => paper.state));
        return { states: [...states], papers: results };
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error in searchOrganizations');
        return { papers: [], states: [] };
    }
});
/**
 * TODO:
 * This is a short-term solution to the inconsistent ways that we save customer/user names across the app.
 * The issue should be addressed more fully in COREDEV-1645 (https://columnpbc.atlassian.net/browse/COREDEV-1645)
 */
export const getFirstAndLastNameFromFullName = (fullName) => {
    return fullName
        .trim()
        .split(' ')
        .reduce((returnObj, nameSegment, nameSegmentIndex) => {
        let { firstName, lastName } = returnObj;
        if (nameSegmentIndex === 0) {
            firstName = nameSegment;
        }
        else {
            lastName = returnObj.lastName
                ? `${returnObj.lastName} ${nameSegment}`
                : nameSegment;
        }
        return { firstName, lastName };
    }, {});
};
export function getStateFromLocationParams() {
    const state = getLocationParams().get('state');
    if (!state) {
        return;
    }
    const existingState = State.by_label(state);
    if (!existingState) {
        return;
    }
    const selectedState = existingState.value;
    if (!selectedState) {
        return;
    }
    return selectedState;
}
export function getRestrictedPapersFromLocationParams() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const ctx = getFirebaseContext();
        const restrictedPapers = getLocationParams().get('restrictedPapers')
            ? (_a = getLocationParams().get('restrictedPapers')) === null || _a === void 0 ? void 0 : _a.split(',')
            : null;
        // Fetch associated states of the restricted papers
        // since we're only given ids in the url
        const restrictedStates = restrictedPapers
            ? (yield Promise.all(restrictedPapers.map((paperId) => __awaiter(this, void 0, void 0, function* () {
                var _b;
                const newspaperSnap = yield ctx
                    .organizationsRef()
                    .doc(paperId)
                    .get();
                if (exists(newspaperSnap)) {
                    return (_b = newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.data()) === null || _b === void 0 ? void 0 : _b.state;
                }
            })))).filter(Number) // Casting because Typescript doesn't narrow after filtering
            : null;
        return { restrictedStates, restrictedPapers };
    });
}
