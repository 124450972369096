import classNames from 'classnames';
import React from 'react';
export const RATE_PROPERTIES_TAB = 'rate-properties-tab';
export const ADDITIONAL_FEES_TAB = 'additional-fees-tab';
export const RATE_USERS_TAB = 'rate-users-tab';
export const RATE_ORGANIZATIONS_TAB = 'rate-organizations-tab';
/**
 * UI component for the buttons in the drawer
 */
function DrawerTabButton({ disabled, isActive, label, onClick }) {
    return (React.createElement("button", { className: classNames('ml-8 inline-block text-base text-black text-left py-6 focus:outline-none font-medium border-b-2 border-transparent border-b-2', {
            'border-blue-600 text-blue-950': isActive,
            'hover:border-b-2 hover:border-blue-600 hover:text-blue-950': !disabled,
            'cursor-not-allowed text-grey-400': disabled
        }), onClick: () => onClick(), disabled: disabled }, label));
}
/**
 * Control the currently visible tab on the affidavit affidavit upload event drawer
 */
export default function RateDrawerTabs({ setRateDrawerTab, rateDrawerTab }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerTabButton, { disabled: false, isActive: rateDrawerTab === RATE_PROPERTIES_TAB, label: "Basic Properties", onClick: () => setRateDrawerTab(RATE_PROPERTIES_TAB) }),
        React.createElement(DrawerTabButton, { disabled: false, isActive: rateDrawerTab === ADDITIONAL_FEES_TAB, label: "Additional Fees", onClick: () => setRateDrawerTab(ADDITIONAL_FEES_TAB) })));
}
