var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { getRecipientName } from 'lib/utils/invoices';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
export function getInvoiceRecipientFromNoticeId(noticeId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const data = yield api.get(`notices/${noticeId}/invoice-recipient`);
            if (data === null || data === void 0 ? void 0 : data.invoiceRecipient) {
                return data.invoiceRecipient;
            }
        }
        catch (err) {
            logAndCaptureException(err, "Failed to get invoice recipient from notice's id", { noticeId });
        }
    });
}
export function getBillingNames(advertiser, invoiceRecipient, invoice) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        // "Billed To" in order of preference:
        //  1) The name of the manually specified invoice recipient
        //  2) The name of the advertiserOrganization on the invoice object
        //  3) The advertiser's name
        let billingName;
        let customerToSaveCardOnName;
        if (invoiceRecipient) {
            billingName = getRecipientName(invoiceRecipient);
        }
        if (invoice.data().advertiserOrganization) {
            const invoiceAdvertiserOrg = yield ((_a = invoice
                .data()
                .advertiserOrganization) === null || _a === void 0 ? void 0 : _a.get());
            if (!invoiceRecipient) {
                billingName = (_b = invoiceAdvertiserOrg === null || invoiceAdvertiserOrg === void 0 ? void 0 : invoiceAdvertiserOrg.data()) === null || _b === void 0 ? void 0 : _b.name;
            }
            customerToSaveCardOnName = (_c = invoiceAdvertiserOrg === null || invoiceAdvertiserOrg === void 0 ? void 0 : invoiceAdvertiserOrg.data()) === null || _c === void 0 ? void 0 : _c.name;
        }
        else {
            if (!invoiceRecipient) {
                billingName = advertiser === null || advertiser === void 0 ? void 0 : advertiser.name;
            }
            customerToSaveCardOnName = advertiser === null || advertiser === void 0 ? void 0 : advertiser.name;
        }
        if (!customerToSaveCardOnName)
            logAndCaptureMessage('No customerToSaveCardOnName value set', {
                invoiceId: invoice.id
            });
        return { billingName, customerToSaveCardOnName };
    });
}
export function getPreventLatePaymentFromNoticeId(noticeId) {
    return __awaiter(this, void 0, void 0, function* () {
        let preventLatePayment = false;
        try {
            const data = yield api.get(`notices/${noticeId}/should-prevent-late-pay`);
            if (data === null || data === void 0 ? void 0 : data.shouldPreventLatePay) {
                preventLatePayment = true;
            }
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to determine if we should prevent late prepay on notice.', { noticeId });
        }
        return preventLatePayment;
    });
}
export function getRequireUpfrontPaymentFromNoticeId(noticeId) {
    return __awaiter(this, void 0, void 0, function* () {
        let requireUpfrontPayment = false;
        try {
            const data = yield api.get(`notices/${noticeId}/require-upfront-payment`);
            if (data === null || data === void 0 ? void 0 : data.requireUpfrontPayment) {
                requireUpfrontPayment = data === null || data === void 0 ? void 0 : data.requireUpfrontPayment;
            }
        }
        catch (err) {
            logAndCaptureException(err, 'Could not get require upfront payment from api', {
                noticeId
            });
        }
        return requireUpfrontPayment;
    });
}
