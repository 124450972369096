import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import Embed from './Embed';
import FIREBASE_CONFIG from '../../config.firebase';
import { appendToCurrentParams } from '../../utils/urls';
import { CONFIRM_NEWSPAPER } from '../placeScroll/helpers';
import PlacementActions from '../../redux/placement';
import ToastActions from '../../redux/toast';
const NEWSPAPER_FORM_ID = 'whM47vYn';
const TypeformEmbed = ({ userAuth, pathname, push, toastActions }) => {
    const match = matchPath(pathname, {
        path: '/form/newspaper/:noticeId',
        exact: true,
        strict: false
    });
    let noticeId = '';
    if (match) {
        noticeId = match.params.noticeId;
    }
    const hiddenFields = {
        documentid: noticeId,
        userid: userAuth ? userAuth.uid : '',
        env: FIREBASE_CONFIG.projectId
    };
    const parametersConfirmed = () => {
        const base = `/place/${noticeId}`;
        const allParams = appendToCurrentParams('step', CONFIRM_NEWSPAPER);
        push(`${base}?${allParams.toString()}`);
    };
    const customFormSubmitted = () => {
        parametersConfirmed();
        toastActions.setToastText(`Thanks for the feedback! If you'd like to place in another newspaper, please continue.`);
    };
    return (React.createElement(Embed, { onSubmit: customFormSubmitted, hiddenFields: hiddenFields, formId: NEWSPAPER_FORM_ID }));
};
const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname
});
const mapDispatchToProps = (dispatch) => ({
    placementActions: bindActionCreators(PlacementActions, dispatch),
    push: (path) => dispatch(push(path)),
    toastActions: bindActionCreators(ToastActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TypeformEmbed);
