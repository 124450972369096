var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { State, OrganizationType, NoticeType, Country } from '../enums';
export const shouldShowPaperRegardlessOfUploadReadiness = (org) => { var _a; return (_a = org.data()) === null || _a === void 0 ? void 0 : _a.alwaysShowInPlacementFlow; };
export const publisherReadyToUpload = (org, allowDisabled = false) => (allowDisabled || !org.data().disabled) &&
    org.data().name &&
    org.data().address &&
    org.data().phone &&
    org.data().city &&
    org.data().county &&
    org.data().state &&
    org.data().adTemplate &&
    org.data().deadlines &&
    (org.data().affidavitDisabled ||
        (org.data().signatoryName &&
            org.data().signatoryRole &&
            org.data().publicationFrequency));
export const paperIsValid = (org, allowDisabled = false) => publisherReadyToUpload(org, allowDisabled) ||
    shouldShowPaperRegardlessOfUploadReadiness(org);
export const getNoticeTypeData = (noticeType, org) => {
    var _a, _b;
    const selectedNoticeType = ((_b = (_a = org
        .data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) === null || _b === void 0 ? void 0 : _b.find(allowedNotice => allowedNotice.value === parseInt(noticeType, 10))) || NoticeType.by_value(noticeType);
    if (!selectedNoticeType) {
        console.error(`Unable to find typeform value ${noticeType} for organization ${org.id}`);
        return NoticeType.custom;
    }
    return selectedNoticeType;
};
export const getNoticeTypeStr = (noticeType, org) => {
    if (noticeType)
        return getNoticeTypeData(noticeType, org).label;
    return 'Public';
};
export const getNoticeTypeDataFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield notice.data().newspaper.get();
    return getNoticeTypeData(notice.data().noticeType, newspaper);
});
export const isBattlebornSync = (org, parent = null) => {
    var _a;
    const { name } = org.data();
    if (name.toLowerCase().includes('sparks') ||
        name.toLowerCase().includes('mineral county') ||
        name.toLowerCase().includes('mesquite local') ||
        name.toLowerCase().includes('eureka sentinel') ||
        name.toLowerCase().includes('ely'))
        return true;
    if ((_a = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _a === void 0 ? void 0 : _a.name.toLowerCase().includes('battle born')) {
        return true;
    }
    return false;
};
// Organization matchers
export const isBattleborn = (org) => __awaiter(void 0, void 0, void 0, function* () {
    if (!org)
        return false;
    const { parent } = org.data();
    let p;
    if (parent)
        p = (yield parent.get());
    return isBattlebornSync(org, p);
});
export const isMontroseOrDelta = (org) => {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport &&
        (((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_MONTROSE' ||
            ((_b = org.data().xmlExport) === null || _b === void 0 ? void 0 : _b.format) === 'VD_DELTA'));
};
export const isGreenValleyOrSahuarita = (org) => {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport &&
        (((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_GREEN_VALLEY' ||
            ((_b = org.data().xmlExport) === null || _b === void 0 ? void 0 : _b.format) === 'VD_SAHUARITA'));
};
export const isEasternArizonaOrCopperEra = (org) => {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport &&
        (((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_EASTERN_ARIZONA' ||
            ((_b = org.data().xmlExport) === null || _b === void 0 ? void 0 : _b.format) === 'VD_COPPER_ERA'));
};
export const isNogales = (org) => {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_NOGALES';
};
export const isSierraVista = (org) => {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_HERALD';
};
export const isDailyTerritorial = (org) => {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport &&
        ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_DAILY_TERRITORIAL');
};
export const isNogalesOrDailyTerritorial = (org) => {
    if (!org)
        return;
    return isNogales(org) || isDailyTerritorial(org);
};
export const isSantaMaria = (org) => {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_SANTA_MARIA');
};
export const isLompocRecord = (org) => {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_LOMPOC';
};
export const isSantaYnez = (org) => {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_SANTA_YNEZ');
};
export const isGrassValley = (org) => {
    var _a;
    if (!org)
        return;
    return ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_GRASS_VALLEY';
};
export const isSantaMariaGroup = (org) => {
    if (!org)
        return;
    return (isSantaMaria(org) ||
        isSantaYnez(org) ||
        isLompocRecord(org) ||
        isGrassValley(org));
};
export const isMatSU = (org) => {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_MAT_SU';
};
export const isAnchoragePress = (org) => {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport &&
        ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_ANCHORAGE_PRESS');
};
export const isSidney = (org) => {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_SIDNEY_HERALD');
};
export const isWilliston = (org) => {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport &&
        ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_WILLISTON_HERALD');
};
export const isWillistonOrSidney = (org) => {
    return isWilliston(org) || isSidney(org);
};
export const isWick = (org) => {
    if (!org)
        return;
    return (isMontroseOrDelta(org) ||
        isGreenValleyOrSahuarita(org) ||
        isEasternArizonaOrCopperEra(org) ||
        isNogalesOrDailyTerritorial(org) ||
        isSierraVista(org) ||
        isMatSU(org) ||
        isAnchoragePress(org) ||
        isWillistonOrSidney(org));
};
export const isOrganizationSetForRPA = (org) => {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport && ((_b = (_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === null || _b === void 0 ? void 0 : _b.startsWith('VD_')));
};
// Orgs that can run donations and public notices
export const canRunDonations = (org) => {
    var _a, _b, _c;
    return org &&
        ((_a = org.data()) === null || _a === void 0 ? void 0 : _a.organizationType) === OrganizationType.newspaper.value &&
        ((_c = State.by_value((_b = org.data()) === null || _b === void 0 ? void 0 : _b.state)) === null || _c === void 0 ? void 0 : _c.can_run_donations);
};
export const getMemoText = (customMemo = '', referenceId, publisherId, isReceipt, country) => {
    const customHeading = '=== Notes ===';
    const boilerplateHeading = '=== How to pay this invoice ===';
    const noticeName = referenceId ? `Notice Name: ${referenceId}\n` : '';
    const publisher = publisherId ? `Order Number: ${publisherId}\n` : '';
    const customIds = `${noticeName}${publisher}`;
    const check = country === Country.GBR.value ? 'cheque' : 'check';
    const boilerplate = `We accept ACH bank transfers, debit/ credit cards, and ${check}s. If you would like to pay by ${check}, please make it out to `;
    let memo = '';
    memo += `${customHeading}\n${customMemo}\n${customIds}\n`;
    if (!isReceipt)
        memo += `${boilerplateHeading}\n${boilerplate}`;
    return memo;
};
/**
 * Function checks whether or not ACH should be disabled for a given publisher.
 * @param newspaper
 * ================
 * @returns value of disableACH on the newspaper (or the value of disableACH
 * on its parent if disableACH is not defined on the newspaper); if not defined
 * on either, it returns false
 */
export const shouldDisableACHForPublisher = (newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    const newspaperParent = yield ((_b = (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.get());
    const isNewspaperACHdisabled = (_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.disableACH;
    const isParentACHdisabled = (_d = newspaperParent === null || newspaperParent === void 0 ? void 0 : newspaperParent.data()) === null || _d === void 0 ? void 0 : _d.disableACH;
    return (_e = isNewspaperACHdisabled !== null && isNewspaperACHdisabled !== void 0 ? isNewspaperACHdisabled : isParentACHdisabled) !== null && _e !== void 0 ? _e : false;
});
