import classNames from 'classnames';
import React from 'react';
/**
 * Wrapper around table icon components to handle click events.
 */
function SettingsTableIcon({ disabled, onClick, icon }) {
    return (React.createElement("button", { className: classNames('p-3 rounded-md', {
            'hover:bg-gray-200': !disabled
        }, {
            'cursor-not-allowed': disabled
        }), disabled: disabled, onClick: e => {
            e.stopPropagation();
            onClick();
        } }, icon));
}
export default SettingsTableIcon;
