/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import ColumnSelect from 'components/select/ColumnSelect';
const YesNoQuestionInput = ({ question, value, onChange }) => {
    const yesNoValue = value !== undefined ? (value ? 'yes' : 'no') : undefined;
    return (React.createElement("div", null,
        React.createElement("p", { className: "mb-1 text-sm" }, question.prompt),
        React.createElement(ColumnSelect, { allowUndefined: true, options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
            ], value: yesNoValue, onChange: v => onChange({ varName: question.varName, value: v === 'yes' }) })));
};
export default YesNoQuestionInput;
