var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Tooltip, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Help } from '@material-ui/icons';
import { getDeadlineTimeForPaper, isNoticeAfterPublicationDeadline } from 'lib/utils/deadlines';
import { firstNoticePublicationDate, getNoticeRequiresUpfrontPayment, getNoticeIsInvoicedOutsideColumn, getDueDate } from 'lib/helpers';
import { CalendarIcon } from 'icons';
import { getFirebaseContext } from 'utils/firebase';
const InvoiceFormDueDate = ({ newspaper, dueDate, setDueDate, noticeSnap, requireUpfrontPayment, setRequireUpfrontPayment, ianaTimezone, invoiceOutsideColumn, setInvoiceOutsideColumn, isWithinBulkPayment }) => {
    const [disableRequireUpfrontPayment, setDisableRequireUpfrontPayment] = useState(false);
    const [deadline, setDeadline] = useState();
    const [currentSameOrBefore] = useState(false);
    useEffect(() => {
        const { deadlines, iana_timezone } = newspaper.data();
        const closestPublicationDate = firstNoticePublicationDate(noticeSnap);
        const deadline = getDeadlineTimeForPaper(closestPublicationDate, deadlines, iana_timezone, noticeSnap.data(), newspaper);
        setDeadline(deadline.toDate());
    }, [noticeSnap, newspaper]);
    useEffect(() => {
        const checkDeadline = () => __awaiter(void 0, void 0, void 0, function* () {
            if (deadline) {
                if (isWithinBulkPayment) {
                    // Upfront payment should always be false
                    // if a notice is within a bulk invoice
                    setDisableRequireUpfrontPayment(true);
                    setRequireUpfrontPayment(false);
                }
                else {
                    const isAfterDeadline = yield isNoticeAfterPublicationDeadline(noticeSnap);
                    const { iana_timezone } = newspaper.data();
                    if (!isAfterDeadline) {
                        setDisableRequireUpfrontPayment(false);
                    }
                    else if (!isWithinBulkPayment) {
                        setDisableRequireUpfrontPayment(true);
                        setRequireUpfrontPayment(false);
                        setDueDate(moment().tz(iana_timezone).add(1, 'M').toDate().getTime() / 1000 -
                            2);
                    }
                }
            }
        });
        void checkDeadline();
    }, [deadline, isWithinBulkPayment]);
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            const ctx = getFirebaseContext();
            const requireUpfrontPayment = yield getNoticeRequiresUpfrontPayment(ctx, noticeSnap);
            setRequireUpfrontPayment(!isWithinBulkPayment && requireUpfrontPayment);
            const shouldInvoiceOutsideColumn = yield getNoticeIsInvoicedOutsideColumn(ctx, noticeSnap);
            if (shouldInvoiceOutsideColumn) {
                setInvoiceOutsideColumn(true);
            }
        }))();
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-white shadow overflow-hidden sm:rounded-lg mt-5" },
            React.createElement("div", { className: "px-4 py-5 sm:p-0" },
                React.createElement("dl", null,
                    React.createElement("div", { className: "flex justify-between items-center px-4 pt-5 pb-3 border-b border-gray-300" },
                        React.createElement("div", { className: "flex items-center text-sm leading-5 font-bold text-gray-800" },
                            React.createElement("div", { className: "mr-2" }, "Require upfront payment?"),
                            React.createElement(Tooltip, { title: `When enabled, upfront payment will be required such that invoice will have a due date one day prior the deadline` },
                                React.createElement(Help, { color: "disabled", style: { width: '1rem', fill: '#2A394A' } }))),
                        React.createElement("div", { className: "flex mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0" },
                            React.createElement(Tooltip, { title: disableRequireUpfrontPayment
                                    ? isWithinBulkPayment
                                        ? `This publisher is set to pay invoices in bulk at the end of the month.`
                                        : `The ad deadline for this notice has already passed, and the advertiser will not have enough time to fulfill the invoice before publication.`
                                    : `` },
                                React.createElement("button", { type: "button", id: "require-upfront-payment", className: `flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${requireUpfrontPayment && !currentSameOrBefore
                                        ? 'bg-blue-650 bg-opacity-75'
                                        : 'bg-gray-200'}`, onClick: () => {
                                        !disableRequireUpfrontPayment &&
                                            !currentSameOrBefore &&
                                            setRequireUpfrontPayment(!requireUpfrontPayment);
                                    } },
                                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                                    React.createElement("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-400 ${requireUpfrontPayment &&
                                            !currentSameOrBefore &&
                                            'bg-blue-650 bg-opacity-75'}` }),
                                    React.createElement("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${requireUpfrontPayment &&
                                            !currentSameOrBefore &&
                                            'translate-x-5'}` }))))),
                    newspaper.data().allowInvoiceOutsideColumn && (React.createElement("div", { className: "flex justify-between items-center px-4 pt-5 pb-3 border-b border-gray-300" },
                        React.createElement("div", { className: "flex items-center text-sm leading-5 font-bold text-gray-800" },
                            React.createElement("div", { className: "mr-2" }, "Invoice outside of Column?"),
                            React.createElement(Tooltip, { title: `When enabled, invoices will be sent outside of the Column System` },
                                React.createElement(Help, { color: "disabled", style: { width: '1rem', fill: '#2A394A' } }))),
                        React.createElement("div", { className: "flex mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0" },
                            React.createElement(Tooltip, { title: invoiceOutsideColumn
                                    ? `This customer will receive their invoice outside of Column`
                                    : 'This customer will receive their invoice inside of Column' },
                                React.createElement("button", { type: "button", id: "require-upfront-payment", className: `flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${invoiceOutsideColumn
                                        ? 'bg-blue-650 bg-opacity-75'
                                        : 'bg-gray-200'}`, onClick: () => {
                                        setInvoiceOutsideColumn(!invoiceOutsideColumn);
                                    } },
                                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                                    React.createElement("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-400 ${invoiceOutsideColumn &&
                                            !currentSameOrBefore &&
                                            'bg-blue-650 bg-opacity-75'}` }),
                                    React.createElement("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${invoiceOutsideColumn && 'translate-x-5'}` })))))),
                    React.createElement("div", { className: "flex justify-between items-center px-4 pt-3 pb-5" },
                        React.createElement("div", { className: "text-sm leading-5 font-bold text-gray-800" }, "Invoice Due Date"),
                        React.createElement("div", { className: "w-32 mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0" },
                            React.createElement(Tooltip, { title: isWithinBulkPayment
                                    ? `This publisher is set to pay invoices in bulk at the end of the month.`
                                    : `` },
                                React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                                    React.createElement(DatePicker, { "data-testid": "datePicker", label: "", value: dueDate &&
                                            ianaTimezone &&
                                            moment(dueDate * 1000)
                                                .tz(ianaTimezone)
                                                .toDate()
                                                .toLocaleString('en-US'), placeholder: "MMM dd, YYYY", format: "MMM dd, YYYY", className: 'text-xs', InputProps: {
                                            disableUnderline: true,
                                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                                React.createElement(CalendarIcon, { className: "text-xs p-0.25" }))),
                                            className: 'text-xs'
                                        }, shouldDisableDate: date => {
                                            if (!date) {
                                                return true;
                                            }
                                            const now = new Date();
                                            if (date.getTime() < now.getTime())
                                                return true;
                                            if (requireUpfrontPayment &&
                                                deadline &&
                                                date.getTime() > deadline.getTime()) {
                                                return true;
                                            }
                                            return false;
                                        }, autoOk: true, onChange: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                            if (ianaTimezone && date) {
                                                if (requireUpfrontPayment) {
                                                    const dueDate = yield getDueDate(getFirebaseContext(), noticeSnap, requireUpfrontPayment, moment(date.getTime()).tz(ianaTimezone).toDate());
                                                    setDueDate(dueDate);
                                                }
                                                else {
                                                    setDueDate(moment(date.getTime())
                                                        .tz(ianaTimezone)
                                                        .toDate()
                                                        .getTime() /
                                                        1000 -
                                                        2);
                                                }
                                            }
                                        }), minDateMessage: "Selected date after publication deadline", disabled: isWithinBulkPayment }))))))))));
};
export default InvoiceFormDueDate;
