import React from 'react';
import { PartyEmoji } from 'gifs';
import { ColumnButton } from 'components/ColumnButton';
export default function AdvertiserRegistrationSuccess({ userName, subHeaderText, onConfirmSuccess }) {
    return (React.createElement("div", { className: "flex flex-col min-h-screen items-center justify-center -mt-8" },
        React.createElement("div", { className: "h-24 w-24 rounded-full flex items-center justify-center bg-yellow-200" },
            React.createElement("img", { src: PartyEmoji, className: "p-4" })),
        React.createElement("p", { className: "mt-6 text-gray-900 font-semibold leading-8 text-2xl p-3 text-center" }, `Great job${` ${userName}`}. You are all set.`),
        React.createElement("p", { className: "text-gray-600 font-medium text-sm leading-6 w-128 text-center p-2 mb-6" }, subHeaderText),
        React.createElement(ColumnButton, { id: "complete-registration", onClick: () => onConfirmSuccess(), buttonText: "Okay, thanks", primary: true })));
}
