import React from 'react';
import { ColumnButton } from 'components/ColumnButton';
import { XIcon } from 'icons';
export function EditModalHeader({ onSave, onClose, disableSave, isCustomerOrganization }) {
    return (React.createElement("div", { className: "flex justify-between py-4 px-6 bg-white border border-grey-100" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement(ColumnButton, { startIcon: React.createElement(XIcon, { className: "h-6 w-6" }), size: "md", onClick: onClose }),
            React.createElement("span", { className: "pl-4 text-gray-800 font-semibold text-xl" },
                "Edit ",
                isCustomerOrganization ? 'Organization' : 'Customer')),
        React.createElement(ColumnButton, { primary: true, buttonText: "Save", size: "lg", onClick: () => {
                void onSave();
            }, disabled: disableSave })));
}
