import { isArray, isObject } from 'lodash';
import stringify from 'safe-stable-stringify';
/**
 * Determine if an object is an ERef
 */
const isRef = (val) => {
    return (val &&
        typeof val === 'object' &&
        !!val._firestore &&
        (!!val._delegate || !!val._path));
};
/**
 * Recursively walk an object and replace ERef with their string path representation.
 */
const replaceReferences = (obj, depth = 0) => {
    // This is just a safeguard against infinite recursion
    if (depth >= 10) {
        return 'max-depth-exceeded';
    }
    if (obj === undefined || obj === null) {
        return obj;
    }
    if (isRef(obj)) {
        return obj.path;
    }
    if (isArray(obj)) {
        return obj.map(el => replaceReferences(el, depth + 1));
    }
    if (isObject(obj)) {
        const replaced = {};
        Object.entries(obj).forEach(([key, value]) => {
            replaced[key] = replaceReferences(value, depth + 1);
        });
        return replaced;
    }
    return obj;
};
/**
 * Safely stringifies an object with stable key ordering, more suitable
 * than JSON.stringify for hashing complex objects.
 */
export const safeStringify = (val, options) => {
    if (options === null || options === void 0 ? void 0 : options.replaceReferences) {
        return stringify(replaceReferences(val));
    }
    return stringify(val);
};
