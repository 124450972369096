var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import deepEqual from 'deep-equal';
import { useTranslation } from 'react-i18next';
// note that we need to do this import from the enum directly to avoid overriding
// the child property
import { exists } from 'lib/types';
import SubmitButton from 'components/SubmitButton';
import { Country, NotificationType, OccupationType, State } from 'lib/enums';
import { getLocalizedDescription } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import Firebase, { Collections } from '../../EnoticeFirebase';
import SettingsHeader from './SettingsHeader';
import { affidavitsAreManagedByColumn } from './publisher/affidavits/affidavitSettingsUtils';
/**
 * Removes irrelevant notifications for publishers in the UI. For example, don't show publishers
 * that aren't using automated affidavits the automated affidavit notification
 * @param notifications
 * @param activeOrg
 * @param childOrgs
 */
const filterNotifications = (notifications, activeOrg, childOrgs) => {
    var _a;
    const filteredNotifications = Object.assign({}, notifications);
    Object.keys(filteredNotifications).forEach(notif_key => {
        if (!filteredNotifications[notif_key].isConfigurable) {
            delete filteredNotifications[notif_key];
        }
    });
    const activeOrgNotInFlorida = ((_a = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.data()) === null || _a === void 0 ? void 0 : _a.state) !== State.florida.value;
    const childOrgsNotInFlorida = childOrgs.every(childOrg => { var _a; return ((_a = childOrg === null || childOrg === void 0 ? void 0 : childOrg.data()) === null || _a === void 0 ? void 0 : _a.state) !== State.florida.value; });
    if (activeOrgNotInFlorida && childOrgsNotInFlorida) {
        delete filteredNotifications.survey_email;
    }
    // delete affidavit notification if affidavits are not managed by column
    if (activeOrg && !affidavitsAreManagedByColumn(activeOrg)) {
        delete filteredNotifications.automated_affidavit_e_edition_upload_success;
    }
    return filteredNotifications;
};
const NotificationSettings = ({ userAuth, user, activeOrganization }) => {
    var _a;
    const docRef = Firebase.firestore()
        .collection(Collections.users)
        .doc(userAuth.uid);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState();
    const [organizationLevelNotifications, setOrganizationLevelNotifications] = useState();
    const [country, setCountry] = useState(Country.USA.value);
    const [childOrgs, setChildOrgs] = useState([]);
    useEffect(() => {
        const getChildOrgs = () => __awaiter(void 0, void 0, void 0, function* () {
            const queriedChildOrgs = yield getFirebaseContext()
                .organizationsRef()
                .where('parent', '==', activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref)
                .get();
            setChildOrgs(queriedChildOrgs.docs);
        });
        if (activeOrganization) {
            void getChildOrgs();
        }
    }, [activeOrganization]);
    useEffect(() => {
        if (!exists(user))
            return;
        if (!deepEqual(user === null || user === void 0 ? void 0 : user.data().notifications, notifications)) {
            setNotifications(user.data().notifications);
        }
        if (user.data().occupation !== OccupationType.publishing.value &&
            user.data().organization)
            setOrganizationLevelNotifications(user.data().receiveOrganizationNotifications);
    }, [user]);
    useEffect(() => {
        var _a, _b;
        if ((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.country) {
            setCountry((_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.country);
        }
    }, [(_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.country]);
    const updateNotifications = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setLoading(true);
        if (organizationLevelNotifications)
            yield docRef.update({
                receiveOrganizationNotifications: organizationLevelNotifications
            });
        const oldNotifications = (_b = (yield docRef.get()).data()) === null || _b === void 0 ? void 0 : _b.notifications;
        yield docRef.update({
            notifications: Object.assign(Object.assign({}, oldNotifications), notifications)
        });
        setLoading(false);
    });
    const NotificationSettingsRow = ({ notification_key, notification }) => {
        const [description, setDescription] = useState('');
        const { t } = useTranslation();
        const matchingEnum = notification_key === 'organization_level'
            ? {
                key: 'organization_level',
                label: `${t('notifications.organization-level.label')}`,
                description: `${t('notifications.organization-level.description')}`
            }
            : NotificationType.by_key(notification_key);
        useEffect(() => {
            if (country && matchingEnum) {
                setDescription(getLocalizedDescription(matchingEnum, country));
            }
        }, [country]);
        if (!matchingEnum)
            return null;
        const { label, key } = matchingEnum;
        return (React.createElement("tr", null,
            React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5" },
                React.createElement("div", { className: "text-base text-gray-700 font-medium" }, label),
                React.createElement("div", { className: `text-base text-gray-700 font-normal 
              ${key === 'invoice_notifications' && 'mb-6'}
            ` }, description)),
            React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5" },
                React.createElement("button", { type: "button", id: `${notification_key}_email`, className: `flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${notification.email ? 'bg-blue-650 bg-opacity-75' : 'bg-gray-200'}`, onClick: () => {
                        if (notification_key === 'organization_level') {
                            setOrganizationLevelNotifications({
                                inApp: !!(organizationLevelNotifications === null || organizationLevelNotifications === void 0 ? void 0 : organizationLevelNotifications.inApp),
                                email: !(organizationLevelNotifications === null || organizationLevelNotifications === void 0 ? void 0 : organizationLevelNotifications.email)
                            });
                            return;
                        }
                        const newNotifications = Object.assign({}, notifications);
                        newNotifications[notification_key] = {
                            inApp: notification.inApp,
                            email: !notification.email,
                            isConfigurable: notification.isConfigurable
                        };
                        if (notification_key === 'survey_email') {
                            newNotifications.survey_reminder = Object.assign(Object.assign({}, newNotifications[notification_key]), { isConfigurable: false });
                        }
                        const notificationEnum = NotificationType.by_key(notification_key);
                        if (notificationEnum) {
                            for (const { key } of notificationEnum.children()) {
                                newNotifications[key] = Object.assign(Object.assign({}, newNotifications[key]), { email: !notification.email });
                            }
                        }
                        setNotifications(newNotifications);
                    } },
                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200 ${notification.email && 'bg-blue-650 bg-opacity-75'}` }),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${notification.email && 'translate-x-5'}` }))),
            React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5" },
                React.createElement("button", { type: "button", id: `${notification_key}_inApp`, className: `flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${notification.inApp ? 'bg-blue-650 bg-opacity-75' : 'bg-gray-200'}`, onClick: () => {
                        if (notification_key === 'organization_level') {
                            setOrganizationLevelNotifications({
                                inApp: !(organizationLevelNotifications === null || organizationLevelNotifications === void 0 ? void 0 : organizationLevelNotifications.inApp),
                                email: !!(organizationLevelNotifications === null || organizationLevelNotifications === void 0 ? void 0 : organizationLevelNotifications.email)
                            });
                            return;
                        }
                        const newNotifications = Object.assign({}, notifications);
                        newNotifications[notification_key] = {
                            inApp: !notification.inApp,
                            email: notification.email,
                            isConfigurable: notification.isConfigurable
                        };
                        if (notification_key === 'survey_email') {
                            newNotifications.survey_reminder = Object.assign(Object.assign({}, newNotifications[notification_key]), { isConfigurable: false });
                        }
                        const notificationEnum = NotificationType.by_key(notification_key);
                        if (notificationEnum) {
                            for (const { key } of notificationEnum.children()) {
                                newNotifications[key] = Object.assign(Object.assign({}, newNotifications[key]), { inApp: !notification.inApp });
                            }
                        }
                        setNotifications(newNotifications);
                    } },
                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200 ${notification.inApp && 'bg-blue-650 bg-opacity-75'}` }),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${notification.inApp && 'translate-x-5'}` })))));
    };
    if (!notifications)
        return null;
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Notification Settings", description: "Control how often you hear from us." }),
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, ' '),
                    React.createElement("th", { className: "pl-2 pr-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "email"),
                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "in app"))),
            React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" },
                organizationLevelNotifications && (React.createElement(NotificationSettingsRow, { notification_key: 'organization_level', notification: Object.assign({ isConfigurable: true }, organizationLevelNotifications) })),
                Object.keys(filterNotifications(notifications, activeOrganization, childOrgs))
                    .filter(notification => {
                    return NotificationType.by_key(notification);
                })
                    .sort((a, b) => {
                    var _a, _b, _c, _d;
                    const priorityA = (_b = (_a = NotificationType.by_key(a)) === null || _a === void 0 ? void 0 : _a.priority) !== null && _b !== void 0 ? _b : 0;
                    const priorityB = (_d = (_c = NotificationType.by_key(b)) === null || _c === void 0 ? void 0 : _c.priority) !== null && _d !== void 0 ? _d : 0;
                    return priorityB - priorityA;
                })
                    // eslint-disable-next-line array-callback-return
                    .map(notification_key => {
                    if (notification_key !== 'new_bulk_invoice' ||
                        (notification_key === 'new_bulk_invoice' &&
                            user.data().bulkPaymentEnabled))
                        return (React.createElement(NotificationSettingsRow, { notification_key: notification_key, notification: notifications[notification_key], key: notification_key }));
                }))),
        React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
            React.createElement(SubmitButton, { id: "submit_noificaions_settings", disabled: loading, loading: loading, text: "Save", type: "submit", onClick: () => updateNotifications() }))));
};
export default NotificationSettings;
