var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import sanitize from 'sanitize-html';
import moment from 'moment-timezone';
import convert from 'convert-length';
import { getErrorReporter } from './utils/errors';
import { FIREBASE_PROJECT, CLOUDINARY_BUCKET, envs } from './constants';
import { exists } from './types';
import { getDeadlineTimeForPaper, publishingDayEnumValuesFromDeadlines, disableNonPublishingDays, getIsAfterPublishingDeadline } from './utils/deadlines';
import { OrganizationType, OccupationType, InvoiceStatus, NoticeStatusType, NoticeType, CurrencyType, Country, RateType } from './enums';
import { FileType } from './types/upload';
import { getOrCreateCustomer, getOrCreateCustomerOrganizationForNotice, getOrCreateCustomerOrganization, getShouldInvoiceCustomerOrCustomerOrgOutsideColumn } from './notice/customer';
import { shouldPreserveLongSequencesForNotice, htmlToIndesignHtml } from './indesign/helpers';
import { addFooterXML, generateFormattedFooter } from './headers_footers/footers';
import { getColumnCentimeters, getColumnInches } from './pricing';
import { callIndesignServer } from './requests';
import { getAllowedOrgsAndRolesFromParent, OPEN_INVITE_STATUSES } from './users';
import { isAffidavitDisabled } from './affidavits';
import { getAdTemplateCacheID } from './types/templates';
import { hasPaymentOrPartialRefund } from './utils/invoices';
import { getNewspaperFromNotice } from './utils/references';
export const getOccupationValFromOrganizationVal = (organizationVal) => {
    var _a;
    const organizationType = OrganizationType.by_value(organizationVal);
    return (_a = OccupationType.by_key(organizationType === null || organizationType === void 0 ? void 0 : organizationType.defaultOccupationKey)) === null || _a === void 0 ? void 0 : _a.value;
};
export const getOrganizationValFromOccupationVal = (occupationVal) => {
    var _a, _b;
    const occupationKey = (_a = OccupationType.by_value(occupationVal)) === null || _a === void 0 ? void 0 : _a.key;
    return (_b = OrganizationType.items().find(org => org && org.defaultOccupationKey === occupationKey)) === null || _b === void 0 ? void 0 : _b.value;
};
export const dateToDateString = (date, timezone) => {
    date.setHours(12);
    return moment(date)
        .tz(timezone || 'America/Chicago')
        .format(!(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America')) ? 'D MMM YYYY' : 'MMM. D, YYYY');
};
export const publicationDateStarted = (publicationDate, timezone) => {
    const currentTime = moment(Date.now());
    return currentTime.isAfter(moment(publicationDate).tz(timezone).startOf('day'));
};
/**
 * Convert unix timestamp into a date based on the given time zone
 * @param {number} timestamp
 * @param {string} timezone
 * @returns Date with a format according to the timezone
 */
export const unixTimeStampToNewspaperTimezoneDate = (timestamp, timezone) => {
    return moment(new Date(timestamp * 1000))
        .tz(timezone || 'America/Chicago')
        .format(!(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America')) ? 'D MMM YYYY' : 'MMM. D, YYYY');
};
export const dateToAbbrev = (date, timezone) => {
    if (!timezone)
        date.setHours(12);
    return moment(date)
        .tz(timezone || 'America/Chicago')
        .format(timezone && !(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America')) ? 'D/M/YY' : 'M/D/YY');
};
export const dateToUtc = (date) => moment.utc(date).format('MMM. D, YYYY');
export const toLocaleString = (date) => moment(date).format('MMM. D, YYYY');
/**
 * Convert a date-like object to a JS Date.
 */
export const firestoreTimestampOrDateToDate = (date) => {
    try {
        if (date._seconds)
            return new Date(date._seconds * 1000);
    }
    catch (err) { }
    if (!date)
        throw new Error('date is undefined');
    try {
        return date.toDate();
    }
    catch (err) {
        if (date.seconds)
            return new Date(date.seconds * 1000);
        if (typeof date === 'number')
            return new Date(date);
        if (typeof date === 'string')
            return new Date(date);
        return date;
    }
};
export const areSameDay = (d1, d2) => {
    return (d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear());
};
/**
 * Checks if the user's local date is on the same UTC day of the given timestamp
 * @param {Date} date The user's local date
 * @param {number} timestamp The start of UTC day timestamp
 * @returns {boolean}
 */
export const areSameDayUTC = (date, timestamp) => {
    return (moment.utc(moment(date).format('YYYY/MM/DD')).startOf('day').valueOf() ===
        timestamp);
};
export const escapeHTML = (str) => {
    const entityMap = new Map(Object.entries({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    }));
    return str.replace(/[&<>"']/g, (tag) => entityMap.get(tag) || tag);
};
export const centsToDollarsString = (cents) => {
    return (cents / 100).toFixed(2);
};
export const confirmationNumberFromTransferId = (transferId) => {
    return transferId.slice(-8);
};
export const guidGenerator = () => {
    const S4 = function () {
        return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    };
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};
export const rateCodeGenerator = () => {
    return Math.floor(100000 + Math.random() * 900000);
};
export const sameDay = (d1, d2) => {
    return (d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate());
};
const isValidDate = (d) => {
    return !Number.isNaN(Date.parse(d.toDateString()));
};
export const closestDayFutureDay = (dates) => {
    let closest = Infinity;
    const startOfToday = new Date(Date.now());
    startOfToday.setHours(0, 0, 0, 0);
    const comparableStartOfToday = startOfToday.getTime();
    dates.forEach(date => {
        const comparableDate = date.getTime();
        if (comparableDate >= comparableStartOfToday && comparableDate < closest) {
            closest = comparableDate;
        }
    });
    const close = new Date(closest);
    if (!isValidDate(close))
        return null;
    return close;
};
export const lastPublicationDate = (dates) => {
    const lastDate = dates[dates.length - 1];
    if (!lastDate)
        return null;
    if (!isValidDate(lastDate))
        return null;
    return lastDate;
};
export const firstNoticePublicationTimestamp = (noticeSnap) => {
    return noticeSnap.data().publicationDates.sort((aTimestamp, bTimesamp) => {
        const a = aTimestamp.toDate();
        const b = bTimesamp.toDate();
        return a < b ? -1 : a > b ? 1 : 0;
    })[0];
};
export const firstNoticePublicationDate = (noticeSnap) => {
    return firstNoticePublicationTimestamp(noticeSnap).toDate();
};
export const lastNoticePublicationDate = (noticeSnap) => noticeSnap
    .data()
    .publicationDates.map(fbTime => fbTime.toDate())
    .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))[0];
export const canPublisherEditNoticeWithoutSupport = (noticeSnap) => {
    if (!noticeSnap.data().publicationDates)
        return true;
    return new Date() < firstNoticePublicationDate(noticeSnap);
};
export const canAdvertiserEditNoticeWithoutSupport = (noticeSnap, newspaperSnap) => {
    const publicationDayEnumValues = publishingDayEnumValuesFromDeadlines(newspaperSnap.data().deadlines);
    // get any unusual publication dates that might have been edited in by the publisher
    const customPublicationDate = noticeSnap.data().publicationDates.find(date => 
    // returns true if date isn't a valid publication date
    disableNonPublishingDays(date.toDate(), publicationDayEnumValues, newspaperSnap.data().deadlineOverrides));
    // if there is an unusual publication date, the advertiser cannot edit this notice
    if (customPublicationDate)
        return false;
    const nearestDeadline = getDeadlineTimeForPaper(firstNoticePublicationDate(noticeSnap), newspaperSnap.data().deadlines, newspaperSnap.data().iana_timezone, noticeSnap.data(), newspaperSnap);
    return moment(new Date()).isBefore(nearestDeadline);
};
export const assetQuality = {
    high: {
        width: 1000
    }
};
export const cdnIfy = (storagePath, options = {}) => {
    const fileType = storagePath && storagePath.split('.').pop();
    const requiresImgix = (options === null || options === void 0 ? void 0 : options.useImgix) ||
        [
            FileType.IDML,
            FileType.HTML,
            FileType.CSV,
            FileType.WORD_DOC,
            FileType.DOCX,
            FileType.TIF,
            FileType.TIFF,
            FileType.HEIC,
            FileType.HEIF,
            FileType.ZIP
        ].includes(fileType === null || fileType === void 0 ? void 0 : fileType.toLowerCase());
    if (requiresImgix)
        return `https://${FIREBASE_PROJECT}.imgix.net/${storagePath}${options.imgixTransformations
            ? `?${new URLSearchParams(options.imgixTransformations).toString()}`
            : ''}`;
    const isImage = [
        FileType.TIF,
        FileType.PNG,
        FileType.JPG,
        FileType.JPEG,
        FileType.PDF
    ].includes(fileType);
    if (!FIREBASE_PROJECT)
        throw new Error('Cannot create CDN link without firebase project');
    if (isImage) {
        return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/image/upload${(options === null || options === void 0 ? void 0 : options.cloudinaryTransformations)
            ? `/${options.cloudinaryTransformations}`
            : ''}/${FIREBASE_PROJECT}/${storagePath}?invalidate=true`;
    }
    return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/raw/upload/${FIREBASE_PROJECT}/${storagePath}?invalidate=true`;
};
export const preventXSS = (html) => sanitize(html, {
    allowedTags: ['div', 'strong', 'ais-highlight-0000000000']
});
export const getNoticeTypeFromNoticeData = (notice, newspaper, options) => {
    var _a, _b;
    const newspaperSpecificType = (_b = (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) === null || _b === void 0 ? void 0 : _b.find(type => {
        if (notice.noticeType === NoticeType.display_ad.value &&
            (options === null || options === void 0 ? void 0 : options.skipDisplayType) &&
            notice.previousNoticeType)
            return type.value === notice.previousNoticeType;
        return type.value === notice.noticeType;
    });
    if (newspaperSpecificType)
        return newspaperSpecificType;
    const noticeType = NoticeType.by_value(notice.noticeType);
    if (noticeType) {
        return noticeType;
    }
    return null;
};
export const getNoticeType = (noticeSnap, newspaperSnap, options) => {
    if (!(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data()))
        return NoticeType.custom;
    return getNoticeTypeFromNoticeData(noticeSnap.data(), newspaperSnap, options);
};
export const DEFAULT_DPI = 600;
export const inchesToPixels = (inches, cols, ppi) => {
    const PPI = { pixelsPerInch: ppi || DEFAULT_DPI };
    return convert(inches, 'in', 'px', PPI);
};
export const noticeNeedsUpFrontInvoice = (notice, newspaper) => {
    var _a;
    if (!newspaper.data().allowedNotices)
        return false;
    if (notice.data().noticeType === NoticeType.custom.value)
        return false;
    if (notice.data().noticeType === NoticeType.display_ad.value)
        return false;
    if (notice.data().invoice)
        return false;
    if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    const typeform = (_a = newspaper
        .data()
        .allowedNotices.find(an => an.value === notice.data().noticeType)) === null || _a === void 0 ? void 0 : _a.typeform;
    if (!typeform)
        return false;
    return true;
};
/**
 * A notice should be auto-invoiced if:
 *  - autoInvoice is set on the newspaper, rate, or notice type AND
 *  - notice is not custom or cancelled
 */
export const shouldAutoInvoice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    // Custom or canceled notices do not auto-invoice
    if (noticeSnap.data().noticeType === NoticeType.custom.value ||
        noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value) {
        return false;
    }
    // TODO(COREDEV-1662): Use new parent boolean helper once merged!
    const newspaperSnap = yield noticeSnap.data().newspaper.get();
    if (!exists(newspaperSnap)) {
        throw new Error(`Newspaper ${newspaperSnap.id} for notice ${noticeSnap.id} does not exist!`);
    }
    const parentSnap = yield ((_a = newspaperSnap.data().parent) === null || _a === void 0 ? void 0 : _a.get());
    const newspaperAutoInvoice = newspaperSnap.data().autoInvoice || ((_b = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _b === void 0 ? void 0 : _b.autoInvoice);
    if (newspaperAutoInvoice) {
        return true;
    }
    const rateSnap = yield ((_c = noticeSnap.data().rate) === null || _c === void 0 ? void 0 : _c.get());
    if ((_d = rateSnap === null || rateSnap === void 0 ? void 0 : rateSnap.data()) === null || _d === void 0 ? void 0 : _d.autoInvoice) {
        return true;
    }
    const noticeTypeAutoInvoice = !!((_e = newspaperSnap
        .data()
        .allowedNotices) === null || _e === void 0 ? void 0 : _e.find(an => an.value === noticeSnap.data().noticeType && an.autoInvoice));
    if (noticeTypeAutoInvoice) {
        return true;
    }
    return false;
});
export const getShouldInvoiceNoticeOutsideColumnByNoticeType = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    const newspaperSnap = (yield noticeSnap
        .data()
        .newspaper.get());
    return (!!((_f = newspaperSnap
        .data()
        .allowedNotices) === null || _f === void 0 ? void 0 : _f.find(an => an.value === noticeSnap.data().noticeType &&
        an.invoiceOutsideColumn)) &&
        !!newspaperSnap.data().allowInvoiceOutsideColumn &&
        noticeSnap.data().noticeType !== NoticeType.custom.value &&
        noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value);
});
export const getShouldSendAffidavitNotification = (noticeSnap, newspaperSnap, advertiserSnap) => {
    const { requireEmailAffidavit, affidavit } = noticeSnap.data();
    if (!requireEmailAffidavit || !affidavit) {
        return false;
    }
    if (!exists(newspaperSnap) || !exists(advertiserSnap)) {
        return false;
    }
    const affidavitDisabled = isAffidavitDisabled(noticeSnap, newspaperSnap);
    if (affidavitDisabled) {
        return false;
    }
    return true;
};
export const getShouldSendAffidavitNotificationForNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield noticeSnap.data().newspaper.get();
    const advertiserSnap = yield noticeSnap.data().filer.get();
    return getShouldSendAffidavitNotification(noticeSnap, newspaperSnap, advertiserSnap);
});
// Returns if we should release the affidavit of a notice for the advertiser before invoice payment
export const getAlwaysAllowAffidavitDownloadForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _g, _h, _j, _k, _l;
    const newspaper = yield noticeSnap.data().newspaper.get();
    const advertiserOrg = yield ((_g = noticeSnap.data().filedBy) === null || _g === void 0 ? void 0 : _g.get());
    const advertiser = yield ((_h = noticeSnap.data().filer) === null || _h === void 0 ? void 0 : _h.get());
    const customer = yield getOrCreateCustomer(ctx, advertiser, newspaper);
    return (((_j = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data()) === null || _j === void 0 ? void 0 : _j.alwaysAllowAffidavitDownload) ||
        ((_k = advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.data()) === null || _k === void 0 ? void 0 : _k.alwaysAllowAffidavitDownload) ||
        ((_l = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _l === void 0 ? void 0 : _l.alwaysAllowAffidavitDownload) ||
        (customer === null || customer === void 0 ? void 0 : customer.data().enableAffidavitsBeforePayment));
});
export const shouldReleaseAffidavit = (ctx, noticeSnap, invoiceSnap) => __awaiter(void 0, void 0, void 0, function* () {
    if (!noticeSnap.data().affidavit) {
        throw new Error(`Should release affidavit helper called on a notice without an affidavit!`);
    }
    const alwaysAllowAffidavitDownload = yield getAlwaysAllowAffidavitDownloadForNotice(ctx, noticeSnap);
    const isInvoicePaid = exists(invoiceSnap) &&
        (hasPaymentOrPartialRefund(invoiceSnap) ||
            invoiceSnap.data().invoiceOutsideColumn);
    if (isInvoicePaid)
        return true;
    if (alwaysAllowAffidavitDownload)
        return true;
    return false;
});
export const shouldReleaseAffidavitForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _m;
    const invoiceSnap = yield ((_m = noticeSnap.data().invoice) === null || _m === void 0 ? void 0 : _m.get());
    return shouldReleaseAffidavit(ctx, noticeSnap, invoiceSnap);
});
export const getCustomAffidavit = (noticeSnap, userSnap, newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _o, _p, _q, _r;
    const noticeType = getNoticeType(noticeSnap, newspaperSnap);
    const rate = yield ((_o = noticeSnap.data().rate) === null || _o === void 0 ? void 0 : _o.get());
    return (noticeSnap.data().customAffidavit ||
        ((_p = userSnap.data()) === null || _p === void 0 ? void 0 : _p.customAffidavit) ||
        ((_q = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _q === void 0 ? void 0 : _q.customAffidavit) ||
        (noticeType === null || noticeType === void 0 ? void 0 : noticeType.customAffidavit) ||
        ((_r = newspaperSnap.data()) === null || _r === void 0 ? void 0 : _r.customAffidavit));
});
export const getAllowMultiPageAffidavits = (noticeSnap, newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _s, _t, _u;
    const rate = yield ((_s = noticeSnap.data().rate) === null || _s === void 0 ? void 0 : _s.get());
    console.log(rate.id);
    return (((_t = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _t === void 0 ? void 0 : _t.multiPageAffidavits) ||
        ((_u = newspaperSnap.data()) === null || _u === void 0 ? void 0 : _u.multiPageAffidavits));
});
/**
 * This function Ensures that dates passed from frontend to backend are same
 * @param date Date string from new Date().toDateString();
 * @returns newDate;
 */
export const ensureSameDate = (date) => {
    if (!moment(date, 'ddd MMM DD YYYY', true).isValid())
        throw new Error('Invalid Date Format');
    return moment(date).toDate();
};
export const getCurrencySymbol = (currency) => {
    var _a, _b;
    return ((_b = (_a = CurrencyType.by_key(currency === null || currency === void 0 ? void 0 : currency.toLowerCase())) === null || _a === void 0 ? void 0 : _a.symbol) !== null && _b !== void 0 ? _b : CurrencyType.usd.symbol);
};
export const getLocalizedDescription = (notification, country) => {
    var _a, _b, _c, _d;
    const countryKey = (_b = (_a = Country.by_value(country)) === null || _a === void 0 ? void 0 : _a.key) !== null && _b !== void 0 ? _b : '';
    const localized = (_c = notification === null || notification === void 0 ? void 0 : notification.localized_description) === null || _c === void 0 ? void 0 : _c[countryKey];
    return (_d = localized !== null && localized !== void 0 ? localized : notification.description) !== null && _d !== void 0 ? _d : '';
};
export const showConvertToLinerWarning = (displayOnlyAds, isDisplay, showDisplayError, postWithoutFormatting) => {
    return (!displayOnlyAds && isDisplay && showDisplayError && !postWithoutFormatting);
};
/*
 * This function is used to determine whether or not a
 * notice requires upfront payment at any time. If
 * called before invoice creation, we pull from the
 * settings of associated objects. During invoice
 * creation, the user can use the override toggle &
 * then that value is set on the notice object.
 */
export const getNoticeRequiresUpfrontPayment = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _v;
    // Logic hierarchy for requireUpfrontPayment:
    // 0. Notice
    if (noticeSnap.data().requireUpfrontPayment != null) {
        return noticeSnap.data().requireUpfrontPayment;
    }
    // 1. Customer (user x for newspaper y)
    const newspaperSnap = (yield noticeSnap
        .data()
        .newspaper.get());
    const advertiserSnap = (yield noticeSnap
        .data()
        .filer.get());
    const customer = yield getOrCreateCustomer(ctx, advertiserSnap, newspaperSnap);
    if (customer.data().requireUpfrontPayment != null) {
        return customer.data().requireUpfrontPayment;
    }
    // 2. Customer organization (advertiser org x for newspaper y)
    const customerOrganization = yield getOrCreateCustomerOrganizationForNotice(ctx, noticeSnap);
    if ((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().requireUpfrontPayment) != null) {
        return customerOrganization.data().requireUpfrontPayment;
    }
    // 3. Custom notice type
    const noticeType = getNoticeType(noticeSnap, newspaperSnap);
    if (noticeType && noticeType.upFrontPayment != null) {
        return noticeType.upFrontPayment;
    }
    // 4. Newspaper (does the newspaper default to requiring upfront payment?)
    if (((_v = newspaperSnap.data()) === null || _v === void 0 ? void 0 : _v.requireUpfrontPayment) != null) {
        return newspaperSnap.data().requireUpfrontPayment;
    }
    return false;
});
export const getDueDate = (ctx, noticeSnap, requireUpfrontPayment, date) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = (yield noticeSnap
        .data()
        .newspaper.get());
    const { deadlines, iana_timezone } = newspaperSnap.data();
    if (yield shouldBulkInvoiceUser(ctx, noticeSnap, newspaperSnap)) {
        const endOfNextMonth = moment()
            .tz(iana_timezone)
            .add(2, 'M')
            .startOf('month')
            .toDate()
            .getTime() - 2000;
        return endOfNextMonth / 1000;
    }
    if (requireUpfrontPayment) {
        const closestPublicationDate = firstNoticePublicationDate(noticeSnap);
        const deadline = getDeadlineTimeForPaper(closestPublicationDate, deadlines, iana_timezone, noticeSnap.data(), newspaperSnap);
        // If publisher changes due date then get the time of the ad deadline for the first publication date
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            const hours = deadline.toDate().getHours();
            const minutes = deadline.toDate().getMinutes();
            const customDeadline = moment(formattedDate).set({
                hour: hours,
                minute: minutes
            });
            return customDeadline.toDate().getTime() / 1000;
        }
        return deadline.toDate().getTime() / 1000;
    }
    return moment().tz(iana_timezone).add(1, 'M').toDate().getTime() / 1000 - 2;
});
export const standardizePhoneNumber = (phoneNumberString) => __awaiter(void 0, void 0, void 0, function* () {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
});
export const validatePhoneNumbers = (phoneNumberString) => __awaiter(void 0, void 0, void 0, function* () {
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    return !!phoneNumberString.match(phoneRegex);
});
export const getPhoneNumberFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _w, _x, _y, _z;
    const userSnap = (yield notice.data().filer.get());
    const activeOrganizationSnap = (yield ((_w = userSnap
        .data()
        .activeOrganization) === null || _w === void 0 ? void 0 : _w.get()));
    const savedPhone = userSnap.data().occupation === OccupationType.publishing.value
        ? (_x = activeOrganizationSnap === null || activeOrganizationSnap === void 0 ? void 0 : activeOrganizationSnap.data()) === null || _x === void 0 ? void 0 : _x.phone
        : ((_y = userSnap.data()) === null || _y === void 0 ? void 0 : _y.phone) || ((_z = activeOrganizationSnap === null || activeOrganizationSnap === void 0 ? void 0 : activeOrganizationSnap.data()) === null || _z === void 0 ? void 0 : _z.phone);
    const standardizedPhone = yield standardizePhoneNumber(savedPhone);
    if (savedPhone !== standardizedPhone) {
        if (userSnap.data().occupation === OccupationType.publishing.value) {
            yield activeOrganizationSnap.ref.update({
                phone: standardizedPhone
            });
        }
        else {
            yield userSnap.ref.update({
                phone: standardizedPhone
            });
        }
    }
    return standardizedPhone;
});
export const maybeGetXMLSyncExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _0, _1;
    const newspaperXMLExport = newspaperSnap.data().xmlExport;
    if (newspaperXMLExport) {
        return newspaperXMLExport;
    }
    const parent = yield ((_0 = newspaperSnap.data().parent) === null || _0 === void 0 ? void 0 : _0.get());
    const parentXMLExport = (_1 = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _1 === void 0 ? void 0 : _1.xmlExport;
    if (parentXMLExport) {
        return parentXMLExport;
    }
    return undefined;
});
export const getXMLSyncExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const syncExportSettings = yield maybeGetXMLSyncExportSettings(newspaperSnap);
    if (!syncExportSettings) {
        throw new Error(`XML export information not specified for paper ${newspaperSnap.data().name} with ID: ${newspaperSnap.id}`);
    }
    return syncExportSettings;
});
/**
 * Helper function calls getXMLExportFormatFromNewspaper function
 * @param notice Snapshot of notice, used to pull newspaper data
 * @returns      XML Export settings from the newspaper referenced in the notice
 */
export const maybeGetXMLSyncExportFormatFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield getNewspaperFromNotice(notice);
    return yield maybeGetXMLSyncExportSettings(newspaper);
});
/**
 * Helper function calls getXMLExportFormatFromNewspaper function
 * @param notice Snapshot of notice, used to pull newspaper data
 * @returns      XML Export settings from the newspaper referenced in the notice
 */
export const getXMLSyncExportFormatFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield getNewspaperFromNotice(notice);
    return yield getXMLSyncExportSettings(newspaper);
});
export const maybeGetBuildIntegrationExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _2, _3;
    const newspaperBuildExport = newspaperSnap.data().buildExport;
    if (newspaperBuildExport) {
        return newspaperBuildExport;
    }
    const parent = yield ((_2 = newspaperSnap.data().parent) === null || _2 === void 0 ? void 0 : _2.get());
    const parentBuildExport = (_3 = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _3 === void 0 ? void 0 : _3.buildExport;
    if (parentBuildExport) {
        return parentBuildExport;
    }
    return undefined;
});
export const getBuildIntegrationExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const buildExportSettings = yield maybeGetBuildIntegrationExportSettings(newspaperSnap);
    if (!buildExportSettings) {
        throw new Error(`Build export information missing for paper ${newspaperSnap.data().name} with ID ${newspaperSnap.id}`);
    }
    return buildExportSettings;
});
export const maybeGetBuildExportFormatFromNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield getNewspaperFromNotice(noticeSnap);
    return yield maybeGetBuildIntegrationExportSettings(newspaperSnap);
});
export const getBuildExportFormatFromNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield getNewspaperFromNotice(noticeSnap);
    return yield getBuildIntegrationExportSettings(newspaperSnap);
});
export const getExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _4, _5, _6, _7, _8, _9;
    const newspaperFTP = (_4 = newspaperSnap.data().bulkDownload) === null || _4 === void 0 ? void 0 : _4.ftp;
    if (newspaperFTP)
        return newspaperFTP;
    const parent = yield ((_5 = newspaperSnap.data().parent) === null || _5 === void 0 ? void 0 : _5.get());
    if ((_7 = (_6 = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _6 === void 0 ? void 0 : _6.bulkDownload) === null || _7 === void 0 ? void 0 : _7.ftp)
        return (_9 = (_8 = parent.data()) === null || _8 === void 0 ? void 0 : _8.bulkDownload) === null || _9 === void 0 ? void 0 : _9.ftp;
    throw new Error(`FTP export information not specified for paper ${newspaperSnap.data().name} with ID: ${newspaperSnap.id}`);
});
export const getShouldShowUpFrontBilling = (notice, newspaper, invoice, isInvoicedOutsideColumn) => {
    // do not show if invoice hasn't been created or if notice is invoiced outside Column
    if (!invoice || isInvoicedOutsideColumn === undefined)
        return false;
    if (isInvoicedOutsideColumn)
        return false;
    // don't run if notice has been cancelled
    if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    // don't run if the invoice has already been paid
    // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
    if ([InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(invoice.data().status))
        return false;
    if (notice.data().requireUpfrontPayment !== undefined) {
        return (notice.data().requireUpfrontPayment ||
            notice.data().requireUpfrontPaymentModifiedByPublisher);
    }
    const noticeType = getNoticeType(notice, newspaper);
    // short circuit if we are specifically setting the property
    if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.upFrontPayment) !== undefined)
        return noticeType.upFrontPayment;
    if (newspaper.data().requireUpfrontPayment)
        return newspaper.data().requireUpfrontPayment;
    return false;
};
// count bold words from confirmedHtml
export const getBoldWords = (html, DOMparser) => {
    const space = /\s/;
    const doc = new DOMparser().parseFromString(html, 'text/html');
    let totalBoldWords = 0;
    const totalBoldElements = [];
    const elementsWithStrongTag = doc.getElementsByTagName('strong');
    const elementsWithBTag = doc.getElementsByTagName('b');
    for (let i = 0; i < elementsWithStrongTag.length; i++) {
        totalBoldElements.push(elementsWithStrongTag === null || elementsWithStrongTag === void 0 ? void 0 : elementsWithStrongTag[i].innerHTML);
    }
    for (let i = 0; i < elementsWithBTag.length; i++) {
        totalBoldElements.push(elementsWithBTag === null || elementsWithBTag === void 0 ? void 0 : elementsWithBTag[i].innerHTML);
    }
    if (!totalBoldElements.length)
        return 0;
    for (let i = 0; i < totalBoldElements.length; i++) {
        if (space.test(totalBoldElements[i])) {
            const splitText = totalBoldElements[i]
                .split(' ')
                .filter((elem) => elem !== '');
            totalBoldWords += splitText.length;
        }
        else
            totalBoldWords += 1;
    }
    return totalBoldWords;
};
export const isPastDueInNewspaperTimezone = (dueDate, timezone, now) => {
    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_DAY = 86400;
    const MILLISECONDS_IN_SECOND = 1000;
    const dueDateNewspaperTimeZone = dueDate.tz(timezone);
    const offset = dueDateNewspaperTimeZone.utcOffset() * SECONDS_IN_MINUTE;
    return now.isAfter(moment((Math.ceil(dueDateNewspaperTimeZone.unix() / SECONDS_IN_DAY) *
        SECONDS_IN_DAY -
        offset) *
        MILLISECONDS_IN_SECOND));
};
// check if upload file type is accepted
export const getFileExtension = (fileName) => {
    var _a, _b;
    return (_b = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split('.')) === null || _a === void 0 ? void 0 : _a.pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase();
};
export const isValidExtension = (currentFileName, validExtensions) => {
    const extension = getFileExtension(currentFileName);
    if (validExtensions.includes(extension)) {
        return true;
    }
    return false;
};
export const getShouldAutoGenerateOrderNumsForNotice = (newspaper, parent, notice) => {
    var _a;
    const { shouldAutoGenOrderNums: childShouldAutoGenOrderNums } = newspaper.data();
    const { shouldAutoGenOrderNums: parentShouldAutoGenOrderNums } = (parent === null || parent === void 0 ? void 0 : parent.data()) || {};
    const shouldAutoGenOrderNums = (_a = childShouldAutoGenOrderNums !== null && childShouldAutoGenOrderNums !== void 0 ? childShouldAutoGenOrderNums : parentShouldAutoGenOrderNums) !== null && _a !== void 0 ? _a : false;
    if (!shouldAutoGenOrderNums) {
        return false;
    }
    if (typeof shouldAutoGenOrderNums === 'boolean') {
        return shouldAutoGenOrderNums;
    }
    const isDisplay = notice.data().noticeType === NoticeType.display_ad.value;
    if (isDisplay) {
        return shouldAutoGenOrderNums.display;
    }
    return shouldAutoGenOrderNums.liners;
};
export const getShouldShowAccountIDInPlacement = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _10;
    if (((_10 = newspaperSnap.data().showAccountIDInPlacementFlow) !== null && _10 !== void 0 ? _10 : null) !== null) {
        return newspaperSnap.data().showAccountIDInPlacementFlow;
    }
    const { parent } = newspaperSnap.data();
    const parentSnap = yield (parent === null || parent === void 0 ? void 0 : parent.get());
    return exists(parentSnap) && !!parentSnap.data().showAccountIDInPlacementFlow;
});
export const getNoticeNumberAndCustomIdFromNewspaper = (newspaper) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const currentNumber = ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.numberOfOrders) || 1;
    const numberOfDigits = ((_c = (_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.orderNumberGeneration) === null || _c === void 0 ? void 0 : _c.orderNumDigitCount) || 4;
    const numOfZeros = numberOfDigits - `${currentNumber}`.length > 0
        ? numberOfDigits - `${currentNumber}`.length
        : 0;
    const prefix = ((_e = (_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.orderNumberGeneration) === null || _e === void 0 ? void 0 : _e.orderNumPrefix) || '';
    const suffix = ((_g = (_f = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _f === void 0 ? void 0 : _f.orderNumberGeneration) === null || _g === void 0 ? void 0 : _g.orderNumSuffix) || '';
    const customId = `${prefix}${'0'.repeat(numOfZeros)}${currentNumber}${suffix}`;
    return {
        currentNumber,
        customId
    };
};
const requestDocument = (ctx, notice, options, DOMparser) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(notice)) {
        throw new Error(`Notice not found`);
    }
    const { format, type, url, optimizeColumns } = options;
    const adTemplate = (yield notice
        .data()
        .adTemplate.get());
    const newspaper = (yield notice
        .data()
        .newspaper.get());
    const generatedFooter = yield generateFormattedFooter(ctx, notice.data(), DOMparser);
    const { confirmedHtml, columns, dynamicHeaders, publicationDates } = notice.data();
    const { isFirstPHeading, downloadUrl, icmlSubstitutions } = adTemplate.data();
    const { linerMaxColumns } = newspaper.data();
    const preserveLongSequences = yield shouldPreserveLongSequencesForNotice(notice);
    const dynamicFooter = notice.data().dynamicFooter || generatedFooter;
    const xmlFooter = dynamicFooter && dynamicFooter.length > 0
        ? addFooterXML(dynamicFooter)
        : '';
    if (!notice.data().dynamicFooter) {
        yield notice.ref.update({ dynamicFooter: xmlFooter });
    }
    const request = {
        id: getAdTemplateCacheID(adTemplate),
        noticeId: notice.id,
        downloadUrl,
        html: htmlToIndesignHtml(confirmedHtml, DOMparser, {
            isFirstPHeading: !!isFirstPHeading,
            preserveLongSequences
        }, {
            dates: publicationDates
        }) + xmlFooter,
        format,
        quality: 'high',
        columns: columns ? columns + (optimizeColumns ? 1 : 0) : 1,
        dynamicHeader: (dynamicHeaders === null || dynamicHeaders === void 0 ? void 0 : dynamicHeaders[0]) || null,
        linerBorder: adTemplate.data().linerBorder || newspaper.data().linerBorder,
        icmlSubstitutions,
        linerMaxColumns,
        optimizeColumns: !!optimizeColumns,
        resizeTextFramesForProofPDF: type === 'DISPLAY_PARAMETERS',
        outputToBase64: type === 'DISPLAY_PARAMETERS'
    };
    const renderRequestOptions = type === 'RAW'
        ? {
            responseType: 'arraybuffer'
        }
        : {};
    const res = yield callIndesignServer(url, request, renderRequestOptions);
    const { data } = res;
    if (type === 'RAW') {
        return { data };
    }
    const boldWords = getBoldWords(notice.data().confirmedHtml, DOMparser);
    return Object.assign(Object.assign({}, data), { boldWords });
});
export const requestRawDocument = (ctx, notice, options, DOMparser) => __awaiter(void 0, void 0, void 0, function* () {
    return yield requestDocument(ctx, notice, options, DOMparser);
});
export const requestDisplayParameters = (ctx, notice, options, domParser) => __awaiter(void 0, void 0, void 0, function* () {
    return yield requestDocument(ctx, notice, options, domParser);
});
export const getDaysSinceFirstWeekdayOfQuarter = (now) => {
    let startOfQuarter = moment(now.format('YYYY-MM-DD')).startOf('quarter');
    // handle Saturday
    if (startOfQuarter.day() === 6) {
        startOfQuarter = startOfQuarter.add(2, 'days');
    }
    // handle Sunday
    if (startOfQuarter.day() === 0) {
        startOfQuarter = startOfQuarter.add(1, 'days');
    }
    return now.startOf('day').diff(startOfQuarter.startOf('day'), 'days');
};
/**
 * getDefaultColumnsForUserUserOrgWithNewspaper looks for the property defaultColumns on a customer or customer organization object
 *
 * @param newspaper The newspaper that we are filing with
 * @param filer The advertiser filing the notice
 * @param customTypeTemplate The template that exists on the custom notice type if any
 * @param customerOrganization The customer organization linking between the paper and the advertiser's org if any
 * @returns the default template to use
 */
export const getAdTemplate = (newspaper, filer, customTypeTemplate, customerOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    var _11, _12, _13, _14, _15;
    // Logic hierarchy for adTemplate:
    // 0. Customer Organization
    const customerOrganizationSnap = yield (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.get());
    if ((_11 = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _11 === void 0 ? void 0 : _11.adTemplate) {
        return (_12 = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _12 === void 0 ? void 0 : _12.adTemplate;
    }
    // 1. Custom Notice Type
    if (customTypeTemplate)
        return customTypeTemplate;
    // 2. User
    if ((_13 = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _13 === void 0 ? void 0 : _13.defaultTemplate)
        return (_14 = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _14 === void 0 ? void 0 : _14.defaultTemplate;
    // 3. Newspaper
    return (_15 = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _15 === void 0 ? void 0 : _15.adTemplate;
});
/**
 * This is a helper function meant to mimic the behavior of Promise.allSettled(),
 * which we do not have available until we upgrade to Node 12. It should be used
 * in place of Promise.all() when an early rejection of an item should not
 * cause the entire Promise.all() to reject.
 *
 * @param awaitArray is an array of items to wait for resolution on. If given a
 * promise, this function waits for it to settle.
 *
 * @returns a promise that resolves to an array after all promises in the given
 * array have settled (either resolved or rejected). The array contains objects
 * with a value if the corresponding item resolved or a reason if it rejected.
 */
export const awaitAllPromises = (awaitArray) => __awaiter(void 0, void 0, void 0, function* () {
    const returnArray = Array(awaitArray.length);
    yield Promise.all(awaitArray.map((promiseOrValue, index) => __awaiter(void 0, void 0, void 0, function* () {
        /**
         * For each item in the array create a wrapper Promise that we resolve
         * only after the item settles (including a rejected Promise). This
         * ensures that the Promise.all() does not settle early on a single
         * reject.
         */
        return new Promise(res => {
            /**
             * We could get a non-promise, so we only need to add handling if it
             * is in fact a Promise.
             */
            if (promiseOrValue instanceof Promise) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                promiseOrValue
                    .then(value => {
                    returnArray[index] = { value, status: 'fulfilled' };
                })
                    .catch(reason => {
                    returnArray[index] = { reason, status: 'rejected' };
                })
                    .finally(() => res());
                return;
            }
            /**
             * We reach this part of the function if the item is merely a value.
             * Push it to the return array as a resolved item and resolve the
             * wrapper Promise.
             */
            returnArray[index] = {
                status: 'fulfilled',
                value: promiseOrValue
            };
            res();
        });
    })));
    return returnArray;
});
export const getFulfilled = (results) => {
    const fulfilled = results.filter((res) => {
        if (res.status === 'fulfilled') {
            return true;
        }
        return false;
    });
    return fulfilled.map(({ value }) => value);
};
export const getRejected = (results) => {
    const rejected = results.filter((res) => {
        if (res.status === 'fulfilled') {
            return false;
        }
        return true;
    });
    return rejected.map(({ reason }) => reason);
};
export const getDisplayUnits = (rate, newspaper, displayParameters) => {
    var _a, _b, _c, _d, _e, _f;
    if (!displayParameters) {
        return ((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.rateType) === RateType.inch.value
            ? {
                unit: RateType.inch.plural,
                value: 0
            }
            : ((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.country) === Country.GBR.value
                ? {
                    unit: RateType.single_column_centimetre.plural,
                    value: 0
                }
                : {
                    unit: RateType.column_inch.plural,
                    value: 0
                };
    }
    return ((_c = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _c === void 0 ? void 0 : _c.rateType) === RateType.inch.value
        ? {
            unit: RateType.inch.plural,
            value: displayParameters.area
        }
        : ((_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.country) === Country.GBR.value
            ? {
                unit: RateType.single_column_centimetre.plural,
                value: getColumnCentimeters(displayParameters.height, displayParameters.columns, ((_e = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _e === void 0 ? void 0 : _e.roundOff) || null)
            }
            : {
                unit: RateType.column_inch.plural,
                value: getColumnInches(displayParameters.height, displayParameters.columns, ((_f = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _f === void 0 ? void 0 : _f.roundOff) || null)
            };
};
export const getNoticeIsInvoicedOutsideColumn = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _16, _17;
    try {
        // Note: throughout this function we use 'foo != null' to find values
        // that are neither 'null' or 'undefined' (relying on null == undefined).
        const newspaper = yield notice.data().newspaper.get();
        const invoice = yield ((_16 = notice.data().invoice) === null || _16 === void 0 ? void 0 : _16.get());
        // Logic hierarchy for invoiceOutsideColumn:
        // 0. Invoice if exists
        if (exists(invoice) && invoice.data().invoiceOutsideColumn != null) {
            return invoice.data().invoiceOutsideColumn;
        }
        // 1. Newspaper
        if (!((_17 = newspaper.data()) === null || _17 === void 0 ? void 0 : _17.allowInvoiceOutsideColumn)) {
            return false;
        }
        // 2. Customer
        const customer = yield getOrCreateCustomer(ctx, yield notice.data().filer.get(), yield notice.data().newspaper.get());
        const customerIsInvoicedOutsideColumn = yield getShouldInvoiceCustomerOrCustomerOrgOutsideColumn(customer);
        if (customerIsInvoicedOutsideColumn != null) {
            return customerIsInvoicedOutsideColumn;
        }
        // 3. Customer organization
        const customerOrg = yield getOrCreateCustomerOrganizationForNotice(ctx, notice);
        const customerOrgIsInvoicedOutsideColumn = yield getShouldInvoiceCustomerOrCustomerOrgOutsideColumn(customerOrg);
        if (customerOrgIsInvoicedOutsideColumn != null) {
            return customerOrgIsInvoicedOutsideColumn;
        }
        // 4. Notice type
        return yield getShouldInvoiceNoticeOutsideColumnByNoticeType(notice);
    }
    catch (err) {
        getErrorReporter().logAndCaptureError(err, `Error determining invoice outside Column status for notice`, { noticeId: notice.id });
        return false;
    }
});
export const getMinutesAndSecondsStringFromSeconds = (seconds) => {
    if (seconds === 1)
        return '1 second';
    if (seconds < 60)
        return `${seconds} seconds`;
    const wholeMinutes = Math.floor(seconds / 60);
    const remainder = seconds % 60;
    if (!remainder)
        return `${wholeMinutes} ${wholeMinutes === 1 ? 'minute' : 'minutes'}`;
    return `${wholeMinutes} ${wholeMinutes === 1 ? 'minute' : 'minutes'} and ${getMinutesAndSecondsStringFromSeconds(remainder)}`;
};
export const getToastMessageFromXMLExportSettings = (exportSettings) => {
    const baseMessage = `Sent request to sync notice.`;
    if (!exportSettings.debouncedQueueTimeInSeconds) {
        return baseMessage;
    }
    const debouncedTimeString = getMinutesAndSecondsStringFromSeconds(exportSettings.debouncedQueueTimeInSeconds);
    return `${baseMessage}. Notice will sync ${debouncedTimeString} after most recent notice event`;
};
export const normalizeSpaces = (stringToNormalize) => {
    if (!stringToNormalize)
        return '';
    return stringToNormalize.replace(/\s+/g, ' ').trim();
};
export const canRefundNoticeThruStripe = (notice, invoice) => {
    return (
    // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
    // If payment is initiated but not fully processed, how does refunding work?
    [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(invoice.data().status) &&
        !invoice.data().paid_outside_stripe &&
        !invoice.data().invoiceOutsideColumn &&
        !notice.data().transfer);
};
export const removeUndefinedFields = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] === undefined) {
            // eslint-disable-next-line no-param-reassign
            delete obj[key];
        }
    });
    return obj;
};
/**
 * Checks if a user is part of Column based on their email address
 * @param {ESnapshotExists<EUser>} user
 * @returns {boolean}
 */
export const isColumnUser = (user) => {
    const allowedDomains = ['column.us', 'enotice.io'];
    const { email = '' } = user.data();
    return allowedDomains.some(domain => {
        return email.endsWith(domain);
    });
};
/**
 * getDefaultColumnsForUserUserOrgWithNewspaper looks for the property defaultColumns on a customer or customer organization object
 *
 * @param customer The customer object linking between the paper and the advertiser
 * @param customerOrganization The customer organization linking between the paper and the advertiser's org if any
 * @returns default number of columns if it is attached to the customer or customer org object if it exists
 */
export const getDefaultColumnsForUserUserOrgWithNewspaper = (customer, customerOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    var _18, _19;
    // if we have a customer organization and a default column
    // value set on it, use that first
    const customerOrganizationSnap = yield (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.get());
    const customerOrgDefaultColumns = (_18 = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _18 === void 0 ? void 0 : _18.defaultColumns;
    if (customerOrgDefaultColumns) {
        return customerOrgDefaultColumns;
    }
    // if there is a default on the customer, use it
    const customerSnap = yield (customer === null || customer === void 0 ? void 0 : customer.get());
    const customerDefaultColumns = (_19 = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _19 === void 0 ? void 0 : _19.defaultColumns;
    if (customerDefaultColumns) {
        return customerDefaultColumns;
    }
    return null;
});
/**
 * a utility function that converts a numeric representation of a number to the
 * corresponding words in English. E.g., convert 1 to "one" or 19 to "nineteen".
 * It accepts the numeric as either a string or a number for ease of use, and
 * works for any number from 0 through 20.
 */
export const getWordsFromNumber = (numOrString) => {
    const num = typeof numOrString === 'number' ? numOrString : parseInt(numOrString, 10);
    if (isNaN(num))
        throw new Error(`The number provided as a string (${numOrString}) could not be parsed`);
    const numStrings = [
        'zero',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
        'twenty'
    ];
    const numString = numStrings[num];
    if (!numString)
        throw new Error(`Sorry, this function does not convert negative numbers or positive numbers greater than 20`);
    return numString;
};
export const getColumnOptionRange = (options = {}) => {
    const columnOptions = [];
    const { min = 1, max = 6, interval = 1 } = options;
    for (let i = min; i <= max; i += interval) {
        columnOptions.push({ val: i, label: getWordsFromNumber(i).toUpperCase() });
    }
    return columnOptions;
};
export const NON_FINALIZED_INVOICE_STATUSES = [
    InvoiceStatus.initiated.value,
    InvoiceStatus.unpaid.value,
    InvoiceStatus.payment_failed.value
];
export const isInvoiceFinalized = (invoice) => {
    return (exists(invoice) &&
        !NON_FINALIZED_INVOICE_STATUSES.includes(invoice.data().status));
};
/**
 * Returns the number of calendar days separating date1 from date2.
 *
 * If date1 is BEFORE date2 then this will return a negative number:
 * Example: calendarDaysApart('2022-05-24', '2022-05-26') === -2
 *
 * If date1 is AFTER date2 then this will return a positive number:
 * Example: calendarDaysApart('2022-05-26', '2022-05-24') === 2
 *
 * Note: localTimezone is the iana timezone string
 * See these docs for supported: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
 *
 * Also, see these docs https://momentjs.com/timezone/docs/#/using-timezones/
 * for how conversions to/from different timezones work
 */
export const calendarDaysApart = (date1, date2, localTimezone) => {
    const m1 = localTimezone
        ? moment.utc(date1).tz(localTimezone).startOf('day')
        : moment.utc(date1).startOf('day');
    const m2 = localTimezone
        ? moment.utc(date2).tz(localTimezone).startOf('day')
        : moment.utc(date2).startOf('day');
    return m1.diff(m2, 'days');
};
export const getDisplayName = (firstName, lastName) => `${firstName} ${lastName}`.trim();
export const getUserOrgStructureFromOrganization = (ctx, organizationSnap, role) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(organizationSnap)) {
        // The user is an individual, so all fields remain undefined
        return {};
    }
    /**
     * Check if the user is joining an organization with existing children
     *
     * If they are, also add those orgs to their allowedOrgs
     *
     * If none are found, this default returns an array containing only
     * the specified org to join
     */
    const { allowedOrganizations, roles } = yield getAllowedOrgsAndRolesFromParent(ctx, organizationSnap, role);
    return {
        allowedOrganizations,
        organization: organizationSnap.ref,
        activeOrganization: organizationSnap.ref,
        roles
    };
});
/**
 * a helper function that determines if a notice requires a partial transfer
 * upon affidavit upload instead of a full transfer. A partial transfer occurs
 * when a notice is canceled, a refund less than the full amount is given, and
 * an affidavit is uploaded after notice cancelation.
 */
export const requiresPartialTransfer = (invoice, notice) => {
    var _a, _b, _c;
    if (!invoice)
        return false;
    const refund_amount = ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.refund_amount) || 0;
    const inAppInvoicedAmt = ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.inAppInvoicedAmt) || 0;
    return (exists(invoice) &&
        ((_c = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _c === void 0 ? void 0 : _c.noticeStatus) === NoticeStatusType.cancelled.value &&
        refund_amount < inAppInvoicedAmt);
};
export const shouldPreventLatePrepay = (userNotice, newspaper) => {
    const isPastPublicationDeadline = getIsAfterPublishingDeadline(userNotice.data().publicationDates[0].toDate(), newspaper.data().deadlines, newspaper.data().iana_timezone, userNotice.data(), newspaper);
    return isPastPublicationDeadline && userNotice.data().requireUpfrontPayment;
};
export const hasAtLeastOneElement = (arr) => {
    return arr.length >= 1;
};
export const getChildOrganizations = (ctx, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const childrenQuery = yield ctx
        .organizationsRef()
        .where('parent', '==', organization)
        .get();
    return childrenQuery.docs;
});
export const getInvitesAssociatedWithEmail = (ctx, email, organizationId) => __awaiter(void 0, void 0, void 0, function* () {
    const normalizedEmail = email.toLowerCase();
    const baseQuery = ctx.invitesRef().where('email', '==', normalizedEmail);
    let inviteResults;
    if (organizationId) {
        inviteResults = yield baseQuery
            .where('organizationId', '==', organizationId)
            .get();
    }
    else {
        inviteResults = yield baseQuery.get();
    }
    return inviteResults.docs;
});
export const orgsHaveSameParent = (orgA, orgB) => {
    var _a, _b;
    return !!(exists(orgA) &&
        exists(orgB) &&
        orgA.data().parent &&
        orgB.data().parent &&
        ((_a = orgA.data().parent) === null || _a === void 0 ? void 0 : _a.id) === ((_b = orgB.data().parent) === null || _b === void 0 ? void 0 : _b.id));
};
export const getOpenInvitesToRelatedOrgs = (ctx, user, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const pendingInvites = [];
    if (!exists(organization)) {
        return pendingInvites;
    }
    const { email } = user.data();
    const invites = (yield ctx
        .invitesRef()
        .where('email', '==', email)
        .where('status', 'in', OPEN_INVITE_STATUSES)
        .where('organizationId', '!=', organization.id)
        .get());
    for (let i = 0; i < invites.docs.length; i++) {
        const inviteSnap = invites.docs[i];
        // eslint-disable-next-line no-await-in-loop
        const orgSnap = yield ctx
            .organizationsRef()
            .doc(inviteSnap.data().organizationId)
            .get();
        if (exists(orgSnap) && orgsHaveSameParent(orgSnap, organization)) {
            pendingInvites.push(inviteSnap);
        }
    }
    return pendingInvites;
});
/**
 * Converts the given duration (milliseconds) to number of days, hours and seconds.
 */
export const convertMilliseconds = (milliseconds) => {
    const total_seconds = Math.floor(milliseconds / 1000);
    const total_minutes = Math.floor(total_seconds / 60);
    const total_hours = Math.floor(total_minutes / 60);
    const days = Math.floor(total_hours / 24);
    const seconds = total_seconds % 60;
    const minutes = total_minutes % 60;
    const hours = total_hours % 24;
    return { d: days, h: hours, m: minutes, s: seconds };
};
export const getOpenInvitesAssociatedWithEmail = (ctx, email, organizationId) => __awaiter(void 0, void 0, void 0, function* () {
    const baseQuery = ctx
        .invitesRef()
        .where('email', '==', email)
        .where('status', 'in', OPEN_INVITE_STATUSES);
    let inviteResults;
    if (organizationId) {
        inviteResults = yield baseQuery
            .where('organizationId', '==', organizationId)
            .get();
    }
    else {
        inviteResults = yield baseQuery.get();
    }
    return inviteResults.docs;
});
export const getOpenInvitesForUser = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getOpenInvitesAssociatedWithEmail(ctx, user.data().email);
});
/**
 * Identify pattern of the e2e test user email.
 * These are random strings before and after @, like that ${random}@${random}.com; and random will be same at both
 * sides so this helps determine email from e2e.
 * @param {string} email
 * @returns {boolean}
 */
export const isTestUser = (email) => {
    const [prefix, suffix] = email.split('@');
    if (`${prefix}.com` === suffix) {
        return true;
    }
    if (`${suffix}` === 'example.org') {
        return true;
    }
    return false;
};
/**
 * Restrict launchDarkly to initialize for e2e users and test environments. But allow for production.
 * @param {string} ENV
 * @param {ESnapshotExists<EUser>} user
 * @returns {boolean}
 */
export const shouldInitializeLD = (ENV, user) => {
    if (ENV === envs.PROD)
        return true;
    if ((user && isTestUser(user === null || user === void 0 ? void 0 : user.data().email)) || ENV === envs.TEST)
        return false;
    return true;
};
// For the given notice and newspaper this returns if bulk payments should be enabled for this notice
export const shouldBulkInvoiceUser = (ctx, notice, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _20, _21, _22, _23, _24;
    // Bulk payments must be enabled on the newspaper
    const parentOrg = yield ((_20 = newspaper.data().parent) === null || _20 === void 0 ? void 0 : _20.get());
    if (newspaper.data().bulkPaymentEnabled === false) {
        return false;
    }
    if (!newspaper.data().bulkPaymentEnabled &&
        !((_21 = parentOrg === null || parentOrg === void 0 ? void 0 : parentOrg.data()) === null || _21 === void 0 ? void 0 : _21.bulkPaymentEnabled)) {
        return false;
    }
    const advertiserOrg = yield ((_22 = notice.data().filedBy) === null || _22 === void 0 ? void 0 : _22.get());
    const advertiser = yield notice.data().filer.get();
    const customer = yield getOrCreateCustomer(ctx, advertiser, newspaper);
    const customerOrganization = advertiserOrg
        ? yield getOrCreateCustomerOrganization(ctx, advertiserOrg, newspaper)
        : null;
    // Check if bulk payments is set on the customer organization level first
    if (customerOrganization &&
        customerOrganization.data().bulkPaymentEnabled !== undefined &&
        customerOrganization.data().bulkPaymentEnabled !== null) {
        return !!customerOrganization.data().bulkPaymentEnabled;
    }
    // Check if the bulk payments is set on the customer
    if (customer.data().bulkPaymentEnabled !== undefined &&
        customer.data().bulkPaymentEnabled !== null) {
        return !!customer.data().bulkPaymentEnabled;
    }
    // Fall back to checking bulk payments on the user and user's org level
    if ((_23 = advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.data()) === null || _23 === void 0 ? void 0 : _23.bulkPaymentEnabled) {
        return true;
    }
    if ((_24 = advertiser.data()) === null || _24 === void 0 ? void 0 : _24.bulkPaymentEnabled) {
        return true;
    }
    return false;
});
export function isDefined(val) {
    return val !== undefined;
}
const MAPBOX_TOKEN = 'pk.eyJ1IjoibGhlbnRzY2hrZXIiLCJhIjoiY2pxMGI0d2RtMGt3ajQyb2R3NHFvaHBvciJ9.7W5gy4Fva8g5p0lDyDk89g';
export const geocode = (query) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield fetch(`https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}`);
    const data = yield result.json();
    if (!data)
        return null;
    const features = data.features;
    if (!features || !features.length)
        return null;
    const { center } = features[0];
    return {
        lat: center[1],
        lng: center[0]
    };
});
export const isAfterLastPublicationDate = (notice) => {
    const lastPublicationDate = lastNoticePublicationDate(notice);
    return moment().isAfter(moment(lastPublicationDate));
};
export const encodeRFC3986URIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
};
