var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
import { STRIPE } from '../constants';
export const getOrganizationGateway = (organization) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(organization))
        return STRIPE;
    const { parent, gateway } = organization.data();
    if (gateway)
        return gateway;
    const parentOrg = yield (parent === null || parent === void 0 ? void 0 : parent.get());
    if (exists(parentOrg) && parentOrg.data().gateway) {
        return parentOrg.data().gateway || STRIPE;
    }
    return STRIPE;
});
