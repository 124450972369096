import React from 'react';
import SuccessModal from 'components/modals/SuccessModal';
import { WarningTriangle } from 'icons';
import { DoublePaperAndShadow } from 'gifs';
const RegisterOrganizationAlreadyExists = ({ name, onBackClicked }) => {
    return (React.createElement(SuccessModal, { disableOverflow: true, noExitOutsideModal: true, setOpen: onBackClicked, gif: React.createElement("div", { className: "w-24 h-24 rounded-full bg-red-300 p-2 items-center justify-center flex" },
            React.createElement("img", { src: DoublePaperAndShadow })), header: React.createElement("div", { className: "flex justify-center items-center" },
            React.createElement("p", null, "Organization already exists "),
            ' ',
            React.createElement(WarningTriangle, { className: "ml-1" })), body: React.createElement("p", null,
            "It looks like the organization ",
            React.createElement("b", null, name),
            " already exists. Please request to join this existing organization instead, or create a new organization with a different name.") }));
};
export default RegisterOrganizationAlreadyExists;
