var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { State } from 'lib/enums';
import { exists } from 'lib/types';
import { useSelector, useDispatch } from 'react-redux';
import { getCounties } from 'lib/utils/counties';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import PlacementActions, { placementSelector } from 'redux/placement';
import { getFirebaseContext } from 'utils/firebase';
import { authSelector } from 'redux/auth';
import { searchOrganizations } from './helpers';
import SelectDropdown from './SelectDropdown';
export function ConfirmNewspaperForm({ newspaper, restrictedStates, restrictedSingleState }) {
    const dispatch = useDispatch();
    const placement = useSelector(placementSelector);
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    // TODO: Investigate if these use effects can be refactored into a Redux action along with the restricted params calculations
    useEffect(() => {
        if (exists(newspaper) && newspaper.data().state !== placement.filingState) {
            dispatch(PlacementActions.setNewspaper(null));
        }
        // Clear the county field when changing state selection
        if (placement.filingCounty) {
            dispatch(PlacementActions.setFilingCounty(null));
        }
    }, [placement.filingState]);
    useEffect(() => {
        if (exists(newspaper) &&
            placement.filingCounty &&
            newspaper.data().county !== placement.filingCounty) {
            dispatch(PlacementActions.setNewspaper(null));
        }
    }, [placement.filingCounty]);
    useEffect(() => {
        if ((restrictedSingleState || enableNewPlacementFlow) &&
            exists(newspaper) &&
            newspaper.data().county !== placement.filingCounty) {
            dispatch(PlacementActions.setFilingCounty(newspaper.data().county));
        }
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    return (React.createElement("div", { className: "grid grid-cols-12 gap-2" },
        (!restrictedSingleState || enableNewPlacementFlow) && (React.createElement("div", { className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-4' },
            React.createElement(SelectStateDropdown, { restrictedStates: restrictedStates, restrictedSingleState: restrictedSingleState }))),
        (restrictedSingleState || enableNewPlacementFlow) && (React.createElement("div", { className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-4' },
            React.createElement(SelectCountyDropdown, null))),
        React.createElement("div", { className: enableNewPlacementFlow ? 'col-span-12' : 'col-span-8' },
            React.createElement(SelectNewspaperDropdown, { newspaper: newspaper }))));
}
function SelectNewspaperDropdown({ newspaper }) {
    const dispatch = useDispatch();
    const placement = useSelector(placementSelector);
    const auth = useSelector(authSelector);
    function handleChange(newValue) {
        if (!newValue)
            return;
        const { id } = newValue;
        const newNewspaperRef = getFirebaseContext().organizationsRef().doc(id);
        dispatch(PlacementActions.setNewspaper(newNewspaperRef));
    }
    function formatOptions(paper) {
        return {
            id: paper.id,
            label: paper.name
        };
    }
    function loadOptions(searchTerm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { isPublisher, user, availableOrganizations } = auth;
            const { filingCounty, filingState } = placement;
            const results = yield searchOrganizations(isPublisher, availableOrganizations, user, filingState, filingCounty, searchTerm);
            return results.papers.map(formatOptions);
        });
    }
    const options = placement.newspaperList.newspapers.map(formatOptions);
    const currentValue = exists(newspaper)
        ? {
            id: newspaper.id,
            label: newspaper.data().name
        }
        : [];
    return (React.createElement(SelectDropdown, { id: "selectNewspaper", placeholder: "Newspaper", options: options, isLoading: !placement.newspaperList.loaded, loadOptions: loadOptions, selected: currentValue, onChange: handleChange, value: currentValue, placeholderText: '#6B7280' }));
}
function SelectStateDropdown({ restrictedStates, restrictedSingleState }) {
    var _a;
    const dispatch = useDispatch();
    const placement = useSelector(placementSelector);
    function handleChange(selected) {
        if (selected.id !== placement.filingState) {
            dispatch(PlacementActions.setFilingState(selected ? selected.id : null));
            dispatch(PlacementActions.setFilingCounty(null));
        }
    }
    const options = (placement.availableStates || [])
        .filter(state => !restrictedStates || restrictedStates.includes(state))
        .filter(state => !restrictedSingleState || state === restrictedSingleState)
        .map(item => {
        var _a;
        return ({
            id: item,
            label: (_a = State.by_value(item)) === null || _a === void 0 ? void 0 : _a.label
        });
    });
    const currentValue = placement.filingState
        ? {
            id: placement.filingState,
            label: (_a = State.by_value(placement.filingState)) === null || _a === void 0 ? void 0 : _a.label
        }
        : '';
    return (React.createElement(SelectDropdown, { id: "selectState", placeholder: "State", onChange: handleChange, selected: currentValue, value: currentValue, options: options, placeholderText: '#6B7280' }));
}
const ALL_COUNTIES_ID = -1;
function SelectCountyDropdown() {
    var _a;
    const dispatch = useDispatch();
    const placement = useSelector(placementSelector);
    function handleChange(selected) {
        dispatch(PlacementActions.setFilingCounty(selected && selected.id !== ALL_COUNTIES_ID ? selected.label : null));
    }
    const counties = getCounties((_a = State.by_value(placement.filingState)) === null || _a === void 0 ? void 0 : _a.label).map((county, index) => ({ id: index, label: county }));
    const options = [{ id: ALL_COUNTIES_ID, label: 'All Counties' }, ...counties];
    const currentValue = placement.filingCounty
        ? { label: placement.filingCounty }
        : '';
    return (React.createElement(SelectDropdown, { id: "selectCounty", placeholder: "County", onChange: handleChange, selected: currentValue, value: currentValue, options: options, placeholderText: '#6B7280', isDisabled: !placement.filingState, disableAlphabetization: true }));
}
