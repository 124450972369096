var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import PayInvoiceModal from 'components/modals/FreeFormCModal';
import CButton from 'components/CButton';
import CTooltip from 'components/Tooltip';
import { DocumentIcon, CopyIcon, UploadIcon, DeleteIcon, DownloadIcon, PencilIcon } from 'icons/index';
import Firebase, { Collections } from 'EnoticeFirebase';
import { NoticeStatusType } from 'lib/enums';
import { exists } from 'lib/types';
import api from 'api';
import { cdnIfy, shouldReleaseAffidavitForNotice, publicationDateStarted, removeUndefinedFields, lastNoticePublicationDate } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { isAffidavitDisabled } from 'lib/affidavits';
import { userIsSuper } from 'utils/permissions';
import UploadConfirmation from './UploadConfirmation';
import ReplaceAffidavitModal from './ReplaceAffidavitModal';
const MAX_AFFIDAVIT_NAME = 60;
const truncateFilename = (fname) => {
    if (fname.length < MAX_AFFIDAVIT_NAME) {
        return fname;
    }
    const lastPeriod = fname.lastIndexOf('.');
    return `${fname
        .slice(0, lastPeriod)
        .slice(0, MAX_AFFIDAVIT_NAME - 5)}....${fname.slice(lastPeriod + 1)}`;
};
const AffidavitActions = ({ isPublisher, notice, invoice, newspaper, affidavitTerm, user }) => {
    const [affidavitURL, setAffidavitURL] = useState(notice.data().affidavitURL);
    const [normalAffidavitURL, setNormalAffidavitURL] = useState('');
    const [showPayInvoiceModal, setShowPayInvoiceModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [isReupload, setIsReupload] = useState(false);
    const [showReplaceAffidavitModal, setShowReplaceAffidavitModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [noticeObj, setNoticeObj] = useState({});
    const { generatedAffidavitURL } = notice.data();
    const { iana_timezone } = newspaper.data();
    const finalPublicationDate = lastNoticePublicationDate(notice);
    const affidavitDisabled = isAffidavitDisabled(notice, newspaper);
    const createDisabled = !invoice || affidavitDisabled;
    const setUploadsDisabled = () => {
        if (userIsSuper(user) &&
            publicationDateStarted(finalPublicationDate, iana_timezone)) {
            return false;
        }
        if (notice.data().affidavit ||
            !publicationDateStarted(finalPublicationDate, iana_timezone) ||
            !invoice ||
            !isPublisher ||
            affidavitDisabled) {
            return true;
        }
    };
    const uploadsDisabled = setUploadsDisabled();
    const setPersistentAffidavitURL = (updated) => __awaiter(void 0, void 0, void 0, function* () {
        const url = yield Firebase.storage()
            .ref(updated.data().affidavit)
            .getDownloadURL();
        yield notice.ref.update({
            affidavitURL: url
        });
        setAffidavitURL(url);
    });
    const getNormalAffidavitURL = (notice) => __awaiter(void 0, void 0, void 0, function* () {
        const url = yield Firebase.storage()
            .ref(notice.data().normalAffidavit)
            .getDownloadURL();
        setNormalAffidavitURL(url);
    });
    const handleClose = () => __awaiter(void 0, void 0, void 0, function* () {
        yield notice.ref.update(noticeObj);
        const updated = yield notice.ref.get();
        if (exists(updated)) {
            yield setPersistentAffidavitURL(updated);
        }
        setShowUploadFileModal(false);
    });
    const uploadFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const snapshot = yield Firebase.storage()
                .ref()
                .child(`${Collections.affidavits}/${notice.id}/${file.name}`)
                .put(file);
            /**
             * We should never change the status of a cancelled notice upon affidavit upload.
             * If the notice still requires a transfer (e.g., in the event of a partial refund),
             * the affidavit upload should still trigger a transfer (see logic in onNoticeUpdate and createTransferIfNotExists)
             */
            const shouldNotUpdateNoticeStatus = notice.data().noticeStatus === NoticeStatusType.cancelled.value;
            setNoticeObj(removeUndefinedFields({
                affidavit: snapshot.ref.fullPath,
                noticeStatus: shouldNotUpdateNoticeStatus
                    ? undefined
                    : NoticeStatusType.affidavit_submitted.value,
                affidavitUploadedBy: user.ref
            }));
            setFileName(snapshot.ref.fullPath);
            setAffidavitURL(yield snapshot.ref.getDownloadURL());
            setLoading(false);
            setShowUploadFileModal(true);
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to upload affidavit file', {
                noticeId: notice.id
            });
        }
    });
    const setPersistentGeneratedAffidavitURL = (notice) => __awaiter(void 0, void 0, void 0, function* () {
        if (notice.data().generatedAffidavitURL ||
            !notice.data().generatedAffidavitStoragePath) {
            return;
        }
        const url = yield Firebase.storage()
            .ref(notice.data().generatedAffidavitStoragePath)
            .getDownloadURL();
        yield notice.ref.update({
            generatedAffidavitURL: url
        });
    });
    useEffect(() => {
        if (!exists(notice))
            return;
        if (notice.data().invoice && !notice.data().generatedAffidavitStoragePath) {
            void api.post(`documents/${notice.id}/regenerate`, {
                docType: 'AFFIDAVIT'
            });
        }
        if (notice.data().affidavit && !notice.data().affidavitURL) {
            void setPersistentAffidavitURL(notice);
        }
        if (notice.data().normalAffidavit) {
            void getNormalAffidavitURL(notice);
        }
        if (notice.data().generatedAffidavitStoragePath &&
            !notice.data().generatedAffidavitURL) {
            void setPersistentGeneratedAffidavitURL(notice);
        }
    }, [notice]);
    const isInvoice = !!invoice;
    const isESignDisabled = !generatedAffidavitURL || !isPublisher;
    const EnabledButtonClasses = 'bg-yellow-300 text-yellow-900 border-yellow-500';
    const DisabledButtonClasses = `${EnabledButtonClasses} opacity-50 cursor-not-allowed pointer-events-none`;
    return (React.createElement("section", { className: "p-6", id: "affidavit-actions-container" },
        React.createElement("div", { className: "flex flex-col sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start" },
            isPublisher && (React.createElement("section", { className: "xl:col-span-4 lg:col-span-5 md:col-span-6 sm:col-span-12 grid grid-rows-2" },
                React.createElement("div", { className: "flex flex-row items-stretch" },
                    React.createElement(CTooltip, { position: "right", classes: "flex-1", helpText: affidavitDisabled
                            ? 'Affidavit not required'
                            : !isInvoice
                                ? `You can't create an affidavit before invoice creation. You will receive an email reminder.`
                                : '' }, notice.data().invoice &&
                        !notice.data().generatedAffidavitURL ? (React.createElement(CButton, { id: "generate-affidavit", className: `border-b p-2 md:p-3 rounded-t-lg w-full font-bold ${EnabledButtonClasses}`, startClasses: "flex items-center col-span-2", startIcon: React.createElement(PencilIcon, { className: "pl-2 w-full stroke-current" }), disabled: !isPublisher || !generatedAffidavitURL, endClasses: "flex items-center col-span-2 pl-2", endIcon: React.createElement("div", { className: "loader-yellow ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }) },
                        "Generating ",
                        affidavitTerm)) : (React.createElement(CButton, { className: `${createDisabled
                            ? DisabledButtonClasses
                            : EnabledButtonClasses} border-b p-2 md:p-3 rounded-t-lg w-full font-bold h-full`, startClasses: "flex items-center col-span-2", id: "create-affidavit", startIcon: React.createElement(PencilIcon, { className: "pl-2 w-full stroke-current" }), onClick: () => {
                            if (isESignDisabled ||
                                !notice.data().generatedAffidavitStoragePath) {
                                return;
                            }
                            window.open(cdnIfy(notice.data().generatedAffidavitStoragePath, {
                                useImgix: true
                            }));
                        }, disabled: createDisabled }, "Create Affidavit")))),
                React.createElement("div", { className: "flex flex-row items-stretch" },
                    React.createElement(CTooltip, { classes: "flex-1", helpText: affidavitDisabled
                            ? 'Affidavit not required'
                            : !uploadsDisabled
                                ? 'Upload your completed affidavit.'
                                : uploadsDisabled && notice.data().affidavit
                                    ? 'To re-upload an affidavit, click on the trash icon and upload a new affidavit.'
                                    : `You can't upload an affidavit before publication and invoice creation. You will receive an affidavit upload email reminder.` }, loading ? (React.createElement(CButton, { disabled: true, className: `bg-yellow-200 border-t text-center font-bold border-yellow-400 p-2 md:p-3 rounded-b-lg text-yellow-500 w-full h-full`, startIcon: React.createElement("div", { className: "loader ease-linear rounded-full border-4 text-center border-t-4 border-yellow-500 h-6 w-6" }) }, "Loading")) : (React.createElement(CButton, { className: `border-t p-2 md:p-3 rounded-b-lg w-full font-bold ${uploadsDisabled
                            ? DisabledButtonClasses
                            : EnabledButtonClasses}`, disabled: uploadsDisabled, startIcon: React.createElement(UploadIcon, { className: "pl-2 pr-1 w-full stroke-current sm:pl-1 sm:p-0" }), startClasses: "flex items-center col-span-2", id: "upload-affidavit", onClick: () => {
                            var _a;
                            if (uploadsDisabled)
                                return;
                            (_a = document.getElementById('contained-button-file')) === null || _a === void 0 ? void 0 : _a.click();
                        } },
                        "Upload Affidavit",
                        React.createElement("input", { type: "file", accept: "application/pdf", className: `hidden`, id: "contained-button-file", onChange: ({ target: { validity, files } }) => {
                                validity.valid && files && uploadFile(files[0]);
                            }, disabled: uploadsDisabled }))))))),
            React.createElement("section", { className: `mt-4 md:mt-0 ${isPublisher
                    ? 'xl:col-span-8 lg:col-span-7 md:col-span-6'
                    : 'md:col-span-10'} sm:col-span-12` },
                notice.data().affidavit && isPublisher && (React.createElement("div", { className: 'bg-gray-200 p-3 md:p-4 rounded w-full text-left p-6 md:py-6 flex flex-row items-center gap-4' },
                    React.createElement("div", null,
                        React.createElement(DocumentIcon, null)),
                    React.createElement("div", { className: "flex-grow ml-1 truncate" }, notice.data().affidavit
                        ? truncateFilename(notice.data().affidavit.split('/')[2] ||
                            notice.data().affidavit.split('/')[1])
                        : ''),
                    React.createElement("button", { title: "Download", className: "cursor-pointer hover:text-blue-500", onClick: () => {
                            window.open(affidavitURL);
                        } },
                        React.createElement(DownloadIcon, null)),
                    React.createElement("button", { title: "Delete and re-upload", className: "cursor-pointer hover:text-red-500", onClick: () => {
                            setIsReupload(true);
                            setShowReplaceAffidavitModal(true);
                        } },
                        React.createElement(DeleteIcon, null)))),
                notice.data().affidavit && !isPublisher && (React.createElement(CButton, { className: 'bg-gray-200 p-3 md:p-4 rounded w-full text-left', startIcon: React.createElement(DocumentIcon, { className: "pl-2 w-full" }), startClasses: 'col-span-1', endClasses: 'col-span-1 sm:col-span-2', middleClasses: 'ml-4 col-span-10 sm:col-span-8 break-all', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const w = window.open();
                        try {
                            const ctx = getFirebaseContext();
                            const allowed = yield shouldReleaseAffidavitForNotice(ctx, notice);
                            if (!w)
                                return;
                            if (allowed) {
                                w.location = normalAffidavitURL || affidavitURL;
                            }
                            else {
                                w.close();
                                setShowPayInvoiceModal(true);
                            }
                        }
                        catch (err) {
                            logAndCaptureException(err, 'Failed to open affidavit file', {
                                noticeId: notice.id
                            });
                            w === null || w === void 0 ? void 0 : w.close();
                        }
                    }) }, notice.data().affidavit
                    ? truncateFilename(notice.data().affidavit.split('/')[2] ||
                        notice.data().affidavit.split('/')[1])
                    : '')),
                !notice.data().affidavit && (React.createElement(CTooltip, { position: "bottom", helpText: affidavitDisabled
                        ? 'Affidavit not required'
                        : !isPublisher
                            ? 'The publisher has not uploaded the affidavit yet. You will be notified by email when the affidavit is available.'
                            : '' },
                    React.createElement("h3", { className: `bg-gray-200 p-3 md:p-4 rounded w-full text-left text-gray-600 ${isPublisher ? 'py-6 md:py-8' : ''}` },
                        React.createElement(CopyIcon, { className: "mx-4 inline-block stroke-current" }),
                        affidavitDisabled
                            ? 'Affidavit not required'
                            : isPublisher
                                ? 'Waiting for signature...'
                                : 'Waiting for publisher...'))))),
        React.createElement(React.Fragment, null,
            showUploadFileModal && (React.createElement(UploadConfirmation, { fileName: fileName, saveNCloseModal: handleClose, setOpen: setShowUploadFileModal, open: showUploadFileModal, downloadURL: affidavitURL, isReupload: isReupload })),
            showPayInvoiceModal && invoice && (React.createElement(PayInvoiceModal, { header: `Download ${affidavitTerm}`, body: `${newspaper
                    ? `${newspaper.data().name} has uploaded your ${affidavitTerm.toLowerCase()}, but you must pay your outstanding invoice in order to download and view it.`
                    : ''}`, setOpen: setShowPayInvoiceModal },
                React.createElement("div", { className: "flex justify-center md:justify-start" },
                    React.createElement("button", { className: `bg-gray-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-auto`, onClick: () => {
                            if (invoice.data().isWithinBulkInvoice)
                                window.open(`${window.location.origin}/payments`);
                            else
                                window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
                        } }, "Pay Invoice")))),
            showReplaceAffidavitModal && (React.createElement(ReplaceAffidavitModal, { saveNCloseModal: handleClose, setOpen: setShowReplaceAffidavitModal, open: showReplaceAffidavitModal, uploadFile: uploadFile })))));
};
export default AffidavitActions;
