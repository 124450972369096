var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { NoticeType } from 'lib/enums';
import { cdnIfy } from 'lib/helpers';
import { getNoticePDFUrlFromNotice } from 'lib/notice/display';
import { ColumnButton } from 'components/ColumnButton';
import BoundedProgress from '../../components/BoundedProgress';
export function NoticePreviewDownloadButtons({ notice, noticeFiles, isPublisher }) {
    const { jpgStoragePath, pdfStoragePath, idmlStoragePath, rtfStoragePath, idmlURL, rtfURL, pdfURL, noticeType } = notice.data();
    // If there are no paths set, the notice is still generating files
    const isGeneratingFiles = [
        jpgStoragePath,
        pdfStoragePath,
        idmlStoragePath,
        rtfStoragePath
    ].every(path => !path);
    const isDisplay = noticeType === NoticeType.display_ad.value;
    if (isGeneratingFiles && !isDisplay && !pdfURL) {
        return React.createElement(GeneratingNoticeFilesLoader, null);
    }
    const getLinkToFileFromOldSchema = (oldSchema) => {
        return Object.values(oldSchema || []).find(value => value.indexOf('http') !== -1);
    };
    return (React.createElement("div", { className: "grid grid-cols-2 gap-2" },
        React.createElement(ColumnButton, { size: "xl", secondary: true, disabled: !pdfStoragePath, onClick: () => __awaiter(this, void 0, void 0, function* () {
                const urlToOpen = yield getNoticePDFUrlFromNotice(notice);
                if (urlToOpen) {
                    openUrlNewWindow(urlToOpen);
                }
            }), buttonText: "PDF" }),
        isPublisher && (React.createElement(ColumnButton, { size: "xl", secondary: true, disabled: !idmlStoragePath, onClick: () => {
                if (idmlURL || idmlStoragePath) {
                    openUrlNewWindow(idmlURL || cdnIfy(idmlStoragePath));
                }
            }, buttonText: "IDML" })),
        !isDisplay && (React.createElement(ColumnButton, { size: "xl", secondary: true, disabled: !rtfStoragePath, onClick: () => {
                if (rtfURL || rtfStoragePath) {
                    openUrlNewWindow(rtfURL || cdnIfy(rtfStoragePath));
                }
            }, buttonText: "RTF" })),
        noticeFiles &&
            noticeFiles
                .filter(noticeFile => {
                const linkFromOldSchemaMetadata = getLinkToFileFromOldSchema(noticeFile.data().oldSchemaMetadata);
                return (noticeFile.data().linkToUploadedFile || linkFromOldSchemaMetadata);
            })
                .map((noticeFile, i) => (React.createElement(ColumnButton, { size: "xl", secondary: true, key: `${i}-original-file`, onClick: () => {
                    const linkFromOldSchemaMetadata = getLinkToFileFromOldSchema(noticeFile.data().oldSchemaMetadata);
                    return openUrlNewWindow(noticeFile.data().linkToUploadedFile ||
                        linkFromOldSchemaMetadata);
                }, buttonText: "Original File" })))));
}
function GeneratingNoticeFilesLoader() {
    return (React.createElement(BoundedProgress, { timeout: 30000, InProgress: React.createElement("h4", { className: "text-2xl text-white mb-4" },
            "Generating Files",
            React.createElement("div", { className: "inline-block align-middle mx-4 loader ease-linear rounded-full border-4 text-center border-t-4 border-white-500 h-6 w-6" })), Fallback: React.createElement("p", { className: "text-white mb-2" }, "There was an issue generating files. Wait 5 minutes, and visit this page again to retry file generation. If the problem persists, contact help@column.us.") }));
}
const openUrlNewWindow = (url) => window.open(url, '_blank');
