import React from 'react';
import { CustomerDrawerRow } from './CustomerDrawerRow';
import { CustomerDrawerToggleRow } from './CustomerDrawerToggleRow';
export const CustomerDrawerSettingsTab = ({ linerRateDescription, displayRateDescription, upfrontPayment, affidavitsBeforePayment, activeOrganization, allowBulkInvoicing, invoicedOutsideColumn, hideDigitalAffidavits, allowAffidavitEmail, isCustomerOrganization }) => {
    var _a, _b;
    return (React.createElement("div", { className: "p-4 text-sm leading-6 font-medium" },
        !isCustomerOrganization && (React.createElement("div", null,
            React.createElement(CustomerDrawerRow, { label: "Liner Rate", value: linerRateDescription }),
            React.createElement(CustomerDrawerRow, { label: "Display Rate", value: displayRateDescription }))),
        React.createElement(CustomerDrawerRow, { label: "Upfront payment", value: upfrontPayment }),
        !isCustomerOrganization && (React.createElement(CustomerDrawerToggleRow, { label: "Allow affidavits before payment?", value: affidavitsBeforePayment })),
        ((_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.bulkPaymentEnabled) && (React.createElement(CustomerDrawerToggleRow, { label: "Allow bulk invoicing?", value: allowBulkInvoicing })),
        !isCustomerOrganization &&
            ((_b = activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.allowInvoiceOutsideColumn) && (React.createElement(CustomerDrawerToggleRow, { label: "Allow invoice outside Column?", value: invoicedOutsideColumn })),
        !isCustomerOrganization && hideDigitalAffidavits && (React.createElement(CustomerDrawerToggleRow, { label: "Allow emailed affidavits?", value: allowAffidavitEmail }))));
};
