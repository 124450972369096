var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch } from 'react-redux';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { NOTICE_NEWSPAPER_REQUESTED } from 'lib/types/events';
import PlacementActions from 'redux/placement';
import { getFirebaseContext } from 'utils/firebase';
export function DisabledNewspaperModal({ newspaper, setOpen }) {
    var _a, _b;
    const dispatch = useDispatch();
    const newspaperName = newspaper && ((_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.name)
        ? (_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.name
        : 'This publication';
    return (React.createElement(FreeformCModal, { setOpen: setOpen, afterExit: () => {
            dispatch(PlacementActions.setNewspaper(null));
        }, noExitOutsideModal: true },
        React.createElement("div", { className: "font-medium text-sm" },
            React.createElement("div", { className: "mb-8" },
                newspaperName,
                " does not currently enable public notice placement through Column.",
                ' ',
                newspaper && newspaper.data().nonColumnPlacementSite && (React.createElement("a", { className: "underline text-primary-600", href: "#", onClick: (e) => __awaiter(this, void 0, void 0, function* () {
                        e.preventDefault();
                        yield openPlacementSite(newspaper);
                    }) }, "Click here to be directed to their website where you can place your notice."))),
            React.createElement("div", null,
                React.createElement("a", { href: "https://info.column.us/what-is-column", className: "underline text-primary-600", target: "_blank", rel: "noopener noreferrer" }, "What is Column?")))));
}
function openPlacementSite(newspaper) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const ctx = getFirebaseContext();
        const targetUrl = (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.nonColumnPlacementSite;
        if (!targetUrl) {
            console.error(`Missing target URL for paper: ${newspaper.id}`);
        }
        const event = yield ctx.eventsRef().add({
            type: NOTICE_NEWSPAPER_REQUESTED,
            data: {
                newspaper: newspaper === null || newspaper === void 0 ? void 0 : newspaper.ref
            }
        });
        window.open(targetUrl, '_blank');
        console.log('Event id:', event.id);
    });
}
