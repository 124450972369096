var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { downloadFileContentsInBrowser, logAndCaptureException } from 'utils';
import ReactPaginate from 'react-paginate';
import LoadingState from 'components/LoadingState';
import { getCurrencySymbol, unixTimeStampToNewspaperTimezoneDate } from 'lib/helpers';
import api from 'api';
import { TransactionType } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import EToast from 'components/EToast';
import { InvoiceIcon, ArrowRight, ArrowLeft, ExternalLinkIcon, DownloadIcon } from 'icons/index';
import { addExtraColumnHeaders, makeCsvContent, PAYOUT_CSV_HEADERS } from 'lib/utils/csv';
import moment from 'moment';
import { ENOTICE_ACCOUNT_ID, DUMMY_ACCOUNT_ID, STRIPE_VARS } from '../../../constants';
import SettingsHeader from '../SettingsHeader';
import Plaid from '../Plaid';
import DwollaCustomerModal from './DwollaCustomerModal';
import DwollaSettings from './DwollaSettings';
import DwollaPasswordResetModal from './DwollaPasswordResetModal';
import AccountVerificationOptionsModal from '../AccountVerificationOptionsModal';
import PayoutGroupRows from './PayoutGroupRows';
const PAGE_SIZE = 10;
const StripeSettings = ({ activeOrganization }) => {
    const [payoutsLoading, setPayoutsLoading] = useState(false);
    const [payoutGroups, setPayoutGroups] = useState([]);
    const [stripeLoginLink, setLoginLink] = useState();
    const [stripeInvoicesLink, setStripeInvoicesLink] = useState('');
    const [payouts, setPayouts] = useState([]);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [pageVisited, setPageVisited] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const startIndex = pageNumber * PAGE_SIZE;
    const endIndex = (payoutGroups === null || payoutGroups === void 0 ? void 0 : payoutGroups.length)
        ? Math.min(startIndex + PAGE_SIZE, payoutGroups === null || payoutGroups === void 0 ? void 0 : payoutGroups.length) - 1
        : 0;
    useEffect(() => {
        const loadLink = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { url } = yield api.post('payments/stripe-login-link', {
                    accountId: activeOrganization.data().accountId
                });
                setLoginLink(url);
            }
            catch (err) {
                logAndCaptureException(err, 'Unable to create login link', {
                    accountId: activeOrganization.data().accountId
                });
            }
        });
        setLoginLink(null);
        void loadLink();
    }, [activeOrganization && activeOrganization.id]);
    useEffect(() => {
        var _a, _b, _c, _d;
        const loadStripeInvoicesLink = (customerId) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { url } = yield api.post('subscription/stripe-billing-session', {
                    customerId,
                    returnUrl: window.location.href,
                    configuration: 'organization_invoices'
                });
                setStripeInvoicesLink(url);
            }
            catch (e) { }
        });
        setStripeInvoicesLink('');
        // Note: this will not be visible if the setting is inherited from the
        // parent org.
        const enabled = (_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.payColumnInvoices) === null || _b === void 0 ? void 0 : _b.enabled;
        const stripeCustomerId = (_d = (_c = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.payColumnInvoices) === null || _d === void 0 ? void 0 : _d.stripeCustomerId;
        if (enabled && stripeCustomerId) {
            void loadStripeInvoicesLink(stripeCustomerId);
        }
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    const downloadCSV = (payoutId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const relevantPayout = payouts.find(payout => payout.id === payoutId);
        const { csvRows, error } = yield api.post('payments/generate-payout', {
            payout: relevantPayout
        });
        const parentId = (_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.id;
        if (error) {
            logAndCaptureException(error, 'An error occurred when downloading payout', {
                accountId: activeOrganization.data().accountId,
                relevantPayout
            });
            return;
        }
        yield Promise.all(csvRows).then((results) => {
            var _a;
            addExtraColumnHeaders(PAYOUT_CSV_HEADERS, activeOrganization.id, parentId || null);
            const csvContent = makeCsvContent(PAYOUT_CSV_HEADERS, results);
            const arrivalDate = unixTimeStampToNewspaperTimezoneDate(relevantPayout.arrival_date, (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.iana_timezone);
            downloadFileContentsInBrowser(`Payout_${moment(arrivalDate).format('YYYY-MM-DD')}.csv`, csvContent, 'text/csv');
        });
    });
    const loadRowsForPayoutTransfer = (payout) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        const currencySymbol = getCurrencySymbol(payout.currency);
        const parentRow = {
            arrival_date: unixTimeStampToNewspaperTimezoneDate(payout.arrival_date, (_c = activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.iana_timezone),
            created: unixTimeStampToNewspaperTimezoneDate(payout.created, (_d = activeOrganization.data()) === null || _d === void 0 ? void 0 : _d.iana_timezone),
            amount: `${currencySymbol}${(payout.amount / 100).toFixed(2)}`,
            id: payout.id,
            type: 'PAYOUT'
        };
        const childRow = [];
        if ((_e = payout === null || payout === void 0 ? void 0 : payout.payments) === null || _e === void 0 ? void 0 : _e.length) {
            for (const payment of payout === null || payout === void 0 ? void 0 : payout.payments) {
                if (!((_f = payment === null || payment === void 0 ? void 0 : payment.source) === null || _f === void 0 ? void 0 : _f.source_transfer)) {
                    continue;
                }
                const type = payment.source.source_transfer.metadata.type === 'revenueShare'
                    ? TransactionType.dividend.label
                    : TransactionType.payment.label;
                childRow.push({
                    id: payment.id,
                    type,
                    amount: `${currencySymbol}${((payment.source.amount || 0) / 100).toFixed(2)}`,
                    parentId: payout.id,
                    noticeId: type !== TransactionType.dividend.label
                        ? payment.source.source_transfer.metadata.userNoticeId || ''
                        : '',
                    receipt: payment.source.receipt_url
                });
            }
        }
        if ((_g = payout === null || payout === void 0 ? void 0 : payout.charges) === null || _g === void 0 ? void 0 : _g.length) {
            for (const charge of payout === null || payout === void 0 ? void 0 : payout.charges) {
                if (!charge.source) {
                    continue;
                }
                childRow.push({
                    id: charge.id,
                    type: TransactionType.processing_fee_deduction.label,
                    amount: `-${currencySymbol}${((charge.source.amount || 0) / 100).toFixed(2)}`,
                    parentId: payout.id,
                    noticeId: (_h = charge.source.metadata.userNoticeId) !== null && _h !== void 0 ? _h : '',
                    receipt: charge.source.receipt_url
                });
            }
        }
        if ((_j = payout === null || payout === void 0 ? void 0 : payout.refunds) === null || _j === void 0 ? void 0 : _j.length) {
            for (const refund of payout === null || payout === void 0 ? void 0 : payout.refunds) {
                if (!((_k = refund.source) === null || _k === void 0 ? void 0 : _k.transfer_reversal)) {
                    continue;
                }
                const transferReversalType = (_l = refund.source.transfer_reversal) === null || _l === void 0 ? void 0 : _l.metadata.type;
                const sourceType = refund.source.metadata.type;
                const type = ((_m = TransactionType.by_key(transferReversalType)) === null || _m === void 0 ? void 0 : _m.label) ||
                    ((_o = TransactionType.by_key(sourceType)) === null || _o === void 0 ? void 0 : _o.label) ||
                    '';
                childRow.push({
                    id: refund.id,
                    type,
                    amount: `-${currencySymbol}${((refund.source.amount || 0) / 100).toFixed(2)}`,
                    parentId: payout.id,
                    noticeId: ((_q = (_p = refund.source.transfer_reversal) === null || _p === void 0 ? void 0 : _p.metadata) === null || _q === void 0 ? void 0 : _q.userNoticeId) || '',
                    receipt: refund.source.receipt_url
                });
            }
        }
        if ((_r = payout === null || payout === void 0 ? void 0 : payout.feeRefunds) === null || _r === void 0 ? void 0 : _r.length) {
            for (const feeRefund of payout === null || payout === void 0 ? void 0 : payout.feeRefunds) {
                if (!feeRefund.source) {
                    continue;
                }
                const type = TransactionType.feeRefund.label;
                childRow.push({
                    id: feeRefund.id,
                    type,
                    amount: `${currencySymbol}${((feeRefund.source.amount || 0) / 100).toFixed(2)}`,
                    parentId: payout.id,
                    noticeId: ((_s = feeRefund.source.metadata) === null || _s === void 0 ? void 0 : _s.userNoticeId) || '',
                    transactionTypeValue: TransactionType.feeRefund.key
                });
            }
        }
        /** This block handles to display manual payouts, this can be of two types:
         * 1. A payout reversed from Stripe dashboard
         * 2. A payout manually paid from Stripe dashboard
         */
        if (!payout.automatic) {
            if (payout.original_payout) {
                childRow.push({
                    id: payout.id,
                    type: TransactionType.paymentReversed.label,
                    amount: `${currencySymbol}${(payout.amount / 100).toFixed(2)}`,
                    parentId: payout.id,
                    noticeId: '' // reversed payouts do not have noticeId in Stripe metadata
                });
            }
            else {
                childRow.push({
                    id: payout.id,
                    type: TransactionType.manualPayment.label,
                    amount: `${currencySymbol}${(payout.amount / 100).toFixed(2)}`,
                    parentId: payout.id,
                    noticeId: '' // manual payouts do not have noticeId in Stripe metadata
                });
            }
        }
        return {
            parent: parentRow,
            children: childRow
        };
    });
    const loadBalanceTransactions = (payouts) => __awaiter(void 0, void 0, void 0, function* () {
        const start = pageNumber * PAGE_SIZE;
        const end = start + 10 <= payouts.length ? start + 10 : payouts.length;
        const payoutsList = payouts.slice(start, end);
        const { balanceTransactions, success } = yield api.post('payments/stripe-bt-payouts-data', {
            accountId: activeOrganization.data().accountId,
            payouts: payoutsList
        });
        if (!balanceTransactions.length || !success)
            return payouts;
        const newPayouts = payouts;
        for (let i = start; i < end; i++) {
            newPayouts[i] = Object.assign(Object.assign({}, payouts[i]), balanceTransactions[i - start]);
        }
        return newPayouts;
    });
    // load balance transactions of paginated payouts
    const loadPayoutGroupsPage = (payouts) => __awaiter(void 0, void 0, void 0, function* () {
        const newPayouts = yield loadBalanceTransactions(payouts);
        const groups = [];
        yield Promise.all(newPayouts.map((payout) => __awaiter(void 0, void 0, void 0, function* () {
            const group = yield loadRowsForPayoutTransfer(payout);
            if (group) {
                groups.push(group);
            }
        })));
        setPayoutGroups(groups);
        !pageVisited.includes(pageNumber) && pageVisited.push(pageNumber);
        setPageVisited(pageVisited);
    });
    const loadPayouts = () => __awaiter(void 0, void 0, void 0, function* () {
        const { payouts } = yield api.post('payments/stripe-payout-data');
        yield loadPayoutGroupsPage(payouts);
        setPayouts(payouts);
    });
    useEffect(() => {
        if (pageNumber !== 0 && !pageVisited.includes(pageNumber)) {
            setPayoutsLoading(true);
            loadPayoutGroupsPage(payouts).finally(() => setPayoutsLoading(false));
        }
    }, [pageNumber]);
    useEffect(() => {
        if (pageNumber !== 0 && !pageVisited.includes(pageNumber)) {
            setPayoutsLoading(true);
            loadPayoutGroupsPage(payouts).finally(() => setPayoutsLoading(false));
        }
    }, [pageNumber]);
    useEffect(() => {
        setPayoutGroups([]);
        setPayoutsLoading(true);
        loadPayouts().finally(() => setPayoutsLoading(false));
    }, [activeOrganization && activeOrganization.id]);
    if (payoutsLoading)
        return React.createElement(LoadingState, { message: "Loading payment data...", timeout: 60 });
    const renderHeader = ({ header, width } = {}) => {
        return (React.createElement("th", { className: `py-3 ${width && `w-${width}`} bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider` }, header));
    };
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Payouts", description: "Expand to see a breakdown of each payout to your bank." },
            stripeInvoicesLink && (React.createElement("button", { type: "button", className: "px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => window.open(stripeInvoicesLink) },
                React.createElement("span", { className: "pr-2" },
                    React.createElement(InvoiceIcon, null)),
                "Invoices")),
            stripeLoginLink && (React.createElement("button", { type: "button", className: "ml-3 px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => window.open(stripeLoginLink) },
                React.createElement("span", { className: "pr-2" },
                    React.createElement(ExternalLinkIcon, null)),
                "Update Account")),
            React.createElement("button", { type: "button", className: "px-3 ml-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => setOpenDownloadModal(true) },
                React.createElement("span", { className: "pr-2" },
                    React.createElement(DownloadIcon, { className: "h-4 w-4 text-blue-800" })),
                "Download Report")),
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    renderHeader(),
                    renderHeader({ header: 'amount' }),
                    renderHeader({ width: '1/5' }),
                    renderHeader({ header: 'created' }),
                    renderHeader({ header: 'arrives/arrived' }),
                    renderHeader({ header: 'actions' }))),
            React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, payoutGroups.slice(startIndex, endIndex + 1).map(group => (React.createElement(PayoutGroupRows, { key: group.parent.id, group: group, payoutSource: 'stripe', handleDownloadCsvClicked: id => downloadCSV(id) }))))),
        React.createElement("footer", { className: "pr-5 py-0.5 bg-gray-50 border border-gray-300" },
            React.createElement("nav", { className: "px-4 flex items-center justify-between sm:px-0" },
                React.createElement("div", { className: "pl-6 sm:block flex-1 flex" },
                    React.createElement("p", { className: "text-sm text-gray-700 font-medium mx-1" }, payoutGroups.length > 1
                        ? `Showing ${startIndex + 1} to ${endIndex + 1} of ${payoutGroups.length} payouts`
                        : 'Showing all payouts')),
                React.createElement("div", { className: "-mt-px w-0 flex-1 flex justify-end" },
                    React.createElement(ReactPaginate, { previousLabel: React.createElement(ArrowRight, null), previousClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", nextLabel: React.createElement(ArrowLeft, null), nextClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", breakLabel: '...', initialPage: pageNumber, forcePage: pageNumber, breakClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", pageClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300", activeLinkClassName: "focus:outline-none outline-none text-blue-500 border-blue-500", pageLinkClassName: "px-4 text-sm", pageCount: Math.ceil(payoutGroups.length / PAGE_SIZE), marginPagesDisplayed: 2, pageRangeDisplayed: 5, onPageChange: pageTo => {
                            setPageNumber(pageTo.selected);
                        }, containerClassName: 'pagination flex', activeClassName: 'text-blue-500 border-blue-500 outline-none' })))),
        openDownloadModal && (React.createElement(PayoutsDownloadModal, { close: () => setOpenDownloadModal(false) }))));
};
export const CheckSettings = ({ activeOrganization, user, fromRegistration, continueRegistration, updateToast, userAuth }) => {
    var _a, _b;
    const [showDwollaCustomerModal, setShowDwollaCustomerModal] = useState(false);
    const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
    const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState('');
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
    const [openPlaid, setOpenPlaid] = useState(fromRegistration);
    const [loading, setLoading] = useState(false);
    // plaid details
    const [plaidData, setPlaidData] = useState();
    const [showToast, setShowToast] = useState(false);
    const [addBankVerifyOptions, setAddBankVerifyOptions] = useState(false);
    // this function check if dwolla customer exists against newspaper
    const checkIsDwollaCustomerExists = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const activeOrg = yield activeOrganization.data();
        if ((_c = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.dwolla) === null || _c === void 0 ? void 0 : _c.dwollaCustomer) {
            if ((_d = user === null || user === void 0 ? void 0 : user.data()) === null || _d === void 0 ? void 0 : _d.dwollaPasswordSecurityEnabled)
                setAddBankVerifyOptions(true);
            else
                setShowPasswordResetModal(true);
        }
        else {
            // create new customer
            setShowDwollaCustomerModal(true);
        }
    });
    const updateValues = () => {
        setShowDwollaCustomerModal(false);
        setShowPasswordResetModal(true);
    };
    const addBankToDwolla = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { success, error } = yield api.post('payments/dwolla-funding-source', plaidData);
            if (success) {
                if (fromRegistration && continueRegistration) {
                    continueRegistration();
                }
                return {};
            }
            if (error)
                return { error };
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to add bank to Dwolla', {
                accountId: activeOrganization.data().accountId
            });
        }
    });
    return (React.createElement("div", null,
        !fromRegistration && (React.createElement("div", null,
            React.createElement("p", { className: "not-italic text-center font-normal text-base pt-3 text-gray-700" }, "You have selected to receive payments via check. To track your payment history and get paid faster, enable ACH by clicking the button below."),
            React.createElement("div", { className: "flex items-center justify-center" },
                React.createElement("button", { className: `font-medium text-sm text-white rounded uppercase px-5 py-2 mt-2 bg-blue-600`, type: "button", id: "direct-deposit", onClick: () => {
                        var _a;
                        if ((_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwollaPaymentEnabled) {
                            void checkIsDwollaCustomerExists();
                        }
                        else
                            window.location.replace(STRIPE_VARS.oauthUrl);
                    } }, "Get paid via direct deposit")))),
        openPlaid && (React.createElement(Plaid, { user: user, setOpen: setOpenPlaid, setShowAuthorizationModal: setShowAuthorizationModal, setPlaidData: setPlaidData })),
        showDwollaCustomerModal && (React.createElement(DwollaCustomerModal, { showCustomerModal: () => setShowDwollaCustomerModal(false), updateOnSuccess: () => updateValues(), organization: activeOrganization })),
        showPasswordResetModal && (React.createElement(DwollaPasswordResetModal, { userAuth: userAuth, passwordUpdateSuccess: () => {
                setShowPasswordResetModal(false);
                setShowToast(true);
                setAddBankVerifyOptions(true);
            }, user: user, setDwollaPasswordModal: () => setShowPasswordResetModal(false) })),
        showToast && (React.createElement(EToast, { message: "Success! Your password has been changed.", type: "success", close: () => setShowToast(false) })),
        showAuthorizationModal && (React.createElement(TailwindModal, { header: "ACH authorization agreement", close: () => setShowAuthorizationModal(false), noExitOutsideModal: true, widthPct: 30 },
            React.createElement("div", { className: "mb-1 mt-2" },
                React.createElement("div", { className: "bg-gray-300 bg-opacity-50" },
                    React.createElement("div", { className: "p-4 text-xs mb-1" },
                        "I hereby authorize and request Column, PBC to debit funds from my account at the Financial Institution indicated, and credit the funds. I authorize Column, PBC to take any and all action required to correct any errors.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        " By clicking the button below, I certify that the information I have given on this ACH Debit Authorization Agreement for Direct Payments is complete, true, and submitted for the purpose selected above. It will remain in effect until I notify Column, PBC of its cancellation by contacting help@column.us.")),
                React.createElement("div", { className: "flex items-center mb-4 mt-3" },
                    React.createElement("input", { id: "agreeToTerms", type: "checkbox", className: "form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out", checked: true }),
                    React.createElement("div", { className: "ml-2 block text-sm leading-4 text-gray-900" }, "I agree to the authorization terms above.")),
                React.createElement("button", { className: `rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, id: "confirm-authorization", type: "button", disabled: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setLoading(true);
                        let result;
                        if (plaidData) {
                            result = yield addBankToDwolla();
                        }
                        setLoading(false);
                        setShowAuthorizationModal(false);
                        if (result === null || result === void 0 ? void 0 : result.error) {
                            // TODO handle errors for plaid flow
                            if (plaidData)
                                setShowAlreadyExistsModal(result.error);
                        }
                        else {
                            plaidData && updateToast && updateToast();
                        }
                    }) },
                    React.createElement("span", { className: "flex" },
                        loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                        "Confirm"))))),
        showAlreadyExistsModal && (React.createElement(TailwindModal, { header: "Bank Account Already Exists", body: showAlreadyExistsModal, close: () => setShowAlreadyExistsModal(''), buttonText: "Back to Settings", onButtonClick: () => setShowAlreadyExistsModal(''), noExitOutsideModal: true })),
        addBankVerifyOptions && (React.createElement(AccountVerificationOptionsModal, { customerId: (_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwolla) === null || _b === void 0 ? void 0 : _b.dwollaCustomer, closeModal: () => setAddBankVerifyOptions(false), secureLoginHandleClick: () => {
                setAddBankVerifyOptions(false);
                setOpenPlaid(true);
            }, verifyAccountHandleClick: () => {
                setAddBankVerifyOptions(false);
            }, user: user === null || user === void 0 ? void 0 : user.data() }))));
};
const PayoutsDownloadModal = ({ close }) => {
    const [loadingPayouts, setLoadingPayouts] = useState(false);
    const [period, setPeriod] = useState('lastMonth');
    const [error, setError] = useState('');
    const downloadPayouts = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingPayouts(true);
        setError('');
        try {
            const { url } = yield api.post(`payments/download-payouts`, {
                period
            });
            if (url)
                window.open(url);
            else
                setError('There are no relevant payouts in the given period');
        }
        catch (err) {
            setError('There was an error downloading your payouts.');
            logAndCaptureException(err, 'Failed to download payouts', {
                period
            });
        }
        setLoadingPayouts(false);
    });
    return (React.createElement("div", { className: "fixed z-10 inset-0 overflow-y-auto" },
        React.createElement("div", { className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity" },
                React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
                React.createElement("div", null,
                    React.createElement("div", { className: "mt-1 text-center" },
                        React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900", id: "modal-headline" }, "Download Payouts"),
                        React.createElement("div", { className: "my-5" },
                            React.createElement("div", { className: "col-span-6 sm:col-span-3" },
                                React.createElement("div", { className: "mb-2 text-left block text-sm font-medium leading-5 text-gray-700" }, "Download payouts from"),
                                React.createElement("select", { value: period, onChange: e => setPeriod(e.target.value), id: "country", className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" },
                                    React.createElement("option", { value: "currMonth" }, "Current month"),
                                    React.createElement("option", { value: "lastMonth" }, "Previous month"),
                                    React.createElement("option", { value: "currYear" }, "Current year"),
                                    React.createElement("option", { value: "lastYear" }, "Previous year"),
                                    React.createElement("option", { value: "all" }, "Download all")))))),
                error && (React.createElement("div", { className: "my-5" },
                    React.createElement("div", { className: "text-red-400 text-sm" }, error))),
                React.createElement("div", { className: "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense mb-2" },
                    React.createElement("span", { className: "flex w-full rounded-md shadow-sm sm:col-start-2" },
                        React.createElement("button", { type: "button", className: `text-white shadow inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5`, onClick: () => downloadPayouts(), disabled: loadingPayouts },
                            React.createElement("span", { className: "flex" },
                                loadingPayouts && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Download"))),
                    React.createElement("span", { className: "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1" },
                        React.createElement("button", { type: "button", className: "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5", onClick: () => close() }, "Cancel")))))));
};
// add a check about dwolla to render dwolla payouts
const PaymentSettings = props => {
    var _a, _b;
    const [showVerifiedToast, setShowVerifiedToast] = useState(false);
    return (React.createElement("div", { id: "payment-settings-wrapper" },
        (props.activeOrganization.data().accountId === ENOTICE_ACCOUNT_ID ||
            props.activeOrganization.data().accountId === DUMMY_ACCOUNT_ID) &&
            !((_a = props.activeOrganization.data().dwolla) === null || _a === void 0 ? void 0 : _a.dwollaFundingSource) ? (React.createElement(CheckSettings, { activeOrganization: props.activeOrganization, updateToast: () => setShowVerifiedToast(true), userAuth: props.userAuth, push: props.push, user: props.user })) : ((_b = props.activeOrganization.data().dwolla) === null || _b === void 0 ? void 0 : _b.dwollaFundingSource) ? (React.createElement(DwollaSettings, { activeOrganization: props.activeOrganization, user: props.user })) : (React.createElement(StripeSettings, Object.assign({}, props))),
        showVerifiedToast && !props.fromRegistration && (React.createElement(EToast, { message: "Successfully verified! You can now get paid for your notices using this direct payment method.", type: "success", close: () => setShowVerifiedToast(false) }))));
};
export default PaymentSettings;
