var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase, { Firestore } from '../EnoticeFirebase';
import { Collections } from '../lib/constants';
const DEFAULT_RETRIES = 60;
/**
 * Construct a reference to a collection that is SDK agnostic.
 */
export const getCollectionRef = (path) => {
    const db = Firebase.firestore();
    return db.collection(path).withConverter({
        toFirestore: (data) => data,
        fromFirestore: snap => snap.data()
    });
};
export const getSubcollectionGroupRef = (path) => {
    const db = Firebase.firestore();
    return db.collectionGroup(path).withConverter({
        toFirestore: (data) => data,
        fromFirestore: snap => snap.data()
    });
};
/**
 * The main entry point into the land of EFirebase.
 */
export const getFirebaseContext = () => ({
    adTemplatesRef: () => getCollectionRef(Collections.adTemplates),
    cardsRef: () => getCollectionRef(Collections.cards),
    cardInvoicesRef: () => getCollectionRef(Collections.cardInvoices),
    cardTransactionsRef: () => getCollectionRef(Collections.cardTransactions),
    customersRef: () => getCollectionRef(Collections.customers),
    customerOrganizationsRef: () => getCollectionRef(Collections.customerOrganizations),
    deadlinesRef: () => getSubcollectionGroupRef(Collections.deadlines),
    displaySitesRef: () => getCollectionRef(Collections.displaySites),
    displaySiteUploadIDsRef: (parent) => getCollectionRef(`${parent.path}/${Collections.uploadIDs}`),
    donationsRef: () => getCollectionRef(Collections.donations),
    donationPayoutsRef: () => getCollectionRef(Collections.donationPayouts),
    donationProfilesRef: () => getCollectionRef(Collections.donationProfiles),
    donorsRef: () => getCollectionRef(Collections.donors),
    emailConfirmationsRef: () => getCollectionRef(Collections.emailConfirmations),
    eventsRef: () => getCollectionRef(Collections.events),
    invitesRef: () => getCollectionRef(Collections.invites),
    invoicesRef: () => getCollectionRef(Collections.invoices),
    invoiceItemsRef: () => getCollectionRef(Collections.invoiceItems),
    notificationsRef: () => getCollectionRef(Collections.notifications),
    organizationsRef: () => getCollectionRef(Collections.organizations),
    organizationDeadlinesRef: (parent) => getCollectionRef(`${parent.path}/${Collections.deadlines}`),
    payoutsRef: () => getCollectionRef(Collections.payouts),
    publicNoticesRef: () => getCollectionRef(Collections.publicNotices),
    previewNoticesRef: () => getCollectionRef(Collections.previewNotices),
    ratesRef: () => getCollectionRef(Collections.rates),
    subscriptionsRef: () => getCollectionRef(Collections.subscriptions),
    surveysRef: () => getCollectionRef(Collections.surveys),
    transfersRef: () => getCollectionRef(Collections.transfers),
    userNoticesRef: () => getCollectionRef(Collections.userNotices),
    userNoticeFilesRef: (parent) => getCollectionRef(`${parent.path}/${Collections.noticeFiles}`),
    userDraftsRef: () => getCollectionRef(Collections.userDrafts),
    usersRef: () => getCollectionRef(Collections.users),
    notarizationsRef: () => getCollectionRef(Collections.notarizations),
    joinRequestsRef: () => getCollectionRef(Collections.joinRequests),
    stripeEventsRef: () => getCollectionRef(Collections.stripeevents)
});
export const { FieldValue } = Firestore;
// Waits for a firebase document to exist and returns its
// snapshot
export const waitForDocument = (documentId, retries = DEFAULT_RETRIES) => __awaiter(void 0, void 0, void 0, function* () {
    const WAIT_TIMEOUT = 1000;
    let snapshot = null;
    let remainingAttempts = retries;
    do {
        // eslint-disable-next-line no-await-in-loop
        yield new Promise(resolve => setTimeout(resolve, WAIT_TIMEOUT));
        try {
            // eslint-disable-next-line no-await-in-loop
            const result = yield Firebase.firestore().doc(documentId).get();
            if (result.exists) {
                snapshot = result;
            }
        }
        catch (err) {
            if (remainingAttempts <= 0) {
                throw new Error(`Max retries exceeded fetching document ${documentId}`);
            }
            remainingAttempts -= 1;
        }
    } while (!snapshot);
    return snapshot;
});
export const waitForNotice = (noticeId) => waitForDocument(`usernotices/${noticeId}`);
export const waitForPropertyOnDocument = (docId, property) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise(resolve => {
        const unsub = Firebase.firestore()
            .doc(docId)
            .onSnapshot(s => {
            if (s && s.exists && s.data()[property]) {
                unsub();
                resolve(s);
            }
        });
    });
});
/**
 * Coerce a Firestore timestamp or Date into a Firestore timestamp. Note that
 * this utility cannot be moved into 'lib' because there are slight differences
 * between how Firestore timestamps are created in firebase vs. firebase-admin.
 */
export const timestampOrDateToTimestamp = (d) => {
    if (d instanceof Date ||
        Object.prototype.toString.call(d) === '[object Date]') {
        return Firestore.Timestamp.fromDate(d);
    }
    if (typeof d.toDate === 'function') {
        return d;
    }
    throw new Error(`Could not convert object to timestamp: ${JSON.stringify(d)} (type ${typeof d})`);
};
