import React from 'react';
import { BuildingIcon } from 'icons';
import { getOrganizationIconColors } from '../modals/JoinOrganizationModals/helpers';
const RequestActionCard = ({ request, index, className }) => {
    const iconStyles = getOrganizationIconColors(index);
    return (React.createElement("div", { className: `${className} border-gray-300 p-5 bg-white space-y-6` },
        React.createElement("div", { className: "relative flex items-center space-x-4" },
            React.createElement("div", { className: "flex-shrink-0" },
                React.createElement("div", { className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` },
                    React.createElement(BuildingIcon, { style: {
                            margin: 'auto',
                            transform: 'translateY(40%)'
                        }, stroke: iconStyles.stroke }))),
            React.createElement("div", { className: "min-w-0 flex-1" },
                React.createElement("p", { className: "text-left text-sm font-medium text-blue-800" },
                    "You have a pending request to join",
                    ' ',
                    React.createElement("span", { className: "font-semibold" }, request.organizationName),
                    ". You\u2019ll be added to",
                    ' ',
                    React.createElement("span", { className: "font-semibold" }, request.organizationName),
                    ' ',
                    "when an organization admin approves your request.")))));
};
export default RequestActionCard;
