var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { getLocationParams } from 'utils/urls';
import LoadingState from 'components/LoadingState';
import { Permissions } from 'lib/permissions/roles';
import { userHasPermission } from 'utils/permissions';
import { HomeIcon, Members } from '../icons';
import OrganizationMemberSettings from '../OrganizationMemberSettings';
import HoldingCompanyInfo from './HoldingCompanyInfo';
const GENERAL_INFO = 'general-setting';
const ORGANIZATION_MEMBER_SETTINGS = 'member-settings';
const drawerWidth = 35;
const styles = (theme) => createStyles({
    root: {
        display: 'flex'
    },
    drawer: {
        width: theme.spacing(drawerWidth),
        flexShrink: 0
    },
    drawerPaper: {
        width: theme.spacing(drawerWidth)
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
});
const HoldingCompanySettings = (_a) => {
    var { classes, activeOrganization, user } = _a, props = __rest(_a, ["classes", "activeOrganization", "user"]);
    const [view, setView] = useState(getLocationParams().get('tab') || GENERAL_INFO);
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/organization/?tab=${view}`);
        setView(view);
    };
    const renderTab = (tabName, tabLabel, icon) => (React.createElement("div", { className: `flex rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tabName && 'bg-gray-200'}`, onClick: () => updateView(tabName), id: tabName },
        React.createElement("div", { className: "flex justify-center w-3/12" }, icon),
        React.createElement("div", { className: "w-9/12" }, tabLabel)));
    const renderSideBar = () => (React.createElement("div", { className: "bg-white rounded border border-gray-300 pt-3 px-4", style: {
            height: 'calc(100vh - 150px)'
        } },
        userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
            renderTab(GENERAL_INFO, 'Newspaper Info', HomeIcon),
        renderTab(ORGANIZATION_MEMBER_SETTINGS, 'Members', Members)));
    if (!activeOrganization)
        return React.createElement(LoadingState, null);
    return (React.createElement("div", { className: classes.root },
        React.createElement(CssBaseline, null),
        renderSideBar(),
        React.createElement("main", { className: classes.content }, {
            [GENERAL_INFO]: (React.createElement(HoldingCompanyInfo, Object.assign({ activeOrganization: activeOrganization }, props))),
            [ORGANIZATION_MEMBER_SETTINGS]: (React.createElement(OrganizationMemberSettings, Object.assign({ activeOrganization: activeOrganization, user: user }, props)))
        }[view])));
};
export default withStyles(styles)(HoldingCompanySettings);
