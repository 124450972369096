var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
const ReplaceAffidavitModal = ({ saveNCloseModal, uploadFile, setOpen }) => {
    const buttonColor = 'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600';
    return (React.createElement(FreeformCModal, { setOpen: setOpen, header: 'Replace Affidavit?', body: `To replace the current affidavit, please upload a new file. The client will be automatically notified of this change.` },
        React.createElement(CButton, { className: `${buttonColor} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full`, id: "replace-affidavit", onClick: () => {
                var _a;
                (_a = document.getElementById('replace-affidavit-file')) === null || _a === void 0 ? void 0 : _a.click();
            } },
            "Select New File",
            React.createElement("input", { type: "file", accept: "application/pdf", className: `hidden`, id: "replace-affidavit-file", onChange: ({ target: { validity, files } }) => __awaiter(void 0, void 0, void 0, function* () {
                    validity.valid && files && uploadFile(files[0]);
                    setOpen(false);
                    yield saveNCloseModal();
                }) }))));
};
export default ReplaceAffidavitModal;
