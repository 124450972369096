var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import Firebase from 'EnoticeFirebase';
import { logAndCaptureException, sanitize } from 'utils';
import { awaitAllPromises, getFulfilled, getRejected } from 'lib/helpers';
export function useGetFirebaseStorageDownloadUrl(storageRef) {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [previewState, setPreviewState] = useState('idle');
    useEffect(() => {
        const getDownloadUrl = () => __awaiter(this, void 0, void 0, function* () {
            try {
                setPreviewState('loading');
                const downloadUrl = yield storageRef.getDownloadURL();
                setDownloadUrl(downloadUrl);
                setPreviewState('idle');
            }
            catch (error) {
                setPreviewState('error');
                logAndCaptureException(error, 'Unable to get download url', {
                    storagePath: storageRef.fullPath
                });
            }
        });
        void getDownloadUrl();
    }, [storageRef.fullPath]);
    return {
        downloadUrl,
        previewState
    };
}
const STORAGE_OBJECT_NOT_FOUND = 'storage/object-not-found';
export function useDeleteFirebaseStorageFile({ storageRef, deleteConfirmation, onDelete }) {
    const [deletionState, setDeletionState] = useState('idle');
    function deleteFile() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const isConfirmed = deleteConfirmation
                    ? yield deleteConfirmation()
                    : true;
                if (isConfirmed) {
                    setDeletionState('deleting');
                    yield storageRef.delete();
                    onDelete && onDelete();
                    return;
                }
                setDeletionState('idle');
            }
            catch (error) {
                if (error.code ===
                    STORAGE_OBJECT_NOT_FOUND) {
                    onDelete && onDelete();
                    return;
                }
                setDeletionState('error');
                logAndCaptureException(error, 'Unable to delete file', {
                    storagePath: storageRef.fullPath
                });
            }
        });
    }
    return {
        deleteFile,
        deletionState
    };
}
export function useFirebaseStorageUpload({ uploadFolder, onFileUpload }) {
    const [uploadState, setUploadState] = useState('idle');
    const [failedUploads, setFailedUploads] = useState();
    const uploadFiles = (files) => __awaiter(this, void 0, void 0, function* () {
        if (!files || files.length === 0)
            return;
        setUploadState('uploading');
        const uploadSnapshots = yield awaitAllPromises(files.map((file) => __awaiter(this, void 0, void 0, function* () {
            return yield Firebase.storage()
                .ref()
                .child(`${uploadFolder}/${new Date().getTime()}/${sanitize(file.name)}`)
                .put(file);
        })));
        const successfulUploads = getFulfilled(uploadSnapshots);
        const failedUploads = getRejected(uploadSnapshots);
        setFailedUploads(failedUploads);
        if (failedUploads && failedUploads.length > 0) {
            logAndCaptureException(new Error('Failed to upload file'), 'Failed to upload file', {
                failedUploads: failedUploads.join(', ')
            });
        }
        successfulUploads &&
            successfulUploads.length > 0 &&
            onFileUpload &&
            onFileUpload({ files, uploadSnapshots: successfulUploads });
        setUploadState('idle');
    });
    return { uploadState, uploadFiles, failedUploads };
}
