import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { ELabels } from 'lib/enums';
const styles = (theme) => createStyles({
    iconContainer: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    help: {
        color: theme.palette.primary.main,
        fontSize: 18,
        verticalAlign: 'text-top'
    }
});
const InvoiceFormCustomerTable = ({ classes, noticeSnap, customerName, setCustomId, customId }) => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-white shadow overflow-hidden sm:rounded-lg" },
            React.createElement("div", { className: "px-4 py-5 sm:p-0" },
                React.createElement("dl", null,
                    React.createElement("div", { className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Customer"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, customerName)),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, ELabels.publisher_id.label),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            React.createElement(TextField, { id: ELabels.publisher_id.key, className: classes.formControl, label: "", value: customId, onChange: e => setCustomId(e.target.value) }),
                            ' ')),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Notice ID"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, noticeSnap.id)))))));
};
export default withStyles(styles)(InvoiceFormCustomerTable);
