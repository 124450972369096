import React from 'react';
import SearchedOrganizationsListItem from './SearchedOrganizationsListItem';
import { Search } from '../../../gifs/index';
export default function SearchedOrganizationsList({ organizationsList, searchOrgText, selectedItems, updateSelectedItems, onCreateNewOrganization }) {
    const isItemSelected = (organizationName) => selectedItems &&
        selectedItems.some(selectedItem => (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) === organizationName);
    const displayOptions = organizationsList.filter(organizationSnap => {
        var _a;
        return (_a = organizationSnap === null || organizationSnap === void 0 ? void 0 : organizationSnap.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchOrgText.toLowerCase());
    });
    return (React.createElement("div", { className: "bg-white rounded mt-2 border shadow px-2 pb-1 overflow-scroll", style: {
            maxHeight: '230px',
            height: !displayOptions.length ? '236px' : ''
        } },
        React.createElement("p", { className: "uppercase font-semibold text-gray-500 text-xs ml-2 mt-4 mb-4" }, "Search to join existing organizations"),
        React.createElement("div", { className: "divide-y divide-gray-250" }, displayOptions.map((organizationSnap, index) => {
            return (organizationSnap && (React.createElement("div", { key: `search-item-${organizationSnap.id}` },
                React.createElement(SearchedOrganizationsListItem, { organizationSnap: organizationSnap, onItemSelected: item => updateSelectedItems([...selectedItems, item]), selected: isItemSelected(organizationSnap.name), index: index }))));
        })),
        !displayOptions.length && (React.createElement("div", { className: "mt-8 flex flex-col items-center justify-center" },
            React.createElement("div", { className: "bg-blue-100 h-16 w-16 rounded-full flex items-center justify-center p-1" },
                React.createElement("img", { src: Search })),
            React.createElement("p", { className: "mt-3 text-gray-450 text-sm\tfont-medium\tleading-6" }, "No results found!"),
            React.createElement("div", { className: "text-primary-500 underline underline-offset-3 text-sm font-medium leading-6 cursor-pointer", onClick: () => onCreateNewOrganization(), 
                // textUnderlineOffset property not included in tailwind verion 1.8.11 (currently used)
                style: { textUnderlineOffset: '3px' } }, "Create a new organization")))));
}
