export const DwollaCustomerStatusData = {
    verified: {
        value: 1,
        label: 'Verified Customer',
        key: 'verified'
    },
    retry: {
        value: 2,
        label: 'Retry Customer',
        key: 'retry'
    },
    document: {
        value: 3,
        label: 'Document Customer',
        key: 'document'
    }
};
