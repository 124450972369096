import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import NoticeTypeCard from './NoticeTypeCard';
import { includeSearchParams } from '../../utils/urls';
const CreateUserNoticeCard = ({ noticeType, noticeId, draftId, push }) => (React.createElement(NoticeTypeCard, { noticeType: noticeType, onClick: () => {
        if (noticeType.children().length) {
            push(includeSearchParams(`/file/${noticeId}/${noticeType.key}`));
            return;
        }
        if (!noticeType.typeform) {
            push(`/error/204/`);
            return;
        }
        push(includeSearchParams(`/form/${draftId}/${noticeType.value}`));
    } }));
export default connect(null, { push })(CreateUserNoticeCard);
