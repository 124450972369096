import { NoticeType } from '../enums';
/**
 * Checks if the given rate supports display notices. A rate supports display notices if:
 *  1. It's a liner rate and `supportsDisplay` is set
 *  2. Or it's a display rate
 */
export const supportsDisplay = (rate) => {
    const { supportsDisplay, noticeType } = rate.data();
    return supportsDisplay || noticeType === NoticeType.display_ad.value;
};
