var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { exists } from 'lib/types';
import * as EventTypes from 'lib/types/events';
import { OccupationType, SyncStatusType } from 'lib/enums';
import { awaitAllPromises, getFulfilled, firestoreTimestampOrDateToDate, isColumnUser } from 'lib/helpers';
const COLUMN_SUPPORT = 'Column Support';
const getEventInitiatorString = (user, organizationName) => {
    if (!exists(user)) {
        return organizationName;
    }
    /**
     * If a member of Column Support has initiated an event by
     * shadowing the publisher or advertiser organization,
     * always show 'Column Support' in the event log
     * instead of the shadowed org
     */
    if (isColumnUser(user)) {
        return `${user.data().name} (${COLUMN_SUPPORT})`;
    }
    return organizationName.length
        ? `${user.data().name} (${organizationName})`
        : `${user.data().name}`;
};
const renderNoticeSubmittedEvent = ({ notice, newspaperName, advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { createdBy } = notice.data();
    if (createdBy) {
        const publisher = (yield createdBy.get());
        return {
            initiator: getEventInitiatorString(publisher, newspaperName),
            content: `Uploaded this notice for ${(_a = advertiserSnap === null || advertiserSnap === void 0 ? void 0 : advertiserSnap.data()) === null || _a === void 0 ? void 0 : _a.name}${advertiserOrgName.length ? ` (${advertiserOrgName})` : ''}.`
        };
    }
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Submitted this notice to ${newspaperName}.`
    };
});
const renderNoticeConfirmedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { confirmedBy } = event.data;
    if (confirmedBy) {
        const publisher = yield confirmedBy.get();
        return {
            initiator: getEventInitiatorString(publisher, newspaperName),
            content: `Confirmed the notice.`
        };
    }
    return {
        initiator: '',
        content: `Notice was confirmed`
    };
});
const renderNoticeCancelledEvent = ({ notice, newspaperName, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { noticeCancellationReason, cancelledBy } = notice.data();
    const user = yield (cancelledBy === null || cancelledBy === void 0 ? void 0 : cancelledBy.get());
    if (user && exists(user)) {
        const orgName = (user === null || user === void 0 ? void 0 : user.data().occupation) === OccupationType.publishing.value
            ? newspaperName
            : advertiserOrgName;
        return {
            initiator: getEventInitiatorString(user, orgName),
            content: `Cancelled this notice, with reason "${noticeCancellationReason}".`
        };
    }
    return {
        initiator: '',
        content: `Notice was cancelled, with reason "${noticeCancellationReason}".`
    };
});
const renderNoticeRefundedEvent = ({ notice, newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const invoice = yield ((_b = notice.data().invoice) === null || _b === void 0 ? void 0 : _b.get());
    if (!exists(invoice)) {
        return {
            initiator: '',
            content: `Initiated a refund.`
        };
    }
    const { refund_amount, paid_outside_stripe } = invoice.data();
    if (paid_outside_stripe || !refund_amount)
        return {
            initiator: newspaperName,
            content: `Initiated a refund.`
        };
    return {
        initiator: newspaperName,
        content: `Initiated a refund for $${(refund_amount / 100).toFixed(2)}.`
    };
});
const renderAffidavituploadedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { uploadedBy } = event.data;
    const publisher = yield (uploadedBy === null || uploadedBy === void 0 ? void 0 : uploadedBy.get());
    return {
        initiator: getEventInitiatorString(publisher, newspaperName),
        content: `Uploaded the affidavit.`
    };
});
const renderAffidavitReuploadedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { uploadedBy } = event.data;
    const publisher = yield (uploadedBy === null || uploadedBy === void 0 ? void 0 : uploadedBy.get());
    return {
        initiator: getEventInitiatorString(publisher, newspaperName),
        content: `Uploaded a new affidavit.`
    };
});
const renderInvoiceCreatedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { createdBy } = event.data;
    if (createdBy) {
        const publisher = yield createdBy.get();
        return {
            initiator: getEventInitiatorString(publisher, newspaperName),
            content: `Created an invoice.`
        };
    }
    return {
        initiator: newspaperName,
        content: `Created an invoice.`
    };
});
const renderPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Paid the invoice.`
    };
});
const renderCheckPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Paid the invoice by check.`
    };
});
const renderCardPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Paid the invoice by card.`
    };
});
const renderACHPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Paid the invoice by ACH.`
    };
});
const renderPaymentInitiatedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Initiated payment.`
    };
});
const renderPaymentProcessedManualEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { user } = event.data;
    const publisherSnap = yield (user === null || user === void 0 ? void 0 : user.get());
    return {
        initiator: getEventInitiatorString(publisherSnap, newspaperName),
        content: `Marked an invoice as paid out of platform.`
    };
});
const renderPaymentNoteEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { user, note } = event.data;
    const publisherSnap = yield (user === null || user === void 0 ? void 0 : user.get());
    return {
        initiator: getEventInitiatorString(publisherSnap, newspaperName),
        content: `Recorded a payment note: ${note}`
    };
});
const renderInvoicePaidOutsideEvent = ({ newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: newspaperName,
        content: `Marked the invoice as to be paid outside Column.`
    };
});
const renderInvoiceCancelledEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { cancelledBy } = event.data;
    const publisher = yield (cancelledBy === null || cancelledBy === void 0 ? void 0 : cancelledBy.get());
    return {
        initiator: getEventInitiatorString(publisher, newspaperName),
        content: `Cancelled an invoice.`
    };
});
const renderInvoiceRefundedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { cancelledBy, refundAmount } = event.data;
    const publisher = yield (cancelledBy === null || cancelledBy === void 0 ? void 0 : cancelledBy.get());
    return {
        initiator: getEventInitiatorString(publisher, newspaperName),
        content: `Initiated a refund for $${(refundAmount / 100).toFixed(2)}.`
    };
});
/**
 * Renders the verification succeeded event to track valid PDF uploads
 * @param {AffidavitVerificationSucceededEvent} event
 * @returns {EventWithTimestamp}
 */
const renderAffidavitVerificationEvent = (event, settings) => __awaiter(void 0, void 0, void 0, function* () {
    const { metadata, runDate } = event.data;
    // ignore invalid events, and only show this to Column users
    if (!metadata)
        return { initiator: '', content: '' };
    const { pdfUrl, verifiedByPublisher } = metadata;
    const dateString = moment
        .utc(firestoreTimestampOrDateToDate(runDate))
        .format('MM.DD.YYYY');
    if (verifiedByPublisher) {
        return {
            initiator: settings.newspaperName,
            content: `Verified the notice published on ${dateString}`
        };
    }
    return {
        initiator: '',
        content: 'Notice published on',
        link: {
            text: dateString,
            url: pdfUrl
        }
    };
});
const renderInvoiceReminderSentEvent = (event, { newspaperName, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const { user, email, byMail } = event.data;
    const userSnap = yield user.get();
    const isPublisher = ((_c = userSnap === null || userSnap === void 0 ? void 0 : userSnap.data()) === null || _c === void 0 ? void 0 : _c.occupation) === OccupationType.publishing.value;
    const initiatedBy = getEventInitiatorString(userSnap, isPublisher ? newspaperName : advertiserOrgName);
    if (byMail && email)
        return {
            initiator: initiatedBy,
            content: `Sent an invoice reminder to ${email} and by mail.`
        };
    if (byMail)
        return {
            initiator: initiatedBy,
            content: `Sent an invoice reminder by mail${isPublisher && advertiserOrgName ? ` to ${advertiserOrgName}` : ''}.`
        };
    return {
        initiator: initiatedBy,
        content: `Sent an invoice reminder to ${email}.`
    };
});
const renderInvoiceMailSent = ({ newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        initiator: newspaperName,
        content: `Sent the invoice by mail.`
    };
});
const renderNoticeEditedEvent = (event, { newspaperName, advertiserOrgName, advertiserSnap }) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    const { editedBy } = event.data;
    const editor = yield (editedBy === null || editedBy === void 0 ? void 0 : editedBy.get());
    const isPublisher = ((_d = editor === null || editor === void 0 ? void 0 : editor.data()) === null || _d === void 0 ? void 0 : _d.occupation) === OccupationType.publishing.value;
    if (editedBy && isPublisher) {
        return {
            initiator: getEventInitiatorString(editor, newspaperName),
            content: `Edited this notice.`
        };
    }
    if (editor && !isPublisher) {
        return {
            initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
            content: `Edited this notice.`
        };
    }
    return {
        initiator: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Notice was edited.`
    };
});
// We only show payout/transfer events to publishers
const renderNoticeTransferredEvent = ({ isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    return isPublisher
        ? { initiator: '', content: 'Payout initiated.' }
        : { initiator: '', content: '' };
});
const renderTriggerForSyncEvent = (event) => __awaiter(void 0, void 0, void 0, function* () {
    switch (event.type) {
        case EventTypes.CUSTOM_ID_UPDATED_SYNC:
            return 'on Order Number Change';
        case EventTypes.INVOICE_CREATED_SYNC:
            return 'on Invoice Creation';
        case EventTypes.INVOICE_PAYMENT_INITIATED_SYNC:
            return 'on Payment Initiated';
        case EventTypes.INVOICE_PAID_OUTSIDE_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC:
            return 'on Payment';
        case EventTypes.NOTICE_AT_DEADLINE_SYNC:
            return 'at Deadline';
        case EventTypes.NOTICE_CANCELLED_SYNC:
            return 'on Cancellation';
        case EventTypes.NOTICE_CONFIRMED_SYNC:
            return 'on Confirmation';
        case EventTypes.NOTICE_CREATED_SYNC:
            return 'on Submission';
        case EventTypes.NOTICE_EDITED_SYNC:
            return 'on Edit';
        case EventTypes.INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC:
            return 'on Payment Terms Changed';
        case EventTypes.MANUAL_SYNC_REQUEST_SYNC:
            return 'on Request';
        default:
            return 'on Unknown Trigger';
    }
});
const renderSyncEvent = (event, { isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f;
    const syncStatus = SyncStatusType.by_value(event.data.syncStatus);
    if (!isPublisher ||
        !syncStatus ||
        // Hide events with status in the 300 category
        Math.floor(syncStatus.value / 100) === 3) {
        return { initiator: '', content: '' };
    }
    const syncTriggerLabel = yield renderTriggerForSyncEvent(event);
    return {
        initiator: `Sync ${syncTriggerLabel}: ${(_e = syncStatus === null || syncStatus === void 0 ? void 0 : syncStatus.label) !== null && _e !== void 0 ? _e : 'Unknown'}`,
        content: (_f = event.data.syncMessage) !== null && _f !== void 0 ? _f : ''
    };
});
export const getEventDescription = (event, settings) => __awaiter(void 0, void 0, void 0, function* () {
    switch (event.type) {
        // Notice events
        case EventTypes.NOTICE_SUBMITTED_EVENT:
            return yield renderNoticeSubmittedEvent(settings);
        case EventTypes.NOTICE_CONFIRMED:
            return yield renderNoticeConfirmedEvent(event, settings);
        case EventTypes.NOTICE_EDITED:
            return yield renderNoticeEditedEvent(event, settings);
        case EventTypes.AFFIDAVIT_UPLOADED:
            return yield renderAffidavituploadedEvent(event, settings);
        case EventTypes.AFFIDAVIT_RE_UPLOADED:
            return yield renderAffidavitReuploadedEvent(event, settings);
        case EventTypes.NOTICE_CANCELLED:
            return yield renderNoticeCancelledEvent(settings);
        case EventTypes.NOTICE_CANCELLED_AND_REFUNDED:
            return yield renderNoticeRefundedEvent(settings);
        case EventTypes.NOTICE_TRANSFERRED:
            return yield renderNoticeTransferredEvent(settings);
        // Sync events
        case EventTypes.NOTICE_SYNC_EVENT:
            return settings.isPublisher
                ? { initiator: '', content: `Notice synced.` }
                : { initiator: '', content: '' };
        case EventTypes.NOTICE_SYNC_FAILURE_EVENT:
            return settings.isPublisher
                ? { initiator: '', content: `Notice sync failed.` }
                : { initiator: '', content: '' };
        // Invoice events
        case EventTypes.INVOICE_MAIL_SENT_EVENT:
            return yield renderInvoiceMailSent(settings);
        case EventTypes.INVOICE_CREATED:
            return yield renderInvoiceCreatedEvent(event, settings);
        case EventTypes.INVOICE_REMINDER_SENT:
            return yield renderInvoiceReminderSentEvent(event, settings);
        case EventTypes.INVOICE_PAYMENT_PROCESSED:
            switch (event.data.paymentMethod) {
                case 'ach':
                    return yield renderACHPaymentProcessedEvent(settings);
                case 'card':
                    return yield renderCardPaymentProcessedEvent(settings);
                case 'check':
                    return yield renderCheckPaymentProcessedEvent(settings);
                default:
                    return yield renderPaymentProcessedEvent(settings);
            }
        case EventTypes.INVOICE_PAYMENT_INITIATED:
            return yield renderPaymentInitiatedEvent(settings);
        case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL:
            return yield renderPaymentProcessedManualEvent(event, settings);
        case EventTypes.INVOICE_PAYMENT_NOTE:
            return yield renderPaymentNoteEvent(event, settings);
        case EventTypes.INVOICE_PAID_OUTSIDE:
            return renderInvoicePaidOutsideEvent(settings);
        case EventTypes.INVOICE_CANCELLED:
            return yield renderInvoiceCancelledEvent(event, settings);
        case EventTypes.INVOICE_REFUNDED:
            return yield renderInvoiceRefundedEvent(event, settings);
        case EventTypes.AFFIDAVIT_VERIFICATION_SUCCESS:
            return yield renderAffidavitVerificationEvent(event, settings);
        case EventTypes.NOTICE_AT_DEADLINE_SYNC:
        case EventTypes.NOTICE_CANCELLED_SYNC:
        case EventTypes.NOTICE_CONFIRMED_SYNC:
        case EventTypes.NOTICE_CREATED_SYNC:
        case EventTypes.NOTICE_EDITED_SYNC:
        case EventTypes.INVOICE_CREATED_SYNC:
        case EventTypes.INVOICE_PAID_OUTSIDE_SYNC:
        case EventTypes.INVOICE_PAYMENT_INITIATED_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_SYNC:
        case EventTypes.INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC:
        case EventTypes.CUSTOM_ID_UPDATED_SYNC:
        case EventTypes.MANUAL_SYNC_REQUEST_SYNC:
            return yield renderSyncEvent(event, settings);
        default:
            return { initiator: '', content: '' };
    }
});
export const getEventDataFromEventSnaps = (noticeEventSnaps, settings) => __awaiter(void 0, void 0, void 0, function* () {
    const { notice } = settings;
    // Add a notice submitted event if it doesn't exist
    const eventSnaps = noticeEventSnaps.slice();
    if (!(eventSnaps === null || eventSnaps === void 0 ? void 0 : eventSnaps.find(e => e.data().type === EventTypes.NOTICE_SUBMITTED_EVENT))) {
        eventSnaps.push({
            data: () => ({
                createdAt: notice.data().createTime || notice.data().confirmedAt,
                type: EventTypes.NOTICE_SUBMITTED_EVENT
            })
        });
    }
    const unsortedEvents = yield awaitAllPromises(eventSnaps.map((eventSnap) => __awaiter(void 0, void 0, void 0, function* () {
        const event = eventSnap.data();
        const eventDescription = yield getEventDescription(eventSnap.data(), settings);
        return Object.assign(Object.assign({}, eventDescription), { timestamp: event.createdAt, type: event.type });
    })));
    const noticeEvents = yield getFulfilled(unsortedEvents);
    const sortedEvents = noticeEvents.sort((event1, event2) => {
        if (event1.type === EventTypes.NOTICE_SUBMITTED_EVENT)
            return -1;
        if (event2.type === EventTypes.NOTICE_SUBMITTED_EVENT)
            return 1;
        if (event1.timestamp.toMillis() > event2.timestamp.toMillis())
            return 1;
        if (event1.timestamp.toMillis() < event2.timestamp.toMillis())
            return -1;
        return 0;
    });
    const filteredEvents = sortedEvents.filter(event => event.content !== '');
    /**
     * We are currently displaying multiple affidavit verification success events
     * for the same publication day. The following two chunks of code remove older
     * verification events and keep the most recent verification event for each
     * publication date.
     */
    // Pulling the content for each event in the activity log
    const eventsText = filteredEvents.map(event => {
        var _a;
        if (event.type !== EventTypes.AFFIDAVIT_VERIFICATION_SUCCESS) {
            return event.content;
        }
        return `${event.content} ${((_a = event.link) === null || _a === void 0 ? void 0 : _a.text) || ''}`;
    });
    const filteredEventsWithoutDuplicates = filteredEvents.filter((event, index) => {
        var _a;
        // We are only removing duplicate affidavit verification success events
        if (event.type !== EventTypes.AFFIDAVIT_VERIFICATION_SUCCESS) {
            return event;
        }
        /**
         * Because filteredEvents and eventsText are sorted by timestamp, we can use lastIndexOf
         * to determine if the event is the most recent verification event for the respective
         * publication date
         */
        if (index ===
            eventsText.lastIndexOf(`${event.content} ${((_a = event.link) === null || _a === void 0 ? void 0 : _a.text) || ''}`)) {
            return event;
        }
        return false;
    });
    return filteredEventsWithoutDuplicates;
});
