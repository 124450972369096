import { createReducer, createActions } from 'reduxsauce';
import { ACTIVE_NOTICES_TAB } from 'routes/userNoticeList/table/types';
import { DEFAULT_NOTICE_FILTERS } from 'utils/noticeFilter';
export const SHOW_CURRENT_USERS_NOTICES = 'my-notices';
export const SHOW_ALL_NOTICES = 'all-notices';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    updateNotices: [],
    updateNoticesHideLoading: [],
    setSort: ['sort'],
    setTab: ['tab'],
    setCurrentPage: ['currentPage'],
    setNotices: ['notices'],
    setTotal: ['total'],
    setShowUserOnlyNotices: ['showUserOnlyNotices'],
    setFetching: ['fetching'],
    setSearch: ['search'],
    setPageSize: ['pageSize'],
    setNoticesFilterValue: ['noticesFilterValue']
});
export const NoticeTypes = Types;
export default Creators;
export const INITIAL_STATE = {
    tab: ACTIVE_NOTICES_TAB,
    sort: { direction: null, field: null },
    currentPage: 0,
    notices: [],
    showUserOnlyNotices: false,
    fetching: true,
    search: '',
    total: 0,
    pageSize: 5,
    noticesFilterValue: DEFAULT_NOTICE_FILTERS
};
export const noticesSelector = (state) => state.notices;
/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_SEARCH]: (state, { search }) => (Object.assign(Object.assign({}, state), { search })),
    [Types.SET_TAB]: (state, { tab }) => (Object.assign(Object.assign({}, state), { tab })),
    [Types.SET_SORT]: (state, { sort }) => (Object.assign(Object.assign({}, state), { sort })),
    [Types.SET_CURRENT_PAGE]: (state, { currentPage }) => (Object.assign(Object.assign({}, state), { currentPage })),
    [Types.SET_NOTICES]: (state, { notices }) => (Object.assign(Object.assign({}, state), { notices })),
    [Types.SET_SHOW_USER_ONLY_NOTICES]: (state, { showUserOnlyNotices }) => (Object.assign(Object.assign({}, state), { showUserOnlyNotices })),
    [Types.SET_FETCHING]: (state, { fetching }) => (Object.assign(Object.assign({}, state), { fetching })),
    [Types.SET_TOTAL]: (state, { total }) => (Object.assign(Object.assign({}, state), { total })),
    [Types.SET_PAGE_SIZE]: (state, { pageSize }) => (Object.assign(Object.assign({}, state), { pageSize })),
    [Types.SET_NOTICES_FILTER_VALUE]: (state, { noticesFilterValue }) => (Object.assign(Object.assign({}, state), { noticesFilterValue }))
});
