import React from 'react';
import { sumFirstI } from './helpers';
import { InvoiceFormCell } from './InvoiceFormCell';
import { InvoiceFormLineItemRow } from './InvoiceFormLineItemRow';
export default function InvoiceFormTable({ handleChange, values, currency }) {
    const tableRows = values.length > 0
        ? values.map((lineItem, index) => {
            const runningTotal = sumFirstI(values.map(value => value.amount), index);
            return (React.createElement(InvoiceFormLineItemRow, { key: `row-${index}`, index: index, lineItem: lineItem, total: runningTotal, currency: currency, handleChange: handleChange }));
        })
        : null;
    return (React.createElement("div", { className: "overflow-x-auto shadow sm:rounded-lg" },
        React.createElement("table", { className: "bg-white min-w-full text-gray-800 divide-y divide-gray-200" },
            React.createElement("thead", { className: "text-xs font-bold uppercase tracking-wider" },
                React.createElement("tr", null,
                    React.createElement(InvoiceFormCell, null, "Quantity"),
                    React.createElement(InvoiceFormCell, null, "Description"),
                    React.createElement(InvoiceFormCell, null, "Price"),
                    React.createElement(InvoiceFormCell, null, "Total"))),
            React.createElement("tbody", { className: "text-sm whitespace-no-wrap divide-y divide-gray-200" }, tableRows))));
}
