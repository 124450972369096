import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
export const CustomerDrawerToggleRow = ({ label, value }) => {
    return (React.createElement("div", { className: "flex w-full border-b border-grey-50" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "pl-4 py-3.5 text-grey-400" }, label),
            React.createElement("div", { className: "absolute right-0 pr-8 text-grey-500" },
                React.createElement(Switch, { checked: !!value, onChange: () => { }, className: classNames(value ? 'bg-primary-200' : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                        width: '36px',
                        cursor: 'default'
                    }, disabled: true },
                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                    React.createElement("span", { "aria-hidden": "true", className: classNames(value ? 'translate-x-4' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') }))))));
};
