var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { OccupationType } from 'lib/enums';
import { handleOAuth } from 'services/billing';
import { getLocationParams } from 'utils/urls';
import { exists } from 'lib/types';
import RegisterOrganizationNewspaper from './RegisterOrganizationNewspaper';
import RegisterIndividual from './individual';
import JoinOrCreateNewOrganization from './JoinOrCreateNewOrganization';
import backgroundImg from '../backgroundImg.svg';
import { Column } from '../constants';
const RegisterOrganization = ({ user, organization, authActions, userAuth, push }) => {
    const [stripeCode] = useState(getLocationParams().get('code'));
    const [updatingAccount, setUpdatingAccount] = useState(false);
    const [anonPlacement, setAnonPlacement] = useState('');
    useEffect(() => {
        const handleStripe = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(user))
                return;
            if (user.data().occupation === OccupationType.individual.value) {
                return;
            }
            if (!user.data().organization)
                return;
            if (stripeCode) {
                setUpdatingAccount(true);
                if (user === null || user === void 0 ? void 0 : user.data())
                    yield handleOAuth(user.data(), push);
            }
        });
        if (!updatingAccount) {
            void handleStripe();
        }
        if (organization && organization.accountId)
            push('/');
    }, [user]);
    useEffect(() => {
        if (!sessionStorage.getItem('placementRedirectUrl'))
            return;
        const { placementRedirectUrl } = sessionStorage;
        setAnonPlacement(placementRedirectUrl);
    }, []);
    const isFloridaDomain = window.location.href.includes('florida');
    const isPublisher = user && user.data().occupation === OccupationType.publishing.value;
    const renderOrganizationUI = () => {
        if (!exists(user))
            return;
        switch (user.data().occupation) {
            case OccupationType.publishing.value:
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "flex items-center justify-center h-screen" },
                        React.createElement(RegisterOrganizationNewspaper, { organization: organization, authActions: authActions, userAuth: userAuth, user: user.data() }))));
            case OccupationType.individual.value:
                return (React.createElement("div", { className: "h-screen" },
                    React.createElement(RegisterIndividual, { userAuth: userAuth, authActions: authActions, user: user, push: push, anonPlacement: anonPlacement })));
            case OccupationType.government_official.value:
            case OccupationType.other_organization.value:
            case OccupationType.lawyer.value:
                return (React.createElement(JoinOrCreateNewOrganization, { userAuth: userAuth, authActions: authActions, user: user, push: push, anonPlacement: anonPlacement }));
        }
    };
    return (React.createElement("div", { className: "bg-gray-50 overflow-y-scroll", style: {
            backgroundImage: `url(${backgroundImg})`,
            width: '100vw',
            height: '100vh'
        } },
        React.createElement("main", { className: `${isPublisher ? 'w-full' : ''} items-center justify-center block flex flex-col`, style: {
                minWidth: 500
            } },
            React.createElement("div", { className: "pl-12 py-6 flex top-0 fixed left-0" },
                React.createElement("img", { className: "h-6 w-auto", src: isFloridaDomain ? '/FPF-color.png' : Column, alt: "Column" })),
            React.createElement("div", { style: { minHeight: '10%', minWidth: '50%' }, className: `${!isPublisher ? 'w-full' : ''}` }, stripeCode ? (React.createElement("div", { className: "flex justify-center align-center flex-col" },
                React.createElement("div", { className: "flex justify-center align-center mt-8 h-full w-full" },
                    React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })))) : (renderOrganizationUI())))));
};
export default RegisterOrganization;
