import React from 'react';
import { connect } from 'react-redux';
import LoadingState from 'components/LoadingState';
import { OccupationType, OrganizationType } from 'lib/enums';
import NewspaperSettings from './NewspaperSettings';
import PressAssociationSettings from './press_association';
import HoldingCompanySettings from './holding_company';
import GovernmentSettings from './government';
import LawFirmSettings from './lawyer';
import OtherOrganizationsSettings from './other_organization';
const OrganizationSettings = props => {
    const { activeOrganization, user, authLoading } = props;
    if (!user || !activeOrganization || authLoading)
        return React.createElement(LoadingState, null);
    const { occupation } = user.data();
    return (React.createElement(React.Fragment, null,
        activeOrganization.data().organizationType ===
            OrganizationType.holding_company.value && (React.createElement(HoldingCompanySettings, Object.assign({}, props))),
        activeOrganization.data().organizationType ===
            OrganizationType.newspaper.value && React.createElement(NewspaperSettings, Object.assign({}, props)),
        occupation === OccupationType.press_association_manager.value && (React.createElement(PressAssociationSettings, Object.assign({}, props))),
        occupation === OccupationType.lawyer.value && (React.createElement(LawFirmSettings, { activeOrganization: activeOrganization, user: user })),
        occupation === OccupationType.government_official.value && (React.createElement(GovernmentSettings, { activeOrganization: activeOrganization, user: user })),
        occupation === OccupationType.other_organization.value && (React.createElement(OtherOrganizationsSettings, { activeOrganization: activeOrganization, user: user }))));
};
const mapStateToProps = (state) => ({
    activeOrganization: state.auth.activeOrganization,
    user: state.auth.user,
    authLoading: state.auth.loading,
    userAuth: state.auth.userAuth
});
export default connect(mapStateToProps)(OrganizationSettings);
