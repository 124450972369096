export const DwollaTransferStatusData = {
    pending: {
        value: 1,
        label: 'Dwolla Transfer Pending',
        key: 'pending'
    },
    initiated: {
        value: 2,
        label: 'Dwolla Transfer Initiated',
        key: 'initiated'
    }
};
