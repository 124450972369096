var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { exists } from 'lib/types';
import * as headers from 'lib/headers_footers/headers';
import { ColumnButton } from 'components/ColumnButton';
import { Firestore } from 'EnoticeFirebase';
import { getNoticeType, requestDisplayParameters } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import NoticePreviewContainer from 'components/noticePreview/NoticePreviewContainer';
import useDebounce from 'utils/UseDebounce';
import LoadingState from 'components/LoadingState';
import { createDBPricingObject } from 'lib/pricing';
import api from 'api';
import { logAndCaptureException } from 'utils';
import NoticeEditorMadlib from 'routes/madlib/components/NoticeEditorMadlib';
import { INDESIGN_URL } from '../../constants';
const Madlib = () => {
    var _a, _b, _c;
    const activeOrganization = useSelector((state) => state.auth.activeOrganization);
    const [errors, setErrors] = useState([]);
    const [madlibState, setMadlibState] = useState();
    // for generating preview
    const [notice, setNotice] = useState();
    const [columns, setColumns] = useState(1);
    const [noticeData, setNoticeData] = useState();
    const [previewContent, setPreviewContent] = useState({
        displayParameters: {},
        price: '0'
    });
    const [previewLoading, setPreviewLoading] = useState(false);
    const [idError, setIDError] = useState('');
    const [templateStyles, setTemplateStyles] = useState();
    // Each time the placement template id is updated, we fetch the template
    // styles from the backend.
    useEffect(() => {
        const fetchTemplateStyles = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const templateId = (_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.adTemplate) === null || _b === void 0 ? void 0 : _b.id;
            if (!templateId) {
                return;
            }
            try {
                const res = (yield api.post('templates/styles', {
                    templateId
                }));
                if (res.success === true) {
                    setTemplateStyles(res.styles);
                }
            }
            catch (e) {
                logAndCaptureException(e, 'Failed to get styles for template', {
                    templateId
                });
            }
        });
        void fetchTemplateStyles();
    }, [(_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.adTemplate) === null || _b === void 0 ? void 0 : _b.id]);
    const debouncedRenderedHtml = useDebounce(madlibState === null || madlibState === void 0 ? void 0 : madlibState.renderedHtml, 1000);
    const ctx = getFirebaseContext();
    const GENERIC_ID_ERROR = "Whoops, we're having trouble loading the preview. Please refresh the page. If that doesn't help email help@column.us and we will assist you!";
    const hidePricing = false;
    const validateField = (fieldState) => {
        const val = fieldState.value.trim();
        // Empty fields are invalid
        if (!val) {
            return false;
        }
        return true;
    };
    const handleRenderClicked = () => {
        if (!madlibState) {
            return;
        }
        const errors = madlibState.fields
            .filter(f => !validateField(f))
            .map(f => f.placeholder);
        setErrors(errors);
    };
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!exists(notice)) {
                return;
            }
            const templateSnap = yield ((_a = notice.data().adTemplate) === null || _a === void 0 ? void 0 : _a.get());
            if (!exists(templateSnap)) {
                return;
            }
            const rateSnap = yield notice.data().rate.get();
            if (!exists(rateSnap)) {
                return;
            }
            setNoticeData({
                rateSnap,
                templateSnap,
                notice: notice
            });
        });
        void fetchData();
    }, [notice === null || notice === void 0 ? void 0 : notice.id, (_c = notice === null || notice === void 0 ? void 0 : notice.data().adTemplate) === null || _c === void 0 ? void 0 : _c.id, notice === null || notice === void 0 ? void 0 : notice.data().rate.id]);
    const updatePreview = (html) => __awaiter(void 0, void 0, void 0, function* () {
        if (!html) {
            return;
        }
        try {
            if (!(noticeData === null || noticeData === void 0 ? void 0 : noticeData.templateSnap)) {
                return;
            }
            setPreviewLoading(true);
            let displayParameters = {};
            try {
                if (!exists(notice))
                    return;
                yield notice.ref.update({
                    confirmedHtml: html
                });
                const updatedNotice = yield notice.ref.get();
                displayParameters = yield requestDisplayParameters(getFirebaseContext(), updatedNotice, {
                    format: 'jpg',
                    type: 'DISPLAY_PARAMETERS',
                    url: INDESIGN_URL,
                    optimizeColumns: false
                }, window.DOMParser);
            }
            catch (err) {
                setPreviewLoading(false);
                setIDError(GENERIC_ID_ERROR);
                return;
            }
            const dbPricingObject = yield createDBPricingObject(getFirebaseContext(), noticeData.notice, displayParameters);
            setPreviewContent({
                displayParameters,
                price: dbPricingObject.total.toFixed(2)
            });
            if (displayParameters.columns && displayParameters.columns !== columns) {
                setColumns(displayParameters.columns);
            }
            setIDError('');
        }
        catch (err) {
            console.error(err);
            setIDError(GENERIC_ID_ERROR);
        }
        setPreviewLoading(false);
    });
    useEffect(() => {
        void updatePreview(madlibState === null || madlibState === void 0 ? void 0 : madlibState.renderedHtml);
    }, [debouncedRenderedHtml]);
    const getAppropriateHeader = (dates, activeOrganization) => {
        if (dates.length === 1 && activeOrganization.data().oneRunHeader)
            return activeOrganization.data().oneRunHeader;
        return activeOrganization.data().headerFormatString;
    };
    const refreshPreviewNotice = (n) => __awaiter(void 0, void 0, void 0, function* () {
        if (!exists(activeOrganization)) {
            return;
        }
        const { publicationDates } = n.data();
        const header = getAppropriateHeader(publicationDates, activeOrganization);
        const noticeType = getNoticeType(n, activeOrganization);
        const dynamicHeaders = header
            ? headers.generate(header, publicationDates, noticeType)
            : null;
        yield n.ref.update({
            adTemplate: activeOrganization.data().adTemplate,
            dynamicHeaders,
            publicationDates
        });
        const updated = yield n.ref.get();
        return updated;
    });
    const createPreviewNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        if (!exists(activeOrganization))
            return {};
        const publicationDates = [Firestore.Timestamp.fromDate(new Date())];
        const headerF = (_d = activeOrganization.data()) === null || _d === void 0 ? void 0 : _d.headerFormatString;
        const noticeType = notice
            ? getNoticeType(notice, activeOrganization)
            : null;
        const { adTemplate } = activeOrganization.data();
        if (!adTemplate)
            return {};
        const previewNoticeObj = Object.assign(Object.assign({ publicationDates, newspaper: activeOrganization.ref }, (adTemplate && { adTemplate })), { rate: (_e = activeOrganization.data()) === null || _e === void 0 ? void 0 : _e.defaultLinerRate, confirmedHtml: 'Lorem ipsum', dynamicHeaders: headerF
                ? headers.generate(headerF, publicationDates, noticeType)
                : null });
        const ref = yield ctx.previewNoticesRef().add(previewNoticeObj);
        const snap = yield ref.get();
        return snap;
    });
    const getPreviewNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!exists(activeOrganization))
            return;
        const results = yield ctx
            .previewNoticesRef()
            .where('newspaper', '==', activeOrganization.ref)
            .limit(1)
            .get();
        const n = results.empty
            ? yield createPreviewNotice()
            : yield refreshPreviewNotice(results.docs[0]);
        setNotice(n);
    });
    useEffect(() => {
        void getPreviewNotice();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    if (!exists(activeOrganization)) {
        return React.createElement(LoadingState, null);
    }
    const dataLoading = previewLoading || !noticeData;
    return (React.createElement("div", { className: "mt-4 mx-20" },
        React.createElement("div", { className: "grid grid-cols-2 gap-4" },
            React.createElement("div", null,
                React.createElement(NoticeEditorMadlib, { onEditorUpdate: state => {
                        setMadlibState(state);
                    }, templateStyles: templateStyles, columns: 1 }),
                React.createElement("div", { className: "flex flex-row gap-2 justify-end mt-4" },
                    React.createElement(ColumnButton, { primary: true, onClick: handleRenderClicked, buttonText: 'Generate Preview' }))),
            React.createElement("div", null,
                errors.length > 0 && (React.createElement("div", null,
                    React.createElement("b", null, "Missing fields:"),
                    React.createElement("ul", null, errors.map((e, i) => (React.createElement("li", { key: i, className: "text-red-500" },
                        "- ",
                        e)))))),
                dataLoading ? (React.createElement("div", { className: "w-full h-96" },
                    React.createElement(LoadingState, null))) : (React.createElement(NoticePreviewContainer, { error: idError, price: previewContent.price, displayParameters: previewContent.displayParameters, rate: noticeData.rateSnap.data(), hidePricing: hidePricing, setColumnsWide: setColumns, columnsWide: columns, newspaper: activeOrganization, disableColumnWidthControls: true }))))));
};
export default Madlib;
