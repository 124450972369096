import React from 'react';
import { connect } from 'react-redux';
import { exists } from 'lib/types';
import CampaignSettings from './CampaignSettings';
const mapStateToProps = (state) => ({
    user: state.auth.user,
    activeOrganization: state.auth.activeOrganization
});
const CampaignSettingsWrapper = ({ user, activeOrganization }) => {
    if (exists(user) && exists(activeOrganization)) {
        return (React.createElement(CampaignSettings, { user: user, activeOrganization: activeOrganization }));
    }
    return React.createElement(React.Fragment, null);
};
export default connect(mapStateToProps)(CampaignSettingsWrapper);
