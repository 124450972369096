import React from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
const AffidavitReminderModal = ({ onConfirmClicked, onBackClicked }) => {
    const handleSetOpen = (val) => {
        if (!val) {
            onBackClicked();
        }
    };
    return (React.createElement(CancelOrSubmitModal, { header: 'Affidavit Reminder', tertiaryButtonText: 'Back', primaryButtonText: 'Confirm', noExitOutsideModal: true, setOpen: handleSetOpen, onSubmit: onConfirmClicked },
        React.createElement("div", { className: "text-gray-800 py-2" },
            React.createElement("p", { className: "pb-3" }, "This notice has already passed its first publication date. Any changes you make may affect the affidavit for this notice."),
            React.createElement("p", { className: "pb-3" }, "When you create an affidavit, please verify its accuracy before submitting it to your customer. If you have already submitted an affidavit, you can upload a new one in Notice Details."),
            React.createElement("p", { className: "pb-3" },
                "Click ",
                React.createElement("b", null, "Confirm"),
                " to submit the edits to this notice."))));
};
export default AffidavitReminderModal;
