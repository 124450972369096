export const ExportFormatTypeData = {
    jpg: {
        value: 1,
        label: 'JPG',
        liner: false,
        display: true,
        key: 'jpg'
    },
    pdf: {
        value: 2,
        label: 'PDF',
        liner: true,
        display: true,
        key: 'pdf'
    },
    rtf: {
        value: 3,
        label: 'RTF',
        liner: true,
        display: false,
        key: 'rtf'
    },
    idml: {
        value: 4,
        label: 'IDML',
        liner: true,
        display: false,
        key: 'idml'
    }
};
