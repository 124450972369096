export var TemplateTypes;
(function (TemplateTypes) {
    TemplateTypes[TemplateTypes["legal"] = 1] = "legal";
    TemplateTypes[TemplateTypes["public"] = 2] = "public";
})(TemplateTypes || (TemplateTypes = {}));
/**
 * Retreive a unique ID that changes when we update the template styles.
 * @param template template we need to retreive the ID from
 * @returns unique ID that can be used to bust caches in the ID server
 */
export const getAdTemplateCacheID = (template) => {
    const { styles } = template.data();
    return (styles === null || styles === void 0 ? void 0 : styles.id) || template.id;
};
