import React, { useState } from 'react';
import { Drawer, CssBaseline, Divider, List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import GroupAdd from '@material-ui/icons/GroupAdd';
import { withStyles, createStyles } from '@material-ui/core/styles';
import PressAssociationInfoSettings from './PressAssociationInfoSettings';
import OrganizationMemberSettings from '../OrganizationMemberSettings';
import AssociationMemberSettings from './AssociationMemberSettings';
import CustomBrandSettings from '../CustomBrandSettings';
const drawerWidth = 35;
const styles = (theme) => createStyles({
    root: {
        display: 'flex'
    },
    drawer: {
        width: theme.spacing(drawerWidth),
        flexShrink: 0
    },
    drawerPaper: {
        width: theme.spacing(drawerWidth)
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
});
const GENERAL_INFO = 'general-info';
const ORGANIZATION_MEMBER_SETTINGS = 'members';
const CUSTOM_BRAND = 'brand';
const ASSOCIATION_MEMBERS = 'association-members';
const PressAssociationSettings = ({ activeOrganization, classes, user }) => {
    const [viewNum, setViewNum] = useState(GENERAL_INFO);
    return (React.createElement("div", { className: classes.root },
        React.createElement(CssBaseline, null),
        React.createElement(Drawer, { className: classes.drawer, variant: "permanent", classes: {
                paper: classes.drawerPaper
            } },
            React.createElement("div", { className: classes.toolbar }),
            React.createElement(List, null,
                React.createElement(MenuItem, { button: true, key: "governmentInfo", selected: viewNum === GENERAL_INFO, onClick: () => setViewNum(GENERAL_INFO) },
                    React.createElement(ListItemIcon, null,
                        React.createElement(HomeIcon, null)),
                    React.createElement(ListItemText, { primary: "Association Info" })),
                React.createElement(MenuItem, { button: true, key: "memberInfo", selected: viewNum === ORGANIZATION_MEMBER_SETTINGS, onClick: () => setViewNum(ORGANIZATION_MEMBER_SETTINGS) },
                    React.createElement(ListItemIcon, null,
                        React.createElement(PeopleIcon, null)),
                    React.createElement(ListItemText, { primary: "Members" })),
                React.createElement(Divider, null),
                React.createElement(MenuItem, { button: true, key: "Custom Branding", selected: viewNum === CUSTOM_BRAND, onClick: () => setViewNum(CUSTOM_BRAND) },
                    React.createElement(ListItemIcon, null,
                        React.createElement(ColorLensIcon, null)),
                    React.createElement(ListItemText, { primary: "Custom Branding" })),
                React.createElement(Divider, null),
                React.createElement(MenuItem, { button: true, key: "Association Members", selected: viewNum === ASSOCIATION_MEMBERS, onClick: () => setViewNum(ASSOCIATION_MEMBERS) },
                    React.createElement(ListItemIcon, null,
                        React.createElement(GroupAdd, null)),
                    React.createElement(ListItemText, { primary: "Association Members" })),
                React.createElement(Divider, null))),
        React.createElement("main", { className: classes.content }, {
            [GENERAL_INFO]: (React.createElement(PressAssociationInfoSettings, { activeOrganization: activeOrganization })),
            [ORGANIZATION_MEMBER_SETTINGS]: (React.createElement(OrganizationMemberSettings, { user: user, activeOrganization: activeOrganization })),
            [CUSTOM_BRAND]: (React.createElement(CustomBrandSettings, { activeOrganization: activeOrganization, user: user })),
            [ASSOCIATION_MEMBERS]: (React.createElement(AssociationMemberSettings, { activeOrganization: activeOrganization }))
        }[viewNum])));
};
export default withStyles(styles)(PressAssociationSettings);
