import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import AuthActions from 'redux/auth';
import NoticeActions from 'redux/notices';
import { OccupationType, OrganizationType } from 'lib/enums';
import { AddIcon, ArrowDownIcon } from 'icons';
import { ACTIVE_NOTICES_TAB } from 'routes/userNoticeList/table/types';
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        availableOrganizations: state.auth.availableOrganizations,
        activeOrganization: (_a = state.auth.activeOrganization) !== null && _a !== void 0 ? _a : undefined,
        user: (_b = state.auth.user) !== null && _b !== void 0 ? _b : undefined,
        showAllOrgsNotices: state.auth.showAllOrgsNotices,
        pathname: state.router.location.pathname
    });
};
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    noticeActions: bindActionCreators(NoticeActions, dispatch),
    push: (path) => dispatch(push(path))
});
const ALL_ORGANIZATIONS = 'all-organizations';
export const formatPaperName = (name) => {
    if (name.startsWith('The ')) {
        return name.replace('The ', '');
    }
    return name;
};
const ActiveOrganizationSelect = ({ authActions, noticeActions, activeOrganization, availableOrganizations, push, user, showAllOrgsNotices, open, setOpen, pathname }) => {
    var _a, _b, _c;
    const [selectedOrg, setSelectedOrg] = useState('');
    useEffect(() => {
        if (activeOrganization) {
            setSelectedOrg(activeOrganization.id);
        }
    }, [activeOrganization]);
    const shouldShowAllOrgs = user && availableOrganizations && availableOrganizations.length > 1;
    const renderMenu = () => {
        if (open)
            return (React.createElement("div", { className: `w-max z-20 border origin-top-right absolute right-1 mt-2 max-w-xs md:max-w-sm lg:max-w-xl rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 overflow-scroll`, style: { maxHeight: '92.5vh' } },
                availableOrganizations
                    .sort((o1, o2) => formatPaperName(o1.data().name) < formatPaperName(o2.data().name)
                    ? -1
                    : 1)
                    .map(o => (React.createElement("div", { key: o.id, className: `w-full block px-4 py-2 text-sm font-normal text-gray-750 ${selectedOrg === o.id ? 'bg-gray-100' : 'hover:bg-gray-100'} cursor-pointer`, onClick: () => {
                        setSelectedOrg(o.id);
                        authActions.setActiveOrganization(o);
                        if (showAllOrgsNotices)
                            authActions.showAllOrgsNotices(false);
                        noticeActions.setTab(ACTIVE_NOTICES_TAB);
                        noticeActions.setCurrentPage(0);
                        const { organizationType } = o.data();
                        if (organizationType !==
                            OrganizationType.holding_company.value &&
                            pathname !== '/notices/')
                            push('/notices/');
                        else if (organizationType ===
                            OrganizationType.holding_company.value &&
                            pathname !== '/reports/') {
                            push('/reports/');
                        }
                        setOpen(false);
                    } }, formatPaperName(o.data().name)))),
                shouldShowAllOrgs && (React.createElement("div", { className: `w-full block px-4 py-2 text-sm font-normal text-gray-750 ${selectedOrg === ALL_ORGANIZATIONS
                        ? 'bg-gray-100'
                        : 'hover:bg-gray-100'} cursor-pointer`, onClick: () => {
                        setSelectedOrg(ALL_ORGANIZATIONS);
                        authActions.showAllOrgsNotices(true);
                        if (window.location.pathname !== '/notices/')
                            push('/notices/');
                        setOpen(false);
                    } }, "All Organizations")),
                (user === null || user === void 0 ? void 0 : user.data().occupation) === OccupationType.publishing.value && (React.createElement("div", { "data-testid": "add-publication-button", className: `w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer flex items-center`, onClick: () => push('/register/organization?type=sub-org') },
                    React.createElement(AddIcon, { className: "w-4 h-4 mr-1" }),
                    "Add Publication"))));
    };
    return availableOrganizations.length ? (React.createElement("div", { className: "ml-2", "data-testid": "active-org" }, (user === null || user === void 0 ? void 0 : user.data().occupation) !== OccupationType.publishing.value &&
        availableOrganizations.length === 1 ? (React.createElement("div", { className: "bg-white p-1 rounded-full text-gray-700 focus:outline-none" }, formatPaperName(((_b = (_a = availableOrganizations[0]) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.name) || ''))) : (React.createElement("div", { className: "ml-2 relative" },
        React.createElement("div", null,
            React.createElement("button", { className: "max-w-xs md:max-w-sm lg:max-w-xl inline-block relative bg-white p-1 rounded-full text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center", onClick: () => setOpen(!open), id: "activeOrg", "data-testid": "active-org-select" },
                React.createElement("span", { className: "sr-only" }, "View organizations"),
                showAllOrgsNotices ? ('All Organizations') : (React.createElement("span", { className: "truncate" }, formatPaperName(((_c = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.name) || ''))),
                React.createElement(ArrowDownIcon, { "data-testid": "arrow-dropdown", className: "ml-2 mt-1" }))),
        renderMenu())))) : null;
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrganizationSelect);
