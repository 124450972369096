var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Paper, Avatar, Typography, Divider, Box } from '@material-ui/core';
import { InvoiceStatus } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import { push } from 'connected-react-router';
import { logAndCaptureMessage } from 'utils';
import { reload } from 'utils/reload';
import InvoiceTable from './components/InvoiceTable';
import PayInvoiceCreditCard from './components/PayInvoiceCreditCard';
import InvoiceStatusInfo from './components/InvoiceStatusInfo';
import { LatePaymentWarning } from './components/LatePaymentWarning';
import PayInvoiceButton from './components/buttons/PayInvoiceButton';
import PayWithCheckInfo from './components/PayWithCheckInfo';
import { PayInvoicePaymentMethods } from './components/PayInvoicePaymentMethods';
import { processPaymentWithSavedMethod } from './helpers/processInvoicePayment';
const styles = (theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(12),
        [theme.breakpoints.up(300 + theme.spacing(6))]: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        backgroundColor: '#F9FAFB' // background color of pay invoice screen
    },
    paper: {
        flexGrow: 6
    },
    leftBigBox: {
        position: 'absolute',
        width: theme.spacing(30),
        height: theme.spacing(40),
        background: '#EBF5FB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(30),
        left: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    leftSmallBox: {
        position: 'absolute',
        width: theme.spacing(25),
        height: theme.spacing(35),
        background: '#2D9BDB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(40),
        left: theme.spacing(5),
        opacity: '0.1',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    rightBigBox: {
        position: 'absolute',
        width: theme.spacing(30),
        height: theme.spacing(40),
        background: '#EBF5FB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(10),
        right: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    rightSmallBox: {
        position: 'absolute',
        width: theme.spacing(25),
        height: theme.spacing(35),
        background: '#2D9BDB',
        borderRadius: '26px',
        transform: 'skewY(-30deg)',
        top: theme.spacing(20),
        right: '0px',
        opacity: '0.1',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    avatar: {
        margin: theme.spacing(1),
        position: 'absolute',
        marginTop: theme.spacing(-4),
        width: theme.spacing(8),
        height: theme.spacing(8),
        boxShadow: '4',
        '& img': {
            height: '40px',
            width: '45px'
        }
    },
    invoiceStateSection: {
        width: '100%',
        backgroundColor: '#FAFCFE' // adds a light gray to where we have our payment methods listed
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    footerText: {
        fontSize: '14px',
        color: '#697386' // gives the footer text a dark gray text
    }
});
function PayInvoicePage({ payInvoiceData, invoicePricingData, savedPaymentsAccess, user, classes }) {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [selectedSavedMethodId, setSelectedSavedMethodId] = useState();
    // TODO: Refactor for centralized modal control rather than individual props
    // https://github.com/enotice-io/enotice-app/pull/13981#discussion_r1068771356
    const [showLoginModal, setShowLoginModal] = useState();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const { billingName, invoice, preventLatePayment, requireUpfrontPayment } = payInvoiceData;
    const { status: invoiceStatus, invoice_number: invoiceNumber, invoice_pdf: invoicePDFPath } = invoice.data();
    const selectPaymentClickHandler = (paymentMethod, savedMethodId) => {
        if (paymentMethod === selectedPaymentMethod &&
            savedMethodId === selectedSavedMethodId) {
            // Clicked again, so deselect option
            setSelectedPaymentMethod(undefined);
            setSelectedSavedMethodId(undefined);
        }
        else {
            // Select option
            setSelectedPaymentMethod(paymentMethod);
            setSelectedSavedMethodId(savedMethodId);
        }
    };
    const handleSuccessfulPayment = () => {
        reload(false);
    };
    const savedMethodPayInvoiceClickHandler = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            if (selectedPaymentMethod && selectedSavedMethodId)
                yield processPaymentWithSavedMethod(selectedPaymentMethod, selectedSavedMethodId, payInvoiceData, invoicePricingData, user);
            handleSuccessfulPayment();
        }
        catch (err) {
            const userMessage = typeof err === 'string'
                ? err
                : 'There was a problem processing your payment';
            logAndCaptureMessage(err.message || err);
            setErr(userMessage);
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(Box, { className: classes.container },
        React.createElement(Box, { className: classes.flex },
            React.createElement(Box, { className: classes.leftBigBox }),
            React.createElement(Box, { className: classes.leftSmallBox })),
        React.createElement(Box, { className: classes.flex },
            React.createElement(Box, { className: classes.rightBigBox }),
            React.createElement(Box, { className: classes.rightSmallBox })),
        React.createElement("main", { className: classes.main },
            React.createElement(LatePaymentWarning, { requireUpfrontPayment: requireUpfrontPayment, preventLatePayment: preventLatePayment, invoiceStatus: invoiceStatus }),
            React.createElement(Paper, { className: `${classes.paper} ${classes.flex}` },
                React.createElement(Avatar, { className: classes.avatar, style: { boxShadow: '0.5px 0.5px 3px', background: 'white' }, src: "/android-chrome-512x512.png" }),
                React.createElement(Box, { ml: 1 },
                    React.createElement(Box, { mt: 4 },
                        React.createElement(Typography, { style: { fontSize: 20 }, align: "center" }, "Invoice from Column")),
                    React.createElement(Typography, { variant: "caption", align: "center", display: "block", color: "textSecondary" },
                        "Billed to ",
                        billingName),
                    React.createElement(Typography, { variant: "caption", align: "center", display: "block", color: "textSecondary" },
                        "Invoice #",
                        invoiceNumber ? invoiceNumber.toUpperCase() : 'NaN')),
                React.createElement(Box, { mt: 4, mb: 2, className: classes.invoiceStateSection },
                    React.createElement(InvoiceStatusInfo, { invoice: invoice, invoicePricingData: invoicePricingData }),
                    React.createElement(Box, { mb: 2, className: classes.flex }, (invoiceStatus === InvoiceStatus.unpaid.value ||
                        invoiceStatus === InvoiceStatus.payment_failed.value) && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "caption", align: "center", color: "textSecondary" }, "Choose how you'd like to pay."),
                        React.createElement(Box, { mt: 3, mb: 2, width: 345 },
                            React.createElement(PayInvoicePaymentMethods, { payInvoiceData: payInvoiceData, savedPaymentsAccess: savedPaymentsAccess, selectedPaymentMethod: selectedPaymentMethod, selectedSavedMethodId: selectedSavedMethodId, user: user, selectPaymentClickHandler: selectPaymentClickHandler, setShowLoginModal: setShowLoginModal }),
                            React.createElement(Box, { mt: 4 },
                                selectedPaymentMethod === 'card' && (React.createElement(PayInvoiceCreditCard, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, disableSavedCards: savedPaymentsAccess.disableSavedCards, handleSuccessfulPayment: handleSuccessfulPayment })),
                                selectedPaymentMethod === 'check' && (React.createElement(PayWithCheckInfo, { invoiceNumber: invoiceNumber, invoicePDFPath: invoicePDFPath })),
                                selectedPaymentMethod &&
                                    selectedSavedMethodId &&
                                    selectedPaymentMethod.startsWith('saved-') && (React.createElement(PayInvoiceButton, { loading: loading, type: 'button', disabled: loading, id: "pay-invoice-stripe", clickHandler: savedMethodPayInvoiceClickHandler }))),
                            err && (React.createElement(Box, { mt: 1, className: classes.flex },
                                React.createElement(Typography, { color: "error", variant: "caption" }, err))),
                            showLoginModal && (React.createElement(TailwindModal, { header: "You must be logged in", body: showLoginModal, close: () => setShowLoginModal(undefined), buttonText: "Log into Column", onButtonClick: () => {
                                    push(`/login/?redirect=/invoices/${invoice.id}/pay`);
                                } })))))),
                    React.createElement(Divider, null)),
                React.createElement(InvoiceTable, { invoice: invoice, invoicePricingData: invoicePricingData }),
                React.createElement(Divider, { style: { width: '100%' } }),
                React.createElement(Box, { ml: 4, mr: 4, mt: 2, mb: 2, className: classes.flex },
                    React.createElement(Typography, { className: classes.footerText, align: "center" },
                        "If you have any questions, contact Column at",
                        ' ',
                        React.createElement("span", { style: { color: '#5469D4' } }, "help@column.us"),
                        "."))))));
}
export default withStyles(styles)(PayInvoicePage);
