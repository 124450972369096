var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';
import TailwindModal from 'components/TailwindModal';
import { RoleType } from 'lib/enums';
import PasswordField from 'components/PasswordField';
import { getRole } from 'utils/permissions';
import { dwollaInvalidPasswordMessage, dwollaPasswordValidation, passwordValidation } from 'lib/passwordValidators';
const ChangePassword = ({ userAuth, user, closeChangePasswordModal, setShowPasswordResetModal }) => {
    var _a, _b;
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorAuth, setErrorAuth] = useState('');
    const [errorMatch, setErrorMatch] = useState(false);
    const [errorNewPassword, setErrorNewPassword] = useState('');
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [userActiveOrg, setUserActiveOrg] = useState();
    const inputTextStyle = 'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';
    const getDwollaUser = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const userOrg = (yield ((_d = (_c = user === null || user === void 0 ? void 0 : user.data()) === null || _c === void 0 ? void 0 : _c.activeOrganization) === null || _d === void 0 ? void 0 : _d.get()));
        setUserActiveOrg(userOrg);
    });
    useEffect(() => {
        void getDwollaUser();
    }, []);
    const role = getRole(user);
    // TODO(IT-4361): Figure out how this relates to roles?
    const isDwollaUser = [RoleType.admin.value, RoleType.billing.value].includes(role) &&
        (((_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.dwollaPasswordSecurityEnabled) ||
            ((_b = userActiveOrg === null || userActiveOrg === void 0 ? void 0 : userActiveOrg.data()) === null || _b === void 0 ? void 0 : _b.dwollaPaymentEnabled));
    const matchPassword = () => {
        if (newPassword && confirmNewPassword) {
            if (isDwollaUser && !dwollaPasswordValidation.test(newPassword)) {
                setDisabled(true);
                return;
            }
            if (!passwordValidation.test(newPassword)) {
                setDisabled(true);
                return;
            }
            if (newPassword.length === confirmNewPassword.length &&
                newPassword === confirmNewPassword) {
                setErrorMatch(false);
                if (newPassword.length > 8) {
                    setDisabled(false);
                    return true;
                }
            }
            else {
                setErrorMatch(true);
                setDisabled(true);
                return false;
            }
        }
    };
    const changePassword = () => {
        if (matchPassword()) {
            const { currentUser } = Firebase.auth();
            if (!currentUser || !currentUser.email) {
                console.error(`Current user does not exist ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.uid}`);
                return;
            }
            setLoading(true);
            setDisabled(true);
            const credentials = FirebaseAuth.EmailAuthProvider.credential(currentUser.email, currentPassword);
            userAuth
                .reauthenticateWithCredential(credentials)
                .then(() => {
                currentUser
                    .updatePassword(newPassword)
                    .then(() => {
                    setPasswordUpdated(true);
                    setLoading(false);
                })
                    .catch((error) => {
                    setErrorAuth(error.message);
                    setLoading(false);
                    setDisabled(false);
                });
            })
                .catch((error) => {
                setErrorAuth(error.message);
                setLoading(false);
                setDisabled(false);
            });
        }
    };
    useEffect(() => {
        matchPassword();
    }, [newPassword, confirmNewPassword]);
    useEffect(() => {
        if (passwordUpdated) {
            setShowPasswordResetModal(true);
            closeChangePasswordModal();
        }
    }, [passwordUpdated]);
    useEffect(() => {
        if (newPassword) {
            if (isDwollaUser) {
                if (!dwollaPasswordValidation.test(newPassword)) {
                    setErrorNewPassword(dwollaInvalidPasswordMessage);
                    setDisabled(true);
                    return;
                }
            }
            else {
                if (newPassword.length < 9) {
                    setErrorNewPassword('Password must be at least 9 characters');
                    return;
                }
                if (!passwordValidation.test(newPassword)) {
                    setErrorNewPassword('Password must contain a number or a special character');
                    return;
                }
            }
            setErrorNewPassword('');
        }
    }, [newPassword]);
    return (React.createElement(TailwindModal, { header: "Change your password", close: () => closeChangePasswordModal(), widthPct: 30 },
        React.createElement(React.Fragment, null,
            React.createElement(PasswordField, { id: "currentPassword", required: true, value: currentPassword, className: inputTextStyle, placeHolderText: "Current password *", onValueChange: (e) => setCurrentPassword(e.target.value), "data-testid": "password" }),
            errorAuth && (React.createElement("div", { className: "text-red-600 text-sm ml-1" }, errorAuth)),
            React.createElement(PasswordField, { id: "password", required: true, value: newPassword, className: inputTextStyle, placeHolderText: "New password *", onValueChange: (e) => setNewPassword(e.target.value), "data-testid": "newPassword" }),
            errorNewPassword && (React.createElement("div", { className: "text-red-600 text-sm ml-1" }, errorNewPassword)),
            React.createElement(PasswordField, { id: "confirmNewPassword", required: true, value: confirmNewPassword, className: inputTextStyle, placeHolderText: "Confirm new password *", onValueChange: (e) => setConfirmNewPassword(e.target.value), "data-testid": "confirm-new-password" }),
            errorMatch && (React.createElement("div", { className: "text-red-600 text-sm ml-1" }, "Passwords do not match")),
            React.createElement("button", { className: `rounded-md font-semibold text-sm flex items-center px-5 py-2 mt-4 ${isDisabled || loading
                    ? 'bg-gray-200 focus:border-gray-500 text-gray-700'
                    : 'bg-blue-500 bg-opacity-25 text-blue-600'}`, type: "button", id: "submit", disabled: isDisabled || loading, onClick: () => changePassword() },
                React.createElement("span", { className: "flex" },
                    loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                    "Set New Password")))));
};
export default ChangePassword;
