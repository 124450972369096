var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnButton } from 'components/ColumnButton';
import { TextField } from 'components/TextField';
import ColumnSelect from 'components/select/ColumnSelect';
import { RateType } from 'lib/enums';
import { Label } from 'components/Label';
import { XIcon } from 'icons';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import SettingsUpdateCard from '../../../SettingsUpdateCard';
import FormInputWrapper from '../../../FormInputWrapper';
import FormSwitch from '../../../FormSwitch';
import { centsToExtendedCurrency, rateDataSupportsDisplay, currencyToCents } from '../ratesTableSettingsUtils';
const enumKeyToSelectInput = (enumItem) => ({
    value: enumItem.value.toString(),
    label: enumItem.label
});
/**
 * Full-screen form to update properties associated with a rate
 */
export default function RateUpdateForm({ closeForm, rateData, rateRef }) {
    const [updatedRateData, setUpdatedRateData] = useState(rateData);
    const [updating, setUpdating] = useState(false);
    const edited = safeStringify(updatedRateData) !== safeStringify(rateData);
    const updateRateProperties = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        try {
            let rateRefToUpdate = rateRef;
            // this runs if we are creating a new rate
            if (!rateRefToUpdate) {
                rateRefToUpdate = yield getFirebaseContext()
                    .ratesRef()
                    .add(Object.assign({}, updatedRateData));
            }
            yield rateRefToUpdate.update(updatedRateData);
            closeForm();
        }
        catch (err) {
            logAndCaptureException(err, 'Unable to update rate properties');
        }
        setUpdating(false);
    });
    // Automatically turn off supporting display if the rate type is line-based
    useEffect(() => {
        if (!rateDataSupportsDisplay(updatedRateData)) {
            setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { supportsDisplay: false }));
        }
    }, [updatedRateData.rateType]);
    // Enable saving instantly for new rates, and after edit for existing rates
    const disableSave = rateRef && (updating || !edited);
    return (React.createElement("div", { className: "fixed overflow-scroll z-100 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out" },
        React.createElement("div", { className: "bg-gray-80 h-screen" },
            React.createElement("div", { className: "flex justify-between py-4 px-6 bg-white border border-grey-100 sticky top-0" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement(ColumnButton, { startIcon: React.createElement(XIcon, { className: "h-6 w-6" }), size: "md", onClick: closeForm }),
                    React.createElement("span", { className: "pl-4 text-gray-800 font-semibold text-xl" }, "Edit Rate")),
                React.createElement(ColumnButton, { buttonText: rateRef ? 'Save' : 'Create Rate', disabled: disableSave, onClick: updateRateProperties, primary: true, size: "lg" })),
            React.createElement(SettingsUpdateCard, { header: "Basic Properties", subheader: "Configure basic properties about your rate." },
                React.createElement(FormInputWrapper, null,
                    React.createElement(TextField, { id: "rate-name", labelText: "Rate Name", value: updatedRateData.description, onChange: newValue => setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { description: newValue })) })),
                React.createElement(FormInputWrapper, { className: "flex flex-col" },
                    React.createElement("div", { className: "pb-2" },
                        React.createElement(Label, { id: "rate-type-label" }, "Rate Type")),
                    React.createElement(ColumnSelect, { className: "flex-1 text-md", options: [
                            enumKeyToSelectInput(RateType.column_inch),
                            enumKeyToSelectInput(RateType.line),
                            enumKeyToSelectInput(RateType.flat),
                            enumKeyToSelectInput(RateType.word_count),
                            enumKeyToSelectInput(RateType.folio),
                            enumKeyToSelectInput(RateType.per_run)
                        ], onChange: newValue => {
                            const newRateType = RateType.by_value(parseInt(newValue, 10));
                            if (!newRateType)
                                return;
                            setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { rateType: newRateType.value }));
                        }, value: updatedRateData.rateType.toString() })),
                React.createElement(FormInputWrapper, { className: "flex flex-col" },
                    React.createElement("div", { className: "pb-2" },
                        React.createElement(Label, { id: "rate-type-label" }, "Supports Display Ads")),
                    React.createElement(ColumnSelect, { className: "flex-1 text-md", options: [
                            {
                                value: 'false',
                                label: 'Use this rate for text-based ads only'
                            }
                        ].concat([
                            ...(rateDataSupportsDisplay(updatedRateData)
                                ? [
                                    {
                                        value: 'true',
                                        label: 'Use this rate for text-based ads and ads with images'
                                    }
                                ]
                                : [])
                        ]), onChange: newValue => {
                            setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { supportsDisplay: newValue === 'true' }));
                        }, value: Boolean(updatedRateData.supportsDisplay).toString() })),
                React.createElement(FormInputWrapper, null,
                    React.createElement(TextField, { id: "round-off", labelText: "Round Off (in inches)", type: "number", placeholder: "0.00", value: String(updatedRateData.roundOff) || '', onChange: newValue => setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { roundOff: parseFloat(newValue) })), min: 0, step: 0.01 })),
                React.createElement(FormInputWrapper, { className: "flex flex-col" },
                    React.createElement("div", { className: "pb-2" },
                        React.createElement(Label, { id: "rate-type-label" }, "Run Based")),
                    React.createElement(ColumnSelect, { className: "flex-1 text-md", options: [
                            {
                                value: 'false',
                                label: 'Charge the same rate per run'
                            },
                            {
                                value: 'true',
                                label: 'Charge different rates per run'
                            }
                        ], onChange: newValue => {
                            setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { runBased: newValue === 'true' }));
                        }, value: Boolean(updatedRateData.runBased).toString() })),
                React.createElement(FormInputWrapper, { className: "col-span-6" },
                    React.createElement(RateCurrencyField, { id: "minimum", labelText: "Minimum (in dollars)", updateKey: "minimum", initialValue: updatedRateData.minimum, setUpdatedRateData: setUpdatedRateData })),
                React.createElement(FormInputWrapper, null,
                    React.createElement(TextField, { id: "rate-code", labelText: "Rate Code", type: "number", value: updatedRateData.code.toFixed(0), disabled: true })),
                React.createElement(FormInputWrapper, null,
                    React.createElement(RateCurrencyField, { id: "rate-0", labelText: "1 Run Rate (in dollars)", updateKey: "rate_0", initialValue: updatedRateData.rate_0, setUpdatedRateData: setUpdatedRateData })),
                React.createElement(FormInputWrapper, null,
                    React.createElement(RateCurrencyField, { id: "rate-1", labelText: "2 Run Rate (in dollars)", updateKey: "rate_1", initialValue: updatedRateData.rate_1, setUpdatedRateData: setUpdatedRateData })),
                React.createElement(FormInputWrapper, { className: "col-span-6" },
                    React.createElement(RateCurrencyField, { id: "rate-2", labelText: "3 Run Rate (in dollars)", updateKey: "rate_2", initialValue: updatedRateData.rate_2, setUpdatedRateData: setUpdatedRateData }))),
            React.createElement(SettingsUpdateCard, { header: "Additional Fees", subheader: "Configure additional fees for your rate." },
                React.createElement(FormInputWrapper, { className: "col-span-1 sm:col-span-6 flex items-center" },
                    React.createElement(FormSwitch, { checked: !!updatedRateData.additionalFee, onChange: () => {
                            if (!updatedRateData.additionalFee) {
                                setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { additionalFee: {
                                        description: 'Additional Fee',
                                        amount: 0
                                    } }));
                            }
                            else {
                                const { additionalFee } = updatedRateData, rest = __rest(updatedRateData, ["additionalFee"]);
                                setUpdatedRateData(Object.assign({ additionalFee: null }, rest));
                            }
                        } }),
                    React.createElement("div", { className: "text-grey-600 ml-3" }, "Enabled")),
                updatedRateData.additionalFee && (React.createElement(React.Fragment, null,
                    React.createElement(FormInputWrapper, null,
                        React.createElement(TextField, { id: "additional-fee-description", labelText: "Fee Description (this will show up on invoices)", value: updatedRateData.additionalFee.description, onChange: newValue => setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { additionalFee: Object.assign(Object.assign({}, updatedRateData.additionalFee), { description: newValue }) })) })),
                    React.createElement(FormInputWrapper, null,
                        React.createElement(TextField, { id: "additional-fee-amount", labelText: "Additional Fee Amount (in dollars)", type: "currency", placeholder: "0.00", value: centsToExtendedCurrency(updatedRateData.additionalFee.amount), onChange: newValue => setUpdatedRateData(updatedRateData => (Object.assign(Object.assign({}, updatedRateData), { additionalFee: Object.assign(Object.assign({}, updatedRateData.additionalFee), { amount: currencyToCents(newValue) }) }))) }))))))));
}
function RateCurrencyField({ id, initialValue, labelText, updateKey, setUpdatedRateData }) {
    const [value, setValue] = useState(centsToExtendedCurrency(initialValue));
    return (React.createElement(TextField, { id: id, labelText: labelText, type: "number", placeholder: "0.00", value: value, onChange: newValue => {
            setValue(newValue);
            setUpdatedRateData(updatedRateData => (Object.assign(Object.assign({}, updatedRateData), { [updateKey]: currencyToCents(newValue) })));
        }, min: 0, step: 0.0001 }));
}
