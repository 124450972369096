import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
/**
 * Splits the header and subsequent items in the activity log. This component only appears when the events have been loaded.
 * @returns
 */
function HorizontalDivider() {
    return (React.createElement("div", { className: "relative mb-4" },
        React.createElement("div", { className: "absolute inset-0 flex items-center", "aria-hidden": "true" },
            React.createElement("div", { className: "w-full border-t border-gray-200" }))));
}
/**
 * Manages external links in the activity log. This component only appears when events have an external link.
 */
function EventSeeMoreLink({ link }) {
    return (React.createElement("a", { className: "underline ml-1", href: link.url, target: "_blank", rel: "noreferrer" }, link.text));
}
function ActivityLogEvent({ timezone, content, header, index, event }) {
    return (React.createElement(Tooltip, { title: moment(event.timestamp.toMillis())
            .tz(timezone || 'America/Chicago')
            .format('LT'), placement: "right" },
        React.createElement("li", { className: classNames(index > 0 && 'pt-4', 'pb-4 text-gray-800 flex justify-between align-top'), key: index },
            React.createElement("div", { className: "flex" },
                React.createElement("div", { className: "flex-1 space-y-1" },
                    header && (React.createElement("h3", { className: "text-sm text-gray-800 font-medium" }, header)),
                    React.createElement("p", { className: "text-sm text-gray-800 opacity-80" },
                        content,
                        event.link && React.createElement(EventSeeMoreLink, { link: event.link })))),
            React.createElement("div", { className: "text-sm -mt-1 flex-none" }, moment(event.timestamp.toMillis())
                .tz(timezone || 'America/Chicago')
                .format('MMM. D')))));
}
/**
 * Events that are initiated by a user, like cancelling a notice or creating an invoice
 */
function InitiatedActivityLogEvent({ event, index, timezone }) {
    return (React.createElement(ActivityLogEvent, { timezone: timezone, content: event.content, header: event.initiator, event: event, index: index }));
}
/**
 * Events that are initiated automatically like automated affidavit reconciliation
 */
function SystemActivityLogEvent({ event, index, timezone }) {
    return (React.createElement(ActivityLogEvent, { timezone: timezone, content: event.content, header: null, event: event, index: index }));
}
function ActivityLogEvents({ events, timezone }) {
    return (React.createElement("div", { className: "row row-span-1 col-span-4" },
        React.createElement("section", { className: "grid" },
            React.createElement("div", { className: classNames(!events && 'flex items-center justify-center', 'outline-none row-span-1 pb-8 pt-2 h-75vh overflow-y-auto hide-scrollbar'), style: { scrollbarWidth: 'none', msOverflowStyle: 'none' } },
                React.createElement(HorizontalDivider, null),
                React.createElement("ul", { className: "divide-y divide-gray-200" }, events.map((event, index) => event.initiator ? (React.createElement(InitiatedActivityLogEvent, { timezone: timezone, event: event, index: index, key: `${index}-${event.type}` })) : (React.createElement(SystemActivityLogEvent, { timezone: timezone, event: event, index: index, key: `${index}-${event.type}` }))))))));
}
export default ActivityLogEvents;
