import { Box, Grid } from '@material-ui/core';
import { CheckCircle, ExclamationCircle } from 'icons';
import React from 'react';
import classNames from 'classnames';
export function CustomerValidationMessage({ customerValidationMessage, messageColor }) {
    return (React.createElement(Grid, { item: true },
        React.createElement(Box, null,
            React.createElement("div", { className: "text-sm" },
                React.createElement("div", { className: "flex flex-row mt-2 space-x-2" },
                    React.createElement("div", { className: classNames('flex flex-row items-center rounded-md align-middle min-h-11 w-full space-x-2 py-1.5 pr-3', {
                            'bg-green-50': messageColor === 'green',
                            'bg-yellow-50': messageColor === 'yellow',
                            'bg-red-50': messageColor === 'red'
                        }) },
                        React.createElement("div", { className: "flex flex-row items-center", id: "customer-validation-icon" },
                            messageColor === 'green' && (React.createElement(CheckCircle, { className: "text-green-625 h-8 w-8 pl-3" })),
                            messageColor === 'yellow' && (React.createElement(ExclamationCircle, { className: "text-yellow-700 h-8 w-8 pl-3" })),
                            messageColor === 'red' && (React.createElement(ExclamationCircle, { className: "text-red-700 h-8 w-8 pl-3" }))),
                        React.createElement("div", { className: classNames('flex items-center', {
                                'text-green-625': messageColor === 'green',
                                'text-yellow-700': messageColor === 'yellow',
                                'text-red-700': messageColor === 'red'
                            }), id: "customer-validation-message" }, customerValidationMessage)))))));
}
