var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import Firebase from 'EnoticeFirebase';
import { sanitize } from 'utils';
import { State } from 'lib/enums';
import EToast from 'components/EToast';
import { AddImageIcon, FilledCircleIcon } from 'icons';
import SettingsHeader from '../settings/SettingsHeader';
import { DocumentDuplicate, Download } from './icons';
import { CUSTOM_BRANDING } from './CampaignSettings';
const LegalSettings = ({ donationProfile, activeOrganization, updateView }) => {
    const [fiscalSponsorshipUrl, setFiscalSponsorshipUrl] = useState();
    const [fiscalSponsorshipUploading, setFiscalSponsorshipUploading] = useState(false);
    const [w9url, setW9url] = useState();
    const [w9Uploading, setW9Uploading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [stateLegalDocs, setStateLegalDocs] = useState([]);
    const [toastText, setToastText] = useState('');
    const [w9Downloaded, setW9Downloaded] = useState(donationProfile.data().legalDocs &&
        (donationProfile.data().legalDocs[0].downloaded ||
            donationProfile.data().legalDocs[0].url));
    const [fiscalSponsorshipDownloaded, setFiscalSponsorshipDownloaded] = useState(donationProfile.data().legalDocs &&
        (donationProfile.data().legalDocs[1].downloaded ||
            donationProfile.data().legalDocs[1].url));
    const fiscalSponsorshipRef = useRef();
    const w9Ref = useRef();
    const getURLFromFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        const snapshot = yield Firebase.storage()
            .ref()
            .child(`donation-profile/${donationProfile.id}/${sanitize(file.name)}`)
            .put(file);
        return yield snapshot.ref.getDownloadURL();
    });
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        if (w9url && fiscalSponsorshipUrl) {
            const legalDocs = [
                {
                    url: w9url,
                    fileName: stateLegalDocs[0].fileName,
                    downloaded: true
                },
                {
                    url: fiscalSponsorshipUrl,
                    fileName: stateLegalDocs[1].fileName,
                    downloaded: true
                }
            ];
            yield donationProfile.ref.update({
                legalDocs
            });
            setToastText('Success! To start receiving donations, ');
        }
        setSubmitting(false);
    });
    const markFiscalSponsorshipAsDownloaded = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!fiscalSponsorshipDownloaded) {
            setFiscalSponsorshipDownloaded(true);
            const { legalDocs } = donationProfile.data();
            if (legalDocs) {
                legalDocs[1].downloaded = true;
                yield donationProfile.ref.update({ legalDocs });
            }
            else {
                const newLegalDocs = [
                    {
                        url: '',
                        fileName: ''
                    },
                    {
                        url: '',
                        fileName: '',
                        downloaded: true
                    }
                ];
                yield donationProfile.ref.update({ legalDocs: newLegalDocs });
            }
        }
    });
    const markW9AsDownloaded = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!w9Downloaded) {
            setW9Downloaded(true);
            const { legalDocs } = donationProfile.data();
            if (legalDocs) {
                legalDocs[0].downloaded = true;
                yield donationProfile.ref.update({ legalDocs });
            }
            else {
                const newLegalDocs = [
                    {
                        url: '',
                        fileName: '',
                        downloaded: true
                    },
                    {
                        url: '',
                        fileName: ''
                    }
                ];
                yield donationProfile.ref.update({ legalDocs: newLegalDocs });
            }
        }
    });
    useEffect(() => {
        const { legalDocs } = donationProfile.data();
        if (legalDocs) {
            setW9url(legalDocs[0].url);
            setFiscalSponsorshipUrl(legalDocs[1].url);
        }
    }, [donationProfile]);
    useEffect(() => {
        var _a, _b;
        setStateLegalDocs((_b = (_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.donations_legal_docs) !== null && _b !== void 0 ? _b : []);
    }, [activeOrganization]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setToastText('');
        }, 15000);
        return () => clearTimeout(timer);
    }, [toastText]);
    if (!stateLegalDocs || !stateLegalDocs.length)
        return React.createElement(React.Fragment, null);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Partnership Documents", description: "Review legal documents associated with this campaign." }),
        React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
            React.createElement("div", { className: "flex flex-col w-2/6" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Fiscal sponsorship agreement*"),
                React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" },
                    "This is required before you can legally accept funds for this campaign.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Download, sign, and upload your completed agreement to proceed.")),
            React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                React.createElement("a", { className: "flex w-4/6 rounded border justify-between py-2 px-2 mb-2 cursor-pointer", href: stateLegalDocs[1].url, target: "_blank", rel: "noopener noreferrer", onClick: () => markFiscalSponsorshipAsDownloaded(), id: "download-fiscal" },
                    React.createElement("div", { className: "flex" },
                        React.createElement("span", { className: "ml-2" }, DocumentDuplicate),
                        React.createElement("span", { className: "ml-2 font-medium text-sm text-gray-800" },
                            "Download ",
                            stateLegalDocs[1].fileName)),
                    Download),
                fiscalSponsorshipUploading ? (React.createElement("div", { className: "flex justify-center items-center w-4/6 px-6 border-2 border-gray-300 border-dashed rounded-md h-32" },
                    React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }))) : (React.createElement("div", { className: `${!fiscalSponsorshipDownloaded &&
                        'cursor-not-allowed pointer-events-none'}`, id: "upload-area" },
                    React.createElement(Dropzone, { onDrop: (files) => __awaiter(void 0, void 0, void 0, function* () {
                            const pdfFile = files[0];
                            setFiscalSponsorshipUploading(true);
                            const downloadUrl = yield getURLFromFile(pdfFile);
                            setFiscalSponsorshipUrl(downloadUrl);
                            setFiscalSponsorshipUploading(false);
                        }), accept: '.pdf' }, ({ getRootProps, getInputProps }) => (React.createElement("div", Object.assign({ className: "w-4/6" }, getRootProps(), { onClick: () => {
                            var _a;
                            fiscalSponsorshipRef &&
                                ((_a = fiscalSponsorshipRef.current) === null || _a === void 0 ? void 0 : _a.click());
                        } }),
                        React.createElement("div", { className: `flex justify-center cursor-pointer items-center px-6 border-2 border-dashed rounded-md h-32 border-gray-300 ${!fiscalSponsorshipDownloaded &&
                                'cursor-not-allowed pointer-events-none'}
                    ` },
                            React.createElement("div", { className: "flex flex-col items-center text-center" },
                                fiscalSponsorshipUrl ? (React.createElement(FilledCircleIcon, { className: "mb-2 h-10 w-10 text-blue-150" })) : (React.createElement(AddImageIcon, { className: "mx-auto h-12 w-12 text-gray-400" })),
                                React.createElement(React.Fragment, null,
                                    fiscalSponsorshipUrl && (React.createElement("span", { onClick: () => window.open(fiscalSponsorshipUrl) }, stateLegalDocs[1].fileName)),
                                    React.createElement("p", { className: "mt-1 mr-1 text-sm text-gray-600" },
                                        React.createElement("button", { type: "button", className: "mr-1 font-medium text-indigo-400 focus:outline-none focus:underline transition duration-150 ease-in-out" },
                                            "Upload ",
                                            fiscalSponsorshipUrl ? 'another ' : 'a '),
                                        "PDF file of your signed agreement")),
                                React.createElement("input", Object.assign({ id: "fiscal-upload" }, getInputProps(), { ref: fiscalSponsorshipRef }))))))))))),
        React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
            React.createElement("div", { className: "flex flex-col w-2/6" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" },
                    stateLegalDocs[0].fileName.replace('.pdf', ''),
                    "*"),
                React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" },
                    "This is legally required before you can process over $250 for this campaign.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Download, sign, and upload your completed agreement to proceed.")),
            React.createElement("div", { className: `not-italic font-normal text-sm text-black w-4/6` },
                React.createElement("a", { className: "flex w-4/6 rounded border justify-between py-2 px-2 mb-2 cursor-pointer", href: stateLegalDocs[0].url, target: "_blank", rel: "noopener noreferrer", onClick: () => markW9AsDownloaded(), id: "download-w9" },
                    React.createElement("div", { className: "flex" },
                        React.createElement("span", { className: "ml-2" }, DocumentDuplicate),
                        React.createElement("span", { className: "ml-2 font-medium text-sm text-gray-800" },
                            "Download ",
                            stateLegalDocs[0].fileName)),
                    Download),
                w9Uploading ? (React.createElement("div", { className: "flex justify-center items-center w-4/6 px-6 border-2 border-gray-300 border-dashed rounded-md h-32" },
                    React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }))) : (React.createElement("div", { className: `${!w9Downloaded && 'cursor-not-allowed pointer-events-none'}` },
                    React.createElement(Dropzone, { onDrop: (files) => __awaiter(void 0, void 0, void 0, function* () {
                            const pdfFile = files[0];
                            setW9Uploading(true);
                            const downloadUrl = yield getURLFromFile(pdfFile);
                            setW9url(downloadUrl);
                            setW9Uploading(false);
                        }), accept: '.pdf' }, ({ getRootProps, getInputProps }) => (React.createElement("div", Object.assign({ className: "w-4/6" }, getRootProps(), { onClick: () => {
                            var _a;
                            w9Ref && ((_a = w9Ref.current) === null || _a === void 0 ? void 0 : _a.click());
                        } }),
                        React.createElement("div", { className: `flex justify-center cursor-pointer items-center px-6 border-2 border-dashed rounded-md h-32 border-gray-300 
                    ` },
                            React.createElement("div", { className: "flex flex-col items-center text-center" },
                                w9url ? (React.createElement(FilledCircleIcon, { className: "mb-2 h-10 w-10 text-blue-150" })) : (React.createElement(AddImageIcon, { className: "mx-auto h-12 w-12 text-gray-400" })),
                                React.createElement(React.Fragment, null,
                                    w9url && (React.createElement("span", { onClick: () => window.open(w9url) }, stateLegalDocs[0].fileName)),
                                    React.createElement("p", { className: "mt-1 mr-1 text-sm text-gray-600" },
                                        React.createElement("button", { type: "button", className: "mr-1 font-medium text-indigo-400 focus:outline-none focus:underline transition duration-150 ease-in-out" },
                                            "Upload ",
                                            w9url ? 'another ' : 'a '),
                                        "PDF file of your signed agreement")),
                                React.createElement("input", Object.assign({ id: "w9-upload" }, getInputProps(), { ref: w9Ref }))))))))))),
        React.createElement("div", { className: "flex items-center justify-end p-3 bg-gray-100 rounded-b-lg" },
            React.createElement("button", { id: "save", type: "button", className: "px-8 py-2 mr-2 flex uppercase text-white bg-blue-650 rounded font-medium text-sm", onClick: handleSubmit },
                submitting && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                "save")),
        toastText && (React.createElement(EToast, { type: "success", close: () => setToastText('') },
            React.createElement("div", { className: "flex" },
                toastText,
                React.createElement("u", { className: "cursor-pointer ml-2 contents", onClick: () => updateView(CUSTOM_BRANDING) },
                    ' ',
                    "set up your campaign page"))))));
};
export default LegalSettings;
