import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { exists } from 'lib/types';
import YourNoticesTableView from './table';
import LoadingState from '../../components/LoadingState';
const YourNotices = ({ user, isPublisher, activeOrganization }) => {
    if (!user || !exists(user)) {
        return React.createElement(LoadingState, { context: { location: 'YourNotices' } });
    }
    if (isPublisher && !activeOrganization) {
        return React.createElement(LoadingState, { context: { location: 'YourNotices' } });
    }
    return (React.createElement(React.Fragment, null, React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, direction: "column", id: "yournotices" },
            React.createElement(Box, { mt: 3 },
                React.createElement(YourNoticesTableView, { user: user }))))));
};
export default YourNotices;
