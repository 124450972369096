import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
const styles = (theme) => createStyles({
    button: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey.button,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        position: 'relative',
        justifyContent: 'flex-start',
        '& .MuiButton-startIcon': {
            marginLeft: 16,
            marginRight: theme.spacing(2),
            left: 0
        },
        '& .MuiButton-endIcon': {
            marginLeft: 16,
            marginRight: theme.spacing(2),
            right: 0
        },
        width: '100%',
        minWidth: 230
    }
});
const EButton = ({ id, classes, children, startIcon, className, disabled, onClick, component, endIcon, variant }) => {
    const classList = className
        ? `${className} ${classes.button}`
        : `${classes.button}`;
    return (React.createElement(Button, { id: id, "data-testid": id, className: classList, disabled: !!disabled, size: "medium", startIcon: startIcon, endIcon: endIcon, onClick: onClick ? () => onClick() : () => { }, component: component, variant: variant }, children));
};
export default withStyles(styles, { withTheme: true })(EButton);
