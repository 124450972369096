import { createReducer, createActions } from 'reduxsauce';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setToastText: ['toastText']
});
export const ToastTypes = Types;
export default Creators;
const INITIAL_STATE = {
    toastText: ''
};
// selector
export const valueSelector = (state) => state.toast;
/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_TOAST_TEXT]: (state, { toastText }) => (Object.assign(Object.assign({}, state), { toastText }))
});
