var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from 'bignumber.js';
import { getFirebaseContext } from 'utils/firebase';
import { RateType } from 'lib/enums';
/**
 * Determines whether or not a rate snapshot supports display ads
 * @param rate rate to test
 * @returns {boolean}
 */
export const rateDataSupportsDisplay = (rateData) => {
    if (rateData.rateType === RateType.line.value) {
        return false;
    }
    if (rateData.rateType === RateType.word_count.value) {
        return false;
    }
    if (rateData.rateType === RateType.folio.value) {
        return false;
    }
    return true;
};
/**
 * Determines whether or not a rate is one of the default liner or display rates for an organization
 * @param organization organization to check
 * @param rate rate object
 * @returns {boolean}
 */
export const isDefaultRate = (organization, rate) => {
    return [
        organization.data().defaultLinerRate.id,
        organization.data().defaultDisplayRate.id
    ].includes(rate.id);
};
/**
 * Formats inputs in a pretty fashion that may be numbers or strings. If the input is a number,
 * it will be formatted to the specified precision without trailing zeros.
 * @param value input to format
 * @param precision precision to format numeric values to
 * @returns {any} formatted value
 */
export const prettyFormatNumber = (value, precision = 4) => {
    var _a;
    if (typeof value === 'number') {
        // set the max precision of 4 decimals
        const precise = parseFloat(`${value}`).toFixed(precision);
        // strip trailing zeroes
        let withoutTrailing = precise.replace(/0+$/, '');
        // ensure we have a minimum of two decimals
        const numberOfDecimals = ((_a = withoutTrailing.split('.')[1]) === null || _a === void 0 ? void 0 : _a.length) || 0;
        for (let i = 0; i < Math.max(0, 2 - numberOfDecimals); i++) {
            withoutTrailing += '0';
        }
        return withoutTrailing;
    }
    return value;
};
/**
 * Converts cents to a string with 4 decimal places to support fractional cents.
 * Uses BigNumber to avoid floating point errors (ex. 120.32 / 100 = 1.2031999999999998)
 * Uses a custom BigNumber instance to avoid changing the global BigNumber instance.
 * @param cents
 * @returns {string} string with up to 4 decimal places
 */
export function centsToExtendedCurrency(cents) {
    const BNExtendedCurrency = BigNumber.clone({ DECIMAL_PLACES: 4 });
    return new BNExtendedCurrency(cents).dividedBy(100).toString();
}
/**
 * Converts currency string to cents.
 * Uses BigNumber to avoid floating point errors (ex. 5.1 * 100 = 509.99999999999994)
 * @param currency
 * @returns {number} number of cents
 */
export function currencyToCents(currency) {
    return new BigNumber(currency).multipliedBy(100).toNumber();
}
/**
 * Combine the results of two queries into a single array, removing duplicates.
 */
const dedupeQueryCombination = (...queries) => {
    const seen = new Set();
    const deduped = [];
    for (const query of queries) {
        for (const doc of query.docs) {
            if (!seen.has(doc.id)) {
                deduped.push(doc);
                seen.add(doc.id);
            }
        }
    }
    return deduped;
};
/**
 * Determines all of the customers that are using a rate as either their liner or display rate.
 * @param organization organization we are searching for
 * @param rate rate object we are looking at
 * @returns {Promise<ESnapshotExists<ECustomer>[]>} set of customers using the rate
 */
export const getCustomersOnRate = (organization, rate) => __awaiter(void 0, void 0, void 0, function* () {
    const customersUsingRateAsLinerQuery = yield getFirebaseContext()
        .customersRef()
        .where('organization', '==', organization.ref)
        .where('linerRate', '==', rate.ref)
        .get();
    const customersUsingRateAsDisplayQuery = yield getFirebaseContext()
        .customersRef()
        .where('organization', '==', organization.ref)
        .where('displayRate', '==', rate.ref)
        .get();
    return dedupeQueryCombination(customersUsingRateAsLinerQuery, customersUsingRateAsDisplayQuery);
});
/**
 * Determines all of the customer organizations that are using a rate as either their liner or display rate.
 * @param organization organization we are looking at
 * @param rate rate we are looking at
 * @returns {Promise<ESnapshotExists<ECustomerOrganization>[]>} set of customer organizations using the rate
 */
export const getCustomerOrgsUsingRate = (organization, rate) => __awaiter(void 0, void 0, void 0, function* () {
    const customerOrgsUsingRateAsLinerQuery = yield getFirebaseContext()
        .customerOrganizationsRef()
        .where('organization', '==', organization.ref)
        .where('linerRate', '==', rate.ref)
        .get();
    const customerOrgsUsingRateAsDisplayQuery = yield getFirebaseContext()
        .customerOrganizationsRef()
        .where('organization', '==', organization.ref)
        .where('linerRate', '==', rate.ref)
        .get();
    return dedupeQueryCombination(customerOrgsUsingRateAsLinerQuery, customerOrgsUsingRateAsDisplayQuery);
});
