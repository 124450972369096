var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState, useRef } from 'react';
import { InputAdornment } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import classNames from 'classnames';
import moment from 'moment';
import { logAndCaptureException, sanitize } from 'utils';
import { timestampOrDateToTimestamp } from 'utils/firebase';
import { FileType } from 'lib/types/upload';
import Firebase from 'EnoticeFirebase';
import api from 'api';
import { exists } from 'lib/types';
import ScrollStep from './ScrollStep';
/**
 * Only show notice by mail to users that have the flag explicitly
 * set for them in Firestore
 * @param user current user in the placement flow
 * @returns {boolean} whether or not the user should be shown the notice by mail step
 */
export const shouldShowNoticeByMail = (user) => {
    var _a;
    if ((_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.isNoticeByMailUser)
        return true;
    return false;
};
/**
 * Uploads a file to cloud storage and returns a static URL for it
 * @param notice notice draft that we are processing
 * @param file file we want to upload to cloud storage
 * @returns {string} URL pointing to the remote file
 */
const getNoticeByMailURLFromFile = (notice, file) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const snapshot = yield Firebase.storage()
        .ref()
        .child(`documentcloud/${(_a = notice.data().original) === null || _a === void 0 ? void 0 : _a.id}/notice-by-mail/${sanitize(file.name)}`)
        .put(file);
    return (yield snapshot.ref.getDownloadURL());
});
function FormLabel({ className, htmlFor, label }) {
    return (React.createElement("label", { htmlFor: htmlFor, className: classNames('block text-sm font-medium text-gray-700', className) }, label));
}
function DocumentUpload({ allowedMimeTypes, existingFileURL, onFileUpload, notice, label, id }) {
    // store the input ref to initiate programmatic clicks on it
    const fileInputRef = useRef(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormLabel, { label: label, htmlFor: id }),
        React.createElement("div", { className: "h-48 mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6" },
            React.createElement("div", { className: "space-y-1 text-center mb-2" }, existingFileURL && (React.createElement("span", { className: "text-sm relative cursor-pointer rounded-md bg-white font-medium text-blue-600 hover:text-blue-500", onClick: () => window.open(existingFileURL, '_blank') }, "Download existing file"))),
            React.createElement("div", { className: "space-y-1 text-center flex flex-col justify-center" },
                React.createElement("svg", { className: "mx-auto h-12 w-12 text-gray-400", stroke: "currentColor", fill: "none", viewBox: "0 0 48 48", "aria-hidden": "true" },
                    React.createElement("path", { d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })),
                React.createElement("div", { className: "flex text-sm text-gray-600 mx-auto" },
                    React.createElement("button", { className: "relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none hover:text-blue-500", onClick: () => { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } },
                        React.createElement("span", null, existingFileURL ? 'Replace file' : 'Upload a file'),
                        React.createElement("input", { type: "file", className: "sr-only", name: "file-upload", ref: fileInputRef, accept: allowedMimeTypes.map(t => `.${t}`).join(','), onChange: (e) => __awaiter(this, void 0, void 0, function* () {
                                const { files } = e.target;
                                if (files === null || files === void 0 ? void 0 : files.length) {
                                    const file = files[0];
                                    const fileURL = yield getNoticeByMailURLFromFile(notice, file);
                                    onFileUpload(fileURL);
                                }
                            }) }))),
                React.createElement("p", { className: "text-xs text-gray-500" }, allowedMimeTypes.map(t => String(t).toUpperCase()).join(', '))))));
}
function DateSelect({ selectedDate, onDateUpdate, label, id }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(FormLabel, { label: label, htmlFor: id }),
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(DatePicker, { className: "w-full cursor-pointer", label: "", id: id, value: selectedDate, placeholder: "MM/DD/YYYY", InputProps: {
                    disableUnderline: true,
                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                        React.createElement("svg", { className: "w-5 h-5", fill: "none", stroke: '#171F2E', viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" })))),
                    className: 'cursor-pointer'
                }, minDate: moment().add('1', 'day').toDate(), onChange: date => onDateUpdate(date), minDateMessage: "Cannot select the current date or prior to the current date." }))));
}
function TextFieldInput({ onUpdate, label, value, id }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(FormLabel, { htmlFor: id, label: label }),
        React.createElement("input", { type: "text", name: id, id: id, className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: value, onChange: e => onUpdate(e.target.value) })));
}
function RadioInput({ isChecked, onCheck, label, id }) {
    return (React.createElement("div", { className: "mt-2 flex items-center" },
        React.createElement("input", { type: "radio", name: id, id: id, className: "cursor-pointer h-4 w-4 border-gray-300 text-indigo-600", checked: isChecked, onChange: onCheck }),
        React.createElement(FormLabel, { className: "ml-3", htmlFor: id, label: label })));
}
/**
 * Generates initial NoticeByMail object. If the notice already has a NoticeByMail
 * attached to it, re-use that value. If not, create a new one
 * @param user The user that is placing the notice
 * @param notice Metadata on the notice
 * @returns {NoticeByMail} initial notice by mail object to populate the UI
 */
const getNoticeByMailStateFromNoticeAndUser = (user, notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    // Return previously saved values if they exist
    if (notice.data().noticeByMail) {
        return notice.data().noticeByMail;
    }
    // Default the organization name to the current active organization name
    const userOrganization = yield ((_b = user.data().activeOrganization) === null || _b === void 0 ? void 0 : _b.get());
    const senderOrganization = ((_c = userOrganization === null || userOrganization === void 0 ? void 0 : userOrganization.data()) === null || _c === void 0 ? void 0 : _c.name) || '';
    // Default to no return address
    const senderReturnAddress = '';
    // Initialize the send date to two weeks from now
    const sendDate = timestampOrDateToTimestamp(moment().add(2, 'weeks').toDate());
    // Default file downloads to empty
    const addressListCSVURL = '';
    const envelopeLogoURL = '';
    const mailDocumentURL = '';
    // Default to emailing the bill to the current user
    const billingDetails = {
        destination: 'email_address'
    };
    const defaultNoticeByMail = {
        senderReturnAddress,
        senderOrganization,
        sendDate,
        mailDocumentURL,
        addressListCSVURL,
        envelopeLogoURL,
        billingDetails
    };
    return defaultNoticeByMail;
});
/**
 * Determines whether or not the form step is complete! The only
 * optional property by default is envelopeLogoURL
 * @param noticeByMail
 * @returns {boolean} whether or not the full step is complete
 */
export const noticeByMailStateIsComplete = (noticeByMail) => {
    if (!noticeByMail)
        return false;
    if (!noticeByMail.senderReturnAddress)
        return false;
    if (!noticeByMail.senderOrganization)
        return false;
    if (!noticeByMail.addressListCSVURL)
        return false;
    if (!noticeByMail.mailDocumentURL)
        return false;
    return true;
};
/**
 * Sends a notification to the R&D team that a notice by mail request
 * has been submitted, tagging an active team member.
 * @param notice Notice that has had a submission run against it
 */
const sendSlackNotificationOfNoticeByMailSubmission = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    // Docs on how to get slack user IDs here: https://www.workast.com/help/article/how-to-find-a-slack-user-id/
    const RESEARCH_DEVELOPMENT_LEAD_SLACK_ID = 'U02PKLDR9GR';
    yield api.post('experiments/log', {
        text: `New notice by mail submitted with draft id: ${notice.id} and notice id: ${(_d = notice.data().original) === null || _d === void 0 ? void 0 : _d.id}. <@${RESEARCH_DEVELOPMENT_LEAD_SLACK_ID}> please confirm!`
    });
});
function NoticeByMailStep({ onDisabledStepClick, activeStepId, previous, notice, user, next, id }) {
    // Does this notice require additional notification by mail?
    const [runNoticeByMail, setRunNoticeByMail] = useState(true);
    // Store the UI state for notice by mail
    const [noticeByMailState, setNoticeByMailState] = useState();
    /**
     * Initialize the UI state
     */
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            if (!exists(user))
                return;
            const initialNoticeByMailState = yield getNoticeByMailStateFromNoticeAndUser(user, notice);
            setNoticeByMailState(initialNoticeByMailState);
        }))();
    }, [Boolean(user)]);
    /**
     * Once the "next" button has been clicked:
     * 1. Update the database object with notice by mail info
     * 2. Send a slack message notifying us about feature usage
     */
    const onExit = () => __awaiter(this, void 0, void 0, function* () {
        // Note: we can enforce existence of the object as isComplete must be true for this function to be called, and isComplete requires this value to be non-empty
        yield notice.ref.update({
            noticeByMail: noticeByMailState
        });
        yield sendSlackNotificationOfNoticeByMailSubmission(notice);
    });
    if (!noticeByMailState)
        return null;
    const { senderOrganization, senderReturnAddress, mailDocumentURL, addressListCSVURL, sendDate, billingDetails } = noticeByMailState;
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: () => __awaiter(this, void 0, void 0, function* () {
            // If we enabled notice by mail, process the submission
            if (runNoticeByMail) {
                try {
                    yield onExit();
                }
                catch (err) {
                    logAndCaptureException(err, 'Error processing notice by mail', {
                        noticeID: notice.id
                    });
                }
            }
            next();
        }), previous: previous, complete: !runNoticeByMail || noticeByMailStateIsComplete(noticeByMailState), onDisabledStepClick: onDisabledStepClick, title: "Notice by Mail", caption: React.createElement("div", null,
            "Need to mail out letters to local property owners about this notice? Don\u2019t stuff the envelopes yourself \u2014 let Column do it for you. Enter in the information below and we will take care of everything, including mailing the documents you upload to everyone on your list and providing envelope tracking information.",
            ' ',
            React.createElement("a", { className: "underline", target: "_blank", href: "https://column.notion.site/Notice-by-Mail-Learn-more-link-Copy-3565a02ba6824040a0077d0fc44f9d11", rel: "noreferrer" }, "Learn More here"),
            ".") },
        React.createElement("div", { className: "relative flex items-start mb-6" },
            React.createElement("div", { className: "flex h-5 items-center" },
                React.createElement("input", { className: "cursor-pointer h-4 w-4 rounded border-gray-300 text-indigo-600", checked: runNoticeByMail, type: "checkbox", onChange: e => setRunNoticeByMail(e.target.checked), id: "run-notice-by-mail" })),
            React.createElement("div", { className: "ml-3" },
                React.createElement(FormLabel, { label: "Send notification by mail along with your public notice", htmlFor: "run-notice-by-mail" }))),
        runNoticeByMail && (React.createElement("div", { className: "ml-5" },
            React.createElement("div", { className: "grid grid-cols-6 gap-6" },
                React.createElement("div", { className: "col-span-6 sm:col-span-3" },
                    React.createElement(TextFieldInput, { label: "Sender Organization", value: senderOrganization, onUpdate: newSenderOrganization => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { senderOrganization: newSenderOrganization })), id: "sender-organization" })),
                React.createElement("div", { className: "col-span-6 sm:col-span-3" },
                    React.createElement(TextFieldInput, { label: "Sender Return Address", value: senderReturnAddress, onUpdate: newSenderReturnAddress => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { senderReturnAddress: newSenderReturnAddress })), id: "sender-return-address" })),
                React.createElement("div", { className: "col-span-6 lg:col-span-3" },
                    React.createElement(DocumentUpload, { allowedMimeTypes: [FileType.PDF], existingFileURL: mailDocumentURL, onFileUpload: (fileURL) => __awaiter(this, void 0, void 0, function* () {
                            setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { mailDocumentURL: fileURL }));
                        }), notice: notice, label: "Property Owner Letter/Documents to Mail (PDF format)", id: "documents-to-mail" })),
                React.createElement("div", { className: "col-span-6 lg:col-span-3" },
                    React.createElement(DocumentUpload, { allowedMimeTypes: [FileType.CSV], existingFileURL: addressListCSVURL, onFileUpload: (fileURL) => __awaiter(this, void 0, void 0, function* () {
                            setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { addressListCSVURL: fileURL }));
                        }), notice: notice, label: "List of Addresses (csv format)", id: "address-list-csv" })),
                React.createElement("div", { className: "col-span-6" },
                    React.createElement("div", { className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" },
                        React.createElement(DateSelect, { id: "mail-date-select", selectedDate: sendDate.toDate(), onDateUpdate: date => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { sendDate: timestampOrDateToTimestamp(date) })), label: "What day you want these letters put in the mail?" }))),
                React.createElement("div", { className: "col-span-6" },
                    React.createElement(FormLabel, { label: "Billing Recipient" }),
                    React.createElement("div", { className: "mt-1 block w-full py-2 px-3 transition duration-150 ease-in-out sm:text-sm sm:leading-5" },
                        React.createElement(RadioInput, { label: "Send invoice to the email associated with my account", isChecked: billingDetails.destination === 'email_address', onCheck: () => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { billingDetails: {
                                    destination: 'email_address'
                                } })), id: "invoice-via-email" }),
                        React.createElement(RadioInput, { label: "Send invoice to the sender return address by mail", isChecked: billingDetails.destination === 'return_address', onCheck: () => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { billingDetails: {
                                    destination: 'return_address'
                                } })), id: "invoice-via-mail" }),
                        React.createElement(RadioInput, { label: "Send invoice to another party", isChecked: billingDetails.destination === 'other_party', onCheck: () => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { billingDetails: {
                                    destination: 'other_party',
                                    otherPartyAddress: ''
                                } })), id: "invoice-to-other-party" }))),
                billingDetails.destination === 'other_party' && (React.createElement("div", { className: "col-span-6 mx-12 -mt-6" },
                    React.createElement(TextFieldInput, { label: "Other Party Address", value: billingDetails.otherPartyAddress, onUpdate: newOtherPartyAddress => setNoticeByMailState(Object.assign(Object.assign({}, noticeByMailState), { billingDetails: Object.assign(Object.assign({}, noticeByMailState.billingDetails), { destination: 'other_party', otherPartyAddress: newOtherPartyAddress }) })), id: "other-party-address-input" }))))))));
}
export default NoticeByMailStep;
