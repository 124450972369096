import { ReactComponent as cancel } from './cancel.svg';
import { ReactComponent as deleteIcon } from './delete.svg';
import { ReactComponent as document } from './document.svg';
import { ReactComponent as dollar } from './dollar.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as lock } from './lock.svg';
import { ReactComponent as download } from './download.svg';
import { ReactComponent as pencil } from './pencil.svg';
import { ReactComponent as invoice } from './invoice.svg';
import { ReactComponent as profile } from './profile.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as globe } from './globe.svg';
import { ReactComponent as billingStatus } from './billingStatus.svg';
import { ReactComponent as link } from './link.svg';
import { ReactComponent as copy } from './copy.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as arrow } from './arrowIcon.svg';
import { ReactComponent as dot } from './dot.svg';
import { ReactComponent as externalLink } from './externalLink.svg';
import { ReactComponent as x } from './x.svg';
import { ReactComponent as arrowRight } from './arrowRight.svg';
import { ReactComponent as flag } from './flag.svg';
import { ReactComponent as printer } from './printer.svg';
import { ReactComponent as cog } from './cog.svg';
import { ReactComponent as questionMarkRounded } from './questionMarkRounded.svg';
import { ReactComponent as bell } from './bell.svg';
import { ReactComponent as accountCircle } from './accountCircle.svg';
import { ReactComponent as arrowDown } from './arrowDown.svg';
import { ReactComponent as questionMarkRoundedFilled } from './questionMarkRoundedFilled.svg';
import { ReactComponent as checkIcon } from './checkIcon.svg';
import { ReactComponent as milestoneCheckIcon } from './milestoneCheck.svg';
import { ReactComponent as clockIcon } from './clockIcon.svg';
import { ReactComponent as arrowLeft } from './arrowLeft.svg';
import { ReactComponent as arrowsExpand } from './arrowsExpand.svg';
import { ReactComponent as searchIcon } from './searchIcon.svg';
import { ReactComponent as addIcon } from './addIcon.svg';
import { ReactComponent as checkRounded } from './checkRounded.svg';
import { ReactComponent as checkOutline } from './checkOutline.svg';
import { ReactComponent as invalidRounded } from './invalidRounded.svg';
import { ReactComponent as arrowRightWithTail } from './arrowRightWithTail.svg';
import { ReactComponent as updateAccount } from './updateAccount.svg';
import { ReactComponent as eye } from './eye.svg';
import { ReactComponent as eyeOff } from './eyeOff.svg';
import { ReactComponent as refresh } from './refresh.svg';
import { ReactComponent as mail } from './mail.svg';
import { ReactComponent as clipboardList } from './clipbaordList.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as informationCircle } from './informationCircle.svg';
import { ReactComponent as bank } from './bank.svg';
import { ReactComponent as card } from './cards.svg';
import { ReactComponent as column } from './column.svg';
import { ReactComponent as onlinePay } from './online-pay.svg';
import { ReactComponent as rewards } from './rewards.svg';
import { ReactComponent as visa } from './visa.svg';
import { ReactComponent as mailOpen } from './mailOpen.svg';
import { ReactComponent as documentDownload } from './documentDownload.svg';
import { ReactComponent as plusCircleIcon } from './plusCircle.svg';
import { ReactComponent as arrowRightIcon } from './arrowRightIcon.svg';
import { ReactComponent as squareUpRight } from './square-up-right.svg';
import { ReactComponent as exclamationCircle } from './exclamation-circle.svg';
import { ReactComponent as checkCircle } from './check-circle.svg';
import { ReactComponent as receiptCheck } from './receipt-check.svg';
import { ReactComponent as identification } from './identification.svg';
import { ReactComponent as building } from './building.svg';
import { ReactComponent as filterIcon } from './filter.svg';
import { ReactComponent as arrowUpCircle } from './arrowUpCircle.svg';
import { ReactComponent as arrowDownCircle } from './arrowDownCircle.svg';
import { ReactComponent as balance } from './balance.svg';
import { ReactComponent as communityProfiles } from './communityProfiles.svg';
import { ReactComponent as warningTriangle } from './warningTriangle.svg';
import { ReactComponent as arrowDiagonal } from './arrowDiagonal.svg';
import { ReactComponent as questionMarkWithCircle } from './questionMarkWithCircle.svg';
import { ReactComponent as questionMark } from './questionMark.svg';
import { ReactComponent as filledCircle } from './filledCircle.svg';
import { ReactComponent as warning } from './warning.svg';
import { ReactComponent as attachment } from './attachment.svg';
import { ReactComponent as addImage } from './addImage.svg';
import { ReactComponent as upDownSelector } from './upDownSelector.svg';
// Notifications type SVG's
import { ReactComponent as notice } from './notice.svg';
import { ReactComponent as alarmNote } from './alarmNote.svg';
import { ReactComponent as plusNote } from './plusNote.svg';
import { ReactComponent as tickNote } from './tickNote.svg';
import { ReactComponent as tickIcon } from './tickIcon.svg';
export const RefreshIcon = refresh;
export const CancelIcon = cancel;
export const DeleteIcon = deleteIcon;
export const DocumentIcon = document;
export const DollarIcon = dollar;
export const EditIcon = edit;
export const LockIcon = lock;
export const DownloadIcon = download;
export const PencilIcon = pencil;
export const InvoiceIcon = invoice;
export const ProfileIcon = profile;
export const CalendarIcon = calendar;
export const GlobeIcon = globe;
export const BillingStatusIcon = billingStatus;
export const LinkIcon = link;
export const CopyIcon = copy;
export const UploadIcon = upload;
export const ArrowIcon = arrow;
export const DotIcon = dot;
export const QuestionMarkIcon = questionMark;
export const ExternalLinkIcon = externalLink;
export const QuestionMarkRoundedIcon = questionMarkRounded;
export const XIcon = x;
export const ArrowRightIcon = arrowRight;
export const FlagIcon = flag;
export const PrinterIcon = printer;
export const CogIcon = cog;
export const BellIcon = bell;
export const AccountCircleIcon = accountCircle;
export const ArrowDownIcon = arrowDown;
export const QuestionMarkRoundedFilledIcon = questionMarkRoundedFilled;
export const CheckIcon = checkIcon;
export const MilestoneCheckIcon = milestoneCheckIcon;
export const ClockIcon = clockIcon;
export const ArrowRight = arrowRight;
export const ArrowLeft = arrowLeft;
export const SearchIcon = searchIcon;
export const AddIcon = addIcon;
export const CheckRoundedIcon = checkRounded;
export const CheckOutlinedIcon = checkOutline;
export const InvalidRoundedIcon = invalidRounded;
export const ArrowRightWithTailIcon = arrowRightWithTail;
export const UpdateAccount = updateAccount;
export const Eye = eye;
export const EyeOff = eyeOff;
export const Mail = mail;
export const ClipbaordList = clipboardList;
export const ArrowsExpand = arrowsExpand;
export const BookmarkIcon = Bookmark;
export const BankIcon = bank;
export const CardIcon = card;
export const ColumnLogo = column;
export const OnlinePayIcon = onlinePay;
export const RewardsIcon = rewards;
export const VisaIcon = visa;
export const InformationCircle = informationCircle;
export const MailOpenIcon = mailOpen;
export const DocumentDownloadIcon = documentDownload;
export const PlusCircleIcon = plusCircleIcon;
export const ArrowPointRight = arrowRightIcon;
export const Notice = notice;
export const AlarmNote = alarmNote;
export const PlusNote = plusNote;
export const TickNote = tickNote;
export const SquareUpRight = squareUpRight;
export const ExclamationCircle = exclamationCircle;
export const CheckCircle = checkCircle;
export const ReceiptCheck = receiptCheck;
export const Identification = identification;
export const BuildingIcon = building;
export const FilterIcon = filterIcon;
export const ArrowUpCircle = arrowUpCircle;
export const ArrowDownCircle = arrowDownCircle;
export const BalanceIcon = balance;
export const TickIcon = tickIcon;
export const CommunityProfiles = communityProfiles;
export const WarningTriangle = warningTriangle;
export const MilestoneDiagonalArrowIcon = arrowDiagonal;
export const QuestionMarkWithCircleIcon = questionMarkWithCircle;
export const FilledCircleIcon = filledCircle;
export const WarningIcon = warning;
export const AttachmentIcon = attachment;
export const AddImageIcon = addImage;
export const UpDownSelectorIcon = upDownSelector;
