export const envs = {
    STAGING: 'STAGING',
    TEST: 'TEST',
    DEMO: 'DEMO',
    PROD: 'PROD',
    DEV: 'DEV'
};
export var IndesignRoute;
(function (IndesignRoute) {
    IndesignRoute["liner_ad_image"] = "/";
    IndesignRoute["display_ad_image"] = "/display/image";
    IndesignRoute["template_specs"] = "/template/specs";
    IndesignRoute["health"] = "/health";
    IndesignRoute["display_ad_marginalia"] = "/display/marginalia";
    IndesignRoute["paginate"] = "/paginate";
    IndesignRoute["paginate_rtf"] = "/paginate/rtf";
})(IndesignRoute || (IndesignRoute = {}));
export const Collections = {
    /**
     * @deprecated announcements are now done through Pendo.
     */
    announcements: 'announcements',
    subscriptions: 'subscriptions',
    publicNotices: 'publicNotices',
    userNotices: 'usernotices',
    userDrafts: 'userdrafts',
    users: 'users',
    organizations: 'organizations',
    invoices: 'invoices',
    notifications: 'notifications',
    invites: 'invites',
    adTemplates: 'adTemplates',
    rates: 'rates',
    transfers: 'transfers',
    previewNotices: 'previewNotices',
    emailConfirmations: 'emailConfirmations',
    donationProfiles: 'donationProfiles',
    donors: 'donors',
    donations: 'donations',
    cards: 'cards',
    donationPayouts: 'donationPayouts',
    cardInvoices: 'cardInvoices',
    cardTransactions: 'cardTransactions',
    noticeFiles: 'noticeFiles',
    /**
     * @deprecated joyrides are no longer used, use Pendo.
     */
    joyrides: 'joyrides',
    events: 'events',
    customers: 'customers',
    customerOrganizations: 'customerOrganizations',
    dwollaEvents: 'dwollaEvents',
    invoiceItems: 'invoiceItems',
    payouts: 'payouts',
    displaySites: 'displaySites',
    notarizations: 'notarizations',
    deadlines: 'deadlines',
    stripeevents: 'stripeevents',
    // Note: this should not be used
    notices: 'notices',
    // Note: this is only used as a subcollection of usernotices
    courthouse: 'courthouse',
    /**
     * Note: this is only used as a subcollection of users / organizations
     * @deprecated after COREDEV-684 this collection is unused.
     */
    sources: 'sources',
    // Note: this is only used as a subcollection of usernnotices
    mail: 'mail',
    // Note: this is unused
    emails: 'emails',
    // Note: this is unused
    surveys: 'surveys',
    // Note: this is not a Firestore collection
    affidavits: 'affidavits',
    // Note: this is only used as a subcollection of display sites
    uploadIDs: 'uploadIDs',
    joinRequests: 'joinRequests'
};
export const Functions = {
    createCustomer: {
        name: 'createCustomer',
        timeout: 300
    },
    createPublisherDocsOnCall: {
        name: 'createPublisherDocsOnCall',
        timeout: 540
    },
    cloudConvertOnCall: { name: 'cloudConvertOnCall', timeout: 540 },
    donate: { name: 'donate', timeout: 540 },
    createBillingSession: { name: 'createBillingSession', timeout: 540 }
};
/** Zero width space (unicode) */
export const ZWS = '\u200b';
export const EChars = {
    tab: `__enotice-tab-char`
};
let firebaseProject;
try {
    firebaseProject = require('../src/config.firebase').default.projectId;
}
catch (err) {
    firebaseProject = process.env.GCLOUD_PROJECT;
}
// Avoid any console.log() in the global scope while running jest tests because
// jest reinitializes all dependences for every test case, meaning this would
// print hundreds of times.
if (!process.env.JEST_WORKER_ID) {
    if (firebaseProject) {
        console.log('using: ', firebaseProject);
    }
    else {
        console.log('Cannot find local firebase project ID');
    }
}
export const CLOUDINARY_BUCKET = 'dgqq2xsfd';
export const FIREBASE_PROJECT = firebaseProject;
export const PROJECT_LOCATION = 'us-central1';
// Note: FUNCTIONS_EMULATOR is only set when running the local functions
// development environment (firebase serve or emulators:start)
const isLocalDev = (process.env.FUNCTIONS_EMULATOR === 'true' ||
    process.env.NODE_ENV === 'development') &&
    FIREBASE_PROJECT !== 'enotice-production';
export const IS_TESTING_PROJECT = FIREBASE_PROJECT === 'enotice-testing' ||
    FIREBASE_PROJECT === 'enotice-testing2' ||
    FIREBASE_PROJECT === 'enotice-testing3';
export const BACKEND_URL = isLocalDev
    ? `http://localhost:5001/${FIREBASE_PROJECT}/us-central1/api/`
    : `https://us-central1-${FIREBASE_PROJECT}.cloudfunctions.net/api/`;
export const PLAID_PUBLIC_KEY = '541c7f3939c359167f48904d2571a0';
export const DEFAULT_ACCEPT = '.docx,.doc,.csv,.xls,.xlsx,.txt,.rtf,.jpg,.jpeg,.png,.pdf,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/rtf,text/rtf,image/jpeg,image/png';
export const PRODUCTION_PROJECT = 'enotice-production';
export const STAGING_PROJECT = 'column-staging';
export const DEMO_PROJECT = 'enotice-demo-8d99a';
export const STRIPE = 'stripe';
export const PAYWAY = 'payway';
export const ELAVON = 'elavon';
export const DEFAULT_TEMPLATE_ID = 'default-20220823';
/** Stripe Account ID */
export const ENOTICE_ACCOUNT_ID_PROD = 'acct_1F4vICCGonLIgiaZ';
export const ENOTICE_ACCOUNT_ID_DEV = 'acct_1FzOn6EwSpcYm9q2';
