export const phoneFormattersByRegion = {
    us: {
        regexSearch: /([0-9]{1,2})([0-9]{1})?([0-9]{1,3})?([0-9]{1,4})?/,
        validRegex: '\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}',
        formatter: (_match, p1, p2, p3, p4) => {
            let formattedValue = '';
            if (p1) {
                formattedValue = `(${p1}`;
            }
            if (p2) {
                formattedValue += `${p2}) `;
            }
            if (p3) {
                formattedValue += `${p3}`;
            }
            if (p4) {
                formattedValue += `-${p4}`;
            }
            return formattedValue;
        },
        maxStringLength: `(999) 999-9999`.length
    }
};
function enforceNumeric(value) {
    return value.replace(/\D/g, '');
}
export function formatPhone(value, phoneFormat) {
    return enforceNumeric(value)
        .replace(phoneFormat.regexSearch, phoneFormat.formatter)
        .slice(0, phoneFormat.maxStringLength);
}
