import Handlebars from 'handlebars';
export const registerHandlebarsHelpers = () => {
    Handlebars.registerHelper('input_text', function (options) {
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            return options.fn(this);
        }
        const key = options.hash.key;
        const placeholder = options.hash.placeholder;
        const hint = options.hash.hint;
        const value = options.data.root[key];
        return new Handlebars.SafeString(`<madlib-input key="${key}" placeholder="${placeholder}" hint="${hint || ''}" value="${value || ''}"></madlib-input>`);
    });
    Handlebars.registerHelper('input_date', function (options) {
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            return options.fn(this);
        }
        const key = options.hash.key;
        const hint = options.hash.hint;
        const value = options.data.root[key];
        return new Handlebars.SafeString(`<madlib-date-input key="${key}" hint="${hint || ''}" value="${value || ''}"></madlib-date-input>`);
    });
    Handlebars.registerHelper('multiple-choice-input', function (options) {
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            return options.fn(this);
        }
        const key = options.hash.key;
        const hint = options.hash.hint;
        const value = options.data.root[key];
        const optionsData = options.hash.options;
        return new Handlebars.SafeString(`
    <madlib-multiple-choice-input key="${key}" hint="${hint}" value="${value || ''}" options='${Handlebars.escapeExpression(optionsData)}'
      ></madlib-multiple-choice-input>
    `);
    });
    Handlebars.registerHelper('gt', function (arg1, arg2) {
        return arg1 > arg2;
    });
    Handlebars.registerHelper('gte', function (arg1, arg2) {
        return arg1 >= arg2;
    });
};
