import React, { useState } from 'react';
import FileUpload from 'components/FileUpload';
import { FileUploadListItem } from 'components/FileUploadListItem';
const FileUploadQuestionInput = ({ question, onChange }) => {
    const [uploadSnapshots, setUploadSnapshots] = useState([]);
    const onDelete = (uploadSnapshot) => {
        const newUploads = [...uploadSnapshots];
        newUploads.splice(newUploads.indexOf(uploadSnapshot), 1);
        setUploadSnapshots(newUploads);
        if (!newUploads.length) {
            onChange({
                varName: question.varName,
                value: ''
            });
        }
    };
    return (React.createElement("div", null,
        React.createElement("p", { className: "mb-1 text-sm" }, question.prompt),
        uploadSnapshots &&
            uploadSnapshots.map(uploadSnapshot => (React.createElement(FileUploadListItem, { key: uploadSnapshot.ref.fullPath, firebaseStoragePath: uploadSnapshot.ref.fullPath, onDelete: () => onDelete(uploadSnapshot) }))),
        React.createElement(FileUpload, { id: "madlib-file-upload", multiple: false, disabled: uploadSnapshots.length > 0, uploadFolder: `documentcloud/madlib-prototyping-notices`, acceptFileTypes: '.pdf', onFileUpload: ({ files, uploadSnapshots }) => {
                setUploadSnapshots(previousUploads => [
                    ...previousUploads,
                    ...uploadSnapshots
                ]);
                onChange({
                    varName: question.varName,
                    value: files[0].name
                });
            } })));
};
export default FileUploadQuestionInput;
