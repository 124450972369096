var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { exists } from 'lib/types';
import { NoticeStatusType, InvoiceStatus } from 'lib/enums';
import api from 'api';
import Firebase, { Firestore } from 'EnoticeFirebase';
import { DocumentIcon, DeleteIcon, DollarIcon, CancelIcon, RefreshIcon, ArrowDownIcon, Mail } from 'icons';
import BoundedProgress from 'components/BoundedProgress';
import CTooltip from 'components/Tooltip';
import CButton from 'components/CButton';
import { cdnIfy, getToastMessageFromXMLExportSettings, getXMLSyncExportSettings, shouldPreventLatePrepay } from 'lib/helpers';
import { FIREBASE_PROJECT } from 'lib/constants';
import { getIsAfterPublishingDeadline } from 'lib/utils/deadlines';
import { getFirebaseContext } from 'utils/firebase';
import classnames from 'classnames';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
import CancelNoticeModal from './CancelNoticeModal';
import CancelInvoiceModal from './CancelInvoiceModal';
import ProcessPaymentModal from './ProcessPaymentModal';
import ProcessCheckModal from './ProcessCheckModal';
import PayInvoiceNoticePastDueModal from './PayInvoiceNoticePastDueModal';
import PreventLatePrepayModal from './PreventLatePrepayModal';
import * as EventTypes from '../../lib/types/events';
import { CancelNoticeSuccessModal } from './CancelNoticeSuccessModal';
const InvoiceActions = ({ invoice, notice, isPublisher, push, user, newspaper, setOpen, setToastMessage, setToastError, isInvoicedOutsideColumn }) => {
    var _a, _b, _c;
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelSuccessModalConfig, setCancelSuccessModalConfig] = useState();
    const [showProcessPaymentModal, setShowProcessPaymentModal] = useState(false);
    const [showProcessCheckModal, setShowProcessCheckModal] = useState(false);
    const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false);
    const [showInvoiceFlyout, setShowInvoiceFlyout] = useState(false);
    const [isCancelNoticeDisabled, setIsCancelNoticeDisabled] = useState(false);
    const [showSyncNotice, setShowSyncNotice] = useState(false);
    const [syncingNotice, setSyncingNotice] = useState(false);
    const [isPayInvoiceDisabled, setIsPayInvoiceDisabled] = useState(false);
    const [showPayInvoiceNoticePastDueModal, setShowPayInvoiceNoticePastDueModal] = useState(false);
    const [showPreventLatePrepayModal, setShowPreventLatePrepayModal] = useState(false);
    const [invoiceCreationInitiated, setInvoiceCreationInitiated] = useState(false);
    const buttonStyles = 'w-full p-4 border rounded font-medium text-gray-600 uppercase bg-white shadow md:shadow-none';
    const getInvoiceCreationEvents = () => {
        return getFirebaseContext()
            .eventsRef()
            .where('notice', '==', notice.ref)
            .where('type', 'in', [
            EventTypes.INVOICE_CREATION_INITIATED,
            EventTypes.INVOICE_CREATED,
            EventTypes.INVOICE_CREATION_FAILED
        ])
            .orderBy('createdAt', 'desc')
            .onSnapshot(eventsSnap => {
            if (!eventsSnap.docs.length)
                return;
            const eventSnapshots = eventsSnap.docs;
            setInvoiceCreationInitiated(eventSnapshots[0].data().type ===
                EventTypes.INVOICE_CREATION_INITIATED);
        });
    };
    useEffect(() => {
        const eventsUnsub = getInvoiceCreationEvents();
        return () => {
            eventsUnsub();
        };
    }, []);
    useEffect(() => {
        var _a, _b;
        isPublisher
            ? setIsCancelNoticeDisabled(((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.status) === InvoiceStatus.refunded.value ||
                ((_b = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _b === void 0 ? void 0 : _b.noticeStatus) === NoticeStatusType.cancelled.value)
            : setIsCancelNoticeDisabled(Boolean(notice.data().affidavit) ||
                notice.data().noticeStatus === NoticeStatusType.cancelled.value);
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (exists(invoice)) {
                setIsPayInvoiceDisabled(isInvoicedOutsideColumn ||
                    // TODO: Should this include InvoiceStatus.initiated.value https://columnpbc.atlassian.net/browse/IT-4424
                    [
                        InvoiceStatus.paid.value,
                        InvoiceStatus.partially_refunded.value
                    ].includes(invoice.data().status) ||
                    invoice.data().void);
            }
            else {
                setIsPayInvoiceDisabled(true);
            }
        }))();
    }, [invoice, notice]);
    const isCreateInvoiceDisabled = !userHasPermission(user, Permissions.INVOICES_CREATE) ||
        (invoice && exists(invoice)) ||
        invoiceCreationInitiated ||
        notice.data().noticeStatus === NoticeStatusType.cancelled.value;
    useEffect(() => {
        if (isPublisher && newspaper) {
            void (() => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const exportSettings = yield getXMLSyncExportSettings(newspaper);
                    if (exportSettings && !exportSettings.hideSyncButton) {
                        setShowSyncNotice(true);
                    }
                }
                catch (err) { }
            }))();
        }
    }, [newspaper]);
    const goToInvoicePayment = () => {
        if (exists(invoice)) {
            invoice.data().isWithinBulkInvoice
                ? push(`/payments`)
                : window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
        }
    };
    const handlePayInvoiceClicked = () => {
        const isPastPublicationDeadline = getIsAfterPublishingDeadline(notice.data().publicationDates[0].toDate(), newspaper.data().deadlines, newspaper.data().iana_timezone, notice.data(), newspaper);
        const shouldShowPastDueModal = !isPublisher &&
            isPastPublicationDeadline &&
            notice.data().requireUpfrontPayment;
        const shouldShowPreventLatePrepayModal = shouldPreventLatePrepay(notice, newspaper);
        if (shouldShowPreventLatePrepayModal) {
            setShowPreventLatePrepayModal(true);
        }
        else if (shouldShowPastDueModal) {
            setShowPayInvoiceNoticePastDueModal(true);
        }
        else {
            goToInvoicePayment();
        }
    };
    const handleSyncNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        setSyncingNotice(true);
        try {
            yield getFirebaseContext()
                .eventsRef()
                .add({
                createdAt: Firestore.FieldValue.serverTimestamp(),
                type: EventTypes.MANUAL_SYNC_REQUEST,
                notice: notice.ref,
                data: {
                    inApp: true,
                    requestedBy: user.ref
                }
            });
            const exportSettings = yield getXMLSyncExportSettings(newspaper);
            if (exportSettings) {
                const toastMessage = getToastMessageFromXMLExportSettings(exportSettings);
                setToastMessage(toastMessage);
            }
        }
        catch (err) {
            setToastError({
                title: 'Sync unsuccessful.',
                message: 'Please try again or contact help@column.us'
            });
            logAndCaptureException(err, 'Failed to sync notice', {
                noticeId: notice.id
            });
        }
        setSyncingNotice(false);
    });
    const ProofButton = () => {
        const disabledProof = !!notice.data().postWithoutFormatting;
        return (React.createElement(CTooltip, { helpText: disabledProof
                ? 'A proof will appear after the newspaper finalizes formatting for this notice.'
                : '' },
            React.createElement(CButton, { id: "download-proof", className: `bg-white py-0 pr-0 ${buttonStyles} ${disabledProof && `opacity-50`}`, startIcon: React.createElement(DocumentIcon, { className: "w-full" }), startClasses: "py-4 col-span-2", middleClasses: "col-span-8 text-left ml-2 py-4", endClasses: "col-span-2 p-2 sm:-ml-3", onClick: () => {
                    if (disabledProof)
                        return;
                    let url;
                    const finalUrl = notice.data().finalProofURL;
                    const { proofStoragePath } = notice.data();
                    if (finalUrl) {
                        if (finalUrl.includes('cloudinary')) {
                            const storagePath = finalUrl
                                .replace('?invalidate=true', '')
                                .split('/')
                                .slice(7)
                                .join('/');
                            url = cdnIfy(storagePath, { useImgix: true });
                        }
                        else {
                            url = finalUrl;
                        }
                    }
                    else if (proofStoragePath) {
                        url = cdnIfy(proofStoragePath, {
                            useImgix: true
                        });
                    }
                    if (url) {
                        window.open(url.replace('undefined', `${FIREBASE_PROJECT}.appspot.com`));
                    }
                } }, "Proof PDF")));
    };
    const GeneratingNewProofButton = () => (React.createElement(CButton, { id: "generating-new-proof", className: `bg-white ${buttonStyles}`, startIcon: React.createElement(DocumentIcon, { className: "w-full" }), middleClasses: 'col-span-8 text-left ml-2', endIcon: React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }) }, "Updating Proof"));
    const getReceiptUrl = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!exists(invoice)) {
            return `https://column.us/notice/${notice.id}`;
        }
        const { receipt_pdf, status, noticeId } = invoice.data();
        if (!receipt_pdf) {
            return `https://column.us/notice/${noticeId}`;
        }
        if (status === InvoiceStatus.refunded.value &&
            receipt_pdf.startsWith('https://')) {
            return receipt_pdf;
        }
        return yield Firebase.storage()
            .ref('')
            .child(`/${receipt_pdf}`)
            .getDownloadURL();
    });
    const hasInvoicePdf = !!((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.invoice_pdf);
    // TODO: Should this include InvoiceStatus.initiated.value and InvoiceStatus.partially_refunded.value?
    // https://columnpbc.atlassian.net/browse/IT-4424
    const isInvoicePaid = ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.status) === InvoiceStatus.paid.value;
    const isNoticeCanceled = notice.data().noticeStatus === NoticeStatusType.cancelled.value;
    const isProcessPaymentButtonEnabled = hasInvoicePdf && !isInvoicePaid && !isNoticeCanceled;
    const invoiceLoading = !notice.data().invoice && invoiceCreationInitiated;
    const shouldDisableInvoicePdf = !(exists(invoice) && ((_c = invoice.data()) === null || _c === void 0 ? void 0 : _c.invoice_pdf)) ||
        isInvoicedOutsideColumn;
    const InvoicePDFButton = () => {
        var _a;
        return (React.createElement("div", null,
            React.createElement(CButton, { id: "invoice-pdf", startIcon: invoiceLoading ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })) : (React.createElement(DocumentIcon, { className: "w-full" })), startClasses: "py-4 col-span-2", className: classnames('bg-white w-full border rounded font-medium text-gray-600 uppercase p-4 py-0 pr-0 shadow md:shadow-none', {
                    'opacity-50 cursor-not-allowed pointer-events-none': shouldDisableInvoicePdf
                }), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (!exists(invoice)) {
                        return;
                    }
                    const invoiceData = invoice.data();
                    const w = window.open();
                    if (!w) {
                        return;
                    }
                    try {
                        const url = invoiceData.receipt_pdf
                            ? yield getReceiptUrl()
                            : invoiceData.invoice_pdf.startsWith('https://')
                                ? invoiceData.invoice_pdf
                                : yield Firebase.storage()
                                    .ref('')
                                    .child(`/${invoiceData.invoice_pdf}`)
                                    .getDownloadURL();
                        w.location = url;
                    }
                    catch (err) {
                        logAndCaptureException(err, 'Failed to get receipt url', {
                            invoiceId: invoice.id
                        });
                        w.close();
                    }
                }), middleClasses: "col-span-8 text-left ml-2 py-4", endClasses: `${isInvoicedOutsideColumn && 'cursor-not-allowed pointer-events-none'} col-span-2 p-2 sm:-ml-3 justify-center flex items-center`, endIcon: isPublisher &&
                    exists(invoice) &&
                    notice.data().noticeStatus !== NoticeStatusType.cancelled.value ? (React.createElement("div", { id: "invoice-actions", className: `block p-2 md:p-1 lg:p-2 hover:bg-gray-300 transition ease-in-out duration-150 mx-2 md:rounded-full w-auto cursor-pointer flex justify-center`, onClick: e => {
                        e.stopPropagation();
                        setShowInvoiceFlyout(true);
                    } },
                    React.createElement(ArrowDownIcon, { className: "w-5 h-5" }))) : undefined, disabled: isInvoicedOutsideColumn }, exists(invoice) && invoice.data().void
                ? 'Voided Invoice'
                : exists(invoice) && invoice.data().receipt_pdf
                    ? 'Invoice Receipt'
                    : 'Invoice PDF'),
            showInvoiceFlyout && (React.createElement("div", { id: "invoice-flyout", className: "absolute right-0 -mt-8 w-auto max-w-xs" },
                React.createElement("div", { className: "rounded-lg shadow-lg" },
                    React.createElement("div", { className: "rounded-lg shadow-xs overflow-hidden" },
                        React.createElement("div", { id: "flyout", className: "relative grid gap-6 bg-white sm:gap-8 p-6" },
                            React.createElement("div", { id: "cancel-invoice", className: "cursor-pointer items-center inline-flex -m-3 p-3 flex rounded-md hover:bg-gray-50 transition ease-in-out duration-150", onClick: () => {
                                    setShowCancelInvoiceModal(true);
                                    setShowInvoiceFlyout(false);
                                } },
                                React.createElement(DeleteIcon, { className: "w-6 h-6 mr-2 inline-block text-gray-750" }),
                                React.createElement("div", { className: "text-base mt-0 font-medium text-gray-700 hover:text-gray-800 leading-none" }, "Delete Invoice")),
                            React.createElement("div", { id: "send-reminder", className: `-m-3 p-3 items-center inline-flex rounded-md transition ease-in-out duration-150 ${isPayInvoiceDisabled
                                    ? 'bg-opacity-50 opacity-50 cursor-not-allowed pointer-events-none'
                                    : 'cursor-pointer hover:bg-gray-50'}`, onClick: () => {
                                    setOpen(true);
                                    setShowInvoiceFlyout(false);
                                } },
                                React.createElement(Mail, { className: "w-6 h-6 mr-2 inline-block text-gray-750" }),
                                React.createElement("div", { className: "text-base mt-0 font-medium text-gray-700 hover:text-gray-800 leading-none" }, "Send Reminder")),
                            React.createElement(Tooltip, { title: ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.isWithinBulkInvoice)
                                    ? `This publisher is set to pay invoices in bulk at the end of the month.`
                                    : '' },
                                React.createElement("div", { id: "process-payment", className: `-m-3 p-3 items-center inline-flex rounded-md transition ease-in-out duration-150 ${isProcessPaymentButtonEnabled
                                        ? 'cursor-pointer hover:bg-gray-50'
                                        : 'bg-opacity-50 opacity-50 cursor-not-allowed pointer-events-none'}`, onClick: () => {
                                        setShowProcessPaymentModal(true);
                                        setShowInvoiceFlyout(false);
                                    } },
                                    React.createElement(RefreshIcon, { className: "w-6 h-6 mr-2 inline-block text-gray-750" }),
                                    React.createElement("div", { className: "text-base mt-0 font-medium text-gray-700 hover:text-gray-800 leading-none" }, "Process Payment")))))))),
            React.createElement("style", null, `
          #flyout {
            z-index: 100 !important;
          }
        `)));
    };
    const PayInvoiceButton = () => {
        var _a;
        return (React.createElement(CButton, { id: "pay-invoice", startIcon: React.createElement(DollarIcon, { className: "w-full text-gray-750" }), startClasses: "py-4 col-span-2", middleClasses: "col-span-8 text-left ml-2 py-4", endClasses: `${isPayInvoiceDisabled && `cursor-not-allowed pointer-events-none`} col-span-2 p-2 sm:-ml-3 justify-center flex items-center`, className: `${isPayInvoiceDisabled &&
                `opacity-50 cursor-not-allowed pointer-events-none`} shadow md:shadow-none bg-white w-full border rounded font-medium text-gray-600 uppercase p-4 py-0 pr-0`, endIcon: ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.status) === InvoiceStatus.payment_failed.value ? (React.createElement("svg", { width: "22", height: "19", viewBox: "0 0 22 19" },
                React.createElement("path", { d: "M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z", fill: "#E24F21" }))) : !isPublisher ? (React.createElement("div", { id: "pay-send-reminder", className: `${isPayInvoiceDisabled && `cursor-not-allowed pointer-events-none`} block lg:p-2 p-1 hover:bg-gray-300 transition ease-in-out duration-150 mx-2 rounded-full w-auto cursor-pointer justify-center flex items-center`, onClick: (e) => {
                    setOpen(true);
                    e.stopPropagation();
                } },
                React.createElement(Mail, { className: "w-6 h-6 inline-block text-gray-750" }))) : undefined, onClick: handlePayInvoiceClicked, disabled: isPayInvoiceDisabled }, 'Pay Invoice'));
    };
    const [receiptLoading, setReceiptLoading] = useState(false);
    const handleReceiptGeneration = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!invoice) {
            return;
        }
        setReceiptLoading(true);
        const { receiptUrl } = yield api.post('payments/create-receipt-pdf', {
            invoiceId: invoice.id
        });
        setReceiptLoading(false);
        if (receiptUrl) {
            if (receiptUrl.startsWith('https://'))
                return receiptUrl;
            const url = yield Firebase.storage()
                .ref('')
                .child(`/${receiptUrl}`)
                .getDownloadURL();
            return url;
        }
        return false;
    });
    useEffect(() => {
        const generateReceipt = () => {
            if (!exists(invoice)) {
                return;
            }
            const paymentOrPartialRefundHasOccurred = hasPaymentOrPartialRefund(invoice);
            if (paymentOrPartialRefundHasOccurred && !invoice.data().receipt_pdf) {
                void handleReceiptGeneration();
            }
        };
        generateReceipt();
    }, [invoice]);
    const PaidReceiptButton = () => {
        var _a, _b, _c;
        return (React.createElement(CButton, { id: "invoice-pdf", startIcon: receiptLoading ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })) : (React.createElement(DocumentIcon, { className: "w-full" })), startClasses: "py-4 col-span-2", className: `${(!invoice || (!isPublisher && isInvoicedOutsideColumn)) &&
                `opacity-50 cursor-not-allowed pointer-events-none`} bg-white w-full border rounded font-medium text-gray-600 uppercase p-4 py-0 pr-0 shadow md:shadow-none`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                if (!exists(invoice)) {
                    return;
                }
                const w = window.open();
                if (!w) {
                    return;
                }
                try {
                    const url = invoice.data().receipt_pdf
                        ? yield getReceiptUrl()
                        : (yield handleReceiptGeneration()) ||
                            (yield Firebase.storage()
                                .ref('')
                                .child(`/${invoice.data().invoice_pdf}`)
                                .getDownloadURL());
                    w.location = url;
                }
                catch (err) {
                    logAndCaptureException(err, 'Failed to get receipt url', {
                        invoiceId: invoice.id
                    });
                    w.close();
                }
            }), middleClasses: "col-span-8 text-left ml-2 py-4", endClasses: `${!isPublisher &&
                isInvoicedOutsideColumn &&
                'cursor-not-allowed pointer-events-none'} flex justify-center col-span-2 p-2 sm:-ml-3`, endIcon: isPublisher &&
                (invoice === null || invoice === void 0 ? void 0 : invoice.data()) &&
                ((_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.noticeStatus) !== NoticeStatusType.cancelled.value ? (React.createElement("a", { id: "cancel-invoice", className: 'block hover:bg-gray-300 p-2 md:mx-2 sm:mx-7 rounded-full', onClick: e => {
                    setShowCancelInvoiceModal(true);
                    e.stopPropagation();
                } },
                React.createElement(DeleteIcon, { className: "m-auto" }))) : undefined, disabled: !isPublisher && isInvoicedOutsideColumn }, ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.status) === InvoiceStatus.refunded.value
            ? 'Refund Receipt'
            : ((_c = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _c === void 0 ? void 0 : _c.noticeStatus) === NoticeStatusType.cancelled.value
                ? 'Voided Receipt'
                : 'Invoice Receipt'));
    };
    return (React.createElement("div", { className: "grid grid-rows-4 gap-2 md:gap-4", onClick: () => {
            if (showInvoiceFlyout)
                setShowInvoiceFlyout(false);
        } },
        React.createElement("div", { className: "row-span-1 md:w-8/12" }, notice.data().invoice && !notice.data().finalProofURL ? (React.createElement(BoundedProgress, { timeout: 30000, InProgress: React.createElement(GeneratingNewProofButton, null), Fallback: React.createElement(ProofButton, null) })) : (React.createElement(ProofButton, null))),
        isPublisher && (React.createElement("div", { className: "row-span-1 md:w-8/12" },
            React.createElement(CButton, { id: "create-invoice", middleClasses: 'col-span-8 text-left ml-2', className: ` ${isCreateInvoiceDisabled && 'opacity-50 cursor-not-allowed'} ${buttonStyles}`, startIcon: React.createElement(DollarIcon, { className: "w-full text-gray-750" }), disabled: isCreateInvoiceDisabled, onClick: () => push(`/notice/${notice.id}/invoice/create`) }, "Create Invoice"))),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row-span-1 md:w-8/12" },
                React.createElement(CTooltip, { id: "invoice-pdf-tooltip", helpText: !isPublisher && isInvoicedOutsideColumn
                        ? 'This publisher handles payments outside of Column.'
                        : !isPublisher && !invoice
                            ? `${newspaper.data().name} has not created an invoice for this notice yet. You will be notified by email with your invoice.`
                            : '' },
                    React.createElement("span", null, exists(invoice) && invoice.data().receipt_pdf ? (React.createElement(PaidReceiptButton, null)) : (React.createElement(InvoicePDFButton, null))))),
            !isPublisher && (React.createElement("div", { className: "md:w-8/12" },
                React.createElement(CTooltip, { id: "pay-invoice-tooltip", helpText: isInvoicedOutsideColumn
                        ? 'This publisher handles payments outside of Column.'
                        : !isPublisher && !invoice
                            ? `${newspaper.data().name} has not created an invoice for this notice yet. You will be notified by email with your invoice.`
                            : '' },
                    React.createElement(PayInvoiceButton, null)))),
            React.createElement("div", { className: "row-span-1 md:w-8/12" },
                React.createElement(CButton, { id: "cancel-notice", className: `
              ${isCancelNoticeDisabled &&
                        `opacity-50 cursor-not-allowed pointer-events-none`} 
              ${buttonStyles} `, onClick: () => {
                        setShowCancelModal(true);
                    }, middleClasses: 'col-span-8 text-left ml-2', disabled: isCancelNoticeDisabled, startIcon: React.createElement(CancelIcon, { className: "w-full" }) }, "Cancel Notice")),
            showSyncNotice && (React.createElement("div", { className: "row-span-1 md:w-8/12" },
                React.createElement(CButton, { id: "sync-notice", className: `
              ${buttonStyles} `, onClick: () => {
                        void handleSyncNotice();
                    }, disabled: syncingNotice, middleClasses: 'col-span-8 text-left ml-2', startIcon: syncingNotice ? (React.createElement("div", { className: "loader ease-linear m-auto rounded-full border-4 text-center border-gray-200 h-5 w-5" })) : (React.createElement(RefreshIcon, { className: "w-full text-gray-750" })) }, "Sync Notice"))),
            showCancelModal && (React.createElement(CancelNoticeModal, { notice: notice, setOpen: setShowCancelModal, setToastMessage: setToastMessage, setCancelSuccessModalConfig: setCancelSuccessModalConfig, invoice: invoice, isPublisher: isPublisher, newspaper: newspaper, isInvoicedOutsideColumn: isInvoicedOutsideColumn })),
            showCancelInvoiceModal && exists(invoice) && (React.createElement(CancelInvoiceModal, { setOpen: setShowCancelInvoiceModal, setToastMessage: setToastMessage, notice: notice, newspaper: newspaper, invoice: invoice })),
            showProcessPaymentModal && exists(invoice) && (React.createElement(ProcessPaymentModal, { setOpen: setShowProcessPaymentModal, setProcessCheck: setShowProcessCheckModal, invoice: invoice, newspaper: newspaper })),
            showProcessCheckModal && exists(invoice) && (React.createElement(ProcessCheckModal, { setOpen: setShowProcessCheckModal, setToastMessage: setToastMessage, notice: notice, invoice: invoice, newspaper: newspaper })),
            showPayInvoiceNoticePastDueModal && exists(invoice) && (React.createElement(PayInvoiceNoticePastDueModal, { setOpen: setShowPayInvoiceNoticePastDueModal, invoice: invoice, notice: notice, onPayInvoiceClicked: goToInvoicePayment })),
            showPreventLatePrepayModal && (React.createElement(PreventLatePrepayModal, { setOpen: setShowPreventLatePrepayModal, notice: notice })),
            cancelSuccessModalConfig && (React.createElement(CancelNoticeSuccessModal, { cancelSuccessModalConfig: cancelSuccessModalConfig, setCancelSuccessModalConfig: setCancelSuccessModalConfig })))));
};
export default connect(null, { push })(InvoiceActions);
