var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import SubmitButton from 'components/SubmitButton';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, UpDownSelectorIcon } from 'icons';
import SettingsHeader from '../SettingsHeader';
const initialReminders = [
    { id: 1, label: '1 day overdue', value: '1-day-overdue' }
];
const reminderFrequencies = [
    { id: 1, label: 'every 7 days after', value: 'every-7-days' }
];
const ReminderSelector = ({ reminder, reminderList, onChange }) => {
    return (React.createElement(Listbox, { value: reminder.id, onChange: onChange }, ({ open }) => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "relative" },
            React.createElement(Listbox.Button, { className: "bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm" },
                React.createElement("span", { className: "block truncate" }, reminder.label),
                React.createElement("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" },
                    React.createElement(UpDownSelectorIcon, { className: "h-5 w-5 text-gray-400" }))),
            React.createElement(Transition, { show: open, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                React.createElement(Listbox.Options, { className: "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" }, reminderList.map(reminder => (React.createElement(Listbox.Option, { key: reminder.id, value: reminder.id }, ({ selected, active }) => (React.createElement("div", { className: `${active ? 'text-white bg-blue-600' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9` },
                    React.createElement("span", { className: `${selected ? 'font-semibold' : 'font-normal'} block truncate` }, reminder.label),
                    selected && (React.createElement("span", { className: `${active ? 'text-white' : 'text-blue-600'} absolute inset-y-0 right-0 flex items-center pr-2` },
                        React.createElement(CheckIcon, { className: "h-5 w-5" })))))))))))))));
};
const InvoiceReminders = ({ activeOrganization }) => {
    const [sendInvoiceReminders, setSendInvoiceReminders] = useState(true);
    const [initialReminder, setInitialReminder] = useState(initialReminders[0]);
    const [reminderFrequency, setReminderFrequency] = useState(reminderFrequencies[0]);
    const [saving, setSaving] = useState(false);
    useEffect(() => {
        setSendInvoiceReminders(!!activeOrganization.data().sendInvoiceReminders);
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    const updateReminders = () => __awaiter(void 0, void 0, void 0, function* () {
        setSaving(true);
        yield activeOrganization.ref.update({
            sendInvoiceReminders
        });
        setSaving(false);
    });
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Invoice Reminders", description: "Specify how you would like to notify your customers." }),
        React.createElement("div", { className: "flex w-full justify-between px-5 py-5" },
            React.createElement("div", null,
                React.createElement("div", { className: "not-italic font-semibold text-base text-gray-700" }, "Overdue Invoices"),
                React.createElement("div", { className: "not-italic font-normal text-base text-gray-500" }, "Automatically send email notifications to advertiser with overdue, unpaid notices.")),
            React.createElement("div", null,
                React.createElement("button", { type: "button", className: `mt-3 flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 ${sendInvoiceReminders ? 'bg-blue-650 bg-opacity-75' : 'bg-gray-200'}`, onClick: () => {
                        setSendInvoiceReminders(!sendInvoiceReminders);
                    } },
                    React.createElement("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-400 ${sendInvoiceReminders && 'bg-blue-650 bg-opacity-75'}` }),
                    React.createElement("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${sendInvoiceReminders && 'translate-x-5'}` })))),
        sendInvoiceReminders && (React.createElement("div", { className: "flex w-full px-5 pb-5 items-center" },
            React.createElement("span", { className: "font-semibold text-base text-gray-700 pr-5" }, "Send when"),
            React.createElement(ReminderSelector, { reminder: initialReminder, reminderList: initialReminders, onChange: newValue => setInitialReminder(initialReminders[newValue - 1]) }),
            React.createElement("span", { className: "font-semibold text-base text-gray-700 px-5" }, "and"),
            React.createElement(ReminderSelector, { reminder: reminderFrequency, reminderList: reminderFrequencies, onChange: newValue => setReminderFrequency(reminderFrequencies[newValue - 1]) }))),
        React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
            React.createElement(SubmitButton, { disabled: saving, loading: saving, text: "Save", type: "submit", id: "submit", onClick: updateReminders }))));
};
export default InvoiceReminders;
