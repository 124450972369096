var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { removeUndefinedFields } from '../helpers';
import { exists } from '../types';
import { getErrorReporter } from '../utils/errors';
export const getNewspaperRefForCustomer = (organization) => {
    var _a;
    const { parent } = organization.data() || {};
    const newspaperRef = !!parent && ((_a = organization.data()) === null || _a === void 0 ? void 0 : _a.preferCustomerOnParent)
        ? parent
        : organization.ref;
    return newspaperRef;
};
export const getCustomer = (ctx, user, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperRef = getNewspaperRefForCustomer(organization);
    const existingCustomerQuery = yield ctx
        .customersRef()
        .where('user', '==', user.ref)
        .where('organization', '==', newspaperRef)
        .get();
    if (existingCustomerQuery.docs.length) {
        return existingCustomerQuery.docs[0];
    }
    return null;
});
export const getOrCreateCustomer = (ctx, user, organization, info = null) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    let customer = yield getCustomer(ctx, user, organization);
    let organizationName;
    const userOrg = (yield ((_b = (_a = user.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.get()));
    if (userOrg) {
        organizationName = (_c = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _c === void 0 ? void 0 : _c.name;
    }
    else if ((_d = user.data()) === null || _d === void 0 ? void 0 : _d.organizationName) {
        organizationName = (_e = user.data()) === null || _e === void 0 ? void 0 : _e.organizationName;
    }
    if (!customer) {
        const newspaperRef = getNewspaperRefForCustomer(organization);
        const customerRef = ctx.customersRef().doc(`${user.id}_${newspaperRef.id}`);
        const customerData = Object.assign({ organization: newspaperRef, user: user.ref, internalID: '', userName: (_f = user.data()) === null || _f === void 0 ? void 0 : _f.name, firstName: (_g = user.data()) === null || _g === void 0 ? void 0 : _g.firstName, lastName: (_h = user.data()) === null || _h === void 0 ? void 0 : _h.lastName, address: ((_j = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _j === void 0 ? void 0 : _j.address) || ((_k = user.data()) === null || _k === void 0 ? void 0 : _k.address) || null, addressLine2: ((_l = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _l === void 0 ? void 0 : _l.addressLine2) || ((_m = user.data()) === null || _m === void 0 ? void 0 : _m.addressLine2) || null, city: ((_o = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _o === void 0 ? void 0 : _o.city) || ((_p = user.data()) === null || _p === void 0 ? void 0 : _p.city) || null, state: ((_q = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _q === void 0 ? void 0 : _q.state) || ((_r = user.data()) === null || _r === void 0 ? void 0 : _r.state) || null, zipCode: ((_s = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _s === void 0 ? void 0 : _s.zipCode) || ((_t = user.data()) === null || _t === void 0 ? void 0 : _t.zipCode) || null, phone: ((_u = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _u === void 0 ? void 0 : _u.phone) || ((_v = user.data()) === null || _v === void 0 ? void 0 : _v.phone) || null }, (organizationName && { organizationName }));
        yield customerRef.set(removeUndefinedFields(customerData));
        customer = (yield customerRef.get());
    }
    if (info) {
        yield customer.ref.update(Object.assign({}, info));
        customer = (yield customer.ref.get());
    }
    return customer;
});
export const getCustomerName = (customer, user, concatenateFirstAndLastName) => {
    var _a, _b;
    const customerName = (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data()) === null || _a === void 0 ? void 0 : _a.userName) === null || _b === void 0 ? void 0 : _b.trim();
    const userName = user.data().name.trim();
    let name;
    if (concatenateFirstAndLastName) {
        if (customerName) {
            name = customerName;
        }
        else if ((customer === null || customer === void 0 ? void 0 : customer.data().firstName) && (customer === null || customer === void 0 ? void 0 : customer.data().lastName)) {
            name = `${customer === null || customer === void 0 ? void 0 : customer.data().firstName} ${customer === null || customer === void 0 ? void 0 : customer.data().lastName}`;
        }
        else {
            name =
                user.data().name || `${user.data().firstName} ${user.data().lastName}`;
        }
        name = (name || '').trim();
    }
    return customerName || name || userName || '';
};
export const userHasCustomerWithBulkPaymentEnabled = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    if (!user) {
        return false;
    }
    const customerWithBulkPaymentEnabled = yield ctx
        .customersRef()
        .where('user', '==', user.ref)
        .where('bulkPaymentEnabled', '==', true)
        .get();
    if (customerWithBulkPaymentEnabled.docs.length) {
        return true;
    }
    return false;
});
export const getCustomerOrganization = (ctx, client, organization) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(client)) {
        return null;
    }
    const newspaperRef = getNewspaperRefForCustomer(organization);
    const existingCustomerOrgQuery = yield ctx
        .customerOrganizationsRef()
        .where('client', '==', client.ref)
        .where('organization', '==', newspaperRef)
        .get();
    if (existingCustomerOrgQuery.docs.length) {
        if (existingCustomerOrgQuery.docs.length > 1) {
            getErrorReporter().logAndCaptureWarning('More than one customer organization exists for the following client organization pair', {
                client: client.id,
                organization: organization.id
            });
        }
        return existingCustomerOrgQuery
            .docs[0];
    }
    return null;
});
export const getOrCreateCustomerOrganization = (ctx, client, organization) => __awaiter(void 0, void 0, void 0, function* () {
    var _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
    const newspaperRef = getNewspaperRefForCustomer(organization);
    let customerOrganization = yield getCustomerOrganization(ctx, client, organization);
    if (!customerOrganization) {
        const customerOrgRef = ctx
            .customerOrganizationsRef()
            .doc(`${client.id}_${newspaperRef.id}`);
        // when we create the customer org, add contact fields
        // from the organization
        yield customerOrgRef.set({
            organization: newspaperRef,
            client: client.ref,
            internalID: ((_w = client.data()) === null || _w === void 0 ? void 0 : _w.internalID) || '',
            name: ((_x = client.data()) === null || _x === void 0 ? void 0 : _x.name) || '',
            address: ((_y = client.data()) === null || _y === void 0 ? void 0 : _y.address) || null,
            addressLine2: ((_z = client.data()) === null || _z === void 0 ? void 0 : _z.addressLine2) || null,
            city: ((_0 = client.data()) === null || _0 === void 0 ? void 0 : _0.city) || null,
            state: ((_1 = client.data()) === null || _1 === void 0 ? void 0 : _1.state) || null,
            zipCode: ((_2 = client.data()) === null || _2 === void 0 ? void 0 : _2.zipCode) || null,
            phone: ((_3 = client.data()) === null || _3 === void 0 ? void 0 : _3.phone) || null
        });
        customerOrganization = (yield customerOrgRef.get());
    }
    else {
        // check to see if we need to update nonexisting fields on the customer organization
        const updates = {
            name: !((_5 = (_4 = customerOrganization.data()) === null || _4 === void 0 ? void 0 : _4.name) === null || _5 === void 0 ? void 0 : _5.trim()) && ((_6 = client.data()) === null || _6 === void 0 ? void 0 : _6.name)
                ? (_7 = client.data()) === null || _7 === void 0 ? void 0 : _7.name
                : undefined,
            address: !((_8 = customerOrganization.data()) === null || _8 === void 0 ? void 0 : _8.address)
                ? ((_9 = client.data()) === null || _9 === void 0 ? void 0 : _9.address) || undefined
                : undefined,
            addressLine2: !((_10 = customerOrganization.data()) === null || _10 === void 0 ? void 0 : _10.addressLine2) &&
                ((_11 = customerOrganization.data()) === null || _11 === void 0 ? void 0 : _11.addressLine2) !== ''
                ? ((_12 = client.data()) === null || _12 === void 0 ? void 0 : _12.addressLine2) || undefined
                : undefined,
            city: !((_13 = customerOrganization.data()) === null || _13 === void 0 ? void 0 : _13.city)
                ? ((_14 = client.data()) === null || _14 === void 0 ? void 0 : _14.city) || undefined
                : undefined,
            state: !((_15 = customerOrganization.data()) === null || _15 === void 0 ? void 0 : _15.state)
                ? ((_16 = client.data()) === null || _16 === void 0 ? void 0 : _16.state) || undefined
                : undefined,
            zipCode: !((_17 = customerOrganization.data()) === null || _17 === void 0 ? void 0 : _17.zipCode)
                ? ((_18 = client.data()) === null || _18 === void 0 ? void 0 : _18.zipCode) || undefined
                : undefined,
            phone: !((_19 = customerOrganization.data()) === null || _19 === void 0 ? void 0 : _19.phone)
                ? ((_20 = client.data()) === null || _20 === void 0 ? void 0 : _20.phone) || undefined
                : undefined
        };
        removeUndefinedFields(updates);
        if (Object.keys(updates).length) {
            yield customerOrganization.ref.update(updates);
        }
    }
    return customerOrganization;
});
export const getCustomerOrganizationName = (customerOrganization, customer) => {
    return ((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().name) ||
        (customer === null || customer === void 0 ? void 0 : customer.data().organizationName) ||
        '').trim();
};
export const getOrCreateCustomerOrganizationForNotice = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    const { filedBy, newspaper } = notice.data();
    if (!filedBy)
        return null;
    const clientOrgSnapshot = yield filedBy.get();
    const newspaperSnapshot = yield newspaper.get();
    return getOrCreateCustomerOrganization(ctx, clientOrgSnapshot, newspaperSnapshot);
});
export const filerOrgHasCustomerOrgWithBulkPaymentEnabled = (ctx, client) => __awaiter(void 0, void 0, void 0, function* () {
    if (!client) {
        return false;
    }
    const customerOrgsWithBulkPaymentEnabled = yield ctx
        .customerOrganizationsRef()
        .where('client', '==', client.ref)
        .where('bulkPaymentEnabled', '==', true)
        .get();
    if (customerOrgsWithBulkPaymentEnabled.docs.length) {
        return true;
    }
    return false;
});
export const getShouldInvoiceCustomerOrCustomerOrgOutsideColumn = (customer) => __awaiter(void 0, void 0, void 0, function* () {
    var _21, _22, _23, _24, _25, _26, _27, _28, _29;
    if (!customer)
        return null;
    // Check if the newspaper allow invoicing outside Column
    const newspaper = yield ((_21 = customer.data().organization) === null || _21 === void 0 ? void 0 : _21.get());
    if (!((_22 = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _22 === void 0 ? void 0 : _22.allowInvoiceOutsideColumn))
        return false;
    if (((_23 = customer.data()) === null || _23 === void 0 ? void 0 : _23.invoicedOutsideColumn) != null)
        return (_24 = customer.data()) === null || _24 === void 0 ? void 0 : _24.invoicedOutsideColumn;
    // invoiceOutsideColumn will be deprecated at the organization level
    // keep this until the property is moved to customerOrganizations
    if ((_25 = customer.data()) === null || _25 === void 0 ? void 0 : _25.user) {
        const user = yield customer
            .data()
            .user.get();
        const userOrg = yield ((_27 = (_26 = user.data()) === null || _26 === void 0 ? void 0 : _26.organization) === null || _27 === void 0 ? void 0 : _27.get());
        if (((_28 = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _28 === void 0 ? void 0 : _28.invoiceOutsideColumn) != null) {
            return (_29 = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _29 === void 0 ? void 0 : _29.invoiceOutsideColumn;
        }
    }
    return null;
});
/**
 *
 * @param ctx
 * @param noticeSnap
 * @param disconnectedFilerSnap is used if you are passing in a notice draft that does not have a filer on it yet
 * @returns the account number for a notice in the following priority order:
 * 0. notice.syncData.syncCustomerId
 * 1. customer.internalID
 * 2. customerOrg.internalID
 * 3. null
 */
export const getAccountNumberForNotice = (ctx, noticeSnap, disconnectedFilerSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _30;
    // 0. return the internalID on the notice if exists
    const noticeCustomerId = (_30 = noticeSnap.data().syncData) === null || _30 === void 0 ? void 0 : _30.syncCustomerId;
    if (noticeCustomerId) {
        return {
            id: noticeCustomerId,
            source: 'notice'
        };
    }
    const { filer: filerRef, newspaper: newspaperRef } = noticeSnap.data();
    const filerSnap = disconnectedFilerSnap || (yield (filerRef === null || filerRef === void 0 ? void 0 : filerRef.get()));
    const newspaperSnap = yield (newspaperRef === null || newspaperRef === void 0 ? void 0 : newspaperRef.get());
    // 0a. if no existing filer or newspaper can be identified, return null
    if (!exists(filerSnap) || !exists(newspaperSnap)) {
        return null;
    }
    const customerSnap = yield getOrCreateCustomer(ctx, filerSnap, newspaperSnap);
    // 1. return the internalID on the customer if exists
    const customerInternalId = customerSnap.data().internalID;
    if (customerInternalId) {
        return {
            id: customerInternalId,
            source: 'customer'
        };
    }
    const customerOrganizationSnap = yield getOrCreateCustomerOrganizationForNotice(ctx, noticeSnap);
    // 2. & 3. return the internalID on the customer organization
    // if exists; else return null
    const customerOrgInternalId = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data().internalID;
    if (customerOrgInternalId) {
        return {
            id: customerOrgInternalId,
            source: 'customerorg'
        };
    }
    return null;
});
