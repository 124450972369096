import React, { useState, useEffect, useMemo } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import * as EventTypes from 'lib/types/events';
import LoadingState from 'components/LoadingState';
import EEditionUploaderModal from 'routes/userNoticeList/table/EEditionUploaderModal';
import SuccessModal from 'components/modals/SuccessModal';
import { PartyEmoji } from 'gifs';
import { affidavitUploadEventsToAffidavitUploadDays, DEFAULT_AFFIDAVIT_UPLOAD_DAY_FILTER, filterAndSortAffidavitUploadDays } from './automatedAffidavitSettingsUtils';
import AutomatedAffidavitSettingsHeader from './AutomatedAffidavitSettingsHeader';
import AffidavitUploadDayTable from './AffidavitUploadDayTable';
import AffidavitUploadDrawer from './AffidavitUploadDrawer';
import PublicationVerificationModal from './PublicationVerificationModal';
/**
 * Automated affidavit settings, most notably including a table of e-edition uploads
 */
export default function AutomatedAffidavitSettings({ activeOrganization }) {
    var _a;
    const [loading, setLoading] = useState(true);
    // success message to show after uploading an e-edition or manually validating a notice
    const [successMessage, setSuccessMessage] = useState();
    // allow uploading new e-editions via the e-edition uploader modal, and enable locking in a date
    const [showEEditionUploadModal, setShowEEditionUploadModal] = useState({
        uploadDay: null,
        show: false
    });
    // allow manual notice publication verification by publishers
    const [showVerifyNoticesPublicationModal, setShowVerifyNoticesPublicationModal] = useState(false);
    // list of all upload days associated with the organization
    const [uploadDays, setUploadDays] = useState([]);
    // upload day to show in a detail drawer
    const [drawerUploadDay, setDrawerUploadDay] = useState();
    // current value of the search bar on the affidavit settings
    const [uploadFilter, setUploadFilter] = useState(DEFAULT_AFFIDAVIT_UPLOAD_DAY_FILTER);
    useEffect(() => {
        const unsubscribe = getFirebaseContext()
            .eventsRef()
            .where('type', '==', EventTypes.AFFIDAVIT_VERIFICATION_UPLOAD_SUCCESS)
            .where('data.newspaper', '==', activeOrganization.ref)
            .onSnapshot(snapshot => {
            const uploadDays = affidavitUploadEventsToAffidavitUploadDays(activeOrganization, snapshot.docs);
            setUploadDays(uploadDays);
            setLoading(false);
        });
        return () => unsubscribe && unsubscribe();
    }, [activeOrganization.id]);
    const filteredAffidavituploadDays = useMemo(() => {
        return filterAndSortAffidavitUploadDays(uploadDays, uploadFilter);
    }, [uploadDays, uploadFilter]);
    if (loading)
        return React.createElement(LoadingState, null);
    return (React.createElement("div", { id: "automated-affidavit-settings" },
        successMessage && (React.createElement(SuccessModal, { setOpen: () => {
                setSuccessMessage(undefined);
            }, gif: React.createElement("img", { className: "rounded-full py-4.5 px-3 h-24 bg-yellow-100", src: PartyEmoji }), header: successMessage.header, body: successMessage.body })),
        showEEditionUploadModal.show && (React.createElement(EEditionUploaderModal, { fixedUploadDate: (_a = showEEditionUploadModal.uploadDay) === null || _a === void 0 ? void 0 : _a.publishingDate, setShowEUploadSuccessModal: () => {
                setSuccessMessage({
                    header: 'E-edition submitted',
                    body: 'Your e-edition was successfully submitted. Affidavit automation will start right away.'
                });
                setDrawerUploadDay(undefined);
            }, activeOrganization: activeOrganization, setOpen: newValue => setShowEEditionUploadModal({
                uploadDay: null,
                show: newValue
            }), id: "affidavit-settings-e-edition-upload-modal" })),
        showVerifyNoticesPublicationModal && (React.createElement(PublicationVerificationModal, { setOpen: setShowVerifyNoticesPublicationModal, activeOrganization: activeOrganization, id: "notice-publication-verification-modal", setShowVerifyNoticesPublicationSuccessModal: () => setSuccessMessage({
                header: 'Verification complete',
                body: 'Affidavit automation will start right away.'
            }) })),
        React.createElement(AutomatedAffidavitSettingsHeader, { setShowEEditionUploadModal: setShowEEditionUploadModal, setShowVerifyNoticesPublicationModal: setShowVerifyNoticesPublicationModal, affidavituploadDays: filteredAffidavituploadDays, activeOrganization: activeOrganization, setSuccessMessage: setSuccessMessage, setUploadFilter: setUploadFilter, uploadFilter: uploadFilter }),
        React.createElement(AffidavitUploadDayTable, { setDrawerUploadDay: setDrawerUploadDay, uploadFilter: uploadFilter, uploadDays: filteredAffidavituploadDays }),
        drawerUploadDay && (React.createElement(AffidavitUploadDrawer, { setShowEEditionUploadModal: setShowEEditionUploadModal, affidavitUploadDay: drawerUploadDay, activeOrganization: activeOrganization, closeDrawer: () => setDrawerUploadDay(undefined) }))));
}
