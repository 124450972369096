import React from 'react';
import Tooltip from 'components/Tooltip';
import { ColumnButton } from 'components/ColumnButton';
import classNames from 'classnames';
import { QuestionMarkIcon } from 'icons';
export const CustomerDrawerFooter = ({ isAccountOnHold, setIsAccountOnHold, setShowEditCustomerModal, setShowEditCustomerOrganizationModal }) => {
    return (React.createElement("div", { className: classNames('absolute bottom-0 flex items-center w-full py-4 px-8 border-t bg-white', {
            'justify-between': !setShowEditCustomerOrganizationModal,
            'justify-end': setShowEditCustomerOrganizationModal
        }) },
        setIsAccountOnHold && (React.createElement("div", { className: "flex items-center" },
            React.createElement(ColumnButton, { buttonText: isAccountOnHold ? 'Remove hold' : 'Put account on hold', primary: !isAccountOnHold, size: "lg", destructive: true, onClick: () => {
                    setIsAccountOnHold(!isAccountOnHold);
                } }),
            React.createElement(Tooltip, { helpText: "Restrict this customer from placing a notice.", position: "top" },
                React.createElement(QuestionMarkIcon, { className: "w-4 ml-2.5 text-grey-300" })))),
        setShowEditCustomerModal && (React.createElement(ColumnButton, { id: "edit-customer-drawer-button", buttonText: "Edit", size: "lg", onClick: () => {
                setShowEditCustomerModal(true);
            } })),
        setShowEditCustomerOrganizationModal && (React.createElement(ColumnButton, { id: "edit-organization-drawer-button", size: "lg", buttonText: "Edit", onClick: () => {
                setShowEditCustomerOrganizationModal(true);
            } }))));
};
