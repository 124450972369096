import { safeStringify } from 'lib/utils/stringify';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import MultipleChoiceQuestionInput from './MultipleChoiceQuestionInput';
import NumberQuestionInput from './NumberQuestionInput';
import YesNoQuestionInput from './YesNoQuestionInput';
import FileUploadQuestionInput from './FileUploadQuestionInput';
const QuestionForm = ({ questions, onChange }) => {
    const [values, setValues] = useState({});
    const firstUnansweredQuestionIndex = questions.findIndex(q => values[q.varName] === undefined);
    const allAnswered = firstUnansweredQuestionIndex === -1;
    const visibleQuestions = allAnswered
        ? questions
        : questions.slice(0, firstUnansweredQuestionIndex + 1);
    const handleChange = (e) => {
        setValues(previousValue => (Object.assign(Object.assign({}, previousValue), { [e.varName]: e.value })));
    };
    useEffect(() => {
        onChange({ complete: !isEmpty(values) && allAnswered, values });
    }, [safeStringify(values)]);
    return (React.createElement("div", null, visibleQuestions.map(q => {
        return (React.createElement("div", { className: "py-2", key: q.varName },
            q.type === 'yesno' && (React.createElement(YesNoQuestionInput, { value: values[q.varName], question: q, onChange: handleChange })),
            q.type === 'number' && (React.createElement(NumberQuestionInput, { question: q, value: values[q.varName], onChange: handleChange })),
            q.type === 'multiplechoice' && (React.createElement(MultipleChoiceQuestionInput, { question: q, value: values[q.varName], onChange: handleChange })),
            q.type === 'fileUpload' && (React.createElement(FileUploadQuestionInput, { question: q, onChange: handleChange }))));
    })));
};
export default QuestionForm;
