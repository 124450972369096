var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { TransactionType } from 'lib/enums';
import { toLocaleString } from 'lib/helpers';
import Tooltip from 'components/Tooltip';
import { Help } from '@material-ui/icons';
import { ArrowDownIcon, DownloadIcon } from 'icons';
import { getFirebaseContext } from 'utils/firebase';
const TableCell = ({ children }) => (React.createElement("td", { className: "py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, children));
const PayoutParentRow = ({ rowData, expand, payoutSource, handleExpandClicked, handleDownloadCsvClicked }) => {
    return (React.createElement("tr", null,
        React.createElement(TableCell, null,
            React.createElement("div", { className: "flex justify-center" },
                React.createElement(ArrowDownIcon, { className: `w-4 h-4 cursor-pointer ${!expand ? 'transform -rotate-90' : ''}`, onClick: () => handleExpandClicked(rowData.id) }))),
        payoutSource === 'dwolla' && (React.createElement(TableCell, null,
            React.createElement("p", { className: `text-right ${rowData.type === TransactionType.processing_fee_deduction.label ||
                    rowData.type === TransactionType.refund.label ||
                    (rowData.type === TransactionType.payment.label &&
                        rowData.status === 'cancelled')
                    ? 'text-red-600'
                    : 'text-black'}` }, (rowData.type === TransactionType.processing_fee_deduction.label ||
                rowData.type === TransactionType.refund.label ||
                (rowData.type === TransactionType.payment.label &&
                    rowData.status === 'cancelled')) &&
                '-'))),
        React.createElement(TableCell, null,
            React.createElement("p", { className: `${rowData.type === TransactionType.processing_fee_deduction.label ||
                    rowData.type === TransactionType.refund.label ||
                    (rowData.type === TransactionType.payment.label &&
                        rowData.status === 'cancelled')
                    ? 'text-red-600'
                    : 'text-black'}` }, rowData.amount)),
        React.createElement(TableCell, null,
            React.createElement("div", null)),
        React.createElement(TableCell, null,
            React.createElement("p", null, payoutSource === 'dwolla'
                ? `${toLocaleString(rowData.created)}`
                : `${rowData.created}`)),
        React.createElement(TableCell, null,
            React.createElement("p", null, rowData.arrival_date.length > 0 && payoutSource === 'dwolla'
                ? `${toLocaleString(rowData.arrival_date)}`
                : `${rowData.arrival_date}`)),
        React.createElement(TableCell, null,
            React.createElement("div", { title: "Download CSV" },
                React.createElement(DownloadIcon, { id: "payment-download", className: "h-4 w-4 ml-4 cursor-pointer text-blue-800", onClick: () => handleDownloadCsvClicked(rowData.id) })))));
};
const PayoutChildRow = ({ rowData, payoutSource }) => {
    const viewNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!rowData.noticeId) {
            return;
        }
        const noticeSnap = (yield getFirebaseContext()
            .userNoticesRef()
            .doc(rowData.noticeId)
            .get());
        const { newspaper } = noticeSnap.data();
        window.open(`/notice/${rowData.noticeId}?activeOrg=${newspaper.id}`);
    });
    return (React.createElement("tr", null,
        React.createElement(TableCell, null,
            React.createElement("div", null)),
        payoutSource === 'dwolla' && (React.createElement(TableCell, null,
            React.createElement("p", { className: `text-right ${rowData.type === TransactionType.processing_fee_deduction.label ||
                    rowData.type === TransactionType.refund.label ||
                    (rowData.type === TransactionType.payment.label &&
                        rowData.status === 'cancelled')
                    ? 'text-red-600'
                    : 'text-black'}` }, (rowData.type === TransactionType.processing_fee_deduction.label ||
                rowData.type === TransactionType.refund.label ||
                (rowData.type === TransactionType.payment.label &&
                    rowData.status === 'cancelled')) &&
                '-'))),
        React.createElement(TableCell, null,
            React.createElement("p", { className: `${rowData.type === TransactionType.processing_fee_deduction.label ||
                    rowData.type === TransactionType.refund.label ||
                    rowData.type === TransactionType.paymentReversed.label ||
                    (rowData.type === TransactionType.payment.label &&
                        rowData.status === 'cancelled')
                    ? 'text-red-600'
                    : 'text-black'}` }, rowData.amount)),
        React.createElement(TableCell, null,
            React.createElement("div", null,
                React.createElement("span", { className: "inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-indigo-200 text-indigo-800" }, rowData.type === TransactionType.processing_fee_deduction.label
                    ? 'Processing Fee'
                    : rowData.type === TransactionType.payment.label &&
                        rowData.status === 'cancelled'
                        ? 'Refund'
                        : rowData.type === TransactionType.payment.label
                            ? 'Notice Payment'
                            : rowData.type === TransactionType.manualPayment.label
                                ? 'Manual Payment'
                                : rowData.type === TransactionType.dividend.label
                                    ? 'Dividend'
                                    : rowData.type === TransactionType.paymentReversed.label
                                        ? 'Payout Reversed'
                                        : 'Refund'),
                rowData.type === TransactionType.processing_fee_deduction.label && (React.createElement(Tooltip, { classes: "inline-block align-middle ml-2 font-normal", helpText: "This fee is due to Column for a notice paid outside the platform." },
                    React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" }))),
                rowData.type === TransactionType.manualPayment.label &&
                    rowData.reason && (React.createElement(Tooltip, { classes: "inline-block align-middle ml-2 font-normal", helpText: rowData.reason, position: 'right' },
                    React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" }))),
                rowData.transactionTypeValue === TransactionType.feeRefund.key && (React.createElement(Tooltip, { classes: "inline-block align-middle ml-2 font-normal", helpText: "This is a refund for a previously deducted processing fee, due to cancelling an invoice paid outside Column." },
                    React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" }))))),
        React.createElement(TableCell, null,
            React.createElement("div", null)),
        React.createElement(TableCell, null,
            React.createElement("div", null)),
        React.createElement(TableCell, null, rowData.type === TransactionType.dividend.label ? (React.createElement("button", { type: "button", className: "px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs flex text-blue-600", onClick: () => window.open(rowData.receipt) }, "COLUMN PAYOUT")) : ![
            TransactionType.manualPayment.label,
            TransactionType.paymentReversed.label
        ].includes(rowData.type) ? (React.createElement("button", { type: "button", className: `${!rowData.noticeId
                ? 'cursor-not-allowed pointer-events-none opacity-50'
                : 'hover:bg-gray-50'} px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs text-blue-600`, onClick: () => viewNotice() }, "View Notice")) : (React.createElement(React.Fragment, null)))));
};
const PayoutGroupRows = ({ group, payoutSource, handleDownloadCsvClicked }) => {
    const [expanded, setExpanded] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(PayoutParentRow, { rowData: group.parent, expand: expanded, payoutSource: payoutSource, handleExpandClicked: () => setExpanded(!expanded), handleDownloadCsvClicked: handleDownloadCsvClicked }),
        expanded &&
            group.children.map(child => (React.createElement(PayoutChildRow, { key: child.id, rowData: child, payoutSource: payoutSource })))));
};
export default PayoutGroupRows;
