var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import TailwindModal from 'components/TailwindModal';
import api from 'api';
import DwollaIav from './Dwolla';
import { DWOLLA_ENV } from '../../constants';
const AccountVerificationOptionsModal = ({ customerId, closeModal, secureLoginHandleClick, verifyAccountHandleClick, user }) => {
    const [iavToken, setIavToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAccountDetailsModal, setShowAccountDetailsModal] = useState(false);
    const [showOptionsModal, setShowOptionsModal] = useState(true);
    const addUnverifiedFundingSource = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!customerId) {
            console.error('customerId is undefined');
            return;
        }
        setLoading(true);
        const result = yield api.post('dwolla/generate-customer-iav', {
            customerId
        });
        if (result.success) {
            setLoading(false);
            setIavToken(result === null || result === void 0 ? void 0 : result.token);
            setShowAccountDetailsModal(true);
            setShowOptionsModal(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        showOptionsModal && (React.createElement(TailwindModal, { header: 'Verify your bank account', close: () => closeModal(), noExitOutsideModal: true, widthPct: 30 },
            React.createElement("div", { className: "flex flex-col mr-4" },
                React.createElement("div", { className: "font-normal text-sm text-gray-700 mb-8" }, "Column offers two ways to link your account. Upon verifying your bank account, Column will automatically send payouts to your account."),
                React.createElement("button", { className: `flex justify-center w-100 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm items-center py-2`, type: "button", onClick: () => {
                        setShowOptionsModal(false);
                        secureLoginHandleClick();
                    } }, "Verify by secure bank login"),
                (user === null || user === void 0 ? void 0 : user.bankAccountsEnabled) && (React.createElement("button", { className: `flex justify-center mt-2 w-100 rounded-md font-semibold border border-blue-500 text-blue-600 text-sm items-center py-2`, type: "button", onClick: () => {
                        void addUnverifiedFundingSource();
                    } },
                    loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                    'Verify by routing & account number'))))),
        showAccountDetailsModal && (React.createElement(DwollaIav, { customerToken: iavToken, environment: DWOLLA_ENV, onClose: () => {
                setShowAccountDetailsModal(false);
                verifyAccountHandleClick();
            } }))));
};
export default AccountVerificationOptionsModal;
