import React from 'react';
/**
 * Default header component for our settings pages
 */
export default function SettingsHeader({ header, description, children }) {
    return (React.createElement("div", { className: "flex pl-7 pr-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "w-full font-medium" },
            React.createElement("p", { className: "text-xl text-blue-800" }, header),
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" }, description)),
        children && (React.createElement("div", { className: "w-full flex justify-end items-end" }, children))));
}
