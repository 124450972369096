import { STRIPE } from 'lib/constants';
import { exists } from 'lib/types';
export const getSavedPaymentsAccess = (payInvoiceData, user) => {
    var _a, _b, _c, _d, _e;
    if (!payInvoiceData)
        return;
    let disableSavedCards = false;
    let disableACH = false;
    let disableSavedPaymentMethods = false;
    const { advertiser, invoice, gateway, preventLatePayment } = payInvoiceData;
    // If the invoice is billed to an organization but the user has a different
    // activeOrganization. In this case we do not show saved payment methods
    const activeOrganizationMismatch = !!invoice.data().advertiserOrganization &&
        ((_b = (_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.activeOrganization) === null || _b === void 0 ? void 0 : _b.id) !==
            ((_c = invoice.data().advertiserOrganization) === null || _c === void 0 ? void 0 : _c.id);
    /**
     * If the invoice does not have an advertiser organization but the advertiser
     * on the invoice is part of an organization, we do not show saved payment methods
     * or provide the option to save a credit card to the Stripe customer.
     */
    const individualFilerAndOrgFilerMismatch = !invoice.data().advertiserOrganization &&
        ((_d = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data()) === null || _d === void 0 ? void 0 : _d.activeOrganization);
    if (!exists(user) ||
        !exists(advertiser) ||
        preventLatePayment ||
        gateway !== STRIPE) {
        disableSavedPaymentMethods = true;
    }
    else {
        const { advertiserOrganization } = invoice.data();
        if (advertiserOrganization) {
            const sameOrg = advertiserOrganization.id === ((_e = user.data().activeOrganization) === null || _e === void 0 ? void 0 : _e.id);
            disableSavedPaymentMethods = !sameOrg;
        }
        else {
            disableSavedPaymentMethods = advertiser.id !== user.id;
        }
        if (individualFilerAndOrgFilerMismatch || activeOrganizationMismatch)
            disableSavedPaymentMethods = true;
        if (invoice.data().disableACH)
            disableACH = true;
    }
    if (disableSavedPaymentMethods) {
        disableACH = true;
        disableSavedCards = true;
    }
    return {
        disableSavedCards,
        disableACH,
        activeOrganizationMismatch
    };
};
