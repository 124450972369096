var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InvoiceStatus } from '../enums';
import { getInvoiceTotalSubtotalAndFees } from '../pricing';
import { exists } from '../types';
import { isElavonInvoice, isPaywayInvoice } from '../types/invoices';
export const getRecipientName = (recipient) => {
    return recipient.organizationName
        ? recipient.organizationName
        : `${recipient.firstName} ${recipient.lastName}`;
};
/**
 * Get the email address that should be used for notifications related to an invoice.
 */
export const getNotificationEmailAddress = (notice, invoice) => __awaiter(void 0, void 0, void 0, function* () {
    const { invoiceRecipient } = notice.data();
    if (invoiceRecipient && invoiceRecipient.type === 'email') {
        return invoiceRecipient.email;
    }
    const advertiserSnap = yield invoice.data().advertiser.get();
    return advertiserSnap.data().email;
});
/**
 * Determine if an invoice was paid directly to publisher (ex: cash or check)
 * and not through a gateway like Stripe or Payway.
 */
export const isPaidDirectToPublisher = (invoice) => {
    return (exists(invoice) &&
        !!invoice.data().paid_outside_stripe &&
        !isPaywayInvoice(invoice) &&
        !isElavonInvoice(invoice));
};
/**
 * Determine if an invoice was paid outside of our normal Stripe flow.
 * Examples:
 *  - Paid via payway, elavon, etc.
 *  - Paid via cash (publisher marked as paid manually)
 */
export const isPaidOutsideStripe = (invoice) => {
    if (!exists(invoice)) {
        return false;
    }
    return (invoice.data().paid_outside_stripe || invoice.data().invoiceOutsideColumn);
};
/**
 * Determine if an invoice's price is the default (based on the notice line items) or if it was
 * customized at invoicing time.
 */
export const isInvoicePriceAdjusted = (notice, invoice) => {
    const noticePricing = notice.data().pricing;
    const invoicePricing = getInvoiceTotalSubtotalAndFees(invoice);
    if (noticePricing.total !== invoicePricing.totalInCents ||
        noticePricing.subtotal !== invoicePricing.subtotalInCents) {
        return true;
    }
    return false;
};
/**
 * Payment or partial refund has occurred when the status of the invoice is one of the following
 * 1. paid (value = 3)
 * 2. partially_refunded (value = 6)
 * 3. inititated (an ACH payment started but not fully processed, value = 2)
 */
export const hasPaymentOrPartialRefund = (invoice) => {
    return [
        InvoiceStatus.paid.value,
        InvoiceStatus.partially_refunded.value,
        InvoiceStatus.initiated.value
    ].includes(invoice.data().status);
};
