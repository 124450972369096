var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { getAdTemplateCacheID } from 'lib/types/templates';
import { getFirebaseContext } from 'utils/firebase';
import { callIndesignServer } from 'lib/requests';
import { IndesignRoute } from 'lib/constants';
import ColumnSelect from 'components/select/ColumnSelect';
import { exists } from 'lib/types';
import useDebounce from 'utils/UseDebounce';
import LoadingState from 'components/LoadingState';
import LayoutSettingsInputWrapper from './LayoutSettingsInputWrapper';
import { INDESIGN_URL } from '../../../../../constants';
import SettingsCard from './SettingsCard';
import { getDynamicFooterFromLayoutSettingsAndNumberOfRuns, getDynamicHeaderFromLayoutSettingsAndNumberOfRuns, SAMPLE_NOTICE_CONTENT_OPTIONS } from './basicLayoutSettingsUtils';
/**
 * Show a preview of what notices would look like when run in the paper. Previews can
 * be toggled and configured through a series of dropdowns.
 */
export default function TestNoticePreview({ activeOrganization, layoutSettings }) {
    // Control the template we want to use for previews
    const [availableTemplates, setAvailableTemplates] = React.useState([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState();
    useEffect(() => {
        const unsubscribe = getFirebaseContext()
            .adTemplatesRef()
            .where('organization', '==', activeOrganization.ref)
            .onSnapshot((snapshot) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const defaultTemplate = yield ((_a = activeOrganization
                .data()
                .adTemplate) === null || _a === void 0 ? void 0 : _a.get());
            if (exists(defaultTemplate))
                setSelectedTemplate(defaultTemplate);
            setAvailableTemplates(snapshot.docs);
        }));
        return () => unsubscribe();
    }, [activeOrganization.id]);
    // Configure the rate we want to use for previews
    const [availableRates, setAvailableRates] = React.useState([]);
    const [selectedRate, setSelectedRate] = React.useState();
    useEffect(() => {
        const ratesSubscription = getFirebaseContext()
            .ratesRef()
            .where('organization', '==', activeOrganization.ref)
            .onSnapshot((snapshot) => __awaiter(this, void 0, void 0, function* () {
            const defaultRate = yield activeOrganization
                .data()
                .defaultLinerRate.get();
            if (exists(defaultRate))
                setSelectedRate(defaultRate);
            setAvailableRates(snapshot.docs.filter(doc => !doc.data().archived));
        }));
        return () => ratesSubscription();
    }, [activeOrganization.id]);
    // Configure which value we want to use for notice content
    const [noticePreviewContent, setPreviewNoticeContent] = useState(SAMPLE_NOTICE_CONTENT_OPTIONS[0].value);
    // Configure how many runs we want to test
    const [numberOfRuns, setNumberOfRuns] = useState(1);
    // Generate preview images given the input parameters
    const [oneColDisplayParams, setOneColDisplayParams] = useState();
    const [twoColDisplayParams, setTwoColDisplayParams] = useState();
    const regenerateNoticePreviews = () => __awaiter(this, void 0, void 0, function* () {
        setOneColDisplayParams(undefined);
        setTwoColDisplayParams(undefined);
        if (!exists(selectedRate) || !exists(selectedTemplate))
            return;
        const dynamicHeader = getDynamicHeaderFromLayoutSettingsAndNumberOfRuns(layoutSettings, numberOfRuns);
        const dynamicFooter = yield getDynamicFooterFromLayoutSettingsAndNumberOfRuns(layoutSettings, numberOfRuns);
        const noticeHtml = noticePreviewContent + dynamicFooter;
        const serverParameters = {
            linerMaxColumns: 2,
            optimizeColumns: false,
            outputToBase64: true,
            dynamicHeader,
            dynamicFooter,
            downloadUrl: selectedTemplate.data().downloadUrl,
            noticeId: 'test',
            quality: 'high',
            columns: 1,
            format: 'jpg',
            html: noticeHtml,
            id: getAdTemplateCacheID(selectedTemplate)
        };
        const oneColumnResp = yield callIndesignServer(`${INDESIGN_URL}${IndesignRoute.liner_ad_image}`, serverParameters);
        setOneColDisplayParams(oneColumnResp.data);
        const twoColumnResp = yield callIndesignServer(`${INDESIGN_URL}${IndesignRoute.liner_ad_image}`, Object.assign(Object.assign({}, serverParameters), { columns: 2 }));
        setTwoColDisplayParams(twoColumnResp.data);
    });
    // Run the preview generation function when the layout settings change, but debounce
    // to only run it every second
    const debouncedLayoutSettings = useDebounce(layoutSettings, 1000);
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            yield regenerateNoticePreviews();
        }))();
    }, [
        debouncedLayoutSettings,
        noticePreviewContent,
        selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id,
        selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.id,
        numberOfRuns
    ]);
    if (!selectedRate || !selectedTemplate)
        return null;
    return (React.createElement(SettingsCard, { header: "Preview Notices", subheader: "See a preview of what notices will look like with your current template settings." },
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Rate"),
            React.createElement(ColumnSelect, { options: availableRates.map(rate => ({
                    value: rate.id,
                    label: rate.data().description
                })), value: selectedRate.id, onChange: newValue => {
                    if (!newValue)
                        return;
                    const matchedRate = availableRates.find(rate => rate.id === newValue);
                    if (!matchedRate)
                        return;
                    setSelectedRate(matchedRate);
                } })),
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Template"),
            React.createElement(ColumnSelect, { options: availableTemplates.map(template => ({
                    value: template.id,
                    label: template.data().name
                })), value: selectedTemplate.id, onChange: newValue => {
                    if (!newValue)
                        return;
                    const matchedTemplate = availableTemplates.find(template => template.id === newValue);
                    if (!matchedTemplate)
                        return;
                    setSelectedTemplate(matchedTemplate);
                } })),
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Notice Preview Content"),
            React.createElement(ColumnSelect, { onChange: newValue => {
                    var _a;
                    return setPreviewNoticeContent(((_a = SAMPLE_NOTICE_CONTENT_OPTIONS.find(option => option.value === newValue)) === null || _a === void 0 ? void 0 : _a.value) || '');
                }, options: SAMPLE_NOTICE_CONTENT_OPTIONS, value: noticePreviewContent })),
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Number of runs"),
            React.createElement(ColumnSelect, { options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' }
                ], onChange: newValue => setNumberOfRuns(parseInt(newValue, 10)), value: numberOfRuns.toString() })),
        !oneColDisplayParams && !twoColDisplayParams && React.createElement(LoadingState, null),
        oneColDisplayParams && (React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Sample one column notice"),
            React.createElement("img", { src: oneColDisplayParams.imgs[0] }))),
        twoColDisplayParams && (React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Sample two column notice"),
            React.createElement("img", { src: twoColDisplayParams.imgs[0] })))));
}
