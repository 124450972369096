var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { connect } from 'react-redux';
import { ExportFormatType } from 'lib/enums';
import { exists } from 'lib/types';
import { EnoticeError } from 'lib/errors';
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from 'components/CButton';
import { CalendarIcon } from 'icons';
import { areSameDayUTC } from 'lib/helpers';
const mapStateToProps = (state) => ({
    user: state.auth.user,
    showAllOrgsNotices: state.auth.showAllOrgsNotices
});
const getFormatPreferences = (user) => {
    const prefs = {
        linerFormat: user.data().linerExportPreference || ExportFormatType.pdf.value,
        displayFormat: user.data().displayExportPreference || ExportFormatType.jpg.value
    };
    return prefs;
};
const GENERIC_ERROR = 'Something went wrong. Please try again.';
const BulkDownloadModal = ({ setOpen, activeOrganization, user, showAllOrgsNotices }) => {
    const [linerFormat, setLinerFormat] = useState();
    const [displayFormat, setDisplayFormat] = useState();
    const [relevantTimestamps, setRelevantTimestamps] = useState([]);
    const [downloadTimestamp, setDownloadTimestamp] = useState(new Date().getTime());
    const [requiresConfirmation, setRequiresConfirmation] = useState();
    const [fetching, setFetching] = useState(false);
    const [fetchingPaidNotices, setFetchingPaidNotices] = useState(true);
    const [error, setError] = useState('');
    const [loadingTimestamps, setLoadingTimestamps] = useState(true);
    const [downloadURL, setDownloadURL] = useState('');
    const buttonStyles = 'mt-6 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition';
    useEffect(() => {
        setError('');
    }, [linerFormat, displayFormat, downloadTimestamp]);
    const getAllUniquePublishingDays = () => __awaiter(void 0, void 0, void 0, function* () {
        const facets = {
            publicationtimestamps: {
                type: 'value',
                name: 'dates',
                size: 250,
                sort: { value: 'desc' }
            }
        };
        const filters = [
            { iscancelled: Number(false) },
            { isarchived: Number(false) },
            { isdraft: Number(false) }
        ];
        const { facets: { publicationtimestamps } } = yield api.post('search/usernotices/facets', {
            search: '',
            showAllOrgsNotices,
            facets,
            filters
        });
        const relevantTimestamps = publicationtimestamps[0].data
            .map((v) => Number(v.value))
            .sort();
        const currentTimestamp = Date.now();
        const downloadTimestamp = relevantTimestamps.find((timestamp) => timestamp > currentTimestamp) || relevantTimestamps[relevantTimestamps.length - 1];
        setDownloadTimestamp(downloadTimestamp);
        setRelevantTimestamps(relevantTimestamps);
        setLoadingTimestamps(false);
    });
    useEffect(() => {
        void getAllUniquePublishingDays();
    }, []);
    const disableDate = (dateIn) => {
        if (!dateIn) {
            return false;
        }
        return relevantTimestamps.every(relevantTimestamp => !areSameDayUTC(dateIn, relevantTimestamp));
    };
    const handleDownload = (downloadOnlyPaidNotices) => __awaiter(void 0, void 0, void 0, function* () {
        const filters = [
            { publicationtimestamps: [downloadTimestamp] },
            { isdraft: [Number(false)] },
            { iscancelled: Number(false) },
            { isarchived: Number(false) }
        ];
        const req = {
            search: '',
            filters,
            size: 250,
            current: 1,
            showAllOrgsNotices,
            isPublisher: true,
            activeOrganizationId: activeOrganization.id
        };
        const resp = yield api.post('search/usernotices', req);
        const { results } = resp;
        const noticeIds = results.map((hit) => hit.id);
        const timer = setTimeout(() => {
            setFetching(false);
            setFetchingPaidNotices(false);
            setError(GENERIC_ERROR);
        }, 60000);
        try {
            const { url, errors, noticesRequireConfirmation } = (yield api.post('documents/zip', {
                activeOrganizationId: activeOrganization.id,
                noticeIds,
                displayFormat,
                linerFormat,
                date: moment.utc(downloadTimestamp).toLocaleString() ||
                    new Date().toDateString(),
                downloadOnlyPaidNotices,
                showAllOrgsNotices
            }));
            setDownloadURL(url);
            if (noticesRequireConfirmation && noticesRequireConfirmation.length) {
                setRequiresConfirmation(noticesRequireConfirmation);
            }
            else {
                window.open(url);
            }
            if (errors && errors.length)
                throw EnoticeError.withMessage(`Unable to retrieve requested filetypes for notices with the following id(s): ${errors
                    .map(err => err.noticeId)
                    .join(', ')}. Contact help@column.us.`);
            setFetching(false);
            setFetchingPaidNotices(false);
        }
        catch (err) {
            setFetching(false);
            setFetchingPaidNotices(false);
            setError(err instanceof EnoticeError ? err.message : GENERIC_ERROR);
        }
        clearTimeout(timer);
    });
    useEffect(() => {
        if (!exists(user))
            return;
        const prefs = getFormatPreferences(user);
        setLinerFormat(prefs.linerFormat);
        setDisplayFormat(prefs.displayFormat);
    }, [user]);
    const noticesAvailable = () => requiresConfirmation && downloadTimestamp;
    return (React.createElement(FreeformCModal, { header: "Bulk Download", body: requiresConfirmation && requiresConfirmation.length
            ? 'Warning: You’ve marked notice(s) included in this download as requiring upfront payment, but some have not yet been paid.'
            : 'Select a date and file types to download all public notices to be published on a certain day.', setOpen: setOpen }, requiresConfirmation ? (React.createElement("section", { className: "mt-4" },
        React.createElement("div", { className: "mb-4" }, requiresConfirmation.map((notice, index) => (React.createElement("div", { key: index, className: "ml-2 text-gray-600 text-sm" },
            "\u2022 \"",
            notice.noticeName,
            "\" filed by ",
            notice.advertiserName)))),
        React.createElement("div", null,
            React.createElement("span", { className: "text-sm font-medium leading-5 text-gray-700" }, "How would you like to proceed?")),
        React.createElement("div", { className: "flex w-full justify-between" },
            React.createElement(CButton, { id: "download-only-paid-notices", onClick: () => {
                    if (fetching || fetchingPaidNotices)
                        return;
                    if (noticesAvailable()) {
                        setError('');
                        setFetchingPaidNotices(true);
                        void handleDownload(true);
                    }
                    else {
                        setOpen(false);
                    }
                }, className: `${buttonStyles} w-1/2 px-1 mr-3 bg-white border border-blue-600 text-blue-600`, startClasses: "hidden", endClasses: "hidden", middleClasses: "col-span-12 flex justify-center" }, fetchingPaidNotices ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })) : noticesAvailable() ? ('Download Only Paid Notices') : ('Cancel')),
            React.createElement(CButton, { id: "download-all-notices", onClick: () => {
                    if (fetching || fetchingPaidNotices)
                        return;
                    setError('');
                    setFetching(true);
                    void handleDownload(false);
                }, className: `${buttonStyles} w-1/2 px-1 bg-blue-150 text-blue-600`, startClasses: "hidden", endClasses: "hidden", middleClasses: "col-span-12 flex justify-center" }, fetching ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })) : ('Download All Notices'))),
        downloadURL && (React.createElement("p", { className: "mt-4 text-sm text-gray-600" },
            "Having trouble seeing your download?",
            ' ',
            React.createElement("a", { className: "text-blue-600", target: "_blank", href: downloadURL, rel: "noreferrer" }, "Click here"))),
        React.createElement("p", { id: "bulk-download-error", "data-testid": "error", className: "mt-4 text-sm text-gray-600" }, error))) : (React.createElement("section", { className: "mt-4" },
        React.createElement("ul", { className: "grid grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-7 lg:grid-cols-7 items-stretch" },
            React.createElement("li", { className: "col-span-3 p-2 pb-0 text-xs flex flex-col text-gray-700 uppercase" }, "Publication Date"),
            React.createElement("li", { className: "col-span-2 p-2 pb-0 flex text-xs flex-col text-gray-700 uppercase" }, "Liner Ads"),
            React.createElement("li", { className: "col-span-2 p-2 pb-0 flex  text-xs flex-col text-gray-700 uppercase" }, "Display Ads"),
            !loadingTimestamps ? (React.createElement("li", { className: "col-span-3 pl-2  flex flex-col text-gray-700 uppercase border rounded" },
                React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                    React.createElement(DatePicker, { "data-testid": "datePicker", label: "", value: moment(downloadTimestamp)
                            .utcOffset(0)
                            .format('YYYY/MM/DD'), placeholder: "MMM dd, yyyy", format: "MMM dd, yyyy", className: 'p-1', InputProps: {
                            disableUnderline: true,
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement(CalendarIcon, { className: "p-0.25" }))),
                            className: ''
                        }, autoOk: true, shouldDisableDate: disableDate, onChange: (newDate) => {
                            if (!newDate) {
                                return;
                            }
                            const date = relevantTimestamps.find(relevantTimestamp => areSameDayUTC(newDate, relevantTimestamp));
                            if (date) {
                                setDownloadTimestamp(date);
                            }
                        }, minDateMessage: "Selected date after publication deadline" })))) : (React.createElement("li", { className: "flex justify-center content-center mt-2 col-span-2" },
                React.createElement("div", { className: "border-4 border-t-4 ease-linear h-6 loader rounded-full w-6" }))),
            React.createElement("li", { className: "col-span-2 pl-2 flex flex-col text-gray-700 uppercase border rounded font-medium" },
                React.createElement("div", { className: "pt-0.5" },
                    React.createElement(CalendarIcon, { className: "text-xs p-0.25 inline-block" }),
                    React.createElement("select", { id: "linerFormat", "data-testid": "linerFormat", value: linerFormat, onChange: (e) => setLinerFormat(e.target.value), className: "select select-download focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 inline-block  sm:text-sm w-8/12" }, ExportFormatType.items()
                        .filter(item => item.liner)
                        .map(item => {
                        return (React.createElement("option", { key: item.value, value: item.value }, item.label));
                    })))),
            React.createElement("li", { className: "col-span-2 pl-2 flex flex-col text-gray-700 uppercase border rounded font-medium" },
                React.createElement("div", { className: "pt-0.5" },
                    React.createElement(CalendarIcon, { className: "text-xs p-0.25 inline-block" }),
                    React.createElement("select", { value: displayFormat, "data-testid": "displayFormat", onChange: (e) => setDisplayFormat(e.target.value), className: "select select-download pr-2 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 inline-block  sm:text-sm w-8/12", id: "displayFormat" }, ExportFormatType.items()
                        .filter(item => item.display)
                        .map(item => {
                        return (React.createElement("option", { key: item.value, value: item.value }, item.label));
                    }))))),
        React.createElement(CButton, { id: "download", onClick: () => {
                if (fetching)
                    return;
                setFetching(true);
                setError('');
                void handleDownload();
            }, className: `${buttonStyles} w-4/12 px-4 bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 hover:text-white`, endIcon: fetching ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 mt-2 ml-1" })) : (React.createElement(React.Fragment, null)) }, fetching ? 'Preparing Files' : 'Download'),
        downloadURL && (React.createElement("p", { className: "mt-4 text-sm text-gray-600" },
            "Having trouble seeing your download?",
            ' ',
            React.createElement("a", { className: "text-blue-600", target: "_blank", href: downloadURL, rel: "noreferrer" }, "Click here"))),
        React.createElement("p", { id: "bulk-download-error", "data-testid": "error", className: "mt-4 text-sm text-gray-600" }, error)))));
};
export default connect(mapStateToProps)(BulkDownloadModal);
