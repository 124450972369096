var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { State } from 'lib/enums';
import api from 'api';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import * as EmailValidator from 'email-validator';
import { logAndCaptureException } from 'utils';
import * as validators from '../../../../../register/organization/validators';
import PayInvoiceButton from '../buttons/PayInvoiceButton';
const PAYWAY_SUCCESS_CODE = '5000';
const styles = () => createStyles({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    bold: {
        fontWeight: 'bold'
    }
});
function CheckoutForm({ payInvoiceData, handleSuccessfulPayment, classes }) {
    // Payway related state
    const [loading, setLoading] = useState(false);
    const [paywayRequestToken, setPaywayRequestToken] = useState('');
    const [paymentName, setPaymentName] = useState('');
    const [err, setErr] = useState('');
    // Payment details
    const [creditCardNumber, setCreditCardNumber] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [isExpirationDateValid, setIsExpirationDateValid] = useState(true);
    const [cvc, setCvc] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [queuedTransactionInstance, setQueuedTransactionInstance] = useState(1);
    // Billing Address
    const [address, setAddress] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    // Customer details
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [payInvoiceDisabled, setPayInvoiceDisabled] = useState(false);
    const { invoice } = payInvoiceData;
    const invoiceId = invoice.id;
    const { noticeId } = invoice.data();
    // generate payway request token, payment name and set payway url
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            try {
                const { token, paymentName } = yield api.get(`gateway/payway/session/${invoiceId}`);
                setPaywayRequestToken(token);
                setPaymentName(paymentName);
            }
            catch (err) {
                setErr('Unable to facilitate payment session. Please contact help@column.us');
            }
        }))();
    }, [queuedTransactionInstance]);
    useEffect(() => {
        if (phone) {
            setIsPhoneValid(!phone.includes('_'));
        }
        else
            setIsPhoneValid(true);
    }, [phone]);
    useEffect(() => {
        if (email) {
            setIsEmailValid(EmailValidator.validate(email));
        }
        else
            setIsEmailValid(true);
    }, [email]);
    useEffect(() => {
        if (expirationDate) {
            setIsExpirationDateValid(validators.expirationDate(expirationDate));
        }
        else
            setIsExpirationDateValid(true);
    }, [expirationDate]);
    const handleZipCodeInput = (zipcode) => {
        if (validators.zip(zipcode))
            setZip(zipcode);
    };
    const handleCreditCardInput = (cc) => {
        if (validators.creditCard(cc))
            setCreditCardNumber(cc);
    };
    const handleCvcInput = (cvc) => {
        if (validators.cvc(cvc))
            setCvc(cvc);
    };
    const validateInput = () => {
        if (!creditCardNumber ||
            !expirationDate ||
            !cvc ||
            !firstName ||
            !lastName) {
            setErr('Please check that all payment fields are complete.');
            return false;
        }
        if (!isExpirationDateValid) {
            setErr('Credit card expiration date must be in the future.');
            return false;
        }
        if (!address || !city || !state || !zip) {
            setErr('Please check that all billing address fields are complete.');
            return false;
        }
        if (!email || !phone) {
            setErr('Please check that all customer detail fields are complete.');
            return false;
        }
        if (!isEmailValid) {
            setErr('Please check that the email entered is correct.');
            return false;
        }
        if (!isPhoneValid) {
            setErr('Please check that the phone number entered is correct.');
            return false;
        }
        return true;
    };
    const handlePaywaySubmission = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const inputsValid = validateInput();
        if (!inputsValid || payInvoiceDisabled) {
            setLoading(false);
            return;
        }
        setErr('');
        const stateAbbrev = State.by_value(state).abbrev.toUpperCase();
        const fullAddress = addressLine2 ? `${address} ${addressLine2}` : address;
        const transactionData = {
            paywayRequestToken,
            creditCardNumber,
            expirationDate,
            firstName,
            lastName,
            fullAddress,
            city,
            zip,
            stateAbbrev,
            email,
            phone,
            noticeId,
            paymentName
        };
        // TODO: IT-4250 Move Payway API calls to gateway-agnostic API route
        const { paywayCode, paywayMessage, columnPaymentSuccess, errorMessage } = yield api.post(`gateway/payway/session/${invoiceId}/queueTransaction`, transactionData);
        if (paywayCode !== PAYWAY_SUCCESS_CODE) {
            console.warn('Payway message:', paywayMessage);
            // payway error code handling
            let paywayErrExp = '';
            // general card declines
            switch (paywayCode) {
                case '5012':
                    paywayErrExp =
                        'The transaction was declined by the issuer. Please check that the card details entered above are correct. If the card details are correct, contact your card issuer.';
                    break;
                case '5020':
                    paywayErrExp =
                        'Duplicate transaction request. The payment request has been rejected.';
                    break;
                case '5032':
                    paywayErrExp =
                        'The card issuer has disallowed this type of transaction. Contact your card issuer if you expect this transaction type to be permitted.';
                    break;
                case '5039':
                    paywayErrExp =
                        'The merchant is not authorized to process this card type. Please try another method of payment.';
                    break;
                // detail-specific declines
                case '5036':
                    paywayErrExp =
                        'Invalid address entered. Please check that the card details entered above are correct.';
                    break;
                case '5037':
                    paywayErrExp =
                        'Invalid credit card expiration date  entered. Please check that the card details entered above are correct.';
                    break;
                case '5038':
                    paywayErrExp =
                        'Invalid CVV or card security code. Please check that the card details entered above are correct.';
                    break;
                case '5040':
                    paywayErrExp =
                        'Invalid city entered. Please check that the card details entered above are correct.';
                    break;
                case '5044':
                    paywayErrExp =
                        'Invalid state entered. Please check that the card details entered above are correct.';
                    break;
                case '5045':
                    paywayErrExp =
                        'Invalid zip or postal code entered. Please check that the card details entered above are correct.';
                    break;
                case '1':
                    paywayErrExp = 'Payment was not successful. Please try again.';
                    break;
                default:
                    paywayErrExp = `The transaction could not be processed. ${paywayMessage} Contact help@column.us for further assistance.`;
            }
            setErr(paywayErrExp);
            setLoading(false);
            const result = yield api.post(`gateway/payway/session/${invoiceId}/reject`, {
                paywayRequestToken
            });
            logAndCaptureException(result, 'processorErrorCode', {
                invoiceId
            });
            setQueuedTransactionInstance(queuedTransactionInstance + 1);
            return;
        }
        // process payment success
        if (columnPaymentSuccess) {
            setLoading(false);
            handleSuccessfulPayment();
        }
        else {
            setPayInvoiceDisabled(true);
            logAndCaptureException(new Error(errorMessage || 'Undetermined error message'), 'Payway invoice was likely paid in Payway but not marked as paid in Column', { invoiceId, paymentName });
            setErr('Something went wrong. Please contact help@column.us to make sure your payment is processed.');
            setLoading(false);
        }
    });
    const inputClasses = 'p-2 block w-full sm:text-sm rounded-md focus:outline-none';
    return (React.createElement("form", { onSubmit: e => {
            e.preventDefault();
            void handlePaywaySubmission();
        } },
        React.createElement("div", { id: "paymentDetails" },
            React.createElement("div", { className: "font-medium text-sm text-gray-900 mb-2" }, "Payment details"),
            React.createElement("div", { className: "bg-white border border-gray-400 rounded-md" },
                React.createElement("input", { "data-private": true, placeholder: "Card number *", maxLength: 16, className: inputClasses, onChange: e => handleCreditCardInput(e.target.value), value: creditCardNumber, autoComplete: "cc-number" })),
            React.createElement("div", { className: "flex mt-2" },
                React.createElement("div", { "data-private": true, className: "mr-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement(InputMask, { mask: "99/99", className: inputClasses, id: "expirationDate", value: expirationDate, name: "expirationDate", onChange: e => setExpirationDate(e.target.value), placeholder: "Expiration date *", autoComplete: "cc-exp" })),
                React.createElement("div", { className: "ml-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { "data-private": true, placeholder: "CVC *", maxLength: 4, className: inputClasses, onChange: e => handleCvcInput(e.target.value), value: cvc, autoComplete: "cc-csc" }))),
            React.createElement("div", { className: "flex mt-2" },
                React.createElement("div", { className: "mr-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { placeholder: "First name *", className: inputClasses, onChange: e => setFirstName(e.target.value), value: firstName, autoComplete: "cc-given-name" })),
                React.createElement("div", { className: "ml-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { placeholder: "Last name *", className: inputClasses, onChange: e => setLastName(e.target.value), value: lastName, autoComplete: "cc-family-name" })))),
        React.createElement("div", { id: "billingAddress", className: "mt-6" },
            React.createElement("div", { className: "font-medium text-sm text-gray-900 mb-2" }, "Billing address"),
            React.createElement("div", { className: "bg-white border border-gray-400 rounded-md" },
                React.createElement("input", { placeholder: "Address line 1 *", maxLength: 35, className: inputClasses, onChange: e => setAddress(e.target.value), value: address, autoComplete: "address-line1" })),
            React.createElement("div", { className: "mt-2 bg-white border border-gray-400 rounded-md" },
                React.createElement("input", { placeholder: "Address line 2", maxLength: 35, className: inputClasses, onChange: e => setAddressLine2(e.target.value), value: addressLine2, autoComplete: "address-line2" })),
            React.createElement("div", { className: "flex mt-2" },
                React.createElement("div", { className: "mr-1 w-2/5 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { placeholder: "City *", className: inputClasses, onChange: e => setCity(e.target.value), value: city, autoComplete: "address-level2" })),
                React.createElement("div", { className: "mx-1 w-2/5 bg-white rounded-md" },
                    React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                            let state = '';
                            if (selected)
                                state = selected.id;
                            setState(state);
                        }, placeholder: "State *", options: State.items().map((state) => ({
                            id: state.value,
                            label: state.label
                        })), selected: State.items().filter(item => {
                            return item.value === parseInt(state, 10)
                                ? {
                                    id: item.value,
                                    label: item.label
                                }
                                : '';
                        }), value: State.items().filter(item => {
                            return item.value === parseInt(state, 10)
                                ? {
                                    id: item.value,
                                    label: item.label
                                }
                                : '';
                        }), borderRadius: '6px 6px 6px 6px', borderColor: '#cbd5e0', placeholderText: '#a0aec0', noShadow: true })),
                React.createElement("div", { className: "ml-1 w-1/5 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { className: inputClasses, id: "zip", value: zip, name: "zip", onChange: e => handleZipCodeInput(e.target.value), placeholder: "Zip *", autoComplete: "postal-code" })))),
        React.createElement("div", { id: "customerDetails", className: "mt-6" },
            React.createElement("div", { className: "font-medium text-sm text-gray-900 mb-2" }, "Customer details"),
            React.createElement("div", { className: "bg-white border border-gray-400 rounded-md mb-2" },
                React.createElement("input", { placeholder: "Email *", className: inputClasses, onChange: e => setEmail(e.target.value), value: email, autoComplete: "email" })),
            React.createElement("div", { className: "bg-white border border-gray-400 rounded-md mb-2" },
                React.createElement(InputMask, { mask: "(999) 999-9999", className: inputClasses, id: "phone", value: phone, name: "phone", onChange: e => setPhone(e.target.value), placeholder: "Phone number *", autoComplete: "tel-national" }))),
        React.createElement(PayInvoiceButton, { loading: loading, type: 'submit', disabled: !paywayRequestToken || payInvoiceDisabled, id: "pay-invoice-payway" }),
        err && (React.createElement(Box, { mt: 1, className: classes.flex },
            React.createElement(Typography, { color: "error", variant: "caption" }, err)))));
}
export default withStyles(styles)(CheckoutForm);
