import classNames from 'classnames';
import { TextField } from 'components/TextField';
import ColumnSelect from 'components/select/ColumnSelect';
import { Label } from 'components/Label';
import React from 'react';
import AffidavitSettingsCard from './AffidavitSettingsCard';
/**
 * Allows control over organization fields that are rendered in affidavits
 * via handlebars.
 * @param activeOrganization
 * @returns {OrganizationAffidavitSettings} key fields for affidavits
 */
export const getAffidavitSignatorySettingsFromActiveOrganization = (activeOrganization) => {
    return {
        commissionExpirationDate: activeOrganization.data()
            .commissionExpirationDate,
        publicationFrequency: activeOrganization.data().publicationFrequency,
        signatoryName: activeOrganization.data().signatoryName,
        signatoryRole: activeOrganization.data().signatoryRole
    };
};
function AffidavitSettingsInputWrapper({ className, children }) {
    return (React.createElement("div", { className: classNames('col-span-3 h-20', className) }, children));
}
export default function AffidavitSignatorySettings({ affidavitsManagedByColumn, setAffidavitSettings, activeOrganization, affidavitSettings }) {
    return (React.createElement(AffidavitSettingsCard, { header: "Basic Settings", subheader: React.createElement("p", null,
            "Control who at your organization is responsible for signing and notarizing affidavits.",
            affidavitsManagedByColumn && (React.createElement("span", { className: "ml-2" },
                "These settings are used for affidavits signed by your staff at",
                ' ',
                activeOrganization.data().name,
                ", and do not apply to affidavits generated by Column."))) },
        React.createElement(AffidavitSettingsInputWrapper, { className: "flex flex-col" },
            React.createElement("div", { className: "pb-2" },
                React.createElement(Label, { id: "publication-frequency-label" }, "Publication Frequency")),
            React.createElement(ColumnSelect, { className: "flex-1 text-md", options: [
                    {
                        value: 'Daily',
                        label: 'Daily'
                    },
                    {
                        value: 'Weekly',
                        label: 'Weekly'
                    }
                ], onChange: newValue => {
                    setAffidavitSettings(Object.assign(Object.assign({}, affidavitSettings), { publicationFrequency: newValue }));
                }, value: affidavitSettings.publicationFrequency })),
        React.createElement(AffidavitSettingsInputWrapper, null,
            React.createElement(TextField, { id: "signatory-name", labelText: "Signatory Name", value: affidavitSettings.signatoryName, onChange: newValue => {
                    setAffidavitSettings(Object.assign(Object.assign({}, affidavitSettings), { signatoryName: newValue }));
                } })),
        React.createElement(AffidavitSettingsInputWrapper, null,
            React.createElement(TextField, { id: "signatory-role", labelText: "Signatory Role", value: affidavitSettings.signatoryRole, onChange: newValue => {
                    setAffidavitSettings(Object.assign(Object.assign({}, affidavitSettings), { signatoryRole: newValue }));
                } })),
        React.createElement(AffidavitSettingsInputWrapper, null,
            React.createElement(TextField, { id: "commission-expiration", labelText: "Commission Expiration Date", value: affidavitSettings.commissionExpirationDate, onChange: newValue => {
                    setAffidavitSettings(Object.assign(Object.assign({}, affidavitSettings), { commissionExpirationDate: newValue }));
                } }))));
}
