import React from 'react';
import classNames from 'classnames';
export function ColumnButton({ id, buttonText, primary, secondary, destructive, fullWidth, dropzone, disabled, size = 'md', onClick, startIcon, endIcon, loading, formId }) {
    const isDisabled = disabled || loading;
    const hasIcons = !!((startIcon || endIcon) && buttonText);
    const tertiary = !primary && !secondary;
    const { layoutClasses, buttonBaseClasses } = getButtonClasses({
        primary,
        secondary,
        tertiary,
        destructive,
        fullWidth,
        dropzone,
        size,
        hasIcons,
        isDisabled
    });
    function handleOnClick(event) {
        if (onClick) {
            onClick(event);
        }
    }
    return (React.createElement("button", { id: id, form: formId, className: buttonBaseClasses, disabled: isDisabled, onClick: handleOnClick },
        React.createElement("div", { className: layoutClasses },
            loading && React.createElement(LoadingSpinner, null),
            !loading && (React.createElement(React.Fragment, null,
                startIcon && React.createElement("span", { "aria-hidden": "true" }, startIcon),
                React.createElement("span", null, buttonText),
                endIcon && React.createElement("span", { "aria-hidden": "true" }, endIcon))))));
}
function LoadingSpinner() {
    return (React.createElement("div", { role: "status", className: "rounded-b" },
        React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5" }),
        React.createElement("span", { className: "sr-only" }, "Loading...")));
}
function getButtonClasses({ primary, secondary, tertiary, destructive, fullWidth, dropzone, size, hasIcons, isDisabled }) {
    const fontClasses = classNames('font-medium', {
        'text-sm': size === 'sm' || size === 'md',
        'text-base': size === 'lg' || size === 'xl',
        'text-lg': size === '2xl'
    });
    const layoutClasses = classNames('grid grid-flow-col-dense items-center text-center justify-center', {
        'gap-1': hasIcons && size !== '2xl',
        'gap-3': hasIcons && size === '2xl',
        'py-1.5 px-2.5': size === 'sm',
        'py-2.5 px-3': size === 'md',
        'py-2.5 px-3.5': size === 'lg',
        'py-3 px-4.5': size === 'xl',
        'py-3.5 px-5': size === '2xl'
    });
    const variantClases = classNames({
        'text-white': primary,
        'border-red-600 bg-red-600 hover:bg-red-700': primary && destructive,
        'border-primary-500 bg-primary-500 hover:bg-blue-600': primary && !destructive,
        'text-primary-600 bg-primary-50 hover:bg-primary-100 hover:text-primary-700 border-primary-500 hover:border-primary-600': secondary && !destructive,
        'text-red-600 bg-red-50 hover:bg-red-100 hover:text-red-700 border-red-300 hover:border-red-400': secondary && destructive,
        'text-gray-800 border-gray-300 hover:bg-gray-150 bg-white': tertiary,
        'border-red-300 text-red-600 hover:text-red-700 hover:border-red-400 hover:bg-red-50': tertiary && destructive,
        'focus:shadow-outline-red': destructive,
        'focus:shadow-outline-blue': !destructive,
        'opacity-25 cursor-not-allowed': isDisabled,
        'w-full': fullWidth,
        'border-dashed': dropzone
    });
    const buttonBaseClasses = classNames('border rounded-md transition duration-300 ease-in-out focus:outline-none', fontClasses, variantClases);
    return { layoutClasses, buttonBaseClasses };
}
