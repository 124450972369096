var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { extractCustomerDataFromCustomer, memberStyles } from 'routes/settings/publisher/Customers';
import { exists } from 'lib/types';
import CustomerTableLoader from './CustomerTableLoader';
export const CustomerOrganizationDrawerMembersTab = ({ customers, loadingCustomers, setShowCustomerDrawer, setCustomerToDisplay, customerEmails, customerRoles }) => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "pt-4 pb-16 h-75vh max-h-full overflow-y-scroll hide-scrollbar text-sm leading-6 font-medium" },
            loadingCustomers && React.createElement(CustomerTableLoader, null),
            !loadingCustomers &&
                (customers === null || customers === void 0 ? void 0 : customers.map((customer, index) => {
                    var _a, _b, _c, _d;
                    return (React.createElement("div", { key: index, className: "flex w-full hover:bg-blue-50 cursor-pointer", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            if (exists(customer)) {
                                const customerTableData = yield extractCustomerDataFromCustomer(customer);
                                setShowCustomerDrawer();
                                setCustomerToDisplay(customerTableData);
                            }
                        }) },
                        React.createElement("div", { className: "px-4 flex w-full border-b border-grey-50" },
                            React.createElement("div", { className: "flex w-full items-center justify-between pl-4 pb-4 pt-4" },
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: `${memberStyles[index % memberStyles.length]} w-10 h-10 rounded-full flex items-center justify-center` },
                                        React.createElement("div", { className: "text-white text-sm leading-6 font-semibold uppercase" },
                                            ((_a = customer === null || customer === void 0 ? void 0 : customer.data().userName) === null || _a === void 0 ? void 0 : _a.split(' ')[0])
                                                ? ((_b = customer === null || customer === void 0 ? void 0 : customer.data().userName) === null || _b === void 0 ? void 0 : _b.split(' ')[0].charAt(0)) || ''
                                                : '',
                                            ((_c = customer === null || customer === void 0 ? void 0 : customer.data().userName) === null || _c === void 0 ? void 0 : _c.split(' ')[1])
                                                ? ((_d = customer === null || customer === void 0 ? void 0 : customer.data().userName) === null || _d === void 0 ? void 0 : _d.split(' ')[1].charAt(0)) || ''
                                                : '')),
                                    React.createElement("div", { className: "inline-block align-middle" },
                                        React.createElement("div", { className: "pl-4 text-grey-500" }, (customer === null || customer === void 0 ? void 0 : customer.data().userName) || '--'),
                                        React.createElement("div", { className: "pl-4 mt-1 text-grey-400" }, customerEmails[index]))),
                                React.createElement("div", { className: "pr-4 text-grey-400" }, customerRoles[index])))));
                })))));
};
