import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { logAndCaptureException } from 'utils';
import { connect } from 'react-redux';
import { UNABLE_TO_FETCH_USER } from 'sagas/AuthSaga';
import { WarningIcon } from 'icons';
import { ENV, PROD, DEMO } from '../constants';
class SuspenseErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // See EnoticeFirebase.ts for an explanation of why we set this property.
        this.showLongPollingSelector = () => {
            return [this.props.error, this.state.hasError].some(e => e === UNABLE_TO_FETCH_USER);
        };
        this.state = {
            hasError: false
        };
    }
    static getDerivedStateFromError(error) {
        if (ENV === DEMO || ENV === PROD) {
            logAndCaptureException(error, 'Suspense Error Boundary Error', {
                env: ENV
            });
        }
        return {
            hasError: true
        };
    }
    render() {
        if (this.props.error || this.state.hasError) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, style: {
                        width: '100vw',
                        height: '100vh',
                        alignItems: 'center',
                        justifyContent: 'center'
                    } },
                    React.createElement(Grid, null,
                        React.createElement(Typography, { variant: "h5" }, "Whoops! We've encountered an error"),
                        React.createElement(Typography, null, "Please refresh the page. If that doesn't help, email help@column.us and we will assist you!"),
                        this.showLongPollingSelector() && (React.createElement("div", { className: "mt-10 flex items-center bg-yellow-200 text-gray-900 text-sm font-normal px-4 py-3", role: "alert" },
                            React.createElement(WarningIcon, { className: "h-5 w-5 mr-3" }),
                            React.createElement("p", null,
                                "We detected an issue with your network that may be preventing you from accessing Column. ",
                                React.createElement("br", null),
                                " Click",
                                ' ',
                                React.createElement("a", { href: "#", onClick: e => {
                                        e.preventDefault();
                                        window.localStorage.setItem('enableAutoDetectLongPolling', 'true');
                                        window.location.reload();
                                        return false;
                                    }, className: "font-semibold underline transition duration-150 ease-in-out" }, "here"),
                                ' ',
                                "to enable an experimental setting which may fix the issue")))))));
        }
        return this.props.children;
    }
}
const mapStateToProps = (state) => ({
    error: state.errors.error
});
export default connect(mapStateToProps)(SuspenseErrorBoundary);
