import React from 'react';
const InviteActionModalHeader = ({ id, title, subtitle, titleEndIcon }) => {
    return (React.createElement("div", { id: id },
        React.createElement("div", { className: "flex text-lg text-gray-900 font-semibold justify-center leading-6 mb-4" },
            title,
            titleEndIcon ? (React.createElement(React.Fragment, null,
                React.createElement("span", null, "\u00A0"),
                titleEndIcon)) : null),
        React.createElement("div", { style: { color: '#667A8C' }, className: "flex leading-6 justify-center mb-10 px-10 font-medium text-sm" }, subtitle)));
};
export default InviteActionModalHeader;
