var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { Grid, Box, LinearProgress, InputLabel, IconButton } from '@material-ui/core';
import { Error, Cancel } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { TemplateTypes } from 'lib/types/templates';
import pricingHelpers from 'lib/pricing';
import { isBattleborn } from 'lib/publishers';
import { getMinAndMaxColumnsForPaper } from 'lib/notice/columns';
import useDebounce from 'utils/UseDebounce';
import { cdnIfy, showConvertToLinerWarning, getColumnOptionRange, getWordsFromNumber, requestDisplayParameters } from 'lib/helpers';
import { ESelect } from 'components/ColorColumnFormComponents';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { slackInvalidHtml, validateHtml, MAX_CONFIRMED_HTML_WORDS } from 'components/noticePreview/indesign_client_utils';
import { exists } from 'lib/types';
import { connect } from 'react-redux';
import NoticeFilePreview from 'components/noticePreview/NoticeFilePreview';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { generateFormattedFooter } from 'lib/headers_footers/footers';
import { getFirebaseContext } from 'utils/firebase';
import RateSelect from './RateSelect';
import { BROWSERS, BROWSER, INDESIGN_URL } from '../../constants';
import Firebase, { Collections } from '../../EnoticeFirebase';
import NoticePreviewContainer from './NoticePreviewContainer';
const styles = (theme) => createStyles({
    actionsContainer: {
        zIndex: 10,
        display: 'flex',
        width: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: 40,
        'flex-wrap': BROWSER === BROWSERS.ie ? 'nowrap' : ''
    },
    errorContainer: {
        background: theme.palette.secondary[500],
        color: theme.palette.grey.scrollFormHeading,
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(2),
        marginBottom: 20
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey.inputBackground
    },
    barColorPrimary: {
        backgroundColor: 'white'
    },
    errorBar: {
        borderRadius: '3px',
        marginTop: theme.spacing(0.75)
    },
    label: {
        color: 'white',
        marginTop: theme.spacing(2),
        transform: 'scale(0.75)',
        fontSize: '18px',
        width: 'max-content',
        fontWeight: 500
    }
});
const ERRORS = {
    RESIZING_NOTICE: 'Your content is too wide for the selected number of columns. <br> Finding optimal column width.',
    TOO_TALL: 'Your content is taller than the max height of 215 inches.',
    GENERIC: `Whoops, we're having trouble loading the preview. Please refresh the page. <br> If that doesn't help email help@column.us and we will assist you!`,
    DISPLAY: `If your ad does not contain graphics or tables, you may want to convert it to a text ad to decrease the cost.`,
    NO_UPFRONT_PROOF: `Click here to submit your notice without formatting, and receive a proof after the newspaper designs your file.`,
    WAIT_REQUEST: `We're sorry for the wait. This notice is taking longer than usual to generate. If you'd like, you can submit this notice without waiting for notice formatting. Click here to continue without a generated notice.`
};
export const ErrorContainer = withStyles(styles)(({ text, classes, showProgress, onDismiss, clickAction }) => {
    const props = Object.assign({}, (clickAction && {
        onClick: (e) => {
            e.preventDefault();
            clickAction();
        }
    }));
    return (React.createElement("div", null,
        React.createElement(Grid, { container: true, direction: "column", className: classes.errorContainer, style: { paddingBottom: showProgress ? 0 : '', position: 'relative' } },
            React.createElement(Grid, { container: true, direction: "row", alignItems: "center", style: { flexWrap: 'nowrap' } },
                React.createElement(Error, null),
                React.createElement(Box, { ml: 1, mr: onDismiss ? 3 : '' },
                    React.createElement("div", Object.assign({}, props, { dangerouslySetInnerHTML: { __html: text }, style: { textAlign: 'justify' } }))),
                onDismiss && (React.createElement(IconButton, { size: "small", onClick: () => onDismiss(), style: {
                        top: 2,
                        right: 4,
                        position: 'absolute',
                        cursor: 'pointer'
                    } },
                    React.createElement(Cancel, { fontSize: "small" })))),
            React.createElement(Grid, { item: true }, showProgress && (React.createElement(React.Fragment, null,
                React.createElement(LinearProgress, { className: classes.errorBar, classes: {
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary
                    } })))))));
});
const templateOptions = [
    { val: TemplateTypes.legal, label: 'Legal Notice' },
    { val: TemplateTypes.public, label: 'Public Notice' }
];
const PlaceNoticePreview = ({ classes, rate, isPublisher, adTemplate, newspaper, readyToFetch, allowEditParams, uploading, uploadedFile, placementActions, placement, isTypeform, setShowSubmitFileWithoutFormattingModal }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const [openModal, setOpenModal] = useState(false);
    const [previewContent, setPreviewContent] = useState({
        displayParams: placement.displayParams,
        price: '--'
    });
    const [widthWarning, setWidthWarning] = useState(false);
    const [heightWarning, setHeightWarning] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(adTemplate ? (_a = adTemplate === null || adTemplate === void 0 ? void 0 : adTemplate.data()) === null || _a === void 0 ? void 0 : _a.type : TemplateTypes.legal);
    const [rateCode, setRateCode] = useState('');
    const [rateCodeError, setRateCodeError] = useState('');
    const [isDefaultRate, setIsDefaultRate] = useState(false);
    const [rateDescriptionOverride, setRateDescriptionOverride] = useState('');
    const [, setIDError] = useState('');
    const [loading, setLoading] = useState(false);
    const [completedInit, setCompletedInit] = useState(false);
    const [optimizeColumns, setOptimizeColumns] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const [showDisplayError, setShowDisplayError] = useState(true);
    const [isBattleBorn, setIsBattleBorn] = useState(false);
    const debouncedRateCode = useDebounce(rateCode, 500);
    const debouncedPlacement = useDebounce(placement, 500);
    const [showWaitError, setShowWaitError] = useState(false);
    const [showLargeFileModal, setShowLargeFileModal] = useState(false);
    const [draftPublicationDates, setDraftPublicationDates] = useState();
    const [newspaperSnap, setNewspaperSnap] = useState();
    useEffect(() => {
        // Manage Large Files Error Modals and Warnings
        // TODO: Investigate ways to consolidate formatting error logic with NoticeContentStep
        // TODO: Complete deprecation of placement.continueWithLargeFile
        if (!placement.processedDisplay &&
            placement.formattingError &&
            !placement.postWithoutFormatting) {
            setShowLargeFileModal(true);
        }
    }, [placement.formattingError]);
    useEffect(() => {
        const checkIsBattleBorn = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(newspaper)) {
                return;
            }
            const battleBornFlag = yield isBattleborn(newspaper);
            setIsBattleBorn(battleBornFlag);
        });
        void checkIsBattleBorn();
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    const getNewspaperDefaultRates = (newspaperSnap) => {
        return [
            newspaperSnap.data().defaultLinerRate.id,
            newspaperSnap.data().defaultDisplayRate.id
        ];
    };
    const checkRateDescriptionOverride = () => __awaiter(void 0, void 0, void 0, function* () {
        var _w, _x, _y, _z;
        const newspaperSnap = yield ((_w = placement.newspaper) === null || _w === void 0 ? void 0 : _w.get());
        if (!exists(newspaperSnap))
            return;
        // If the notice type has no associated rate (typeform/zap),
        // set the rate description override so the correct rate is reflected in the
        // notice preview pane
        const customNoticeType = ((_y = (_x = newspaperSnap
            .data()) === null || _x === void 0 ? void 0 : _x.allowedNotices) === null || _y === void 0 ? void 0 : _y.find((type) => { var _a, _b; return type.value === ((_b = (_a = placement.draftSnap) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.noticeType); })) || null;
        const defaultRates = getNewspaperDefaultRates(newspaperSnap);
        if (customNoticeType &&
            !customNoticeType.rate &&
            defaultRates.includes(((_z = placement.rate) === null || _z === void 0 ? void 0 : _z.id) || '')) {
            setRateDescriptionOverride(customNoticeType.label);
            setRateCode('');
        }
        else {
            setRateDescriptionOverride('');
        }
    });
    useEffect(() => {
        void checkRateDescriptionOverride();
    }, [(_c = (_b = placement.draftSnap) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.noticeType, (_d = placement.rate) === null || _d === void 0 ? void 0 : _d.id]);
    useEffect(() => {
        const getNewspaperSnap = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const newspaperSnap = yield ((_a = placement.newspaper) === null || _a === void 0 ? void 0 : _a.get());
            setNewspaperSnap(newspaperSnap);
        });
        void getNewspaperSnap();
    }, [(_e = placement.newspaper) === null || _e === void 0 ? void 0 : _e.id]);
    useEffect(() => {
        var _a, _b;
        if (exists(newspaperSnap) && ((_a = debouncedPlacement.rate) === null || _a === void 0 ? void 0 : _a.id)) {
            const newspaperDefaults = getNewspaperDefaultRates(newspaperSnap);
            setIsDefaultRate(newspaperDefaults.includes((_b = debouncedPlacement.rate) === null || _b === void 0 ? void 0 : _b.id));
        }
    }, [newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id, (_f = debouncedPlacement === null || debouncedPlacement === void 0 ? void 0 : debouncedPlacement.rate) === null || _f === void 0 ? void 0 : _f.id]);
    const handleRateCodeSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const matchingRate = yield Firebase.firestore()
            .collection(Collections.rates)
            .where('code', '==', parseInt(rateCode, 10))
            .get();
        if (matchingRate.empty) {
            setLoading(false);
            return setRateCodeError('Not found');
        }
        setRateCodeError('');
        placementActions.setRate(matchingRate.docs[0].ref);
        placementActions.setDefaultRateOverride(true);
        placementActions.saveDraft();
        setLoading(false);
    });
    useEffect(() => {
        if (!isDisplay && placement.processedDisplay)
            setIsDisplay(true);
        if (isDisplay && !placement.processedDisplay)
            setIsDisplay(false);
        // hide display to text switch container when ad is converted to text else vice-versa
        if (!placement.processedDisplay)
            setShowDisplayError(false);
        else
            setShowDisplayError(true);
    }, [placement]);
    useEffect(() => {
        var _a;
        if (!rateCode) {
            return;
        }
        if ((rateCode && rateCode.length < 6) ||
            parseInt(rateCode, 10) === ((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.code)) {
            return;
        }
        void handleRateCodeSubmit();
    }, [debouncedRateCode]);
    const updatePreview = (
    /**
     * Because this function is often called multiple times in the placement flow via useEffects, it
     * is possible that we could accidentally set stale displayParams due to race conditions.
     * Luckily useEffect has a built in solution for this:
     * https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
     *
     * This function tells us if the useEffect is still fresh or stale before we set display params.
     */
    shouldSetDisplayParams) => __awaiter(void 0, void 0, void 0, function* () {
        var _0, _1, _2;
        if (!placement.draft)
            return;
        if (!(newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.ref))
            return;
        let notice = yield placement.draft.get();
        try {
            if ((!placement.processedDisplay && !placement.confirmedHtml) ||
                (placement.processedDisplay && !placement.displayParams)) {
                // Clear old preview if it exists
                if (previewContent.displayParams) {
                    setPreviewContent({
                        displayParams: placement.displayParams,
                        price: '--'
                    });
                }
                return;
            }
            if (!adTemplate)
                return;
            if (!placement.publicationDates)
                return;
            if (!((_0 = notice.data()) === null || _0 === void 0 ? void 0 : _0.publicationDates))
                return;
            setLoading(true);
            const footer = yield generateFormattedFooter(getFirebaseContext(), placement, window.DOMParser);
            yield notice.ref.update({
                dynamicFooter: footer,
                adTemplate: adTemplate.ref,
                newspaper: newspaperSnap.ref,
                columns: placement.columns,
                publicationDates: placement.publicationDates,
                dynamicHeaders: placement.dynamicHeaders || null
            });
            let finalDisplayParams;
            if (!placement.processedDisplay && !placement.continueWithLargeFile) {
                const valid = validateHtml(placement.confirmedHtml);
                if (!valid) {
                    setShowLargeFileModal(true);
                    setLoading(false);
                    void slackInvalidHtml(notice);
                    return;
                }
                notice = yield placement.draft.get(); // replace with onsnapshot
                finalDisplayParams = yield requestDisplayParameters(getFirebaseContext(), notice, {
                    format: 'jpg',
                    type: 'DISPLAY_PARAMETERS',
                    url: INDESIGN_URL,
                    optimizeColumns
                }, window.DOMParser);
            }
            else {
                finalDisplayParams = placement.displayParams;
            }
            // When optimizing columns, adjust if the server returned a greater number
            if (optimizeColumns) {
                const originalColumns = ((_1 = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _1 === void 0 ? void 0 : _1.columns) || 1;
                const finalColumns = finalDisplayParams.columns;
                if (finalColumns > originalColumns) {
                    placementActions.setColumns(finalColumns);
                }
            }
            const widthWarning = finalDisplayParams.isTableOverflow ||
                finalDisplayParams.areAnyTablesTooWide;
            const heightWarning = finalDisplayParams.maxHeightExceeded;
            if (isDisplay && !finalDisplayParams.height)
                return;
            const rate = yield ((_2 = placement.rate) === null || _2 === void 0 ? void 0 : _2.get());
            const dbPricingObject = yield pricingHelpers.createDBPricingObject(getFirebaseContext(), notice, finalDisplayParams, exists(rate) ? rate : undefined);
            if (shouldSetDisplayParams()) {
                setPreviewContent({
                    displayParams: finalDisplayParams,
                    price: pricingHelpers
                        .getUIPricingObject(dbPricingObject)
                        .total.toFixed(2)
                });
            }
            setOptimizeColumns(widthWarning && !optimizeColumns && placement.columns < 3);
            if (!isDisplay && shouldSetDisplayParams()) {
                placementActions.setDisplayParams(finalDisplayParams);
            }
            setWidthWarning(widthWarning);
            setHeightWarning(heightWarning);
            setIDError('');
            if (!completedInit)
                setCompletedInit(true);
        }
        catch (err) {
            console.error(err);
        }
        setLoading(false);
    });
    useEffect(() => {
        if (!readyToFetch)
            return;
        let shouldSet = true;
        const shouldSetDisplayParams = () => shouldSet;
        void updatePreview(shouldSetDisplayParams);
        return () => {
            shouldSet = false;
        };
    }, [
        debouncedPlacement.confirmedHtml,
        debouncedPlacement.columns,
        isDisplay && debouncedPlacement.displayParams,
        (_h = (_g = debouncedPlacement.draftSnap) === null || _g === void 0 ? void 0 : _g.data()) === null || _h === void 0 ? void 0 : _h.noticeType,
        debouncedPlacement.publicationDates,
        (_j = debouncedPlacement.rate) === null || _j === void 0 ? void 0 : _j.id,
        debouncedPlacement.dynamicHeaders,
        (_k = debouncedPlacement.adTemplate) === null || _k === void 0 ? void 0 : _k.id
    ]);
    useEffect(() => {
        // Clear the price when the newspaper switches mid-placement
        if (previewContent.displayParams) {
            setPreviewContent({
                displayParams: placement.displayParams,
                price: '--'
            });
        }
    }, [(_l = debouncedPlacement.newspaper) === null || _l === void 0 ? void 0 : _l.id, debouncedPlacement.noticeType]);
    useEffect(() => {
        var _a, _b;
        if (((_b = (_a = placement.draftSnap) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.publicationDates) &&
            (!draftPublicationDates ||
                draftPublicationDates.length !==
                    placement.draftSnap.data().publicationDates.length)) {
            setDraftPublicationDates(placement.draftSnap.data().publicationDates);
            let shouldSet = true;
            const shouldSetDisplayParams = () => shouldSet;
            void updatePreview(shouldSetDisplayParams);
            return () => {
                shouldSet = false;
            };
        }
    }, [(_o = (_m = placement.draftSnap) === null || _m === void 0 ? void 0 : _m.data()) === null || _o === void 0 ? void 0 : _o.publicationDates]);
    useEffect(() => {
        if (!readyToFetch)
            return;
        if (optimizeColumns) {
            let shouldSet = true;
            const shouldSetDisplayParams = () => shouldSet;
            void updatePreview(shouldSetDisplayParams);
            return () => {
                shouldSet = false;
            };
        }
    }, [optimizeColumns]);
    useEffect(() => {
        // Fetch ID preview on intial scroll to step
        if (completedInit)
            return;
        let shouldSet = true;
        const shouldSetDisplayParams = () => shouldSet;
        void updatePreview(shouldSetDisplayParams);
        return () => {
            shouldSet = false;
        };
    }, [readyToFetch]);
    useEffect(() => {
        // Fetch ID preview after rate code update
        if (!rateCode)
            return;
        setCompletedInit(false);
    }, [rateCode]);
    useEffect(() => {
        // Post Without Formating After File Is Processed
        if (!placement.postWithoutFormatting && !uploadedFile)
            return;
        placementActions.fileWithoutProof(placement.formattingError ||
            `Notice Length Greater than ${MAX_CONFIRMED_HTML_WORDS} Words`, true, true);
    }, [placement.postWithoutFormatting && uploadedFile]);
    const displayJPGUrl = isDisplay && placement.processedDisplay && placement.displayUrl
        ? cdnIfy(placement.pdfStoragePath || placement.displayUrl, {
            cloudinaryTransformations: 'f_jpg'
        })
        : '';
    const displayPDFUrl = isDisplay && placement.displayUrl
        ? cdnIfy(placement.pdfStoragePath || placement.displayUrl, {
            cloudinaryTransformations: 'f_pdf'
        })
        : '';
    const handleNoticeWithoutAdProof = () => {
        placementActions.setPostWithoutFormatting(true);
        setShowWaitError(false);
        setShowLargeFileModal(false);
    };
    const handleNoticeWithProof = () => {
        setShowLargeFileModal(false);
        setShowWaitError(false);
    };
    // Conditionally add an additional option to the notice column dropdown
    // selection based on fixed values for the specific notice type that may
    // be outside the actual range of the paper
    const handleCustomFilingColumns = (columnOptions) => {
        var _a, _b;
        // determine the number of columns affixed to the custom notice type
        const numColumns = exists(newspaperSnap) &&
            newspaperSnap.data().allowedNotices &&
            ((_b = (_a = newspaperSnap
                .data()
                .allowedNotices) === null || _a === void 0 ? void 0 : _a.find((type) => type.value === placement.noticeType && type.columns)) === null || _b === void 0 ? void 0 : _b.columns);
        // if there is no fixed number of columns on the notice type or
        // it is already allowed, no changes are necessary
        if (!numColumns ||
            columnOptions.find(f => {
                return f.val === numColumns;
            })) {
            return columnOptions;
        }
        // we hit this block if we have a fixed number of columns that is
        // not allowed by default by the paper
        columnOptions.push({
            val: numColumns,
            label: getWordsFromNumber(numColumns)
        });
        return columnOptions.sort((a, b) => (a.val > b.val ? 1 : -1));
    };
    const { minColumns, maxColumns } = getMinAndMaxColumnsForPaper(newspaper, isDisplay);
    const getColumnSelectProps = () => {
        const values = handleCustomFilingColumns(getColumnOptionRange({
            min: minColumns,
            max: maxColumns
        }));
        if (values.find(f => f.val === placement.columns))
            return { values, value: placement.columns };
        return { values, value: values[0].val };
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, direction: "column", className: classes === null || classes === void 0 ? void 0 : classes.actionsContainer, id: "place-preview" },
            React.createElement("div", { className: "row row-span-1 col-span-2" },
                React.createElement("h2", { className: "text-white text-4xl font-medium" }, "Preview")),
            widthWarning && (React.createElement(Box, { mt: 1 },
                React.createElement(React.Fragment, null,
                    placement.columns < maxColumns && (React.createElement(ErrorContainer, { text: ERRORS.RESIZING_NOTICE, showProgress: true })),
                    placement.columns === maxColumns && (React.createElement(ErrorContainer, { text: `Your content is too wide for the selected number of columns. <br> We recommend adjusting your content to fit in ${maxColumns} columns.` }))))),
            heightWarning && (React.createElement(Box, { mt: 1 },
                React.createElement(ErrorContainer, { text: ERRORS.TOO_TALL }))),
            showConvertToLinerWarning(!!((_p = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _p === void 0 ? void 0 : _p.displayOnlyAds), isDisplay, showDisplayError, placement.postWithoutFormatting) && (React.createElement(Box, { mt: 1 },
                React.createElement(ErrorContainer, { text: ERRORS.DISPLAY, onDismiss: () => setShowDisplayError(false) }),
                React.createElement(ErrorContainer, { text: ERRORS.NO_UPFRONT_PROOF, clickAction: () => setShowSubmitFileWithoutFormattingModal(true), onDismiss: () => setShowDisplayError(false) }))),
            showWaitError &&
                (placement.continueWithLargeFile ||
                    !previewContent.displayParams) && (React.createElement(Box, { mt: 1, style: { cursor: 'pointer' } },
                React.createElement(ErrorContainer, { text: ERRORS.WAIT_REQUEST, clickAction: handleNoticeWithoutAdProof }))),
            placement.postWithoutFormatting && ((_q = placement.filesToAttach) === null || _q === void 0 ? void 0 : _q.length) ? (React.createElement(Box, { mt: 2 },
                React.createElement(NoticeFilePreview, { noticeFile: (_r = placement.filesToAttach) === null || _r === void 0 ? void 0 : _r[0] }))) : (React.createElement(Box, { mt: 2 },
                React.createElement(NoticePreviewContainer, { previewContent: previewContent, loading: loading || showWaitError, openModal: () => setOpenModal(true), isDisplay: isDisplay, isTypeform: isTypeform, uploading: uploading, displayJPGUrl: displayJPGUrl, displayPDFUrl: displayPDFUrl, rate: rate, newspaper: newspaper }))),
            React.createElement(Box, { mt: 2 },
                React.createElement(Grid, { container: true, direction: "column", style: {
                        marginTop: BROWSER === BROWSERS.ie ? '12px' : '',
                        display: placement.postWithoutFormatting ? 'none' : 'inherit'
                    } },
                    React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: BROWSER === BROWSERS.ie ? 12 : 8, style: { flex: BROWSER === BROWSERS.ie ? '1 1 auto' : '' } },
                            React.createElement(ESelect, Object.assign({}, getColumnSelectProps(), { disabled: !placement.columnsEditingEnabled ||
                                    !allowEditParams ||
                                    (!isPublisher && isTypeform), label: "Columns Wide", onChange: (e) => placementActions.setColumns(e.target.value) }))),
                        isDisplay && (React.createElement(InputLabel, { className: classes === null || classes === void 0 ? void 0 : classes.label },
                            "Minimum Column Width:",
                            ' ',
                            Math.max(minColumns, (_s = previewContent.displayParams) === null || _s === void 0 ? void 0 : _s.minColumns, 0) || ' '))),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(Box, { mt: 1 },
                            React.createElement(RateSelect, { disabled: !allowEditParams, setRateCode: setRateCode, rateCode: rateCode, rate: rate, rateCodeError: rateCodeError, setRateCodeError: setRateCodeError, placementActions: placementActions, isDefaultRate: isDefaultRate, rateDescriptionOverride: rateDescriptionOverride, isPublisher: isPublisher }))),
                    isPublisher && isBattleBorn && (newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().govTemplate) && (React.createElement(Box, { mt: 1 },
                        React.createElement(Grid, { item: true, xs: BROWSER === BROWSERS.ie ? 12 : 8, style: { flex: BROWSER === BROWSERS.ie ? '1 1 auto' : '' } },
                            React.createElement(ESelect, { disabled: !allowEditParams, id: "switch-template", values: templateOptions, label: "Template", value: selectedTemplate, onChange: (e) => setSelectedTemplate(e.target.value) }))))))),
        showLargeFileModal && !previewContent.displayParams && (React.createElement(FreeformCModal, { header: "Large File Detected", noExitOutsideModal: true, setOpen: setShowLargeFileModal, afterExit: () => !previewContent.displayParams && setShowWaitError(true), body: `We're having trouble auto-formatting your notice. Would you like to skip formatting and submit the file directly to the newspaper?` },
            React.createElement("section", null,
                React.createElement("button", { id: `confirm-without-formatting`, onClick: handleNoticeWithoutAdProof, className: "bg-blue-200 border w-auto border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition m-w-32" }, "Submit Without Formatting"),
                React.createElement("button", { id: `confirm-keep-waiting`, className: `bg-transparent duration-150 ease-in-out font-medium leading-6 mt-3 px-4 py-2 rounded-md sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-blue-800 transition w-32`, onClick: handleNoticeWithProof }, "Keep Waiting")))),
        openModal && (React.createElement(ImagePreviewModal, { jpgURLs: displayJPGUrl
                ? [displayJPGUrl]
                : ((_u = (_t = previewContent.displayParams) === null || _t === void 0 ? void 0 : _t.imgs) === null || _u === void 0 ? void 0 : _u.length)
                    ? (_v = previewContent.displayParams) === null || _v === void 0 ? void 0 : _v.imgs
                    : [], classes: classes, setOpenModal: setOpenModal }))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(PlaceNoticePreview));
