var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, Box, FormHelperText } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/styles';
import * as EmailValidator from 'email-validator';
import { connect } from 'react-redux';
import api from 'api';
import { exists } from 'lib/types';
import { InviteStatus, OccupationType, State } from 'lib/enums';
import { createNotificationsObject } from 'lib/auth';
import { getCustomer } from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { getOpenInvitesAssociatedWithEmail, getShouldShowAccountIDInPlacement, removeUndefinedFields } from 'lib/helpers';
import { getAllowedOrganizationSnaps } from 'lib/users';
import { customerFetchMethods, customerValidationState } from 'types/customers';
import { TextField } from 'components/TextField';
import { logAndCaptureException } from 'utils';
import { isEqual } from 'lodash';
import { safeStringify } from 'lib/utils/stringify';
import { getLocationParams } from 'utils/urls';
import { push } from 'connected-react-router';
import { Firestore } from 'EnoticeFirebase';
import { useActivelyTyping } from './hooks/useActivelyTyping';
import { CustomerValidationMessage } from './CustomerValidationMessage';
import ScrollStep from './ScrollStep';
import SelectDropdown from './SelectDropdown';
import { fetchAccountNumber, fetchPublisherCustomer, isAnonymousFlow, getFirstAndLastNameFromFullName } from './helpers';
import { useLoading } from './hooks/useLoading';
import { useFirestoreListener } from './hooks/useFirestoreListener';
// Messages to show if we are displaying the account number field
const getCustomerValidationMessageAndColor = ({ customerValidationValue, loadingAccountNumber, loadingPublisherCustomer, newspaperName }) => {
    if (loadingAccountNumber || loadingPublisherCustomer) {
        return { message: 'Loading...', color: 'yellow' };
    }
    const redMessage = `We couldn't find a matching account with ${newspaperName}. Please check your information again or proceed without an account number.`;
    switch (customerValidationValue) {
        case customerValidationState.PUBLISHER_CUSTOMER_FOUND:
            return {
                message: 'Account found. Please edit the account number or customer information if you want to place this notice in a different account.',
                color: 'green'
            };
        case customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN:
            return {
                message: `This customer doesn't have an account number saved in Column. Click the verify button to find a matching account in ${newspaperName}'s database, or enter an account number.`,
                color: 'yellow'
            };
        case customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO:
            return { message: redMessage, color: 'red' };
        case customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID:
            return { message: redMessage, color: 'red' };
        default:
            console.error('Invalid customer validation state value');
            return { message: undefined, color: undefined };
    }
};
const styles = (theme) => createStyles({
    icon: {
        color: theme.palette.grey.scrollFormHeading,
        height: 16
    }
});
const FilerSelect = ({ id, activeStepId, next, previous, onDisabledStepClick, newspaper, placement, isPublisher, placementActions, noticeState, authActions, setShowEnterPasswordModal, setUserEmail }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [filerInfo, setFilerInfo] = useState();
    const [, setSaving] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const isPreviousEnabled = exists(newspaper);
    const [existingCustomer, setExistingCustomer] = useFirestoreListener();
    const [existingFiler, setExistingFiler] = useState(null);
    const [filerOrgOptions, setFilerOrgOptions] = useState();
    const [selectedFilerOrg, setSelectedFilerOrg] = useState();
    const [isAccountOnHold, setIsAccountOnHold] = useState(false);
    const [showAccountNumber, setShowAccountNumber] = useState(false);
    const [accountNumberOnFile, setAccountNumberOnFile] = useState(null);
    const [verifiedAccountNumber, setVerifiedAccountNumber] = useState(null);
    const [activelyTypingEmail, setActivelyTypingEmail] = useActivelyTyping(3000);
    const [accountNumberFoundByCustomerInfo, setAccountNumberFoundByCustomerInfo] = useState(false);
    const [loadingPublisherCustomer, fetchAndSetPublisherCustomerWithLoading] = useLoading();
    const [loadingAccountNumber, fetchAndSetAccountNumberWithLoading] = useLoading();
    const [customerValidationValue, setCustomerValidationValue] = useState(null);
    const ctx = getFirebaseContext();
    const { t } = useTranslation();
    useEffect(() => {
        var _a;
        if (!loggedOutFlow &&
            !isPublisher &&
            !existingFiler &&
            !!((_a = placement.filer) === null || _a === void 0 ? void 0 : _a.id)) {
            void (() => __awaiter(void 0, void 0, void 0, function* () {
                const advertiserSnap = yield placement.filer.get();
                if (exists(advertiserSnap)) {
                    setExistingFiler(advertiserSnap);
                }
            }))();
        }
    }, [(_a = placement.filer) === null || _a === void 0 ? void 0 : _a.id]);
    useEffect(() => {
        setUserEmail((existingFiler === null || existingFiler === void 0 ? void 0 : existingFiler.data().email) || '');
    }, [safeStringify(existingFiler)]);
    useEffect(() => {
        if ((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) &&
            id === activeStepId &&
            EmailValidator.validate(filerInfo.email)) {
            void setFilerState(filerInfo.email);
            /* If the current notice was duplicated from an existing one, use the filer information from that old notice */
        }
        else if (getLocationParams().get('duplicate') === 'true' &&
            exists(noticeState === null || noticeState === void 0 ? void 0 : noticeState.filer)) {
            void handleEmailChange(noticeState.filer.data().email);
        }
    }, [activeStepId]);
    useEffect(() => {
        if (!existingFiler) {
            setFilerOrgOptions([]);
            setExistingCustomer(null);
            setFilerInfo(Object.assign({}, ((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) ? { email: filerInfo.email } : {})));
            setCustomerValidationValue(null);
        }
        if (existingFiler && !loggedOutFlow) {
            void (() => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                const { organization, email } = existingFiler.data();
                if (organization) {
                    const placementFilerOrg = (yield ((_a = placement === null || placement === void 0 ? void 0 : placement.filedBy) === null || _a === void 0 ? void 0 : _a.get()));
                    const allOrgs = yield getAllowedOrganizationSnaps(existingFiler);
                    setFilerOrgOptions(allOrgs);
                    if (shouldShowOrgDropdown &&
                        allOrgs.some(o => o.id === (placementFilerOrg === null || placementFilerOrg === void 0 ? void 0 : placementFilerOrg.id))) {
                        setSelectedFilerOrg(placementFilerOrg);
                    }
                }
                if (!organization) {
                    setFilerOrgOptions([]);
                }
                const customer = newspaper
                    ? yield getCustomer(ctx, existingFiler, newspaper)
                    : null;
                if (!isEqual(customer === null || customer === void 0 ? void 0 : customer.data(), existingCustomer === null || existingCustomer === void 0 ? void 0 : existingCustomer.data())) {
                    setExistingCustomer(customer);
                }
                if (customer) {
                    setFilerInfo(removeUndefinedFields({
                        email,
                        firstName: customer.data().firstName,
                        lastName: customer.data().lastName,
                        organizationName: customer.data().organizationName,
                        address: customer.data().address || '',
                        addressLine2: customer.data().addressLine2 || '',
                        city: (_b = customer.data().city) !== null && _b !== void 0 ? _b : undefined,
                        state: (_c = customer.data().state) !== null && _c !== void 0 ? _c : undefined,
                        zipCode: (_d = customer.data().zipCode) !== null && _d !== void 0 ? _d : undefined,
                        phone: (_e = customer.data().phone) !== null && _e !== void 0 ? _e : undefined
                    }));
                }
                else {
                    // If a user exists in column but does not have
                    // a customer account with the current newspaper,
                    // populate the customer information step with
                    // information from the user
                    setFilerInfo(removeUndefinedFields({
                        email,
                        firstName: existingFiler.data().firstName,
                        lastName: existingFiler.data().lastName,
                        organizationName: existingFiler.data().organizationName,
                        address: existingFiler.data().address || '',
                        addressLine2: existingFiler.data().addressLine2 || '',
                        city: (_f = existingFiler.data().city) !== null && _f !== void 0 ? _f : undefined,
                        state: (_g = existingFiler.data().state) !== null && _g !== void 0 ? _g : undefined,
                        zipCode: (_h = existingFiler.data().zipCode) !== null && _h !== void 0 ? _h : undefined,
                        phone: (_j = existingFiler.data().phone) !== null && _j !== void 0 ? _j : undefined
                    }));
                }
                setIsAccountOnHold(!!((_k = customer === null || customer === void 0 ? void 0 : customer.data()) === null || _k === void 0 ? void 0 : _k.isAccountOnHold));
            }))();
        }
        if (showAccountNumber &&
            !!(filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) &&
            !accountNumberFoundByCustomerInfo) {
            void fetchAndSetAccountNumberWithLoading(fetchAndSetAccountNumber);
        }
    }, [safeStringify(existingFiler), safeStringify(existingCustomer)]);
    useEffect(() => {
        if ((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.phone) && !/[0-9]/.test(filerInfo.phone)) {
            handleFilerInfoFieldChange({ phone: '' });
        }
        setIsPhoneValid((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.phone) === undefined || !filerInfo.phone.includes('_'));
    }, [filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.phone]);
    useEffect(() => {
        const determineWhetherToShowAccountNum = () => __awaiter(void 0, void 0, void 0, function* () {
            if (exists(newspaper)) {
                const shouldShowAccountNum = yield getShouldShowAccountIDInPlacement(newspaper);
                setShowAccountNumber(shouldShowAccountNum);
            }
            else {
                setShowAccountNumber(false);
            }
        });
        void determineWhetherToShowAccountNum();
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    const getFiler = (email) => __awaiter(void 0, void 0, void 0, function* () {
        const existingQuery = yield getFirebaseContext()
            .usersRef()
            .where('email', '==', email.toLowerCase())
            .get();
        if (existingQuery.docs.length) {
            if (existingQuery.docs.length > 1) {
                void api.post('notifications/slack', {
                    message: `Confirm Filer Step found duplicate users: ${existingQuery.docs
                        .map(doc => doc.id)
                        .join(', ')}`
                });
            }
            return existingQuery.docs[0];
        }
    });
    const loggedOutFlow = isAnonymousFlow();
    const shouldShowOrgDropdown = !loggedOutFlow && !!(filerOrgOptions === null || filerOrgOptions === void 0 ? void 0 : filerOrgOptions.length) && filerOrgOptions.length > 1;
    const shouldShowOnlyOrgDropdown = shouldShowOrgDropdown && !isPublisher;
    const filerHasExactlyOneOrg = (filerOrgOptions === null || filerOrgOptions === void 0 ? void 0 : filerOrgOptions.length) === 1;
    /**
     * For advertisers who start placing a notice anonymously,
     * we show the confirm filer step with the customer fields disabled
     * to maintain the same steps as shown in the anonymous flow
     */
    const advertiserStartedPlacingAnonymously = sessionStorage.getItem('startedFromAnonymousFlow') === 'true' &&
        !isPublisher &&
        !loggedOutFlow;
    /* We should disable all fields except for email & account number under the following circumstances:
      1. A valid email has not been entered
      2. A customer already exists for a given filer/newspaper */
    const shouldDisableFixedCustomerFields = activelyTypingEmail ||
        !EmailValidator.validate((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) || '') ||
        !!existingCustomer ||
        advertiserStartedPlacingAnonymously;
    const setFilerState = (email) => __awaiter(void 0, void 0, void 0, function* () {
        if (!email) {
            setExistingFiler(null);
            return;
        }
        const filer = yield getFiler(email);
        if (exists(filer)) {
            setExistingFiler(filer);
        }
        else {
            setExistingFiler(null);
        }
    });
    const proceedToNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onExit();
    });
    const eraseAccountNumberAndProceed = () => __awaiter(void 0, void 0, void 0, function* () {
        placementActions.setAccountNumber(null);
        setAccountNumberFoundByCustomerInfo(false);
        setCustomerValidationValue(null);
        yield proceedToNextStep();
    });
    const handleFilerInfoFieldChange = (updates) => {
        setFilerInfo(Object.assign(Object.assign({}, filerInfo), updates));
    };
    const fillEmptyFilerInfoFields = (newFilerData) => {
        const updateFields = Object.keys(newFilerData).reduce((updates, dataField) => {
            if (!(filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo[dataField])) {
                return Object.assign(Object.assign({}, updates), { [dataField]: newFilerData[dataField] });
            }
            return updates;
        }, {});
        handleFilerInfoFieldChange(updateFields);
    };
    const handleAccountNumberChange = (accountNumber) => {
        placementActions.setAccountNumber(accountNumber);
        setAccountNumberFoundByCustomerInfo(false);
        if (!!accountNumber &&
            (accountNumber === verifiedAccountNumber ||
                accountNumber === accountNumberOnFile)) {
            setCustomerValidationValue(customerValidationState.PUBLISHER_CUSTOMER_FOUND);
        }
        else {
            setCustomerValidationValue(null);
        }
    };
    const handleEmailChange = (email) => __awaiter(void 0, void 0, void 0, function* () {
        setActivelyTypingEmail(true);
        handleFilerInfoFieldChange({ email });
        const validEmail = EmailValidator.validate(email);
        if (email && validEmail) {
            setEmailError('');
            yield setFilerState(email);
        }
        else if (email && !validEmail) {
            setEmailError('Invalid Email');
            setExistingFiler(null);
        }
        else {
            setEmailError('');
            setExistingFiler(null);
        }
    });
    const fetchAndSetPublisherCustomer = (filerInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const fetchResults = yield fetchPublisherCustomer({
            accountNumber: placement.accountNumber || null,
            verifiedAccountNumber,
            customerInfo: filerInfo || null,
            newspaperSnap: newspaper || null
        });
        if (fetchResults.success) {
            setCustomerValidationValue(customerValidationState.PUBLISHER_CUSTOMER_FOUND);
            if (fetchResults.method === customerFetchMethods.FROM_CUSTOMER_INFO ||
                fetchResults.method === customerFetchMethods.FROM_ACCOUNT_NUMBER) {
                placementActions.setAccountNumber(`${fetchResults.customer.CustomerID}`);
                setVerifiedAccountNumber(`${fetchResults.customer.CustomerID}`);
                if (!existingCustomer) {
                    const { firstName, lastName } = getFirstAndLastNameFromFullName(fetchResults.customer.CustomerName);
                    fillEmptyFilerInfoFields(Object.assign(Object.assign(Object.assign({ email: fetchResults.customer.Email, phone: fetchResults.customer.Phone }, (firstName ? { firstName } : {})), (lastName ? { lastName } : {})), { name: fetchResults.customer.CustomerName }));
                }
                setAccountNumberFoundByCustomerInfo(true);
            }
        }
        else if (fetchResults.reason ===
            customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN) {
            setCustomerValidationValue(customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN);
        }
        else if (fetchResults.reason ===
            customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID) {
            setCustomerValidationValue(customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID);
        }
        else if (fetchResults.reason ===
            customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO) {
            setCustomerValidationValue(customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO);
        }
    });
    const fetchAndSetAccountNumber = () => __awaiter(void 0, void 0, void 0, function* () {
        const fetchResults = yield fetchAccountNumber({
            noticeSnap: (noticeState === null || noticeState === void 0 ? void 0 : noticeState.notice) || null,
            filerRef: (existingFiler === null || existingFiler === void 0 ? void 0 : existingFiler.ref) || null
        });
        if (fetchResults.success) {
            placementActions.setAccountNumber(fetchResults.accountNumber);
            setAccountNumberOnFile(fetchResults.accountNumber);
            setCustomerValidationValue(customerValidationState.PUBLISHER_CUSTOMER_FOUND);
        }
        else {
            placementActions.setAccountNumber(null);
            setAccountNumberOnFile(null);
            setCustomerValidationValue(customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN);
        }
        setAccountNumberFoundByCustomerInfo(false);
    });
    const getOrCreateFiler = () => __awaiter(void 0, void 0, void 0, function* () {
        var _j, _k, _l, _m;
        let filer;
        const { email } = filerInfo;
        if (email) {
            filer = yield getFiler(email);
        }
        if (filer) {
            const { anonymous } = filer.data();
            if (anonymous && filerInfo) {
                yield filer.ref.update({
                    firstName: filerInfo.firstName,
                    lastName: filerInfo.lastName,
                    name: `${filerInfo.firstName} ${filerInfo.lastName}`,
                    organizationName: filerInfo.organizationName || '',
                    phone: filerInfo.phone || '',
                    address: filerInfo.address || '',
                    addressLine2: filerInfo.addressLine2 || '',
                    city: filerInfo.city || '',
                    zipCode: filerInfo.zipCode || '',
                    state: filerInfo.state
                        ? parseInt(`${filerInfo.state}`, 10)
                        : (_j = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _j === void 0 ? void 0 : _j.state
                });
            }
            return filer.ref;
        }
        const pendingInvites = yield getOpenInvitesAssociatedWithEmail(ctx, email.toLowerCase());
        /**
         * If the input user has no existing pending invites,
         * send them an individual invite and
         * register them as an anonymous user
         */
        if (!pendingInvites.length && filerInfo && filerInfo.email) {
            yield ctx.invitesRef().add({
                email: filerInfo.email.toLowerCase(),
                occupationType: OccupationType.individual.value,
                createdAt: Firestore.FieldValue.serverTimestamp(),
                status: InviteStatus.pending.value
            });
            const newFiler = yield ctx.usersRef().add(Object.assign(Object.assign({}, filerInfo), { anonymous: true, notifications: createNotificationsObject(OccupationType.individual.value), name: `${filerInfo.firstName} ${filerInfo.lastName}`, email: ((_k = filerInfo.email) === null || _k === void 0 ? void 0 : _k.toLowerCase()) || '', phone: filerInfo.phone || '', lastSignInTime: null, address: filerInfo.address || '', addressLine2: filerInfo.addressLine2 || '', city: filerInfo.city || '', zipCode: filerInfo.zipCode || '', state: filerInfo.state
                    ? parseInt(`${filerInfo.state}`, 10)
                    : (_l = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _l === void 0 ? void 0 : _l.state }));
            return newFiler;
        }
        /**
         * Otherwise, if the user does have pending invites (individual or organization)
         * create a new user with the information that has been inpout
         */
        const newFiler = yield ctx.usersRef().add(Object.assign(Object.assign({}, filerInfo), { anonymous: true, notifications: createNotificationsObject(OccupationType.individual.value), name: filerInfo ? `${filerInfo.firstName} ${filerInfo.lastName}` : '', email: ((_m = filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) === null || _m === void 0 ? void 0 : _m.toLowerCase()) || '', phone: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.phone) || '', lastSignInTime: null, zipCode: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.zipCode) || '' }));
        return newFiler;
    });
    const shouldShowEnterPasswordModal = () => __awaiter(void 0, void 0, void 0, function* () {
        if (loggedOutFlow && exists(existingFiler)) {
            const authResponse = yield api.post('users/get-auth-providers-by-email', {
                email: existingFiler.data().email
            });
            if (authResponse.success) {
                // Non-anonymous users have a non-zero number of firebase auth providers
                return !!authResponse.numAuthProviders;
            }
        }
        return false;
    });
    const onExit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _o, _p, _q;
        if (!exists(newspaper)) {
            return;
        }
        const customerUserName = ((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.name) || `${filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.firstName} ${filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.lastName}`).trim();
        placementActions.setNewCustomerInfo(Object.assign(Object.assign({}, filerInfo), (customerUserName ? { userName: customerUserName } : {})));
        setSaving(true);
        const filer = yield getOrCreateFiler();
        const filerObj = yield filer.get();
        const { defaultTemplate } = filerObj.data() || {};
        if (filer) {
            placementActions.setFiler(filer);
        }
        if (selectedFilerOrg) {
            placementActions.setFiledBy(selectedFilerOrg.ref);
        }
        else if (filerHasExactlyOneOrg) {
            placementActions.setFiledBy(filerOrgOptions[0].ref);
        }
        else {
            placementActions.setFiledBy(null);
        }
        if (defaultTemplate)
            placementActions.setTemplate(defaultTemplate);
        /**
         * If the entered email is associated with an existing filer,
         * open the login modal to prompt the user to login
         * and do not move onto the next step
         */
        if (yield shouldShowEnterPasswordModal()) {
            placementActions.saveDraft();
            setShowEnterPasswordModal(true);
            return;
        }
        /**
         * If we get here, the filer is anonymous and does not have an existing account
         * Create an anonymous account for the user and log them in using a temporary password.
         */
        if (loggedOutFlow) {
            const randomPassword = Math.random().toString(36).substring(2);
            const filerSnap = yield filer.get();
            const email = ((_o = filerSnap.data()) === null || _o === void 0 ? void 0 : _o.email) || '';
            const { success, error } = yield api.post('users/register', {
                firstName: ((_p = filerSnap.data()) === null || _p === void 0 ? void 0 : _p.firstName) || '',
                lastName: ((_q = filerSnap.data()) === null || _q === void 0 ? void 0 : _q.lastName) || '',
                email,
                password: randomPassword,
                occupation: OccupationType.individual.value
            });
            if (success) {
                placementActions.setFiler(filer);
                placementActions.saveDraft();
                authActions.login(email, randomPassword, false);
                authActions.setShowPasswordReset(true);
                push('/');
            }
            else {
                logAndCaptureException('Error registering and logging in user during anonymous flow', error, { userId: filer.id });
                return;
            }
        }
        next();
    });
    const filerInfoIsComplete = Boolean((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.firstName) &&
        (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.lastName) &&
        (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) &&
        EmailValidator.validate(filerInfo.email) &&
        isPhoneValid &&
        (!((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.requireAddressOnFilers) ||
            ((filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.address) &&
                (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.state) &&
                (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.zipCode) &&
                (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.city))));
    const stepCompleteWithoutAccountNumber = !isAccountOnHold &&
        filerInfoIsComplete &&
        (!shouldShowOrgDropdown || !!selectedFilerOrg) &&
        !loadingPublisherCustomer;
    const stepComplete = stepCompleteWithoutAccountNumber &&
        customerValidationValue !==
            customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO &&
        customerValidationValue !==
            customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID;
    const nextOrVerifyText = !showAccountNumber ||
        (verifiedAccountNumber && verifiedAccountNumber === placement.accountNumber)
        ? 'Next'
        : 'Verify';
    const isNextOrVerifyEnabled = {
        Next: stepComplete,
        Verify: stepComplete ||
            (!isAccountOnHold &&
                !!placement.accountNumber &&
                !loadingAccountNumber &&
                !loadingPublisherCustomer &&
                customerValidationValue !==
                    customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO &&
                customerValidationValue !==
                    customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID)
    }[nextOrVerifyText];
    const handleNextOrVerify = {
        Next: proceedToNextStep,
        Verify: () => fetchAndSetPublisherCustomerWithLoading(() => __awaiter(void 0, void 0, void 0, function* () {
            return yield fetchAndSetPublisherCustomer(filerInfo);
        }))
    }[nextOrVerifyText];
    const nextOrVerifyTooltipText = {
        Next: '',
        Verify: isNextOrVerifyEnabled
            ? ''
            : `Enter email, name, and phone number for this customer${showAccountNumber ? ', or enter an account number.' : '.'}`
    }[nextOrVerifyText];
    return (React.createElement(ScrollStep, { id: id, complete: isNextOrVerifyEnabled, activeStepId: activeStepId, next: handleNextOrVerify, nextText: nextOrVerifyText, nextTooltipText: nextOrVerifyTooltipText, previous: isPreviousEnabled
            ? () => __awaiter(void 0, void 0, void 0, function* () {
                previous();
            })
            : () => { }, edit: existingCustomer &&
            (() => window.open(`/settings/organization/?tab=customers&customerId=${existingCustomer.id}`, '_blank')), editText: "Edit customer", editTooltipText: `This customer is already in ${((_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _c === void 0 ? void 0 : _c.name) || 'your newspaper'}'s customer list in Column. You will be directed to the customer page to edit their contact information.`, canSkip: stepCompleteWithoutAccountNumber, skip: showAccountNumber ? eraseAccountNumberAndProceed : null, skipText: 'Skip account number', skipTooltipText: `If you proceed without an account number, then a new account with ${((_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.name) || 'your newspaper'} will be created upon notice submission.`, onDisabledStepClick: onDisabledStepClick, title: loggedOutFlow || advertiserStartedPlacingAnonymously
            ? 'Provide Contact Information'
            : 'Customer Information', caption: loggedOutFlow || advertiserStartedPlacingAnonymously
            ? 'The publisher will reach out if they have questions about your notice.'
            : isPublisher
                ? 'Who placed this notice? Please enter information below and Column will handle billing.'
                : 'Please select the organization that is placing this notice.', onHoldText: isAccountOnHold
            ? 'This customer’s account is on hold. To remove the hold, navigate to the customer tab.'
            : undefined },
        React.createElement("div", { className: "container flex-col" },
            !shouldShowOnlyOrgDropdown && (React.createElement(React.Fragment, null,
                React.createElement(Box, { mt: 1, style: { width: '100%' } },
                    React.createElement(Grid, { container: true, direction: "row" },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(FormControl, { required: true, fullWidth: true },
                                React.createElement(Box, null,
                                    React.createElement(TextField, { id: "customer-email", placeholder: "Email *", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) || '', type: "email", disabled: loadingPublisherCustomer ||
                                            advertiserStartedPlacingAnonymously, onChange: handleEmailChange, labelText: "", size: "small" })))))),
                React.createElement(Box, { mt: 1, style: { width: '100%' } },
                    React.createElement(Grid, { container: true, direction: "row" },
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(FormControl, { required: true, fullWidth: true },
                                React.createElement(Box, null,
                                    React.createElement(TextField, { id: "customer-first-name", placeholder: "First name *", size: "small", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.firstName) || '', onChange: firstName => handleFilerInfoFieldChange({ firstName }), labelText: "", disabled: shouldDisableFixedCustomerFields })))),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Box, { ml: 1 },
                                React.createElement(FormControl, { required: true, fullWidth: true },
                                    React.createElement(TextField, { id: "customer-last-name", placeholder: "Last name *", size: "small", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.lastName) || '', onChange: lastName => handleFilerInfoFieldChange({ lastName }), labelText: "", disabled: shouldDisableFixedCustomerFields })))))),
                React.createElement(Box, { mt: 1, style: { width: '100%' } },
                    React.createElement(Grid, { container: true, direction: "row" },
                        React.createElement(Grid, { item: true, xs: 4, style: { zIndex: 50 } },
                            React.createElement(Box, null,
                                React.createElement(FormControl, { required: true, fullWidth: true },
                                    React.createElement(TextField, { type: "tel", size: "small", errorText: isPhoneValid ? '' : ' ', id: "customer-phone-number", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.phone) || '', onChange: phone => handleFilerInfoFieldChange({ phone }), placeholder: "Phone Number", labelText: "", disabled: shouldDisableFixedCustomerFields })))),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(FormControl, { required: true, fullWidth: true },
                                React.createElement(Box, { ml: 1 },
                                    React.createElement(TextField, { id: "customer-address", placeholder: "Address", size: "small", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.address) || '', onChange: address => handleFilerInfoFieldChange({ address }), labelText: "", disabled: shouldDisableFixedCustomerFields })))),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Box, { ml: 1 },
                                React.createElement(FormControl, { required: true, fullWidth: true },
                                    React.createElement(TextField, { id: "customer-address-2", placeholder: "Address Line 2", size: "small", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.addressLine2) || '', onChange: addressLine2 => handleFilerInfoFieldChange({ addressLine2 }), labelText: "", disabled: shouldDisableFixedCustomerFields })))))),
                React.createElement(Box, { mt: 1, style: { width: '100%' } },
                    React.createElement(Grid, { container: true, direction: "row" },
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Box, null,
                                React.createElement(FormControl, { required: true, fullWidth: true },
                                    React.createElement(TextField, { id: "customer-city", placeholder: "City", size: "small", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.city) || '', onChange: city => handleFilerInfoFieldChange({ city }), labelText: "", disabled: shouldDisableFixedCustomerFields })))),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(FormControl, { required: true, fullWidth: true },
                                React.createElement(Box, { ml: 1 },
                                    React.createElement(TextField, { id: "customer-zip", placeholder: "Zip Code", size: "small", value: (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.zipCode) || '', onChange: zipCode => handleFilerInfoFieldChange({ zipCode }), labelText: "", disabled: shouldDisableFixedCustomerFields })))),
                        React.createElement(Grid, { item: true, xs: 4, style: { zIndex: 70 } },
                            React.createElement(Box, { ml: 1 },
                                React.createElement("div", { className: "mt-2" },
                                    React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                                            if (selected) {
                                                handleFilerInfoFieldChange({
                                                    state: selected.id
                                                });
                                            }
                                        }, placeholder: "State *", options: State.items().map((state) => ({
                                            id: state.value,
                                            label: state.label
                                        })), selected: State.items().filter(item => {
                                            return item.value === (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.state)
                                                ? { id: item.value, label: item.label }
                                                : '';
                                        }), value: State.items().filter(item => {
                                            return item.value === (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.state)
                                                ? { id: item.value, label: item.label }
                                                : '';
                                        }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', noShadow: true, required: true, isDisabled: shouldDisableFixedCustomerFields })))))))),
            React.createElement(Box, { style: { width: '100%', marginTop: '12px' } },
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, style: { maxWidth: '100%' } },
                        React.createElement(FormControl, { required: true, fullWidth: true, style: { zIndex: 50, maxWidth: '100%' } }, shouldShowOrgDropdown ? (React.createElement(SelectDropdown, { id: "customer-organization-select", placeholder: "Organization", className: "text-gray-700", options: filerOrgOptions.map(org => ({
                                value: org.id,
                                label: org.data().name
                            })), selected: selectedFilerOrg
                                ? {
                                    id: selectedFilerOrg.id,
                                    label: (_e = selectedFilerOrg.data()) === null || _e === void 0 ? void 0 : _e.name
                                }
                                : null, value: selectedFilerOrg
                                ? {
                                    id: selectedFilerOrg.id,
                                    label: (_f = selectedFilerOrg.data()) === null || _f === void 0 ? void 0 : _f.name
                                }
                                : '', onChange: (newValue) => {
                                if (!newValue)
                                    return;
                                setSelectedFilerOrg(filerOrgOptions.find(org => org.id === newValue.value));
                            } })) : (React.createElement(TextField, { id: "customer-organization-name", placeholder: t('placement.confirm-filer-step.customer-org'), value: filerHasExactlyOneOrg
                                ? filerOrgOptions[0].data().name
                                : (filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.organizationName) || '', size: "small", onChange: organizationName => handleFilerInfoFieldChange({ organizationName }), labelText: "", disabled: filerHasExactlyOneOrg || shouldDisableFixedCustomerFields })))))),
            showAccountNumber && (React.createElement(Box, { mt: 1, style: { width: '100%' } },
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(FormControl, { required: true, fullWidth: true },
                            React.createElement(TextField, { id: "account-number-publisher", placeholder: "Account Number", size: "small", value: placement.accountNumber || '', onChange: handleAccountNumberChange, labelText: "", errorText: [
                                    customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID,
                                    customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
                                ].includes(customerValidationValue || NaN)
                                    ? ' '
                                    : '' })))))),
            React.createElement(FormControl, { required: true, fullWidth: true },
                emailError !== '' && (React.createElement(Box, null,
                    React.createElement(FormHelperText, { error: emailError !== '', style: { marginTop: 0 } }, emailError))),
                shouldShowOrgDropdown && isPublisher && (React.createElement(Grid, { container: true, direction: "row", alignItems: "flex-start", wrap: "nowrap", style: { paddingTop: '8px' } },
                    React.createElement(Grid, { item: true },
                        React.createElement("div", { className: "pl-1" },
                            React.createElement(FormHelperText, { "data-testid": "filer-organization-message", style: { marginTop: 0 } }, `${(filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) || 'This user'} is associated with multiple organizations on Column. Select the organization that is placing the notice.`))))),
                !!existingCustomer && !filerInfoIsComplete && (React.createElement(Grid, { container: true, direction: "row", alignItems: "flex-start", wrap: "nowrap", style: { paddingTop: '8px' } },
                    React.createElement(Grid, { item: true },
                        React.createElement("div", { className: "pl-1" },
                            React.createElement(FormHelperText, { "data-testid": "edit-customer-message", style: { marginTop: 0 } }, `${(filerInfo === null || filerInfo === void 0 ? void 0 : filerInfo.email) || 'This user'} is in your customers list, but they are missing contact information. Please click "Edit customer" to provide this customer's details.`))))),
                showAccountNumber && customerValidationValue && (React.createElement(CustomerValidationMessage, { customerValidationMessage: getCustomerValidationMessageAndColor({
                        customerValidationValue,
                        loadingAccountNumber,
                        loadingPublisherCustomer,
                        newspaperName: ((_g = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _g === void 0 ? void 0 : _g.name) || 'your newspaper'
                    }).message, messageColor: getCustomerValidationMessageAndColor({
                        customerValidationValue,
                        loadingAccountNumber,
                        loadingPublisherCustomer,
                        newspaperName: ((_h = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _h === void 0 ? void 0 : _h.name) || 'your newspaper'
                    }).color })),
                advertiserStartedPlacingAnonymously && (React.createElement(CustomerValidationMessage, { customerValidationMessage: 'If you need to update your account information, please go to settings.', messageColor: 'yellow' }))))));
};
const mapStateToProps = (state) => ({
    placement: state.placement,
    isPublisher: state.auth.isPublisher
});
export default connect(mapStateToProps)(withStyles(styles)(FilerSelect));
