var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { QuestionMarkRoundedIcon, CogIcon, AccountCircleIcon } from 'icons';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { canRunDonations } from 'lib/publishers';
import { useTranslation } from 'react-i18next';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { getLocationParams, getSubdomain } from 'utils/urls';
import { userHasPermission, userIsSuper } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { getFirebaseContext } from 'utils/firebase';
import { filerOrgHasCustomerOrgWithBulkPaymentEnabled, userHasCustomerWithBulkPaymentEnabled } from 'lib/notice/customer';
import { OccupationType, Country, OrganizationType } from 'lib/enums';
import SwitchToChrome from '../components/SwitchToChrome';
import ActiveOrganizationSelect from './ActiveOrganizationSelect';
import { exists } from '../lib/types';
import Notifications from '../components/NotificationsDrawer/Notifications';
import Survey from '../routes/settings/publisher/Survey';
const mapStateToProps = (state) => {
    var _a, _b, _c, _d, _e;
    return ({
        activeOrganization: (_a = state.auth.activeOrganization) !== null && _a !== void 0 ? _a : undefined,
        organization: (_b = state.auth.organization) !== null && _b !== void 0 ? _b : undefined,
        orgContext: (_c = state.auth.orgContext) !== null && _c !== void 0 ? _c : undefined,
        user: (_d = state.auth.user) !== null && _d !== void 0 ? _d : undefined,
        userAuth: (_e = state.auth.userAuth) !== null && _e !== void 0 ? _e : undefined,
        location: state.router.location,
        showAllOrgsNotices: state.auth.showAllOrgsNotices
    });
};
const COLUMN_LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2FColumn_Logo_Blue-08%20(1).png?alt=media&token=9c3821a8-d4be-401c-af9b-e0e2c0d8e328';
const isCurrentlyRunningDonations = (activeOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    if (canRunDonations(activeOrganization)) {
        return false;
    }
    const donationProfileQuery = yield Firebase.firestore()
        .collection(Collections.donationProfiles)
        .where('organization', '==', activeOrganization.ref)
        .limit(1)
        .get();
    if (donationProfileQuery.empty)
        return false;
    const [profile] = donationProfileQuery.docs;
    if (!profile.data().legalDocs)
        return false;
    return true;
});
const Frame = ({ userAuth, organization, user, noFramePadding, location, activeOrganization, push, showAllOrgsNotices, children }) => {
    var _a;
    const isSuper = user && userIsSuper(user);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
    const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);
    const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
    const [isActiveOrgSelectOpen, setIsActiveOrgSelectOpen] = useState(false);
    const [frameLogoURL, setFrameLogoURL] = useState(COLUMN_LOGO_URL);
    const [showDonationTab, setShowDonationTab] = useState(false);
    const [customerWithBulkPaymentEnabled, setCustomerWithBulkPaymentEnabled] = useState(false);
    const [customerOrgWithBulkPaymentEnabled, setCustomerOrgWithBulkPaymentEnabled] = useState(false);
    const pathName = window.location.pathname;
    const { t, i18n } = useTranslation();
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(activeOrganization))
                return;
            // Future TODO: we should have a language toggle
            if (activeOrganization.data().country === Country.GBR.value) {
                yield i18n.changeLanguage('en-GB');
            }
            if (yield isCurrentlyRunningDonations(activeOrganization)) {
                setShowDonationTab(true);
            }
            try {
                const ctx = getFirebaseContext();
                const res = yield filerOrgHasCustomerOrgWithBulkPaymentEnabled(ctx, activeOrganization);
                if (res) {
                    setCustomerOrgWithBulkPaymentEnabled(true);
                }
            }
            catch (e) {
                // This is somewhat expected, it happens on every log out
                console.warn('Failure in filerOrgHasCustomerOrgWithBulkPaymentEnabled');
            }
        }))();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(user))
                return;
            try {
                const ctx = getFirebaseContext();
                const res = yield userHasCustomerWithBulkPaymentEnabled(ctx, user);
                if (res) {
                    setCustomerWithBulkPaymentEnabled(true);
                }
            }
            catch (e) {
                // This is somewhat expected, it happens on every log out
                console.warn('Failure in userHasCustomerWithBulkPaymentEnabled');
            }
        }))();
    }, [user === null || user === void 0 ? void 0 : user.id]);
    useEffect(() => {
        const getFrameUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            if (window.location.href.includes('florida'))
                return '/FPF-color.png';
            const subdomainLogo = getLocationParams().get('logo');
            if (subdomainLogo) {
                setFrameLogoURL(subdomainLogo);
                return;
            }
            const subdomainSnapshot = yield Firebase.firestore()
                .collection(Collections.organizations)
                .where('subdomain', '==', getSubdomain())
                .get();
            if (!subdomainSnapshot.docs.length) {
                return;
            }
            const subdomainOrg = subdomainSnapshot.docs[0];
            if (subdomainOrg.data().filingFlowSubdomainImage &&
                getSubdomain() === subdomainOrg.data().subdomain) {
                setFrameLogoURL(subdomainOrg.data().filingFlowSubdomainImage);
            }
        });
        void getFrameUrl();
    }, []);
    const userIsLoggedOutOrAnonymous = !userAuth || userAuth.isAnonymous;
    const userIsLoggedIn = userAuth && !userAuth.isAnonymous;
    /**
     * Show a Report tab, which links out to/ shows GDS dashboards,
     * if the logged-in user is a part of a newspaper,
     * and we are not in the all organizations view.
     */
    const showReportsTab = ((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.organizationType) ===
        OrganizationType.newspaper.value && !showAllOrgsNotices;
    const displayPaymentTab = (user, activeOrganization) => {
        var _a, _b, _c, _d;
        if (!exists(user))
            return false;
        let hasBulkPaymentEnabled = ((_a = user.data()) === null || _a === void 0 ? void 0 : _a.bulkPaymentEnabled) || customerWithBulkPaymentEnabled;
        if (exists(activeOrganization)) {
            hasBulkPaymentEnabled =
                ((_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.bulkPaymentEnabled) ||
                    customerWithBulkPaymentEnabled ||
                    customerOrgWithBulkPaymentEnabled;
            return (userHasPermission(user, Permissions.PAYMENTS_REPORT) &&
                hasBulkPaymentEnabled &&
                ((_c = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.organizationType) !==
                    OrganizationType.newspaper.value);
        }
        return (userHasPermission(user, Permissions.PAYMENTS_REPORT) &&
            hasBulkPaymentEnabled &&
            ((_d = user.data()) === null || _d === void 0 ? void 0 : _d.occupation) !== OccupationType.publishing.value);
    };
    // Only grants Debugger Tool access to those with @column.us email and who has their hasDebuggerAccess set to true
    const debuggerAccess = () => {
        var _a;
        if (userAuth) {
            if (((_a = userAuth.email) === null || _a === void 0 ? void 0 : _a.match('.*@column[.]us')) &&
                (user === null || user === void 0 ? void 0 : user.data().hasDebuggerAccess)) {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "flex flex-col h-screen bg-gray-80" },
        React.createElement("div", { className: "z-20 flex" },
            exists(user) && isSuper && (React.createElement("div", { className: "flex-grow flex justify-center items-center bg-yellow-500" },
                React.createElement("div", { className: "font-semibold" }, "SUPERUSER"))),
            exists(activeOrganization) &&
                activeOrganization.data().disabled === true && (React.createElement("div", { className: "flex-grow flex justify-center items-center bg-blue-500" },
                React.createElement("div", { className: "font-semibold" }, "TESTING")))),
        React.createElement("nav", { className: "bg-white shadow z-20" },
            React.createElement("div", { className: "max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" },
                React.createElement("div", { className: "relative flex justify-between h-16" },
                    React.createElement("div", { className: "md:flex-1 flex items-center justify-center sm:items-stretch sm:justify-between" },
                        React.createElement("div", { className: "flex-shrink-0 flex items-center" },
                            frameLogoURL && (React.createElement("div", { onClick: () => {
                                    user ? push('/') : push('/login');
                                } },
                                React.createElement("img", { className: `object-cover object-left h-5 md:object-contain md:object-left -mt-1 cursor-pointer`, src: showAllOrgsNotices ? COLUMN_LOGO_URL : frameLogoURL, alt: "Company name" }))),
                            location && !location.pathname.includes('search') && (React.createElement(ActiveOrganizationSelect, { open: isActiveOrgSelectOpen, setOpen: (value) => {
                                    setIsActiveOrgSelectOpen(value);
                                    if (value) {
                                        setIsHelpMenuOpen(false);
                                        setIsSettingsMenuOpen(false);
                                        setIsNotificationMenuOpen(false);
                                    }
                                } })),
                            React.createElement(Survey, { fromMainView: true, user: user, activeOrganization: activeOrganization })),
                        React.createElement("div", { className: "hidden sm:ml-6 sm:flex sm:space-x-8 mr-2" },
                            showReportsTab && (React.createElement("div", { className: `inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes('/reports') &&
                                    'border-blue-600 text-gray-900'}`, onClick: () => {
                                    var _a, _b;
                                    /**
                                     * If a publisher user has access to more than one newspaper,
                                     * link out to their active organization's reportUrl if it exists
                                     */
                                    if (user &&
                                        exists(user) &&
                                        (user.data().allowedOrganizations || []).length > 1 &&
                                        !!((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.reportUrl)) {
                                        const w = window.open();
                                        if (w) {
                                            try {
                                                w.location = (_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.reportUrl;
                                            }
                                            catch (err) {
                                                console.error(err);
                                                w.close();
                                            }
                                        }
                                        return;
                                    }
                                    // Otherwise, just navigate to the embedded GDS dashboard
                                    push('/reports');
                                }, "data-testid": "reports" }, "Reports")),
                            userIsLoggedIn && (React.createElement("div", { className: `inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes('/notices') &&
                                    'border-blue-600 text-gray-900'}`, onClick: () => push('/') }, "My Notices")),
                            showDonationTab && (React.createElement("div", { className: `inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes('/donation-campaign') &&
                                    'border-blue-600 text-gray-900'}`, onClick: () => push('/donation-campaign') }, "Donation Campaign")),
                            (user === null || user === void 0 ? void 0 : user.data().card) && (React.createElement("div", { className: `inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes('/cards') &&
                                    'border-blue-600 text-gray-900'}`, onClick: () => push('/cards') }, "Cards")),
                            displayPaymentTab(user, activeOrganization) && (React.createElement("div", { className: `inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes('/payments') &&
                                    'border-blue-600 text-gray-900'}`, onClick: () => push('/payments'), "data-testid": "payments" }, "Payments")))),
                    React.createElement("div", { className: "absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0" },
                        user && exists(user) && (React.createElement(Notifications, { user: user, open: isNotificationMenuOpen, setOpen: (value) => {
                                setIsNotificationMenuOpen(value);
                                if (value) {
                                    setIsHelpMenuOpen(false);
                                    setIsSettingsMenuOpen(false);
                                }
                            }, activeOrganization: activeOrganization })),
                        React.createElement("div", { className: "relative" },
                            React.createElement("div", null,
                                React.createElement("button", { id: "help-bar-icon", "data-testid": "help-bar-icon", className: "bg-white mx-3 p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", onClick: () => {
                                        setIsHelpMenuOpen(!isHelpMenuOpen);
                                        setIsSettingsMenuOpen(false);
                                        setIsNotificationMenuOpen(false);
                                        setIsActiveOrgSelectOpen(false);
                                    } },
                                    React.createElement(QuestionMarkRoundedIcon, { className: "h-6 w-6" }))),
                            React.createElement("div", { className: `${!isHelpMenuOpen && 'hidden'} z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow`, role: "menu", "aria-orientation": "vertical", "aria-labelledby": "user-menu", "data-testid": "help-menu-dropdown" },
                                React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-750 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                        window.open('https://help.column.us');
                                    } }, "Visit Help Center"))),
                        React.createElement("div", { className: "relative" },
                            React.createElement(Tooltip, { title: showAllOrgsNotices
                                    ? 'Select a specific organization to see settings'
                                    : '', disableHoverListener: !showAllOrgsNotices },
                                React.createElement("div", null,
                                    React.createElement("button", { id: "settings-icon", className: `bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${showAllOrgsNotices && 'opacity-50'}`, onClick: () => {
                                            setIsSettingsMenuOpen(!isSettingsMenuOpen);
                                            setIsHelpMenuOpen(false);
                                            setIsNotificationMenuOpen(false);
                                            setIsActiveOrgSelectOpen(false);
                                        }, disabled: showAllOrgsNotices }, userIsLoggedIn ? (React.createElement(CogIcon, { className: "text-white hover:text-gray-200" })) : (React.createElement(AccountCircleIcon, { className: "text-gray-400 hover:text-gray-500" }))))),
                            React.createElement("div", { className: `${!isSettingsMenuOpen && 'hidden'} z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow`, role: "menu", "aria-orientation": "vertical", "aria-labelledby": "user-menu" },
                                userIsLoggedIn && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                        push('/settings/');
                                    } }, "My Account")),
                                debuggerAccess() && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                        push('/debugger');
                                    } }, "Debugger Tool")),
                                organization && (React.createElement("div", { id: "my-organization", className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                        push('/settings/organization');
                                    } }, t('frame.org-settings'))),
                                userIsLoggedIn && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                        push('/logout');
                                    } }, "Logout")),
                                userIsLoggedOutOrAnonymous && (React.createElement("div", null,
                                    React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                            push('/login');
                                        } }, "Sign In"),
                                    organization && (React.createElement("div", { id: "my-organization", className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                            push('/settings/organization');
                                        } }, t('frame.org-settings'))),
                                    userIsLoggedIn && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: () => {
                                            push('/register');
                                        } }, "Register"))))))))),
            React.createElement("div", { className: "hidden sm:hidden" },
                React.createElement("div", { className: "pt-2 pb-4 space-y-1" },
                    canRunDonations(activeOrganization) && (React.createElement("div", { className: "block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50", onClick: () => push('/donation-campaign') }, "Donation Campaign")),
                    React.createElement("a", { href: "#", className: "block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300" }, "Team")))),
        React.createElement(SwitchToChrome, { hideOnTheseRoutes: ['/search'] }),
        React.createElement("main", { id: "main", className: noFramePadding
                ? 'fixed min-w-full bg-gray-80 h-full'
                : 'flex-grow p-3 bg-gray-80 h-full overflow-x-scroll', onClick: () => {
                setIsNotificationMenuOpen(false);
                setIsSettingsMenuOpen(false);
                setIsHelpMenuOpen(false);
                setIsActiveOrgSelectOpen(false);
            } }, children),
        React.createElement("footer", { id: "appfooter", className: `${!pathName.includes('/notices') && 'hidden'} text-gray-700 pb-3 text-center bg-gray-80` },
            React.createElement("div", null,
                React.createElement("span", { onClick: () => window.open('https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/Terms%20of%20Use.pdf?alt=media&token=7c8fd6e4-c71b-4602-8f48-ed7948bbeea2'), className: "cursor-pointer" }, "Terms of Use"),
                ' ',
                "|",
                ' ',
                React.createElement("span", { onClick: () => window.open('https://enotice-production.imgix.net/custom-documents/permalink/4953.6bc4e-Column%20Privacy%20Policy.pdf'), className: "cursor-pointer" }, "Privacy Policy"),
                ' ',
                "| ",
                React.createElement("span", null, "help@column.us"),
                " |",
                ' ',
                React.createElement("span", { onClick: () => window.open('https://www.column.us'), className: "cursor-pointer" }, "column.us")))));
};
export default connect(mapStateToProps, { push })(Frame);
