import { useEffect } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { getFirstAndLastNameFromFullName } from 'routes/placeScroll/helpers';
export function useSyncCustomerData({ customerData }) {
    var _a;
    useEffect(() => {
        var _a;
        const customer = customerData.customer.data();
        const user = customerData.user.data();
        const advertiserOrg = (_a = customerData.organization) === null || _a === void 0 ? void 0 : _a.data();
        // Check if we need to override customer data by properties from org or user
        const updates = {
            userName: (!customer.userName || customer.userName === ' ') && user.name
                ? user.name
                : undefined,
            firstName: (!customer.firstName || customer.firstName === ' ') && user.firstName
                ? user.firstName
                : undefined,
            lastName: (!customer.lastName || customer.lastName === ' ') && user.lastName
                ? user.lastName
                : undefined,
            address: !customer.address
                ? (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.address) || user.address || undefined
                : undefined,
            addressLine2: !customer.addressLine2 && customer.addressLine2 !== ''
                ? (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.addressLine2) || user.addressLine2 || undefined
                : undefined,
            city: !customer.city
                ? (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.city) || user.city || undefined
                : undefined,
            state: !customer.state
                ? (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.state) || user.state || undefined
                : undefined,
            zipCode: !customer.zipCode
                ? (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.zipCode) || user.zipCode || undefined
                : undefined,
            phone: !customer.phone
                ? (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.phone) || user.phone || undefined
                : undefined
        };
        // If we were not able to pull name info from the customer, but we did from the user `name` property, set the first & last name on the customer based on that user `name`
        const { firstName, lastName } = getFirstAndLastNameFromFullName(updates.userName || '');
        if (!updates.firstName && firstName) {
            updates.firstName = firstName;
        }
        if (!updates.lastName && lastName) {
            updates.lastName = lastName;
        }
        removeUndefinedFields(updates);
        if (Object.keys(updates).length) {
            void customerData.customer.ref.update(updates);
        }
    }, [
        safeStringify(customerData.customer.data()),
        safeStringify(customerData.user.data()),
        safeStringify((_a = customerData.organization) === null || _a === void 0 ? void 0 : _a.data())
    ]);
}
