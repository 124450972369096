import { combineReducers } from 'redux';
export const makeRootReducer = (history) => {
    return combineReducers({
        errors: require('../redux/errors').reducer,
        auth: require('../redux/auth').reducer,
        placement: require('../redux/placement').reducer,
        toast: require('../redux/toast').reducer,
        router: require('../redux/router').reducer(history),
        notices: require('../redux/notices').reducer
    });
};
export const injectReducer = (store, { key, reducer }) => {
    if (Object.hasOwnProperty.call(store.asyncReducers, key))
        return;
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers[key] = reducer;
    store.replaceReducer(makeRootReducer(store.asyncReducers));
};
export default makeRootReducer;
