var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { bindActionCreators } from 'redux';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { exists } from 'lib/types';
import { getNoticeTypeData } from 'lib/publishers';
import { getFirebaseContext } from 'utils/firebase';
import { CONFIRM_FILER, CONFIRM_SCHEDULE } from 'routes/placeScroll/helpers';
import Embed from './Embed';
import FIREBASE_CONFIG from '../../config.firebase';
import { appendToCurrentParams, getLocationParams } from '../../utils/urls';
import PlacementActions from '../../redux/placement';
function useInterval(callback, delay) {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
const hashString = (input) => {
    let hash = 0;
    let i;
    let chr;
    if (input.length === 0)
        return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0;
    }
    return hash;
};
const generateHashedValueFromDraft = (draft) => {
    var _a;
    return `${hashString(((_a = draft.data()) === null || _a === void 0 ? void 0 : _a.text) || '')}`;
};
const TypeformEmbed = ({ placementActions, isPublisher, orgContext, userAuth, pathname, push }) => {
    var _a, _b;
    const [typeform, setTypeform] = useState('');
    const match = matchPath(pathname, {
        path: '/form/:draftId/:noticeType',
        exact: true,
        strict: false
    });
    const draftId = (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.draftId;
    const noticeType = (_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.noticeType;
    const hiddenFields = {
        documentid: draftId,
        userid: userAuth ? userAuth.uid : '',
        env: FIREBASE_CONFIG.projectId
    };
    const parametersConfirmed = () => __awaiter(void 0, void 0, void 0, function* () {
        const draft = yield getFirebaseContext().userDraftsRef().doc(draftId).get();
        if (!exists(draft)) {
            console.error('Draft does not exist');
            return;
        }
        if (noticeType) {
            yield draft.ref.update({
                noticeType: parseInt(noticeType, 10)
            });
        }
        const { original } = draft.data();
        if (!original) {
            console.error('Original notice does not exist on the draft');
            return;
        }
        const base = `/place/${original.id}`;
        const key = 'step';
        const value = orgContext
            ? isPublisher
                ? CONFIRM_FILER
                : CONFIRM_SCHEDULE
            : CONFIRM_SCHEDULE;
        const allParams = appendToCurrentParams(key, value);
        push(`${base}?${allParams.toString()}`);
    });
    const handleTypeformSubmission = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        const draft = yield getFirebaseContext().userDraftsRef().doc(draftId).get();
        if (!exists(draft)) {
            console.error('Draft does not exist');
            return;
        }
        const noticeId = (_c = draft.data().original) === null || _c === void 0 ? void 0 : _c.id;
        if (!noticeId) {
            console.error('Original notice does not exist');
            return;
        }
        yield draft.ref.update({ confirmedHtml: '' });
        yield placementActions.hydrateNoticeData(noticeId);
        yield placementActions.setNoticeText(draft.data().text);
        yield parametersConfirmed();
    });
    useEffect(() => {
        const loadTypeformFromDraft = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!noticeType || !draftId) {
                return;
            }
            const draft = yield getFirebaseContext()
                .userDraftsRef()
                .doc(draftId)
                .get();
            if (!exists(draft) || !draft.data().newspaper) {
                return;
            }
            const newspaper = yield draft.data().newspaper.get();
            const selectedType = getNoticeTypeData(noticeType, newspaper);
            setTypeform(selectedType.typeform);
            const urlParams = getLocationParams();
            const urlHash = urlParams.get('textHash');
            if (!urlHash) {
                const stringHash = generateHashedValueFromDraft(draft);
                const hashedParams = appendToCurrentParams('textHash', stringHash);
                push(`${window.location.pathname}?${hashedParams.toString()}`);
            }
        });
        void loadTypeformFromDraft();
    }, [noticeType, draftId]);
    useInterval(() => {
        /**
         * We introduced the below diff to fix buggy Typeform redirect behavior.
         *
         * This change tracks when confirmedHtml changes on the backend but not on the frontend.
         * For odd rendering reasons, the only way that this can be checked is
         * via persisting the state of the previous text in the url.
         *
         * For some reason things get re-rendered higher up in the DOM tree in a way that
         * entirely breaks useEffect calls. So in order to see if the text changes:
         *  1. On initial load we set the URL hash based on the current text content
         *  2. Query the text content on a interval and if its hash doesn't match the url, we update
         *
         */
        const checkForHtmlChanges = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (!draftId) {
                return;
            }
            const draft = yield getFirebaseContext()
                .userDraftsRef()
                .doc(draftId)
                .get();
            const urlParams = getLocationParams();
            const urlHash = urlParams.get('textHash');
            const stringHash = generateHashedValueFromDraft(draft);
            if (stringHash !== urlHash && ((_b = (_a = draft.data()) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b.length)) {
                yield handleTypeformSubmission();
            }
        });
        void checkForHtmlChanges();
    }, 1000);
    if (!typeform)
        return null;
    if (!draftId)
        return null;
    return (React.createElement(Embed, { onSubmit: () => { }, hiddenFields: hiddenFields, formId: typeform }));
};
const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname
});
const mapDispatchToProps = (dispatch) => ({
    placementActions: bindActionCreators(PlacementActions, dispatch),
    push: (path) => dispatch(push(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(TypeformEmbed);
