import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { CalendarIcon } from 'icons';
const ColumnDatePicker = ({ className, format, momentFormat, placeholderText, value, onChange, shouldDisableDate }) => {
    return (React.createElement("div", { className: classNames('p-2 bg-white border rounded border-gray-300', className !== null && className !== void 0 ? className : '') },
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(DatePicker, { "data-testid": "datePicker", label: "", value: value, format: format, className: 'p-1', TextFieldComponent: props => (React.createElement("div", { className: "flex items-center text-sm", onClick: e => props.onClick(e) },
                    React.createElement("span", { className: classNames('mr-auto', { 'text-gray-700': !value }) }, value ? moment(value).format(momentFormat) : placeholderText),
                    React.createElement(InputAdornment, { position: "end" },
                        React.createElement(CalendarIcon, { className: "w-4 h-4 text-gray-700", strokeWidth: "2", stroke: "currentColor" })))), InputProps: {
                    disableUnderline: true,
                    className: ''
                }, autoOk: true, onChange: newDate => {
                    if (newDate) {
                        onChange(new Date(newDate.toISOString()));
                    }
                    else {
                        onChange(undefined);
                    }
                }, shouldDisableDate: date => {
                    if (shouldDisableDate) {
                        return shouldDisableDate(date);
                    }
                    return false;
                } }))));
};
export default ColumnDatePicker;
