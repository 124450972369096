import React, { useState } from 'react';
import ColumnSelect from 'components/select/ColumnSelect';
import { ColumnButton } from 'components/ColumnButton';
import moment from 'moment';
import ColumnSelectItem from 'components/select/ColumnSelectItem';
import ColumnDatePicker from 'components/ColumnDatePicker';
import classNames from 'classnames';
import { AFFIDAVIT_OPTIONS, DEFAULT_NOTICE_FILTERS, INVOICE_OPTIONS, PUBLICATION_DATE_OPTIONS, STATUS_OPTIONS } from 'utils/noticeFilter';
import { calendarDaysApart } from 'lib/helpers';
const NoticesFilterDialog = ({ className, value, onChange }) => {
    var _a, _b, _c, _d, _e, _f;
    const [statusFilter, setStatusFilter] = useState(value.status);
    const [invoiceFilter, setInvoiceFilter] = useState(value.invoice);
    const [affidavitFilter, setAffidavitFilter] = useState(value.affidavit);
    const [dateFilter, setDateFilter] = useState(value.publicationDate);
    const [customStartDate, setCustomStartDate] = useState((_a = value.publicationDateRange) === null || _a === void 0 ? void 0 : _a.start);
    const [customEndDate, setCustomEndDate] = useState((_b = value.publicationDateRange) === null || _b === void 0 ? void 0 : _b.end);
    const publicationDateRange = customStartDate && customEndDate
        ? {
            start: customStartDate,
            end: customEndDate
        }
        : undefined;
    const pendingValue = {
        status: statusFilter,
        invoice: invoiceFilter,
        affidavit: affidavitFilter,
        publicationDate: dateFilter,
        publicationDateRange
    };
    const hasChange = pendingValue.status !== value.status ||
        pendingValue.invoice !== value.invoice ||
        pendingValue.affidavit !== value.affidavit ||
        pendingValue.publicationDate !== value.publicationDate ||
        ((_c = pendingValue.publicationDateRange) === null || _c === void 0 ? void 0 : _c.start) !==
            ((_d = value.publicationDateRange) === null || _d === void 0 ? void 0 : _d.start) ||
        ((_e = pendingValue.publicationDateRange) === null || _e === void 0 ? void 0 : _e.end) !== ((_f = value.publicationDateRange) === null || _f === void 0 ? void 0 : _f.end);
    const handleResetClick = () => {
        if (onChange) {
            onChange(DEFAULT_NOTICE_FILTERS);
        }
    };
    const handleApplyClick = () => {
        if (onChange) {
            onChange(pendingValue);
        }
    };
    return (React.createElement("div", { className: classNames('overflow-y-visible mt-1 w-72 z-10 rounded-md shadow-md bg-white border border-gray-200 focus:outline-none py-5 px-4 font-normal', className), role: "menu", "aria-orientation": "vertical", "aria-labelledby": "affidavit-options-menu" },
        React.createElement("p", { className: "uppercase text-gray-500 text-xs font-bold mb-3" }, "Filter by"),
        React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Notice status"),
        React.createElement(ColumnSelect, { className: "mb-3", options: STATUS_OPTIONS, value: statusFilter, onChange: setStatusFilter }),
        React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Invoices"),
        React.createElement(ColumnSelect, { className: "mb-3", options: INVOICE_OPTIONS, value: invoiceFilter, onChange: setInvoiceFilter }),
        React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Affidavits"),
        React.createElement(ColumnSelect, { className: "mb-3", options: AFFIDAVIT_OPTIONS, value: affidavitFilter, onChange: setAffidavitFilter }),
        React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Publication date"),
        React.createElement(ColumnSelect, { className: "mb-3", options: PUBLICATION_DATE_OPTIONS, value: dateFilter, onChange: setDateFilter, renderItem: (option, active) => {
                let description;
                const today = moment();
                const startOfMonth = moment().startOf('month');
                const lastMonth = moment().subtract(1, 'month');
                if (option.value === 'this-month') {
                    description = `${startOfMonth.format('MMM D')} to ${today.format('MMM D')}`;
                }
                if (option.value === 'last-month') {
                    description = lastMonth.format('MMMM');
                }
                return (React.createElement(ColumnSelectItem, { option: option, active: active, description: description }));
            } }),
        pendingValue.publicationDate === 'custom-date' && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Choose a date range"),
            React.createElement("div", { className: "flex flex-row gap-2" },
                React.createElement(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "Start date", value: customStartDate, onChange: setCustomStartDate, shouldDisableDate: date => {
                        return !!(date &&
                            customEndDate &&
                            calendarDaysApart(moment(date).startOf('day').toDate(), moment(customEndDate).startOf('day').toDate()) > 0);
                    } }),
                React.createElement(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "End date", value: customEndDate, onChange: setCustomEndDate, shouldDisableDate: date => {
                        return !!(date &&
                            customStartDate &&
                            calendarDaysApart(moment(customStartDate).startOf('day').toDate(), moment(date).startOf('day').toDate()) > 0);
                    } })))),
        React.createElement("div", { className: "flex flex-row justify-between items-center mt-6" },
            React.createElement(ColumnButton, { buttonText: "Reset", onClick: handleResetClick }),
            React.createElement(ColumnButton, { buttonText: "Apply", primary: true, disabled: !hasChange, onClick: handleApplyClick }))));
};
export default NoticesFilterDialog;
