var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
import { roundUp } from '../pricing';
import { cdnIfy } from '../helpers';
import { CLOUDINARY_BUCKET } from '../constants';
import { NoticeType } from '../enums';
/**
 * Check if a notice is a display ad.
 */
export const isDisplay = (notice) => {
    return notice.data().noticeType === NoticeType.display_ad.value;
};
/**
 * Imgix has a max height/width of 8192 pixels, which is 6.83 inches at 1200 DPI.
 * So, we need to downscale the quality for images that are going to be taller
 * (or wider) than that.
 * @returns the highest possible DPI value (in increments of 150) that will
 * preserve the correct notice height and width params in the final PDF.
 */
const getHighestDPI = (height, width) => {
    const imgixMax = 8192;
    const largerParam = Math.max(height, width);
    let dpi = 1200;
    while (largerParam * dpi > imgixMax)
        dpi -= 150;
    if (dpi < 300)
        throw Error('Notice too tall to generate quality image');
    return dpi;
};
/** This function centralizes logic for generating downloadable URLs for PDFs
 * associated with notices. This function should be used both in integrations as
 * well as in the frontend when users are accessing notice content for pagination.
 *
 * @param notice A snapshot of the notice we are generating the PDF for
 */
export const getNoticePDFUrlFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const { id } = notice;
    if (!exists(notice))
        throw new Error(`Unable to get PDF Url for notice ${id}: missing data.`);
    const newspaper = yield ((_b = (_a = notice.data()) === null || _a === void 0 ? void 0 : _a.newspaper) === null || _b === void 0 ? void 0 : _b.get());
    if (!exists(newspaper))
        throw new Error(`Unable to get PDF Url for notice ${notice.id}: missing newspaper`);
    /**
     * There are specific configuration settings stored at the organization level
     * that tell this function to round the ad height as well as apply an optional
     * border.
     */
    const { configureNoticePDFs } = newspaper.data();
    if (configureNoticePDFs) {
        const { displayParams, noticeType } = notice.data();
        const { roundAdHeight, applyBorderToDisplay, applyBorderToLiners } = configureNoticePDFs;
        /**
         * We only want to generate a rounded height PDF if the notice has height and
         * width (part of displayParams) and the newspaper has a non-zero roundAdHeight
         * value.
         */
        if (displayParams && roundAdHeight) {
            try {
                const { width, height } = displayParams;
                const roundedHeight = roundUp(height, roundAdHeight);
                /**
                 * To generate a high quality image, we need to set DPI to 1200 pixels per
                 * inch and then calculate the height, width, border and padding parameters.
                 */
                const imageDPI = getHighestDPI(roundedHeight, width);
                const imageHeight = roundedHeight * imageDPI;
                const imageWidth = width * imageDPI;
                const imageBorder = 32 * (imageDPI / 1200);
                const imagePadding = imageBorder * 2;
                /**
                 * We apply a border after rounding the ad height to ensure that any
                 * padding necessary to increase the height is inside the border. Some
                 * papers configure this border conditionally depending on the ad type
                 * (display or liner).
                 */
                const applyBorder = noticeType === NoticeType.display_ad.value
                    ? applyBorderToDisplay
                    : applyBorderToLiners;
                /**
                 * Get a high quality JPEG from imgix based on the width and rounded height
                 * of the notice. Optionally add a border if the newspaper is configured
                 * to add a wholeNoticeBorder. Then create a cloudinary URL that transforms
                 * the imgix JPEG to a PDF, as imgix only supports DPI settings for JPEGs.
                 */
                const highQualityJPG = cdnIfy(notice.data().pdfStoragePath, {
                    useImgix: true,
                    imgixTransformations: Object.assign({ height: `${imageHeight.toFixed(0)}`, width: `${imageWidth.toFixed(0)}`, dpi: `${imageDPI}`, fit: 'fill', fill: 'solid', 'fill-color': 'white', fm: 'jpg' }, (applyBorder
                        ? {
                            border: `${imageBorder},000`,
                            pad: `${imagePadding}`
                        }
                        : {}))
                });
                return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/image/fetch/f_pdf/${encodeURIComponent(highQualityJPG)}`;
            }
            catch (err) {
                /**
                 * If the above fails (usually a notice that is too large to generate a
                 * high quality PDF with IMGIX) log the error but allow this function to
                 * fall back to the pdfURL or pdfStoragePath below.
                 */
                console.dir(err);
            }
        }
    }
    // if we have saved a cached PDF url use that
    if (notice.data().pdfURL)
        return notice.data().pdfURL;
    // generate our own CDN url as a final fallback
    return cdnIfy(notice.data().pdfStoragePath);
});
