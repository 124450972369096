var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { CurrencyType } from 'lib/enums';
import { getInvoiceTotalSubtotalAndFees } from 'lib/pricing';
import { logAndCaptureMessage } from 'utils';
import { STRIPE } from 'lib/constants';
function getCurrencyFromInvoice(invoice) {
    var _a;
    return (_a = CurrencyType.by_key(invoice.currency)) !== null && _a !== void 0 ? _a : CurrencyType.usd;
}
function fetchStripePricing(invoiceSnap, gateway) {
    return __awaiter(this, void 0, void 0, function* () {
        // For Payway, Elavon, etc we don't do this call.
        const isStripeCheckout = gateway === STRIPE;
        if (!isStripeCheckout) {
            return undefined;
        }
        const stripePricingRes = yield api.get(`payments/${invoiceSnap.id}/stripe-pricing`);
        if (!stripePricingRes.success) {
            return undefined;
        }
        return stripePricingRes.details;
    });
}
export function fetchPricingForBulkInvoice(invoiceSnap, gateway) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const currency = getCurrencyFromInvoice(invoiceSnap.data());
        let invoiceTotal = 0;
        for (const lineItem of invoiceSnap.data().inAppLineItems) {
            const itemAmount = lineItem.refund ? -1 * lineItem.amount : lineItem.amount;
            invoiceTotal += itemAmount;
        }
        const invoiceNetTotal = (_a = invoiceSnap.data().netTotal) !== null && _a !== void 0 ? _a : invoiceTotal;
        // When an invoice is paid in Stripe we the user will always be charged amount_due even
        // if that does not match up with our calculations. So that is what we display.
        // See: ONCALL-1576
        const stripePricing = yield fetchStripePricing(invoiceSnap, gateway);
        const stripeTotal = (stripePricing === null || stripePricing === void 0 ? void 0 : stripePricing.amount_due) || invoiceNetTotal;
        // Temporary logging to see how often we have a mismatch
        if (stripeTotal !== invoiceNetTotal) {
            logAndCaptureMessage('Stripe and invoice totals do not match', {
                invoiceId: invoiceSnap.id,
                stripeTotal: `${stripeTotal}`,
                invoiceNetTotal: `${invoiceNetTotal}`
            });
        }
        return {
            currency,
            processingFee: 0,
            taxFee: 0,
            totalAmount: invoiceTotal,
            netTotal: stripeTotal,
            subtotal: invoiceTotal,
            inAppLineItems: invoiceSnap.data().inAppLineItems,
            appliedBalance: invoiceSnap.data().appliedBalance || 0
        };
    });
}
export function fetchPricingForSingleInvoice(invoiceSnap, gateway) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const pricingRes = yield api.get(`payments/${invoiceSnap.id}/invoice-to-pricing`);
        if (!pricingRes.success) {
            logAndCaptureMessage('Failed to get pricing for single invoice', {
                invoiceId: invoiceSnap.id
            });
            return;
        }
        const currency = getCurrencyFromInvoice(invoiceSnap.data());
        const { DBPricingObject } = pricingRes;
        const invoiceNetTotal = (_a = invoiceSnap.data().netTotal) !== null && _a !== void 0 ? _a : DBPricingObject.total;
        // When an invoice is paid in Stripe we the user will always be charged amount_due even
        // if that does not match up with our calculations. So that is what we display.
        // See: ONCALL-1576
        const stripePricing = yield fetchStripePricing(invoiceSnap, gateway);
        const stripeTotal = (stripePricing === null || stripePricing === void 0 ? void 0 : stripePricing.amount_due) || invoiceNetTotal;
        // Temporary logging to see how often we have a mismatch
        if (invoiceNetTotal !== stripeTotal) {
            logAndCaptureMessage('Stripe and invoice totals do not match', {
                invoiceId: invoiceSnap.id,
                stripeTotal: `${stripeTotal}`,
                invoiceNetTotal: `${invoiceNetTotal}`
            });
        }
        const { feesInCents } = getInvoiceTotalSubtotalAndFees(invoiceSnap);
        return {
            currency,
            processingFee: DBPricingObject.distributed ? 0 : feesInCents,
            taxFee: DBPricingObject.taxAmt,
            totalAmount: DBPricingObject.total,
            netTotal: stripeTotal,
            subtotal: DBPricingObject.subtotal,
            inAppLineItems: DBPricingObject.lineItems,
            appliedBalance: invoiceSnap.data().appliedBalance || 0
        };
    });
}
