var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import ExitWithoutSavingModal from 'components/modals/ExitWithoutSavingModal';
import * as validators from 'routes/register/organization/validators';
import HorizontalDivider from '../HorizontalDivider';
import { EditModalHeader } from './EditModalHeader';
import { EditModalDetails } from './EditModalDetails';
import { EditCustomerOrganizationModalSettings } from './EditCustomerOrganizationModalSettings';
const EditCustomerOrganizationModal = ({ customerOrganizationData, customerOrganizationEdits, activeOrganization, setOpen, setCustomerOrganization, setToastText }) => {
    const [customerOrganizationName, setCustomerOrganizationName] = useState('');
    const [allowBulkInvoicing, setAllowBulkInvoicing] = useState();
    const [internalID, setInternalID] = useState();
    const [address, setAddress] = useState();
    const [addressLine2, setAddressLine2] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState();
    const [phone, setPhone] = useState();
    const [requireUpfrontPayment, setRequireUpfrontPayment] = useState();
    const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] = useState();
    const DEFAULT_SETTING = 0;
    const ALWAYS_REQUIRE = 1;
    const NEVER_REQUIRE = 2;
    const [requireUpfrontPaymentId, setRequireUpfrontPaymentId] = useState(DEFAULT_SETTING);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
        // Update state vars on CustomerData update
        if (customerOrganizationEdits) {
            setCustomerOrganizationName(customerOrganizationEdits.name || '--');
            setRequireUpfrontPaymentId((customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment) === null ||
                (customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment) === undefined
                ? DEFAULT_SETTING
                : (customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment)
                    ? ALWAYS_REQUIRE
                    : NEVER_REQUIRE);
            setAddress(customerOrganizationEdits.address);
            setAddressLine2(customerOrganizationEdits.addressLine2);
            setCity(customerOrganizationEdits.city);
            setState(customerOrganizationEdits.state);
            setZipCode(customerOrganizationEdits.zipCode);
            setPhone(customerOrganizationEdits.phone);
            setAllowBulkInvoicing(customerOrganizationEdits.bulkPaymentEnabled);
            setInternalID(customerOrganizationEdits.internalID);
        }
        else {
            setCustomerOrganizationName(((_a = customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.name) || '--');
            setRequireUpfrontPaymentId(((_b = customerOrganizationData.customerOrganization.data()) === null || _b === void 0 ? void 0 : _b.requireUpfrontPayment) === null ||
                ((_c = customerOrganizationData.customerOrganization.data()) === null || _c === void 0 ? void 0 : _c.requireUpfrontPayment) === undefined
                ? DEFAULT_SETTING
                : ((_d = customerOrganizationData.customerOrganization.data()) === null || _d === void 0 ? void 0 : _d.requireUpfrontPayment)
                    ? ALWAYS_REQUIRE
                    : NEVER_REQUIRE);
            setAddress((_e = customerOrganizationData.customerOrganization.data()) === null || _e === void 0 ? void 0 : _e.address);
            setAddressLine2((_f = customerOrganizationData.customerOrganization.data()) === null || _f === void 0 ? void 0 : _f.addressLine2);
            setCity((_g = customerOrganizationData.customerOrganization.data()) === null || _g === void 0 ? void 0 : _g.city);
            setState((_h = customerOrganizationData.customerOrganization.data()) === null || _h === void 0 ? void 0 : _h.state);
            setZipCode((_j = customerOrganizationData.customerOrganization.data()) === null || _j === void 0 ? void 0 : _j.zipCode);
            setPhone((_k = customerOrganizationData.customerOrganization.data()) === null || _k === void 0 ? void 0 : _k.phone);
            setAllowBulkInvoicing((_l = customerOrganizationData.customerOrganization.data()) === null || _l === void 0 ? void 0 : _l.bulkPaymentEnabled);
            setInternalID((_m = customerOrganizationData.customerOrganization.data()) === null || _m === void 0 ? void 0 : _m.internalID);
        }
        // Check if we need to override customer data by properties from org or user
        const updates = {
            name: (!((_o = customerOrganizationData.customerOrganization.data()) === null || _o === void 0 ? void 0 : _o.name) ||
                ((_p = customerOrganizationData.customerOrganization.data()) === null || _p === void 0 ? void 0 : _p.name) === ' ') &&
                ((_q = customerOrganizationData.clientOrganization.data()) === null || _q === void 0 ? void 0 : _q.name)
                ? (_r = customerOrganizationData.clientOrganization.data()) === null || _r === void 0 ? void 0 : _r.name
                : undefined,
            address: !((_s = customerOrganizationData.customerOrganization.data()) === null || _s === void 0 ? void 0 : _s.address)
                ? ((_t = customerOrganizationData.clientOrganization.data()) === null || _t === void 0 ? void 0 : _t.address) ||
                    undefined
                : undefined,
            addressLine2: !((_u = customerOrganizationData.customerOrganization.data()) === null || _u === void 0 ? void 0 : _u.addressLine2) &&
                ((_v = customerOrganizationData.customerOrganization.data()) === null || _v === void 0 ? void 0 : _v.addressLine2) !==
                    ''
                ? ((_w = customerOrganizationData.clientOrganization.data()) === null || _w === void 0 ? void 0 : _w.addressLine2) ||
                    undefined
                : undefined,
            city: !((_x = customerOrganizationData.customerOrganization.data()) === null || _x === void 0 ? void 0 : _x.city)
                ? ((_y = customerOrganizationData.clientOrganization.data()) === null || _y === void 0 ? void 0 : _y.city) || undefined
                : undefined,
            state: !((_z = customerOrganizationData.customerOrganization.data()) === null || _z === void 0 ? void 0 : _z.state)
                ? ((_0 = customerOrganizationData.clientOrganization.data()) === null || _0 === void 0 ? void 0 : _0.state) || undefined
                : undefined,
            zipCode: !((_1 = customerOrganizationData.customerOrganization.data()) === null || _1 === void 0 ? void 0 : _1.zipCode)
                ? ((_2 = customerOrganizationData.clientOrganization.data()) === null || _2 === void 0 ? void 0 : _2.zipCode) ||
                    undefined
                : undefined,
            phone: !((_3 = customerOrganizationData.customerOrganization.data()) === null || _3 === void 0 ? void 0 : _3.phone)
                ? ((_4 = customerOrganizationData.clientOrganization.data()) === null || _4 === void 0 ? void 0 : _4.phone) || undefined
                : undefined
        };
        removeUndefinedFields(updates);
        if (Object.keys(updates).length) {
            void customerOrganizationData.customerOrganization.ref.update(updates);
        }
    }, [
        safeStringify(customerOrganizationData.customerOrganization.data()),
        safeStringify(customerOrganizationData.clientOrganization.data())
    ]);
    const [changedFields, setChangedFields] = useState({
        name: false,
        address: false,
        addressLine2: false,
        city: false,
        state: false,
        zipCode: false,
        phone: false,
        internalID: false,
        requireUpfrontPayment: false,
        bulkPaymentEnabled: false
    });
    const onSave = () => __awaiter(void 0, void 0, void 0, function* () {
        const updates = {
            name: customerOrganizationName.trim(),
            internalID,
            phone,
            address,
            addressLine2,
            city,
            state,
            zipCode,
            requireUpfrontPayment,
            bulkPaymentEnabled: Boolean(allowBulkInvoicing)
        };
        removeUndefinedFields(updates);
        yield customerOrganizationData.customerOrganization.ref.update(updates);
        setOpen();
        const toastText = `Your changes to ${customerOrganizationName.trim()}'s details have been updated.`;
        setToastText(toastText);
        if (setCustomerOrganization) {
            setCustomerOrganization(updates);
        }
    });
    const isZipValid = validators.zipRegex.test(zipCode || '') || zipCode === '';
    const isPhoneValid = validators.phoneRegex.test(phone || '') || phone === '';
    const disableSave = !Object.values(changedFields).includes(true) ||
        !customerOrganizationName.trim() ||
        !isZipValid ||
        !isPhoneValid;
    const onClose = () => {
        if (!Object.values(changedFields).includes(true)) {
            setOpen();
        }
        else {
            setShowExitWithoutSavingModal(true);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "relative" },
            React.createElement("div", { className: "fixed overflow-scroll z-20 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out" },
                React.createElement(EditModalHeader, { onSave: onSave, onClose: onClose, disableSave: disableSave, isCustomerOrganization: true }),
                React.createElement("div", { className: "bg-gray-80 overflow-scroll" },
                    React.createElement(EditModalDetails, { name: customerOrganizationName, setName: setCustomerOrganizationName, internalID: internalID, setInternalID: setInternalID, changedFields: changedFields, setChangedFields: setChangedFields, customerOrganizationData: customerOrganizationData, phone: phone, setPhone: setPhone, address: address, setAddress: setAddress, addressLine2: addressLine2, setAddressLine2: setAddressLine2, city: city, setCity: setCity, state: state, setState: setState, zipCode: zipCode, setZipCode: setZipCode, isCustomerOrganization: true }),
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "w-1/6" }),
                        React.createElement("div", { className: "w-2/3" },
                            React.createElement(HorizontalDivider, null),
                            React.createElement("div", { className: "w-1/6" }))),
                    React.createElement(EditCustomerOrganizationModalSettings, { changedFields: changedFields, setChangedFields: setChangedFields, customerOrganizationData: customerOrganizationData, activeOrganization: activeOrganization, setRequireUpfrontPayment: setRequireUpfrontPayment, requireUpfrontPaymentId: requireUpfrontPaymentId, setRequireUpfrontPaymentId: setRequireUpfrontPaymentId, allowBulkInvoicing: allowBulkInvoicing, setAllowBulkInvoicing: setAllowBulkInvoicing, onClose: onClose, onSave: onSave, disableSave: disableSave }))),
            showExitWithoutSavingModal && (React.createElement(ExitWithoutSavingModal, { keepEditing: () => setShowExitWithoutSavingModal(false), exitWithoutSaving: () => {
                    setShowExitWithoutSavingModal(false);
                    setOpen();
                }, body: 'Your progress will be lost if you exit now. Click keep editing to go back to save your changes instead' })))));
};
export default EditCustomerOrganizationModal;
