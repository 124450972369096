var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Box, InputLabel, FormControl, Input } from '@material-ui/core';
import ColorPicker from 'material-ui-color-picker';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { exists } from 'lib/types';
import computeColors from 'lib/theme';
import { invertHex } from 'utils/styles';
import SettingsWrapper from './SettingsWrapper';
import UploadButton from '../../components/UploadButton';
import LoadingState from '../../components/LoadingState';
const styles = (theme) => createStyles({
    uploadGrid: {
        display: 'flex'
    },
    saveButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    formControl: {
        width: 165
    },
    thumb: {
        maxHeight: 80,
        maxWidth: 100
    },
    formRow: {
        marginBottom: theme.spacing(3)
    },
    formWrapper: {
        marginTop: theme.spacing(2)
    }
});
const CustomBrandSettings = ({ user, classes, activeOrganization, theme: enoticeTheme }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [loading, setLoading] = useState(true);
    const [theme, setTheme] = useState();
    const [icon, setIcon] = useState();
    useEffect(() => {
        const initTheme = () => __awaiter(void 0, void 0, void 0, function* () {
            const orgTheme = activeOrganization.data().theme;
            setTheme({
                palette: Object.assign(Object.assign({}, enoticeTheme.palette), (orgTheme ? orgTheme.palette : {}))
            });
            setIcon(activeOrganization.data().icon);
            setLoading(false);
        });
        void initTheme();
    }, [activeOrganization]);
    if (loading || !theme || !exists(user))
        return React.createElement(LoadingState, null);
    return (React.createElement(SettingsWrapper, null,
        React.createElement(ValidatorForm, { onSubmit: e => {
                e.preventDefault();
                // TODO: check if we need the entire theme object
                void activeOrganization.ref.update({
                    theme: JSON.parse(JSON.stringify(theme)),
                    icon
                });
            } },
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "h5", component: "h4", gutterBottom: true }, "Custom Branding")),
                React.createElement(Grid, { className: classes.formWrapper },
                    React.createElement(Grid, { className: classes.formRow, container: true, direction: "row", alignItems: "center" },
                        React.createElement(Box, { className: classes.formLabel, mr: 2 },
                            React.createElement(Typography, { variant: "h6" }, "Organization Logo")),
                        React.createElement(Typography, null, "This logo will appear on your public-facing search page and emails sent to clients through Column"),
                        React.createElement(Grid, { alignItems: "center", container: true, className: classes.uploadGrid },
                            icon && (React.createElement(Box, { mr: 2 },
                                React.createElement("img", { className: classes.thumb, src: icon, alt: "icon" }))),
                            React.createElement(UploadButton, { folder: `documentcloud/${activeOrganization.id}/icons/`, accept: "image/x-png,image/gif,image/jpeg", label: icon ? 'Update Icon' : 'Choose Icon', color: "secondary", processUpload: (file) => __awaiter(void 0, void 0, void 0, function* () { return setIcon(yield file.ref.getDownloadURL()); }) }))),
                    activeOrganization.data().subdomain && (React.createElement(Grid, { className: classes.formRow, container: true, direction: "column", item: true },
                        React.createElement(FormControl, { className: classes.formControl, required: true },
                            React.createElement(InputLabel, { htmlFor: "circulationSize" }, "Subdomain"),
                            React.createElement(Input, { value: activeOrganization.data().subdomain, disabled: true })))),
                    React.createElement(Grid, { className: classes.formRow, container: true, direction: "column", item: true },
                        React.createElement(Box, { className: classes.formLabel, mr: 2 },
                            React.createElement(Typography, { variant: "h6" }, "Primary Color"),
                            React.createElement(Typography, null, "This color will appear on buttons and headers throughout Column and your public facing search site")),
                        React.createElement(Box, null,
                            React.createElement(ColorPicker, { name: "color", defaultValue: (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.primary.main, value: (_b = theme.palette) === null || _b === void 0 ? void 0 : _b.primary.main, onChange: (color) => setTheme(Object.assign(Object.assign({}, theme), { palette: Object.assign(Object.assign({}, theme.palette), { primary: computeColors(color) }) })) }))),
                    React.createElement(Grid, { className: classes.formRow, container: true, direction: "column", item: true },
                        React.createElement(Box, { className: classes.formLabel, mr: 2 },
                            React.createElement(Typography, { variant: "h6" }, "Secondary Color"),
                            React.createElement(Typography, null, "This color will appear on notifications and secondary buttons throughout the Column site")),
                        React.createElement(Box, null,
                            React.createElement(ColorPicker, { name: "color", defaultValue: (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.secondary.main, value: (_d = theme.palette) === null || _d === void 0 ? void 0 : _d.secondary.main, onChange: (color) => setTheme(Object.assign(Object.assign({}, theme), { palette: Object.assign(Object.assign({}, theme.palette), { secondary: computeColors(color) }) })) })))),
                React.createElement(Grid, { className: classes.formRow, container: true, direction: "column", item: true },
                    React.createElement(Box, { className: classes.formLabel, mr: 2 },
                        React.createElement(Typography, { variant: "h6" }, "Background Color"),
                        React.createElement(Typography, null, "This color will appear as a background to your components across the Column site")),
                    React.createElement(Box, { id: "background-picker" },
                        React.createElement(ColorPicker, { name: "color", defaultValue: (_e = theme.palette) === null || _e === void 0 ? void 0 : _e.background.default, internalValue: "red", value: (_f = theme.palette) === null || _f === void 0 ? void 0 : _f.background.default, onChange: (color) => setTheme(Object.assign(Object.assign({}, theme), { palette: Object.assign(Object.assign({}, theme.palette), { background: { default: color } }) })) }))),
                React.createElement(Grid, { container: true, alignItems: "center", justify: "center" },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Button, { className: classes.saveButton, variant: "contained", color: "primary", type: "submit" }, "Save")))),
            React.createElement("style", null, `
        #background-picker > div {
          background: #${invertHex(((_g = theme.palette) === null || _g === void 0 ? void 0 : _g.background.default) || '')};
        }
      `))));
};
export default withStyles(styles, { withTheme: true })(CustomBrandSettings);
