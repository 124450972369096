import { all, take, takeEvery, call, put, select, race, fork, takeLatest, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { logAndCaptureException } from 'utils';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { OccupationType, RoleType, State } from 'lib/enums';
import { exists } from 'lib/types';
import { push } from 'connected-react-router';
import api from 'api';
import { getFirebaseContext } from 'utils/firebase';
import { getAllowedOrganizationSnaps } from 'lib/users';
import { getRole } from '../utils/permissions';
import AuthActions, { AuthTypes, authSelector } from '../redux/auth';
import ErrorActions from '../redux/errors';
import Firebase from '../EnoticeFirebase';
import { getLocationParams, getSubdomain, getHostname } from '../utils/urls';
import { ENV, PROD, DEMO, SHOULD_RUN_PENDO } from '../constants';
import { getRedirect } from './RoutingSaga';
import * as flags from '../utils/flags';
export const UNABLE_TO_FETCH_USER = 'Unable to fetch user';
/**
 *
 * @param {snapshot} org Organization to watch
 * Firebase snapshot to watch for updates on
 */
export function* watchActiveOrg(org) {
    if (!org)
        return;
    const orgChannel = eventChannel(emitter => org.ref.onSnapshot(emitter, err => logAndCaptureException(err, 'Error listening to active org snapshot ')));
    // ignore the first update
    yield take(orgChannel);
    yield takeEvery(orgChannel, function* f(org) {
        const authState = yield select(authSelector);
        const { user } = authState;
        if (!user)
            return;
        const userSnap = yield call(fetchUser, user.ref);
        const availableOrgs = yield call(getAllowedOrganizationSnaps, userSnap);
        yield put(AuthActions.setAvailableOrganizations(availableOrgs));
        yield put(AuthActions.setActiveOrganization(org));
    });
    yield take(AuthTypes.LOGOUT_SUCCESS);
    orgChannel.close();
}
export function* listenActiveOrg() {
    while (true) {
        const auth = yield select(authSelector);
        yield race([
            call(watchActiveOrg, auth.activeOrganization),
            take(AuthTypes.SET_ACTIVE_ORGANIZATION)
        ]);
    }
}
export function* fetchUser(userRef) {
    for (let i = 0; i < 5; i++) {
        try {
            const userSnap = yield call([userRef, userRef.get]);
            if (!userSnap.exists)
                throw new Error('User not found');
            return userSnap;
        }
        catch (err) {
            /**
             * This means we have a permanent error reaching Firestore and it's likely the user's
             * network will not allow the connection.
             *
             * We should suggest the following troubleshooting:
             * 1) Refresh the page
             * 2) If they've already refreshed and still see the error, change the setting
             */
            if (err && err.code === 'unavailable') {
                logAndCaptureException(err, 'User not found', {
                    userId: userRef.id
                });
                break;
            }
            // Retry fetching the user up to 5 times at 2000ms apart
            if (i < 4) {
                yield delay(2000);
            }
        }
    }
    yield put(ErrorActions.setError({ error: UNABLE_TO_FETCH_USER }));
    throw new Error('User fetch failed');
}
export function* watchActiveUser(userSnapshot) {
    const userChannel = eventChannel(emitter => userSnapshot.ref.onSnapshot(emitter, err => logAndCaptureException(err, 'Error listening to user snapshot')));
    yield takeEvery(userChannel, function* f(user) {
        // Update the LaunchDarkly user; do this before setting user in state, so any components that
        // re-render on user changes evaluate LD flags with the right user.
        yield call(flags.setUser, user);
        // Set the local user state
        yield put(AuthActions.setUser(user));
    });
    yield take(AuthTypes.LOGOUT_SUCCESS);
    userChannel.close();
    // Set the LaunchDarkly user to anonymous
    yield call(flags.setUser, undefined);
}
export function* setUserParameters(user) {
    try {
        const ctx = getFirebaseContext();
        /**
         * Exit early if the user is not signed in using any method
         * or is signed in anonymously
         */
        if (!user) {
            return;
        }
        yield put(AuthActions.setUserAuth(user));
        if (user.isAnonymous) {
            return;
        }
        const userRef = ctx.usersRef().doc(user.uid);
        const userSnap = yield call(fetchUser, userRef);
        userSnap.ref.update({
            lastSignInTime: new Date(Date.now())
        });
        yield fork(watchActiveUser, userSnap);
        const userdata = userSnap.data();
        if (!userdata.organization) {
            yield put(AuthActions.setActiveOrganization(null));
            return;
        }
        const organizationSnapshot = yield call([
            userdata.organization,
            userdata.organization.get
        ]);
        const activeOrgQuery = getLocationParams().get('activeOrg');
        let orgFromQuery;
        if (activeOrgQuery) {
            const ref = ctx.organizationsRef().doc(activeOrgQuery);
            orgFromQuery = yield call([ref, ref.get]);
            const userCanAccessThisOrg = (orgFromQuery.exists && userdata.organization.id === orgFromQuery.id) ||
                (orgFromQuery.data().parent &&
                    orgFromQuery.data().parent.id === userdata.organization.id);
            const isDefaultOrg = orgFromQuery.data().id === userdata.organization.id;
            if (!userCanAccessThisOrg || isDefaultOrg)
                orgFromQuery = null;
        }
        let orgFromUserdata;
        if (userdata.activeOrganization) {
            orgFromUserdata = yield call([
                userdata.activeOrganization,
                userdata.activeOrganization.get
            ]);
        }
        else {
            orgFromUserdata = null;
        }
        const availableOrgs = yield call(getAllowedOrganizationSnaps, userSnap);
        yield put(AuthActions.setAvailableOrganizations(availableOrgs));
        yield put(AuthActions.setOrganization(organizationSnapshot));
        yield put(AuthActions.setActiveOrganization(orgFromQuery || orgFromUserdata || organizationSnapshot));
        yield fork(listenActiveOrg);
    }
    catch (err) {
        logAndCaptureException(err, 'Auth: Error in setUserParameters', {
            userEmail: (user === null || user === void 0 ? void 0 : user.email) || ''
        });
    }
    finally {
        const { user, userAuth } = yield select(authSelector);
        if (userAuth && !user)
            yield take(AuthTypes.SET_USER);
        yield put(AuthActions.endAuth());
    }
}
function* register() {
    yield call(setUserParameters, Firebase.auth().currentUser);
}
function* loginTokenFlow({ token }) {
    yield put(AuthActions.startAuth());
    yield call([Firebase.auth(), Firebase.auth().signInWithCustomToken], token);
    yield put(push(yield getRedirect()));
}
function* loginFlow({ email, password, redirect }) {
    try {
        yield put(AuthActions.startAuth());
        yield call([Firebase.auth(), Firebase.auth().signInWithEmailAndPassword], email, password);
        const placementRedirectUrl = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('placementRedirectUrl');
        if (placementRedirectUrl)
            yield put(push(`/?redirect=${encodeURIComponent(placementRedirectUrl)}`));
        if (redirect) {
            yield put(push(yield getRedirect()));
        }
        yield call([api, api.post], 'notifications/slack', {
            message: `New login from ${email}`
        });
    }
    catch (err) {
        // We don't need to log if the user just enters the wrong password.
        const e = err;
        const shouldLogError = !(e.code &&
            (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found'));
        if (shouldLogError) {
            logAndCaptureException(err, 'Auth: Error in loginFlow', {
                email
            });
        }
        yield put(AuthActions.setAuthError(err.message));
    }
}
export function* anonymousLogin() {
    const { userAuth } = yield select(authSelector);
    if (!userAuth) {
        try {
            yield put(AuthActions.startAuth());
            yield call([Firebase.auth(), Firebase.auth().signInAnonymously]);
            // Set the LaunchDarkly user to anonymous
            yield call(flags.setUser, undefined);
        }
        catch (err) {
            logAndCaptureException(err, 'Auth: Error in anonymousLogin');
            yield put(AuthActions.setAuthError('Something went wrong in anonymous sign in'));
        }
        finally {
            yield put(AuthActions.endAuth());
        }
    }
}
function* listenOnAuth() {
    const shouldDisplayChat = ENV === PROD || ENV === DEMO;
    const authChannel = eventChannel(emitter => Firebase.auth().onAuthStateChanged(authState => {
        if (authState) {
            emitter(authState);
            const email = authState.email || '';
            const name = authState.displayName || '';
            if (ENV === PROD || ENV === DEMO) {
                Sentry.configureScope(scope => scope.setUser({
                    email
                }));
            }
            if (shouldDisplayChat) {
                window.Beacon('identify', {
                    name,
                    email,
                    PhoneNumber: authState.phoneNumber
                });
                window.Beacon('prefill', {
                    name,
                    email
                });
            }
            LogRocket.identify(authState.uid, {
                name,
                email,
                enviornment: ENV
            });
            if (SHOULD_RUN_PENDO) {
                try {
                    window.pendo.initialize({
                        visitor: {
                            id: authState.uid,
                            email
                        }
                    });
                }
                catch (err) {
                    logAndCaptureException(err, 'Auth: Failed to initialize pendo', {
                        email
                    });
                }
            }
        }
        else {
            emitter(false);
        }
    }));
    yield takeEvery(authChannel, setUserParameters);
}
function* logoutFlow() {
    yield put(AuthActions.startAuth());
    yield call([Firebase.auth(), Firebase.auth().signOut]);
    yield put(AuthActions.logoutSuccess());
    sessionStorage.clear();
    yield put(push('/login'));
}
/**
 * Sets the organization context from the current subdomain or hostname
 * or, if one exists, from the current active organization
 */
const getContextKey = () => {
    const hostname = getHostname();
    if (['publicnoticecolorado'].indexOf(hostname) !== -1)
        return hostname;
    return getSubdomain();
};
function* getOrgContext() {
    const ctx = getFirebaseContext();
    const contextKey = getContextKey();
    const orgQuery = ctx.organizationsRef().where('subdomain', '==', contextKey);
    const childOrgSnapshot = yield call([orgQuery, orgQuery.get]);
    if (childOrgSnapshot.docs.length) {
        const orgContext = childOrgSnapshot.docs[0];
        yield put(AuthActions.setOrgContext(orgContext));
    }
    else {
        const { activeOrganization } = yield take(AuthTypes.SET_ACTIVE_ORGANIZATION);
        if (activeOrganization) {
            yield put(AuthActions.setOrgContext(activeOrganization));
        }
    }
}
function* updateActiveOrganization({ activeOrganization }) {
    try {
        if (activeOrganization) {
            const { user } = yield select(authSelector);
            if (user) {
                yield call([user.ref, user.ref.update], {
                    activeOrganization: activeOrganization.ref
                });
            }
            yield call(getOrgContext);
        }
    }
    catch (err) {
        logAndCaptureException(err, 'Auth: Error in updateActiveOrganization', {
            activeOrgId: activeOrganization.id
        });
    }
}
function* initializeProductTracking({ user }) {
    var _a, _b, _c, _d, _e;
    if (!exists(user))
        return;
    if (SHOULD_RUN_PENDO) {
        try {
            const userOccupation = OccupationType.by_value(user.data().occupation);
            let userRole;
            const userState = State.by_value((_a = user.data()) === null || _a === void 0 ? void 0 : _a.state);
            let activeOrgSnap;
            const { activeOrganization } = user.data();
            if (activeOrganization) {
                activeOrgSnap = yield call([
                    activeOrganization,
                    activeOrganization.get
                ]);
                userRole = yield call(getRole, user);
            }
            const pendoOrganizationData = exists(activeOrgSnap)
                ? {
                    id: activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.id,
                    organizationName: (activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data().name) || '',
                    parentOrg: ((_c = (_b = activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data()) === null || _b === void 0 ? void 0 : _b.parent) === null || _c === void 0 ? void 0 : _c.id) || '',
                    disabled: !!(activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data().disabled),
                    dwolla: !!(activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data().dwolla)
                }
                : {};
            window.pendo.initialize({
                visitor: {
                    id: user.id,
                    email: user.data().email,
                    occupation: (userOccupation === null || userOccupation === void 0 ? void 0 : userOccupation.label) || '',
                    role: (_e = (_d = RoleType.by_value(userRole)) === null || _d === void 0 ? void 0 : _d.label) !== null && _e !== void 0 ? _e : '',
                    state: (userState === null || userState === void 0 ? void 0 : userState.label) || ''
                },
                account: pendoOrganizationData
            });
        }
        catch (err) {
            console.error(`Failed to initialize pendo: ${err}`);
        }
    }
}
export default function* root() {
    yield fork(getOrgContext);
    yield fork(listenOnAuth);
    yield all([
        takeEvery(AuthTypes.REGISTER, register),
        takeEvery(AuthTypes.LOGIN, action => loginFlow(action)),
        takeEvery(AuthTypes.ANONYMOUS_LOGIN, anonymousLogin),
        takeEvery(AuthTypes.LOGIN_TOKEN, action => loginTokenFlow(action)),
        takeEvery(AuthTypes.LOGOUT, logoutFlow),
        takeEvery(AuthTypes.SET_ACTIVE_ORGANIZATION, action => updateActiveOrganization(action)),
        takeEvery(AuthTypes.SET_USER, getOrgContext),
        takeLatest([AuthTypes.SET_USER, AuthTypes.SET_ACTIVE_ORGANIZATION], action => initializeProductTracking(action))
    ]);
}
