var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OccupationType, NotificationType, State } from '../enums';
import { exists } from '../types';
export const getChildStates = (ctx, organization) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(organization))
        return;
    const children = yield ctx
        .organizationsRef()
        .where('parent', '==', organization.ref)
        .get();
    return children.docs.map(child => child.data().state);
});
export const createNotificationsObject = (occupation, orgState = null, childStates = null) => {
    var _a;
    const occupation_key = (_a = OccupationType.by_value(occupation)) === null || _a === void 0 ? void 0 : _a.key;
    if (!occupation_key) {
        throw new Error(`invalid occupation value ${occupation}`);
    }
    const notifications = {};
    NotificationType.items().forEach((notification) => {
        let isRelevantToUser = (notification.recipient_occupation_type_keys || []).includes(occupation_key || '');
        if (notification.key === 'survey_email' ||
            notification.key === 'survey_reminder') {
            const orgIsInFlorida = orgState === State.florida.value;
            const childIsInFlorida = (childStates || []).some(childState => childState === State.florida.value);
            isRelevantToUser =
                isRelevantToUser && (orgIsInFlorida || childIsInFlorida);
        }
        notifications[notification.key] = {
            inApp: isRelevantToUser,
            email: isRelevantToUser,
            isConfigurable: isRelevantToUser && notification.isConfigurable
        };
    });
    return notifications;
};
