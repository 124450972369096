var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PlacementActions from 'redux/placement';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { PROD, ENV } from '../../constants';
import ScrollStep from './ScrollStep';
/**
 * Only show planrooms to users that have the flag explicitly set for them
 * in Firestore
 * @param user current user in the placement flow
 * @returns boolean whether or not the should be shown the planroom
 */
export const shouldShowBiddyIntegrationToUser = (user) => {
    var _a;
    if ((_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.isProcurementUser)
        return true;
    return false;
};
const BIDDY_ORIGIN = ENV !== PROD
    ? 'https://column.staging.getpropeller.com'
    : 'https://column.biddyhq.com';
const getPlanroomFormCreationURL = (biddyID, saltData) => {
    if (!saltData)
        return null;
    const planroomBase = `${BIDDY_ORIGIN}/pages/jobs/iframe`;
    // if this is a new planroom
    if (!biddyID) {
        return `${planroomBase}/new`;
    }
    // if this is an existing planroom, pass in the bcrypt value
    return `${planroomBase}/${biddyID}/edit?timestamp=${saltData.timestamp}&calculated_value=${saltData.calculatedValue}`;
};
export const getColumnHostedPlanroomURLFromBiddyID = (notice) => {
    var _a, _b;
    return `https://planroom.column.us/${((_b = (_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.original) === null || _b === void 0 ? void 0 : _b.id) || ''}`;
};
const EXISTING_ROOM_HEIGHT = 810;
const NEW_ROOM_HEIGHT = 520;
const BiddyPlanroom = ({ onDisabledStepClick, placementActions, activeStepId, previous, notice, next, id }) => {
    const { biddyID } = notice.data();
    const [iframeHydrated, setIframeHydrated] = useState(false);
    const [saltData, setSaltData] = useState();
    // the URL of the embedded Biddy IFrame
    const planroomFormCreationURL = getPlanroomFormCreationURL(biddyID, saltData);
    // dyanmic planroom URL based on the current Biddy ID
    const columnHostedPlanroomURL = getColumnHostedPlanroomURLFromBiddyID(notice);
    // Target URL for folks to learn more about the Column <> Biddy partnership
    const PARTNERSHIP_URL = 'https://column.notion.site/column/Full-Service-Bid-Package-Management-51fad258afe844e08cbe451288eaea2a';
    // ref to the underlying iframe element
    const planroomIframe = useRef();
    /**
     * once the iframe has been loaded, inject our authentication session
     * data into the iframe
     */
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            const saltData = yield api.post('experiments/planroom');
            setSaltData(saltData);
        }))();
    }, []);
    /**
     * Once an iframe has been added to the document, wait for
     * a postmessage back from the iframe that says it is ready to
     * receive the salt data
     */
    useEffect(() => {
        const listenForBiddyHydration = (e) => {
            if (e.data === 'iframeLoaded') {
                setIframeHydrated(true);
                return;
            }
            window.removeEventListener('message', listenForBiddyHydration);
        };
        window.addEventListener('message', listenForBiddyHydration, false);
    }, [planroomIframe.current]);
    /**
     * send a postmessage to the Biddy IFrame with the authentication credentials
     * to enable submission
     */
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (!iframeHydrated || !saltData)
                return;
            (_b = (_a = planroomIframe.current) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage(JSON.stringify(saltData), BIDDY_ORIGIN);
        }))();
    }, [iframeHydrated, Boolean(saltData)]);
    /**
     * Once the "next" button has been clicked, run a submission postmessage
     * through the Biddy form and see what we get back in response
     */
    const onExit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!planroomIframe.current) {
            throw new Error(`Unable to pass submission to iFrame as ref is unpopulated`);
        }
        // listen to response from Biddy that may or may not be successful
        const biddyID = yield new Promise((resolve, reject) => {
            var _a, _b;
            const listenToBiddyResponse = (e) => {
                // ignore iframeLoaded responses which come from the successful response!
                if (e.data === 'iframeLoaded')
                    return;
                if (e.origin === BIDDY_ORIGIN) {
                    window.removeEventListener('message', listenToBiddyResponse);
                    const biddyResponse = JSON.parse(e.data);
                    biddyResponse.success ? resolve(biddyResponse.id) : reject();
                }
            };
            window.addEventListener('message', listenToBiddyResponse, false);
            // submit the form in the iFrame
            (_b = (_a = planroomIframe.current) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage(JSON.stringify(Object.assign({ message: 'submitForm' }, saltData)), BIDDY_ORIGIN);
        });
        // if we get a success response AND a new ID, set it on the notice object
        // and populate the notice text with that ID
        if (biddyID) {
            yield notice.ref.update({
                biddyID
            });
            yield placementActions.setNoticeText(`Once your planroom has been approved and published by our experts, it will be available at this link: ${getColumnHostedPlanroomURLFromBiddyID(notice)}`);
        }
    });
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield onExit();
                next();
            }
            catch (err) {
                logAndCaptureException(err, 'Error creating biddy planroom', {
                    noticeID: notice.id
                });
            }
        }), previous: previous, complete: true, onDisabledStepClick: onDisabledStepClick, title: React.createElement("div", { className: "flex" },
            React.createElement("div", null, "Full Service Bid Package Distribution"),
            React.createElement("span", { className: "ml-2 inline-flex items-center rounded-full bg-gray-200 px-2 py-1 font-medium text-gray-800 text-xs" }, "FREE!")), caption: React.createElement(React.Fragment, null,
            React.createElement("div", null,
                "Upload all of your bid documents and we will handle the entire process for you including distributing bid documents to interested bidders, keeping your planholder\u2019s list up-to-date, and distributing addendums or other changes.",
                ' ',
                React.createElement("a", { className: "underline", target: "_blank", href: PARTNERSHIP_URL, rel: "noreferrer" }, "Learn More")),
            React.createElement("div", { className: "mt-4" },
                "Your planroom for this project will be available at the following url:",
                ' ',
                React.createElement("a", { href: columnHostedPlanroomURL, className: "underline", target: "_blank", rel: "noopener noreferer noreferrer" }, columnHostedPlanroomURL))) }, planroomFormCreationURL && (React.createElement("div", null,
        React.createElement("iframe", { ref: planroomIframe, className: "w-full", style: {
                // we hardcode different iframe heights based on whether or not
                // the form is already hydrated
                height: biddyID ? EXISTING_ROOM_HEIGHT : NEW_ROOM_HEIGHT
            }, title: "Biddy Planroom", src: planroomFormCreationURL })))));
};
const mapStateToProps = (state) => ({
    placementHTML: state.placement.confirmedHtml
});
const mapDispatchToProps = (dispatch) => ({
    placementActions: bindActionCreators(PlacementActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(BiddyPlanroom);
