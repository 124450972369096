import React, { useEffect, useMemo, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Eye } from 'icons';
import { debounce } from 'utils/funcs';
import { isFontInstalled, templateStylesToCss } from 'utils/styles';
import { getEditor, setEditor, preProcess, postProcess, resizeTables, formatTablesOnCopyPaste } from './mceHelpers';
import { TINY_MCE_KEY } from '../../constants';
const setComputedWidth = (tables) => {
    for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        const firstRow = table.querySelector('tr');
        const cells = firstRow ? [...firstRow.querySelectorAll('td')] : [];
        for (let z = 0; z < cells.length; z++) {
            const computedStyles = window.getComputedStyle(cells[z]);
            const paddingLeft = parseFloat(computedStyles.paddingLeft) || 0;
            const paddingRight = parseFloat(computedStyles.paddingRight) || 0;
            const width = parseFloat(computedStyles.width);
            const computedWidth = width + paddingLeft + paddingRight;
            cells[z].setAttribute('computed-width', `${computedWidth}px;`);
        }
    }
};
const addComputedWidthAttributeToTables = (contentDOM) => {
    const tables = contentDOM && contentDOM.querySelectorAll('table');
    if (tables && tables.length) {
        setComputedWidth(tables);
    }
};
const MCE = React.forwardRef((props, ref) => {
    var _a, _b, _c;
    const { onEditorUpdate = () => { }, initialState, cleanFunction, placeholder, disabled, errorTitle, errorFunction, clickText, squashableColumn, onPaste, newspaper, isPublisher, placement, displayPaperFileReplacement, templateStyles, columns } = props;
    const [zoom, setZoom] = useState(1.5);
    const [tableInNotice, setTableInNotice] = useState(false);
    useEffect(() => {
        const editor = getEditor();
        if (!editor)
            return;
        if (!disabled) {
            editor.setMode('design');
        }
        else {
            editor.setMode('readonly');
        }
    });
    const isTableInNotice = (notice) => {
        setTableInNotice(notice.includes('<table') && notice.includes('</table>'));
    };
    const handleEditorChange = debounce(() => {
        const editor = getEditor();
        if (!editor) {
            return;
        }
        const contentDOM = editor.getBody();
        if (!contentDOM)
            return;
        isTableInNotice(contentDOM.innerHTML);
        /*
          Add a computed-width attribute to table cells
          This attribute is stringified and passed along the Indesign
          pipeline so that it can be used to accurately determine
          column widths based on what the user sees in TinyMCE.
        */
        addComputedWidthAttributeToTables(contentDOM);
        if (squashableColumn === 'squash') {
            onEditorUpdate(formatTablesOnCopyPaste(contentDOM.innerHTML));
        }
        else {
            onEditorUpdate(contentDOM.innerHTML);
        }
        if (!disabled) {
            editor.setMode('design');
        }
        else {
            editor.setMode('readonly');
        }
    }, 500);
    const handleOnPaste = debounce(() => {
        if (squashableColumn !== 'squash')
            return;
        const editor = getEditor();
        if (!editor)
            return;
        const contentDOM = editor.getBody();
        if (!contentDOM)
            return;
        isTableInNotice(contentDOM.innerHTML);
        /*
          Add a computed-width attribute to table cells
          This attribute is stringified and passed along the Indesign
          pipeline so that it can be used to accurately determine
          column widths based on what the user sees in TinyMCE.
        */
        addComputedWidthAttributeToTables(contentDOM);
        onPaste && onPaste(formatTablesOnCopyPaste(contentDOM.innerHTML));
        if (!disabled) {
            editor.setMode('design');
        }
        else {
            editor.setMode('readonly');
        }
    }, 500);
    const isTemplateFontInstalled = useMemo(() => {
        return templateStyles.font ? isFontInstalled(templateStyles.font) : true;
    }, [templateStyles.font]);
    const getBodyStyles = () => {
        const templateBodyStyles = templateStylesToCss(templateStyles, columns, isTemplateFontInstalled);
        // These styles are unrelated to the template styles
        const transformStyle = zoom === 1.5 ? 'scale(1.5)' : zoom === 2.0 ? 'scale(2.0)' : 'unset';
        const transformOriginStyle = zoom > 1.0 ? '0 0' : 'unset';
        const otherBodyStyles = {
            'border-left': '2px dotted rgba(0, 0, 0, 0.2)',
            'border-right': '2px dotted rgba(0, 0, 0, 0.2)',
            margin: '12px 0 0 12px',
            padding: 0,
            'min-height': '95%',
            // CSS transform based on zoom setting
            transform: transformStyle,
            'transform-origin': transformOriginStyle
        };
        return Object.assign(Object.assign({}, otherBodyStyles), templateBodyStyles);
    };
    const bodyStyles = getBodyStyles();
    const bodyCss = Object.entries(bodyStyles)
        .map(([a, b]) => `${a}: ${b};`)
        .join('\n');
    // Custom style added to the TinyMCE editor. This only affects how things
    // look inside the MCE editor box, it does not affect Indesign in any way.
    const contentStyle = `
  html {
    height: 100%;
  }

  body {
    ${bodyCss}
  }

  .mce-content-readonly {
    background: #cacaca;
  }

  .mce-content-body {
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
  
  .mce-content-body p { 
    margin-bottom: 0;
    margin-top: 0;
  }

  .mce-content-body ul, 
  .mce-content-body ol {
    list-style-position: inside;
  
    margin-top: 0;
    margin-bottom: 0;

    padding-left: 0;
    padding-right: 0;
  }

  .mce-content-body table tr th,
  .mce-content-body table tr td {
    padding: 0;
  }`;
    /**
     * Updates the CSS inside the MCE iframe, since TinyMCE only takes custom_styles
     * at initialization time.
     */
    const updateEditorStyle = () => {
        var _a, _b;
        const editor = getEditor();
        if (!editor) {
            return;
        }
        const styleElements = (_b = (_a = editor.iframeElement) === null || _a === void 0 ? void 0 : _a.contentDocument) === null || _b === void 0 ? void 0 : _b.getElementsByTagName('style');
        if (!styleElements || styleElements.length === 0) {
            return;
        }
        styleElements[0].innerHTML = contentStyle;
    };
    useEffect(() => {
        updateEditorStyle();
    }, [contentStyle]);
    /**
     * NOTE: Currently we're saving the raw `innerHTML` of the editor body from the actual DOM as the HTML string
     * for a notice. However, the TinyMCE input uses a different verion of the HTML markup as its actual value, which
     * it then uses to render the resulting `innterHTML`. See documentation at [this link](https://www.tiny.cloud/docs/integrations/react/#oneditorchange).
     * Compare the `value` of `onEditorChange` to `editor.getBody().innerHTMl` to see the difference.
     *
     * Specifically, the actual markup on the page contains `<p><br data-mce-bogus="1"><\/p>` for empty line breaks after
     * TinyMCE renders it, but the "value" stored by the input and used to actually render the content in the form
     * contains `\n<p>&nbsp;</p>` for the same effect. Previously we were replacing the form's value with the resulting
     * DOM markup, causing rendering issues and cursor jumps when a user presses enter.
     *
     * This workaround reverses the raw DOM markup back to the markup TinyMCE expects to render for the linebreak.
     * This is not a sustainable fix, but I'm not clear on how our entire rendering process handles the current version of the
     * markup using `<p><br data-mce-bogus="1"><\/p>`, or if there are further differences between the raw DOM and the value
     * TinyMCE uses to render the content that may need to be addressed.
     */
    const formattedHtmlString = (initialState || '').replace(/<p><br data-mce-bogus="1"><\/p>/g, '\n<p>&nbsp;</p>');
    return (React.createElement(React.Fragment, null,
        !isTemplateFontInstalled && (React.createElement("div", { className: "flex items-center mb-2 px-3 py-2 rounded overflow-hidden text-sm bg-gray-100 text-gray-600" },
            React.createElement("div", { className: "ml-1 mr-3 text-gray-700" },
                React.createElement(Eye, null)),
            React.createElement("div", null, "This newspaper uses a custom font, so text may appear differently in the editor below and the rendered preview."))),
        ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.cleanVariant) && tableInNotice && (React.createElement("div", { className: "flex items-center mb-2 px-3 py-2 rounded overflow-hidden text-sm bg-gray-100 text-gray-600" },
            React.createElement("div", { className: "ml-1 mr-3 text-gray-700" },
                React.createElement(Eye, null)),
            React.createElement("div", null, "This newspaper does not allow tables so the text may appear differently in the editor below and the rendered preview."))),
        React.createElement("div", { style: { height: 500, minHeight: 500, width: '100%' }, ref: ref, className: "relative" },
            disabled && errorTitle && (React.createElement("div", { className: "absolute bg-white h-full opacity-75 w-full z-10 flex items-center flex-col" },
                errorTitle,
                !((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.displayOnlyAds) && errorFunction && (React.createElement("p", { className: "text-center text-gray-600" },
                    React.createElement("a", { href: "#", rel: "noreferrer", className: "underline", onClick: errorFunction }, "Click here"),
                    ' ',
                    clickText)),
                ((_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _c === void 0 ? void 0 : _c.displayOnlyAds) &&
                    !displayPaperFileReplacement &&
                    isPublisher &&
                    (placement === null || placement === void 0 ? void 0 : placement.postWithoutFormatting) &&
                    errorFunction && (React.createElement("p", { className: "text-center text-gray-600" },
                    React.createElement("a", { href: "#", rel: "noreferrer", className: "underline", onClick: errorFunction }, "Click here"),
                    ' ',
                    clickText)))),
            React.createElement(Editor, { apiKey: TINY_MCE_KEY, onEditorChange: handleEditorChange, onInit: () => {
                    if (initialState !== placeholder)
                        handleEditorChange();
                }, value: formattedHtmlString, onPaste: handleOnPaste, disabled: disabled, init: {
                    browser_spellcheck: true,
                    setup: (editor) => {
                        setEditor(editor);
                        disabled && editor.setMode('readonly');
                        !disabled && editor.setMode('design');
                        editor.ui.registry.addMenuButton('customzoom', {
                            icon: 'zoom-in',
                            tooltip: 'Zoom',
                            fetch: callback => {
                                callback([
                                    {
                                        type: 'menuitem',
                                        text: '100% Zoom (actual size)',
                                        onAction: () => {
                                            setZoom(1.0);
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: '150% Zoom (default)',
                                        onAction: () => {
                                            setZoom(1.5);
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: '200% Zoom',
                                        onAction: () => {
                                            setZoom(2.0);
                                        }
                                    }
                                ]);
                            }
                        });
                        editor.on('init', () => {
                            // set placeholder text
                            const { menuItems } = editor.ui.registry.getAll();
                            if (menuItems.tableprops) {
                                delete menuItems.tableprops;
                            }
                            resizeTables();
                            editor.ui.registry.addToggleButton('applystroke', {
                                text: 'Apply Stroke',
                                onAction: (api) => {
                                    var _a, _b, _c;
                                    const selectedNode = editor.selection.getNode();
                                    function getClosest(el, tag) {
                                        const localTag = tag.toUpperCase();
                                        let localEl = el;
                                        do {
                                            if (localEl && localEl.nodeName === localTag) {
                                                return localEl;
                                            }
                                        } while ((localEl = localEl === null || localEl === void 0 ? void 0 : localEl.parentNode));
                                        return null;
                                    }
                                    const selectedTable = getClosest(selectedNode, 'table');
                                    if (!selectedTable)
                                        return;
                                    const isStroke = ((_a = selectedTable.parentElement) === null || _a === void 0 ? void 0 : _a.getAttribute('data-custom-style')) === 'stroke';
                                    const styles = {
                                        isStroke: {
                                            border: '1px solid rgba(0, 0, 0, 0.87)',
                                            attributeName: 'stroke'
                                        },
                                        default: {
                                            border: '1px solid #ccc'
                                        }
                                    };
                                    if (!isStroke) {
                                        (_b = selectedTable.parentElement) === null || _b === void 0 ? void 0 : _b.setAttribute('data-custom-style', styles.isStroke.attributeName);
                                    }
                                    if (isStroke) {
                                        (_c = selectedTable.parentElement) === null || _c === void 0 ? void 0 : _c.removeAttribute('data-custom-style');
                                    }
                                    const childCells = selectedTable.parentElement
                                        ? [...selectedTable.parentElement.querySelectorAll('td')]
                                        : [];
                                    for (let i = 0; i < childCells.length; i++) {
                                        const style = isStroke
                                            ? styles.default.border
                                            : styles.isStroke.border;
                                        childCells[i].style.border = style;
                                    }
                                    if (editor) {
                                        onEditorUpdate(editor.getBody().innerHTML);
                                    }
                                    api.setActive(!api.isActive());
                                }
                            });
                            // There are a lot of components of the default table plugin we do not support
                            // this is a way of hiding them, and making room for custom components in the future
                            editor.ui.registry.addMenuButton('customTable', {
                                icon: 'table',
                                tooltip: 'Table',
                                fetch: callback => {
                                    const items = [
                                        editor.ui.registry.getAll().menuItems.inserttable,
                                        editor.ui.registry.getAll().menuItems.deletetable,
                                        editor.ui.registry.getAll().menuItems.row,
                                        editor.ui.registry.getAll().menuItems.column
                                    ];
                                    callback(items);
                                }
                            });
                        });
                        return editor;
                    },
                    paste_preprocess: preProcess(cleanFunction),
                    paste_postprocess: postProcess,
                    init_instance_callback: () => {
                        const freeTiny = document.querySelector('.tox-notifications-container');
                        if (freeTiny && freeTiny.style) {
                            freeTiny.style.display = 'none';
                        }
                    },
                    underline: {
                        inline: 'u',
                        styles: { 'text-decoration': 'underline' },
                        exact: true
                    },
                    formats: {
                        strikethrough: [
                            { inline: 's' },
                            { inline: 'del' },
                            { inline: 'strike' },
                            { inline: 'span', styles: { textDecoration: 'line-through' } }
                        ]
                    },
                    height: '100%',
                    menubar: false,
                    nonbreaking_force_tab: true,
                    plugins: [
                        'advlist autolink lists link charmap preview anchor',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste code help nonbreaking'
                    ],
                    paste_word_valid_elements: '@[style],-strong/b,-em/i,-span,-p,-ol,-ul,-li,-table,-tr,-td[colspan|rowspan],-th,-thead,-tfoot,-tbody,-a[href|name],sub,sup,strike,br,u',
                    content_style: contentStyle,
                    toolbar: 'customzoom | undo redo | bold italic underline strikethrough | alignleft aligncenter alignright blockquote | bullist numlist | table | customTable ',
                    table_toolbar: 'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | applystroke',
                    contextmenu: 'paste | inserttable | cell row column deletetable',
                    resize: false,
                    // Only allow simple disc bullets and standard numbered lists
                    // See: https://www.tiny.cloud/docs/plugins/opensource/advlist/#advlist_bullet_styles
                    advlist_bullet_styles: 'default',
                    advlist_number_styles: 'default'
                } })),
        React.createElement("style", null, `
          .mceContentBody p {
            margin-bottom: 0;
            margin-top: 0;
          }
        
          .mce-notification {
            display: none !important;
          }
  `)));
});
export default MCE;
