var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { isColumnUser } from 'lib/helpers';
import { HomeIcon, Calendar, Branding, Members, Note, Rates, Template, CurrencyDollar, CurrencyPound, FolderDownload, InvoiceRemindersIcon, CustomersIcon, BulkInvoicesIcon } from './icons';
import NewspaperSettingsInfo from './NewspaperSettingsInfo';
import NewspaperSettingsTemplates from './publisher/templates';
import NewspaperSettingsRates from './publisher/rates';
import NewspaperSettingsPayment from './publisher/PaymentSettings';
import InvoiceReminders from './publisher/InvoiceReminders';
import Customers from './publisher/Customers';
import OrganizationMemberSettings from './OrganizationMemberSettings';
import AdvertisingDeadlinesSettings from './publisher/deadlines/AdvertisingDeadlinesSettings';
import FTPSettings from './publisher/upload';
import AffidavitSettings from './publisher/affidavits';
import CustomBrandSettings from './CustomBrandSettings';
import { getLocationParams } from '../../utils/urls';
import LoadingState from '../../components/LoadingState';
import { CurrencyType } from '../../lib/enums';
import BulkInvoicesSettings from './BulkInvoicesSettings';
const mapStateToProps = (state) => ({
    availableOrganizations: state.auth.availableOrganizations,
    activeOrganization: state.auth.activeOrganization
});
const drawerWidth = 35;
export const GENERAL_INFO = 'general-setting';
export const ADVERTISING_DEADLINES = 'advertising-deadlines';
export const ORGANIZATION_MEMBER_SETTINGS = 'member-settings';
export const PAYMENT = 'payment-settings';
export const PRICING_RESTRICTIONS = 'pricing-settings';
export const ORGANIZATION_AFFIDAVIT_SETTINGS = 'affidavit-settings';
export const CUSTOM_BRAND = 'custom-brand';
export const TEMPLATES = 'templates';
export const FTP = 'ftp';
export const INVOICE_REMINDERS = 'invoice-reminders';
export const CUSTOMERS = 'customers';
export const BULK_INVOICES = 'bulk-invoices';
const styles = (theme) => createStyles({
    root: {
        display: 'flex'
    },
    drawer: {
        width: theme.spacing(drawerWidth),
        flexShrink: 0
    },
    drawerPaper: {
        width: theme.spacing(drawerWidth)
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        paddingTop: '0'
    },
    toolbarBanner: {
        paddingTop: theme.spacing(12)
    },
    toolbar: theme.mixins.toolbar
});
const NewspaperSettings = (_a) => {
    var { availableOrganizations, activeOrganization, userAuth, classes, user, push } = _a, props = __rest(_a, ["availableOrganizations", "activeOrganization", "userAuth", "classes", "user", "push"]);
    const [view, setView] = useState(getLocationParams().get('tab') ||
        (userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO)
            ? GENERAL_INFO
            : ORGANIZATION_MEMBER_SETTINGS));
    const [hasBulkInvoices, setHasBulkInvoices] = useState();
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/organization/?tab=${view}`);
        setView(view);
    };
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            const invoicesQuery = yield Firebase.firestore()
                .collection(Collections.invoices)
                .where('isBulkInvoice', '==', true)
                .where('organization', '==', activeOrganization.ref)
                .get();
            setHasBulkInvoices(!invoicesQuery.empty);
        }))();
    }, []);
    const renderTab = (tabName, tabLabel, icon) => (React.createElement("div", { className: `flex rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tabName && 'bg-gray-200'}`, onClick: () => updateView(tabName), id: tabName },
        React.createElement("div", { className: "flex justify-center w-3/12" }, icon),
        React.createElement("div", { className: "w-9/12" }, tabLabel)));
    const renderSideBar = () => {
        var _a, _b;
        return (React.createElement("div", { className: "bg-white rounded border border-gray-300 pt-3 px-4", style: {
                height: 'calc(100vh - 150px)'
            } },
            userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
                renderTab(GENERAL_INFO, 'Newspaper Info', HomeIcon),
            activeOrganization.data().ftp &&
                renderTab(FTP, 'Statewide Site Uploads', FolderDownload),
            userHasPermission(user, Permissions.SETTINGS_DEADLINES) &&
                renderTab(ADVERTISING_DEADLINES, 'Advertising Deadlines', Calendar),
            user &&
                userHasPermission(user, Permissions.SETTINGS_CUSTOM_BRANDING) &&
                renderTab(CUSTOM_BRAND, 'Custom Branding', Branding),
            renderTab(ORGANIZATION_MEMBER_SETTINGS, 'Members', Members),
            userHasPermission(user, Permissions.SETTINGS_AFFIDAVITS) &&
                (!activeOrganization.data().affidavitDisabled ||
                    ((_a = activeOrganization.data().affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.affidavitsManagedByColumn)) &&
                renderTab(ORGANIZATION_AFFIDAVIT_SETTINGS, 'Affidavits', Note),
            userHasPermission(user, Permissions.SETTINGS_RATES) &&
                renderTab(PRICING_RESTRICTIONS, 'Rates', Rates),
            userHasPermission(user, Permissions.SETTINGS_TEMPLATES) &&
                isColumnUser(user) &&
                renderTab(TEMPLATES, 'Templates', Template),
            userHasPermission(user, Permissions.SETTINGS_PAYMENT) &&
                renderTab(PAYMENT, 'Payment Settings', ((_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.currency) === CurrencyType.gbp.key
                    ? CurrencyPound
                    : CurrencyDollar),
            userHasPermission(user, Permissions.SETTINGS_INVOICE_REMINDERS) &&
                renderTab(INVOICE_REMINDERS, 'Invoice Reminders', InvoiceRemindersIcon),
            userHasPermission(user, Permissions.SETTINGS_CUSTOMERS) &&
                renderTab(CUSTOMERS, 'Customers', CustomersIcon),
            hasBulkInvoices &&
                userHasPermission(user, Permissions.SETTINGS_BULK_INVOICES) &&
                renderTab(BULK_INVOICES, 'Bulk Invoices', BulkInvoicesIcon)));
    };
    if (!activeOrganization)
        return React.createElement(LoadingState, null);
    // TODO: handle super
    return (React.createElement("div", { className: classes.root },
        renderSideBar(),
        React.createElement("main", { className: classes.content },
            React.createElement("div", null, {
                [CUSTOM_BRAND]: (React.createElement(CustomBrandSettings, Object.assign({ activeOrganization: activeOrganization, user: user }, props))),
                [FTP]: (React.createElement(FTPSettings, { availableOrganizations: availableOrganizations, activeOrganization: activeOrganization })),
                [ADVERTISING_DEADLINES]: (React.createElement(AdvertisingDeadlinesSettings, Object.assign({ activeOrganization: activeOrganization }, props))),
                [ORGANIZATION_MEMBER_SETTINGS]: (React.createElement(OrganizationMemberSettings, Object.assign({ activeOrganization: activeOrganization, user: user }, props))),
                [GENERAL_INFO]: (React.createElement(NewspaperSettingsInfo, Object.assign({ activeOrganization: activeOrganization }, props))),
                [ORGANIZATION_AFFIDAVIT_SETTINGS]: (React.createElement(AffidavitSettings, { activeOrganization: activeOrganization, user: user })),
                [PRICING_RESTRICTIONS]: (React.createElement(NewspaperSettingsRates, { activeOrganization: activeOrganization, user: user })),
                [TEMPLATES]: (React.createElement(NewspaperSettingsTemplates, { activeOrganization: activeOrganization })),
                [PAYMENT]: (React.createElement(NewspaperSettingsPayment, Object.assign({ activeOrganization: activeOrganization, userAuth: userAuth, push: push, user: user }, props))),
                [INVOICE_REMINDERS]: (React.createElement(InvoiceReminders, Object.assign({ activeOrganization: activeOrganization }, props))),
                [CUSTOMERS]: (React.createElement(Customers, Object.assign({ activeOrganization: activeOrganization }, props))),
                [BULK_INVOICES]: (React.createElement(BulkInvoicesSettings, Object.assign({ activeOrganization: activeOrganization }, props)))
            }[view]))));
};
export default connect(mapStateToProps, { push })(withStyles(styles)(NewspaperSettings));
