var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TextField, Typography } from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';
import CurrencyFormatter from 'components/CurrencyFormatter';
import { RateType } from 'lib/enums';
class RatesTableCell extends React.Component {
    constructor() {
        super(...arguments);
        this.handleClickCell = (e) => {
            if (this.props.columnDef.disableClick) {
                e.stopPropagation();
            }
        };
        this.getStyle = () => {
            let cellStyle = {};
            if (typeof this.props.columnDef.cellStyle === 'function') {
                cellStyle = Object.assign(Object.assign({}, cellStyle), this.props.columnDef.cellStyle(this.props.value, this.props.rowData));
            }
            else {
                cellStyle = Object.assign(Object.assign({}, cellStyle), this.props.columnDef.cellStyle);
            }
            if (this.props.columnDef.disableClick) {
                cellStyle.cursor = 'default';
            }
            return Object.assign(Object.assign({}, this.props.style), cellStyle);
        };
    }
    getRoundOffField(rowData) {
        if (rowData.roundOff)
            return rowData.roundOff;
        return 'n/a';
    }
    getRenderValue() {
        const rate = parseInt(this.props.rowData.rateType, 10);
        if ((this.props.columnDef.field === 'rate_1' ||
            this.props.columnDef.field === 'rate_2') &&
            rate === RateType.per_run.value)
            return 'n/a';
        if ((this.props.columnDef.field === 'rate_0' ||
            this.props.columnDef.field === 'rate_1' ||
            this.props.columnDef.field === 'rate_2') &&
            rate === RateType.berthoud_government.value)
            return 'n/a';
        if ((this.props.columnDef.field === 'rate_0' ||
            this.props.columnDef.field === 'rate_1' ||
            this.props.columnDef.field === 'rate_2' ||
            this.props.columnDef.field === 'minimum') &&
            rate === RateType.battle_born.value) {
            return 'n/a';
        }
        if (this.props.columnDef.emptyValue !== undefined &&
            (this.props.value === undefined || this.props.value === null)) {
            return this.getEmptyValue(this.props.columnDef.emptyValue);
        }
        if (this.props.columnDef.render) {
            if (this.props.rowData) {
                return this.props.columnDef.render(this.props.rowData, 'row');
            }
            return this.props.columnDef.render(this.props.value, 'group');
        }
        if (this.props.columnDef.type === 'date') {
            if (this.props.value instanceof Date) {
                return this.props.value.toLocaleDateString();
            }
            return this.props.value;
        }
        if (this.props.columnDef.type === 'currency') {
            return this.getEnoticeCurrency(this.props.value);
        }
        if (this.props.columnDef.field === 'confirmed') {
            return this.getConfirmedCell(this.props.value);
        }
        if (this.props.columnDef.field === 'code') {
            return this.getRateCodeCell(this.props.value);
        }
        if (this.props.columnDef.field === 'roundOff') {
            return this.getRoundOffField(this.props.rowData);
        }
        if (this.props.columnDef.type === 'boolean') {
            const style = { textAlign: 'left', verticalAlign: 'middle', width: 48 };
            if (this.props.value) {
                return React.createElement(this.props.icons.Check, { style: style });
            }
            return React.createElement(this.props.icons.ThirdStateCheck, { style: style });
        }
        return this.props.value;
    }
    getEnoticeCurrency(value) {
        return (React.createElement(TextField, { value: value, onChange: () => { }, id: "formatted-numberformat-input", style: { color: 'black' }, InputProps: {
                style: {
                    pointerEvents: 'none'
                },
                disableUnderline: true,
                inputComponent: CurrencyFormatter
            } }));
    }
    getRateCodeCell(value) {
        return React.createElement(Typography, null, value);
    }
    getConfirmedCell(value) {
        const { classes } = this.props;
        return value ? (React.createElement(CheckCircle, { className: classes.checkIcon })) : (React.createElement(Error, { className: classes.warning }));
    }
    getEmptyValue(emptyValue) {
        if (typeof emptyValue === 'function') {
            return this.props.columnDef.emptyValue(this.props.rowData);
        }
        return emptyValue;
    }
    render() {
        const _a = this.props, { icons, columnDef, rowData, classes } = _a, cellProps = __rest(_a, ["icons", "columnDef", "rowData", "classes"]);
        return (React.createElement(TableCell, Object.assign({}, cellProps, { style: this.getStyle(), align: ['numeric'].indexOf(this.props.columnDef.type) !== -1
                ? 'right'
                : 'left', onClick: this.handleClickCell }),
            this.props.children,
            this.getRenderValue()));
    }
}
RatesTableCell.defaultProps = {
    columnDef: {},
    value: undefined
};
export default RatesTableCell;
