import React from 'react';
import { getOrganizationIconColors } from 'components/modals/JoinOrganizationModals/helpers';
import { BuildingIcon, TickIcon } from 'icons';
import { State } from 'lib/enums';
export default function SearchedOrganizationsListItem({ organizationSnap, onItemSelected, selected, index }) {
    var _a;
    const iconStyles = getOrganizationIconColors(index);
    return (React.createElement("div", { className: "flex justify-between items-center py-2 px-2" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` },
                React.createElement(BuildingIcon, { className: "m-auto transform translate-y-2", stroke: iconStyles.stroke })),
            React.createElement("div", { className: "pl-2" },
                React.createElement("p", { className: "font-medium\ttext-sm\ttext-gray-800" }, organizationSnap.name),
                React.createElement("p", { className: "text-gray-600 text-sm leading-400" }, `${(_a = State.by_value(organizationSnap.state)) === null || _a === void 0 ? void 0 : _a.label}, ${organizationSnap.city}`))),
        React.createElement("div", null, selected ? (React.createElement("div", { className: "h-8 w-24 bg-green-150 rounded-full items-center justify-center flex" },
            React.createElement("div", { className: "flex text-xs font-medium leading-5 text-green-600 items-center " },
                React.createElement("p", { className: "mr-2" }, "Selected"),
                React.createElement(TickIcon, null)))) : (React.createElement("button", { id: `ask-join-org-${index}`, className: "bg-blue-50 font-medium text-sm rounded-md transition duration-300 ease-in-out border w-128 hover:bg-blue-100 text-blue-500 hover:text-blue-600 border-blue-500 focus:shadow-outline-blue focus:border-blue-50 w-full py-1 px-2", onClick: () => {
                const updatedObj = {
                    value: organizationSnap.id,
                    label: organizationSnap.name
                };
                onItemSelected(updatedObj);
            } }, "Ask to Join")))));
}
