import React, { useEffect, useState } from 'react';
import { WarningOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { ColumnButton } from 'components/ColumnButton';
/**
 * Represents a single row in our automated affidavit upload table
 */
function AffidavitUploadDayTableRow({ setDrawerUploadDay, uploadDay }) {
    const uploadMethods = [
        ...new Set(uploadDay.pdfsUploaded.map(pdf => pdf.uploadMethod)).values()
    ];
    const uploadDayHasEEdition = uploadMethods.length > 0;
    return (React.createElement("tr", { className: "hover:bg-gray-50 cursor-pointer h-20 text-sm leading-6 font-medium text-gray-650", onClick: () => {
            setDrawerUploadDay(uploadDay);
        } },
        React.createElement("td", { className: "pl-7 w-48 overflow-scroll" }, uploadDayHasEEdition ? (React.createElement("span", null, uploadDay.publishingDateString)) : (React.createElement(Tooltip, { title: "Missing e-edition" },
            React.createElement("div", { className: "flex items-center" },
                React.createElement(WarningOutlined, { className: "text-red-400 mr-2" }),
                React.createElement("span", null, uploadDay.publishingDateString))))),
        React.createElement("td", { className: "pl-7" }, uploadDayHasEEdition ? uploadMethods.join(', ') : '--'),
        React.createElement("td", { className: "pl-7 w-81 overflow-scroll" },
            React.createElement("div", { className: "flex items-center" },
                React.createElement("span", { className: classNames('text-gray-600 max-w-64', {
                        underline: uploadDayHasEEdition
                    }) }, uploadDay.pdfsUploaded.length
                    ? uploadDay.pdfsUploaded[0].pdfName
                    : '--'),
                uploadDay.pdfsUploaded.length > 1 && (React.createElement("span", { className: "pl-2 text-gray-600" },
                    ' ',
                    "+ ",
                    uploadDay.pdfsUploaded.length - 1,
                    " more")))),
        React.createElement("td", { className: "text-center" }, uploadDayHasEEdition ? uploadDay.noticesValidated.length : '--'),
        React.createElement("td", { className: "text-center" }, uploadDayHasEEdition ? uploadDay.noticesNotValidated.length : '--')));
}
/**
 * Table of e-edition uploads for review in publisher settings
 */
export default function AffidavitUploadDayTable({ setDrawerUploadDay, uploadFilter, uploadDays }) {
    const [pageNumber, setPageNumber] = useState(0);
    // Reset the page number when we change the search
    useEffect(() => {
        setPageNumber(0);
    }, [uploadFilter]);
    const PAGE_SIZE = 5;
    const uploadDayPage = uploadDays.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    const totalPages = Math.ceil(uploadDays.length / PAGE_SIZE);
    return (React.createElement("div", { id: "affidavit-upload-day-table" },
        React.createElement("table", { id: "affidavit-upload-day-table", className: "min-w-full divide-y divide-gray-200" },
            React.createElement("thead", { className: "h-16 bg-gray-50 font-medium text-left uppercase text-gray-650 text-sm tracking-wider" },
                React.createElement("tr", null,
                    React.createElement("th", { className: "pl-7" }, "Publication Date"),
                    React.createElement("th", { className: "pl-7" }, "Method"),
                    React.createElement("th", { className: "pl-7" }, "File Name"),
                    React.createElement("th", { className: "text-center" }, "Verified Notices"),
                    React.createElement("th", { className: "text-center" }, "Unverified Notices"))),
            React.createElement("tbody", null, uploadDayPage.map((uploadDay, index) => (React.createElement(AffidavitUploadDayTableRow, { setDrawerUploadDay: setDrawerUploadDay, uploadDay: uploadDay, key: index }))))),
        React.createElement("footer", { className: "bg-white pr-5 py-0.5 border-t border-b border-gray-300 rounded-b-md" },
            React.createElement("div", { className: "pl-6 py-3.5 flex justify-between" },
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Previous", disabled: pageNumber === 0, onClick: () => setPageNumber(pageNumber - 1) })),
                React.createElement("div", { className: "py-2.5 leading-6 text-sm font-medium text-grey-400" },
                    "Page ",
                    pageNumber + 1,
                    " of ",
                    totalPages),
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Next", disabled: pageNumber === totalPages - 1, onClick: () => setPageNumber(pageNumber + 1) }))))));
}
