import React, { useState, useEffect } from 'react';
import EToast from 'components/EToast';
import TailwindModal from 'components/TailwindModal';
import { useTranslation } from 'react-i18next';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { AddIcon } from 'icons';
import CreateInvitesFormContainer from '../invites/CreateInvitesFormContainer';
import OrgSettingsMemberTable from './OrgSettingsMemberTable';
import SettingsHeader from './SettingsHeader';
const OrganizationMemberSettings = ({ user, activeOrganization }) => {
    const [showInviteForm, setShowInviteForm] = useState(false);
    const [InviteFormHeader, setInviteFormHeader] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        const timer = setTimeout(() => {
            setToastMessage('');
        }, 3000);
        return () => clearTimeout(timer);
    }, [toastMessage]);
    return (React.createElement("div", { className: "bg-white border box-border rounded-lg" },
        React.createElement(SettingsHeader, { header: t('member-settings.title'), description: t('member-settings.subtitle') }, userHasPermission(user, Permissions.ORGANIZATION_MODIFY_MEMBERS) && (React.createElement("button", { id: "invite", type: "button", className: "px-3 bg-white box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => {
                setShowInviteForm(true);
                setInviteFormHeader(t('member-settings.invite-header'));
            } },
            React.createElement("span", { className: "pr-2" },
                React.createElement(AddIcon, { className: "h-6 w-6" })),
            "Invite Additional"))),
        React.createElement(OrgSettingsMemberTable, { activeOrganization: activeOrganization, setRefresh: setRefresh, refresh: refresh, user: user, setToastMessage: setToastMessage }),
        showInviteForm && (React.createElement(TailwindModal, { header: InviteFormHeader, close: () => setShowInviteForm(false) },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "w-full items-center" },
                    React.createElement(CreateInvitesFormContainer, { organization: activeOrganization, user: user.data(), setToastMessage: setToastMessage, setShowInviteForm: setShowInviteForm, setInviteFormHeader: setInviteFormHeader }))))),
        toastMessage && (React.createElement(EToast, { message: toastMessage, type: "success", close: () => setToastMessage('') }))));
};
export default OrganizationMemberSettings;
