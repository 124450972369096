import React, { useState } from 'react';
import classNames from 'classnames';
import { ColumnButton } from 'components/ColumnButton';
import { rateDataSupportsDisplay } from '../ratesTableSettingsUtils';
import BasicPropertiesTab from './BasicPropertiesTab';
import AdditionalFeesTab from './AdditionalFeesTab';
import RateDrawerTabs, { ADDITIONAL_FEES_TAB, RATE_PROPERTIES_TAB } from './RateDrawerTabs';
/**
 * Main content displayed in the drawer for affidavit e-edition upload events
 */
export default function RateDrawerBody({ activeOrganization, setEditedRate, rate }) {
    const [rateDrawerTab, setRateDrawerTab] = useState(RATE_PROPERTIES_TAB);
    const canBeUsedAsDisplayRate = rateDataSupportsDisplay(rate.data());
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "border-b flex" },
            React.createElement(RateDrawerTabs, { setRateDrawerTab: setRateDrawerTab, rateDrawerTab: rateDrawerTab })),
        React.createElement("div", { className: "px-4 overflow-scroll flex-1" },
            rateDrawerTab === RATE_PROPERTIES_TAB && (React.createElement(BasicPropertiesTab, { rate: rate })),
            rateDrawerTab === ADDITIONAL_FEES_TAB && (React.createElement(AdditionalFeesTab, { rate: rate }))),
        React.createElement("div", { className: classNames('absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between') },
            activeOrganization.data().defaultLinerRate.id !== rate.id && (React.createElement(ColumnButton, { buttonText: "Set as Default Liner Rate", secondary: true, size: "lg", onClick: () => activeOrganization.ref.update({
                    defaultLinerRate: rate.ref
                }) })),
            activeOrganization.data().defaultDisplayRate.id !== rate.id && (React.createElement(ColumnButton, { buttonText: "Set as Default Display", disabled: !canBeUsedAsDisplayRate, secondary: true, size: "lg", onClick: () => activeOrganization.ref.update({
                    defaultDisplayRate: rate.ref
                }) })),
            React.createElement(ColumnButton, { id: "edit-rate", primary: true, size: "lg", buttonText: "Edit", onClick: () => setEditedRate(rate) }))));
}
