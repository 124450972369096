import React from 'react';
import { OpenMailboxWithLoweredFlag } from 'emojis';
const EmptyNotificationTray = () => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex items-center justify-center" },
            React.createElement("div", { className: "w-24 h-24 rounded-full bg-blue-200 p-2" },
                React.createElement("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/0ec9.8b27b-buddy-11.gif" }))),
        React.createElement("p", { className: "mt-8 text-base font-semibold text-gray-850 flex items-center justify-center" },
            "It's a clear day over here \u00A0",
            React.createElement(OpenMailboxWithLoweredFlag, null)),
        React.createElement("p", { className: "mt-2 font-medium text-xs text-gray-650 flex items-center justify-center" }, "You don\u2019t have any notifications.")));
};
export default EmptyNotificationTray;
