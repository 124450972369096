var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { removeUndefinedFields, shouldBulkInvoiceUser } from 'lib/helpers';
import { State, BillingStatusType, InvoiceStatus } from 'lib/enums';
import { getUserStripeId } from 'lib/utils/users';
import { INVOICE_CREATION_INITIATED } from 'lib/types/events';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { Firestore } from '../../EnoticeFirebase';
import { STRIPE_VARS, ENOTICE_ACCOUNT_ID } from '../../constants';
import { getLocationParams } from '../../utils/urls';
const mapEnumToState = (stateEnum) => {
    const item = State.items().find(item => item.value === stateEnum);
    return item.label;
};
const getAddressObj = (advertiser) => {
    if (!advertiser.address)
        return {};
    return {
        line1: advertiser.address,
        city: advertiser.city,
        state: mapEnumToState(advertiser.state),
        postal_code: advertiser.zipCode
    };
};
const createUserStripeId = (advertiserSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const advertiser = advertiserSnap.data();
    const createCustomer = getCreateCustomerFn();
    const createCustomerObj = {
        name: advertiser.organizationName ||
            (advertiser.name
                ? advertiser.name
                : `${advertiser.firstName} ${advertiser.lastName}`),
        email: advertiser.email,
        address: getAddressObj(advertiser),
        firestoreUID: advertiserSnap.id
    };
    const result = yield createCustomer(createCustomerObj);
    if (!result.data.success) {
        throw new Error(result.data.error.message);
    }
    return result.data.stripeId;
});
const getOrCreateUserStripeId = (advertiserSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const stripeId = yield getUserStripeId(advertiserSnap);
    if (!stripeId) {
        return yield createUserStripeId(advertiserSnap);
    }
    return stripeId;
});
const getStatusEnum = (userNoticeSnap, invoiceSnap, isPublisher) => __awaiter(void 0, void 0, void 0, function* () {
    const { transfer } = userNoticeSnap.data();
    if (!invoiceSnap)
        return BillingStatusType.invoice_not_submitted;
    const isInvoicedOutsideColumn = invoiceSnap.data().invoiceOutsideColumn;
    if (invoiceSnap && !transfer) {
        if (!exists(invoiceSnap))
            return BillingStatusType.invoice_not_submitted;
        if (isInvoicedOutsideColumn)
            return BillingStatusType.invoiced_outside_column;
        if (invoiceSnap.data().status === InvoiceStatus.unpaid.value)
            return BillingStatusType.invoice_submitted_to_advertiser;
        if ([
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(invoiceSnap.data().status))
            return BillingStatusType.invoice_paid_by_advertiser;
        if (invoiceSnap.data().status === InvoiceStatus.initiated.value)
            return BillingStatusType.payment_initiated;
        if (invoiceSnap.data().status === InvoiceStatus.payment_failed.value)
            return BillingStatusType.payment_failed;
    }
    if (invoiceSnap.data().status === InvoiceStatus.refunded.value)
        return BillingStatusType.payment_refunded;
    if (!isPublisher && transfer) {
        if (isInvoicedOutsideColumn)
            return BillingStatusType.invoiced_outside_column;
        return BillingStatusType.invoice_paid_by_advertiser;
    }
    if (isPublisher && transfer) {
        if (isInvoicedOutsideColumn)
            return BillingStatusType.invoiced_outside_column;
        return BillingStatusType.transfer_created;
    }
    return BillingStatusType.invoice_not_submitted;
});
const getBillingData = ({ userNoticeSnap, isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { invoice } = userNoticeSnap.data();
    const invoiceSnap = (yield (invoice === null || invoice === void 0 ? void 0 : invoice.get()));
    const statusEnum = yield getStatusEnum(userNoticeSnap, invoiceSnap, isPublisher);
    return {
        statusEnum,
        invoiceNumber: (_a = invoiceSnap === null || invoiceSnap === void 0 ? void 0 : invoiceSnap.data()) === null || _a === void 0 ? void 0 : _a.invoice_number
    };
});
export const getStripeIdIfNotExists = (noticeSnap, advertiserSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    // We prefer the stripe ID on the notice filedBy organization but fall
    // back onto the user.
    const filedByOrganization = yield ((_b = noticeSnap.data().filedBy) === null || _b === void 0 ? void 0 : _b.get());
    let stripeId = (_c = filedByOrganization === null || filedByOrganization === void 0 ? void 0 : filedByOrganization.data()) === null || _c === void 0 ? void 0 : _c.stripeId;
    if (!stripeId) {
        stripeId = yield getOrCreateUserStripeId(advertiserSnap);
    }
    return stripeId;
});
const invoiceAdvertiser = ({ inAppInvoicedAmt, stripeLineItems, inAppLineItems, inAppTaxPct, noticeSnap, advertiserSnap, newspaperSnap, customId, customMemo, dueDate, requireUpfrontPayment, invoiceOutsideColumn, user }) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e;
    const stripeId = yield getStripeIdIfNotExists(noticeSnap, advertiserSnap);
    yield noticeSnap.ref.update({
        requireUpfrontPayment: !!requireUpfrontPayment
    });
    const isWithinBulkInvoice = yield shouldBulkInvoiceUser(getFirebaseContext(), noticeSnap, newspaperSnap);
    const parentOrg = yield ((_d = newspaperSnap.data().parent) === null || _d === void 0 ? void 0 : _d.get());
    const bulkInvoiceByPubDate = newspaperSnap.data().bulkInvoiceByPubDate ||
        ((_e = parentOrg === null || parentOrg === void 0 ? void 0 : parentOrg.data()) === null || _e === void 0 ? void 0 : _e.bulkInvoiceByPubDate);
    if (isWithinBulkInvoice) {
        yield noticeSnap.ref.update({
            isWithinBulkInvoice: true,
            bulkInvoiceByPubDate: !!bulkInvoiceByPubDate
        });
    }
    const invoiceData = {
        customer: stripeId,
        inAppLineItems,
        stripeLineItems,
        inAppInvoicedAmt,
        inAppTaxPct,
        userNoticeId: noticeSnap.id,
        organizationId: newspaperSnap.id,
        advertiserId: advertiserSnap.id,
        customId,
        customMemo,
        publisherId: customId || '',
        dueDate,
        isWithinBulkInvoice,
        invoiceOutsideColumn,
        createdBy: user && user.ref
    };
    removeUndefinedFields(invoiceData);
    yield getFirebaseContext()
        .eventsRef()
        .add({
        type: INVOICE_CREATION_INITIATED,
        notice: noticeSnap.ref,
        data: invoiceData,
        createdAt: Firestore.FieldValue.serverTimestamp()
    });
});
export const handleOAuth = (user, push) => __awaiter(void 0, void 0, void 0, function* () {
    if (!user.activeOrganization) {
        throw new Error('User has no activeOrganization');
    }
    const activeOrg = yield user.activeOrganization.get();
    if (exists(activeOrg) &&
        activeOrg.data().accountId &&
        activeOrg.data().accountId !== ENOTICE_ACCOUNT_ID) {
        push('/');
        return;
    }
    const error = getLocationParams().get('error');
    if (error) {
        throw new Error(error);
    }
    const code = getLocationParams().get('code');
    if (!code || code === 'loading')
        return window.location.replace(STRIPE_VARS.oauthUrl);
    // Fetch account id from stripe
    try {
        const { success, error, accountId } = yield api.post('payments/update-connected-account', {
            code
        });
        if (!success) {
            console.error(error);
            return;
        }
        yield activeOrg.ref.update({
            accountId
        });
    }
    catch (err) {
        console.error('Failed to fetch account id from stripe. Error:', err);
    }
    push('/');
});
const BillingFunctions = {
    invoiceAdvertiser,
    getBillingData,
    handleOAuth,
    getStatusEnum
};
export default BillingFunctions;
