export const AdTypesData = {
    draft: {
        value: 1,
        label: 'Interim Ad Draft',
        key: 'ad_draft'
    },
    proof: {
        value: 2,
        label: 'Official Ad Proof',
        key: 'ad_proof'
    }
};
