var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import classNames from 'classnames';
import { html, css, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { live } from 'lit/directives/live.js';
import { ColorsCss } from './styles';
/**
 * Custom webcomponent representing a multi-line inline input
 * element with Madlibs styling.
 *
 *   <madlib-input
 *     key="..."
 *     placeholder="..."
 *     hint="..."
 *     value="..."
 *   ></madlib-input>
 */
export class MadlibInput extends LitElement {
    constructor() {
        super(...arguments);
        this.key = undefined;
        this.placeholder = undefined;
        this.hint = '';
        this.value = '';
        this._dirty = false;
    }
    _getEditable() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.editable');
    }
    _handleInput(e) {
        // TODO: Do not allow formatted input (copy-paste, ctrl+b, etc
        this.value = e.target.innerText || '';
        // Dispatch event that the template has changed and we should re-render the result
        const event = new CustomEvent('template-updated', {
            bubbles: true,
            composed: true
        });
        this.dispatchEvent(event);
        // The first non-empty input event makes this "dirty" (has been edited)
        if (this.value) {
            this._dirty = true;
        }
    }
    _handleBlur() {
        // TODO: Custom validations
        const isValid = !!this.value;
        if (!isValid && this._dirty) {
            this._error = 'Missing value';
        }
        else {
            this._error = undefined;
        }
    }
    /**
     * When the placeholder is clicked, focus the editable element
     */
    _handlePlaceholderClick() {
        var _a;
        (_a = this._getEditable()) === null || _a === void 0 ? void 0 : _a.focus();
    }
    render() {
        const editableClass = classNames({
            valid: !this._error
        });
        const placeholderHtml = !this.value
            ? html `<span class="placeholder" @click=${this._handlePlaceholderClick}
          >${this.placeholder}</span
        >`
            : undefined;
        const wrapperClass = classNames({
            error: !!this._error
        });
        return html `<span class="input_text ${wrapperClass}">
      <madlib-hint text="${this.hint}"></madlib-hint>
      <span
        class="editable ${editableClass}"
        contenteditable="true"
        spellcheck="false"
        @input=${this._handleInput}
        @blur=${this._handleBlur}
        .innerText=${live(this.value)}
      ></span
      >${placeholderHtml}
    </span>`;
    }
}
MadlibInput.TAG_NAME = 'madlib-input';
MadlibInput.styles = css `
    .input_text {
      margin: 0;
      padding: 0;
    }

    .input_text:focus-within .editable,
    .input_text:focus-within .placeholder {
      border-bottom: 1px solid #63a4f4;
    }

    .input_text.error .editable,
    .input_text.error .placeholder {
      border-bottom: 1px solid rgb(255, 125, 125);
    }

    .input_text.error .placeholder {
      color: rgb(255, 125, 125);
    }

    .editable {
      min-width: 30px;
    }

    .editable:focus {
      outline: none;
    }

    .editable.valid {
      border-bottom: 1px solid ${ColorsCss.DARK_BLUE};
    }

    .editable.invalid {
      border-bottom: 1px solid #ff0000;
    }

    .placeholder {
      font-weight: 500;
      color: ${ColorsCss.LIGHT_BLUE};
    }
  `;
__decorate([
    property()
], MadlibInput.prototype, "key", void 0);
__decorate([
    property()
], MadlibInput.prototype, "placeholder", void 0);
__decorate([
    property()
], MadlibInput.prototype, "hint", void 0);
__decorate([
    property()
], MadlibInput.prototype, "value", void 0);
__decorate([
    state()
], MadlibInput.prototype, "_dirty", void 0);
__decorate([
    state()
], MadlibInput.prototype, "_error", void 0);
