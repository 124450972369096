var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ErrorOutline, Help } from '@material-ui/icons';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { exists } from 'lib/types';
import moment from 'moment';
import Tooltip from 'components/Tooltip';
import { CTable } from 'components/table';
import { CheckIcon, ClockIcon, CopyIcon, DeleteIcon, CheckRoundedIcon, InvalidRoundedIcon } from 'icons';
import { duplicateNotice } from 'utils/duplicateNotice';
import { logAndCaptureException } from 'utils';
import { AffidavitStatusType, BillingStatusType } from 'lib/enums';
import DeleteDraftModal from './DeleteDraftModal';
import { PUBLISHER_BILLING_STATUS, ADVERTISER_BILLING_STATUS, AFFIDAVIT_STATUS, CONFIRMED, PUBLICATION_DATES, NEWSPAPER_NAME, ARCHIVED_NOTICES_TAB, DRAFT_NOTICES_TAB, ACTIVE_NOTICES_TAB } from './types';
const YourNoticesTable = ({ notices, title, loading, isPublisher, actions, showAllOrgsNotices, id, rowsPerNoticeTablePage, push, user, setSort, setCurrentPage, setPageSize, setTab, searchValue, setSearchValue, total, currentPage, setShowUserOnlyNotices, showUserOnlyNotices, sort }) => {
    const [noticeToDelete, setNoticeToDelete] = useState();
    const handleClose = () => {
        setNoticeToDelete(undefined);
    };
    const cellStyle = 'w-full h-full block px-6 py-4 cursor-pointer text-sm';
    const isDraft = !!(id === 'draft-notices-table');
    const confirmedUI = ({ row }) => {
        if (isDraft)
            return '';
        const { original } = row;
        const { iscancelled } = original;
        return (React.createElement("span", { className: cellStyle },
            React.createElement("span", { className: "mr-5 block" }, iscancelled ? (React.createElement("span", { className: "billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-gray-200 text-gray-800" }, "Cancelled")) : original.isconfirmed ? (React.createElement(React.Fragment, null,
                React.createElement(CheckRoundedIcon, { className: "text-blue-600 h-6" }),
                React.createElement("p", { className: "hidden" }, "true"))) : (React.createElement(React.Fragment, null,
                React.createElement(InvalidRoundedIcon, { className: "text-gray-800 h-6" }),
                React.createElement("p", { className: "hidden" }, "false"))))));
    };
    const billingStatusUI = ({ row }) => {
        var _a, _b, _c;
        if (isDraft)
            return '';
        const { original } = row;
        const { requireupfrontpayment, publisherbillingstatusvalue, advertiserbillingstatusvalue, publisherbillingstatus, advertiserbillingstatus, iscancelled } = original;
        const bg = {
            yellow: 'bg-opacity-50 bg-yellow-500',
            gray: 'bg-gray-200',
            red: 'bg-red-200',
            indigo: 'bg-indigo-200',
            blue: 'bg-blue-200 bg-opacity-50'
        };
        const icons = {
            check: React.createElement(CheckIcon, { className: "p-0.25 pl-0 px-1" }),
            waiting: React.createElement(ClockIcon, { className: "p-1 pl-0" }),
            failed: React.createElement(ErrorOutline, { className: "p-0.5 pl-0" })
        };
        const text = {
            yellow: 'text-yellow-900',
            gray: 'text-gray-800',
            red: 'text-red-800',
            indigo: 'text-indigo-800',
            blue: 'text-blue-800'
        };
        let billingStatus = isPublisher
            ? publisherbillingstatusvalue
            : advertiserbillingstatusvalue;
        if (!billingStatus) {
            billingStatus = isPublisher
                ? (_a = BillingStatusType.by_label(publisherbillingstatus)) === null || _a === void 0 ? void 0 : _a.value
                : (_b = BillingStatusType.by_label(advertiserbillingstatus)) === null || _b === void 0 ? void 0 : _b.value;
        }
        let statusText = !iscancelled
            ? (_c = BillingStatusType.by_value(billingStatus)) === null || _c === void 0 ? void 0 : _c.label
            : 'Cancelled';
        let statusIcon = !iscancelled ? icons.waiting : null;
        let chipColor = bg.gray;
        let textColor = text.gray;
        if (!iscancelled)
            switch (billingStatus) {
                case BillingStatusType.invoice_submitted_to_advertiser.value:
                    statusText = isPublisher ? 'Awaiting Client Payment' : 'Unpaid';
                    statusIcon = isPublisher ? null : statusIcon;
                    chipColor = isPublisher ? bg.gray : bg.yellow;
                    textColor = isPublisher ? text.gray : text.yellow;
                    break;
                case BillingStatusType.invoice_paid_by_advertiser.value:
                    statusText = isPublisher ? 'Invoice Paid by Client' : 'Invoice Paid';
                    statusIcon = icons.check;
                    chipColor = bg.indigo;
                    textColor = text.indigo;
                    break;
                case BillingStatusType.transfer_created.value:
                    statusIcon = icons.check;
                    chipColor = bg.indigo;
                    textColor = text.indigo;
                    break;
                case BillingStatusType.payment_initiated.value:
                    statusText = BillingStatusType.payment_initiated.label;
                    statusIcon = icons.check;
                    chipColor = bg.blue;
                    textColor = text.blue;
                    break;
                case BillingStatusType.payment_failed.value:
                    statusText = BillingStatusType.payment_failed.label;
                    chipColor = bg.red;
                    statusIcon = icons.failed;
                    textColor = text.red;
                    break;
                case BillingStatusType.invoiced_outside_column.value:
                    statusText = 'Outside of Column';
                    chipColor = bg.gray;
                    statusIcon = null;
                    textColor = text.gray;
                    break;
                case BillingStatusType.invoice_not_submitted.value:
                default:
                    statusText = BillingStatusType.invoice_not_submitted.label;
                    statusIcon = isPublisher ? statusIcon : null;
                    chipColor = isPublisher ? bg.yellow : bg.gray;
                    textColor = isPublisher ? text.yellow : text.gray;
                    break;
            }
        return (React.createElement("span", { className: `${cellStyle} flex items-center` },
            React.createElement("span", { className: `billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold ${chipColor} ${textColor}` },
                statusIcon,
                " ",
                statusText),
            billingStatus ===
                BillingStatusType.invoice_submitted_to_advertiser.value &&
                requireupfrontpayment &&
                !iscancelled ? (React.createElement(Tooltip, { helpText: "Upfront payment is required for this notice.", classes: "w-32" },
                React.createElement(InvalidRoundedIcon, { className: "w-4 ml-1 text-red-550" }))) : (billingStatus === BillingStatusType.payment_initiated.value && (React.createElement(Tooltip, { helpText: "Payment is currently processing and should be complete in a few days.", classes: "w-32" },
                React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" }))))));
    };
    const affidavitSubmittedUI = ({ row }) => {
        if (isDraft)
            return '';
        const { iscancelled, affidavitsubmitted, affidavitdisabled, requireemailaffidavit, mailaffidavitsoutsidecolumn, publicationtimestamps } = row.original;
        const bg = {
            yellow: 'bg-opacity-50 bg-yellow-500',
            gray: 'bg-gray-200',
            red: 'bg-red-200',
            indigo: 'bg-indigo-200'
        };
        const icons = {
            check: React.createElement(CheckIcon, { className: "p-0.25 pl-0 px-1" }),
            waiting: React.createElement(ClockIcon, { className: "p-1 pl-0" })
        };
        const text = {
            yellow: 'text-yellow-900',
            gray: 'text-gray-800',
            red: 'text-red-800',
            indigo: 'text-indigo-800'
        };
        const statusIcon = !iscancelled ? icons.waiting : null;
        let affidavit;
        // TODO: should be removed after releasing BACKEND-298
        const beforeLastPubDate = moment().isBefore(publicationtimestamps &&
            moment(publicationtimestamps[publicationtimestamps.length - 1]));
        if (iscancelled) {
            return (React.createElement("span", { className: cellStyle },
                React.createElement("span", { className: "billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-gray-200 text-gray-800" }, "Cancelled")));
        }
        if (affidavitdisabled) {
            return (React.createElement("span", { className: cellStyle },
                React.createElement("span", { className: "billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-gray-200 text-gray-800" }, AffidavitStatusType.affidavit_disabled.label)));
        }
        if (affidavitsubmitted) {
            affidavit = (React.createElement("span", { className: `billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold ${bg.indigo} ${text.indigo}` },
                icons.check,
                " ",
                AffidavitStatusType.submitted.label));
        }
        if (!affidavitsubmitted && beforeLastPubDate) {
            affidavit = (React.createElement("span", { className: `billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold ${bg.gray} ${text.gray}` }, AffidavitStatusType.awaiting_publication.label));
        }
        if (isPublisher && !affidavitsubmitted && !beforeLastPubDate) {
            affidavit = (React.createElement("span", { className: `billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold ${bg.yellow} ${text.gray}` },
                statusIcon,
                AffidavitStatusType.incomplete.label));
        }
        if (!isPublisher && !affidavitsubmitted && !beforeLastPubDate) {
            affidavit = (React.createElement("span", { className: `billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold ${bg.gray} ${text.gray}` }, AffidavitStatusType.awaiting_upload.label));
        }
        if (!requireemailaffidavit &&
            mailaffidavitsoutsidecolumn &&
            !affidavitsubmitted) {
            affidavit = (React.createElement("span", { className: `billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold ${bg.gray} ${text.gray}` }, AffidavitStatusType.outside_of_column.label));
        }
        return React.createElement("span", { className: cellStyle }, affidavit);
    };
    const DuplicateNoticeButton = ({ row }) => {
        const { original } = row;
        const [duplicating, setDuplicating] = useState(false);
        return (React.createElement("button", { title: "Duplicate notice", className: "duplicate-notice", disabled: duplicating, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                if (!user) {
                    console.warn('Cannot duplicate notice, user is null');
                    return;
                }
                setDuplicating(true);
                try {
                    const { newNoticeRef } = yield duplicateNotice(original.id, user);
                    setDuplicating(false);
                    push(`/place/${newNoticeRef.id}?duplicate=true`);
                }
                catch (e) {
                    setDuplicating(false);
                    logAndCaptureException(e, 'Error duplicating notice', {
                        noticeId: original.id
                    });
                }
            }) },
            React.createElement("div", { className: "mx-4" }, duplicating ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-4 w-4" })) : (React.createElement(CopyIcon, { className: "stroke-current stroke-2" })))));
    };
    const tableActionsUI = ({ row }) => {
        const { original } = row;
        return (React.createElement("span", { className: "px-6 py-4" },
            !isDraft && React.createElement(DuplicateNoticeButton, { row: row }),
            isDraft && (React.createElement("button", { id: `delete-${original.id}`, onClick: () => setNoticeToDelete(original) },
                React.createElement(DeleteIcon, null)))));
    };
    let columns = [
        !!(!isPublisher && showAllOrgsNotices) && {
            Header: 'ORGANIZATION',
            id: NEWSPAPER_NAME,
            accessor: 'filer',
            isVisible: !!(!isPublisher && showAllOrgsNotices),
            Cell: ({ row }) => {
                const { original } = row;
                return (React.createElement("span", { className: cellStyle }, original.filerorganizationname));
            },
            width: 'md:w-2/12',
            disableSortBy: true
        },
        {
            Header: (React.createElement("a", { className: "whitespace-no-wrap" },
                "NOTICE NAME",
                React.createElement(Tooltip, { classes: "inline-block align-middle ml-2 font-normal", helpText: 'This title is for reference purposes only.' },
                    React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" })))),
            accessor: 'referenceId',
            Cell: ({ row }) => {
                const { original } = row;
                return (React.createElement("div", { className: `${cellStyle} flex items-center` },
                    isPublisher && original.customid
                        ? `${original.noticename} - ${original.customid}`
                        : original.noticename,
                    isPublisher && original.failedsync ? (React.createElement(Tooltip, { helpText: "Sync failed", classes: "w-8" },
                        React.createElement(InvalidRoundedIcon, { className: "w-4 ml-1 text-red-550" }))) : ('')));
            },
            width: 'md:w-2/12',
            disableSortBy: true
        },
        {
            Header: isPublisher ? 'CLIENT' : 'PUBLICATION',
            accessor: isPublisher ? 'filer' : 'newspaperName',
            Cell: ({ row }) => {
                var _a;
                const { original } = row;
                return (React.createElement("span", { className: cellStyle }, isPublisher
                    ? `${((_a = original.filername) === null || _a === void 0 ? void 0 : _a.trim()) || 'User Pending Registration'}${original.filerorganizationname
                        ? ` - ${original.filerorganizationname}`
                        : ''}`
                    : original.publication));
            },
            width: 'md:w-2/12',
            disableSortBy: true
        },
        {
            Header: 'PUBLICATION DATE',
            accessor: 'publicationDatesString',
            Cell: ({ row }) => {
                const { original } = row;
                return React.createElement("span", { className: cellStyle }, original.publicationdates);
            },
            id: PUBLICATION_DATES,
            width: 'md:w-2/12'
        },
        {
            Header: 'CONFIRMED',
            accessor: 'confirmed',
            Cell: confirmedUI,
            id: CONFIRMED,
            width: 'md:w-1/12'
        },
        {
            Header: 'BILLING STATUS',
            accessor: 'billingStatus',
            Cell: billingStatusUI,
            id: isPublisher ? PUBLISHER_BILLING_STATUS : ADVERTISER_BILLING_STATUS,
            width: 'md:w-1/12'
        },
        {
            Header: 'AFFIDAVIT SUBMITTED',
            accessor: 'affidavitSubmitted',
            Cell: affidavitSubmittedUI,
            id: AFFIDAVIT_STATUS,
            width: 'md:w-1/12'
        },
        !!(isPublisher && showAllOrgsNotices) && {
            Header: 'PUBLICATION',
            id: NEWSPAPER_NAME,
            accessor: 'newspaperName',
            isVisible: !!(isPublisher && showAllOrgsNotices),
            Cell: ({ row }) => {
                const { original } = row;
                return React.createElement("span", { className: cellStyle }, original.publication);
            },
            width: 'md:w-2/12',
            disableSortBy: true
        },
        {
            Header: 'ACTIONS',
            accessor: 'table-actions',
            id: 'table-actions',
            Cell: tableActionsUI,
            width: 'md:w-1/12',
            disableSortBy: true
        }
    ];
    columns = columns.filter((cols) => cols);
    const handleTabChange = (index) => setTab(index);
    const Headings = ({ title }) => {
        const tableTitleHeadStyles = 'inline-block my-3 py-1 text-lg text-black text-left p-3 focus:outline-none font-medium pb-3 mb-0 hover:text-gray-500';
        const highlight = 'border-b-2 border-blue-600';
        return (React.createElement("div", { className: "inline-block w-5/12" },
            React.createElement("div", { className: 'inline-block mx-1' },
                React.createElement("button", { onClick: () => handleTabChange(ACTIVE_NOTICES_TAB), id: "active-notices-tab", className: `${tableTitleHeadStyles} ${title === 'Active' ? highlight : 'text-gray-800'}` }, "Active Notices")),
            React.createElement("div", { className: 'inline-block mx-1' },
                React.createElement("button", { id: "archived-notices-tab", onClick: () => handleTabChange(ARCHIVED_NOTICES_TAB), className: `${tableTitleHeadStyles} ${title === 'Archived' ? highlight : 'text-gray-800'}` }, "Archived Notices")),
            React.createElement("div", { className: 'inline-block mx-1' },
                React.createElement("button", { id: "draft-notices-tab", onClick: () => handleTabChange(DRAFT_NOTICES_TAB), className: `${tableTitleHeadStyles} ${title === 'Drafted' ? highlight : 'text-gray-800'}` },
                    "Drafts",
                    title === 'Drafted' && notices.length > 0 && (React.createElement("span", { className: `inline-flex items-center px-2 py-1 ml-2 rounded-full text-xs font-medium bg-gray-700 text-white` }, total))))));
    };
    // TODO: do I drop the affidavit column here
    const columnsData = React.useMemo(() => [
        {
            Header: Headings({ title }),
            id: `${title} Notices`,
            columns
        }
    ], [columns]);
    const [emptyHeader, emptySubtitle] = title === 'Archived'
        ? [
            `You don't have any notices yet`,
            'Notices will appear here after they finish publication or are cancelled.'
        ]
        : title === 'Drafted'
            ? [
                `You don't have any drafts yet`,
                'Drafts will appear here before you finalize submission for your notices.'
            ]
            : [
                `You don't have any notices yet`,
                'You can track and manage your notices here. Click the button below to place a notice.'
            ];
    return (React.createElement(React.Fragment, null,
        noticeToDelete && (React.createElement(DeleteDraftModal, { setOpen: handleClose, handleClose: handleClose, noticeToDelete: noticeToDelete, open: Boolean(noticeToDelete) })),
        React.createElement(CTable, { columns: columnsData, id: id, data: notices, emptyHeaderText: emptyHeader, emptySubtitleText: emptySubtitle, push: push, loading: loading, actions: actions, onSort: (sort) => setSort(sort), setSearchValue: setSearchValue, sort: sort, tableState: {
                rowCount: rowsPerNoticeTablePage || 5,
                currentPage,
                total
            }, onPageChange: (page) => setCurrentPage(page || 0), onRowsChange: (rows) => {
                if (exists(user)) {
                    void user.ref.update({
                        noticeTablePageSize: rows
                    });
                }
                setPageSize(rows);
            }, setShowUserOnlyNotices: setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue })));
};
const mapStateToProps = (state) => ({
    showAllOrgsNotices: state.auth.showAllOrgsNotices,
    user: state.auth.user
});
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(YourNoticesTable);
