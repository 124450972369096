import React from 'react';
import { ColumnButton } from 'components/ColumnButton';
import { PlusCircleIcon } from 'icons';
export default function AdditionalFeesSettingsHeader({ activeOrganization, setEditedAdditionalFee }) {
    const addFee = () => {
        const newFee = {
            amount: 100,
            description: 'New Additional Fee'
        };
        setEditedAdditionalFee(newFee);
    };
    return (React.createElement("div", { className: "bg-white flex items-center pl-7 pr-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "w-full font-medium" },
            React.createElement("p", { className: "text-xl text-blue-800" }, "Cross-Paper Fees"),
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" },
                "Configure additional fees that show up across all rates for",
                ' ',
                activeOrganization.data().name,
                ".")),
        React.createElement("div", { className: "w-32" },
            React.createElement(ColumnButton, { startIcon: React.createElement(PlusCircleIcon, null), id: "add-additional-fee", buttonText: "Add Fee", onClick: addFee, fullWidth: true, primary: true }))));
}
