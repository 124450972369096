import React, { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';
import RatesSettingsHeader from './RatesSettingsHeader';
import { isDefaultRate } from './ratesTableSettingsUtils';
import RateUpdateForm from './rateUpdateForm';
import RateDrawer from './ratesSettingsDrawer';
import RatesTable from './RatesTable';
/**
 * Wrapper component for the Rates table page.
 */
export default function RateSettings({ activeOrganization }) {
    const [ratesFilter, setRatesFilter] = useState('');
    const [drawerRate, setDrawerRate] = useState();
    const [editedRateData, setEditedRateData] = useState();
    const [editedRateRef, setEditedRateRef] = useState();
    const [rates, setRates] = useState();
    useEffect(() => {
        const unsubscribe = getFirebaseContext()
            .ratesRef()
            .where('organization', '==', activeOrganization.ref)
            .onSnapshot(rateSnapshots => setRates(rateSnapshots.docs));
        return () => unsubscribe();
    }, []);
    const filteredRates = rates === null || rates === void 0 ? void 0 : rates.filter(rate => !rate.data().archived &&
        (!ratesFilter ||
            rate
                .data()
                .description.toLowerCase()
                .includes(ratesFilter.toLowerCase()))).sort((a, b) => {
        if (isDefaultRate(activeOrganization, a) &&
            !isDefaultRate(activeOrganization, b)) {
            return -1;
        }
        if (!isDefaultRate(activeOrganization, a) &&
            isDefaultRate(activeOrganization, b)) {
            return 1;
        }
        return a.data().description.localeCompare(b.data().description);
    });
    return (React.createElement("div", null,
        React.createElement(RatesSettingsHeader, { activeOrganization: activeOrganization, setEditedRateData: setEditedRateData, setRatesFilter: setRatesFilter, ratesFilter: ratesFilter }),
        filteredRates ? (React.createElement(RatesTable, { activeOrganization: activeOrganization, setEditedRateData: setEditedRateData, setEditedRateRef: setEditedRateRef, setDrawerRate: setDrawerRate, ratesFilter: ratesFilter, rates: filteredRates })) : (React.createElement(LoadingState, null)),
        drawerRate && (React.createElement(RateDrawer, { activeOrganization: activeOrganization, setEditedRateData: setEditedRateData, setEditedRateRef: setEditedRateRef, closeDrawer: () => setDrawerRate(undefined), drawerRate: drawerRate })),
        editedRateData && (React.createElement(RateUpdateForm, { activeOrganization: activeOrganization, closeForm: () => {
                setEditedRateData(undefined);
                setEditedRateRef(undefined);
            }, rateData: editedRateData, rateRef: editedRateRef }))));
}
