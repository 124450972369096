var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useImperativeHandle, useEffect, useState, useRef } from 'react';
import { logAndCaptureException, sanitize } from 'utils';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button, Box } from '@material-ui/core';
import { Alert } from 'components/Alert';
import useDebounce from 'utils/UseDebounce';
import useWindowResize from 'utils/UseWindowResize';
import { NoticeType } from 'lib/enums';
import { cdnIfy, assetQuality, getNoticeTypeFromNoticeData } from 'lib/helpers';
import { exists } from 'lib/types';
import { displayParamsFromNoticeAndPageParams } from 'lib/indesign/helpers';
import NoticeEditorMCE from 'components/noticePreview/NoticeEditorMCE';
import { cleanContent, squash } from 'components/noticePreview/mceHelpers';
import LoadingState from 'components/LoadingState';
import FileUpload from 'components/FileUpload';
import { FileUploadListItem } from 'components/FileUploadListItem';
import PDFBounder from 'components/PDFBounder';
import { getPageParams, validateHtml } from 'components/noticePreview/indesign_client_utils';
import { FileType, Extensions } from 'lib/types/upload';
import { connect } from 'react-redux';
import api from 'api';
import * as headers from 'lib/headers_footers/headers';
import NoticeEditorMadlib from 'routes/madlib/components/NoticeEditorMadlib';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PlacementError } from '../../lib/errors/PlacementError';
import ScrollStep from './ScrollStep';
import Firebase from '../../EnoticeFirebase';
import { getPdfFileChanges, getTextFileChanges, getWordFileChanges, getExcelFileChanges, getCsvFileChanges, getRtfChanges, getOtherFileChanges, getAcceptedFiles, getFirebaseStoragePath, getOriginalFileName } from './helpers';
import { EraseContentModal } from './EraseContentModal';
import { EditorErrorMessage } from './EditorErrorMessage';
const styles = (theme) => createStyles({
    convertContentTypeButton: {
        marginTop: theme.spacing(3),
        width: 220,
        height: 55,
        color: '#2C9BDB',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Helvetica Neue',
        textTransform: 'none',
        border: 'solid',
        borderWidth: 'thin',
        borderColor: '#2C9BDB'
    },
    bigButton: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: `${theme.palette.secondary[400]} !important;`
        },
        position: 'relative',
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(2),
            left: 0
        },
        width: '100%',
        height: 100,
        minWidth: 240,
        borderRadius: 0,
        color: theme.palette.grey.scrollFormHeading
    },
    titleButtonOverrides: {
        height: 60,
        minWidth: 200,
        pointerEvents: 'none',
        paddingLeft: 0,
        backgroundColor: '#fafafa',
        color: theme.palette.grey.scrollFormHeading,
        '& .MuiButton-startIcon': {
            marginLeft: `0px !important`,
            color: theme.palette.grey.scrollFormHeading
        }
    },
    originalFileButtonOverrides: {
        height: 60,
        maxWidth: 220,
        minWidth: 220,
        paddingLeft: 0,
        backgroundColor: 'white',
        color: theme.palette.grey.scrollFormHeading,
        '& .MuiButton-startIcon': {
            color: theme.palette.grey.scrollFormHeading
        }
    },
    mceContainer: {
        marginBottom: theme.spacing(2)
    },
    mceLoading: {
        alignSelf: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 2,
        opacity: 1,
        width: '100%'
    },
    fileChip: {
        '& .MuiChip-label': {
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
            'max-width': '200px',
            display: 'block',
            overflow: 'hidden'
        }
    },
    headingText: {
        color: theme.palette.grey.scrollFormHeading
    }
});
const DEFAULT_DISPLAY_COLUMNS = 2;
const NoticeContentStep = React.forwardRef((props, ref) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { classes, activeStepId, id, notice, newspaper, setUploading, uploadedFile, setUploadedFile, isTypeform, isMadlib, next, previous, onDisabledStepClick, editing, placement, placementActions, isPublisher, showSubmitFileWithoutFormattingModal, setShowSubmitFileWithoutFormattingModal, renderSubmitFileWithoutFormattingModal } = props;
    // display ad state
    const [pageParams, setPageParams] = useState();
    const debouncedCrop = useDebounce(placement.confirmedCrop, 500);
    const windowSize = useWindowResize();
    const [upload, setUpload] = useState();
    const [uploadLocation, setUploadLocation] = useState('');
    const [parsing, setParsing] = useState(false);
    const [showEraseContentModal, setShowEraseContentModal] = useState();
    // step metadata state
    const [active, setActive] = useState(false);
    const [initialEditorState, setInitialEditorState] = useState('');
    const [copyPasteContent, setCopyPasteContent] = useState(false);
    // PDF state
    const [pdf, setPdf] = useState();
    // Use to restrict user to show enabled editor for display-only papers in publisher account
    const [displayPaperFileReplacement, setDisplayPaperFileReplacement] = useState(false);
    const [shouldGrayscalePDFDisplays, setShouldGrayscalePDFDisplays] = useState(false);
    /*
    We use this key to force re-mounting of tinymce component.
    This is necessary in order to reset the 'initialState' of mce.
    Unfortunately, attempts to make TinyMCE a normal controlled component
    have resulted in user-unfriendly behavior in the editor so far.
    */
    const [mceKey, setMCEKey] = useState(1);
    // Mablibs
    const [isMadlibPreQuestionsCompleted, setIsMadlibPreQuestionsCompleted] = useState(false);
    const [templateStyles, setTemplateStyles] = useState({
        id: 'unknown'
    });
    const [isMadlibComplete, setIsMadlibComplete] = useState(false);
    const placeHolderText = '';
    const squashableColumn = (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.cleanVariant;
    // Determine whether we should should show hide certain formatting elements for this notice
    const cleanFunction = exists(newspaper) &&
        newspaper.data().cleanVariant &&
        placement.squashable !== false
        ? squash
        : cleanContent;
    const enableMadlibs = getBooleanFlag(LaunchDarklyFlags.ENABLE_MADLIBS_EDITOR, false);
    useEffect(() => { }, [windowSize]);
    useEffect(() => {
        let active = true;
        const checkShouldGrayscalePDFDisplays = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const parent = yield ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().parent) === null || _a === void 0 ? void 0 : _a.get());
            if (active) {
                setShouldGrayscalePDFDisplays((_d = (_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().grayscalePDFDisplayUploads) !== null && _b !== void 0 ? _b : (_c = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _c === void 0 ? void 0 : _c.grayscalePDFDisplayUploads) !== null && _d !== void 0 ? _d : false);
            }
        });
        void checkShouldGrayscalePDFDisplays();
        return () => {
            active = false;
        };
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    useEffect(() => {
        if (!exists(newspaper)) {
            return;
        }
        const sanitizedName = uploadedFile ? sanitize(uploadedFile.name) : '';
        const originalName = sanitizedName.split('.')[0];
        const originalFormat = sanitizedName.split('.')[1];
        const fileProperties = {
            sanitizedName,
            originalName,
            originalFormat,
            shouldGrayscalePDFDisplays
        };
        let uploadType;
        const parse = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            if (!uploadedFile) {
                return;
            }
            setParsing(true);
            const parsingTimer = setTimeout(() => {
                placementActions.setFormattingError('File Unable To Process under 25 secs');
            }, 25000);
            const extension = (_c = (_b = (_a = upload === null || upload === void 0 ? void 0 : upload.metadata) === null || _a === void 0 ? void 0 : _a.fullPath) === null || _b === void 0 ? void 0 : _b.split('.').pop()) === null || _c === void 0 ? void 0 : _c.toLowerCase();
            if (extension) {
                uploadType = Extensions[extension];
            }
            let html;
            let convertedUpload;
            let changes;
            if (uploadType &&
                [FileType.TIF, FileType.PNG, FileType.JPG, FileType.JPEG].indexOf(uploadType) !== -1 &&
                (upload === null || upload === void 0 ? void 0 : upload.ref.fullPath)) {
                const resp = yield fetch(cdnIfy(upload.ref.fullPath, {
                    cloudinaryTransformations: `f_jpg,w_${assetQuality.high.width}/e_sharpen`
                }));
                /* This code refactors legacy code without changing its behavior in order to remove the use of `any`
                The File type is an extension of Blob: we are asserting the return from calling blob() as a File
                but being protective (e.g., by not assuming the result has a `name` property) when reading the File */
                const pdf = Object.assign({}, (yield resp.blob()));
                const pdfName = pdf.name ||
                    upload.ref.fullPath.split('/')[upload.ref.fullPath.split('/').length - 1];
                pdf.name = `converted_${pdfName.split('.')[0]}`;
                setUploadedFile(pdf);
                convertedUpload = yield Firebase.storage()
                    .ref()
                    .child(`${upload.ref.fullPath.split('.')[0]}.pdf`)
                    .put(pdf);
                uploadType = FileType.PDF;
            }
            else {
                convertedUpload = upload;
            }
            if (uploadType === FileType.PDF) {
                changes = yield getPdfFileChanges(convertedUpload, upload, fileProperties);
                placementActions.setColumns(DEFAULT_DISPLAY_COLUMNS);
            }
            else if (uploadType === FileType.TEXT) {
                changes = yield getTextFileChanges(uploadedFile, upload, fileProperties);
            }
            else if (uploadType === FileType.WORD_DOC ||
                uploadType === FileType.DOCX) {
                changes = yield getWordFileChanges(uploadedFile, newspaper, upload, fileProperties);
            }
            else if (uploadType === FileType.EXCEL) {
                changes = yield getExcelFileChanges(html, upload, fileProperties);
                placementActions.setColumns(DEFAULT_DISPLAY_COLUMNS);
            }
            else if (uploadType === FileType.CSV) {
                if (exists(newspaper) && newspaper.data().allowedNotices) {
                    setParsing(false);
                    setUploading(false);
                    setUploadedFile(null);
                    return;
                }
                changes = yield getCsvFileChanges(uploadedFile, upload, fileProperties);
                placementActions.setColumns(DEFAULT_DISPLAY_COLUMNS);
            }
            else if (uploadType === FileType.RTF) {
                changes = yield getRtfChanges(uploadedFile, upload, fileProperties);
            }
            else {
                changes = yield getOtherFileChanges(upload, fileProperties);
            }
            if (changes) {
                Object.keys(changes).forEach(key => {
                    if (key === 'noticeType') {
                        if (placement.noticeType === NoticeType.custom.value ||
                            changes.noticeType !== NoticeType.custom.value) {
                            // Save previous notice type to reset to it if notice is converted to liner
                            placementActions.setPreviousNoticeType(placement.noticeType);
                            placementActions.setNoticeType(changes.noticeType);
                        }
                    }
                    if (key === 'file')
                        // TODO (APP-36): update this call to not necessarily override existing files on placement
                        placementActions.setFilesToAttach([changes.file]);
                    if (key === 'text') {
                        setInitialEditorState(changes.text);
                        placementActions.setNoticeText(changes.text);
                        setParsing(false);
                    }
                    if (key === 'unusedConfirmedHtml')
                        placementActions.setUnusedConfirmedHtml(changes.unusedConfirmedHtml);
                    if (key === 'processedDisplay') {
                        placementActions.setDisplayUrl(changes.file.firebaseStoragePath);
                        placementActions.setProcessedDisplay(changes.processedDisplay);
                    }
                });
            }
            setUploading(false);
            placementActions.saveDraft();
            clearTimeout(parsingTimer);
        });
        if (upload && uploadedFile && upload.metadata.name === sanitizedName) {
            // TODO: Investigate why we're not handling errors in file parsing here and add error handling if possible
            void parse();
        }
    }, [upload, uploadedFile, newspaper === null || newspaper === void 0 ? void 0 : newspaper.id, shouldGrayscalePDFDisplays]);
    const tiny = useRef();
    const noticeIsTooTall = exists(newspaper) &&
        ((_b = newspaper.data().noticeHeightThresholds) === null || _b === void 0 ? void 0 : _b.maxNoticeHeight) &&
        ((_c = placement === null || placement === void 0 ? void 0 : placement.displayParams) === null || _c === void 0 ? void 0 : _c.height) &&
        ((_d = placement === null || placement === void 0 ? void 0 : placement.displayParams) === null || _d === void 0 ? void 0 : _d.height) >
            (((_e = newspaper.data().noticeHeightThresholds) === null || _e === void 0 ? void 0 : _e.maxNoticeHeight) || 0);
    const isComplete = () => {
        if (isMadlib &&
            enableMadlibs &&
            !(isMadlibComplete && isMadlibPreQuestionsCompleted))
            return false;
        if (parsing)
            return false;
        const type = placement.noticeType;
        if (!type)
            return false;
        if (!validateHtml(placement.confirmedHtml))
            return false;
        if (!placement.displayParams ||
            !Object.keys(placement.displayParams).length)
            return false;
        if (noticeIsTooTall)
            return false;
        return !!(type === NoticeType.display_ad.value
            ? debouncedCrop &&
                placement.displayParams &&
                pdf &&
                placement.displayParams.height
            : placement.confirmedHtml && placement.confirmedHtml.length);
    };
    const complete = isComplete();
    useEffect(() => {
        const mceNotInitialized = !!(!placement.confirmedHtml &&
            placement.publicationDates &&
            placement.newspaper &&
            initialEditorState !== placement.confirmedHtml);
        if (mceNotInitialized) {
            setInitialEditorState(placement.confirmedHtml);
            setMCEKey(mceKey + 1);
        }
        if (placement.confirmedHtml) {
            setInitialEditorState(placement.confirmedHtml);
        }
        // Non PDF Upload Has Completed
        if (typeof placement.confirmedHtml === 'string' && parsing) {
            setParsing(false);
        }
    }, [placement.confirmedText, Boolean(placement.confirmedHtml)]);
    useEffect(() => {
        if (placement.original) {
            // notice files will be uploaded to a folder named with
            // the actual notice id (not the draft's id)
            setUploadLocation(`/documentcloud/${placement.original.id}`);
        }
    }, [placement.original]);
    useEffect(() => {
        if (placement.postWithoutFormatting) {
            placementActions.setDisplayParams({});
            placementActions.setNoticeText(null);
            placementActions.setProcessedDisplay(null);
            placementActions.setConfirmedCrop(null);
            placementActions.setUnusedConfirmedHtml(null);
            placementActions.setDisplayUrl(null);
            setInitialEditorState('');
        }
    }, [placement.postWithoutFormatting]);
    const convertDisplayToText = () => __awaiter(void 0, void 0, void 0, function* () {
        setInitialEditorState(placement.unusedConfirmedHtml || null);
        placementActions.setNoticeText(placement.unusedConfirmedHtml || null);
        placementActions.setProcessedDisplay(false);
        placementActions.setUnusedDisplay(placement.displayUrl);
        placementActions.setDisplayUrl(null);
        placementActions.setDisplayParams(null);
        // Custom filing notices converted to liners should be assigned correct notice types
        if (placement.noticeType === NoticeType.display_ad.value) {
            if (placement.previousNoticeType &&
                placement.previousNoticeType !== NoticeType.custom.value) {
                placementActions.setNoticeType(placement.previousNoticeType);
            }
            else {
                placementActions.setNoticeType(NoticeType.custom.value);
            }
        }
        placementActions.saveDraft();
    });
    const revertToDisplay = () => __awaiter(void 0, void 0, void 0, function* () {
        placementActions.setUnusedConfirmedHtml(placement.confirmedHtml);
        placementActions.setDisplayUrl(placement.unusedDisplay);
        placementActions.setUnusedDisplay(null);
        placementActions.setNoticeText(null);
        placementActions.setProcessedDisplay(true);
        placementActions.setNoticeType(NoticeType.display_ad.value);
        yield initPDF();
    });
    const onExit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _k, _l, _m, _o;
        const { publicationDates } = placement;
        const getHeader = () => {
            var _a;
            if ((publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length) === 1 &&
                exists(newspaper) &&
                newspaper.data().oneRunHeader) {
                return newspaper.data().oneRunHeader;
            }
            return ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.headerFormatString) || '';
        };
        const noticeType = getNoticeTypeFromNoticeData(placement, newspaper) || NoticeType.custom;
        const dynamicHeaders = headers.generate(getHeader(), publicationDates || [], noticeType);
        placementActions.confirmSchedule({
            publicationDates,
            dynamicHeaders,
            dynamicFooter: placement.dynamicFooter,
            footerFormatString: null
        });
        const adTemplate = yield ((_k = placement.adTemplate) === null || _k === void 0 ? void 0 : _k.get());
        let result;
        try {
            if (!exists(adTemplate)) {
                throw new Error('Template does not exist');
            }
            result = yield getPageParams(newspaper, adTemplate, notice, placement.columns);
            setPageParams(result);
        }
        catch (err) {
            logAndCaptureException(err, 'Error getting page params', {
                noticeDraftId: (_l = placement.draft) === null || _l === void 0 ? void 0 : _l.id
            });
        }
        if (placement.noticeType === NoticeType.display_ad.value &&
            !placement.postWithoutFormatting) {
            if (!((_m = placement.draft) === null || _m === void 0 ? void 0 : _m.id)) {
                console.error('Draft does not exist on placement');
                return;
            }
            const data = yield api.post('documents/set-display-crop', {
                noticeDraftId: placement.draft.id
            });
            const { pdfStoragePath, error } = data;
            if (error) {
                placementActions.setPlacementError(new PlacementError());
                logAndCaptureException(error, 'Failed to crop file', {
                    noticeDraftId: (_o = placement.draft) === null || _o === void 0 ? void 0 : _o.id
                });
                return previous();
            }
            placementActions.setPdfStoragePath(pdfStoragePath);
        }
    });
    useImperativeHandle(ref, () => ({
        animateIn() {
            setActive(true);
        }
    }));
    useEffect(() => {
        if (!tiny.current)
            return;
        tiny.current.style.height = '60vh';
    }, [active]);
    useEffect(() => {
        setActive(activeStepId === id);
    }, [activeStepId]);
    useEffect(() => {
        const updatePrice = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!placement.processedDisplay)
                return;
            if (!placement.columns || !placement.displayParams || !pageParams)
                return;
            if (!placement.confirmedCrop ||
                !placement.confirmedCrop.absHeight ||
                !placement.confirmedCrop.absWidth ||
                !pageParams)
                return;
            const newParams = displayParamsFromNoticeAndPageParams(placement.confirmedCrop, pageParams, placement.columns);
            placementActions.setDisplayParams(newParams);
            if (placement.columns < newParams.minColumns)
                placementActions.setColumns(newParams.minColumns);
            setUploading(false);
        });
        void updatePrice();
    }, [
        placement.processedDisplay,
        placement.columns,
        debouncedCrop,
        placement.displayParams !== undefined,
        pageParams,
        (_f = placement.rate) === null || _f === void 0 ? void 0 : _f.id
    ]);
    useEffect(() => {
        const handleUpdatedCrop = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!debouncedCrop || !pageParams || !placement.confirmedCrop)
                return;
            if (!debouncedCrop.absHeight || !debouncedCrop.absWidth)
                return;
            const displayParams = displayParamsFromNoticeAndPageParams(placement.confirmedCrop, pageParams, placement.columns);
            placementActions.setColumns(displayParams.columns);
            placementActions.setDisplayParams(displayParams);
        });
        if (placement.noticeType === NoticeType.display_ad.value) {
            void handleUpdatedCrop();
        }
    }, [debouncedCrop, pageParams]);
    useEffect(() => {
        if (copyPasteContent) {
            setTimeout(() => {
                setParsing(false);
                setCopyPasteContent(false);
            }, 1000);
        }
    }, [copyPasteContent]);
    const initPDF = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!placement.displayUrl) {
            return;
        }
        const fileURL = yield Firebase.storage()
            .ref()
            .child(placement.displayUrl)
            .getDownloadURL();
        const resp = yield fetch(fileURL);
        const blob = yield resp.blob();
        setPdf(blob);
        setParsing(false);
    });
    useEffect(() => {
        const initPageParams = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!placement.adTemplate)
                return;
            const adTemplate = yield placement.adTemplate.get();
            if (!exists(adTemplate) || !exists(newspaper) || !exists(notice)) {
                return;
            }
            try {
                const result = yield getPageParams(newspaper, adTemplate, notice, placement.columns);
                setPageParams(result);
            }
            catch (err) {
                logAndCaptureException(err, 'Error getting page params', {
                    noticeDraftId: (_a = placement.draft) === null || _a === void 0 ? void 0 : _a.id
                });
            }
        });
        if (!placement.processedDisplay) {
            setPdf(undefined);
            return;
        }
        void initPDF();
        void initPageParams();
    }, [
        placement.processedDisplay,
        (_g = placement.adTemplate) === null || _g === void 0 ? void 0 : _g.id,
        placement.displayUrl
    ]);
    function eraseContent() {
        placementActions.setDisplayParams(null);
        placementActions.setNoticeText(null);
        placementActions.setFormattingError(null);
        placementActions.setProcessedDisplay(null);
        placementActions.setConfirmedCrop(null);
        placementActions.setUnusedConfirmedHtml(null);
        placementActions.setDisplayUrl(null);
        setInitialEditorState('');
        setUpload(undefined);
        setUploadedFile(null);
        placementActions.setFilesToAttach(null);
        setMCEKey(mceKey + 1);
        placementActions.setPdfStoragePath(null);
        placementActions.resetColumns();
        placementActions.setProofStoragePath(null);
        placementActions.clearFileWithoutProof();
        // Custom filing notices converted to liners should be assigned correct notice types
        if (placement.noticeType === NoticeType.display_ad.value) {
            if (placement.previousNoticeType &&
                placement.previousNoticeType !== NoticeType.custom.value)
                placementActions.setNoticeType(placement.previousNoticeType);
            else
                placementActions.setNoticeType(NoticeType.custom.value);
        }
        placementActions.saveDraft();
    }
    const renderUploadButton = () => {
        var _a, _b;
        return (React.createElement("div", null,
            !isTypeform && !!((_a = placement.filesToAttach) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(FileUploadListItem, { firebaseStoragePath: getFirebaseStoragePath(placement), displayFileName: getOriginalFileName(placement), deleteConfirmation: () => __awaiter(void 0, void 0, void 0, function* () {
                    // TODO: Only show delete confirmation modal if there's only one file attached. If there are more files, allow them to be deleted without confirmation
                    return new Promise(resolve => {
                        setShowEraseContentModal({
                            confirm: () => resolve(true),
                            cancel: () => resolve(false)
                        });
                    });
                }), onDelete: () => {
                    eraseContent();
                } })),
            !isTypeform && (React.createElement("div", { className: "mb-3" },
                React.createElement(FileUpload, { id: "notice-content-file-upload", 
                    // TODO: Update multiple, disabled, and onFileUpload props to allow multiple files to be uploaded
                    multiple: false, disabled: Boolean(placement.filesToAttach && ((_b = placement.filesToAttach) === null || _b === void 0 ? void 0 : _b.length) > 0), acceptFileTypes: getAcceptedFiles(newspaper), uploadFolder: uploadLocation, onFileUpload: ({ files, uploadSnapshots }) => __awaiter(void 0, void 0, void 0, function* () {
                        setUploading(true);
                        setUpload(uploadSnapshots[0]);
                        placementActions.setNoticeText(null);
                        placementActions.setPdfStoragePath(null);
                        setUploadedFile(files[0]);
                    }) }))),
            !isTypeform && React.createElement(SectionDivider, null),
            noticeIsTooTall && React.createElement(NoticeTooTallAlert, { newspaper: newspaper }),
            showEraseContentModal && (React.createElement(EraseContentModal, { setShowEraseContentModal: setShowEraseContentModal, confirm: showEraseContentModal.confirm, cancel: showEraseContentModal.cancel })),
            showSubmitFileWithoutFormattingModal &&
                renderSubmitFileWithoutFormattingModal()));
    };
    useEffect(() => {
        if (activeStepId !== 'confirm-ad')
            return;
        if (placement.postWithoutFormatting && !editing)
            next();
    }, [placement.postWithoutFormatting]);
    // Each time the placement template id is updated, we fetch the template
    // styles from the backend.
    useEffect(() => {
        const fetchTemplateStyles = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const templateId = (_a = placement === null || placement === void 0 ? void 0 : placement.adTemplate) === null || _a === void 0 ? void 0 : _a.id;
            if (!templateId) {
                return;
            }
            try {
                const res = (yield api.post('templates/styles', {
                    templateId
                }));
                if (res.success === true) {
                    setTemplateStyles(res.styles);
                }
            }
            catch (e) {
                logAndCaptureException(e, 'Failed to get styles for template', {
                    templateId
                });
            }
        });
        void fetchTemplateStyles();
    }, [(_h = placement === null || placement === void 0 ? void 0 : placement.adTemplate) === null || _h === void 0 ? void 0 : _h.id]);
    const updateForDisplayOnly = () => {
        var _a;
        if (isPublisher && ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.displayOnlyAds)) {
            setDisplayPaperFileReplacement(true);
        }
    };
    const renderMCE = () => {
        var _a, _b;
        return (React.createElement(Grid, { container: true, direction: "column", className: classes.mceContainer },
            placement.displayUrl &&
                placement.processedDisplay &&
                !placement.postWithoutFormatting ? (React.createElement(PDFBounder, { src: cdnIfy(`${placement.displayUrl}`, {
                    cloudinaryTransformations: 'f_jpg'
                }), setCrop: placementActions.setConfirmedCrop, crop: placement.confirmedCrop })) : (React.createElement(React.Fragment, null,
                React.createElement(NoticeEditorMCE, { key: mceKey, ref: tiny, disabled: placement.postWithoutFormatting ||
                        !!((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.displayOnlyAds), cleanFunction: cleanFunction, onEditorUpdate: (html) => {
                        placementActions.setNoticeText(html);
                        placementActions.saveDraft();
                    }, onPaste: (html) => {
                        setParsing(true);
                        setInitialEditorState(html);
                        setMCEKey(mceKey + 1);
                        setCopyPasteContent(true);
                    }, squashableColumn: squashableColumn, displayPaperFileReplacement: displayPaperFileReplacement, newspaper: newspaper, isPublisher: isPublisher, placement: placement, errorTitle: React.createElement(EditorErrorMessage, { newspaper: newspaper, continueWithLargeFile: placement.continueWithLargeFile, postWithoutFormatting: placement.postWithoutFormatting, isPublisher: isPublisher }), errorFunction: e => {
                        e.preventDefault();
                        placementActions.setDisplayParams(null);
                        placementActions.setNoticeText(null);
                        placementActions.setProcessedDisplay(null);
                        placementActions.setConfirmedCrop(null);
                        placementActions.setUnusedConfirmedHtml(null);
                        placementActions.setDisplayUrl(null);
                        setInitialEditorState('');
                        setUpload(undefined);
                        setUploadedFile(null);
                        placementActions.setFilesToAttach(null);
                        setMCEKey(mceKey + 1);
                        // Custom filing notices converted to liners should be assigned correct notice types
                        if (placement.noticeType === NoticeType.display_ad.value) {
                            if (placement.previousNoticeType &&
                                placement.previousNoticeType !== NoticeType.custom.value)
                                placementActions.setNoticeType(placement.previousNoticeType);
                            else
                                placementActions.setNoticeType(NoticeType.custom.value);
                        }
                        placementActions.setPdfStoragePath(null);
                        placementActions.resetColumns();
                        placementActions.setProofStoragePath(null);
                        placementActions.clearFileWithoutProof();
                        setShowEraseContentModal(null);
                        updateForDisplayOnly();
                    }, initialState: initialEditorState || placeHolderText, placeholder: placeHolderText, clickText: isPublisher
                        ? 'to replace the original file with a formatted notice file.'
                        : 'to start over', templateStyles: templateStyles, columns: placement.columns }))),
            placement.processedDisplay &&
                placement.unusedConfirmedHtml &&
                !((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.displayOnlyAds) && (React.createElement(Box, { style: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                } },
                React.createElement(Button, { id: "convert-to-text", className: classes.convertContentTypeButton, onClick: () => convertDisplayToText(), style: {
                        width: '48%'
                    } },
                    React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center" },
                        React.createElement("span", null, "Convert to text ad"))),
                React.createElement(Button, { id: "submit-without-formatting-button", className: classes.convertContentTypeButton, onClick: () => setShowSubmitFileWithoutFormattingModal(true), style: {
                        width: '48%'
                    } },
                    React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center" },
                        React.createElement("span", null, "Submit file without formatting"))))),
            placement.unusedDisplay && !placement.processedDisplay && (React.createElement(Box, { style: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                } },
                React.createElement(Button, { id: "revert-to-display", className: classes.convertContentTypeButton, onClick: () => revertToDisplay(), style: {
                        width: '48%'
                    } },
                    React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center" },
                        React.createElement("span", null, "Revert To Display Ad")))))));
    };
    const caption = placement.processedDisplay
        ? 'Click and drag on your PDF to crop out the part of your notice you would like in the paper.'
        : isTypeform
            ? 'Your notice has been prepared for you. Please review and make edits as desired.'
            : `Copy and paste notice content below, or upload a file. The dotted lines indicate the actual width of your notice.`;
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: () => __awaiter(void 0, void 0, void 0, function* () {
            yield onExit();
            next();
        }), previous: () => __awaiter(void 0, void 0, void 0, function* () {
            yield onExit();
            previous();
        }), complete: placement.postWithoutFormatting ||
            placement.continueWithLargeFile ||
            (!parsing && complete), title: editing ? 'Edit Notice Content' : 'Draft Notice', caption: caption, onDisabledStepClick: onDisabledStepClick },
        React.createElement(React.Fragment, null,
            parsing && (React.createElement("div", { className: classes.mceLoading },
                React.createElement(LoadingState, { timeout: 120, message: "Preparing notice..." }))),
            React.createElement("div", { style: { opacity: parsing ? 0.1 : 1, width: '100%' } }, enableMadlibs && isMadlib ? (React.createElement(NoticeEditorMadlib, { onEditorUpdate: state => {
                    placementActions.setNoticeText(state === null || state === void 0 ? void 0 : state.renderedHtml);
                    placementActions.saveDraft();
                    if ((state === null || state === void 0 ? void 0 : state.templateData) &&
                        Object.keys(state === null || state === void 0 ? void 0 : state.templateData).length) {
                        setIsMadlibComplete(Object.entries(state === null || state === void 0 ? void 0 : state.templateData).every(
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        ([_property, value]) => !!value));
                    }
                }, onMadlibPreQuestionsUpdate: value => {
                    setIsMadlibPreQuestionsCompleted(value);
                }, columns: (_j = placement.columns) !== null && _j !== void 0 ? _j : 1, templateStyles: templateStyles })) : (React.createElement(React.Fragment, null,
                renderUploadButton(),
                renderMCE())))),
        React.createElement("style", null, `
          .tox-notifications-container {
            display: none;
          }
        `)));
});
function SectionDivider() {
    return (React.createElement("div", { className: "relative text-center text-gray-500 py-6" },
        React.createElement("span", { className: "relative bg-white px-4 py-3 z-10" }, "or"),
        React.createElement("hr", { className: "absolute w-full", style: { top: '50%' } })));
}
function NoticeTooTallAlert({ newspaper }) {
    var _a, _b, _c;
    const description = ((_a = newspaper.data().noticeHeightThresholds) === null || _a === void 0 ? void 0 : _a.noticeHeightExceededWarning) ? (React.createElement("div", { dangerouslySetInnerHTML: {
            __html: ((_b = newspaper.data().noticeHeightThresholds) === null || _b === void 0 ? void 0 : _b.noticeHeightExceededWarning) || ''
        } })) : (`The maximum height of a notice placed in ${newspaper.data().name} is
      ${(_c = newspaper.data().noticeHeightThresholds) === null || _c === void 0 ? void 0 : _c.maxNoticeHeight} inches.`);
    return (React.createElement("div", { className: "my-2" },
        React.createElement(Alert, { id: 'notice-too-tall', title: 'There was an error with your submission', status: "error", description: description })));
}
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(withStyles(styles, { withTheme: true })(NoticeContentStep));
