var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { downloadFileContentsInBrowser, logAndCaptureException } from 'utils';
import ReactPaginate from 'react-paginate';
import LoadingState from 'components/LoadingState';
import Firebase, { Collections } from 'EnoticeFirebase';
import api from 'api';
import { PayoutStatusType, TransactionType } from 'lib/enums';
import EToast from 'components/EToast';
import TailwindModal from 'components/TailwindModal';
import { InvoiceIcon, CalendarIcon, ArrowRight, ArrowLeft, ExternalLinkIcon, DownloadIcon } from 'icons/index';
import { addWeekdays } from 'lib/date';
import { getFirebaseContext } from 'utils/firebase';
import { makeCsvContent } from 'lib/utils/csv';
import SettingsHeader from '../SettingsHeader';
import DwollaCustomerModal from './DwollaCustomerModal';
import ManageDwollaAccounts from './ManageDwollaAccounts';
import PayoutGroupRows from './PayoutGroupRows';
const DwollaSettings = ({ activeOrganization }) => {
    var _a, _b, _c, _d;
    const [payoutsLoading, setPayoutsLoading] = useState(false);
    const [payoutGroups, setPayoutGroups] = useState([]);
    const [infoLoading, setInfoLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [customerData, setCustomerData] = useState();
    const [showDwollaCustomerModal, setShowDwollaCustomerModal] = useState(false);
    const [showVerifiedToast, setShowVerifiedToast] = useState('');
    const [manageAccounts, setManageAccounts] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [pendingPayoutsAmount, setPendingPayoutsAmount] = useState(0);
    const [dwollaAccountSettingsModal, setDwollaAccountSettingsModal] = useState(false);
    const [stripeInvoicesLink, setStripeInvoicesLink] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const PAGE_SIZE = 10;
    const startIndex = pageNumber * PAGE_SIZE;
    const endIndex = payoutGroups.length
        ? Math.min(startIndex + PAGE_SIZE, payoutGroups.length) - 1
        : 0;
    useEffect(() => {
        var _a, _b, _c, _d;
        const loadStripeInvoicesLink = (customerId) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { url } = yield api.post('subscription/stripe-billing-session', {
                    customerId,
                    returnUrl: window.location.href,
                    configuration: 'organization_invoices'
                });
                setStripeInvoicesLink(url);
            }
            catch (err) {
                logAndCaptureException(err, 'Error while getting stripe billing url', {
                    customerId
                });
            }
        });
        setStripeInvoicesLink('');
        // Note: this will not be visible if the setting is inherited from the
        // parent org.
        const enabled = (_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.payColumnInvoices) === null || _b === void 0 ? void 0 : _b.enabled;
        const stripeCustomerId = (_d = (_c = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.payColumnInvoices) === null || _d === void 0 ? void 0 : _d.stripeCustomerId;
        if (enabled && stripeCustomerId) {
            void loadStripeInvoicesLink(stripeCustomerId);
        }
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    // retrieve customer data
    const getCustomer = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f;
        const customerId = (_f = (_e = activeOrganization.data()) === null || _e === void 0 ? void 0 : _e.dwolla) === null || _f === void 0 ? void 0 : _f.dwollaCustomer;
        try {
            const response = yield api.post('dwolla/get-dwolla-customer', {
                customerId
            });
            setInfoLoading(false);
            setDwollaAccountSettingsModal(false);
            if (response.success) {
                setCustomerData(response.customer);
                setShowDwollaCustomerModal(true);
            }
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to retrieve customer data', {
                customerId
            });
        }
    });
    // retrieve bank accounts
    const getBankAccounts = () => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h;
        const customerId = (_h = (_g = activeOrganization.data()) === null || _g === void 0 ? void 0 : _g.dwolla) === null || _h === void 0 ? void 0 : _h.dwollaCustomer;
        try {
            const response = yield api.post('dwolla/get-bank-accounts', {
                customerId
            });
            setAccountsLoading(false);
            if (response.success) {
                const bankAccountsData = [];
                if (response.bankAccounts[0])
                    bankAccountsData.push(response.bankAccounts[0]);
                setBankAccounts(bankAccountsData);
                setDwollaAccountSettingsModal(false);
                setManageAccounts(true);
            }
        }
        catch (err) {
            setAccountsLoading(false);
            logAndCaptureException(err, 'Failed to retrieve bank accounts', {
                customerId
            });
        }
    });
    const loadRowsForPayoutTransfer = (payout) => __awaiter(void 0, void 0, void 0, function* () {
        var _j, _k, _l, _m, _o;
        // We assume that ACH transactions arrive no later than two weekdays
        // after we send them.
        const arrivalDate = payout.status === 'processed' && payout.created
            ? addWeekdays(new Date(payout.created), 2).toISOString()
            : '';
        const parentRow = {
            created: payout.created,
            amount: `$${payout.amount.value}`,
            arrival_date: arrivalDate,
            id: payout.id,
            type: ((_j = payout.metadata) === null || _j === void 0 ? void 0 : _j.type) || '',
            metadata: payout.metadata,
            status: payout.status
        };
        const payoutId = (_k = payout === null || payout === void 0 ? void 0 : payout.metadata) === null || _k === void 0 ? void 0 : _k.payoutId;
        // If there is no 'payoutId' in the metadata then this is an old-style
        // payout for a single notice, not a batch payout with a Firestore record.
        if (!payoutId) {
            const childRow = {
                id: `${Math.random().toString(36).slice(-5)}1`,
                amount: `$${payout.amount.value}`,
                parentId: payout.id,
                type: ((_l = payout.metadata) === null || _l === void 0 ? void 0 : _l.type) || '',
                noticeId: ((_m = payout.metadata) === null || _m === void 0 ? void 0 : _m.type) !== TransactionType.dividend.label
                    ? ((_o = payout.metadata) === null || _o === void 0 ? void 0 : _o.noticeId) || ''
                    : '',
                status: payout.status,
                receipt: ''
            };
            return {
                parent: parentRow,
                children: [childRow]
            };
        }
        try {
            const ctx = getFirebaseContext();
            const payoutSnap = yield ctx.payoutsRef().doc(payoutId).get();
            const payoutData = payoutSnap.data();
            if (!payoutData) {
                return undefined;
            }
            if (payoutData.timeClosed) {
                parentRow.arrival_date = payoutData.timeClosed.toDate().toISOString();
            }
            const childRows = payoutData.lineItems.map((item, index) => {
                var _a;
                return ({
                    id: item.noticeId || `${payout.id}_${index}`,
                    amount: `$${(item.amount / 100).toFixed(2)}`,
                    parentId: payout.id,
                    type: ((_a = payout.metadata) === null || _a === void 0 ? void 0 : _a.type) || '',
                    noticeId: item.noticeId || '',
                    status: payout.status,
                    receipt: '',
                    reason: payoutData.reason || ''
                });
            });
            return {
                parent: parentRow,
                children: childRows
            };
        }
        catch (e) {
            console.warn(`Failed to load payout ${payoutId}`);
        }
        return undefined;
    });
    const loadPendingPayouts = () => __awaiter(void 0, void 0, void 0, function* () {
        const statuses = [
            PayoutStatusType.open.value,
            PayoutStatusType.pending_ready_to_send_to_dwolla.value,
            PayoutStatusType.pending_sent_to_dwolla.value
        ];
        const pendingPayoutsSnap = yield Firebase.firestore()
            .collection(Collections.payouts)
            .where('newspaper', '==', activeOrganization.ref)
            .where('status', 'in', statuses)
            .get();
        const pendingPayouts = pendingPayoutsSnap.docs;
        let pendingAmount = 0;
        for (const p of pendingPayouts) {
            pendingAmount += p.data().amount;
        }
        setPendingPayoutsAmount(pendingAmount);
    });
    const loadPayouts = () => __awaiter(void 0, void 0, void 0, function* () {
        var _p;
        const response = yield api.post('payments/dwolla-payout-data', (_p = activeOrganization.data()) === null || _p === void 0 ? void 0 : _p.dwolla);
        if (!response.success) {
            setPayoutGroups([]);
            return;
        }
        const { payouts } = response;
        const anyPending = payouts.some(payout => payout.status === 'pending');
        if (!isPending && anyPending) {
            setIsPending(true);
        }
        const groups = [];
        for (const payout of payouts) {
            if (payout.status === 'failed') {
                continue;
            }
            // eslint-disable-next-line no-await-in-loop
            const group = yield loadRowsForPayoutTransfer(payout);
            if (group) {
                groups.push(group);
            }
        }
        setPayoutGroups(groups);
    });
    const downloadCSV = (payoutId) => __awaiter(void 0, void 0, void 0, function* () {
        var _q;
        const relevantPayout = (_q = payoutGroups.find(g => g.parent.id === payoutId)) === null || _q === void 0 ? void 0 : _q.parent;
        if (!relevantPayout) {
            console.error(`unable to find the relevant payout ${payoutId}`);
            return;
        }
        const req = {
            payout: relevantPayout
        };
        const { csvRows, csvHeaders, error } = yield api.post('payments/generate-dwolla-payout', req);
        if (error) {
            logAndCaptureException(error, 'An error occurred when downloading payout', {
                payoutId: relevantPayout.id
            });
            return;
        }
        yield Promise.all(csvRows).then((results) => {
            const csvContent = makeCsvContent(csvHeaders, results);
            const arrivalDate = new Date(relevantPayout.created);
            downloadFileContentsInBrowser(`Payout_${arrivalDate.toISOString().split('T')[0]}.csv`, csvContent, 'text/csv');
        });
    });
    useEffect(() => {
        setPayoutGroups([]);
        setPayoutsLoading(true);
        loadPayouts().finally(() => setPayoutsLoading(false));
        setPendingPayoutsAmount(0);
        void loadPendingPayouts();
    }, [activeOrganization && activeOrganization.id]);
    if (payoutsLoading) {
        return React.createElement(LoadingState, { message: "Loading payment data...", timeout: 60 });
    }
    const renderHeader = ({ header, width } = {}) => {
        return (React.createElement("th", { className: `py-3 ${width && `w-${width}`} bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider` }, header));
    };
    const updateValues = () => {
        setShowDwollaCustomerModal(false);
    };
    const customerId = (_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwolla) === null || _b === void 0 ? void 0 : _b.dwollaCustomer;
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow overflow-hidden" },
        React.createElement(SettingsHeader, { header: "Payouts", description: "Expand to see a breakdown of each payout to your bank." },
            React.createElement(React.Fragment, null,
                stripeInvoicesLink && (React.createElement("button", { type: "button", className: "px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => window.open(stripeInvoicesLink) },
                    React.createElement("span", { className: "pr-2" },
                        React.createElement(InvoiceIcon, null)),
                    "Invoices")),
                React.createElement("button", { type: "button", id: "update-account", className: "ml-3 px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => {
                        setAccountsLoading(true);
                        void getBankAccounts();
                    } },
                    accountsLoading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                    React.createElement("span", { className: "pr-2" },
                        React.createElement(ExternalLinkIcon, null)),
                    "Update Account"),
                React.createElement("button", { type: "button", className: "px-3 ml-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => setOpenDownloadModal(true) },
                    React.createElement("span", { className: "pr-2" },
                        React.createElement(DownloadIcon, { className: "h-4 w-4 text-blue-800" })),
                    "Download Report"))),
        pendingPayoutsAmount > 0 && (React.createElement("div", { className: "flex flex-row items-center gap-2 px-7 py-2 text-sm" },
            React.createElement(CalendarIcon, null),
            React.createElement("p", null,
                "In addition to the payouts below, you have",
                ' ',
                React.createElement("b", null,
                    "$",
                    (pendingPayoutsAmount / 100).toFixed(2)),
                " in future payouts pending."))),
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200", id: "payout-table" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    renderHeader(),
                    renderHeader(),
                    renderHeader({ header: 'amount' }),
                    renderHeader({ width: '1/5' }),
                    renderHeader({ header: 'created' }),
                    renderHeader({ header: 'arrived' }),
                    renderHeader({ header: 'actions' }))),
            React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, payoutGroups.slice(startIndex, endIndex + 1).map(group => (React.createElement(PayoutGroupRows, { key: group.parent.id, group: group, payoutSource: 'dwolla', handleDownloadCsvClicked: id => downloadCSV(id) }))))),
        React.createElement("footer", { className: "pr-5 py-0.5 bg-gray-50 border border-gray-300" },
            React.createElement("nav", { className: "px-4 flex items-center justify-between sm:px-0" },
                React.createElement("div", { className: "pl-6 sm:block flex-1 flex" },
                    React.createElement("p", { className: "text-sm text-gray-700 font-medium mx-1" }, payoutGroups.length > 1
                        ? `Showing ${startIndex + 1} to ${endIndex + 1} of ${payoutGroups.length} payouts`
                        : 'Showing all payouts')),
                React.createElement("div", { className: "-mt-px w-0 flex-1 flex justify-end" },
                    React.createElement(ReactPaginate, { previousLabel: React.createElement(ArrowRight, null), previousClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", nextLabel: React.createElement(ArrowLeft, null), nextClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", breakLabel: '...', initialPage: pageNumber, breakClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", pageClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300", activeLinkClassName: "focus:outline-none outline-none text-blue-500 border-blue-500", pageLinkClassName: "px-4 text-sm", pageCount: Math.ceil(payoutGroups.length / PAGE_SIZE), marginPagesDisplayed: 2, pageRangeDisplayed: PAGE_SIZE, onPageChange: pageTo => {
                            setPageNumber(pageTo.selected);
                        }, containerClassName: 'pagination flex', activeClassName: 'text-blue-500 border-blue-500 outline-none' })))),
        showDwollaCustomerModal && (React.createElement(DwollaCustomerModal, { showCustomerModal: () => setShowDwollaCustomerModal(false), updateOnSuccess: () => updateValues(), customerId: (_d = (_c = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.dwolla) === null || _d === void 0 ? void 0 : _d.dwollaCustomer, customerData: customerData, organization: activeOrganization })),
        showVerifiedToast && (React.createElement(EToast, { message: showVerifiedToast, type: "success", close: () => setShowVerifiedToast('') })),
        dwollaAccountSettingsModal && (React.createElement(TailwindModal, { header: 'Account Settings', close: () => setDwollaAccountSettingsModal(false), noExitOutsideModal: true, widthPct: 30 },
            React.createElement("div", { className: "flex flex-col mr-4" },
                React.createElement("div", { className: "font-normal text-sm text-gray-700 mb-8" }, "Update your account information by selecting an option below."),
                React.createElement("button", { className: `flex justify-center w-100 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm items-center py-2 px-2`, type: "button", id: "update-personal-info", onClick: () => {
                        var _a, _b;
                        if ((_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwolla) === null || _b === void 0 ? void 0 : _b.dwollaCustomer) {
                            setInfoLoading(true);
                            void getCustomer();
                        }
                    } },
                    infoLoading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                    "View personal information"),
                React.createElement("button", { className: `flex justify-center mt-2 w-100 rounded-md font-semibold border border-blue-500 text-blue-600 text-sm items-center py-2 px-2`, type: "button", onClick: () => {
                        setAccountsLoading(true);
                        void getBankAccounts();
                    } },
                    accountsLoading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                    'Manage bank account')))),
        manageAccounts && (React.createElement(ManageDwollaAccounts, { closeModal: () => setManageAccounts(false), bankAccounts: bankAccounts, isPendingTransaction: isPending, activeOrganization: activeOrganization, showToastOnVerifyMicroDeposits: () => setShowVerifiedToast('Account has been successfuly verified.') })),
        openDownloadModal && customerId && (React.createElement(PayoutsDownloadModal, { close: () => setOpenDownloadModal(false), customerId: customerId }))));
};
const PayoutsDownloadModal = ({ close, customerId }) => {
    const [loadingPayouts, setLoadingPayouts] = useState(false);
    const [period, setPeriod] = useState('lastMonth');
    const [error, setError] = useState('');
    const downloadDwollaPayouts = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingPayouts(true);
        setError('');
        try {
            const { url } = yield api.post(`payments/download-dwolla-payouts`, {
                period,
                customerId
            });
            if (url)
                window.open(url);
            else
                setError('There are no relevant payouts in the given period');
        }
        catch (err) {
            logAndCaptureException(err, 'An error occurred when downloading payout', {
                customerId,
                period
            });
            setError('There was an error downloading your payouts.');
        }
        setLoadingPayouts(false);
    });
    return (React.createElement("div", { className: "fixed z-10 inset-0 overflow-y-auto" },
        React.createElement("div", { className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity" },
                React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
                React.createElement("div", null,
                    React.createElement("div", { className: "mt-1 text-center" },
                        React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900", id: "modal-headline" }, "Download Payouts"),
                        React.createElement("div", { className: "my-5" },
                            React.createElement("div", { className: "col-span-6 sm:col-span-3" },
                                React.createElement("div", { className: "mb-2 text-left block text-sm font-medium leading-5 text-gray-700" }, "Download payouts from"),
                                React.createElement("select", { value: period, onChange: e => setPeriod(e.target.value), id: "country", className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" },
                                    React.createElement("option", { value: "currMonth" }, "Current month"),
                                    React.createElement("option", { value: "lastMonth" }, "Previous month"),
                                    React.createElement("option", { value: "currYear" }, "Current year"),
                                    React.createElement("option", { value: "lastYear" }, "Previous year"),
                                    React.createElement("option", { value: "all" }, "Download all")))))),
                error && (React.createElement("div", { className: "my-5" },
                    React.createElement("div", { className: "text-red-400 text-sm" }, error))),
                React.createElement("div", { className: "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense mb-2" },
                    React.createElement("span", { className: "flex w-full rounded-md shadow-sm sm:col-start-2" },
                        React.createElement("button", { type: "button", className: `text-white shadow inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5`, onClick: () => downloadDwollaPayouts(), disabled: loadingPayouts },
                            React.createElement("span", { className: "flex" },
                                loadingPayouts && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Download"))),
                    React.createElement("span", { className: "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1" },
                        React.createElement("button", { type: "button", className: "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5", onClick: () => close() }, "Cancel")))))));
};
export default DwollaSettings;
