var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, FormControl, Input, Button, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TimezoneType } from 'lib/enums';
import SettingsWrapper from '../SettingsWrapper';
const styles = (theme) => createStyles({
    formControl: {
        display: 'flex'
    },
    saveButton: {
        marginTop: theme.spacing(4),
        width: '100%'
    },
    header: {
        marginBottom: theme.spacing(3)
    }
});
const PressAssociationSettingsInfo = ({ activeOrganization, classes }) => {
    const orgData = activeOrganization.data();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [timezone, setTimezone] = useState();
    useEffect(() => {
        if (orgData) {
            setName(orgData.name);
            setPhone(orgData.phone);
            setTimezone(orgData.iana_timezone);
        }
    }, [activeOrganization]);
    return (React.createElement(SettingsWrapper, null,
        React.createElement(ValidatorForm, { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                e.preventDefault();
                yield activeOrganization.ref.update({
                    iana_timezone: timezone,
                    phone,
                    name
                });
            }) },
            React.createElement(Typography, { variant: "h5", className: classes.header }, "Basic information"),
            React.createElement(Grid, { container: true, spacing: 4 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(FormControl, { required: true, fullWidth: true },
                        React.createElement(InputLabel, null, "Organization Name"),
                        React.createElement(Input, { required: true, autoComplete: "none", value: name, onChange: e => setName(e.target.value) }))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { required: true, fullWidth: true },
                        React.createElement(InputMask, { mask: "(999) 999-9999", maskChar: "", value: phone, onChange: e => setPhone(e.target.value) }, () => (React.createElement(TextValidator, { name: "phone", value: phone, required: true, autoComplete: "none", label: "Phone number", validators: ['minStringLength:14'], errorMessages: ['Phone number must be 10 digits'] }))))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { required: true, fullWidth: true },
                        React.createElement(InputLabel, null, "Timezone"),
                        React.createElement(Select, { onChange: e => {
                                setTimezone(e.target.value);
                            }, value: timezone }, TimezoneType.items().map(tz => (React.createElement(MenuItem, { key: tz.value, value: tz.logical }, tz.label)))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Button, { id: "InfoSave", type: "submit", variant: "contained", color: "primary", className: classes.saveButton }, "Save"))))));
};
export default withStyles(styles)(PressAssociationSettingsInfo);
