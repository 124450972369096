var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * The default error reporter just returns the default in case it is somehow unset.
 */
let _LAUNCH_DARKLY_CONTEXT = {
    getBooleanFeatureFlag: (flag, options) => __awaiter(void 0, void 0, void 0, function* () {
        console.warn(`No LaunchDarklyContext found when retrieving ${flag}`);
        return !!(options === null || options === void 0 ? void 0 : options.defaultValue);
    })
};
export const setLaunchDarklyContext = (context) => {
    _LAUNCH_DARKLY_CONTEXT = context;
};
export const getLaunchDarklyContext = () => {
    return _LAUNCH_DARKLY_CONTEXT;
};
