var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { BuildingIcon, PencilIcon, SearchIcon } from 'icons';
import { exists } from 'lib/types';
import { Collections } from 'lib/constants';
import Firebase from 'EnoticeFirebase';
import { Tab } from '@headlessui/react';
import { getLocationParams } from 'utils/urls';
import { ColumnButton } from 'components/ColumnButton';
import api from 'api';
import { logAndCaptureException } from 'utils';
import classNames from 'classnames';
import CustomerDrawer from 'components/CustomerDrawer/CustomerDrawer';
import CustomerOrganizationDrawer from 'components/CustomerDrawer/CustomerOrganizationDrawer';
import { getFirebaseContext } from 'utils/firebase';
import EditCustomerModal from 'components/CustomerDrawer/EditCustomerModal';
import EditCustomerOrganizationModal from 'components/CustomerDrawer/EditCustomerOrganizationModal';
import { getNewspaperRefForCustomer } from 'lib/notice/customer';
import { Toast } from 'components/Toast';
import { TextField } from 'components/TextField';
import { Badge } from 'components/Badge';
import CustomerTableLoader from 'components/CustomerDrawer/CustomerTableLoader';
import SettingsHeader from '../SettingsHeader';
const blueCustomerOrgStyles = 'bg-blue-100 text-blue-900';
const greenCustomerOrgStyles = 'bg-green-100 text-green-650';
const redCustomerOrgStyles = 'bg-red-100 text-red-500';
const purpleCustomerOrgStyles = 'bg-purple-100 text-purple-500';
const pinkCustomerOrgStyles = 'bg-pink-100 text-pink-500';
const roseCustomerOrgStyles = 'bg-rose-100 text-rose-500';
const orangeCustomerOrgStyles = 'bg-orange-100 text-orange-500';
export const buildingStyles = [
    blueCustomerOrgStyles,
    greenCustomerOrgStyles,
    redCustomerOrgStyles,
    purpleCustomerOrgStyles,
    pinkCustomerOrgStyles,
    roseCustomerOrgStyles,
    orangeCustomerOrgStyles
];
const blueMemberStyles = 'bg-blue-650';
const greenMemberStyles = 'bg-green-400';
const redMemberStyles = 'bg-red-500';
const purpleMemberStyles = 'bg-purple-500';
const pinkMemberStyles = 'bg-pink-500';
const roseMemberStyles = 'bg-rose-500';
const orangeMemberStyles = 'bg-orange-500';
export const memberStyles = [
    blueMemberStyles,
    greenMemberStyles,
    redMemberStyles,
    purpleMemberStyles,
    pinkMemberStyles,
    roseMemberStyles,
    orangeMemberStyles
];
export const extractCustomerDataFromCustomer = (customer) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    if (!exists(customer)) {
        return null;
    }
    let userSnap;
    try {
        userSnap = (yield customer.data().user.get());
    }
    catch (err) {
        console.error('Error pulling user for customer', customer.id);
        return null;
    }
    if (!customer.data().organization)
        return null;
    if (!exists(userSnap))
        return null;
    const newspaper = (yield customer
        .data()
        .organization.get());
    const organizationSnap = (yield ((_a = userSnap.data().organization) === null || _a === void 0 ? void 0 : _a.get()));
    let defaultLinerRate = (yield newspaper
        .data()
        .defaultLinerRate.get());
    let defaultDisplayRate = (yield newspaper
        .data()
        .defaultDisplayRate.get());
    if (customer.data().linerRate) {
        const customerLinerRate = yield ((_b = customer.data().linerRate) === null || _b === void 0 ? void 0 : _b.get());
        if (exists(customerLinerRate)) {
            defaultLinerRate = customerLinerRate;
        }
    }
    if (customer.data().displayRate) {
        const customerDisplayRate = yield ((_c = customer.data().displayRate) === null || _c === void 0 ? void 0 : _c.get());
        if (exists(customerDisplayRate)) {
            defaultDisplayRate = customerDisplayRate;
        }
    }
    let requireUpfrontPayment;
    if (customer.data().requireUpfrontPayment !== undefined &&
        customer.data().requireUpfrontPayment !== null) {
        requireUpfrontPayment = customer.data().requireUpfrontPayment;
    }
    else {
        requireUpfrontPayment = (_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.requireUpfrontPayment;
    }
    return {
        user: userSnap,
        organization: organizationSnap,
        customer,
        defaultLinerRate,
        defaultDisplayRate,
        requireUpfrontPayment
    };
});
export const extractCustomerOrganizationDataFromOrganization = (customerOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    if (!customerOrganization) {
        return null;
    }
    const clientOrganization = yield ((_e = customerOrganization.data()) === null || _e === void 0 ? void 0 : _e.client.get());
    return {
        clientOrganization,
        customerOrganization
    };
});
const Customers = ({ activeOrganization }) => {
    const [customerTable, setCustomerTable] = useState([]);
    const [customerOrganizationsTable, setCustomerOrganizationsTable] = useState([]);
    const [customerFilter, setCustomerFilter] = useState('');
    const [customerOrganizationsFilter, setCustomerOrganizationsFilter] = useState('');
    const [customersOnNewspaper, setCustomersOnNewspaper] = useState(true);
    const [customerOrganizationsOnNewspaper, setCustomerOrganizationsOnNewspaper] = useState(true);
    /**
     * The `filteredTablePage` and `customerId` were added to handle the case where:
     1- The user clicks on one customer and opens the customer modal
     2- The user changes some of the data of the customer
     3- The user clicks on `Submit` then close the modal and quickly re-open it
     4- The user should see the new data even if it updates after some time
    `filteredTablePage` is a new property for the customers on the current page only and it is updated whenever updates happen to all the customers (ex: new changes for a customer have been saved) so we can get an updated customer when the user re-opens the modal.
    */
    const [filteredTable, setFilteredTable] = useState();
    const [filteredTablePage, setFilteredTablePage] = useState();
    const [filteredCustomerOrganizationsTable, setFilteredCustomerOrganizationsTable] = useState();
    const [filteredCustomerOrganizationsTablePage, setFilteredCustomerOrganizationsTablePage] = useState();
    const [customer, setCustomer] = useState();
    const [customerId, setCustomerId] = useState('');
    const [customerOrganization, setCustomerOrganization] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [showEditCustomerOrganizationModal, setShowEditCustomerOrganizationModal] = useState(false);
    const [orgRates, setOrgRates] = useState();
    const [showCustomerOrganizationDrawer, setShowCustomerOrganizationDrawer] = useState(false);
    const PAGE_SIZE = 10;
    const [pageNumber, setPageNumber] = useState(0);
    const [toastText, setToastText] = useState('');
    const [loadingCustomerReport, setLoadingCustomerReport] = useState(false);
    const REGISTERED = 'Registered';
    const NOT_REGISTERED = 'Not registered';
    const newspaperRef = getNewspaperRefForCustomer(activeOrganization);
    const maxCustomerTablePage = Math.ceil((filteredTable ? filteredTable.length : 0) / PAGE_SIZE);
    const maxCustomerOrganizationTablePage = Math.ceil((filteredCustomerOrganizationsTable
        ? filteredCustomerOrganizationsTable.length
        : 0) / PAGE_SIZE);
    useEffect(() => {
        const unsub = getFirebaseContext()
            .ratesRef()
            .where('organization', '==', activeOrganization.ref)
            .onSnapshot(result => {
            const orgRates = result.docs;
            setOrgRates(orgRates);
        });
        return unsub;
    }, []);
    useEffect(() => {
        const unsub = Firebase.firestore()
            .collection(Collections.customers)
            .where('organization', '==', newspaperRef)
            .where('verified', '==', true)
            .onSnapshot((result) => __awaiter(void 0, void 0, void 0, function* () {
            const shallowCustomerRows = result.docs;
            const customersWithData = yield Promise.all(shallowCustomerRows.map(extractCustomerDataFromCustomer));
            const validCustomersWithData = customersWithData.filter(Boolean);
            if (!validCustomersWithData.length) {
                setCustomersOnNewspaper(false);
            }
            setCustomerTable(validCustomersWithData);
        }));
        return unsub;
    }, []);
    useEffect(() => {
        const unsub = Firebase.firestore()
            .collection(Collections.customerOrganizations)
            .where('organization', '==', newspaperRef)
            .onSnapshot((result) => __awaiter(void 0, void 0, void 0, function* () {
            const shallowCustomerOrganizationRows = result.docs;
            const customerOrganizationsWithData = yield Promise.all(shallowCustomerOrganizationRows.map(extractCustomerOrganizationDataFromOrganization));
            const validCustomerOrganizationsWithData = customerOrganizationsWithData.filter(Boolean);
            if (!validCustomerOrganizationsWithData.length) {
                setCustomerOrganizationsOnNewspaper(false);
            }
            setCustomerOrganizationsTable(validCustomerOrganizationsWithData);
        }));
        return unsub;
    }, []);
    useEffect(() => {
        const getCustomerFromUrl = () => {
            const customerIdFromUrl = getLocationParams().get('customerId');
            if (customerIdFromUrl) {
                setShowCustomerDrawer(true);
                const unsub = Firebase.firestore()
                    .collection(Collections.customers)
                    .doc(customerIdFromUrl)
                    .onSnapshot((snapshot) => __awaiter(void 0, void 0, void 0, function* () {
                    const customerData = yield extractCustomerDataFromCustomer(snapshot);
                    if (customerData) {
                        setCustomer(customerData);
                    }
                    else {
                        console.error('Unable to extract customer data');
                    }
                }));
                return unsub;
            }
        };
        return getCustomerFromUrl();
    }, []);
    // The current page customers are re-set whenever `filteredTable` is updated which happens
    // when customers onSnapshot has new updates to send updated customer to the custoemr modal
    useEffect(() => {
        if (filteredTable === null || filteredTable === void 0 ? void 0 : filteredTable.length) {
            const filteredTablePageData = filteredTable
                .sort((a, b) => {
                var _a, _b;
                return (((_a = a.customer.data().userName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '') <
                    (((_b = b.customer.data().userName) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || '')
                    ? -1
                    : 1;
            })
                .slice(pageNumber * PAGE_SIZE, Math.min((pageNumber + 1) * PAGE_SIZE, filteredTable.length));
            setFilteredTablePage(filteredTablePageData);
            const customerWithId = filteredTable.find(customerData => customerData.customer.id === customerId);
            if (customerWithId) {
                setCustomer(customerWithId);
            }
        }
    }, [filteredTable, customerId, pageNumber]);
    useEffect(() => {
        if (filteredCustomerOrganizationsTable === null || filteredCustomerOrganizationsTable === void 0 ? void 0 : filteredCustomerOrganizationsTable.length) {
            const filteredCustomerOrganizationsTablePageData = filteredCustomerOrganizationsTable
                .sort((a, b) => {
                var _a, _b, _c, _d, _e, _f;
                return (((_c = (_b = (_a = a.customerOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.toLowerCase()) || '') <
                    (((_f = (_e = (_d = b.customerOrganization) === null || _d === void 0 ? void 0 : _d.data()) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.toLowerCase()) || '')
                    ? -1
                    : 1;
            })
                .slice(pageNumber * PAGE_SIZE, Math.min((pageNumber + 1) * PAGE_SIZE, filteredCustomerOrganizationsTable.length));
            setFilteredCustomerOrganizationsTablePage(filteredCustomerOrganizationsTablePageData);
        }
    }, [filteredCustomerOrganizationsTable, pageNumber]);
    useEffect(() => {
        if (!customerFilter) {
            setFilteredTable(customerTable);
            return;
        }
        const matchString = customerFilter.toLowerCase();
        setFilteredTable(customerTable.filter(a => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            if ((_a = a.customer.data().userName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(matchString)) {
                return true;
            }
            if (((_b = a.customer.data().firstName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(matchString)) ||
                a.user.data().firstName.toLowerCase().includes(matchString)) {
                return true;
            }
            if (((_c = a.customer.data().lastName) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(matchString)) ||
                a.user.data().lastName.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_d = a.user.data().name) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_e = a.customer
                .data()
                .organizationName) === null || _e === void 0 ? void 0 : _e.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_h = (_g = (_f = a.organization) === null || _f === void 0 ? void 0 : _f.data()) === null || _g === void 0 ? void 0 : _g.name) === null || _h === void 0 ? void 0 : _h.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_j = a.user.data().organizationName) === null || _j === void 0 ? void 0 : _j.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_k = a.customer.data().internalID) === null || _k === void 0 ? void 0 : _k.toLowerCase().includes(matchString)) {
                return true;
            }
            if (a.user.data().email.toLowerCase().includes(matchString)) {
                return true;
            }
            return false;
        }));
    }, [customerFilter, customerTable]);
    useEffect(() => {
        if (!customerOrganizationsFilter) {
            setFilteredCustomerOrganizationsTable(customerOrganizationsTable);
            return;
        }
        const matchString = customerOrganizationsFilter.toLowerCase();
        setFilteredCustomerOrganizationsTable(customerOrganizationsTable.filter(a => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if ((_c = (_b = (_a = a === null || a === void 0 ? void 0 : a.customerOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_f = (_e = (_d = a === null || a === void 0 ? void 0 : a.clientOrganization) === null || _d === void 0 ? void 0 : _d.data()) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(matchString)) {
                return true;
            }
            if ((_j = (_h = (_g = a.customerOrganization) === null || _g === void 0 ? void 0 : _g.data()) === null || _h === void 0 ? void 0 : _h.internalID) === null || _j === void 0 ? void 0 : _j.toLowerCase().includes(matchString)) {
                return true;
            }
            return false;
        }));
    }, [customerOrganizationsFilter, customerOrganizationsTable]);
    if (!customerTable && !customerOrganizationsTable) {
        return null;
    }
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 mb-24" },
        React.createElement(CustomerSettingsTabs, { tabs: ['Customers', 'Organizations'], tabIndex: tabIndex, setTabIndex: setTabIndex, setPageNumber: setPageNumber }),
        tabIndex === 0 && (React.createElement("div", null,
            React.createElement(SettingsHeader, { header: "Customers", description: "People who have placed notices with your publication." },
                React.createElement("div", { className: "w-1/3" },
                    React.createElement(TextField, { id: 'customer-search', labelText: '', placeholder: "Search", size: "small", value: customerFilter, onChange: value => {
                            setCustomerFilter(value);
                            if (pageNumber)
                                setPageNumber(0);
                        }, prefix: React.createElement(SearchIcon, { className: "w-5 text-grey-300", "aria-hidden": "true" }) })),
                React.createElement("div", { className: "ml-2 mb-px" },
                    React.createElement(ColumnButton, { primary: true, size: "sm", buttonText: "Download All", loading: loadingCustomerReport, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setLoadingCustomerReport(true);
                            try {
                                const { url } = yield api.get(`organizations/${newspaperRef.id}/customers-report`);
                                window.open(url);
                            }
                            catch (e) {
                                logAndCaptureException(e, 'Failed to download customers report', {
                                    newspaperId: activeOrganization.id
                                });
                            }
                            finally {
                                setLoadingCustomerReport(false);
                            }
                        }) }))),
            !filteredTablePage && customersOnNewspaper && (React.createElement("div", { role: "status" },
                React.createElement(CustomerTableLoader, null))),
            React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
                filteredTablePage && (React.createElement("thead", { className: "h-16" },
                    React.createElement("tr", { className: "text-left bg-gray-50 uppercase text-gray-650 text-sm tracking-wider" },
                        React.createElement("th", { className: "pl-12 font-medium" }, "Name"),
                        React.createElement("th", { className: "pl-8 font-medium" }, "ID"),
                        React.createElement("th", { className: "font-medium" }, "Status"),
                        React.createElement("th", { className: "pl-24 font-medium" }, "Actions")))),
                React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, filteredTablePage &&
                    maxCustomerTablePage !== 0 &&
                    filteredTablePage.map((customer, index) => {
                        return (React.createElement("tr", { key: index, className: "hover:bg-gray-50 cursor-pointer h-20", onClick: () => {
                                setShowCustomerDrawer(true);
                                setCustomerId(customer.customer.id);
                            } },
                            React.createElement(CustomerInfoCell, { index: index, customer: customer.customer, user: customer.user }),
                            React.createElement("td", { className: "px-8 whitespace-no-wrap text-sm leading-6 font-medium text-gray-650" }, customer.customer.data().internalID || '--'),
                            React.createElement("td", { className: "w-1/4 whitespace-no-wrap" },
                                React.createElement(Badge, { status: customer.user.data().lastSignInTime
                                        ? 'success'
                                        : 'critical' }, customer.user.data().lastSignInTime
                                    ? REGISTERED
                                    : NOT_REGISTERED)),
                            React.createElement("td", { className: "pl-26 w-1/4 whitespace-no-wrap text-sm leading-5" },
                                React.createElement(ActionButton, { id: `edit-customer-table-button-${index}`, onClick: () => {
                                        setShowEditCustomerModal(true);
                                        setCustomerId(customer.customer.id);
                                    } }))));
                    }))),
            showEditCustomerModal && customer && (React.createElement(EditCustomerModal, { closeModal: () => {
                    setShowEditCustomerModal(false);
                }, customerData: customer, activeOrganization: activeOrganization, rates: orgRates, setToastText: setToastText })),
            React.createElement("footer", { className: "pr-5 py-0.5 bg-white border-t border-b border-gray-300 rounded-b-md" },
                React.createElement("div", { className: "pl-6 py-3.5 flex justify-between" },
                    React.createElement("div", { className: "flex" },
                        React.createElement(ColumnButton, { buttonText: "Previous", disabled: !filteredTablePage || pageNumber === 0, onClick: () => {
                                setPageNumber(pageNumber - 1);
                            } })),
                    React.createElement("div", { className: "py-2.5 leading-6 text-sm font-medium text-grey-400" },
                        "Page ",
                        pageNumber + 1,
                        " of",
                        ' ',
                        !filteredTablePage ? 1 : maxCustomerTablePage || 1),
                    React.createElement("div", { className: "flex" },
                        React.createElement(ColumnButton, { buttonText: "Next", disabled: !filteredTablePage ||
                                pageNumber === maxCustomerTablePage - 1 ||
                                maxCustomerTablePage === 0, onClick: () => {
                                setPageNumber(pageNumber + 1);
                            } })))))),
        tabIndex === 1 && (React.createElement("div", null,
            React.createElement(SettingsHeader, { header: "Organizations", description: "Governments, legal services, and agencies using your publication." },
                React.createElement(TextField, { id: 'organization-search', labelText: '', placeholder: "Search", size: "small", value: customerOrganizationsFilter, onChange: value => {
                        setCustomerOrganizationsFilter(value);
                        if (pageNumber)
                            setPageNumber(0);
                    }, prefix: React.createElement(SearchIcon, { className: "w-5 text-grey-300", "aria-hidden": "true" }) })),
            !filteredCustomerOrganizationsTablePage &&
                customerOrganizationsOnNewspaper && React.createElement(CustomerTableLoader, null),
            React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
                filteredCustomerOrganizationsTablePage && (React.createElement("thead", { className: "h-16" },
                    React.createElement("tr", null,
                        React.createElement("th", { className: "pl-12 w-1/2 bg-gray-50 uppercase text-left text-sm leading-4 font-medium text-gray-650 tracking-wider" }, "name"),
                        React.createElement("th", { className: "w-4/12 pl-8 bg-gray-50 uppercase text-left text-sm leading-4 font-medium text-gray-650 tracking-wider" }, "id"),
                        React.createElement("th", { className: "pl-24 w-2/12 bg-gray-50 uppercase text-left text-sm leading-4 font-medium text-gray-650 tracking-wider" }, "actions")))),
                React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, filteredCustomerOrganizationsTablePage &&
                    maxCustomerOrganizationTablePage !== 0 &&
                    filteredCustomerOrganizationsTablePage.map((customerOrganization, index) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return (React.createElement("tr", { key: index, className: "hover:bg-gray-50 cursor-pointer h-20", onClick: () => {
                                setShowCustomerOrganizationDrawer(true);
                                setCustomerOrganization(customerOrganization);
                            } },
                            React.createElement("td", { className: "pl-12 w-1/3 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: `${buildingStyles[index % buildingStyles.length]} w-10 h-10 rounded-full flex items-center justify-center` },
                                        React.createElement(BuildingIcon, { className: "w-5 h-5" })),
                                    React.createElement("div", { className: "pl-3 text-sm leading-6 font-medium text-gray-850" }, `${((_b = (_a = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.customerOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.name)
                                        ? (_d = (_c = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.customerOrganization) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.name
                                        : ((_e = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.clientOrganization.data()) === null || _e === void 0 ? void 0 : _e.name) || ''}`))),
                            React.createElement("td", { className: "pl-8 w-1/4 whitespace-no-wrap font-medium text-sm leading-6 text-gray-650" }, `${((_g = (_f = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.customerOrganization) === null || _f === void 0 ? void 0 : _f.data()) === null || _g === void 0 ? void 0 : _g.internalID)
                                ? (_j = (_h = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.customerOrganization) === null || _h === void 0 ? void 0 : _h.data()) === null || _j === void 0 ? void 0 : _j.internalID
                                : '--'}`),
                            React.createElement("td", { className: "pl-26 w-1/4 whitespace-no-wrap" },
                                React.createElement(ActionButton, { id: `edit-organization-table-button-${index}`, onClick: () => {
                                        setShowEditCustomerOrganizationModal(true);
                                        setCustomerOrganization(customerOrganization);
                                    } }))));
                    }))),
            showEditCustomerOrganizationModal && customerOrganization && (React.createElement(EditCustomerOrganizationModal, { setOpen: () => {
                    setShowEditCustomerOrganizationModal(false);
                    setShowCustomerOrganizationDrawer(false);
                }, customerOrganizationData: customerOrganization, activeOrganization: activeOrganization, setToastText: setToastText })),
            React.createElement("footer", { className: "pr-5 py-0.5 bg-white border-t border-b border-gray-300 rounded-b-md" },
                React.createElement("div", { className: "pl-6 py-3.5 flex justify-between" },
                    React.createElement("div", { className: "flex" },
                        React.createElement(ColumnButton, { buttonText: "Previous", disabled: !filteredCustomerOrganizationsTablePage || pageNumber === 0, onClick: () => {
                                setPageNumber(pageNumber - 1);
                            } })),
                    React.createElement("div", { className: "py-2.5 leading-6 text-sm font-medium text-grey-400" },
                        "Page ",
                        pageNumber + 1,
                        " of",
                        ' ',
                        Math.ceil((!filteredCustomerOrganizationsTablePage
                            ? 1
                            : filteredCustomerOrganizationsTable
                                ? filteredCustomerOrganizationsTable.length
                                : 1) / PAGE_SIZE) || 1),
                    React.createElement("div", { className: "flex" },
                        React.createElement(ColumnButton, { buttonText: "Next", disabled: !filteredCustomerOrganizationsTablePage ||
                                pageNumber === maxCustomerOrganizationTablePage - 1 ||
                                maxCustomerOrganizationTablePage === 0, onClick: () => {
                                setPageNumber(pageNumber + 1);
                            } })))))),
        showCustomerDrawer && customer && (React.createElement(CustomerDrawer, { closeDrawer: () => {
                setShowCustomerDrawer(false);
            }, setShowCustomerOrganizationDrawer: setShowCustomerOrganizationDrawer, setCustomerOrganization: setCustomerOrganization, customerData: customer, activeOrganization: activeOrganization, setShowEditCustomerModal: setShowEditCustomerModal })),
        showCustomerOrganizationDrawer && customerOrganization && (React.createElement(CustomerOrganizationDrawer, { setOpen: () => {
                setShowCustomerOrganizationDrawer(false);
            }, setShowCustomerDrawer: setShowCustomerDrawer, setCustomer: setCustomer, customerOrganizationData: customerOrganization, activeOrganization: activeOrganization, rates: orgRates, open: true, setToastText: setToastText })),
        toastText && (React.createElement(Toast, { status: "success", headerText: "Changes successfully saved", bodyText: toastText, close: () => setToastText('') }))));
};
function CustomerSettingsTabs({ tabs, tabIndex, setTabIndex, setPageNumber }) {
    return (React.createElement(Tab.Group, { onChange: (index) => {
            setTabIndex(index);
            setPageNumber(0);
        } },
        React.createElement(Tab.List, { className: "border-b border-gray-300" }, tabs.map((tab, index) => (React.createElement(Tab, { key: index, "aria-label": index === 0 ? 'Customers Tab' : 'Organizations Tab', className: classNames('ml-7 py-6 text-base text-blue-800 font-medium hover:text-blue-950', { 'border-b-2 border-blue-900 text-blue-950': tabIndex === index }), style: { outline: 'none' } }, tab))))));
}
function CustomerInfoCell({ index, customer, user }) {
    const name = (customer.data().userName && customer.data().userName !== ' '
        ? customer.data().userName
        : user.data().name) || '';
    return (React.createElement("td", { className: "pl-12 w-1/3 whitespace-no-wrap text-sm leading-5" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement(InitialAvatar, { userName: name, index: index }),
            React.createElement("div", { className: "pl-3 text-sm leading-6 font-medium" },
                React.createElement("div", { className: "text-gray-850" }, name),
                React.createElement("div", { className: "text-gray-650" }, user.data().email)))));
}
function InitialAvatar({ userName, index }) {
    const [firstName, lastName] = userName === null || userName === void 0 ? void 0 : userName.split(' ');
    return (React.createElement("div", { className: `${memberStyles[index % memberStyles.length]} w-10 h-10 rounded-full flex items-center justify-center text-white text-sm leading-6 font-semibold uppercase` },
        React.createElement("span", null,
            firstName && firstName.charAt(0),
            lastName && lastName.charAt(0))));
}
function ActionButton({ id, onClick }) {
    return (React.createElement("button", { className: "p-3 hover:bg-gray-200 rounded-md", id: id, onClick: e => {
            e.stopPropagation();
            onClick();
        } },
        React.createElement(PencilIcon, { color: "#667A8C" })));
}
export default Customers;
