import React, { useState } from 'react';
import AdditionalFeeUpdateForm from './additionalFeeUpdateForm';
import AdditionalFeesHeader from './AdditionalFeesSettingsHeader';
import AdditionalFeesTable from './AdditionalFeesTable';
export default function AdditionalFees({ activeOrganization }) {
    const [editedAdditionalFee, setEditedAdditionalFee] = useState();
    const [editedAdditionalFeeIndex, setEditedAdditionalFeeIndex] = useState(-1);
    return (React.createElement(React.Fragment, null,
        editedAdditionalFee && (React.createElement(AdditionalFeeUpdateForm, { editedAdditionalFeeIndex: editedAdditionalFeeIndex, editedAdditionalFee: editedAdditionalFee, activeOrganization: activeOrganization, closeForm: () => setEditedAdditionalFee(undefined) })),
        React.createElement("div", { className: "bg-gray-50 flex flex-col", style: { maxHeight: 790 } },
            React.createElement(AdditionalFeesHeader, { setEditedAdditionalFee: setEditedAdditionalFee, activeOrganization: activeOrganization }),
            React.createElement("div", { className: "flex-1 overflow-scroll" },
                React.createElement(AdditionalFeesTable, { setEditedAdditionalFeeIndex: setEditedAdditionalFeeIndex, setEditedAdditionalFee: setEditedAdditionalFee, activeOrganization: activeOrganization, additionalFees: activeOrganization.data().additionalFees || [] })))));
}
