var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import algoliasearch from 'algoliasearch';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { stripHtml } from 'utils';
import { getSubdomain } from 'utils/urls';
import { getCounties } from 'lib/utils/counties';
import { State } from 'lib/enums';
import { XIcon, DeleteIcon, WarningIcon, InformationCircle, PencilIcon } from 'icons';
import SettingsHeader from '../../SettingsHeader';
import { ALGOLIA_CONFIG } from '../../../../constants';
/**
 * Note: when updating this also update SUBCATEGORY_ID_CACHE
 */
export const DEFAULT_SELECTABLE_TYPES = [
    'Abandoned Property',
    'Administrators Notice',
    'Administrators Sale',
    'Adoption Notices',
    'Adoption',
    'Alcohol Permit Hearing Notices',
    'Amended and Restated Articles',
    'Annual Statement',
    'Application for Water',
    'Application Letters of Administration',
    'Articles of Amendment to Articles of Organization',
    'Articles of Dissolution',
    'Articles of Incorporation',
    'Articles of Organization',
    'Assumed Names',
    'Bid Notices and Requests for Services',
    'Bid Proposals',
    'Bids & Proposals',
    'Bond Issue',
    'Business Name',
    'Change of Name',
    'Child Adoption',
    'Child In Need of Care',
    'Citation by Publication',
    'Claims Heirs Intestate',
    'Claims',
    'Court Filings',
    'Delinquent Taxpayers',
    'Denial of Will',
    'Divorce Petition',
    'Divorce',
    'Election Machine Testing',
    'Election',
    'Elections',
    'Environmental Report',
    'Estate (Probate) Filings',
    'Final Account Supervised',
    'Foreclosure Sale',
    'Foreclosure Sales – Commissioner (Judicial) Sales',
    'Foreclosure Sales – Miscellaneous',
    'Foreclosure Sales – Non-judicial Sales',
    "Foreclosure Sales – US Marshall's Sales",
    'General Election',
    'Government Publications - Election Notices',
    'Government Publications – Notices of Hearings',
    'Government Publications – Notices of Rule Making',
    'Government Publications – Ordinances',
    'Government Publications – Tax Notices',
    'Heirs Only',
    'Invitation to Comment',
    'Legal Notice',
    'Meeting Agenda',
    'Meeting Minutes',
    'Miscellaneous Notices',
    'Miscellaneous',
    'Mortage Foreclosure',
    'Name Change',
    'Notice of Action',
    'Notice of Application',
    'Notice of Auction',
    'Notice of Availability',
    'Notice of Development',
    'Notice of Election',
    'Notice of Equalization',
    'Notice of Final Settlement',
    'Notice of Gaming Chip Redemption',
    'Notice of Hearing',
    'Notice of Non-Compliance',
    'Notice of Nondiscriminatory Policy',
    'Notice of Nuisance',
    'Notice of Proposed Budget',
    'Notice of Purchase of Property',
    'Notice of Regulatory Hearing',
    'Notice of Sale',
    'Notice of Seizure/Forfeiture',
    'Notice of Suit',
    "Notice of Trustee's Sale",
    'Notice of Vacancy',
    'Notice to Creditors',
    'Notice to Cut Weeds',
    'Official Notices',
    'Omitted Heirs',
    'Ordinance',
    'Parental Action',
    'Personal Property',
    'Personal',
    'Pit Closure',
    'Presumption of Death',
    'Primary Election',
    'Probate Notice',
    'Probate',
    'Proceedings',
    'Public Auction',
    'Public Health',
    'Public Hearing',
    'Public Sales and Auctions, Notices of',
    'Request for Bid',
    'Request for Bids',
    'Request for Candidates',
    'Request for Comments',
    'Request for Proposal',
    'Request for Qualifications',
    'Resolution',
    'Rezoning',
    'Saltwater Injection',
    'School Elections',
    'Sheriff Sale',
    'Special Election',
    'Special Notices',
    'Special Permit for Construction',
    'Special Use Permit',
    'Statement of Non-Discrimination',
    'Summary Administration',
    'Summons',
    'Supplemental Proceedings',
    'Trustee Sale',
    'Trustee Sales',
    'Upload Notice',
    'Vehicle Auctions',
    'Verified Report Independent',
    'Voter Registration',
    'Warnings to Appear – Circuit Court Proceedings',
    'Warnings to Appear – Divorce Proceedings',
    'Warnings to Appear – Foreclosures',
    'Warnings to Appear – Miscellaneous',
    'Warnings to Appear – Municipal Court Proceedings',
    'Warnings to Appear – Quiet Title Actions',
    'Warnings to Appear – US District Court Proceedings',
    'Water Rights',
    'Well Abandonment',
    'Well Extension',
    'Will and Claims',
    'Will Only',
    'Zoning and Planning'
];
const WAPO_SELECTABLE_TYPES = [
    'Bids & Proposals',
    'Legal Notice',
    'Official Notices',
    'Special Notices',
    'Trustee Sale'
];
const SELECTABLE_STATES = ['DC', 'Maryland', 'Pennsylvania', 'Virginia'];
const SELECTABLE_COUNTYS = [
    'Anne Arundel Co.',
    'Calvert Co.',
    'Charles Co.',
    'Culpeper Co.',
    'Fairfax Co.',
    'Fauquier Co.',
    'Frederick Co.',
    'Howard Co.',
    'Loudon Co.',
    'Montgomery Co.',
    'Orange Co.',
    'Philadelphia Co.',
    "Prince George's Co.",
    'Prince William Co.',
    'Spotsylvania Co.',
    'Stafford Co.',
    'Washington'
];
const IS_WAPO = getSubdomain() === 'wapo-admin';
const selectableTypes = IS_WAPO
    ? WAPO_SELECTABLE_TYPES
    : DEFAULT_SELECTABLE_TYPES;
const NoticeListItem = ({ filterDeletedNotice, notice }) => {
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [publishedDate, setPublishedDate] = useState('');
    const [noticeType, setNoticeType] = useState('');
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [text, setText] = useState('');
    const [publicationMethod, setPublicationMethod] = useState('');
    const [shouldShowPublicationMethod, setShouldShowPublicationMethod] = useState(false);
    const deleteNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Firebase.firestore()
            .collection(Collections.publicNotices)
            .doc(notice.objectID)
            .delete();
        setShowDelete(false);
        filterDeletedNotice(notice);
    });
    const saveNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!publishedDate) {
            console.error('Unable to save notice due to missing publication date');
            return;
        }
        const updates = {
            publishedTimestamp: new Date(publishedDate).getTime(),
            publishedDate,
            noticeType,
            county,
            state,
            text
        };
        if (shouldShowPublicationMethod) {
            updates.publicationMethod = publicationMethod;
        }
        yield Firebase.firestore()
            .collection(Collections.publicNotices)
            .doc(notice.objectID)
            .update(updates);
        setShowEdit(false);
    });
    useEffect(() => {
        if (!notice)
            return;
        const loadNotice = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const firestoreNotice = yield Firebase.firestore()
                .collection(Collections.publicNotices)
                .doc(notice.objectID)
                .get();
            setPublishedDate((_a = firestoreNotice.data()) === null || _a === void 0 ? void 0 : _a.publishedDate);
            setNoticeType((_b = firestoreNotice.data()) === null || _b === void 0 ? void 0 : _b.noticeType);
            setCounty(((_c = firestoreNotice.data()) === null || _c === void 0 ? void 0 : _c.county) || '');
            setState((_d = firestoreNotice.data()) === null || _d === void 0 ? void 0 : _d.state);
            setText((_e = firestoreNotice.data()) === null || _e === void 0 ? void 0 : _e.text);
            setShouldShowPublicationMethod(((_f = firestoreNotice.data()) === null || _f === void 0 ? void 0 : _f.state) === 'Florida');
            if ((_g = firestoreNotice.data()) === null || _g === void 0 ? void 0 : _g.publicationMethod) {
                setPublicationMethod((_h = firestoreNotice.data()) === null || _h === void 0 ? void 0 : _h.publicationMethod);
            }
        });
        void loadNotice();
    }, [notice === null || notice === void 0 ? void 0 : notice.objectID]);
    const { newspaperName, pdfUrl } = notice;
    return (React.createElement(React.Fragment, null,
        showEdit && (React.createElement("div", { className: "fixed z-20 inset-0 overflow-y-auto" },
            React.createElement("div", { className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
                React.createElement("div", { className: "fixed inset-0 transition-opacity" },
                    React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75" })),
                React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
                "\u200B",
                React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
                    React.createElement("div", { className: "hidden sm:block absolute top-0 right-0 pt-4 pr-4", onClick: () => setShowEdit(false) },
                        React.createElement("button", { type: "button", className: "text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150", "aria-label": "Close" },
                            React.createElement(XIcon, { className: "h-6 w-6" }))),
                    React.createElement("div", { className: "px-4 py-5 bg-white sm:p-6" },
                        React.createElement("div", null,
                            React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900" },
                                "Edit Public Notice in ",
                                newspaperName)),
                        React.createElement("div", { className: "mt-4" },
                            React.createElement("div", { className: "col-span-3 sm:col-span-2" },
                                React.createElement("label", { className: "block text-sm font-medium leading-5 text-gray-700" }, "Publication Date"),
                                React.createElement("div", { className: "box-border rounded border border-gray-300" },
                                    React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                                        React.createElement(DatePicker, { label: "", value: publishedDate ? new Date(publishedDate) : new Date(), placeholder: "MM/DD/YYYY", className: `date-picker-publish`, InputProps: {
                                                disableUnderline: true,
                                                startAdornment: (React.createElement("svg", { width: "60", height: "38", className: "border-r border-gray-300 mr-4" },
                                                    React.createElement("svg", { width: "42", height: "38", viewBox: "0 0 42 38", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                                        React.createElement("path", { d: "M0 2C0 0.89543 0.895431 0 2 0H42V38H2C0.89543 38 0 37.1046 0 36V2Z", fill: "#F9FAFB" })),
                                                    React.createElement("svg", { x: "11.5", y: "11", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                                        React.createElement("path", { d: "M5.66667 4.83333V1.5V4.83333ZM12.3333 4.83333V1.5V4.83333ZM4.83333 8.16667H13.1667H4.83333ZM3.16667 16.5H14.8333C15.2754 16.5 15.6993 16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5 4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333 3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738 1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072 16.3244 2.72464 16.5 3.16667 16.5Z", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))))
                                            }, autoOk: true, onChange: newDate => {
                                                if (newDate) {
                                                    setPublishedDate(moment(newDate).format('MM/DD/YYYY'));
                                                }
                                            } }))))),
                        React.createElement("div", { className: "mt-4" },
                            React.createElement("label", { className: "block text-sm leading-5 font-medium text-gray-700" }, "Notice Type"),
                            React.createElement("select", { className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: noticeType, onChange: e => setNoticeType(e.target.value) }, selectableTypes.sort().map((label, i) => (React.createElement("option", { key: i, value: label }, label))))),
                        IS_WAPO && (React.createElement("div", { className: "mt-4" },
                            React.createElement("label", { className: "block text-sm leading-5 font-medium text-gray-700" }, "State"),
                            React.createElement("select", { className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: state, onChange: e => setState(e.target.value) }, SELECTABLE_STATES.map((label, i) => (React.createElement("option", { key: i, value: label }, label)))))),
                        !IS_WAPO && (React.createElement("div", { className: "mt-4" },
                            React.createElement("label", { className: "block text-sm leading-5 font-medium text-gray-700" }, "County"),
                            React.createElement("select", { className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: county, onChange: e => setCounty(e.target.value) }, getCounties(state).map((label, i) => (React.createElement("option", { key: i, value: label }, label)))))),
                        IS_WAPO && (React.createElement("div", { className: "mt-4" },
                            React.createElement("label", { className: "block text-sm leading-5 font-medium text-gray-700" }, "County"),
                            React.createElement("select", { className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: county, onChange: e => setCounty(e.target.value) }, SELECTABLE_COUNTYS.map((label, i) => (React.createElement("option", { key: i, value: label }, label)))))),
                        shouldShowPublicationMethod && (React.createElement("div", { className: "mt-4" },
                            React.createElement("label", { className: "block text-sm leading-5 font-medium text-gray-700" }, "Publication Method"),
                            React.createElement("select", { className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: publicationMethod, onChange: e => setPublicationMethod(e.target.value) },
                                React.createElement("option", { key: "print-and-website", value: "print and website" }, "Print and website"),
                                React.createElement("option", { key: "website-only", value: "website only" }, "Website only")))),
                        React.createElement("div", { className: "mt-4" },
                            React.createElement("label", { htmlFor: "about", className: "block text-sm leading-5 font-medium text-gray-700" }, pdfUrl ? 'PDF' : 'Text'),
                            pdfUrl && (React.createElement("div", { className: "flex" },
                                React.createElement("div", { className: "w-0 flex-1 flex items-center" },
                                    React.createElement("svg", { className: "flex-shrink-0 h-5 w-5 text-gray-400", viewBox: "0 0 20 20", fill: "currentColor" },
                                        React.createElement("path", { fillRule: "evenodd", d: "M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z", clipRule: "evenodd" })),
                                    React.createElement("span", { className: "ml-2 flex-1 w-0 truncate" }, "Uploaded PDF")),
                                React.createElement("div", { className: "ml-4 flex-shrink-0" },
                                    React.createElement("div", { className: "cursor-pointer font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out", onClick: () => window.open(pdfUrl) }, "Download")))),
                            !pdfUrl && (React.createElement("div", { className: "rounded-md shadow-sm" },
                                React.createElement("textarea", { id: "about", rows: 10, className: "form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5", placeholder: "you@example.com", value: text, onChange: e => setText(e.target.value) })))),
                        React.createElement("div", { className: "mt-4" },
                            React.createElement("div", { className: "flex justify-end" },
                                React.createElement("span", { className: "inline-flex rounded-md shadow-sm" },
                                    React.createElement("button", { type: "button", className: "py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out", onClick: () => setShowEdit(false) }, "Cancel")),
                                React.createElement("span", { className: "ml-3 inline-flex rounded-md shadow-sm" },
                                    React.createElement("button", { type: "submit", className: "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out", onClick: saveNotice }, "Save"))))))))),
        showDelete && (React.createElement("div", { className: "fixed z-10 inset-0 overflow-y-auto" },
            React.createElement("div", { className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
                React.createElement("div", { className: "fixed inset-0 transition-opacity" },
                    React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75" })),
                React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
                "\u200B",
                React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
                    React.createElement("div", { className: "hidden sm:block absolute top-0 right-0 pt-4 pr-4", onClick: () => setShowDelete(false) },
                        React.createElement("button", { type: "button", className: "text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150", "aria-label": "Close" },
                            React.createElement(XIcon, { className: "h-6 w-6" }))),
                    React.createElement("div", { className: "sm:flex sm:items-start" },
                        React.createElement("div", { className: "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10" },
                            React.createElement(WarningIcon, { className: "h-6 w-6 text-red-600" })),
                        React.createElement("div", { className: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
                            React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900", id: "modal-headline" }, "Delete Public Notice"),
                            React.createElement("div", { className: "mt-2" },
                                React.createElement("p", { className: "text-sm leading-5 text-gray-500" }, "Are you sure you want to delete this notice? It will no longer appear on the public notice website for your state.")))),
                    React.createElement("div", { className: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" },
                        React.createElement("span", { className: "flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto", onClick: deleteNotice },
                            React.createElement("button", { type: "button", className: "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5" }, "Delete")),
                        React.createElement("span", { className: "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto", onClick: () => setShowDelete(false) },
                            React.createElement("button", { type: "button", className: "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5" }, "Cancel"))))))),
        React.createElement("li", { className: "col-span-1 bg-white rounded-lg shadow" },
            React.createElement("div", { className: "w-full flex items-center justify-between p-6 space-x-6" },
                React.createElement("div", { className: "flex-1 truncate" },
                    React.createElement("div", { className: "flex items-center space-x-3" },
                        React.createElement("h3", { className: "text-gray-900 text-sm leading-5 font-medium truncate flex-1" },
                            newspaperName,
                            " - ",
                            publishedDate),
                        React.createElement("span", { className: "flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full" }, noticeType)),
                    React.createElement("p", { className: "mt-1 text-gray-500 text-sm leading-5 h-20 whitespace-pre-line" },
                        pdfUrl && 'PDF Upload:\n\n',
                        stripHtml(text)))),
            React.createElement("div", { className: "border-t border-gray-200" },
                React.createElement("div", { className: "-mt-px flex" },
                    React.createElement("div", { className: "w-0 flex-1 flex border-r border-gray-200" },
                        React.createElement("div", { className: "cursor-pointer hover:bg-gray-50 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150", onClick: () => setShowDelete(true) },
                            React.createElement(DeleteIcon, { className: "w-5 h-5 text-gray-400" }),
                            React.createElement("span", { className: "ml-3" }, "Delete"))),
                    React.createElement("div", { className: "-ml-px w-0 flex-1 flex" },
                        React.createElement("div", { className: "cursor-pointer hover:bg-gray-50 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150", onClick: () => setShowEdit(true) },
                            React.createElement(PencilIcon, { className: "w-5 h-5 text-gray-400" }),
                            React.createElement("span", { className: "ml-3" }, "Edit"))))))));
};
const PAGE_SIZE = 10;
const client = algoliasearch(ALGOLIA_CONFIG.app, ALGOLIA_CONFIG.key);
const index = client.initIndex(ALGOLIA_CONFIG.index);
const FTPSettings = ({ organization }) => {
    const [notices, setNotices] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [totalHits, setTotalHits] = useState(0);
    const updateNotices = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { hits, nbHits } = (yield index.search(searchValue, {
            offset: page * PAGE_SIZE,
            length: PAGE_SIZE,
            filters: `newspaperName:"${organization.data().name}" AND state:"${(_a = State.by_value(organization.data().state)) === null || _a === void 0 ? void 0 : _a.label}"`
        }));
        setNotices(hits);
        setHasMore((page + 1) * PAGE_SIZE < nbHits);
        setTotalHits(nbHits);
    });
    useEffect(() => {
        void updateNotices();
    }, [page]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow mb-20" },
            React.createElement("div", null,
                React.createElement(SettingsHeader, { header: "Archive Settings", description: "View, edit and delete previously uploaded notices." },
                    React.createElement("button", { type: "button", className: "px-3 bg-white box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => window.open('https://help.column.us/') },
                        React.createElement("span", { className: "pr-2" },
                            React.createElement(InformationCircle, { className: "text-gray-750" })),
                        "Help"))),
            React.createElement("div", { className: "p-3 bg-gray-100", style: { minHeight: '60vh' } },
                React.createElement("div", { className: "my-5 max-w-sm ml-auto" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "mt-1 relative rounded-md shadow-sm flex" },
                            React.createElement("input", { className: "form-input block w-full sm:text-sm sm:leading-5", placeholder: "My keywords...", value: searchValue, onChange: e => setSearchValue(e.target.value), maxLength: 500 }),
                            React.createElement("button", { type: "button", className: "ml-5 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150", onClick: () => {
                                    setPage(0);
                                    void updateNotices();
                                } }, "Search")))),
                React.createElement("ul", { className: "grid grid-cols-1 gap-6 sm:grid-cols-2" }, notices.map((notice, i) => (React.createElement(NoticeListItem, { key: i, updateNotices: updateNotices, notice: notice, filterDeletedNotice: (deletedNotice) => __awaiter(void 0, void 0, void 0, function* () {
                        const filtered = notices.filter(n => n.objectID !== deletedNotice.objectID);
                        setNotices(filtered);
                    }) }))))),
            React.createElement("nav", { className: "px-4 py-3 sm:rounded-lg flex items-center justify-between border-t border-gray-200 sm:px-6" },
                React.createElement("div", { className: "hidden sm:block" },
                    React.createElement("p", { className: "text-sm leading-5 text-gray-700" },
                        "Showing results",
                        React.createElement("span", { className: "ml-1 mr-1 font-medium" }, page * PAGE_SIZE + 1),
                        "to",
                        React.createElement("span", { className: "ml-1 font-medium" }, page * PAGE_SIZE + notices.length),
                        ' ',
                        "of",
                        React.createElement("span", { className: "ml-1 font-medium" }, totalHits))),
                React.createElement("div", { className: "flex-1 flex justify-between sm:justify-end" },
                    React.createElement("div", { className: `${page > 0 ? 'cursor-pointer' : 'cursor-not-allowed'} relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => {
                            if (page < 1)
                                return;
                            setPage(page - 1);
                            void updateNotices();
                        } }, "Previous"),
                    React.createElement("div", { className: `${hasMore ? 'cursor-pointer' : 'cursor-not-allowed'} ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => {
                            if (!hasMore)
                                return;
                            setPage(page + 1);
                            void updateNotices();
                        } }, "Next"))))));
};
export default FTPSettings;
