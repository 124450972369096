var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from 'components/SubmitButton';
import InputMask from 'react-input-mask';
import EToast from 'components/EToast';
import { minLastNameLength } from 'routes/register/user/Register';
import { State } from 'lib/enums';
import ChangePassword from './user_settings';
import SettingsHeader from './SettingsHeader';
import { Edit } from './icons';
import SelectDropdown from '../placeScroll/SelectDropdown';
const SettingsForm = ({ user, userAuth }) => {
    const [userData, setUserData] = useState({});
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isZipValid, setIsZipValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [changePassword, setChangePassword] = useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
    const updateField = (field) => (e) => {
        if (field === 'zipCode' && e.target.value.length > 5)
            return;
        setUserData(Object.assign(Object.assign({}, userData), { [field]: e.target.value || '' }));
    };
    const { t } = useTranslation();
    useEffect(() => {
        var _a;
        if (!((_a = user.data()) === null || _a === void 0 ? void 0 : _a.organization)) {
            if (userData.phone && !/[0-9]/.test(userData.phone))
                setUserData(Object.assign(Object.assign({}, userData), { phone: '' }));
            setIsPhoneValid(userData.phone ? !userData.phone.includes('_') : false);
        }
    }, [userData.phone]);
    useEffect(() => {
        var _a;
        if (!((_a = user.data()) === null || _a === void 0 ? void 0 : _a.organization)) {
            const zipRegex = /[0-9]{5}/;
            setIsZipValid(userData.zipCode ? zipRegex.test(userData.zipCode) : false);
        }
    }, [userData.zipCode]);
    useEffect(() => setUserData(user.data()), [user]);
    const openChangePasswordModal = () => {
        setChangePassword(true);
    };
    const closeChangePasswordModal = () => {
        setChangePassword(false);
    };
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                e.preventDefault();
                if (!isPhoneValid || !isZipValid)
                    return;
                if (Object.keys(errors).length > 0)
                    return;
                setLoading(true);
                // Update user (in users firestore collection) with other data
                yield user.ref.update(Object.assign(Object.assign({}, userData), { name: `${userData.firstName} ${userData.lastName}` }));
                setLoading(false);
            }) },
            React.createElement(SettingsHeader, { header: "User Info", description: "Tell us a bit more about yourself." }),
            React.createElement("div", { className: "px-4 py-5 sm:p-6" },
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "flex w-12/12 flex-row" },
                        React.createElement("div", { className: "flex-grow w-full flex-1" },
                            React.createElement("input", { className: "w-full appearance-none rounded relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", required: true, autoComplete: "given-name", id: "firstName", value: userData.firstName, onChange: updateField('firstName'), placeholder: "First Name" })),
                        React.createElement("div", { className: "w-3 flex-grow-0" }),
                        React.createElement("div", { className: "flex-grow w-full flex-1" },
                            React.createElement("input", { className: `w-full hide-arrows appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:${errors.last_name
                                    ? 'border-red-400 border-red-400'
                                    : 'border-blue-300'} focus:z-10 sm:text-sm sm:leading-5`, "data-testid": "circulation-size", required: true, autoComplete: "family-name", id: "lastName", value: userData.lastName, onChange: e => {
                                    const newValue = e.target.value;
                                    setUserData(Object.assign(Object.assign({}, userData), { lastName: newValue }));
                                    const error = newValue.length < minLastNameLength
                                        ? `Must be at least ${minLastNameLength} characters`
                                        : newValue.length > 30
                                            ? 'Cannot exceed 30 characters'
                                            : undefined;
                                    if (error)
                                        setErrors(Object.assign(Object.assign({}, errors), { last_name: error }));
                                    else {
                                        const newErrors = errors;
                                        delete newErrors.last_name;
                                        setErrors(newErrors);
                                    }
                                }, placeholder: "Last Name" }),
                            errors.last_name && (React.createElement("p", { className: "text-red-400" }, errors.last_name)))),
                    !(userData === null || userData === void 0 ? void 0 : userData.organization) && (React.createElement("div", null,
                        React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                            React.createElement("div", { className: "flex-grow w-full flex-1" },
                                React.createElement("input", { id: "organization", className: "appearance-none rounded w-full relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", value: userData.organizationName, placeholder: t('user-settings.org-name'), onChange: updateField('organizationName') }))),
                        React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                            React.createElement("div", { className: "flex-grow w-full flex-1" },
                                React.createElement("input", { className: "w-full appearance-none rounded relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "address", required: true, autoComplete: "address-line1", value: userData.address, onChange: updateField('address'), placeholder: "Address *" })),
                            React.createElement("div", { className: "w-3 flex-grow-0" }),
                            React.createElement("div", { className: "flex relative w-full flex-grow flex-1" },
                                React.createElement("input", { className: "w-full appearance-none rounded relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "address2", autoComplete: "address-line2", value: userData.addressLine2, onChange: updateField('addressLine2'), placeholder: "Address Line 2" }))),
                        React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                            React.createElement("div", { className: "flex-grow w-full flex-1" },
                                React.createElement("input", { className: "w-full appearance-none rounded relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "city", required: true, autoComplete: "address-level2", value: userData.city, onChange: updateField('city'), placeholder: "City *" })),
                            React.createElement("div", { className: "w-3 flex-grow-0" }),
                            React.createElement("div", { className: "flex relative w-full flex-grow flex-1" },
                                React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                                        if (selected) {
                                            setUserData(Object.assign(Object.assign({}, userData), { state: parseInt(selected.id, 10) }));
                                        }
                                    }, placeholder: "State *", options: State.items().map((state) => ({
                                        id: state.value,
                                        label: state.label
                                    })), selected: State.items().filter(item => {
                                        return item.value ===
                                            (typeof userData.state === 'number'
                                                ? userData.state
                                                : userData.state
                                                    ? parseInt(userData.state, 10)
                                                    : '')
                                            ? { id: item.value, label: item.label }
                                            : '';
                                    }), value: State.items().filter(item => {
                                        return item.value ===
                                            (typeof userData.state === 'number'
                                                ? userData.state
                                                : userData.state
                                                    ? parseInt(userData.state, 10)
                                                    : '')
                                            ? { id: item.value, label: item.label }
                                            : '';
                                    }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true }))),
                        React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                            React.createElement("div", { className: "flex relative w-full flex-grow flex-1" },
                                React.createElement("input", { className: `w-full appearance-none relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none ${isZipValid
                                        ? 'focus:shadow-outline-blue focus:border-blue-300'
                                        : 'border-red-400'} focus:z-10 sm:text-sm sm:leading-5`, id: "zipCode", required: true, value: userData.zipCode, name: "zipCode", autoComplete: "", onChange: updateField('zipCode'), placeholder: "Zip*" })),
                            React.createElement("div", { className: "w-3 flex-grow-0" }),
                            React.createElement("div", { className: "flex relative w-full flex-grow flex-1" }, !userData.organization && (React.createElement(InputMask, { mask: !/[0-9]/.test(userData.phone || '')
                                    ? ''
                                    : '(999) 999-9999', alwaysShowMask: false, className: `w-full appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5 
            ${isPhoneValid
                                    ? 'focus:shadow-outline-blue focus:border-blue-300'
                                    : 'border-red-400'}
            `, id: "phone", required: true, autoComplete: "tel-national", value: userData.phone, name: "phone", onChange: updateField('phone'), placeholder: "Phone *" })))))),
                    React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                        React.createElement("div", { className: "flex-grow w-full flex-1" },
                            React.createElement("input", { className: "w-full appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", required: true, autoComplete: "email", id: "email", type: "email", name: "email", value: userData.email, onChange: updateField('email'), disabled: true, placeholder: "Email" })),
                        React.createElement("div", { className: "w-3 flex-grow-0" }),
                        React.createElement("div", { className: "flex relative w-full flex-grow flex-1" },
                            React.createElement("input", { className: "w-full hide-arrows appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "password", value: '••••••', disabled: true, "aria-label": "password" }),
                            React.createElement("div", { className: "text-sm absolute right-0 top-0 mt-2 mr-2 leading-5 float-right" },
                                React.createElement("a", { 
                                    // eslint-disable-next-line no-script-url
                                    href: "#!", onClick: openChangePasswordModal, className: "font-sm uppercase flex relative bottom-7 right-2 text-gray-500 hover:text-gray-400 focus:outline-none focus:underline transition ease-in-out duration-150" },
                                    "change password",
                                    React.createElement("div", { className: "ml-2" },
                                        React.createElement(Edit, null)))))))),
            React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
                React.createElement(SubmitButton, { disabled: loading, loading: loading, text: "Save", type: "submit", id: "submit" }))),
        changePassword && (React.createElement(ChangePassword, { user: user, userAuth: userAuth, closeChangePasswordModal: closeChangePasswordModal, setShowPasswordResetModal: setShowPasswordResetModal })),
        showPasswordResetModal && (React.createElement(EToast, { message: "Success! Your password has been changed.", type: "success", close: () => {
                setShowPasswordResetModal(false);
                closeChangePasswordModal();
            } }))));
};
export default SettingsForm;
