import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import * as Sentry from '@sentry/browser';
import { Collections, Functions } from './lib/constants';
import FIREBASE_CONFIG from './config.firebase';
const Firebase = firebase.initializeApp(FIREBASE_CONFIG);
/**
 * Re-export key parts of firebase.firestore so that we only need to import
 * the firebase SDK in one file.
 */
export const Firestore = {
    FieldValue: firebase.firestore.FieldValue,
    Timestamp: firebase.firestore.Timestamp
};
/**
 * Re-export key parts of firebase.auth so that we only need to import
 * the firebase SDK in one file.
 */
export const FirebaseAuth = {
    EmailAuthProvider: firebase.auth.EmailAuthProvider
};
/**
 * Some corporate networks buffer traffic in a way that causes firestore calls to fail.
 * This setting should fix these failures by enabling long polling for users.
 *
 * See SuspenseErrorBoundary for where this flag gets set
 * and AuthSaga/fetchUser for where we catch this error
 *
 * See docs:
 * https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Settings#experimentalautodetectlongpolling
 */
try {
    const enableAutoDetectLongPolling = window.localStorage.getItem('enableAutoDetectLongPolling') === 'true';
    firebase.firestore().settings({
        experimentalAutoDetectLongPolling: enableAutoDetectLongPolling
    });
    Sentry.setTag('enableAutoDetectLongPolling', `${enableAutoDetectLongPolling}`);
}
catch (_a) {
    /**
     * Due to the way we mock firebase on the frontend (see mocksdk), the above
     * will throw an error during testing. This approach of catching the error
     * and silently continuing was preferable to hacking the mock.
     */
}
export { Collections, Functions };
/**
 * Get a native Firestore doc reference when an ERef won't work.
 */
export const getDocReference = (path) => {
    return firebase.firestore().doc(path);
};
if (process.env.NODE_ENV === 'development') {
    Firebase.functions()._url = function (name) {
        return `http://localhost:5001/${FIREBASE_CONFIG.projectId}/us-central1/${name}`;
    };
}
if (process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_FIREBASE_EMULATORS === 'true') {
    // These values should match the ports in firebase.json
    firebase.auth().useEmulator('http://localhost:9099');
    firebase.functions().useEmulator('localhost', 5001);
    firebase.firestore().useEmulator('localhost', 8001);
}
window.Firebase = Firebase;
export default Firebase;
