var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { getLayoutSettingsFromOrganization } from './basicLayoutSettingsUtils';
import BasicLayoutSettingsHeader from './BasicLayoutSettingsHeader';
import LayoutSettingsForm from './LayoutSettingsForm';
import TestNoticePreview from './TestNoticePreview';
/**
 * Controls basic settings for the layout of notices and enables previewing those settings
 */
export default function BasicLayoutSettings({ activeOrganization }) {
    const [updatedLayoutSettings, setUpdatedLayoutSettings] = useState(getLayoutSettingsFromOrganization(activeOrganization));
    const settingsAreUpdated = useMemo(() => safeStringify(updatedLayoutSettings) !==
        safeStringify(getLayoutSettingsFromOrganization(activeOrganization)), [updatedLayoutSettings]);
    return (React.createElement("div", { className: "bg-gray-50 flex flex-col", style: { maxHeight: 790 } },
        React.createElement(BasicLayoutSettingsHeader, { updateBasicLayoutSettings: () => __awaiter(this, void 0, void 0, function* () { return activeOrganization.ref.update(updatedLayoutSettings); }), activeOrganization: activeOrganization, updateEnabled: settingsAreUpdated }),
        React.createElement("div", { className: "flex-1 overflow-scroll" },
            React.createElement(LayoutSettingsForm, { setLayoutSettings: setUpdatedLayoutSettings, layoutSettings: updatedLayoutSettings }),
            React.createElement(TestNoticePreview, { activeOrganization: activeOrganization, layoutSettings: updatedLayoutSettings }),
            React.createElement("div", { className: "mb-24" }))));
}
