var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ColumnButton } from 'components/ColumnButton';
const CancelOrSubmitModal = ({ setOpen, header, body, children, id, noExitOutsideModal, overrideExitOutsideModal, onSubmit, width, maxWidth, disableOverflow, disablePrimaryButton, destructive, formId, tertiaryButtonText, primaryButtonText, primaryButtonId, overrideTertiaryClose, showLoadingSpinner, backgroundStyle, loadingMessage, overrideChildMargins, overrideEntablaturePadding }) => {
    const [loading, setLoading] = useState(false);
    const handleExit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (noExitOutsideModal)
            return;
        if (overrideExitOutsideModal) {
            yield overrideExitOutsideModal();
        }
        else {
            setOpen && setOpen(false);
        }
    });
    return (React.createElement("div", { id: id || 'cancel-or-submit-modal', className: `fixed z-100 inset-0 ${!disableOverflow ? 'overflow-hidden' : ''}` },
        React.createElement("div", { className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity", onClick: handleExit },
                React.createElement("div", { className: "absolute inset-0 bg-black opacity-50" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            React.createElement("div", { className: `inline-block align-bottom ${backgroundStyle || 'bg-white'} rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id || 'cancel-or-submit-modal'}-inner` },
                React.createElement("div", { className: `${overrideEntablaturePadding || 'p-6'} border-gray-300 ${(header || body) && 'border-b'}` },
                    header && (React.createElement("h3", { className: "text-xl leading-6 font-semibold text-gray-900", id: "modal-headline" }, header)),
                    body && (React.createElement("div", { className: "mt-2" },
                        React.createElement("span", { className: "text-sm font-medium leading-5 text-gray-700" }, body)))),
                React.createElement("div", { className: "overflow-scroll hide-scrollbar" },
                    React.createElement("div", { className: `${overrideChildMargins || 'mx-6'} sm:mt-0 text-left`, style: { maxHeight: '550px' } },
                        children,
                        loading ? React.createElement("div", null,
                            " ",
                            loadingMessage,
                            " ") : undefined)),
                React.createElement("div", { className: "flex justify-between py-5 px-6 border-t border-gray-300" },
                    React.createElement(ColumnButton, { size: "lg", buttonText: tertiaryButtonText, disabled: loading, onClick: () => {
                            if (overrideTertiaryClose) {
                                void overrideTertiaryClose();
                            }
                            else {
                                setOpen(false);
                            }
                        } }),
                    React.createElement(ColumnButton, { id: primaryButtonId, formId: formId, primary: true, destructive: destructive, size: "lg", disabled: disablePrimaryButton, buttonText: primaryButtonText, loading: showLoadingSpinner ? loading : undefined, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setLoading(true);
                            onSubmit && (yield onSubmit());
                            setLoading(false);
                        }) }))))));
};
export default CancelOrSubmitModal;
