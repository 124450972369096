import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, Box, Grid, FormHelperText } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { normalizeSpaces } from 'lib/helpers';
import ScrollStep from './ScrollStep';
const styles = () => createStyles({
    iconInput: {
        width: '100%',
        margin: '20px 0 20px'
    },
    iconInputWithError: {
        marginBottom: 0
    }
});
const ReferenceIdStep = ({ id, placementActions, activeStepId, next, previous, onDisabledStepClick, editing }) => {
    const [titleError, setTitleError] = useState(false);
    // Get the initial reference ID from redux. This is non-empty when
    // editing an existing notice or draft.
    const placementReferenceId = useSelector((state) => {
        return state.placement.referenceId;
    });
    const [referenceId, setReferenceId] = useState(placementReferenceId);
    const normalizedReferenceId = normalizeSpaces(referenceId);
    const complete = normalizedReferenceId
        ? normalizedReferenceId.length > 0
        : false;
    const LABELS = {
        TITLE: 'Name Your Notice',
        CAPTION: 'To keep track of your order, give your notice a name such as a case number, purchase order number, or title. This name will not appear in the printed publication.',
        PLACEHOLDER: 'I.e. Notice to Creditors, Jane Doe',
        MAXCHARS: 'Maximum 45 characters allowed.'
    };
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: () => {
            placementActions.confirmReferenceId(normalizedReferenceId);
            next();
        }, previous: previous, complete: complete, onDisabledStepClick: onDisabledStepClick, title: editing ? 'Edit Notice Name' : LABELS.TITLE, caption: LABELS.CAPTION },
        React.createElement(Grid, { container: true, direction: "row" },
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(Box, { ml: 0 },
                        React.createElement("input", { id: "notice-name", placeholder: LABELS.PLACEHOLDER, className: "mt-1 form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: referenceId || '', onChange: (e) => {
                                const maxCharLength = 45;
                                if (e.target.value.length > maxCharLength) {
                                    setTitleError(true);
                                    e.target.value = e.target.value.slice(0, maxCharLength);
                                }
                                else {
                                    setTitleError(false);
                                }
                                setReferenceId(e.target.value);
                            }, disabled: activeStepId !== 'reference-id' }),
                        titleError && React.createElement(FormHelperText, null, LABELS.MAXCHARS)))))));
};
export default withStyles(styles)(ReferenceIdStep);
