var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import Validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import api from 'api';
import AuthActions from 'redux/auth';
import { includeSearchParams, getLocationParams } from 'utils/urls';
import { OccupationType } from 'lib/enums';
import { getOpenInvitesForUser } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import FormError from '../../errors/FormError';
export const minLastNameLength = 2;
const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
const Register = ({ invite, user, push, authActions }) => {
    /**
     * if the user has clicked on an invite and gotten to this stage,
     * pendingInvites[0] will contain the invite they clicked on
     */
    const inviteData = invite === null || invite === void 0 ? void 0 : invite.data();
    const [isEditing] = useState(user || null);
    const [email, setEmail] = useState((inviteData && inviteData.email) || '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const [validator, setValidator] = useState({
        email: true,
        password: true,
        confirm_password: true,
        first_name: true,
        last_name: true
    });
    const [responseError, setResponseError] = useState('');
    const [errorClasses] = useState('border-red-500 text-xs');
    const TEXT = {
        min: `Must be at least ${minLastNameLength} characters`,
        max: 'Must not exceed 30 characters.',
        email: 'Invalid email address.',
        password: 'Password must be at least 9 characters.',
        weak_password: 'Password must contain letters, numbers, and symbols.',
        mismatch: 'Passwords must match.',
        validate_all: 'Please ensure all fields are complete.'
    };
    const reg = /.*([0-9]|[!"#$%&\\'()*+,-./:;<=>?@^_`{|}~]).*$/;
    const organizationId = inviteData && inviteData.organizationId;
    const occupationParam = getLocationParams().get('OccupationType');
    const anonymousUid = getLocationParams().get('uid');
    const [occupation, setOccupation] = useState();
    useEffect(() => {
        if (user &&
            (user.data().organization !== null ||
                user.data().occupation === OccupationType.individual.value))
            push('/');
    });
    /**
     * The Function Flashes the error message (by default) for 5 secs
     * @param message:string Message to flash
     */
    const flashResponseError = (message, timeout = true) => {
        setResponseError(message);
        if (timeout) {
            setTimeout(() => {
                setResponseError('');
            }, 5000);
        }
    };
    /* flashResponseError ENDS */
    useEffect(() => {
        if (exists(user)) {
            const { firstName, lastName, email } = user.data();
            setEmail(email);
            setFirstName(firstName);
            setLastName(lastName);
        }
    }, [user]);
    useEffect(() => {
        if (!occupationParam)
            return;
        const selectedOccupation = OccupationType.items().find(e => e.label.toLowerCase() === occupationParam.toLowerCase());
        if (selectedOccupation) {
            setOccupation(selectedOccupation);
        }
    }, [occupationParam]);
    /**
     * Calls the Firebase Function registerUser with set parameter to complete 1st step for user registration
     * @throws LoginTokenError | Internal System Error
     */
    const registerUser = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!Object.values(validator).every(Boolean)) {
            flashResponseError(TEXT.validate_all);
            return;
        }
        if (isEditing && user) {
            yield user.ref.update({ firstName, lastName });
            push('/');
        }
        else {
            setLoading(true);
            const req = {
                firstName,
                lastName,
                email,
                password,
                anonymousUid,
                organizationId,
                occupation: occupation === null || occupation === void 0 ? void 0 : occupation.value
            };
            const { success, token, error, userId } = yield api.post('users/register', req);
            if (success) {
                authActions.loginToken(token);
                const ctx = getFirebaseContext();
                const userSnap = (yield ctx
                    .usersRef()
                    .doc(userId)
                    .get());
                if ((invite === null || invite === void 0 ? void 0 : invite.id) || (yield getOpenInvitesForUser(ctx, userSnap)).length) {
                    push('/notices/');
                    return;
                }
                push(includeSearchParams('/'));
            }
            else {
                console.table(error);
                setLoading(false);
                if (error.status === 'INVALID_ARGUMENT')
                    flashResponseError(error.message, false);
                else
                    flashResponseError('There was a problem processing your request, please check your connection, refresh and try again');
                console.error(error);
            }
        }
    });
    /* registerUser Ends */
    const loadField = (type, id, testId, onChange, autoComplete, value, placeholder, validation, disabled = false) => {
        return (React.createElement("input", { id: id, "data-testid": testId, onChange: onChange, type: type, autoComplete: autoComplete, value: value, disabled: disabled, placeholder: placeholder, className: `form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${validation ? '' : errorClasses}
        ${disabled ? `bg-gray-200` : ''}
        ` }));
    };
    const loadErrorField = (validator, error, classes = '') => {
        return (React.createElement("p", { className: `text-red-500 text-xs ${classes} ${validator ? `hidden` : ''}` }, error));
    };
    const isFloridaDomain = window.location.href.includes('florida');
    return (React.createElement("div", { className: "overflow-hidden" },
        React.createElement("div", { className: "hidden md:block h-0 float-right" },
            React.createElement("img", { className: "-mt-1/3 -mr-20", src: "https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff" })),
        React.createElement("div", { className: "hidden md:block h-0 float-left" },
            React.createElement("img", { className: "-ml-8 mt-6/12", src: "https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff" })),
        React.createElement("div", { className: "min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8" }, loading ? (React.createElement(LoadingState, null)) : (React.createElement("div", { className: "max-w-md w-full" },
            React.createElement("form", { className: "mt-8", onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    e.preventDefault();
                    yield registerUser();
                }) },
                React.createElement("div", null,
                    React.createElement("img", { src: isFloridaDomain
                            ? '/FPF-color.png'
                            : 'https://firebasestorage.googleapis.com/v0/b/enotice-demo-8d99a.appspot.com/o/column-landing%2FLOGO_BLUE_1_BLUE_2.png?alt=media&token=4ef89835-3a6c-4df3-be53-570081b8e4c8', alt: "Column", className: "mx-auto h-7 w-auto" }),
                    React.createElement("p", { className: "mt-10 text-gray-900 text-center text-2xl leading-8 font-semibold" }, "Create your account"),
                    React.createElement("p", { className: "mt-4 text-center text-sm font-medium leading-5 text-gray-600" }, isFloridaDomain
                        ? 'Florida Community News Fund'
                        : 'Let’s future-proof public notice together.')),
                React.createElement("div", null,
                    React.createElement("div", { className: "mt-10 grid grid-cols-1 gap-y-6 gap-x-2 sm:grid-cols-6" },
                        React.createElement("div", { className: "sm:col-span-3" },
                            React.createElement("div", { className: "mt-1 rounded-md shadow-sm" }, loadField('input', 'first_name', 'firstName', e => {
                                setFirstName(e.target.value);
                                setValidator(Object.assign(Object.assign({}, validator), { first_name: Validator.isLength(e.target.value, {
                                        min: 0,
                                        max: 30
                                    }) }));
                            }, 'first name', firstName, 'First Name', validator.first_name)),
                            loadErrorField(validator.first_name, firstName.length > 3 ? TEXT.max : TEXT.min)),
                        React.createElement("div", { className: "sm:col-span-3" },
                            React.createElement("div", { className: "mt-1 rounded-md shadow-sm" }, loadField('input', 'last_name', 'lastName', e => {
                                setLastName(e.target.value);
                                setValidator(Object.assign(Object.assign({}, validator), { last_name: Validator.isLength(e.target.value, {
                                        min: minLastNameLength,
                                        max: 30
                                    }) }));
                            }, 'last name', lastName, 'Last Name', validator.last_name)),
                            loadErrorField(validator.last_name, lastName.length > minLastNameLength ? TEXT.max : TEXT.min)))),
                React.createElement("div", { className: "mt-3 flex items-center justify-between" }, loadField('email', 'email', 'email', e => {
                    setEmail(e.target.value);
                    /*
                      This condition ensures that error message will be displayed
                        until user beings to change the email input field
                    */
                    if (responseError === 'A user with this email already exists.') {
                        setResponseError('');
                    }
                    setValidator(Object.assign(Object.assign({}, validator), { email: Validator.isEmail(e.target.value) }));
                }, 'email', email, 'Email Address', validator.email, (inviteData && !!inviteData.email) || !!isEditing)),
                loadErrorField(validator.email, TEXT.email),
                !isEditing && (React.createElement("div", null,
                    React.createElement("div", { className: "mt-3 flex items-center justify-between" }, loadField('password', 'create_password', 'createPassword', e => {
                        setPassword(e.target.value);
                        setValidator(Object.assign(Object.assign({}, validator), { password: Validator.isLength(e.target.value, { min: 9 }) &&
                                reg.test(e.target.value) }));
                    }, 'new-password', password, 'Create Password', validator.password)),
                    loadErrorField(validator.password, password.length >= 9 ? TEXT.weak_password : TEXT.password),
                    React.createElement("div", { className: "mt-3 flex items-center justify-between" }, loadField('password', 'confirm_password', 'confirmPassword', e => {
                        setConfirmPassword(e.target.value);
                        setValidator(Object.assign(Object.assign({}, validator), { confirm_password: password === e.target.value }));
                    }, 'new-password', confirmPassword, 'Confirm Password', validator.confirm_password)),
                    loadErrorField(validator.confirm_password, TEXT.mismatch))),
                React.createElement("div", { className: "mt-6" },
                    React.createElement("button", { disabled: !firstName ||
                            !lastName ||
                            !email ||
                            !password ||
                            !confirmPassword, type: "submit", id: "submit", className: `group relative w-full flex justify-center py-2 px-4 border border-transparent text-base leading-5 font-medium rounded-md text-white bg-black hover:bg-gray-600 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out` },
                        React.createElement("span", { className: "absolute left-0 inset-y-0 flex items-center pl-3" },
                            React.createElement("svg", { className: "h-5 w-5 text-gray-700 group-hover:text-gray-400 active:bg-gray-700 transition ease-in-out duration-150", fill: "currentColor", viewBox: "0 0 20 20" },
                                React.createElement("path", { fillRule: "evenodd", d: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z", clipRule: "evenodd" }))),
                        isEditing ? `Update Account` : `Create Account`))),
            responseError && React.createElement(FormError, { error: responseError }),
            React.createElement("div", { className: "mt-6" },
                React.createElement("div", { className: "relative" },
                    React.createElement("div", { className: "relative flex justify-center text-base leading-5" },
                        React.createElement("hr", { className: "flex-1 text-gray-700 mt-3 px-10" }),
                        React.createElement("p", { className: "flex-auto text-center text-gray-700 text-base" }, "Already have an account?"),
                        React.createElement("hr", { className: "flex-1 text-gray-700 mt-3 px-10  " })))),
            React.createElement("div", { className: "mt-6" },
                React.createElement("button", { type: "button", onClick: () => push(includeSearchParams('/login/')), id: "back-to-signin", className: "group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-base leading-5 font-medium rounded-md hover:bg-gray-200 focus:outline-none active:text-white focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out" }, "Back to Sign In")))))));
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
