import moment from 'moment';
import { XMLReplacementTagType } from '../enums';
import { headers } from '../indesign/helpers';
import { EHandlebars } from './shared';
const castXMLReplacementTagType = XMLReplacementTagType;
const formatStrReplace = (formatString, { numRuns, publicationDate }) => {
    const { replacements } = castXMLReplacementTagType.dynamicHeader;
    const numRunsMatcher = new RegExp(replacements.numRuns.regexMatch, 'g');
    const dateMatcher = new RegExp(replacements.dateString.regexMatch, 'g');
    return formatString
        .replace('&', '&amp;')
        .replace(numRunsMatcher, numRuns)
        .replace(dateMatcher, (a, b) => moment(publicationDate).format(b));
};
/**
 * @param {headerFormatString} string conversion format
 * @param {publicationDates} Date[] list of dates
 * @returns A list containing formated dates Array<string>
 */
const generateV1Headers = (headerFormatString, publicationDates) => {
    if (!headerFormatString)
        return [];
    const result = new Array();
    publicationDates.forEach((date) => {
        const headerFilledString = formatStrReplace(headerFormatString, {
            numRuns: publicationDates.length,
            publicationDate: date
        });
        result.push(headerFilledString);
    });
    return result;
};
export const { addXML } = headers;
export const generate = (format, dates, noticeType) => {
    if (!format)
        return [''];
    const isV1Header = format.slice(0, 3) === 'V1:';
    if (isV1Header)
        return generateV1Headers(format.slice(3, format.length), dates.map(date => date.toDate())).map(addXML);
    const compiled = EHandlebars.compile(format);
    return [addXML(compiled({ dates, noticeType: noticeType || {} }))];
};
