import React from 'react';
const FOOTER_OFFSET = 20;
const Report = ({ reportUrl, height }) => {
    return (React.createElement("div", { style: {
            height,
            overflow: 'hidden'
        } },
        React.createElement("iframe", { title: "studio-report", style: {
                height: `calc(${height} + ${FOOTER_OFFSET}px)`,
                width: '100%'
            }, src: reportUrl, frameBorder: "0" })));
};
export default Report;
