var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ColumnButton } from 'components/ColumnButton';
import { WarningIcon } from 'icons';
import LoadingState from 'components/LoadingState';
import FormSwitch from '../../../FormSwitch';
import { camelCaseToHumanReadable, DEFAULT_AD_TEMPLATE, DEFAULT_FULL_PAGE_TEMPLATE, getTemplateStylesFromURL, prettyFormatStyleValue } from '../../templateSettingsUtils';
function TemplateDrawerRow({ label, value }) {
    return (React.createElement("div", { className: "flex w-full px-4 py-3.5 justify-between" },
        React.createElement("div", { className: "text-grey-400" }, label),
        React.createElement("div", { className: "text-grey-500", id: `${label}` }, value || '--')));
}
function TemplateStylesTable({ defaultIDMLUrl, allowDisable = false, template, urlKey }) {
    const [styles, setStyles] = useState();
    const [downloadError, setDownloadError] = useState('');
    const templateDownloadURL = template.data()[urlKey];
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            setDownloadError('');
            if (!templateDownloadURL)
                return;
            try {
                const styles = yield getTemplateStylesFromURL(templateDownloadURL);
                setStyles(styles);
            }
            catch (err) {
                setDownloadError(`${err}`);
            }
        }))();
    }, [templateDownloadURL]);
    if (downloadError)
        return React.createElement(TemplateDownloadError, { error: downloadError });
    return (React.createElement(React.Fragment, null,
        allowDisable && (React.createElement(TemplateDrawerRow, { label: "Enabled", value: React.createElement(FormSwitch, { checked: !!templateDownloadURL, onChange: () => __awaiter(this, void 0, void 0, function* () {
                    if (templateDownloadURL) {
                        yield template.ref.update({
                            [urlKey]: null
                        });
                    }
                    else {
                        yield template.ref.update({
                            [urlKey]: defaultIDMLUrl
                        });
                    }
                }) }) })),
        templateDownloadURL && (React.createElement(TemplateDrawerRow, { label: "Template File", value: React.createElement("a", { className: "underline text-blue-600", target: "_blank", href: templateDownloadURL, rel: "noopener noreferrer" }, "Download") })),
        templateDownloadURL &&
            (styles ? (Object.entries(styles)
                .filter(entry => entry[0] !== 'id')
                .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                .map(([styleKey, styleValue]) => (React.createElement(TemplateDrawerRow, { label: camelCaseToHumanReadable(styleKey), value: prettyFormatStyleValue(styleValue), key: `${styleKey}-${styleValue}` })))) : (React.createElement(LoadingState, null)))));
}
/**
 * Displays error message when template download fails
 */
function TemplateDownloadError({ error }) {
    return (React.createElement("div", { className: "flex w-full justify-center  mt-10" },
        React.createElement(WarningIcon, { className: "w-6 h-6 text-red-600" }),
        React.createElement("span", { className: "ml-2" }, error)));
}
function DrawerTabButton({ isActive, label, onClick }) {
    return (React.createElement("button", { className: classNames('ml-8 inline-block text-base text-black text-left py-6 focus:outline-none font-medium border-b-2 border-transparent hover:border-b-2 hover:border-blue-600 hover:text-blue-950 border-b-2', isActive && 'border-blue-600 text-blue-950'), onClick: () => onClick() }, label));
}
const LAYOUT_SETTINGS_TAB = 'layout-settings';
const BULK_DOWNLOAD_SETTINGS_TAB = 'bulk-download-settings';
export default function TemplateSettingsDrawerBody({ setEditedTemplate, activeOrganization, template }) {
    var _a;
    const [templateDrawerTab, setTemplateDrawerTab] = useState(LAYOUT_SETTINGS_TAB);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "border-b flex" },
            React.createElement(DrawerTabButton, { isActive: templateDrawerTab === LAYOUT_SETTINGS_TAB, label: "Ad Template Settings", onClick: () => setTemplateDrawerTab(LAYOUT_SETTINGS_TAB) }),
            React.createElement(DrawerTabButton, { isActive: templateDrawerTab === BULK_DOWNLOAD_SETTINGS_TAB, label: "Bulk Template Settings", onClick: () => setTemplateDrawerTab(BULK_DOWNLOAD_SETTINGS_TAB) })),
        React.createElement("div", { className: "p-4 overflow-scroll" },
            templateDrawerTab === LAYOUT_SETTINGS_TAB && (React.createElement(TemplateStylesTable, { defaultIDMLUrl: DEFAULT_AD_TEMPLATE, template: template, urlKey: "downloadUrl" })),
            templateDrawerTab === BULK_DOWNLOAD_SETTINGS_TAB && (React.createElement(TemplateStylesTable, { defaultIDMLUrl: DEFAULT_FULL_PAGE_TEMPLATE, template: template, urlKey: "fullPageTemplate", allowDisable: true }))),
        React.createElement("div", { className: classNames('absolute bottom-0 flex w-full py-4 px-8 border-t bg-white') },
            React.createElement("div", { className: "flex-1" }, ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id) !== template.id && (React.createElement(ColumnButton, { buttonText: "Set as Default", secondary: true, size: "lg", onClick: () => {
                    void activeOrganization.ref.update({
                        adTemplate: template.ref
                    });
                } }))),
            React.createElement(ColumnButton, { id: "edit-template", primary: true, size: "lg", buttonText: "Edit", onClick: () => {
                    setEditedTemplate(template);
                } }))));
}
