var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Papa from 'papaparse';
export const csvToHtml = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const rows = yield new Promise(resolve => Papa.parse(file, {
        complete: results => resolve(results.data)
    }));
    let parsedHtml = '<table>';
    rows.forEach(row => (parsedHtml += `<tr>${row.map(c => `<td>${c}</td>`).join('')}</tr>`));
    parsedHtml += '</table>';
    return parsedHtml;
});
