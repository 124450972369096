import React, { useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
const PDFBounder = ({ src, crop, setCrop }) => {
    useEffect(() => {
        if (!crop || crop.width === 0)
            setCrop({
                height: 100,
                width: 100,
                x: 0,
                y: 0,
                unit: '%'
            });
    }, [src, crop]);
    return (React.createElement(ReactCrop, { src: src, crop: crop || undefined, onImageLoaded: () => {
            if (!crop)
                setCrop({
                    height: 100,
                    width: 100,
                    x: 0,
                    y: 0,
                    unit: '%'
                });
        }, onChange: (cropAbs, cropPct) => {
            if (cropAbs.width === 0 ||
                (cropAbs.width === (crop === null || crop === void 0 ? void 0 : crop.absWidth) &&
                    cropAbs.height === (crop === null || crop === void 0 ? void 0 : crop.absHeight)))
                return;
            setCrop(Object.assign(Object.assign({}, cropPct), { absWidth: cropAbs.width, absHeight: cropAbs.height, aspect: null }));
        } }));
};
export default PDFBounder;
