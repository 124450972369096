import { call, take, select, put, takeLatest } from 'redux-saga/effects';
import { routerSelector } from 'redux/router';
import { authSelector, AuthTypes } from 'redux/auth';
import { push, LOCATION_CHANGE, replace } from 'connected-react-router';
import { OrganizationType, OccupationType, State } from 'lib/enums';
import pathToRegex from 'path-to-regexp';
import { organizationIsSetup } from 'routes/redirects';
import { includeSearchParams, getSubdomain, getHostname } from 'utils/urls';
import { exists } from 'lib/types';
import { getOpenInvitesForUser, isColumnUser } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { PUBLISHER_CAN_REGISTER } from '../constants';
function* getLandingPage() {
    const auth = yield select(authSelector);
    let { user } = auth;
    const { userAuth } = auth;
    // Firebase Auth reports a user, but we have not yet loaded the user
    // document from Firestore
    if (userAuth && !user) {
        user = (yield take(AuthTypes.SET_USER)).user;
    }
    if (exists(user)) {
        const userData = user.data();
        const activeOrg = userData.activeOrganization &&
            (yield call([
                userData.activeOrganization,
                userData.activeOrganization.get
            ]));
        const activeOrgData = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.data();
        return (activeOrgData === null || activeOrgData === void 0 ? void 0 : activeOrgData.organizationType) ===
            OrganizationType.holding_company.value && (activeOrgData === null || activeOrgData === void 0 ? void 0 : activeOrgData.reportUrl)
            ? '/reports/'
            : '/notices/';
    }
    const subdomain = getSubdomain();
    if (['florida'].indexOf(subdomain) !== -1) {
        return '/login/';
    }
    if (State.by_key(subdomain)) {
        return '/search/';
    }
    if (['wapo-production'].indexOf(subdomain) !== -1) {
        return '/search/';
    }
    const hostname = getHostname();
    if (['publicnoticecolorado', 'washingtonpost'].indexOf(hostname) !== -1)
        return '/search/';
    return '/login/';
}
export function* getRedirect() {
    const r = yield select(routerSelector);
    return decodeURIComponent(r.location.query.redirect || '/');
}
export function* checkNeedToRedirect() {
    const { user } = yield select(authSelector);
    const { location } = yield select(routerSelector);
    if (!user)
        return false;
    if (location.pathname.includes('/landing'))
        return false;
    const userData = user.data();
    const ctx = getFirebaseContext();
    const pendingInvites = yield call(getOpenInvitesForUser, ctx, user);
    const redirectUrl = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('placementRedirectUrl');
    if (pendingInvites.length) {
        return false;
    }
    /*
      Restricting user to go to the welcome screen to select from newspaper/advertiser, while placement as anonymous user
    */
    if (userData &&
        redirectUrl &&
        location.pathname.includes('/register/welcome')) {
        yield put(push('/register/occupations'));
        return true;
    }
    /*
      Redirect user to occupation if user has start registration as individual and not completed
    */
    if ((userData === null || userData === void 0 ? void 0 : userData.occupation) === OccupationType.individual.value &&
        !userData.postRegistrationComplete &&
        !location.pathname.includes('/register/organization') &&
        !userData.state) {
        if (location.pathname.includes('/register/'))
            return false;
        yield put(push('/register/organization'));
        return true;
    }
    /*
      Redirecting user to occupations if a user is already selecting an occupation after initial registration step
    */
    if ((userData === null || userData === void 0 ? void 0 : userData.occupation) &&
        (location.pathname === '/register/' || location.pathname === '/register')) {
        yield put(push('/register/organization'));
        return true;
    }
    if (userData &&
        !userData.occupation &&
        (location.pathname === '/register/' || location.pathname === '/register')) {
        yield put(push('/register/welcome'));
        return true;
    }
    if (userData.emailNeedsConfirm) {
        if (location.pathname.includes('/register/confirm/'))
            return false;
        yield put(push('/register/confirm/'));
        return true;
    }
    if (!userData.occupation &&
        !PUBLISHER_CAN_REGISTER &&
        location.pathname.includes('/register/') &&
        !location.pathname.includes('/register/occupations')) {
        yield put(push(includeSearchParams('/register/occupations')));
        return true;
    }
    if (!userData.occupation &&
        location.pathname.includes('/register/occupations')) {
        if (location.pathname.includes('/register/'))
            return false;
        yield put(push(includeSearchParams('/register/occupations')));
        return true;
    }
    if (!userData.occupation) {
        if (location.pathname.includes('/register/'))
            return false;
        yield put(push(includeSearchParams('/register/welcome')));
        return true;
    }
    const needsToCompleteOrgSetupStepOne = userData.occupation !== OccupationType.individual.value &&
        !userData.organization;
    if (needsToCompleteOrgSetupStepOne) {
        if (location.pathname.includes('/register/'))
            return false;
        yield put(push('/register/organization'));
        return true;
    }
    const activeOrg = userData.activeOrganization &&
        (yield call([
            userData.activeOrganization,
            userData.activeOrganization.get
        ]));
    if (!activeOrg)
        return false;
    if (organizationIsSetup(userData, activeOrg.data()) &&
        (activeOrg.data().organizationType === OrganizationType.government.value ||
            activeOrg.data().organizationType === OrganizationType.law_firm.value ||
            activeOrg.data().organizationType ===
                OrganizationType.other_organization.value) &&
        activeOrg.data().postRegistrationComplete &&
        redirectUrl) {
        if (location.pathname.includes('/post-registration'))
            return false;
        yield put(push(`/?redirect=${encodeURIComponent(redirectUrl)}`));
        sessionStorage.clear();
        return true;
    }
    if (organizationIsSetup(userData, activeOrg.data()) &&
        (activeOrg.data().organizationType === OrganizationType.government.value ||
            activeOrg.data().organizationType === OrganizationType.law_firm.value ||
            activeOrg.data().organizationType ===
                OrganizationType.other_organization.value ||
            activeOrg.data().organizationType === OrganizationType.newspaper.value) &&
        !activeOrg.data().postRegistrationComplete) {
        if (location.pathname.includes('/post-registration'))
            return false;
        yield put(push(`/register/organization/post-registration?redirect=${encodeURIComponent(location.pathname + location.search)}`));
        return true;
    }
    if (!organizationIsSetup(userData, activeOrg.data())) {
        if (location.pathname.includes('/register/organization'))
            return false;
        return true;
    }
    const alreadyRedirected = [
        '/register/organization',
        '/register/organization/post-registration',
        '/new-feature-onboard/advertising-deadlines'
    ].find(path => location.pathname.includes(path));
    if (alreadyRedirected)
        return false;
}
export function* redirect(action) {
    const { user } = yield select(authSelector);
    if (!user)
        yield take([AuthTypes.SET_USER, AuthTypes.END_AUTH]);
    function* restrict() {
        const { pathname } = action.payload.location;
        const { userAuth } = yield select(authSelector);
        if (!userAuth)
            yield put(push(`/login/?redirect=${encodeURIComponent(pathname)}`));
    }
    function* restrictInternal() {
        const { pathname } = action.payload.location;
        const authState = yield select(authSelector);
        const { userAuth, user } = authState;
        if (!userAuth)
            yield put(push(`/login/?redirect=${encodeURIComponent(pathname)}`));
        if (!user || !isColumnUser(user)) {
            yield put(replace(`/`));
        }
    }
    if (yield call(checkNeedToRedirect))
        return;
    const { pathname } = action.payload.location;
    const test = (path) => {
        return pathToRegex(path).test(pathname);
    };
    switch (true) {
        case test('/'): {
            const landingPage = yield call(getLandingPage);
            // const redirectUrl = sessionStorage?.getItem('placementRedirectUrl');
            // if (redirectUrl) {
            //   yield put(push(redirectUrl));
            //   break;
            // } else {
            yield put(replace(includeSearchParams(landingPage)));
            break;
            // }
        }
        // routes restricted for logged in users
        case test('/login/'): {
            const { userAuth } = yield select(authSelector);
            if (userAuth) {
                const redirect = yield call(getRedirect);
                yield put(push(redirect));
            }
            break;
        }
        // unrestricted routes
        case test('/search/'):
        case test('/register/'):
        case test('/place/:id?'):
        case test('/rfps/:id'):
        case test('/foreclosures/:id'):
        case test('/form/:noticeType/:noticeId'):
        case test('/form/newspaper/:noticeId'):
        case test('/association/'):
        case test('/file/by-type/:type'):
        case test('/file/:id/:noticeSlug?'):
        case test('/email-action'):
        case test('/invites/:id'):
        case test('/invoices/:id/pay'):
        case test('/forgot-password'):
        case test('/reset-password/'):
        case test('/public-notice/:id'): {
            break;
        }
        // restricted routes
        case test('/logout/'):
        case test('/register/welcome/'):
        case test('/register/occupations/'):
        case test('/register/organization/'):
        case test('/register/organization/post-registration/'):
        case test('/register/confirm/'):
        case test('/reports/'):
        case test('/cards/'):
        case test('/payments/'):
        case test('/temporary'):
        case test('/place_press/:bulkId?'):
        case test('/bulk/:bulkId'):
        case test('/settings/'):
        case test('/settings/organization/'):
        case test('/:path(notice|publish)/:id/'):
        case test('/:path(notice|publish)/:id/invoice/create'):
        case test('/:path(notice|publish)/:id/invoice/review'):
        case test('/:path(notice|publish)/:id/invoice/create-bulk'):
        case test('/error/:code'):
        case test('/cards/invoices/:id/pay'):
        case test('/donation-campaign'):
        case test('/notices/'): {
            yield call(restrict);
            break;
        }
        // internal restricted routes (@column.us only)
        case test('/debugger'):
        case test('/madlib'): {
            yield call(restrictInternal);
            break;
        }
        default: {
            console.warn('Unhandled pathname', { pathname });
            const landingPage = yield call(getLandingPage);
            yield put(replace(landingPage));
            break;
        }
    }
}
export default function* root() {
    yield takeLatest(LOCATION_CHANGE, redirect);
}
