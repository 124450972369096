var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, FormControl, Input, Button, InputLabel, Select, MenuItem, Typography, FormHelperText, Box } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TimezoneType, State } from '../../../lib/enums';
import SelectTypeahead from '../../../components/forms/SelectTypeahead';
const styles = (theme) => createStyles({
    formControl: {
        display: 'flex'
    },
    saveButton: {
        marginTop: theme.spacing(4),
        width: '100%'
    },
    header: {
        marginBottom: theme.spacing(3)
    }
});
const PressAssociationSettingsInfo = ({ activeOrganization, classes }) => {
    const orgData = activeOrganization.data();
    const [name, setName] = useState();
    const [title, setTitle] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState();
    const [website, setWebsite] = useState();
    const [timezone, setTimezone] = useState();
    const [phone, setPhone] = useState();
    useEffect(() => {
        setName(orgData.name);
        setTitle(orgData.title);
        setAddress(orgData.address);
        setCity(orgData.city);
        setState(orgData.state);
        setZipCode(orgData.zipCode);
        setWebsite(orgData.website);
        setTimezone(orgData.timezone);
        setPhone(orgData.phone);
    }, [activeOrganization]);
    return (React.createElement(ValidatorForm, { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
            e.preventDefault();
            yield activeOrganization.ref.update({
                name,
                title,
                address,
                city,
                state,
                zipCode,
                website,
                timezone,
                phone
            });
        }) },
        React.createElement(Typography, { variant: "h5", className: classes.header }, "Basic information"),
        React.createElement(Grid, { container: true, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputLabel, null, "Press Association Name"),
                    React.createElement(Input, { required: true, autoComplete: "none", value: name, onChange: e => setName(e.target.value) }))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputLabel, { htmlFor: "name" }, "Your Title"),
                    React.createElement(Input, { required: true, autoComplete: "none", value: title, onChange: e => setTitle(e.target.value) }))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputLabel, { htmlFor: "address" }, "Address"),
                    React.createElement(Input, { id: "address", required: true, autoComplete: "none", value: address, onChange: e => setAddress(e.target.value) }))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputLabel, { htmlFor: "city" }, "City"),
                    React.createElement(Input, { id: "city", required: true, autoComplete: "none", value: city, onChange: e => setCity(e.target.value) }))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(Box, { mt: 2 },
                        React.createElement(SelectTypeahead, { placeholder: "State", updateValue: (selected) => selected ? setState(selected.id) : setState(''), value: state
                                ? {
                                    id: state,
                                    label: State.by_value(state).label
                                }
                                : undefined, options: State.items().map(item => ({
                                id: `${item.value}`,
                                label: item.label
                            })) })))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputMask, { mask: "99999", maskChar: "", value: zipCode, onChange: e => setZipCode(e.target.value) }, () => (React.createElement(TextValidator, { name: "zipCode", value: zipCode, required: true, autoComplete: "none", label: "Zip code", validators: ['minStringLength:5'], errorMessages: ['Must be five digits'] }))))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputMask, { mask: "(999) 999-9999", maskChar: "", value: phone, onChange: e => setPhone(e.target.value) }, () => (React.createElement(TextValidator, { name: "phone", value: phone, required: true, autoComplete: "none", label: "Phone number", validators: ['minStringLength:14'], errorMessages: ['Phone number must be 10 digits'] }))))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputLabel, null, "Timezone"),
                    React.createElement(Select, { onChange: e => {
                            setTimezone(e.target.value);
                        }, value: timezone }, TimezoneType.items().map(tz => (React.createElement(MenuItem, { key: tz.value, value: tz.logical }, tz.label)))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement(InputLabel, { htmlFor: "website" }, "Website"),
                    React.createElement(Input, { id: "website", required: true, autoComplete: "none", value: website, onChange: e => setWebsite(e.target.value) }),
                    React.createElement(FormHelperText, null, "E.g., https://www.your-domain.com"))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Button, { id: "InfoSave", type: "submit", variant: "contained", color: "primary", className: classes.saveButton }, "Save")))));
};
export default withStyles(styles)(PressAssociationSettingsInfo);
