import React from 'react';
import { TextField } from 'components/TextField';
import ColumnSelect from 'components/select/ColumnSelect';
import { Label } from 'components/Label';
import FormInputWrapper from './TemplateSettingsFormInputWrapper';
import UpdateCard from './TemplateSettingsUpdateCard';
/**
 * Form wrapper to update the styles associated with a template
 */
export default function TemplateLayoutSettings({ subheader, onUpdate, header, styles }) {
    const { pageHeightInInches, pageWidthInInches, font, fontSize, leading, tracking, firstLineIndent, justification, pointsPerInch, columnCount, columnGutter, hyphenation, textInset, borderWidth } = styles;
    return (React.createElement(UpdateCard, { subheader: subheader, header: header },
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "page-height", labelText: "Page Height (in)", value: String(pageHeightInInches), type: "number", step: 0.01, onChange: newValue => onUpdate('pageHeightInInches', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "page-width", labelText: "Page Width (in)", value: String(pageWidthInInches), type: "number", step: 0.01, onChange: newValue => onUpdate('pageWidthInInches', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "font", labelText: "Font", value: String(font), type: "text", onChange: newValue => onUpdate('font', newValue) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "font-size", labelText: "Font Size", value: String(fontSize), type: "number", step: 1, onChange: newValue => onUpdate('fontSize', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "leading", labelText: "Leading", value: String(leading), type: "number", step: 0.01, onChange: newValue => onUpdate('leading', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "tracking", labelText: "Tracking", value: String(tracking), type: "number", step: 0.01, onChange: newValue => onUpdate('tracking', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "first-line-indent", labelText: "First Line Indent", value: String(firstLineIndent), type: "number", step: 0.01, onChange: newValue => onUpdate('firstLineIndent', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement("div", { className: "pb-2" },
                React.createElement(Label, { id: "justification-label" }, "Justification")),
            React.createElement(ColumnSelect, { className: "flex-1 text-md", value: String(justification), onChange: newValue => onUpdate('justification', newValue), options: [
                    {
                        value: 'LeftJustified',
                        label: 'Left Justified'
                    },
                    {
                        value: 'LeftAlign',
                        label: 'Left Aligned'
                    },
                    {
                        value: 'CenterAlign',
                        label: 'Center Align'
                    }
                ] })),
        React.createElement(FormInputWrapper, { className: "flex flex-col" },
            React.createElement("div", { className: "pb-2" },
                React.createElement(Label, { id: "hyphenation-label" }, "Hyphenation")),
            React.createElement(ColumnSelect, { className: "flex-1 text-md", value: String(hyphenation), onChange: newValue => onUpdate('hyphenation', newValue), options: [
                    {
                        value: 'true',
                        label: 'True'
                    },
                    {
                        value: 'false',
                        label: 'False'
                    }
                ] })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "points-per-inch", labelText: "Points Per Inch", value: String(pointsPerInch), type: "number", step: 0.01, onChange: newValue => onUpdate('pointsPerInch', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "column-count", labelText: "Column Count", value: String(columnCount), type: "number", step: 1, onChange: newValue => onUpdate('columnCount', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "column-gutter", labelText: "Column Gutter", value: String(columnGutter), type: "number", step: 0.01, onChange: newValue => onUpdate('columnGutter', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "border-width", labelText: "Border Width (in points)", value: String(borderWidth), type: "number", step: 1, onChange: newValue => onUpdate('borderWidth', parseFloat(newValue)) })),
        React.createElement(FormInputWrapper, null,
            React.createElement(TextField, { id: "text-inset", labelText: "Text Inset (in points)", value: String(textInset), type: "number", step: 0.01, onChange: newValue => onUpdate('textInset', parseFloat(newValue)) }))));
}
