import React from 'react';
import Firebase from 'EnoticeFirebase';
import { DeleteIcon, SquareUpRight, ExclamationCircle } from 'icons';
import { ColumnButton } from 'components/ColumnButton';
import { useGetFirebaseStorageDownloadUrl, useDeleteFirebaseStorageFile } from 'utils/firebaseStorageHooks';
export function FileUploadListItem({ firebaseStoragePath, deleteConfirmation, displayFileName, onDelete }) {
    const storageRef = Firebase.storage().ref(firebaseStoragePath);
    const { downloadUrl, previewState } = useGetFirebaseStorageDownloadUrl(storageRef);
    const { deleteFile, deletionState } = useDeleteFirebaseStorageFile({
        storageRef,
        onDelete,
        deleteConfirmation
    });
    const downloadUrlNotFound = previewState === 'error';
    const deletionHelpText = {
        idle: 'Delete',
        deleting: 'Deleting...',
        error: 'Unable to delete'
    }[deletionState];
    const previewHelpText = {
        idle: 'View',
        loading: 'Loading preview...',
        error: 'File not found'
    }[previewState];
    return (React.createElement("div", { className: "flex mb-2 gap-3" },
        React.createElement("p", { className: "w-full bg-grey-50 px-4 py-3 rounded-md border border-grey-100 text-grey-300 font-medium text-sm truncate" }, displayFileName || storageRef.name),
        React.createElement(ColumnButton, { secondary: true, loading: previewState === 'loading', disabled: downloadUrlNotFound, startIcon: downloadUrlNotFound ? (React.createElement(ExclamationCircle, { className: "h-6 w-6" })) : (React.createElement(SquareUpRight, { className: "h-6 w-6" })), onClick: () => openFile(downloadUrl), buttonText: previewHelpText }),
        React.createElement(ColumnButton, { destructive: true, secondary: true, loading: deletionState === 'deleting', startIcon: React.createElement("div", { className: "flex items-center justify-center h-6 w-6" },
                React.createElement(DeleteIcon, null)), onClick: deleteFile, buttonText: deletionHelpText })));
}
export const openFile = (storagePath) => {
    if (!storagePath)
        return;
    window.open(storagePath);
};
