import React from 'react';
import Drawer from 'components/Drawer';
import AffidavitUploadDayDrawerHeader from './AffidavitUploadDayDrawerHeader';
import AffidavitUploadDayDrawerBody from './AffidavitUploadDayDrawerBody';
/**
 * Popout drawer component that displays details about the affidavit upload event
 */
export default function AffidavitSettingsDrawer({ setShowEEditionUploadModal, affidavitUploadDay, activeOrganization, closeDrawer }) {
    return (React.createElement(Drawer, { closeDrawer: () => closeDrawer(), className: "flex flex-col", header: React.createElement(AffidavitUploadDayDrawerHeader, { affidavitUploadDay: affidavitUploadDay, activeOrganization: activeOrganization }), open: true },
        React.createElement(AffidavitUploadDayDrawerBody, { setShowEEditionUploadModal: setShowEEditionUploadModal, activeOrganization: activeOrganization, affidavitUploadDay: affidavitUploadDay })));
}
