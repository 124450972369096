import { State } from 'lib/enums';
import React from 'react';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import * as validators from 'routes/register/organization/validators';
import { EditModalDetailsCell, EditModalDetailsRow } from './EditModalDetailsRow';
export const EditModalDetails = ({ name, setName, changedFields, setChangedFields, customerData, customerOrganizationData, internalID, setInternalID, phone, setPhone, address, setAddress, addressLine2, setAddressLine2, city, setCity, state, setState, zipCode, setZipCode, organizationName, setOrganizationName, showOrganizationName, isCustomerOrganization }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const isPhoneInputValid = (newValue) => {
        return !(!validators.phone(newValue) &&
            newValue.replace(/[^0-9]/g, '').length > validators.MAX_PHONE_LENGTH);
    };
    const isZipInputValid = (newValue) => {
        return validators.zip(newValue) && newValue.length >= 0;
    };
    return (React.createElement("div", { className: "pt-8 pb-6 flex justify-center" },
        React.createElement("div", { className: "w-2/3 inline-block bg-white border border-grey-100 rounded-md", style: { boxShadow: '0px 2px 24px rgba(18, 33, 46, 0.04' } },
            React.createElement("div", { className: "px-8" },
                React.createElement("div", { className: "pt-8 font-semibold text-lg text-gray-850" },
                    "Provide ",
                    isCustomerOrganization ? 'organization' : 'personal',
                    ' ',
                    "details."),
                React.createElement("div", { className: "pt-2 font-medium text-sm text-gray-650" },
                    "Save basic details about this",
                    ' ',
                    isCustomerOrganization ? 'organization' : 'customer',
                    ", including their name and contact information."),
                React.createElement("div", { className: "py-10 font-medium text-sm leading-6" },
                    isCustomerOrganization && (React.createElement(EditModalDetailsRow, { isFirstRow: true },
                        React.createElement(EditModalDetailsCell, { label: 'Organization name', fieldKey: 'name', fieldValue: name, setFieldValue: setName, originalFieldValue: (_a = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.name, changedFields: changedFields, setChangedFields: setChangedFields, isRequired: true }),
                        React.createElement(EditModalDetailsCell, { label: 'Organization ID', fieldKey: 'internalID', fieldValue: internalID, setFieldValue: setInternalID, originalFieldValue: (_b = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _b === void 0 ? void 0 : _b.internalID, changedFields: changedFields, setChangedFields: setChangedFields }))),
                    !isCustomerOrganization && (React.createElement(EditModalDetailsRow, { isFirstRow: true },
                        React.createElement(EditModalDetailsCell, { label: 'Customer name', fieldKey: 'name', fieldValue: name, setFieldValue: setName, originalFieldValue: customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().userName, changedFields: changedFields, setChangedFields: setChangedFields, isRequired: true }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "text-blue-800" }, "Email address"),
                            React.createElement("input", { id: "email", className: "form-input focus:outline-none appearance-none rounded-md relative w-full bg-grey-50 px-4 py-3 mb-0 border border-grey-200 text-gray-650 font-medium text-sm leading-6", type: "text", value: customerData === null || customerData === void 0 ? void 0 : customerData.user.data().email, disabled: true })))),
                    React.createElement(EditModalDetailsRow, null,
                        React.createElement(EditModalDetailsCell, { label: 'Phone number', fieldKey: 'phone', fieldValue: phone, setFieldValue: setPhone, originalFieldValue: (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().phone) ||
                                ((_d = (_c = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.phone), changedFields: changedFields, setChangedFields: setChangedFields, validate: isPhoneInputValid }),
                        React.createElement(EditModalDetailsCell, { label: 'Address line 1', fieldKey: 'address', fieldValue: address, setFieldValue: setAddress, originalFieldValue: (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().address) ||
                                ((_f = (_e = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization) === null || _e === void 0 ? void 0 : _e.data()) === null || _f === void 0 ? void 0 : _f.address), changedFields: changedFields, setChangedFields: setChangedFields })),
                    React.createElement(EditModalDetailsRow, null,
                        React.createElement(EditModalDetailsCell, { label: 'Address line 2', fieldKey: 'addressLine2', fieldValue: addressLine2, setFieldValue: setAddressLine2, originalFieldValue: (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().addressLine2) ||
                                ((_h = (_g = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization) === null || _g === void 0 ? void 0 : _g.data()) === null || _h === void 0 ? void 0 : _h.addressLine2), changedFields: changedFields, setChangedFields: setChangedFields }),
                        React.createElement(EditModalDetailsCell, { label: 'City', fieldKey: 'city', fieldValue: city, setFieldValue: setCity, originalFieldValue: (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().city) ||
                                ((_k = (_j = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization) === null || _j === void 0 ? void 0 : _j.data()) === null || _k === void 0 ? void 0 : _k.city), changedFields: changedFields, setChangedFields: setChangedFields })),
                    React.createElement(EditModalDetailsRow, null,
                        React.createElement("div", null,
                            React.createElement("div", { className: "text-blue-800" }, "State"),
                            React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                                    var _a, _b;
                                    if (selected) {
                                        setState(selected.id);
                                    }
                                    if ((customerData &&
                                        selected.id === (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().state)) ||
                                        (customerOrganizationData &&
                                            selected.id ===
                                                ((_b = (_a = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.state))) {
                                        setChangedFields(Object.assign(Object.assign({}, changedFields), { state: false }));
                                    }
                                    else {
                                        setChangedFields(Object.assign(Object.assign({}, changedFields), { state: true }));
                                    }
                                }, placeholder: "State", options: State.items().map((state) => ({
                                    id: state.value,
                                    label: state.label
                                })), selected: State.items().filter(item => {
                                    return item.value === state
                                        ? { id: item.value, label: item.label }
                                        : '';
                                }), value: State.items().filter(item => {
                                    return item.value === state
                                        ? { id: item.value, label: item.label }
                                        : '';
                                }), borderRadius: 6, borderColor: '#C8D0D6', placeholderText: '#24415B', noShadow: true, dropdownHeight: 'large' })),
                        React.createElement(EditModalDetailsCell, { label: 'Zip Code', fieldKey: 'zipCode', fieldValue: zipCode, setFieldValue: setZipCode, originalFieldValue: (customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().zipCode) ||
                                ((_m = (_l = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization) === null || _l === void 0 ? void 0 : _l.data()) === null || _m === void 0 ? void 0 : _m.zipCode), changedFields: changedFields, setChangedFields: setChangedFields, validate: isZipInputValid })),
                    showOrganizationName && (React.createElement(EditModalDetailsRow, { oneCellRow: true },
                        React.createElement(EditModalDetailsCell, { label: 'Organization Name', fieldKey: 'organizationName', fieldValue: organizationName, setFieldValue: setOrganizationName, originalFieldValue: customerData === null || customerData === void 0 ? void 0 : customerData.customer.data().organizationName, changedFields: changedFields, setChangedFields: setChangedFields }))))))));
};
