import React, { useState } from 'react';
import CrossPaperFees from './crossPaperFees';
import SettingsTabs from '../SettingsTabs';
import RatesTable from './ratesTable';
const RATES_TABLE_TAB = {
    label: 'Rates',
    enabled: true
};
const CROSS_PAPER_FEES_SETTINGS = {
    label: 'Cross-Paper Fees',
    enabled: true
};
const RATES_TABS = [RATES_TABLE_TAB, CROSS_PAPER_FEES_SETTINGS];
export default function RatesSettings({ activeOrganization }) {
    const [rateSettingsTab, setRateSettingsTab] = useState(RATES_TABLE_TAB);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow mb-24" },
        React.createElement(SettingsTabs, { setActiveTab: setRateSettingsTab, activeTab: rateSettingsTab, tabs: RATES_TABS }),
        rateSettingsTab.label === RATES_TABLE_TAB.label && (React.createElement(RatesTable, { activeOrganization: activeOrganization })),
        rateSettingsTab.label === CROSS_PAPER_FEES_SETTINGS.label && (React.createElement(CrossPaperFees, { activeOrganization: activeOrganization }))));
}
