var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureMessage } from 'utils';
import { getOrganizationGateway } from 'lib/billing/gateways';
import { getBillingNames, getInvoiceRecipientFromNoticeId, getPreventLatePaymentFromNoticeId, getRequireUpfrontPaymentFromNoticeId } from './helpers/loadPayInvoiceData';
import { fetchPricingForBulkInvoice, fetchPricingForSingleInvoice } from './helpers/loadInvoicePricingData';
import PayInvoicePage from './PayInvoicePage';
import { getSavedPaymentsAccess } from './helpers/getSavedPaymentsAccess';
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
const mapStateToProps = (state) => ({
    user: state.auth.user
});
function PayInvoice({ push, user }) {
    const [payInvoiceData, setPayInvoiceData] = useState();
    const [invoicePricingData, setInvoicePricingData] = useState();
    const savedPaymentsAccess = getSavedPaymentsAccess(payInvoiceData, user);
    const isLoading = invoicePricingData === undefined ||
        payInvoiceData === undefined ||
        savedPaymentsAccess === undefined;
    useEffect(() => {
        // TODO: Move this entire load into an API route to have less back-and-forth comms
        //   and improve performance.  Also good candidate for coupling the idemptotent session!
        const loadPayInvoiceData = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const invoiceId = window.location.href.split('/')[4];
            const invoiceSnap = yield getFirebaseContext()
                .invoicesRef()
                .doc(invoiceId)
                .get();
            if (!exists(invoiceSnap)) {
                logAndCaptureMessage('User is trying to access a payment url for an invoice that does not exist', { invoiceId, userId: (user === null || user === void 0 ? void 0 : user.id) || '' });
                push('/');
                return;
            }
            const invoiceRecipient = yield getInvoiceRecipientFromNoticeId(invoiceSnap.data().noticeId);
            const advertiserSnap = yield getFirebaseContext()
                .usersRef()
                .doc(invoiceSnap.data().advertiser.id)
                .get();
            const advertiser = exists(advertiserSnap) ? advertiserSnap : undefined;
            const gateway = yield getOrganizationGateway(yield ((_a = invoiceSnap.data().organization) === null || _a === void 0 ? void 0 : _a.get()));
            const { billingName, customerToSaveCardOnName } = yield getBillingNames(advertiser === null || advertiser === void 0 ? void 0 : advertiser.data(), invoiceRecipient, invoiceSnap);
            const preventLatePayment = yield getPreventLatePaymentFromNoticeId(invoiceSnap.data().noticeId);
            const requireUpfrontPayment = yield getRequireUpfrontPaymentFromNoticeId(invoiceSnap.data().noticeId);
            let pricingData;
            if (invoiceSnap.data().isBulkInvoice)
                pricingData = yield fetchPricingForBulkInvoice(invoiceSnap, gateway);
            else
                pricingData = yield fetchPricingForSingleInvoice(invoiceSnap, gateway);
            if (pricingData) {
                setInvoicePricingData(pricingData);
            }
            else {
                logAndCaptureMessage('Unable to load pricing data for invoice', {
                    invoiceId: invoiceSnap.id
                });
            }
            setPayInvoiceData({
                invoice: invoiceSnap,
                advertiser,
                gateway,
                invoiceRecipient,
                preventLatePayment,
                requireUpfrontPayment,
                billingName,
                customerToSaveCardOnName
            });
        });
        void loadPayInvoiceData();
    }, []);
    if (isLoading) {
        return React.createElement(LoadingState, { context: { location: 'PayInvoice' } });
    }
    return (React.createElement(PayInvoicePage, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, savedPaymentsAccess: savedPaymentsAccess, user: user }));
}
export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice);
