var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { AttachmentIcon } from 'icons';
import { ErrorContainer } from '../../routes/placeScroll/PlaceNoticePreview';
const NoticeFilePreview = ({ noticeFile, isPublisher, noticeId, push }) => {
    var _a;
    const urlFromOldSchemaMetadata = (noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.oldSchemaMetadata)
        ? noticeFile.oldSchemaMetadata[Object.keys(noticeFile.oldSchemaMetadata).filter((key) => !key.includes('File'))[0]]
        : undefined;
    const url = (noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.linkToUploadedFile) || urlFromOldSchemaMetadata;
    const [fileSize, setFileSize] = useState(0);
    const [showWarning, setShowWarning] = useState(true);
    useEffect(() => {
        const fetchFileSize = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!url)
                return;
            setFileSize((yield fetch(url)).headers.get('content-length') / 1024);
        });
        if (fileSize < 1)
            void fetchFileSize();
    });
    const ERROR_TEXT = `This notice was submitted without an upfront proof. Download the file to complete layout before publication. Once you format the notice, please upload a final copy of the notice via "Edit Notice" or by clicking here.`;
    return (React.createElement(React.Fragment, null,
        isPublisher && showWarning && (React.createElement("div", { className: "shadow sm:rounded-lg bottom-1/4 relative" },
            React.createElement(ErrorContainer, { text: ERROR_TEXT, onDismiss: () => setShowWarning(false), clickAction: () => {
                    console.log('noticeId:', noticeId);
                    noticeId && push && push(`/place/${noticeId}?step=confirm-ad`);
                } }))),
        React.createElement("div", { className: `bg-white shadow sm:rounded-lg w-full relative ${showWarning ? 'bottom-1/2' : 'bottom-3/4'}` },
            React.createElement("div", { className: "p-4" },
                React.createElement("div", { className: "text-sm text-gray-500" },
                    React.createElement("p", { className: "flex flex-row" },
                        React.createElement(AttachmentIcon, { className: "inline-block mr-3 mt-1", style: { alignSelf: 'start' } }),
                        React.createElement("em", { className: "break-all", style: { alignSelf: 'start', flex: 1 } }, (noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.originalFileName) ||
                            ((_a = noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.oldSchemaMetadata) === null || _a === void 0 ? void 0 : _a['File Name'])))),
                React.createElement("div", { className: "mt-2 flex flex-row justify-between" },
                    React.createElement("a", { href: url, target: "_blank", rel: "noopener noreferrer", className: "text-sm" },
                        React.createElement("svg", { width: "24", height: "16", viewBox: "0 0 24 16", className: "fill-current inline-block mr-3", xmlns: "http://www.w3.org/2000/svg" },
                            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM19 14H6C3.79 14 2 12.21 2 10C2 7.95 3.53 6.24 5.56 6.03L6.63 5.92L7.13 4.97C8.08 3.14 9.94 2 12 2C14.62 2 16.88 3.86 17.39 6.43L17.69 7.93L19.22 8.04C20.78 8.14 22 9.45 22 11C22 12.65 20.65 14 19 14ZM10.55 6H13.45V9H16L12 13L8 9H10.55V6Z" })),
                        "Download"),
                    React.createElement("span", { className: "float-right text-sm text-gray-500" },
                        fileSize,
                        "KB"))))));
};
export default NoticeFilePreview;
