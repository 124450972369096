import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { DeleteIcon, PencilIcon } from 'icons';
import { ColumnButton } from 'components/ColumnButton';
import { RateType } from 'lib/enums';
import { Badge } from 'components/Badge';
import SettingsTableIcon from '../../SettingsTableIcon';
import { isDefaultRate } from './ratesTableSettingsUtils';
import ArchiveRateModal from './ArchiveRateModal';
/**
 * Table component for display rates and handling pagination
 */
export default function RatesTable({ activeOrganization, setEditedRateData, setEditedRateRef, setDrawerRate, ratesFilter, rates }) {
    const [rateToArchive, setRateToArchive] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const PAGE_SIZE = 10;
    const ratesTableSlice = rates.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    const totalPages = Math.ceil(rates.length / PAGE_SIZE);
    useEffect(() => {
        setPageNumber(0);
    }, [ratesFilter]);
    return (React.createElement(React.Fragment, null,
        rateToArchive && (React.createElement(ArchiveRateModal, { rateToArchive: rateToArchive, onClose: () => setRateToArchive(undefined) })),
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
            React.createElement("thead", { className: "h-16" },
                React.createElement("tr", { className: "text-left bg-gray-50 uppercase text-gray-650 text-sm tracking-wider" },
                    React.createElement("th", { className: "pl-12 font-medium" }, "Rate Name"),
                    React.createElement("th", { className: "font-medium" }, "Rate Type"),
                    React.createElement("th", { className: "font-medium" },
                        React.createElement("div", { className: "flex pr-12" },
                            React.createElement("div", { className: "flex-1" }),
                            React.createElement("div", null, "Actions"))))),
            React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, ratesTableSlice.map(rate => {
                var _a, _b;
                return (React.createElement("tr", { className: "hover:bg-gray-50 cursor-pointer h-20 text-sm leading-6 font-medium text-gray-650", onClick: () => setDrawerRate(rate), key: rate.id },
                    React.createElement("td", { className: "pl-12" },
                        rate.data().description,
                        isDefaultRate(activeOrganization, rate) && (React.createElement("span", { className: "ml-2" },
                            React.createElement(Badge, { status: "success" }, ((_a = activeOrganization.data().defaultLinerRate) === null || _a === void 0 ? void 0 : _a.id) ===
                                rate.id
                                ? 'Default Liner Rate'
                                : 'Default Display Rate')))),
                    React.createElement("td", null, (_b = RateType.by_value(rate.data().rateType)) === null || _b === void 0 ? void 0 : _b.label),
                    React.createElement("td", null,
                        React.createElement("div", { className: "flex mr-10" },
                            React.createElement("div", { className: "flex-1" }),
                            React.createElement(SettingsTableIcon, { onClick: () => {
                                    setEditedRateData(rate.data());
                                    setEditedRateRef(rate.ref);
                                }, icon: React.createElement(PencilIcon, { className: "w-5 h-5 text-grey-400" }) }),
                            React.createElement(SettingsTableIcon, { onClick: () => setRateToArchive(rate), disabled: isDefaultRate(activeOrganization, rate), icon: React.createElement(DeleteIcon, { className: classNames(
                                    // allow deleting non-default rates
                                    {
                                        'text-grey-400': !isDefaultRate(activeOrganization, rate)
                                    }, 
                                    // don't delete default rates
                                    {
                                        'text-gray-200': isDefaultRate(activeOrganization, rate)
                                    }) }) })))));
            }))),
        React.createElement("footer", { className: "bg-white pr-5 py-0.5 border-t border-b border-gray-300 rounded-b-md" },
            React.createElement("div", { className: "pl-6 py-3.5 flex justify-between" },
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Previous", disabled: pageNumber === 0, onClick: () => setPageNumber(pageNumber - 1) })),
                React.createElement("div", { className: "py-2.5 leading-6 text-sm font-medium text-grey-400" },
                    "Page ",
                    pageNumber + 1,
                    " of ",
                    totalPages),
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Next", disabled: pageNumber === totalPages - 1, onClick: () => setPageNumber(pageNumber + 1) }))))));
}
