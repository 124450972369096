import React from 'react';
import { isColumnUser } from 'lib/helpers';
import OldAffidavitSettings from './deprecated';
import AffidavitSettings from './AffidavitSettings';
/**
 * Feature-flag a new affidavit settings page, currently available only to Column users
 */
export default function AffidavitSettingsWrapper({ activeOrganization, user }) {
    if (isColumnUser(user)) {
        return (React.createElement(AffidavitSettings, { activeOrganization: activeOrganization, user: user }));
    }
    return React.createElement(OldAffidavitSettings, { activeOrganization: activeOrganization });
}
