var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import * as typeformEmbed from '@typeform/embed';
const objectToUrlParams = (obj) => Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');
const styles = () => createStyles({
    root: {
        marginLeft: -24,
        marginRight: -24,
        marginTop: -24,
        height: 'calc(100vh - 64px)',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background: 'white'
    },
    formContainer: {
        width: 'calc(100% - 100px)',
        marginRight: 60,
        height: '100%'
    }
});
const TYPEFORM_BASE = 'https://leohentschker154703.typeform.com/to';
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
const TypeformEmbed = ({ onSubmit, formId, hiddenFields, classes }) => {
    const [mounted, setMounted] = useState(false);
    const prevProps = usePrevious({ formId });
    let formContainer = null;
    const loadForm = () => {
        let timesRun = 0;
        if (!formContainer)
            return;
        typeformEmbed.makeWidget(formContainer, `${TYPEFORM_BASE}/${formId}?${objectToUrlParams(hiddenFields)}`, {
            onSubmit: () => {
                if (timesRun > 0) {
                    return;
                }
                timesRun += 1;
                onSubmit();
            }
        });
    };
    useEffect(() => {
        setMounted(true);
        loadForm();
    }, []);
    useEffect(() => {
        function checkFormID() {
            return __awaiter(this, void 0, void 0, function* () {
                if (mounted && formId !== (prevProps === null || prevProps === void 0 ? void 0 : prevProps.formId)) {
                    loadForm();
                }
            });
        }
        void checkFormID();
    }, [mounted]);
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.formContainer, ref: ref => (formContainer = ref), id: "form-container" })));
};
export default withStyles(styles, { withTheme: true })(TypeformEmbed);
