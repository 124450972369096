import classNames from 'classnames';
import React from 'react';
export const EditModalDetailsCell = ({ label, fieldKey, fieldValue, setFieldValue, originalFieldValue, changedFields, setChangedFields, validate, isRequired }) => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex inline-block" },
            React.createElement("div", { className: "text-blue-800" }, label),
            isRequired && (React.createElement("div", { className: "font-medium text-sm leading-6 text-gray-650 pl-0.5" }, "*"))),
        React.createElement("input", { id: fieldKey, className: "form-input focus:outline-none focus:border-blue-900 focus:shadow-outline-blue hover:border-blue-900 hover:shadow-outline-blue appearance-none rounded-md relative w-full bg-white px-4 py-3 mb-0 border border-grey-200 placeholder-gray-500 text-blue-800 font-medium text-sm leading-6", type: "text", value: fieldValue || '', onChange: e => {
                if (validate) {
                    if (!validate(e.target.value)) {
                        return;
                    }
                }
                setFieldValue(e.target.value);
                if (e.target.value === originalFieldValue) {
                    setChangedFields(Object.assign(Object.assign({}, changedFields), { [fieldKey]: false }));
                }
                else {
                    setChangedFields(Object.assign(Object.assign({}, changedFields), { [fieldKey]: true }));
                }
            } })));
};
export const EditModalDetailsRow = ({ isFirstRow, oneCellRow, children }) => {
    return (React.createElement("div", { className: classNames('grid gap-8', {
            'pt-6': !isFirstRow,
            'grid-cols-1': oneCellRow,
            'grid-cols-2': !oneCellRow
        }) }, children));
};
