var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnButton } from 'components/ColumnButton';
import { TextField } from 'components/TextField';
import ColumnSelect from 'components/select/ColumnSelect';
import { Label } from 'components/Label';
import { XIcon } from 'icons';
import SettingsUpdateCard from '../../../SettingsUpdateCard';
import FormInputWrapper from '../../../FormInputWrapper';
import { centsToExtendedCurrency, currencyToCents } from '../../ratesTable/ratesTableSettingsUtils';
/**
 * Full-screen form to update properties associated with a rate
 */
export default function AdditionalFeeUpdateForm({ editedAdditionalFeeIndex, editedAdditionalFee, activeOrganization, closeForm }) {
    const [updatedEditedAdditionalFee, setUpdatedEditedAdditionalFee] = useState(editedAdditionalFee);
    const [updating, setUpdating] = useState(false);
    const edited = safeStringify(updatedEditedAdditionalFee) !==
        safeStringify(editedAdditionalFee);
    const updateRateProperties = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        let newFees = [...(activeOrganization.data().additionalFees || [])];
        // create a new fee object
        if (editedAdditionalFeeIndex === -1) {
            newFees = [...newFees, updatedEditedAdditionalFee];
        }
        // update an existing fee object
        else {
            newFees[editedAdditionalFeeIndex] = updatedEditedAdditionalFee;
        }
        yield activeOrganization.ref.update({
            additionalFees: newFees
        });
        setUpdating(false);
        closeForm();
    });
    // Enable saving instantly for new rates, and after edit for existing rates
    const disableSave = editedAdditionalFeeIndex > -1 && (updating || !edited);
    return (React.createElement("div", { className: "fixed overflow-scroll z-100 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out" },
        React.createElement("div", { className: "bg-gray-80 h-screen" },
            React.createElement("div", { className: "flex justify-between py-4 px-6 bg-white border border-grey-100 sticky top-0" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement(ColumnButton, { startIcon: React.createElement(XIcon, { className: "h-6 w-6" }), size: "md", onClick: closeForm }),
                    React.createElement("span", { className: "pl-4 text-gray-800 font-semibold text-xl" }, "Edit Additional Fee")),
                React.createElement(ColumnButton, { buttonText: true ? 'Save' : 'Create Rate', disabled: disableSave, onClick: updateRateProperties, primary: true, size: "lg" })),
            React.createElement(SettingsUpdateCard, null,
                React.createElement(FormInputWrapper, null,
                    React.createElement(TextField, { id: "fee-description", labelText: "Fee Description", value: updatedEditedAdditionalFee.description, onChange: newValue => setUpdatedEditedAdditionalFee(Object.assign(Object.assign({}, updatedEditedAdditionalFee), { description: newValue })) })),
                React.createElement(FormInputWrapper, { className: "flex flex-col" },
                    React.createElement("div", { className: "pb-2" },
                        React.createElement(Label, { id: "rate-type-label" }, "Fee Configuration")),
                    React.createElement(ColumnSelect, { className: "flex-1 text-md", options: [
                            {
                                value: 'true',
                                label: 'Charge this fee for each run date'
                            },
                            {
                                value: 'false',
                                label: 'Charge this fee once'
                            }
                        ], onChange: newValue => {
                            if (!newValue)
                                return;
                            setUpdatedEditedAdditionalFee(Object.assign(Object.assign({}, updatedEditedAdditionalFee), { perRun: newValue === 'true' }));
                        }, value: updatedEditedAdditionalFee.perRun ? 'true' : 'false' })),
                React.createElement(FormInputWrapper, null,
                    React.createElement(TextField, { id: "fee-description", labelText: "Fee Amount", value: centsToExtendedCurrency(updatedEditedAdditionalFee.amount / 100), onChange: newValue => setUpdatedEditedAdditionalFee(Object.assign(Object.assign({}, updatedEditedAdditionalFee), { amount: currencyToCents(newValue) })) }))))));
}
