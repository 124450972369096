export const getAffidavitMilestoneTrackerParams = (isInvoicePaid, isNoticePublished, isAffidavitAvailable, isAffidavitDisabled, isInvoicedOutsideColumn) => {
    const invoicePaidOutsideColumn = isInvoicePaid && isInvoicedOutsideColumn;
    return [
        {
            status: invoicePaidOutsideColumn
                ? 'completeOutsideColumn'
                : isInvoicePaid
                    ? 'complete'
                    : isNoticePublished
                        ? 'error'
                        : 'inactive',
            nextConnector: isInvoicePaid && isNoticePublished ? 'active' : 'inactive',
            text: invoicePaidOutsideColumn
                ? 'Invoiced Outside Column'
                : 'Invoice Paid',
            tooltip: isInvoicedOutsideColumn
                ? 'Contact newspaper for your invoice and payment status.'
                : ''
        },
        {
            status: isNoticePublished ? 'complete' : 'inactive',
            nextConnector: isNoticePublished && (isAffidavitAvailable || isAffidavitDisabled)
                ? 'active'
                : 'inactive',
            text: 'Publication Completed'
        },
        {
            status: isAffidavitAvailable || isAffidavitDisabled ? 'complete' : 'inactive',
            text: isAffidavitDisabled
                ? 'Affidavit Not Required'
                : 'Affidavit Available'
        }
    ];
};
