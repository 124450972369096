var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { exists } from 'lib/types';
import { FormStep } from 'components/forms';
import pricingHelpers, { getDistributeFeeSettings, getInvoiceLineItems, shouldDistributeFee } from 'lib/pricing';
import LoadingState from 'components/LoadingState';
import { getCurrencySymbol, getDueDate, shouldBulkInvoiceUser } from 'lib/helpers';
import { getNoticeTypeStr } from 'lib/publishers';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { Firestore } from 'EnoticeFirebase';
import { getCustomer, getCustomerName } from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { BillingService } from 'services';
import { LineItemType } from 'lib/enums';
import { isAffidavitDisabled } from 'lib/affidavits';
import { sumFirstI } from './helpers';
import InvoiceFormCustomerTable from './InvoiceFormCustomerTable';
import InvoiceFormPaperInfoTable from './InvoiceFormPaperInfoTable';
import InvoiceFormTable from './InvoiceFormTable';
import InvoiceFormTotalDueTable from './InvoiceFormTotalDueTable';
import InvoiceFormDueDate from './InvoiceFormDueDate';
import InvoiceConfirm from './InvoiceConfirm';
import copy from './copy';
import InvoiceFormMemo from './InvoiceFormMemo';
import NoticePreview from '../NoticePreview';
const mapStateToProps = (state) => ({
    user: state.auth.user,
    activeOrganization: state.auth.activeOrganization,
    isPublisher: state.auth.isPublisher
});
const styles = (theme) => {
    return createStyles({
        formContainer: {
            marginTop: theme.spacing(2)
        },
        invoiceHeading: {
            marginBottom: theme.spacing(2)
        },
        totalDueTable: {
            marginTop: theme.spacing(2)
        },
        modalCloseButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        container: {
            width: '66%',
            maxHeight: '100vh',
            overflow: 'scroll'
        }
    });
};
const InvoiceForm = ({ forward, forwardLabel, back, classes, advertiserSnap, rateSnap, noticeSnap, newspaperSnap, activeOrganization, isPublisher, user, push }) => {
    const { postWithoutFormatting } = noticeSnap.data();
    const lineItemValues = postWithoutFormatting
        ? [{ amount: 0, date: new Date(), type: null }]
        : getInvoiceLineItems(noticeSnap, newspaperSnap, rateSnap);
    const [values, setValues] = useState(lineItemValues);
    const [stepComplete, setStepComplete] = useState(false);
    const [subtotal, setSubTotal] = useState(0);
    const [taxPercent, setTaxpercent] = useState(postWithoutFormatting ? 0 : noticeSnap.data().pricing.taxPct);
    const [totalDue, setTotalDue] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [belowMinimum, setBelowMinimum] = useState(true);
    const [customMemo, setCustomMemo] = useState('');
    const [customId, setCustomId] = useState(noticeSnap.data().customId || '');
    const [invoiceOutsideColumn, setInvoiceOutsideColumn] = useState(false);
    const [organizationName, setOrganizationName] = useState();
    const [advertiserName, setAdvertiserName] = useState();
    const [dueDate, setDueDate] = useState();
    const [requireUpfrontPayment, setRequireUpfrontPayment] = useState();
    const [ianaTimezone, setIanaTimezone] = useState();
    const [isWithinBulkPayment, setIsWithinBulkPayment] = useState();
    const [isConfirmingInvoice, setIsConfirmingInvoice] = useState(false);
    const handleChange = (i) => (event) => {
        const newValues = [...values];
        newValues[i].amount = Number(Math.min(30000, +parseFloat(event.target.value)).toFixed(2));
        setValues(newValues);
    };
    const isValid = () => {
        return !belowMinimum;
    };
    useEffect(() => {
        if (isNaN(subtotal) || !rateSnap.data())
            return;
        return setBelowMinimum(pricingHelpers.uiToDBCurrency(subtotal) < rateSnap.data().minimum);
    }, [subtotal]);
    useEffect(() => {
        if (exists(newspaperSnap)) {
            const { iana_timezone } = newspaperSnap.data();
            setIanaTimezone(iana_timezone);
            void (() => __awaiter(void 0, void 0, void 0, function* () {
                const dueDate = yield getDueDate(getFirebaseContext(), noticeSnap, requireUpfrontPayment);
                setDueDate(dueDate);
                const dueDateMoment = moment(dueDate * 1000);
                if (moment().isAfter(dueDateMoment)) {
                    setRequireUpfrontPayment(false);
                }
            }))();
        }
    }, [requireUpfrontPayment, newspaperSnap]);
    useEffect(() => {
        const fetchOrgAndAdvertiserName = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            let filerOrg;
            const { filedBy } = noticeSnap.data();
            if (filedBy) {
                filerOrg = yield filedBy.get();
            }
            const ctx = getFirebaseContext();
            const customer = yield getCustomer(ctx, advertiserSnap, newspaperSnap);
            setOrganizationName(((_a = filerOrg === null || filerOrg === void 0 ? void 0 : filerOrg.data()) === null || _a === void 0 ? void 0 : _a.name) || '');
            setAdvertiserName(getCustomerName(customer, advertiserSnap, false));
        });
        if (noticeSnap && exists(noticeSnap)) {
            void fetchOrgAndAdvertiserName();
        }
    }, [noticeSnap, newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id]);
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsConfirmingInvoice(true);
        const noticeTypeStr = getNoticeTypeStr(noticeSnap.data().noticeType, newspaperSnap);
        const stripeLineItems = values.map(v => {
            const { amount, date, description } = v;
            const pubDateStr = moment(date.getTime()).format('MM/DD/YYYY');
            return {
                // convert for stripe
                amount: pricingHelpers.uiToDBCurrency(amount),
                // convert to unix time for sending to stripe
                date: date.getTime() / 1000,
                description: description || `${pubDateStr}: ${noticeTypeStr} Notice`
            };
        });
        const inAppLineItems = values.map(({ amount, date, description, type }) => {
            const pubDateStr = moment(date.getTime()).format('MM/DD/YYYY');
            return {
                amount: pricingHelpers.uiToDBCurrency(amount),
                date: Firestore.Timestamp.fromDate(date),
                description: description || `${pubDateStr}: ${noticeTypeStr} Notice`,
                type: type || LineItemType.publication.value
            };
        });
        const inAppInvoicedAmt = pricingHelpers.uiToDBCurrency(totalDue);
        if (customId)
            yield noticeSnap.ref.update({
                customId
            });
        yield BillingService.invoiceAdvertiser({
            inAppInvoicedAmt,
            stripeLineItems,
            inAppLineItems,
            inAppTaxPct: taxPercent,
            noticeSnap,
            advertiserSnap,
            newspaperSnap,
            customId,
            customMemo,
            dueDate,
            requireUpfrontPayment,
            invoiceOutsideColumn,
            user
        });
        setIsConfirmingInvoice(false);
        setOpenModal(false);
        forward();
    });
    useEffect(() => {
        const amounts = postWithoutFormatting ? [0] : values.map(val => val.amount);
        setSubTotal(sumFirstI(amounts, values.length));
        setStepComplete(isValid());
    }, [values, belowMinimum]);
    useEffect(() => {
        setTotalDue(subtotal * (1 + taxPercent / 100));
    }, [subtotal, taxPercent]);
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(newspaperSnap) || !exists(noticeSnap)) {
                return;
            }
            const isWithinBulkPayment = yield shouldBulkInvoiceUser(getFirebaseContext(), noticeSnap, newspaperSnap);
            setIsWithinBulkPayment(isWithinBulkPayment);
        }))();
    }, [newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id, noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.id]);
    if (!exists(newspaperSnap) ||
        !activeOrganization ||
        isWithinBulkPayment === undefined) {
        return React.createElement(LoadingState, { context: { location: 'InvoiceForm' } });
    }
    const handleTaxPercentChange = (e) => {
        const pct = e.target.value;
        setTaxpercent(+parseFloat(pct).toFixed(2));
    };
    const affidavitDisabled = isAffidavitDisabled(noticeSnap, newspaperSnap);
    const getTermsText = ({ showDistributeMessage } = { showDistributeMessage: false }) => {
        const { invoiceRecipient } = noticeSnap.data();
        const role = invoiceRecipient ? 'invoice recipient' : 'advertiser';
        const name = invoiceRecipient
            ? invoiceRecipient.organizationName ||
                `${invoiceRecipient.firstName} ${invoiceRecipient.lastName}`
            : organizationName || advertiserName;
        return `The ${role}, ${name}, will be billed for the advertisement plus taxes and fees. ${copy.enoticeName} will fill this invoice and pay ${newspaperSnap.data().name} net 30 from when ${affidavitDisabled
            ? 'payment is received.'
            : 'the proof of publication is uploaded.'}${shouldDistributeFee(getDistributeFeeSettings(newspaperSnap, rateSnap)) &&
            showDistributeMessage
            ? '</br></br> Column processing fee will be distributed across existing line items. To make fee a separate line item, contact help@column.us'
            : ''}`;
    };
    const currency = getCurrencySymbol(newspaperSnap.data().currency);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-gray-100 " },
            React.createElement("div", { id: "notice-details-page", className: "max-w-full mx-auto sm:px-6 lg:px-8 lg:pr-0 sm:pr-0" },
                React.createElement("div", { className: "grid grid-cols-8 grid-flow-col gap-8 -ml-8" },
                    React.createElement("div", { className: "col-span-8 md:col-span-5 py-20 overflow-y-scroll h-screen -mr-8" },
                        React.createElement(FormStep, { title: 'Create Invoice', stepComplete: stepComplete, forward: () => {
                                setOpenModal(true);
                            }, id: "invoice-form", forwardLabel: forwardLabel, back: back },
                            React.createElement(Grid, { container: true, direction: "column", className: classes.formContainer },
                                React.createElement(Grid, { container: true, direction: "row", spacing: 4, justify: "space-between" },
                                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                        React.createElement(InvoiceFormPaperInfoTable, { organization: newspaperSnap.data() }),
                                        React.createElement(Typography, { variant: "h5" })),
                                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                        React.createElement(InvoiceFormCustomerTable, { noticeSnap: noticeSnap, customerName: pricingHelpers.ENOTICE_NAME, setCustomId: setCustomId, customId: customId }))),
                                React.createElement(Grid, { container: true, direction: "row", className: classes.invoiceHeading })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(InvoiceFormTable, { handleChange: handleChange, values: values, currency: currency })),
                            React.createElement(Grid, { container: true, direction: "row", spacing: 4, justify: "space-between" },
                                React.createElement(Grid, { item: true, className: classes.totalDueTable, sm: 12, md: 6 }, noticeSnap.data().adTemplate && (React.createElement(InvoiceFormMemo, { terms: getTermsText({ showDistributeMessage: true }), noticeSnap: noticeSnap, setCustomMemo: setCustomMemo, customMemo: customMemo, newspaper: newspaperSnap.data(), currency: currency }, ' '))),
                                React.createElement(Grid, { item: true, className: classes.totalDueTable, sm: 12, md: 6 },
                                    React.createElement(InvoiceFormTotalDueTable, { handleTaxPercentChange: handleTaxPercentChange, subtotal: subtotal, belowMinimum: belowMinimum, rateMinimum: pricingHelpers.dbToUICurrencyString(rateSnap.data().minimum), taxPercent: taxPercent, totalDue: totalDue, currency: currency }),
                                    React.createElement(InvoiceFormDueDate, Object.assign({ newspaper: newspaperSnap }, {
                                        noticeSnap,
                                        setDueDate,
                                        dueDate,
                                        requireUpfrontPayment,
                                        setRequireUpfrontPayment,
                                        invoiceOutsideColumn,
                                        setInvoiceOutsideColumn,
                                        ianaTimezone,
                                        isWithinBulkPayment
                                    })))))),
                    React.createElement("div", { id: "sidebar", className: "hidden md:block md:col-span-3 h-screen" },
                        React.createElement("div", { className: "bg-gray-800 h-full" },
                            React.createElement(NoticePreview, { newspaper: newspaperSnap, isPublisher: !!isPublisher, notice: noticeSnap, push: push })))))),
        openModal && (React.createElement(FreeformCModal, { setOpen: setOpenModal, header: React.createElement("span", null,
                React.createElement("h6", { className: "leading-6 text-lg" }, " Review and Submit "),
                React.createElement("div", { className: "not-italic font-normal text-sm leading-6 flex items-center text-center text-gray-800 mb-4" },
                    "This invoice is due",
                    noticeSnap.data().invoiceRecipient
                        ? ' '
                        : ' by the advertiser ',
                    "on",
                    React.createElement("b", { className: "ml-1" }, dueDate && ianaTimezone
                        ? moment(dueDate * 1000)
                            .tz(ianaTimezone)
                            .format('MMMM D, YYYY')
                        : ''))), body: React.createElement(InvoiceConfirm, { getTermsText: getTermsText, handleConfirm: handleConfirm, isConfirmingInvoice: isConfirmingInvoice }) })),
        React.createElement("style", null, `
        #sidebar {
          z-index: 100;
        }
      `)));
};
export default connect(mapStateToProps, { push })(withStyles(styles, { withTheme: true })(InvoiceForm));
