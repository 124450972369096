import React from 'react';
/**
 * Header component for the e-edition drawer
 */
export default function AffidavitUploadDayDrawerHeader({ affidavitUploadDay, activeOrganization }) {
    return (React.createElement("div", { id: "affidavit-upload-day-drawer-header" },
        React.createElement("div", { className: "text-gray-800 font-semibold text-xl" },
            activeOrganization.data().name,
            " |",
            ' ',
            affidavitUploadDay.publishingDateString)));
}
