var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { exists } from 'lib/types';
import EModal from 'components/modals/EModal';
import FreeformCModal from 'components/modals/FreeFormCModal';
import AuthActions from 'redux/auth';
import Firebase, { Collections } from 'EnoticeFirebase';
import LoadingState from 'components/LoadingState';
import { getUserSnap } from '../../userNoticeList/queries';
import InvoiceForm from './InvoiceForm';
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        organization: state.auth.organization,
        activeOrganization: state.auth.activeOrganization,
        availableOrganizations: state.auth.availableOrganizations,
        id: (_b = (_a = matchPath(state.router.location.pathname, {
            path: '/:path(notice|publish)/:id/invoice/create',
            exact: true,
            strict: false
        })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id,
        state
    });
};
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
const CreateInvoice = ({ id, push, activeOrganization, availableOrganizations, authActions }) => {
    var _a;
    const [advertiserSnap, setAdvertiserSnap] = useState();
    const [rateSnap, setRateSnap] = useState();
    const [newspaperSnap, setNewspaperSnap] = useState();
    const [lastModifiedDate, setLastModifiedDate] = useState();
    const [needRefresh, setNeedRefresh] = useState(false);
    const [openPostModal, setOpenPostModal] = useState();
    const [notice] = useDocument(Firebase.firestore().collection(Collections.userNotices).doc(id));
    const noticeSnap = notice;
    const getRelatedFields = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!exists(noticeSnap)) {
            return;
        }
        const [rateSnap, advertiserSnap, newspaperSnap] = yield Promise.all([
            noticeSnap.data().rate.get(),
            getUserSnap({ uid: noticeSnap.data().userId }),
            noticeSnap.data().newspaper.get()
        ]);
        const newLastModifiedDate = (noticeSnap.data().editedAt || noticeSnap.data().confirmedAt).toMillis();
        if (!lastModifiedDate || newLastModifiedDate === lastModifiedDate)
            setLastModifiedDate(newLastModifiedDate);
        else
            setNeedRefresh(true);
        if (exists(rateSnap)) {
            setRateSnap(rateSnap);
        }
        if (exists(advertiserSnap)) {
            setAdvertiserSnap(advertiserSnap);
        }
        if (exists(newspaperSnap)) {
            setNewspaperSnap(newspaperSnap);
        }
    });
    useEffect(() => {
        if (exists(noticeSnap)) {
            void getRelatedFields();
            if (noticeSnap.data().postWithoutFormatting)
                setOpenPostModal(noticeSnap.data().postWithoutFormatting);
        }
    }, [noticeSnap]);
    useEffect(() => {
        if (exists(newspaperSnap) &&
            exists(activeOrganization) &&
            newspaperSnap.id !== activeOrganization.id) {
            authActions.setActiveOrganization(availableOrganizations.find(o => o.id === newspaperSnap.id));
        }
    }, [newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id, activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    if (!exists(noticeSnap) ||
        !exists(advertiserSnap) ||
        !exists(rateSnap) ||
        !exists(newspaperSnap) ||
        (newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id) !== (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id)) {
        return (React.createElement(LoadingState, { context: {
                location: 'CreateInvoice',
                tags: {
                    noticeId: (_a = noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.id) !== null && _a !== void 0 ? _a : ''
                }
            } }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(InvoiceForm, { forward: () => {
                if (!window.location.pathname.includes('/notices/')) {
                    push(`/notice/${id}`);
                }
            }, back: () => {
                push(`/notice/${id}`);
            }, rateSnap: rateSnap, advertiserSnap: advertiserSnap, noticeSnap: noticeSnap, newspaperSnap: newspaperSnap, forwardLabel: "Review & Submit" }),
        needRefresh && (React.createElement(EModal, { header: "Notice Update", body: `The notice content has been updated by another user. 
        In order to submit your invoice, you must refresh this page to see an updated price.`, buttonText: "Refresh Invoice", onConfirm: () => push(window.location.pathname) })),
        openPostModal && exists(newspaperSnap) && (React.createElement(FreeformCModal, { id: "upload-proof", header: "Upload Proof", body: `Before creating an invoice, please upload a final proof for this notice as it will appear in ${newspaperSnap.data().name}.`, setOpen: setOpenPostModal },
            React.createElement("section", null,
                React.createElement("button", { id: `confirm-upload-proof`, onClick: () => push(`/place/${id}?step=confirm-ad`), className: "bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-32" }, "Continue"))))));
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
