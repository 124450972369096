import React, { useState } from 'react';
import { isColumnUser } from 'lib/helpers';
import { EHandlebars } from 'lib/headers_footers/shared';
import { Alert } from 'components/Alert';
import { getAffidavitHandlebarsForActiveOrganization } from './basicAffidavitSettingsUtils';
import { affidavitsAreManagedByColumn } from '../affidavitSettingsUtils';
import SettingsTabs from '../../SettingsTabs';
import AffidavitSettingsCard from './AffidavitSettingsCard';
import AffidavitMCEEditor from './AffidavitMCEEditor';
const WYSIWYG_EDITOR_TAB = 'Edit Content';
const HTML_EDITOR_TAB = 'Edit HTML Source';
const PREVIEW_TAB = 'Preview';
/**
 * Returns a set of layout tabs based on the user's role and whether the affidavit is notarized. Column users see everything, other users see everything for their affidavit, and only see preview for Column managed affidavits.
 * @param user
 * @param isColumnNotarizedAffidavit
 * @returns {string[]} set of tabs
 */
export const getLayoutSettingsTabs = (user, isColumnNotarizedAffidavit) => {
    return [
        {
            label: PREVIEW_TAB,
            enabled: true
        },
        {
            label: WYSIWYG_EDITOR_TAB,
            enabled: isColumnUser(user) || !isColumnNotarizedAffidavit
        },
        {
            label: HTML_EDITOR_TAB,
            enabled: isColumnUser(user) || !isColumnNotarizedAffidavit
        }
    ];
};
/**
 * Returns the subheader for the affidavit edit box
 * @param user
 * @param isColumnNotarizedAffidavit
 * @returns {string} subheader
 */
const getLayoutSubheader = (activeOrganization, user, isColumnNotarizedAffidavit) => {
    if (isColumnNotarizedAffidavit) {
        if (isColumnUser(user)) {
            return 'Control the appearance of affidavits managed by Column. Edit functionality of Column affidavits is available for Column users only.';
        }
        return 'Column generated affidavits can only be edited by Column support. To make customizations to this layout, reach out to your Column account executive.';
    }
    if (affidavitsAreManagedByColumn(activeOrganization)) {
        return `Control the appearance of your affidavits. Settings here apply to affidavits that are manually submitted by staff at ${activeOrganization.data().name}. This setting should be used by advanced users only.`;
    }
    return 'Control the appearance of your affidavits. This setting should be used by advanced users only.';
};
/**
 * Control HTML contained in the organization's affidavit
 */
export default function AffidavitLayoutSettings({ isColumnNotarizedAffidavit, activeOrganization, setAffidavitHTML, affidavitHTML, errors, user }) {
    const [editorTab, setEditorTab] = useState({
        label: PREVIEW_TAB,
        enabled: true
    });
    const compiledHtml = EHandlebars.compile(affidavitHTML)(getAffidavitHandlebarsForActiveOrganization(activeOrganization));
    const tabs = getLayoutSettingsTabs(user, isColumnNotarizedAffidavit);
    return (React.createElement(AffidavitSettingsCard, { header: isColumnNotarizedAffidavit
            ? 'Column Affidavit Layout Settings'
            : 'Advanced Settings', subheader: getLayoutSubheader(activeOrganization, user, isColumnNotarizedAffidavit) },
        errors.length > 0 && (React.createElement("div", { className: "col-span-6" },
            React.createElement(Alert, { status: "error", title: `${errors.length} errors found in custom affidavit HTML:`, id: "error-with-affidavit" },
                React.createElement("ul", { className: "pl-4 list-disc" }, errors.map(error => (React.createElement("li", { className: "text-sm leading-6 text-red-700", key: error.message }, error.message))))))),
        React.createElement("div", { className: "col-span-6 flex items-center -mt-8" },
            React.createElement(SettingsTabs, { setActiveTab: setEditorTab, className: "flex justify-center", activeTab: editorTab, tabs: tabs })),
        editorTab.label === WYSIWYG_EDITOR_TAB && (React.createElement("div", { className: "col-span-6" },
            React.createElement(AffidavitMCEEditor, { activeOrganization: activeOrganization, setAffidavitHTML: setAffidavitHTML, affidavitHTML: affidavitHTML }))),
        editorTab.label === HTML_EDITOR_TAB && (React.createElement("div", { className: "p-6 bg-gray-50 col-span-6 shadow" },
            React.createElement("textarea", { className: "bg-white w-full h-80", onChange: e => setAffidavitHTML(e.target.value), value: affidavitHTML }))),
        editorTab.label === PREVIEW_TAB && (React.createElement("div", { className: "col-span-6 w-full h-full shadow bg-gray-50 p-6", style: { height: 1000 } },
            React.createElement("iframe", { title: "in-app-html-editor", className: "bg-white h-full w-full", srcDoc: compiledHtml }))),
        React.createElement("style", null, `
        .tox-notifications-container {
          display: none;
        }
      `)));
}
