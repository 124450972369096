import React from 'react';
import { dbToUICurrencyString } from 'lib/pricing';
import RatePropertiesRow from './RatePropertiesRow';
import FormSwitch from '../../../FormSwitch';
/**
 * Tab for displaying and enabling (or removing) additional fees for a rate.
 */
export default function AdditionalFeesTab({ rate }) {
    var _a;
    const { additionalFee } = rate.data();
    return (React.createElement(React.Fragment, null,
        React.createElement(RatePropertiesRow, { label: "Enabled", value: React.createElement(FormSwitch, { disabled: true, checked: !!rate.data().additionalFee }) }),
        additionalFee && (React.createElement(React.Fragment, null,
            React.createElement(RatePropertiesRow, { label: "Additional Fee", value: additionalFee.amount
                    ? `$${dbToUICurrencyString(additionalFee.amount)}`
                    : '' }),
            React.createElement(RatePropertiesRow, { label: "Additional Fee Description", value: (_a = rate.data().additionalFee) === null || _a === void 0 ? void 0 : _a.description })))));
}
