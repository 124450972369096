var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from 'components/CButton';
import { exists } from 'lib/types';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
const DeleteDraftModal = ({ handleClose, setOpen, noticeToDelete, noticeActions }) => {
    const deleteNotice = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userNoticeRef = getFirebaseContext()
                .userNoticesRef()
                .doc(noticeToDelete.orginalnoticeid);
            const userNoticeSnap = yield userNoticeRef.get();
            if (!exists(userNoticeSnap)) {
                logAndCaptureMessage('Tried to delete a notice which does not exist', {
                    noticeId: noticeToDelete.orginalnoticeid
                });
                handleClose();
                return;
            }
            const userNotice = userNoticeSnap.data();
            if (userNotice.drafts && userNotice.drafts.length) {
                yield Promise.all(userNotice.drafts.map(d => d.delete()));
            }
            if (noticeToDelete.isdraft && !userNotice.noticeStatus) {
                yield userNoticeRef.delete();
            }
            // Refresh drafts table after 3 seconds to allow it to be deleted in Elastic
            setTimeout(() => {
                noticeActions.updateNotices();
            }, 3000);
        }
        catch (e) {
            logAndCaptureException(e, 'Error deleting notice', {
                noticeId: noticeToDelete.id
            });
        }
        handleClose();
    });
    return (React.createElement(FreeformCModal, { id: "delete-draft-modal", setOpen: setOpen, header: "Delete draft?", body: `Are you sure you would like to delete this draft? Clicking Delete cannot be undone.` },
        React.createElement(CButton, { "data-tesid": "confirm-delete", id: 'confirm-delete', className: `bg-red-200 focus:border-red-500 text-red-700 hover:bg-red-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-3/6`, onClick: deleteNotice }, "Delete")));
};
const mapDispatchToProps = (dispatch) => ({
    noticeActions: bindActionCreators(NoticesActions, dispatch)
});
export default connect(null, mapDispatchToProps)(DeleteDraftModal);
