import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton, InputAdornment, Box } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { dateObjectToDay, getDeadlineString, getRelevantDeadline } from 'lib/utils/deadlines';
import { NoticeType } from 'lib/enums';
import { getNoticeType } from 'lib/helpers';
import { DeleteIcon } from 'icons';
import { shouldDisableDate } from './helpers';
const styles = (theme) => createStyles({
    checkCircle: {
        color: theme.palette.primary.main
    },
    warning: {
        color: theme.palette.error.main
    },
    adDeadlineMessage: {
        marginTop: theme.spacing(1)
    },
    infoIcon: {
        color: theme.palette.primary.main
    },
    modalInnerContainer: {
        padding: theme.spacing(2)
    },
    card: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    cardContainer: {
        width: '100%'
    },
    removeButton: {
        color: theme.palette.secondary.main
    },
    modalCloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    checkButton: {
        padding: theme.spacing(1)
    },
    statusText: {
        color: theme.palette.grey[600],
        textAlign: 'center'
    },
    picker: {
        background: 'white',
        '& .MuiInputBase-root': {
            height: theme.spacing(4)
        },
        border: '1px #cbd5e0 solid',
        borderRadius: '0.375rem',
        padding: '4px 0px 4px 12px',
        width: 180
    },
    enabled: {
        '& .MuiInputBase-root': {
            color: '#171F2E'
        }
    },
    disabled: {
        '& .MuiInputBase-root': {
            color: '#718096'
        }
    },
    dateInput: {
        cursor: 'pointer'
    },
    pickerIcon: {
        marginLeft: theme.spacing(1)
    },
    subHeading: {
        color: theme.palette.grey.scrollFormHeading,
        fontWeight: 600
    },
    deleteButton: {
        color: 'rgba(0, 0, 0, 0.4)'
    }
});
const ScheduleDecisionRow = ({ newspaper, placement, deadlines, publicationDate, newspaperTimezone, isAfterPublishingDeadline, classes, index, handleRemoveRowClick, handlePubDateChange, isPublisher, editing, user, disabled, showDelete, notice }) => {
    const getDeadlineText = () => {
        if (!publicationDate)
            return 'Deadline information will appear once a date has been selected.';
        if (isPublisher && isAfterPublishingDeadline) {
            return `Your deadline for ${dateObjectToDay(publicationDate)} has passed, but you can still upload this notice.`;
        }
        const relevantDeadline = getRelevantDeadline(publicationDate, deadlines, newspaper.data().deadlineOverrides);
        let text;
        if (!isAfterPublishingDeadline) {
            const baseDeadlineText = getDeadlineString(publicationDate, deadlines, newspaperTimezone, placement, newspaper);
            text = `Must be submitted by ${baseDeadlineText}`;
        }
        else {
            text = `The deadline for advertisments for ${dateObjectToDay(publicationDate)} was ${getDeadlineString(publicationDate, deadlines, newspaperTimezone, placement, newspaper)}`;
        }
        if (relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset) {
            const noticeWithOppositeType = Object.assign(Object.assign({}, placement), { noticeType: placement.noticeType === NoticeType.display_ad.value
                    ? NoticeType.custom.value
                    : NoticeType.display_ad.value });
            const otherDeadlineString = getDeadlineString(publicationDate, deadlines, newspaperTimezone, noticeWithOppositeType, newspaper);
            if (placement.noticeType === NoticeType.display_ad.value) {
                text += ` for display advertisements and ${otherDeadlineString} for liners`;
            }
            else {
                text += ` for liner advertisements and ${otherDeadlineString} for displays`;
            }
        }
        text += '.';
        return text;
    };
    const deadlineText = getDeadlineText();
    const noticeType = getNoticeType(notice, newspaper);
    return (React.createElement(Grid, { container: true, direction: "row", justify: "flex-start", alignItems: "center", style: { flexWrap: 'nowrap', position: 'relative' } },
        React.createElement(Grid, { item: true, container: true, className: classes.publicationDate, style: { width: 180, minWidth: 180 }, justify: "center" },
            React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                React.createElement(DatePicker, { id: `date-picker-${index}`, label: "", value: publicationDate, placeholder: "MM/DD/YYYY", className: `${classes.picker} date-picker-publish ${disabled
                        ? `${classes.disabled}.cursor-not-allowed`
                        : `${classes.enabled}`}`, shouldDisableDate: (day) => {
                        if (day) {
                            return shouldDisableDate(day, newspaper, user, placement, editing, noticeType, isPublisher);
                        }
                        return true;
                    }, InputProps: {
                        disableUnderline: true,
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement("svg", { className: "w-5 h-5", fill: "none", stroke: disabled ? '#718096' : '#171F2E', viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" })))),
                        className: classes.dateInput
                    }, disablePast: !isPublisher, 
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled: disabled ||
                        (!!(noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
                            !isPublisher &&
                            index > 0 &&
                            index < (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications)), autoOk: true, onChange: date => handlePubDateChange(date, index), minDateMessage: "Selected date after publication deadline" }))),
        React.createElement("div", { className: "flex-col content-start min-w-0" },
            React.createElement(Grid, { container: true, direction: "row", className: "items-center" },
                React.createElement(Box, { ml: 2, style: { overflow: 'hidden' } },
                    React.createElement(Grid, { item: true, style: {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            margin: 'auto 0 auto 0'
                        } },
                        React.createElement("div", { "data-testid": "deadline-text-preview", className: `font-medium text-sm whitespace-normal ${disabled ? 'text-gray-700' : 'text-gray-900'}`, title: deadlineText }, deadlineText))),
                !disabled &&
                    showDelete &&
                    !(!!(noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
                        !isPublisher &&
                        index < (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications)) && (React.createElement(Grid, { item: true, xs: true },
                    React.createElement(Box, { ml: 1, style: { textAlign: 'end' } },
                        React.createElement(IconButton, { size: "small", className: classes.deleteButton, onClick: () => handleRemoveRowClick(index) },
                            React.createElement(DeleteIcon, { className: "w-5 h-5 text-grey-700" })))))))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps)(withStyles(styles)(ScheduleDecisionRow));
