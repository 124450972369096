import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import React from 'react';
/**
 * Tab control over whether we are managing:
 * 1) Affidavit settings
 * 2) Automated affidavits
 */
export default function SettingsTabs({ setActiveTab, className, activeTab, tabs }) {
    return (React.createElement(Tab.Group, { onChange: (index) => {
            if (tabs[index].enabled) {
                setActiveTab(tabs[index]);
            }
        } },
        React.createElement(Tab.List, { className: classNames('w-full border-b border-gray-300', className) }, tabs.map((tab, index) => (React.createElement(Tab, { className: classNames('w-40 ml-7 py-6 text-base font-medium focus:outline-none', {
                'text-blue-950 border-b-2 border-blue-900': activeTab.label === tab.label
            }, {
                'hover:text-blue-950': tab.enabled
            }, {
                'text-gray-500 cursor-not-allowed': !tab.enabled
            }), key: index }, tab.label))))));
}
