var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PlacementActions from 'redux/placement';
import { getStateFromLocationParams, getRestrictedPapersFromLocationParams } from '../helpers';
// TODO: Is there a better place to handle this state logic in an async Redux action rather than a useEffect?
export function useRestrictedPlacementFlowParams() {
    const dispatch = useDispatch();
    const [restrictedPapers, setRestrictedPapers] = useState();
    const [restrictedStates, setRestrictedStates] = useState();
    const restrictedSingleState = getStateFromLocationParams();
    useEffect(() => {
        if (restrictedSingleState) {
            dispatch(PlacementActions.setFilingState(restrictedSingleState));
        }
    }, [restrictedSingleState]);
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            const { restrictedStates, restrictedPapers } = yield getRestrictedPapersFromLocationParams();
            setRestrictedStates(restrictedStates);
            setRestrictedPapers(restrictedPapers);
        }))();
    }, []);
    return { restrictedStates, restrictedPapers, restrictedSingleState };
}
