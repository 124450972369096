var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { getEventDataFromEventSnaps } from './utils';
import ActivityLogHeader from './ActivityLogHeader';
import ActivityLogEvents from './ActivityLogEvents';
import ActivityLogLoader from './ActivityLogLoader';
function Activity({ setShowPreviewOnMobile, isPublisher, notice, user }) {
    var _a, _b;
    const [advertiserSnap, setAdvertiserSnap] = useState();
    const [advertiserOrgName, setAdvertiserOrgName] = useState('');
    const [newspaperName, setNewspaperName] = useState('');
    const [timezone, setTimezone] = useState('');
    const [events, setEvents] = useState();
    // fetch data related to the notice
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            const filer = yield notice.data().filer.get();
            if (exists(filer))
                setAdvertiserSnap(filer);
        }))();
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    // fetch data related to the advertiser
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const userOrg = yield ((_b = (_a = advertiserSnap === null || advertiserSnap === void 0 ? void 0 : advertiserSnap.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.get());
            const advertiserOrgName = ((_c = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _c === void 0 ? void 0 : _c.name) || ((_d = advertiserSnap === null || advertiserSnap === void 0 ? void 0 : advertiserSnap.data()) === null || _d === void 0 ? void 0 : _d.organizationName);
            if (advertiserOrgName)
                setAdvertiserOrgName(advertiserOrgName);
        }))();
    }, [advertiserSnap === null || advertiserSnap === void 0 ? void 0 : advertiserSnap.id]);
    // fetch data related the newspaper
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const newspaperSnap = yield notice.data().newspaper.get();
            setNewspaperName(((_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.name) || '');
            setTimezone(((_b = newspaperSnap.data()) === null || _b === void 0 ? void 0 : _b.iana_timezone) || '');
        }))();
    }, [(_b = (_a = notice.data()) === null || _a === void 0 ? void 0 : _a.newspaper) === null || _b === void 0 ? void 0 : _b.id]);
    // create a snapshot listener on our events collection and return a set of relevant events
    useEffect(() => {
        let eventsUnsub;
        if (!newspaperName)
            return;
        void (() => __awaiter(this, void 0, void 0, function* () {
            eventsUnsub = getFirebaseContext()
                .eventsRef()
                .where('notice', '==', notice.ref)
                .orderBy('createdAt')
                .onSnapshot((queryResult) => __awaiter(this, void 0, void 0, function* () {
                const eventData = yield getEventDataFromEventSnaps(queryResult.docs, {
                    advertiserOrgName,
                    advertiserSnap,
                    newspaperName,
                    isPublisher,
                    notice,
                    user
                });
                setEvents(eventData);
            }));
        }))();
        return () => {
            eventsUnsub && eventsUnsub();
        };
    }, [notice === null || notice === void 0 ? void 0 : notice.id, newspaperName, isPublisher, user === null || user === void 0 ? void 0 : user.id, advertiserSnap === null || advertiserSnap === void 0 ? void 0 : advertiserSnap.id]);
    return (React.createElement("div", { className: `h-screen py-20 border border-b-0` },
        React.createElement("div", { className: "grid grid-cols-4 gap-4 p-6 pt-0" },
            React.createElement(ActivityLogHeader, { setShowPreviewOnMobile: setShowPreviewOnMobile }),
            events && (events === null || events === void 0 ? void 0 : events.length) > 0 ? (React.createElement(ActivityLogEvents, { timezone: timezone, events: events })) : (React.createElement(ActivityLogLoader, null)))));
}
export default Activity;
