import React, { useState } from 'react';
import { connect } from 'react-redux';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import Archive from './Archive';
import Upload from './Upload';
const UPLOAD = 'upload';
const ARCHIVE = 'archive';
const FTPSettings = ({ availableOrganizations, activeOrganization, organization }) => {
    const [activeTab, setActiveTab] = useState(UPLOAD);
    if (!exists(organization)) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("nav", { className: "flex pb-2" },
                React.createElement("div", { className: `px-3 py-2 font-medium text-sm text-gray-700 leading-5 rounded-md ${activeTab === UPLOAD ? 'bg-gray-200' : 'hover:bg-gray-100'} focus:outline-none focus:text-indigo-600 focus:bg-indigo-50 cursor-pointer`, onClick: () => setActiveTab(UPLOAD) }, "Upload"),
                React.createElement("div", { className: `ml-4 px-3 py-2 font-medium text-sm text-gray-700 leading-5 rounded-md ${activeTab === ARCHIVE ? 'bg-gray-200' : 'hover:bg-gray-100'} focus:outline-none focus:text-indigo-800 focus:bg-indigo-200 cursor-pointer`, onClick: () => setActiveTab(ARCHIVE) }, "Archive"))),
        activeTab === UPLOAD && (React.createElement(Upload, { availableOrganizations: availableOrganizations, activeOrganization: activeOrganization, organization: organization })),
        activeTab === ARCHIVE && React.createElement(Archive, { organization: activeOrganization })));
};
const mapStateToProps = (state) => ({
    organization: state.auth.organization
});
export default connect(mapStateToProps)(FTPSettings);
