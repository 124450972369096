var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CircularProgress, Button } from '@material-ui/core';
import { UploadIcon } from 'icons';
import React, { useState, useEffect } from 'react';
import { sanitize } from 'utils';
import Firebase from '../EnoticeFirebase';
import EModal from './modals/EModal';
const UploadButton = ({ folder, processUpload, processFile, accept, color, label, droppedFile, allowLargeFile, intervalAction }) => {
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const uploadFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        if (!file)
            return;
        setLoading(true);
        intervalAction && intervalAction();
        const snapshot = yield Firebase.storage()
            .ref()
            .child(`${folder}/${new Date().getTime()}/${sanitize(file.name)}`)
            .put(file);
        processUpload && processUpload(snapshot);
        setLoading(false);
    });
    useEffect(() => {
        if (!droppedFile)
            return;
        if (processUpload) {
            void uploadFile(droppedFile);
        }
        if (processFile) {
            processFile(droppedFile);
        }
    }, [droppedFile]);
    useEffect(() => {
        if (loading)
            return;
        uploadError && allowLargeFile && allowLargeFile();
    }, [loading]);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "file", accept: accept, style: { display: 'none' }, id: "contained-button-file", onChange: ({ target }) => {
                const { validity } = target;
                const [file] = target.files || [];
                if (!validity.valid) {
                    return;
                }
                if (processUpload) {
                    void uploadFile(file);
                }
                if (processFile) {
                    processFile(file);
                }
            } }),
        React.createElement("label", { htmlFor: "contained-button-file" }, loading ? (React.createElement(CircularProgress, null)) : (React.createElement(Button, { id: "upload-button", variant: "contained", color: color || 'primary', component: "span", startIcon: React.createElement(UploadIcon, null), size: "medium", className: "w-auto whitespace-no-wrap inline-flex items-center justify-between px-4 py-2 border border-gray-400 placeholder-gray-700 text-base leading-6 font-medium rounded-md text-gray-700 bg-white" },
            React.createElement("div", { className: "normal-case" }, label)))),
        uploadError && !allowLargeFile && (React.createElement(EModal, { setOpen: () => {
                setUploadError('');
            }, header: "TROUBLE UPLOADING", body: uploadError, buttonText: "OK", onConfirm: () => {
                setUploadError('');
            } })),
        React.createElement("style", null, `
      #upload-button {
        background-color: white;
        color: #6B7280;
        box-shadow: none;
        border: 1px #e2e8f0 solid;
        font-size: 16px;
        border-radius: 6px;
      }
      `)));
};
export default UploadButton;
