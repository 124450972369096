var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import api from 'api';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { canCancelInvoiceWithoutSupport } from 'utils/permissions';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
const CancelInvoiceModal = ({ setOpen, setToastMessage, notice, newspaper, invoice }) => {
    const [loading, setLoading] = useState(false);
    const canCancel = canCancelInvoiceWithoutSupport(invoice, notice);
    const cancelInvoice = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield api.post('payments/cancel-invoice', { noticeId: notice.id });
        }
        catch (err) {
            console.error(err.toString());
        }
        finally {
            setToastMessage(`You've successfully cancelled this invoice.`);
            setLoading(false);
            setOpen(false);
        }
    });
    const handleCancelInvoice = () => {
        return canCancel
            ? cancelInvoice()
            : window.open(`mailto:help@column.us?subject=Request to cancel invoice on Notice #${notice.id}`);
    };
    const buttonStyles = canCancel
        ? 'bg-red-200 focus:border-red-500 text-red-700 hover:bg-red-600'
        : 'bg-gray-200 focus:border-gray-500 text-gray-700 hover:bg-gray-600';
    let body;
    let buttonText;
    let buttonId;
    if (canCancel) {
        buttonText = 'Delete Invoice';
        buttonId = 'confirm-cancel-invoice';
        const paymentOrPartialRefundHasOccurred = hasPaymentOrPartialRefund(invoice);
        if (paymentOrPartialRefundHasOccurred &&
            !!invoice.data().manualPaymentDetails &&
            !notice.data().transfer) {
            body = `The advertiser paid for this notice outside of Column. If you void this invoice, ${newspaper.data().name} will be responsible for issuing a refund to the advertiser outside of Column. Please make sure to create a new invoice.`;
        }
        else if (paymentOrPartialRefundHasOccurred &&
            !invoice.data().invoiceOutsideColumn &&
            !notice.data().transfer) {
            body = `The advertiser has already paid for this notice. If you need to void the invoice, the advertiser will receive a full refund through Column. Please make sure to create a new invoice.`;
            buttonText = 'Void and Refund';
        }
        else if (invoice.data().invoiceOutsideColumn) {
            body =
                'You marked this invoice as to be paid outside Column. If you void the current invoice, please be sure to create a new invoice.';
        }
        else {
            body =
                'The client will automatically be notified by email. Please be sure to create a new invoice.';
        }
    }
    else {
        buttonText = 'Contact Support';
        buttonId = 'contact-support';
        body = `It looks like the customer has already paid this invoice. To refund and cancel this invoice, you will need to reach out to customer support. Email help@column.us or click below.`;
    }
    return (React.createElement(FreeformCModal, { setOpen: setOpen, header: "Void Invoice?", body: body },
        React.createElement(CButton, { id: buttonId, className: `${buttonStyles} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-6 px-3 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-sm md:text-base hover:text-white transition w-full md:w-3/6`, onClick: handleCancelInvoice, startIcon: loading ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })) : ('') }, buttonText)));
};
export default CancelInvoiceModal;
