import React from 'react';
import { State } from 'lib/enums';
import { Arrow } from '../icons';
import { ENV, PROD } from '../../../constants';
const Footer = ({ donationProfile, setOpenTestModeMenu, openTestModeMenu, submitting }) => {
    const getDonationSiteUrl = () => {
        const { donation_subdomain } = State.by_value(donationProfile.data().state) || {};
        return `https://${ENV === PROD
            ? `${donation_subdomain}.column.us/`
            : 'donations-demo.column.us/'}${donationProfile.data().domain}`;
    };
    return (React.createElement("div", { className: "flex items-center justify-end p-3 bg-gray-100 rounded-b-lg" },
        React.createElement("span", { className: `flex items-center bg-white rounded-md border ${!donationProfile.data().domain && 'opacity-50'}` },
            React.createElement("button", { type: "button", className: `flex items-center px-3 py-2 border-r rounded-r-none uppercase text-blue-600 rounded-md text-sm ${!donationProfile.data().domain && 'cursor-not-allowed tooltip'}`, onClick: () => donationProfile.data().domain && window.open(getDonationSiteUrl()), disabled: !donationProfile.data().domain },
                !donationProfile.data().domain && (React.createElement("span", { className: "tooltiptext" }, "You must publish your site before viewing")),
                "view website"),
            React.createElement("span", { className: `cursor-pointer p-2 text-blue-600 ${!donationProfile.data().domain && 'cursor-not-allowed'}`, onClick: () => {
                    if (donationProfile.data().domain) {
                        setOpenTestModeMenu(!openTestModeMenu);
                        window.scrollTo(0, document.body.scrollHeight ||
                            document.documentElement.scrollHeight);
                    }
                } }, Arrow),
            openTestModeMenu && (React.createElement("div", { className: "absolute transform max-w-xs sm:px-0", style: { transform: `translate(0px, 73%)` } },
                React.createElement("div", { className: "bg-white border rounded-md shadow-lg text-base", style: { padding: '0px 1px' } },
                    React.createElement("div", { className: "rounded overflow-hidden bg-white text-gray-700 pr-1 m-1 pl-2 py-2 cursor-pointer hover:bg-indigo-100 hover:bg-opacity-50 ", onClick: () => window.open(getDonationSiteUrl()) }, "View live website"),
                    React.createElement("div", { className: "rounded overflow-hidden bg-white text-gray-700 pr-1 m-1 pl-2 py-2 cursor-pointer hover:bg-indigo-100 hover:bg-opacity-50 ", onClick: () => window.open(`${getDonationSiteUrl()}/test`) }, "View in test mode"))))),
        React.createElement("button", { type: "submit", className: "px-4 py-2 ml-3 flex uppercase text-white bg-blue-600 rounded font-medium text-sm" },
            submitting && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
            "publish")));
};
export default Footer;
