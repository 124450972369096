import React from 'react';
import TemplateStatusChip from '../TemplateStatusChip';
export default function TemplateSettingsDrawerHeader({ activeOrganization, template }) {
    var _a;
    return (React.createElement("div", null,
        React.createElement("div", { className: "text-gray-800 font-semibold text-xl" }, template.data().name),
        React.createElement("div", { className: "flex items-center text-grey-400" },
            React.createElement("a", { className: "text-xs font-medium underline text-blue-950", target: "_blank", href: template.data().downloadUrl, rel: "noopener noreferrer" }, "Download IDML"),
            activeOrganization.data().adTemplate &&
                template.id === ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id) && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "mx-3" }, "|"),
                React.createElement(TemplateStatusChip, null))))));
}
