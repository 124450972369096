import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthActions from '../../redux/auth';
import NoticeDetail from './NoticeDetail';
import ToastActions from '../../redux/toast';
const mapStateToProps = (state) => {
    var _a;
    return ({
        isPublisher: state.auth.isPublisher,
        user: state.auth.user,
        alwaysAllowAffidavitDownload: state.auth.alwaysAllowAffidavitDownload,
        activeOrganization: state.auth.activeOrganization,
        availableOrganizations: state.auth.availableOrganizations,
        toastText: (_a = state.toast) === null || _a === void 0 ? void 0 : _a.toastText
    });
};
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    toastActions: bindActionCreators(ToastActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(NoticeDetail);
