var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlacementActions, { placementSelector } from 'redux/placement';
import { exists } from 'lib/types';
import { publisherReadyToUpload } from 'lib/publishers';
import { FieldValue } from 'utils/firebase';
// TODO: Is there a better place to handle this state syncing logic in an async Redux action rather than a useEffect?
export function useSyncUserSavedInfoToState({ restrictedPapers, restrictedSingleState }) {
    const dispatch = useDispatch();
    const placement = useSelector(placementSelector);
    const [savedInfo, setSavedInfo] = useState();
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (!placement.filer)
                return;
            const filerSnap = yield placement.filer.get();
            const { savedInfo } = filerSnap.data() || {};
            if (!savedInfo)
                return;
            const savedNewspaper = yield ((_a = savedInfo.newspaper) === null || _a === void 0 ? void 0 : _a.get());
            // If a filer has a saved newspaper that is disabled,
            // remove it from the user
            if ((_b = savedNewspaper === null || savedNewspaper === void 0 ? void 0 : savedNewspaper.data()) === null || _b === void 0 ? void 0 : _b.disabled) {
                yield placement.filer.update({
                    'savedInfo.newspaper': FieldValue.delete()
                });
                return;
            }
            setSavedInfo(savedInfo);
        }))();
    }, [placement.filer]);
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            const savedInfoStateExists = savedInfo && savedInfo.state;
            const fieldsAlreadySet = placement.newspaper || placement.filingState;
            if (!savedInfoStateExists || fieldsAlreadySet)
                return;
            const isRestrictedByState = restrictedSingleState && restrictedSingleState !== savedInfo.state;
            if (isRestrictedByState)
                return;
            dispatch(PlacementActions.setFilingState(savedInfo.state));
        }))();
    }, [savedInfo, restrictedSingleState]);
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const savedInfoNewspaperExists = savedInfo && savedInfo.newspaper;
            const fieldsAlreadySet = placement.newspaper;
            if (!savedInfoNewspaperExists || fieldsAlreadySet)
                return;
            const isRestrictedByPapers = restrictedPapers &&
                savedInfo.newspaper &&
                !restrictedPapers.includes(savedInfo.newspaper.id);
            const isRestrictedByState = restrictedSingleState && restrictedSingleState !== savedInfo.state;
            const matchesCurrentSelectedState = placement.filingState && savedInfo.state === placement.filingState;
            if (isRestrictedByPapers ||
                isRestrictedByState ||
                !matchesCurrentSelectedState) {
                return;
            }
            const savedNewspaper = yield ((_a = savedInfo.newspaper) === null || _a === void 0 ? void 0 : _a.get());
            if (exists(savedNewspaper) && publisherReadyToUpload(savedNewspaper)) {
                dispatch(PlacementActions.setNewspaper(savedInfo.newspaper));
            }
        }))();
    }, [
        placement.filingState,
        savedInfo,
        restrictedPapers,
        restrictedSingleState
    ]);
}
