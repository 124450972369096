var _a, _b, _c;
import { detect } from 'detect-browser';
import { ENOTICE_ACCOUNT_ID_DEV, ENOTICE_ACCOUNT_ID_PROD, envs } from './lib/constants';
import FIREBASE_CONFIG from './config.firebase';
export const { PROD } = envs;
export const { STAGING } = envs;
export const { DEV } = envs;
export const { TEST } = envs;
export const { DEMO } = envs;
export const ENOTICE_SUPPORT_EMAIL = 'help@column.us';
export const BROWSER = detect() ? (_a = detect()) === null || _a === void 0 ? void 0 : _a.name : null;
export const BROWSERS = {
    ie: 'ie',
    edge: 'edge',
    safari: 'safari',
    chrome: 'chrome',
    firefox: 'firefox'
};
export const RECORD = false;
export const IS_E2E = FIREBASE_CONFIG.projectId === 'enotice-testing' ||
    FIREBASE_CONFIG.projectId === 'enotice-testing2';
// determine the corresponding environment
export const ENV = (process.env.NODE_ENV === 'production' &&
    FIREBASE_CONFIG.projectId === 'enotice-production' &&
    PROD) ||
    (window.location.host.split('.')[0] === 'staging' && STAGING) ||
    (FIREBASE_CONFIG.projectId === 'enotice-demo-8d99a' && DEMO) ||
    (process.env.NODE_ENV === 'test' && TEST) ||
    (IS_E2E && TEST) ||
    DEV;
export const HOST = window.location.host.split(':')[0].replace('//', '');
export const PORT = window.location.host.split(':')[1];
export const IS_LOCALHOST = PORT ||
    HOST === 'localhost' ||
    HOST === '127.0.0.1' ||
    false;
console.log('USING ENVIRONMENT', ENV, HOST, IS_LOCALHOST, window.location.host);
export const IMAGES = {
    SHAPE_LEFT: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff',
    SHAPE_RIGHT: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff'
};
// Stripe
const DEV_STRIPE_VARS = {
    invoices: 'https://dashboard.stripe.com/test/invoices/create',
    transfer: 'https://dashboard.stripe.com/test/connect/accounts/',
    key: 'pk_test_lHCdsg49uFGcdXruY4mpo0Y6008ZDcTc2P',
    oauthUrl: `https://connect.stripe.com/express/oauth/authorize?response_type=code&redirect_uri=${window.location.origin}/register/organization/&client_id=ca_FCoJmwUpr1XVipWIjUQy7Ty0WlOBOlx1&stripe_user[business_type]=company`
};
const LIVE_STRIPE_VARS = {
    invoices: 'https://dashboard.stripe.com/invoices/create',
    transfer: 'https://dashboard.stripe.com/connect/accounts/',
    key: 'pk_live_rcQzdsMuxEALkEV9qiOcyLBi001yQrpvs5',
    oauthUrl: `https://connect.stripe.com/express/oauth/authorize?response_type=code&redirect_uri=${window.location.origin}/register/organization/&client_id=ca_FCoJqm431x55xVYdszWhc7UpwSwVXGaE&stripe_user[business_type]=company`
};
export const TINY_MCE_KEY = ENV === PROD || ENV === DEMO
    ? 's6l4pcl31len6pryiz7kb7r6l058nzynil908zgpyjezx7j7'
    : '';
export const LOGROCKET_KEY = ENV === PROD
    ? 'risv5h/web-app'
    : ENV === DEV || ENV === TEST
        ? 'risv5h/column-testing'
        : 'risv5h/enotice-demo';
export const SENTRY_DSN = 'https://17f327f9eaba4d249da251c9c10c6077@sentry.io/1487041';
export const STRIPE_VARS = ENV === PROD ? LIVE_STRIPE_VARS : DEV_STRIPE_VARS;
export const AUTH_TOKEN = 'auth-token';
export const GOOGLE_API_KEY = 'AIzaSyBHww1bTf3bX-cqdpexvYIBgRM7FVTX70U';
// the google maps api key created for the enotice-app project
export const GOOGLE_MAPS_API_KEY_DEV = 'AIzaSyBODcyh0QZoBEPWyrNlW--p4Xx5czgKqVQ';
const DEV_ALGOLIA_CONFIG = {
    index: 'dev_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
const STAGING_ALGOLIA_CONFIG = {
    index: 'staging_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
const DEMO_ALGOLIA_CONFIG = {
    index: 'demo_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
const PROD_ALGOLIA_CONFIG = {
    index: 'prod_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
const DEV_INDESIGN_URL = 'https://indesign-dev.enotice.io';
const PROD_INDESIGN_URL = 'https://indesign.enotice.io';
export const INDESIGN_URL = {
    [DEV]: DEV_INDESIGN_URL,
    [STAGING]: DEV_INDESIGN_URL,
    [DEMO]: PROD_INDESIGN_URL,
    [PROD]: PROD_INDESIGN_URL,
    [TEST]: DEV_INDESIGN_URL
}[ENV];
export const ALGOLIA_CONFIG = {
    [DEV]: DEV_ALGOLIA_CONFIG,
    [STAGING]: STAGING_ALGOLIA_CONFIG,
    [DEMO]: DEMO_ALGOLIA_CONFIG,
    [PROD]: PROD_ALGOLIA_CONFIG,
    [TEST]: DEV_ALGOLIA_CONFIG
}[ENV];
export const ANY_ORIGIN_ENUM = '*';
export const ORIGINS_WHITELISTED_FOR_PUBLISHER_REGISTRATION = {
    [DEV]: [
        'http://localhost:3000',
        'https://enotice-testing.web.app',
        'https://enotice-testing2.web.app',
        'https://enotice-hotfix.firebaseapp.com',
        'https://enotice-hotfix.web.app',
        'https://enotice-betts.web.app',
        'https://enotice-sheraz.web.app',
        'https://enotice-teresa.web.app',
        'https://enotice-example.web.app',
        'https://enotice-setup-env.web.app'
    ],
    [STAGING]: [ANY_ORIGIN_ENUM],
    [DEMO]: ['https://florida-demo.column.us', 'https://demo.enotice.io'],
    [PROD]: [
        'https://kansas.enotice.io',
        'https://kansas.column.us',
        'https://florida.column.us',
        'https://app.enotice.io',
        'https://enotice.io',
        'https://www.enotice.io',
        'https://www.column.us',
        'https://app.column.us',
        'https://column.us'
    ],
    [TEST]: [ANY_ORIGIN_ENUM]
};
export const PUBLISHER_CAN_REGISTER = ORIGINS_WHITELISTED_FOR_PUBLISHER_REGISTRATION[ENV].includes(ANY_ORIGIN_ENUM) ||
    ORIGINS_WHITELISTED_FOR_PUBLISHER_REGISTRATION[ENV].includes(window.location.origin) ||
    window.location.origin.includes('netlify.app') ||
    window.location.origin.includes('web.app');
export const PENDO_KEY = '5f61770e-7118-4f29-785e-28a8beae9b78';
export const SHOULD_RUN_PENDO = ENV === PROD || ENV === DEMO;
const DEV_LOB_CONFIG = {
    key: 'test_pub_1759d77711aca0d3075f59541b5d713',
    address: { primary_line: 'undeliverable no match' }
};
const PROD_LOB_CONFIG = {
    key: 'live_pub_439e498c923e164febdcafbf0cdd62f',
    address: { primary_line: '' }
};
export const LOB_CONFIG = {
    [PROD]: PROD_LOB_CONFIG,
    [DEV]: DEV_LOB_CONFIG,
    [DEMO]: DEV_LOB_CONFIG,
    [STAGING]: DEV_LOB_CONFIG,
    [TEST]: DEV_LOB_CONFIG
};
export const ENOTICE_ACCOUNT_ID = ENV === PROD ? ENOTICE_ACCOUNT_ID_PROD : ENOTICE_ACCOUNT_ID_DEV;
export const DUMMY_ACCOUNT_ID = '123456';
export const PLAID_ENV = {
    [PROD]: 'production',
    [DEV]: 'sandbox',
    [DEMO]: 'sandbox',
    [STAGING]: 'sandbox',
    [TEST]: 'sandbox'
};
export const DWOLLA_ENV = ENV === PROD ? 'prod' : 'sandbox';
export const REACT_APP_SENTRY_VERSION = (_c = (_b = process.env.REACT_APP_SENTRY_VERSION) !== null && _b !== void 0 ? _b : FIREBASE_CONFIG === null || FIREBASE_CONFIG === void 0 ? void 0 : FIREBASE_CONFIG.projectId) !== null && _c !== void 0 ? _c : 'dev';
// Elavon
export const ELAVON_TOKENIZER_URL = ENV === PROD
    ? 'https://api.convergepay.com/hosted-payments/Checkout.js'
    : 'https://api.demo.convergepay.com/hosted-payments/Checkout.js';
