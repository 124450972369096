var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { RateType, NoticeType, Day, LineItemType } from '../enums';
import { exists } from '../types';
import { firestoreTimestampOrDateToDate } from '../helpers';
import { dateObjectToDayEnum } from '../utils/deadlines';
import { Collections } from '../constants';
import { getInitialAffidavitFeeInCents } from './affidavits';
import { getErrorReporter } from '../utils/errors';
import { getAffidavitAdditionalFeeLineItemsFromRate, getNonAffidavitAdditionalFeeLineItemsFromRate, getLastNonFeeLineItemIndex } from './additionalFees';
export const DEFAULT_COLUMN_FEE_PCT = 10;
// Based on the following rate sheet: https://michiganpress.org/wp-content/uploads/2021/03/Long-Folio-Chart.pdf
export const getFolios = (words) => {
    const folios = Math.ceil(words / 100);
    return folios;
};
// (3.1, .25) => 3.25, (3.1, 0) => 3.1, (3.0, 0.25) => 3.0
export const roundUp = (x, r) => r ? Math.ceil(x / r) * r : x;
export const getColumnInches = (actualHeight, columns, roundOff) => {
    const possiblyRoundedHeight = roundUp(actualHeight, roundOff);
    return possiblyRoundedHeight * columns;
};
export const getColumnCentimeters = (height, columns, roundOff) => {
    return getColumnInches(height, columns, roundOff) * 2.54;
};
/**
 * Safely check if a maybe-number is a number (and not NaN or Infinity).
 */
const valueIsNumber = (val) => {
    return typeof val === 'number' && !Number.isNaN(val) && Number.isFinite(val);
};
export const oklahoma = {
    // body words are currently calculated as words that are left
    // justified. This is the default setting in our TinyMCE editor
    getBodyWords: (displayParameters) => { var _a, _b; return ((_b = (_a = displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.justifications) === null || _a === void 0 ? void 0 : _a.LEFT_JUSTIFIED) === null || _b === void 0 ? void 0 : _b.words) || 0; },
    // tabular lines are calculated as all lines that are not left
    // justified. This includes paragraphs that are explicitly "left" aligned
    // beyond the default alignment settings.
    // NOTE: currently this calculation includes the header lines, but
    // there are outstanding questions that need to be resolved by the
    // Oklahoma press association to confirm that this is intended
    getTabularLines: (displayParameters) => {
        var _a, _b, _c, _d, _e, _f;
        return (((_b = (_a = displayParameters.justifications) === null || _a === void 0 ? void 0 : _a.LEFT_ALIGN) === null || _b === void 0 ? void 0 : _b.lines) || 0) +
            (((_d = (_c = displayParameters.justifications) === null || _c === void 0 ? void 0 : _c.RIGHT_ALIGN) === null || _d === void 0 ? void 0 : _d.lines) || 0) +
            (((_f = (_e = displayParameters.justifications) === null || _e === void 0 ? void 0 : _e.CENTER_ALIGN) === null || _f === void 0 ? void 0 : _f.lines) || 0);
    }
};
export const calculateConvenienceFee = (subtotal, percentage) => {
    // 0 pct means it is a government rate
    if (percentage === 0)
        return 0;
    const convenience_fee = Math.round((subtotal * percentage) / 100);
    return convenience_fee;
};
export const getApplicableRate = (numRuns, rateRecord, runNumber) => {
    let applicableRate;
    const applicableRun = rateRecord.runBased ? runNumber : numRuns;
    if (applicableRun === 1 || rateRecord.rateType === RateType.per_run.value) {
        applicableRate = rateRecord.rate_0;
    }
    else if (applicableRun === 2) {
        applicableRate = rateRecord.rate_1;
    }
    else if (applicableRun === 3) {
        applicableRate = rateRecord.rate_2;
    }
    else if (applicableRun >= 4 && rateRecord.additionalRates) {
        const maxRateNumber = Math.max(...Object.keys(rateRecord.additionalRates).map(rate => parseInt(rate.replace('rate_', ''), 10)));
        const rate = Math.min(maxRateNumber, applicableRun - 1);
        applicableRate = rateRecord.additionalRates[`rate_${rate}`];
    }
    else {
        applicableRate = rateRecord.rate_0;
    }
    return applicableRate;
};
export const relevantDisplayParameterFromRate = (rateRecord, displayParameters, columns) => {
    if (rateRecord.rateType === RateType.flat.value)
        return null;
    if (rateRecord.rateType === RateType.per_run.value)
        return null;
    if (rateRecord.rateType === RateType.word_count.value)
        return displayParameters.words;
    if (rateRecord.rateType === RateType.inch.value)
        return roundUp(displayParameters.height * displayParameters.width, rateRecord.roundOff).toFixed(2);
    if (rateRecord.rateType === RateType.column_inch.value)
        return getColumnInches(displayParameters.height, columns, rateRecord.roundOff).toFixed(2);
    if (rateRecord.rateType === RateType.line.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.folio.value)
        return getFolios(displayParameters.words);
    if (rateRecord.rateType === RateType.nebraska.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.oklahoma.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.battle_born.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.berthoud_government.value)
        return `${displayParameters.lines} lines, ${getColumnInches(displayParameters.height, columns, rateRecord.roundOff).toFixed(2)} total column inches`;
    if (rateRecord.rateType === RateType.enterprise.value)
        // This is the only rate type where we round after calculating column inches
        return `${roundUp(getColumnInches(displayParameters.height, columns, null), rateRecord.roundOff).toFixed(2)} total column inches`;
    if (rateRecord.rateType === RateType.single_column_centimetre.value) {
        return `${getColumnCentimeters(displayParameters.height, columns, rateRecord.roundOff).toFixed(2)} total scm`;
    }
    throw new Error(`Unknown rate type ${rateRecord.rateType}`);
};
export const floatToP2Float = (bigFloat) => {
    const floatStr = bigFloat.toFixed(2);
    return parseFloat(floatStr);
};
export const uiToDBCurrency = (bigFloat) => {
    const parsedFloat = typeof bigFloat === 'string' ? parseFloat(bigFloat) : bigFloat;
    const floatStr = parsedFloat.toFixed(2);
    const float = parseFloat(floatStr);
    const dbNum = Math.round(float * 100);
    return dbNum;
};
export const dbToUICurrency = (num) => {
    const floatStr = (num / 100).toFixed(2);
    return parseFloat(floatStr);
};
export const dbToUICurrencyString = (num) => {
    const floatStr = (num / 100).toFixed(2);
    return floatStr;
};
export const floatToDBPercent = (pct) => {
    const isStr = typeof pct === 'string';
    const parsedPct = isStr ? parseFloat(pct) : pct;
    const floatStr = parsedPct.toFixed(2);
    const dbNum = parseFloat(floatStr);
    return dbNum;
};
const getPercentString = (pct, options) => {
    if (pct % 1 === 0 && (options === null || options === void 0 ? void 0 : options.returnWithoutFixedDecimals)) {
        return pct.toString();
    }
    return pct.toFixed(2);
};
export const getInvoiceTotalSubtotalAndFees = (invoiceSnap) => {
    const { inAppInvoicedAmt: subtotalInCents, total, affidavitFeeInCents: possibleAffidavitFee, convenienceFeePct } = invoiceSnap.data();
    const affidavitFeeInCents = possibleAffidavitFee || 0;
    // TODO:
    // - Should this handle distributed fees differently?
    // - Does this handle taxes correctly?
    const convenienceFee = calculateConvenienceFee(subtotalInCents, convenienceFeePct);
    let feesInCents;
    if (total) {
        feesInCents = total - subtotalInCents;
    }
    else {
        feesInCents = affidavitFeeInCents + convenienceFee;
    }
    return {
        subtotalInCents,
        feesInCents,
        totalInCents: total || subtotalInCents + feesInCents
    };
};
export const calculateFee = (paper, numRuns, runNumber) => paper.data().fee && runNumber + 1 === numRuns ? paper.data().fee : 0;
export const calculateBoldPrices = (noticeRecord, rateRecord, displayParameters) => {
    let boldPrice = 0;
    if (noticeRecord.noticeType !== NoticeType.display_ad.value) {
        if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.bold_words) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords)) {
            return (boldPrice +=
                (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords) * rateRecord.bold_words);
        }
        // if we have a flat bold rate tied to the notice add that on top
        // of the price charged if the notice contains a bold word
        if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.flatBoldPricing) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords)) {
            return (boldPrice += rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.flatBoldPricing);
        }
        if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.line_with_bold_words) &&
            (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.nonTableBoldedLines)) {
            return (boldPrice +=
                (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.nonTableBoldedLines) *
                    rateRecord.line_with_bold_words);
        }
    }
    return boldPrice;
};
export const calculateDBPrice = (noticeRecord, rateRecord, displayParameters, numRuns, columns, dayRate, runNumber) => {
    if (noticeRecord.fixedPrice) {
        if (runNumber > 0)
            return 0;
        return noticeRecord.fixedPrice;
    }
    let result;
    const applicableRate = dayRate || getApplicableRate(numRuns, rateRecord, runNumber + 1);
    if (rateRecord.rateType === RateType.flat.value) {
        if (dayRate)
            return dayRate;
        if (runNumber > 0)
            return 0;
        if (numRuns === 1)
            return rateRecord.rate_0;
        if (numRuns === 2)
            return rateRecord.rate_1;
        if (numRuns >= 4 && rateRecord.additionalRates) {
            const maxRateNumber = Math.max(...Object.keys(rateRecord.additionalRates).map(rate => parseInt(rate.replace('rate_', ''), 10)));
            const rate = Math.min(maxRateNumber, numRuns - 1);
            return rateRecord.additionalRates[`rate_${rate}`];
        }
        return rateRecord.rate_2;
    }
    if (rateRecord.rateType === RateType.per_run.value) {
        return dayRate || rateRecord.rate_0;
    }
    result = runNumber === 0 ? applicableRate : 0;
    const offset = rateRecord.offset || 0;
    if (rateRecord.rateType === RateType.word_count.value) {
        const processedWordCount = Math.max(roundUp(displayParameters.words, rateRecord.folioSize) - offset, 0);
        result = floatToP2Float(processedWordCount * applicableRate);
    }
    if (rateRecord.rateType === RateType.folio.value) {
        result = floatToP2Float(getFolios(displayParameters.words) * applicableRate);
    }
    if (rateRecord.rateType === RateType.inch.value)
        result = floatToP2Float(roundUp(Math.max(displayParameters.height * displayParameters.width - offset, 0), rateRecord.roundOff) * applicableRate);
    if (rateRecord.rateType === RateType.column_inch.value) {
        const columnInches = Math.max(getColumnInches(displayParameters.height, columns, rateRecord.roundOff) -
            offset, 0);
        result = floatToP2Float(columnInches * applicableRate);
    }
    if (rateRecord.rateType === RateType.line.value) {
        const { lines } = displayParameters;
        if (!valueIsNumber(lines)) {
            console.warn(`rateType=${rateRecord.rateType} but displayParameters.lines=${displayParameters.lines}`);
            result = 0;
        }
        else {
            result = floatToP2Float(Math.max(lines - offset, 0) * columns * applicableRate);
        }
    }
    if (rateRecord.rateType === RateType.nebraska.value) {
        if (runNumber === 0) {
            result = floatToP2Float(displayParameters.lines * columns * rateRecord.rate_0);
        }
        else if (runNumber === 1) {
            result = floatToP2Float(displayParameters.lines * columns * rateRecord.rate_1);
        }
        else {
            result = floatToP2Float(displayParameters.lines * columns * rateRecord.rate_2);
        }
    }
    if (rateRecord.rateType === RateType.oklahoma.value) {
        const wordRate = runNumber === 0 ? 15 : 14;
        const tabularRate = runNumber === 0 ? 70 : 65;
        const wordTotal = floatToP2Float(oklahoma.getBodyWords(displayParameters) * wordRate);
        const tabularTotal = floatToP2Float(oklahoma.getTabularLines(displayParameters) * tabularRate * columns);
        result = wordTotal + tabularTotal;
    }
    if (rateRecord.rateType === RateType.battle_born.value) {
        const lineRate50 = 200;
        const lineRate50Gt = 170;
        const lines50 = 50;
        const lines50Gt = Math.max(displayParameters.lines - 50, 0);
        result =
            runNumber + 1 === numRuns
                ? lines50 * lineRate50 + lines50Gt * lineRate50Gt
                : 0;
    }
    if (rateRecord.rateType === RateType.berthoud_government.value) {
        const lineRate = 44;
        const ciRate = 766;
        result =
            runNumber === 0
                ? lineRate * displayParameters.lines
                : ciRate *
                    getColumnInches(displayParameters.height, displayParameters.columns, rateRecord.roundOff);
    }
    if (rateRecord.rateType === RateType.enterprise.value) {
        const flatciRate = 9500;
        const ciRate = 950;
        const cInches = getColumnInches(displayParameters.height, displayParameters.columns, null);
        result =
            cInches <= 10
                ? flatciRate
                : roundUp(cInches, rateRecord.roundOff) * ciRate;
    }
    if (rateRecord.rateType === RateType.single_column_centimetre.value) {
        result = floatToP2Float(getColumnCentimeters(displayParameters.height, columns, rateRecord.roundOff) * applicableRate);
    }
    if (!(result === 0 || result))
        console.error(`Unknown pricing scheme: ${rateRecord.rateType} with result ${result}`);
    return result;
};
export const getRelevantRateString = (rateRecord, displayParameters, columns) => {
    var _a;
    const ratePlural = (_a = RateType.by_value(rateRecord.rateType)) === null || _a === void 0 ? void 0 : _a.plural;
    const relevantParam = relevantDisplayParameterFromRate(rateRecord, displayParameters, columns);
    if (rateRecord.rateType === RateType.oklahoma.value)
        return `Tabular Lines: ${oklahoma.getTabularLines(displayParameters)}, Body Words: ${oklahoma.getBodyWords(displayParameters)}`;
    if (rateRecord.rateType === RateType.single_column_centimetre.value)
        return `Single column centimetres: ${relevantParam}`;
    if (rateRecord.rateType === RateType.per_run.value)
        return '';
    return rateRecord.rateType === RateType.column_inch.value
        ? `Height: ${roundUp(displayParameters.height, rateRecord.roundOff).toFixed(2)} / Columns: ${columns}`
        : `${ratePlural}: ${relevantParam ? `${relevantParam}` : 'n/a'}`;
};
export const getCorrectNoticeRate = (oldRate, newType, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    const defaultDisplay = (yield newspaper
        .data()
        .defaultDisplayRate.get());
    const defaultLiner = (yield newspaper
        .data()
        .defaultLinerRate.get());
    const oldRateData = oldRate && oldRate.data();
    if (newType === NoticeType.display_ad.value &&
        oldRateData &&
        oldRateData.code === defaultLiner.data().code) {
        return defaultDisplay.ref;
    }
    if (newType !== NoticeType.display_ad.value &&
        oldRateData &&
        oldRateData.code === defaultDisplay.data().code) {
        return defaultLiner.ref;
    }
    return oldRate.ref;
});
export const getNoticeRate = (notice, newspaper, placementRate) => __awaiter(void 0, void 0, void 0, function* () {
    let rateSnap;
    if (placementRate) {
        rateSnap = yield placementRate.get();
    }
    else if (notice.rate) {
        rateSnap = yield notice.rate.get();
    }
    else if (notice.noticeType === NoticeType.display_ad.value) {
        rateSnap = yield newspaper.defaultDisplayRate.get();
    }
    else {
        rateSnap = yield newspaper.defaultLinerRate.get();
    }
    return rateSnap;
});
const mockDisplayParams = {
    words: 0,
    lines: 0,
    area: 0,
    height: 0,
    width: 0,
    headerLines: 0,
    headerWords: 0,
    nonTableBoldedLines: 0,
    boldWords: 0,
    justifications: {
        RIGHT_ALIGN: {
            lines: 0,
            words: 0
        },
        LEFT_ALIGN: {
            lines: 0,
            words: 0
        },
        CENTER_ALIGN: {
            lines: 0,
            words: 0
        },
        LEFT_JUSTIFIED: {
            lines: 0,
            words: 0
        }
    }
};
/**
 * Additional fee line items are publisher fees on the organiztion object
 * Fee line items will NOT start with a date string in the description (ex. description: Affidavit Fee)
 * Non-fee line item will start with a date string (ex. 07/01/2022: Custom Notice)
 *
 * @param lineItem
 * @returns boolean
 */
export const isAdditionalFeeLineItem = (lineItem) => {
    // Prefer the type field when it exists
    if (typeof lineItem.type === 'number') {
        return lineItem.type === LineItemType.fee.value;
    }
    // no description means not custom
    if (!lineItem.description)
        return false;
    // return false on line items of the format mm/dd/yyyy:
    if (lineItem.description.match(/^\d{2}\/\d{2}\/\d{4}/))
        return false;
    return true;
};
export const findDayRate = (rateSnap, date) => {
    var _a, _b;
    const rate = rateSnap.data();
    const dayEnum = dateObjectToDayEnum(date.toDate());
    const matchingDayRate = (_a = rate.dayRates) === null || _a === void 0 ? void 0 : _a.find(dRate => dRate.day === dayEnum);
    const dayRate = (_b = matchingDayRate === null || matchingDayRate === void 0 ? void 0 : matchingDayRate.rate) !== null && _b !== void 0 ? _b : 0;
    return { dayRate, dayEnum };
};
/**
 * Calculate the "db price" for a single run of a notice. This is
 * the default price unless it is modified in the invoice step.
 */
export const calculateSingleRunPrice = (notice, displayParameters, newspaperSnap, rateSnap, runNumber) => {
    var _a;
    if (!notice.publicationDates) {
        throw new Error(`Cannot compute pricing for notice ${notice.referenceId} without publication dates`);
    }
    const numRuns = notice.publicationDates.length;
    if (runNumber >= numRuns) {
        throw new Error(`Cannot compute pricing for run #${runNumber} of notice ${notice.referenceId} with ${numRuns} runs`);
    }
    const publicationDate = notice.publicationDates[runNumber];
    const { dayRate } = findDayRate(rateSnap, publicationDate);
    const rate = rateSnap.data();
    const columns = displayParameters.columns || notice.columns || 1;
    const baseDbPrice = calculateDBPrice(notice, rate, displayParameters, numRuns, columns, dayRate, runNumber);
    const boldPrice = calculateBoldPrices(notice, rate, displayParameters);
    const fee = (_a = calculateFee(newspaperSnap, numRuns, runNumber)) !== null && _a !== void 0 ? _a : 0;
    return baseDbPrice + boldPrice + fee;
};
/**
 * Get or infer the LineItemType from a LineItem
 */
export const getLineItemType = (item) => {
    const { type } = item;
    if (typeof type === 'number') {
        return type;
    }
    if (item.singleNoticeId) {
        return LineItemType.bulk_invoice.value;
    }
    return isAdditionalFeeLineItem(item)
        ? LineItemType.fee.value
        : LineItemType.publication.value;
};
/**
 * Transform the line items of a notice's 'pricing' field into invoice line items
 * which can be edited by the user in the InvoiceForm UI.
 */
export const getInvoiceLineItems = (noticeSnap, activeOrganization, rateSnap) => {
    // TODO(COREDEV-1310): Remove this cast
    const pricing = noticeSnap.data().pricing;
    // Calculate the total additional fees by parsing line items
    let additionalFeeTotal = 0;
    pricing.lineItems.forEach(item => {
        if (isAdditionalFeeLineItem(item)) {
            additionalFeeTotal += item.amount;
        }
    });
    return pricing.lineItems.map((item, i) => {
        var _a;
        const { description } = item;
        const date = firestoreTimestampOrDateToDate(item.date);
        const type = getLineItemType(item);
        if (((_a = getDistributeFeeSettings(activeOrganization, rateSnap)) === null || _a === void 0 ? void 0 : _a.finalLineItem) &&
            rateSnap.data().finalLineItemPricing &&
            rateSnap.data().rateType === RateType.flat.value) {
            return {
                amount: i === 0
                    ? dbToUICurrency(pricing.subtotal - additionalFeeTotal)
                    : isAdditionalFeeLineItem(item)
                        ? dbToUICurrency(item.amount)
                        : 0,
                date,
                description,
                type
            };
        }
        if (rateSnap.data().rateType === RateType.flat.value) {
            return {
                amount: dbToUICurrency(item.amount),
                date,
                description,
                type
            };
        }
        return {
            amount: dbToUICurrency(item.amount),
            date,
            description,
            type
        };
    });
};
/**
 * Get the invoiced price for a single run of a notice.
 *
 * Note that this does not attempt to reverse any fee-embedding, so
 * when calling this function be sure to understand the newspaper context!
 */
export const getSingleRunPriceFromInvoice = (notice, invoice, runNumber) => {
    const { publicationDates } = notice.data();
    if (!publicationDates || publicationDates.length <= runNumber) {
        throw new Error(`Notice ${notice.id} does not have ${runNumber} publication dates!`);
    }
    const publicationDate = firestoreTimestampOrDateToDate(publicationDates[runNumber]);
    const { inAppLineItems } = invoice.data();
    const sameDayLineItems = inAppLineItems.filter(item => {
        if (item.refund || item.singleNoticeId) {
            return false;
        }
        if (isAdditionalFeeLineItem(item)) {
            return false;
        }
        const itemDate = firestoreTimestampOrDateToDate(item.date);
        return moment(itemDate).isSame(moment(publicationDate), 'day');
    });
    if (sameDayLineItems.length === 0) {
        getErrorReporter().logAndCaptureWarning('Invoice has no line items for day', {
            noticeId: notice.id,
            invoiceId: invoice.id,
            publicationDate: publicationDate.toISOString()
        });
        return;
    }
    if (sameDayLineItems.length >= 2) {
        getErrorReporter().logAndCaptureWarning('Invoice has multiple line items for one day', {
            noticeId: notice.id,
            invoiceId: invoice.id,
            publicationDate: publicationDate.toISOString()
        });
        return undefined;
    }
    const matchingLineItem = sameDayLineItems[0];
    return matchingLineItem.amount;
};
export const createDBPricingObjectFromData = (ctx, notice, displayParameters = mockDisplayParams, rateSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const newspaperSnap = yield ((_a = notice.newspaper) === null || _a === void 0 ? void 0 : _a.get());
    // In the anonymous placement flow filer will not exist, so do NOT throw if filerSnap doesn't exist
    const filerSnap = yield ((_b = notice.filer) === null || _b === void 0 ? void 0 : _b.get());
    if (!exists(newspaperSnap)) {
        throw new Error(`Cannot compute pricing for notice ${notice.referenceId} without newspaper`);
    }
    const newspaper = newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.data();
    const noticeRateSnap = rateSnap !== null && rateSnap !== void 0 ? rateSnap : (yield getNoticeRate(notice, newspaper));
    const rate = noticeRateSnap.data();
    const { publicationDates } = notice;
    if (!(publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length)) {
        throw new Error(`Cannot compute pricing for notice ${notice.referenceId} without publication dates`);
    }
    let lineItems = publicationDates.map((date, i) => {
        const { dayRate, dayEnum } = findDayRate(noticeRateSnap, date);
        const amount = calculateSingleRunPrice(notice, displayParameters, newspaperSnap, noticeRateSnap, i);
        return Object.assign(Object.assign({ date: date.toDate(), amount }, (dayRate && {
            description: `${moment(date.toDate()).format('MM/DD/YYYY')}: Custom notice (${Day.by_value(dayEnum).label} Rate)`
        })), { type: LineItemType.publication.value });
    });
    const lastIndex = publicationDates.length - 1;
    const lastPubDatePlusOneMinute = moment(publicationDates[lastIndex].toDate()).add(1, 'm');
    // allow for additional line items at the newspaper level
    if (newspaper.additionalFees) {
        lineItems = lineItems.concat(newspaper.additionalFees.map(fee => ({
            date: lastPubDatePlusOneMinute.toDate(),
            amount: fee.amount,
            description: fee.description,
            type: LineItemType.fee.value
        })));
    }
    // allow for additional fees for custom affidavits
    // this enables additional fees for Ogden papers that
    // are requesting notice-type-specific pricing
    if (newspaper.customAffidavitFee) {
        const item = {
            date: lastPubDatePlusOneMinute.toDate(),
            amount: newspaper.customAffidavitFee,
            description: 'Custom Affidavit Fee',
            type: LineItemType.fee.value
        };
        lineItems = [item].concat(lineItems);
    }
    const additionalFeeLineItems = getNonAffidavitAdditionalFeeLineItemsFromRate(rate, notice, lastPubDatePlusOneMinute.toDate(), newspaper);
    const affidavitAdditionalFeeLineItems = getAffidavitAdditionalFeeLineItemsFromRate(rate, notice, lastPubDatePlusOneMinute.toDate());
    lineItems = [
        ...lineItems,
        ...additionalFeeLineItems,
        ...affidavitAdditionalFeeLineItems
    ];
    const taxPct = newspaper.taxPct || 0;
    const totalAcrossRuns = lineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0);
    const subtotal = Math.max(totalAcrossRuns, rate.minimum);
    const lastNonFeeLineItemIndex = getLastNonFeeLineItemIndex(lineItems);
    // enforce that all line items are cents
    let roundedSubtotal = 0;
    for (const item of lineItems) {
        const centTotal = Math.floor(item.amount);
        item.amount = centTotal;
        roundedSubtotal += centTotal;
    }
    // put the impact of rounding onto the last non-fee line item
    lineItems[lastNonFeeLineItemIndex].amount += subtotal - roundedSubtotal;
    let additionalFeeTotal = 0;
    let changedFinalLineItem = false;
    if (rate.finalLineItemPricing) {
        for (let i = lineItems.length - 1; i >= 0; i -= 1) {
            if (isAdditionalFeeLineItem(lineItems[i])) {
                additionalFeeTotal += lineItems[i].amount;
            }
            else if (changedFinalLineItem) {
                lineItems[i].amount = 0;
            }
            else {
                lineItems[i].amount = totalAcrossRuns - additionalFeeTotal;
                changedFinalLineItem = true;
            }
        }
    }
    const taxAmt = lineItems.reduce((acc, lineItem) => acc + Math.round((lineItem.amount * taxPct) / 100), 0);
    const convenience_fee = calculateConvenienceFee(subtotal, rate.enotice_fee_pct);
    /**
     * This is the only time we should call this function as the notice data has not yet been set.
     * Otherwise, use `getAffidavitFeeInCentsForNotice`.
     */
    const affidavitFeeInCents = yield getInitialAffidavitFeeInCents(ctx, newspaperSnap, filerSnap);
    const total = subtotal + taxAmt + convenience_fee + affidavitFeeInCents;
    return Object.assign(Object.assign({ lineItems,
        subtotal, taxPct: floatToDBPercent(taxPct), taxAmt, convenienceFeePct: floatToDBPercent(rate.enotice_fee_pct), convenienceFee: convenience_fee }, (affidavitFeeInCents && { affidavitFeeInCents })), { total });
});
export const createDBPricingObject = (ctx, noticeSnap, displayParameters = mockDisplayParams, rateSnap) => __awaiter(void 0, void 0, void 0, function* () {
    if (!noticeSnap.data()) {
        throw new Error('Notice not set');
    }
    const notice = noticeSnap.data();
    const mailQuery = yield noticeSnap.ref.collection(Collections.mail).get();
    const mail = mailQuery.docs.map(doc => doc.data());
    return yield createDBPricingObjectFromData(ctx, Object.assign(Object.assign({}, notice), { mail }), displayParameters, rateSnap);
});
export const createDBPricingFromNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const rate = (yield noticeSnap.data().rate.get());
    const { displayParams } = noticeSnap.data();
    return createDBPricingObject(ctx, noticeSnap, displayParams, rate);
});
export const distributeDbPrice = (dbPricingObj, distributeEnoticeFee, finalLineItemPricing, rateType) => {
    const itemsToDistributeOver = dbPricingObj.lineItems.filter(l => !isAdditionalFeeLineItem(l));
    const combinedFee = getCombinedColumnFeeInCentsFromDbPricingObj(dbPricingObj);
    const distributedFee = combinedFee / itemsToDistributeOver.length;
    const subtotal = dbPricingObj.subtotal + combinedFee;
    const taxAmt = dbPricingObj.lineItems
        .map(item => Math.round(item.amount * (dbPricingObj.taxPct / 100)))
        .reduce((pre, cur) => pre + cur, 0);
    const total = subtotal + taxAmt;
    let finalLineItemAmount;
    let lineItems;
    if ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) &&
        finalLineItemPricing &&
        rateType === RateType.flat.value) {
        let additionalFeeTotal = 0;
        dbPricingObj.lineItems.forEach(item => {
            if (isAdditionalFeeLineItem(item)) {
                additionalFeeTotal += item.amount;
            }
        });
        lineItems = dbPricingObj.lineItems.map((item, i) => {
            return {
                description: item.description,
                date: item.date,
                amount: i === 0
                    ? subtotal - additionalFeeTotal
                    : isAdditionalFeeLineItem(item)
                        ? item.amount
                        : 0
            };
        });
    }
    else if ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) &&
        !finalLineItemPricing &&
        rateType === RateType.flat.value) {
        finalLineItemAmount = combinedFee + itemsToDistributeOver[0].amount;
        lineItems = dbPricingObj.lineItems.map((item, i) => {
            return {
                description: item.description,
                date: item.date,
                amount: i === itemsToDistributeOver.length - 1
                    ? finalLineItemAmount
                    : isAdditionalFeeLineItem(item)
                        ? item.amount
                        : 0
            };
        });
    }
    else if ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) &&
        !finalLineItemPricing &&
        rateType !== RateType.flat.value) {
        finalLineItemAmount = combinedFee + itemsToDistributeOver[0].amount;
        lineItems = dbPricingObj.lineItems.map((item, i) => {
            return {
                description: item.description,
                date: item.date,
                amount: i === itemsToDistributeOver.length - 1
                    ? finalLineItemAmount
                    : item.amount
            };
        });
    }
    else if (((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.evenly) &&
        (rateType === RateType.flat.value || finalLineItemPricing)) ||
        ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) && finalLineItemPricing)) {
        lineItems = dbPricingObj.lineItems.map(item => {
            return {
                description: item.description,
                date: item.date,
                amount: item.amount !== 0 && !isAdditionalFeeLineItem(item)
                    ? item.amount + combinedFee
                    : item.amount
            };
        });
    }
    else {
        const totalToDistributeOver = itemsToDistributeOver.reduce((a, b) => a + b.amount, 0);
        // if there is only one value to distribute over, put the whole fee on it
        if (itemsToDistributeOver.length === 1) {
            finalLineItemAmount = totalToDistributeOver + combinedFee;
        }
        // otherwise we need to be careful with rounding
        else {
            const initialDistributed = itemsToDistributeOver
                .slice(0, -1)
                .reduce((a, b) => a + b.amount + Math.round(distributedFee), 0);
            finalLineItemAmount =
                totalToDistributeOver + combinedFee - initialDistributed;
        }
        const lastNonFeeLineItemIndex = getLastNonFeeLineItemIndex(dbPricingObj.lineItems);
        lineItems = dbPricingObj.lineItems.map((item, i) => {
            let amount;
            if (isAdditionalFeeLineItem(item)) {
                // don't include the fee on custom items
                amount = item.amount;
            }
            else if (i === lastNonFeeLineItemIndex) {
                // handle rounding on the final non-fee item
                amount = finalLineItemAmount;
            }
            else {
                // otherwise include the default spread
                amount = item.amount + Math.round(distributedFee);
            }
            return {
                description: item.description,
                date: item.date,
                amount
            };
        });
    }
    return Object.assign(Object.assign({}, dbPricingObj), { lineItems, convenienceFee: null, affidavitFeeInCents: null, convenienceFeePct: null, subtotal,
        taxAmt,
        total, distributed: true, distributedFee: combinedFee });
};
export const invoiceDataToDBPricingObject = (inAppLineItems, convenienceFeePct, optionalAffidavitFeeInCents, inAppTaxPct, distributeEnoticeFee, disableMinimumConvenienceFee, finalLineItemPricing, rateType, appliedBalance) => {
    const subtotal = inAppLineItems
        .map(item => item.amount)
        .reduce((pre, cur) => pre + cur, 0);
    const convenienceFee = calculateConvenienceFee(subtotal, convenienceFeePct);
    const affidavitFeeInCents = optionalAffidavitFeeInCents || 0;
    const taxAmt = inAppLineItems
        .map(item => Math.round(item.amount * (inAppTaxPct / 100)))
        .reduce((pre, cur) => pre + cur, 0);
    const total = subtotal +
        taxAmt +
        convenienceFee +
        affidavitFeeInCents -
        (appliedBalance || 0);
    let obj = {
        lineItems: inAppLineItems
            .map(item => {
            // TODO(COREDEV-1031): This brings in extra properties from LineItem
            // which are not on the DBLineItem type!
            return Object.assign(Object.assign({}, item), { type: getLineItemType(item), date: firestoreTimestampOrDateToDate(item.date) });
        })
            .sort((a, b) => a.date.getTime() - b.date.getTime()),
        taxPct: inAppTaxPct,
        taxAmt,
        convenienceFeePct,
        convenienceFee,
        affidavitFeeInCents,
        subtotal,
        total
    };
    if (distributeEnoticeFee && shouldDistributeFee(distributeEnoticeFee)) {
        obj = distributeDbPrice(obj, distributeEnoticeFee, finalLineItemPricing, rateType);
    }
    return obj;
};
export const getDBPricingObjectFromInvoiceRateAndPaper = (newspaper, invoice, rate) => {
    var _a, _b;
    const { inAppLineItems, convenienceFeePct, affidavitFeeInCents, inAppTaxPct, disableMinimumConvenieceFee } = invoice.data();
    return invoiceDataToDBPricingObject(inAppLineItems, convenienceFeePct, affidavitFeeInCents, inAppTaxPct, getDistributeFeeSettings(newspaper, rate), disableMinimumConvenieceFee, (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.finalLineItemPricing, (_b = rate.data()) === null || _b === void 0 ? void 0 : _b.rateType);
};
export const getDBPricingObject = (invoice, notice) => __awaiter(void 0, void 0, void 0, function* () {
    const rate = yield notice.data().rate.get();
    const newspaper = yield notice.data().newspaper.get();
    return getDBPricingObjectFromInvoiceRateAndPaper(newspaper, invoice, rate);
});
export const getUIPricingObject = (dbPricingObj) => {
    const lineItems = dbPricingObj.lineItems.map(item => {
        return { date: item.date, amount: dbToUICurrency(item.amount) };
    });
    const subtotal = dbToUICurrency(dbPricingObj.subtotal);
    const { convenienceFee, convenienceFeePct, taxPct, affidavitFeeInCents } = dbPricingObj;
    const total = dbToUICurrency(dbPricingObj.total);
    return {
        lineItems,
        subtotal,
        taxPct,
        convenienceFeePct,
        convenienceFee,
        affidavitFeeInCents,
        total
    };
};
export const getUIPricingObjectFromInvoiceRateAndPaper = (newspaper, invoice, rate) => {
    const dbPricingObject = getDBPricingObjectFromInvoiceRateAndPaper(newspaper, invoice, rate);
    return getUIPricingObject(dbPricingObject);
};
export const getUIPricingObjectStrings = (dbPricingObj, options, finalLineItemPricing, rateType) => {
    const dbPricing = options &&
        options.distributeEnoticeFee &&
        shouldDistributeFee(options.distributeEnoticeFee)
        ? distributeDbPrice(dbPricingObj, options.distributeEnoticeFee, finalLineItemPricing, rateType)
        : dbPricingObj;
    const lineItems = dbPricing.lineItems.map(item => {
        return {
            date: item.date,
            amount: dbToUICurrencyString(item.amount),
            description: item.description || null
        };
    });
    const convenienceFeePct = dbPricing.convenienceFeePct
        ? getPercentString(dbPricing.convenienceFeePct)
        : undefined;
    /* For all UI purposes (invoices, proofs, & notice/invoice detail pages),
     * we will be combining the 10% convenience fee with the $5 affidavit fee,
     * if applicable. */
    const combinedFee = getCombinedColumnFeeInCentsFromDbPricingObj(dbPricing);
    const convenienceFee = combinedFee
        ? dbToUICurrencyString(combinedFee)
        : undefined;
    const subtotal = dbToUICurrencyString(dbPricing.subtotal);
    const taxPct = getPercentString(dbPricing.taxPct, {
        returnWithoutFixedDecimals: true
    });
    const taxAmt = dbToUICurrencyString(dbPricing.taxAmt);
    const total = dbToUICurrencyString(dbPricing.total);
    return {
        lineItems,
        subtotal,
        taxPct,
        taxAmt,
        convenienceFeePct,
        convenienceFee,
        total
    };
};
export const getCombinedColumnFeeInCentsFromDbPricingObj = (dbPricingObj) => {
    return dbPricingObj.convenienceFee + (dbPricingObj.affidavitFeeInCents || 0);
};
export const ENOTICE_NAME = 'Column, PBC';
// Retrieves the distribute Fee settings either from the rate or the newspaper respectively
export const getDistributeFeeSettings = (newspaper, rate) => {
    var _a, _b;
    return (((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.distributeEnoticeFee) || ((_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.distributeEnoticeFee));
};
// Checks if one of the distribute settings is turned on, other wise it returns false
export const shouldDistributeFee = (distributeFeeSettings) => {
    return (!!distributeFeeSettings &&
        Object.values(distributeFeeSettings).some(setting => setting));
};
export default {
    getColumnInches,
    getColumnCentimeters,
    calculateDBPrice,
    getRelevantRateString,
    getApplicableRate,
    relevantDisplayParameterFromRate,
    getCorrectNoticeRate,
    getNoticeRate,
    createDBPricingObject,
    createDBPricingObjectFromData,
    getUIPricingObject,
    getUIPricingObjectStrings,
    floatToP2Float,
    uiToDBCurrency,
    dbToUICurrency,
    dbToUICurrencyString,
    floatToDBPercent,
    ENOTICE_NAME,
    getInvoiceTotalSubtotalAndFees,
    calculateConvenienceFee,
    calculateBoldPrices,
    getFolios,
    getCombinedColumnFeeInCentsFromDbPricingObj
};
