/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { exists } from 'lib/types';
import { getNoticeType } from 'lib/helpers';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from 'components/CButton';
import ScrollStep from './ScrollStep';
import { NoticeType } from '../../lib/enums';
import SelectDropdown from './SelectDropdown';
import { shouldLoadTypeform } from './typeform';
const styles = (theme) => createStyles({
    headingText: {
        color: theme.palette.grey.scrollFormHeading
    },
    subHeading: {
        color: theme.palette.grey.scrollFormHeading,
        fontWeight: 600
    }
});
const NoticeTypeSelect = ({ id, activeStepId, newspaper, notice, next, previous, onDisabledStepClick, placement, placementActions, editing, push }) => {
    const [validNoticeTypes, setValidNoticeTypes] = useState();
    // Function used to initialize selected notice type value
    const getSelectedNoticeType = () => {
        var _a, _b, _c, _d, _e;
        // Fetch the notice type
        // For display ads the real notice type is saved in previousNoticeType
        let match;
        if (((_a = notice.data()) === null || _a === void 0 ? void 0 : _a.noticeType) !== NoticeType.display_ad.value) {
            match = getNoticeType(notice, newspaper) || null;
        }
        else {
            match =
                ((_c = (_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.allowedNotices) === null || _c === void 0 ? void 0 : _c.find(type => type.value === (placement === null || placement === void 0 ? void 0 : placement.previousNoticeType))) || null;
        }
        if ((match === null || match === void 0 ? void 0 : match.value) === NoticeType.custom.value) {
            // Hide Other option if not allowed by the newspaper
            if (!((_e = (_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.allowedNotices) === null || _e === void 0 ? void 0 : _e.find(type => type.value === NoticeType.custom.value))) {
                return null;
            }
            // Rename Other/Custom notice type's label
            return Object.assign(Object.assign({}, match), { label: match.label
                    .replace('Other', 'Other Public and Legal Notices')
                    .replace('Custom', 'Other Public and Legal Notices') });
        }
        return match;
    };
    const [selectedNoticeType, setNoticeType] = useState(getSelectedNoticeType());
    const [showWarning, setShowWarning] = useState(false);
    const isComplete = () => {
        var _a, _b;
        if (selectedNoticeType && selectedNoticeType.value) {
            return !!selectedNoticeType;
        }
        if (((_b = (_a = placement === null || placement === void 0 ? void 0 : placement.draftSnap) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.noticeType) === NoticeType.display_ad.value)
            return true;
        return false;
    };
    useEffect(() => {
        var _a, _b;
        const noticeTypes = (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices;
        if (exists(newspaper) && noticeTypes) {
            const validNoticeTypes = noticeTypes.map(notice => ({
                typeform: notice.typeform,
                value: notice.value,
                label: notice.label
                    .replace('Other', 'Other Public and Legal Notices')
                    .replace('Custom', 'Other Public and Legal Notices')
            }));
            if (!validNoticeTypes.find(n => n.value === NoticeType.custom.value) &&
                !((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.hideOtherNoticeOption)) {
                validNoticeTypes.push({ value: 0, label: 'Other', typeform: '' });
            }
            // If the current selectedNoticeType is not valid based on the
            // paper's allowedNotice types, reset to null
            if (!!selectedNoticeType &&
                !validNoticeTypes.map(nt => nt.value).includes(selectedNoticeType.value)) {
                setNoticeType(null);
            }
            setValidNoticeTypes(validNoticeTypes);
        }
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    useEffect(() => {
        var _a;
        if (selectedNoticeType &&
            !selectedNoticeType.value &&
            (!placement.noticeType || ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.showWarningOnOtherNoticeType)))
            setShowWarning(true);
    }, [selectedNoticeType]);
    useEffect(() => {
        if (!validNoticeTypes)
            return;
        const currentNoticeType = placement.noticeType === NoticeType.display_ad.value
            ? placement.previousNoticeType
            : placement.noticeType;
        const type = validNoticeTypes.find(item => {
            if (item.value === currentNoticeType) {
                return item;
            }
        });
        if (type)
            setNoticeType(type);
    }, [placement.noticeType, validNoticeTypes]);
    const synchronizeReduxStoreValues = (newNoticeType) => {
        if ((placement === null || placement === void 0 ? void 0 : placement.noticeType) === NoticeType.display_ad.value) {
            placementActions.setPreviousNoticeType(newNoticeType.value);
        }
        else {
            placementActions.setPreviousNoticeType(placement.noticeType);
            placementActions.setNoticeType(newNoticeType.value);
        }
    };
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: () => {
            var _a, _b;
            if (selectedNoticeType) {
                // confirm the previous notice type in the redux store. This is relevant
                // for papers that have a default custom notice type!
                synchronizeReduxStoreValues(selectedNoticeType);
                if (shouldLoadTypeform(placement, newspaper, notice, selectedNoticeType)) {
                    if (((_a = placement.draft) === null || _a === void 0 ? void 0 : _a.id) && (selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.value)) {
                        // Save the draft before moving on to the Typeform
                        placementActions.saveDraft();
                        const redir = `/form/${(_b = placement.draft) === null || _b === void 0 ? void 0 : _b.id}/${selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.value}`;
                        push(redir);
                    }
                    return;
                }
            }
            next();
        }, previous: previous, complete: isComplete(), onDisabledStepClick: onDisabledStepClick, title: editing ? 'Edit Notice Type' : 'Select Notice Type' },
        React.createElement("div", { className: "grid grid-cols-12 gap-2" },
            React.createElement("div", { className: "col-span-12" },
                React.createElement(SelectDropdown, { id: "selectNoticeType", placeholder: "Notice Type", options: validNoticeTypes && validNoticeTypes.length
                        ? validNoticeTypes
                        : null, selected: validNoticeTypes && selectedNoticeType
                        ? {
                            id: placement.noticeType.toString(),
                            label: selectedNoticeType.label
                        }
                        : null, value: validNoticeTypes && selectedNoticeType
                        ? {
                            id: placement.noticeType.toString(),
                            label: selectedNoticeType.label
                        }
                        : '', onChange: (newValue) => {
                        if (!newValue)
                            return;
                        synchronizeReduxStoreValues(newValue);
                        setNoticeType(newValue);
                    } }))),
        showWarning && exists(newspaper) && (React.createElement(FreeformCModal, { id: "notice-not-available-warning", header: "Notices not available", body: newspaper.data().noticeNotAvailableWarning
                ? newspaper.data().noticeNotAvailableWarning
                : newspaper.data().supportEmail
                    ? `Right now ${newspaper.data().name} only accepts the notice types shown above through Column. 
          To file a different type of notice please email ${newspaper.data().supportEmail}`
                    : `Right now ${newspaper.data().name} only accepts the notice types shown above through Column.` },
            React.createElement(CButton, { className: `bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-40`, onClick: () => {
                    setNoticeType(null);
                    setShowWarning(false);
                } }, "Close")))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps, { push })(withStyles(styles)(NoticeTypeSelect));
