var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { XIcon } from 'icons';
import { OccupationType } from 'lib/enums';
import React, { useEffect, useRef, useState } from 'react';
import CTooltip from 'components/Tooltip';
import api from 'api';
import classNames from 'classnames';
import { logAndCaptureException } from 'utils';
import { getOrganizationValFromOccupationVal } from 'lib/helpers';
import ConfirmToast from 'components/modals/ConfirmToast';
import LoadingState from 'components/LoadingState';
import RegisterOrganizationLawFirm from './law_firm';
import RegisterOrganizationGovernment from './government';
import RegisterOtherOrganization from './other_organization';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import searchIcon from '../searchIcon.svg';
import StepsCounter from '../StepsCounter';
import useOutsideClickHook from '../useOutsideClickHook';
import SearchedOrganizationsList from './SearchedOrganizationsList';
const JoinOrCreateNewOrganization = ({ user, authActions, userAuth, anonPlacement, push }) => {
    const [searchOrgText, setSearchOrgText] = useState('');
    const [organizationsList, setOrganizationsList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [shouldCreateNewOrganization, setShouldCreateNewOrganization] = useState(false);
    const [isOrganizationStepDone, setIsOrganizationStepDone] = useState(false);
    const [preventDismissingInfo, setPreventDismissingInfo] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClickHook(wrapperRef, () => setShowOptions(false));
    const organizationType = getOrganizationValFromOccupationVal(user.data().occupation);
    useEffect(() => {
        if (organizationType) {
            void (function getOrganizationsList() {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const req = {
                            organizationType
                        };
                        const response = yield api.post('search/advertiser-organizations', req);
                        if (response.success) {
                            setOrganizationsList(response.organizationsList);
                        }
                    }
                    catch (error) {
                        logAndCaptureException(error, 'Failed to load organizations');
                    }
                });
            })();
        }
    }, []);
    const disable = !!selectedItems.length;
    const onBackClick = () => {
        push('/register/occupations');
    };
    const nextButtonClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        if (shouldCreateNewOrganization)
            return;
        if (!selectedItems.length)
            return;
        if (!user.data().email)
            return;
        setLoading(true);
        try {
            sessionStorage.setItem('selectedOrganizations', JSON.stringify(selectedItems));
            const response = yield api.post('users/assign-occupation', {
                email: user.data().email,
                occupation: OccupationType.individual.value
            });
            if (response.success) {
                push('/register/organization');
            }
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to assign occupation to user', {
                user: user.id
            });
        }
        setLoading(false);
    });
    const removeSelectedItem = (item) => {
        const selectedItemsArray = selectedItems.filter(selectedItem => selectedItem.label !== item.label);
        setSelectedItems(selectedItemsArray);
    };
    const onChangeInputValue = (value) => {
        setSearchOrgText(value);
        if (!showOptions) {
            setShowOptions(true);
        }
    };
    const onInputFocus = () => {
        if (shouldCreateNewOrganization && !open && preventDismissingInfo) {
            // very small artifical delay to show the focus of user on input, then disable it;
            // Omitting this will not give user the impression of focus on input field
            setTimeout(() => {
                setOpen(true);
            }, 70);
        }
        else {
            setShouldCreateNewOrganization(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        organizationsList.length === 0 ? (React.createElement(LoadingState, null)) : (React.createElement("div", { className: classNames('flex flex-col items-center overflow-y-scroll', {
                'justify-center h-screen -mt-12': !shouldCreateNewOrganization,
                'mt-20 mb-20': shouldCreateNewOrganization
            }) },
            React.createElement(StepsCounter, { maxStep: '4', stepCount: '3', isStepDone: Boolean(selectedItems.length) || isOrganizationStepDone }),
            React.createElement("div", { className: "mt-8 text-center" },
                React.createElement("p", { className: "text-gray-900 font-semibold leading-8 text-2xl" }, "Join or create an organization"),
                React.createElement("p", { className: "mt-4 text-gray-600 font-medium text-sm leading-6 w-128" }, "Is your team currently on Column? Find and request to join them or create a new organization.")),
            React.createElement("div", null,
                selectedItems.length ? (React.createElement("div", { className: "mt-6 rounded-md p-0.5 bg-blue-100" },
                    React.createElement("div", { className: "bg-white rounded-md" },
                        React.createElement("div", { className: "flex flex-wrap items-center icon sm:w-auto md:w-128 lg:w-128 py-1 px-3 border border-blue-500 rounded-md shadow-sm" },
                            selectedItems &&
                                selectedItems.map(item => (React.createElement(SelectedOrganization, { key: `${item.label}-selected-item`, item: item, removeSelectedItem: () => removeSelectedItem(item) }))),
                            React.createElement("input", { value: searchOrgText, className: "mx-2 focus:outline-none flex flex-auto", onChange: e => onChangeInputValue(e.target.value) }))))) : (React.createElement("input", { id: "search-org", className: "mt-8 icon form-input block sm:w-auto md:w-128 lg:w-128 h-12 py-2 px-4 border border-gray-400 placeholder-gray-400 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-500 transition duration-150 ease-in-out sm:leading-5 text-sm font-medium", placeholder: "Search to join existing organizations", value: searchOrgText, onChange: e => onChangeInputValue(e.target.value), onFocus: () => onInputFocus(), disabled: open })),
                showOptions && (React.createElement("div", { className: "z-20 absolute sm:w-auto md:w-128 lg:w-128", ref: wrapperRef },
                    React.createElement(SearchedOrganizationsList, { organizationsList: organizationsList, searchOrgText: searchOrgText, selectedItems: selectedItems, updateSelectedItems: (values) => {
                            setSelectedItems(values);
                            setShowOptions(false);
                            setSearchOrgText('');
                        }, onCreateNewOrganization: () => {
                            setShowOptions(false);
                            setSearchOrgText('');
                            setShouldCreateNewOrganization(true);
                        } })))),
            React.createElement("p", { className: "text-gray-600 font-medium text-md leading-6 my-3 text-center" }, "or"),
            shouldCreateNewOrganization &&
                {
                    [OccupationType.lawyer.value]: (React.createElement(React.Fragment, null,
                        React.createElement(RegisterOrganizationLawFirm, { userAuth: userAuth, authActions: authActions, push: push, user: user.data(), anonPlacement: anonPlacement, stepComplete: (value) => setIsOrganizationStepDone(value), setPreventDismissingInfo: value => setPreventDismissingInfo(value) }))),
                    [OccupationType.government_official.value]: (React.createElement(React.Fragment, null,
                        React.createElement(RegisterOrganizationGovernment, { userAuth: userAuth, authActions: authActions, user: user.data(), push: push, anonPlacement: anonPlacement, stepComplete: (value) => setIsOrganizationStepDone(value), setPreventDismissingInfo: value => setPreventDismissingInfo(value) }))),
                    [OccupationType.other_organization.value]: (React.createElement(React.Fragment, null,
                        React.createElement(RegisterOtherOrganization, { userAuth: userAuth, authActions: authActions, user: user.data(), push: push, anonPlacement: anonPlacement, stepComplete: (value) => setIsOrganizationStepDone(value), setPreventDismissingInfo: value => setPreventDismissingInfo(value) })))
                }[user.data().occupation],
            !shouldCreateNewOrganization && (React.createElement("div", null,
                React.createElement("div", { className: "flex flex-row items-stretch" },
                    React.createElement(CTooltip, { classes: "flex-1", position: "bottom", helpText: disable
                            ? `You can either join an organization or create a new one. We noticed you’ve decided to join ${selectedItems
                                .map(item => item.label)
                                .join(', ')}.
                You can also click the “x” icon beside the organization name to cancel your request and create a new organization now.`
                            : '', helpHeaderText: 'Why can’t you create a new organization?', innerBackgroundColor: "gray" },
                        React.createElement("button", { id: "create-new-org", className: classNames('bg-blue-50 h-12 font-medium text-sm rounded-md transition duration-300 ease-in-out border sm:w-auto md:w-128 lg:w-128 py-2 px-4 focus:shadow-outline-blue focus:border-blue-50', {
                                'text-blue-300 border-blue-200 cursor-default': disable,
                                'hover:bg-blue-100 text-blue-500 hover:text-blue-600 border-blue-500': !disable
                            }), onClick: () => {
                                setShouldCreateNewOrganization(true);
                                setSearchOrgText('');
                            }, disabled: disable }, "Create a new organization"))))),
            open && (React.createElement(ConfirmToast, { headerText: 'Are you sure?', bodyText: `If you search for an existing organization, your progress will be lost. Please click "Yes, continue" to confirm.`, leftButtonText: 'Cancel', rightButtonText: `Yes, continue`, onLeftClick: () => {
                    setOpen(false);
                }, onRightClick: () => {
                    setOpen(false);
                    setShouldCreateNewOrganization(false);
                }, position: "bottomRight" })))),
        !shouldCreateNewOrganization && (React.createElement(AdvertiserRegistrationFooter, { id: "next-to-join", backButtonText: "Go back", nextButtonText: "Continue", onBackButtonClick: onBackClick, onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: !selectedItems.length })),
        React.createElement("style", null, `
      .icon {
        padding-right: 25px;
        background: url("${searchIcon}") no-repeat 97.5% center;
        background-size: 17px;
      }
      `)));
};
export default JoinOrCreateNewOrganization;
// Show selected organizations in input field
export function SelectedOrganization({ item, removeSelectedItem }) {
    return (React.createElement("div", { className: "mr-2 h-7 px-2 my-1 text-blue-500 bg-blue-100 rounded-full items-center justify-center flex" },
        React.createElement("p", { className: "mr-1 w-max text-xs font-medium leading-5" }, item.label),
        React.createElement("div", { onClick: () => removeSelectedItem(item) },
            React.createElement(XIcon, { className: "h-4 w-4" }))));
}
