export var LaunchDarklyFlags;
(function (LaunchDarklyFlags) {
    /**
     * Pause the entire Dwolla payouts pipeline. This is intended only as an
     * emergency kill switch if we are having payments isues.
     */
    LaunchDarklyFlags["DISABLE_DWOLLA_PAYOUTS"] = "disable-dwolla-payouts";
    /**
     * Pause the monthly bulk invoice generation pipeline. This is intended only as an
     * emergency kill switch if we are having payments isues.
     */
    LaunchDarklyFlags["DISABLE_BULK_INVOICE_SCHEDULER"] = "disable-bulk-invoice-scheduler";
    /**
     * Kill switch to pause affidavit reminders.
     * This is intended as a temporary flag until ONCALL-1133 gets resolved
     */
    LaunchDarklyFlags["DISABLE_AFFIDAVIT_REMINDER_NOTIFICATIONS"] = "disable-affidavit-reminder-notifications";
    /**
     * Kill switch to pause invoice upfront payment required reminders.
     * This is intended as a temporary flag until ONCALL-1133 gets resolved
     */
    LaunchDarklyFlags["DISABLE_INVOICE_REMINDER_NOTIFICATIONS"] = "disable-invoice-reminder-notifications";
    /**
     * Allow multi-org publishers to select the placement paper
     * See COREDEV-950
     */
    LaunchDarklyFlags["ENABLE_NEWSPAPER_SELECTION_FOR_PUBLISHERS"] = "enable-newspaper-selection-for-publishers";
    /**
     * Enable bank account verification via Stripe UI instead of Plaid
     * See COREDEV_469
     */
    LaunchDarklyFlags["ENABLE_STRIPE_UI_BANK_ACCOUNT_VERIFICATION"] = "enable-stripe-ui-bank-account-verification";
    /**
     * For auto-invoiced notices, allow invoices to be regenerated after edits.
     * See COREDEV-1669
     */
    LaunchDarklyFlags["ENABLE_AUTO_INVOICE_AFTER_EDITS"] = "enable-auto-invoice-after-edits";
    /**
     * Enables new hyphenation settings for long sequences in notice content.
     * See: IT-4401 and ONCALL-1530
     */
    LaunchDarklyFlags["ENABLE_ADVANCED_HYPHENATION"] = "enable-advanced-hyphenation";
    /**
     * Test feature flag.
     */
    LaunchDarklyFlags["TEST_FEATURE"] = "test-feature";
    /**
     * This flag determines whether the "Upload E-Edition" button will be visible to users
     * See COREDEV-1116
     */
    LaunchDarklyFlags["SHOW_E_EDITION_UPLOADER"] = "show-e-edition-uploader";
    /**
     * Enables indexing customer documents to Elastic
     */
    LaunchDarklyFlags["ENABLE_SYNC_CUSTOMERS_TO_ELASTIC"] = "enable-sync-customers-to-elastic";
    /**
     * Enables notice tracker at top of notice detail page
     */
    LaunchDarklyFlags["ENABLE_NOTICE_TRACKER"] = "enable-notice-tracker";
    /**
     * Enables whether or not a publisher can edit the order number for an invoice
     */
    LaunchDarklyFlags["ENABLE_ORDER_NUMBER_EDITING"] = "enable-order-number-editing";
    /**
     * Will allow for bulk pagination without ever hitting the Indesign server
     */
    LaunchDarklyFlags["ENABLE_TURBO_BULK_PAGINATION"] = "enable-turbo-bulk-pagination";
    /**
     * To be used to push code piecemeal while changing our refund flows. Will be deleted once ticket is fully finished & tested.
     */
    LaunchDarklyFlags["APP_5_HANDLE_TRANSFERS_AFTER_REFUNDS"] = "app-5-handle-transfers-after-refunds";
    /**
     * Used to feature flag new anonymous placement which removes the redirect to registration
     */
    LaunchDarklyFlags["DISABLE_NEW_ANONYMOUS_PLACEMENT"] = "disable-new-anonymous-placement";
    /**
     * Used for column express, where we redirect users through a typeform
     * to enter their newspaper information and proceed to file notices on their behalf
     * This will be toggled off (Feb 23) until we restructure Column Express
     */
    LaunchDarklyFlags["ENABLE_CANT_FIND_NEWSPAPER"] = "enable-cant-find-newspaper";
    /**
     * @deprecated this flag was replaced by another one.
     */
    LaunchDarklyFlags["COREDEV_1166_HANDLE_TRANSFERS_AFTER_REFUNDS"] = "coredev-1166-handle-transfers-after-refunds";
    /**
     * Determines whether we use the new integration gateway/script for a given publisher.
     */
    LaunchDarklyFlags["ENABLE_EVENT_DRIVEN_INTEGRATION"] = "enable-event-driven-integration";
    /**
     * Enable the Madlibs editor in the placement flow.
     */
    LaunchDarklyFlags["ENABLE_MADLIBS_EDITOR"] = "enable-madlibs-editor";
    /**
     * @deprecated determines what triggers integrations that sync at deadline.
     */
    LaunchDarklyFlags["ENABLE_DEADLINE_SYNCS_BASED_ON_EVENT_CREATION"] = "enable-deadline-syncs-based-on-event-creation";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_SHOW_REPORTS_TAB"] = "enable-show-reports-tab";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_MORE_ACTIONS_BUTTON"] = "enable-more-actions-button";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_TEMPLATED_NOTICE_EDITOR"] = "enable-templated-notice-editor";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_NOTICE_TABLE_FILTERS"] = "enable-notice-table-filters";
    /**
     * Disables the new refactored PayInvoice FE stack and reverts to old code
     */
    LaunchDarklyFlags["DISABLE_NEW_PAYINVOICE_FE"] = "disable-new-payinvoice-fe";
    /**
     * Enable the new version of the placement flow
     */
    LaunchDarklyFlags["ENABLE_NEW_PLACEMENT_FLOW"] = "enable-new-placement-flow";
    /**
     * Numeric feature flag. Returns number 0-100
     */
    LaunchDarklyFlags["INDESIGN_ERROR_SAMPLE_RATE"] = "indesign-error-sample-rate";
})(LaunchDarklyFlags || (LaunchDarklyFlags = {}));
