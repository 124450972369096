var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import ETooltip from 'components/Tooltip';
import { isBattleborn, getNoticeTypeData } from 'lib/publishers';
import { exists } from 'lib/types';
import { getShouldShowUpFrontBilling, getNoticeIsInvoicedOutsideColumn } from 'lib/helpers';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';
import { NoticeStatusType, InvoiceStatus, State } from 'lib/enums';
import { EditIcon, ArrowRightWithTailIcon, ClipbaordList, BookmarkIcon, ArrowPointRight } from 'icons/index';
import EToast from 'components/EToast';
import { getFirebaseContext } from 'utils/firebase';
import { addToCurrentURLParameters, updateHistoryNoRerender } from 'utils/urls';
import { safeStringify } from 'lib/utils/stringify';
import { CONFIRM_NEWSPAPER, CONFIRM_NOTICE_TYPE, CONFIRM_SCHEDULE } from 'routes/placeScroll/helpers';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { canEditNoticeWithoutSupport } from 'utils/permissions';
import MilestoneTracker from 'components/MilestoneTracker/MilestoneTracker';
import moment from 'moment';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import { getAffidavitMilestoneTrackerParams } from 'utils/milestones';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
import BasicInfo from './BasicInfo';
import InvoiceActions from './InvoiceActions';
import FreeformCModal from '../../components/modals/FreeFormCModal';
import AffidavitActions from './AffidavitActions';
import MailTable from './MailTable';
import NoticePreview from './NoticePreview';
import ConfirmReceiptModal from './ConfirmReceipt';
import ConfirmNoticeWithoutProof from './ConfirmNoticeWithoutProof';
import { BillingService } from '../../services/index';
import SendReminderModal from './SendReminderModal';
import NoticePlacedAnonymouslyModal from './NoticePlacedAnonymouslyModal';
import Activity from './activityLog';
// Views that are shown on the right panel
const ACTIVITY_LOG = 'activity-log';
const NOTICE_PREVIEW = 'notice-preview';
const mapStateToProps = (state) => {
    var _a;
    return ({
        pathname: state.router.location.pathname,
        toastText: (_a = state === null || state === void 0 ? void 0 : state.toast) === null || _a === void 0 ? void 0 : _a.toastText,
        showPasswordReset: state.auth.showPasswordReset
    });
};
const NoticeDetail = ({ isPublisher, alwaysAllowAffidavitDownload, pathname, activeOrganization, availableOrganizations, authActions, push, user, toastActions, toastText, showPasswordReset }) => {
    var _a, _b, _c, _d, _e, _f;
    const { params: { id } } = matchPath(pathname, {
        path: '/:path(notice|publish)/:id/',
        exact: true,
        strict: false
    }) || { params: {} };
    const [error, setError] = useState('');
    const [state, setState] = useState(null);
    const [noticeSnap, setNoticeSnap] = useState();
    const [affidavitTerm, setAffidavitTerm] = useState();
    const [disableEditReason, setDisableEditReason] = useState('Loading...');
    // Edit modal
    const [editModal, setEditModal] = useState(false);
    // Confirm Receipt Modal
    const [confirmReceiptModalOpen, setConfirmReceiptModalOpen] = useState(false);
    const [withoutAdProofModalOpen, setWithoutAdProofModalOpen] = useState(false);
    // Send reminder modal
    const [showSendReminderModal, setShowSendReminderModal] = useState(false);
    // Notice placed modal (for anonymous filers)
    const [showNoticePlacedModal, setShowNoticePlacedModal] = useState(showPasswordReset || false);
    // Invoice snapshot
    const [invoiceSnap, setInvoiceSnap] = useState();
    // Is invoice overdue
    const [invoiceOverdue, setInvoiceOverdue] = useState(false);
    const [toastMessage, setToastMessage] = useState(toastText || '');
    const DEFAULT_TOAST_ERROR = { title: '', message: '' };
    const [toastError, setToastError] = useState(DEFAULT_TOAST_ERROR);
    // Notice Tracker inputs
    const publicationDates = (noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().publicationDates) || false;
    const isNoticePublished = publicationDates &&
        publicationDates.length > 0 &&
        moment().isAfter(publicationDates.slice(-1)[0].toMillis());
    const isAffidavitAvailable = !!(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().affidavit) || false;
    // TODO: Should this include InvoiceStatus.initiated.value https://columnpbc.atlassian.net/browse/IT-4424
    const isInvoicePaid = exists(invoiceSnap) &&
        [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(invoiceSnap.data().status);
    // Notice tracker should only display to advertisers
    const showNoticeTracker = useBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_TRACKER) && !isPublisher;
    // Mobile View
    const [showPreviewOnMobile, setShowPreviewOnMobile] = useState(false);
    // Invoiced outside Column
    const [isInvoicedOutsideColumn, setIsInvoicedOutsideColumn] = useState();
    const [canEditNotice, setCanEditNotice] = useState(false);
    // Which view to show on the right panel (notice preview or activity log)
    const [rightPanelView, setRightPanelView] = useState(NOTICE_PREVIEW);
    const getBillingStatusText = (notice, isPublisher) => __awaiter(void 0, void 0, void 0, function* () {
        const statusEnum = yield BillingService.getBillingData({
            userNoticeSnap: notice,
            isPublisher
        });
        return statusEnum.statusEnum.label;
    });
    const fetchNoticeRelatedData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!exists(noticeSnap)) {
            return setError('Notice not found');
        }
        const { newspaper, invoice, adTemplate, rate, filer, userId } = noticeSnap.data();
        if (!filer && !userId) {
            return setError('Notice has no advertiser');
        }
        const filerRef = filer !== null && filer !== void 0 ? filer : getFirebaseContext().usersRef().doc(userId);
        const [newspaperSnap, adTemplateSnap, rateSnap, filerSnap] = yield Promise.all([
            newspaper.get(),
            adTemplate.get(),
            rate.get(),
            filerRef.get()
        ]);
        const s = {
            notice: noticeSnap,
            newspaper: newspaperSnap,
            adTemplate: adTemplateSnap,
            rate: rateSnap,
            filer: filerSnap,
            invoice
        };
        if (isPublisher &&
            activeOrganization &&
            activeOrganization.id !== newspaperSnap.id) {
            authActions.setActiveOrganization(availableOrganizations.find(o => o.id === newspaperSnap.id));
        }
        const billingStatus = yield getBillingStatusText(noticeSnap, isPublisher);
        s.billingStatus = billingStatus;
        const checkIsBattleBorn = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!newspaperSnap)
                return;
            const battleBornFlag = yield isBattleborn(newspaperSnap);
            if (battleBornFlag)
                return setAffidavitTerm('Proof of Publication');
            setAffidavitTerm('Affidavit');
        });
        yield checkIsBattleBorn();
        setState(s);
    });
    useEffect(() => {
        const timer = setTimeout(() => {
            setToastMessage('');
            toastActions.setToastText('');
        }, 15000);
        return () => clearTimeout(timer);
    }, []);
    /**
     * This useEffect call enables R&D workflows that run arbitrary
     * pendo guides on specific notice details screens. It checks for
     * data in the field experimentData and adds it to the URL via URL Params.
     * These URL Params can then be targeted by Pendo guides.
     */
    const experimentData = ((_b = (_a = state === null || state === void 0 ? void 0 : state.notice) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.experimentData) || {};
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        const classifiedType = (_b = (_a = state === null || state === void 0 ? void 0 : state.notice) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.classifiedType;
        if (classifiedType) {
            const stateLabel = (_e = State.by_value((_d = (_c = state === null || state === void 0 ? void 0 : state.newspaper) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.state)) === null || _e === void 0 ? void 0 : _e.label;
            if (stateLabel) {
                experimentData.classifiedType = classifiedType;
                experimentData.state = stateLabel;
            }
        }
        if (Object.keys(experimentData).length === 0)
            return;
        const newParams = addToCurrentURLParameters(experimentData);
        const updatedUrl = `/notice/${(_f = state === null || state === void 0 ? void 0 : state.notice) === null || _f === void 0 ? void 0 : _f.id}?${newParams.toString()}`;
        updateHistoryNoRerender(updatedUrl);
    }, [safeStringify(experimentData), (_d = (_c = state === null || state === void 0 ? void 0 : state.notice) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.classifiedType]);
    useEffect(() => {
        var _a;
        if (!(state === null || state === void 0 ? void 0 : state.notice))
            return;
        if (((_a = state.notice.data()) === null || _a === void 0 ? void 0 : _a.invoice) && !exists(invoiceSnap))
            return;
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            const ctx = getFirebaseContext();
            if (exists(invoiceSnap)) {
                setIsInvoicedOutsideColumn(invoiceSnap.data().invoiceOutsideColumn);
            }
            else {
                setIsInvoicedOutsideColumn(!!(yield getNoticeIsInvoicedOutsideColumn(ctx, state.notice)));
            }
        }))();
    }, [(_e = state === null || state === void 0 ? void 0 : state.notice) === null || _e === void 0 ? void 0 : _e.id, invoiceSnap === null || invoiceSnap === void 0 ? void 0 : invoiceSnap.id]);
    useEffect(() => {
        if (!exists(noticeSnap) || error)
            return;
        if (!noticeSnap.data().newspaper)
            return;
        void fetchNoticeRelatedData();
    }, [noticeSnap, activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    // listens for updates on invoice snap
    useEffect(() => {
        if (state === null || state === void 0 ? void 0 : state.invoice)
            return state.invoice.onSnapshot(doc => {
                setInvoiceSnap(doc);
            });
    }, [state === null || state === void 0 ? void 0 : state.invoice]);
    useEffect(() => {
        const configureEditButton = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(noticeSnap) || error)
                return;
            if (!noticeSnap.data().newspaper || !noticeSnap.data().publicationDates)
                return;
            const newspaperSnap = yield noticeSnap.data().newspaper.get();
            const draftsSnaps = yield Promise.all((noticeSnap.data().drafts || []).map((draftRef) => draftRef.get()));
            /**
             * the edit button is disabled until the submitted draft is deleted
             *  to prevent user from going into that draft
             */
            const shouldDisableEditButton = () => {
                var _a;
                const dateToCompare = noticeSnap.data().editedAt || noticeSnap.data().confirmedAt;
                if (draftsSnaps.find((draft) => { var _a, _b; return ((_b = (_a = draft.data()) === null || _a === void 0 ? void 0 : _a.editedAt) === null || _b === void 0 ? void 0 : _b.toMillis()) === dateToCompare.toMillis(); }))
                    return 'Loading...';
                if (!isPublisher && !((_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.advertiserEditingEnabled))
                    return 'This newspaper disabled editing.';
                return '';
            };
            setDisableEditReason(shouldDisableEditButton());
        });
        void configureEditButton();
    }, [noticeSnap, isPublisher]);
    const listenToNotice = () => {
        return getFirebaseContext()
            .userNoticesRef()
            .doc(id)
            .onSnapshot(doc => {
            setNoticeSnap(doc);
        }, err => {
            console.error(err);
            setError('Could not find notice');
        });
    };
    const listenToInvoice = () => {
        if (exists(noticeSnap) && noticeSnap.data().invoice) {
            const { invoice } = noticeSnap.data();
            return invoice === null || invoice === void 0 ? void 0 : invoice.onSnapshot(() => setNoticeSnap(noticeSnap));
        }
        return () => { };
    };
    useEffect(() => {
        if (!id)
            return;
        const noticeUnsub = listenToNotice();
        const invoiceUnsub = listenToInvoice();
        return () => {
            noticeUnsub();
            invoiceUnsub && invoiceUnsub();
            setError('Could not find notice');
        };
    }, [!error]);
    useEffect(() => {
        if (state && isPublisher && !state.notice.data().confirmedReceipt)
            setConfirmReceiptModalOpen(isPublisher && !state.notice.data().confirmedReceipt);
        if (state &&
            isPublisher &&
            state.notice.data().postWithoutFormatting &&
            !state.notice.data().confirmPostWithoutFormatting)
            setWithoutAdProofModalOpen(true);
    }, [
        state && isPublisher && !state.notice.data().confirmedReceipt,
        state && isPublisher && !state.notice.data().confirmPostWithoutFormatting
    ]);
    const LoadingState = (React.createElement("div", { className: "h-full w-full flex items-center justify-center" },
        React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })));
    const ErrorModal = (React.createElement("div", { className: "flex content-center flex-wrap h-screen w-full" },
        React.createElement("div", { className: "m-auto p-2" },
            React.createElement("p", { className: "text-3xl" }, "Unknown Notice"),
            React.createElement("p", null,
                "The notice you are trying to access either doesn't exist or you don't have permission to view it.",
                React.createElement("br", null),
                "If you believe this is an error, please contact help@column.us to figure out next steps."))));
    if (!user ||
        !state ||
        !exists(state.notice) ||
        !exists(state.newspaper) ||
        !affidavitTerm ||
        !state.notice.data().publicationDates) {
        if (error === '')
            return LoadingState;
        return ErrorModal;
    }
    // Make sure that invoiceSnap (if exists on notice) has setup correctly to avoid flickernsess in invoice data
    if (((_f = state.notice.data()) === null || _f === void 0 ? void 0 : _f.invoice) && !exists(invoiceSnap)) {
        return LoadingState;
    }
    if (isPublisher &&
        (!activeOrganization || activeOrganization.id !== state.newspaper.id)) {
        return LoadingState;
    }
    const { notice, newspaper, billingStatus } = state;
    // Hide Edit notice button for cancelled notices
    const shouldHideEditNoticeButton = notice.data().noticeStatus === NoticeStatusType.cancelled.value;
    const buttonGray = 'bg-gray-200 focus:border-gray-500 text-gray-700 hover:bg-gray-600';
    const customerNotice = getNoticeTypeData(notice.data().noticeType, newspaper);
    const isAffidavitDisabled = customerNotice.affidavitDisabled;
    return (React.createElement("div", { className: "bg-gray-100" },
        React.createElement("div", { id: "notice-details-page", className: "flex max-w-full mx-auto px-0 md:pl-6 lg:pl-8" },
            React.createElement("div", { className: "grid grid-cols-8 grid-flow-col gap-8 w-full" },
                confirmReceiptModalOpen && (React.createElement(ConfirmReceiptModal, { notice: notice, user: user, newspaper: state.newspaper, setOpen: setConfirmReceiptModalOpen })),
                !confirmReceiptModalOpen && withoutAdProofModalOpen && (React.createElement(ConfirmNoticeWithoutProof, { newspaper: state.newspaper, setOpen: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield state.notice.ref.update({
                            confirmPostWithoutFormatting: true
                        });
                        setWithoutAdProofModalOpen(false);
                    }) })),
                showNoticePlacedModal && (React.createElement(NoticePlacedAnonymouslyModal, { setOpen: (showModal) => {
                        setShowNoticePlacedModal(showModal);
                        authActions.setShowPasswordReset(showModal);
                    }, user: user, newspaper: newspaper })),
                showSendReminderModal && (React.createElement(SendReminderModal, { filer: state.filer, notice: notice, invoice: invoiceSnap, newspaper: state.newspaper, setOpen: setShowSendReminderModal, setToastMessage: setToastMessage })),
                React.createElement("div", { className: "col-span-11 md:col-span-5" },
                    React.createElement("section", { className: "h-screen overflow-y-auto md:pr-6 md:-mr-8" },
                        React.createElement("div", { className: "pt-16 md:pt-20" }),
                        getShouldShowUpFrontBilling(state.notice, state.newspaper, invoiceSnap, isInvoicedOutsideColumn) &&
                            !(exists(invoiceSnap) && hasPaymentOrPartialRefund(invoiceSnap)) && (React.createElement("div", { className: classNames((noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                ? 'bg-primary-100'
                                : 'bg-white border border-grey-150', 'relative mb-3 rounded-md') },
                            React.createElement("div", { className: "flex justify-between items-center max-w-screen-xl mx-auto h-20 md:h-14 text-sm text-gray-800" },
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: "w-4 md:w-2 h-20 md:h-14 mr-4" }),
                                    React.createElement("p", { className: "flex items-center" },
                                        isPublisher && (React.createElement("div", null,
                                            React.createElement(Switch, { checked: !!(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment), onChange: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    yield (noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.ref.update({
                                                        requireUpfrontPayment: !noticeSnap.data()
                                                            .requireUpfrontPayment,
                                                        requireUpfrontPaymentModifiedByPublisher: true
                                                    }));
                                                }), className: classNames((noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                                    ? 'bg-blue-900'
                                                    : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                                    width: '36px'
                                                } },
                                                React.createElement("span", { className: "sr-only" }, "Use setting"),
                                                React.createElement("span", { "aria-hidden": "true", className: classNames((noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                                        ? 'translate-x-4'
                                                        : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })))),
                                        React.createElement("span", { className: classNames('ml-2 mr-1 font-medium text-sm leading-6', {
                                                'text-grey-500': noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment,
                                                'text-grey-400': !(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                            }) },
                                            "Upfront payment required.",
                                            ' ',
                                            isPublisher
                                                ? 'The advertiser has not yet fulfilled the invoice for this notice.'
                                                : invoiceOverdue
                                                    ? 'Because your invoice is overdue, your notice might not be published.'
                                                    : 'If you don’t pay your invoice on time, your notice might not be published.'))),
                                isPublisher ? (React.createElement("div", { className: "uppercase mr-6 cursor-pointer font-medium text-sm leading-6 text-primary-600 flex items-center", onClick: () => setShowSendReminderModal(true) },
                                    React.createElement("span", null, "send reminder"),
                                    React.createElement(ArrowPointRight, { className: "ml-1 w-4 h-4 stroke-2" }))) : (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: invoiceSnap
                                        ? `${window.location.origin}/invoices/${invoiceSnap.id}/pay`
                                        : '', className: "uppercase mx-4 cursor-pointer font-bold" }, "pay invoice"))))),
                        React.createElement("div", { className: "mb-4" },
                            React.createElement("h4", { id: "notice-name", className: "ml-4 md:ml-0 text-4xl font-medium text-gray-800" },
                                notice.data().referenceId
                                    ? notice.data().referenceId
                                    : 'Notice Details',
                                !shouldHideEditNoticeButton && (React.createElement(ETooltip, { helpText: disableEditReason || 'Edit Notice', position: "right", classes: "inline-block pb-1" },
                                    React.createElement("button", { id: "edit-notice", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            if (disableEditReason)
                                                return;
                                            const canEdit = canEditNoticeWithoutSupport(notice, user, newspaper);
                                            setCanEditNotice(canEdit);
                                            if (isPublisher) {
                                                const { allowedNotices } = newspaper.data();
                                                push(`/place/${notice.id}?step=${canPublisherUserSeeNewspaperSelect(user, false)
                                                    ? CONFIRM_NEWSPAPER
                                                    : (allowedNotices === null || allowedNotices === void 0 ? void 0 : allowedNotices.length)
                                                        ? CONFIRM_NOTICE_TYPE
                                                        : CONFIRM_SCHEDULE}`);
                                            }
                                            else if (canEdit && !notice.data().invoice) {
                                                push(`/place/${notice.id}?step=${CONFIRM_SCHEDULE}`);
                                            }
                                            else {
                                                setEditModal(true);
                                            }
                                        }), className: "bg-tranparent text-gray-800 ml-3" },
                                        React.createElement(EditIcon, { className: "" })))),
                                notice.data().noticeStatus ===
                                    NoticeStatusType.cancelled.value && (React.createElement("span", { className: "align-middle bg-red-400 leading-5 ml-6 px-2 py-1 rounded text-sm text-white uppercase" }, "Cancelled"))),
                            React.createElement("div", { className: "flex" },
                                React.createElement("div", { className: "flex ml-4 md:ml-0 text-sm cursor-pointer md:hidden", onClick: () => {
                                        setRightPanelView(NOTICE_PREVIEW);
                                        setShowPreviewOnMobile(true);
                                    } },
                                    "NOTICE PREVIEW",
                                    React.createElement(ArrowRightWithTailIcon, { className: "ml-2 w-3 h-5" })),
                                React.createElement("div", { className: "flex ml-6 md:ml-0 text-sm cursor-pointer md:hidden", onClick: () => {
                                        setRightPanelView(ACTIVITY_LOG);
                                        setShowPreviewOnMobile(true);
                                    } },
                                    "ACTIVITY LOG",
                                    React.createElement(ArrowRightWithTailIcon, { className: "ml-2 w-3 h-5" })))),
                        showNoticeTracker && (React.createElement("section", { className: "mx-4 shadow md:mx-0 md:shadow-none mb-4 bg-white py-3 rounded border" },
                            React.createElement(MilestoneTracker, { milestones: getAffidavitMilestoneTrackerParams(isInvoicePaid, isNoticePublished, isAffidavitAvailable, isAffidavitDisabled, isInvoicedOutsideColumn) }))),
                        React.createElement("section", { className: "m-4 grid col-span-12 gap-6 md:m-0 md:mb-4 md:grid-cols-6 md:gap-10" },
                            React.createElement("section", { className: "col-span-6 md:col-span-3" },
                                React.createElement(BasicInfo, { notice: notice, invoice: invoiceSnap, invoiceOverdue: invoiceOverdue, setInvoiceOverdue: setInvoiceOverdue, newspaper: newspaper, isPublisher: isPublisher, billingStatus: billingStatus, filer: state.filer, isInvoicedOutsideColumn: isInvoicedOutsideColumn })),
                            React.createElement("section", { className: "col-span-6 md:col-span-3" },
                                React.createElement(InvoiceActions, { notice: notice, invoice: invoiceSnap, isPublisher: isPublisher, user: user, newspaper: newspaper, setOpen: setShowSendReminderModal, setToastError: setToastError, setToastMessage: setToastMessage, isInvoicedOutsideColumn: isInvoicedOutsideColumn }))),
                        !newspaper.data().affidavitDisabled && (React.createElement("section", { className: "mx-4 shadow md:mx-0 md:shadow-none mb-4 bg-white py-6 rounded border" },
                            React.createElement("section", { className: "mb-2" },
                                React.createElement("article", { id: "affidavit-actions" },
                                    React.createElement("h5", { className: "text-lg text-gray-800 font-medium pb-6 px-6 border-b" }, affidavitTerm),
                                    React.createElement(AffidavitActions, { affidavitTerm: affidavitTerm, invoice: invoiceSnap, newspaper: state.newspaper, notice: notice, isPublisher: isPublisher, user: user, alwaysAllowAffidavitDownload: alwaysAllowAffidavitDownload }))),
                            React.createElement("section", { className: "mb-4" },
                                React.createElement("article", { className: "" },
                                    React.createElement("h5", { className: "text-lg text-gray-600 mb-4 px-6" }, "Mail Requests"),
                                    React.createElement(MailTable, { notice: notice, isPublisher: isPublisher, invoiceSnap: invoiceSnap }))))))),
                React.createElement("div", { className: `${showPreviewOnMobile ? 'absolute block w-full' : 'hidden'} md:col-span-3 md:relative md:block` }, rightPanelView === NOTICE_PREVIEW ? (React.createElement("div", { className: "bg-white overflow-y-auto" },
                    React.createElement(NoticePreview, { isPublisher: isPublisher, invoiceOverdue: invoiceOverdue, newspaper: state.newspaper, notice: notice, push: push, setShowPreviewOnMobile: setShowPreviewOnMobile }))) : (React.createElement("div", { className: "bg-white" },
                    React.createElement(Activity, { setShowPreviewOnMobile: setShowPreviewOnMobile, isPublisher: isPublisher, notice: notice, user: user }))))),
            React.createElement("nav", { "aria-label": "Sidebar", className: "hidden md:block md:flex-shrink-0 md:bg-white md:overflow-y-auto pt-16" },
                React.createElement("div", { className: "relative w-20 flex flex-col p-3 space-y-3" },
                    React.createElement(Tooltip, { title: "Preview", placement: "left" },
                        React.createElement("div", { className: `${rightPanelView === NOTICE_PREVIEW ? 'bg-gray-100' : ''} hover:bg-gray-100 text-gray-800 flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg cursor-pointer py-3`, onClick: () => setRightPanelView(NOTICE_PREVIEW) },
                            React.createElement(BookmarkIcon, null))),
                    React.createElement(Tooltip, { title: "Activity", placement: "left" },
                        React.createElement("div", { className: `${rightPanelView === ACTIVITY_LOG ? 'bg-gray-100' : ''} text-gray-800 hover:bg-gray-100 flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg cursor-pointer py-3`, onClick: () => setRightPanelView(ACTIVITY_LOG) },
                            React.createElement(ClipbaordList, null)))))),
        editModal && (React.createElement(FreeformCModal, { id: "contact-support", setOpen: setEditModal, header: "Edit Notice?", body: `It looks like ${newspaper.data().editRunDatesAfterInvoicePaid && canEditNotice
                ? "you've already been invoiced. Are you sure you want to edit this notice?"
                : "the deadline for the first publication date has passed or you've already been invoiced. To edit this notice, you'll need to reach out to customer support. Email help@column.us, or click below."}` },
            React.createElement("div", { className: "flex justify-center md:justify-start" }, newspaper.data().editRunDatesAfterInvoicePaid && canEditNotice ? (React.createElement("button", { id: "edit-notice", onClick: () => {
                    push(`/place/${notice.id}?step=confirm-ad`);
                }, className: "px-8 py-2 mr-2 flex uppercase text-white bg-blue-650 rounded font-medium text-sm mt-3" }, "Edit Notice")) : (React.createElement("button", { className: `${buttonGray} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-auto`, onClick: () => window.open(`mailto:help@column.us?subject=${isPublisher ? 'Publisher' : 'Advertiser'} Request to edit Notice #${notice.id}`) }, "Contact Support"))))),
        toastMessage && (React.createElement(EToast, { id: "success-toast", message: toastMessage, type: "success", close: () => {
                toastActions.setToastText('');
                setToastMessage('');
            } })),
        toastError.message && (React.createElement(EToast, { id: "error-toast", type: "error", close: () => {
                setToastError(DEFAULT_TOAST_ERROR);
            } },
            React.createElement("div", { className: "flex" },
                React.createElement("p", { className: "font-bold" }, toastError.title),
                "\u00A0",
                React.createElement("p", null, toastError.message))))));
};
export default connect(mapStateToProps, { push })(NoticeDetail);
