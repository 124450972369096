import classNames from 'classnames';
import React, { useRef } from 'react';
const Tooltip = ({ id, helpText, children, position, customPosition, classes, helpHeaderText, innerBackgroundColor }) => {
    const tooltipRef = useRef(null);
    const positionClass = {
        left: 'right-full top-1 mr-2 -left-0',
        right: 'left-full top-1 ml-2 -right-0',
        bottom: 'top-full mt-4 -bottom-0',
        top: 'top-0 -mt-16',
        topLeft: 'top-0 -mt-16 right-0',
        topCenter: 'top-0 -mt-8'
    }[position !== null && position !== void 0 ? position : 'top'];
    const backgroundColorClass = {
        black: 'bg-black',
        gray: 'bg-gray-850'
    }[innerBackgroundColor !== null && innerBackgroundColor !== void 0 ? innerBackgroundColor : 'black'];
    return (React.createElement("div", { id: id, className: `relative tooltip-container ${classes || ''}` },
        children,
        helpText && (React.createElement("div", { style: { minWidth: helpText.length > 40 ? '200px' : '24px' }, ref: tooltipRef, id: "tooltip-text", className: classNames(`absolute text-white text-xs rounded py-1 px-4 w-auto z-50 hidden ${backgroundColorClass} ${customPosition || positionClass}`, { 'whitespace-normal': helpText.length > 50 }) },
            helpHeaderText && (React.createElement("p", { className: "mb-2 font-semibold" }, helpHeaderText)),
            helpText))));
};
export default Tooltip;
