var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Modal, IconButton, CircularProgress } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { exists } from 'lib/types';
import CelebrationBg from './celebration_bg.svg';
import Celebration from './celebration.svg';
import EButton from '../EButton';
const styles = (theme) => createStyles({
    modalContent: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        maxWidth: 900,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        textAlign: 'center',
        outline: 'none',
        minWidth: 500
    },
    headingText: {
        color: theme.palette.grey[700],
        textTransform: 'uppercase'
    },
    partition: {
        padding: theme.spacing(1)
    },
    progress: {
        color: 'white'
    },
    heading: {
        fontWeight: 600,
        fontSize: '30px',
        textTransform: 'uppercase',
        color: '#3F3D56'
    },
    explanationText: {
        color: '#3F3D56'
    },
    closeIcon: {
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        opacity: 0.25
    },
    topSection: {
        background: 'rgb(243, 212, 112)',
        padding: theme.spacing(3, 8, 3),
        borderTopRightRadius: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1)
    },
    bottomSection: {
        padding: theme.spacing(3, 8, 3)
    },
    confirmButton: {
        background: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main} !important;`
        },
        maxWidth: '50%',
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'center'
    }
});
export const InnerPostRegistrationModal = ({ isPublisher, user, activeOrganization, classes }) => {
    const shouldOpen = () => {
        var _a;
        // for publishers, modal appears after organization post reg flow
        if (isPublisher) {
            return (user &&
                exists(user) &&
                !user.data().postRegistrationComplete &&
                activeOrganization &&
                ((_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.postRegistrationComplete));
        }
        // for advertisers, mark registration as complete
        if (user && exists(user) && !user.data().postRegistrationComplete) {
            void user.ref.update({
                postRegistrationComplete: true
            });
        }
        return false;
    };
    const [open, setOpen] = useState(shouldOpen());
    const [loading, setLoading] = useState(false);
    const updateUser = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield user.ref.update({
            postRegistrationComplete: true
        });
    });
    useEffect(() => {
        setOpen(shouldOpen());
    }, [user, activeOrganization]);
    const buttonText = "Let's Get Started";
    return (React.createElement(Modal, { onBackdropClick: () => {
            void updateUser();
        }, open: open || false },
        React.createElement(Grid, { "data-testid": "post-registration-modal-content", id: "post-registration-modal-content", className: classes === null || classes === void 0 ? void 0 : classes.modalContent },
            React.createElement("div", { className: classes === null || classes === void 0 ? void 0 : classes.topSection, style: { position: 'relative' } },
                React.createElement(Grid, { className: classes === null || classes === void 0 ? void 0 : classes.partition, style: {} },
                    React.createElement(IconButton, { id: "cancel-celebration-modal", size: "small", className: classes === null || classes === void 0 ? void 0 : classes.closeIcon, onClick: () => {
                            void updateUser();
                        } },
                        React.createElement(Cancel, null)),
                    React.createElement(Typography, { variant: "subtitle2", className: classes === null || classes === void 0 ? void 0 : classes.heading }, "Welcome to Column!")),
                React.createElement(Grid, { className: classes === null || classes === void 0 ? void 0 : classes.partition },
                    React.createElement(Typography, { variant: "subtitle2", className: classes === null || classes === void 0 ? void 0 : classes.explanationText }, isPublisher &&
                        activeOrganization &&
                        exists(activeOrganization) && (React.createElement(React.Fragment, null, !activeOrganization.data().adTemplate ? (React.createElement(React.Fragment, null,
                        "You've successfully registered",
                        ' ',
                        activeOrganization.data().name,
                        ". The Column team will finalize your account and be in touch shortly.")) : (React.createElement(React.Fragment, null,
                        "You've successfully joined the",
                        ' ',
                        activeOrganization.data().name,
                        ".")))))),
                React.createElement("div", { style: {
                        position: 'relative',
                        width: 380,
                        height: 260,
                        margin: 'auto'
                    } }, !loading ? (React.createElement(React.Fragment, null,
                    React.createElement("img", { src: Celebration, style: {
                            top: 0,
                            left: 0,
                            width: 380,
                            position: 'absolute',
                            zIndex: 5
                        } }),
                    React.createElement("img", { src: CelebrationBg, style: { top: 70, left: 0, width: 380, position: 'absolute' } }))) : (React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justify: "center", style: { height: '100%' } },
                    React.createElement(CircularProgress, { style: { color: 'white' }, disableShrink: true })))),
                React.createElement("div", { style: {
                        borderRadius: '100% 100% 100% 100%',
                        height: '80px',
                        width: '50%',
                        background: 'white',
                        position: 'absolute',
                        bottom: -55,
                        left: '25%'
                    } })),
            React.createElement("div", { className: classes === null || classes === void 0 ? void 0 : classes.bottomSection },
                React.createElement(Grid, { "data-testid": "modal-action-link", container: true, className: classes === null || classes === void 0 ? void 0 : classes.partition, alignItems: "center" },
                    React.createElement(Grid, { item: true, xs: true }),
                    React.createElement(EButton, { disabled: loading, className: classes === null || classes === void 0 ? void 0 : classes.confirmButton, onClick: () => {
                            void user.ref.update({
                                postRegistrationComplete: true
                            });
                        } }, buttonText),
                    React.createElement(Grid, { item: true, xs: true }))))));
};
const mapStateToProps = (state) => ({
    activeOrganization: state.auth.activeOrganization,
    isPublisher: state.auth.isPublisher,
    user: state.auth.user
});
export default connect(mapStateToProps)(withStyles(styles)(InnerPostRegistrationModal));
