import React, { useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Provider } from 'react-redux';
import { STRIPE_VARS } from './constants';
import Router from './Router';
import EnoticeTheme from './Theme';
import Header from './components/Header';
const App = ({ store }) => {
    const [stripe, setStripe] = useState(null);
    const setStripeAccount = () => {
        if (window.Stripe) {
            setStripe(window.Stripe(STRIPE_VARS.key));
        }
        else {
            const script = document.querySelector('#stripe-js');
            if (script) {
                script.addEventListener('load', () => {
                    setStripe(window.Stripe(STRIPE_VARS.key));
                });
            }
            else {
                console.log('Stripe not loaded correctly');
            }
        }
    };
    useEffect(() => {
        setStripeAccount();
    }, []);
    return (React.createElement("div", { className: "App" },
        React.createElement(StripeProvider, { stripe: stripe },
            React.createElement(Provider, { store: store },
                React.createElement(EnoticeTheme, null,
                    React.createElement(Router, null)),
                React.createElement(Header, null)))));
};
export default App;
