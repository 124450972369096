var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { getFirebaseContext } from 'utils/firebase';
import { getOrCreateCustomer } from 'lib/notice/customer';
import { getNoticeNumberAndCustomIdFromNewspaper, getShouldAutoGenerateOrderNumsForNotice } from 'lib/helpers';
import { Firestore } from 'EnoticeFirebase';
const ConfirmReceiptModal = ({ notice, setOpen, newspaper, user }) => {
    const [confirmed, setConfirmed] = useState(false);
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        setConfirmed(true);
        yield new Promise(resolve => setTimeout(resolve, 2000));
        const updates = {
            confirmedReceipt: true,
            confirmedBy: user.ref,
            confirmedReceiptTime: Firestore.FieldValue.serverTimestamp()
        };
        const parent = yield ((_b = (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.get());
        const shouldAutoGenOrderNums = getShouldAutoGenerateOrderNumsForNotice(newspaper, parent, notice);
        if (shouldAutoGenOrderNums) {
            const orgToUpdate = ((_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.shouldAutoGenOrderNums)
                ? newspaper
                : parent;
            const { customId, currentNumber } = getNoticeNumberAndCustomIdFromNewspaper(orgToUpdate);
            updates.customId = customId;
            yield (orgToUpdate === null || orgToUpdate === void 0 ? void 0 : orgToUpdate.ref.update({
                numberOfOrders: currentNumber + 1
            }));
        }
        yield notice.ref.update(updates);
        const { userId } = notice.data();
        const ctx = getFirebaseContext();
        const filer = yield ctx.usersRef().doc(userId).get();
        const customer = yield getOrCreateCustomer(ctx, filer, newspaper);
        yield customer.ref.update({
            verified: true
        });
        setConfirmed(false);
        setOpen(false);
    });
    return (React.createElement(FreeformCModal, { header: "Confirm Notice", body: `Once confirmed, you may still edit or cancel the notice prior to
      publication. By clicking Confirm, you acknowledge that${' '}
      ${newspaper.data().name} will:`, setOpen: setOpen, noExitOutsideModal: true },
        React.createElement("section", null,
            React.createElement("div", { className: "mt-4 ml-3 md:ml-5", id: "column-notice-conditions" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("input", { id: "dates_shown", type: "checkbox", disabled: true, checked: true, className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out" }),
                    React.createElement("label", { htmlFor: "dates_shown", "aria-controls": "dates_shown", className: "ml-3 block text-sm leading-5 text-gray-700" }, "Publish this notice on the date(s) shown.")),
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("input", { id: "issue_invoice", type: "checkbox", disabled: true, checked: true, className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out" }),
                    React.createElement("label", { htmlFor: "issue_invoice", "aria-controls": "issue_invoice", className: "ml-3 block text-sm leading-5 text-gray-700" }, "Issue an invoice for this notice through column.us.")),
                !newspaper.data().affidavitDisabled && (React.createElement("div", { className: "flex items-center" },
                    React.createElement("input", { id: "issue_invoice", type: "checkbox", disabled: true, checked: true, className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out" }),
                    React.createElement("label", { htmlFor: "issue_invoice", "aria-controls": "issue_invoice", className: "ml-3 block text-sm leading-5 text-gray-700" }, "Upload the affidavit of publication to column.us.")))),
            React.createElement("p", { className: "mt-2 text-sm leading-5 text-gray-700" }, "Upon clicking Confirm, the customer will receive a notification."),
            React.createElement("div", { className: "flex justify-center md:justify-start" },
                React.createElement("button", { type: "button", id: "confirm-receipt", className: "bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-full md:w-32", onClick: handleConfirm },
                    confirmed && (React.createElement("div", { className: "loader ease-linear m-auto rounded-full border-4 text-center border-t-4 border-blue-500 h-6 w-6" })),
                    !confirmed && `Confirm`)))));
};
export default ConfirmReceiptModal;
