import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const CreateInvitesResult = ({ emailsLinkedToNotices, alreadyHaveInvitesToCurrentOrg, alreadyLinkedToIncompatibleOrg, alreadyInvitedToIncompatibleOrg, alreadyMembersOfInvitedOrg, setInviteFormHeader, backClick }) => {
    useEffect(() => {
        if (setInviteFormHeader) {
            const headerText = alreadyHaveInvitesToCurrentOrg.length ||
                emailsLinkedToNotices ||
                alreadyLinkedToIncompatibleOrg.length ||
                alreadyMembersOfInvitedOrg ||
                alreadyInvitedToIncompatibleOrg.length
                ? 'Oops! The invitation was not sent.'
                : 'Add users to your organization?';
            setInviteFormHeader(headerText);
        }
    }, [setInviteFormHeader]);
    const { t } = useTranslation();
    const handleClick = () => {
        if (setInviteFormHeader)
            setInviteFormHeader('Invite members to your organization');
        backClick();
    };
    const haveInvitesToCurrentOrg = alreadyHaveInvitesToCurrentOrg.length;
    console.log(alreadyHaveInvitesToCurrentOrg);
    const usersLinkedToIncompatibleOrg = alreadyLinkedToIncompatibleOrg.length;
    const usersInvitedToIncompatibleOrg = alreadyInvitedToIncompatibleOrg.length;
    const usersMembersOfCurrentOrg = alreadyMembersOfInvitedOrg.length;
    const showTryAgain = !!(emailsLinkedToNotices ||
        haveInvitesToCurrentOrg ||
        usersLinkedToIncompatibleOrg ||
        usersInvitedToIncompatibleOrg ||
        usersMembersOfCurrentOrg);
    const renderEmailsRow = (emails) => {
        return emails.map((email, i) => (React.createElement("div", { key: i },
            React.createElement("text", null, `\u2022`),
            React.createElement("text", { className: "pl-5" }, email))));
    };
    /**
     * RESULT MODAL ORDER:
     * 1. User is already in an incompatible org
     * 2. User has already been invited to an incompatible org
     * 3. User has already placed notices and is not an individual
     * 4. User already belongs to this org
     * 5. User has already been invited to this org
     */
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "font-regular text-base text-gray-700 mb-8" },
            React.createElement(React.Fragment, null, usersLinkedToIncompatibleOrg ? (React.createElement(React.Fragment, null,
                `It looks like the following users already belong to an
              ${t('create-invites.result.organization')} on Column:`,
                ' ',
                React.createElement("br", null),
                React.createElement("br", null),
                renderEmailsRow(alreadyLinkedToIncompatibleOrg),
                React.createElement("br", null),
                "Please try inviting a different user or contact help@column.us for assistance.")) : usersInvitedToIncompatibleOrg ? (`${alreadyInvitedToIncompatibleOrg.join(', ')} ${alreadyInvitedToIncompatibleOrg.length > 1 ? 'have' : 'has'} already been invited to another ${t('create-invites.result.organization')}.`) : emailsLinkedToNotices ? (React.createElement(React.Fragment, null, `It looks like the user(s) you selected have already placed notices
              on Column. If you would like to add them to your ${t('create-invites.result.organization')},
              please contact help@column.us for assistance.`)) : usersMembersOfCurrentOrg ? (`${alreadyMembersOfInvitedOrg.join(', ')} already ${alreadyMembersOfInvitedOrg.length > 1 ? 'belong' : 'belongs'} to this ${t('create-invites.result.organization')}.`) : (`${alreadyHaveInvitesToCurrentOrg.join(', ')} ${alreadyHaveInvitesToCurrentOrg.length > 1 ? 'have' : 'has'} already been invited.`))),
        React.createElement(React.Fragment, null, showTryAgain ? (React.createElement("button", { className: `rounded-md font-semibold bg-blue-100 text-blue-500 text-sm flex items-center px-5 py-2`, type: "button", onClick: handleClick }, "Try Again")) : (React.createElement(React.Fragment, null)))));
};
export default CreateInvitesResult;
