import { NoticeType } from 'lib/enums';
export const DEPRECATED_TYPEFORM_KEY = 'yrNuK9';
export const shouldLoadTypeform = (placement, newspaper, notice, selectedNoticeType) => {
    var _a, _b, _c, _d, _e;
    if (placement.editing)
        return false;
    if (!selectedNoticeType)
        return false;
    // if editing display ad notice and newspaper has allowed notices
    if (((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) &&
        ((_c = (_b = placement === null || placement === void 0 ? void 0 : placement.draftSnap) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.noticeType) === NoticeType.display_ad.value)
        return false;
    // only load typeform if we have allowed notices set
    if (!((_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.allowedNotices))
        return false;
    // if we are a custom value, don't go through typeform
    if (selectedNoticeType.value === NoticeType.custom.value)
        return false;
    // if we don't have typeform set, don't go through typeform
    if (!selectedNoticeType.typeform)
        return false;
    // don't show the default typeform anymore! This has been deprecated
    if (selectedNoticeType.typeform === DEPRECATED_TYPEFORM_KEY)
        return false;
    // don't go through typeform if the notice has already been submitted
    if ((_e = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _e === void 0 ? void 0 : _e.noticeStatus)
        return false;
    // if we didn't change the notice type, and the text is set, don't go
    // through typeform again
    if (placement.confirmedHtml &&
        String(placement.previousNoticeType) === String(selectedNoticeType.value))
        return false;
    // otherwise return true
    return true;
};
