import classnames from 'classnames';
import React from 'react';
import MilestoneConnector from './MilestoneConnector';
import MilestoneNode from './MilestoneNode';
function MilestoneTracker({ milestones }) {
    function buildMilestoneElement(milestoneElement, index) {
        const isFirst = index === 0;
        const isLast = !milestoneElement.nextConnector;
        return (React.createElement("div", { className: classnames('flex flex-col flex-wrap h-20 lg:h-12', {
                'flex-grow': !isLast
            }), key: index },
            React.createElement(MilestoneNode, { milestoneElement: milestoneElement, isFirst: isFirst, isLast: isLast }),
            milestoneElement.nextConnector && (React.createElement(MilestoneConnector, { status: milestoneElement.nextConnector }))));
    }
    return (React.createElement("div", { className: "flex flex-row px-7 h-20 lg:h-12" }, milestones.map((milestone, index) => buildMilestoneElement(milestone, index))));
}
export default MilestoneTracker;
