var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { GovernmentType, State } from 'lib/enums';
import SubmitButton from '../../../components/SubmitButton';
import SelectDropdown from '../../placeScroll/SelectDropdown';
import SettingsHeader from '../SettingsHeader';
import * as validators from '../../register/organization/validators';
const GovernmentSettingsInfo = ({ activeOrganization }) => {
    const [govtData, setGovtData] = useState({});
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isZipValid, setIsZipValid] = useState(true);
    const [state, setState] = useState(activeOrganization.data().state);
    const [govtType, setGovtType] = useState(activeOrganization.data().governmentType);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const updateField = (field) => (e) => {
        if (field === 'zipCode' && !validators.zip(e.target.value))
            return;
        setGovtData(Object.assign(Object.assign({}, govtData), { [field]: e.target.value || '' }));
    };
    useEffect(() => {
        if (govtData.phone && !/[0-9]/.test(govtData.phone))
            setGovtData(Object.assign(Object.assign({}, govtData), { phone: '' }));
        setIsPhoneValid(govtData.phone ? !govtData.phone.includes('_') : false);
    }, [govtData.phone]);
    useEffect(() => {
        var _a;
        const zipRegex = /[0-9]{5}/;
        if (govtData.zipCode) {
            setIsZipValid(zipRegex.test(govtData.zipCode));
        }
        else if (((_a = govtData.zipCode) === null || _a === void 0 ? void 0 : _a.length) <= 0) {
            setIsZipValid(false);
        }
        else
            setIsZipValid(true);
    }, [govtData.zipCode]);
    useEffect(() => {
        setGovtData(activeOrganization.data());
    }, [activeOrganization]);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!state) {
            setError('Select state to continue');
            return;
        }
        if (!govtType) {
            setError('Select government type or position to continue');
            return;
        }
        setLoading(true);
        try {
            yield activeOrganization.ref.update(Object.assign(Object.assign({}, govtData), { state, governmentType: govtType }));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            setError(error);
        }
    });
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                e.preventDefault();
                yield handleSubmit();
            }), className: "flex flex-col w-full" },
            React.createElement(SettingsHeader, { header: "Government Info", description: "Tell us a bit more about your organization." }),
            React.createElement("div", { className: "px-4 py-5 sm:p-6" },
                React.createElement("div", { className: "flex justify-center items-center mt-2" },
                    React.createElement("input", { className: "appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "name", required: true, autoComplete: "none", value: govtData.name, onChange: updateField('name'), placeholder: "Name of government *" })),
                React.createElement("div", { className: "flex justify-center mt-4" },
                    React.createElement("input", { className: "relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "address", required: true, autoComplete: "none", value: govtData.address, onChange: updateField('address'), placeholder: "Address *" }),
                    React.createElement("input", { className: "relative rounded block w-6/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "city", required: true, autoComplete: "none", value: govtData.city, onChange: updateField('city'), placeholder: "City *" })),
                React.createElement("div", { className: "flex justify-center mt-4" },
                    React.createElement("input", { className: "relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "address2", autoComplete: "none", value: govtData.addressLine2, onChange: updateField('addressLine2'), placeholder: "Address Line 2" }),
                    React.createElement("div", { className: "w-6/12" },
                        React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                                if (selected) {
                                    setState(selected.id);
                                }
                            }, placeholder: "State *", options: State.items().map((state) => ({
                                id: state.value,
                                label: state.label
                            })), selected: State.items().filter(item => {
                                return item.value === state
                                    ? { id: item.value, label: item.label }
                                    : '';
                            }), value: State.items().filter(item => {
                                return item.value === state
                                    ? { id: item.value, label: item.label }
                                    : '';
                            }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true }))),
                React.createElement("div", { className: "flex justify-center mt-4" },
                    React.createElement("input", { className: `relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none ${isZipValid
                            ? 'focus:shadow-outline-blue focus:border-blue-300'
                            : 'border-red-400'} focus:z-10 sm:text-sm sm:leading-5`, id: "zipCode", required: true, name: "zipCode", autoComplete: "", value: govtData.zipCode, placeholder: "Zip code *", onChange: updateField('zipCode') }),
                    React.createElement("div", { className: "w-6/12" },
                        React.createElement(InputMask, { mask: "(999) 999-9999", className: `appearance-none rounded w-full relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 
            ${isPhoneValid
                                ? 'focus:shadow-outline-blue focus:border-blue-300'
                                : 'border-red-400'}
            `, id: "phone", required: true, autoComplete: "none", name: "phone", value: govtData.phone, onChange: updateField('phone'), placeholder: "Phone *" }))),
                React.createElement("div", { className: "flex justify-center w-full" },
                    React.createElement("p", { className: "text-red-500 text-xs w-6/12" }, !isZipValid && 'Zipcode should be a 5 digit code.'),
                    React.createElement("p", { className: "text-red-500 text-xs w-6/12 ml-3" }, !isPhoneValid && 'Phone number must be 10 digits.')),
                React.createElement("div", { className: "flex justify-center mt-4" },
                    React.createElement(SelectDropdown, { id: "governmentType", onChange: (selected) => {
                            if (selected) {
                                setGovtType(selected.id);
                            }
                        }, placeholder: "Government Type or Position *", options: GovernmentType.items().map((govtType) => ({
                            id: govtType.value,
                            label: govtType.label
                        })), selected: GovernmentType.items().filter(item => {
                            return item.value === govtType
                                ? { id: item.value, label: item.label }
                                : '';
                        }), value: GovernmentType.items().filter(item => {
                            return item.value === govtType
                                ? { id: item.value, label: item.label }
                                : '';
                        }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true }))),
            React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
                React.createElement(SubmitButton, { disabled: loading, loading: loading, text: "Save", type: "submit", id: "infoSave" })))));
};
export default GovernmentSettingsInfo;
