import { InvoiceStatus, NoticeStatusType, OccupationType, RoleType } from 'lib/enums';
import { exists } from 'lib/types';
import { canPublisherEditNoticeWithoutSupport, canAdvertiserEditNoticeWithoutSupport } from 'lib/helpers';
import { roleHasPermission } from 'lib/permissions/roles';
export const getRole = (user) => {
    const { occupation, activeOrganization, roles } = user.data();
    try {
        // Individuals always have admin permissions for their own account
        if (occupation === OccupationType.individual.value) {
            return RoleType.admin.value;
        }
        if (!activeOrganization) {
            throw new Error(`Cannot get role for user without activeOrganization set ${user.id}`);
        }
        if (!roles) {
            throw new Error(`Cannot get role for user without roles set ${user.id}`);
        }
        const permissionsForOrg = roles[activeOrganization.id];
        if (permissionsForOrg) {
            return permissionsForOrg;
        }
        throw new Error(`Unable to get role for user ${user.id} in organization ${activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id}`);
    }
    catch (err) {
        console.error(err);
        return 0;
    }
};
export const userHasPermission = (user, permission) => {
    const role = getRole(user);
    return roleHasPermission(role, permission);
};
export const userIsSuper = (user) => {
    return getRole(user) === RoleType.super.value;
};
export const userIsAdmin = (user) => {
    return getRole(user) === RoleType.admin.value;
};
export const userIsBilling = (user) => {
    return getRole(user) === RoleType.billing.value;
};
export const canEditNoticeWithoutSupport = (noticeSnap, userSnap, newspaperSnap) => {
    const role = getRole(userSnap);
    if (role === RoleType.super.value)
        return true;
    if (noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    if (userSnap.data().occupation === OccupationType.publishing.value)
        return canPublisherEditNoticeWithoutSupport(noticeSnap);
    return (exists(newspaperSnap) &&
        canAdvertiserEditNoticeWithoutSupport(noticeSnap, newspaperSnap));
};
export const canCancelInvoiceWithoutSupport = (invoice, notice) => {
    const noticeTransfer = notice === null || notice === void 0 ? void 0 : notice.data().transfer;
    if (noticeTransfer) {
        return false;
    }
    return ((invoice === null || invoice === void 0 ? void 0 : invoice.data()) &&
        (invoice.data().status === InvoiceStatus.unpaid.value ||
            invoice.data().status === InvoiceStatus.payment_failed.value ||
            invoice.data().invoiceOutsideColumn ||
            !invoice.data().isWithinBulkInvoice));
};
