import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Box, Tooltip, Typography } from '@material-ui/core';
import SavedPaymentMethodButton from './buttons/SavedPaymentMethodButton';
import LinkBankAccountButton from './buttons/LinkBankAccountButton';
const styles = () => createStyles({
    gotoText: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#697386' // gives the go to my text a dark gray color
    }
});
function SavedPaymentMethods({ payInvoiceData, userPaymentMethods, savedPaymentsAccess, user, selectedSavedMethodId, selectPaymentClickHandler, setShowLoginModal, classes }) {
    const { disableSavedCards, disableACH, activeOrganizationMismatch } = savedPaymentsAccess;
    const { billingName, preventLatePayment } = payInvoiceData;
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: disableACH
                ? `Linked bank accounts can only be used to pay for notices within your organization.`
                : '' },
            React.createElement(Box, { style: !disableACH
                    ? {
                        opacity: '100%',
                        cursor: 'pointer',
                        marginTop: '8px'
                    }
                    : {
                        opacity: '35%',
                        cursor: 'not-allowed',
                        marginTop: '8px'
                    } }, !disableACH &&
                (userPaymentMethods === null || userPaymentMethods === void 0 ? void 0 : userPaymentMethods.bankAccounts.map((bankAccount, index) => bankAccount.status === 'verified' && (React.createElement(SavedPaymentMethodButton, { paymentMethodBrand: bankAccount.bank_name, paymentMethodId: bankAccount.id, paymentMethodLast4: bankAccount.last4, paymentMethodType: 'saved-ach', isSelected: bankAccount.id === selectedSavedMethodId, key: index, clickHandler: selectPaymentClickHandler })))))),
        (!userPaymentMethods || userPaymentMethods.bankAccounts.length === 0) &&
            !disableACH && (React.createElement(LinkBankAccountButton, { setShowLoginModal: setShowLoginModal, disabled: preventLatePayment, isLoggedIn: !!user })),
        React.createElement(Box, { style: {
                opacity: '100%',
                cursor: 'pointer',
                marginTop: '8px'
            } }, !disableACH &&
            (userPaymentMethods === null || userPaymentMethods === void 0 ? void 0 : userPaymentMethods.bankAccountsPaymentMethods.map((bankAccount, index) => (React.createElement(SavedPaymentMethodButton, { paymentMethodBrand: bankAccount.us_bank_account.bank_name, paymentMethodId: bankAccount.id, paymentMethodLast4: bankAccount.us_bank_account.last4, paymentMethodType: 'saved-bank', isSelected: bankAccount.id === selectedSavedMethodId, key: index, clickHandler: selectPaymentClickHandler }))))),
        React.createElement(Box, { style: { opacity: '100%', cursor: 'pointer', marginTop: '8px' } }, !disableSavedCards &&
            (userPaymentMethods === null || userPaymentMethods === void 0 ? void 0 : userPaymentMethods.cards.map((creditCard, index) => (React.createElement(SavedPaymentMethodButton, { paymentMethodBrand: creditCard.card.brand, paymentMethodId: creditCard.id, paymentMethodLast4: creditCard.card.last4, paymentMethodType: 'saved-card', isSelected: creditCard.id === selectedSavedMethodId, key: index, clickHandler: selectPaymentClickHandler }))))),
        !disableACH && activeOrganizationMismatch && (React.createElement(Box, { mt: 2, mx: 2, className: "text-center" },
            React.createElement(Typography, { variant: "caption", align: "center", color: "textSecondary" },
                React.createElement("span", null,
                    "Don't see your bank accounts? Log into \"",
                    billingName,
                    "\" to pay via ACH.")))),
        user && (React.createElement(Box, { className: classes.gotoText, mt: 1 },
            "Go to my",
            React.createElement("span", { style: { color: '#5469D4', cursor: 'pointer' }, onClick: () => window.open(`${window.location.origin}/settings/?tab=bank-accounts`) }, ` Payment Methods.`)))));
}
export default withStyles(styles)(SavedPaymentMethods);
