import { takeEvery, all } from 'redux-saga/effects';
import { logAndCaptureException } from 'utils';
import { ENV, DEMO, PROD } from '../constants';
import { ErrorTypes } from '../redux/errors';
export function* handleError(action) {
    var _a;
    if (ENV === DEMO || ENV === PROD) {
        logAndCaptureException(action.data.error, 'error', Object.assign({ env: ENV }, ((_a = action.data.tags) !== null && _a !== void 0 ? _a : {})));
    }
    yield true;
}
export default function* root() {
    return yield all([
        takeEvery(ErrorTypes.SET_ERROR, action => handleError(action))
    ]);
}
