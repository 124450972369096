import React from 'react';
import { FilledCircleIcon, XIcon } from 'icons';
const EToast = ({ message, type, close, children }) => {
    const styles = {
        success: {
            text: 'text-white ',
            background: 'bg-gray-800',
            close: 'text-white focus:text-gray-500'
        },
        error: {
            text: 'text-red-700',
            background: 'bg-red-200 border border-red-500',
            close: 'text-red-700 focus:text-red-500'
        }
    }[type];
    return (React.createElement("div", { className: "z-100 fixed inset-0 flex px-4 py-6 pointer-events-none sm:p-6 items-end justify-start" },
        React.createElement("div", { className: `${styles.background} max-w-lg rounded shadow-xs pointer-events-auto` },
            React.createElement("div", { className: "rounded shadow-xs overflow-hidden" },
                React.createElement("div", { className: "p-4" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "flex-1 flex justify-between items-center" },
                            type === 'success' && (React.createElement(FilledCircleIcon, { className: "mx-2 text-green-500" })),
                            React.createElement("p", { className: `${styles.text} flex-1 text-sm leading-5 font-medium font-medium text-sm pl-3` }, message || children)),
                        React.createElement("div", { className: "ml-4 flex-shrink-0 flex" },
                            React.createElement("button", { id: "close-toast", type: "button", className: `${styles.close} inline-flex focus:outline-none transition ease-in-out duration-150`, onClick: () => close() },
                                React.createElement(XIcon, { className: "h-5 w-5" })))))))));
};
export default EToast;
