export const customerValidationState = {
    PUBLISHER_CUSTOMER_FOUND: 1,
    NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN: 2,
    INVALID_PUBLISHER_CUSTOMER_ID: 3,
    INVALID_PUBLISHER_CUSTOMER_INFO: 4
};
export const customerFetchMethods = {
    FROM_CUSTOMER_INFO: 'FROM_CUSTOMER_INFO',
    FROM_ACCOUNT_NUMBER: 'FROM_ACCOUNT_NUMBER',
    ALREADY_VERIFIED: 'ALREADY_VERIFIED'
};
