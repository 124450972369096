import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import PercentFormatter from '../../../components/PercentFormatter';
const InvoiceFormTotalDueTable = ({ totalDue, taxPercent, handleTaxPercentChange, subtotal, belowMinimum, rateMinimum, currency }) => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-white shadow overflow-hidden sm:rounded-lg" },
            React.createElement("div", { className: "px-4 py-5 sm:p-0" },
                React.createElement("dl", null,
                    React.createElement("div", { className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Subtotal"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            React.createElement(NumberFormat, { value: subtotal || `${currency}0.00`, thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: "text" }))),
                    belowMinimum && (React.createElement("div", { className: "sm:px-6 sm:pb-5 sm:-mt-2 text-xs text-red-600" }, `This subtotal is below your minimum of ${currency}${rateMinimum}. Adjust line item
                  pricing to reach minimum.`)),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Tax"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            React.createElement(TextField, { className: "text-sm", label: "", value: taxPercent || '0.00', onChange: e => handleTaxPercentChange(e), id: "formatted-numberformat-input", InputProps: {
                                    style: {
                                        fontSize: '14px',
                                        width: '50px',
                                        textAlign: 'right'
                                    },
                                    inputComponent: PercentFormatter
                                } }),
                            ' ')),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Total Due"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" },
                            React.createElement(NumberFormat, { placeholder: `${currency}0.00`, value: totalDue || `${currency}0.00`, thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: "text" }),
                            ' ')))))));
};
export default InvoiceFormTotalDueTable;
