var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { getLocationParams } from 'utils/urls';
import Firebase, { Collections } from 'EnoticeFirebase';
import { Lock } from 'routes/settings/icons';
import { LightBulb, BrandingIcon, DashboardIcon, Clipboard } from './icons';
import BrandingSettings from './brandingSettings/BrandingSettings';
import CampaignDashboard from './CampaignDashboard';
import SharingSettings from './SharingSettings';
import LegalSettings from './LegalSettings';
export const CUSTOM_BRANDING = 'custom-branding';
export const DASHBOARD = 'dashboard';
export const INFORMATION = 'information';
export const LEGAL = 'legal';
const CampaignSettings = ({ user, activeOrganization }) => {
    const [view, setView] = useState(getLocationParams().get('tab') || CUSTOM_BRANDING);
    const [donationProfile, setDonationProfile] = useState();
    useEffect(() => {
        if (activeOrganization) {
            const unsub = Firebase.firestore()
                .collection(Collections.donationProfiles)
                .where('organization', '==', activeOrganization.ref)
                .onSnapshot((querySnap) => __awaiter(void 0, void 0, void 0, function* () {
                if (querySnap.docs.length) {
                    const profile = querySnap
                        .docs[0];
                    if (exists(profile)) {
                        setDonationProfile(profile);
                    }
                }
                else {
                    yield Firebase.firestore()
                        .collection(Collections.donationProfiles)
                        .add({
                        organization: activeOrganization.ref,
                        name: activeOrganization.data().name,
                        city: activeOrganization.data().city,
                        state: activeOrganization.data().state
                    });
                }
            }));
            return unsub;
        }
    }, [activeOrganization]);
    const updateView = (view) => {
        window.history.replaceState(null, '', `/donation-campaign/?tab=${view}`);
        setView(view);
    };
    const renderTab = (tabName, tabLabel, icon) => (React.createElement("div", { className: `flex rounded font-medium text-base leading-5 text-gray-600 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tabName && 'bg-gray-200'}`, onClick: () => updateView(tabName) },
        React.createElement("div", { className: "flex justify-center w-3/12" }, icon),
        React.createElement("div", { className: "w-9/12" }, tabLabel)));
    const renderSideBar = () => (React.createElement("div", { className: "bg-white rounded border border-gray-300 min-h-screen pt-3 px-4" },
        renderTab(CUSTOM_BRANDING, 'Campaign', BrandingIcon),
        renderTab(DASHBOARD, 'Dashboard', DashboardIcon),
        renderTab(INFORMATION, 'Marketing Tools', LightBulb),
        renderTab(LEGAL, 'Partnership Documents', Clipboard)));
    if (!donationProfile)
        return React.createElement(LoadingState, null);
    const hasLegalDocs = donationProfile.data().legalDocs &&
        donationProfile.data().legalDocs.length > 1 &&
        donationProfile.data().legalDocs[0].url &&
        donationProfile.data().legalDocs[1].url;
    return (React.createElement("div", { className: "flex" },
        renderSideBar(),
        React.createElement("main", { className: "flex-grow pt-0 p-4" },
            !hasLegalDocs && view !== LEGAL && (React.createElement("div", { className: "flex justify-between items-center h-16 bg-blue-600 bg-opacity-25 mb-2" },
                React.createElement("div", { className: "flex items-center h-full" },
                    React.createElement("div", { className: "border-4 h-full border-blue-650 rotate-90 mr-3" }),
                    Lock,
                    React.createElement("div", { className: "font-normal text-base text-blue-650 ml-3 mr-1" }, "Before you set up your campaign, you\u2019ll need to sign legal agreements."),
                    React.createElement("div", { className: "font-medium text-base text-blue-650 underline cursor-pointer", onClick: () => {
                            updateView(LEGAL);
                        } }, "Review and sign here.")))),
            React.createElement("div", { className: `${!hasLegalDocs &&
                    view !== LEGAL &&
                    'opacity-50 cursor-not-allowed pointer-events-none'}` }, {
                [CUSTOM_BRANDING]: (React.createElement(BrandingSettings, Object.assign({}, { activeOrganization, user, donationProfile }))),
                [DASHBOARD]: (React.createElement(CampaignDashboard, { donationProfile: donationProfile })),
                [INFORMATION]: (React.createElement(SharingSettings, { activeOrganization: activeOrganization, donationProfile: donationProfile })),
                [LEGAL]: (React.createElement(LegalSettings, Object.assign({}, { donationProfile, activeOrganization, updateView })))
            }[view]))));
};
export default CampaignSettings;
