import { createStyles, withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import Validator from 'validator';
import { AddIcon, DeleteIcon } from 'icons';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { getInviteRoleOptions } from 'lib/utils/invites';
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);
const styles = (theme) => createStyles({
    icon: {
        color: 'white'
    },
    removeButton: {
        color: theme.palette.secondary.main
    },
    addMoreButton: {
        marginRight: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    },
    submitError: {
        marginTop: theme.spacing(2)
    },
    heading: {
        margin: theme.spacing(1)
    },
    form: {
        width: '100%'
    },
    emailInput: {
        marginLeft: 0,
        width: '90%'
    },
    roleInput: {
        width: '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    infoIcon: {
        color: theme.palette.primary.main
    },
    iconButton: {
        marginTop: theme.spacing(1)
    },
    row: {
        marginBottom: theme.spacing(2)
    },
    formContainer: {
        marginTop: theme.spacing(1)
    },
    bottomRow: {
        marginTop: theme.spacing(2)
    }
});
const InfoTooltip = () => {
    return (React.createElement(HtmlTooltip, { placement: "top", title: React.createElement(React.Fragment, null,
            'Roles determine which parts of your Column organization members can edit. If you are unsure, choose',
            ' ',
            React.createElement("b", null, "User"),
            ".") },
        React.createElement("svg", { className: "h-5 w-5" },
            React.createElement("path", { d: "M10.375 13H9.5V9.5H8.625M9.5 6H9.50875M17.375 9.5C17.375 10.5342 17.1713 11.5582 16.7756 12.5136C16.3798 13.4691 15.7997 14.3372 15.0685 15.0685C14.3372 15.7997 13.4691 16.3798 12.5136 16.7756C11.5582 17.1713 10.5342 17.375 9.5 17.375C8.46584 17.375 7.44181 17.1713 6.48637 16.7756C5.53093 16.3798 4.6628 15.7997 3.93153 15.0685C3.20027 14.3372 2.6202 13.4691 2.22445 12.5136C1.82869 11.5582 1.625 10.5342 1.625 9.5C1.625 7.41142 2.45469 5.40838 3.93153 3.93153C5.40838 2.45469 7.41142 1.625 9.5 1.625C11.5886 1.625 13.5916 2.45469 15.0685 3.93153C16.5453 5.40838 17.375 7.41142 17.375 9.5Z", stroke: "#4285F4", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))));
};
const CreateInvitesForm = ({ loading, handleSubmit, removeClick, handleChange, handleRoleSelect, addClick, values, engagedWithForm, onSkipForNowClick, organization }) => {
    const [isEmailValid, setIsEmailValid] = useState([]);
    const [errors, setErrors] = useState();
    const DUPLICATE_EMAIL_ERROR = 'Do not enter duplicate emails.';
    const orgIsPublisher = isPublisherOrganization(organization);
    useEffect(() => {
        setIsEmailValid(values.map(val => (val.email ? Validator.isEmail(val.email) : true)));
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        setErrors(values.map(_ => ''));
    }, [values]);
    return (React.createElement("form", { className: "w-full", onSubmit: (e) => {
            e.preventDefault();
            const emails = values.map(val => { var _a; return (_a = val.email) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
            const errorsFound = values.map((v, i) => {
                if (!isEmailValid[i])
                    return 'Email is invalid';
                if (new Set(emails).size !== emails.length)
                    return DUPLICATE_EMAIL_ERROR;
                return '';
            });
            if (!errorsFound.every(e => !e)) {
                setErrors(errorsFound);
                return;
            }
            handleSubmit(e);
        } },
        React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement("div", { className: "font-regular text-large text-gray-700 mb-3 -mt-1" },
                "If additional colleagues should have permission to manage",
                ' ',
                organization.data().name,
                "'s account on Column, please invite them here."),
            values.map((el, i) => (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { maxHeight: 45 }, key: i, className: "flex w-full gap-x-4" },
                    React.createElement("input", { required: true, autoComplete: "none", id: "email", placeholder: "Email *", name: "email", value: el.email || '', onChange: e => handleChange(i, e), disabled: loading, className: `form-input w-7/12 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${!isEmailValid[i] && 'border-red-500 text-xs'}` }),
                    React.createElement("select", { className: `form-select w-4/12 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${!el.role && 'text-gray-500'}`, value: el.role || '', id: "select-role", onChange: e => handleRoleSelect(i, e), required: true },
                        React.createElement("option", { value: "", disabled: true, hidden: true }, "Role *"),
                        getInviteRoleOptions(orgIsPublisher).map(role => {
                            return (React.createElement("option", { key: role.value, value: role.value }, role.label));
                        })),
                    React.createElement("div", { className: "flex items-center justify-center w-1/12" }, i === 0 ? (React.createElement(InfoTooltip, null)) : (React.createElement("button", { type: "button", onClick: () => removeClick(i), disabled: loading },
                        React.createElement(DeleteIcon, { className: "text-gray-750" }))))),
                errors && errors[i] && (React.createElement("div", { key: `i-error`, className: "flex w-full justify-start text-red-500" },
                    React.createElement("div", { className: "flex w-7/12 justify-center" }, (errors[i] !== DUPLICATE_EMAIL_ERROR ||
                        (errors[i] === DUPLICATE_EMAIL_ERROR &&
                            i === errors.length - 1)) &&
                        errors[i]))))))),
        React.createElement("div", { className: "flex items-center mt-2" },
            React.createElement("button", { type: "button", onClick: () => addClick() },
                React.createElement(AddIcon, { className: "h-5 w-5 text-indigo-400" })),
            React.createElement("div", { className: "ml-2 cursor-pointer not-italic font-medium text-sm flex items-center text-indigo-400", onClick: () => addClick() }, "Invite Additional User")),
        React.createElement("div", { className: "flex mt-4" },
            React.createElement("button", { type: "submit", className: "rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-650 text-sm flex items-center px-8 py-2", id: "confirm-invite", disabled: loading },
                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                "Confirm"),
            onSkipForNowClick && (React.createElement("button", { id: "submit", type: "submit", className: "text-blue-650 ml-3 font-semibold", onClick: () => onSkipForNowClick() }, engagedWithForm ? 'Done' : 'Skip for now')))));
};
export default withStyles(styles, { withTheme: true })(CreateInvitesForm);
