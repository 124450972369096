import React from 'react';
import { SearchIcon } from 'icons';
import { TextField } from 'components/TextField';
import AffidavitUploadFilterDialog from './AffidavitUploadFilterDialog';
import MoreAffidavitActions from './MoreAffidavitActions';
/*
 * Displays the header for the Affidavit upload events page
 */
export default function AutomatedAffidavitSettingsHeader({ setShowEEditionUploadModal, setShowVerifyNoticesPublicationModal, affidavituploadDays, activeOrganization, setSuccessMessage, setUploadFilter, uploadFilter }) {
    return (React.createElement("div", { className: "flex items-center pl-7 pr-3 py-5 border-b border-gray-300", id: "automated-affidavit-settings-header" },
        React.createElement("div", { className: "w-2/3 font-medium" },
            React.createElement("p", { className: "text-xl text-blue-800" }, "Verification Status"),
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" }, "Track your e-edition uploads and manually kick off notice verification.")),
        React.createElement("div", { className: "w-1/3" },
            React.createElement(TextField, { id: 'affidavit-files-search', labelText: '', placeholder: "Search", size: "small", value: uploadFilter.search, onChange: newValue => setUploadFilter(Object.assign(Object.assign({}, uploadFilter), { search: newValue })), prefix: React.createElement(SearchIcon, { className: "w-5 text-grey-300", "aria-hidden": "true" }) })),
        React.createElement(AffidavitUploadFilterDialog, { setUploadFilter: setUploadFilter, uploadFilter: uploadFilter }),
        React.createElement(MoreAffidavitActions, { setShowEEditionUploadModal: setShowEEditionUploadModal, setShowVerifyNoticesPublicationModal: setShowVerifyNoticesPublicationModal, activeOrganization: activeOrganization, setSuccessMessage: setSuccessMessage, uploadDays: affidavituploadDays })));
}
