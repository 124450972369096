import React from 'react';
import moment from 'moment-timezone';
import { State } from 'lib/enums';
const InvoiceFormPaperInfoTable = ({ organization }) => {
    var _a;
    const { address, name, city, state, zipCode } = organization;
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-white shadow overflow-hidden sm:rounded-lg" },
            React.createElement("div", { className: "px-4 py-5 sm:p-0" },
                React.createElement("dl", null,
                    React.createElement("div", { className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Newspaper"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, name)),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Address"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-tight text-gray-900 sm:mt-0 sm:col-span-2" },
                            address,
                            " ",
                            React.createElement("br", null),
                            " ",
                            city,
                            ' ', (_a = State.by_value(state)) === null || _a === void 0 ? void 0 :
                            _a.abbrev.toUpperCase(),
                            ", ",
                            zipCode,
                            ' ')),
                    React.createElement("div", { className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" },
                        React.createElement("dt", { className: "text-sm leading-5 font-bold text-gray-800" }, "Date"),
                        React.createElement("dd", { className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, moment(new Date(Date.now())).format('MM/DD/YYYY'))))))));
};
export default InvoiceFormPaperInfoTable;
