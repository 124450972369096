var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { useSelector } from 'react-redux';
import { placementSelector } from 'redux/placement';
import { authSelector } from 'redux/auth';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import LinkTo from 'components/LinkTo';
import { useRestrictedPlacementFlowParams } from './hooks/useRestrictedPlacementFlowParams';
import { useSyncUserSavedInfoToState } from './hooks/useSyncUserSavedInfoToState';
import ScrollStep from './ScrollStep';
import { publisherReadyToUpload } from '../../lib/publishers';
import { DisabledNewspaperModal } from './DisabledNewspaperModal';
import { ConfirmNewspaperForm } from './ConfirmNewspaperForm';
import { CONFIRM_NEWSPAPER } from './helpers';
export default function ConfirmNewspaperStep({ activeStepId, next, onDisabledStepClick, newspaper }) {
    var _a;
    const placement = useSelector(placementSelector);
    const auth = useSelector(authSelector);
    const [showDisabledNewspaperModal, setShowDisabledNewspaperModal] = useState(false);
    const customer = useFetchCustomer(placement.customer);
    const { restrictedStates, restrictedPapers, restrictedSingleState } = useRestrictedPlacementFlowParams();
    useSyncUserSavedInfoToState({ restrictedPapers, restrictedSingleState });
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const enableColumnExpressLink = getBooleanFlag(LaunchDarklyFlags.ENABLE_CANT_FIND_NEWSPAPER);
    const newspaperDisabled = exists(newspaper) && !publisherReadyToUpload(newspaper, auth.isPublisher);
    useEffect(() => {
        if (newspaperDisabled) {
            setShowDisabledNewspaperModal(true);
        }
    }, [newspaperDisabled]);
    function onNext() {
        if (newspaperDisabled) {
            return setShowDisabledNewspaperModal(true);
        }
        return next();
    }
    const isAccountOnHold = (_a = customer === null || customer === void 0 ? void 0 : customer.data()) === null || _a === void 0 ? void 0 : _a.isAccountOnHold;
    const title = enableNewPlacementFlow
        ? 'Where would you like to place a notice?'
        : 'Select Newspaper';
    const caption = enableColumnExpressLink && !restrictedSingleState && placement.original ? (React.createElement(React.Fragment, null,
        React.createElement("span", null, "Can't find your local newspaper? "),
        React.createElement(LinkTo, { className: "text-primary-500", href: `/form/newspaper/${placement.original.id}` }, "Click here to let us know."))) : null;
    const onHoldText = isAccountOnHold && exists(newspaper)
        ? `Your account is on hold. Please reach out to ${newspaper.data().name} for more information.`
        : undefined;
    const isComplete = !!(placement.filingState && newspaper) && !isAccountOnHold;
    return (React.createElement(ScrollStep, { id: CONFIRM_NEWSPAPER, activeStepId: activeStepId, next: onNext, complete: isComplete, onDisabledStepClick: onDisabledStepClick, title: title, caption: caption, onHoldText: onHoldText },
        React.createElement(ConfirmNewspaperForm, { newspaper: newspaper, restrictedStates: restrictedStates, restrictedSingleState: restrictedSingleState }),
        showDisabledNewspaperModal && (React.createElement(DisabledNewspaperModal, { newspaper: newspaper, setOpen: setShowDisabledNewspaperModal }))));
}
function useFetchCustomer(customerRef) {
    const [customer, setCustomer] = useState();
    useEffect(() => {
        const fetchCustomer = () => __awaiter(this, void 0, void 0, function* () {
            if (!customerRef) {
                return setCustomer(null);
            }
            const customerSnap = yield customerRef.get();
            setCustomer(exists(customerSnap) ? customerSnap : null);
        });
        void fetchCustomer();
    }, [customerRef === null || customerRef === void 0 ? void 0 : customerRef.id]);
    return customer;
}
