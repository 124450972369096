var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { Paper, Avatar, Grid, Typography, FormControl, InputLabel, Input, Button, Box } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { CheckCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Firebase from '../../EnoticeFirebase';
import { getLocationParams } from '../../utils/urls';
const styles = (theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: 400
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1)
    },
    submitWrapper: {
        padding: theme.spacing(1)
    },
    error: {
        marginTop: theme.spacing(2)
    },
    icon: {
        width: 200
    }
});
const ResetPassword = ({ classes, push }) => {
    const actionCode = getLocationParams().get('oobCode');
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        const pullEmail = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const email = yield Firebase.auth().verifyPasswordResetCode(actionCode);
                setEmail(email);
            }
            catch (err) {
                setError('Password reset link has expired');
            }
        });
        void pullEmail();
    }, []);
    const [password, setPassword] = useState('');
    return (React.createElement(Grid, { container: true, justify: "center", alignItems: "center" },
        React.createElement(Paper, { className: classes.paper },
            !success && (React.createElement(Avatar, { className: classes.avatar },
                React.createElement(LockOutlinedIcon, null))),
            React.createElement(Grid, { container: true, justify: "center", alignItems: "center" }, success ? (React.createElement(Box, { mt: 2, display: "flex", justifyContent: "center", alignItems: "center" },
                React.createElement(Box, { ml: -1, mr: 1, display: "flex", justifyContent: "center", alignItems: "center" },
                    React.createElement(CheckCircle, { color: "primary" })),
                React.createElement(Typography, { component: "h1", variant: "h5" }, "Success!"))) : (React.createElement(Typography, { component: "h1", variant: "h5" }, "Reset Password"))),
            success && (React.createElement(Box, { mt: 2 },
                React.createElement(Typography, { align: "center", style: {
                        fontSize: 14
                    } }, "Your password has been updated"))),
            !success && !error && (React.createElement("form", { className: classes.form, onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    e.preventDefault();
                    yield Firebase.auth().confirmPasswordReset(actionCode, password);
                    setSuccess(true);
                    yield new Promise(resolve => setTimeout(resolve, 2000));
                    yield push('/');
                }) },
                React.createElement(FormControl, { margin: "normal", required: true, fullWidth: true },
                    React.createElement(InputLabel, { htmlFor: "email" }, "Email"),
                    React.createElement(Input, { value: email, disabled: true })),
                React.createElement(FormControl, { margin: "normal", required: true, fullWidth: true },
                    React.createElement(InputLabel, { htmlFor: "email" }, "New Password"),
                    React.createElement(Input, { name: "password", type: "password", autoComplete: "password", autoFocus: true, value: password, onChange: e => setPassword(e.target.value) })),
                React.createElement(Grid, null,
                    React.createElement(Grid, { item: true, xs: 12, className: classes.submitWrapper },
                        React.createElement(Button, { variant: "contained", color: "primary", type: "submit", fullWidth: true }, "Reset Password"))))),
            error && !success && (React.createElement(Typography, { color: "error", align: "center", className: classes.error }, error)))));
};
const EmailAction = (props) => {
    const mode = getLocationParams().get('mode');
    return {
        resetPassword: React.createElement(ResetPassword, Object.assign({}, props))
    }[mode];
};
export default connect(null, { push })(withStyles(styles)(EmailAction));
