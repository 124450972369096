var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CreateUserNoticeCard from './CreateUserNoticeCard';
const styles = (theme) => createStyles({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    noticeCardContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    root: {
        marginLeft: theme.spacing(3),
        textAlign: 'center',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    input: {
        marginLeft: 8,
        flex: 1
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4
    }
});
const AddNoticeTypeScreen = ({ classes, noticesToDisplay, noticeId, draftId }) => {
    const [noticeTypes] = React.useState(noticesToDisplay || []);
    const [filteredOutNotices, setFilteredOutNotices] = React.useState([]);
    // UPDATES FILTERED NOTICES
    const searchNoticeTypes = (newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const term = newValue.toLowerCase().replace(/ /g, '');
        let filteredNotices = [];
        if (term.length > 0) {
            filteredNotices = noticeTypes.filter(notice => {
                const subNotices = notice.childItemKeys;
                const subNoticesExists = subNotices === null || subNotices === void 0 ? void 0 : subNotices.some((subNotice) => subNotice.replace('_', '').toLowerCase().includes(term));
                if (!notice.label.toLowerCase().replace(/ /g, '').includes(term) &&
                    !subNoticesExists) {
                    return notice;
                }
                return null;
            });
        }
        setFilteredOutNotices(filteredNotices);
    });
    return (React.createElement("div", { className: "grid grid-cols-12 gap-12 w-full px-6" },
        React.createElement("div", { className: "col-span-6 md:col-span-8 lg:col-span-9 text-2xl lg:text-3xl xl:text-4xl font-medium text-gray-800 mb-4" }, "What type of notice are you placing?"),
        React.createElement("div", { className: "col-span-6 md:col-span-4 lg:col-span-3 items-center flex h-12 text-center bg-white border border-gray-300 rounded-md" },
            React.createElement(IconButton, { className: "p-0", "aria-label": "Search" },
                React.createElement(SearchIcon, null)),
            React.createElement(InputBase, { onChange: newValue => searchNoticeTypes(newValue.target.value), className: classes.input, placeholder: "Search Notice Types", id: "searchNoticeTypes", inputProps: { 'aria-label': 'Search Notice Types' } })),
        noticeTypes.map(item => {
            if (!filteredOutNotices ||
                !filteredOutNotices.some(element => element.label === item.label)) {
                return (React.createElement("div", { key: item.value, className: "col-span-6 md:col-span-4" },
                    React.createElement(CreateUserNoticeCard, { noticeType: item, noticeId: noticeId, draftId: draftId })));
            }
            return null;
        })));
};
export default withStyles(styles)(AddNoticeTypeScreen);
