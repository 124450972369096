/**
 * FINALIZED_DISPLAY_AD is a single file that represents the entirety of a display notice that is to be published
 *
 * DISPLAY_AD_COMPONENT is a file that constitutes part of a display notice that must be further built before being published (these will persist on a notice's subcollection even after the ad is built and a FINALIZED_DISPLAY_AD is added to the subcollection)
 *
 * SUPPLEMENTAL_UPLOAD is a file, typically uploaded via Zapier or Madlibs, that is not part of the notice content itself, but rather used to verify the information in or the legal compliance of a notice
 *
 * TEXT_FILE is a file, e.g. a Word file, RTF, or plain text file, that is uploaded and then immediately translated into a liner notice
 */
export var NoticeFileTypes;
(function (NoticeFileTypes) {
    NoticeFileTypes["finalized_display_ad"] = "finalized display ad";
    NoticeFileTypes["display_ad_component"] = "display ad component";
    NoticeFileTypes["supplemental_upload"] = "supplemental upload";
    NoticeFileTypes["text_file"] = "text file";
})(NoticeFileTypes || (NoticeFileTypes = {}));
export const isLegacySchema = (noticeFileSnap) => {
    return !!noticeFileSnap.data().legacySchema;
};
export const isNoticeContent = (noticeFileSnap) => {
    const isValidType = [
        NoticeFileTypes.display_ad_component,
        NoticeFileTypes.finalized_display_ad,
        NoticeFileTypes.text_file
    ].includes(noticeFileSnap.data().type);
    const isNotLegacy = !isLegacySchema(noticeFileSnap);
    return isValidType && isNotLegacy;
};
export const isSupplementalUpload = (noticeFileSnap) => {
    const isValidType = noticeFileSnap.data().type === NoticeFileTypes.supplemental_upload;
    const isNotLegacy = !isLegacySchema(noticeFileSnap);
    return isValidType && isNotLegacy;
};
