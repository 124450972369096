var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import Modal from 'components/modals/FreeFormCModal';
import api from 'api';
import CButton from 'components/CButton';
import { CalendarIcon } from 'icons';
import { areSameDayUTC } from 'lib/helpers';
import { SPECIFIC_PUBLICATION_DATE, ALL_INCOMPLETE_AFFIDAVITS, AFFIDAVITS_AWAITING_PUBLICATION } from 'lib/constants/bulkAffidavitDownloadConstants';
const BulkAffidavitDownload = ({ setOpen, activeOrganization, setMessage }) => {
    const DATE_RANGE = [
        {
            value: 1,
            label: SPECIFIC_PUBLICATION_DATE,
            id: 'specific_publication_date',
            key: 'specific_publication_date'
        },
        {
            value: 2,
            label: ALL_INCOMPLETE_AFFIDAVITS,
            id: 'all_incomplete_affidavits',
            key: 'all_incomplete_affidavits'
        },
        {
            value: 3,
            label: AFFIDAVITS_AWAITING_PUBLICATION,
            id: 'affidavits_awaiting_publication',
            key: 'affidavits_awaiting_publication'
        }
    ];
    const showAllOrgsNotices = useSelector((state) => state.auth.showAllOrgsNotices);
    const [range, setRange] = useState(DATE_RANGE[0].label);
    const [relevantTimestamps, setRelevantTimestamps] = useState([]);
    const [downloadTimestamp, setDownloadTimestamp] = useState(new Date().getTime());
    const [loading, setLoading] = useState(false);
    const [loadingTimestamps, setLoadingTimestamps] = useState(true);
    const [error, setError] = useState('');
    const buttonStyles = 'border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition';
    const getAllUniquePublishingDays = () => __awaiter(void 0, void 0, void 0, function* () {
        const facets = {
            lastpublicationtimestamp: {
                type: 'value',
                name: 'dates',
                size: 100
            }
        };
        const filters = [
            { affidavitsubmitted: Number(false) },
            { iscancelled: Number(false) },
            { isdraft: Number(false) },
            { isarchived: Number(false) }
        ];
        const { facets: { lastpublicationtimestamp } } = yield api.post('search/usernotices/facets', {
            search: '',
            hasinvoice: true,
            showAllOrgsNotices,
            facets,
            filters
        });
        const relevantTimestamps = lastpublicationtimestamp[0].data
            .map((v) => Number(v.value))
            .sort();
        setDownloadTimestamp(relevantTimestamps[relevantTimestamps.length - 1]);
        setRelevantTimestamps(relevantTimestamps);
        setLoadingTimestamps(false);
    });
    useEffect(() => {
        void getAllUniquePublishingDays();
    }, []);
    const disableDate = (dateIn) => {
        if (!dateIn) {
            return false;
        }
        return relevantTimestamps.every(relevantTimestamp => !areSameDayUTC(dateIn, relevantTimestamp));
    };
    const downloadAffidavits = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const requestData = {
                date: moment.utc(downloadTimestamp).format('ddd MMM DD YYYY'),
                activeOrganizationId: showAllOrgsNotices
                    ? 'all-organizations'
                    : activeOrganization.id,
                noticeRange: range
            };
            const response = yield api.post('documents/download-bulk-affidavits', requestData);
            window.open(response.url, '_blank');
            setOpen(false);
            setMessage('Success! Your file(s) are downloaded.');
            setLoading(false);
        }
        catch (err) {
            console.error(err);
        }
    });
    const isIncompleteOrAwaiting = range === ALL_INCOMPLETE_AFFIDAVITS ||
        range === AFFIDAVITS_AWAITING_PUBLICATION;
    return (React.createElement(Modal, { setOpen: setOpen, body: "Which affidavits would you like to download?", header: "Bulk download affidavits", noExitOutsideModal: true, width: "sm:max-w-2xl" },
        React.createElement("div", { className: "mt-5 mb-10", id: "bulk-download-modal" },
            React.createElement("div", { className: error ? 'grid grid-cols-3 mb-5' : 'grid grid-cols-3 mb-5' },
                React.createElement("section", { className: isIncompleteOrAwaiting ? 'col-span-3' : 'col-span-2 mr-3' },
                    React.createElement("div", null,
                        React.createElement("p", { className: "text-xs uppercase text-gray-500 font-medium" }, "Date Range"),
                        React.createElement(SelectDropdown, { id: "date-range", selected: range, placeholder: DATE_RANGE[0].label, onChange: (newValue) => {
                                if (!newValue)
                                    return false;
                                setError('');
                                setRange(newValue.label);
                            }, options: DATE_RANGE }))),
                !isIncompleteOrAwaiting ? (React.createElement("section", { className: "col-span-1" },
                    React.createElement("p", { className: "text-xs uppercase text-gray-500 font-medium" }, "Final Publication Date"),
                    !loadingTimestamps ? (React.createElement("li", { className: "col-span-3 pl-2  flex flex-col text-gray-700 border-gray-400 uppercase border rounded py-0.25" },
                        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                            React.createElement(DatePicker, { value: moment(downloadTimestamp)
                                    .utcOffset(0)
                                    .format('YYYY/MM/DD'), placeholder: "MMM dd, yyyy", format: "MMM dd, yyyy", className: 'font-medium border border-gray-400 rounded py-0.5', InputProps: {
                                    disableUnderline: true,
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(CalendarIcon, { className: "text-xs p-0.25" }))),
                                    className: 'text-sm'
                                }, autoOk: true, shouldDisableDate: disableDate, onChange: (newDate) => {
                                    if (!newDate) {
                                        return;
                                    }
                                    const date = relevantTimestamps.find(relevantTimestamp => areSameDayUTC(newDate, relevantTimestamp));
                                    if (date) {
                                        setDownloadTimestamp(date);
                                    }
                                }, minDateMessage: "Selected date after publication deadline" })))) : (React.createElement("div", { className: "flex justify-center content-center mt-2" },
                        React.createElement("div", { className: "border-4 border-t-4 ease-linear h-6 loader rounded-full w-6" }))))) : null),
            error && React.createElement("p", { className: 'text-red-500 font-normal text-xs' }, error),
            React.createElement(CButton, { id: "download", onClick: () => downloadAffidavits(), className: `${buttonStyles} w-4/12 px-4 bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 hover:text-white`, startClasses: "col-span-3", middleClasses: loading ? 'col-span-6' : 'col-span-12', endClasses: "col-span-3", startIcon: loading ? (React.createElement("div", { className: "border-4 border-t-4 ease-linear h-6 loader rounded-full w-6" })) : (React.createElement(React.Fragment, null)) }, "Download"))));
};
export default BulkAffidavitDownload;
