var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { disableNonPublishingDays, getIsAfterPublishingDeadline, getClosestFuturePublishingDay, publishingDayEnumValuesFromDeadlines } from 'lib/utils/deadlines';
import { getNoticeType } from 'lib/helpers';
import * as headers from 'lib/headers_footers/headers';
import { exists } from 'lib/types';
import { connect } from 'react-redux';
import { NoticeType } from 'lib/enums';
import { Firestore } from 'EnoticeFirebase';
import { safeStringify } from 'lib/utils/stringify';
import { AddIcon } from 'icons';
import ScrollStep from './ScrollStep';
import ConfirmScheduleRow from './ConfirmScheduleRow';
import { handlePubDateChangeForWeekendEdition, removeRowClickForWeekendEdition, SATURDAY_DAY_INDEX, shouldDisableDate, SUNDAY_DAY_INDEX } from './helpers';
import { timestampOrDateToTimestamp } from '../../utils/firebase';
const styles = (theme) => createStyles({
    headingText: {
        color: theme.palette.grey.scrollFormHeading
    },
    rowMT: {
        marginTop: theme.spacing(1)
    },
    subHeading: {
        color: theme.palette.grey.scrollFormHeading,
        fontWeight: 600
    },
    container: {
        marginTop: theme.spacing(1)
    }
});
export const shouldAutoSetDates = (notice, newspaper, publicationDates) => {
    var _a, _b, _c;
    // don't auto-set if there isn't an allowed notice array
    if (!((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices))
        return false;
    // don't auto-set if it is a default notice type
    if (((_b = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _b === void 0 ? void 0 : _b.noticeType) === NoticeType.custom.value ||
        ((_c = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _c === void 0 ? void 0 : _c.noticeType) === NoticeType.display_ad.value)
        return false;
    // get the associated notice type
    const noticeType = getNoticeType(notice, newspaper);
    if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
        publicationDates.length <= (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications))
        return true;
    return false;
};
const ConfirmSchedule = ({ id, activeStepId, notice, newspaper, classes, isPublisher, editing, previous, next, onDisabledStepClick, user, placementActions, placement }) => {
    var _a, _b;
    const [complete, setComplete] = useState(false);
    const [initialPublicationDate, setInitialPublicationDate] = useState((_b = (_a = placement.publicationDates) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.toDate());
    const noticeType = getNoticeType(notice, newspaper);
    const deadlines = exists(newspaper) ? newspaper.data().deadlines : null;
    const newspaperTimezone = exists(newspaper)
        ? newspaper.data().iana_timezone
        : null;
    const cannotFileWPaper = !deadlines || !newspaperTimezone;
    const publicationDates = placement.publicationDates
        ? placement.publicationDates.map((timestamp) => timestamp.toDate())
        : [];
    const setPlacementPublicationDates = (...dates) => {
        placementActions.setPublicationDates(dates.map(timestampOrDateToTimestamp));
    };
    const isComplete = (publicationDates) => {
        const areRepeatedDateSelections = (publicationDates) => {
            const dateStrings = publicationDates
                .map(date => {
                if (!date)
                    return false;
                return date.toDateString();
            })
                .filter(Boolean);
            return new Set(dateStrings).size !== dateStrings.length;
        };
        const areAnyDaysDisabled = (publicationDates) => {
            return publicationDates.find(day => shouldDisableDate(day, newspaper, user, placement, editing, noticeType, isPublisher));
        };
        return (!areRepeatedDateSelections(publicationDates) &&
            (!areAnyDaysDisabled(publicationDates) || isPublisher));
    };
    const onExit = () => __awaiter(void 0, void 0, void 0, function* () {
        const sortedPublicationDates = publicationDates
            .sort((a, b) => a.getTime() - b.getTime())
            .map(d => {
            d.setHours(12, 0, 0, 0);
            return d;
        });
        const getHeader = () => {
            var _a;
            if (sortedPublicationDates.length === 1 &&
                exists(newspaper) &&
                newspaper.data().oneRunHeader)
                return newspaper.data().oneRunHeader;
            return ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.headerFormatString) || '';
        };
        const fbDates = sortedPublicationDates.map(Firestore.Timestamp.fromDate);
        const dynamicHeaders = headers.generate(getHeader(), fbDates, noticeType);
        placementActions.confirmSchedule({
            publicationDates: fbDates,
            dynamicHeaders,
            dynamicFooter: null,
            footerFormatString: null
        });
    });
    const handleAddMoreClick = () => {
        if (!deadlines || !newspaperTimezone) {
            return;
        }
        let newPublicationDates = [...publicationDates];
        const DEFAULT_DAYS_TO_ADD = 7;
        const daysToAdd = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) &&
            (noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultDaysBetweenPublication)
            ? noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultDaysBetweenPublication
            : DEFAULT_DAYS_TO_ADD;
        const nextPotentialPublishingDate = moment(publicationDates[publicationDates.length - 1])
            .add(daysToAdd, 'days')
            .toDate();
        const nextPublishingDate = getClosestFuturePublishingDay(deadlines, newspaperTimezone, placement, newspaper, nextPotentialPublishingDate);
        // Adds both next Saturday and next Sunday if next publishing day is a weekend
        if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) &&
            moment(nextPublishingDate).day() === SATURDAY_DAY_INDEX) {
            const sunday = moment(nextPublishingDate).day(7).toDate();
            newPublicationDates.push(sunday);
        }
        else if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) &&
            moment(nextPublishingDate).day() === SUNDAY_DAY_INDEX) {
            const saturday = moment(nextPublishingDate).day(-1).toDate();
            newPublicationDates.push(saturday);
        }
        newPublicationDates.push(nextPublishingDate);
        /**
         * Using a unary + operator to coerce Date to Number avoids ts(2362) error
         * https://github.com/microsoft/TypeScript/issues/5710
         */
        newPublicationDates = newPublicationDates.sort((a, b) => +a - +b);
        placementActions.setPublicationDatesUpdated(true);
        setPlacementPublicationDates(...newPublicationDates);
    };
    const handleRemoveRowClick = (i) => __awaiter(void 0, void 0, void 0, function* () {
        let newDates = [];
        if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) {
            newDates = removeRowClickForWeekendEdition(publicationDates, i);
        }
        else {
            newDates = publicationDates.filter((s, sIndex) => i !== sIndex);
        }
        // If the array is empty add the initial date to prevent error
        if (newDates.length === 0 && initialPublicationDate) {
            newDates.push(initialPublicationDate);
        }
        placementActions.setPublicationDatesUpdated(true);
        setPlacementPublicationDates(...newDates);
    });
    const handlePubDateChange = (pubDate, i) => {
        pubDate.setHours(12);
        let newPubDates;
        if (!initialPublicationDate) {
            setInitialPublicationDate(publicationDates[0]);
        }
        if (exists(newspaper) &&
            shouldAutoSetDates(notice, newspaper, publicationDates) &&
            !isPublisher) {
            newPubDates = [pubDate];
        }
        else {
            newPubDates = [...publicationDates];
            newPubDates[i] = pubDate;
        }
        if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) {
            newPubDates = handlePubDateChangeForWeekendEdition(pubDate, publicationDates, i);
        }
        /**
         * Using a unary + operator to coerce Date to Number avoids ts(2362) error
         * https://github.com/microsoft/TypeScript/issues/5710
         */
        newPubDates = newPubDates.sort((a, b) => +a - +b);
        setPlacementPublicationDates(...newPubDates);
    };
    /**
     * Set the publication dates to the next valid one for the chosen newspaper.
     */
    const resetPublicationDates = () => {
        if (!exists(newspaper) || !deadlines || !newspaperTimezone) {
            return;
        }
        setPlacementPublicationDates(getClosestFuturePublishingDay(deadlines, newspaperTimezone, placement, newspaper));
    };
    useEffect(() => {
        if (!exists(newspaper) ||
            !exists(notice) ||
            !deadlines ||
            !newspaperTimezone) {
            return;
        }
        if (!(publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length)) {
            resetPublicationDates();
        }
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id, notice === null || notice === void 0 ? void 0 : notice.id, publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length]);
    useEffect(() => {
        if (!publicationDates.length || publicationDates.find(d => d === null))
            return;
        setComplete(isComplete(publicationDates));
    }, [
        safeStringify(placement.publicationDates),
        activeStepId,
        deadlines,
        newspaperTimezone
    ]);
    useEffect(() => {
        if (!publicationDates.length || publicationDates.find(d => d === null)) {
            return;
        }
        setComplete(isComplete(publicationDates));
    }, []);
    const handleCustomNoticeTypesFlowDisable = (publicationDate) => {
        // publishers can always edit
        if (isPublisher)
            return false;
        if (publicationDates.indexOf(publicationDate) === 0)
            return false;
        // only disable on custom notice types with preset runs and that do not allow for
        // additional runs to be added beyond the required ones
        if (exists(newspaper) &&
            shouldAutoSetDates(notice, newspaper, publicationDates) &&
            !(noticeType === null || noticeType === void 0 ? void 0 : noticeType.canAddBeyondRequired))
            return true;
        // by default allow editing
        return false;
    };
    const canAddMoreDates = isPublisher ||
        (exists(newspaper) &&
            !(newspaper.data().allowedNotices &&
                placement.noticeType !== NoticeType.custom.value &&
                (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
                !(noticeType === null || noticeType === void 0 ? void 0 : noticeType.canAddBeyondRequired)));
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: () => __awaiter(void 0, void 0, void 0, function* () {
            yield onExit();
            next();
        }), previous: previous, complete: complete, onDisabledStepClick: onDisabledStepClick, title: editing ? 'Edit Publication Dates' : 'Select Publication Dates', caption: exists(newspaper)
            ? isPublisher
                ? 'What date(s) did the customer request that the notice run on?'
                : `What date(s) should ${newspaper.data().name} publish your notice?`
            : '' },
        cannotFileWPaper && (React.createElement("div", null, "This paper has not set up a publication schedule yet.")),
        !cannotFileWPaper && (React.createElement(Grid, { container: true, direction: "column", className: classes.container },
            React.createElement(Box, null,
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, style: { width: 180 } },
                        React.createElement("div", { className: "font-normal text-sm text-gray-700 uppercase" }, "Publication Date")),
                    React.createElement(Grid, { item: true },
                        React.createElement("div", { className: "font-normal text-sm text-gray-700 ml-4 uppercase" }, "Deadline Information")))),
            newspaper &&
                !!(publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length) &&
                !!(deadlines === null || deadlines === void 0 ? void 0 : deadlines.length) &&
                newspaperTimezone && (React.createElement(React.Fragment, null,
                React.createElement(Box, { mt: 1, id: "text-box", style: { width: 'inherit' } }, publicationDates.map((publicationDate, index) => {
                    const isAfterPublishingDeadline = getIsAfterPublishingDeadline(publicationDate, deadlines, newspaperTimezone, placement, newspaper);
                    return (React.createElement(Grid, { item: true, className: index > 0 ? classes.rowMT : '', key: index },
                        React.createElement(ConfirmScheduleRow, { deadlines: deadlines, publicationDate: publicationDate, handlePubDateChange: handlePubDateChange, disableNonPublishingDays: disableNonPublishingDays, publishingDayEnumValuesFromDeadlines: publishingDayEnumValuesFromDeadlines, newspaperTimezone: newspaperTimezone, isAfterPublishingDeadline: isAfterPublishingDeadline, index: index, handleRemoveRowClick: handleRemoveRowClick, editing: editing, isPublisher: isPublisher, user: user, disabled: handleCustomNoticeTypesFlowDisable(publicationDate), newspaper: newspaper, notice: notice, showDelete: publicationDates.length !== 1 })));
                })),
                canAddMoreDates && (React.createElement(Box, { mt: 1 },
                    React.createElement(Grid, { container: true, direction: "row", alignItems: "center", justify: "flex-start", className: "items-center justify-items-start", id: "add-more-dates-container" },
                        React.createElement(IconButton, { size: "small", onClick: handleAddMoreClick },
                            React.createElement(AddIcon, { className: "w-5 h-5 text-grey-700" })),
                        React.createElement("div", { id: "add-more-dates", onClick: handleAddMoreClick, style: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            } },
                            React.createElement("div", { className: "font-medium text-sm text-gray-700 ml-1" }, "Add More Dates")))))))))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps)(withStyles(styles)(ConfirmSchedule));
