import React, { useState, useEffect } from 'react';
import AuthButton from 'routes/auth/AuthButton';
import FormError from '../errors/FormError';
import LoginWrapperWithLogo from './LoginWrapperWithLogo';
const Login = ({ authActions, authError, push }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    useEffect(() => {
        if (!authError)
            return;
        authActions.setAuthError('');
    }, [email, password]);
    return (React.createElement(LoginWrapperWithLogo, null,
        React.createElement("div", null,
            React.createElement("h2", { className: "mt-6 text-center text-base leading-9 text-gray-900" }, "Log in to your account"),
            React.createElement("form", { className: "mt-8", onSubmit: e => {
                    e.preventDefault();
                    authActions.login(email, password, true);
                } },
                React.createElement("input", { type: "hidden", name: "remember", value: "true" }),
                React.createElement("div", { className: "rounded-md shadow-sm" },
                    React.createElement("div", null,
                        React.createElement("input", { name: "email", id: "email", type: "email", autoComplete: "email", value: email || '', onChange: e => setEmail(e.target.value), className: "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", placeholder: "Email address", required: true })),
                    React.createElement("div", { className: "-mt-px" },
                        React.createElement("input", { name: "password", type: "password", id: "password", autoComplete: "current-password", value: password || '', onChange: e => setPassword(e.target.value), className: "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", placeholder: "Password" }))),
                React.createElement("div", { className: "text-sm leading-5 float-right" },
                    React.createElement("a", { href: "/forgot-password", className: "font-sm relative bottom-7 right-2 underline text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150" }, "Forgot?")),
                React.createElement("div", { className: "mt-6" },
                    React.createElement(AuthButton, null, "Log in")),
                authError && React.createElement(FormError, { error: authError }),
                React.createElement("div", { className: "mt-6 flex" },
                    React.createElement("hr", { className: "flex-1 text-gray-700 mt-3 px-10" }),
                    React.createElement("p", { className: "flex-auto text-center text-gray-700 text-base" }, "Don't have an account?"),
                    React.createElement("hr", { className: "flex-1 text-gray-700 mt-3 px-10  " })),
                React.createElement("div", { className: "mt-6" },
                    React.createElement("button", { type: "submit", id: "register-button", onClick: () => push(`/register/${window.location.search}`), className: "group relative w-full flex justify-center py-2 px-4 border border-solid text-base leading-5 font-medium rounded-md hover:bg-gray-200 focus:outline-none focus:border-gray-200 focus:shadow-outline-gray active:bg-gray-200 transition duration-150 ease-in-out" }, "Register"))))));
};
export default Login;
