var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { logAndCaptureException } from 'utils';
import { KeyboardArrowDown } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, DatePicker } from '@material-ui/pickers';
import { getDeadlineOverrideKeyFromDate, deadlineOverrideDateToDeadlineOverride, deadlineOverrideToDeadlineOverrideDate, deadlineSettingsToDeadlineDate } from 'lib/types/deadlines';
import SubmitButton from 'components/SubmitButton';
import ToastActions from 'redux/toast';
import EToast from 'components/EToast';
import FreeformCModal from 'components/modals/FreeFormCModal';
import api from 'api';
import DatePickerTextField from './DatePickerTextField';
import SettingsHeader from '../../SettingsHeader';
const DeadlineOverrides = ({ newspaper, toastActions, toastText }) => {
    const newspaperOverrides = newspaper.data().deadlineOverrides || {};
    const [dateToOverride, setDateToOverride] = useState(moment().add(1, 'day').toDate());
    const [isPublishing, setIsPublishing] = useState(false);
    const [isPublishingNotChanged, setIsPublishingNotChanged] = useState(true);
    const [isAdDeadlineDayNotOverriden, setIsAdDeadlineDayNotOverriden] = useState(true);
    const dateIsCurrentlyOverridden = newspaperOverrides[getDeadlineOverrideKeyFromDate(dateToOverride)] &&
        !isPublishingNotChanged &&
        !isAdDeadlineDayNotOverriden;
    const [currentAdDeadline, setCurrentAdDeadline] = useState(null);
    const [overridenDeadlineDate, setOverridenDeadlineDate] = useState(null);
    const [showInterruptModal, setShowInterruptModal] = useState(false);
    const [savedOverride, setSavedOverride] = useState();
    const [saveButtonSpinner, setSaveButtonSpinner] = useState(false);
    const [contButtonSpinner, setContButtonSpinner] = useState(false);
    const existingPublishingDeadline = newspaper
        .data()
        .deadlines.find(deadline => deadline.publish && deadline.dayEnum === dateToOverride.getDay() + 1);
    useEffect(() => {
        const overrideKey = getDeadlineOverrideKeyFromDate(dateToOverride);
        const existingOverride = newspaperOverrides[overrideKey];
        if (existingOverride) {
            const savedDeadlineMoment = moment(deadlineOverrideToDeadlineOverrideDate(overrideKey, existingOverride));
            setCurrentAdDeadline(savedDeadlineMoment.toDate());
            setOverridenDeadlineDate(savedDeadlineMoment.toDate());
            setIsPublishing(existingOverride.publish);
        }
        else {
            setOverridenDeadlineDate(null);
            let deadlineMoment = moment(dateToOverride);
            if (existingPublishingDeadline) {
                deadlineMoment = moment(deadlineSettingsToDeadlineDate(dateToOverride, existingPublishingDeadline));
            }
            setCurrentAdDeadline(deadlineMoment.toDate());
            setIsPublishing(Boolean(existingPublishingDeadline));
        }
    }, [dateToOverride]);
    useEffect(() => {
        if (!isPublishing || overridenDeadlineDate)
            return;
        let deadlineMoment = moment(dateToOverride);
        deadlineMoment = deadlineMoment.set('minutes', 0);
        if (existingPublishingDeadline) {
            deadlineMoment = moment(deadlineSettingsToDeadlineDate(dateToOverride, existingPublishingDeadline));
        }
        else {
            deadlineMoment = deadlineMoment.subtract(1, 'day');
            deadlineMoment = deadlineMoment.set('hours', 12);
        }
        setOverridenDeadlineDate(deadlineMoment.toDate());
    }, [isPublishing, overridenDeadlineDate]);
    useEffect(() => {
        if (overridenDeadlineDate && currentAdDeadline) {
            setIsAdDeadlineDayNotOverriden(overridenDeadlineDate.getTime() === currentAdDeadline.getTime());
        }
    }, [overridenDeadlineDate]);
    const continueOverrideDeadline = (overrideDeadline) => __awaiter(void 0, void 0, void 0, function* () {
        if (showInterruptModal)
            setContButtonSpinner(true);
        const shouldRemoveOverride = !isPublishing && dateIsCurrentlyOverridden;
        if (shouldRemoveOverride) {
            delete newspaperOverrides[getDeadlineOverrideKeyFromDate(dateToOverride)];
        }
        else {
            newspaperOverrides[getDeadlineOverrideKeyFromDate(dateToOverride)] = overrideDeadline;
        }
        yield newspaper.ref.update({ deadlineOverrides: newspaperOverrides });
        toastActions.setToastText(`Success! Settings for ${moment(dateToOverride).format('MM/DD/YY')} updated.`);
        setTimeout(() => toastActions.setToastText(''), 5000);
        setShowInterruptModal(false);
        setContButtonSpinner(false);
        setSaveButtonSpinner(false);
        setIsPublishingNotChanged(true);
        setCurrentAdDeadline(overridenDeadlineDate);
        setIsAdDeadlineDayNotOverriden(true);
    });
    const overrideDeadline = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!overridenDeadlineDate) {
            return;
        }
        setSaveButtonSpinner(true);
        const overrideDeadline = deadlineOverrideDateToDeadlineOverride(dateToOverride, overridenDeadlineDate, isPublishing);
        if (!isPublishing) {
            try {
                const { notices } = yield api.get(`organizations/${newspaper.id}/publication-date/${moment(dateToOverride).format('MM-DD-YYYY')}`);
                if (notices.length) {
                    setSavedOverride(overrideDeadline);
                    setShowInterruptModal(true);
                    setSaveButtonSpinner(false);
                    return;
                }
            }
            catch (err) {
                logAndCaptureException(err, 'Failed to override deadline', {
                    newspaperId: newspaper.id
                });
            }
        }
        yield continueOverrideDeadline(overrideDeadline);
    });
    if (!dateToOverride)
        return null;
    return (React.createElement(React.Fragment, null,
        showInterruptModal && (React.createElement(FreeformCModal, { header: 'Warning: Reschedule Notices', setOpen: setShowInterruptModal },
            React.createElement("div", null,
                React.createElement("span", { className: "text-gray-700" },
                    "You already have notices scheduled to publish on",
                    ' ',
                    moment(dateToOverride).format('MM/DD/YY'),
                    ". If you cancel publication on this date, please reschedule those notices. You can do so by editing their publication dates in Notice Details."),
                React.createElement("button", { id: "continue-cancel", type: "submit", className: "mt-3 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-650 text-sm flex items-center px-8 py-2", onClick: () => savedOverride && continueOverrideDeadline(savedOverride), disabled: contButtonSpinner },
                    contButtonSpinner && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                    "Continue")))),
        React.createElement("form", { className: "flex mt-7", onSubmit: e => {
                e.preventDefault();
                void overrideDeadline();
            } },
            React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow flex-1 flex flex-col" },
                React.createElement(SettingsHeader, { header: "Schedule Changes", description: "Make adjustments to your publication days and ad deadlines." }),
                React.createElement("table", { className: "min-w-full divide-y divide-gray-200 flex-1" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "PUBLISHING DAY"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "AD DEADLINE DAY"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "AD DEADLINE TIME"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-center" }, "Publishing?"))),
                    React.createElement("tbody", { className: "bg-white divide-y divide-gray-200" },
                        React.createElement("tr", null,
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, moment(dateToOverride).format('MMM DD, YYYY')),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, !isPublishing ? ('N/A') : (React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                                React.createElement(DatePicker, { value: overridenDeadlineDate, maxDate: dateToOverride, disablePast: true, placeholder: "MMM dd, YYYY", format: "MMM dd, YYYY", className: 'text-xs', InputProps: {
                                        disableUnderline: true,
                                        className: 'text-xs'
                                    }, TextFieldComponent: props => (React.createElement("div", { className: "flex justify-between items-center", onClick: e => props.onClick && props.onClick(e) },
                                        React.createElement("span", null, overridenDeadlineDate
                                            ? moment(overridenDeadlineDate).format('MMM DD, YYYY')
                                            : ''),
                                        React.createElement(KeyboardArrowDown, { fontSize: 'small', className: "text-gray-700" }))), autoOk: true, onChange: newDeadlineDate => {
                                        if (overridenDeadlineDate && newDeadlineDate) {
                                            newDeadlineDate.setHours(overridenDeadlineDate.getHours());
                                            newDeadlineDate.setMinutes(overridenDeadlineDate.getMinutes());
                                            setOverridenDeadlineDate(newDeadlineDate);
                                        }
                                    } })))),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 h-16" }, !isPublishing ? ('N/A') : (React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                                React.createElement(KeyboardTimePicker, { TextFieldComponent: DatePickerTextField, id: "select-deadline-time", placeholder: "08:00 AM", mask: "__:__ _M", value: overridenDeadlineDate, onChange: newDeadlineTime => {
                                        if (overridenDeadlineDate && newDeadlineTime) {
                                            const newDate = moment(overridenDeadlineDate).toDate();
                                            newDate.setHours(newDeadlineTime.getHours());
                                            newDate.setMinutes(newDeadlineTime.getMinutes());
                                            setOverridenDeadlineDate(newDeadlineTime);
                                        }
                                    } })))),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                React.createElement("input", { className: "focus:ring-blue-400 h-4 w-4 text-blue-500 border-gray-300 rounded", onChange: e => {
                                        setIsPublishing(e.target.checked);
                                        setIsPublishingNotChanged(!isPublishingNotChanged);
                                    }, checked: isPublishing, type: "checkbox" }))))),
                React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
                    React.createElement(SubmitButton, { disabled: saveButtonSpinner ||
                            (!isPublishing &&
                                !existingPublishingDeadline &&
                                !newspaperOverrides[getDeadlineOverrideKeyFromDate(dateToOverride)] &&
                                !dateIsCurrentlyOverridden) ||
                            (isPublishingNotChanged && isAdDeadlineDayNotOverriden), loading: saveButtonSpinner, text: "Save", type: "submit", id: "submit" }))),
            React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow ml-8 overflow-hidden" },
                React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                    React.createElement(DatePicker, { minDate: moment().startOf('day').add(1, 'day').toDate(), label: "", value: dateToOverride, placeholder: "MMM dd, YYYY", format: "MMM dd, YYYY", className: 'text-xs', InputProps: {
                            disableUnderline: true,
                            className: 'text-xs'
                        }, autoOk: true, onChange: date => {
                            if (date) {
                                setDateToOverride(date);
                            }
                        }, variant: "static", disableToolbar: true })))),
        toastText && (React.createElement(EToast, { message: toastText, type: "success", close: () => toastActions.setToastText('') }))));
};
const mapDispatchToProps = (dispatch) => ({
    toastActions: bindActionCreators(ToastActions, dispatch)
});
const mapStateToProps = (state) => {
    var _a;
    return ({
        toastText: ((_a = state.toast) === null || _a === void 0 ? void 0 : _a.toastText) || ''
    });
};
export default connect(mapStateToProps, mapDispatchToProps)(DeadlineOverrides);
