var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import MaterialTable from 'material-table';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Grid, CircularProgress } from '@material-ui/core';
import { exists } from 'lib/types';
import tableIcons from 'components/tableIcons';
import { rateCodeGenerator } from 'lib/helpers';
import { uiToDBCurrency, dbToUICurrency } from 'lib/pricing';
import { isBattlebornSync } from 'lib/publishers';
import Firebase, { Collections } from 'EnoticeFirebase';
import { NoticeType, RateType, State, Country, CurrencyType } from 'lib/enums';
import { InvalidRowError } from 'lib/errors/RatesTableErrors';
import api from 'api';
import RatesEditField from './RatesEditField';
import RatesTableCell from './RatesTableCell';
const styles = (theme) => createStyles({
    tabRow: {
        marginBottom: theme.spacing(1)
    },
    loadingContainer: {
        height: '60vh'
    },
    loadingGrid: {
        height: '100%'
    }
});
const isInchesRow = ({ rateType }) => parseInt(rateType, 10) === RateType.inch.value ||
    parseInt(rateType, 10) === RateType.column_inch.value;
const transformEnumToMTableLookup = (activeOrganization, noticeType, parentOrganization = null) => {
    const mTablelookup = {};
    RateType.items()
        .filter(rate => {
        if (rate.value === RateType.battle_born.value)
            return isBattlebornSync(activeOrganization, parentOrganization);
        if (rate.value === RateType.berthoud_government.value)
            return activeOrganization.data().name.includes('Berthoud');
        if (rate.value === RateType.enterprise.value)
            return activeOrganization.data().name.includes('The Enterprise');
        // only show Nebraska enum if you are in Nebraska
        if (noticeType !== NoticeType.display_ad.value &&
            activeOrganization.data().state === State.nebraska.value) {
            return rate.value === RateType.nebraska.value;
        }
        if (rate.value === RateType.oklahoma.value &&
            activeOrganization.data().state !== State.oklahoma.value) {
            return false;
        }
        if (rate.value === RateType.nebraska.value) {
            return false;
        }
        if (noticeType === NoticeType.display_ad.value) {
            return (rate.value === RateType.inch.value ||
                rate.value === RateType.column_inch.value ||
                rate.value === RateType.single_column_centimetre.value);
        }
        return true;
    })
        .forEach((rate) => {
        mTablelookup[rate.value] = rate.label;
    });
    return mTablelookup;
};
const fetchConvenienceFee = (isGovernment, rate) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api.post('payments/get-fee', { isGovernment, rate });
    return result.fee;
});
const isValidRow = (rowData) => {
    const reqdColumns = [
        'description',
        'rateType',
        'rate_0',
        'rate_1',
        'rate_2',
        'minimum'
    ];
    if (isInchesRow(rowData))
        reqdColumns.push('roundOff');
    const missingValue = reqdColumns.find(column => {
        if (rowData[column] === 0)
            return false;
        return !rowData[column];
    });
    if (missingValue)
        return false;
    return true;
};
const rateCodeExists = (rateCode) => __awaiter(void 0, void 0, void 0, function* () {
    return ((yield Firebase.firestore()
        .collection(Collections.rates)
        .where('code', '==', rateCode)
        .get()).docs.length > 0);
});
const transformRatesForTable = (rates, activeOrganization) => {
    return rates
        .filter(rate => exists(rate))
        .map(rate => {
        const { code, description, isGovernment, noticeType, minimum, rate_0, rate_1, rate_2, rateType, roundOff, archived } = rate.data();
        const tableRate = {
            code,
            description,
            isGovernment,
            noticeType,
            minimum: dbToUICurrency(minimum),
            rate_0: dbToUICurrency(rate_0),
            rate_1: dbToUICurrency(rate_1),
            rate_2: dbToUICurrency(rate_2),
            rateType: rateType.toString(),
            roundOff: (roundOff === null || roundOff === void 0 ? void 0 : roundOff.toString()) || '',
            id: rate.id,
            isDefaultDisplay: false,
            isDefaultLiner: false,
            archived
        };
        if (rate.id === activeOrganization.data().defaultDisplayRate.id)
            tableRate.isDefaultDisplay = true;
        if (rate.ref.id === activeOrganization.data().defaultLinerRate.id)
            tableRate.isDefaultLiner = true;
        return tableRate;
    })
        .sort(a => (a.isDefaultLiner || a.isDefaultDisplay ? -1 : 0))
        .filter(a => !a.archived);
};
class Editable extends React.Component {
    constructor(props) {
        var _a, _b, _c, _d;
        super(props);
        const currencySymbol = ((_b = (_a = props.activeOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.country) === Country.GBR.value
            ? CurrencyType.gbp.symbol
            : CurrencyType.usd.symbol;
        this.state = {
            loading: true,
            columns: [
                { title: 'Description', field: 'description' },
                {
                    title: 'Rate Type',
                    field: 'rateType',
                    lookup: transformEnumToMTableLookup(this.props.activeOrganization, this.props.noticeType),
                    initialEditValue: false
                },
                {
                    title: `1 Run (${currencySymbol})`,
                    field: 'rate_0',
                    initialEditValue: 1,
                    type: 'currency'
                },
                {
                    title: `2 Runs (${currencySymbol})`,
                    field: 'rate_1',
                    initialEditValue: 1,
                    type: 'currency'
                },
                {
                    title: `3+ Runs (${currencySymbol})`,
                    field: 'rate_2',
                    initialEditValue: 1,
                    type: 'currency'
                },
                {
                    title: 'Minimum',
                    field: 'minimum',
                    type: 'currency',
                    initialEditValue: 10
                },
                {
                    title: `Round Off (${((_d = (_c = props.activeOrganization) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.country) === Country.GBR.value
                        ? 'cm'
                        : 'in'})`,
                    field: 'roundOff',
                    type: 'numeric',
                    initialEditValue: 0
                },
                // {
                //   title: 'Is Gov Rate',
                //   field: 'isGovernment',
                //   initialEditValue: false,
                //   type: 'boolean'
                // },
                {
                    title: 'Rate Code',
                    field: 'code',
                    type: 'numeric'
                }
            ],
            data: []
        };
    }
    componentDidMount() {
        void this.fetchData();
    }
    fetchData() {
        return __awaiter(this, void 0, void 0, function* () {
            const ratesSnap = yield Firebase.firestore()
                .collection(Collections.rates)
                .where('organization', '==', this.props.activeOrganization.ref)
                .get();
            const filteredRates = ratesSnap.docs.filter(rateSnap => {
                const displayAd = NoticeType.display_ad.value;
                if (this.props.noticeType === displayAd) {
                    return rateSnap.data().noticeType === this.props.noticeType;
                }
                return rateSnap.data().noticeType !== displayAd;
            });
            yield this.setState({
                data: transformRatesForTable(filteredRates, this.props.activeOrganization)
            }, () => {
                this.setState({ loading: false });
            });
        });
    }
    render() {
        if (this.state.loading)
            return (React.createElement(Paper, { className: this.props.classes.loadingContainer },
                React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justify: "center", className: this.props.classes.loadingGrid },
                    React.createElement(Grid, { item: true },
                        React.createElement(CircularProgress, { disableShrink: true })))));
        return (React.createElement(React.Fragment, null,
            React.createElement(MaterialTable, { title: "Rates", columns: this.state.columns, data: this.state.data, icons: tableIcons, components: {
                    Cell: props => {
                        const { classes } = props, other = __rest(props, ["classes"]);
                        return React.createElement(RatesTableCell, Object.assign({ classes: classes }, other));
                    },
                    EditField: props => {
                        const { classes } = props, other = __rest(props, ["classes"]);
                        return React.createElement(RatesEditField, Object.assign({ classes: classes }, other));
                    }
                }, options: {
                    emptyRowsWhenPaging: false,
                    rowStyle: rowData => ({
                        backgroundColor: rowData.isDefaultLiner || rowData.isDefaultDisplay
                            ? '#EEE'
                            : '#FFF'
                    })
                }, editable: {
                    isEditable: () => true,
                    isDeletable: rowData => {
                        if (rowData.isDefaultLiner || rowData.isDefaultDisplay)
                            return false;
                        return true;
                    },
                    onRowAdd: (rowData) => __awaiter(this, void 0, void 0, function* () {
                        const { activeOrganization } = this.props;
                        if (!isValidRow(rowData)) {
                            throw new InvalidRowError();
                        }
                        let rateCode;
                        let codeExists = true;
                        while (codeExists) {
                            rateCode = rateCodeGenerator();
                            // eslint-disable-next-line no-await-in-loop
                            codeExists = yield rateCodeExists(rateCode);
                        }
                        const newData = Object.assign(Object.assign(Object.assign(Object.assign({}, rowData), { rateType: parseInt(rowData.rateType, 10), roundOff: isInchesRow(rowData) && rowData.roundOff
                                ? parseFloat(rowData.roundOff)
                                : null, minimum: uiToDBCurrency(rowData.minimum), rate_0: uiToDBCurrency(rowData.rate_0), rate_1: uiToDBCurrency(rowData.rate_1), rate_2: uiToDBCurrency(rowData.rate_2) }), (this.props.noticeType && {
                            noticeType: this.props.noticeType
                        })), { code: rateCode, enotice_fee_pct: yield fetchConvenienceFee(rowData.isGovernment, rowData) });
                        yield Firebase.firestore()
                            .collection(Collections.rates)
                            .add(Object.assign(newData, {
                            organization: activeOrganization.ref
                        }));
                        yield this.fetchData();
                    }),
                    onRowUpdate: (newData) => __awaiter(this, void 0, void 0, function* () {
                        if (!isValidRow(newData))
                            return;
                        const updateObj = Object.assign(Object.assign({}, newData), { rateType: parseInt(newData.rateType, 10), roundOff: isInchesRow(newData) && newData.roundOff
                                ? parseFloat(newData.roundOff)
                                : null, minimum: uiToDBCurrency(newData.minimum), rate_0: uiToDBCurrency(newData.rate_0), rate_1: uiToDBCurrency(newData.rate_1), rate_2: uiToDBCurrency(newData.rate_2), enotice_fee_pct: yield fetchConvenienceFee(newData.isGovernment, newData) });
                        delete updateObj.id;
                        yield Firebase.firestore()
                            .collection(Collections.rates)
                            .doc(newData.id)
                            .update(updateObj);
                        yield this.fetchData();
                    }),
                    onRowDelete: (oldData) => __awaiter(this, void 0, void 0, function* () {
                        yield Firebase.firestore()
                            .collection(Collections.rates)
                            .doc(oldData.id)
                            .update({ archived: true });
                        yield this.fetchData();
                    })
                } }),
            React.createElement("style", null, `
          span[title="Delete"] {
            display: flex;
          }

          span[aria-describedby^="mui-tooltip-"] {
            display: flex;
          }
        `)));
    }
}
export default withStyles(styles)(Editable);
