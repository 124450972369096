import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CTooltip from 'components/Tooltip';
const styles = (theme) => {
    return createStyles({
        cardHeader: {
            backgroundColor: '#2D9BDB',
            padding: theme.spacing(1) / 2
        },
        cardSecondaryHeader: {
            backgroundColor: theme.palette.secondary.main,
            padding: theme.spacing(1) / 2
        },
        paper: {
            display: 'none'
        },
        chip: {
            margin: theme.spacing(1)
        }
    });
};
const NoticeTypeCard = ({ noticeType, classes, onClick }) => (React.createElement(Card, { onClick: onClick, className: "col-span-1 bg-white rounded-lg shadow cursor-pointer" },
    React.createElement(CardActionArea, null,
        React.createElement(CardHeader, { id: noticeType.key, className: classes.cardHeader }),
        React.createElement(CardContent, null,
            React.createElement("div", { className: "min-w-0 items-center justify-between px-4 pt-6 pb-4 space-x-6 truncate" },
                React.createElement("div", null,
                    React.createElement(CTooltip, { helpText: noticeType.label, classes: 'mb-3' },
                        React.createElement("div", { className: "flex items-center space-x-3" },
                            React.createElement("svg", { className: "w-5 h-5 inline-block flex-shrink-0", fill: "#2D9BDB", stroke: "#2D9BDB", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" })),
                            React.createElement("span", { className: "min-w-0 text-gray-900 text-2xl font-medium truncate" }, noticeType.label))),
                    React.createElement("div", { className: "h-auto" },
                        React.createElement("p", { className: "mt-1 text-gray-700 text-base leading-5 h-full whitespace-normal" }))))))));
export default withStyles(styles)(NoticeTypeCard);
