export const ACTIVE_NOTICES_TAB = 'active-notices';
export const DRAFT_NOTICES_TAB = 'draft-notices';
export const ARCHIVED_NOTICES_TAB = 'archived-notices';
export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';
export const PUBLICATION_DATES = 'firstpublicationtimestamp';
export const CONFIRMED = 'isconfirmed';
export const PUBLISHER_BILLING_STATUS = 'publisherbillingstatusvalue';
export const ADVERTISER_BILLING_STATUS = 'advertiserbillingstatusvalue';
export const AFFIDAVIT_STATUS = 'affidavitstatus';
export const NEWSPAPER_NAME = 'publication';
export const CREATE_TIME = 'createtime';
export const CONFIRMED_AT = 'confirmedat';
