import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useState } from 'react';
import moment from 'moment';
import { FilterIcon } from 'icons';
import { safeStringify } from 'lib/utils/stringify';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnButton } from 'components/ColumnButton';
import ColumnSelect from 'components/select/ColumnSelect';
import { ALL_PUBLICATION_DATES, DEFAULT_AFFIDAVIT_UPLOAD_DAY_FILTER, VERIFICATION_STATUSES } from './automatedAffidavitSettingsUtils';
/**
 * Advanced filtering controls for the affidavit uploads table
 */
export default function AffidavitUploadFilterDialog({ setUploadFilter, uploadFilter }) {
    // whether or not we are showing the dropdown dialog
    const [open, setOpen] = useState(false);
    // store changes in local state until the user clicks "Apply"
    const [updatedUploadFilter, setUpdatedUploadFilter] = useState(uploadFilter);
    const hasChange = safeStringify(updatedUploadFilter) !== safeStringify(uploadFilter);
    // on click of "Apply", update the upload filter and close the dialog
    const applyChangesClickHandler = () => {
        setUploadFilter(updatedUploadFilter);
        setOpen(false);
    };
    // parse the date range into a more easily typed format
    const currentDateRange = updatedUploadFilter.dateRange !== ALL_PUBLICATION_DATES
        ? updatedUploadFilter.dateRange
        : null;
    return (React.createElement("div", { className: "relative inline-block text-left mt-1 ml-5", id: "affidavit-upload-filter-dialog" },
        React.createElement("div", { onClick: () => setOpen(!open), className: "cursor-pointer" },
            React.createElement("div", { className: "items-center inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" },
                React.createElement(FilterIcon, null),
                React.createElement("span", { className: "mx-1" }, "Filter"),
                React.createElement(KeyboardArrowDown, null))),
        open && (React.createElement("div", { className: "absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-5 px-4 w-100" },
            React.createElement("p", { className: "uppercase text-gray-500 text-xs font-bold mb-3" }, "Filter by"),
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Verification Status"),
            React.createElement(ColumnSelect, { className: "mb-3", options: VERIFICATION_STATUSES.map(status => ({
                    value: status,
                    label: status
                })), value: updatedUploadFilter.verificationStatus, onChange: newValue => setUpdatedUploadFilter(Object.assign(Object.assign({}, updatedUploadFilter), { verificationStatus: newValue })) }),
            React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Publication Dates"),
            React.createElement(ColumnSelect, { className: "mb-3", options: [
                    {
                        value: ALL_PUBLICATION_DATES,
                        label: ALL_PUBLICATION_DATES
                    },
                    {
                        value: 'Custom',
                        label: 'Custom Date Range'
                    }
                ], value: updatedUploadFilter.dateRange === ALL_PUBLICATION_DATES
                    ? ALL_PUBLICATION_DATES
                    : 'Custom', onChange: newValue => {
                    if (newValue === ALL_PUBLICATION_DATES) {
                        setUpdatedUploadFilter(Object.assign(Object.assign({}, updatedUploadFilter), { dateRange: ALL_PUBLICATION_DATES }));
                    }
                    else {
                        setUpdatedUploadFilter(Object.assign(Object.assign({}, updatedUploadFilter), { dateRange: {
                                start: moment().subtract(1, 'month').toDate(),
                                end: moment().toDate()
                            } }));
                    }
                } }),
            currentDateRange && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "text-gray-600 mb-2 text-sm" }, "Choose a date range"),
                React.createElement("div", { className: "flex flex-row gap-2" },
                    React.createElement(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "Start date", value: currentDateRange.start, onChange: newDate => newDate &&
                            setUpdatedUploadFilter(Object.assign(Object.assign({}, updatedUploadFilter), { dateRange: Object.assign(Object.assign({}, currentDateRange), { start: newDate }) })), shouldDisableDate: date => !date || moment(date).isAfter(currentDateRange.end) }),
                    React.createElement(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "End date", value: currentDateRange.end, onChange: newDate => newDate &&
                            setUpdatedUploadFilter(Object.assign(Object.assign({}, updatedUploadFilter), { dateRange: Object.assign(Object.assign({}, currentDateRange), { end: newDate }) })), shouldDisableDate: date => !date || moment().isBefore(date) })))),
            React.createElement("div", { className: "flex flex-row justify-between items-center mt-6" },
                React.createElement(ColumnButton, { buttonText: "Reset", onClick: () => setUploadFilter(DEFAULT_AFFIDAVIT_UPLOAD_DAY_FILTER) }),
                React.createElement(ColumnButton, { buttonText: "Apply", primary: true, disabled: !hasChange, onClick: applyChangesClickHandler }))))));
}
