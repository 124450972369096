import sanitize from 'sanitize-html';
const WEIRD_SPACE = String.fromCharCode(160);
const LOTS_OF_WEIRD_SPACES = WEIRD_SPACE.repeat(3);
const LOTS_OF_SPACES_PATTERN = /(&nbsp;\s{0,1}){5,}|[\s]{5,}/g;
const TR_SPLIT_PATTERN = new RegExp(`${LOTS_OF_WEIRD_SPACES}|(&nbsp;\\s{0,1}){3,}|[\\s]{3,}/g`);
/**
 * Set the global 'editor' instance.
 */
export const setEditor = (editor) => {
    window.editor = editor;
};
/**
 * Get the global 'editor' instance.
 */
export const getEditor = () => {
    return window.editor;
};
export const addComputedWidthToTables = (tables) => {
    for (const table of tables) {
        const cells = [...table.querySelectorAll('td')];
        for (const cell of cells) {
            const computedStyle = window.getComputedStyle(cell);
            cell.style.width = computedStyle.getPropertyValue('width');
            cell.setAttribute('width', computedStyle.getPropertyValue('width'));
        }
    }
};
export const preProcess = (cleanFunction) => (_, args) => {
    // encode tabs pasted from word
    // eslint-disable-next-line no-param-reassign
    args.content = cleanFunction(args.content);
    return args.content;
};
export const resizeTables = () => {
    const editor = getEditor();
    if (!editor) {
        return;
    }
    const targetNode = editor.getBody();
    const tables = targetNode.querySelectorAll('table');
    if (tables && tables[0]) {
        tables[0].setAttribute('data-mce-selected', '1');
        addComputedWidthToTables([...tables]);
        editor.fire('Change');
        return true;
    }
    return false;
};
export const postProcess = () => {
    const editor = getEditor();
    if (!editor) {
        return;
    }
    const config = { childList: true, subtree: true };
    const targetNode = editor.getBody();
    const callback = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                if (resizeTables()) {
                    observer.disconnect();
                }
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
};
export const checkIsTableRow = (paragraph) => {
    const strippedHtml = paragraph.replace(/<[^>]*>?/gm, '');
    const hasWeirdSpacing = strippedHtml.split(LOTS_OF_WEIRD_SPACES).length > 1;
    const splitBySpaces = strippedHtml.split(LOTS_OF_SPACES_PATTERN).length > 1;
    const tooLong = strippedHtml.replace(/&nbsp;/g, '').replace(/\W/g, '').length > 60;
    const isTableRow = (hasWeirdSpacing || splitBySpaces) && !tooLong;
    return isTableRow;
};
const formatTd = (html) => {
    let formattedTd = '';
    html.split('<p>').forEach(elt => {
        formattedTd += elt.replace('</p>', '<br/>');
    });
    return formattedTd.trim();
};
export const addHiddenTables = (str) => {
    var _a;
    const ROW_PATTERN = /(<ul.*?>(.*?)<\/ul>)|(<table.*?>(.*?)<\/table>)|(<div.*?>(.*?)<\/div>)|(<p.*?>(.*?)<\/p>)/g;
    if (!str.match(ROW_PATTERN)) {
        return str;
    }
    let result = '';
    let evaluatingTable = false;
    let centering = false;
    let tableData = [];
    const startTable = () => {
        evaluatingTable = true;
    };
    const closeTable = () => {
        evaluatingTable = false;
        if (!tableData.length) {
            return;
        }
        if (tableData.length === 1 && !centering) {
            result += `<p>${tableData[0].join('&emsp;')}</p>`;
            tableData.length = 0;
            return;
        }
        const nCols = Math.max(...tableData.map(r => r.length));
        result += '<table>';
        for (const row of tableData) {
            result += '<tr>';
            let tds = row;
            for (let i = 0; i < nCols - row.length; i++) {
                tds = tds.concat('');
            }
            for (const td of tds) {
                result += formatTd(`<td>${td}</td>`);
            }
            result += '</tr>';
        }
        result += '</table>';
        tableData.length = 0;
    };
    const rowMatches = (_a = str.match(ROW_PATTERN)) !== null && _a !== void 0 ? _a : [];
    for (const paragraph of rowMatches) {
        if (!paragraph) {
            continue;
        }
        // Please forgive this ugliness
        // Given the string: <p style='textalign: 'left';'>dddd</p>
        // we get as output
        // prefix: <p style='textalign: 'left';'>
        // line: dddd
        // suffix: </p>
        const prefix = `${paragraph.split('>')[0]}>`;
        const line = paragraph
            .split('>')
            .slice(1)
            .join('>')
            .split('</')
            .slice(0, -1)
            .join('</');
        const suffix = `</${paragraph.split('</').pop()}`;
        // put indented lines into a table with columns on either side
        if (prefix.indexOf('margin-left') !== -1 &&
            prefix.indexOf('margin-right') !== -1 &&
            prefix.indexOf('<table') === -1) {
            if (!evaluatingTable) {
                startTable();
            }
            tableData = tableData.concat([['', line.trim(), '']]);
            centering = true;
            continue;
        }
        else if (centering && evaluatingTable) {
            closeTable();
            centering = false;
        }
        // use repeated odd spacing characters to determine if we are in a table
        const isTableRow = checkIsTableRow(line);
        if (isTableRow) {
            if (!evaluatingTable) {
                startTable();
            }
            // check if we are in a header row
            const isBold = line.indexOf('<strong>') !== -1;
            // clean out html tags
            const cleanRow = line.replace('<strong>', '').replace('</strong>', '');
            const tableElements = cleanRow
                // split on odd spacing
                .split(TR_SPLIT_PATTERN)
                .map(e => (e ? e.replace(/&nbsp;/g, '').trim() : ''))
                .filter(e => e)
                .reduce((row, e) => row.concat(e
                .split('&emsp;')
                .filter(td => td)
                .map(td => (isBold ? `<strong>${td}</strong>` : td))), []);
            tableData = tableData.concat([tableElements]);
        }
        else {
            if (evaluatingTable) {
                closeTable();
            }
            result += prefix + line + suffix;
        }
    }
    if (evaluatingTable) {
        closeTable();
    }
    return result;
};
const removeCruft = (str) => str.replace(/&nbsp(?!;)/g, '&nbsp;');
const cleanHtml = (str) => {
    const sanitized = sanitize(str, {
        allowedAttributes: {
            p: ['style'],
            div: ['style'],
            td: ['style'],
            tr: ['style'],
            table: ['style']
        },
        allowedStyles: {
            '*': {
                'text-align': [/^left$/, /^right$/, /^center$/],
                'margin-left': [/.*/],
                'margin-right': [/.*/]
            }
        },
        transformTags: {
            span: (tagName, attribs) => {
                const isBold = attribs.style && attribs.style.indexOf('font-weight:700') !== -1;
                const updatedTag = isBold ? 'b' : tagName;
                return {
                    tagName: updatedTag,
                    attribs
                };
            }
        }
    });
    return sanitized.replace(/<td>(.*?)<\/td>/g, match => formatTd(match));
};
const replaceOddTags = (html) => html
    .replace(/<p><center>/g, '<p style="text-align: center">')
    .replace(/<\/p><\/center>/g, '</p>');
export const cleanContent = (str) => {
    const tagsReplaced = replaceOddTags(str);
    const cleaned = cleanHtml(tagsReplaced);
    const cruftRemoved = removeCruft(cleaned);
    const tablesAdded = addHiddenTables(cruftRemoved);
    return tablesAdded;
};
export const squash = (html) => {
    const { DOMParser } = window;
    const fakeDOM = new DOMParser().parseFromString(cleanContent(html), 'text/html');
    let text = '';
    const removeSquashCruft = (s) => s.replace(/\)/g, '').replace(/<br>/g, '');
    const handleLeaf = (leaf) => {
        removeSquashCruft(leaf.innerText);
        const leafHtml = leaf.innerHTML.replace(/&nbsp;/g, '');
        for (const subText of leafHtml.split('<br>')) {
            if (subText.trim()) {
                text += `<p>${subText.trim()}</p>`;
            }
        }
    };
    const handleChild = (child) => {
        if (child.tagName === 'P') {
            handleLeaf(child);
        }
        else if (child.tagName === 'TABLE') {
            for (const td of child.querySelectorAll('td')) {
                handleLeaf(td);
            }
            text += '<br />';
        }
        else if (child.tagName === 'DIV') {
            for (const divChild of child.children) {
                handleChild(divChild);
            }
        }
        else {
            handleLeaf(child);
        }
    };
    if (fakeDOM.body.children.length) {
        for (const child of fakeDOM.body.children) {
            const childText = child.innerText;
            if (childText.trim().length &&
                childText.replace(/\)/g, '').trim().length &&
                childText.replace(/_/g, '').length) {
                handleChild(child);
            }
        }
    }
    else {
        text += fakeDOM.body.innerHTML;
    }
    const final = text
        .replace(/<br\s\/>(<br\s\/>)+/g, '<br />')
        .replace(/<\/b>[a-zA-Z]/g, sub => `</b> ${sub.slice(4)}`);
    return final;
};
const tableSquashFormatter = (fakeDOM) => {
    try {
        const tables = fakeDOM.querySelectorAll('table');
        Array.from(tables).forEach((table) => {
            var _a;
            const cells = table.querySelectorAll('td');
            const outer = fakeDOM.createElement('div');
            Array.from(cells).forEach(cell => {
                if (!cell.innerText)
                    return;
                const tag = fakeDOM.createElement('p');
                const text = document.createTextNode(cell.innerText);
                tag.appendChild(text);
                outer.appendChild(tag);
            });
            (_a = table === null || table === void 0 ? void 0 : table.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(outer, table);
        });
    }
    catch (err) {
        console.error(err.toString());
    }
};
const addSanitize = (html) => {
    return sanitize(html, {
        allowedTags: false,
        allowedAttributes: {
            '*': ['style', 'data-mce-style']
        },
        allowedStyles: {
            '*': {
                'text-align': [/^.*$/],
                'text-transform': [/^.*$/],
                'text-decoration': [/^.*/]
            }
        },
        transformTags: {
            pre: 'p',
            sup: 'span'
        }
    });
};
export const formatTablesOnCopyPaste = (html) => {
    const fakeDOM = new DOMParser().parseFromString(html, 'text/html');
    tableSquashFormatter(fakeDOM);
    return addSanitize(fakeDOM.body.outerHTML);
};
