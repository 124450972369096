export const MailStatusData = {
    initiated: {
        value: 1,
        label: 'Mailing Initiated',
        key: 'initiated'
    },
    scheduled: {
        value: 2,
        label: 'Mail Scheduled',
        key: 'scheduled'
    },
    en_route: {
        value: 3,
        label: 'En Route',
        key: 'en_route'
    },
    delivered: {
        value: 4,
        label: 'Delivered',
        key: 'delivered'
    },
    delivery_failed: {
        value: 5,
        label: 'Delivery Failed',
        key: 'delivery_failed'
    }
};
