var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { BellIcon } from 'icons';
import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import { calculateTimeDifferenceFromTimestamp, logAndCaptureException } from 'utils';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnButton } from 'components/ColumnButton';
import AuthActions from 'redux/auth';
import { bindActionCreators } from 'redux';
import { exists } from 'lib/types';
import { OPEN_INVITE_STATUSES } from 'lib/users';
import RequestActionCard from 'components/requestsComponents/RequestActionCard';
import { RoleType, InviteStatus } from 'lib/enums';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { acceptInvitesHelper, acceptRequestHelper, declineInviteHelper, declineRequestHelper, transformInvitesToActionCardInvites, transformRequestsToActionCard } from '../modals/JoinOrganizationModals/helpers';
import { getFirebaseContext } from '../../utils/firebase';
import Drawer from '../Drawer';
import HorizontalDivider from '../HorizontalDivider';
import EmptyNotificationTray from './EmptyNotificationTray';
import { getNotificationTypeStyling, getNotificationsEmoji } from './NotificationTypesStyles';
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path)),
    authActions: bindActionCreators(AuthActions, dispatch)
});
const Notifications = ({ user, push, open, setOpen, authActions, activeOrganization }) => {
    const [notifications, setNotifications] = useState([]);
    const [invites, setInvites] = useState([]);
    const [requests, setRequests] = useState([]);
    const [clearingNotifications, setClearingNotifications] = useState(false);
    const [decliningInvitesLoading, setDecliningInvitesLoading] = useState(false);
    const [acceptingInvitesLoading, setAcceptingInvitesLoading] = useState(false);
    const [transformedInvites, setTransformedInvites] = useState();
    const [transformedOrganizationRequests, setTransformedOrganizationRequests] = useState();
    const [joinRequests, setJoinRequests] = useState([]);
    const [title, setTitle] = useState('General');
    const tableTitleHeadStyles = 'inline-block my-3 text-lg text-black text-left pt-3 focus:outline-none font-medium pb-3 border-b-2 border-transparent hover:border-b-2 hover:border-blue-600 hover:text-blue-950';
    const highlight = 'border-b-2 border-blue-600 text-blue-950';
    const highlightCount = 'bg-blue-100 text-blue-900';
    const tableTitleTabStyles = 'ml-2 rounded-full px-3 py-0.25 text-sm';
    const emptyNotificationTrayHeight = 'my-48';
    const allNotifications = notifications.length +
        invites.length +
        requests.length +
        joinRequests.length;
    const invitesAndRequestsNotifications = invites.length + requests.length + joinRequests.length;
    const isPublisherOrg = isPublisherOrganization(activeOrganization);
    const ctx = getFirebaseContext();
    const getUserNotifications = () => {
        return ctx
            .notificationsRef()
            .where('user', '==', user.ref)
            .where('inApp.read', '==', false)
            .orderBy('created', 'desc')
            .limit(51)
            .onSnapshot(notificationsSnaps => {
            setNotifications(notificationsSnaps.docs);
        });
    };
    const getInvites = () => {
        var _a;
        return ctx
            .invitesRef()
            .where('email', '==', (_a = user.data()) === null || _a === void 0 ? void 0 : _a.email)
            .where('status', '==', InviteStatus.snoozed.value)
            .orderBy('createdAt', 'desc')
            .onSnapshot(invitesSnaps => {
            setInvites(invitesSnaps.docs);
        });
    };
    // Requests that individual send to organization at the time of registration,
    // this query get results for individual users
    const getIndividualRequests = () => {
        return ctx
            .joinRequestsRef()
            .where('email', '==', user.data().email)
            .where('status', 'in', OPEN_INVITE_STATUSES)
            .orderBy('createdAt', 'desc')
            .onSnapshot((requestsSnaps) => __awaiter(void 0, void 0, void 0, function* () {
            yield transformRequests(requestsSnaps.docs);
        }));
    };
    // Requests that individual send to organization at the time of registration,
    // this query works to show notification for organization users
    const getOrganizationRequests = () => {
        var _a;
        if (exists(activeOrganization) &&
            ((_a = user.data().roles) === null || _a === void 0 ? void 0 : _a[activeOrganization.id]) === RoleType.admin.value) {
            return ctx
                .joinRequestsRef()
                .where('organization', '==', activeOrganization.ref)
                .where('status', '==', InviteStatus.snoozed.value)
                .orderBy('createdAt', 'desc')
                .onSnapshot((requestsSnaps) => __awaiter(void 0, void 0, void 0, function* () {
                setJoinRequests(requestsSnaps.docs);
            }));
        }
        return () => { };
    };
    const transformOrganizationRequests = () => __awaiter(void 0, void 0, void 0, function* () {
        const transformedRequests = yield transformRequestsToActionCard(joinRequests, ctx);
        setTransformedOrganizationRequests(transformedRequests);
    });
    const transformRequests = (requests) => __awaiter(void 0, void 0, void 0, function* () {
        const requestItemsList = [];
        yield Promise.all(requests.map((doc) => __awaiter(void 0, void 0, void 0, function* () {
            const organizationSnap = yield doc.data().organization.get();
            if (!exists(organizationSnap))
                return;
            const reqObject = {
                joinRequestRef: doc.ref,
                organizationName: organizationSnap.data().name
            };
            requestItemsList.push(reqObject);
        })));
        setRequests(requestItemsList);
    });
    const transformedUserInvites = () => __awaiter(void 0, void 0, void 0, function* () {
        const transformedInvites = yield transformInvitesToActionCardInvites(invites);
        setTransformedInvites(transformedInvites);
    });
    useEffect(() => {
        const notificationsUnsub = getUserNotifications();
        const invitesUnsub = getInvites();
        const requestsUnsub = getIndividualRequests();
        return () => {
            notificationsUnsub();
            invitesUnsub();
            requestsUnsub();
        };
    }, []);
    useEffect(() => {
        if (exists(activeOrganization)) {
            const orgRequestsUnsub = getOrganizationRequests();
            return () => {
                orgRequestsUnsub();
            };
        }
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    useEffect(() => {
        void transformedUserInvites();
    }, [safeStringify(invites)]);
    useEffect(() => {
        void transformOrganizationRequests();
    }, [safeStringify(joinRequests)]);
    const markNotificationAsRead = (notificationSnap) => __awaiter(void 0, void 0, void 0, function* () {
        const { inApp } = notificationSnap === null || notificationSnap === void 0 ? void 0 : notificationSnap.data();
        const updateObj = Object.assign({}, inApp);
        updateObj.read = true;
        if (exists(notificationSnap))
            yield notificationSnap.ref.update({ inApp: updateObj });
    });
    // Render list of all General notifications
    const listOfGeneralNotifications = () => notifications.map((docSnapshot, index) => {
        const { inApp, created, notice } = docSnapshot === null || docSnapshot === void 0 ? void 0 : docSnapshot.data();
        const { img, styles } = getNotificationTypeStyling(inApp.key);
        const link = (inApp && inApp.link) || (notice && `/notice/${notice.id}`) || '';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { key: index, 
                // inApp.key is not related to design -- it's purpose is to differentiate notifications in pendo
                className: `${inApp.key} flex py-5 px-6 cursor-pointer`, onClick: () => {
                    setOpen(false);
                    push(link);
                    void markNotificationAsRead(docSnapshot);
                } },
                React.createElement("div", { className: `${styles} w-12 h-12 rounded-full flex items-center justify-center p-4` }, img),
                React.createElement("div", { className: `${!inApp.body && 'flex items-center'} ml-5 w-full` },
                    React.createElement("div", { className: "flex w-full justify-between" },
                        React.createElement("div", { className: "text-blue-800 text-sm font-medium" },
                            inApp.text,
                            getNotificationsEmoji(inApp.key)),
                        React.createElement("div", { className: "text-gray-450 text-xs font-medium" },
                            React.createElement("p", null, calculateTimeDifferenceFromTimestamp(created)))),
                    React.createElement("p", { className: "mt-1 text-gray-650 text-xs font-medium" }, inApp.body)))));
    });
    const updateUserRoles = (roleValue, index) => {
        if (transformedOrganizationRequests) {
            const currTransformRequests = [...transformedOrganizationRequests];
            currTransformRequests[index].role = RoleType.by_label(roleValue).value;
            setTransformedOrganizationRequests(currTransformRequests);
        }
    };
    // Render list of all snoozed invites
    const renderSnoozedInvitesList = () => transformedInvites &&
        transformedInvites.map((invite, index) => {
            return (React.createElement("div", { key: `user-invite-${invite.userInvite.id}` },
                React.createElement(InviteActionCard, { invite: invite, onAcceptClick: () => acceptInvite(invite.userInvite), onDeclineClick: () => declineInvite(invite.userInvite), index: index, className: 'border-b', type: "invite" })));
        });
    const renderJoinOrganizationRequests = () => transformedOrganizationRequests &&
        transformedOrganizationRequests.map((request, index) => {
            return (React.createElement("div", { key: `user-request-${request.userRequest.id}` },
                React.createElement(InviteActionCard, { request: request, index: index, type: 'request', className: 'border-b', isPublisherOrg: isPublisherOrg, updateUserRole: roleValue => updateUserRoles(roleValue, index), onAcceptClick: () => acceptRequest(request), onDeclineClick: () => declineRequest(request) })));
        });
    // Render pending/snoozed requests list
    const renderRequestsList = () => requests.length > 0 &&
        requests.map((request, index) => {
            return (React.createElement("div", { key: `user-request-${request.joinRequestRef.id}` },
                React.createElement(RequestActionCard, { request: request, index: index, className: 'border-b' })));
        });
    const clearAllNotifications = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Promise.all(notifications.map((notification) => __awaiter(void 0, void 0, void 0, function* () {
            yield markNotificationAsRead(notification);
        })));
    });
    const declineInvite = (inviteSnap) => __awaiter(void 0, void 0, void 0, function* () {
        yield declineInviteHelper(inviteSnap);
    });
    const acceptInvite = (inviteSnap) => __awaiter(void 0, void 0, void 0, function* () {
        const { organizationId, email } = inviteSnap.data();
        try {
            yield acceptInvitesHelper(ctx, [inviteSnap], user, authActions);
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to join organization from invite', {
                userEmail: email || '',
                orgId: organizationId || '',
                inviteId: inviteSnap.id || ''
            });
        }
    });
    const acceptAllInvites = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.length)) {
            return;
        }
        try {
            yield Promise.all(transformedInvites.map((invite) => __awaiter(void 0, void 0, void 0, function* () {
                const inviteSnap = invite.userInvite;
                yield acceptInvite(inviteSnap);
            })));
        }
        catch (err) {
            logAndCaptureException(err, 'Error accepting all invites', {
                userId: user.id
            });
        }
    });
    const declineAllInvites = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.length)) {
            return;
        }
        try {
            yield Promise.all(transformedInvites.map((invite) => __awaiter(void 0, void 0, void 0, function* () {
                const inviteSnap = invite.userInvite;
                yield declineInvite(inviteSnap);
            })));
        }
        catch (err) {
            logAndCaptureException(err, 'Error declining all invites', {
                userId: user.id
            });
        }
    });
    const acceptRequest = (joinRequest) => __awaiter(void 0, void 0, void 0, function* () {
        yield acceptRequestHelper(ctx, [joinRequest]);
    });
    const declineRequest = (joinRequest) => __awaiter(void 0, void 0, void 0, function* () {
        yield declineRequestHelper([joinRequest]);
    });
    const acceptAllRequests = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(transformedOrganizationRequests === null || transformedOrganizationRequests === void 0 ? void 0 : transformedOrganizationRequests.length))
            return;
        yield acceptRequestHelper(ctx, transformedOrganizationRequests);
    });
    const declineAllRequests = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(transformedOrganizationRequests === null || transformedOrganizationRequests === void 0 ? void 0 : transformedOrganizationRequests.length))
            return;
        yield declineRequestHelper(transformedOrganizationRequests);
    });
    const closeDrawer = () => {
        setOpen(!open);
    };
    const header = (React.createElement("div", { className: "text-gray-800 font-semibold text-xl" }, "Notifications"));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "relative" },
            React.createElement("div", null,
                React.createElement("button", { className: "flex inline-block relative bg-white p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", onClick: () => closeDrawer() },
                    React.createElement("span", { className: "sr-only" }, "View notifications"),
                    React.createElement(BellIcon, null),
                    allNotifications > 0 && (React.createElement("span", { className: `pt-0.25 absolute top-0 right-0 block h-4 w-4 rounded-circle bg-yellow-350 items-center justify-center text-gray-750 font-bold text-xxs -mt-1 -mr-1 ${notifications.length > 50 && 'w-6'}` }, allNotifications > 50 ? '50+' : allNotifications)))),
            open && (React.createElement(Drawer, { open: open, closeDrawer: closeDrawer, header: header },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "h-16" },
                        React.createElement("div", { className: "inline-block" },
                            React.createElement("div", { className: 'inline-block mx-6' },
                                React.createElement("button", { onClick: () => setTitle('General'), id: "general", className: `${tableTitleHeadStyles} ${title === 'General' ? highlight : 'text-blue-800'}` },
                                    React.createElement("div", { className: "flex" },
                                        "General",
                                        React.createElement("p", { className: `${tableTitleTabStyles} ${title === 'General'
                                                ? highlightCount
                                                : 'bg-gray-150 text-blue-800'}` }, notifications.length > 50
                                            ? '50+'
                                            : notifications.length)))),
                            React.createElement("div", { className: 'inline-block mx-1' },
                                React.createElement("button", { onClick: () => setTitle('Team Invites'), id: "team-invites", className: `${tableTitleHeadStyles} ${title === 'Team Invites' ? highlight : 'text-gray-800'}` },
                                    React.createElement("div", { className: "flex" },
                                        "Team Invites",
                                        React.createElement("p", { className: `${tableTitleTabStyles} ${title === 'Team Invites'
                                                ? highlightCount
                                                : 'bg-gray-150 text-blue-800'}` }, invitesAndRequestsNotifications > 50
                                            ? '50+'
                                            : invitesAndRequestsNotifications)))))),
                    React.createElement(HorizontalDivider, null),
                    React.createElement("div", null,
                        title === 'General' &&
                            (notifications.length === 0 ? (React.createElement("div", { className: emptyNotificationTrayHeight },
                                React.createElement(EmptyNotificationTray, null))) : (React.createElement("div", { className: "h-75vh max-h-full overflow-y-scroll hide-scrollbar md:pb-5 divide-y" }, listOfGeneralNotifications()))),
                        title === 'Team Invites' &&
                            (invitesAndRequestsNotifications === 0 ? (React.createElement("div", { className: emptyNotificationTrayHeight },
                                React.createElement(EmptyNotificationTray, null))) : (React.createElement("div", { className: "h-75vh max-h-full overflow-y-scroll hide-scrollbar md:pb-5" },
                                renderSnoozedInvitesList(),
                                renderRequestsList(),
                                renderJoinOrganizationRequests())))),
                    title === 'General' && notifications.length > 0 && (React.createElement("div", { className: "absolute bottom-0 px-6 py-3 shadow flex w-full justify-end border-t bg-white" },
                        React.createElement(ColumnButton, { id: "clear-all-notifications", size: "lg", buttonText: "Clear all", loading: clearingNotifications, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setClearingNotifications(true);
                                try {
                                    yield clearAllNotifications();
                                }
                                finally {
                                    setClearingNotifications(false);
                                }
                            }) }))),
                    title === 'Team Invites' &&
                        (invites.length > 0 || joinRequests.length > 0) && (React.createElement("div", { className: "absolute bottom-0 px-6 py-3 shadow w-full flex justify-between border-t bg-white" },
                        React.createElement(ColumnButton, { id: "decline-all-invites", size: "lg", buttonText: "Decline all", loading: decliningInvitesLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setDecliningInvitesLoading(true);
                                try {
                                    yield declineAllInvites();
                                    yield declineAllRequests();
                                }
                                finally {
                                    setDecliningInvitesLoading(false);
                                }
                            }) }),
                        React.createElement(ColumnButton, { id: "accept-all-invites", primary: true, size: "lg", buttonText: "Accept all", loading: acceptingInvitesLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setAcceptingInvitesLoading(true);
                                try {
                                    yield acceptAllInvites();
                                    yield acceptAllRequests();
                                }
                                finally {
                                    setAcceptingInvitesLoading(false);
                                }
                            }) }))))))),
        React.createElement("style", null, `
              button#team-invites:hover p {
                background-color: #EAF5FB;
                color: #2D9BDB;
              }
              
              button#general:hover p {
                background-color: #EAF5FB;
                color: #2D9BDB;
              }
            `)));
};
export default connect(null, mapDispatchToProps)(Notifications);
