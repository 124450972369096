var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import Firebase from 'EnoticeFirebase';
import { getFirebaseContext } from 'utils/firebase';
import { exists } from 'lib/types';
import { guidGenerator } from 'lib/helpers';
import { FIREBASE_PROJECT } from 'lib/constants';
import { ENV, PROD } from '../../constants';
import { handleEnums } from './handleEnums';
const ctx = getFirebaseContext();
/*
  userIdInputHanlde, emailInputHandle, noticeInputHandle,
  customIdInputHandle, refIdInputHandle, invoiceNumInputHandle,
  orgNameInputHandle, orgIdInputHandle

  All the above async functions take some string parameter,
  fetch the user/org/invoice/notice that match that parameter,
  and return a snapshot of that information, if it exists.
*/
const userIdInputHanlde = (userID) => __awaiter(void 0, void 0, void 0, function* () {
    const userSnap = yield ctx.usersRef().doc(userID).get();
    if (!exists(userSnap)) {
        console.log('User Not Found.');
        return;
    }
    return [userSnap];
});
const emailInputHandle = (email) => __awaiter(void 0, void 0, void 0, function* () {
    const userSnap = yield ctx.usersRef().where('email', '==', email).get();
    if (userSnap.empty) {
        console.log('User Not Found.');
        return;
    }
    return [userSnap.docs[0]];
});
const fetchCustomerOrganizations = (advertiserOrganizationID, orgID) => __awaiter(void 0, void 0, void 0, function* () {
    const customerOrganizationsQuery = yield ctx
        .customerOrganizationsRef()
        .where('client', '==', ctx.organizationsRef().doc(advertiserOrganizationID))
        .where('organization', '==', ctx.organizationsRef().doc(orgID))
        .get();
    if (customerOrganizationsQuery.empty) {
        console.log('Customer Organizations Not Found.');
        return [];
    }
    return customerOrganizationsQuery.docs;
});
const fetchCustomers = (userID, orgID) => __awaiter(void 0, void 0, void 0, function* () {
    const customerQuery = yield ctx
        .customersRef()
        .where('user', '==', ctx.usersRef().doc(userID))
        .where('organization', '==', ctx.organizationsRef().doc(orgID))
        .get();
    if (customerQuery.empty) {
        console.log('Customers Not Found.');
        return [];
    }
    return customerQuery.docs;
});
const noticeInputHandle = (noticeID) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeSnap = yield ctx.userNoticesRef().doc(noticeID).get();
    if (!exists(noticeSnap)) {
        console.log('Notice Not Found.');
        return;
    }
    return [noticeSnap];
});
const customIdInputHandle = (customId) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeSnap = yield ctx
        .userNoticesRef()
        .where('customId', '==', customId)
        .limit(10)
        .get();
    if (noticeSnap.empty) {
        console.log('Notice Not Found.');
        return;
    }
    return [...noticeSnap.docs];
});
const refIdInputHandle = (refId) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeSnap = yield ctx
        .userNoticesRef()
        .where('referenceId', '==', refId)
        .limit(10)
        .get();
    if (noticeSnap.empty) {
        console.log('Notice Not Found.');
        return;
    }
    return [...noticeSnap.docs];
});
const invoiceNumInputHandle = (invoiceNum) => __awaiter(void 0, void 0, void 0, function* () {
    const invoiceSnap = yield ctx
        .invoicesRef()
        .where('invoice_number', '==', invoiceNum)
        .limit(10)
        .get();
    if (invoiceSnap.empty) {
        console.log('Invoice Not Found.');
        return;
    }
    return [...invoiceSnap.docs];
});
const orgNameInputHandle = (orgName) => __awaiter(void 0, void 0, void 0, function* () {
    const orgSnap = yield ctx
        .organizationsRef()
        .where('name', '==', orgName)
        .limit(10)
        .get();
    if (orgSnap.empty) {
        console.log('Organization Not Found.');
        return;
    }
    return [...orgSnap.docs];
});
const orgIdInputHandle = (orgID) => __awaiter(void 0, void 0, void 0, function* () {
    const orgSnap = yield ctx.organizationsRef().doc(orgID).get();
    if (!exists(orgSnap)) {
        console.log('Organization Not Found.');
        return;
    }
    return [orgSnap];
});
/*
  Takes in a name and value parameters (i.e. name of a field and its value in Firestore)
  and returns an array of [name, reformatted value, type]
  where type can be a string, referece, array, unkown

  Essentially, the purpose of this function is to simplify Firestore fields
  so that they can be rendered dynamically later on.
*/
function examineField(name, value) {
    const valueType = typeof value;
    // Handling stripe-related fields
    if ((name === 'stripeId' || name === 'id') &&
        valueType === 'string' &&
        value.includes('_')) {
        const stripeType = value.split('_')[0];
        const stripeTypeMap = new Map([
            ['in', 'invoices'],
            ['cus', 'customers'],
            ['pi', 'payments'],
            ['sub', 'subscriptions']
        ]);
        const stripeLinkInitial = ENV === PROD
            ? 'https://dashboard.stripe.com/'
            : 'https://dashboard.stripe.com/test/';
        try {
            const stripeLink = `${stripeLinkInitial}${stripeTypeMap.get(stripeType)}/${value}`;
            return [name, stripeLink, 'stripe'];
        }
        catch (e) {
            // in case stripeTypeMap does not have stripeType
            return [name, value, 'string'];
        }
    }
    if (valueType === 'string' && name !== 'createdBy') {
        if (value !== '') {
            return [name, value.toString(), 'string'];
        }
        return [name, '""', 'string'];
    }
    if (valueType === 'boolean') {
        return [name, value.toString(), 'boolean'];
    }
    if (valueType === 'number') {
        return handleEnums(name, value);
    }
    if (valueType === 'object') {
        if (!value) {
            return [name, 'null', 'string'];
        }
        if (Array.isArray(value)) {
            const returnArr = [];
            // Recursivley call examineField on each element of the array
            for (let i = 0; i < value.length; i++) {
                returnArr.push(`${examineField(name, value[i])[1]} `);
            }
            if (returnArr.length === 0) {
                return [name, '[]', 'string'];
            }
            return [name, returnArr, 'array'];
        }
        if (value.firestore && value._delegate) {
            const path = value._delegate._key.path.segments.slice(5).join('/');
            return [name, path, 'reference'];
        }
        // createdBy is not a reference on Firestore but it should be
        if (name === 'createdBy') {
            return [name, `users/${value}`, 'reference'];
        }
        if ((value.seconds || value.nanoseconds) && value.toDate) {
            return [name, value.toDate().toString(), 'timestamp'];
        }
        // Get the fields & sort them alphabetically
        const fields = Object.keys(value).sort((a, b) => (a > b ? 1 : -1));
        const returnArr = [];
        // Recursively call examineField on each field of the map
        for (let i = 0; i < fields.length; i++) {
            returnArr.push(`${fields[i]}:  ${examineField(name, value[fields[i]])[1]} `);
        }
        return [name, returnArr, 'array'];
    }
    return [name, value, 'unknown'];
}
/*
  Fetches the data of the clicked reference and adds it to infoStack
*/
const handleRef = (path, pushToStack) => __awaiter(void 0, void 0, void 0, function* () {
    const newNode = yield Firebase.firestore().doc(path).get();
    pushToStack(newNode);
});
const CollapsibleFieldBlock = ({ field }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [name, value, type] = field;
    return (React.createElement("div", null,
        React.createElement("p", { className: "inline-block font-bold font-mono text-sm" },
            name,
            ":"),
        React.createElement("span", { className: "pl-1 font-mono text-gray-500 cursor-pointer hover:text-gray-700", onClick: () => setCollapsed(!collapsed) }, collapsed ? '[+]' : '[-]'),
        !collapsed && type === 'string' && (React.createElement("p", { className: "w-full break-all text-sm" }, value)),
        !collapsed && type === 'array' && (React.createElement("ul", null, value.map(f => {
            return (React.createElement("li", { className: "text-sm", key: guidGenerator().slice(0, 5) },
                "-\u00A0 ",
                f));
        })))));
};
/*
  InfoBlock represents each single square block that displays
  the fields of usernotices, rates, adTemplates, etc.
 */
const InfoBlock = ({ nodeSnap, pushToStack, extendInfoArr }) => {
    const nodePath = nodeSnap.ref.path;
    const nodeName = nodePath.split('/')[0];
    const nodeId = nodePath.split('/')[1];
    const retoolId = nodeSnap.id;
    const node = nodeSnap.data();
    // Get the fields & sort them alphabetically
    const fields = Object.keys(node).sort((a, b) => (a > b ? 1 : -1));
    // For displaying "Load Recent 10 Notices" button
    let displayNoticesBtn = false;
    if (nodeName === 'users' || nodeName === 'organizations') {
        displayNoticesBtn = true;
    }
    // To ensure retool link is only displayed for appropriate InfoBlocks
    let displayRetoolLink = false;
    if (nodeName === 'users' ||
        nodeName === 'organizations' ||
        nodeName === 'usernotices') {
        displayRetoolLink = true;
    }
    let displayStripeImg = false;
    let stripeFieldName = '';
    if (fields.includes('id')) {
        displayStripeImg = true;
        stripeFieldName = 'id';
    }
    else if (fields.includes('stripeId')) {
        displayStripeImg = true;
        stripeFieldName = 'stripeId';
    }
    // CHECK: could not get the link for invoice ID for Retool :(
    const retoolLinksMap = new Map([
        [
            'usernotices',
            'https://enotice.retool.com/apps/76dfc020-ea5f-11eb-a808-27af8e1f809b/Admin/Notice%20Detail?noticeId='
        ],
        [
            'users',
            'https://enotice.retool.com/apps/b07b5ec0-ea5f-11eb-8493-2f526915bad5/Admin/User%20Detail?userId='
        ],
        [
            'organizations',
            'https://enotice.retool.com/apps/7f8a60e0-ea5f-11eb-a334-6b465059a2f2/Admin/Organization%20Detail?orgId='
        ]
    ]);
    return (React.createElement("div", { className: "inline-block whitespace-normal w-1/2 overflow-y-scroll overflow-x-hidden border border-gray-500 bg-gray-100 rounded py-2 px-4 center my-12 mx-1 justify-center items-center box-sizing: content-box", style: { height: '600px' }, key: guidGenerator().slice(0, 5) },
        React.createElement("div", { style: { fontWeight: 'bold' }, className: "text-center" },
            ' ',
            nodePath),
        React.createElement("div", null,
            React.createElement("button", { className: `w-full bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-l my-3 ${displayNoticesBtn ? 'block' : 'hidden'}`, onClick: () => {
                    extendInfoArr(nodePath);
                } }, "Load Recent 10 Notices")),
        React.createElement("div", { className: "w-full" },
            React.createElement("p", { className: "underline" }, "Related Links:"),
            React.createElement("div", { className: "inline-block" },
                React.createElement("a", { href: `https://console.firebase.google.com/u/1/project/${FIREBASE_PROJECT}/firestore/data/~2F${nodeName}~2F${nodeId}`, target: "_blank", rel: "noreferrer" },
                    React.createElement("img", { src: "https://firebasestorage.googleapis.com/v0/b/column-iman.appspot.com/o/firebase-logo.png?alt=media&token=e38a6bfb-3b3e-444f-ab66-1f66d186be43", alt: "Firestore", className: "w-24" }))),
            React.createElement("div", { className: "inline-block" },
                React.createElement("a", { href: retoolLinksMap.get(nodeName) + retoolId, className: `inline-block underline ${displayRetoolLink ? 'block' : 'hidden'}`, target: "_blank", rel: "noreferrer" },
                    React.createElement("img", { src: "https://firebasestorage.googleapis.com/v0/b/column-iman.appspot.com/o/retool-logo.png?alt=media&token=b6878bc2-8f23-4cd9-a7f5-a05686ec6104", alt: "Retool", className: "w-24" }))),
            React.createElement("div", { className: `${displayStripeImg ? 'inline-block' : 'hidden'}` },
                React.createElement("a", { href: examineField(stripeFieldName, node[stripeFieldName])[1], target: "_blank", rel: "noreferrer" },
                    React.createElement("img", { src: "https://firebasestorage.googleapis.com/v0/b/column-iman.appspot.com/o/stripe%20logo.png?alt=media&token=b2e8eaf4-716d-4262-a7e0-55400c0c6abc", alt: "Stripe", className: "w-16" })))),
        React.createElement("div", null,
            React.createElement("p", { className: "underline" }, "Firestore Data:")),
        fields.map(f => {
            const fd = examineField(f, node[f]);
            const [name, value, type] = fd;
            if (type === 'boolean' || type === 'enum' || type === 'timestamp') {
                let textColor;
                if (value === 'false') {
                    textColor = 'text-red-600';
                }
                else if (value === 'true') {
                    textColor = 'text-green-700';
                }
                else if (type === 'timestamp') {
                    textColor = 'text-orange-600';
                }
                else {
                    textColor = 'text-pink-800';
                }
                return (React.createElement("div", { className: "w-auto", key: name },
                    React.createElement("p", { className: "inline-block font-bold font-mono text-sm " },
                        name,
                        ":\u00A0"),
                    React.createElement("p", { className: `inline-block break-all ${textColor}` }, value)));
            }
            if (type === 'string') {
                if (name.endsWith('URL') ||
                    name.endsWith('Url') ||
                    name.endsWith('Html')) {
                    return (React.createElement("div", { key: name },
                        React.createElement(CollapsibleFieldBlock, { field: fd })));
                }
                return (React.createElement("div", { className: "w-auto", key: name },
                    React.createElement("p", { className: "inline-block font-bold font-mono text-sm " },
                        name,
                        ":\u00A0"),
                    React.createElement("p", { className: `inline-block break-all ${value === 'null' ? 'text-red-600' : 'text-blue-600'}` }, value)));
            }
            if (type === 'reference') {
                return (React.createElement("div", { key: name },
                    React.createElement("p", { className: "inline-block font-bold font-mono text-sm" },
                        name,
                        ":\u00A0"),
                    React.createElement("button", { className: "underline inline-block text-purple-700", onClick: () => {
                            void handleRef(value, pushToStack);
                        } }, value)));
            }
            if (type === 'array') {
                return (React.createElement("div", { className: "w-full", key: guidGenerator().slice(0, 5) },
                    React.createElement(CollapsibleFieldBlock, { field: fd })));
            }
            if (type === 'stripe') {
                return (React.createElement("div", { className: "w-full", key: name },
                    React.createElement("p", { className: "inline-block font-bold font-mono text-sm" },
                        name,
                        ":\u00A0"),
                    React.createElement("a", { href: value, target: "_blank", className: "break-all underline text-gray-700", rel: "noreferrer" }, value)));
            }
            return React.createElement("div", { key: name }, "unknown");
        })));
};
/*
  InfoRow essentially represents a row that can have one or more square blocks
  of information (i.e. one or more InfoBlocks)
*/
const InfoRow = ({ doc, infoArr, setInfoArr }) => {
    /*
      infoStack is an array of all the InfoBlock data
      (more precisely, array of Firestore snapshots) within one InfoRow
    */
    const [infoStack, setInfoStack] = useState([doc]);
    /*
      pushToStack expands the infoStack when necessary
      (i.e. when a reference is clicked and we need to
        disaply the corresponding information of that reference)
    */
    const pushToStack = (node) => {
        const newStack = [node, ...infoStack];
        setInfoStack(newStack);
    };
    /*
      extendInfoArr fetches last 10 recent notices for organizations
      and users blocks and appends them to infoArr to display each notice in its own InfoRow
    */
    const extendInfoArr = (path) => __awaiter(void 0, void 0, void 0, function* () {
        let newNodes;
        const noticesLimit = 10;
        const collectionName = path.split('/')[0];
        if (collectionName === 'organizations') {
            newNodes = yield ctx
                .userNoticesRef()
                .where('newspaper', '==', `/${path}`)
                .orderBy('createTime', 'desc')
                .limit(noticesLimit)
                .get();
        }
        else if (collectionName === 'users') {
            newNodes = yield ctx
                .userNoticesRef()
                .where('userId', '==', path.split('/')[1])
                .orderBy('createTime', 'desc')
                .limit(noticesLimit)
                .get();
        }
        if (newNodes) {
            setInfoArr([...infoArr, ...newNodes.docs]);
        }
    });
    return (React.createElement("div", { className: "overflow-x-scroll w-10/12 transform translate-x-32 " },
        React.createElement("div", { className: "whitespace-no-wrap" }, infoStack.map(element => {
            return (React.createElement(InfoBlock, { key: guidGenerator().slice(0, 5), nodeSnap: element, pushToStack: pushToStack, extendInfoArr: extendInfoArr }));
        }))));
};
const Debugger = () => {
    const [userEmail, setEmail] = useState('');
    const [noticeID, setNoticeID] = useState('');
    const [customID, setCustomID] = useState('');
    const [userID, setUserID] = useState('');
    const [refID, setRefID] = useState('');
    const [invoiceNum, setInvoiceNum] = useState('');
    const [orgID, setOrgID] = useState('');
    const [advertiserOrganizationID, setAdvertiserOrganizationID] = useState('');
    const [orgName, setOrgName] = useState('');
    const [infoArr, setInfoArr] = useState([]);
    const handleEmailOnChange = (event) => {
        setEmail(event.target.value);
    };
    const handleNoticeOnChange = (event) => {
        setNoticeID(event.target.value);
    };
    const handleCustomOnChange = (event) => {
        setCustomID(event.target.value);
    };
    const handleUserIdOnChange = (event) => {
        setUserID(event.target.value);
    };
    const handleRefOnChange = (event) => {
        setRefID(event.target.value);
    };
    const handleInvoiceOnChange = (event) => {
        setInvoiceNum(event.target.value);
    };
    const handleOrgIdOnChanlge = (event) => {
        setOrgID(event.target.value);
    };
    const handleAdvertiserOrganizationIdOnChange = (event) => {
        setAdvertiserOrganizationID(event.target.value);
    };
    const handleOrgNameOnChange = (event) => {
        setOrgName(event.target.value);
    };
    const handleCustomerOrganizationsSearchClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setInfoArr([]);
        if (!orgID || !advertiserOrganizationID) {
            return;
        }
        const data = yield fetchCustomerOrganizations(advertiserOrganizationID, orgID);
        setInfoArr(data);
    });
    const handleCustomersSearchClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setInfoArr([]);
        if (!orgID || !userID) {
            return;
        }
        const data = yield fetchCustomers(userID, orgID);
        setInfoArr(data);
    });
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        // Set InfoArr to [] to remove previous information every time user clicks 'Submit'
        setInfoArr([]);
        let data;
        if (userEmail) {
            data = yield emailInputHandle(userEmail.trim());
        }
        else if (noticeID) {
            data = yield noticeInputHandle(noticeID.trim());
        }
        else if (customID) {
            data = yield customIdInputHandle(customID.trim());
        }
        else if (userID) {
            data = yield userIdInputHanlde(userID.trim());
        }
        else if (refID) {
            data = yield refIdInputHandle(refID.trim());
        }
        else if (invoiceNum) {
            data = yield invoiceNumInputHandle(invoiceNum.trim());
        }
        else if (orgName) {
            data = yield orgNameInputHandle(orgName.trim());
        }
        else if (orgID) {
            data = yield orgIdInputHandle(orgID.trim());
        }
        else {
            return;
        }
        // If no information found for the input field
        if (!data) {
            return;
        }
        setInfoArr(data);
    });
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("div", { className: "mt-5 flex items-center justify-center bg-white px-4 sm:px-6 lg:px-8" },
                React.createElement("div", { className: "w-full" },
                    React.createElement("div", null,
                        React.createElement("img", { src: "https://firebasestorage.googleapis.com/v0/b/column-iman.appspot.com/o/image_6487327%20(1).JPG?alt=media&token=e9b45848-b79b-4ef2-9513-fdf9b95641d7", alt: "Column", className: "mx-auto h-auto w-1/2" })))),
            React.createElement("div", { className: "w-full max-w-s bg-gray-50" },
                React.createElement("form", { className: "bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" },
                    React.createElement("div", { className: "flex" },
                        React.createElement("div", { className: "p-1 flex-1 mb-4" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "User Email"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "userEmail", type: "text", value: userEmail, onChange: handleEmailOnChange, placeholder: "User Email" })),
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "User ID"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "userID", type: "User ID", value: userID, onChange: handleUserIdOnChange, placeholder: "User ID" })),
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Notice ID"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "noticeID", type: "Notice ID", value: noticeID, onChange: handleNoticeOnChange, placeholder: "Notice ID" })),
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Notice Name"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "refID", type: "reference ID", value: refID, onChange: handleRefOnChange, placeholder: "Notice Name" }))),
                    React.createElement("div", { className: "flex" },
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Custom ID"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "customID", type: "Custom ID", value: customID, onChange: handleCustomOnChange, placeholder: "Custom ID" })),
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Invoice Number"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "invoiceNum", type: "Invoice Number", value: invoiceNum, onChange: handleInvoiceOnChange, placeholder: "Invoice Number" })),
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Organization Name"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "orgName", type: "Org Name", value: orgName, onChange: handleOrgNameOnChange, placeholder: "Organization Name" })),
                        React.createElement("div", { className: "p-1 flex-1 mb-6" },
                            React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Organization ID"),
                            React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "orgID", type: "orgID", value: orgID, onChange: handleOrgIdOnChanlge, placeholder: "Organization ID" }))),
                    React.createElement("div", { className: "flex items-center justify-center" },
                        React.createElement("button", { className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline", id: "submit", type: "button", onClick: handleClick }, "Search")))),
            React.createElement("div", null,
                React.createElement("div", { className: "mt-12 flex items-center justify-center bg-white px-4 sm:px-6 lg:px-8" },
                    React.createElement("div", { className: "w-full" },
                        React.createElement("div", { className: "text-center font-bold text-2xl" }, "Find Customer(s)"))),
                React.createElement("div", { className: "w-full max-w-s bg-gray-50" },
                    React.createElement("form", { className: "bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" },
                        React.createElement("div", { className: "flex" },
                            React.createElement("div", { className: "p-1 flex-1 mb-4" },
                                React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "User ID"),
                                React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "userID", type: "text", value: userID, onChange: handleUserIdOnChange, placeholder: "User ID" })),
                            React.createElement("div", { className: "p-1 flex-1 mb-6" },
                                React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Newspaper ID"),
                                React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "newspaperID", type: "Newspaper ID", value: orgID, onChange: handleOrgIdOnChanlge, placeholder: "Newspaper ID" }))),
                        React.createElement("div", { className: "flex items-center justify-center" },
                            React.createElement("button", { className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline", id: "submit", type: "button", onClick: handleCustomersSearchClick }, "Search"))))),
            React.createElement("div", null,
                React.createElement("div", { className: "mt-12 flex items-center justify-center bg-white px-4 sm:px-6 lg:px-8" },
                    React.createElement("div", { className: "w-full" },
                        React.createElement("div", { className: "text-center font-bold text-2xl" }, "Find Customer Organization(s)"))),
                React.createElement("div", { className: "w-full max-w-s bg-gray-50" },
                    React.createElement("form", { className: "bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" },
                        React.createElement("div", { className: "flex" },
                            React.createElement("div", { className: "p-1 flex-1 mb-4" },
                                React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Advertiser Organization ID"),
                                React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "advertiserOrganizationID", type: "text", value: advertiserOrganizationID, onChange: handleAdvertiserOrganizationIdOnChange, placeholder: "Advertiser Organization ID" })),
                            React.createElement("div", { className: "p-1 flex-1 mb-6" },
                                React.createElement("div", { className: "text-gray-700 text-sm font-bold mb-2" }, "Newspaper ID"),
                                React.createElement("input", { className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", id: "newspaperID", type: "Newspaper ID", value: orgID, onChange: handleOrgIdOnChanlge, placeholder: "Newspaper ID" }))),
                        React.createElement("div", { className: "flex items-center justify-center" },
                            React.createElement("button", { className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline", id: "submit", type: "button", onClick: handleCustomerOrganizationsSearchClick }, "Search")))))),
        infoArr.map((doc, index) => {
            return (React.createElement(InfoRow, { key: index, doc: doc, infoArr: infoArr, setInfoArr: setInfoArr }));
        })));
};
export default Debugger;
