import React, { useState } from 'react';
import { getLocationParams } from 'utils/urls';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import OrganizationMemberSettings from './OrganizationMemberSettings';
import { HomeIcon, Members, Dollar } from './icons';
import UserSettingsBankAccounts from './userSettingsBankAccounts';
export const GENERAL_INFO = 'general-info';
export const ORGANIZATION_MEMBER_SETTINGS = 'members';
export const BANK_ACCOUNTS = 'bank-accounts';
const AdvertiserOrganizationSettings = ({ activeOrganization, user, infoSettingsName, infoSettingsTab }) => {
    const [view, setView] = useState(getLocationParams().get('tab') ||
        (userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO)
            ? GENERAL_INFO
            : ORGANIZATION_MEMBER_SETTINGS));
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/organization?tab=${view}`);
        setView(view);
    };
    const renderTab = (tab, tabLabel, icon) => (React.createElement("div", { className: `flex rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tab && 'bg-gray-200'}`, onClick: () => updateView(tab), id: tab },
        React.createElement("div", { className: "flex justify-center w-3/12" }, icon),
        React.createElement("div", { className: "w-9/12" }, tabLabel)));
    // We only show bank accounts in the organization settings for advertisers
    // that have the Admin or Billing role in their organization.
    const showBankAccountsTab = userHasPermission(user, Permissions.BANK_ACCOUNTS_WRITE);
    const renderSideBar = () => (React.createElement("div", { className: "bg-white rounded border border-gray-300 pt-3 px-4", style: {
            height: 'calc(100vh - 150px)'
        } },
        userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
            renderTab(GENERAL_INFO, infoSettingsName, HomeIcon),
        renderTab(ORGANIZATION_MEMBER_SETTINGS, 'Members', Members),
        showBankAccountsTab && renderTab(BANK_ACCOUNTS, 'Bank Accounts', Dollar)));
    const tabs = {
        [GENERAL_INFO]: infoSettingsTab({ activeOrganization }),
        [ORGANIZATION_MEMBER_SETTINGS]: (React.createElement(OrganizationMemberSettings, { activeOrganization: activeOrganization, user: user }))
    };
    if (showBankAccountsTab) {
        tabs[BANK_ACCOUNTS] = React.createElement(UserSettingsBankAccounts, { user: user });
    }
    return (React.createElement("div", { className: "flex" },
        renderSideBar(),
        React.createElement("main", { className: "flex-grow px-4" }, tabs[view])));
};
export default AdvertiserOrganizationSettings;
