import React from 'react';
import classNames from 'classnames';
export function Alert({ id, title, description, icon, children, status, onDismiss }) {
    const alertBaseClasses = classNames('flex space-between p-4 rounded-lg text-sm', {
        'bg-gray-50 text-grey-400': !status,
        'bg-primary-25 text-primary-600': status === 'info',
        'bg-red-50 text-red-600': status === 'error',
        'bg-yellow-50 text-yellow-600': status === 'warning',
        'bg-green-50 text-green-600': status === 'success'
    });
    const alertTitleClasses = classNames('font-medium', {
        'text-grey-500': !status,
        'text-primary-700': status === 'info',
        'text-red-700': status === 'error',
        'text-yellow-700': status === 'warning',
        'text-green-700': status === 'success'
    });
    const labelProps = {
        role: onDismiss ? 'alertdialog' : 'alert',
        'aria-labelledby': labelId(id),
        'aria-describedby': descriptionId(id)
    };
    return (React.createElement("div", Object.assign({}, labelProps, { className: alertBaseClasses }),
        icon && React.createElement("div", { className: "pr-3 self-center" }, icon),
        title && (React.createElement("h1", { id: labelId(id), className: alertTitleClasses }, title)),
        React.createElement("p", { id: descriptionId(id) }, description),
        children));
}
function labelId(id) {
    return `alert-${id}-label`;
}
function descriptionId(id) {
    return `alert-${id}-description`;
}
