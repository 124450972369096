var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
export const getFilerFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const filer = yield notice.data().filer.get();
    if (!exists(filer)) {
        throw new Error(`Filer does not exist for notice: ${notice.id}.`);
    }
    return filer;
});
export const getNewspaperFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield notice.data().newspaper.get();
    if (!exists(newspaper)) {
        throw new Error(`Newspaper does not exist for notice: ${notice.id}.`);
    }
    return newspaper;
});
export const getNewspaperParentOrg = (newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return yield ((_a = newspaper.data().parent) === null || _a === void 0 ? void 0 : _a.get());
});
export const getInvoiceFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const invoice = yield ((_b = notice.data().invoice) === null || _b === void 0 ? void 0 : _b.get());
    if (!exists(invoice)) {
        throw new Error(`Invoice does not exist for notice: ${notice.id}.`);
    }
    return invoice;
});
