import React, { forwardRef } from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { CircularProgress, Button } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import { DeleteIcon } from 'icons';
const tableIcons = {
    Add: forwardRef((props, ref) => (React.createElement(AddBox, Object.assign({}, props, { ref: ref })))),
    Check: forwardRef((props, ref) => (React.createElement(Check, Object.assign({ color: "primary" }, props, { ref: ref })))),
    Clear: forwardRef((props, ref) => (React.createElement(Clear, Object.assign({}, props, { ref: ref })))),
    Delete: forwardRef(() => React.createElement(DeleteIcon, { alt: "trash" })),
    Edit: forwardRef(() => (React.createElement(Button, { variant: "outlined", color: "primary" }, "Edit"))),
    DetailPanel: forwardRef((props, ref) => (React.createElement(ChevronRight, Object.assign({}, props, { ref: ref })))),
    Filter: forwardRef((props, ref) => (React.createElement(FilterList, Object.assign({}, props, { ref: ref })))),
    FirstPage: forwardRef((props, ref) => (React.createElement(FirstPage, Object.assign({}, props, { ref: ref })))),
    LastPage: forwardRef((props, ref) => (React.createElement(LastPage, Object.assign({}, props, { ref: ref })))),
    NextPage: forwardRef((props, ref) => (React.createElement(ChevronRight, Object.assign({}, props, { ref: ref })))),
    PreviousPage: forwardRef((props, ref) => (React.createElement(ChevronLeft, Object.assign({}, props, { ref: ref })))),
    ResetSearch: forwardRef((props, ref) => (React.createElement(Clear, Object.assign({}, props, { ref: ref })))),
    Search: forwardRef((props, ref) => (React.createElement(Search, Object.assign({}, props, { ref: ref })))),
    SortArrow: forwardRef((props, ref) => (React.createElement(ArrowUpward, Object.assign({}, props, { ref: ref })))),
    ThirdStateCheck: forwardRef((props, ref) => (React.createElement(Remove, Object.assign({}, props, { ref: ref })))),
    ViewColumn: forwardRef((props, ref) => (React.createElement(ViewColumn, Object.assign({}, props, { ref: ref })))),
    CheckCircle: forwardRef((props, ref) => (React.createElement(CheckCircle, Object.assign({}, props, { ref: ref })))),
    CircularProgress: forwardRef((props, ref) => (React.createElement(CircularProgress, Object.assign({ size: 25 }, props, { ref: ref }))))
};
export default tableIcons;
