var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { dwollaInvalidPasswordMessage, dwollaPasswordValidation } from 'lib/passwordValidators';
import PasswordField from 'components/PasswordField';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';
const DwollaPasswordResetModal = ({ user, userAuth, fromMainView, passwordUpdateSuccess, setDwollaPasswordModal }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [isDisabled, setDisabled] = useState(true);
    const [currPassword, setCurrPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const [validationError, setValidationError] = useState();
    const [passwordMatchingError, setPasswordMatchingError] = useState();
    const inputTextStyle = 'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';
    const matchPassword = () => !error && !validationError;
    useEffect(() => {
        if (newPassword && !dwollaPasswordValidation.test(newPassword)) {
            setValidationError(dwollaInvalidPasswordMessage);
            return;
        }
        setValidationError('');
    }, [newPassword]);
    useEffect(() => {
        if (confirmNewPassword && newPassword) {
            if (newPassword.length === confirmNewPassword.length &&
                newPassword === confirmNewPassword) {
                setError('');
                if (dwollaPasswordValidation.test(newPassword)) {
                    setDisabled(false);
                }
            }
            else {
                setError('Passwords do not match.');
                setDisabled(true);
            }
        }
    }, [confirmNewPassword, newPassword]);
    if (!userAuth) {
        console.error('UserAuth is undefined');
        return React.createElement(React.Fragment, null);
    }
    const changePassword = () => __awaiter(void 0, void 0, void 0, function* () {
        if (matchPassword() && currPassword && newPassword) {
            setLoading(true);
            const { currentUser } = Firebase.auth();
            if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.email)) {
                console.error('Current User`s email is undefined');
                return;
            }
            const credentials = FirebaseAuth.EmailAuthProvider.credential(currentUser.email, currPassword);
            try {
                const userAuthResponse = yield userAuth.reauthenticateWithCredential(credentials);
                if (userAuthResponse) {
                    currentUser
                        .updatePassword(newPassword)
                        .then(() => __awaiter(void 0, void 0, void 0, function* () {
                        yield user.ref.update({
                            dwollaPasswordSecurityEnabled: true
                        });
                        setLoading(false);
                        if (passwordUpdateSuccess) {
                            passwordUpdateSuccess();
                        }
                    }))
                        .catch((error) => {
                        setError(error.message);
                        setLoading(false);
                    });
                }
            }
            catch (error) {
                setPasswordMatchingError(error.message);
                setLoading(false);
            }
        }
    });
    return (React.createElement("div", null,
        React.createElement(FreeformCModal, { header: 'Update Your Column Password', setOpen: fromMainView || !setDwollaPasswordModal
                ? undefined
                : () => setDwollaPasswordModal(), customStyle: 'px-2', noExitOutsideModal: true, width: '30%' },
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    void changePassword();
                } },
                React.createElement("div", { className: "overflow-y-auto py-2 mt-2 px-2" },
                    React.createElement("div", { className: "text-gray-700 mb-6 text-sm -mt-3" },
                        fromMainView
                            ? "Another user recently linked a bank account in your newspaper's payment settings. To increase the security of your account, please change your Column password."
                            : 'Before linking your bank account, please change your Column password to increase the security of your account.',
                        ' ',
                        ' Your new password should be at least 12 characters long and include a letter, number and symbol.'),
                    React.createElement("div", null,
                        React.createElement(PasswordField, { id: "current-password", required: true, value: currPassword || '', className: inputTextStyle, placeHolderText: "Current password *", onValueChange: (e) => setCurrPassword(e.target.value) }),
                        passwordMatchingError && (React.createElement("div", { className: "text-red-500 text-sm mb-3", id: "currentPassword" }, passwordMatchingError)),
                        React.createElement(PasswordField, { id: "new-password", required: true, value: newPassword || '', className: inputTextStyle, placeHolderText: "New password *", onValueChange: (e) => setNewPassword(e.target.value) }),
                        validationError && (React.createElement("div", { className: "text-red-500 text-sm mb-3" }, validationError)),
                        React.createElement(PasswordField, { id: "confirm-new-password", required: true, value: confirmNewPassword || '', className: inputTextStyle, placeHolderText: "Confirm new password *", onValueChange: (e) => setConfirmNewPassword(e.target.value) }),
                        error && (React.createElement("div", { className: "text-red-500 text-sm my-3" }, error)),
                        React.createElement("button", { className: `mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${isDisabled
                                ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                                : 'bg-blue-500'}`, type: "submit", disabled: isDisabled || loading },
                            React.createElement("span", { className: "flex" },
                                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Confirm"))))))));
};
export default DwollaPasswordResetModal;
