import { fork } from 'redux-saga/effects';
import ErrorSaga from './ErrorSaga';
import AuthSaga from './AuthSaga';
import SetupSaga from './SetupSaga';
import RoutingSaga from './RoutingSaga';
import PlacementSaga from './PlacementSaga';
import ToastSaga from './ToastSaga';
import NoticeSaga from './NoticeSaga';
export default function* root() {
    yield fork(SetupSaga);
    yield fork(ErrorSaga);
    yield fork(AuthSaga);
    yield fork(RoutingSaga);
    yield fork(PlacementSaga);
    yield fork(ToastSaga);
    yield fork(NoticeSaga);
}
