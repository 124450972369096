export const DEFAULT_LINER_MIN_COLUMNS = 1;
export const DEFAULT_DISPLAY_MIN_COLUMNS = 1;
export const DEFAULT_LINER_MAX_COLUMNS = 3;
export const DEFAULT_DISPLAY_MAX_COLUMNS = 6;
/**
 * The getMinAndMaxColumnsForPaper function determines the minimum
 * and maximum number of columns allowed for a given paper, conditioned
 * on whether or not that notice is a disply notice. Conditioning on whether
 * or not is a display notice is important as papers can have different requirements.
 *
 * @param {ESnapshotExists<EOrganization>} newspaper the newspaper we are determining params for
 * @param {boolean} isDisplay whether or not it is a display notice
 * @returns {minColumns: number, maxColumns: number} object with min and max columns
 */
export const getMinAndMaxColumnsForPaper = (newspaper, isDisplay) => {
    const { linerMaxColumns, linerMinColumns, displayMaxColumns, displayMinColumns } = (newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) || {};
    let minColumns;
    if (isDisplay)
        minColumns = displayMinColumns || DEFAULT_DISPLAY_MIN_COLUMNS;
    else
        minColumns = linerMinColumns || DEFAULT_LINER_MIN_COLUMNS;
    let maxColumns;
    if (isDisplay)
        maxColumns = displayMaxColumns || DEFAULT_DISPLAY_MAX_COLUMNS;
    else
        maxColumns = linerMaxColumns || DEFAULT_LINER_MAX_COLUMNS;
    // ensure that the max number of columns is *at least* the minimum number
    // of columns
    maxColumns = Math.max(minColumns, maxColumns);
    return {
        minColumns,
        maxColumns
    };
};
