var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState, useRef } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { appendToCurrentParams, getLocationParams, getSubdomain, updateHistoryNoRerender } from 'utils/urls';
import { OccupationType, NoticeType, State, NoticeStatusType, OrganizationType } from 'lib/enums';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import { push } from 'connected-react-router';
import { getNoticeType } from 'lib/helpers';
import FreeformCModal from 'components/modals/FreeFormCModal';
import EToast from 'components/EToast';
import { history } from 'redux/router';
import PlacementLoginModal from 'components/modals/PlacementLoginModal';
import { getFirebaseContext } from 'utils/firebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import ContentPreparedStep from './ContentPreparedStep';
import NoticeContentStep from './NoticeContentStep';
import ConfirmNewspaperStep from './ConfirmNewspaperStep';
import PlaceNoticePreview from './PlaceNoticePreview';
import ConfirmSchedule from './ConfirmSchedule';
import AffidavitRecipientsStep from './AffidavitRecipientsStep';
import ConfirmProofStep from './ConfirmProofStep';
import ConfirmFilerStep from './ConfirmFilerStep';
import ReferenceIdStep from './ReferenceIdStep';
import ConfirmNoticeTypeStep from './ConfirmNoticeTypeStep';
import InvoiceRecipientStep from './InvoiceRecipientStep';
import AccountInformationStep from './AccountInformationStep';
import { CONFIRM_NEWSPAPER, MAILED_AFFIDAVIT, CONFIRM_PROOF, CONTENT_PREPARED, CONFIRM_NOTICE_TYPE, CONFIRM_SCHEDULE, CONFIRM_AD, INVOICE_RECIPIENT, REFERENCE_ID, CONFIRM_FILER, BIDDY_PLANROOM, getAdvertiserOrder, getPublisherOrder, NOTICE_BY_MAIL, ACCOUNT_INFO, isAnonymousFlow } from './helpers';
import BiddyPlanroom, { shouldShowBiddyIntegrationToUser } from './BiddyPlanroom';
import NoticeByMailStep, { shouldShowNoticeByMail } from './NoticeByMailStep';
import { useShouldShowAccountInfoStep } from './hooks/useShouldShowAccountInfoStep';
const maxStepPadding = 40;
const minStepPadding = 24;
const styles = (theme) => createStyles({
    colorColumn: {
        backgroundColor: '#2A394A',
        right: 0,
        bottom: 0,
        height: '100vh',
        maxWidth: '33%',
        maxHeight: '100vh',
        transition: 'max-width .5s',
        position: 'relative',
        flexWrap: 'nowrap',
        overflowY: 'scroll',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'hidden',
            whiteSpace: 'normal'
        }
    },
    colorColumnContainer: {
        minWidth: 'max-content'
    },
    navSpacer: Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: theme.spacing(3), width: '100%' }, theme.mixins.toolbar),
    mainContainer: {
        flexWrap: 'nowrap'
    },
    formContainer: {
        position: 'relative',
        paddingLeft: maxStepPadding,
        paddingRight: maxStepPadding,
        maxHeight: '100vh',
        overflowY: 'scroll',
        flexWrap: 'nowrap',
        '& > div:not(:first-child)': {
            marginTop: theme.spacing(4)
        },
        transition: 'padding .5s',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'hidden',
            whiteSpace: 'normal'
        }
    },
    stepButton: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary[700]
        },
        color: 'white'
    },
    stepButtonInactive: {
        opacity: 0.5
    },
    uploadModal: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        textAlign: 'center',
        outline: 'none',
        overflow: 'unset',
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
        color: 'white',
        opacity: 1,
        zIndex: 4500
    }
});
const getSteps = (user, orgContext, newspaper, noticeType, isEditing, shouldShowAccountIDInPlacement) => {
    var _a;
    const isPublisher = ((_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.occupation) === OccupationType.publishing.value;
    const getOrder = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        let order;
        const onSubDomain = getSubdomain() === ((_a = orgContext === null || orgContext === void 0 ? void 0 : orgContext.data()) === null || _a === void 0 ? void 0 : _a.subdomain);
        const hasTypeform = ((isPublisher || onSubDomain) &&
            exists(orgContext) &&
            !!((_b = State.by_value(orgContext.data().state)) === null || _b === void 0 ? void 0 : _b.hasTypeform)) ||
            (exists(newspaper) &&
                !!((_c = State.by_value(newspaper.data().state)) === null || _c === void 0 ? void 0 : _c.hasTypeform));
        const isNewspaperPlaceLink = exists(orgContext) &&
            orgContext.data().organizationType === OrganizationType.newspaper.value;
        // We should prioritize the placement flow steps for the newspaper if it exists
        const hasNoticeTypeSelect = exists(newspaper)
            ? (_d = newspaper.data().allowedNotices) === null || _d === void 0 ? void 0 : _d.length
            : (_f = (_e = orgContext === null || orgContext === void 0 ? void 0 : orgContext.data()) === null || _e === void 0 ? void 0 : _e.allowedNotices) === null || _f === void 0 ? void 0 : _f.length;
        const isCustomNoticeType = noticeType === NoticeType.custom.value ||
            noticeType === NoticeType.display_ad.value ||
            !((_j = (_h = (_g = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _g === void 0 ? void 0 : _g.allowedNotices) === null || _h === void 0 ? void 0 : _h.find(an => an.value === noticeType)) === null || _j === void 0 ? void 0 : _j.typeform);
        const hasInvoiceMailing = !!((_k = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _k === void 0 ? void 0 : _k.enableInvoiceMailing);
        if (isPublisher) {
            order = getPublisherOrder({
                user,
                hasInvoiceMailing,
                hasNoticeTypeSelect,
                hasTypeform,
                isEditing
            });
        }
        else {
            const showAccountInfoStep = exists(newspaper) && shouldShowAccountIDInPlacement && !isEditing;
            order = getAdvertiserOrder({
                user,
                isNewspaperPlaceLink,
                hasNoticeTypeSelect,
                hasTypeform,
                isCustomNoticeType,
                isEditing,
                showAccountInfoStep
            });
        }
        // Potentially insert the biddy planroom after the confirm schedule
        // step for procurement users. Project documentation from the R&D team
        // is available here: https://www.notion.so/column/Biddy-Column-Partnership-Plan-486b87c065f14dbc9686b32536e09e9e
        if (shouldShowBiddyIntegrationToUser(user)) {
            const publicationDateStep = order.indexOf(CONFIRM_SCHEDULE);
            order.splice(publicationDateStep, 0, BIDDY_PLANROOM);
        }
        if (shouldShowNoticeByMail(user)) {
            const publicationDateStep = order.indexOf(CONFIRM_SCHEDULE);
            order.splice(publicationDateStep, 0, NOTICE_BY_MAIL);
        }
        // Remove affidavit step if disabled on newspaper
        if ((_l = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _l === void 0 ? void 0 : _l.affidavitDisabled) {
            const affidavitIndex = order.indexOf(MAILED_AFFIDAVIT);
            if (affidavitIndex > -1) {
                order.splice(affidavitIndex, 1);
            }
        }
        return order;
    };
    const order = getOrder();
    return order.reduce((acc, stepName, i) => {
        acc[stepName] = {
            id: stepName,
            next: order[i + 1],
            previous: order[i - 1]
        };
        // We group CONFIRM_PROOF with MAILED_AFFIDAVIT to make UI work
        if (stepName === CONFIRM_PROOF) {
            acc[stepName].previous = order[i - 2];
        }
        return acc;
    }, {});
};
const PlaceNotice = ({ classes, id, theme, isPublisher, orgContext, user, isAnonymous, push, authLoading, userAuth, placementActions, authActions, placement, toastActions, toastText }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [current, setCurrent] = useState();
    const [noticeState, setNoticeState] = useState(null);
    const [STEPS, setSteps] = useState({});
    const [isTypeform, setIsTypeform] = useState(false);
    const [isMadlib, setIsMadlib] = useState(false);
    const [uploading, setUploading] = useState(false);
    // uploaded file state
    const [uploadedFile, setUploadedFile] = useState(null);
    const [simultaneousActivity, setSimultaneousActivity] = useState();
    const [showSubmitFileWithoutFormattingModal, setShowSubmitFileWithoutFormattingModal] = useState(false);
    const [newspaperUnsub, setNewspaperUnsub] = useState();
    const [userEmail, setUserEmail] = useState(null);
    const [showEnterPasswordModal, setShowEnterPasswordModal] = useState(false);
    const shouldShowAccountIDInPlacement = useShouldShowAccountInfoStep({
        noticeState
    });
    const disableNewAnonymousPlacement = getBooleanFlag(LaunchDarklyFlags.DISABLE_NEW_ANONYMOUS_PLACEMENT, true);
    /*
    Part that involves the management of steps
    */
    const refs = {
        [CONFIRM_NEWSPAPER]: {
            container: useRef(null)
        },
        [CONTENT_PREPARED]: {
            container: useRef(null)
        },
        [CONFIRM_NOTICE_TYPE]: {
            container: useRef(null)
        },
        [CONFIRM_SCHEDULE]: {
            container: useRef(null)
        },
        [BIDDY_PLANROOM]: {
            container: useRef(null)
        },
        [NOTICE_BY_MAIL]: {
            container: useRef(null)
        },
        [CONFIRM_AD]: {
            container: useRef(null),
            step: useRef(null)
        },
        [INVOICE_RECIPIENT]: {
            container: useRef(null),
            step: useRef(null)
        },
        [MAILED_AFFIDAVIT]: {
            container: useRef(null),
            step: useRef(null)
        },
        [CONFIRM_PROOF]: {
            container: useRef(null),
            step: useRef(null)
        },
        [REFERENCE_ID]: {
            container: useRef(null),
            step: useRef(null)
        },
        [CONFIRM_FILER]: {
            container: useRef(null),
            step: useRef(null)
        },
        [ACCOUNT_INFO]: {
            container: useRef(null),
            step: useRef(null)
        }
    };
    const formContainer = useRef();
    const navSpacer = useRef();
    const ctx = getFirebaseContext();
    function scrollToRef(ref) {
        if (!formContainer.current || !ref.current || !navSpacer.current)
            return;
        const navStyle = window.getComputedStyle(navSpacer.current);
        const navHeight = parseFloat(navStyle.getPropertyValue('height'));
        formContainer.current.scrollTo({
            top: ref.current.offsetTop - navHeight - theme.spacing(2),
            behavior: 'smooth'
        });
    }
    const next = () => {
        var _a, _b;
        const userOccupation = (_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.occupation;
        if (userOccupation === OccupationType.government_official.value ||
            userOccupation === OccupationType.lawyer.value ||
            userOccupation === OccupationType.other_organization.value) {
            if (!placement.filedBy) {
                placementActions.setFiledBy((_b = user === null || user === void 0 ? void 0 : user.data()) === null || _b === void 0 ? void 0 : _b.activeOrganization);
            }
        }
        if (current) {
            const nextStepId = STEPS[current.id].next;
            if (nextStepId) {
                setCurrent(STEPS[nextStepId]);
            }
        }
        placementActions.saveDraft();
    };
    const previous = () => {
        var _a, _b;
        if (!isPublisher &&
            placement.editing &&
            noticeState &&
            ((_a = noticeState.notice.data()) === null || _a === void 0 ? void 0 : _a.invoice) &&
            !((_b = noticeState.newspaper) === null || _b === void 0 ? void 0 : _b.data().postInvoiceEdit) &&
            current &&
            STEPS[current.id].id === CONFIRM_AD &&
            STEPS[current.id].previous === CONFIRM_SCHEDULE) {
            return;
        }
        if (!current) {
            return;
        }
        const previousStepId = STEPS[current.id].previous;
        if (!previousStepId) {
            return;
        }
        setCurrent(STEPS[previousStepId]);
    };
    const onDisabledStepClick = (stepId) => {
        var _a, _b;
        const stepOrder = Object.keys(STEPS);
        const isStepBeforeCurrentStep = stepOrder.indexOf(current ? current.id : '') > stepOrder.indexOf(stepId);
        if (!isStepBeforeCurrentStep)
            return;
        if (!isPublisher &&
            placement.editing &&
            noticeState &&
            ((_a = noticeState.notice.data()) === null || _a === void 0 ? void 0 : _a.invoice) &&
            !((_b = noticeState.newspaper) === null || _b === void 0 ? void 0 : _b.data().postInvoiceEdit) &&
            stepId === CONFIRM_SCHEDULE) {
            return;
        }
        setCurrent(STEPS[stepId]);
    };
    const handleScrollUI = () => {
        var _a, _b;
        if (!current || !current.id || !placement || !placement.original)
            return;
        updateHistoryNoRerender(`/place/${placement.original.id}?${appendToCurrentParams('step', current.id).toString()}`);
        const ref = refs[current.id].container;
        if (ref) {
            if (current.id === CONFIRM_AD &&
                refs[current.id] &&
                refs[current.id].step &&
                ((_a = refs[current.id].step) === null || _a === void 0 ? void 0 : _a.current) &&
                ((_b = refs[current.id].step) === null || _b === void 0 ? void 0 : _b.current.animateIn)) {
                scrollToRef(ref);
                const { step } = refs[current.id];
                step === null || step === void 0 ? void 0 : step.current.animateIn();
            }
            else {
                scrollToRef(ref);
            }
        }
    };
    const urlStep = getLocationParams().get('step');
    useEffect(() => {
        var _a;
        if (!current || !refs)
            return;
        if (placement && placement.postWithoutFormatting) {
            scrollToRef(refs[current === null || current === void 0 ? void 0 : current.id].container);
        }
        if (((_a = placement === null || placement === void 0 ? void 0 : placement.currentStep) === null || _a === void 0 ? void 0 : _a.id) !== (current === null || current === void 0 ? void 0 : current.id)) {
            placementActions.setCurrentStep(current);
        }
    }, [current]);
    const determineFirstStep = () => {
        const first = Object.keys(STEPS).find(key => !STEPS[key].previous);
        let startStep = STEPS[first || ''];
        if (urlStep && STEPS[urlStep]) {
            startStep = STEPS[urlStep];
        }
        if (startStep) {
            setCurrent(startStep);
        }
    };
    useEffect(() => {
        if (!Object.keys(STEPS).length)
            return;
        determineFirstStep();
    }, [STEPS]);
    useEffect(() => {
        var _a, _b, _c;
        // make sure scheduling step is disabled when there is an active invoice for notice
        if (current &&
            !isPublisher &&
            current.id === CONFIRM_SCHEDULE &&
            noticeState &&
            !((_a = noticeState.newspaper) === null || _a === void 0 ? void 0 : _a.data().postInvoiceEdit) &&
            !((_b = noticeState.newspaper) === null || _b === void 0 ? void 0 : _b.data().editRunDatesAfterInvoicePaid) &&
            ((_c = noticeState.notice.data()) === null || _c === void 0 ? void 0 : _c.invoice)) {
            setCurrent(STEPS[CONFIRM_AD]);
            return;
        }
        /*
          if scrolling directly to CONFIRM_SCHEDULE, on init, wait a while to
          make sure that all elements have loaded, and that scroll offset is correct
        */
        !!current && handleScrollUI();
    }, [current, !!(placement === null || placement === void 0 ? void 0 : placement.original)]);
    useEffect(() => {
        if (sessionStorage.getItem('placementRedirectUrl')) {
            sessionStorage.removeItem('placementRedirectUrl');
        }
    }, []);
    useEffect(() => {
        urlStep === CONFIRM_SCHEDULE
            ? setTimeout(handleScrollUI, 1000)
            : handleScrollUI();
    }, []);
    /**
     * Part that involves placement of notice
     */
    useEffect(() => {
        var _a, _b, _c;
        if (authLoading && !userAuth)
            return;
        placementActions.hydrateNoticeData(id || ((_a = placement.original) === null || _a === void 0 ? void 0 : _a.id));
        setSteps(getSteps(user, orgContext, noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper, (_c = (_b = placement.draftSnap) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.noticeType, placement.editing, shouldShowAccountIDInPlacement || false));
    }, [authLoading, userAuth, orgContext !== null]);
    useEffect(() => {
        if (placement === null || placement === void 0 ? void 0 : placement.placementError) {
            setSimultaneousActivity({
                id: 'draft-submitted',
                header: 'Placement Error',
                body: `An error occurred while placing the notice. Please try opening a new tab and contact help@column.us if the problem persists.`,
                onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                    history.push('/notices/');
                    window.location.reload();
                }),
                buttonText: 'Back to Notices'
            });
        }
    }, [placement === null || placement === void 0 ? void 0 : placement.placementError]);
    useEffect(() => {
        var _a, _b;
        if (!exists(noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper))
            return;
        setSteps(getSteps(user, orgContext, noticeState.newspaper, (_b = (_a = placement.draftSnap) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.noticeType, placement.editing, shouldShowAccountIDInPlacement || false));
    }, [
        (_a = noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper) === null || _a === void 0 ? void 0 : _a.id,
        (_c = (_b = placement.draftSnap) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.noticeType,
        shouldShowAccountIDInPlacement
    ]);
    useEffect(() => {
        if (!placement.original || !noticeState)
            return;
        const handleSimultaneousActivity = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!placement.original || !noticeState)
                return;
            if (placement.draftSnap && !exists(placement.draftSnap)) {
                yield new Promise(resolve => setTimeout(resolve, 8000));
                const originalNotice = yield ctx
                    .userNoticesRef()
                    .doc(placement.original.id)
                    .get();
                if (exists(originalNotice) &&
                    originalNotice.data().noticeStatus ===
                        NoticeStatusType.cancelled.value) {
                    // notice cancelled
                    setSimultaneousActivity({
                        id: 'notice-cancelled',
                        header: 'Notice Cancelled',
                        body: `Another user just cancelled this notice.
             You will no longer be able to make edits. 
             For additional help, please reach out to customer support.`,
                        onConfirm: () => push(`/notice/${originalNotice.id}`),
                        buttonText: 'Back To Notice Details'
                    });
                }
                // invoice created
                else if (noticeState.newspaper &&
                    noticeState.notice &&
                    !noticeState.newspaper.data().allowedNotices &&
                    exists(originalNotice) &&
                    originalNotice.data().invoice) {
                    setSimultaneousActivity({
                        id: 'invoice-created',
                        header: 'Invoice Created',
                        body: `The newspaper just submitted an invoice for this notice.
             You are no longer able to make edits, as edits may affect price. 
             Please reach out to customer support for help if needed.`,
                        onConfirm: () => {
                            placementActions.resetState();
                            push(`/notice/${originalNotice.id}`);
                        },
                        buttonText: 'Back To Notice Details'
                    });
                }
                // draft updated
            }
            else {
                setSimultaneousActivity({
                    id: 'notice-updated',
                    header: 'Notice Update',
                    body: `This notice has been updated by another user. 
          In order to continue with your edits, you must refresh 
          this draft with the latest changes.`,
                    onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _a, _b;
                        const original = yield ((_a = placement.original) === null || _a === void 0 ? void 0 : _a.get());
                        if (!exists(original)) {
                            return;
                        }
                        const { drafts } = original.data();
                        const otherDrafts = drafts
                            ? drafts.filter(draft => { var _a; return draft.id !== ((_a = placement.draftSnap) === null || _a === void 0 ? void 0 : _a.id); })
                            : [];
                        yield original.ref.update({
                            drafts: otherDrafts
                        });
                        yield ((_b = placement.draftSnap) === null || _b === void 0 ? void 0 : _b.ref.delete());
                        window.location.reload();
                    }),
                    buttonText: 'Refresh Draft'
                });
            }
        });
        if (placement.draftSnap &&
            (!(placement.draftSnap && exists(placement.draftSnap)) ||
                placement.draftSnap.data().inactive)) {
            void handleSimultaneousActivity();
        }
    }, [placement.draftSnap, placement.original]);
    const fetchNoticeData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h, _j;
        /** * use placement instead of calling the firestore for draft, placement serves as step before saved draft */
        if (!placement.draft)
            return;
        const noticeSnap = yield placement.draft.get();
        if (!exists(noticeSnap))
            return;
        const { newspaper, adTemplate, rate, filer } = placement;
        let newspaperSnap;
        let rateSnap;
        let adTemplateSnap;
        const snap = yield (newspaper === null || newspaper === void 0 ? void 0 : newspaper.get());
        if (newspaper && exists(snap)) {
            newspaperSnap = snap;
            if (!placement.filingState) {
                try {
                    placementActions.setFilingState(newspaperSnap.data().state);
                }
                catch (err) {
                    console.error(err.toString());
                }
            }
            /**
             * move it to the saga
             * cancel the subscription
             */
            if (noticeSnap.data() &&
                noticeSnap.data().noticeStatus &&
                !isPublisher &&
                !(noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper)) {
                const unsub = newspaper.onSnapshot(doc => {
                    var _a;
                    if (!simultaneousActivity && !((_a = doc.data()) === null || _a === void 0 ? void 0 : _a.advertiserEditingEnabled)) {
                        setSimultaneousActivity({
                            header: 'Editing Disabled',
                            body: `The newspaper has disabled editing. 
                You are no longer able to make edits. 
                Please reach out to customer support for help if needed.`,
                            buttonText: 'Back to Notice Details',
                            onConfirm: () => {
                                var _a;
                                placementActions.resetState();
                                push(`/notice/${(_a = placement.original) === null || _a === void 0 ? void 0 : _a.id}`);
                            }
                        });
                    }
                });
                setNewspaperUnsub(unsub);
            }
        }
        if (adTemplate) {
            adTemplateSnap = yield adTemplate.get();
        }
        else if (exists(newspaperSnap)) {
            placementActions.setTemplate(newspaperSnap.data().adTemplate);
        }
        if (STEPS[CONTENT_PREPARED]) {
            const { previous } = STEPS[CONTENT_PREPARED];
            const { next } = STEPS[CONTENT_PREPARED];
            if (previous)
                if (exists(newspaperSnap) &&
                    !((_h = State.by_value(newspaperSnap.data().state)) === null || _h === void 0 ? void 0 : _h.hasTypeform)) {
                    STEPS[previous].next = next;
                    if (next) {
                        STEPS[next].previous = previous;
                    }
                }
                else if (exists(newspaperSnap) &&
                    ((_j = State.by_value(newspaperSnap.data().state)) === null || _j === void 0 ? void 0 : _j.hasTypeform)) {
                    STEPS[previous].next = CONTENT_PREPARED;
                    if (next) {
                        STEPS[next].previous = CONTENT_PREPARED;
                    }
                }
        }
        if (STEPS[CONFIRM_NOTICE_TYPE]) {
            const { previous } = STEPS[CONFIRM_NOTICE_TYPE];
            const { next } = STEPS[CONFIRM_NOTICE_TYPE];
            if (previous)
                if (exists(newspaperSnap) && !newspaperSnap.data().allowedNotices) {
                    STEPS[previous].next = next;
                    if (next) {
                        STEPS[next].previous = previous;
                    }
                }
                else {
                    STEPS[previous].next = CONFIRM_NOTICE_TYPE;
                    if (next) {
                        STEPS[next].previous = CONFIRM_NOTICE_TYPE;
                    }
                }
        }
        if (!filer && noticeSnap) {
            // todo tech-debt ticket coredev-1437
            // to move the isAnonymous check somewhere less risky
            if (noticeSnap.data().userId && isAnonymous === false) {
                try {
                    const user = yield ctx.usersRef().doc(noticeSnap.data().userId).get();
                    placementActions.setFiler(user.ref);
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
        if (rate)
            rateSnap = yield rate.get();
        const filerSnap = yield (filer === null || filer === void 0 ? void 0 : filer.get());
        const state = {
            notice: noticeSnap,
            newspaper: newspaperSnap,
            rate: exists(rateSnap) ? rateSnap : undefined,
            adTemplate: exists(adTemplateSnap) ? adTemplateSnap : undefined,
            filer: exists(filerSnap) ? filerSnap : undefined
        };
        const display = noticeSnap.data().noticeType === NoticeType.display_ad.value;
        const custom = noticeSnap.data().noticeType === NoticeType.custom.value;
        const noticeType = getNoticeType(noticeSnap, newspaperSnap);
        setNoticeState(state);
        setIsTypeform(Boolean(!display && !custom && (noticeType === null || noticeType === void 0 ? void 0 : noticeType.typeform)));
        setIsMadlib(Boolean(!display && !custom && (noticeType === null || noticeType === void 0 ? void 0 : noticeType.madlib)));
    });
    useEffect(() => {
        if (!placement.draft || !placement.original)
            return;
        void fetchNoticeData();
        return () => {
            if (newspaperUnsub) {
                newspaperUnsub();
                setNewspaperUnsub(undefined);
            }
        };
    }, [
        placement.draft,
        placement.newspaper,
        placement.adTemplate,
        placement.rate,
        placement.filer,
        placement.proofStoragePath,
        (_e = (_d = placement.draftSnap) === null || _d === void 0 ? void 0 : _d.data()) === null || _e === void 0 ? void 0 : _e.noticeType
    ]);
    if (!placement.draft || !noticeState || !noticeState.notice) {
        return React.createElement(LoadingState, null);
    }
    const renderBiddyPlanroomStep = () => {
        return (React.createElement(Grid, { item: true, ref: refs[BIDDY_PLANROOM].container },
            React.createElement(BiddyPlanroom, { onDisabledStepClick: onDisabledStepClick, activeStepId: (current === null || current === void 0 ? void 0 : current.id) || '', previous: previous, editing: placement.editing, notice: noticeState.notice, next: next, id: BIDDY_PLANROOM })));
    };
    const renderNoticeByMailStep = () => {
        return (React.createElement(Grid, { item: true, ref: refs[NOTICE_BY_MAIL].container },
            React.createElement(NoticeByMailStep, { onDisabledStepClick: onDisabledStepClick, activeStepId: (current === null || current === void 0 ? void 0 : current.id) || '', previous: previous, notice: noticeState.notice, user: user, next: next, id: NOTICE_BY_MAIL })));
    };
    const renderConfirmNewspaperStep = () => {
        return (React.createElement(Grid, { item: true, ref: refs[CONFIRM_NEWSPAPER].container },
            React.createElement(ConfirmNewspaperStep, { activeStepId: current ? current.id : '', next: next, onDisabledStepClick: onDisabledStepClick, newspaper: noticeState.newspaper })));
    };
    const renderContentPreparedStep = () => {
        return (React.createElement(Grid, { item: true, id: CONTENT_PREPARED, ref: refs[CONTENT_PREPARED].container },
            React.createElement(ContentPreparedStep, { id: CONTENT_PREPARED, newspaper: noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper, activeStepId: current === null || current === void 0 ? void 0 : current.id, onChange: (valid) => {
                    if (current) {
                        setCurrent(Object.assign(Object.assign({}, current), { valid }));
                    }
                }, next: next, onDisabledStepClick: onDisabledStepClick })));
    };
    const renderConfirmScheduleStep = () => {
        return (React.createElement(Grid, { item: true, id: CONFIRM_SCHEDULE, ref: refs[CONFIRM_SCHEDULE].container },
            React.createElement(ConfirmSchedule, { id: CONFIRM_SCHEDULE, user: user, activeStepId: current === null || current === void 0 ? void 0 : current.id, newspaper: noticeState.newspaper, notice: noticeState.notice, isPublisher: isPublisher, next: () => {
                    var _a;
                    if (disableNewAnonymousPlacement && !STEPS[CONFIRM_AD] && !user) {
                        placementActions.saveDraft();
                        const redirectURL = `/place/${(_a = placement.original) === null || _a === void 0 ? void 0 : _a.id}?step=${REFERENCE_ID}`;
                        sessionStorage.setItem('placementRedirectUrl', redirectURL);
                        push(`/register/?redirect=/register/occupations`);
                    }
                    else {
                        next();
                    }
                }, previous: STEPS[CONFIRM_SCHEDULE].previous &&
                    (isPublisher || !placement.editing)
                    ? previous
                    : undefined, onDisabledStepClick: onDisabledStepClick, editing: placement.editing, push: push, placementActions: placementActions })));
    };
    const handleNoticeWithoutAdProof = () => {
        placementActions.setPostWithoutFormatting(true);
        setShowSubmitFileWithoutFormattingModal(false);
    };
    const renderSubmitFileWithoutFormattingModal = () => (React.createElement(FreeformCModal, { setOpen: setShowSubmitFileWithoutFormattingModal, header: "Submit file without formatting?", id: "submit-without-formatting-modal", body: `You can opt to submit your file directly to the newspaper instead of Column formatting your notice. If you proceed, you will not receive a proof until the newspaper designs your notice manually.` },
        React.createElement("section", null,
            React.createElement("button", { type: "button", className: "bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-50", id: "submit-without-formatting", onClick: handleNoticeWithoutAdProof }, "Submit Without Formatting"),
            React.createElement("button", { type: "button", className: "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 hover:text-blue-800 bg-transparent transition duration-150 ease-in-out", id: "cancel-submit-without-formatting", onClick: () => setShowSubmitFileWithoutFormattingModal(false) }, "Cancel"))));
    const renderContentStep = () => {
        return (React.createElement(Grid, { item: true, ref: refs[CONFIRM_AD].container },
            React.createElement(NoticeContentStep, { placementActions: placementActions, ref: refs[CONFIRM_AD].step, id: CONFIRM_AD, activeStepId: current === null || current === void 0 ? void 0 : current.id, notice: noticeState.notice, newspaper: noticeState.newspaper, isTypeform: isTypeform, isMadlib: isMadlib, setUploading: setUploading, uploadedFile: uploadedFile, setUploadedFile: setUploadedFile, isPublisher: isPublisher, next: () => {
                    var _a;
                    if (!placement.continueWithLargeFile &&
                        !placement.postWithoutFormatting) {
                        placementActions.setFormattingError(null);
                    }
                    placementActions.saveDraft();
                    if (disableNewAnonymousPlacement && !user) {
                        const redirectURL = `/place/${(_a = placement.original) === null || _a === void 0 ? void 0 : _a.id}?step=${REFERENCE_ID}`;
                        sessionStorage.setItem('placementRedirectUrl', redirectURL);
                        push(`/register/?redirect=/register/occupations`);
                    }
                    else if (current === null || current === void 0 ? void 0 : current.next) {
                        setCurrent(STEPS[current.next]);
                    }
                }, previous: previous, onDisabledStepClick: onDisabledStepClick, editing: placement.editing, showSubmitFileWithoutFormattingModal: showSubmitFileWithoutFormattingModal, setShowSubmitFileWithoutFormattingModal: setShowSubmitFileWithoutFormattingModal, renderSubmitFileWithoutFormattingModal: renderSubmitFileWithoutFormattingModal })));
    };
    const renderInvoiceRecipientStep = () => {
        return (React.createElement(Grid, { item: true, ref: refs[INVOICE_RECIPIENT].container },
            React.createElement(InvoiceRecipientStep, { id: INVOICE_RECIPIENT, activeStepId: current === null || current === void 0 ? void 0 : current.id, previous: previous, next: next, onDisabledStepClick: onDisabledStepClick, user: user, isPublisher: isPublisher, placementActions: placementActions })));
    };
    const renderPreviewStep = () => {
        var _a;
        return (React.createElement(Grid, { item: true, container: true, xs: true, direction: "column", className: classes.colorColumn, style: {
                maxWidth: '30%',
                display: 'unset'
            } },
            React.createElement("div", { className: classes.navSpacer }),
            React.createElement(PlaceNoticePreview, { rate: noticeState.rate, isPublisher: isPublisher, adTemplate: (_a = noticeState.adTemplate) !== null && _a !== void 0 ? _a : null, newspaper: noticeState.newspaper, readyToFetch: [
                    CONFIRM_AD,
                    MAILED_AFFIDAVIT,
                    CONFIRM_NEWSPAPER
                ].includes((current === null || current === void 0 ? void 0 : current.id) || ''), allowEditParams: (current === null || current === void 0 ? void 0 : current.id) === CONFIRM_AD, uploading: uploading, uploadedFile: uploadedFile, placementActions: placementActions, isTypeform: isTypeform, setShowSubmitFileWithoutFormattingModal: setShowSubmitFileWithoutFormattingModal })));
    };
    const renderReferenceIdStep = () => {
        return (React.createElement(Grid, { item: true, id: REFERENCE_ID, ref: refs[REFERENCE_ID].container },
            React.createElement(ReferenceIdStep, { id: REFERENCE_ID, placementActions: placementActions, activeStepId: current === null || current === void 0 ? void 0 : current.id, next: next, previous: previous, onDisabledStepClick: onDisabledStepClick, editing: placement.editing })));
    };
    const renderAffidavitRecepientStep = () => (React.createElement(Grid, { item: true, id: MAILED_AFFIDAVIT, ref: refs[MAILED_AFFIDAVIT].container },
        React.createElement(AffidavitRecipientsStep, { id: MAILED_AFFIDAVIT, activeStepId: current === null || current === void 0 ? void 0 : current.id, draft: noticeState.notice, newspaper: noticeState.newspaper, filer: noticeState.filer, placementActions: placementActions, isPublisher: isPublisher, next: next, previous: previous, onDisabledStepClick: onDisabledStepClick, editing: placement.editing })));
    const renderConfirmProof = () => (React.createElement(Grid, { item: true, id: CONFIRM_PROOF, ref: refs[CONFIRM_PROOF].container },
        React.createElement(ConfirmProofStep, { id: CONFIRM_PROOF, activeStepId: current === null || current === void 0 ? void 0 : current.id, newspaper: noticeState.newspaper, notice: noticeState.notice, isPublisher: isPublisher, user: user, onDisabledStepClick: onDisabledStepClick, editing: placement.editing, placementActions: placementActions, toastActions: toastActions }),
        React.createElement("div", { className: classes.navSpacer })));
    const renderFilerStep = () => (React.createElement(Grid, { item: true, ref: refs[CONFIRM_FILER].container },
        React.createElement(ConfirmFilerStep, { id: CONFIRM_FILER, activeStepId: current === null || current === void 0 ? void 0 : current.id, notice: noticeState.notice, newspaper: noticeState.newspaper, noticeState: noticeState, setShowEnterPasswordModal: setShowEnterPasswordModal, next: next, previous: previous, onDisabledStepClick: onDisabledStepClick, placementActions: placementActions, authActions: authActions, setUserEmail: setUserEmail })));
    const renderNoticeTypeSelectStep = () => (React.createElement(Grid, { item: true, id: CONFIRM_NOTICE_TYPE, ref: refs[CONFIRM_NOTICE_TYPE].container },
        React.createElement(ConfirmNoticeTypeStep, { id: CONFIRM_NOTICE_TYPE, newspaper: noticeState.newspaper, activeStepId: (current === null || current === void 0 ? void 0 : current.id) || '', next: next, previous: STEPS[CONFIRM_NOTICE_TYPE].previous ? previous : undefined, onDisabledStepClick: onDisabledStepClick, placementActions: placementActions, editing: placement.editing, notice: noticeState.notice })));
    const renderAccountInformationStep = () => {
        if (!exists(noticeState.newspaper)) {
            return;
        }
        return (React.createElement(Grid, { item: true, id: ACCOUNT_INFO, ref: refs[ACCOUNT_INFO].container },
            React.createElement(AccountInformationStep, { id: ACCOUNT_INFO, activeStepId: (current === null || current === void 0 ? void 0 : current.id) || '', next: next, previous: previous, onDisabledStepClick: onDisabledStepClick, noticeState: noticeState, filerRef: placement.filer, placementActions: placementActions })));
    };
    const shouldShowEditNoticeType = () => {
        if (!isPublisher && !placement.editing)
            return true;
        if (isPublisher)
            return true;
        return false;
    };
    return (React.createElement(Grid, { xs: 12, container: true, item: true, direction: "row", className: classes.mainContainer, style: { position: 'relative' } },
        React.createElement(Grid, { xs: true, item: true, container: true, direction: "column", className: classes.formContainer, id: "place-notice-form-container", ref: formContainer, style: {
                paddingLeft: minStepPadding,
                paddingRight: minStepPadding,
                minWidth: '600px',
                width: '1px'
            } },
            React.createElement("div", { className: classes.navSpacer, id: 'nav-spacer', ref: navSpacer }),
            placement.editing && React.createElement(Typography, { variant: "h4" }, "Edit Notice"),
            (!placement.editing || isPublisher) &&
                STEPS[CONFIRM_NEWSPAPER] &&
                renderConfirmNewspaperStep(),
            STEPS[CONFIRM_NOTICE_TYPE] &&
                shouldShowEditNoticeType() &&
                noticeState.newspaper &&
                noticeState.newspaper.data().allowedNotices &&
                !!((_f = noticeState.newspaper.data().allowedNotices) === null || _f === void 0 ? void 0 : _f.length) &&
                renderNoticeTypeSelectStep(),
            !placement.editing &&
                STEPS[CONTENT_PREPARED] &&
                noticeState.newspaper &&
                noticeState.newspaper.data() &&
                ((_g = State.by_value(noticeState.newspaper.data().state)) === null || _g === void 0 ? void 0 : _g.hasTypeform) &&
                renderContentPreparedStep(),
            !placement.editing && STEPS[CONFIRM_FILER] && renderFilerStep(),
            STEPS[BIDDY_PLANROOM] && renderBiddyPlanroomStep(),
            STEPS[NOTICE_BY_MAIL] && renderNoticeByMailStep(),
            STEPS[CONFIRM_SCHEDULE] && renderConfirmScheduleStep(),
            STEPS[CONFIRM_AD] && renderContentStep(),
            renderReferenceIdStep(),
            STEPS[INVOICE_RECIPIENT] && renderInvoiceRecipientStep(),
            STEPS[MAILED_AFFIDAVIT] && renderAffidavitRecepientStep(),
            STEPS[ACCOUNT_INFO] && renderAccountInformationStep(),
            renderConfirmProof()),
        renderPreviewStep(),
        simultaneousActivity && (React.createElement(FreeformCModal, { id: simultaneousActivity.id, header: simultaneousActivity.header, body: simultaneousActivity.body },
            React.createElement("section", null,
                React.createElement("button", { id: `confirm-${simultaneousActivity.id}`, onClick: () => simultaneousActivity.onConfirm(), className: "bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-32" }, simultaneousActivity.buttonText)))),
        isAnonymousFlow() && showEnterPasswordModal && (React.createElement(PlacementLoginModal, { authActions: authActions, showModal: setShowEnterPasswordModal, email: userEmail })),
        toastText && (React.createElement(EToast, { message: toastText, type: "success", close: () => toastActions.setToastText('') }))));
};
export default withStyles(styles, { withTheme: true, push })(PlaceNotice);
