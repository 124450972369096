/**
 * Custom type guard which can be used to help the TS compiler narrow Firebase types.
 */
export const exists = (snap) => {
    return (snap === null || snap === void 0 ? void 0 : snap.exists) === true;
};
export const userConverter = {
    toFirestore(user) {
        return Object.assign({}, user);
    },
    fromFirestore(snapshot) {
        return snapshot;
    }
};
export const OrganizationConverter = {
    toFirestore(user) {
        return Object.assign({}, user);
    },
    fromFirestore(snapshot) {
        return snapshot;
    }
};
