var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SubmitButton from 'components/SubmitButton';
import api from 'api';
import SuccessModal from 'components/modals/SuccessModal';
import { publisherReadyToUpload } from 'lib/publishers';
import LoadingState from 'components/LoadingState';
import SettingsHeader from '../../../SettingsHeader';
import AffidavitSettingsSaveModal from './AffidavitSettingsSaveModal';
const styles = (theme) => createStyles({
    formControl: {
        display: 'flex',
        margin: theme.spacing(1)
    },
    formRow: {
        width: '100%'
    },
    radioGroup: {
        justifyContent: 'space-around'
    },
    priceInput: {
        marginTop: theme.spacing(1)
    },
    priceRadio: {
        marginTop: theme.spacing(1)
    },
    saveButton: {
        marginTop: theme.spacing(8),
        width: '100%'
    },
    header: {
        marginBottom: theme.spacing(3)
    }
});
const AffidavitSettings = ({ activeOrganization }) => {
    const [loading, setLoading] = useState(true);
    const [signatoryName, setSignatoryName] = useState();
    const [signatoryRole, setSignatoryRole] = useState();
    const [publicationFrequency, setPublicationFrequency] = useState('');
    const [commissionExpirationDate, setCommissionExpirationDate] = useState();
    const [edited, setEdited] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [openSaveSuccessModal, setOpenSaveSuccessModal] = useState(false);
    const [previewing, setPreviewing] = useState(false);
    useEffect(() => {
        const initSettings = () => __awaiter(void 0, void 0, void 0, function* () {
            setSignatoryName(activeOrganization.data().signatoryName || '');
            setSignatoryRole(activeOrganization.data().signatoryRole || '');
            setCommissionExpirationDate(activeOrganization.data().commissionExpirationDate || '');
            setPublicationFrequency(activeOrganization.data().publicationFrequency || '');
            setLoading(false);
        });
        void initSettings();
    }, [activeOrganization]);
    const previewAffidavit = () => __awaiter(void 0, void 0, void 0, function* () {
        setPreviewing(true);
        const { url } = yield api.post('documents/generate-affidavit', {
            publicationDates: [new Date().getTime() / 1000],
            fee: '250'
        });
        window.open(url);
        setPreviewing(false);
    });
    if (loading)
        return React.createElement(LoadingState, null);
    const canPreviewAffidavit = activeOrganization && publisherReadyToUpload(activeOrganization);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement("form", { onSubmit: e => {
                e.preventDefault();
                setOpenSaveModal(true);
            } },
            React.createElement("div", null,
                React.createElement(SettingsHeader, { header: "Affidavit Settings", description: "Specify the information you\u2019d like on your affidavits." },
                    React.createElement("button", { type: "button", className: `${canPreviewAffidavit
                            ? 'text-gray-800'
                            : 'text-gray-400 cursor-not-allowed'} tooltip px-3 bg-white box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider border`, disabled: !canPreviewAffidavit, onClick: previewAffidavit },
                        React.createElement("span", { className: "pr-2" }, previewing ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })) : (React.createElement("svg", { className: "h-5 w-5", viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                            React.createElement("path", { d: "M4.72364 2H3.14722C2.72913 2 2.32816 2.15804 2.03252 2.43934C1.73689 2.72064 1.5708 3.10218 1.5708 3.5V12.5C1.5708 12.8978 1.73689 13.2794 2.03252 13.5607C2.32816 13.842 2.72913 14 3.14722 14H12.6057C13.0238 14 13.4248 13.842 13.7204 13.5607C14.0161 13.2794 14.1821 12.8978 14.1821 12.5V3.5C14.1821 3.10218 14.0161 2.72064 13.7204 2.43934C13.4248 2.15804 13.0238 2 12.6057 2H11.0293M7.87647 1.25V7.25M7.87647 7.25L10.2411 5M7.87647 7.25L5.51184 5M1.5708 8.75H3.60911C3.81814 8.75004 4.01859 8.82909 4.16637 8.96975L6.06911 10.7802C6.21689 10.9209 6.41734 11 6.62637 11H9.12657C9.3356 11 9.53605 10.9209 9.68383 10.7802L11.5866 8.96975C11.7344 8.82909 11.9348 8.75004 12.1438 8.75H14.1821", stroke: canPreviewAffidavit ? '#4A5568' : '#cbd5e0', strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })))),
                        !canPreviewAffidavit && (React.createElement("span", { className: "tooltiptext right-0", style: { left: 'unset', top: '48px', width: '100%' } }, "Complete all required fields and save before previewing the affidavit.")),
                        "Preview Affidavit")),
                React.createElement("div", { className: "flex flex-col m-6" },
                    React.createElement("input", { id: "signatory-name", className: "form-input rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300 placeholder-gray-500 text-gray-900", autoComplete: "none", placeholder: "Affidavit Signatory (Jane Doe) *", required: true, value: signatoryName, onChange: e => {
                            setSignatoryName(e.target.value);
                            setEdited(true);
                        } }),
                    React.createElement("input", { id: "signatory-role", className: "form-input rounded block w-full mt-3 transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300 placeholder-gray-500 text-gray-900", autoComplete: "none", placeholder: "Affidavit Signatory Role (Authorized Agent) *", required: true, value: signatoryRole, onChange: e => {
                            setSignatoryRole(e.target.value);
                            setEdited(true);
                        } }),
                    React.createElement("input", { id: "commission-expiration", className: "form-input rounded block w-full mt-3 transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300 placeholder-gray-500 text-gray-900", autoComplete: "none", placeholder: "Notary Commission Expiration Date", value: commissionExpirationDate, onChange: e => {
                            setCommissionExpirationDate(e.target.value);
                            setEdited(true);
                        } }),
                    React.createElement("span", { className: "w-full" },
                        React.createElement("select", { id: "publication-frequency", required: true, value: publicationFrequency, autoComplete: "none", onChange: e => {
                                setPublicationFrequency(e.target.value);
                                setEdited(true);
                            }, className: `appearance-none form-select ${!publicationFrequency && 'text-gray-500'} rounded block w-full mt-3 px-3 py-2 border border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5` },
                            React.createElement("option", { value: "", disabled: true, hidden: true }, "Publication frequency *"),
                            React.createElement("option", { value: "Weekly" }, "Weekly"),
                            React.createElement("option", { value: "Daily" }, "Daily"))))),
            React.createElement("div", { className: "flex w-full rounded justify-end bg-gray-100 p-5" },
                React.createElement(SubmitButton, { disabled: !edited, loading: false, text: "Save", type: "submit", id: "submit" }))),
        openSaveModal && (React.createElement(AffidavitSettingsSaveModal, { setOpen: setOpenSaveModal, activeOrganization: activeOrganization, publicationFrequency: publicationFrequency || '', signatoryName: signatoryName || '', signatoryRole: signatoryRole || '', commissionExpirationDate: commissionExpirationDate || '', onSuccessfulSave: () => {
                setOpenSaveSuccessModal(true);
                setEdited(false);
            } })),
        openSaveSuccessModal && (React.createElement(SuccessModal, { setOpen: setOpenSaveSuccessModal, header: "Changes Saved", body: "You have successfully applied these changes to your affidavit settings (changes might take a moment to appear).", gif: React.createElement("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/bb78.9cbe2-report-downloaded.gif", style: {
                    clipPath: 'circle()',
                    width: '104px',
                    height: '104px'
                } }) }))));
};
export default connect(null, { push })(withStyles(styles)(AffidavitSettings));
