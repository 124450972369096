var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getFirebaseContext } from 'utils/firebase';
import api from 'api';
import AutomatedAffidavitSettingsPDFPreview from './AutomatedAffidavitSettingsPDFPreview';
import NoticeList from './NoticeList';
import AffidavitUploadDrawerTabs, { PREVIEW_TAB, UNVERIFIED_NOTICES_TAB, VERIFIED_NOTICES_TAB } from './AffidavitUploadDrawerTabs';
/**
 * Determines the timestamp for the upload day. This is then used to fetch the notices that were published
 * on that day. If no notices were published on that day, we return null.
 * @param uploadDay
 * @returns {number | null}
 */
const getUploadTimestampForUploadDay = (uploadDay) => __awaiter(void 0, void 0, void 0, function* () {
    const facets = {
        publicationtimestamps: {
            type: 'value',
            name: 'dates',
            size: 250,
            sort: { value: 'desc' }
        }
    };
    const facetFilters = [
        { iscancelled: Number(false) },
        { isarchived: Number(false) },
        { isdraft: Number(false) }
    ];
    const { facets: { publicationtimestamps } } = yield api.post('search/usernotices/facets', {
        search: '',
        showAllOrgsNotices: false,
        facets,
        filters: facetFilters
    });
    const relevantDates = publicationtimestamps[0].data.filter((timestamp) => moment(uploadDay.publishingDate).isSame(moment(timestamp.value), 'day'));
    if (!relevantDates.length)
        return null;
    const uploadTimestamp = relevantDates[0];
    return uploadTimestamp.value;
});
/**
 * For empty upload days, we need to fetch the notices that were published on that day. This function
 * adds in missing notices that weren't included in the initial upload day object. If there are no notices
 * for the upload day, it returns the original upload day object.
 * @param activeOrganization
 * @param uploadDay
 * @returns {AffidavitUploadDay}
 */
const createFullUploadDay = (activeOrganization, uploadDay) => __awaiter(void 0, void 0, void 0, function* () {
    const uploadTimestamp = yield getUploadTimestampForUploadDay(uploadDay);
    // if we can't find the upload timestamp, we can't fetch the notices
    if (!uploadTimestamp)
        return uploadDay;
    const filters = [
        {
            publicationtimestamps: [uploadTimestamp]
        },
        { isdraft: [Number(false)] },
        { iscancelled: Number(false) },
        { isarchived: Number(false) }
    ];
    const req = {
        activeOrganizationId: activeOrganization.id,
        showAllOrgsNotices: false,
        isPublisher: true,
        current: 1,
        filters,
        search: '',
        size: 1000
    };
    const { results } = (yield api.post('search/usernotices', req));
    const noticeIds = results.map(hit => hit.id);
    const noticesNotValidated = noticeIds.map(noticeID => getFirebaseContext().userNoticesRef().doc(noticeID));
    return Object.assign(Object.assign({}, uploadDay), { noticesNotValidated });
});
/**
 * Main content displayed in the drawer for affidavit e-edition upload events
 */
export default function AffidavitUploadDayDrawerBody({ setShowEEditionUploadModal, activeOrganization, affidavitUploadDay }) {
    const [affidavitUploadDayDrawerTab, setAffidavitUploadDayDrawerTab] = useState(PREVIEW_TAB);
    const [fullUploadDay, setFullUploadDay] = useState(null);
    /**
     * If the upload day is empty, dynamically create it using the notices that were published on that day
     * and set it in state. Otherwise, just set the upload day in state.
     */
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            if (affidavitUploadDay.noticesValidated.length === 0) {
                const fullUploadDay = yield createFullUploadDay(activeOrganization, affidavitUploadDay);
                setFullUploadDay(fullUploadDay);
            }
            else {
                setFullUploadDay(affidavitUploadDay);
            }
        }))();
    }, [affidavitUploadDay]);
    if (!fullUploadDay)
        return null;
    return (React.createElement("div", { id: "affidavit-upload-day-drawer-body", className: "overflow-scroll flex flex-col flex-1" },
        React.createElement("div", { className: "border-b flex" },
            React.createElement(AffidavitUploadDrawerTabs, { setAffidavitUploadDayDrawerTab: setAffidavitUploadDayDrawerTab, affidavitUploadDayDrawerTab: affidavitUploadDayDrawerTab, affidavitUploadDay: fullUploadDay })),
        React.createElement("div", { className: "px-6 overflow-scroll flex-1" },
            affidavitUploadDayDrawerTab === PREVIEW_TAB && (React.createElement(AutomatedAffidavitSettingsPDFPreview, { setShowEEditionUploadModal: setShowEEditionUploadModal, uploadDay: fullUploadDay })),
            affidavitUploadDayDrawerTab === VERIFIED_NOTICES_TAB && (React.createElement(NoticeList, { noticeRefs: fullUploadDay.noticesValidated })),
            affidavitUploadDayDrawerTab === UNVERIFIED_NOTICES_TAB && (React.createElement(NoticeList, { noticeRefs: fullUploadDay.noticesNotValidated })))));
}
