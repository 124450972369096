import React from 'react';
import { ExclamationCircle } from 'icons';
export default function ConfirmToast({ headerText, bodyText, leftButtonText, rightButtonText, onLeftClick, onRightClick, position }) {
    const positionClass = {
        bottomRight: 'right-0 mr-8',
        bottomLeft: 'left-0 ml-8'
    }[position !== null && position !== void 0 ? position : 'bottomLeft'];
    return (React.createElement("div", { className: `fixed z-100 inset-0 overflow-y-auto
        }` },
        React.createElement("div", { className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity opacity-25" },
                React.createElement("div", { className: "absolute inset-0 bg-black opacity-50" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: `${positionClass} bottom-0 absolute inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
                React.createElement("div", { className: "sm:flex sm:items-start" },
                    React.createElement("div", { className: "flex" },
                        React.createElement("div", { className: "h-12 w-12 rounded-full bg-red-100 flex items-center justify-center" },
                            React.createElement(ExclamationCircle, { className: "text-red-500 h-6 w-6" }))),
                    React.createElement("div", { className: `sm:mt-0 sm:mx-4 text-left` },
                        React.createElement("p", { className: "font-medium text-base leading-6 text-gray-800" }, headerText),
                        React.createElement("p", { className: "font-medium\ttext-sm text-gray-700 py-2" }, bodyText),
                        React.createElement("div", { className: "pt-2" },
                            React.createElement("button", { className: "font-medium text-base leading-5 text-gray-800", onClick: () => onLeftClick() }, leftButtonText),
                            React.createElement("button", { className: "ml-2 font-medium text-base leading-5 text-blue-600", onClick: () => onRightClick() }, rightButtonText))))))));
}
