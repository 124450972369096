import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress } from '@material-ui/core';
import { ZoomOutMap } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { RateType } from 'lib/enums';
import { roundUp } from 'lib/pricing';
import { getCurrencySymbol, getDisplayUnits } from 'lib/helpers';
import { BROWSER, BROWSERS } from '../../constants';
const minWidth = '180px';
const styles = (theme) => createStyles({
    scrollContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '&::-webkit-scrollbar-track': {
            display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            display: 'none'
        },
        marginBottom: theme.spacing(1),
        width: '100%',
        height: 'max-content'
    },
    hoverOverlay: {
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    hoverItems: {
        position: 'absolute',
        alignSelf: 'center',
        color: 'white',
        opacity: 1,
        zIndex: 4000,
        cursor: 'pointer'
    },
    circularProgress: {
        color: 'rgba(255, 255, 255, 0.25);'
    },
    expectedPrice: {
        backgroundColor: '#cbd5e0',
        color: '#1a202c',
        textAlign: 'left',
        borderRadius: theme.spacing(0, 0, 1, 1),
        padding: theme.spacing(1, 2, 2, 2),
        minWidth
    },
    previewContainerOuter: {
        width: '100%',
        height: '50vh',
        minWidth,
        borderRadius: theme.spacing(1, 1, 0, 0),
        backgroundColor: '#718096',
        color: 'rgba(255, 255, 255, 0.2);',
        textAlign: 'center',
        overflowY: 'scroll',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
    }
});
const NoticePreviewContainer = ({ previewContent, loading, classes, openModal, isDisplay, isTypeform, uploading, displayJPGUrl, displayPDFUrl, rate, newspaper }) => {
    var _a, _b;
    const [isHovering, setIsHovering] = useState(false);
    const [encodedImgPreviews, setEncodedImgPreviews] = useState([]);
    const { displayParams, price } = previewContent;
    const measurement = getDisplayUnits(rate, newspaper, displayParams);
    const currencySymbol = getCurrencySymbol(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().currency);
    useEffect(() => {
        if (!(displayParams === null || displayParams === void 0 ? void 0 : displayParams.imgs))
            return;
        setEncodedImgPreviews(displayParams.imgs);
    }, [displayParams === null || displayParams === void 0 ? void 0 : displayParams.imgs]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, justify: "center", alignItems: "center", className: classes.previewContainerOuter, onClick: () => !loading &&
                (displayPDFUrl
                    ? window.open(displayPDFUrl)
                    : encodedImgPreviews.length && openModal()), onMouseEnter: () => !loading &&
                (displayJPGUrl || encodedImgPreviews.length) &&
                setIsHovering(true), onMouseLeave: () => !loading &&
                (displayJPGUrl || encodedImgPreviews.length) &&
                setIsHovering(false) },
            isHovering && (React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justify: "center", className: classes.hoverItems },
                React.createElement(Grid, { item: true },
                    React.createElement(ZoomOutMap, null)),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "h5" }, "Click to Expand")))),
            React.createElement(Box, { className: classes.scrollContainer },
                React.createElement(Grid, { item: true, container: true, direction: "column", xs: true, alignItems: "center", justify: "center" }, loading || uploading ? (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true },
                        React.createElement(CircularProgress, { className: classes.circularProgress })),
                    React.createElement(Grid, { item: true, style: {
                            width: BROWSER === BROWSERS.ie ? '100%' : '',
                            overflow: BROWSER === BROWSERS.ie ? 'hidden' : ''
                        } },
                        React.createElement(Typography, { variant: "h4" },
                            ' ',
                            uploading
                                ? 'Uploading File'
                                : isDisplay
                                    ? 'Updating Price'
                                    : 'Generating Preview')))) : displayJPGUrl || encodedImgPreviews.length ? (React.createElement("div", { className: `px-2 ${classes.hoverOverlay}`, style: {
                        maxWidth: `${displayJPGUrl
                            ? 2.5
                            : (displayParams === null || displayParams === void 0 ? void 0 : displayParams.width) || 1.5}in`,
                        display: BROWSER === BROWSERS.safari ? 'flex' : '',
                        flexFlow: BROWSER === BROWSERS.safari ? 'column' : ''
                    } }, displayJPGUrl ? (React.createElement("img", { id: "notice-preview-image", src: displayJPGUrl, style: {
                        opacity: isHovering ? 0.35 : 1,
                        flex: BROWSER === BROWSERS.safari ? '1 1 0' : ''
                    } })) : (React.createElement("div", { id: "notice-preview-image", style: {
                        opacity: isHovering ? 0.35 : 1,
                        flex: BROWSER === BROWSERS.safari ? '1 1 0' : ''
                    } }, encodedImgPreviews.map((encodedImgPreview, i) => (React.createElement("img", { key: i, id: "notice-preview-image", src: encodedImgPreview }))))))) : (React.createElement(Grid, { item: true, style: {
                        padding: '12px',
                        width: BROWSER === BROWSERS.ie ? '100%' : '',
                        overflow: BROWSER === BROWSERS.ie ? 'hidden' : ''
                    } },
                    React.createElement(Typography, { variant: "h5" }, (() => {
                        if (isDisplay) {
                            return 'Preview available after completing Draft Notice step';
                        }
                        if (isTypeform) {
                            return 'Preview available upon reaching Draft Notice step';
                        }
                        return 'Preview available after upload or copy and paste';
                    })())))))),
        React.createElement(Box, { style: { width: '100%' } },
            React.createElement(Grid, { container: true, direction: "column", justify: "center", className: classes.expectedPrice },
                React.createElement(Grid, { item: true }, !((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.hidePreview) && (React.createElement(Typography, { style: { fontSize: BROWSER === BROWSERS.ie ? 18 : 24 } },
                    "Estimated Price:",
                    ' ',
                    `${currencySymbol}${Number(price) ? Number(price).toFixed(2) : '--'}`,
                    ' '))),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { style: { fontSize: 14 } },
                        ' ',
                        `${displayParams && displayParams.words ? displayParams.words : 0} ${RateType.word_count.plural} - ${displayParams && displayParams.lines ? displayParams.lines : 0} ${RateType.line.plural} - ${measurement.value
                            ? roundUp(measurement.value, (_b = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _b === void 0 ? void 0 : _b.roundOff).toFixed(2)
                            : 0} ${measurement.unit}`))))));
};
export default withStyles(styles)(NoticePreviewContainer);
