import React, { useState } from 'react';
import AffidavitSettingsTabs from '../SettingsTabs';
import { affidavitsAreManagedByColumn } from './affidavitSettingsUtils';
import AutomatedAffidavitsSettings from './automatedAffidavitsSettings';
import BasicAffidavitSettings from './basicAffidavitSettings';
export const BASIC_AFFIDAVIT_SETTINGS_TAB = 'Affidavit Layout';
export const AUTOMATED_AFFIDAVIT_SETTINGS = 'Automated Affidavits';
export const AFFIDAVIT_SETTINGS_TABS = [
    BASIC_AFFIDAVIT_SETTINGS_TAB,
    AUTOMATED_AFFIDAVIT_SETTINGS
];
export const getAffidavitSettingsTabs = (activeOrganization) => {
    return [
        {
            label: BASIC_AFFIDAVIT_SETTINGS_TAB,
            enabled: true
        },
        {
            label: AUTOMATED_AFFIDAVIT_SETTINGS,
            enabled: affidavitsAreManagedByColumn(activeOrganization)
        }
    ];
};
/**
 * Wrapper component around affidavit settings that shows:
 * 1) Basic affidavit html and configurations
 * 2) If automated affidavits are enabled, details on automated affidavit uploads
 */
export default function AffidavitSettings({ activeOrganization, user }) {
    const tabs = getAffidavitSettingsTabs(activeOrganization);
    const [activeTab, setActiveTab] = useState(tabs[0]);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow mb-24" },
        React.createElement(AffidavitSettingsTabs, { setActiveTab: setActiveTab, activeTab: activeTab, tabs: tabs }),
        activeTab.label === BASIC_AFFIDAVIT_SETTINGS_TAB && (React.createElement(BasicAffidavitSettings, { activeOrganization: activeOrganization, user: user })),
        activeTab.label === AUTOMATED_AFFIDAVIT_SETTINGS && (React.createElement(AutomatedAffidavitsSettings, { activeOrganization: activeOrganization }))));
}
