import React, { useState } from 'react';
import { ColumnButton } from 'components/ColumnButton';
export default function InvoiceConfirm({ getTermsText, handleConfirm, isConfirmingInvoice }) {
    const [confirmed, setConfirmed] = useState(false);
    return (React.createElement("div", { id: "confirm-modal" },
        React.createElement("div", { className: "pb-4 flex items-center" },
            React.createElement("input", { id: "invoice-confirm-submit", name: "invoice-confirmation", type: "checkbox", checked: confirmed, onChange: e => {
                    setConfirmed(e.target.checked);
                }, className: "form-checkbox text-gray-700 transition duration-150 ease-in-out" }),
            React.createElement("label", { htmlFor: "invoice-confirm-submit", className: "ml-3 block text-sm leading-5 text-gray-700" }, getTermsText())),
        React.createElement(ColumnButton, { id: "invoice-confirm-submit-button", primary: true, onClick: handleConfirm, disabled: !confirmed, loading: isConfirmingInvoice, buttonText: "Submit Invoice" })));
}
