var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useRef, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { exists } from 'lib/types';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import Firebase, { Firestore } from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { State } from 'lib/enums';
import { FileType } from 'lib/types/upload';
import { awaitAllPromises, getRejected, guidGenerator } from 'lib/helpers';
import Dropzone from 'react-dropzone';
import { truncateString, sanitize, logAndCaptureException } from 'utils';
import TailwindModal from 'components/TailwindModal';
import SubmitButton from 'components/SubmitButton';
import api from 'api';
import { getCounties } from 'lib/utils/counties';
import { AddImageIcon, ArrowLeft, XIcon, InformationCircle, DeleteIcon, FilledCircleIcon } from 'icons';
import SettingsHeader from '../../SettingsHeader';
import SelectDropdown from '../../../placeScroll/SelectDropdown';
import { DEFAULT_SELECTABLE_TYPES } from './Archive';
const styles = (theme) => createStyles({
    header: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    chipContainerOuter: {
        maxWidth: 600,
        overflow: 'scroll'
    }
});
export const getNoticeChunks = (text, pdfUrl) => {
    if (pdfUrl)
        return [text.replace('<![CDATA[', '').replace(']]>', '')];
    const trimmedText = text.startsWith('|') ? text.slice(1) : text;
    const PIPE_PATTERN = /[\n\r]\s{0,2}\|\s{0,2}[\n\r]/g;
    let chunks;
    if (trimmedText.match(PIPE_PATTERN)) {
        chunks = trimmedText.split(PIPE_PATTERN);
    }
    else {
        chunks = trimmedText.split(/((\n-\s{0,1}-\s{0,1}-\n)|–{20,40}|(\n\s{0,1}){3}|mmm\s{0,1}\n)/);
    }
    const cleanChunks = chunks.map(c => (c || '')
        .replace(/\n-\s{0,1}-\s{0,1}-\n/g, '')
        .replace(/–/g, '')
        .replace(/\n\|\n/g, '')
        // eslint-disable-next-line no-control-regex
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/mmm/g, '')
        .trim());
    return cleanChunks.filter(Boolean);
};
const FTPSettings = ({ availableOrganizations, activeOrganization, classes }) => {
    var _a, _b, _c;
    const [publicationDates, setPublicationDates] = useState([new Date()]);
    const [noticeText, setNoticeText] = useState('');
    const [uploadedSnapshots, setUploadedSnapshots] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(activeOrganization);
    const [publicationMethod, setPublicationMethod] = useState('');
    const shouldShowPublicationMethod = ((_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.state) === State.florida.value;
    const [county, setCounty] = useState(((_b = activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.county) || '');
    const [noticeType, setNoticeType] = useState('');
    const [expandOption1, setExpandOption1] = useState(true);
    const [expandOption2, setExpandOption2] = useState(false);
    const [expandOption3, setExpandOption3] = useState(false);
    const [files, setFiles] = useState();
    const [showSubmissionSuccessfulDialog, setShowSubmissionSuccessfulDialog] = useState();
    const [loading, setLoading] = useState();
    const dropzoneRef = useRef();
    const extractText = (snapshot) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        const extension = (_e = (_d = snapshot.ref.fullPath.split('.').pop()) === null || _d === void 0 ? void 0 : _d.toLowerCase()) !== null && _e !== void 0 ? _e : '';
        if (extension === FileType.PDF) {
            const url = (yield snapshot.ref.getDownloadURL());
            const { text } = yield api.post('documents/run-ocr', { url });
            return {
                noticeText: text,
                pdfUrl: url
            };
        }
        const fileTextResp = yield fetch(yield snapshot.ref.getDownloadURL());
        const noticeText = yield fileTextResp.text();
        return {
            pdfUrl: null,
            noticeText
        };
    });
    const uploadNotice = (fullText, pdfUrl, publicationDate, publicationMethod, county, noticeType, totalRuns, runNumber) => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        if (!exists(selectedOrganization)) {
            return;
        }
        const formattedDate = moment(publicationDate).format('MM/DD/YYYY');
        const stateString = (_f = State.by_value(selectedOrganization.data().state)) === null || _f === void 0 ? void 0 : _f.label;
        const noticeChunks = getNoticeChunks(fullText.slice(), pdfUrl);
        yield Promise.all(noticeChunks.map((cleanText) => __awaiter(void 0, void 0, void 0, function* () {
            const hash = guidGenerator();
            console.log('hash & cleanText:', hash, cleanText);
            const updates = {
                publishedDate: formattedDate,
                newspaperName: selectedOrganization.data().name,
                noticeType: noticeType || 'Legal Notice',
                pdfUrl,
                state: stateString,
                text: cleanText,
                county,
                uploadTime: Firestore.FieldValue.serverTimestamp(),
                uploadedBy: selectedOrganization.ref,
                totalRuns,
                runNumber
            };
            if (shouldShowPublicationMethod && publicationMethod) {
                updates.publicationMethod = publicationMethod;
            }
            return yield Firebase.firestore()
                .collection(Collections.publicNotices)
                .doc(hash)
                .set(updates);
        })));
    });
    const uploadFiles = (files) => __awaiter(void 0, void 0, void 0, function* () {
        if (!files)
            return;
        setLoading(true);
        const snapshots = [];
        yield Promise.all(files.map((file) => __awaiter(void 0, void 0, void 0, function* () {
            const uniqueFileNameComponents = file.name.split('.');
            uniqueFileNameComponents.splice(-1, 0, guidGenerator().slice(0, 8));
            const uniqueFileName = uniqueFileNameComponents.join('.');
            const snapshot = yield Firebase.storage()
                .ref()
                .child(`direct-ftp-upload/${activeOrganization.id}/${sanitize(uniqueFileName)}`)
                .put(file);
            snapshots.push(snapshot);
        })));
        setUploadedSnapshots(uploadedSnapshots.concat(snapshots));
        setNoticeText('');
        setLoading(false);
    });
    const submitNotices = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        const uploadResults = yield awaitAllPromises(publicationDates.map((publicationDate, runNumber) => __awaiter(void 0, void 0, void 0, function* () {
            if (uploadedSnapshots.length) {
                yield Promise.all(uploadedSnapshots.map((snapshot) => __awaiter(void 0, void 0, void 0, function* () {
                    const { noticeText, pdfUrl } = yield extractText(snapshot);
                    yield uploadNotice(noticeText, pdfUrl, publicationDate, publicationMethod, county, noticeType, publicationDates.length, runNumber);
                })));
            }
            else {
                yield uploadNotice(noticeText, null, publicationDate, publicationMethod, county, noticeType, publicationDates.length, runNumber);
            }
        })));
        const failedNotices = getRejected(uploadResults);
        if (failedNotices.length) {
            failedNotices.forEach(err => {
                logAndCaptureException(err, 'Failed to upload notice', {
                    activeOrgId: activeOrganization.id
                });
            });
        }
        else {
            setNoticeText('');
            setUploadedSnapshots([]);
            setShowSubmissionSuccessfulDialog(true);
        }
        setSubmitting(false);
    });
    useEffect(() => {
        if (files && files.length) {
            void uploadFiles(files);
        }
    }, [files]);
    let countyLabel = 'County';
    const stateString = (_c = State.by_value(selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.data().state)) === null || _c === void 0 ? void 0 : _c.label;
    if (stateString === 'Louisiana') {
        countyLabel = 'Parish';
    }
    const renderOptionHeader = (headerPosition, subHeader, expand, setExpand) => {
        const rotationStyling = expand ? 'transform rotate-90' : '';
        return (React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300 cursor-pointer select-none", onClick: () => setExpand(!expand) },
            React.createElement("div", { className: "w-full" },
                React.createElement("p", { className: "not-italic font-semibold text-base text-gray-700" },
                    "Option ",
                    headerPosition),
                React.createElement("p", { className: "not-italic font-normal text-base text-gray-500" }, subHeader)),
            React.createElement("div", { className: "flex w-full justify-end items-center" },
                React.createElement(ArrowLeft, { className: `duration-300 ${rotationStyling} text-gray-750` }))));
    };
    const renderFTPFeedOption = () => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6" }, "Server Name"),
            React.createElement("p", { className: "not-italic font-normal text-sm text-black w-4/6" }, "upload-files.enotice.io")),
        React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6" }, "Port"),
            React.createElement("p", { className: "not-italic font-normal text-sm text-black w-4/6" }, "21")),
        React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6" }, "User Name"),
            React.createElement("p", { className: "not-italic font-normal text-sm text-black w-4/6" }, activeOrganization.data().ftp.username)),
        React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6" }, "Password"),
            React.createElement("p", { className: "not-italic font-normal text-sm text-black w-4/6" }, activeOrganization.data().ftp.initialPassword))));
    const renderDirectUploadOption = () => {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6" }, "Publication Dates *"),
                React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                    React.createElement("div", { className: "flex flex-col mb-2" },
                        publicationDates.map((d, i) => (React.createElement("div", { key: i, className: "flex items-center mb-2 cursor-pointer" },
                            React.createElement("div", { className: "box-border rounded border border-gray-300 w-1/3" },
                                React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                                    React.createElement(DatePicker, { label: "", value: d, placeholder: "MM/DD/YYYY", className: `${classes.picker} date-picker-publish`, InputProps: {
                                            disableUnderline: true,
                                            startAdornment: (React.createElement("svg", { width: "60", height: "38", className: "border-r border-gray-300 mr-4" },
                                                React.createElement("svg", { width: "42", height: "38", viewBox: "0 0 42 38", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                                    React.createElement("path", { d: "M0 2C0 0.89543 0.895431 0 2 0H42V38H2C0.89543 38 0 37.1046 0 36V2Z", fill: "#F9FAFB" })),
                                                React.createElement("svg", { x: "11.5", y: "11", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                                    React.createElement("path", { d: "M5.66667 4.83333V1.5V4.83333ZM12.3333 4.83333V1.5V4.83333ZM4.83333 8.16667H13.1667H4.83333ZM3.16667 16.5H14.8333C15.2754 16.5 15.6993 16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5 4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333 3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738 1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072 16.3244 2.72464 16.5 3.16667 16.5Z", stroke: "#4A5568", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))))
                                        }, autoOk: true, onChange: newDate => setPublicationDates(publicationDates.map((date, j) => i === j ? newDate : date)) }))),
                            i !== 0 && (React.createElement(DeleteIcon, { className: "ml-3 text-gray-750", onClick: () => setPublicationDates(publicationDates.filter((_, j) => i !== j)) }))))),
                        React.createElement("div", { className: "font-medium text-base text-indigo-400 cursor-pointer flex items-baseline ml-2", onClick: () => setPublicationDates(publicationDates.concat(new Date())) },
                            React.createElement("p", { className: "text-2xl mr-2" }, "+"),
                            " Add More Dates")))),
            availableOrganizations && availableOrganizations.length > 1 && (React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
                React.createElement("p", { className: "flex items-center not-italic font-semibold text-sm text-gray-700 w-2/6" }, "Select Publication"),
                React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                    React.createElement("select", { id: "select-publication", required: true, value: selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.id, autoComplete: "none", onChange: e => {
                            var _a;
                            return setSelectedOrganization((_a = availableOrganizations.find(o => o.id === e.target.value)) !== null && _a !== void 0 ? _a : null);
                        }, className: `appearance-none form-select ${!selectedOrganization && 'text-gray-500'} rounded block w-1/2 px-3 py-2 border border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5` },
                        React.createElement("option", { value: "", disabled: true, hidden: true }, "Select Publication *"),
                        availableOrganizations.map(o => (React.createElement("option", { key: o.id, value: o.id }, o.data().name))))))),
            activeOrganization &&
                activeOrganization.data().state === State.florida.value && (React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6", style: { alignSelf: 'center' } }, "Publication Method *"),
                React.createElement("div", { className: "not-italic font-normal text-sm text-gray-500 mt-1 w-4/6" },
                    React.createElement("div", { className: "flex items-center", style: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingRight: '45%'
                        } },
                        React.createElement("div", { id: "print-and-website-block" },
                            React.createElement("input", { value: "print and website", id: "print-and-website", name: "publication-method", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", onChange: e => setPublicationMethod(e.target.value) }),
                            React.createElement("label", { className: "ml-3", htmlFor: "print-and-website" },
                                React.createElement("span", { className: "text-md leading-5 font-medium text-gray-700 opacity-75" }, "Print and website"))),
                        React.createElement("div", { id: "website-only-block" },
                            React.createElement("input", { value: "website only", id: "website-only", name: "publication-method", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", onChange: e => setPublicationMethod(e.target.value) }),
                            React.createElement("label", { className: "ml-3", htmlFor: "website-only" },
                                React.createElement("span", { className: "text-md leading-5 font-medium text-gray-700 opacity-75" }, "Website only"))))))),
            React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6", style: { alignSelf: 'center' } },
                    countyLabel,
                    " *"),
                React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-3/5" },
                    React.createElement(SelectDropdown, { className: "not-italic font-normal text-sm text-black", id: "county", onChange: (selected) => {
                            setCounty(selected.label);
                        }, placeholder: "County *", options: getCounties((_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.label).map(county => ({ id: county, label: county })), selected: { id: county, label: county }, value: { id: county, label: county }, borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true, maxHeight: 250 }))),
            React.createElement("div", { className: "flex w-full px-5 py-5 border-b border-gray-300" },
                React.createElement("div", { className: "flex flex-col w-2/6" },
                    React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700 w-2/6" }, "Notice Type *"),
                    React.createElement("p", { className: "not-italic font-normal text-sm text-gray-500 mt-1 w-3/4" }, "Select your notice type.")),
                React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-3/5" },
                    React.createElement(SelectDropdown, { className: "not-italic font-normal text-sm text-black", id: "noticeType", onChange: (selected) => {
                            setNoticeType(selected.label);
                        }, placeholder: "Select notice type", options: DEFAULT_SELECTABLE_TYPES.map(type => ({
                            id: type,
                            label: type
                        })), selected: noticeType ? { id: noticeType, label: noticeType } : '', value: noticeType ? { id: noticeType, label: noticeType } : '', borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true, maxHeight: 250 }))),
            React.createElement("div", { className: "flex w-full px-5 py-5" },
                React.createElement("div", { className: "flex flex-col w-2/6" },
                    React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700" }, "Notice Content *"),
                    React.createElement("p", { className: "not-italic font-normal text-sm text-gray-500 mt-1 w-3/4" },
                        "Upload a file of your notice, or manually paste the notice content, to submit. ",
                        React.createElement("b", null, "You may only submit a single notice at a time. "),
                        "This is to ensure that each notice can be individually searched and tagged with the correct notice type.")),
                React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-3/5" },
                    loading ? (React.createElement("div", { className: "flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-48" },
                        React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }))) : (React.createElement(Dropzone, { onDrop: files => {
                            setFiles(files);
                        }, accept: '.pdf,.txt' }, ({ getRootProps, getInputProps }) => (React.createElement("div", Object.assign({ className: "w-full" }, getRootProps(), { onClick: () => {
                            dropzoneRef && dropzoneRef.current.click();
                        } }),
                        React.createElement("div", { className: "flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-48" },
                            React.createElement("div", { className: "flex flex-col items-center text-center" },
                                uploadedSnapshots && uploadedSnapshots.length ? (React.createElement(FilledCircleIcon, { className: "mb-2 text-blue-150" })) : (React.createElement(AddImageIcon, { className: "mx-auto h-12 w-12 text-gray-500" })),
                                React.createElement(React.Fragment, null,
                                    React.createElement("p", { className: "mt-1 mr-1 text-sm text-gray-700" },
                                        React.createElement("button", { type: "button", className: "mr-1 font-medium focus:outline-none focus:underline transition duration-150 ease-in-out", style: { color: 'rgb(47, 128, 237)' } },
                                            "Upload",
                                            ' ',
                                            uploadedSnapshots && uploadedSnapshots.length
                                                ? 'additional files '
                                                : 'a file '),
                                        "or drag and drop"),
                                    React.createElement("p", { className: "mt-1 text-xs text-gray-500" }, "TXT or PDF files")),
                                React.createElement("input", Object.assign({ id: "temp-upload" }, getInputProps(), { ref: dropzoneRef })))))))),
                    (!uploadedSnapshots || !uploadedSnapshots.length) && (React.createElement("textarea", { className: "mt-2 rounded border border-gray-300 font-medium text-sm text-gray-700 p-2", rows: 4, placeholder: uploadedSnapshots.length
                            ? 'Remove the uploaded files to copy and paste content'
                            : 'Paste your notice content here', value: noticeText, onChange: e => setNoticeText(e.target.value), disabled: !!uploadedSnapshots.length })))),
            uploadedSnapshots && uploadedSnapshots.length !== 0 && (React.createElement("div", { className: "flex w-full px-5 py-5" },
                React.createElement("div", { className: "flex flex-col w-2/6" },
                    React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-700" }, "Uploaded Files"),
                    React.createElement("p", { className: "not-italic font-medium text-sm text-gray-500 mt-1 w-3/4" }, "Click the \"x\" button to remove uploaded files")),
                React.createElement("div", { className: "flex flex-wrap items-start not-italic font-normal text-sm text-black w-4/6" }, uploadedSnapshots.map((snap, i) => (React.createElement("div", { key: i, className: "flex rounded-lg content-around items-center px-2 py-1 mr-2 mb-2 bg-blue-100 font-semibold text-xs text-blue-500" },
                    React.createElement("p", { className: "mr-2" }, snap && snap.ref ? truncateString(snap.ref.name) : ''),
                    React.createElement(XIcon, { className: "cursor-pointer h-3 w-3 text-blue-500", onClick: () => {
                            setUploadedSnapshots(uploadedSnapshots.filter(s => s.ref.fullPath !== snap.ref.fullPath));
                        } }))))))),
            React.createElement("div", { className: "flex justify-end w-full p-5 rounded bg-gray-100" },
                React.createElement(SubmitButton, { onClick: () => submitNotices(), disabled: !selectedOrganization ||
                        submitting ||
                        (!uploadedSnapshots.length && !noticeText) ||
                        (shouldShowPublicationMethod && !publicationMethod), loading: submitting, text: "Submit", type: "button" }))));
    };
    const renderUseIngestionOption = () => (React.createElement("div", { className: "w-full p-5 border-b border-gray-300" },
        React.createElement("p", { className: "not-italic font-normal text-sm text-gray-700", style: { alignSelf: 'center' } },
            "Use Column to manage your public notices and we'll automatically upload published notices to the statewide website. If you would like to discuss this option, please use",
            ' ',
            React.createElement("a", { className: "text-blue-700 underline", href: "https://meetings.hubspot.com/alex-lutz/misc-state-site-uploads" }, "this link to schedule a time"),
            ' ',
            "that works for you.")));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow mb-20" },
            React.createElement("div", null,
                React.createElement(SettingsHeader, { header: "Upload Settings", description: "Specify which upload method you prefer." },
                    React.createElement("button", { type: "button", className: "px-3 bg-white box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => window.open('https://help.column.us/category/74-uploads-to-public-site') },
                        React.createElement("span", { className: "pr-2" },
                            React.createElement(InformationCircle, { className: "text-gray-750" })),
                        "Help"))),
            renderOptionHeader(1, 'Upload notices directly', expandOption1, setExpandOption1),
            expandOption1 && renderDirectUploadOption(),
            renderOptionHeader(2, 'Upload via FTP feed', expandOption2, setExpandOption2),
            expandOption2 && renderFTPFeedOption(),
            renderOptionHeader(3, 'Upload notices automatically', expandOption3, setExpandOption3),
            expandOption3 && renderUseIngestionOption(),
            showSubmissionSuccessfulDialog && (React.createElement(TailwindModal, { header: "Submission successful.", close: () => setShowSubmissionSuccessfulDialog(false) },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "font-medium text-base text-gray-500 mb-8" }, "The submitted notices will be viewable on your site upon publication date."),
                    React.createElement("button", { className: `rounded-md font-semibold bg-blue-100 text-blue-500 text-sm flex items-center px-5 py-2`, type: "button", onClick: () => setShowSubmissionSuccessfulDialog(false) }, "Back to FTP Settings")))))));
};
export default withStyles(styles)(FTPSettings);
