var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
const RegisterOrganizationInput = (_a) => {
    var { fieldName, styleProps, onChange, valid, errorMessage, required } = _a, otherProps = __rest(_a, ["fieldName", "styleProps", "onChange", "valid", "errorMessage", "required"]);
    return (React.createElement("div", { className: "w-full block w-6/12" },
        React.createElement("div", null,
            React.createElement("p", { className: "text-gray-800 font-medium text-sm leading-5" },
                fieldName,
                required && ' *')),
        React.createElement("input", Object.assign({ className: `${valid === false
                ? 'border-red-400'
                : 'focus:shadow-outline-blue focus:border-blue-300 border-gray-400'} form-input hover:border-blue-300 hover:shadow-outline-blue appearance-none rounded-md relative w-full bg-white px-4 py-3 mt-2 mb-2 border placeholder-gray-500 text-blue-800 font-medium text-sm leading-6` }, otherProps, { onChange: e => onChange(e.target.value) })),
        !valid && errorMessage && (React.createElement("p", { className: "text-red-400 sm:text-sm" }, errorMessage))));
};
export default RegisterOrganizationInput;
