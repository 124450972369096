import { LineItemType, NoticeType } from '../enums';
/**
 * This function is used for applying the rounding difference in cents to the last non-fee line item.
 * If a non-fee line item does not exist, this function will return 0 so we don't lose the rounding difference.
 */
export const getLastNonFeeLineItemIndex = (lineItems) => {
    let lastNonFeeLineItemIndex = 0;
    for (const [i, item] of lineItems.entries()) {
        if (!isAdditionalFeeLineItem(item))
            lastNonFeeLineItemIndex = i;
    }
    return lastNonFeeLineItemIndex;
};
/**
 * Additional fee line items are publisher fees on the organization object
 * Fee line items will NOT start with a date string in the description (ex. description: Affidavit Fee)
 * Non-fee line item will start with a date string (ex. 07/01/2022: Custom Notice)
 *
 * @param lineItem
 * @returns boolean
 */
export const isAdditionalFeeLineItem = (lineItem) => {
    // Prefer the type field when it exists
    if (typeof lineItem.type === 'number') {
        return lineItem.type === LineItemType.fee.value;
    }
    // no description means not custom
    if (!lineItem.description)
        return false;
    // return false on line items of the format mm/dd/yyyy:
    if (lineItem.description.match(/^\d{2}\/\d{2}\/\d{4}/))
        return false;
    return true;
};
export function getAdditionalFeesFromRate(rate) {
    if (rate.additionalFees) {
        return rate.additionalFees.filter(fee => fee.amount);
    }
    // TODO: Remove this once we've migrated all rates to use additionalFees
    if (rate.additionalFee)
        return [Object.assign(Object.assign({}, rate.additionalFee), { perAffidavitFee: rate.perAffidavitFee })];
    return [];
}
export function getAffidavitAdditionalFeeLineItemsFromRate(rate, notice, lastPubDatePlusOneMinute) {
    var _a;
    const additionalFees = getAdditionalFeesFromRate(rate);
    if (!((_a = notice.mail) === null || _a === void 0 ? void 0 : _a.length))
        return [];
    const totalNumberOfAffidavits = notice.mail.reduce((a, m) => a + (m.copies || 0), 0);
    const affidavitFeeLineItems = additionalFees
        .filter(additionalFee => additionalFee.perAffidavitFee)
        .map(additionalFee => ({
        date: lastPubDatePlusOneMinute,
        amount: additionalFee.amount * totalNumberOfAffidavits,
        description: additionalFee.description,
        type: LineItemType.fee.value
    }));
    return affidavitFeeLineItems;
}
export const getNonAffidavitAdditionalFeeLineItemsFromRate = (rate, notice, lastPubDatePlusOneMinute, newspaper) => {
    var _a;
    const additionalFees = getAdditionalFeesFromRate(rate);
    // allow for additional line items at the rate level
    // Note: some typeform-zap notice types have fixed pricing and
    // no associated rate; do not add rate-associated line items
    // for those
    const customNoticeType = ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.allowedNotices) === null || _a === void 0 ? void 0 : _a.find((type) => type.value === notice.noticeType)) || null;
    const blockAdditionalRateFee = customNoticeType &&
        !customNoticeType.rate &&
        !NoticeType.by_value(notice.noticeType);
    if (blockAdditionalRateFee)
        return [];
    const additionalFeeLineItems = additionalFees
        .filter(additionalFee => !additionalFee.perAffidavitFee)
        .map(additionalFee => {
        let feeAmount = additionalFee.amount;
        if (additionalFee.perRun && notice.publicationDates)
            feeAmount *= notice.publicationDates.length;
        return {
            date: lastPubDatePlusOneMinute,
            amount: feeAmount,
            description: additionalFee.description,
            type: LineItemType.fee.value
        };
    });
    return additionalFeeLineItems;
};
