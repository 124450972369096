import React from 'react';
import Tooltip from 'components/Tooltip';
const tableTitleHeadStyles = 'inline-block text-base text-black text-left py-6 focus:outline-none font-medium border-b-2 border-transparent hover:border-b-2 hover:border-blue-600 hover:text-blue-950';
const highlight = 'border-b-2 border-blue-600 text-blue-950';
export const CustomerDrawerHeader = ({ title, setTitle, customerData, hasVerifiedMembers }) => {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement("div", null,
        React.createElement("div", { className: 'inline-block ml-8 mr-8' },
            React.createElement("button", { onClick: () => setTitle('Details'), id: "details", className: `${tableTitleHeadStyles} ${title === 'Details' ? highlight : 'text-blue-800'}` },
                React.createElement("div", null, "Details"))),
        !!customerData && (React.createElement("div", { className: 'inline-block mr-8' },
            ((_c = (_b = (_a = customerData === null || customerData === void 0 ? void 0 : customerData.user) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.allowedOrganizations) === null || _c === void 0 ? void 0 : _c.length) && (React.createElement("button", { onClick: () => setTitle('Organization'), id: "organization", className: `${tableTitleHeadStyles} ${title === 'Organization' ? highlight : 'text-gray-800'}` },
                React.createElement("div", null, "Organization"))),
            !((_f = (_e = (_d = customerData === null || customerData === void 0 ? void 0 : customerData.user) === null || _d === void 0 ? void 0 : _d.data()) === null || _e === void 0 ? void 0 : _e.allowedOrganizations) === null || _f === void 0 ? void 0 : _f.length) && (React.createElement(Tooltip, { helpText: "This customer doesn't belong to an organization", position: "topCenter" },
                React.createElement("div", { id: "organization", className: "inline-block text-base text-grey-200 text-left py-6 focus:outline-none font-medium border-b-2 border-transparent" },
                    React.createElement("div", null, "Organization")))))),
        !customerData && (React.createElement("div", { className: 'inline-block mr-8' },
            hasVerifiedMembers && (React.createElement("div", null,
                React.createElement("button", { onClick: () => setTitle('Members'), id: "members", className: `${tableTitleHeadStyles} ${title === 'Members' ? highlight : 'text-gray-800'}` },
                    React.createElement("div", null, "Members")))),
            !hasVerifiedMembers && (React.createElement("div", { role: "button" },
                React.createElement(Tooltip, { helpText: "This organization doesn't have verified customers.", position: "topCenter" },
                    React.createElement("div", { id: "organization", className: "inline-block text-base text-grey-200 text-left py-6 focus:outline-none font-medium border-b-2 border-transparent" },
                        React.createElement("div", null, "Members"))))))),
        React.createElement("div", { className: 'inline-block mr-8' },
            React.createElement("button", { onClick: () => setTitle('Settings'), id: "settings", "data-testid": "settings", className: `${tableTitleHeadStyles} ${title === 'Settings' ? highlight : 'text-gray-800'}` },
                React.createElement("div", null, "Settings")))));
};
