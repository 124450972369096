/**
 * NOTE: When adding or editing these, please make corresponding changes in the Notion page:
 * https://www.notion.so/column/Sync-Event-Statuses-294fd9d2977f4143aa16560739ce047d
 */
export const SyncStatusData = {
    // 100 Category: Column errors
    // These should alert integrations engineers to address, and should be
    // reserved for cases that require such attention. Issues that publishers or
    // support can handle should have a 400 or 500 category status.
    unexpected_column: {
        value: 100,
        label: 'Unexpected Error',
        in_app_notification_text: 'We encountered an unexpected error and did not sync this notice; Column engineers have been notified to address the issue.',
        key: 'unexpected_column'
    },
    incomplete_implementation: {
        value: 101,
        label: 'Incomplete Implementation',
        in_app_notification_text: 'Syncing this notice requires additional configuration on our backend.',
        key: 'incomplete_implementation'
    },
    server_unavailable_column: {
        value: 103,
        label: 'Column Server Unavailable',
        in_app_notification_text: 'We could not connect to our shared FTP server, so this notice will not sync; Column engineers have been notified to investigate the issue.',
        key: 'server_unavailable_column'
    },
    unexpected_column_build: {
        value: 104,
        label: 'Unexpected Build Error',
        in_app_notification_text: 'We encountered an unexpected error and did not sync this notice; Column engineers have been notified to address the issue.',
        key: 'unexpected_column_build'
    },
    // 200 Category: Success
    // Likely self-explanatory and least likely to need additional values.
    success: {
        value: 200,
        label: 'Success',
        in_app_notification_text: 'Notice synced successfully.',
        key: 'success'
    },
    // 300 Category: No-ops
    // Cases where we want to capture status but not show the publisher the
    // message.
    awaiting_response: {
        value: 300,
        label: 'Awaiting Response',
        in_app_notification_text: 'This notice is waiting for the publisher system to send a response. It may or may not sync.',
        key: 'awaiting_response.'
    },
    debounced: {
        value: 301,
        label: 'Debounced',
        in_app_notification_text: 'Concurrent sync trigger detected, combining events.',
        key: 'debounced'
    },
    delayed: {
        value: 302,
        label: 'Delayed',
        in_app_notification_text: 'The publisher has a delay configured for automatic syncs at deadline.',
        key: 'delayed'
    },
    verified_response: {
        value: 303,
        label: 'Verified Response',
        in_app_notification_text: 'We recieved a timely response from the publisher system',
        key: 'verified_response'
    },
    cancelled_before_first_sync: {
        value: 304,
        label: 'Cancelled before first sync',
        in_app_notification_text: 'The notice was cancelled prior to its first sync with the publisher system',
        key: 'cancelled_before_first_sync'
    },
    // 400 Category: Client error
    // Event message should add context to help publishers address the issue
    // (e.g., what required data is missing and how to resolve it)
    missing_required_data: {
        value: 400,
        label: 'Missing Required Data',
        in_app_notification_text: 'This notice will not sync; it is missing required data.',
        key: 'missing_required_data'
    },
    confirmation_required: {
        value: 401,
        label: 'Confirmation Required',
        in_app_notification_text: 'This notice will not sync unless it is confirmed by the publisher.',
        key: 'confirmation_required'
    },
    upfront_payment_required: {
        value: 402,
        label: 'Upfront Payment Required',
        in_app_notification_text: 'This notice requires upfront payment, so it will not sync until it is paid for.',
        key: 'upfront_payment_required'
    },
    invoice_required: {
        value: 403,
        label: 'Invoice Required',
        in_app_notification_text: 'This notice will not sync unless it has an invoice.',
        key: 'invoice_required'
    },
    notice_not_found: {
        value: 404,
        label: 'Notice Does Not Exist',
        in_app_notification_text: 'We cannot sync this notice, as it does not exist in our database.',
        key: 'notice_not_found'
    },
    cannot_sync_ad_after_deadline: {
        value: 405,
        label: 'Cannot Sync After Deadline',
        in_app_notification_text: 'We cannot sync this notice after deadline, so it will not sync.',
        key: 'cannot_sync_ad_after_deadline'
    },
    cannot_sync_ad_after_publication: {
        value: 406,
        label: 'Cannot Sync After Publication',
        in_app_notification_text: 'We cannot sync this notice after the first publication date, so it will not sync.',
        key: 'cannot_sync_ad_after_publication'
    },
    customer_is_blocked: {
        value: 407,
        label: 'Cannot Sync with Blocked Customer',
        in_app_notification_text: 'The associated customer is blocked from placing notices, so this notice will not sync.',
        key: 'customer_is_blocked'
    },
    invalid_billing_status: {
        value: 408,
        label: 'Invalid billing status',
        in_app_notification_text: 'The invoice for this notice has an invalid billing status, so this notice will not sync.',
        key: 'invalid_billing_status'
    },
    notice_is_a_teapot: {
        value: 418,
        label: 'Notice is a Teapot',
        in_app_notification_text: 'This notice cannot brew coffee; it is a teapot.',
        key: 'notice_is_a_teapot'
    },
    // 500 Category: Publisher system errors
    // Event message should add context to help publishers address the issue
    // (e.g., who to contact about the problem)
    unexpected_publisher_system: {
        value: 500,
        label: 'Unexpected Error with Publisher System',
        in_app_notification_text: 'We encountered an unexpected error when trying to send this notice to the publisher system.',
        key: 'unexpected_publisher_system'
    },
    server_unavailable_publisher_system: {
        value: 503,
        label: 'Server Unavailable',
        in_app_notification_text: 'We could not connect to the publisher system server to sync this notice.',
        key: 'server_unavailable_publisher_system'
    },
    no_response_from_publisher_system: {
        value: 504,
        label: 'No Response From Publisher System',
        in_app_notification_text: 'We did not receive a timely response from the publisher system; this notice may not sync.',
        key: 'no_response_from_publisher_system'
    }
};
