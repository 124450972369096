export const InvoiceItemTypeData = {
    processing_fee_oop: {
        value: 1,
        label: 'Processing Fee (Out of Platform)',
        key: 'processing_fee_oop'
    },
    public_notice_refund: {
        value: 2,
        label: 'Public Notice Refund',
        key: 'public_notice_refund'
    },
    processing_fee_reversal: {
        value: 3,
        label: 'Processing Fee Reversal',
        key: 'processing_fee_reversal'
    }
};
