import { ColumnButton } from 'components/ColumnButton';
import React from 'react';
const ExitWithoutSavingModal = ({ keepEditing, exitWithoutSaving, body, id, width, maxWidth, disableOverflow }) => {
    // TODO: we need to change the image URL to something in the codebase instead of URL
    return (React.createElement("div", { id: id, className: `fixed z-100 inset-0 ${!disableOverflow ? 'overflow-y-auto' : ''}` },
        React.createElement("div", { className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity" },
                React.createElement("div", { className: "absolute inset-0 bg-black opacity-50" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: `inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id}-inner` },
                React.createElement("div", { className: "p-12 border-b border-gray-300" },
                    React.createElement("div", { className: `sm:mt-0 text-center` },
                        React.createElement("div", { className: "flex justify-center" },
                            React.createElement("img", { src: 'https://enotice-production.imgix.net/custom-documents/permalink/e311.1f52d-giphy-6--unscreen.gif', style: {
                                    clipPath: 'circle()',
                                    backgroundColor: '#FFDF66',
                                    width: '104px',
                                    height: '104px'
                                } })),
                        React.createElement("h3", { className: "text-xl pt-12 leading-6 font-semibold text-gray-900", id: "modal-headline" }, "Are you sure you want to exit?"),
                        body && (React.createElement("div", { className: "pt-6 pb-12" },
                            React.createElement("span", { className: "text-sm font-medium leading-5 text-gray-700" }, body))),
                        React.createElement("div", { className: "text-center" },
                            React.createElement("span", { className: "pr-4" },
                                React.createElement(ColumnButton, { secondary: true, size: "lg", buttonText: "Keep editing", onClick: keepEditing })),
                            React.createElement(ColumnButton, { primary: true, size: "lg", buttonText: "Yes, discard", onClick: exitWithoutSaving }))))))));
};
export default ExitWithoutSavingModal;
