import React, { useState } from 'react';
import classNames from 'classnames';
import { ArrowDownIcon } from 'icons';
import ColumnSelectItem from './ColumnSelectItem';
const ColumnSelect = ({ options, className, value, onChange, renderItem, itemsListDescription, inline, inlineTextColor, size, allowUndefined }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedValue = options.find(o => o.value === value);
    const selectedOption = allowUndefined
        ? selectedValue
        : selectedValue || options[0];
    const onOptionSelected = (o) => {
        setIsOpen(false);
        if (onChange) {
            onChange(o.value);
        }
    };
    return (React.createElement("div", { className: classNames('relative font-normal text-sm', className) },
        React.createElement("div", { "aria-haspopup": "listbox", "aria-expanded": isOpen, "aria-controls": "select-listbox", tabIndex: 0, className: classNames(`${inlineTextColor} cursor-pointer flex flex-row items-center rounded-md h-full`, {
                'border-gray-400': !isOpen,
                'border-blue-400': isOpen,
                'p-2 border': !inline,
                'h-12': size === 'large'
            }), onFocus: () => setIsOpen(true), onBlur: e => {
                var _a;
                // Detect clicks outside the select
                if (!((_a = e.target.parentElement) === null || _a === void 0 ? void 0 : _a.contains(e.relatedTarget))) {
                    setIsOpen(false);
                }
            } },
            (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) ? (React.createElement("span", null, selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label)) : (React.createElement("span", { className: "opacity-50" }, "Select option")),
            React.createElement(ArrowDownIcon, { className: classNames({
                    'ml-auto': !inline,
                    'ml-1': inline
                }) })),
        isOpen && (React.createElement("ul", { id: "select-listbox", role: "listbox", tabIndex: -1, className: "absolute w-full bg-white p-1.5 mt-1 rounded border border-gray-300 z-50" },
            itemsListDescription && (React.createElement("p", { className: "uppercase font-semibold text-xs text-grey-300 py-2" }, itemsListDescription)),
            options.map(o => {
                const active = o.value === (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value);
                return (React.createElement("li", { key: o.value, role: "option", "aria-selected": active, onClick: () => onOptionSelected(o) }, renderItem ? (renderItem(o, active)) : (React.createElement(ColumnSelectItem, { active: active, option: o }))));
            })))));
};
export default ColumnSelect;
