var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker, DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import CurrencyFormatter from 'components/CurrencyFormatter';
import { RateType } from 'lib/enums';
function ForceFloat(props) {
    const { inputRef, onChange } = props, other = __rest(props, ["inputRef", "onChange"]);
    return (React.createElement(NumberFormat, Object.assign({}, other, { getInputRef: inputRef, onValueChange: values => {
            if (onChange) {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }
        }, decimalScale: 2, fixedDecimalScale: true })));
}
class MTableEditField extends React.Component {
    getProps() {
        const props = __rest(this.props, []);
        return props;
    }
    renderLookupField() {
        return (React.createElement(Select, Object.assign({}, this.getProps(), { id: "select-rate-type", value: this.props.value === undefined ? '' : this.props.value, onChange: event => this.props.onChange(event.target.value), style: {
                fontSize: 13
            } }), Object.keys(this.props.columnDef.lookup).map(key => (React.createElement(MenuItem, { key: key, value: key }, this.props.columnDef.lookup[key])))));
    }
    renderBooleanField() {
        return (React.createElement(Checkbox, Object.assign({}, this.getProps(), { value: String(this.props.value), checked: Boolean(this.props.value), onChange: event => this.props.onChange(event.target.checked), style: {
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0
            } })));
    }
    renderDateField() {
        return (React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(DatePicker, Object.assign({}, this.getProps(), { format: "dd.MM.yyyy", value: this.props.value || null, onChange: this.props.onChange, clearable: true, InputProps: {
                    style: {
                        fontSize: 13
                    }
                } }))));
    }
    renderTimeField() {
        return (React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(TimePicker, Object.assign({}, this.getProps(), { format: "HH:mm:ss", value: this.props.value || null, onChange: this.props.onChange, clearable: true, InputProps: {
                    style: {
                        fontSize: 13
                    }
                } }))));
    }
    renderDateTimeField() {
        return (React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(DateTimePicker, Object.assign({}, this.getProps(), { format: "dd.MM.yyyy HH:mm:ss", value: this.props.value || null, onChange: this.props.onChange, clearable: true, InputProps: {
                    style: {
                        fontSize: 13
                    }
                } }))));
    }
    renderTextField() {
        return (React.createElement(TextField, Object.assign({}, this.getProps(), { style: this.props.columnDef.type === 'numeric' ? { float: 'right' } : {}, type: this.props.columnDef.type === 'numeric' ? 'number' : 'text', placeholder: this.props.columnDef.title, value: this.props.value === undefined ? '' : this.props.value, onChange: event => this.props.onChange(event.target.value), InputProps: {
                style: {
                    fontSize: 13
                }
            } })));
    }
    renderEnoticeCurrencyField() {
        return (React.createElement(TextField, { value: this.props.value, onChange: event => this.props.onChange(event.target.value), id: "formatted-numberformat-input", InputProps: {
                style: {
                    fontSize: 13
                },
                inputComponent: CurrencyFormatter
            } }));
    }
    renderRateCodeField() {
        return 'Rate codes are assigned automatically';
    }
    renderRoundOffField(rowData) {
        if (parseInt(rowData.rateType, 10) === RateType.inch.value ||
            parseInt(rowData.rateType, 10) === RateType.column_inch.value)
            return (React.createElement(TextField, { value: this.props.value, onChange: event => this.props.onChange(event.target.value), id: "round-off-at", InputProps: {
                    inputComponent: ForceFloat,
                    style: {
                        fontSize: 13
                    }
                } }));
        return 'n/a';
    }
    renderCurrencyField() {
        return 'ok';
    }
    render() {
        let component = 'ok';
        const rate = parseInt(this.props.rowData.rateType, 10);
        if ((this.props.columnDef.field === 'rate_1' ||
            this.props.columnDef.field === 'rate_2') &&
            rate === RateType.per_run.value) {
            return 'n/a';
        }
        if ((this.props.columnDef.field === 'rate_0' ||
            this.props.columnDef.field === 'rate_1' ||
            this.props.columnDef.field === 'rate_2') &&
            rate === RateType.berthoud_government.value)
            return 'n/a';
        if ((this.props.columnDef.field === 'rate_0' ||
            this.props.columnDef.field === 'rate_1' ||
            this.props.columnDef.field === 'rate_2' ||
            this.props.columnDef.field === 'minimum') &&
            rate === RateType.battle_born.value) {
            return 'n/a';
        }
        if (this.props.columnDef.type === 'currency') {
            return this.renderEnoticeCurrencyField();
        }
        if (this.props.columnDef.field === 'code') {
            return this.renderRateCodeField();
        }
        if (this.props.columnDef.field === 'roundOff') {
            return this.renderRoundOffField(this.props.rowData);
        }
        if (this.props.columnDef.field === 'isGovernment') {
            if (this.props.rowData.isDefaultLiner ||
                this.props.rowData.isDefaultDisplay) {
                return 'n/a';
            }
            return this.renderBooleanField();
        }
        if (this.props.columnDef.field === 'enotice_fee_pct') {
            return 'Column fees are assigned automatically';
        }
        if (this.props.columnDef.lookup) {
            component = this.renderLookupField();
        }
        else if (this.props.columnDef.type === 'boolean') {
            component = this.renderBooleanField();
        }
        else if (this.props.columnDef.type === 'date') {
            component = this.renderDateField();
        }
        else if (this.props.columnDef.type === 'time') {
            component = this.renderTimeField();
        }
        else if (this.props.columnDef.type === 'datetime') {
            component = this.renderDateTimeField();
        }
        else {
            component = this.renderTextField();
        }
        return component;
    }
}
MTableEditField.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    columnDef: PropTypes.object.isRequired
};
export default MTableEditField;
