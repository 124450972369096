import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import TemplateSettingsTabs from './TemplateSettingsTabs';
import BasicLayoutSettings from './basicLayoutSettings';
import AdTemplateSettings from './adTemplateSettings';
export const BASIC_LAYOUT_SETTINGS = 'Basic Layout';
export const AD_TEMPLATE_SETTINGS = 'Ad Template Settings';
export const AFFIDAVIT_SETTINGS_TABS = [
    BASIC_LAYOUT_SETTINGS,
    AD_TEMPLATE_SETTINGS
];
/**
 * Wrapper component around affidavit settings that shows:
 * 1) Basic affidavit html and configurations
 * 2) If automated affidavits are enabled, details on automated affidavit uploads
 */
export default function TemplateSettings({ activeOrganization }) {
    const [activeTab, setActiveTab] = useState(BASIC_LAYOUT_SETTINGS);
    // Store a live-refreshing local copy of the active organization
    const [localActiveOrganization, setLocalActiveOrganization] = useState(activeOrganization);
    useEffect(() => {
        const unsubscribe = activeOrganization.ref.onSnapshot(snapshot => {
            if (!exists(snapshot))
                return;
            setLocalActiveOrganization(snapshot);
        });
        return () => unsubscribe();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow mb-24" },
        React.createElement(TemplateSettingsTabs, { setActiveTab: setActiveTab, activeTab: activeTab, tabs: [BASIC_LAYOUT_SETTINGS, AD_TEMPLATE_SETTINGS] }),
        activeTab === BASIC_LAYOUT_SETTINGS && (React.createElement(BasicLayoutSettings, { activeOrganization: localActiveOrganization })),
        activeTab === AD_TEMPLATE_SETTINGS && (React.createElement(AdTemplateSettings, { activeOrganization: localActiveOrganization }))));
}
