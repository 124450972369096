import React from 'react';
import { MilestoneCheckIcon, DotIcon, MilestoneDiagonalArrowIcon, QuestionMarkWithCircleIcon, XIcon } from 'icons';
import classnames from 'classnames';
import Tooltip from 'components/Tooltip';
function MilestoneNode({ milestoneElement, isFirst, isLast }) {
    function getMilestoneIcon(status) {
        switch (status) {
            case 'error':
                return React.createElement(XIcon, { className: "h-4 w-4 text-red-500" });
            case 'complete':
                return React.createElement(MilestoneCheckIcon, { className: "text-blue-500" });
            case 'inactive':
                return React.createElement(DotIcon, { className: "text-grey-200" });
            case 'completeOutsideColumn':
                return React.createElement(MilestoneDiagonalArrowIcon, { className: "text-blue-500" });
        }
    }
    const { status, text, tooltip } = milestoneElement;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `flex items-center h-8 lg:h-6` },
            React.createElement("div", { className: classnames('flex my-1 items-center justify-center w-5 h-5 rounded-full border-1.5', {
                    'border-blue-500': status === 'complete' || status === 'completeOutsideColumn',
                    'border-grey-200': status === 'inactive',
                    'border-red-500': status === 'error'
                }) },
                React.createElement("div", null, getMilestoneIcon(status)))),
        React.createElement("div", { className: classnames('pt-2 sm:pt-3 lg:pt-0 milestone-text w-5 flex whitespace-normal h-10 lg:whitespace-no-wrap lg:h-5 items-center', {
                'justify-left': isFirst,
                'justify-end': isLast,
                'justify-center': !isFirst && !isLast
            }) },
            text,
            tooltip && (React.createElement(Tooltip, { id: "milestone-help-text", helpText: tooltip, position: 'top', innerBackgroundColor: "gray" },
                React.createElement("div", null, status === 'completeOutsideColumn' && (React.createElement(QuestionMarkWithCircleIcon, { className: "h-4 w-4 ml-1 text-grey-300" }))))))));
}
export default MilestoneNode;
