var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import api from 'api';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import { logAndCaptureException } from 'utils';
const NoticePlacedAnonymouslyModal = ({ setOpen, user, newspaper }) => {
    return (React.createElement(CancelOrSubmitModal, { tertiaryButtonText: 'Skip for now', primaryButtonText: 'Reset password', noExitOutsideModal: true, setOpen: setOpen, onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
            const { success, error, passwordResetLink } = yield api.get(`users/${user.id}/password-reset-link`);
            if (success) {
                setOpen(false);
                window.open(passwordResetLink);
            }
            else {
                logAndCaptureException(error, 'Unable to generate password reset link', {
                    userId: user.id
                });
            }
        }) },
        React.createElement("div", { className: "mx-10 mb-4 text-center z-20 font-semibold text-xl", id: "notice-placed-header" },
            React.createElement("div", { className: "flex justify-center" },
                React.createElement("img", { src: 'https://enotice-production.imgix.net/custom-documents/permalink/838f.6f277-endless_newsprint.gif', className: "bg-yellow-400", style: {
                        clipPath: 'circle()',
                        width: '104px',
                        height: '104px'
                    } })),
            React.createElement("div", { className: "text-xl pt-10 leading-6 font-semibold text-gray-900" },
                "Notice placed",
                ' ',
                React.createElement("span", { role: "img", "aria-label": "Party popper" }, "\uD83C\uDF89"))),
        React.createElement("div", { className: "mb-12 mx-4 text-center text-sm font-medium leading-5 text-gray-700", id: "notice-placed-modal-body" },
            newspaper.data().name,
            " has received your notice. We created a temporary password for your Column account. Reset your password so you can login later to access notice proofs, invoices and affidavits.")));
};
export default NoticePlacedAnonymouslyModal;
