var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { take, takeEvery, call, put, select, takeLatest, all } from 'redux-saga/effects';
import { logAndCaptureException } from 'utils';
import { eventChannel } from 'redux-saga';
import { Firestore } from 'EnoticeFirebase';
import { authSelector, AuthTypes } from 'redux/auth';
import { push } from 'connected-react-router';
import { NoticeType, NoticeStatusType, OccupationType, OrganizationType } from 'lib/enums';
import { getMinAndMaxColumnsForPaper } from 'lib/notice/columns';
import { getCustomer, getOrCreateCustomer, getOrCreateCustomerOrganization } from 'lib/notice/customer';
import { addDraftMailToNotice, getMailDataFromNoticeOrDraft, getNoticeMailAndSetOnDraft, refreshDraftMail } from 'lib/mail';
import { exists } from 'lib/types';
import { appendToCurrentParams, getLocationParams, updateHistoryNoRerender } from 'utils/urls';
import { generateFormattedFooter } from 'lib/headers_footers/footers';
import { checkForDefault, rateCanPublishWithPlacementData } from 'routes/placeScroll/rates';
import api from 'api';
import { getAdTemplate, getDefaultColumnsForUserUserOrgWithNewspaper, getNoticeType, getNoticeTypeFromNoticeData, removeUndefinedFields, getXMLSyncExportSettings } from 'lib/helpers';
import { getNoticeTypeData } from 'lib/publishers';
import { supportsDisplay } from 'lib/notice/rates';
import { getFirebaseContext, timestampOrDateToTimestamp } from 'utils/firebase';
import { getNoticeDraftFieldsFromPlacement } from 'utils/dataCleaning';
import { getClosestFuturePublishingDay } from 'lib/utils/deadlines';
import moment from 'moment';
import { searchOrganizations, shouldDisableDate } from 'routes/placeScroll/helpers';
import { PlacementError } from 'lib/errors/PlacementError';
import { addDraftFilesToNotice, getFilesDataFromNoticeOrDraft, getNoticeFilesAndSetOnDraft, refreshDraftFiles } from 'lib/files';
import AuthActions from '../redux/auth';
import PlacementActions, { PlacementTypes, placementSelector } from '../redux/placement';
import ToastActions from '../redux/toast';
export function* createNotice() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const auth = yield select(authSelector);
    try {
        if (auth.user &&
            auth.user.data().occupation !== OccupationType.publishing.value) {
            yield put(PlacementActions.setFiler(auth.user.ref));
        }
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error in createNotice', {
            userId: (_a = auth.user) === null || _a === void 0 ? void 0 : _a.id
        });
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
    const newspaperRef = (exists(auth.user) &&
        auth.user.data().occupation === OccupationType.publishing.value &&
        auth.user.data().activeOrganization) ||
        (exists(auth.orgContext) &&
            ((_b = auth.orgContext.data()) === null || _b === void 0 ? void 0 : _b.organizationType) ===
                OrganizationType.newspaper.value &&
            auth.orgContext.ref) ||
        null;
    let adTemplateRef = null;
    let rateRef = null;
    yield put(PlacementActions.setNewspaper(newspaperRef));
    let newspaper;
    if (newspaperRef) {
        newspaper = yield call([newspaperRef, newspaperRef.get]);
        adTemplateRef = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().adTemplate;
        if (!adTemplateRef) {
            yield put(push('/'));
            return;
        }
        yield put(PlacementActions.setTemplate(adTemplateRef));
        rateRef = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().defaultLinerRate;
        yield put(PlacementActions.setRate(rateRef));
    }
    if (auth.user && auth.user.data().defaultTemplate) {
        adTemplateRef = auth.user.data().defaultTemplate;
        yield put(PlacementActions.setTemplate(adTemplateRef));
    }
    const placement = yield select(placementSelector);
    const filedBy = auth.isPublisher
        ? undefined
        : ((_d = (_c = auth.user) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.activeOrganization) || undefined;
    const createdBy = auth.isPublisher ? (_e = auth.user) === null || _e === void 0 ? void 0 : _e.ref : undefined;
    const noticeType = ((_f = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _f === void 0 ? void 0 : _f.defaultNoticeType) || NoticeType.custom.value;
    /**
     * NoticeType.custom.value is the default initial value;
     * only update placement if the newspaper has specified
     * a different default notice type
     */
    if (noticeType !== NoticeType.custom.value) {
        yield put(PlacementActions.setNoticeType(noticeType));
    }
    const anonymousFilerId = ((_g = auth.userAuth) === null || _g === void 0 ? void 0 : _g.isAnonymous)
        ? ((_h = auth.userAuth) === null || _h === void 0 ? void 0 : _h.uid) || ''
        : undefined;
    const initialNotice = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ noticeType, newspaper: placement.newspaper, adTemplate: placement.adTemplate, rate: placement.rate }, (placement.filer ? { filer: placement.filer } : {})), (anonymousFilerId ? { anonymousFilerId } : {})), { userId: ((_j = auth.user) === null || _j === void 0 ? void 0 : _j.id) || '', isArchived: false, createTime: Firestore.FieldValue.serverTimestamp() }), (filedBy ? { filedBy } : {})), (createdBy ? { createdBy } : {}));
    const ctx = getFirebaseContext();
    const noticeRef = ctx.userNoticesRef().doc();
    yield call([noticeRef, noticeRef.set], initialNotice);
    const draftNotice = Object.assign(Object.assign(Object.assign({}, initialNotice), { original: noticeRef }), (auth.user ? { owner: auth.user.ref } : {}));
    const draftRef = ctx.userDraftsRef().doc();
    yield call([draftRef, draftRef.set], draftNotice);
    yield call([noticeRef, noticeRef.update], { drafts: [draftRef] });
    yield put(PlacementActions.setOriginal(noticeRef));
    yield put(PlacementActions.setDraft(draftRef));
    yield put(PlacementActions.setFiledBy(filedBy));
    yield put(PlacementActions.setAnonymousFilerId(((_k = auth.userAuth) === null || _k === void 0 ? void 0 : _k.isAnonymous) ? (_l = auth.userAuth) === null || _l === void 0 ? void 0 : _l.uid : null));
    yield put(PlacementActions.setCreatedBy(createdBy));
    const draftSnap = yield call([draftRef, draftRef.get]);
    yield put(PlacementActions.setDraftSnap(draftSnap));
    return noticeRef;
}
export function* getAvailableStatesAndPapers(action) {
    yield put(PlacementActions.setNewspaperList({ loaded: false, newspapers: [] }));
    const placement = yield select(placementSelector);
    const auth = yield select(authSelector);
    const { isPublisher, availableOrganizations, user } = auth;
    const { filingState, filingCounty } = placement;
    const results = yield call(searchOrganizations, isPublisher, availableOrganizations, user, filingState, filingCounty, '');
    // Avoid reseting available states when state/county have changed
    if (!(action === null || action === void 0 ? void 0 : action.type)) {
        yield put(PlacementActions.setAvailableStates(results.states));
    }
    yield put(PlacementActions.setNewspaperList({
        loaded: true,
        newspapers: results.papers
    }));
}
export function* watchNotice() {
    const placement = yield select(placementSelector);
    const noticeRef = placement.draft;
    if (!noticeRef)
        return;
    // Open a new snapshot listener to the draft
    const noticeChannel = eventChannel(emitter => noticeRef.onSnapshot(emitter, err => logAndCaptureException(err, 'Error listening to notice snapshot ')));
    // Store a reference to the listener channel so we can close it later
    yield put(PlacementActions.setDraftSnapshotUnsubscribe(() => noticeChannel.close()));
    // ignore the first update
    yield take(noticeChannel);
    yield takeLatest(noticeChannel, function* f(noticeSnap) {
        yield put(PlacementActions.setDraftSnap(noticeSnap));
        if (!exists(noticeSnap)) {
            console.log(`Ignoring update on deleted draft ${noticeSnap.id}`);
            return;
        }
        const { confirming } = yield select(placementSelector);
        if (confirming) {
            console.log(`Ignoring update while confirming draft ${noticeSnap.id}`);
            return;
        }
        const { text, confirmedHtml, unusedConfirmedHtml, proofStoragePath } = noticeSnap.data();
        if (proofStoragePath)
            yield put(PlacementActions.setProofStoragePath(proofStoragePath));
        if (unusedConfirmedHtml)
            yield put(PlacementActions.setUnusedConfirmedHtml(unusedConfirmedHtml));
        if (text || confirmedHtml)
            yield put(PlacementActions.setNoticeText(confirmedHtml || text));
    });
}
export function* publishNotice() {
    var _a, _b;
    try {
        const placement = yield select(placementSelector);
        const auth = yield select(authSelector);
        const { draft, original, filer } = placement;
        if (!draft || !original || !filer)
            return;
        const originalNotice = yield call([original, original.get]);
        const submissionTime = Firestore.Timestamp.now();
        const draftData = yield call([draft, draft.get]);
        if (draftData.data().inactive)
            return;
        yield call(addDraftFilesToNotice, getFirebaseContext(), draft, original);
        yield call(addDraftMailToNotice, draft, original);
        const { newspaper } = draftData.data();
        const newspaperSnap = yield call([newspaper, newspaper.get]);
        const draftUpdates = draftData.data();
        // TODO: remove this. This line prevents us from nulling out the userId on the notice
        if (!draftUpdates.userId) {
            delete draftUpdates.userId;
        }
        const noticeObject = Object.assign(Object.assign(Object.assign({}, draftUpdates), (newspaperSnap.data().disabled ? { testNotice: true } : {})), { 
            // clear the generated PDF document
            noticeStatus: [
                NoticeStatusType.affidavit_approved.value,
                NoticeStatusType.affidavit_submitted.value
            ].includes(originalNotice.data().noticeStatus)
                ? originalNotice.data().noticeStatus
                : NoticeStatusType.pending.value, 
            /* Note:
          This is Commented for making sure confirm modal does not reappear on noticeEdit.
          After Conversation with Haris, no known sideEffects were found and keeping the commented code
          for reverting back if any extensive sideEffect is found
          
          confirmedReceipt: !!auth.isPublisher,
          */
            confirmedAt: originalNotice.data().confirmedAt || submissionTime, confirmedReceiptTime: auth.isPublisher ? submissionTime : null, confirmed: true, confirmedBy: auth.isPublisher ? auth.user.ref : null, editedAt: submissionTime, lastEditedBy: auth.user.ref });
        /* If a customer does not yet exist, then we create one at the point the notice is published */
        const customerRef = placement.customer;
        let customerSnap;
        if (!customerRef) {
            const filerSnap = yield call([filer, filer.get]);
            customerSnap = yield call(getOrCreateCustomer, getFirebaseContext(), filerSnap, newspaperSnap, placement.newCustomerInfo);
        }
        else {
            customerSnap = yield call([customerRef, customerRef.get]);
        }
        /**
         * If we have an account number on state and a customer
         * without that account number, we should set the account
         * number on the customer. If the account number on state
         * does not match an existing account number on the
         * customer, we should set the state account number on
         * the notice as a one-off override.
         */
        const { accountNumber } = placement;
        const shouldUpdateCustomer = !!accountNumber &&
            exists(customerSnap) &&
            !customerSnap.data().internalID;
        const shouldUpdateNotice = !!accountNumber &&
            !!((_a = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _a === void 0 ? void 0 : _a.internalID) &&
            ((_b = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _b === void 0 ? void 0 : _b.internalID) !== accountNumber;
        if (shouldUpdateCustomer) {
            yield call([customerSnap.ref, customerSnap.ref.update], {
                internalID: accountNumber
            });
        }
        else if (shouldUpdateNotice) {
            const existingSyncData = noticeObject.syncData || {};
            const exportSettings = yield call(getXMLSyncExportSettings, newspaperSnap);
            noticeObject.syncData = Object.assign(Object.assign(Object.assign({}, existingSyncData), (exportSettings.format ? { format: exportSettings.format } : {})), { syncCustomerId: accountNumber });
        }
        // delete (noticeObject as any).owner;
        delete noticeObject.anonymousFilerId;
        delete noticeObject.inactive;
        delete noticeObject.original;
        delete noticeObject.confirming;
        delete noticeObject.unusedDisplay;
        delete noticeObject.generatedAffidavitURL;
        delete noticeObject.generatedAffidavitStoragePath;
        noticeObject.displayParams &&
            delete noticeObject.displayParams.imgs;
        noticeObject.displayParams &&
            delete noticeObject.displayParams.err;
        yield call([draft, draft.update], {
            editedAt: submissionTime,
            lastEditedBy: auth.user.ref
        });
        yield call([original, original.update], noticeObject);
        if (!placement.editing &&
            !originalNotice.data().invoice &&
            !auth.isPublisher) {
            yield put(ToastActions.setToastText('Success! Your notice has been submitted.'));
        }
        // Redirect the user to the notice details path
        yield put(push(`/notice/${original.id}`));
        // Clear session var that showed the customer step for advertisers
        // who started placing anonymously
        sessionStorage.removeItem('startedFromAnonymousFlow');
        // After leaving the placement flow, reset state
        yield put(PlacementActions.resetState());
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error in publishNotice');
    }
    finally {
        yield put(PlacementActions.setConfirming(false));
    }
}
function* listenToNotice() {
    yield call(watchNotice);
}
function* fetchAndHydrateNoticeDataHelper(action) {
    var _a, _b, _c, _d, _e, _f;
    if (!action.noticeId) {
        try {
            const ref = yield call(createNotice);
            if (action.redirect !== false) {
                const { currentStep } = yield select(placementSelector);
                yield call(updateHistoryNoRerender, `/place/${ref.id}?${appendToCurrentParams('step', currentStep.id).toString()}`);
            }
            else {
                yield call(fetchAndHydrateNoticeData, {
                    noticeId: ref.id,
                    redirect: false
                });
            }
        }
        catch (e) {
            logAndCaptureException(e, 'Placement: Error creating notice in fetchAndHydrateNoticeData');
            yield put(PlacementActions.setPlacementError(new PlacementError()));
        }
    }
    else {
        try {
            const noticeRef = getFirebaseContext()
                .userNoticesRef()
                .doc(action.noticeId);
            const notice = yield call([noticeRef, noticeRef.get]);
            yield put(PlacementActions.setEditing(!!((_a = notice.data()) === null || _a === void 0 ? void 0 : _a.noticeStatus)));
            yield put(PlacementActions.setOriginal(noticeRef));
            const draftSnaps = [];
            for (const doc of ((_b = notice.data()) === null || _b === void 0 ? void 0 : _b.drafts) || []) {
                const snapshot = yield call([doc, doc.get]);
                if (exists(snapshot))
                    draftSnaps.push(snapshot);
            }
            let draftSnap = null;
            const auth = yield select(authSelector);
            /**
             *
             * if the drafts exist
             * if the user exists
             * if only one draft and no owner
             * anonymous user flow
             */
            if (draftSnaps.length) {
                if (auth.user) {
                    if (draftSnaps.length === 1 && !((_c = draftSnaps[0].data()) === null || _c === void 0 ? void 0 : _c.owner)) {
                        [draftSnap] = draftSnaps;
                    }
                    else {
                        draftSnap =
                            draftSnaps.find(snap => snap.data().owner && snap.data().owner.id === auth.user.ref.id) || null;
                    }
                }
                else {
                    draftSnap = draftSnaps.find(snap => !snap.data().owner) || null;
                }
            }
            // eslint-disable-next-line no-extra-boolean-cast
            if (auth.user &&
                auth.user.data().occupation !== OccupationType.publishing.value) {
                if (draftSnaps.length > 1) {
                    draftSnap = draftSnaps[draftSnaps.length - 1];
                }
                [draftSnap] = draftSnaps;
            }
            if (draftSnap) {
                const filesArray = yield call(getFilesDataFromNoticeOrDraft, getFirebaseContext(), draftSnap.ref);
                const mailArray = yield call(getMailDataFromNoticeOrDraft, draftSnap.ref);
                yield put(PlacementActions.setDraftSnap(draftSnap));
                yield put(PlacementActions.setFilesToAttach(filesArray));
                yield put(PlacementActions.setMail(mailArray));
                yield put(PlacementActions.setDraft(draftSnap.ref));
                // The destructuring of `confirming` existed in old code before draftSnap was typed properly
                // Not certain whether/how `confirming` property is making it onto the draft since it's not typed there
                // But keeping this destructuring here so as not to introduce unwanted side effects
                const _g = draftSnap.data(), { proofStoragePath, confirming } = _g, data = __rest(_g, ["proofStoragePath", "confirming"]);
                yield put(PlacementActions.populateNoticeData(data));
                if (data.confirmedHtml || data.text) {
                    yield put(PlacementActions.setConfirmedText(data.confirmedHtml || data.text));
                }
                if ((_d = draftSnap.data()) === null || _d === void 0 ? void 0 : _d.newspaper) {
                    yield put(PlacementActions.setNewspaper(draftSnap.data().newspaper));
                }
                if (!((_e = draftSnap.data()) === null || _e === void 0 ? void 0 : _e.userId)) {
                    let user;
                    if (auth.user)
                        user = auth.user;
                    else {
                        const action = yield take(AuthTypes.SET_USER);
                        user = action.user;
                    }
                    if (user.data().occupation !== OccupationType.publishing.value) {
                        yield put(PlacementActions.setFiler(user.ref));
                    }
                }
            }
            else {
                const draftRef = getFirebaseContext().userDraftsRef().doc();
                const draftObject = Object.assign(Object.assign({}, notice.data()), { original: notice.ref, owner: auth.user ? auth.user.ref : null });
                delete draftObject.editedAt;
                delete draftObject.lastEditedBy;
                delete draftObject.drafts;
                delete draftObject.proofStoragePath;
                const drafts = ((_f = notice.data()) === null || _f === void 0 ? void 0 : _f.drafts) || [];
                drafts.push(draftRef);
                const noticeRef = notice.ref;
                yield call([noticeRef, noticeRef.update], { drafts });
                yield call([draftRef, draftRef.set], draftObject);
                if (auth.user) {
                    const filesArray = yield call(getNoticeFilesAndSetOnDraft, getFirebaseContext(), notice.ref, draftRef);
                    const mailArray = yield call(getNoticeMailAndSetOnDraft, notice.ref, draftRef);
                    yield put(PlacementActions.setFilesToAttach(filesArray));
                    yield put(PlacementActions.setMail(mailArray));
                }
                const draftSnap = yield call([draftRef, draftRef.get]);
                yield put(PlacementActions.populateNoticeData(draftObject));
                if (draftObject.confirmedHtml || draftObject.text) {
                    yield put(PlacementActions.setConfirmedText(draftObject.confirmedHtml || draftObject.text));
                }
                yield put(PlacementActions.setDraftSnap(draftSnap));
                yield put(PlacementActions.setDraft(draftRef));
            }
        }
        catch (e) {
            logAndCaptureException(e, 'Placement: Error setting draft contents in fetchAndHydrateNoticeData:');
            yield put(PlacementActions.setPlacementError(new PlacementError()));
        }
    }
}
function* fetchAndHydrateNoticeData(action) {
    yield put(PlacementActions.resetState());
    const auth = yield select(authSelector);
    if (!auth.userAuth) {
        yield put(AuthActions.anonymousLogin());
        sessionStorage.setItem('startedFromAnonymousFlow', 'true');
        yield take(AuthTypes.SET_USER_AUTH);
    }
    // We run `getAvailableStatesAndPapers` and `fetchAndHydrateNoticeDataHelper` in parallel as they are independent and both take almost the same amount of time.
    yield all([
        call(getAvailableStatesAndPapers),
        call(fetchAndHydrateNoticeDataHelper, action)
    ]);
}
function* updateDraftFiles() {
    const placement = yield select(placementSelector);
    const { draft, filesToAttach } = placement;
    if (!draft)
        return;
    try {
        yield call(refreshDraftFiles, getFirebaseContext(), draft, filesToAttach || []);
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error processing files in saveDraft', {
            draft: (draft === null || draft === void 0 ? void 0 : draft.id) || ''
        });
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
}
function* updateDraftMails() {
    const placement = yield select(placementSelector);
    const { draft, mail } = placement;
    if (!draft)
        return;
    try {
        yield call(refreshDraftMail, draft, mail || []);
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error processing mail in saveDraft', {
            draft: (draft === null || draft === void 0 ? void 0 : draft.id) || ''
        });
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
}
function* saveDraft() {
    var _a;
    const placement = yield select(placementSelector);
    const { draft } = placement;
    if (!draft) {
        return;
    }
    const updateObject = getNoticeDraftFieldsFromPlacement(placement);
    /**
     * Note: somewhat unclear what the following condition is meant
     * to catch. Since updateObject is derived from placement fields,
     * placement.displayParams and updateObj.displayParams
     * should either be both existent or both nonexistent
     *
     * See tests in dataCleaning
     */
    if (!updateObject.displayParams ||
        !Object.keys(placement.displayParams || {}).length) {
        delete updateObject.displayParams;
    }
    // Remove imgs from the display params before saving in the database
    // as this field is *massive* and is later encoded in the image urls
    if ((_a = updateObject.displayParams) === null || _a === void 0 ? void 0 : _a.imgs) {
        delete updateObject.displayParams.imgs;
    }
    try {
        updateObject.userId = placement.filer ? placement.filer.id : null;
        if (!updateObject.publicationDates) {
            updateObject.publicationDates = null;
        }
        const placementDraft = yield call([draft, draft.get]);
        if (placementDraft) {
            // TODO(BACKEND-212): This cast should not be necessary but our types currently
            // don't make complete sense since EPlacement uses nullable fileds while ENoticeDraft
            // uses optional fields.
            const updatePartial = updateObject;
            yield call([draft, draft.update], removeUndefinedFields(updatePartial));
        }
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error saving draft in saveDraft', {
            draft: (draft === null || draft === void 0 ? void 0 : draft.id) || ''
        });
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
}
/**
 * This generator update handles:
 *  1. Setting templates
 *  2. Re-setting the customer object
 *  3. Setting the notice type
 */
export function* processNewspaperUpdate(action) {
    var _a, _b, _c, _d;
    const placement = yield select(placementSelector);
    if (action.newspaperRef) {
        const { newspaperRef } = action;
        try {
            const { draft, draftSnap, processedDisplay, columns, noticeType, customerOrganization, filer } = yield select(placementSelector);
            const newspaper = yield call([
                newspaperRef,
                newspaperRef.get
            ]);
            const { adTemplate, defaultColumns, defaultNoticeType } = newspaper.data();
            // Reset placement data on newspaper update
            if (newspaper.id !== ((_b = (_a = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _a === void 0 ? void 0 : _a.newspaper) === null || _b === void 0 ? void 0 : _b.id) && draftSnap) {
                // Reset notice types to defaults for display/liner notices
                if (processedDisplay) {
                    yield put(PlacementActions.setNoticeType(NoticeType.display_ad.value));
                }
                else {
                    const defaultLinerNoticeType = defaultNoticeType || NoticeType.custom.value;
                    yield put(PlacementActions.setNoticeType(defaultLinerNoticeType));
                }
                // Reset publication dates as schedules vary between papers
                yield call([draftSnap.ref, draftSnap.ref.update], {
                    publicationDates: Firestore.FieldValue.delete(),
                    fixedPrice: Firestore.FieldValue.delete()
                });
                yield put(PlacementActions.setPublicationDates([
                    getClosestFuturePublishingDay(newspaper.data().deadlines, newspaper.data().iana_timezone, placement, newspaper)
                ].map(timestampOrDateToTimestamp)));
            }
            /* When the newspaper is reset and the notice type has already been selected
            (e.g., when returning to placement from a Typeform), we do not want to
            miss setting any required publication dates, so we verify the publication dates again. */
            yield* verifyPublicationDates();
            // Before overriding the template, check if the notice has a template set by the
            // custom notice type
            const noticeTypeCustomTemplate = (_c = getNoticeTypeFromNoticeData({ noticeType }, newspaper)) === null || _c === void 0 ? void 0 : _c.template;
            const filerSnap = filer
                ? yield call([filer, filer.get])
                : null;
            /* We should use the same template hierarchy when returning to the placement flow
            from a Typeform as we would if we were setting the template in a normal flow, so
            we use the same helper function */
            const adTemplateToUse = yield call(getAdTemplate, newspaper, filerSnap, noticeTypeCustomTemplate || null, customerOrganization);
            if (adTemplateToUse) {
                yield put(PlacementActions.setTemplate(adTemplateToUse));
            }
            else {
                yield put(PlacementActions.setTemplate(adTemplate));
            }
            // reset the number of columns if needed after the paper has been updated
            const { minColumns, maxColumns } = getMinAndMaxColumnsForPaper(newspaper, processedDisplay);
            if (columns < minColumns) {
                yield put(PlacementActions.setColumns(minColumns));
            }
            else if (columns > maxColumns) {
                yield put(PlacementActions.setColumns(maxColumns));
            }
            if (defaultColumns && !((_d = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _d === void 0 ? void 0 : _d.columns)) {
                yield put(PlacementActions.setColumns(defaultColumns));
            }
            if (draft) {
                yield draft.update({
                    newspaper: newspaperRef
                });
            }
        }
        catch (e) {
            logAndCaptureException(e, 'Placement: Error in processNewspaperUpdate');
            yield put(PlacementActions.setPlacementError(new PlacementError()));
        }
    }
}
export function* processAdTemplateChanges() {
    var _a, _b, _c;
    try {
        const { newspaper, filer, noticeType, customerOrganization } = yield select(placementSelector);
        if (!newspaper)
            return;
        const filerSnap = filer
            ? yield call([filer, filer.get])
            : null;
        const advertiserOrg = (_a = filerSnap === null || filerSnap === void 0 ? void 0 : filerSnap.data()) === null || _a === void 0 ? void 0 : _a.organization;
        const advertiserOrgSnap = advertiserOrg
            ? yield call([advertiserOrg, advertiserOrg.get])
            : null;
        // `advertiserOrgSnap` and `customerOrganization` should be either both set or unset.
        // Having one of them set, means the customerOrganization is not yet updated accordingly.
        if ((advertiserOrgSnap && !customerOrganization) ||
            (!advertiserOrgSnap && customerOrganization)) {
            return;
        }
        const newspaperSnap = yield call([
            newspaper,
            newspaper.get
        ]);
        const chosenCustomType = (_c = (_b = newspaperSnap
            .data()) === null || _b === void 0 ? void 0 : _b.allowedNotices) === null || _c === void 0 ? void 0 : _c.find((nt) => nt.value === noticeType);
        const chosenCustomTypeTemplate = chosenCustomType
            ? chosenCustomType.template
            : null;
        const template = yield call(getAdTemplate, newspaperSnap, filerSnap, chosenCustomTypeTemplate, customerOrganization);
        yield put(PlacementActions.setTemplate(template));
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error in processAdTemplateChanges');
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
}
function* initAdProofGeneration() {
    try {
        const { draft } = yield select(placementSelector);
        yield call([draft, draft.update], {
            proofStoragePath: null,
            proofUrl: null,
            jpgStoragePath: null,
            jpgURL: null
        });
        const noticeId = draft.id;
        yield call([api, api.post], 'documents/generate-proof', {
            noticeDraft: noticeId
        });
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error in initAdProofGeneration');
    }
}
export function* updateRates(action) {
    var _a, _b, _c, _d, _e, _f, _g;
    const { newspaper, draft, rate, noticeType, previousNoticeType, editing, original } = yield select(placementSelector);
    if (!newspaper)
        return;
    const draftSnap = draft
        ? yield call([draft, draft.get])
        : undefined;
    const oldNoticeType = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data().noticeType;
    const oldPreviousNoticeType = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data().previousNoticeType;
    // In editing/duplicating flow, we would like to persist the already chosen rate
    // unless the publisher switches the notice type or chooses a new rate through the rate select
    if ((editing && draft) || getLocationParams().get('duplicate') === 'true') {
        if (![
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_PREVIOUS_NOTICE_TYPE
        ].includes(action.type)) {
            return;
        }
        if (action.type === PlacementTypes.SET_NOTICE_TYPE &&
            oldNoticeType === noticeType) {
            return;
        }
        if (action.type === PlacementTypes.SET_PREVIOUS_NOTICE_TYPE &&
            oldPreviousNoticeType === previousNoticeType) {
            return;
        }
    }
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    let oldRate;
    try {
        oldRate = rate ? yield call([rate, rate.get]) : undefined;
    }
    catch (err) {
        // Malformed rate references like rate/xyz instead of rates/xyz
        // caused hard to trace permissions errors
        logAndCaptureException(err, 'Error getting rate in placement saga', {
            rateReference: rate === null || rate === void 0 ? void 0 : rate.path,
            noticeId: original === null || original === void 0 ? void 0 : original.id
        });
    }
    // if we are on a display notice, pull rates from the notice
    // type associated with "previous notice type"
    const relevantNoticeTypeForRates = noticeType === NoticeType.display_ad.value
        ? previousNoticeType
        : noticeType;
    const chosenCustomType = (_b = (_a = newspaperSnap
        .data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) === null || _b === void 0 ? void 0 : _b.find(nt => nt.value === relevantNoticeTypeForRates);
    /**
     * Clear fixed price if was previously set on the draft
     *
     * If a user later switches back to a typeform notice (see ingestNotice) with a fixedPrice,
     * the fixedPrice will be set from zapier after setNoticeType is called
     */
    if ((draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.exists) && draftSnap.data().noticeType !== noticeType) {
        yield put(PlacementActions.setFixedPrice(null));
    }
    const newRate = yield call(checkForDefault, yield select(placementSelector), newspaperSnap, noticeType);
    const defaultRates = [
        newspaperSnap.data().defaultLinerRate.id,
        newspaperSnap.data().defaultDisplayRate.id
    ];
    const placementHasNewCustomAdvertiserRate = newRate && !defaultRates.includes(newRate.id);
    const isDisplay = noticeType === NoticeType.display_ad.value;
    /**
     * Order of precedence when setting rates:
     * - any custom rate associated with the notice type
     * - any custom rate associated with the advertiser
     * - newspaper defaults
     */
    // custom rate with advertiser
    if (placementHasNewCustomAdvertiserRate) {
        yield put(PlacementActions.setRate(newRate));
    }
    // custom rate with notice type for non display notices
    else if ((chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate) && !isDisplay) {
        yield put(PlacementActions.setRate(chosenCustomType.rate));
    }
    // newspaper defaults
    else if (isDisplay) {
        // determine if the existing rate is associated with the paper
        const previousRateIsAssociatedWithNoticeType = Boolean((_c = newspaperSnap
            .data()
            .allowedNotices) === null || _c === void 0 ? void 0 : _c.find(an => { var _a; return ((_a = an.rate) === null || _a === void 0 ? void 0 : _a.id) === (oldRate === null || oldRate === void 0 ? void 0 : oldRate.id); }));
        const previousRateCanRunOnPaper = Boolean(!((_d = oldRate === null || oldRate === void 0 ? void 0 : oldRate.data().organization) === null || _d === void 0 ? void 0 : _d.id) ||
            newspaperSnap.ref.id === ((_e = oldRate.data().organization) === null || _e === void 0 ? void 0 : _e.id));
        const previousRateIsCustomRate = Boolean((((_f = oldRate === null || oldRate === void 0 ? void 0 : oldRate.data().filers) === null || _f === void 0 ? void 0 : _f.length) || 0) > 0 ||
            ((_g = oldRate === null || oldRate === void 0 ? void 0 : oldRate.data().organizations) === null || _g === void 0 ? void 0 : _g.length));
        const customRateTypeSnapshot = (chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate)
            ? yield call([chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate, chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate.get])
            : null;
        // if we are swapping notice types and the old rate
        // supports display, use it still!
        if (exists(customRateTypeSnapshot) &&
            supportsDisplay(customRateTypeSnapshot)) {
            yield put(PlacementActions.setRate(customRateTypeSnapshot.ref));
        }
        else if (exists(oldRate) &&
            supportsDisplay(oldRate) &&
            (previousRateCanRunOnPaper || previousRateIsCustomRate) &&
            !previousRateIsAssociatedWithNoticeType) {
            yield put(PlacementActions.setRate(oldRate.ref));
        }
        else {
            yield put(PlacementActions.setRate(newspaperSnap.data().defaultDisplayRate));
        }
    }
    else {
        yield put(PlacementActions.setRate(newspaperSnap.data().defaultLinerRate));
    }
}
export function* updateNoticeColumns() {
    const { newspaper, noticeType, draftSnap, columns, editing } = yield select(placementSelector);
    if (!newspaper)
        return;
    const newspaperSnapshot = yield call([
        newspaper,
        newspaper.get
    ]);
    const isDisplay = noticeType === NoticeType.display_ad.value;
    // The default for display ads is 2 columns
    let newColumns = isDisplay ? 2 : columns;
    // If the custom type has a default number of columns, prefer that
    const chosenCustomType = getNoticeTypeData(noticeType, newspaperSnapshot);
    if (chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.columns) {
        newColumns = chosenCustomType.columns;
    }
    else if (exists(draftSnap) &&
        draftSnap.data().noticeType !== draftSnap.data().previousNoticeType) {
        newColumns = 1;
    }
    // For notice editing if notice type does not change, column width value should remain as previous selection
    if (editing &&
        exists(draftSnap) &&
        draftSnap.data().noticeType === draftSnap.data().previousNoticeType) {
        newColumns = columns;
    }
    // Make sure the column value is bounded by the paper's min and max
    const { minColumns, maxColumns } = getMinAndMaxColumnsForPaper(newspaperSnapshot, isDisplay);
    newColumns = Math.max(minColumns, Math.min(newColumns, maxColumns));
    yield put(PlacementActions.setColumns(newColumns));
    yield call(saveDraft);
}
// Adds one more publication date to match the notice type required publication dates
const addAdditionalPublicationDate = (publicationDates, newspaper, placement, noticeType, isPublisher) => {
    let newPublicationDates = [...publicationDates];
    // if we are adding an additional publication date to a notice that has required publications
    // and restricted publishing days, we need to change the first publication day because the closest
    // future publishing day for the newspaper may not match the closest future publishing day for the
    // notice type (e.g. if a notice type only publishes on Monday while the paper publishes Mon, Wed, Fri)
    if (publicationDates.length === 1 &&
        (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
        (noticeType === null || noticeType === void 0 ? void 0 : noticeType.restrictedPublicationDays) &&
        isPublisher) {
        const { deadlines, iana_timezone } = newspaper.data();
        newPublicationDates = [
            getClosestFuturePublishingDay(deadlines, iana_timezone, placement, newspaper)
        ].map(timestampOrDateToTimestamp);
    }
    const nextPotentialPublishingDate = moment(publicationDates[publicationDates.length - 1].toMillis())
        .add((noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultDaysBetweenPublication) || 7, 'days')
        .toDate();
    const { deadlines, iana_timezone } = newspaper.data();
    const nextPublishingDate = getClosestFuturePublishingDay(deadlines, iana_timezone, placement, newspaper, nextPotentialPublishingDate);
    newPublicationDates.push(nextPublishingDate);
    return newPublicationDates.map(timestampOrDateToTimestamp);
};
// When the publication dates update, we should check if the chosen dates are valid (ie: they are publishing days, not after the deadline)
// When notice type update, we should check if the notice type has restrictions on number of the dates or on specific publishing days
export function* verifyPublicationDates() {
    var _a;
    const placement = yield select(placementSelector);
    const auth = yield select(authSelector);
    const { newspaper, publicationDates, draftSnap, editing, publicationDatesUpdated } = placement;
    if (!newspaper)
        return;
    const newspaperSnapshot = yield call([
        newspaper,
        newspaper.get
    ]);
    const isPublisher = !!auth.isPublisher;
    if (exists(newspaperSnapshot) && exists(draftSnap)) {
        const noticeType = getNoticeType(draftSnap, newspaperSnapshot);
        let requiredPublications = 0;
        if (!isPublisher && !editing) {
            requiredPublications = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) || 0;
        }
        else if (!isPublisher && editing) {
            requiredPublications = ((_a = draftSnap.data().publicationDates) === null || _a === void 0 ? void 0 : _a.length) || 0;
        }
        else if (isPublisher) {
            requiredPublications = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) || 0;
        }
        const numPublicationDates = (publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length) || 0;
        // If a new notice type is chosen that has fewer required publications than
        // the currently selected number of dates and the dates have not been
        // manually edited yet, we reset the dates back to the initial state.
        const hasTooManyPublicationDates = !isPublisher &&
            !editing &&
            !publicationDatesUpdated &&
            numPublicationDates > 1 &&
            numPublicationDates > requiredPublications;
        // When the notice type changes, it's possible we have invalid dates in
        // placement data, as one notice type may have different restricted days
        // from another. So if there are any invalid, we reset to one valid date.
        const hasInvalidDates = !publicationDates ||
            publicationDates.some(day => {
                var _a;
                return shouldDisableDate(day.toDate(), newspaperSnapshot, (_a = auth.user) !== null && _a !== void 0 ? _a : undefined, placement, editing, noticeType !== null && noticeType !== void 0 ? noticeType : undefined, auth.isPublisher);
            });
        const hasRestrictedPublicationDays = numPublicationDates === 0 &&
            (noticeType === null || noticeType === void 0 ? void 0 : noticeType.restrictedPublicationDays) &&
            isPublisher;
        // we want to set the publication dates to just the closest future publication date if
        // the notice has invalid dates, too many dates, or the notice type
        // has restricted publication days and does not have pub dates set yet
        if (hasInvalidDates ||
            hasTooManyPublicationDates ||
            hasRestrictedPublicationDays) {
            const { deadlines, iana_timezone } = newspaperSnapshot.data();
            yield put(PlacementActions.setPublicationDates([
                getClosestFuturePublishingDay(deadlines, iana_timezone, placement, newspaperSnapshot)
            ].map(timestampOrDateToTimestamp)));
            return;
        }
        const publisherHasEditedPublicationDates = isPublisher && publicationDatesUpdated;
        if (!publisherHasEditedPublicationDates &&
            (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
            publicationDates.length < requiredPublications) {
            // This only adds one date, but the change will fire a redux action and this
            // function will be triggered again until it's added all necessary dates.
            const newPublicationDates = addAdditionalPublicationDate(publicationDates, newspaperSnapshot, placement, noticeType, isPublisher);
            yield put(PlacementActions.setPublicationDates(newPublicationDates));
        }
    }
}
function* updateFooter() {
    try {
        const placement = yield select(placementSelector);
        const { draft } = placement;
        if (!draft)
            return;
        const footer = yield call(generateFormattedFooter, getFirebaseContext(), placement, window.DOMParser);
        yield put(PlacementActions.setDynamicFooter(footer));
        const placementDraft = yield call([draft, draft.get]);
        /**
         * Tech debt ticketed in COREDEV-1559:
         * It doesn't appear that the below block ever runs because
         * we are check the old placement which doesn't have the
         * updated dynamicFooter
         */
        if (placement.dynamicFooter && placementDraft.exists) {
            yield call([draft, draft.update], {
                dynamicFooter: placement.dynamicFooter
            });
        }
    }
    catch (e) {
        logAndCaptureException(e, 'Placement: Error in updateFooter');
    }
}
function* processPublicationDateUpdate() {
    const placement = yield select(placementSelector);
    const { rate, newspaper } = placement;
    if (!rate || !newspaper)
        return;
    const rateSnapshot = yield call([rate, rate.get]);
    if (rateCanPublishWithPlacementData(rateSnapshot, placement))
        return;
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    const newRate = yield call(checkForDefault, yield select(placementSelector), newspaperSnap);
    if (newRate)
        yield put(PlacementActions.setRate(newRate));
}
// Override the default column width if the height extends beyond a threshold
function* processDisplayParameterUpdate({ displayParams }) {
    var _a;
    const { newspaper, columns } = yield select(placementSelector);
    if (!newspaper)
        return;
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    const thresholds = (_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.thresholds;
    if (!thresholds)
        return;
    if (!displayParams)
        return;
    let minimumColumnSize = columns;
    const totalColumnInches = displayParams.height * columns;
    Object.keys(thresholds)
        .sort()
        .forEach(thresholdSize => {
        if (parseFloat(thresholdSize) > totalColumnInches)
            return;
        minimumColumnSize = thresholds[thresholdSize];
    });
    if (minimumColumnSize > columns)
        yield put(PlacementActions.setColumns(minimumColumnSize));
}
function* processColumnUpdate() {
    const { newspaper, columns, noticeType, adTemplate } = yield select(placementSelector);
    if (noticeType === NoticeType.display_ad.value)
        return;
    if (!newspaper)
        return;
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    const { templateThresholds } = newspaperSnap.data();
    if (!templateThresholds)
        return;
    let updatedTemplate = adTemplate;
    Object.keys(templateThresholds)
        .sort()
        .forEach(columnThreshold => {
        var _a;
        const thresholdNumber = parseInt(columnThreshold, 10);
        if (columns < thresholdNumber)
            return;
        if ((updatedTemplate === null || updatedTemplate === void 0 ? void 0 : updatedTemplate.id) !== ((_a = templateThresholds[thresholdNumber]) === null || _a === void 0 ? void 0 : _a.id)) {
            updatedTemplate = templateThresholds[thresholdNumber];
        }
    });
    if ((updatedTemplate === null || updatedTemplate === void 0 ? void 0 : updatedTemplate.id) !== (adTemplate === null || adTemplate === void 0 ? void 0 : adTemplate.id)) {
        yield put(PlacementActions.setTemplate(updatedTemplate));
    }
}
/**
 * This generator function is called whenever a new filer is set for the notice.
 * All logic that needs to fire when the filer is updated should branch from this saga
 *
 */
export function* processFilerUpdate() {
    var _a;
    try {
        const { filer, newspaper, filedBy, customerOrganization, customer, anonymousFilerId } = yield select(placementSelector);
        const auth = yield select(authSelector);
        if (!((_a = auth.userAuth) === null || _a === void 0 ? void 0 : _a.isAnonymous) && !!anonymousFilerId) {
            yield put(PlacementActions.setAnonymousFilerId(null));
        }
        // if we haven't set a paper or the filer, short circuit as we are still
        // too early in the placement flow
        if (!filer || !newspaper)
            return;
        // pull associated data
        const userOrg = filedBy
            ? yield call([filedBy, filedBy.get])
            : null;
        // `advertiserOrgSnap` and `customerOrganization` should be either both set or unset.
        // Having one of them set, means the customerOrganization is not yet updated accordingly.
        if ((userOrg && !customerOrganization) ||
            (!userOrg && customerOrganization)) {
            return;
        }
        // determine the default number of columns needed for this user
        const defaultColumnsForPlacement = yield call(() => __awaiter(this, void 0, void 0, function* () {
            return getDefaultColumnsForUserUserOrgWithNewspaper(customer, customerOrganization);
        }));
        // only update columns if we have a new default on that user
        if (defaultColumnsForPlacement) {
            yield put(PlacementActions.setColumns(defaultColumnsForPlacement));
        }
    }
    catch (err) {
        logAndCaptureException(err, 'Placement: Error in processFilerUpdate');
    }
}
/**
 * This generator function is called whenever the filer or the newspaper are updated to get or create the customer organization.
 * This function should prevent creating duplicates of customer organizations as it is the only one allowed to call getOrCreateCustomerOrganization()
 *
 */
export function* updateCustomerOrganization() {
    try {
        const { filer, newspaper, filedBy } = yield select(placementSelector);
        if (!filer || !newspaper || !filedBy) {
            yield put(PlacementActions.setCustomerOrganization(null));
            return;
        }
        const advertiserOrg = yield call([
            filedBy,
            filedBy.get
        ]);
        const newspaperSnap = yield call([
            newspaper,
            newspaper.get
        ]);
        const ctx = getFirebaseContext();
        const customerOrgSnap = yield call(getOrCreateCustomerOrganization, ctx, advertiserOrg, newspaperSnap);
        yield put(PlacementActions.setCustomerOrganization(customerOrgSnap.ref || null));
    }
    catch (err) {
        logAndCaptureException(err, 'Placement: Error in updateCustomerOrganization');
    }
}
/**
 * This generator function is called whenever the filer or the newspaper are updated to get or create the customer.
 * This function should prevent creating duplicates of customers as it is the only one allowed to call getOrCreateCustomer()
 *
 */
export function* updateCustomer() {
    try {
        const { filer, newspaper } = yield select(placementSelector);
        const { userAuth } = yield select(authSelector);
        /**
         * Don't set the customer if there's no filer or newspaper, as
         * both are required to fetch the customer
         *
         * Also don't set the customer in the anonymous flow - users only have
         * permission to do so after logging in
         */
        if (!filer || !newspaper || (userAuth === null || userAuth === void 0 ? void 0 : userAuth.isAnonymous)) {
            yield put(PlacementActions.setCustomer(null));
            return;
        }
        const newspaperSnap = yield call([
            newspaper,
            newspaper.get
        ]);
        const filerSnap = yield call([filer, filer.get]);
        const ctx = getFirebaseContext();
        /* We should not create a new customer in the placement flow until the very end when the notice is confirmed */
        const customerSnap = yield call(getCustomer, ctx, filerSnap, newspaperSnap);
        yield put(PlacementActions.setCustomer((customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.ref) || null));
    }
    catch (err) {
        logAndCaptureException(err, 'Placement: Error in updateCustomer');
    }
}
function* placementSaga() {
    yield all([
        yield takeLatest(PlacementTypes.HYDRATE_NOTICE_DATA, fetchAndHydrateNoticeData),
        yield takeEvery(PlacementTypes.SAVE_DRAFT, saveDraft),
        yield takeEvery(PlacementTypes.SET_FILES_TO_ATTACH, updateDraftFiles),
        yield takeEvery(PlacementTypes.CONFIRM_AFFIDAVIT_RECIPIENTS, updateDraftMails),
        yield takeEvery(PlacementTypes.SET_NEWSPAPER, processNewspaperUpdate),
        yield takeEvery(PlacementTypes.CONFIRM_SCHEDULE, processPublicationDateUpdate),
        yield takeEvery([
            PlacementTypes.SET_CUSTOMER_ORGANIZATION,
            PlacementTypes.SET_CUSTOMER,
            PlacementTypes.SET_FILER
        ], processFilerUpdate),
        yield takeEvery([
            PlacementTypes.SET_FILER,
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_NEWSPAPER,
            PlacementTypes.SET_CUSTOMER_ORGANIZATION
        ], processAdTemplateChanges),
        yield takeLatest(PlacementTypes.PUBLISH_NOTICE, publishNotice),
        yield takeLatest(PlacementTypes.SET_CONFIRMED_CROP, saveDraft),
        yield takeEvery(PlacementTypes.SET_PROOF, initAdProofGeneration),
        yield takeEvery([
            PlacementTypes.POPULATE_NOTICE_DATA,
            PlacementTypes.SET_NOTICE_TEXT,
            PlacementTypes.SET_COLUMNS,
            PlacementTypes.SET_DISPLAY_PARAMS,
            PlacementTypes.CONFIRM_SCHEDULE
        ], updateFooter),
        yield takeLatest([PlacementTypes.SET_NOTICE_TYPE, PlacementTypes.RESET_COLUMNS], updateNoticeColumns),
        yield takeLatest([
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_PUBLICATION_DATES,
            PlacementTypes.HYDRATE_NOTICE_DATA
        ], verifyPublicationDates),
        yield takeLatest([
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_CUSTOMER,
            PlacementTypes.SET_PREVIOUS_NOTICE_TYPE
        ], action => updateRates(action)),
        yield takeLatest(PlacementTypes.SET_DISPLAY_PARAMS, action => processDisplayParameterUpdate(action)),
        yield takeEvery(PlacementTypes.SET_COLUMNS, processColumnUpdate),
        yield takeLatest([
            PlacementTypes.SET_FILED_BY,
            PlacementTypes.SET_FILER,
            PlacementTypes.SET_NEWSPAPER,
            PlacementTypes.POPULATE_NOTICE_DATA
        ], updateCustomerOrganization),
        yield takeLatest([
            PlacementTypes.SET_FILER,
            PlacementTypes.SET_NEWSPAPER,
            PlacementTypes.POPULATE_NOTICE_DATA
        ], updateCustomer),
        yield takeLatest(PlacementTypes.SET_DRAFT, listenToNotice),
        yield takeLatest([PlacementTypes.SET_FILING_STATE, PlacementTypes.SET_FILING_COUNTY], (action) => getAvailableStatesAndPapers(action))
    ]);
}
export default placementSaga;
