import React, { useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorActions from '../redux/errors';
const mapDispatchToProps = (dispatch) => ({
    errorActions: bindActionCreators(ErrorActions, dispatch)
});
const styles = (theme) => createStyles({
    loadingContainer: {
        width: '100%',
        height: '80vh'
    },
    message: {
        marginTop: theme.spacing(3)
    }
});
const LoadingState = ({ classes, message, timeout = 30, context, errorActions }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            var _a;
            const msg = (context === null || context === void 0 ? void 0 : context.location)
                ? `[${context.location}] operation timed out`
                : 'Operation timed out';
            const tags = Object.assign({ location: (_a = context === null || context === void 0 ? void 0 : context.location) !== null && _a !== void 0 ? _a : 'unknown' }, context === null || context === void 0 ? void 0 : context.tags);
            errorActions.setError({ error: msg, tags });
        }, timeout * 1000);
        return () => clearTimeout(timer);
    }, []);
    return (React.createElement(Grid, { container: true, justify: "center", alignItems: "center", direction: "column", xs: 12, className: classes.loadingContainer, item: true },
        React.createElement("div", { className: "flex justify-center align-center mt-8", style: { width: '100%' } },
            React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })),
        message && (React.createElement(Typography, { className: classes.message }, message))));
};
export default connect(null, mapDispatchToProps)(withStyles(styles)(LoadingState));
