var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createNotificationsObject } from '../auth';
import { InviteStatus, OccupationType, RoleType } from '../enums';
import { getOpenInvitesForUser } from '../helpers';
import { exists } from '../types';
export const OPEN_INVITE_STATUSES = [
    InviteStatus.pending.value,
    InviteStatus.snoozed.value
];
export const CLOSED_INVITE_STATUSES = [
    InviteStatus.declined.value,
    InviteStatus.accepted.value
];
export const getAllowedOrganizationSnaps = (user) => __awaiter(void 0, void 0, void 0, function* () {
    const { allowedOrganizations } = user.data();
    if (!allowedOrganizations || allowedOrganizations.length === 0) {
        return [];
    }
    return yield Promise.all(allowedOrganizations === null || allowedOrganizations === void 0 ? void 0 : allowedOrganizations.map((org) => __awaiter(void 0, void 0, void 0, function* () {
        return (yield org.get());
    })));
});
export const getSubOrganizations = (ctx, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const querySnapshot = yield ctx
        .organizationsRef()
        .where('parent', '==', organization.ref)
        .get();
    return querySnapshot.docs;
});
export const getRolesFromAllowedOrgs = (allowedOrganizations, role) => {
    return allowedOrganizations.reduce((acc, org) => (Object.assign(Object.assign({}, acc), { [org.id]: role || RoleType.admin.value })), {});
};
/**
 * Returns a list of child organizations refs given a specified parent org
 * This is used to populate/update a user's allowedOrganizations
 * array which contains all the orgs they can access
 *
 * If no children are found, just returns the original org in the array
 */
export const getAllowedOrgsAndRolesFromParent = (ctx, organization, role) => __awaiter(void 0, void 0, void 0, function* () {
    let childOrgs = [];
    childOrgs = yield getSubOrganizations(ctx, organization);
    const allowedOrganizations = childOrgs
        .concat([organization])
        .map(org => org.ref);
    return {
        allowedOrganizations,
        roles: getRolesFromAllowedOrgs(allowedOrganizations, role)
    };
});
export const makeUserIndividual = (deleteFieldValue, user) => __awaiter(void 0, void 0, void 0, function* () {
    yield user.ref.update({
        organization: deleteFieldValue(),
        activeOrganization: deleteFieldValue(),
        allowedOrganizations: deleteFieldValue(),
        occupation: OccupationType.individual.value,
        notifications: createNotificationsObject(OccupationType.individual.value),
        roles: deleteFieldValue()
    });
});
// Helper to make testing easier
export const getUpdatedUserOrgStructure = (user, orgToGetRemovedFrom) => __awaiter(void 0, void 0, void 0, function* () {
    const { allowedOrganizations, activeOrganization, organization, roles } = user.data();
    if (!allowedOrganizations) {
        throw new Error(`User ${user.id} does not have allowedOrganizations`);
    }
    if (!roles) {
        throw new Error(`User ${user.id} does not have roles`);
    }
    const updateObj = {};
    const updatedAllowedOrganizations = allowedOrganizations.filter(o => o.id !== orgToGetRemovedFrom.ref.id);
    const _a = roles, _b = orgToGetRemovedFrom.id, discardedRole = _a[_b], updatedRoles = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    updateObj.roles = updatedRoles;
    updateObj.allowedOrganizations = updatedAllowedOrganizations;
    // Update the user's activeOrganization if set to the now-deleted org
    if ((activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) === orgToGetRemovedFrom.ref.id) {
        // eslint-disable-next-line prefer-destructuring
        updateObj.activeOrganization = updatedAllowedOrganizations[0];
    }
    // Update the user's organization if set to the now-deleted org
    if ((organization === null || organization === void 0 ? void 0 : organization.id) === orgToGetRemovedFrom.ref.id) {
        // eslint-disable-next-line prefer-destructuring
        updateObj.organization = updatedAllowedOrganizations[0];
    }
    return updateObj;
});
export const removeUserFromOrganization = (deleteFieldValue, userSnap, orgSnap) => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * There are currently two paths of removing a user from an org:
     * 1. If the user belongs to more than 1 org currently, update their
     *    allowedOrganizations to remove the org they're getting deleted from
     * 2. Otherwise, make the user an individual and blank out their org settings
     */
    const allowedOrganizations = userSnap.data().allowedOrganizations || [];
    // If user isn't a member of the specified org, throw an error
    if (!allowedOrganizations.find(o => o.id === orgSnap.id)) {
        throw new Error(`Can't remove user from organization because they are not a member`);
    }
    if ((allowedOrganizations === null || allowedOrganizations === void 0 ? void 0 : allowedOrganizations.length) > 1) {
        const updateObj = yield getUpdatedUserOrgStructure(userSnap, orgSnap);
        yield userSnap.ref.update(updateObj);
    }
    else {
        yield makeUserIndividual(deleteFieldValue, userSnap);
    }
});
/**
 * Filter for only open organization invites
 */
export const getOpenOrgInvitesForUser = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield getOpenInvitesForUser(ctx, user)).filter(invite => !!invite.data().organizationId);
});
/**
 * Filter open organization invites for only pending invites
 */
export const getPendingOrgInvitesForUser = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield getOpenOrgInvitesForUser(ctx, user)).filter(invite => invite.data().status === InviteStatus.pending.value);
});
/**
 * Open invite statuses: pending, snoozed
 * Closed invite statuses: declined, accepted
 */
export const inviteIsOpen = (invite) => {
    return exists(invite) && OPEN_INVITE_STATUSES.includes(invite.data().status);
};
export const updateRoleForOrg = (user, role, organization) => __awaiter(void 0, void 0, void 0, function* () {
    yield user.ref.update({
        roles: Object.assign(Object.assign({}, user.data().roles), { [organization.id]: role })
    });
});
export const getAdminUsersOfOrganization = (organizationSnap, ctx) => __awaiter(void 0, void 0, void 0, function* () {
    const membersSnap = yield ctx
        .usersRef()
        .where('allowedOrganizations', 'array-contains', organizationSnap.ref)
        .get();
    if (!membersSnap.docs.length)
        return;
    const adminMembers = membersSnap.docs.filter(member => {
        var _a;
        return exists(member) &&
            ((_a = member.data()) === null || _a === void 0 ? void 0 : _a.roles[organizationSnap.id]) === RoleType.admin.value;
    });
    return adminMembers;
});
