var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';
import { getFirebaseContext } from 'utils/firebase';
import { exists } from 'lib/types';
import Firebase, { Collections } from 'EnoticeFirebase';
import { InviteStatus } from 'lib/enums';
import { inviteIsOpen } from 'lib/users';
import Register from '../register/user';
import LoadingState from '../../components/LoadingState';
const UntypedRegister = Register;
const Invitation = ({ id, push, userAuth }) => {
    const ctx = getFirebaseContext();
    const [invite, loading, error] = useDocument(Firebase.firestore().collection(Collections.invites).doc(id));
    const [existingUser, setExistingUser] = useState(false);
    const inviteSnap = invite;
    useEffect(() => {
        if (exists(inviteSnap)) {
            void (() => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                /**
                 * Query for an existing registered (not anonymous)
                 * account associated with the email in the invite
                 */
                const maybeUser = (yield ctx
                    .usersRef()
                    .where('email', '==', (_a = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _a === void 0 ? void 0 : _a.email)
                    .get()).docs;
                setExistingUser(!!maybeUser.filter(user => !user.data().anonymous).length);
            }))();
        }
    }, [inviteSnap]);
    useEffect(() => {
        var _a, _b, _c, _d;
        const getUserData = () => __awaiter(void 0, void 0, void 0, function* () {
            const inviteSnap = yield ctx.invitesRef().doc(id).get();
            if (exists(inviteSnap)) {
                yield inviteSnap.ref.update({
                    status: InviteStatus.snoozed.value
                });
            }
        });
        /**
         * If a user is logged in, redirect to the notice table if:
         *  - the invite has already been accepted
         *  - the invite has not been accepted but the invite user is the one logged in
         */
        if ((((_a = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _a === void 0 ? void 0 : _a.status) === InviteStatus.accepted.value &&
            userAuth) ||
            (inviteIsOpen(inviteSnap) && userAuth && ((_b = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _b === void 0 ? void 0 : _b.user))) {
            if ((_c = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _c === void 0 ? void 0 : _c.user) {
                void getUserData();
            }
            push('/notices');
        }
        else if (((_d = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _d === void 0 ? void 0 : _d.status) === InviteStatus.accepted.value ||
            existingUser) {
            /**
             * If the invite has been accepted or the invite is associated
             * with an existing column user, redirect to the login page
             */
            push('/login');
        }
    });
    if (error && !exists(inviteSnap)) {
        return React.createElement("div", null, "This is not a valid invitation!");
    }
    if (loading)
        return React.createElement(LoadingState, null);
    // If we get here, redirect the user to registration as they don't yet exist in Column
    sessionStorage.setItem('inviteId', invite.id);
    return React.createElement(UntypedRegister, { invite: inviteSnap });
};
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        id: (_b = (_a = matchPath(state.router.location.pathname, {
            path: '/invites/:id',
            exact: true,
            strict: false
        })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id,
        userAuth: state.auth.userAuth
    });
};
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(Invitation);
