var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
import { OccupationType } from '../enums';
/**
 * Check if a user has an organization ref and that they have the occupation
 * of an organization member.
 */
export const isMemberOfOrganization = (user) => {
    if (!exists(user)) {
        return false;
    }
    const { organization, occupation } = user.data();
    if (!organization) {
        return false;
    }
    return (occupation === OccupationType.government_official.value ||
        occupation === OccupationType.lawyer.value ||
        occupation === OccupationType.other_organization.value ||
        occupation === OccupationType.publishing.value);
};
/**
 * Get the appropriate stripeId for a user. If they are a member of an
 * organization prefer the org's Stripe ID.
 */
export const getUserStripeId = (user) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (isMemberOfOrganization(user)) {
        const orgSnap = yield user.data().organization.get();
        const orgStripeId = (_a = orgSnap.data()) === null || _a === void 0 ? void 0 : _a.stripeId;
        if (orgStripeId) {
            return orgStripeId;
        }
    }
    return user.data().stripeId;
});
/**
 * Determine if a user is a publisher (as opposed to an advertiser).
 */
export const isPublisher = (user) => {
    return user.data().occupation === OccupationType.publishing.value;
};
