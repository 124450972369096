var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { exists } from 'lib/types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { cdnIfy } from 'lib/helpers';
import { getIsAfterPublishingDeadline } from 'lib/utils/deadlines';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import classNames from 'classnames';
import { canEditNoticeWithoutSupport } from 'utils/permissions';
import { isInvoicePriceAdjusted } from 'lib/utils/invoices';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getUploadedFileURL } from './helpers';
import ScrollStep from './ScrollStep';
import InvoiceReminderModal from './InvoiceReminderModal';
import AffidavitReminderModal from './AffidavitReminderModal';
const styles = (theme) => createStyles({
    bigButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main[400]} !important;`
        },
        position: 'relative',
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(2),
            left: 0
        },
        width: '100%',
        height: 100,
        minWidth: 240,
        borderRadius: 0
    },
    stepContainer: {
        flexWrap: 'nowrap',
        backgroundColor: theme.palette.primary.main
    },
    confirmProofModal: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        width: 400,
        outline: 'none'
    },
    darkContainer: {
        padding: theme.spacing(2, 4, 3),
        backgroundColor: theme.palette.primary[900],
        borderRadius: theme.spacing(1)
    },
    secondaryText: {
        color: 'white',
        opacity: 0.9
    },
    primaryText: {
        color: 'white'
    },
    icon: {
        color: 'white',
        opacity: 0.5
    },
    progress: {
        opacity: 0.5
    },
    invoiceProgress: {
        color: 'white'
    },
    buttonLeft: {
        width: '100%',
        height: 60,
        backgroundColor: theme.palette.grey[100],
        '&:hover': {
            backgroundColor: theme.palette.grey[400]
        },
        borderTopRightRadius: `0 !important`,
        borderBottomRightRadius: `0 !important`
    },
    buttonRight: {
        width: '100%',
        height: 60,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary[600]
        },
        color: 'white',
        borderTopLeftRadius: `0 !important`,
        borderBottomLeftRadius: `0 !important`
    },
    confirmCheckbox: {
        width: '90%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 'large'
    },
    checkBox: {
        color: theme.palette.grey.scrollFormHeading
    }
});
const ConfirmProofStep = ({ user, id, activeStepId, newspaper, notice, isPublisher, onDisabledStepClick, editing, placement, placementActions }) => {
    var _a;
    const [showProofConfirmationModal, setShowProofConfirmationModal] = useState(false);
    const [showInvoiceReminderModal, setShowInvoiceReminderModal] = useState(false);
    const [showAffidavitReminderModal, setShowAffidavitReminderModal] = useState(false);
    const [error, setError] = useState('');
    const [invoiceSnap, setInvoiceSnap] = useState();
    const autoInvoiceFeatureEnabled = useBooleanFlag(LaunchDarklyFlags.ENABLE_AUTO_INVOICE_AFTER_EDITS);
    const invoicedOutsideColumn = !!((_a = invoiceSnap === null || invoiceSnap === void 0 ? void 0 : invoiceSnap.data()) === null || _a === void 0 ? void 0 : _a.invoiceOutsideColumn);
    const { proofStoragePath } = placement;
    const fileUrl = getUploadedFileURL(placement);
    const previewUrl = placement.postWithoutFormatting
        ? fileUrl
        : proofStoragePath
            ? cdnIfy(proofStoragePath, { useImgix: true })
            : '';
    const fileReady = proofStoragePath || placement.postWithoutFormatting;
    // TODO(COREDEV-1669): Actually implement this based on the settings of the notice,
    // and then make sure the auto-invoicing happens.
    const shouldAutoInvoice = autoInvoiceFeatureEnabled && false;
    const invoicePriceModified = exists(invoiceSnap) && isInvoicePriceAdjusted(notice, invoiceSnap);
    const deadlinePassed = () => {
        var _a;
        if (!exists(newspaper) || !((_a = placement.publicationDates) === null || _a === void 0 ? void 0 : _a.length))
            return;
        const { deadlines, iana_timezone } = newspaper.data();
        return getIsAfterPublishingDeadline(placement.publicationDates[0].toDate(), deadlines, iana_timezone, placement, newspaper);
    };
    const confirmProof = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        if (!isPublisher && ((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.editRunDatesAfterInvoicePaid)) {
            yield finishConfirmation();
        }
        else if (editing &&
            isPublisher &&
            exists(user) &&
            !canEditNoticeWithoutSupport(notice, user, newspaper) &&
            !((_c = notice.data()) === null || _c === void 0 ? void 0 : _c.invoice)) {
            setShowProofConfirmationModal(false);
            setShowAffidavitReminderModal(true);
        }
        else if (!((_d = notice.data()) === null || _d === void 0 ? void 0 : _d.invoice)) {
            yield finishConfirmation();
        }
        else {
            setShowProofConfirmationModal(false);
            setShowInvoiceReminderModal(true);
        }
    });
    const finishConfirmation = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        if (!placement.confirming) {
            // Prevent calling publishNotice multiple times since this causes issues due to draft being deleted
            placementActions.setConfirming(true);
            // regenerate the affidavit after notice editing
            if (placement.editing &&
                exists(notice) &&
                notice.data().invoice &&
                isPublisher) {
                yield ((_e = notice.data().original) === null || _e === void 0 ? void 0 : _e.update({
                    generatedAffidavitStoragePath: '',
                    generatedAffidavitURL: ''
                }));
            }
            if (!deadlinePassed() || isPublisher) {
                placementActions.publishNotice();
            }
            else {
                setError('Error: The ad deadline for this notice has passed. Please select a new date.');
            }
        }
    });
    useEffect(() => {
        const isInvoicedOutsideColumn = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!exists(notice) || !notice.data().invoice)
                return;
            const invoice = yield ((_a = notice.data().invoice) === null || _a === void 0 ? void 0 : _a.get());
            if (exists(invoice)) {
                setInvoiceSnap(invoice);
            }
        });
        void isInvoicedOutsideColumn();
    }, [notice]);
    useEffect(() => {
        if (placement.publicationDates && !deadlinePassed()) {
            setError('');
        }
    }, [placement.publicationDates]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, overrideStyles: true, onDisabledStepClick: onDisabledStepClick },
            React.createElement("div", { className: "items-center" },
                React.createElement("button", { id: "confirm-options", className: "w-full h-20 bg-blue-900 text-white text-2xl font-medium uppercase rounded-md", onClick: () => {
                        if (!placement.postWithoutFormatting) {
                            placementActions.setProofStoragePath(null);
                            placementActions.setProof();
                        }
                        setShowProofConfirmationModal(true);
                    } },
                    React.createElement("div", { className: "inline-block" },
                        'Review & Confirm',
                        " ",
                        editing ? ' edits' : '')))),
        React.createElement("div", { id: "modal-container" }, showProofConfirmationModal && (React.createElement(CancelOrSubmitModal, { setOpen: setShowProofConfirmationModal, onSubmit: confirmProof, primaryButtonText: 'Confirm', primaryButtonId: 'confirm-proof-button', disablePrimaryButton: !!error || !fileReady, tertiaryButtonText: 'Back', overrideTertiaryClose: () => {
                setShowProofConfirmationModal(false);
            }, overrideEntablaturePadding: 'p-0', overrideChildMargins: 'mx-0', maxWidth: 'sm:max-w-2xl', showLoadingSpinner: true },
            React.createElement("div", { id: "confirm-proof-modal", className: "text-center divide-y" },
                React.createElement("div", { id: "confirm-proof-preview", className: "relative" },
                    React.createElement("div", { className: "h-80 bg-grey-100" }, fileReady ? (React.createElement("embed", { className: "w-full h-full", src: `${previewUrl}#toolbar=0&navpanes=0&scrollbar=0`, type: "application/pdf" })) : (React.createElement("div", { className: "w-full h-full flex" },
                        React.createElement("div", { className: "px-3 rounded-b m-auto" },
                            React.createElement("div", { style: { borderTopColor: '#2D9BDB' }, className: "loader ease-linear rounded-full border-4 border-t-4 border-white h-8 w-8" })))))),
                React.createElement("a", { style: {
                        top: '307px',
                        right: '187px',
                        border: '1px solid #ABD7F1'
                    }, href: previewUrl, target: "_blank", rel: "noreferrer", className: classNames(`absolute w-fit items-center rounded-full bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-900`, { 'cursor-not-allowed pointer-events-none': !fileReady }) },
                    "Scroll above or click here to open in a new tab",
                    ' ',
                    React.createElement("span", { role: "img", "aria-label": "Hand with index finger pointing up" }, "\u261D")),
                React.createElement("div", { id: "confirm-proof-body", className: "px-12 py-10" },
                    React.createElement("div", { className: "pb-4 text-xl font-semibold text-gray-900" }, fileReady
                        ? `Preview ${placement.postWithoutFormatting ? 'file' : 'proof'} and confirm submission`
                        : `Generating proof...`),
                    React.createElement("div", { className: "text-sm font-medium text-grey-400 leading-6" },
                        "Scroll above to view your",
                        ' ',
                        placement.postWithoutFormatting ? 'uploaded file' : 'proof',
                        ' ',
                        "or",
                        ' ',
                        React.createElement("a", { className: classNames('text-blue-900', {
                                'cursor-not-allowed pointer-events-none': !fileReady
                            }), href: previewUrl, target: "_blank", rel: "noreferrer" }, "click here"),
                        ' ',
                        "to open it in a new tab.",
                        placement.postWithoutFormatting
                            ? ' By submitting your notice without formatting, you grant the newspaper permission to edit the layout of your notice. '
                            : ' ',
                        "After review, click the confirm button to submit your notice to ",
                        (newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().name) || 'the newspaper',
                        "."),
                    error && (React.createElement("div", { className: "pt-2 text-red-600 leading-tight" }, error))))))),
        showInvoiceReminderModal && (React.createElement(InvoiceReminderModal, { invoicedOutsideColumn: invoicedOutsideColumn, shouldAutoInvoice: shouldAutoInvoice, invoicePriceModified: invoicePriceModified, onBackClicked: () => setShowInvoiceReminderModal(false), onConfirmClicked: () => {
                if (exists(user) &&
                    !canEditNoticeWithoutSupport(notice, user, newspaper)) {
                    setShowInvoiceReminderModal(false);
                    setShowAffidavitReminderModal(true);
                }
                else {
                    void finishConfirmation();
                }
            } })),
        showAffidavitReminderModal && (React.createElement(AffidavitReminderModal, { onConfirmClicked: () => finishConfirmation(), onBackClicked: () => setShowAffidavitReminderModal(false) }))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps, { push })(withStyles(styles)(ConfirmProofStep));
