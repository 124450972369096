var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { shouldInitializeLD } from 'lib/helpers';
import { logAndCaptureException } from 'utils';
import { ENV, PROD, DEMO, TEST } from '../constants';
const PROD_CLIENT_SIDE_ID = '627ea6452e2c5315224da77b';
const TEST_CLIENT_SIDE_ID = '627ea6452e2c5315224da77a';
const DEMO_CLIENT_SIDE_ID = '629e6fc9b2fb3f1544ef101a';
const DEV_CLIENT_SIDE_ID = '62e8f1cfbd13ad10c0ad99ad';
const ANONYMOUS = { anonymous: true };
let _CLIENT;
/**
 * See: https://docs.launchdarkly.com/sdk/concepts/client-side-server-side#client-side-id
 */
const getClientSideId = () => {
    switch (ENV) {
        case PROD:
            return PROD_CLIENT_SIDE_ID;
        case DEMO:
            return DEMO_CLIENT_SIDE_ID;
        case TEST:
            return TEST_CLIENT_SIDE_ID;
        default:
            return DEV_CLIENT_SIDE_ID;
    }
};
/**
 * Get the LaunchDarkly client singleton.
 */
const getClient = (initialUser = ANONYMOUS) => {
    if (!_CLIENT) {
        _CLIENT = LDClient.initialize(getClientSideId(), initialUser);
    }
    return _CLIENT;
};
/**
 * Get the value of a boolean feature flag.
 */
export const getBooleanFlag = (flag, defaultValue = false) => {
    if (!shouldInitializeLD(ENV))
        return defaultValue;
    return getClient().variation(flag, defaultValue);
};
/**
 * Subscribe to changes to a single boolean feature flag.
 * Returns a function which can be invoked to unsubscrube.
 */
export const subscribeToBooleanFlag = (flag, callback) => {
    if (!shouldInitializeLD(ENV))
        return () => { };
    const client = getClient();
    const key = `change:${flag}`;
    client.on(key, callback);
    return () => {
        client.off(key, callback);
    };
};
/**
 * Custom hook to use the value of a feature flag, with live updates.
 */
export const useBooleanFlag = (flag, defaultValue = false) => {
    const [val, setVal] = useState(getBooleanFlag(flag, defaultValue));
    useEffect(() => {
        const unsub = subscribeToBooleanFlag(flag, value => {
            setVal(value);
        });
        return unsub;
    }, [flag]);
    return val;
};
/**
 * Set the current user and wait for the client to be ready.
 */
export const setUser = (user) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        if (!shouldInitializeLD(ENV, user))
            return;
        const ldUser = user
            ? {
                key: user.id,
                email: user.data().email,
                custom: {
                    activeOrganization: ((_a = user.data().activeOrganization) === null || _a === void 0 ? void 0 : _a.id) || '',
                    occupation: user.data().occupation
                }
            }
            : ANONYMOUS;
        const client = getClient(ldUser);
        yield client.waitUntilReady();
        const flagSet = yield client.identify(ldUser);
        console.log('Feature Flags', flagSet);
    }
    catch (err) {
        logAndCaptureException(err, 'Failed to set launchDarkly user', {
            userId: (user === null || user === void 0 ? void 0 : user.id) || '',
            environment: ENV
        });
    }
});
