import { LockIcon } from 'icons';
import React from 'react';
/**
 * This button is used in scenarios that kick off an auth action,
 * such as login and password reset
 */
const AuthButton = ({ children }) => {
    return (React.createElement("button", { id: "submit", type: "submit", className: "group relative w-full flex justify-center py-2 px-4 border border-transparent text-base leading-5 font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out" },
        React.createElement("span", { className: "absolute left-0 inset-y-0 flex items-center text-bold pl-3" },
            React.createElement(LockIcon, { className: "text-gray-700" })),
        children));
};
export default AuthButton;
