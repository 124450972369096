import React from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
const InvoiceReminderModal = ({ invoicedOutsideColumn, shouldAutoInvoice, invoicePriceModified, onConfirmClicked, onBackClicked }) => {
    const handleSetOpen = (val) => {
        if (!val) {
            onBackClicked();
        }
    };
    const renderBody = () => {
        if (invoicedOutsideColumn) {
            return (React.createElement("div", { className: "text-gray-800 py-2" },
                React.createElement("p", { className: "pb-3" }, "You opted to issue an invoice for this notice outside of Column. If you edited any fields which affect pricing, you may also wish to update your invoice to reflect any changes."),
                React.createElement("p", { className: "pb-3" },
                    "Click ",
                    React.createElement("b", null, "Confirm"),
                    " to continue.")));
        }
        if (shouldAutoInvoice) {
            if (invoicePriceModified) {
                return (React.createElement("div", { className: "text-gray-800 py-2" },
                    React.createElement("p", { className: "pb-3" }, "If you edited the newspaper, notice type, content, or publication dates, these edits may affect the notice price, and your original invoice may be inaccurate."),
                    React.createElement("p", { className: "pb-3" },
                        "This notice is configured for auto-invoicing, but the last invoice had a custom modified price so the invoice will ",
                        React.createElement("b", null, "not"),
                        " be automatically re-issued."),
                    React.createElement("p", { className: "pb-3" },
                        "If you wish to update the invoice, please re-invoice the client on the Notice Details page after clicking ",
                        React.createElement("b", null, "Confirm.")),
                    React.createElement("p", { className: "pb-3" }, "Otherwise, the original invoice will remain active.")));
            }
            return (React.createElement("div", { className: "text-gray-800 py-2" },
                React.createElement("p", { className: "pb-3" }, "If you edited the newspaper, notice type, content, or publication dates, these edits may affect the notice price, and your original invoice may be inaccurate."),
                React.createElement("p", { className: "pb-3" }, "This notice is configured for auto-invoicing, so the invoice will automatically be re-issued with the new price.")));
        }
        return (React.createElement("div", { className: "text-gray-800 py-2" },
            React.createElement("p", { className: "pb-3" }, "If you edited the newspaper, notice type, content, or publication dates, these edits may affect the notice price, and your original invoice may be inaccurate."),
            React.createElement("p", { className: "pb-3" },
                "If you wish to update the invoice, please re-invoice the client on the Notice Details page after clicking ",
                React.createElement("b", null, "Confirm.")),
            React.createElement("p", { className: "pb-3" }, "Otherwise, the original invoice will remain active.")));
    };
    return (React.createElement(CancelOrSubmitModal, { header: 'Invoice Reminder', tertiaryButtonText: 'Back', primaryButtonText: 'Confirm', noExitOutsideModal: true, setOpen: handleSetOpen, onSubmit: onConfirmClicked }, renderBody()));
};
export default InvoiceReminderModal;
