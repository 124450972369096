var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { getShouldShowAccountIDInPlacement } from 'lib/helpers';
export const useShouldShowAccountInfoStep = ({ noticeState }) => {
    var _a;
    const [shouldShowAccountIDInPlacement, setShouldShowAccountIDInPlacement] = useState();
    useEffect(() => {
        if (!exists(noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper))
            return;
        const determineWhetherToShowAccountInfo = () => __awaiter(void 0, void 0, void 0, function* () {
            const newspaperShowsAccountInfoStep = yield getShouldShowAccountIDInPlacement(noticeState.newspaper);
            setShouldShowAccountIDInPlacement(newspaperShowsAccountInfoStep);
        });
        void determineWhetherToShowAccountInfo();
    }, [(_a = noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper) === null || _a === void 0 ? void 0 : _a.id]);
    return shouldShowAccountIDInPlacement;
};
