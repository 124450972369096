var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ExternalLinkIcon } from 'icons/index';
import EModal from 'components/modals/EModal';
import { exists } from 'lib/types';
import { InvoiceStatus, MailStatus, State } from 'lib/enums';
import Firebase, { Collections } from '../../EnoticeFirebase';
export const MailTable = ({ notice, isPublisher, invoiceSnap }) => {
    const [affidavitSnap, setAffidavitSnap] = useState();
    const [mailSnapshots, loading] = useCollection(Firebase.firestore().collection(`${Collections.userNotices}/${notice.id}/mail`));
    const [invoiceMailings, setInvoiceMailings] = useState([]);
    const [mailAffidavitsOutsideColumn, setMailAffidavitsOutsideColumn] = useState(false);
    useEffect(() => {
        const getOrganisationFromNotice = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            setMailAffidavitsOutsideColumn(!!((_a = (yield notice.data().newspaper.get()).data()) === null || _a === void 0 ? void 0 : _a.mailAffidavitsOutsideColumn));
        });
        const { invoiceMailings } = notice.data();
        if (invoiceMailings) {
            setInvoiceMailings(invoiceMailings);
        }
        void getOrganisationFromNotice();
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    const isInvoicePaymentInitiatedOrPaid = () => exists(invoiceSnap) &&
        // TODO: Should this include InvoiceStatus.partially_refunded.value https://columnpbc.atlassian.net/browse/IT-4424
        [InvoiceStatus.paid.value, InvoiceStatus.initiated.value].includes(invoiceSnap.data().status);
    if (loading && !invoiceMailings.length)
        return null;
    const getStateLine = (mail) => {
        var _a;
        return `${mail.address.address_city}, 
  ${typeof mail.address.address_state === 'number'
            ? (_a = State.by_value(mail.address.address_state)) === null || _a === void 0 ? void 0 : _a.label
            : mail.address.address_state} 
  ${mail.address.address_zip}`;
    };
    const getMailStatusLine = (mail) => {
        const { url } = mail;
        return (React.createElement(React.Fragment, null,
            url && (React.createElement("p", { className: "text-gray-700" },
                "Mail Scheduled",
                ' ',
                React.createElement("button", { onClick: () => window.open(url) },
                    React.createElement(ExternalLinkIcon, { className: 'stroke-current text-gray-700 w-4 h-4' })))),
            mail.expected_delivery_date && (React.createElement("p", { className: "text-gray-700" },
                "Expected Delivery",
                ' ',
                moment(mail.expected_delivery_date).format('MM/DD/YYYY')))));
    };
    const getCourthouseStatus = (affidavitSnapshot) => {
        var _a;
        const { expected_delivery_date, mailStatus, url } = affidavitSnapshot.data();
        return (React.createElement(React.Fragment, null,
            mailStatus
                ? (_a = MailStatus.by_value(mailStatus)) === null || _a === void 0 ? void 0 : _a.label
                : !notice.data().affidavit
                    ? 'Waiting for affidavit upload...'
                    : !isInvoicePaymentInitiatedOrPaid()
                        ? 'Waiting for invoice payment...'
                        : '',
            url && (React.createElement("p", { className: "text-gray-700" },
                "Mail Scheduled",
                ' ',
                React.createElement("button", { onClick: () => window.open(url) },
                    React.createElement(ExternalLinkIcon, { className: 'stroke-current text-gray-700 w-4 h-4' })))),
            expected_delivery_date && (React.createElement("p", { className: "text-gray-700" },
                "Expected Delivery",
                ' ',
                moment(expected_delivery_date).format('MM/DD/YYYY')))));
    };
    const mailMobileView = (mail, i, isInvoice = false) => {
        return (React.createElement("div", { key: `mail-row${i}`, className: "text-gray-700 text-sm md:hidden" },
            React.createElement("div", { className: "border-b border-gray-300 w-full mb-2 mt-5" }),
            React.createElement("div", { className: "flex justify-between" },
                React.createElement("div", null,
                    React.createElement("div", { className: "uppercase font-light mb-1" }, "address"),
                    React.createElement("div", { className: "leading-4" }, mail.name),
                    React.createElement("div", { className: "leading-4" }, mail.address.address_line1),
                    React.createElement("div", { className: "leading-4" }, getStateLine(mail))),
                React.createElement("div", null,
                    React.createElement("div", { className: "uppercase font-light mb-1" }, "document"),
                    React.createElement("div", null, isInvoice ? 'Invoice ' : 'Affidavit')),
                React.createElement("div", null,
                    React.createElement("div", { className: "uppercase font-light mb-1" }, "copies"),
                    React.createElement("div", null, mail.copies || '1'))),
            React.createElement("div", null,
                React.createElement("div", { className: "uppercase font-light mb-1 mt-2" }, "status"),
                React.createElement("div", null, mailAffidavitsOutsideColumn
                    ? 'Mailing outside of Column'
                    : getMailStatusLine(mail)))));
    };
    const courthouseMobileView = (affidavitSnapshot, i) => {
        return (React.createElement("div", { key: `courthouse-row${i}`, className: "text-sm text-gray-700 md:hidden" },
            React.createElement("div", { className: "border-b border-gray-300 w-full mb-2 mt-5" }),
            React.createElement("div", { className: "flex justify-between" },
                React.createElement("div", null,
                    React.createElement("div", { className: "uppercase font-light mb-1" }, "address"),
                    React.createElement("div", { className: "leading-4" }, affidavitSnapshot.data().name),
                    React.createElement("div", { className: "leading-4" }, affidavitSnapshot.data().address.address_line1),
                    React.createElement("div", { className: "leading-4" }, getStateLine(affidavitSnapshot.data()))),
                React.createElement("div", null,
                    React.createElement("div", { className: "uppercase font-light mb-1" }, "document"),
                    React.createElement("div", null, "Affidavit")),
                React.createElement("div", null,
                    React.createElement("div", { className: "uppercase font-light mb-1" }, "copies"),
                    React.createElement("div", null, affidavitSnapshot.data().copies))),
            React.createElement("div", null,
                React.createElement("div", { className: "uppercase font-light mb-1 mt-2" }, "status"),
                React.createElement("div", null, mailAffidavitsOutsideColumn
                    ? 'Mailing outside of Column'
                    : getCourthouseStatus(affidavitSnapshot)))));
    };
    return (React.createElement("div", { className: "px-6" },
        !(mailSnapshots === null || mailSnapshots === void 0 ? void 0 : mailSnapshots.docs.length) && !invoiceMailings.length ? (React.createElement("h4", { className: "text-base -mt-2 leading-4 text-gray-500" }, isPublisher
            ? 'No print affidavit requested.'
            : 'No mailed affidavits requested')) : (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                mailSnapshots &&
                    mailSnapshots.docs
                        .filter((doc) => !doc.data().isCourthouse)
                        .map((affidavitSnapshot, i) => mailMobileView(affidavitSnapshot.data(), i)),
                mailSnapshots &&
                    mailSnapshots.docs
                        .filter((doc) => doc.data().isCourthouse)
                        .map((affidavitSnapshot, i) => courthouseMobileView(affidavitSnapshot, i)),
                invoiceMailings.length
                    ? invoiceMailings.map((mail, i) => mailMobileView(mail, i, true))
                    : ''),
            React.createElement("table", { className: "hidden md:table min-w-full divide-y divide-gray-200" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Address"),
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Document"),
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Copies"),
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Status"))),
                React.createElement("tbody", { className: "bg-white divide-y divide-gray-200", "x-max": "1" },
                    mailSnapshots &&
                        mailSnapshots.docs
                            .filter((doc) => !doc.data().isCourthouse)
                            .map((affidavitSnapshot, i) => (React.createElement("tr", { key: `${i}-mailing-address` },
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700" },
                                React.createElement("b", null, affidavitSnapshot.data().name),
                                React.createElement("br", null),
                                affidavitSnapshot.data().address.address_line1,
                                React.createElement("br", null),
                                affidavitSnapshot.data().address.address_line2,
                                React.createElement("br", null),
                                getStateLine(affidavitSnapshot.data())),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, "Affidavit"),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, affidavitSnapshot.data().copies),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, mailAffidavitsOutsideColumn
                                ? 'Mailing outside of Column'
                                : getMailStatusLine(affidavitSnapshot.data()))))),
                    mailSnapshots &&
                        mailSnapshots.docs
                            .filter((doc) => doc.data().isCourthouse)
                            .map((affidavitSnapshot, i) => (React.createElement("tr", { key: `${i}-courthouse-address` },
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700" },
                                React.createElement("b", null, affidavitSnapshot.data().name),
                                React.createElement("br", null),
                                affidavitSnapshot.data().address.address_line1,
                                React.createElement("br", null),
                                getStateLine(affidavitSnapshot.data())),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, "Affidavit"),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, affidavitSnapshot.data().copies),
                            React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, mailAffidavitsOutsideColumn
                                ? 'Mailing outside of Column'
                                : getCourthouseStatus(affidavitSnapshot))))),
                    invoiceMailings.map((invoiceMail, i) => (React.createElement("tr", { key: `${i}-courthouse-address` },
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700" },
                            React.createElement("b", null, invoiceMail.name),
                            React.createElement("br", null),
                            invoiceMail.address.address_line1,
                            React.createElement("br", null),
                            getStateLine(invoiceMail)),
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, "Invoice"),
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, '1'),
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, mailAffidavitsOutsideColumn
                            ? 'Mailing outside of Column'
                            : getMailStatusLine(invoiceMail))))))))),
        affidavitSnap && (React.createElement(EModal, { setOpen: setAffidavitSnap, header: "SEND PRINT AFFIDAVIT?", body: "e-notice will automatically send the paper affidavit on your behalf via snail mail. Once you click this button you do not need to take further action.", buttonText: "Confirm", onConfirm: () => affidavitSnap.ref
                .update({
                mailStatus: MailStatus.initiated.value
            })
                .then(() => setAffidavitSnap(undefined)) }))));
};
export default MailTable;
