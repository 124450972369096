import React from 'react';
import classNames from 'classnames';
function MilestoneConnector({ status }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-testid": "milestone-connector", className: `h-8 lg:h-6 w-full justify-center flex items-center` },
            React.createElement("div", { className: classNames('border h-0 w-full mr-5', {
                    'border-blue-500 bg-blue-500': status === 'active',
                    'border-grey-100 bg-grey-100': status === 'inactive'
                }) })),
        React.createElement("div", { className: `h-10 lg:h-5` })));
}
export default MilestoneConnector;
