import React from 'react';
import SuccessModal from 'components/modals/SuccessModal';
export function CancelNoticeSuccessModal({ setCancelSuccessModalConfig, cancelSuccessModalConfig }) {
    return (React.createElement(SuccessModal, { setOpen: setCancelSuccessModalConfig, header: cancelSuccessModalConfig.header, body: cancelSuccessModalConfig.body, buttonText: 'Okay, got it', gif: React.createElement(SailboatGif, null) }));
}
function SailboatGif() {
    return (React.createElement("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/11e6.50832-giphy-9-.gif", alt: "Two cats in a sailboat made out of a newspaper", className: "bg-red-200", style: {
            clipPath: 'circle()',
            width: '104px',
            height: '104px'
        } }));
}
