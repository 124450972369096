import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import React from 'react';
/**
 * Tab control over whether we are managing:
 * 1) Basic layout settings like headers and footers
 * 2) Details about our templates like fonts, kerning
 */
export default function TemplateSettingsTabs({ setActiveTab, className, activeTab, tabs }) {
    return (React.createElement(Tab.Group, { onChange: (index) => {
            setActiveTab(tabs[index]);
        } },
        React.createElement(Tab.List, { className: classNames('w-full border-b border-gray-300', className) }, tabs.map((tab, index) => (React.createElement(Tab, { className: classNames('w-40 ml-7 py-6 text-base text-blue-800 font-medium hover:text-blue-950 focus:outline-none', {
                'text-blue-950 border-b-2 border-blue-900': activeTab === tab
            }), onClick: () => setActiveTab(tab), key: index }, tab))))));
}
