var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createReducer, createActions } from 'reduxsauce';
import { NoticeType } from 'lib/enums';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    hydrateNoticeData: ['noticeId', 'redirect'],
    populateNoticeData: ['noticeData'],
    setFiler: ['filerRef'],
    setFiledBy: ['filedBy'],
    setCreatedBy: ['createdBy'],
    setFilingState: ['filingState'],
    setFilingCounty: ['filingCounty'],
    setFixedPrice: ['fixedPrice'],
    setAvailableStates: ['availableStates'],
    setNewspaper: ['newspaperRef'],
    setPublicationDates: ['publicationDates'],
    setNoticeText: ['noticeText'],
    setNoticeType: ['noticeType'],
    setPreviousNoticeType: ['previousNoticeType'],
    setFile: ['file'],
    setColumns: ['columns'],
    resetColumns: [],
    setColumnsEditingEnabled: ['columnsEditingEnabled'],
    setDraft: ['draftRef'],
    setOriginal: ['originalRef'],
    setFilesToAttach: ['filesToAttach'],
    setMail: ['mail'],
    setRate: ['rateRef'],
    setTemplate: ['templateRef'],
    setNewspaperList: ['newspaperList'],
    setDisplayUrl: ['displayUrl'],
    setDisplayParams: ['displayParams'],
    setProcessedDisplay: ['processedDisplay'],
    setSquashable: ['squashable'],
    setUnusedConfirmedHtml: ['unusedConfirmedHtml'],
    confirmSchedule: ['scheduleParams'],
    setConfirmedCrop: ['crop'],
    setPdfStoragePath: ['pdfStoragePath'],
    setUnusedDisplay: ['unusedDisplay'],
    setDynamicFooter: ['dynamicFooter'],
    setDynamicHeaders: ['dynamicHeaders'],
    setProofStoragePath: ['proofStoragePath'],
    setDefaultRateOverride: ['defaultRateOverride'],
    setDraftSnap: ['draftSnap'],
    setProof: [],
    saveDraft: [],
    publishNotice: [],
    resetState: [],
    setEditing: ['editing'],
    setPublicationDatesUpdated: ['publicationDatesUpdated'],
    setPlacementError: ['placementError'],
    setConfirmedText: ['confirmedText'],
    setFormattingError: ['formattingError'],
    setPostWithoutFormatting: ['postWithoutFormatting'],
    fileWithoutProof: [
        'formattingError',
        'postWithoutFormatting',
        'requiresFormatting'
    ],
    clearFileWithoutProof: [],
    continueWithLargeFile: [],
    setConfirming: ['confirming'],
    setCurrentStep: ['currentStep'],
    setNewCustomerInfo: ['newCustomerInfo'],
    setCustomer: ['customer'],
    setCustomerOrganization: ['customerOrganization'],
    setAccountNumber: ['accountNumber'],
    setDraftSnapshotUnsubscribe: ['draftSnapshotUnsubscribe'],
    confirmReferenceId: ['referenceId'],
    confirmInvoiceRecipient: ['data'],
    confirmAffidavitRecipients: ['data'],
    setAnonymousFilerId: ['anonymousFilerId']
});
export const PlacementTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    filer: null,
    filedBy: null,
    createdBy: null,
    availableStates: null,
    filingState: 0,
    filingCounty: '',
    publicationDates: null,
    adTemplate: null,
    original: null,
    draft: null,
    rate: null,
    newspaper: null,
    newspaperList: { loaded: false, newspapers: [] },
    noticeType: NoticeType.custom.value,
    previousNoticeType: 3,
    columns: 1,
    columnsEditingEnabled: true,
    confirmedHtml: '',
    unusedConfirmedHtml: '',
    confirmedCrop: null,
    displayParams: null,
    displayUrl: '',
    mail: null,
    invoiceMailings: null,
    proofStoragePath: null,
    filesToAttach: null,
    processedDisplay: false,
    squashable: false,
    dynamicHeaders: null,
    dynamicFooter: null,
    footerFormatString: '',
    pdfStoragePath: '',
    jpgStoragePath: '',
    jpgURL: '',
    referenceId: '',
    customAffidavit: '',
    defaultRateOverride: false,
    userId: '',
    publicationDatesUpdated: false,
    editing: false,
    draftSnap: null,
    unusedDisplay: '',
    placementError: null,
    confirmedText: '',
    postWithoutFormatting: false,
    requiresFormatting: false,
    continueWithLargeFile: false,
    confirming: false,
    currentStep: { id: '' },
    customer: null,
    newCustomerInfo: null,
    customerOrganization: null,
    requireEmailAffidavit: false,
    mailAffidavitsOutsideColumn: false,
    invoiceRecipient: null,
    fixedPrice: null,
    accountNumber: '',
    draftSnapshotUnsubscribe: null,
    anonymousFilerId: null
};
export const placementSelector = (state) => state.placement;
/* ------------- Reducer ------------- */
const handlers = {
    [Types.SET_FILING_STATE]: (state, { filingState }) => (Object.assign(Object.assign({}, state), { filingState })),
    [Types.SET_FILING_COUNTY]: (state, { filingCounty }) => (Object.assign(Object.assign({}, state), { filingCounty })),
    [Types.SET_DRAFT_SNAP]: (state, { draftSnap }) => (Object.assign(Object.assign({}, state), { draftSnap })),
    [Types.RESET_STATE]: (state) => {
        if (state.draftSnapshotUnsubscribe) {
            state.draftSnapshotUnsubscribe();
        }
        return Object.assign({}, INITIAL_STATE);
    },
    [Types.POPULATE_NOTICE_DATA]: (state, { noticeData }) => {
        const { text, confirmedHtml } = noticeData, noticeState = __rest(noticeData, ["text", "confirmedHtml"]);
        return Object.assign(Object.assign(Object.assign({}, state), noticeState), { confirmedHtml: confirmedHtml || text || null });
    },
    [Types.SET_MAIL]: (state, { mail }) => (Object.assign(Object.assign({}, state), { mail })),
    [Types.SET_NEWSPAPER]: (state, { newspaperRef }) => (Object.assign(Object.assign({}, state), { newspaper: newspaperRef })),
    [Types.SET_FILER]: (state, { filerRef }) => (Object.assign(Object.assign({}, state), { filer: filerRef, userId: filerRef.id })),
    [Types.SET_FILED_BY]: (state, { filedBy }) => (Object.assign(Object.assign({}, state), { filedBy })),
    [Types.SET_CREATED_BY]: (state, { createdBy }) => (Object.assign(Object.assign({}, state), { createdBy })),
    [Types.SET_TEMPLATE]: (state, { templateRef }) => (Object.assign(Object.assign({}, state), { adTemplate: templateRef })),
    [Types.SET_RATE]: (state, { rateRef }) => (Object.assign(Object.assign({}, state), { rate: rateRef })),
    [Types.SET_ORIGINAL]: (state, { originalRef }) => (Object.assign(Object.assign({}, state), { original: originalRef })),
    [Types.SET_DRAFT]: (state, { draftRef }) => (Object.assign(Object.assign({}, state), { draft: draftRef })),
    [Types.SET_AVAILABLE_STATES]: (state, { availableStates }) => (Object.assign(Object.assign({}, state), { availableStates })),
    [Types.SET_NEWSPAPER_LIST]: (state, { newspaperList }) => (Object.assign(Object.assign({}, state), { newspaperList })),
    [Types.SET_NOTICE_TYPE]: (state, { noticeType }) => (Object.assign(Object.assign({}, state), { noticeType })),
    [Types.SET_PREVIOUS_NOTICE_TYPE]: (state, { previousNoticeType }) => (Object.assign(Object.assign({}, state), { previousNoticeType })),
    [Types.SET_DISPLAY_URL]: (state, { displayUrl }) => (Object.assign(Object.assign({}, state), { displayUrl })),
    [Types.SET_PROCESSED_DISPLAY]: (state, { processedDisplay }) => (Object.assign(Object.assign({}, state), { processedDisplay })),
    [Types.SET_COLUMNS]: (state, { columns }) => (Object.assign(Object.assign({}, state), { columns })),
    [Types.SET_COLUMNS_EDITING_ENABLED]: (state, { columnsEditingEnabled }) => (Object.assign(Object.assign({}, state), { columnsEditingEnabled })),
    [Types.SET_FILES_TO_ATTACH]: (state, { filesToAttach }) => (Object.assign(Object.assign({}, state), { filesToAttach })),
    [Types.SET_NOTICE_TEXT]: (state, { noticeText }) => (Object.assign(Object.assign({}, state), { confirmedHtml: noticeText })),
    [Types.SET_UNUSED_CONFIRMED_HTML]: (state, { unusedConfirmedHtml }) => (Object.assign(Object.assign({}, state), { unusedConfirmedHtml })),
    [Types.SET_SQUASHABLE]: (state, { squashable }) => (Object.assign(Object.assign({}, state), { squashable })),
    [Types.SET_DISPLAY_PARAMS]: (state, { displayParams }) => (Object.assign(Object.assign({}, state), { displayParams })),
    [Types.SET_CONFIRMED_CROP]: (state, { crop }) => (Object.assign(Object.assign({}, state), { confirmedCrop: crop })),
    [Types.SET_PROOF_STORAGE_PATH]: (state, { proofStoragePath }) => (Object.assign(Object.assign({}, state), { proofStoragePath })),
    [Types.SET_PDF_STORAGE_PATH]: (state, { pdfStoragePath }) => (Object.assign(Object.assign({}, state), { pdfStoragePath })),
    [Types.SET_FIXED_PRICE]: (state, { fixedPrice }) => (Object.assign(Object.assign({}, state), { fixedPrice })),
    [Types.CONFIRM_SCHEDULE]: (state, { scheduleParams }) => (Object.assign(Object.assign({}, state), { publicationDates: scheduleParams.publicationDates, dynamicFooter: scheduleParams.dynamicFooter, footerFormatString: scheduleParams.footerFormatString, dynamicHeaders: scheduleParams.dynamicHeaders })),
    [Types.SET_DYNAMIC_HEADERS]: (state, { dynamicHeaders }) => (Object.assign(Object.assign({}, state), { dynamicHeaders })),
    [Types.SET_DYNAMIC_FOOTER]: (state, { dynamicFooter }) => (Object.assign(Object.assign({}, state), { dynamicFooter })),
    [Types.SET_DEFAULT_RATE_OVERRIDE]: (state, { defaultRateOverride }) => (Object.assign(Object.assign({}, state), { defaultRateOverride })),
    [Types.SET_EDITING]: (state, { editing }) => (Object.assign(Object.assign({}, state), { editing })),
    [Types.SET_PUBLICATION_DATES_UPDATED]: (state, { publicationDatesUpdated }) => (Object.assign(Object.assign({}, state), { publicationDatesUpdated })),
    [Types.SET_UNUSED_DISPLAY]: (state, { unusedDisplay }) => (Object.assign(Object.assign({}, state), { unusedDisplay })),
    [Types.SET_PLACEMENT_ERROR]: (state, { placementError }) => (Object.assign(Object.assign({}, state), { placementError })),
    [Types.SET_CONFIRMED_TEXT]: (state, { confirmedText }) => (Object.assign(Object.assign({}, state), { confirmedText })),
    [Types.SET_FORMATTING_ERROR]: (state, { formattingError }) => (Object.assign(Object.assign({}, state), { formattingError })),
    [Types.SET_POST_WITHOUT_FORMATTING]: (state, { postWithoutFormatting }) => (Object.assign(Object.assign({}, state), { postWithoutFormatting })),
    [Types.SET_PUBLICATION_DATES]: (state, { publicationDates }) => (Object.assign(Object.assign({}, state), { publicationDates })),
    [Types.FILE_WITHOUT_PROOF]: (state, { formattingError, postWithoutFormatting, requiresFormatting }) => (Object.assign(Object.assign({}, state), { formattingError,
        postWithoutFormatting,
        requiresFormatting, continueWithLargeFile: false })),
    [Types.CLEAR_FILE_WITHOUT_PROOF]: (state) => (Object.assign(Object.assign({}, state), { proofStoragePath: null, jpgStoragePath: '', jpgURL: '', formattingError: null, postWithoutFormatting: false, requiresFormatting: false, continueWithLargeFile: false })),
    [Types.CONTINUE_WITH_LARGE_FILE]: (state) => (Object.assign(Object.assign({}, state), { continueWithLargeFile: true, formattingError: null, postWithoutFormatting: false, requiresFormatting: false })),
    [Types.SET_CONFIRMING]: (state, { confirming }) => (Object.assign(Object.assign({}, state), { confirming })),
    [Types.SET_CURRENT_STEP]: (state, { currentStep }) => (Object.assign(Object.assign({}, state), { currentStep })),
    [Types.SET_NEW_CUSTOMER_INFO]: (state, { newCustomerInfo }) => (Object.assign(Object.assign({}, state), { newCustomerInfo })),
    [Types.SET_CUSTOMER]: (state, { customer }) => (Object.assign(Object.assign({}, state), { customer })),
    [Types.SET_CUSTOMER_ORGANIZATION]: (state, { customerOrganization }) => (Object.assign(Object.assign({}, state), { customerOrganization })),
    [Types.SET_ACCOUNT_NUMBER]: (state, { accountNumber }) => (Object.assign(Object.assign({}, state), { accountNumber })),
    [Types.SET_DRAFT_SNAPSHOT_UNSUBSCRIBE]: (state, { draftSnapshotUnsubscribe }) => {
        if (state.draftSnapshotUnsubscribe) {
            state.draftSnapshotUnsubscribe();
        }
        return Object.assign(Object.assign({}, state), { draftSnapshotUnsubscribe });
    },
    [Types.CONFIRM_REFERENCE_ID]: (state, { referenceId }) => (Object.assign(Object.assign({}, state), { referenceId, 
        // When the reference ID changes, set the proof storage path
        // to 'null' so that the proof is regenerated
        proofStoragePath: null })),
    [Types.CONFIRM_INVOICE_RECIPIENT]: (state, payload) => {
        const { invoiceRecipient } = payload.data;
        return Object.assign(Object.assign({}, state), { invoiceRecipient });
    },
    [Types.CONFIRM_AFFIDAVIT_RECIPIENTS]: (state, payload) => {
        const newState = Object.assign(Object.assign({}, state), { requireEmailAffidavit: payload.data.requireEmailAffidavit, mailAffidavitsOutsideColumn: payload.data.mailAffidavitsOutsideColumn });
        if (payload.data.customAffidavit !== undefined) {
            newState.customAffidavit = payload.data.customAffidavit;
        }
        if (payload.data.mail !== undefined) {
            newState.mail = payload.data.mail;
        }
        return newState;
    },
    [Types.SET_ANONYMOUS_FILER_ID]: (state, { anonymousFilerId }) => (Object.assign(Object.assign({}, state), { anonymousFilerId }))
};
export const reducer = createReducer(INITIAL_STATE, handlers);
/**
 * For testing only! Create a reducer with initial state.
 */
export const testReducerWithState = (state) => createReducer(state, handlers);
