import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTable, useGlobalFilter, useFilters, usePagination, useSortBy } from 'react-table';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';
import { ArrowDownIcon, ArrowLeft, ArrowRight, SearchIcon } from 'icons';
import ReactPaginate from 'react-paginate';
import { OccupationType } from 'lib/enums';
import { SORT_DESCENDING, SORT_ASCENDING } from 'routes/userNoticeList/table/types';
import LinkTo from 'components/LinkTo';
import PlaceNoticeButton from 'components/PlaceNoticeButton';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import Tooltip from 'components/Tooltip';
import { publisherReadyToUpload } from 'lib/publishers';
const GlobalFilter = ({ actions, user, setSearchValue, setShowUserOnlyNotices, showUserOnlyNotices, searchValue }) => {
    const showDropdownMenu = () => {
        var _a;
        if (!user)
            return false;
        if (!((_a = user.data()) === null || _a === void 0 ? void 0 : _a.activeOrganization))
            return false;
        if ([
            OccupationType.lawyer.value,
            OccupationType.government_official.value,
            OccupationType.other_organization.value
        ].includes(user.data().occupation))
            return true;
        return false;
    };
    const [isOpen, setOpen] = useState(false);
    // TODO: filter to my notices only
    const dropdownTitle = () => {
        if (!showUserOnlyNotices)
            return 'All notices';
        return 'Only my notices';
    };
    return (React.createElement("div", { className: "w-7/12 inline-block align-middle" },
        React.createElement("div", { className: `flex items-center gap-2` },
            showDropdownMenu() && (React.createElement("div", { className: "relative inline-block text-left" },
                React.createElement("div", { className: "mr-5 w-48 flex justify-between" },
                    React.createElement("button", { type: "button", onClick: () => setOpen(!isOpen), className: "bg-white border-2 truncate focus:outline-none hover:border-blue-400 p-2 px-4 rounded text-gray-600 font-semibold flex justify-between w-full", id: "dropdown-options-menu", "aria-expanded": "true", "aria-haspopup": "true" },
                        React.createElement("span", { className: "mr-2" }, dropdownTitle()),
                        React.createElement(ArrowDownIcon, { className: "h-5 w-5 text-gray-600 bold mt-1" }))),
                isOpen ? (React.createElement("div", { className: "-right-8 absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "dropdown-options-menu" },
                    React.createElement("div", { className: "py-1", role: "none" },
                        React.createElement("a", { href: "#", id: "show-all-notices", className: "block p-3 font-medium text-md text-gray-600 hover:bg-gray-100 hover:text-gray-900", role: "menuitem", onClick: () => {
                                setShowUserOnlyNotices && setShowUserOnlyNotices(false);
                                setOpen(false);
                            } }, "All Notices"),
                        React.createElement("a", { href: "#", id: "show-my-notices", className: "block p-3 font-medium text-md text-gray-600 hover:bg-gray-100 hover:text-gray-900", role: "menuitem", onClick: () => {
                                setShowUserOnlyNotices && setShowUserOnlyNotices(true);
                                setOpen(false);
                            } }, "Only my notices")))) : null)),
            React.createElement("div", { className: `relative rounded-md inline-block flex-1 ${!actions && 'ml-10p'}` },
                React.createElement("div", { className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" },
                    React.createElement(SearchIcon, null)),
                React.createElement("input", { type: "text", id: "search", value: searchValue || '', onChange: e => {
                        setSearchValue(e.target.value);
                    }, placeholder: `Search Notices...`, className: "block border border-gray-300 pl-10 py-1.5 rounded-md w-full" })),
            actions && actions)));
};
const mapStateToPropsForGlobalFilter = (state) => ({
    user: state.auth.user
});
const CGlobalFilter = connect(mapStateToPropsForGlobalFilter)(GlobalFilter);
const Table = ({ columns, data, actions, id, loading, tableState, sort, onPageChange, onRowsChange, onSort, setSearchValue, setShowUserOnlyNotices, showUserOnlyNotices, searchValue, user, activeOrganization, showAllOrgsNotices, emptyHeaderText, emptySubtitleText }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page, state: { pageSize, sortBy }, setPageSize, setSortBy } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: tableState.currentPage,
            pageSize: tableState.rowCount || 5,
            globalFilter: '',
            sortBy: sort.field
                ? [
                    {
                        id: sort.field,
                        desc: sort.direction === SORT_DESCENDING
                    }
                ]
                : []
        },
        manualSortBy: true,
        manualPagination: true,
        autoResetSortBy: false,
        autoResetGlobalFilter: false
    }, useFilters, useGlobalFilter, useSortBy, usePagination);
    // Triggered when the sort is changed in the UI of the tab at hand
    useEffect(() => {
        onSort({
            field: sortBy[0] ? sortBy[0].id : null,
            direction: sortBy[0]
                ? sortBy[0].desc
                    ? SORT_DESCENDING
                    : SORT_ASCENDING
                : null
        });
    }, [sortBy]);
    // Triggered when the sort is changed in redux (the UI of a different tab than the one at hand)
    useEffect(() => {
        var _a, _b;
        if (!sort.field) {
            if (sortBy[0]) {
                setSortBy([]);
            }
        }
        else if (((_a = sortBy[0]) === null || _a === void 0 ? void 0 : _a.id) !== sort.field ||
            ((_b = sortBy[0]) === null || _b === void 0 ? void 0 : _b.desc) !== (sort.direction === SORT_DESCENDING)) {
            setSortBy([{ id: sort.field, desc: sort.direction === SORT_DESCENDING }]);
        }
    }, [sort]);
    useEffect(() => {
        if (loading)
            return;
        setPageSize(tableState.rowCount);
    }, [loading, tableState.rowCount]);
    const [showFilter, setShowFilter] = useState({
        id: null
    });
    const isPublisher = user.data().occupation === OccupationType.publishing.value ||
        user.data().occupation === OccupationType.press_association_manager.value;
    const publisherCanSeeNewspaperSelectinPlacement = canPublisherUserSeeNewspaperSelect(user, true);
    const tableTitleHeadStyles = 'bg-gray-50 mx-3 my-5 px-3 text-left';
    const tableHeadStyles = 'px-6 py-3 bg-white text-left text-sm leading-4 text-gray-600 text-md font-medium border-t tracking-wider';
    const tableBodyStyles = 'bg-white divide-y divide-gray-200';
    // const tableRowStyles = 'px-6 py-4';
    const tableRowStyles = 'p-0';
    let pageHeight = 'h-32';
    switch (page.length) {
        case 1:
            pageHeight = 'h-56 border-none';
            break;
        case 2:
            pageHeight = 'h-48 border-none';
            break;
        case 3:
            pageHeight = 'h-32 border-none';
            break;
        case 4:
            pageHeight = 'h-16 border-none';
            break;
        default:
            break;
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: "-my-2 sm:-mx-6 lg:-mx-8" },
            React.createElement("div", { className: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" },
                React.createElement("div", { className: "shadow border border-gray-200 sm:rounded-lg" },
                    React.createElement("table", Object.assign({ id: id, className: "min-w-full divide-y divide-gray-200 pt-4 border-b table-fixed" }, getTableProps()),
                        React.createElement("thead", null, headerGroups.map((headerGroup, titleIndex) => (React.createElement("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { id: `thead-${titleIndex}`, key: `thead-${titleIndex}` }), headerGroup.headers.map((column, index) => (React.createElement("th", Object.assign({}, column.getHeaderProps(column.getSortByToggleProps()), { scope: "col", id: `thead-column-${index}`, key: `thead-column-${index}`, className: titleIndex === 0
                                ? tableTitleHeadStyles
                                : `${tableHeadStyles} ${column.width && column.width} ${index === 0 && 'whitespace-no-wrap'}`, onMouseEnter: () => setShowFilter({ id: column.id }), onMouseLeave: () => setShowFilter({ id: null }) }),
                            column.render('Header'),
                            titleIndex === 0 && (React.createElement(CGlobalFilter, { setSearchValue: setSearchValue, actions: actions, setShowUserOnlyNotices: setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue })),
                            titleIndex === 1 && !column.disableSortBy && (React.createElement("span", null, column.isSorted ? (column.isSortedDesc ? (React.createElement("span", { className: "pl-2" }, "\u2191")) : (React.createElement("span", { className: "pl-2" }, "\u2193"))) : (React.createElement("span", { className: `${showFilter.id === column.id
                                    ? 'text-gray-600 pl-2'
                                    : 'text-transparent pl-2'}` }, "\u2193"))))))))))),
                        rows.length > 0 && (React.createElement("tbody", Object.assign({}, getTableBodyProps(), { className: tableBodyStyles }), page.map((row, i) => {
                            prepareRow(row);
                            return (React.createElement("tr", Object.assign({}, row.getRowProps(), { key: `tr-${i}`, id: row.original.id, className: "table-row hover:bg-gray-100" }), row.cells.map((cell, index) => {
                                return (React.createElement("td", Object.assign({}, cell.getCellProps(), { key: `td-${index}`, id: `td-${index}`, className: tableRowStyles }), cell.column.Header !== 'ACTIONS' ? (React.createElement(LinkTo, { className: "block", href: `/${row.original.isdraft ? 'place' : 'notice'}/${row.original.orginalnoticeid ||
                                        row.original.id}` }, cell.render('Cell'))) : (cell.render('Cell'))));
                            })));
                        })))),
                    loading && (React.createElement("span", { className: "bg-white h-56 m-auto pt-24 text-center block" },
                        React.createElement("div", { className: "flex justify-center align-center w-full" },
                            React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })))),
                    !loading && rows.length === 0 && (React.createElement(React.Fragment, null,
                        React.createElement("div", { id: "empty-table-banner", className: "bg-white w-full" },
                            React.createElement("div", { className: "m-auto text-center pb-32 pt-20 w-100" },
                                React.createElement("div", { style: {
                                        clipPath: 'circle()',
                                        marginBottom: '14px'
                                    }, id: "empty-table-icon", className: "inline-block justify-center bg-yellow-500 w-24 h-24 overflow-hidden" },
                                    React.createElement("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/58d1.a511f-postman.gif", className: "scale-125" })),
                                React.createElement("div", { className: "mb-3 text-gray-850 text-lg font-semibold" }, emptyHeaderText),
                                React.createElement("div", { className: "mb-6 text-grey-400 text-sm font-medium" }, emptySubtitleText),
                                React.createElement(Tooltip, { position: "bottom", helpText: showAllOrgsNotices &&
                                        !publisherCanSeeNewspaperSelectinPlacement
                                        ? 'Select a specific organization to upload notice'
                                        : isPublisher &&
                                            !publisherReadyToUpload(activeOrganization, true) &&
                                            !showAllOrgsNotices
                                            ? 'Your account implementation is still being finalized. A member of the Column team will reach out.'
                                            : '' },
                                    React.createElement(PlaceNoticeButton, null)))))),
                    rows.length > 0 && page.length !== pageSize && page.length < 5 && (React.createElement("p", { className: `bg-white ${pageHeight} m-auto pt-12 text-center` })),
                    React.createElement("footer", { className: "px-5 py-0.5 bg-gray-50" },
                        React.createElement("nav", { className: "px-4 flex items-center justify-between sm:px-0" },
                            React.createElement("div", { className: "sm:block flex-1 flex" },
                                React.createElement("p", { className: "text-sm text-gray-700" },
                                    "Showing",
                                    React.createElement("span", { className: "font-medium mx-1" }, page.length > 0
                                        ? tableState.currentPage * pageSize + 1
                                        : 0),
                                    "to",
                                    React.createElement("span", { className: "font-medium mx-1" }, tableState.currentPage
                                        ? tableState.currentPage * pageSize + page.length
                                        : rows.length),
                                    "of",
                                    React.createElement("span", { className: "font-medium mx-1" }, tableState.total),
                                    "results")),
                            React.createElement("div", { className: "-mt-px w-0 flex-1 flex justify-end" },
                                React.createElement("select", { "aria-invalid": "false", value: pageSize, className: "select select-main bg-transparent text-gray-500 mr-2 focus:outline-none font-medium text-sm", onChange: e => {
                                        setPageSize(Number(e.target.value));
                                        onRowsChange && onRowsChange(Number(e.target.value));
                                        onPageChange(0);
                                    } }, [5, 10, 20, 50, 100].map(rowSize => (React.createElement("option", { key: rowSize, value: rowSize },
                                    rowSize,
                                    " rows")))),
                                React.createElement(ReactPaginate, { previousLabel: React.createElement(ArrowRight, null), previousClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", nextLabel: React.createElement(ArrowLeft, null), nextClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", breakLabel: '...', forcePage: tableState.currentPage, breakClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700", pageClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300", activeLinkClassName: "focus:outline-none outline-none text-blue-500 border-blue-500", pageLinkClassName: "px-4 text-sm", pageCount: Math.ceil(Math.min(tableState.total, pageSize * 100) / pageSize), marginPagesDisplayed: 2, pageRangeDisplayed: 5, onPageChange: pageTo => {
                                        onPageChange && onPageChange(pageTo.selected);
                                    }, containerClassName: 'pagination flex', activeClassName: 'text-blue-500 border-blue-500 outline-none' })))))))));
};
const mapStateToProps = (state) => ({
    activeOrganization: state.auth.activeOrganization,
    showAllOrgsNotices: state.auth.showAllOrgsNotices,
    user: state.auth.user
});
const mapDispatchToProps = (dispatch) => ({
    noticeActions: bindActionCreators(NoticesActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Table);
