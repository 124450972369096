import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import React from 'react';
import { exists } from 'lib/types';
const Header = ({ orgContext }) => {
    return (React.createElement(Helmet, null,
        React.createElement("title", null, exists(orgContext)
            ? `${orgContext.data().name} | Public Notices`
            : 'Column - Public Notice')));
};
const mapStateToProps = (state) => ({
    orgContext: state.auth.orgContext
});
export default connect(mapStateToProps)(Header);
