var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import ExitWithoutSavingModal from 'components/modals/ExitWithoutSavingModal';
import * as validators from 'routes/register/organization/validators';
import { getFirstAndLastNameFromFullName } from 'routes/placeScroll/helpers';
import HorizontalDivider from '../HorizontalDivider';
import { EditModalHeader } from './EditModalHeader';
import { EditModalDetails } from './EditModalDetails';
import { EditCustomerModalSettings } from './EditCustomerModalSettings';
import { useSyncCustomerData } from './useSyncCustomerData';
const EditCustomerModal = ({ customerData, activeOrganization, rates, closeModal, setToastText }) => {
    var _a, _b, _c;
    useSyncCustomerData({ customerData });
    const customerRecord = customerData.customer.data();
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [phone, setPhone] = useState('');
    const [internalID, setInternalID] = useState('');
    const [affidavitsBeforePayment, setAffidavitsBeforePayment] = useState(customerRecord.enableAffidavitsBeforePayment);
    const [allowBulkInvoicing, setAllowBulkInvoicing] = useState(customerRecord.bulkPaymentEnabled);
    const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] = useState();
    const DEFAULT_SETTING = 0;
    const ALWAYS_REQUIRE = 1;
    const NEVER_REQUIRE = 2;
    const [linerRate, setLinerRate] = useState(customerData.defaultLinerRate);
    const [displayRate, setDisplayRate] = useState(customerData.defaultDisplayRate);
    const [allowAffidavitEmail, setAllowAffidavitEmail] = useState(customerRecord.allowAffidavitEmail);
    const [invoicedOutsideColumn, setInvoicedOutsideColumn] = useState(customerRecord.invoicedOutsideColumn);
    const [requireUpfrontPayment, setRequireUpfrontPayment] = useState();
    const [requireUpfrontPaymentId, setRequireUpfrontPaymentId] = useState(customerRecord.requireUpfrontPayment === null ||
        customerRecord.requireUpfrontPayment === undefined
        ? DEFAULT_SETTING
        : customerRecord.requireUpfrontPayment
            ? ALWAYS_REQUIRE
            : NEVER_REQUIRE);
    const [changedFields, setChangedFields] = useState({
        userName: false,
        firstName: false,
        lastName: false,
        address: false,
        addressLine2: false,
        city: false,
        state: false,
        zipCode: false,
        phone: false,
        internalID: false,
        requireUpfrontPayment: false,
        invoicedOutsideColumn: false,
        linerRate: false,
        displayRate: false,
        allowAffidavitEmail: false,
        affidavitsBeforePayment: false,
        allowBulkInvoicing: false,
        organizationName: false
    });
    useEffect(() => {
        var _a;
        // Set fields on initial load and when updates are received from `useSyncCustomerData` if necessary
        const { firstName, lastName } = getFirstAndLastNameFromFullName(customerRecord.userName || '');
        setUserName(customerRecord.userName || '');
        setFirstName(firstName || '');
        setLastName(lastName || '');
        setAddress(customerRecord.address || '');
        setAddressLine2(customerRecord.addressLine2 || '');
        setCity(customerRecord.city || '');
        setState((_a = customerRecord.state) !== null && _a !== void 0 ? _a : undefined);
        setZipCode(customerRecord.zipCode || '');
        setOrganizationName(customerRecord.organizationName || '');
        setPhone(customerRecord.phone || '');
        setInternalID(customerRecord.internalID || '');
    }, [safeStringify(customerData.customer.data())]);
    const { hideDigitalAffidavits } = activeOrganization.data() || {};
    const onSave = () => __awaiter(void 0, void 0, void 0, function* () {
        const updates = {
            linerRate: linerRate.ref,
            displayRate: displayRate.ref,
            enableAffidavitsBeforePayment: Boolean(affidavitsBeforePayment),
            allowAffidavitEmail: Boolean(allowAffidavitEmail),
            userName: userName.trim(),
            firstName,
            lastName,
            address,
            addressLine2,
            city,
            state,
            zipCode,
            organizationName,
            phone,
            internalID,
            invoicedOutsideColumn,
            requireUpfrontPayment,
            bulkPaymentEnabled: Boolean(allowBulkInvoicing)
        };
        removeUndefinedFields(updates);
        yield customerData.customer.ref.update(updates);
        const toastText = `Your changes to ${userName.trim()}'s details have been updated.`;
        setToastText(toastText);
        closeModal();
    });
    const isZipValid = validators.zipRegex.test(zipCode || '') || zipCode === '';
    const isPhoneValid = validators.phoneRegex.test(phone || '') || phone === '';
    const disableSave = !Object.values(changedFields).includes(true) ||
        !userName.trim() ||
        !isZipValid ||
        !isPhoneValid;
    const onClose = () => {
        if (!Object.values(changedFields).includes(true)) {
            closeModal();
        }
        else {
            setShowExitWithoutSavingModal(true);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "relative" },
            React.createElement("div", { className: "fixed overflow-scroll z-100 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out" },
                React.createElement(EditModalHeader, { onSave: onSave, onClose: onClose, disableSave: disableSave }),
                React.createElement("div", { className: "bg-gray-80 overflow-scroll" },
                    React.createElement(EditModalDetails, { name: userName, setName: setUserName, changedFields: changedFields, setChangedFields: setChangedFields, customerData: customerData, phone: phone, setPhone: setPhone, address: address, setAddress: setAddress, addressLine2: addressLine2, setAddressLine2: setAddressLine2, city: city, setCity: setCity, state: state, setState: setState, zipCode: zipCode, setZipCode: setZipCode, setInternalID: setInternalID, organizationName: organizationName, setOrganizationName: setOrganizationName, showOrganizationName: !((_c = (_b = (_a = customerData === null || customerData === void 0 ? void 0 : customerData.user) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.allowedOrganizations) === null || _c === void 0 ? void 0 : _c.length) }),
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "w-1/6" }),
                        React.createElement("div", { className: "w-2/3" },
                            React.createElement(HorizontalDivider, null),
                            React.createElement("div", { className: "w-1/6" }))),
                    React.createElement(EditCustomerModalSettings, { changedFields: changedFields, setChangedFields: setChangedFields, customerData: customerData, rates: rates, linerRate: linerRate, setLinerRate: setLinerRate, displayRate: displayRate, setDisplayRate: setDisplayRate, activeOrganization: activeOrganization, internalID: internalID, setInternalID: setInternalID, setRequireUpfrontPayment: setRequireUpfrontPayment, requireUpfrontPaymentId: requireUpfrontPaymentId, setRequireUpfrontPaymentId: setRequireUpfrontPaymentId, affidavitsBeforePayment: affidavitsBeforePayment, setAffidavitsBeforePayment: setAffidavitsBeforePayment, allowBulkInvoicing: allowBulkInvoicing, setAllowBulkInvoicing: setAllowBulkInvoicing, invoicedOutsideColumn: invoicedOutsideColumn, setInvoicedOutsideColumn: setInvoicedOutsideColumn, hideDigitalAffidavits: hideDigitalAffidavits, allowAffidavitEmail: allowAffidavitEmail, setAllowAffidavitEmail: setAllowAffidavitEmail, onClose: onClose, onSave: onSave, disableSave: disableSave }))),
            showExitWithoutSavingModal && (React.createElement(ExitWithoutSavingModal, { keepEditing: () => setShowExitWithoutSavingModal(false), exitWithoutSaving: () => {
                    setShowExitWithoutSavingModal(false);
                    closeModal();
                }, body: 'Your progress will be lost if you exit now. Click “Keep editing” to go back and save your changes instead' })))));
};
export default EditCustomerModal;
