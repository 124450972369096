import React from 'react';
import { XIcon } from 'icons';
const TailwindModal = ({ header, body, close, onButtonClick, buttonText, warning, children, loading, noExitOutsideModal, widthPct, disabled }) => {
    return (React.createElement("div", { className: "fixed bottom-0 md:inset-x-0 inset-0 flex items-center justify-center" },
        React.createElement("div", { className: "fixed inset-0 transition-opacity" },
            React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75", onClick: () => !noExitOutsideModal && close() })),
        React.createElement("div", { className: `flex bg-white w-7/20 rounded-lg pl-10 pr-5 py-8 overflow-hidden shadow-xl transform transition-all`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", style: widthPct ? { width: `${widthPct}%` } : undefined },
            React.createElement("div", { className: "flex-grow" },
                React.createElement("div", { className: "font-semibold text-lg text-gray-900 mb-4 flex justify-between mr-4" }, header),
                body && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "font-normal text-sm text-gray-700 mb-8 mr-4" }, body),
                    buttonText && (React.createElement("button", { className: `rounded-md font-semibold ${warning
                            ? 'bg-red-200 text-red-700 focus:border-red-500 hover:bg-red-600 hover:text-white'
                            : 'bg-blue-500 bg-opacity-25 text-blue-600'} ${disabled &&
                            !warning &&
                            'bg-gray-200 focus:border-gray-500 text-gray-700 hover:bg-gray-600'} text-sm flex items-center px-5 py-2`, type: "button", onClick: () => onButtonClick && onButtonClick(), disabled: loading },
                        React.createElement("span", { className: "flex" },
                            loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                            buttonText))))),
                children),
            React.createElement("div", null,
                React.createElement("span", { id: "close-modal", className: "cursor-pointer", onClick: () => close() },
                    React.createElement(XIcon, { className: "h-6 w-6" }))))));
};
export default TailwindModal;
