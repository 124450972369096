import React from 'react';
import { connect } from 'react-redux';
import { exists } from 'lib/types';
import Payments from './PaymentsRoute';
const mapStateToProps = (state) => ({
    user: state.auth.user,
    organization: state.auth.organization
});
const PaymentsWrapper = ({ user, organization }) => {
    if (!exists(user)) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(Payments, { user: user, organization: organization });
};
export default connect(mapStateToProps)(PaymentsWrapper);
