var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { roundUp } from 'lib/pricing';
import { RateType, NoticeType } from 'lib/enums';
import { cdnIfy, getDisplayUnits, requestDisplayParameters } from 'lib/helpers';
import { exists } from 'lib/types';
import { XIcon, ArrowsExpand } from 'icons/index';
import NoticeFilePreview from 'components/noticePreview/NoticeFilePreview';
import { ErrorContainer } from 'routes/placeScroll/PlaceNoticePreview';
import { getCreatePublisherDocsOnCallFn } from 'utils/callableFunctions';
import { INDESIGN_URL } from '../../constants';
import { getFirebaseContext } from '../../utils/firebase';
import ImagePreviewModal from '../../components/modals/ImagePreviewModal';
import { NoticePreviewDownloadButtons } from './NoticePreviewDownloadButtons';
import useDebounce from '../../utils/UseDebounce';
const NoticePreview = ({ notice, newspaper, isPublisher, invoiceOverdue, push, setShowPreviewOnMobile }) => {
    var _a, _b, _c, _d;
    const [isHovering, setIsHovering] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [rate, setRate] = useState();
    const [displayParams, setDisplayParams] = useState((_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.displayParams);
    const [noticeFiles, setNoticeFiles] = useState(null);
    const inInvoiceRoute = window.location.pathname.includes('invoice');
    const measurement = getDisplayUnits(rate, newspaper, (_b = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _b === void 0 ? void 0 : _b.displayParams);
    // TODO(APP-150): We were calling createPublisherDocs too often. This should
    // reduce the frequency of the calls without changing the actual behavior.
    const debouncedNotice = useDebounce(notice, 1000);
    useEffect(() => {
        if (!exists(notice))
            return;
        const fetchNoticeFiles = () => __awaiter(void 0, void 0, void 0, function* () {
            const filesFromNotice = yield getFirebaseContext()
                .userNoticeFilesRef(notice.ref)
                .get();
            if (filesFromNotice.size) {
                setNoticeFiles(filesFromNotice.docs);
            }
            else {
                setNoticeFiles(null);
            }
        });
        void fetchNoticeFiles();
    }, [notice]);
    useEffect(() => {
        if (!notice)
            return;
        const shouldRetryFileCreation = () => {
            if (notice.data().noticeType === NoticeType.display_ad.value ||
                notice.data().pdfURL ||
                notice.data().postWithoutFormatting) {
                return false;
            }
            return true;
        };
        if (shouldRetryFileCreation()) {
            const createPublisherDocs = getCreatePublisherDocsOnCallFn();
            void createPublisherDocs({
                noticeId: notice.id
            });
        }
    }, [debouncedNotice]);
    const getColumns = () => __awaiter(void 0, void 0, void 0, function* () {
        setRate(yield notice.data().rate.get());
    });
    const getDisplayParams = () => __awaiter(void 0, void 0, void 0, function* () {
        const displayParameters = yield requestDisplayParameters(getFirebaseContext(), notice, {
            format: 'jpg',
            type: 'DISPLAY_PARAMETERS',
            url: INDESIGN_URL,
            optimizeColumns: false
        }, window.DOMParser);
        setDisplayParams(displayParameters);
        yield notice.ref.update({
            displayParams: displayParameters
        });
    });
    useEffect(() => {
        if (notice.data().postWithoutFormatting)
            return;
        if (notice && notice.data().noticeType === NoticeType.display_ad.value) {
            void getColumns();
        }
        if (notice && notice.data().noticeType !== NoticeType.display_ad.value) {
            if (!notice.data().displayParams) {
                void getDisplayParams();
            }
            else {
                setDisplayParams(notice.data().displayParams);
            }
        }
    }, [notice, newspaper]);
    useEffect(() => {
        if (displayParams) {
            void (() => __awaiter(void 0, void 0, void 0, function* () { return setRate(yield notice.data().rate.get()); }))();
        }
    }, [displayParams, newspaper]);
    const handleOpenModal = () => {
        if (notice.data().noticeType === NoticeType.display_ad.value) {
            const { pdfStoragePath } = notice.data();
            if (pdfStoragePath) {
                window.open(cdnIfy(pdfStoragePath));
            }
        }
        else {
            setOpenModal(true);
        }
    };
    const ShowClick = () => {
        return (React.createElement("a", { className: "absolute rounded-t h-full w-full flex items-center justify-center", onClick: () => setOpenModal(true) },
            React.createElement("div", { className: "opacity-100 text-current text-center text-2xl " },
                React.createElement("div", { className: "h-12" },
                    React.createElement(ArrowsExpand, { className: "inline-block align-baseline stroke-current mx-10 h-full" })),
                "Click to expand")));
    };
    const [imgLoaded, setImgLoaded] = useState(false);
    const { jpgStoragePath, pdfStoragePath } = notice.data();
    return (React.createElement("div", { className: "h-screen pt-20 border border-b-0" },
        React.createElement("div", { className: "grid grid-cols-4 gap-4 p-6 pt-0" },
            React.createElement("div", { className: "row w-full row-span-1 col-span-4 flex justify-between items-center" },
                React.createElement("h2", { className: `${inInvoiceRoute ? 'text-white' : 'text-gray-800'} text-4xl font-medium` }, "Preview"),
                setShowPreviewOnMobile && !inInvoiceRoute && (React.createElement(XIcon, { className: "text-gray-800 md:hidden h-6 w-6", onClick: () => setShowPreviewOnMobile(false) }))),
            React.createElement("div", { className: "row row-span-1 col-span-4" }, notice.data().postWithoutFormatting && noticeFiles ? (React.createElement(NoticeFilePreview, { noticeFile: noticeFiles[0].data(), noticeId: notice.id, isPublisher: isPublisher, push: push })) : (React.createElement("div", { className: `rounded w-full ${inInvoiceRoute ? '' : 'border border-gray-400'} shadow md:shadow-none` },
                React.createElement("div", { className: "grid" },
                    React.createElement("div", { className: `relative ${inInvoiceRoute
                            ? 'bg-gray-600 rounded-t'
                            : 'bg-gray-150 rounded'} flex`, onMouseEnter: () => setIsHovering(true), onMouseLeave: () => setIsHovering(false) },
                        React.createElement("button", { className: `grid items-center outline-none row-span-1 p-3 h-80 overflow-y-auto ${isHovering ? 'z-10 opacity-50' : ''} w-full`, onClick: handleOpenModal },
                            React.createElement("img", { id: "notice-preview-image", src: jpgStoragePath
                                    ? cdnIfy(jpgStoragePath)
                                    : pdfStoragePath
                                        ? cdnIfy(pdfStoragePath, {
                                            cloudinaryTransformations: 'f_jpg'
                                        })
                                        : '', className: `w-auto m-auto ${isHovering ? 'opacity-50' : ''} ${!imgLoaded ? 'hidden' : ''}`, ref: img => {
                                    if (!img)
                                        return;
                                    // eslint-disable-next-line no-param-reassign
                                    img.onload = () => {
                                        setImgLoaded(true);
                                    };
                                } }),
                            React.createElement("div", { className: ` ${imgLoaded ? 'hidden' : ''} block align-middle m-auto loader ease-linear rounded-full border-4 text-center border-t-4 border-white-500 h-6 w-6` })),
                        isHovering && React.createElement(ShowClick, null)),
                    React.createElement("div", { className: `${inInvoiceRoute ? 'bg-gray-400' : 'bg-gray-150 bg-opacity-20'} p-3 rounded-b row-span-1` },
                        React.createElement("h4", { className: "font-medium text-gray-900 uppercase" }, notice.data().noticeType === NoticeType.display_ad.value
                            ? 'Display Ad'
                            : 'Liner Ad'),
                        notice.data().noticeType === NoticeType.display_ad.value ? (React.createElement("p", null, `${notice.data().columns} Column(s) Wide - ${measurement.value
                            ? roundUp(measurement.value, (_c = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _c === void 0 ? void 0 : _c.roundOff).toFixed(2)
                            : 0} ${measurement.unit}`)) : (''),
                        displayParams &&
                            (displayParams === null || displayParams === void 0 ? void 0 : displayParams.words) &&
                            rate &&
                            notice.data().noticeType !== NoticeType.display_ad.value ? (React.createElement("p", { "data-testid": "display-unit" }, `${displayParams.words} ${RateType.word_count.plural} - ${displayParams.lines} ${RateType.line.plural} - ${measurement.value && notice.data().displayParams
                            ? roundUp(measurement.value, (_d = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _d === void 0 ? void 0 : _d.roundOff).toFixed(2)
                            : 0} ${measurement.unit}`)) : ('')))))),
            invoiceOverdue && isPublisher && (React.createElement("div", { className: "row row-span-1 col-span-4" },
                React.createElement(ErrorContainer, { text: `Warning: The advertiser's invoice payment is overdue. Are you sure you would still like to publish this notice?` }))),
            !notice.data().postWithoutFormatting && (React.createElement("footer", { className: "col-span-4" },
                React.createElement("h4", { className: `text-lg ${inInvoiceRoute ? 'text-white' : 'text-gray-800'} mb-4 font-bold` }, "Download"),
                React.createElement(NoticePreviewDownloadButtons, { notice: notice, isPublisher: isPublisher, noticeFiles: noticeFiles })))),
        openModal && (React.createElement(ImagePreviewModal, { jpgURLs: pdfStoragePath
                ? [
                    cdnIfy(pdfStoragePath, {
                        cloudinaryTransformations: `f_jpg/e_sharpen`
                    })
                ]
                : [], setOpenModal: setOpenModal }))));
};
export default NoticePreview;
