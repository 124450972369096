import React from 'react';
import { Grid } from '@material-ui/core';
import { exists } from 'lib/types';
import { Report } from 'components/datastudio';
/**
 * Returns the GDS report for the given organization.
 * @param organization organization to get reports for
 * @returns {string} report url
 */
export const getReportUrl = (organization) => {
    if (organization.data().reportUrl)
        return organization.data().reportUrl;
    return `https://datastudio.google.com/embed/reporting/193efc28-0f38-462e-912e-0072a33f088c/page/CMiGC?params=%7B%22root_organization_id%22:%22${organization.id}%22%7D`;
};
const Reports = ({ activeOrganization }) => {
    if (!exists(activeOrganization))
        return null;
    const reportUrl = getReportUrl(activeOrganization);
    return (React.createElement(Grid, { style: {
            height: 'calc(100vh - 65px)',
            position: 'fixed',
            left: 0,
            top: 65,
            width: '100vw'
        }, id: "studio-dashboard" },
        React.createElement(Report, { reportUrl: reportUrl, height: "100%" })));
};
export default Reports;
