import moment from 'moment';
import { exists } from '../types';
import { AffidavitStatusType, NoticeStatusType } from '../enums';
import { getNoticeTypeData } from '../publishers';
const getAffidavitStatusEnum = (noticeSnap, isPublisher, newspaper) => {
    if (!exists(newspaper)) {
        return AffidavitStatusType.awaiting_publication;
    }
    const { noticeStatus, publicationDates, requireEmailAffidavit, mailAffidavitsOutsideColumn } = noticeSnap.data();
    if (newspaper.data().affidavitDisabled)
        return AffidavitStatusType.affidavit_disabled;
    const isAffidavitSubmitted = noticeStatus === NoticeStatusType.affidavit_submitted.value ||
        noticeStatus === NoticeStatusType.affidavit_approved.value;
    const beforeLastPubDate = moment().isBefore(publicationDates && publicationDates.slice(-1)[0].toMillis());
    if (isAffidavitSubmitted)
        return AffidavitStatusType.submitted;
    if (!requireEmailAffidavit &&
        mailAffidavitsOutsideColumn &&
        !isAffidavitSubmitted)
        return AffidavitStatusType.outside_of_column;
    if (beforeLastPubDate)
        return AffidavitStatusType.awaiting_publication;
    if (!beforeLastPubDate)
        return isPublisher
            ? AffidavitStatusType.incomplete
            : AffidavitStatusType.awaiting_upload;
    return AffidavitStatusType.affidavit_disabled;
};
export const getAdvertiserAffidavitStatusEnum = (noticeSnap, newspaper) => getAffidavitStatusEnum(noticeSnap, false, newspaper);
export const getPublisherAffidavitStatusEnum = (noticeSnap, newspaper) => getAffidavitStatusEnum(noticeSnap, true, newspaper);
export const isAffidavitDisabled = (noticeSnap, newspaper) => {
    var _a;
    const customNotice = getNoticeTypeData(noticeSnap.data().noticeType, newspaper);
    return ((_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.affidavitDisabled) || customNotice.affidavitDisabled;
};
