var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NoticeType } from 'lib/enums';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
const chooseRate = (noticeType, userRates, orgRates) => {
    // make sure that rates associated with the notice type show up first
    const highestPriorityNoticeTypeOnRate = noticeType !== NoticeType.custom.value ? noticeType : null;
    const sortedOrgRates = orgRates
        ? orgRates.sort(a => a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1)
        : null;
    const sortedUserRates = userRates.sort(a => a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1);
    const orgCandidate = sortedOrgRates ? sortedOrgRates[0] : null;
    const userCandidate = sortedUserRates.length ? sortedUserRates[0] : null;
    if (orgCandidate && !userCandidate)
        return orgCandidate.ref;
    return userCandidate && userCandidate.ref;
};
/**
 * This function determines whether or not a particular rate can be
 * tied to an existing placement state. It takes into account things like
 * whether or not the notice is a display notice and if the rate is restricted
 * for particular days of the week.
 * @param rateSnapshot this is the rate that we are checking to see if it can run
 * @param placement the current redux state
 * @returns {boolean} true if we can use this rate, false if not
 */
export const rateCanPublishWithPlacementData = (rateSnapshot, placement) => {
    var _a, _b;
    // first determine if we can use this rate based on whether or not
    // the notice is a display notice
    const isDisplayNotice = placement.noticeType === NoticeType.display_ad.value;
    const rateIsDisplayOnly = rateSnapshot.data().noticeType === NoticeType.display_ad.value;
    const rateSupportsDisplay = rateIsDisplayOnly || rateSnapshot.data().supportsDisplay;
    if (!isDisplayNotice && rateIsDisplayOnly)
        return false;
    if (isDisplayNotice && !rateSupportsDisplay)
        return false;
    // determine whether or not we can use this rate based on whether or
    // not there are date restrictions
    if (!((_a = rateSnapshot.data().allowedDays) === null || _a === void 0 ? void 0 : _a.length))
        return true;
    const { publicationDates } = placement;
    if (!publicationDates || !publicationDates.length)
        return true;
    const firstPublicationDate = firestoreTimestampOrDateToDate(publicationDates[0]);
    const firstPublicationDay = firstPublicationDate.getDay();
    if ((_b = rateSnapshot.data().allowedDays) === null || _b === void 0 ? void 0 : _b.includes(firstPublicationDay))
        return true;
    return false;
};
/**
 * Order of precedence for setting rates:
 * 1. customer defaults
 * 2. user / user organization defaults (see chooseRate for priority ranking)
 * 3. newspaper defaults
 */
export const checkForDefault = (placement, newspaper, newNoticeType) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    const noticeType = newNoticeType || placement.noticeType;
    const isDisplay = noticeType === NoticeType.display_ad.value;
    let defaultOrgRates;
    if (!placement.filer)
        return;
    if (!newspaper)
        return;
    const user = yield placement.filer.get();
    const { customer } = placement;
    const customerSnap = yield (customer === null || customer === void 0 ? void 0 : customer.get());
    if (((_a = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _a === void 0 ? void 0 : _a.linerRate) && !isDisplay) {
        return (_b = customerSnap.data()) === null || _b === void 0 ? void 0 : _b.linerRate;
    }
    if (((_c = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _c === void 0 ? void 0 : _c.displayRate) && isDisplay) {
        return (_d = customerSnap.data()) === null || _d === void 0 ? void 0 : _d.displayRate;
    }
    const ctx = getFirebaseContext();
    const customBaseQuery = ctx
        .ratesRef()
        .where('organization', '==', newspaper.ref);
    if ((_e = user.data()) === null || _e === void 0 ? void 0 : _e.activeOrganization) {
        const defaultOrgRateQuery = yield customBaseQuery
            .where('organizations', 'array-contains', (_f = user.data()) === null || _f === void 0 ? void 0 : _f.activeOrganization)
            .get();
        defaultOrgRates = defaultOrgRateQuery.docs.filter(r => rateCanPublishWithPlacementData(r, placement));
    }
    const defaultUserRateQuery = yield customBaseQuery
        .where('filers', 'array-contains', user.ref)
        .get();
    const defaultUserRates = defaultUserRateQuery.docs.filter(r => rateCanPublishWithPlacementData(r, placement));
    const d = chooseRate(noticeType, defaultUserRates, defaultOrgRates);
    if (d)
        return d;
    const defaultRate = isDisplay
        ? newspaper.data().defaultDisplayRate
        : newspaper.data().defaultLinerRate;
    if (rateCanPublishWithPlacementData((yield defaultRate.get()), placement))
        return defaultRate;
    const newspaperRates = yield customBaseQuery.get();
    const availableNewspaperRates = newspaperRates.docs.filter(r => rateCanPublishWithPlacementData(r, placement));
    return availableNewspaperRates[0].ref;
});
