var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { OccupationType } from 'lib/enums';
/**
 * Determine if an organization with the given name and type already exists.
 */
export const organizationAlreadyExists = (organizationType, name) => __awaiter(void 0, void 0, void 0, function* () {
    const snap = yield Firebase.firestore()
        .collection(Collections.organizations)
        .where('organizationType', '==', organizationType)
        .where('name', '==', name)
        .limit(1)
        .get();
    return !snap.empty;
});
export const isAdvertiserOrganization = (occupation) => occupation === OccupationType.lawyer.value ||
    occupation === OccupationType.other_organization.value ||
    occupation === OccupationType.government_official.value;
export const isAddressHasAnyField = (address) => address.name ||
    address.city ||
    address.phone ||
    address.state.label ||
    address.addressLine1 ||
    address.zipCode;
