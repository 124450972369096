var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import api from 'api';
import { connect } from 'react-redux';
import { OrganizationType } from 'lib/enums';
import { getChildOrganizations } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import CreateInvitesResult from './CreateInvitesResult';
import CreateInvitesForm from './CreateInvitesForm';
import InviteWarningModal from './InviteWarningModal';
const mapStateToProps = (state) => ({
    availableOrganizations: state.auth.availableOrganizations
});
const inviteSuccessMessage = 'User(s) invited successfully!';
class CreateInvitesFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [{ email: null, role: null }],
            loading: false,
            invited: [],
            alreadyHaveInvitesToCurrentOrg: [],
            showParentWarningModal: false,
            warningModalDisplayed: false,
            emailsLinkedToNotices: [],
            alreadyLinkedToIncompatibleOrganization: [],
            alreadyInvitedToIncompatibleOrganization: [],
            alreadyMembersOfInvitedOrg: [],
            invitesUnsnoozed: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRoleSelect = this.handleRoleSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.removeClick = this.removeClick.bind(this);
        this.addClick = this.addClick.bind(this);
        this.reset = this.reset.bind(this);
        this.backClick = this.backClick.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
    }
    reset() {
        this.setState({
            values: [{ email: null, role: null }],
            loading: false,
            invited: [],
            alreadyHaveInvitesToCurrentOrg: [],
            showParentWarningModal: false,
            warningModalDisplayed: false,
            emailsLinkedToNotices: [],
            alreadyLinkedToIncompatibleOrganization: [],
            alreadyInvitedToIncompatibleOrganization: [],
            alreadyMembersOfInvitedOrg: [],
            invitesUnsnoozed: 0
        });
    }
    handleChange(i, event) {
        const values = [...this.state.values];
        // TODO: Get EventTarget type
        values[i].email = event.target.value;
        this.setState({ values });
    }
    handleRoleSelect(i, event) {
        const values = [...this.state.values];
        values[i].role = Number(event.target.value);
        this.setState({ values });
    }
    addClick() {
        this.setState(prevState => ({
            values: [...prevState.values, { email: null, role: null }]
        }));
    }
    removeClick(i) {
        const values = [...this.state.values];
        values.splice(i, 1);
        this.setState({ values });
    }
    backClick() {
        this.reset();
    }
    onConfirmClick(event) {
        this.setState({
            showParentWarningModal: false,
            warningModalDisplayed: true
        }, () => this.handleSubmit(event));
    }
    handleSubmit(event) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctx = getFirebaseContext();
                const childOrgs = yield getChildOrganizations(ctx, this.props.organization.ref);
                const shouldShowWarningModal = this.props.organization.data().organizationType ===
                    OrganizationType.newspaper.value &&
                    childOrgs.length &&
                    !this.state.warningModalDisplayed &&
                    this.props.availableOrganizations.length > 1;
                if (shouldShowWarningModal) {
                    this.setState({ showParentWarningModal: true });
                    return;
                }
                event.preventDefault();
                // while emails are being sent, show loader in button
                // on success, clear form
                this.setState({ loading: true });
                const req = {
                    inviteData: this.state.values,
                    organizationId: this.props.organization.id,
                    isExistingUserInvite: false
                };
                const result = yield api.post('users/invite', req);
                const { invited, invitesUnsnoozed, alreadyHaveInvitesToCurrentOrg, emailsLinkedToNotices, alreadyLinkedToIncompatibleOrganization, alreadyInvitedToIncompatibleOrganization, alreadyMembersOfInvitedOrg } = result.data;
                this.setState({
                    invited,
                    alreadyHaveInvitesToCurrentOrg,
                    loading: false,
                    emailsLinkedToNotices,
                    alreadyLinkedToIncompatibleOrganization,
                    alreadyInvitedToIncompatibleOrganization,
                    alreadyMembersOfInvitedOrg,
                    invitesUnsnoozed
                });
                const { setEngagedWithForm } = this.props;
                if (setEngagedWithForm)
                    setEngagedWithForm(true);
                if ((invited.length || invitesUnsnoozed) &&
                    !alreadyHaveInvitesToCurrentOrg.length &&
                    !alreadyMembersOfInvitedOrg.length &&
                    !alreadyLinkedToIncompatibleOrganization.length &&
                    !alreadyInvitedToIncompatibleOrganization.length) {
                    this.props.setToastMessage(inviteSuccessMessage);
                    if (this.props.setShowInviteForm) {
                        this.props.setShowInviteForm(false);
                    }
                }
            }
            catch (err) {
                console.error('err', err);
            }
        });
    }
    render() {
        const { user, onSkipForNowClick, engagedWithForm } = this.props;
        const { invited, alreadyHaveInvitesToCurrentOrg, values, loading, emailsLinkedToNotices, alreadyLinkedToIncompatibleOrganization, alreadyInvitedToIncompatibleOrganization, alreadyMembersOfInvitedOrg, invitesUnsnoozed } = this.state;
        const userInvitationFailed = emailsLinkedToNotices.length ||
            alreadyHaveInvitesToCurrentOrg.length ||
            alreadyMembersOfInvitedOrg.length ||
            alreadyLinkedToIncompatibleOrganization.length ||
            alreadyInvitedToIncompatibleOrganization.length;
        if ((invited.length || invitesUnsnoozed) &&
            !alreadyHaveInvitesToCurrentOrg.length &&
            !alreadyMembersOfInvitedOrg.length &&
            !alreadyLinkedToIncompatibleOrganization.length &&
            !alreadyInvitedToIncompatibleOrganization.length) {
            if (!this.props.setEngagedWithForm) {
                if (this.props.setShowInviteForm) {
                    this.props.setShowInviteForm(false);
                }
                return React.createElement(React.Fragment, null);
            }
            // In post registration
            return (React.createElement(React.Fragment, null,
                !this.props.setInviteFormHeader && (React.createElement("div", { className: "font-semibold text-xl text-gray-700 mb-2" }, "Invite members to your organization")),
                React.createElement(CreateInvitesForm, { handleSubmit: this.handleSubmit, removeClick: this.removeClick, addClick: this.addClick, handleChange: this.handleChange, handleRoleSelect: this.handleRoleSelect, values: values, loading: loading, user: user, onSkipForNowClick: onSkipForNowClick, engagedWithForm: engagedWithForm, organization: this.props.organization })));
        }
        if (userInvitationFailed) {
            return (React.createElement("div", null,
                !this.props.setInviteFormHeader && (React.createElement("div", { className: "font-semibold text-xl text-gray-700 mb-4" }, "Oops! The invitation was not sent.")),
                React.createElement(CreateInvitesResult, { alreadyHaveInvitesToCurrentOrg: alreadyHaveInvitesToCurrentOrg, alreadyLinkedToIncompatibleOrg: alreadyLinkedToIncompatibleOrganization, alreadyInvitedToIncompatibleOrg: alreadyInvitedToIncompatibleOrganization, alreadyMembersOfInvitedOrg: alreadyMembersOfInvitedOrg, setInviteFormHeader: this.props.setInviteFormHeader, backClick: this.backClick, emailsLinkedToNotices: emailsLinkedToNotices.length
                        ? this.props.organization.data().organizationType ===
                            OrganizationType.newspaper.value
                        : false })));
        }
        if (this.state.showParentWarningModal) {
            return (React.createElement("div", null,
                React.createElement(InviteWarningModal, { onConfirmClick: this.onConfirmClick, organization: this.props.organization, setInviteFormHeader: this.props.setInviteFormHeader })));
        }
        return (React.createElement(React.Fragment, null,
            !this.props.setInviteFormHeader && (React.createElement("div", { className: "font-semibold text-xl text-gray-700 mb-2" }, "Invite members to your organization")),
            React.createElement(CreateInvitesForm, { handleSubmit: this.handleSubmit, removeClick: this.removeClick, addClick: this.addClick, handleChange: this.handleChange, handleRoleSelect: this.handleRoleSelect, values: values, loading: loading, user: user, onSkipForNowClick: onSkipForNowClick, engagedWithForm: engagedWithForm, organization: this.props.organization })));
    }
}
export default connect(mapStateToProps)(CreateInvitesFormContainer);
