var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import MadlibEditor from 'routes/madlib/components/MadlibEditor';
import QuestionForm from 'routes/madlib/components/QuestionForm';
// TODO: Move these out into a config repo
import HANDLEBARS_TEMPLATE_URL from '../data/template.handlebars';
import QUESTIONS_FILE from '../data/questions.json';
function NoticeEditorMadlib({ onEditorUpdate, templateStyles, onMadlibPreQuestionsUpdate, columns }) {
    const [questions, setQuestions] = useState([]);
    const [rawTemplate, setRawTemplate] = useState('');
    const [questionTemplateData, setQuestionTemplatedata] = useState({});
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    useEffect(() => {
        const fetchTemplate = () => __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(HANDLEBARS_TEMPLATE_URL);
            const text = yield res.text();
            setRawTemplate(text);
            setQuestions(QUESTIONS_FILE.questions);
        });
        void fetchTemplate();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white mb-4 border px-6 py-2 shadow-sm rounded" },
            React.createElement(QuestionForm, { questions: questions, onChange: e => {
                    setQuestionTemplatedata(e.values);
                    setAllQuestionsAnswered(e.complete);
                    // Validations for questions that are not tied to undefined as empty value
                    const isAllNonEmptyAnswers = e.complete &&
                        Object.entries(e.values).every(key => key[1] !== '');
                    onMadlibPreQuestionsUpdate &&
                        onMadlibPreQuestionsUpdate(isAllNonEmptyAnswers);
                } })),
        allQuestionsAnswered && (React.createElement("div", { className: "bg-white mb-4 border px-4 py-2 shadow-sm rounded overflow-y-scroll h-128" },
            React.createElement(MadlibEditor, { templateStyles: templateStyles, columns: columns, rawTemplate: rawTemplate, questionTemplateData: questionTemplateData, onStateChange: state => {
                    onEditorUpdate(state);
                } })))));
}
export default NoticeEditorMadlib;
