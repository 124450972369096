var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { sanitize } from 'utils';
import Firebase from 'EnoticeFirebase';
import { cdnIfy } from 'lib/helpers';
import EToast from 'components/EToast';
import LoadingState from 'components/LoadingState';
import { SubdomainStatus, State } from 'lib/enums';
import FormError from 'routes/errors/FormError';
import api from 'api';
import { InformationCircle } from 'icons';
import SettingsHeader from '../../settings/SettingsHeader';
import { Link as LinkIcon, Social, BrandingIcon } from '../icons';
import RequestSubdomainModal from './RequestSubdomainModal';
import SubdomainSection from './SubdomainSection';
import Footer from './Footer';
import PdfSection from './PdfSection';
import { geocode } from '../../../lib/helpers';
const BrandingSettings = ({ user, activeOrganization, donationProfile }) => {
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [about, setAbout] = useState('');
    const [campaignDomain, setCampaignDomain] = useState('');
    const [logo, setLogo] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [orgColor, setOrgColor] = useState('');
    const [pdfUploadLoading, setPdfUploadLoading] = useState(false);
    const [pdfFile, setPdfFile] = useState();
    const [orgPdfUrl, setOrgPdfUrl] = useState('');
    const [logoFile, setLogoFile] = useState('');
    const [editorQuote, setEditorQuote] = useState({
        text: '',
        memberName: '',
        title: ''
    });
    const [showColorPicker, setShowColorPicker] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [showSubdomainModal, setShowSubdomainModal] = useState();
    const [toastText, setToastText] = useState('');
    const [showFormError, setShowFormError] = useState();
    const [nameError, setNameError] = useState();
    const [cityError, setCityError] = useState();
    const [aboutError, setAboutError] = useState();
    const [pdfError, setPdfError] = useState(false);
    const [domainError, setDomainError] = useState(false);
    const [quoteTextError, setQuoteTextError] = useState();
    const [quoteTextTouched, setQuoteTextTouched] = useState();
    const [aboutTouched, setAboutTouched] = useState();
    const [openTestModeMenu, setOpenTestModeMenu] = useState(false);
    const fileInputRef = useRef();
    useEffect(() => {
        const { name, about, domain, website, facebookLink, twitterLink, color, quote, logoUrl, pdfUrl, city, _geoloc } = donationProfile.data();
        setName(name);
        setCity(city);
        setAbout(about);
        setCampaignDomain(domain || name.split(' ').join('-').toLowerCase());
        setWebsiteUrl(website);
        setFacebook(facebookLink || '');
        setTwitter(twitterLink || '');
        setOrgColor(color || '#5850EC');
        setLogo(logoUrl);
        setOrgPdfUrl(pdfUrl);
        quote && setEditorQuote(quote);
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!_geoloc) {
                const { address, state, city } = activeOrganization.data();
                const addressToGeoLoc = `${address} ${city} ${State.by_value(state)}`;
                const _geoloc = yield geocode(addressToGeoLoc);
                if (_geoloc) {
                    yield donationProfile.ref.update({ _geoloc });
                }
            }
        }))();
    }, [donationProfile]);
    useEffect(() => {
        const uploadLogo = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            const file = (_c = (_b = (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : null;
            if (!file) {
                return;
            }
            const snapshot = yield Firebase.storage()
                .ref()
                .child(`donation-profile/${donationProfile.id}/${sanitize(file.name)}`)
                .put(file);
            setLogo(yield snapshot.ref.getDownloadURL());
        });
        if (logoFile)
            void uploadLogo();
    }, [logoFile]);
    useEffect(() => {
        const uploadPdf = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!pdfFile) {
                return;
            }
            setPdfUploadLoading(true);
            yield Firebase.storage()
                .ref()
                .child(`donation-profile/${donationProfile.id}/${sanitize(pdfFile.name)}`)
                .put(pdfFile);
            setOrgPdfUrl(cdnIfy(`donation-profile/${donationProfile.id}/${sanitize(pdfFile.name)}`, {
                cloudinaryTransformations: 'f_jpg'
            }));
            setPdfUploadLoading(false);
        });
        void uploadPdf();
    }, [pdfFile]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setToastText('');
        }, 15000);
        return () => clearTimeout(timer);
    }, [toastText]);
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setSubmitting(true);
        if (!(quoteTextError ||
            aboutError ||
            nameError ||
            cityError ||
            domainError ||
            !editorQuote.text ||
            !about ||
            !orgPdfUrl)) {
            yield donationProfile.ref.update({
                name,
                city,
                about,
                domain: campaignDomain,
                website: websiteUrl || '',
                facebookLink: facebook || '',
                twitterLink: twitter || '',
                color: orgColor || '#5850EC',
                logoUrl: logo || '',
                pdfUrl: orgPdfUrl,
                quote: editorQuote
                // goals: orgGoals
            });
            setToastText('Your campaign is published! Click “View Website” to check it out.');
        }
        else {
            setShowFormError(true);
            if (!orgPdfUrl)
                setPdfError(true);
        }
        setSubmitting(false);
    });
    useEffect(() => {
        if (!name)
            setNameError(true);
        else
            setNameError(false);
    }, [name]);
    useEffect(() => {
        if (!city)
            setCityError(true);
        else
            setCityError(false);
    }, [city]);
    useEffect(() => {
        if (aboutTouched && (!about || about.length < 50))
            setAboutError(true);
        else
            setAboutError(false);
    }, [about]);
    useEffect(() => {
        if (quoteTextTouched && (!editorQuote.text || editorQuote.text.length < 50))
            setQuoteTextError(true);
        else
            setQuoteTextError(false);
    }, [editorQuote.text]);
    useEffect(() => {
        if (showFormError) {
            setAboutTouched(true);
            setQuoteTextTouched(true);
            window.scrollTo(0, 0);
        }
    }, [showFormError]);
    useEffect(() => {
        if (showFormError) {
            const timer = setTimeout(() => {
                setShowFormError(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showFormError]);
    const requestSubdomain = (subdomain) => __awaiter(void 0, void 0, void 0, function* () {
        yield donationProfile.ref.update({
            subdomain,
            subdomainStatus: SubdomainStatus.pending.value
        });
        setShowSubdomainModal(false);
        setToastText('Subdomain request successful!');
        void api.post('notifications/slack', {
            message: `New subdomain request!\n*Newspaper Name:* ${activeOrganization.data().name}\n*Organization ID:* ${activeOrganization.id}\n*Donation Profile ID:* ${donationProfile.id}\n*User Email:* ${user.data().email}\n*Subdomain Requested:* ${subdomain}\n`
        });
    });
    const handleAboutInput = (newValue) => {
        setAbout(newValue.slice(0, 299));
    };
    const handleQuoteTextInput = (newValue) => {
        setEditorQuote(Object.assign(Object.assign({}, editorQuote), { text: newValue.slice(0, 299) }));
    };
    const handleQuoteNameInput = (newValue) => {
        setEditorQuote(Object.assign(Object.assign({}, editorQuote), { memberName: newValue.slice(0, 59) }));
    };
    const handleQuoteTitleInput = (newValue) => {
        setEditorQuote(Object.assign(Object.assign({}, editorQuote), { title: newValue.slice(0, 59) }));
    };
    const renderLogoSection = () => (React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex flex-col w-2/6" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Logo"),
            React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Upload your newspaper\u2019s logo.")),
        React.createElement("div", { className: "flex items-center not-italic font-normal text-sm text-black w-4/6" },
            logo ? (React.createElement("img", { className: "inline-block h-12 w-12 rounded-full", src: logo, alt: "Logo" })) : (React.createElement("span", { className: "inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100" },
                React.createElement("svg", { className: "h-full w-full text-gray-300", fill: "currentColor", viewBox: "0 0 24 24" },
                    React.createElement("path", { d: "M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" })))),
            React.createElement("div", { className: "rounded border border-gray-300 font-medium text-xs text-gray-800 cursor-pointer ml-3 px-3 py-2", onClick: () => {
                    if (fileInputRef && fileInputRef.current)
                        fileInputRef.current.click();
                } },
                "Upload",
                React.createElement("input", { ref: fileInputRef, type: "file", className: "hidden", accept: "image/*", onChange: e => setLogoFile(e.target.value) })))));
    const renderLinksSection = () => (React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex flex-col w-2/6" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Links"),
            React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Include links to your newspaper\u2019s website and social media.")),
        React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-4/6" },
            React.createElement("div", { className: "mt-1 flex rounded-md shadow-sm w-4/6" },
                React.createElement("span", { className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50" }, LinkIcon),
                React.createElement("input", { className: "form-input flex-1 block px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5 placeholder-gray-400", placeholder: "Newspaper Website", value: websiteUrl, onChange: e => setWebsiteUrl(e.target.value) })),
            React.createElement("div", { className: "flex w-4/6 justify-between mt-2" },
                React.createElement("div", { className: "mt-1 flex rounded-md shadow-sm", style: { width: '47%' } },
                    React.createElement("span", { className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50" }, Social),
                    React.createElement("input", { className: "form-input w-full block px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5 placeholder-gray-400", placeholder: "Facebook", value: facebook, onChange: e => setFacebook(e.target.value) })),
                React.createElement("div", { className: "mt-1 flex rounded-md shadow-sm", style: { width: '47%' } },
                    React.createElement("span", { className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50" }, Social),
                    React.createElement("input", { className: "form-input block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5 placeholder-gray-400", placeholder: "Twitter", value: twitter, onChange: e => setTwitter(e.target.value) }))))));
    const renderColorSection = () => (React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex flex-col w-2/6" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Color customization"),
            React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "We recommend choosing a dark color.")),
        React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-4/6" },
            React.createElement("div", { className: "mt-1 flex rounded-md shadow-sm w-4/6" },
                React.createElement("span", { className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50" }, BrandingIcon),
                React.createElement("span", { className: "w-full" },
                    React.createElement("input", { className: "form-input flex-1 w-full block px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5", style: { color: orgColor }, value: orgColor, onClick: () => {
                            setShowColorPicker(!showColorPicker);
                        } })),
                showColorPicker && (React.createElement("div", { className: "absolute z-10" },
                    React.createElement("div", { className: "fixed top-0 bottom-0 left-0 right-0", onClick: () => {
                            setShowColorPicker(!showColorPicker);
                        } }),
                    React.createElement(ChromePicker, { color: orgColor, onChange: (color) => setOrgColor(color.hex) })))))));
    const renderQuoteSection = () => (React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex flex-col w-2/6" },
            React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Pull quote*"),
            React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Include a captivating quote from your publisher, editor-in-chief, or staff member. This will appear on your site.")),
        React.createElement("div", { className: "flex flex-col not-italic font-normal text-sm text-black w-4/6" },
            React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                React.createElement("textarea", { id: "quote-text", className: `appearance-none shadow-sm form-input w-full rounded block px-3 py-2 border ${quoteTextError
                        ? 'border-red-300 focus:shadow-outline-red focus:border-red-300'
                        : 'focus:outline-none focus:shadow-outline-blue focus:border-blue-300'} focus:z-10 sm:text-sm sm:leading-5 ${!editorQuote.text && 'italic'}`, value: editorQuote.text, onChange: e => handleQuoteTextInput(e.target.value), rows: 4, placeholder: "e.g. \u201CWe are asking for your support. Like many local businesses, we've been hit financially by this pandemic. We ask that you please consider donating to this tax-deductible program for coronavirus coverage.\u201D *", required: true, onFocus: () => setQuoteTextTouched(true) }),
                quoteTextError && (React.createElement("span", { className: "text-xs text-red-600 ml-1" }, "Please write a minimum of 50 characters."))),
            React.createElement("div", { className: "flex w-4/6 justify-between mt-2" },
                React.createElement("input", { id: "quote-teller-name", className: "form-input w-full block px-3 py-2 shadow-sm rounded-md sm:text-sm sm:leading-5 placeholder-gray-400", placeholder: "Name of source*", value: editorQuote.memberName, onChange: e => handleQuoteNameInput(e.target.value), style: { width: '47%' }, required: true }),
                React.createElement("input", { id: "quote-teller-title", className: "form-input w-full block px-3 py-2 shadow-sm rounded-md sm:text-sm sm:leading-5 placeholder-gray-400", placeholder: "Role of source*", value: editorQuote.title, onChange: e => handleQuoteTitleInput(e.target.value), style: { width: '47%' }, required: true })))));
    if (!donationProfile)
        return React.createElement(LoadingState, null);
    return (React.createElement(React.Fragment, null,
        showFormError && (React.createElement("div", { className: "mb-3 -mt-6" },
            React.createElement(FormError, { prefix: "Oops!", error: "Please resolve errors below and try publishing your site again." }))),
        React.createElement("form", { className: "bg-white sm:rounded-lg border border-gray-300 shadow mb-24", onSubmit: e => handleSubmit(e) },
            React.createElement(SettingsHeader, { header: "Campaign", description: "Create your public campaign site." },
                React.createElement("button", { type: "button", className: "px-3 bg-white box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => window.open('https://help.column.us/Donations-Campaign-Help-Center-21954c65cd164346b6d3d8dcc5e785cf') },
                    React.createElement("span", { className: "pr-2" },
                        React.createElement(InformationCircle, { className: "text-gray-750" })),
                    "Help")),
            React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800 w-2/6" }, "Newspaper name*"),
                React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                    React.createElement("input", { className: `appearance-none form-input shadow-sm rounded block w-4/6 px-3 py-2 border ${nameError
                            ? 'border-red-300 focus:shadow-outline-red focus:border-red-300'
                            : 'focus:outline-none focus:shadow-outline-blue focus:border-blue-300'} focus:z-10 sm:text-sm sm:leading-5`, value: name, onChange: e => setName(e.target.value), required: true }),
                    nameError && (React.createElement("span", { className: "text-xs text-red-600 ml-1" }, "Newspaper name should not be empty.")))),
            React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800 w-2/6" }, "Location of newspaper*"),
                React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                    React.createElement("input", { className: `appearance-none form-input shadow-sm rounded block w-4/6 px-3 py-2 border ${cityError
                            ? 'border-red-300 focus:shadow-outline-red focus:border-red-300'
                            : 'focus:outline-none focus:shadow-outline-blue focus:border-blue-300'} focus:z-10 sm:text-sm sm:leading-5`, value: city, onChange: e => setCity(e.target.value), required: true }),
                    cityError && (React.createElement("span", { className: "text-xs text-red-600 ml-1" }, "City of newspaper should not be empty.")))),
            React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
                React.createElement("div", { className: "flex flex-col w-2/6" },
                    React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "About your newspaper*"),
                    React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Write a few sentences about your newspaper and the work you do. This will appear in the header of your site.")),
                React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" },
                    React.createElement("textarea", { id: "about", className: `appearance-none form-input shadow-sm rounded block w-4/6 px-3 py-2 border ${aboutError
                            ? 'border-red-300 focus:shadow-outline-red focus:border-red-300'
                            : 'focus:outline-none focus:shadow-outline-blue focus:border-blue-300'} focus:z-10 sm:text-sm sm:leading-5 ${!about && 'italic'}`, value: about, onChange: e => handleAboutInput(e.target.value), rows: 5, required: true, onFocus: () => setAboutTouched(true), placeholder: `e.g. We at ${donationProfile.data().name} are working tirelessly to keep you informed during these challenging times. Giving you factual, up-to-date information about the effect COVID-19 is having on our lives and our communities is some of the most important work we've ever done.` }),
                    aboutError && (React.createElement("span", { className: "text-xs text-red-600 ml-1" }, "Please write a minimum of 50 characters.")))),
            React.createElement(SubdomainSection, Object.assign({}, {
                donationProfile,
                campaignDomain,
                setCampaignDomain,
                setShowSubdomainModal,
                domainError,
                setDomainError,
                activeOrganization
            })),
            renderLogoSection(),
            renderLinksSection(),
            renderColorSection(),
            React.createElement(PdfSection, Object.assign({}, { pdfUploadLoading, setPdfFile, pdfFile, orgPdfUrl, pdfError })),
            renderQuoteSection(),
            React.createElement(Footer, Object.assign({}, {
                donationProfile,
                setOpenTestModeMenu,
                openTestModeMenu,
                submitting
            })),
            showSubdomainModal && (React.createElement(RequestSubdomainModal, { close: () => setShowSubdomainModal(false), requestSubdomain: requestSubdomain, state: donationProfile.data().state })),
            toastText && (React.createElement(EToast, { message: toastText, type: "success", close: () => setToastText('') })))));
};
export default BrandingSettings;
