import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { ColumnButton } from 'components/ColumnButton';
import React from 'react';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
export const EditCustomerOrganizationModalSettings = ({ changedFields, setChangedFields, customerOrganizationData, activeOrganization, setRequireUpfrontPayment, requireUpfrontPaymentId, setRequireUpfrontPaymentId, allowBulkInvoicing, setAllowBulkInvoicing, onClose, onSave, disableSave }) => {
    var _a, _b, _c, _d;
    const DEFAULT_SETTING = 0;
    const ALWAYS_REQUIRE = 1;
    const NEVER_REQUIRE = 2;
    const REQUIRE_UPFRONT_PAYMENT_OPTIONS = [
        { id: DEFAULT_SETTING, description: 'Default' },
        { id: ALWAYS_REQUIRE, description: 'Always require' },
        { id: NEVER_REQUIRE, description: 'Never require' }
    ];
    return (React.createElement("div", { className: "pt-6 pb-8 flex justify-center" },
        React.createElement("div", { className: "w-2/3 bg-white border border-grey-100 rounded-md", style: { boxShadow: '0px 2px 24px rgba(18, 33, 46, 0.04' } },
            React.createElement("div", { className: "px-8" },
                React.createElement("div", { className: "pt-8 font-semibold text-lg text-gray-850" }, "Set up this organization's preferences."),
                React.createElement("div", { className: "pt-2 font-medium text-sm text-gray-650" }, "Configure how you want this organization to interact with your publication."),
                React.createElement("div", { className: "pt-10 font-medium text-sm leading-6" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "w-full inline-block" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("div", { className: "text-blue-800 pb-2" }, "Do you require upfront payment for this customer?"))),
                            React.createElement(SelectDropdown, { id: '', placeholder: "Require upfront payment?", onChange: (e) => {
                                    var _a, _b, _c;
                                    if (e.id === 0) {
                                        setRequireUpfrontPayment(null);
                                        setRequireUpfrontPaymentId(DEFAULT_SETTING);
                                        if (((_a = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.requireUpfrontPayment) === null) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: true }));
                                        }
                                    }
                                    else if (e.id === 1) {
                                        setRequireUpfrontPayment(true);
                                        setRequireUpfrontPaymentId(ALWAYS_REQUIRE);
                                        if (((_b = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _b === void 0 ? void 0 : _b.requireUpfrontPayment) === true) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: true }));
                                        }
                                    }
                                    else {
                                        setRequireUpfrontPayment(false);
                                        setRequireUpfrontPaymentId(NEVER_REQUIRE);
                                        if (((_c = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _c === void 0 ? void 0 : _c.requireUpfrontPayment) === false) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { requireUpfrontPayment: true }));
                                        }
                                    }
                                }, options: REQUIRE_UPFRONT_PAYMENT_OPTIONS.map(option => ({
                                    id: option.id,
                                    label: option.description
                                })), selected: {
                                    id: (_a = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.requireUpfrontPayment,
                                    label: ((_b = REQUIRE_UPFRONT_PAYMENT_OPTIONS.find(item => item.id === requireUpfrontPaymentId)) === null || _b === void 0 ? void 0 : _b.description) || ''
                                }, value: {
                                    id: requireUpfrontPaymentId,
                                    label: ((_c = REQUIRE_UPFRONT_PAYMENT_OPTIONS.find(item => item.id === requireUpfrontPaymentId)) === null || _c === void 0 ? void 0 : _c.description) || ''
                                }, disableAlphabetization: true, noShadow: true, dropdownHeight: 'large' }))),
                    React.createElement("div", { className: "flex items-center" }, ((_d = activeOrganization.data()) === null || _d === void 0 ? void 0 : _d.bulkPaymentEnabled) && (React.createElement("div", { className: "w-full pt-8 inline-block ", "data-testid": "bulkInvoicing" },
                        React.createElement("div", { className: "border border-grey-100 rounded-md" },
                            React.createElement("div", { className: "pt-4 pl-4 flex items-center" },
                                React.createElement(Switch, { checked: !!allowBulkInvoicing, onChange: () => {
                                        var _a;
                                        setAllowBulkInvoicing(!allowBulkInvoicing);
                                        if (!allowBulkInvoicing ===
                                            Boolean((_a = customerOrganizationData === null || customerOrganizationData === void 0 ? void 0 : customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.bulkPaymentEnabled)) {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { allowBulkInvoicing: false }));
                                        }
                                        else {
                                            setChangedFields(Object.assign(Object.assign({}, changedFields), { allowBulkInvoicing: true }));
                                        }
                                    }, className: classNames(allowBulkInvoicing ? 'bg-blue-900' : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                        width: '36px',
                                        height: '20px'
                                    } },
                                    React.createElement("span", { className: "sr-only" }, "Use setting"),
                                    React.createElement("span", { "aria-hidden": "true", className: classNames(allowBulkInvoicing
                                            ? 'translate-x-4'
                                            : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })),
                                React.createElement("div", { className: "text-blue-800 pl-3 font-medium text-sm leading-6 inline-block" }, "Allow bulk invoicing?")),
                            React.createElement("div", { className: "pt-2 pl-16 pr-4 pb-4 font-medium text-sm text-gray-650" }, "Toggle this on if you want to create one monthly invoice for this organization."))))),
                    React.createElement("div", { className: "flex justify-between items-center py-10" },
                        React.createElement(ColumnButton, { buttonText: "Cancel", size: "lg", onClick: onClose }),
                        React.createElement(ColumnButton, { primary: true, buttonText: "Save", size: "lg", onClick: () => {
                                void onSave();
                            }, disabled: disableSave })))))));
};
