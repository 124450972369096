var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import LoadingState from 'components/LoadingState';
import { BankAccountVerification, Country, OccupationType } from 'lib/enums';
import { loadStripe } from '@stripe/stripe-js';
import NumberFormat from 'react-number-format';
import api from 'api';
import TailwindModal from 'components/TailwindModal';
import EToast from 'components/EToast';
import { getUserPaymentMethods } from 'utils/sources';
import { isMemberOfOrganization } from 'lib/utils/users';
import { logAndCaptureException } from 'utils';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { AddIcon } from 'icons';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { STRIPE_VARS } from '../../constants';
import Plaid from './Plaid';
import SettingsHeader from './SettingsHeader';
import trash from './publisher/trash.svg';
import { AccountBalance, Check, Clock, ErrorOutline } from './icons';
import VerifyMicrodepositsModal from './VerifyMicrodepositsModal';
const DEFAULT_DETAILS = {
    routing_number: '',
    account_number: '',
    account_holder_name: '',
    account_holder_type: ''
};
const createCustomerForUser = (user) => __awaiter(void 0, void 0, void 0, function* () {
    const createCustomer = getCreateCustomerFn();
    return yield createCustomer({
        name: user.data().name,
        email: user.data().email,
        firestoreUID: user.id
    });
});
/**
 * We choose the stripe ID in the following preference order:
 * 1) From the user's active organization
 * 2) From the user's organization
 * 3) From the user as an individual.
 * 4) Create a new one for the user as an individual
 */
const getOrCreateStripeIdForUser = (user) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    let stripeId;
    if (isMemberOfOrganization(user)) {
        const orgRef = (_a = user.data().activeOrganization) !== null && _a !== void 0 ? _a : user.data().organization;
        const orgSnap = yield orgRef.get();
        stripeId = (_b = orgSnap.data()) === null || _b === void 0 ? void 0 : _b.stripeId;
    }
    else {
        let userStripeId = user.data().stripeId;
        if (!userStripeId) {
            const { data } = yield createCustomerForUser(user);
            userStripeId = data.stripeId;
        }
        stripeId = userStripeId;
    }
    return stripeId;
});
const CentsFormatter = props => {
    const { inputRef, onChange } = props, other = __rest(props, ["inputRef", "onChange"]);
    return (React.createElement(NumberFormat, Object.assign({}, other, { style: { cursor: 'default' }, getInputRef: inputRef, onChange: e => {
            let value = e.target.value.substr(0, 2);
            while (value.length < 2)
                value = value.concat('0');
            value = value.replace(' ', '0');
            onChange({
                target: {
                    value
                }
            });
        }, onFocus: e => {
            e.preventDefault();
            e.target.setSelectionRange(0, 0);
        }, format: "##" })));
};
const UserSettingsBankAccounts = ({ user }) => {
    var _a, _b, _c;
    // modals
    const [showVerifyOptionsModal, setShowVerifyOptionsModal] = useState();
    const [showAccountDetailsModal, setShowAccountDetailsModal] = useState();
    const [showAuthorizationModal, setShowAuthorizationModal] = useState();
    const [showAwaitingDepositsModal, setShowAwaitingDepositsModal] = useState();
    const [showUnlinkModal, setShowUnlinkModal] = useState();
    const [showUnlinkModalBankAccountPaymentMethod, setShowUnlinkModalBankAccountPaymentMethod] = useState();
    const [showUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits, setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits] = useState();
    const [bankAccountPaymentMethodStatuses, setBankAccountPaymentMethodStatuses] = useState([]);
    const [showUnlinkedToast, setShowUnlinkedToast] = useState();
    const [showVerifiedToast, setShowVerifiedToast] = useState();
    const [achAgreementForBankAccountPaymentmethod, setAchAgreementForBankAccountPaymentMethod] = useState();
    const [bankAccountClientSecretForAchAgreement, setBankAccountClientSecretForAchAgreement] = useState();
    const [showConfirmDepositsModal, setShowConfirmDepositsModal] = useState();
    const [showConfirmDepositsForBankAccountPaymentMethodModal, setShowConfirmDepositsForBankAccountPaymentMethodModal] = useState();
    const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState();
    // plaid details
    const [plaidData, setPlaidData] = useState();
    // bank account details form
    const [accountDetails, setAccountDetails] = useState(DEFAULT_DETAILS);
    // microdeposits
    const [microdeposits, setMicrodeposits] = useState(['00', '00']);
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [openPlaid, setOpenPlaid] = useState();
    const [stripeBankAccountsPaymentMethods, setStripeBankAccountsPaymentMethods] = useState([]);
    const [stripeBankAccounts, setStripeBankAccounts] = useState([]);
    const [bankAccountPaymentMethodsRequiringMicrodeposits, setBankAccountPaymentMethodsRequiringMicrodeposits] = useState([]);
    // See COREDEV-469
    const enableStripeUIBankAccountVerification = useBooleanFlag(LaunchDarklyFlags.ENABLE_STRIPE_UI_BANK_ACCOUNT_VERIFICATION);
    const handleDelete = (bankAccount) => __awaiter(void 0, void 0, void 0, function* () {
        yield withLoading(() => __awaiter(void 0, void 0, void 0, function* () {
            const stripeId = yield getOrCreateStripeIdForUser(user);
            if (!stripeId) {
                console.warn(`Could not locate stripe id for user ${user.id}`);
                setError("Sorry, there was an error deleting your bank account. Please contact help@column.us and we'll help you.");
                return;
            }
            const req = {
                stripeId,
                sourceId: bankAccount.id
            };
            yield api.post('payments/delete-source', req);
            // After deletion, re-fetch the user's bank accounts
            yield fetchBankAccounts();
        }));
        setShowUnlinkModal(undefined);
        setShowUnlinkedToast(true);
    });
    const handleDeleteBankAccountPaymentMethod = (bankAccount) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield api.post('payments/delete-payment-method', {
                bankAccount: bankAccount.id
            });
        }
        catch (err) {
            logAndCaptureException(err, 'Could not delete bank account payment method ', {
                bankAccount: bankAccount.id
            });
        }
        // After deletion, re-fetch the user's bank accounts
        yield fetchBankAccounts();
        setShowUnlinkModalBankAccountPaymentMethod(undefined);
        setShowUnlinkedToast(true);
    });
    const handleDeleteBankAccountPaymentMethodRequiringMicrodeposits = (bankAccount) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield api.post('payments/stripe-cancel-setup-intent', {
                bankAccount: bankAccount.id
            });
        }
        catch (err) {
            logAndCaptureException(err, 'Could not delete bank account payment method requiring microdeposits', {
                bankAccount: bankAccount.id
            });
        }
        // After deletion, re-fetch the user's bank accounts
        yield fetchBankAccounts();
        setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits(undefined);
        setShowUnlinkedToast(true);
    });
    const bg = {
        yellow: 'bg-yellow-350 bg-opacity-40',
        gray: 'bg-gray-200',
        red: 'bg-red-400',
        blue: 'bg-blue-500 bg-opacity-25'
    };
    const icons = {
        check: React.createElement(Check, null),
        waiting: React.createElement(Clock, null),
        failed: React.createElement(ErrorOutline, null)
    };
    const text = {
        grayDark: 'text-gray-900',
        gray: 'text-gray-800',
        white: 'text-white',
        blue: 'text-blue-600'
    };
    let statusText;
    let statusIcon;
    let chipColor = bg.gray;
    let textColor = text.gray;
    const renderStatus = (bankAccount) => {
        const statusValue = bankAccount.status;
        switch (statusValue) {
            case 'new':
                statusText = BankAccountVerification.microdepositsPending.label;
                statusIcon = null;
                chipColor = bg.gray;
                textColor = text.gray;
                break;
            case 'verification_failed':
            case 'errored':
                statusText = BankAccountVerification.actionRequired.label;
                statusIcon = icons.waiting;
                chipColor = bg.yellow;
                textColor = text.grayDark;
                break;
            case 'verified':
            case 'validated':
                statusText = BankAccountVerification.verified.label;
                statusIcon = icons.check;
                chipColor = bg.blue;
                textColor = text.blue;
                break;
            default:
                statusText = 'Unknown';
                statusIcon = icons.waiting;
                chipColor = bg.gray;
                textColor = text.gray;
                break;
        }
        return (React.createElement("span", { className: `inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}` },
            statusIcon && React.createElement("span", { className: "mr-2" }, statusIcon),
            statusText));
    };
    const retrieveStatusFromBankAccountPaymentMethod = (bankAccount) => __awaiter(void 0, void 0, void 0, function* () {
        const { status } = yield api.post('payments/get-bank-account-payment-method-status', {
            payment_method: bankAccount.id
        });
        return status;
    });
    const renderStatusBankAccountPaymentMethod = (statusValue) => {
        switch (statusValue) {
            case 'processing':
                statusText = BankAccountVerification.microdepositsPending.label;
                statusIcon = null;
                chipColor = bg.gray;
                textColor = text.gray;
                break;
            case 'requires_action':
            case 'canceled':
                statusText = BankAccountVerification.actionRequired.label;
                statusIcon = icons.waiting;
                chipColor = bg.yellow;
                textColor = text.grayDark;
                break;
            case 'succeeded':
                statusText = BankAccountVerification.verified.label;
                statusIcon = icons.check;
                chipColor = bg.blue;
                textColor = text.blue;
                break;
            default:
                statusText = 'Unknown';
                statusIcon = icons.waiting;
                chipColor = bg.gray;
                textColor = text.gray;
                break;
        }
        return (React.createElement("span", { className: `inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}` },
            statusIcon && React.createElement("span", { className: "mr-2" }, statusIcon),
            statusText));
    };
    const renderStatusBankAccountPaymentMethodRequiringMicrodeposits = () => {
        statusText = BankAccountVerification.microdepositsPending.label;
        statusIcon = null;
        chipColor = bg.gray;
        textColor = text.gray;
        return (React.createElement("span", { className: `inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}` },
            statusIcon && React.createElement("span", { className: "mr-2" }, statusIcon),
            statusText));
    };
    const addVerifiedBankAccount = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!user.data().stripeId && !isMemberOfOrganization(user)) {
            yield createCustomerForUser(user);
        }
        const stripeId = yield getOrCreateStripeIdForUser(user);
        if (!stripeId || !plaidData) {
            if (!stripeId) {
                console.warn(`Could not locate stripe id for user ${user.id}`);
            }
            else {
                console.warn(`Plaid data is undefined`);
            }
            return {
                error: "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
            };
        }
        const req = Object.assign(Object.assign({}, plaidData), { stripeId });
        const { success, error } = yield api.post('payments/create-verified-account', req);
        if (success)
            return {};
        if (error)
            return { error };
        return {};
    });
    const handleAccountDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e, _f;
        const stripePromise = loadStripe(STRIPE_VARS.key);
        const stripe = yield stripePromise;
        if (!stripe) {
            throw new Error('stripe undefined');
        }
        const response = yield (stripe === null || stripe === void 0 ? void 0 : stripe.createToken('bank_account', Object.assign({ country: (_e = (_d = Country.by_value(user.data().country)) === null || _d === void 0 ? void 0 : _d.iso) !== null && _e !== void 0 ? _e : Country.USA.iso, currency: user.data().currency || 'usd' }, accountDetails)));
        if (response && response.error)
            return { error: response.error.message };
        const stripeId = yield getOrCreateStripeIdForUser(user);
        // This should never happen because all orgs should have stripe IDs, but better safe than sorry.
        if (!stripeId) {
            console.warn(`Could not locate stripe id for user ${user.id}`);
            return {
                error: "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
            };
        }
        if (response && ((_f = response.token) === null || _f === void 0 ? void 0 : _f.id)) {
            const req = {
                stripeId,
                token: response.token.id
            };
            const resp = yield api.post('payments/create-bank-account', req);
            if (resp.success) {
                return {};
            }
            if (resp.error) {
                let error;
                if (resp.error.code === 'bank_account_exists')
                    error = `A bank account with that routing number and account number already exists${user.data().occupation !== OccupationType.individual.value
                        ? ' within your organization'
                        : ''}`;
                else
                    error = 'Something went wrong!';
                return { error };
            }
        }
        return {
            error: "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
        };
    });
    const handleAccountAddedViaStripeUIFlow = () => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h;
        const stripePromise = loadStripe(STRIPE_VARS.key);
        const stripe = yield stripePromise;
        const customer = yield getOrCreateStripeIdForUser(user);
        if (!stripe) {
            throw new Error('stripe undefined');
        }
        const confirmBankAccount = yield stripe.confirmUsBankAccountSetup(bankAccountClientSecretForAchAgreement || '');
        setLoading(false);
        setShowAuthorizationModal(false);
        if (((_g = confirmBankAccount.setupIntent) === null || _g === void 0 ? void 0 : _g.status) === 'succeeded') {
            yield api.post('payments/attach-payment-method-to-customer', {
                payment_method: (_h = confirmBankAccount.setupIntent) === null || _h === void 0 ? void 0 : _h.payment_method,
                customer
            });
            setShowVerifiedToast(true);
        }
        else {
            yield api.get('payments/bank-account-payment-methods-that-require-microdeposits', {
                customer
            });
            setShowAwaitingDepositsModal(true);
        }
    });
    const clearData = () => {
        setAccountDetails(DEFAULT_DETAILS);
        setPlaidData(undefined);
        setMicrodeposits(['00', '00']);
        setError('');
    };
    /**
     * Get the user's bank accounts from Stripe.
     */
    const fetchBankAccounts = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const customer = yield getOrCreateStripeIdForUser(user);
            if (enableStripeUIBankAccountVerification) {
                const { bankAccounts, bankAccountsPaymentMethods } = yield getUserPaymentMethods(user);
                try {
                    const { bankAccountPaymentMethodsRequiringMicrodeposits } = yield api.get('payments/bank-account-payment-methods-that-require-microdeposits', {
                        customer
                    });
                    setBankAccountPaymentMethodsRequiringMicrodeposits(bankAccountPaymentMethodsRequiringMicrodeposits || []);
                }
                catch (err) {
                    logAndCaptureException(err, 'Could not pull bank account payment methods that require microdeposits for customer ', {
                        customer
                    });
                }
                setStripeBankAccountsPaymentMethods(bankAccountsPaymentMethods || []);
                if (bankAccountsPaymentMethods.length > 0) {
                    const statuses = yield Promise.all(bankAccountsPaymentMethods.map(bankAccount => {
                        return retrieveStatusFromBankAccountPaymentMethod(bankAccount);
                    }));
                    setBankAccountPaymentMethodStatuses(statuses);
                }
                setStripeBankAccounts(bankAccounts || []);
            }
            else {
                const { bankAccounts } = yield getUserPaymentMethods(user);
                setStripeBankAccounts(bankAccounts || []);
            }
        }
        catch (e) {
            console.warn('Failed to get user bank accounts', e);
            setStripeBankAccountsPaymentMethods([]);
            setStripeBankAccounts([]);
            setBankAccountPaymentMethodsRequiringMicrodeposits([]);
        }
    });
    const launchFinancialConnectionsSession = () => __awaiter(void 0, void 0, void 0, function* () {
        var _j, _k;
        try {
            const customer = yield getOrCreateStripeIdForUser(user);
            const { clientSecret } = yield api.post('payments/stripe-create-setup-intent', {
                customer
            });
            const stripePromise = loadStripe(STRIPE_VARS.key);
            const stripe = yield stripePromise;
            if (!stripe) {
                throw new Error('stripe undefined');
            }
            const { name, email } = yield api.get('payments/stripe-customer-details', {
                customer
            });
            const result = yield stripe.collectBankAccountForSetup({
                clientSecret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name,
                            email
                        }
                    }
                },
                expand: ['payment_method']
            });
            if (((_j = result.setupIntent) === null || _j === void 0 ? void 0 : _j.status) !== 'requires_payment_method') {
                setAchAgreementForBankAccountPaymentMethod(true);
                setBankAccountClientSecretForAchAgreement(((_k = result.setupIntent) === null || _k === void 0 ? void 0 : _k.client_secret) || '');
                setShowAuthorizationModal(true);
            }
        }
        catch (e) {
            logAndCaptureException(e, 'An error occurred when going through UI flow to add bank account for user ', {
                user: user.id
            });
        }
    });
    /**
     * Helper to set loading state while a promise runs.
     */
    function withLoading(fn) {
        setLoading(true);
        return fn().finally(() => setLoading(false));
    }
    useEffect(() => {
        if (showUnlinkedToast || showVerifiedToast) {
            const timer = setTimeout(() => {
                setShowVerifiedToast(false);
                setShowUnlinkedToast(false);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [showUnlinkedToast, showVerifiedToast]);
    useEffect(() => {
        if (!showConfirmDepositsModal ||
            !showConfirmDepositsForBankAccountPaymentMethodModal) {
            setMicrodeposits(['00', '00']);
            setError('');
        }
    }, [
        showConfirmDepositsModal === null || showConfirmDepositsModal === void 0 ? void 0 : showConfirmDepositsModal.id,
        showConfirmDepositsForBankAccountPaymentMethodModal === null || showConfirmDepositsForBankAccountPaymentMethodModal === void 0 ? void 0 : showConfirmDepositsForBankAccountPaymentMethodModal.id
    ]);
    useEffect(() => {
        if (!showAuthorizationModal) {
            setPlaidData(undefined);
        }
    }, [showAuthorizationModal]);
    useEffect(() => {
        void withLoading(() => fetchBankAccounts());
    }, [user.id, (_a = user.data().activeOrganization) === null || _a === void 0 ? void 0 : _a.id]);
    if (!user)
        return React.createElement(LoadingState, null);
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Bank Accounts", description: "Link your bank for direct payments." },
            React.createElement("button", { id: "invite", type: "button", className: "px-3 bg-white box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => {
                    if (enableStripeUIBankAccountVerification) {
                        void launchFinancialConnectionsSession();
                    }
                    else {
                        setShowVerifyOptionsModal(true);
                    }
                } },
                React.createElement("span", { className: "pr-2" },
                    React.createElement(AddIcon, { className: "h-5 w-5" })),
                "Add bank account")),
        React.createElement(React.Fragment, null,
            React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, ' '),
                        React.createElement("th", { className: "pl-2 pr-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "account number"),
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "account name"),
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "status"),
                        React.createElement("th", { className: "px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "actions"))),
                !loading && (React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" },
                    stripeBankAccounts &&
                        stripeBankAccounts.map((bankAccount, index) => {
                            return (React.createElement("tr", { key: index, onClick: () => setShowConfirmDepositsModal(bankAccount), className: `
                          hover:bg-gray-100 cursor-pointer` },
                                React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                    React.createElement("div", { className: "flex items-center justify-center bg-yellow-250 w-10 h-10 rounded-circle" },
                                        React.createElement(AccountBalance, null))),
                                React.createElement("td", { className: "pl-2 pr-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, `**** **** ${bankAccount.last4}`),
                                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, bankAccount.bank_name || 'N/A'),
                                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                    React.createElement(React.Fragment, null, renderStatus(bankAccount))),
                                React.createElement("td", { className: "flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900" },
                                    React.createElement("div", { onClick: e => {
                                            e.stopPropagation();
                                            setShowUnlinkModal(bankAccount);
                                        }, className: "cursor-pointer ml-4", id: `delete-${index}` },
                                        React.createElement("img", { src: trash, alt: "trash" })))));
                        }),
                    enableStripeUIBankAccountVerification &&
                        stripeBankAccountsPaymentMethods &&
                        stripeBankAccountsPaymentMethods.map((bankAccount, index) => {
                            var _a, _b;
                            return (React.createElement("tr", { key: index, className: `
                          hover:bg-gray-100 cursor-pointer` },
                                React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                    React.createElement("div", { className: "flex items-center justify-center bg-yellow-250 w-10 h-10 rounded-circle" },
                                        React.createElement(AccountBalance, null))),
                                React.createElement("td", { className: "pl-2 pr-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, `**** **** ${((_a = bankAccount.us_bank_account) === null || _a === void 0 ? void 0 : _a.last4) || 'n/a'}`),
                                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, ((_b = bankAccount.us_bank_account) === null || _b === void 0 ? void 0 : _b.bank_name) || 'N/A'),
                                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, renderStatusBankAccountPaymentMethod(bankAccountPaymentMethodStatuses[index])),
                                React.createElement("td", { className: "flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900" },
                                    React.createElement("div", { onClick: e => {
                                            e.stopPropagation();
                                            setShowUnlinkModalBankAccountPaymentMethod(bankAccount);
                                        }, className: "cursor-pointer ml-4", id: `delete-${index}` },
                                        React.createElement("img", { src: trash, alt: "trash" })))));
                        }),
                    enableStripeUIBankAccountVerification &&
                        bankAccountPaymentMethodsRequiringMicrodeposits &&
                        bankAccountPaymentMethodsRequiringMicrodeposits.map((bankAccount, index) => {
                            var _a, _b;
                            return (React.createElement("tr", { key: index, onClick: () => setShowConfirmDepositsForBankAccountPaymentMethodModal(bankAccount), className: `
                          hover:bg-gray-100 cursor-pointer` },
                                React.createElement("td", { className: "pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                    React.createElement("div", { className: "flex items-center justify-center bg-yellow-250 w-10 h-10 rounded-circle" },
                                        React.createElement(AccountBalance, null))),
                                React.createElement("td", { className: "pl-2 pr-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, `**** **** ${((_a = bankAccount.us_bank_account) === null || _a === void 0 ? void 0 : _a.last4) || 'n/a'}`),
                                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, ((_b = bankAccount.us_bank_account) === null || _b === void 0 ? void 0 : _b.bank_name) || 'N/A'),
                                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, renderStatusBankAccountPaymentMethodRequiringMicrodeposits()),
                                React.createElement("td", { className: "flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900" },
                                    React.createElement("div", { onClick: e => {
                                            e.stopPropagation();
                                            setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits(bankAccount);
                                        }, className: "cursor-pointer ml-4", id: `delete-${index}` },
                                        React.createElement("img", { src: trash, alt: "trash" })))));
                        })))),
            !stripeBankAccounts &&
                !stripeBankAccountsPaymentMethods &&
                !loading && (React.createElement("div", { className: "h-12 flex items-center justify-center bg-white rounded-b" }, "No records to display")),
            loading && (React.createElement("div", { className: "h-12 flex items-center justify-center bg-white rounded-b" },
                React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })))),
        showVerifyOptionsModal && (React.createElement(TailwindModal, { header: 'Verify your bank account', close: () => setShowVerifyOptionsModal(false), noExitOutsideModal: true, widthPct: 30 },
            React.createElement("div", { className: "flex flex-col mr-4" },
                React.createElement("div", { className: "font-normal text-sm text-gray-700 mb-6" }, "Upon verifying your bank account, you will be able to complete future payments in one click."),
                React.createElement("button", { className: `flex justify-center w-100 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm items-center py-2`, type: "button", onClick: () => {
                        setOpenPlaid(true);
                        setShowVerifyOptionsModal(false);
                    } },
                    "Verify by secure bank log in ",
                    React.createElement("br", null),
                    " (instantaneous)"),
                React.createElement(React.Fragment, null,
                    ((_b = user === null || user === void 0 ? void 0 : user.data()) === null || _b === void 0 ? void 0 : _b.bankAccountsEnabled) && (React.createElement("button", { className: `flex justify-center mt-2 mb-4 w-100 rounded-md font-semibold border border-blue-500 text-blue-600 text-sm items-center py-2`, type: "button", onClick: () => {
                            setShowVerifyOptionsModal(false);
                            setShowAccountDetailsModal(true);
                        }, disabled: !user.data().bankAccountsEnabled },
                        'Verify by routing & account number',
                        React.createElement("br", null),
                        " ",
                        '(3-5 business days)')),
                    ((_c = user === null || user === void 0 ? void 0 : user.data()) === null || _c === void 0 ? void 0 : _c.bankAccountsEnabled) ? (React.createElement("p", { className: "text-xs mt-1 text-blue-600 text-right hover:underline" },
                        React.createElement("a", { href: "https://help.column.us/article/27-how-do-micro-deposits-work", target: "_blank", rel: "noopener noreferrer" }, "Why 3-5 business days?"))) : (React.createElement("p", { className: "text-xs mt-1 text-blue-600 text-right hover:underline" }, "Contact help@column.us to enable verification via account and routing number.")))))),
        showAuthorizationModal && (React.createElement(TailwindModal, { header: "ACH authorization agreement", close: () => setShowAuthorizationModal(false), noExitOutsideModal: true, widthPct: 30 },
            React.createElement("div", { className: "mb-1 mt-2" },
                React.createElement("div", { className: "bg-gray-300 bg-opacity-50" },
                    React.createElement("div", { className: "p-4 text-xs mb-1" },
                        "I hereby authorize and request Column, PBC. to debit funds from my account at the Financial Institution indicated, and credit the funds. I authorize Column, PBC. to take any and all action required to correct any errors.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        " By clicking the button below, I certify that the information I have given on this ACH Debit Authorization Agreement for Direct Payments is complete, true, and submitted for the purpose selected above. It will remain in effect until I notify Column, PBC. of its cancellation by contacting help@column.us or by unlinking the bank account in my Column settings page.")),
                React.createElement("div", { className: "flex items-center mb-4 mt-3" },
                    React.createElement("input", { id: "agreeToTerms", type: "checkbox", className: "form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out", checked: true }),
                    React.createElement("div", { className: "ml-2 block text-sm leading-4 text-gray-900" }, "I agree to the authorization terms above.")),
                React.createElement("button", { className: `rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, id: "confirm-authorization", type: "button", disabled: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setLoading(true);
                        if (enableStripeUIBankAccountVerification &&
                            achAgreementForBankAccountPaymentmethod) {
                            yield handleAccountAddedViaStripeUIFlow();
                        }
                        else {
                            const result = plaidData
                                ? yield addVerifiedBankAccount()
                                : yield handleAccountDetails();
                            setLoading(false);
                            setShowAuthorizationModal(false);
                            if (result.error) {
                                // TODO handle errors for plaid flow
                                if (plaidData) {
                                    setShowAlreadyExistsModal(result.error);
                                }
                                else {
                                    setError(result.error);
                                    setShowAccountDetailsModal(true);
                                }
                            }
                            else {
                                clearData();
                                if (plaidData) {
                                    setShowVerifiedToast(true);
                                }
                                else {
                                    setShowAwaitingDepositsModal(true);
                                }
                            }
                        }
                        // Re-load the user's bank accounts after the plaid flow
                        yield withLoading(() => fetchBankAccounts());
                    }) },
                    React.createElement("span", { className: "flex" },
                        loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                        "Confirm"))))),
        showAccountDetailsModal && (React.createElement(TailwindModal, { header: "Verify your bank account", close: () => {
                setShowAccountDetailsModal(false);
                clearData();
            }, noExitOutsideModal: true, widthPct: 30 },
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    if (accountDetails.routing_number.length !== 9) {
                        setError('Routing number must have 9 digits');
                        return;
                    }
                    setShowAccountDetailsModal(false);
                    setShowAuthorizationModal(true);
                } },
                React.createElement("div", { className: "text-gray-700 mb-4 text-sm -mt-3 ml-1" }, "Enter details below to link your bank account."),
                React.createElement("input", { id: "holder-name", className: "appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", type: "text", placeholder: "Account Holder Name *", required: true, value: accountDetails.account_holder_name, onChange: e => setAccountDetails(Object.assign(Object.assign({}, accountDetails), { account_holder_name: e.target.value })) }),
                React.createElement("input", { id: "account-number", className: "appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", type: "text", placeholder: "Account Number *", required: true, value: accountDetails.account_number, onChange: e => setAccountDetails(Object.assign(Object.assign({}, accountDetails), { account_number: e.target.value })) }),
                React.createElement("input", { id: "routing-number", className: "appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", type: "text", placeholder: "Routing Number *", required: true, value: accountDetails.routing_number, onChange: e => setAccountDetails(Object.assign(Object.assign({}, accountDetails), { routing_number: e.target.value })) }),
                React.createElement("select", { id: "account-type", className: "form-select appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", placeholder: "Account Type *", required: true, value: accountDetails.account_holder_type, onChange: e => setAccountDetails(Object.assign(Object.assign({}, accountDetails), { account_holder_type: e.target.value })) },
                    React.createElement("option", { value: "", hidden: true, disabled: true }, "Account Holder Type"),
                    React.createElement("option", { value: "individual" }, "Individual"),
                    React.createElement("option", { value: "company" }, "Company")),
                React.createElement("div", { className: "text-gray-500 mb-5 text-xs" }, "Once you link your account, you\u2019ll be able to pay with this account in one click for future invoices."),
                React.createElement("button", { className: `rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, type: "submit", disabled: loading },
                    React.createElement("span", { className: "flex" },
                        loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                        "Begin verification")),
                error && React.createElement("div", { className: "text-red-700 text-sm" }, error)))),
        showAwaitingDepositsModal && (React.createElement(TailwindModal, { header: "Awaiting microdeposits", body: "You will receive two microdeposits in your bank account in the next couple of days.", close: () => setShowAwaitingDepositsModal(false), buttonText: "Go back to bank account settings", onButtonClick: () => setShowAwaitingDepositsModal(false), noExitOutsideModal: true })),
        showConfirmDepositsModal && (React.createElement(TailwindModal, { header: "Confirm Microdeposits", close: () => {
                setShowConfirmDepositsModal(undefined);
                clearData();
            }, noExitOutsideModal: true },
            React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    e.preventDefault();
                    setError('');
                    yield withLoading(() => __awaiter(void 0, void 0, void 0, function* () {
                        const source = showConfirmDepositsModal;
                        const sourceId = source.id;
                        const userStripeId = yield getOrCreateStripeIdForUser(user);
                        if (!userStripeId) {
                            console.warn(`Could not locate stripe id for user ${user.id}`);
                            setError("Sorry, there was an error verifying your bank account. Please contact help@column.us and we'll help you.");
                            return;
                        }
                        const req = {
                            userStripeId,
                            sourceId,
                            amounts: microdeposits.map(deposit => Number(deposit))
                        };
                        const { error } = yield api.post('payments/verify-bank-account', req);
                        if (error) {
                            setError(error.message);
                        }
                        else {
                            setShowConfirmDepositsModal(undefined);
                            setShowVerifiedToast(true);
                        }
                        yield fetchBankAccounts();
                    }));
                }) },
                enableStripeUIBankAccountVerification && (React.createElement(VerifyMicrodepositsModal, { onVerifyClicked: (e) => setMicrodeposits(e), CentsFormatter: CentsFormatter, loading: loading })),
                !enableStripeUIBankAccountVerification && (React.createElement("div", null,
                    React.createElement("div", { className: "mb-3 text-sm" }, "Two microdeposits should appear in your account within 1-2 business days of initial authorization. Please verify those here."),
                    React.createElement("div", { className: "flex justify-between mb-2" },
                        React.createElement(TextField, { id: "deposit-1", style: {
                                fontSize: '16px',
                                lineHeight: '18px',
                                color: '#4A5568',
                                width: '48%'
                            }, value: microdeposits[0], onChange: e => setMicrodeposits([e.target.value, microdeposits[1]]), InputProps: {
                                inputComponent: CentsFormatter,
                                startAdornment: (React.createElement(InputAdornment, { position: "start", style: {
                                        fontSize: '16px',
                                        lineHeight: '18px',
                                        marginTop: -1,
                                        marginRight: -0.5
                                    } },
                                    React.createElement(Typography, { style: { color: 'black' } }, "$0.")))
                            }, required: true, variant: "outlined", margin: "dense" }),
                        React.createElement(TextField, { id: "deposit-2", style: {
                                fontSize: '16px',
                                lineHeight: '18px',
                                color: '#4A5568',
                                width: '48%'
                            }, value: microdeposits[1], onChange: e => setMicrodeposits([microdeposits[0], e.target.value]), InputProps: {
                                inputComponent: CentsFormatter,
                                startAdornment: (React.createElement(InputAdornment, { position: "start", style: {
                                        fontSize: '16px',
                                        lineHeight: '18px',
                                        marginTop: -1,
                                        marginRight: -0.5
                                    } },
                                    React.createElement(Typography, { style: { color: 'black' } }, "$0.")))
                            }, required: true, variant: "outlined", margin: "dense" })),
                    React.createElement("button", { className: `rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, type: "submit", id: "verify-micro-deposits", disabled: loading },
                        React.createElement("span", { className: "flex" },
                            loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                            "Verify")))),
                error && React.createElement("div", { className: "text-red-700 text-sm" }, error)))),
        enableStripeUIBankAccountVerification &&
            showConfirmDepositsForBankAccountPaymentMethodModal && (React.createElement(TailwindModal, { header: "Confirm Microdeposits", close: () => {
                setShowConfirmDepositsForBankAccountPaymentMethodModal(undefined);
                clearData();
            }, noExitOutsideModal: true },
            React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    e.preventDefault();
                    setError('');
                    yield withLoading(() => __awaiter(void 0, void 0, void 0, function* () {
                        const stripePromise = loadStripe(STRIPE_VARS.key);
                        const stripe = yield stripePromise;
                        if (!stripe) {
                            throw new Error('stripe undefined');
                        }
                        const source = showConfirmDepositsForBankAccountPaymentMethodModal;
                        const payment_method = source.id;
                        const userStripeId = yield getOrCreateStripeIdForUser(user);
                        if (!userStripeId) {
                            console.warn(`Could not locate stripe id for user ${user.id}`);
                            setError("Sorry, there was an error verifying your bank account. Please contact help@column.us and we'll help you.");
                            return;
                        }
                        try {
                            const { setupIntentClientSecret } = yield api.get('payments/client-secret-for-setup-intent', {
                                payment_method
                            });
                            yield (stripe === null || stripe === void 0 ? void 0 : stripe.verifyMicrodepositsForSetup(setupIntentClientSecret, {
                                amounts: microdeposits.map(deposit => Number(deposit))
                            }).then(result => {
                                if (result.error) {
                                    setError(result.error.message);
                                }
                                else {
                                    setShowConfirmDepositsForBankAccountPaymentMethodModal(undefined);
                                    setShowVerifiedToast(true);
                                }
                            }));
                        }
                        catch (err) {
                            logAndCaptureException(err, 'Could not verify microdeposits for stripe user id ', {
                                userStripeId
                            });
                        }
                        yield fetchBankAccounts();
                    }));
                }) },
                React.createElement(VerifyMicrodepositsModal, { onVerifyClicked: (e) => setMicrodeposits(e), CentsFormatter: CentsFormatter, loading: loading }),
                error && React.createElement("div", { className: "text-red-700 text-sm" }, error)))),
        showUnlinkModal && (React.createElement(TailwindModal, { header: "Unlink bank account?", body: "This bank account will be deleted from your saved payment methods on Column.", close: () => setShowUnlinkModal(undefined), buttonText: "Unlink", onButtonClick: () => handleDelete(showUnlinkModal), loading: loading, noExitOutsideModal: true })),
        enableStripeUIBankAccountVerification &&
            showUnlinkModalBankAccountPaymentMethod && (React.createElement(TailwindModal, { header: "Unlink bank account?", body: "This bank account will be deleted from your saved payment methods on Column.", close: () => setShowUnlinkModalBankAccountPaymentMethod(undefined), buttonText: "Unlink", onButtonClick: () => handleDeleteBankAccountPaymentMethod(showUnlinkModalBankAccountPaymentMethod), loading: loading, noExitOutsideModal: true })),
        enableStripeUIBankAccountVerification &&
            showUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits && (React.createElement(TailwindModal, { header: "Unlink bank account?", body: "This bank account will be deleted from your saved payment methods on Column.", close: () => setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits(undefined), buttonText: "Unlink", onButtonClick: () => handleDeleteBankAccountPaymentMethodRequiringMicrodeposits(showUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits), loading: loading, noExitOutsideModal: true })),
        showUnlinkedToast && (React.createElement(EToast, { message: "Bank account unlinked.", type: "success", close: () => setShowUnlinkedToast(false) })),
        showVerifiedToast && (React.createElement(EToast, { message: "Successfully verified! You can now pay for your notices with the click of a button using this payment method.", type: "success", close: () => setShowVerifiedToast(false) })),
        showAlreadyExistsModal && (React.createElement(TailwindModal, { header: "Bank Account Already Exists", body: showAlreadyExistsModal, close: () => setShowAlreadyExistsModal(''), buttonText: "Back to Settings", onButtonClick: () => setShowAlreadyExistsModal(''), noExitOutsideModal: true })),
        openPlaid && (React.createElement(Plaid, { user: user, setOpen: setOpenPlaid, setShowAuthorizationModal: setShowAuthorizationModal, setShowVerifyOptionsModal: setShowVerifyOptionsModal, setPlaidData: setPlaidData }))));
};
export default UserSettingsBankAccounts;
