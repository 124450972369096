import React from 'react';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
/**
 * A switch component for use in forms in rates settings.
 */
export default function FormSwitch({ checked, onChange, disabled }) {
    return (React.createElement(Switch, { checked: checked, onChange: onChange, className: classNames('cursor-pointer relative inline-flex h-5 w-9 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2', disabled ? 'cursor-not-allowed' : 'cursor-pointer', checked ? 'bg-primary-600' : 'bg-gray-200') },
        React.createElement("span", { className: "sr-only" }, "Enable"),
        React.createElement("span", { "aria-hidden": "true", className: classNames(checked ? 'translate-x-4' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })));
}
