var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, Typography, Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { history } from 'redux/router';
import { OrganizationType, RoleType, State } from 'lib/enums';
import { getLocationParams } from 'utils/urls';
import { exists } from 'lib/types';
import AccountVerificationOptionsModal from 'routes/settings/AccountVerificationOptionsModal';
import { getFirebaseContext } from 'utils/firebase';
import { DEFAULT_TEMPLATE_ID } from 'lib/constants';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import GetDefaultDeadlines from './GetDefaultDeadlines';
import { BasicDetailsStep, AdvertisingDeadlinesStep } from './RegisterOrganizationNewspaperSteps';
import Firebase, { Collections, Firestore } from '../../../EnoticeFirebase';
import { BROWSER, BROWSERS, ENOTICE_ACCOUNT_ID } from '../../../constants';
import DwollaCustomerModal from '../../settings/publisher/DwollaCustomerModal';
import { CheckSettings } from '../../settings/publisher/PaymentSettings';
const styles = (theme) => createStyles({
    stepper: {
        width: BROWSER === BROWSERS.ie ? '90%' : '100%'
    },
    buttonContainer: {
        marginBottom: theme.spacing(2)
    },
    error: {
        marginTop: theme.spacing(1)
    },
    progress: {
        marginTop: theme.spacing(2)
    },
    spinner: {
        marginRight: theme.spacing(1),
        color: 'white'
    }
});
const BASIC_INFO_STEP = 0;
const DEADLINES_STEP = 1;
const Stepper = ({ step }) => {
    return (React.createElement("div", { className: "hidden stepper stepper-text sm:hidden md:flex lg:flex xl:flex flex-row h-10 w-full justify-evenly bg-white overflow-hidden shadow rounded-lg mt-7", style: { whiteSpace: 'nowrap', textOverflow: 'ellipsis' } },
        React.createElement("div", { className: `font-semibold self-center stepper-text opacity-50 text-gray-600 ${step >= 0 && 'opacity-75 text-gray-800'}` }, "01 - Newspaper Info"),
        React.createElement("svg", { className: "h-10 self-center", width: "16", height: "55", viewBox: "0 0 16 55", fill: "none" },
            React.createElement("path", { d: "M0.999999 54L15 27.5L0.999999 1", stroke: "#D2D6DC" })),
        React.createElement("div", { className: `font-semibold self-center stepper-text opacity-50 text-gray-600 ${step >= 1 && 'opacity-75 text-gray-800'}` }, "02 - Publication Dates")));
};
const RegisterOrganizationNewspaper = ({ userAuth, classes, user, organization, authActions, push }) => {
    var _a, _b;
    const getStep = () => {
        const locationParmStep = getLocationParams().get('step');
        if (locationParmStep) {
            return parseInt(locationParmStep, 10);
        }
        return BASIC_INFO_STEP;
    };
    const [step, setStep] = useState(getStep());
    const [isEditing, setIsEditing] = useState(false);
    const [orgToUpdate, setOrgToUpdate] = useState();
    const [activeOrganization, setActiveOrganization] = useState();
    // Step One
    const [name, setName] = useState('');
    const [circulationSize, setCirculationSize] = useState(0);
    const [county, setCounty] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [stateError, setStateError] = useState('');
    const [timezone, setTimezone] = useState('');
    // Advertising deadlines step
    const [deadlines, setDeadlines] = useState(GetDefaultDeadlines());
    const [advertiserEditingEnabled, setAdvertiserEditingEnabled] = useState(true);
    // Step Two
    const [pricing] = useState(1);
    const [pricePerInch] = useState('');
    const [roundOff] = useState({ value: 0 });
    const [minInches] = useState('');
    const [errorMessage, setError] = useState('');
    const [loading, setLoading] = useState(false);
    // for dwolla
    const [openPlaid, setOpenPlaid] = useState(false);
    const [showDwollaCustomerModal, setShowDwollaCustomerModal] = useState(false);
    const [addBankVerifyOptions, setAddBankVerifyOptions] = useState(false);
    const plaidUser = user;
    const readyForStripe = getLocationParams().get('code');
    useEffect(() => {
        const fillBasicInfo = () => __awaiter(void 0, void 0, void 0, function* () {
            const querySnapshot = yield getFirebaseContext()
                .organizationsRef()
                .where('organizationType', '==', OrganizationType.newspaper.value)
                .where('createdBy', '==', userAuth.uid)
                .get();
            if (!querySnapshot.empty &&
                querySnapshot.docs.filter(doc => doc.data().accountId === undefined)
                    .length) {
                setIsEditing(true);
                const orgSnap = querySnapshot.docs.find(doc => doc.data().accountId === undefined);
                if (!exists(orgSnap)) {
                    setOrgToUpdate(undefined);
                    return;
                }
                setOrgToUpdate(orgSnap);
                const { name, address, city, phone, circulationSize, county, iana_timezone, zipCode, deadlines } = orgSnap.data();
                setName(name);
                setAddress(address);
                setCity(city);
                setCirculationSize(circulationSize || 0);
                setCounty(county);
                setPhone(phone);
                setTimezone(iana_timezone);
                setZipCode(zipCode);
                setDeadlines(deadlines);
            }
        });
        if (user) {
            void fillBasicInfo();
        }
    }, [user]);
    useEffect(() => {
        const fetchActiveOrg = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const org = yield ((_a = user.activeOrganization) === null || _a === void 0 ? void 0 : _a.get());
            if (exists(org)) {
                setActiveOrganization(org);
            }
            else {
                setActiveOrganization(undefined);
            }
        });
        if (user && user.activeOrganization) {
            void fetchActiveOrg();
        }
    }, [user]);
    if (readyForStripe || loading) {
        return (React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justify: "center", spacing: 2, className: classes.progress },
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })),
            !organization ? (React.createElement(Typography, { align: "center" }, "Creating your organization...")) : (React.createElement(React.Fragment, null, organization && (React.createElement(Grid, { item: true },
                React.createElement(Typography, { align: "center" }, "Do not close this page. Finalizing your account...")))))));
    }
    const updateOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!orgToUpdate)
            return;
        if (!((_c = State.by_value(Number(state))) === null || _c === void 0 ? void 0 : _c.run_donations_by_default)) {
            yield orgToUpdate.ref.update({
                name,
                circulationSize,
                county,
                address,
                city,
                state: parseInt(state, 10),
                zipCode,
                phone,
                email,
                pricing,
                iana_timezone: timezone,
                deadlines,
                advertiserEditingEnabled
            });
        }
        else {
            yield orgToUpdate.ref.update({
                name,
                circulationSize,
                county,
                address,
                city,
                state: parseInt(state, 10),
                zipCode,
                phone,
                email,
                iana_timezone: timezone
            });
        }
        return orgToUpdate.ref;
    });
    const createOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e, _f, _g;
        try {
            // Create stripe customer id for organization
            const createCustomer = getCreateCustomerFn();
            const customerResult = yield createCustomer({
                name: user.name,
                email: user.email,
                firestoreUID: userAuth.uid
            });
            const newspEnum = OrganizationType.newspaper.value;
            let orgData;
            if (!((_d = State.by_value(Number(state))) === null || _d === void 0 ? void 0 : _d.run_donations_by_default)) {
                orgData = {
                    organizationType: newspEnum,
                    name,
                    circulationSize,
                    county,
                    address,
                    city,
                    state: parseInt(state, 10),
                    zipCode,
                    phone,
                    email,
                    pricing,
                    pricePerInch,
                    roundOff,
                    minInches,
                    createdBy: userAuth.uid,
                    iana_timezone: timezone,
                    parent: user.organization ? user.organization : null,
                    deadlines,
                    stripeId: customerResult.data.stripeId,
                    advertiserEditingEnabled,
                    shouldAutoGenOrderNums: false,
                    accountId: ENOTICE_ACCOUNT_ID,
                    disabled: true
                };
            }
            else {
                orgData = {
                    organizationType: newspEnum,
                    name,
                    circulationSize,
                    county,
                    address,
                    city,
                    state: parseInt(state, 10),
                    zipCode,
                    phone,
                    email,
                    deadlines,
                    createdBy: userAuth.uid,
                    iana_timezone: timezone,
                    parent: user.organization ? user.organization : null,
                    stripeId: customerResult.data.stripeId,
                    shouldAutoGenOrderNums: false,
                    accountId: ENOTICE_ACCOUNT_ID,
                    disabled: true
                };
            }
            if (user && user.email === 'placement@column.us') {
                orgData.isPlacementOnly = true;
            }
            orgData.adTemplate = getFirebaseContext()
                .adTemplatesRef()
                .doc(DEFAULT_TEMPLATE_ID);
            const created = yield Firebase.firestore()
                .collection(Collections.organizations)
                .add(orgData);
            if ((_e = State.by_value(Number(state))) === null || _e === void 0 ? void 0 : _e.run_donations_by_default) {
                const donationProfile = {
                    organization: created,
                    name,
                    city,
                    state: parseInt(state, 10)
                };
                yield Firebase.firestore()
                    .collection(Collections.donationProfiles)
                    .add(donationProfile);
            }
            const orgSnap = (yield created.get());
            const userUpdateObject = {
                activeOrganization: created,
                allowedOrganizations: Firestore.FieldValue.arrayUnion(orgSnap.ref),
                roles: Object.assign(Object.assign({}, user.roles), { [orgSnap.id]: RoleType.admin.value })
            };
            if (!user.organization) {
                userUpdateObject.organization = created;
            }
            // set the currently active organization on the user snapshot
            yield Firebase.firestore()
                .collection(Collections.users)
                .doc(userAuth.uid)
                .update(userUpdateObject);
            if ((_f = State.by_value(Number(state))) === null || _f === void 0 ? void 0 : _f.run_donations_by_default) {
                yield Firebase.firestore()
                    .collection(Collections.users)
                    .doc(userAuth.uid)
                    .update({
                    postRegistrationComplete: true
                });
            }
            yield ((_g = Firebase.auth().currentUser) === null || _g === void 0 ? void 0 : _g.getIdToken(true));
            authActions.register();
            return created;
        }
        catch (error) {
            setError(error.toString());
            setLoading(false);
        }
    });
    const onBackClick = (e) => {
        e.preventDefault();
        if (step !== BASIC_INFO_STEP)
            setStep(step - 1);
        else {
            history.replace('/register/welcome');
            push('/register/welcome');
        }
    };
    const renderHeader = () => {
        const heading = {
            [BASIC_INFO_STEP]: {
                title: 'Newspaper Info',
                subtitle: 'Tell us a bit more about your publication.'
            },
            [DEADLINES_STEP]: {
                title: 'Publication Deadlines',
                subtitle: 'Select your newspaper publication days and ad deadlines.'
            }
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("h3", { className: "text-md opacity-75 font-semibold", style: { color: '#2A394A' } }, heading[step].title),
            React.createElement("p", { className: "mt-1 text-md opacity-75 text-gray-800 font-medium", style: { color: 'rgba(42, 57, 74, 0.67)' } }, heading[step].subtitle)));
    };
    const renderFooter = (next, back, canNext = true, options = { allowPartialSubmit: false }) => {
        const nextDisabled = !canNext ||
            (step === DEADLINES_STEP &&
                deadlines &&
                !deadlines.some(element => element.publish)) ||
            loading;
        return (React.createElement("div", { className: "border-t border-gray-200 px-4 py-4 sm:px-6", style: { backgroundColor: '#f9fafb' } },
            React.createElement(Grid, { container: true, justify: "flex-end", alignItems: "center", item: true, direction: "row" },
                (step !== BASIC_INFO_STEP ||
                    (step === BASIC_INFO_STEP && !user.activeOrganization)) && (React.createElement(Box, { mr: 2 },
                    React.createElement("button", { tabIndex: -1, type: "reset", className: "rounded-md w-32 h-10 font-medium", style: {
                            backgroundColor: 'transparent',
                            color: '#2D9BDB'
                        }, onClick: (e) => (back ? back() : onBackClick(e)), disabled: loading, id: "back-registration" }, "Back"))),
                React.createElement(Box, null,
                    React.createElement("button", { id: "next", "data-testid": "next", type: "submit", onClick: e => {
                            if (next) {
                                e.preventDefault();
                                next();
                            }
                        }, style: { backgroundColor: '#2D9BDB', color: 'white' }, className: `flex items-center justify-center rounded-md w-32 h-10 font-medium ${nextDisabled && 'opacity-50'}`, disabled: !options.allowPartialSubmit && nextDisabled }, "Next"))),
            errorMessage !== '' && (React.createElement(Grid, { container: true, alignItems: "center", justify: "center", className: classes.error },
                React.createElement(Typography, { color: "error", align: "center" }, errorMessage)))));
    };
    const updateValues = () => {
        setShowDwollaCustomerModal(false);
        setAddBankVerifyOptions(true);
    };
    const continueDwollaSetup = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h;
        const { organization, activeOrganization } = user;
        if (!organization || !activeOrganization) {
            throw new Error(`User does not have organization and activeOrganization`);
        }
        const mainOrg = yield organization.get();
        const activeOrg = yield activeOrganization.get();
        if ((_h = activeOrg.data()) === null || _h === void 0 ? void 0 : _h.accountId) {
            push('/');
            return;
        }
        yield activeOrganization.update(Object.assign({ dwollaPaymentEnabled: true }, (exists(mainOrg) && { accountId: mainOrg.data().accountId })));
        push('/');
    });
    return (React.createElement(Grid, { container: true, style: { display: BROWSER === BROWSERS.ie ? 'block' : '' }, justify: "center" },
        React.createElement(ValidatorForm, { style: { width: '100%' }, onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                var _j;
                e.preventDefault();
                setStateError('');
                setError('');
                if (step === BASIC_INFO_STEP) {
                    if (!state) {
                        setStateError('Please select a state');
                        return;
                    }
                    if (!zipCode || zipCode.length < 5) {
                        setStateError('Enter a valid zip code to continue');
                        return;
                    }
                    if (phone.includes('_'))
                        return;
                    const querySnapshot = yield Firebase.firestore()
                        .collection(Collections.organizations)
                        .where('organizationType', '==', OrganizationType.newspaper.value)
                        .where('name', '==', name)
                        .get();
                    if ((!exists(orgToUpdate) && !querySnapshot.empty) ||
                        (exists(orgToUpdate) &&
                            !querySnapshot.empty &&
                            name !== orgToUpdate.data().name)) {
                        setStateError(`Newspaper account for ${name} already created`);
                        return;
                    }
                }
                const isOnePublishingDay = deadlines.find(day => day.publish);
                if (step === DEADLINES_STEP && !isOnePublishingDay) {
                    setStateError('Please select at least one publishing day');
                    return;
                }
                if (step === DEADLINES_STEP) {
                    setLoading(true);
                    if (isEditing)
                        yield updateOrganization();
                    else
                        yield createOrganization();
                    // Introduce a small delay, so onOrganizationCreate has time to create default rates
                    yield new Promise(res => setTimeout(() => res(), 540));
                    setLoading(false);
                    push('/');
                }
                if (step !== DEADLINES_STEP &&
                    state &&
                    !((_j = State.by_value(Number(state))) === null || _j === void 0 ? void 0 : _j.run_donations_by_default)) {
                    setStep(step + 1);
                }
            }) },
            React.createElement(Grid, { container: true, justify: "center" },
                React.createElement(Stepper, { step: step })),
            React.createElement("div", { className: "bg-white shadow rounded-lg mt-4" },
                React.createElement("div", { className: "border-b border-gray-200 px-6 py-6" }, renderHeader()),
                step === BASIC_INFO_STEP && (React.createElement(BasicDetailsStep, { name: name, setName: setName, circulationSize: circulationSize, setCirculationSize: setCirculationSize, county: county, setCounty: setCounty, email: email, setEmail: setEmail, address: address, setAddress: setAddress, city: city, setCity: setCity, state: state, setState: setState, zipCode: zipCode, setZipCode: setZipCode, phone: phone, timezone: timezone, setTimezone: setTimezone, setPhone: setPhone, stateError: stateError, renderFooter: renderFooter })),
                step === DEADLINES_STEP && (React.createElement(React.Fragment, null,
                    React.createElement(AdvertisingDeadlinesStep, { deadlines: deadlines, setDeadlines: setDeadlines, advertiserEditingEnabled: advertiserEditingEnabled, setAdvertiserEditingEnabled: setAdvertiserEditingEnabled, stateError: stateError }),
                    renderFooter())))),
        showDwollaCustomerModal && activeOrganization && (React.createElement(DwollaCustomerModal, { showCustomerModal: () => setShowDwollaCustomerModal(false), updateOnSuccess: () => updateValues(), organization: activeOrganization })),
        openPlaid && activeOrganization && (React.createElement(CheckSettings, { activeOrganization: activeOrganization, push: push, user: plaidUser, fromRegistration: openPlaid, continueRegistration: continueDwollaSetup })),
        addBankVerifyOptions && activeOrganization && (React.createElement(AccountVerificationOptionsModal, { customerId: (_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwolla) === null || _b === void 0 ? void 0 : _b.dwollaCustomer, closeModal: () => setAddBankVerifyOptions(false), secureLoginHandleClick: () => {
                setAddBankVerifyOptions(false);
                setOpenPlaid(true);
            }, verifyAccountHandleClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setAddBankVerifyOptions(false);
                yield continueDwollaSetup();
            }), user: user }))));
};
export default connect(null, { push })(withStyles(styles, { withTheme: true })(RegisterOrganizationNewspaper));
