import { getEnum } from './enums';
import { AdTypesData, AffidavitStatusTypeData, BankAccountVerificationData, BillingStatusData, CountryData, CurrencyData, CustomAffidavitData, DayData, DisplaySiteUploadStatusData, DwollaCustomerStatusData, DwollaTransferStatusData, ExportFormatTypeData, FrequencyData, GovernmentTypeData, InviteStatusTypeData, InvoiceItemTypeData, InvoiceStatusData, LabelsData, LawTypeData, LineItemTypeData, MailStatusData, NoticeStatusData, NoticeTypeData, NotificationTypeData, OccupationTypeData, OrganizationTypeData, PayoutStatusTypeData, RateTypeData, RoleTypeData, StateData, StripeMetadataTypeData, SubdomainStatusData, SyncStatusData, SyncStatusCategoryData, TimezoneData, TransactionTypeData, XMLReplacementTagData } from './shared';
import { CourtHouseData } from './CourtHouse';
// BASIC ENUMS, not extended with additional data:
export const AdType = getEnum(AdTypesData);
export const AffidavitStatusType = getEnum(AffidavitStatusTypeData);
export const BankAccountVerification = getEnum(BankAccountVerificationData);
export const BillingStatusType = getEnum(BillingStatusData);
export const CustomAffidavit = getEnum(CustomAffidavitData);
export const Day = getEnum(DayData);
export const DwollaCustomerStatus = getEnum(DwollaCustomerStatusData);
export const DwollaTransferStatus = getEnum(DwollaTransferStatusData);
export const ELabels = getEnum(LabelsData);
export const Frequency = getEnum(FrequencyData);
export const GovernmentType = getEnum(GovernmentTypeData);
export const InviteStatus = getEnum(InviteStatusTypeData);
export const InvoiceItemType = getEnum(InvoiceItemTypeData);
export const InvoiceStatus = getEnum(InvoiceStatusData);
export const LawType = getEnum(LawTypeData);
export const LineItemType = getEnum(LineItemTypeData);
export const MailStatus = getEnum(MailStatusData);
export const OccupationType = getEnum(OccupationTypeData);
export const PayoutStatusType = getEnum(PayoutStatusTypeData);
export const StripeMetadataType = getEnum(StripeMetadataTypeData);
export const SubdomainStatus = getEnum(SubdomainStatusData);
export const SyncStatusCategory = getEnum(SyncStatusCategoryData);
export const TransactionType = getEnum(TransactionTypeData);
export const XMLReplacementTagType = getEnum(XMLReplacementTagData);
// COMPLEX ENUMS, extended with additional data (requires type arguments to get proper types):
export const Country = getEnum(CountryData);
export const CurrencyType = getEnum(CurrencyData);
export const DisplaySiteUploadStatus = getEnum(DisplaySiteUploadStatusData);
export const ExportFormatType = getEnum(ExportFormatTypeData);
export const NoticeStatusType = getEnum(NoticeStatusData);
export const NoticeType = getEnum(NoticeTypeData);
export const NotificationType = getEnum(NotificationTypeData);
export const OrganizationType = getEnum(OrganizationTypeData);
export const RateType = getEnum(RateTypeData);
export const RoleType = getEnum(RoleTypeData);
export const State = getEnum(StateData);
export const SyncStatusType = getEnum(SyncStatusData);
export const TimezoneType = getEnum(TimezoneData);
// Not exactly an Enum, but hey, we're open-minded around here and there's plenty of room
export const CourtHouse = CourtHouseData;
