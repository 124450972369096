import React from 'react';
import { Notice, TickNote, BalanceIcon, DollarIcon, PlusNote, AlarmNote, CommunityProfiles } from 'icons';
import { NotificationType } from 'lib/enums';
import { PartyPopper, MoneyWithWings, AlarmClock, CancelledAndNoEntry, PageFacingUp, MoneyBag, Memo } from '../../emojis/index';
const blueStyles = 'bg-blue-100 text-blue-900';
const greenStyles = 'bg-green-150  text-green-650';
const redStyles = 'bg-red-100 text-red-500';
// Add svg image and styles based on the notification type
export const getNotificationTypeStyling = (key) => {
    switch (key) {
        case NotificationType.publisher_edited_notice.key:
        case NotificationType.notice_receipt_confirmed.key:
        case NotificationType.notice_submitted_anonymous.key:
            return { img: React.createElement(Notice, null), styles: greenStyles };
        case NotificationType.charge_succeeded.key:
        case NotificationType.invoice_paid_by_advertiser.key:
            return { img: React.createElement(TickNote, null), styles: greenStyles };
        case NotificationType.affidavit_available.key:
        case NotificationType.publisher_updated_affidavit.key:
        case NotificationType.new_affidavit.key:
            return { img: React.createElement(BalanceIcon, null), styles: greenStyles };
        case NotificationType.payout_paid.key:
            return { img: React.createElement(DollarIcon, null), styles: greenStyles };
        case NotificationType.organization_request_approved.key:
            return { img: React.createElement(CommunityProfiles, null), styles: greenStyles };
        case NotificationType.notice_filed.key:
            return { img: React.createElement(Notice, null), styles: blueStyles };
        case NotificationType.new_invoice.key:
            return { img: React.createElement(PlusNote, null), styles: blueStyles };
        case NotificationType.affidavit_upload_reminder.key:
            return { img: React.createElement(BalanceIcon, null), styles: blueStyles };
        case NotificationType.new_invoice_upfront_payment.key:
        case NotificationType.invoice_reminder.key:
            return { img: React.createElement(PlusNote, null), styles: redStyles };
        case NotificationType.charge_failed.key:
        case NotificationType.invoice_cancelled.key:
        case NotificationType.invoice_cancelled_no_payment.key:
        case NotificationType.automated_invoice_reminder.key:
        case NotificationType.invoice_cancelled_paid_through_gateway.key:
            return { img: React.createElement(AlarmNote, null), styles: redStyles };
        case NotificationType.request_notice_cancellation.key:
        case NotificationType.notice_cancelled.key:
        case NotificationType.refund_issued.key:
            return { img: React.createElement(Notice, null), styles: redStyles };
        case NotificationType.organization_request_denied.key:
            return { img: React.createElement(CommunityProfiles, null), styles: redStyles };
        default:
            return { img: React.createElement(Notice, null), styles: blueStyles };
    }
};
// Add emojis in notice header based on the notification types
export const getNotificationsEmoji = (key) => {
    switch (key) {
        case NotificationType.notice_receipt_confirmed.key:
        case NotificationType.notice_filed.key:
        case NotificationType.organization_request_approved.key:
            return React.createElement(PartyPopper, null);
        case NotificationType.publisher_edited_notice.key:
            return React.createElement(Memo, null);
        case NotificationType.new_invoice.key:
        case NotificationType.charge_succeeded.key:
        case NotificationType.invoice_paid_by_advertiser.key:
            return React.createElement(MoneyWithWings, null);
        case NotificationType.new_invoice_upfront_payment.key:
        case NotificationType.automated_invoice_reminder.key:
        case NotificationType.invoice_reminder.key:
            return React.createElement(AlarmClock, null);
        case NotificationType.charge_failed.key:
        case NotificationType.invoice_cancelled.key:
        case NotificationType.notice_cancelled.key:
        case NotificationType.refund_issued.key:
        case NotificationType.invoice_cancelled_paid_through_gateway.key:
        case NotificationType.invoice_cancelled_no_payment.key:
        case NotificationType.request_notice_cancellation.key:
        case NotificationType.organization_request_denied.key:
            return React.createElement(CancelledAndNoEntry, null);
        case NotificationType.affidavit_available.key:
        case NotificationType.publisher_updated_affidavit.key:
        case NotificationType.new_affidavit.key:
        case NotificationType.affidavit_upload_reminder.key:
        case NotificationType.notice_submitted_anonymous.key:
            return React.createElement(PageFacingUp, null);
        case NotificationType.payout_paid.key:
            return React.createElement(MoneyBag, null);
        default:
            return React.createElement(React.Fragment, null);
    }
};
