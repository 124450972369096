var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { getRedirect } from 'utils/urls';
import Firebase, { Collections } from 'EnoticeFirebase';
import { OrganizationType } from 'lib/enums';
import api from 'api';
import { isAddressHasAnyField, organizationAlreadyExists } from 'routes/register/helpers';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import ConfirmToast from 'components/modals/ConfirmToast';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import RegisterOrganizationAddressForm, { EMPTY_ADDRESS } from '../RegisterOrganizationAddressForm';
import RegisterOrganizationAlreadyExists from '../RegisterOrganizationAlreadyExists';
const RegisterOtherOrganization = ({ userAuth, authActions, anonPlacement, push, user, stepComplete, setPreventDismissingInfo }) => {
    var _a;
    // Form Data
    const [address, setAddress] = useState(EMPTY_ADDRESS);
    const [alreadyExists, setAlreadyExists] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const allFieldsValid = address.validation.valid;
    const disable = !allFieldsValid;
    useEffect(() => {
        if (user.organization) {
            push(getRedirect());
        }
    }, [(_a = user.organization) === null || _a === void 0 ? void 0 : _a.id]);
    useEffect(() => {
        stepComplete(allFieldsValid);
    }, [allFieldsValid]);
    useEffect(() => {
        if (isAddressHasAnyField(address)) {
            setPreventDismissingInfo(true);
        }
        else {
            setPreventDismissingInfo(false);
        }
    }, [isAddressHasAnyField(address)]);
    const onBackClick = (e) => {
        e.preventDefault();
        if (isAddressHasAnyField(address)) {
            setOpen(true);
        }
        else {
            goBackLink();
        }
    };
    const goBackLink = () => {
        push('/register/occupations');
    };
    const nextButtonClicked = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        yield handleSubmit();
    });
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!allFieldsValid) {
            return;
        }
        setLoading(true);
        // Check if org already exists
        const orgAlreadyExists = yield organizationAlreadyExists(OrganizationType.other_organization.value, address.name);
        if (orgAlreadyExists) {
            setAlreadyExists(true);
            setLoading(false);
            return;
        }
        // Create stripe customer id for organization
        const createCustomer = getCreateCustomerFn();
        const result = yield createCustomer({
            name: user.name,
            email: user.email,
            firestoreUID: userAuth.uid
        });
        // Add organization, update user's organization ref
        const created = yield Firebase.firestore()
            .collection(Collections.organizations)
            .add({
            organizationType: OrganizationType.other_organization.value,
            name: address.name,
            address: address.addressLine1,
            addressLine2: address.addressLine2 || '',
            city: address.city,
            state: address.state.id,
            zipCode: address.zipCode,
            phone: address.phone,
            createdBy: userAuth.uid,
            stripeId: result.data.stripeId
        });
        yield api.post('users/link-filer-to-org', {
            organizationId: created.id,
            uid: userAuth.uid
        });
        authActions.register();
        if (anonPlacement) {
            yield created.update({
                postRegistrationComplete: true
            });
        }
        // Sometimes organization is not set on user and redirect re-returns the join organization
        // screen. Now it will redirect to invites from there only if user has organization otherwise useEffect will work to redirect
        if (user.organization) {
            push(getRedirect());
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col lg:w-3/5 md:w-3/5 sm:w-auto bg-white rounded-md px-8 pt-8 pb-10 shadow-2 border" },
            React.createElement("div", { className: "flex flex-col w-full" },
                React.createElement("div", null,
                    React.createElement(RegisterOrganizationAddressForm, { nameFieldText: 'Name of organization', handleInputChanged: setAddress, namePlaceholderText: "Name of organization" })))),
        React.createElement(AdvertiserRegistrationFooter, { id: "submitOtherOrganization", backButtonText: "Go back", nextButtonText: "Create organization", onBackButtonClick: onBackClick, onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: disable }),
        open && (React.createElement(ConfirmToast, { headerText: 'Are you sure?', bodyText: `If you go back now, your progress will be lost. Please click "Yes, continue" to confirm.`, leftButtonText: 'Cancel', rightButtonText: `Yes, continue`, onLeftClick: () => setOpen(false), onRightClick: () => goBackLink() })),
        alreadyExists && (React.createElement(RegisterOrganizationAlreadyExists, { name: address.name, onBackClicked: () => setAlreadyExists(false) }))));
};
export default RegisterOtherOrganization;
