import React from 'react';
import { cdnIfy } from 'lib/helpers';
import { Tooltip } from '@material-ui/core';
import { State } from 'lib/enums';
import SettingsHeader from '../settings/SettingsHeader';
import { DocumentDuplicate, Download } from './icons';
const tokenizedLinkToCDNLink = (tokenizedLink) => {
    const suffix = tokenizedLink
        .split('?')[0]
        .split('/o/')[1]
        .replace(/%(25){0,1}(2F){0,1}/g, '/');
    return cdnIfy(suffix);
};
const buildFloridaAds = (donationProfile) => {
    const buildSampleAd1 = (width, fontSize) => {
        let adUrl = `http://enotice-production.imgix.net/oneoff/donations/florida/ads/fpf-base-1.png?w=${width}&`;
        if (donationProfile.data().logoUrl) {
            const markUrl = tokenizedLinkToCDNLink(donationProfile.data().logoUrl);
            adUrl += `mark=${markUrl}&mark-w=${Math.round(width / 6)}&mark-x=10&mark-y=${Math.round(width / 4)}`;
        }
        adUrl += `&txt=${encodeURIComponent(`https://fpf.column.us/${donationProfile.data().domain}`)}&txt-align=center&txt-size=${fontSize}&txt-font=serif`;
        return adUrl;
    };
    const buildSampleAd2 = (width, fontSize) => {
        let adUrl = `http://enotice-production.imgix.net/oneoff/donations/florida/ads/fpf-base2.png?w=${width}&`;
        if (donationProfile.data().logoUrl) {
            const markUrl = tokenizedLinkToCDNLink(donationProfile.data().logoUrl);
            adUrl += `mark=${markUrl}&mark-w=${Math.round(width / 6)}&mark-x=0&mark-y=${Math.round(width / 1.8)}`;
        }
        adUrl += `&txt=${encodeURIComponent(`https://fpf.column.us/${donationProfile.data().domain}`)}&txt-y=${Math.round(width / 1.5)}&txt-align=center&txt-size=${fontSize}&txt-font=serif`;
        return adUrl;
    };
    return [
        {
            link: buildSampleAd1(800, 18),
            name: 'sample_ad_1_800x340.png'
        },
        {
            name: 'sample_ad_1_1200x510.png',
            link: buildSampleAd1(1200, 27)
        },
        {
            name: 'sample_ad_2_400x333.png',
            link: buildSampleAd2(400, 12)
        },
        {
            name: 'sample_ad_2_800x667.png',
            link: buildSampleAd2(800, 18)
        }
    ];
};
const buildKansasAds = (donationProfile) => {
    const buildStatewideAd = (width) => {
        let adUrl = `http://enotice-production.imgix.net/oneoff/donations/kansas/kpa_ad_1.png?txt-align=center&txt-size=25&txt-y=773`;
        adUrl += `&w=${width}`;
        adUrl += `&txt=${encodeURIComponent(`https://knf.column.us/${donationProfile.data().domain}`)}`;
        console.log('Ad URL:', adUrl);
        return adUrl;
    };
    return [
        {
            name: 'statewide_ad_800x844.pdf',
            link: buildStatewideAd(800)
        }
    ];
};
const SharingSettings = ({ activeOrganization, donationProfile }) => {
    let sampleAds = [];
    if (activeOrganization.data().state === State.kansas.value) {
        sampleAds = buildKansasAds(donationProfile);
    }
    else if (activeOrganization.data().state === State.florida.value) {
        sampleAds = buildFloridaAds(donationProfile);
    }
    else {
        console.log('No ads built for state', activeOrganization.data().state);
        sampleAds = [];
    }
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Marketing Tools", description: "Share your campaign with potential donors." }),
        React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
            React.createElement("div", { className: "flex flex-col w-2/6" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Website Widget"),
                React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Embed a widget by copying and pasting the following script code into your website")),
            React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" }, !donationProfile.data().domain ? (React.createElement(Tooltip, { title: "Set your organization's domain to access widget" },
                React.createElement("textarea", { id: "about", className: "cursor-not-allowed appearance-none form-input rounded block w-4/6 px-3 py-2 border focus:z-10 sm:text-sm sm:leading-5", value: `<script src="https://cdn.column.us/${donationProfile.id}"></script>`, disabled: true, rows: 3 }))) : (React.createElement("textarea", { id: "about", className: 'appearance-none form-input rounded block w-4/6 px-3 py-2 border focus:z-10 sm:text-sm sm:leading-5', value: `<script src="https://cdn.column.us/${donationProfile.id}"></script>`, disabled: true, rows: 3 })))),
        React.createElement("div", { className: "flex w-full items-center px-5 py-5 border-b border-gray-300" },
            React.createElement("div", { className: "flex flex-col w-2/6" },
                React.createElement("p", { className: "not-italic font-semibold text-sm text-gray-800" }, "Print Advertisements"),
                React.createElement("p", { className: "not-italic font-medium text-sm text-gray-600 mt-1 pr-6" }, "Download ad assets if you would like to run print ads in your newspaper promoting your campaign.")),
            React.createElement("div", { className: "not-italic font-normal text-sm text-black w-4/6" }, sampleAds.map(sampleAd => !donationProfile.data().domain ? (React.createElement(Tooltip, { title: "Set your organization's domain to access prebuilt ads" },
                React.createElement("div", { className: "cursor-not-allowed flex w-4/6 rounded border justify-between py-2 px-2 mb-2 cursor-pointer" },
                    React.createElement("div", { className: "flex" },
                        DocumentDuplicate,
                        React.createElement("span", { className: "ml-2 font-medium text-sm text-gray-800" }, sampleAd.name)),
                    Download))) : (React.createElement("div", { className: "flex flex-col w-4/6" },
                React.createElement("a", { className: "flex rounded border justify-between py-2 px-2 mb-2 cursor-pointer", href: sampleAd.link, target: "_blank", rel: "noopener noreferrer" },
                    React.createElement("div", { className: "flex" },
                        DocumentDuplicate,
                        React.createElement("span", { className: "ml-2 font-medium text-sm text-gray-800" }, sampleAd.name)),
                    Download))))))));
};
export default SharingSettings;
