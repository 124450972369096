import React, { useState } from 'react';
import { State } from 'lib/enums';
const RequestSubdomainModal = ({ close, requestSubdomain, state }) => {
    var _a, _b;
    const [subdomain, setSubdomain] = useState();
    const [inputShadow, setInputShadow] = useState(false);
    const handleDomainEnter = (value) => {
        // eslint-disable-next-line no-useless-escape
        if (/^[0-9a-zA-z-_~]*$/.test(value))
            setSubdomain(value.toLowerCase());
    };
    return (React.createElement("div", { className: "fixed bottom-0 inset-x-0  sm:inset-0 flex items-center justify-center" },
        React.createElement("div", { className: "fixed inset-0 transition-opacity" },
            React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75", onClick: () => close() })),
        React.createElement("div", { className: "bg-white rounded-lg px-8 py-8 max-w-xl overflow-hidden shadow-xl transform transition-all", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
            React.createElement("div", null,
                React.createElement("div", { className: "font-semibold text-xl text-gray-800 mb-4" }, "Request custom subdomain"),
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "font-medium text-base text-gray-600 mb-4" },
                        "If you want your campaign page URL customized to start with text other than ", (_a = State.by_value(state)) === null || _a === void 0 ? void 0 :
                        _a.donation_subdomain,
                        ".column.us/, Column can configure a domain for you."),
                    React.createElement("div", { className: "flex justify-between items-center mr-4" },
                        React.createElement("div", { className: `mt-1 flex rounded shadow-sm w-3/4 ${inputShadow && 'shadow-outline-blue border border-indigo-300'}` },
                            React.createElement("input", { className: "form-input flex-1 block w-full px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5 focus:shadow-none focus:border", placeholder: "Subdomain*", value: subdomain, onChange: e => handleDomainEnter(e.target.value), onFocus: () => setInputShadow(true), onBlur: () => setInputShadow(false) }),
                            React.createElement("span", { className: "inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-700 sm:text-sm" }, ".column.us")),
                        React.createElement("button", { type: "button", className: "rounded bg-blue-100 font-semibold text-sm text-blue-500 px-6 py-2", onClick: () => requestSubdomain(subdomain) }, "Request")),
                    React.createElement("div", { className: "font-normal text-sm text-gray-600 leading-4 mt-4" },
                        "Note: Your request may take multiple days to process. In the meantime, your campaign page will be available at the",
                        ' ', (_b = State.by_value(state)) === null || _b === void 0 ? void 0 :
                        _b.donation_subdomain,
                        ".column.us subdomain."))))));
};
export default RequestSubdomainModal;
