import { Button, SvgIcon, withStyles, createStyles } from '@material-ui/core';
import React from 'react';
const styles = () => createStyles({
    checkButton: {
        backgroundColor: 'white',
        textTransform: 'none',
        paddingRight: '92px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400
    },
    checkButtonDisabled: {
        backgroundColor: '#c7c7c7',
        textTransform: 'none',
        paddingRight: '92px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400,
        cursor: 'not-allowed'
    }
});
function PayWithCheckButton({ disabled, selected, clickHandler, classes }) {
    return (React.createElement(Button, { className: disabled ? classes.checkButtonDisabled : classes.checkButton, variant: "outlined", onClick: () => clickHandler('check'), startIcon: React.createElement(SvgIcon, { fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: selected ? '#4285F4' : 'currentColor', style: {
                fill: 'none',
                marginLeft: '0'
            } },
            React.createElement("path", { d: "M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" })), style: {
            color: selected ? '#4285F4' : '#4A5568',
            border: selected ? '1px solid #4285F4' : '1px solid #DADADA',
            width: 'auto'
        }, disabled: disabled }, "Check"));
}
export default withStyles(styles)(PayWithCheckButton);
