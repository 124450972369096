import React from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
export function EraseContentModal({ setShowEraseContentModal, cancel, confirm }) {
    return (React.createElement(CancelOrSubmitModal, { id: "erase-content", destructive: true, header: "Erase Notice Content?", setOpen: (open) => {
            cancel();
            setShowEraseContentModal(open);
        }, tertiaryButtonText: "Cancel", primaryButtonText: "Yes, Clear Content", onSubmit: () => {
            confirm();
            setShowEraseContentModal(null);
        } },
        React.createElement("p", { className: "py-3" }, "Are you sure you want to clear the content of your notice and start over?")));
}
