var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NoticeStatusType } from '../enums';
export const getDatesAvailableForAffidavitUpload = (ctx, newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const outstandingNoticePublicationDates = [];
    const noticeSnaps = (yield ctx
        .userNoticesRef()
        .where('newspaper', '==', newspaperSnap.ref)
        .where('noticeStatus', 'in', [
        NoticeStatusType.pending.value,
        NoticeStatusType.published.value
    ])
        .get()).docs;
    noticeSnaps.forEach(noticeSnap => {
        const { publicationDates } = noticeSnap.data();
        publicationDates.map(pd => outstandingNoticePublicationDates.push(pd.toDate()));
    });
    return outstandingNoticePublicationDates;
});
