var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
/**
 * Modal to confirm before archiving a rate
 * */
export default function ArchiveRateModal({ rateToArchive, onClose }) {
    function onSubmit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield rateToArchive.ref.update({ archived: true });
            onClose();
        });
    }
    return (React.createElement(CancelOrSubmitModal, { tertiaryButtonText: "Cancel", primaryButtonText: "Archive", onSubmit: onSubmit, setOpen: onClose, header: `Archive Rate ${rateToArchive.data().description}`, destructive: true },
        React.createElement("div", { className: "py-3" },
            React.createElement("p", null, "Once archived, rates can no longer be recovered in the app. However, the Column team will still be able to recover your settings."))));
}
