var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { logAndCaptureException } from 'utils';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { Typography, Box, Button, CircularProgress } from '@material-ui/core';
import api from 'api';
import { BROWSER, BROWSERS } from '../../../../constants';
import { reload } from '../../../../utils/reload';
const CheckoutForm = ({ classes, amount, advertiser, invoiceId, customerToSaveCardOnName, allowSavedCreditCards }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const [saveCreditCard, setSaveCreditCard] = useState(allowSavedCreditCards);
    const handleStripeSubmission = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setLoading(true);
        setErr('');
        const customer_email = advertiser.data().email;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = yield stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        });
        if (error) {
            // We don't want to log simple user errors with entering their credit card
            // information, so we ignore some known codes.
            const shouldLogError = !(error.type === 'validation_error' &&
                ['incomplete_number', 'incomplete_zip', 'incomplete_cvc'].includes((_a = error.code) !== null && _a !== void 0 ? _a : ''));
            if (shouldLogError) {
                logAndCaptureException(error, 'Failed to create Stripe payment method', {
                    invoiceId
                });
            }
            setErr(error.message || '');
            setLoading(false);
        }
        else {
            const req = {
                paymentMethodId: paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.id,
                amount,
                customer_email,
                invoiceId,
                saveCreditCard,
                paymentMethod: 'card'
            };
            const result = yield api.post('payments/pay-invoice', req);
            if (result.success) {
                setLoading(false);
                reload(false);
            }
            else {
                setErr(result.error || 'Something went wrong');
                setLoading(false);
            }
        }
    });
    return (React.createElement("form", { onSubmit: e => {
            e.preventDefault();
            void handleStripeSubmission();
        } },
        React.createElement("div", { style: {
                boxSizing: 'border-box',
                border: '1px solid #DADADA',
                boxShadow: '0px 1px 3px rgba(230, 235, 241, 0.25)',
                borderRadius: '4px',
                color: '#A9B7C4',
                lineHeight: '19px',
                display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            } },
            React.createElement("div", { style: {
                    width: '90%',
                    height: '30px'
                } },
                React.createElement("div", { style: {
                        marginTop: '6px'
                    } },
                    React.createElement(CardElement, null)))),
        React.createElement(Box, { style: {
                background: '#FFF5F5',
                borderTop: '1px solid #C53030',
                borderBottom: '1px solid #C53030',
                marginTop: '9px'
            } },
            React.createElement(Typography, { style: {
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#C53030',
                    paddingTop: '12px',
                    paddingBottom: '13px',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                } },
                `The merchant name on your credit card billing statement will be `,
                React.createElement("span", { className: classes.bold }, `Column Software PBC.`))),
        allowSavedCreditCards && (React.createElement("div", { className: "pt-1 flex items-start", onClick: () => setSaveCreditCard(!saveCreditCard) },
            React.createElement("div", null,
                React.createElement("input", { className: "form-checkbox focus:ring-blue-400 h-4 w-4 text-blue-500 border-gray-300 rounded-md", checked: saveCreditCard, type: "checkbox" })),
            React.createElement("span", { className: "font-medium text-sm text-gray-600 pl-2 pt-0.5" },
                "Save this card for all payments from ",
                customerToSaveCardOnName,
                "."))),
        React.createElement(Box, { className: classes.flex },
            React.createElement(Button, { type: "submit", id: "pay-invoice-stripe", className: classes.payInvoice, disabled: loading, startIcon: loading && (React.createElement(CircularProgress, { size: 20, className: classes.progress, style: { color: 'white' } })) }, "Pay invoice")),
        err && (React.createElement(Box, { mt: 1, className: classes.flex },
            React.createElement(Typography, { color: "error", variant: "caption" }, err)))));
};
export default CheckoutForm;
