import { Switch, Route } from 'react-router';
import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import ForgotPassword from './routes/forgotPassword/ForgotPassword';
import LoadingState from './components/LoadingState';
import WithFrame from './routes/WithFrame';
import SuspenseErrorBoundary from './components/SuspenseErrorBoundary';
import history from './customHistory';
import Reports from './routes/reports';
import OrganizationSettings from './routes/settings/OrganizationSettings';
import Typeform from './routes/typeform';
import NewspaperTypeForm from './routes/typeform/newspaper';
import RegisterOrganization from './routes/register/organization';
import PostOrgRegistration from './routes/register/organization/PostOrgRegistration';
import Payments from './routes/payments';
import EmailAction from './routes/redirects/EmailAction';
import AddNoticeWrapper from './routes/addNotice';
import UserNotices from './routes/userNoticeList';
import Settings from './routes/settings';
import Register from './routes/register/user';
import Welcome from './routes/register/welcome';
import Occupation from './routes/register/occupations';
import Invitation from './routes/invites/invitation';
import Login from './routes/login';
import Logout from './routes/logout';
import ErrorPage from './routes/errors/ErrorPage';
import NewspaperSettings from './routes/settings/NewspaperSettings';
import NoticeDetail from './routes/notice';
import CreateInvoice from './routes/notice/billing/CreateInvoice';
import EmailNeedsConfirm from './routes/register/user/EmailNeedsConfirm';
import PayInvoice from './routes/notice/billing/payInvoiceOld/PayInvoice';
import PlaceScroll from './routes/placeScroll';
import DonationCampaign from './routes/donationCampaign';
import ResetPassword from './routes/resetPassword/index';
import Debugger from './routes/debugger';
import Madlib from './routes/madlib';
const EnoticeRouter = () => {
    return (React.createElement(ConnectedRouter, { history: history },
        React.createElement(SuspenseErrorBoundary, null,
            React.createElement(Suspense, { fallback: React.createElement(LoadingState, null) },
                React.createElement(Switch, null,
                    React.createElement(WithFrame, { exact: true, path: "/login/", component: Login }),
                    React.createElement(WithFrame, { exact: true, path: "/logout/", component: Logout }),
                    React.createElement(WithFrame, { exact: true, path: "/reports/", component: Reports }),
                    React.createElement(WithFrame, { exact: true, path: "/place/", noFramePadding: true, component: PlaceScroll }),
                    React.createElement(WithFrame, { exact: true, path: "/place/:id/", noFramePadding: true, component: PlaceScroll }),
                    React.createElement(WithFrame, { hideFrame: true, exact: true, path: "/register/", component: Register }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, hideFrame: true, path: "/register/welcome", component: Welcome }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, hideFrame: true, path: "/register/occupations", component: Occupation }),
                    React.createElement(WithFrame, { exact: true, path: "/email-action", component: EmailAction }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/register/organization/post-registration/", component: PostOrgRegistration }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/register/organization/", component: RegisterOrganization }),
                    React.createElement(WithFrame, { exact: true, path: "/register/confirm/", noFramePadding: true, component: EmailNeedsConfirm }),
                    React.createElement(WithFrame, { exact: true, path: "/temporary", component: NewspaperSettings }),
                    React.createElement(WithFrame, { exact: true, path: "/settings/", component: Settings }),
                    React.createElement(WithFrame, { exact: true, path: "/form/newspaper/:noticeId", component: NewspaperTypeForm }),
                    React.createElement(WithFrame, { exact: true, path: "/form/:noticeId/:noticeType", component: Typeform }),
                    React.createElement(WithFrame, { exact: true, path: "/file/by-type/:type", component: AddNoticeWrapper }),
                    React.createElement(WithFrame, { exact: true, path: "/file/:id/", component: AddNoticeWrapper }),
                    React.createElement(WithFrame, { exact: true, path: "/file/:id/:noticeSlug?", component: AddNoticeWrapper }),
                    React.createElement(WithFrame, { exact: true, path: "/notices/", component: UserNotices }),
                    React.createElement(WithFrame, { exact: true, path: "/settings/organization/", component: OrganizationSettings }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, path: "/:path(notice|publish)/:id/", component: NoticeDetail }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, path: "/:path(notice|publish)/:id/invoice/create", component: CreateInvoice }),
                    React.createElement(WithFrame, { exact: true, path: "/payments/", noFramePadding: true, component: Payments }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/invites/:id", component: Invitation }),
                    React.createElement(WithFrame, { exact: true, path: "/error/:code", component: ErrorPage }),
                    React.createElement(WithFrame, { exact: true, path: "/donation-campaign", component: DonationCampaign }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/invoices/:id/pay/", component: PayInvoice }),
                    React.createElement(WithFrame, { exact: true, path: "/forgot-password/", component: ForgotPassword }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/reset-password/", component: ResetPassword }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/debugger/", component: Debugger }),
                    React.createElement(WithFrame, { exact: true, path: "/madlib/", component: Madlib }),
                    React.createElement(Route, { exact: true, path: "/", render: () => React.createElement(LoadingState, { context: { location: 'Router' } }) }))))));
};
const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname
});
export default connect(mapStateToProps)(EnoticeRouter);
