var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColumnButton } from 'components/ColumnButton';
import React from 'react';
const SuccessModal = ({ setOpen, gif, buttonText, onSubmit, header, body, footer, id, noExitOutsideModal, width, maxWidth, disableOverflow }) => {
    const handleExit = () => {
        if (noExitOutsideModal)
            return;
        setOpen && setOpen(false);
    };
    // TODO: we need to change the image URL to something in the codebase instead of URL
    return (React.createElement("div", { id: id, className: `fixed z-100 inset-0 ${!disableOverflow ? 'overflow-y-auto' : ''}` },
        React.createElement("div", { className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity", onClick: handleExit },
                React.createElement("div", { className: "absolute inset-0 bg-black opacity-50" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: `inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id}-inner` },
                React.createElement("div", { className: "p-14 border-b border-gray-300" },
                    React.createElement("div", { className: `sm:mt-0 text-center` },
                        React.createElement("div", { className: "flex justify-center" }, gif),
                        header && (React.createElement("h3", { className: "text-xl pt-10 leading-6 font-semibold text-gray-900", id: "modal-headline" }, header)),
                        body && (React.createElement("div", { className: "pt-4 pb-10" },
                            React.createElement("span", { className: "text-sm font-medium leading-5 text-gray-700" }, body))),
                        React.createElement(ColumnButton, { primary: true, buttonText: buttonText || 'Done', size: "xl", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                onSubmit && (yield onSubmit());
                                setOpen && setOpen(false);
                            }) }),
                        footer && (React.createElement("div", { className: "pt-10" },
                            React.createElement("span", { className: "text-sm font-medium leading-5 text-gray-700" }, footer)))))))));
};
export default SuccessModal;
