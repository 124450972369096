var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { QuestionMarkRoundedIcon, BalanceIcon } from 'icons';
import { State } from 'lib/enums';
import { Check } from 'routes/settings/icons';
const PAGE_SIZE = 10;
const donationsToMoney = (donations) => {
    const totalDonationAmountCents = donations.reduce((prev, curr) => prev + parseInt(curr.data().amount, 10), 0);
    const totalDonationAmount = totalDonationAmountCents / 100;
    return `$${totalDonationAmount.toFixed(2)}`;
};
const countTotalSupporters = (donations) => {
    const donorSet = new Set();
    donations.forEach(d => donorSet.add(d.data().email));
    return donorSet.size;
};
const getLastWeekDonations = (donations) => {
    return donations.filter(d => moment(d.data().timestamp.toDate()).add(7, 'days') > moment(new Date()));
};
const CampaignDashboard = ({ donationProfile }) => {
    const [donations, setDonations] = useState([]);
    const [totalDonations, setTotalDonations] = useState();
    const [totalSupporters, setTotalSupporters] = useState();
    const [weeklyDonations, setWeeklyDonations] = useState();
    const [allMsgsEnabled, setAllMsgsEnabled] = useState(true);
    // TODO: We should use a better typing for this StateEnum
    const [stateObj, setStateObj] = useState();
    useEffect(() => {
        const unsub = Firebase.firestore()
            .collection(Collections.donations)
            .where('donationProfileId', '==', donationProfile.id)
            .orderBy('timestamp', 'desc')
            .onSnapshot(snapshot => setDonations(snapshot.docs));
        setStateObj(State.by_value(donationProfile.data().state));
        return unsub;
    }, []);
    useEffect(() => {
        setTotalDonations(donationsToMoney(donations));
        setTotalSupporters(countTotalSupporters(donations));
        setWeeklyDonations(donationsToMoney(getLastWeekDonations(donations)));
        let msgsEnabled = true;
        for (const donation of donations) {
            if (donation.data().message && donation.data().displayMessage === false) {
                msgsEnabled = false;
                break;
            }
        }
        setAllMsgsEnabled(msgsEnabled);
    }, [donations]);
    const [pageNumber, setPageNumber] = useState(0);
    const updateDisplayMessage = (donation) => __awaiter(void 0, void 0, void 0, function* () {
        const { displayMessage } = donation.data();
        if (displayMessage === false)
            yield donation.ref.update({
                displayMessage: true
            });
        else
            yield donation.ref.update({
                displayMessage: false
            });
    });
    const disableAllMessages = () => __awaiter(void 0, void 0, void 0, function* () {
        for (const donation of donations) {
            if (donation.data().message) {
                yield donation.ref.update({
                    displayMessage: !allMsgsEnabled
                });
            }
        }
    });
    return (React.createElement("div", { className: "mt-8" },
        React.createElement("div", { className: "mx-auto px-4 sm:px-6 lg:px-8" },
            React.createElement("h2", { className: "text-2xl leading-6 font-medium text-gray-900" }, "Overview"),
            React.createElement("div", { className: "mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3" },
                React.createElement("div", { className: "bg-white overflow-hidden shadow rounded-lg" },
                    React.createElement("div", { className: "p-7" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement("div", { className: "flex-shrink-0" },
                                React.createElement(BalanceIcon, { className: "h-6 w-6 text-gray-600" })),
                            React.createElement("div", { className: "ml-5 w-0 flex-1" },
                                React.createElement("dl", null,
                                    React.createElement("dt", { className: "text-sm leading-5 font-medium text-gray-600 truncate" }, "Total Donations"),
                                    React.createElement("dd", null,
                                        React.createElement("div", { className: "text-lg leading-7 font-medium text-gray-900" }, totalDonations))))))),
                React.createElement("div", { className: "bg-white overflow-hidden shadow rounded-lg" },
                    React.createElement("div", { className: "p-7" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement("div", { className: "flex-shrink-0" },
                                React.createElement(BalanceIcon, { className: "h-6 w-6 text-gray-600" })),
                            React.createElement("div", { className: "ml-5 w-0 flex-1" },
                                React.createElement("dl", null,
                                    React.createElement("dt", { className: "text-sm leading-5 font-medium text-gray-600 truncate" }, "Total Supporters"),
                                    React.createElement("dd", null,
                                        React.createElement("div", { className: "text-lg leading-7 font-medium text-gray-900" }, totalSupporters))))))),
                React.createElement("div", { className: "bg-white overflow-hidden shadow rounded-lg" },
                    React.createElement("div", { className: "p-7" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement("div", { className: "flex-shrink-0" },
                                React.createElement(BalanceIcon, { className: "h-6 w-6 text-gray-600" })),
                            React.createElement("div", { className: "ml-5 w-0 flex-1" },
                                React.createElement("dl", null,
                                    React.createElement("dt", { className: "text-sm leading-5 font-medium text-gray-600 truncate" }, "Donations Last Week"),
                                    React.createElement("dd", null,
                                        React.createElement("div", { className: "text-lg leading-7 font-medium text-gray-900" }, weeklyDonations))))))))),
        React.createElement("h2", { className: "mx-auto mt-8 px-4 text-2xl leading-6 font-medium text-gray-900 sm:px-6 lg:px-8" }, "Transactions"),
        React.createElement("div", { className: "hidden sm:block" },
            React.createElement("div", { className: "mx-auto px-4 sm:px-6 lg:px-8" },
                React.createElement("div", { className: "flex flex-col mt-2" },
                    React.createElement("div", { className: "align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg" },
                        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider" }, "Transaction"),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider" }, "Amount"),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider" }, "Date"),
                                    stateObj && stateObj.hasSubscribers && (React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider" }, "Signed up for emails?")),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider" }, "Message"),
                                    React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider" },
                                        React.createElement("div", { className: "inline-flex items-center" },
                                            React.createElement("span", { className: "tooltip" },
                                                React.createElement(QuestionMarkRoundedIcon, { className: "mr-2 h-5 w-5" }),
                                                React.createElement("span", { className: "tooltiptext normal-case mt-20", style: { left: '-110px' } }, "Checked messages will appear on your public donation site.")),
                                            "Display",
                                            ' ',
                                            React.createElement("input", { id: "display-all-messages", type: "checkbox", className: "form-checkbox h-4 w-4 ml-2 text-gray-600 transition duration-150 ease-in-out cursor-pointer", checked: allMsgsEnabled, onClick: () => disableAllMessages() }))))),
                            React.createElement("tbody", { className: "bg-white divide-y divide-gray-200" }, donations
                                .slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE)
                                .map((donation, i) => (React.createElement("tr", { key: i, className: "bg-white" },
                                React.createElement("td", { className: "max-w-0 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                    React.createElement("div", { className: "flex" },
                                        React.createElement("a", { href: "#", className: "group inline-flex space-x-2 truncate text-sm leading-5" },
                                            React.createElement("svg", { className: "flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-in-out duration-150", viewBox: "0 0 20 20", fill: "currentColor" },
                                                React.createElement("path", { fillRule: "evenodd", d: "M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z", clipRule: "evenodd" })),
                                            React.createElement("p", { className: "text-gray-900 truncate group-hover:text-gray-900 transition ease-in-out duration-150" },
                                                donation.data().check ? 'Check' : 'Card',
                                                ' ',
                                                "payment",
                                                ' ',
                                                donation.data().isAnonymous
                                                    ? `to ${donationProfile.data().name}`
                                                    : `by ${donation.data().email}`)))),
                                React.createElement("td", { className: "px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-gray-900" },
                                    React.createElement("span", { className: "text-gray-900 font-medium" },
                                        "$",
                                        (donation.data().amount / 100).toFixed(2),
                                        ' ')),
                                React.createElement("td", { className: "px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-gray-900" }, moment(donation.data()
                                    .timestamp.toDate()).format('MMM. D, Y')),
                                stateObj && stateObj.hasSubscribers && (React.createElement("td", { className: "flex justify-center px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-gray-900" }, donation.data().isSubscriber && React.createElement(Check, null))),
                                React.createElement("td", { className: "px-6 py-4 text-left text-sm leading-5 text-gray-900" },
                                    React.createElement("span", { className: "text-gray-900" },
                                        !donation.data().isAnonymous
                                            ? donation.data().message
                                            : '',
                                        ' ')),
                                React.createElement("td", { className: "px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-gray-900" }, !donation.data().isAnonymous &&
                                    donation.data().message && (React.createElement("input", { id: "display-message", type: "checkbox", className: "form-checkbox h-4 w-4 ml-2 text-gray-600 transition duration-150 ease-in-out", checked: donation.data().displayMessage !== false, onClick: () => updateDisplayMessage(donation) })))))))),
                        React.createElement("nav", { className: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" },
                            React.createElement("div", { className: "hidden sm:block" },
                                React.createElement("p", { className: "text-sm leading-5 text-gray-700" },
                                    "Showing",
                                    ' ',
                                    React.createElement("span", { className: "font-medium" }, pageNumber * PAGE_SIZE + 1),
                                    ' ',
                                    "to",
                                    ' ',
                                    React.createElement("span", { className: "font-medium" }, pageNumber * PAGE_SIZE + PAGE_SIZE > donations.length
                                        ? donations.length
                                        : pageNumber * PAGE_SIZE + PAGE_SIZE),
                                    ' ',
                                    "of",
                                    ' ',
                                    React.createElement("span", { className: "font-medium" }, donations ? donations.length : '0'),
                                    ' ',
                                    "results")),
                            React.createElement("div", { className: "flex-1 flex justify-between sm:justify-end" },
                                React.createElement("div", { className: `${pageNumber > 0 ? 'cursor-pointer' : 'cursor-not-allowed'} relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => pageNumber > 0 && setPageNumber(pageNumber - 1) }, "Previous"),
                                React.createElement("div", { className: `${(pageNumber + 1) * PAGE_SIZE < donations.length
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'} ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => (pageNumber + 1) * PAGE_SIZE < donations.length &&
                                        setPageNumber(pageNumber + 1) }, "Next")))))))));
};
export default CampaignDashboard;
