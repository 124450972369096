import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router';
import PlacementActions from 'redux/placement';
import AuthActions from 'redux/auth';
import ToastActions from 'redux/toast';
import PlaceNotice from './PlaceNotice';
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path)),
    replace: (path) => dispatch(replace(path)),
    placementActions: bindActionCreators(PlacementActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch),
    toastActions: bindActionCreators(ToastActions, dispatch)
});
const mapStateToProps = (state) => {
    var _a, _b, _c;
    return ({
        activeOrganization: state.auth.activeOrganization,
        isPublisher: state.auth.isPublisher,
        authLoading: state.auth.loading,
        userAuth: state.auth.userAuth,
        user: state.auth.user,
        isAnonymous: (_a = state.auth.userAuth) === null || _a === void 0 ? void 0 : _a.isAnonymous,
        orgContext: state.auth.orgContext,
        placement: state.placement,
        id: (((_b = matchPath(state.router.location.pathname, {
            path: '/place/:id/',
            exact: true,
            strict: false
        })) === null || _b === void 0 ? void 0 : _b.params) || {}).id,
        toastText: (_c = state.toast) === null || _c === void 0 ? void 0 : _c.toastText
    });
};
export default connect(mapStateToProps, mapDispatchToProps)(PlaceNotice);
