var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import { OrganizationType } from 'lib/enums';
import tableIcons from 'components/tableIcons';
import Firebase, { Collections } from '../../../EnoticeFirebase';
const AssociationMemberSettings = ({ activeOrganization }) => {
    const [publishers, setPublishers] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const pullPapers = () => __awaiter(void 0, void 0, void 0, function* () {
            const paperQuery = yield Firebase.firestore()
                .collection(Collections.organizations)
                .where('organizationType', '==', OrganizationType.newspaper.value)
                .where('state', '==', activeOrganization.data().state)
                .get();
            const paperData = yield Promise.all(paperQuery.docs.map((p) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                const creator = yield Firebase.firestore()
                    .collection(Collections.users)
                    .doc(p.data().createdBy)
                    .get();
                return Object.assign(Object.assign({}, p.data()), { publisherEmail: ((_a = creator.data()) === null || _a === void 0 ? void 0 : _a.email) || '', publisherName: ((_b = creator.data()) === null || _b === void 0 ? void 0 : _b.name) || '' });
            })));
            setPublishers(paperData);
            setLoading(false);
        });
        void pullPapers();
    }, []);
    return (React.createElement(Grid, { container: true, direction: "column", item: true, xs: 10 },
        React.createElement(Grid, { container: true, direction: "row", alignContent: "space-between", justify: "space-between" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "h5" }, "Member Newspapers"))),
        React.createElement(Box, { mt: 4 },
            React.createElement(MaterialTable, { title: `${activeOrganization.data().name} Newspapers`, icons: tableIcons, columns: [
                    {
                        title: 'Name of Newspaper',
                        field: 'name'
                    },
                    {
                        title: 'County',
                        field: 'county'
                    },
                    {
                        title: 'Publisher Name',
                        field: 'publisherName'
                    },
                    {
                        title: 'Publisher Email',
                        field: 'publisherEmail'
                    }
                ], data: loading ? [] : publishers }))));
};
export default AssociationMemberSettings;
