import { BillingStatusType, TimezoneType, Day, Frequency, GovernmentType, LawType, MailStatus, NoticeStatusType, NoticeType, NotificationType, OccupationType, OrganizationType, RateType, RoleType, State, XMLReplacementTagType, ExportFormatType, AdType, BankAccountVerification, InvoiceStatus, TransactionType, ELabels, SubdomainStatus, CurrencyType, Country, DwollaTransferStatus, DwollaCustomerStatus, InvoiceItemType, PayoutStatusType } from '../../lib/enums';
export function handleEnums(name, value) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    let returnValue;
    if (name === 'occupation') {
        returnValue = (_a = OccupationType.by_value(value)) === null || _a === void 0 ? void 0 : _a.label;
    }
    else if (name === 'role') {
        returnValue = (_b = RoleType.by_value(value)) === null || _b === void 0 ? void 0 : _b.label;
    }
    else if (name === 'state') {
        returnValue = (_c = State.by_value(value)) === null || _c === void 0 ? void 0 : _c.label;
    }
    else if (name === 'country') {
        returnValue = (_d = Country.by_value(value)) === null || _d === void 0 ? void 0 : _d.label;
    }
    else if (name === 'noticeType' || name === 'previousNoticeType') {
        returnValue = (_e = NoticeType.by_value(value)) === null || _e === void 0 ? void 0 : _e.label;
    }
    else if (name === 'type') {
        returnValue = (_f = AdType.by_value(value)) === null || _f === void 0 ? void 0 : _f.label;
    }
    // CHECK
    else if (name === 'billingStatus') {
        returnValue = (_g = BillingStatusType.by_value(value)) === null || _g === void 0 ? void 0 : _g.label;
    }
    else if (name === 'timezone') {
        returnValue = (_h = TimezoneType.by_value(value)) === null || _h === void 0 ? void 0 : _h.label;
    }
    else if (name === 'dayEnum') {
        returnValue = (_j = Day.by_value(value)) === null || _j === void 0 ? void 0 : _j.label;
    }
    else if (name === 'frequency') {
        returnValue = (_k = Frequency.by_value(value)) === null || _k === void 0 ? void 0 : _k.label;
    }
    else if (name === 'government') {
        returnValue = (_l = GovernmentType.by_value(value)) === null || _l === void 0 ? void 0 : _l.label;
    }
    else if (name === 'law') {
        returnValue = (_m = LawType.by_value(value)) === null || _m === void 0 ? void 0 : _m.label;
    }
    else if (name === 'mailStatus') {
        returnValue = (_o = MailStatus.by_value(value)) === null || _o === void 0 ? void 0 : _o.label;
    }
    else if (name === 'noticeStatus') {
        returnValue = (_p = NoticeStatusType.by_value(value)) === null || _p === void 0 ? void 0 : _p.label;
    }
    else if (name === 'notificationType') {
        returnValue = (_q = NotificationType.by_value(value)) === null || _q === void 0 ? void 0 : _q.label;
    }
    else if (name === 'organizationType') {
        returnValue = (_r = OrganizationType.by_value(value)) === null || _r === void 0 ? void 0 : _r.label;
    }
    else if (name === 'rateType') {
        returnValue = (_s = RateType.by_value(value)) === null || _s === void 0 ? void 0 : _s.label;
    }
    else if (name === 'XMLReplacementTagType') {
        returnValue = (_t = XMLReplacementTagType.by_value(value)) === null || _t === void 0 ? void 0 : _t.label;
    }
    else if (name === 'ExportFormatType') {
        returnValue = (_u = ExportFormatType.by_value(value)) === null || _u === void 0 ? void 0 : _u.label;
    }
    else if (name === 'BankAccountVerification') {
        returnValue = (_v = BankAccountVerification.by_value(value)) === null || _v === void 0 ? void 0 : _v.label;
    }
    else if (name === 'invoiceStatus') {
        returnValue = (_w = InvoiceStatus.by_value(value)) === null || _w === void 0 ? void 0 : _w.label;
    }
    else if (name === 'transactionType') {
        returnValue = (_x = TransactionType.by_value(value)) === null || _x === void 0 ? void 0 : _x.label;
    }
    else if (name === 'transactionType') {
        returnValue = (_y = TransactionType.by_value(value)) === null || _y === void 0 ? void 0 : _y.label;
    }
    else if (name === 'ELabels') {
        returnValue = (_z = ELabels.by_value(value)) === null || _z === void 0 ? void 0 : _z.label;
    }
    else if (name === 'subdomainStatus') {
        returnValue = (_0 = SubdomainStatus.by_value(value)) === null || _0 === void 0 ? void 0 : _0.label;
    }
    else if (name === 'currency') {
        returnValue = (_1 = CurrencyType.by_value(value)) === null || _1 === void 0 ? void 0 : _1.label;
    }
    else if (name === 'DwollaTransferStatus') {
        returnValue = (_2 = DwollaTransferStatus.by_value(value)) === null || _2 === void 0 ? void 0 : _2.label;
    }
    else if (name === 'DwollaCustomerStatus') {
        returnValue = (_3 = DwollaCustomerStatus.by_value(value)) === null || _3 === void 0 ? void 0 : _3.label;
    }
    else if (name === 'InvoiceItemType') {
        returnValue = (_4 = InvoiceItemType.by_value(value)) === null || _4 === void 0 ? void 0 : _4.label;
    }
    else if (name === 'PayoutStatusType') {
        returnValue = (_5 = PayoutStatusType.by_value(value)) === null || _5 === void 0 ? void 0 : _5.label;
    }
    else {
        return [name, value, 'enum'];
    }
    return [name, `${value} (${returnValue})`, 'enum'];
}
