import sanitizeFilename from 'sanitize-filename';
import * as Sentry from '@sentry/browser';
import { removeUndefinedFields } from 'lib/helpers';
import moment from 'moment';
export const removeDuplicatesFromArray = (array) => {
    return [...new Set(array)];
};
export const guidGenerator = () => {
    const S4 = function () {
        return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    };
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};
export const truncateString = (s, maxLength = 25) => s.length > maxLength ? `${s.slice(0, maxLength)}...` : s;
export const sanitize = (str) => sanitizeFilename(str)
    .replace(new RegExp(/[^a-zA-Z\d:.]/g), '_')
    .toLowerCase();
export const stripHtml = (str) => {
    if (!str)
        return '';
    return str.replace(/<[^>]*>?/gm, '');
};
// vaidate if the object is of Error type or not
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
const isError = (err) => {
    return Object.prototype.toString.call(err) === '[object Error]';
};
// handles frontend error logging by combining sentry and console.log functions
export const logAndCaptureException = (e, msg, tags = {}) => {
    var _a;
    removeUndefinedFields(tags);
    console.error(msg, e, tags);
    for (const k of Object.keys(tags)) {
        Sentry.setTag(k, (_a = tags[k]) !== null && _a !== void 0 ? _a : '');
    }
    if (isError(e)) {
        Sentry.captureException(e);
    }
    else {
        Sentry.captureException(new Error(JSON.stringify(e)));
    }
};
export const logAndCaptureMessage = (msg, tags = {}) => {
    console.warn(msg, JSON.stringify(tags));
    Sentry.captureMessage(msg);
};
export const calculateTimeDifferenceFromTimestamp = (time) => {
    if (!time)
        return;
    const createdDate = moment(time.toDate());
    return moment(createdDate).fromNow();
};
/**
 * Downloads contents in the browser as a file
 * @param filename
 * @param contents
 * @param contentType
 */
export const downloadFileContentsInBrowser = (filename, contents, contentType = 'text/plain') => {
    const blob = new Blob([contents], { type: contentType });
    const link = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    link.click();
};
