import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import Drawer from 'components/Drawer';
import RateDrawerHeader from './RateDrawerHeader';
import RateDrawerBody from './RateDrawerBody';
/**
 * Drawer component for seeing details on a particular rate
 */
export default function RateDrawer({ activeOrganization, setEditedRateData, setEditedRateRef, drawerRate, closeDrawer }) {
    const [rate, setRate] = useState();
    useEffect(() => {
        const unsub = drawerRate.ref.onSnapshot(rate => {
            if (exists(rate))
                setRate(rate);
        });
        return () => unsub();
    }, [drawerRate.id]);
    return (React.createElement(Drawer, { closeDrawer: () => closeDrawer(), header: React.createElement(RateDrawerHeader, { activeOrganization: activeOrganization, rate: drawerRate }), open: true }, rate && !(rate === null || rate === void 0 ? void 0 : rate.data().additionalFees) ? (
    // TODO: Add support for additionalFees in rateDrawerBody after additionalFee migration
    React.createElement(RateDrawerBody, { activeOrganization: activeOrganization, setEditedRate: editedRate => {
            setEditedRateData(editedRate.data());
            setEditedRateRef(editedRate.ref);
        }, rate: rate })) : (rate === null || rate === void 0 ? void 0 : rate.data().additionalFees) ? (React.createElement("p", null,
        "Contact ",
        React.createElement("a", { href: "help@column.us" }, "help@column.us"),
        " to administer this rate")) : (React.createElement(LoadingState, null))));
}
