import React from 'react';
import SettingsHeader from './SettingsHeader';
import { FileDownload } from './icons';
const UserSettingsDocuments = () => {
    const documents = [
        {
            display_title: 'W-9 Form',
            url: 'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/Column_Enotice_Updated_W9.pdf?invalidate=true'
        }
    ];
    return (React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
        React.createElement(SettingsHeader, { header: "Documents", description: "Download legal and financial documents from Column." }),
        React.createElement(React.Fragment, null,
            React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "px-5 pr-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "document"),
                        React.createElement("th", { className: "px-5 py-3 bg-gray-50 uppercase text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "actions"))),
                documents && (React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, documents &&
                    documents.map((document, index) => {
                        return (React.createElement("tr", { key: index },
                            React.createElement("td", { className: "pl-5 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, document.display_title),
                            React.createElement("td", { className: "flex items-end px-6 py-6 float-right whitespace-no-wrap text-right text-sm leading-5 font-medium text-gray-900" },
                                React.createElement("a", { href: document.url, rel: "noopener noreferrer", target: "_blank", className: "cursor-pointer ml-4" },
                                    React.createElement("div", { className: "w-9/12" }, FileDownload)))));
                    })))))));
};
export default UserSettingsDocuments;
