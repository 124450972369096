import { useEffect } from 'react';
/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideClickHook = (ref, onClick) => {
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClick();
        }
    };
    useEffect(() => {
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    });
};
export default useOutsideClickHook;
