var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Select from 'react-select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { TextField, MenuItem, Typography, Chip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { BROWSER, BROWSERS } from '../../constants';
const styles = (theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        display: 'flex',
        flex: 1
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    input: {
        display: 'flex'
    },
    valueContainer: {
        display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: 25
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
        maxWidth: 180
    },
    chipFocused: {
        backgroundColor: emphasize(theme.palette.type === 'light'
            ? theme.palette.grey[300]
            : theme.palette.grey[700], 0.08)
    },
    noOptionsMessage: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    },
    singleValue: {
        fontSize: 16
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16
    },
    paper: {
        position: 'absolute',
        Index: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    divider: {
        height: theme.spacing(2)
    }
});
const NoOptionsMessage = (props) => {
    return (React.createElement(Typography, Object.assign({ color: "textSecondary", className: props.selectProps.classes.noOptionsMessage }, props.innerProps), props.children));
};
const inputComponent = (_a) => {
    var { inputRef } = _a, props = __rest(_a, ["inputRef"]);
    return (React.createElement("div", Object.assign({ ref: inputRef }, props)));
};
const Control = (props) => (React.createElement(TextField, Object.assign({ fullWidth: true, InputProps: {
        inputComponent,
        inputProps: Object.assign({ className: props.selectProps.classes.input, inputRef: props.innerRef, children: props.children }, props.innerProps)
    } }, props.selectProps.textFieldProps)));
const Option = (props) => (React.createElement(MenuItem, Object.assign({ buttonRef: props.innerRef, selected: props.isFocused, component: "div", style: {
        fontWeight: props.isSelected ? 500 : 400
    } }, props.innerProps), props.children));
const Placeholder = (props) => (React.createElement(Typography, Object.assign({ color: "textSecondary", className: props.selectProps.classes.placeholder }, props.innerProps), props.children));
const SingleValue = (props) => (React.createElement(Typography, Object.assign({ className: props.selectProps.classes.singleValue }, props.innerProps), props.children));
const ValueContainer = (props) => (React.createElement("div", { className: props.selectProps.classes.valueContainer }, props.children));
const MultiValue = (props) => (React.createElement(Chip, { tabIndex: -1, label: props.children, className: classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
    }), onDelete: () => {
        props.setValue(props
            .getValue()
            .filter((v) => v.label !== props.children));
    }, deleteIcon: React.createElement(CancelIcon, Object.assign({}, props.removeProps)) }));
const components = {
    Control,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};
const SelectTypeahead = ({ classSelector, classes, theme, value, updateValue, options, placeholder, menuPlacement, label, isMulti, id }) => {
    const selectStyles = {
        input: (base) => (Object.assign(Object.assign({}, base), { color: theme.palette.text.primary, '& input': {
                font: 'inherit'
            } }))
    };
    return (React.createElement(Select, { id: id, classes: classes, components: components, styles: selectStyles, options: options, className: classSelector || 'react-select', classNamePrefix: classSelector || 'react-select', textFieldProps: {
            label,
            InputLabelProps: {
                shrink: true
            }
        }, isOptionSelected: (option, options) => options.findIndex(o => o.label === option.label) !== -1, value: value || null, placeholder: placeholder, onChange: (newValue) => updateValue(newValue), isClearable: !isMulti, menuPlacement: menuPlacement || 'auto', isMulti: isMulti }));
};
export default withStyles(styles, { withTheme: true })(SelectTypeahead);
