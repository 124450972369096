var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import Validator from 'validator';
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
// dwolla customer details
const INITIAL_USER_DETAILS = {
    firstName: '',
    lastname: '',
    email: '',
    businessName: ''
};
const DwollaCustomerModal = ({ showCustomerModal, updateOnSuccess, organization, customerData }) => {
    const [dwollaCustomerDetails, setDwollaCustomerDetails] = useState(INITIAL_USER_DETAILS);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [termsChecked, setTermsChecked] = useState(false);
    const allAlphabets = (inputText) => {
        const letters = /^[A-Za-z]+$/;
        if (inputText === '')
            return true;
        if (letters.test(inputText))
            return true;
        return false;
    };
    useEffect(() => {
        if (!customerData) {
            setDwollaCustomerDetails(Object.assign(Object.assign({}, dwollaCustomerDetails), { businessName: organization.data().name }));
            return;
        }
        setDwollaCustomerDetails(Object.assign(Object.assign({}, dwollaCustomerDetails), { firstName: customerData.firstName, lastname: customerData.lastName, email: customerData.email, businessName: customerData.businessName }));
    }, []);
    // this will create a new dwolla customer
    const addDwollaCustomer = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setError('');
            setLoading(true);
            const response = yield api.post('dwolla/creating-dwolla-customer', dwollaCustomerDetails);
            setLoading(false);
            if (response.success) {
                updateOnSuccess();
            }
            else if (!response.success && (response === null || response === void 0 ? void 0 : response.error) === 'ValidationError') {
                setError(response.message);
            }
            else {
                setError('There is some issue while validating customer information.');
            }
        }
        catch (err) {
            setLoading(false);
            logAndCaptureException(err, 'Failed to add Dwolla customer', {
                dwollaCustomerEmail: dwollaCustomerDetails.email
            });
            setError('There is some issue while creating customer.');
        }
    });
    return (React.createElement("div", null,
        React.createElement(FreeformCModal, { header: !customerData ? 'Customer Information' : 'Verify Personal Information', setOpen: () => {
                showCustomerModal();
            }, customStyle: 'px-2', noExitOutsideModal: true, width: '30%' },
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    if (!isValidEmail)
                        return;
                    if (!(allAlphabets(dwollaCustomerDetails.firstName) &&
                        allAlphabets(dwollaCustomerDetails.lastname))) {
                        setError('First and last name should be only alphabets.');
                        return;
                    }
                    if (!customerData)
                        void addDwollaCustomer();
                } },
                React.createElement("div", { className: "overflow-y-auto py-2 mt-2 px-2" },
                    React.createElement("div", { className: "text-gray-700 mb-4 text-sm -mt-3" }, !customerData && 'Enter your information below to get started.'),
                    React.createElement("div", { className: "flex mb-2" },
                        React.createElement("input", { id: "first-name", className: "mr-1 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", type: "text", placeholder: "First Name *", required: true, value: dwollaCustomerDetails.firstName, onChange: e => setDwollaCustomerDetails(Object.assign(Object.assign({}, dwollaCustomerDetails), { firstName: e.target.value })) }),
                        React.createElement("input", { id: "last-name", className: "ml-1 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", type: "text", placeholder: "Last Name *", required: true, value: dwollaCustomerDetails.lastname, onChange: e => setDwollaCustomerDetails(Object.assign(Object.assign({}, dwollaCustomerDetails), { lastname: e.target.value })) })),
                    React.createElement("div", { className: "mb-2" },
                        React.createElement("input", { id: "email", className: `appearance-none rounded relative block w-full px-3 py-2 mb-0 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5 ${!isValidEmail && dwollaCustomerDetails.email
                                ? 'border-red-400'
                                : 'focus:shadow-outline-blue focus:border-blue-300'}`, type: "text", placeholder: "Email *", required: true, value: dwollaCustomerDetails.email, onChange: e => {
                                setDwollaCustomerDetails(Object.assign(Object.assign({}, dwollaCustomerDetails), { email: e.target.value }));
                                setIsValidEmail(Validator.isEmail(e.target.value));
                            } }),
                        !isValidEmail && dwollaCustomerDetails.email && (React.createElement("p", { className: "text-red-500 text-xs" }, "Enter valid email address."))),
                    !customerData && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "flex items-center mb-4 mt-3" },
                            React.createElement("input", { id: "agreeToTerms", type: "checkbox", className: "form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out", checked: termsChecked, onChange: () => setTermsChecked(!termsChecked) }),
                            React.createElement("div", { className: "ml-2 block text-sm leading-4 text-gray-900" },
                                "By checking this box, you agree to our",
                                ' ',
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Flegal%2Fcolumn%2FColumn%20Terms%20of%20Use.pdf?alt=media&token=e0435fa6-5a22-43e8-870e-6b37a4cc71ef", className: "text-blue-600 hover:underline" }, "Terms of Service"),
                                ' ',
                                "and",
                                ' ',
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://enotice-production.imgix.net/custom-documents/permalink/4953.6bc4e-Column%20Privacy%20Policy.pdf", className: "text-blue-600 hover:underline" }, "Privacy Policy"),
                                ", as well as our partner Dwolla's",
                                ' ',
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.dwolla.com/legal/tos/", className: "text-blue-600 hover:underline" }, "Terms of Service"),
                                ' ',
                                "and",
                                ' ',
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.dwolla.com/legal/privacy/", className: "text-blue-600 hover:underline" }, "Privacy Policy"),
                                ".")),
                        error && (React.createElement("div", { className: "text-red-500 text-sm mb-3" }, error)),
                        React.createElement("button", { className: `rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${!termsChecked
                                ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                                : 'bg-blue-500'}`, type: "submit", disabled: loading && !termsChecked },
                            React.createElement("span", { className: "flex" },
                                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Agree & Continue")))))))));
};
export default DwollaCustomerModal;
