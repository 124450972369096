var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import Validator from 'validator';
import StepsCounter from 'routes/register/StepsCounter';
import { PlusCircleIcon } from 'icons/index';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import RegistrationHeader from 'routes/register/RegistrationHeader';
import api from 'api';
import { OrganizationType, RoleType } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import { safeStringify } from 'lib/utils/stringify';
import AdvertiserRegistrationSuccess from 'routes/register/AdvertiserRegistrationSuccess';
import backgroundImg from '../register/backgroundImg.svg';
import AdvertisersInviteInputRow from './AdvertisersInviteInputRow';
import CreateInvitesResult from './CreateInvitesResult';
const AdvertisersInvitesForm = ({ user, onSkipForNowClick, organization }) => {
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInvitationFailedModal, setShowInvitationFailedModal] = useState(false);
    const [invitesData, setInvitesData] = useState();
    const DUPLICATE_EMAIL_ERROR = 'Do not enter duplicate emails.';
    const [values, setValues] = useState([
        { email: null, role: null }
    ]);
    const disable = values.some(val => {
        return val.email === null || val.email === '' || val.role === null;
    });
    useEffect(() => {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        setErrors(values.map(_ => ''));
    }, [safeStringify(values)]);
    const confirmInvites = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const emails = values.map(val => { var _a; return (_a = val.email) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
        const isEmailValid = values.map(val => val.email ? Validator.isEmail(val.email) : true);
        const errorsFound = values.map((v, i) => {
            if (!isEmailValid[i])
                return 'Email is invalid';
            const duplicateEmail = emails.filter(email => email === v.email).length;
            if (duplicateEmail > 1)
                return DUPLICATE_EMAIL_ERROR;
            return '';
        });
        if (errorsFound.some(e => !!e)) {
            setErrors(errorsFound);
            return;
        }
        setLoading(true);
        const req = {
            inviteData: values,
            organizationId: organization.id,
            isExistingUserInvite: false
        };
        const result = yield api.post('users/invite', req);
        const inviteResponseData = result.data;
        setInvitesData(inviteResponseData);
        setLoading(false);
        const isInvitedSuccessfully = (inviteResponseData.invited.length ||
            inviteResponseData.invitesUnsnoozed) &&
            !inviteResponseData.alreadyHaveInvitesToCurrentOrg.length &&
            !inviteResponseData.alreadyMembersOfInvitedOrg.length &&
            !inviteResponseData.alreadyLinkedToIncompatibleOrganization.length &&
            !inviteResponseData.alreadyInvitedToIncompatibleOrganization.length;
        if (isInvitedSuccessfully) {
            setSuccess(true);
        }
        else {
            setShowInvitationFailedModal(true);
        }
    });
    const onConfirm = () => {
        onSkipForNowClick && onSkipForNowClick();
    };
    const onAddClick = () => {
        setValues([...values, { email: null, role: null }]);
    };
    const onRoleSelect = (i, roleValue) => {
        var _a, _b;
        const currValues = [...values];
        currValues[i].role = (_b = (_a = RoleType.by_label(roleValue)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
        setValues(currValues);
    };
    const onChange = (i, value) => {
        const currValues = [...values];
        currValues[i].email = value;
        setValues(currValues);
    };
    const onRemoveClick = (i) => {
        const currValues = [...values];
        currValues.splice(i, 1);
        setValues(currValues);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "grid" },
            React.createElement("img", { src: backgroundImg, className: "col-start-1 row-start-1 self-center w-full h-screen bg-gray-50", alt: "Background" }),
            React.createElement("div", { className: "col-start-1 row-start-1" },
                React.createElement(RegistrationHeader, null),
                !success ? (React.createElement("div", { className: "min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 -mt-12" },
                    React.createElement(StepsCounter, { maxStep: '4', stepCount: '4', isStepDone: !disable }),
                    React.createElement("div", { className: "mt-8 text-center" },
                        React.createElement("p", { className: "text-gray-900 font-semibold leading-8 text-2xl" }, "Get your teammates onboard"),
                        React.createElement("p", { className: "text-gray-600 font-medium text-sm leading-6 w-128 mt-4" }, "One last step. Invite anyone responsible for managing your organization's public notices and payments.")),
                    React.createElement("div", { className: "flex flex-col mt-10 border rounded-md shadow bg-white py-4 px-8 w-3/6 max-h-1/4 overflow-y-scroll" },
                        React.createElement("div", { className: "flex flex-col justify-center" }, values.map((el, i) => (React.createElement("div", { key: `email-${i}` },
                            React.createElement(AdvertisersInviteInputRow, { index: i, value: el, loading: loading, onValueChange: (e) => onChange(i, e.target.value), onRoleSelect: roleValue => onRoleSelect(i, roleValue), onRemoveClick: () => onRemoveClick(i), error: errors && errors[i] }))))),
                        React.createElement("div", { className: "flex items-center mt-6 mb-6 text-blue-500", onClick: () => onAddClick() },
                            React.createElement(PlusCircleIcon, null),
                            React.createElement("div", { className: "ml-2 cursor-pointer not-italic font-medium text-sm flex items-center" }, "Add another team member"))))) : (React.createElement(AdvertiserRegistrationSuccess, { userName: user.firstName, subHeaderText: `Your account is all set to place and manage notices.`, onConfirmSuccess: onConfirm })),
                showInvitationFailedModal && invitesData && (React.createElement(TailwindModal, { noExitOutsideModal: true, header: 'Oops! The invitation was not sent.', close: () => setShowInvitationFailedModal(false) },
                    React.createElement(CreateInvitesResult, { alreadyHaveInvitesToCurrentOrg: invitesData.alreadyHaveInvitesToCurrentOrg, alreadyLinkedToIncompatibleOrg: invitesData.alreadyLinkedToIncompatibleOrganization, alreadyInvitedToIncompatibleOrg: invitesData.alreadyInvitedToIncompatibleOrganization, alreadyMembersOfInvitedOrg: invitesData.alreadyMembersOfInvitedOrg, backClick: () => setShowInvitationFailedModal(false), emailsLinkedToNotices: invitesData.emailsLinkedToNotices.length
                            ? organization.data().organizationType ===
                                OrganizationType.newspaper.value
                            : false }))))),
        !success && (React.createElement(AdvertiserRegistrationFooter, { id: 'confirm-invite', backButtonText: 'Skip for now', nextButtonText: "Send invitation", onBackButtonClick: () => setSuccess(true), onNextButtonClick: confirmInvites, loading: loading, disableNextButton: disable }))));
};
export default AdvertisersInvitesForm;
