import { Day } from '../../../lib/enums';
const GetDefaultDeadlines = () => {
    return [
        {
            dayEnum: Day.sunday.value,
            publish: false,
            deadline: {
                dayEnum: Day.sunday.value,
                time: '12:00'
            }
        },
        {
            dayEnum: Day.monday.value,
            publish: true,
            deadline: {
                dayEnum: Day.monday.value,
                time: '12:00'
            }
        },
        {
            dayEnum: Day.tuesday.value,
            publish: false,
            deadline: {
                dayEnum: Day.tuesday.value,
                time: '12:00'
            }
        },
        {
            dayEnum: Day.wednesday.value,
            publish: false,
            deadline: {
                dayEnum: Day.wednesday.value,
                time: '12:00'
            }
        },
        {
            dayEnum: Day.thursday.value,
            publish: false,
            deadline: {
                dayEnum: Day.thursday.value,
                time: '12:00'
            }
        },
        {
            dayEnum: Day.friday.value,
            publish: false,
            deadline: {
                dayEnum: Day.friday.value,
                time: '12:00'
            }
        },
        {
            dayEnum: Day.saturday.value,
            publish: false,
            deadline: {
                dayEnum: Day.saturday.value,
                time: '12:00'
            }
        }
    ];
};
export default GetDefaultDeadlines;
