import React, { useState } from 'react';
import Invoices from './Invoices';
import Notices from './Notices';
const NOTICES_TAB = 'notices';
const INVOICES_TAB = 'invoices';
const Payments = ({ user, organization }) => {
    const [activeTab, setActiveTab] = useState(NOTICES_TAB);
    return (React.createElement("div", { style: {
            marginTop: 64,
            height: 'calc(100vh - 64px)',
            width: '100vw'
        } },
        React.createElement("div", { className: "flex h-full" },
            React.createElement("div", { className: "flex flex-col max-w-screen-lg w-full m-20" },
                React.createElement("div", { className: "overflow-auto -my-2 sm:-mx-6 lg:-mx-8 h-full" },
                    React.createElement("div", { className: "hidden sm:block" },
                        React.createElement("div", { className: "border-b border-gray-200" },
                            React.createElement("nav", { className: "-mb-px flex" },
                                React.createElement("div", { className: `cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none ${activeTab === NOTICES_TAB
                                        ? 'text-blue-700 border-blue-700 focus:text-blue-800 focus:border-blue-800'
                                        : 'focus:text-gray-700 focus:border-gray-300'}`, onClick: () => setActiveTab(NOTICES_TAB) }, "Notices"),
                                React.createElement("div", { className: `cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none ${activeTab === INVOICES_TAB
                                        ? 'text-blue-700 border-blue-700 focus:text-blue-800 focus:border-blue-800'
                                        : 'focus:text-gray-700 focus:border-gray-300'}`, onClick: () => setActiveTab(INVOICES_TAB) }, "Invoices")))),
                    activeTab === INVOICES_TAB && (React.createElement(Invoices, Object.assign({}, { user, organization }))),
                    activeTab === NOTICES_TAB && (React.createElement(Notices, Object.assign({}, { user, organization }))))))));
};
export default Payments;
