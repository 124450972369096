var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { DotIcon } from 'icons';
import { RoleType } from 'lib/enums';
import { getFirebaseContext } from '../../utils/firebase';
import Drawer from '../Drawer';
import HorizontalDivider from '../HorizontalDivider';
import EditCustomerOrganizationModal from './EditCustomerOrganizationModal';
import { CustomerDrawerDetailsTab } from './CustomerDrawerDetailsTab';
import { CustomerOrganizationDrawerMembersTab } from './CustomerOrganizationDrawerMembersTab';
import { CustomerDrawerSettingsTab } from './CustomerDrawerSettingsTab';
import { CustomerDrawerFooter } from './CustomerDrawerFooter';
import { CustomerDrawerHeader } from './CustomerDrawerHeader';
const CustomerOrganizationDrawer = ({ customerOrganizationData, activeOrganization, open, setOpen, testing, disableDarkenBackground, setToastText, setShowCustomerDrawer, setCustomer }) => {
    const [customerOrganizationEdits, setCustomerOrganizationEdits] = useState();
    const [title, setTitle] = useState('Details');
    const [customerOrganizationName, setCustomerOrganizationName] = useState('');
    const [allowBulkInvoicing, setAllowBulkInvoicing] = useState();
    const [address, setAddress] = useState();
    const [addressLine2, setAddressLine2] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState();
    const [phone, setPhone] = useState();
    const [internalID, setInternalID] = useState();
    const DEFAULT_SETTING = 0;
    const ALWAYS_REQUIRE = 1;
    const NEVER_REQUIRE = 2;
    const [requireUpfrontPaymentId, setRequireUpfrontPaymentId] = useState(DEFAULT_SETTING);
    const [customers, setCustomers] = useState();
    const [loadingCustomers, setLoadingCustomers] = useState(false);
    const [customerEmails, setCustomerEmails] = useState([]);
    const [customerRoles, setCustomerRoles] = useState([]);
    const [numberOfNoticesPlaced, setNumberOfNoticesPlaced] = useState(0);
    const [showEditCustomerOrganizationModal, setShowEditCustomerOrganizationModal] = useState();
    const REQUIRE_UPFRONT_PAYMENT_OPTIONS_ARRAY = [
        'Default',
        'Always require',
        'Never require'
    ];
    const ctx = getFirebaseContext();
    const closeDrawer = () => {
        setOpen(!open);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13;
        const getCustomersFromCustomerOrg = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoadingCustomers(true);
            const usersInOrg = yield ctx
                .usersRef()
                .where('allowedOrganizations', 'array-contains', customerOrganizationData.clientOrganization.ref)
                .get();
            const userSnapshots = usersInOrg.docs;
            const customerFromCustomerOrg = yield Promise.all(userSnapshots.map((user) => __awaiter(void 0, void 0, void 0, function* () {
                const customer = yield ctx
                    .customersRef()
                    .where('user', '==', user.ref)
                    .where('organization', '==', activeOrganization.ref)
                    .where('verified', '==', true)
                    .get();
                if (customer.docs.length) {
                    return customer.docs[0];
                }
                return null;
            })));
            const filteredCustomerFromCustomerOrg = customerFromCustomerOrg.filter(customer => {
                return customer !== null;
            });
            const customerFromCustomerOrgEmails = yield Promise.all(filteredCustomerFromCustomerOrg.map((customer) => __awaiter(void 0, void 0, void 0, function* () {
                var _14;
                const user = yield (customer === null || customer === void 0 ? void 0 : customer.data().user.get());
                return ((_14 = user === null || user === void 0 ? void 0 : user.data()) === null || _14 === void 0 ? void 0 : _14.email) || '--';
            })));
            const customerFromCustomerOrgRoles = (yield Promise.all(filteredCustomerFromCustomerOrg.map((customer) => __awaiter(void 0, void 0, void 0, function* () {
                var _15, _16, _17;
                const user = yield (customer === null || customer === void 0 ? void 0 : customer.data().user.get());
                const userRole = ((_15 = user === null || user === void 0 ? void 0 : user.data()) === null || _15 === void 0 ? void 0 : _15.roles)
                    ? (_16 = user === null || user === void 0 ? void 0 : user.data()) === null || _16 === void 0 ? void 0 : _16.roles[customerOrganizationData.clientOrganization.id]
                    : RoleType.user.value;
                return (_17 = RoleType.by_value(userRole)) === null || _17 === void 0 ? void 0 : _17.label;
            })))).filter((role) => role !== undefined);
            setCustomers(filteredCustomerFromCustomerOrg);
            setCustomerEmails(customerFromCustomerOrgEmails);
            setCustomerRoles(customerFromCustomerOrgRoles);
            setLoadingCustomers(false);
        });
        const getNumberOfNoticesPlaced = () => __awaiter(void 0, void 0, void 0, function* () {
            const notices = yield ctx
                .userNoticesRef()
                .where('filedBy', '==', customerOrganizationData.clientOrganization.ref)
                .where('newspaper', '==', activeOrganization.ref)
                .get();
            setNumberOfNoticesPlaced(notices.docs.length || 0);
        });
        if (!testing) {
            void getCustomersFromCustomerOrg();
            void getNumberOfNoticesPlaced();
        }
        // Update state vars on CustomerData update
        if (customerOrganizationEdits) {
            setCustomerOrganizationName(customerOrganizationEdits.name || '--');
            setRequireUpfrontPaymentId((customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment) === null ||
                (customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment) === undefined
                ? DEFAULT_SETTING
                : (customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment)
                    ? ALWAYS_REQUIRE
                    : NEVER_REQUIRE);
            setAddress(customerOrganizationEdits.address);
            setAddressLine2(customerOrganizationEdits.addressLine2);
            setCity(customerOrganizationEdits.city);
            setState(customerOrganizationEdits.state);
            setZipCode(customerOrganizationEdits.zipCode);
            setPhone(customerOrganizationEdits.phone);
            setAllowBulkInvoicing(customerOrganizationEdits.bulkPaymentEnabled);
            setInternalID(customerOrganizationEdits.internalID);
        }
        else {
            setCustomerOrganizationName(((_a = customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.name) ||
                (((_b = customerOrganizationData.clientOrganization.data()) === null || _b === void 0 ? void 0 : _b.name) !== ' ' &&
                    ((_c = customerOrganizationData.clientOrganization.data()) === null || _c === void 0 ? void 0 : _c.name)) ||
                '--');
            setRequireUpfrontPaymentId(((_d = customerOrganizationData.customerOrganization.data()) === null || _d === void 0 ? void 0 : _d.requireUpfrontPayment) === null ||
                ((_e = customerOrganizationData.customerOrganization.data()) === null || _e === void 0 ? void 0 : _e.requireUpfrontPayment) === undefined
                ? DEFAULT_SETTING
                : ((_f = customerOrganizationData.customerOrganization.data()) === null || _f === void 0 ? void 0 : _f.requireUpfrontPayment)
                    ? ALWAYS_REQUIRE
                    : NEVER_REQUIRE);
            setAddress(((_g = customerOrganizationData.customerOrganization.data()) === null || _g === void 0 ? void 0 : _g.address) ||
                ((_h = customerOrganizationData.clientOrganization.data()) === null || _h === void 0 ? void 0 : _h.address));
            setAddressLine2(((_j = customerOrganizationData.customerOrganization.data()) === null || _j === void 0 ? void 0 : _j.addressLine2) ||
                (((_k = customerOrganizationData.customerOrganization.data()) === null || _k === void 0 ? void 0 : _k.addressLine2) !== ''
                    ? (_l = customerOrganizationData.clientOrganization.data()) === null || _l === void 0 ? void 0 : _l.addressLine2
                    : ''));
            setCity(((_m = customerOrganizationData.customerOrganization.data()) === null || _m === void 0 ? void 0 : _m.city) ||
                ((_o = customerOrganizationData.clientOrganization.data()) === null || _o === void 0 ? void 0 : _o.city));
            setState(((_p = customerOrganizationData.customerOrganization.data()) === null || _p === void 0 ? void 0 : _p.state) ||
                ((_q = customerOrganizationData.clientOrganization.data()) === null || _q === void 0 ? void 0 : _q.state));
            setZipCode(((_r = customerOrganizationData.customerOrganization.data()) === null || _r === void 0 ? void 0 : _r.zipCode) ||
                ((_s = customerOrganizationData.clientOrganization.data()) === null || _s === void 0 ? void 0 : _s.zipCode));
            setPhone(((_t = customerOrganizationData.customerOrganization.data()) === null || _t === void 0 ? void 0 : _t.phone) ||
                ((_u = customerOrganizationData.clientOrganization.data()) === null || _u === void 0 ? void 0 : _u.phone));
            setAllowBulkInvoicing((_v = customerOrganizationData.customerOrganization.data()) === null || _v === void 0 ? void 0 : _v.bulkPaymentEnabled);
            setInternalID((_w = customerOrganizationData.customerOrganization.data()) === null || _w === void 0 ? void 0 : _w.internalID);
        }
        // Check if we need to override customer data by properties from org or user
        const updates = {
            name: (!((_x = customerOrganizationData.customerOrganization.data()) === null || _x === void 0 ? void 0 : _x.name) ||
                ((_y = customerOrganizationData.customerOrganization.data()) === null || _y === void 0 ? void 0 : _y.name) === ' ') &&
                ((_z = customerOrganizationData.clientOrganization.data()) === null || _z === void 0 ? void 0 : _z.name)
                ? (_0 = customerOrganizationData.clientOrganization.data()) === null || _0 === void 0 ? void 0 : _0.name
                : undefined,
            address: !((_1 = customerOrganizationData.customerOrganization.data()) === null || _1 === void 0 ? void 0 : _1.address)
                ? ((_2 = customerOrganizationData.clientOrganization.data()) === null || _2 === void 0 ? void 0 : _2.address) ||
                    undefined
                : undefined,
            addressLine2: !((_3 = customerOrganizationData.customerOrganization.data()) === null || _3 === void 0 ? void 0 : _3.addressLine2) &&
                ((_4 = customerOrganizationData.customerOrganization.data()) === null || _4 === void 0 ? void 0 : _4.addressLine2) !==
                    ''
                ? ((_5 = customerOrganizationData.clientOrganization.data()) === null || _5 === void 0 ? void 0 : _5.addressLine2) ||
                    undefined
                : undefined,
            city: !((_6 = customerOrganizationData.customerOrganization.data()) === null || _6 === void 0 ? void 0 : _6.city)
                ? ((_7 = customerOrganizationData.clientOrganization.data()) === null || _7 === void 0 ? void 0 : _7.city) || undefined
                : undefined,
            state: !((_8 = customerOrganizationData.customerOrganization.data()) === null || _8 === void 0 ? void 0 : _8.state)
                ? ((_9 = customerOrganizationData.clientOrganization.data()) === null || _9 === void 0 ? void 0 : _9.state) || undefined
                : undefined,
            zipCode: !((_10 = customerOrganizationData.customerOrganization.data()) === null || _10 === void 0 ? void 0 : _10.zipCode)
                ? ((_11 = customerOrganizationData.clientOrganization.data()) === null || _11 === void 0 ? void 0 : _11.zipCode) ||
                    undefined
                : undefined,
            phone: !((_12 = customerOrganizationData.customerOrganization.data()) === null || _12 === void 0 ? void 0 : _12.phone)
                ? ((_13 = customerOrganizationData.clientOrganization.data()) === null || _13 === void 0 ? void 0 : _13.phone) || undefined
                : undefined
        };
        removeUndefinedFields(updates);
        if (Object.keys(updates).length) {
            void customerOrganizationData.customerOrganization.ref.update(updates);
        }
    }, [
        customerOrganizationEdits,
        safeStringify(customerOrganizationData.customerOrganization.data()),
        safeStringify(customerOrganizationData.clientOrganization.data())
    ]);
    const header = (React.createElement("div", { className: "text-gray-800 font-semibold text-xl" }, customerOrganizationName || '--'));
    const subheader = (React.createElement("div", { className: "flex items-center" },
        React.createElement("div", { className: "text-xs leading-5 text-grey-400 font-medium pt-1" },
            "ID: ",
            internalID || '--'),
        React.createElement("div", { className: "mx-3 h-full relative" },
            React.createElement("div", { className: "border-l border-solid border-gray-400", style: {
                    height: '14px',
                    transform: 'translateY(20%)'
                } })),
        React.createElement("div", { className: "pt-0.25" },
            React.createElement("div", { className: "billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-green-50 text-green-625" },
                React.createElement(DotIcon, { className: 'text-green-625' }),
                React.createElement("div", { style: { paddingLeft: '7px' } },
                    numberOfNoticesPlaced,
                    " notices placed")))));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "relative" },
            open && (React.createElement(Drawer, { open: open, closeDrawer: closeDrawer, header: React.createElement("div", null,
                    header,
                    subheader), disableDarkenBackground: disableDarkenBackground },
                React.createElement(React.Fragment, null,
                    React.createElement(CustomerDrawerHeader, { title: title, setTitle: setTitle, hasVerifiedMembers: customers ? customers.length > 0 : false }),
                    React.createElement(HorizontalDivider, null),
                    React.createElement("div", null,
                        title === 'Details' && (React.createElement(CustomerDrawerDetailsTab, { userName: customerOrganizationName, address: address, addressLine2: addressLine2, phone: phone, city: city, state: state, zipCode: zipCode, internalID: internalID, isCustomerOrganization: true })),
                        title === 'Members' && (React.createElement(CustomerOrganizationDrawerMembersTab, { customers: customers, loadingCustomers: loadingCustomers, setShowCustomerDrawer: () => {
                                setShowCustomerDrawer(true);
                                setOpen(false);
                            }, setCustomerToDisplay: setCustomer, customerEmails: customerEmails, customerRoles: customerRoles })),
                        title === 'Settings' && (React.createElement(CustomerDrawerSettingsTab, { upfrontPayment: REQUIRE_UPFRONT_PAYMENT_OPTIONS_ARRAY[requireUpfrontPaymentId], activeOrganization: activeOrganization, allowBulkInvoicing: allowBulkInvoicing, isCustomerOrganization: true })),
                        React.createElement(CustomerDrawerFooter, { setShowEditCustomerOrganizationModal: setShowEditCustomerOrganizationModal }))))),
            showEditCustomerOrganizationModal && (React.createElement(EditCustomerOrganizationModal, { setOpen: () => {
                    setShowEditCustomerOrganizationModal(false);
                }, setCustomerOrganization: (customerOrganization) => {
                    setCustomerOrganizationEdits(customerOrganization);
                }, customerOrganizationData: customerOrganizationData, customerOrganizationEdits: customerOrganizationEdits, activeOrganization: activeOrganization, setToastText: setToastText })))));
};
export default CustomerOrganizationDrawer;
