var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { debounce } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import CTooltip from 'components/Tooltip';
import { ExclamationCircle, QuestionMarkRoundedIcon, SearchIcon } from 'icons';
import { Badge } from 'components/Badge';
import api from 'api';
import { SORT_DESCENDING } from 'routes/userNoticeList/table/types';
import { logAndCaptureException } from 'utils';
import { TextField } from 'components/TextField';
import { Alert } from 'components/Alert';
const NUMBER_OF_PUBLICATION_DATES_TO_SHOW = 3;
function PublicationVerificationModal({ setShowVerifyNoticesPublicationSuccessModal, activeOrganization, setOpen, id }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [notices, setNotices] = useState([]);
    const [fetchingNotices, setFetchingNotices] = useState(false);
    const [verifiedNotices, setVerifiedNotices] = useState([]);
    const todaysTimestamp = moment().utc().startOf('d').valueOf();
    const updateSearch = useMemo(() => debounce(value => {
        setDebouncedSearchTerm(value);
    }, 400), []);
    // Displays notices which has 1 or more unverified past publication dates
    const shouldDisplayNotice = (notice) => {
        const pastPublicationTimestamps = notice.publicationtimestamps.filter(pt => pt <= todaysTimestamp);
        const verifiedPublicationTimestamps = notice.verifiedpublicationtimestamps || [];
        return pastPublicationTimestamps.some(pt => !verifiedPublicationTimestamps.includes(pt));
    };
    // Calculates the number of publication dates that have passed for the given notice
    const getNumberOfPastPublicationDates = (notice) => {
        return notice.publicationtimestamps.filter(pt => pt <= todaysTimestamp)
            .length;
    };
    const transformPublicationDates = (notice) => {
        return notice.publicationdates
            .split(',')
            .slice(0, getNumberOfPastPublicationDates(notice));
    };
    const fetchNotices = () => __awaiter(this, void 0, void 0, function* () {
        setFetchingNotices(true);
        const postBody = {
            current: 1,
            size: 1000,
            sort: [{ lastpublicationtimestamp: SORT_DESCENDING }],
            search: searchTerm,
            isArchivedTab: false,
            isDraftTab: false,
            isPublisher: true,
            activeOrganizationId: activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id,
            filters: [{ affidavitsubmitted: 0 }],
            anyFilters: [
                {
                    firstpublicationtimestamp: {
                        // Using an early date as range filtering does not work unless we use both boundaries (from, to)
                        from: moment('2020/1/1').startOf('day').toDate().getTime(),
                        to: moment().startOf('day').toDate().getTime()
                    }
                }
            ]
        };
        let results;
        try {
            const response = yield api.post('search/usernotices', postBody);
            results = response.results;
        }
        catch (err) {
            logAndCaptureException(err, 'Unable to fetch notices missing verification events');
        }
        const filteredNotices = results.filter((notice) => shouldDisplayNotice(notice));
        setNotices(filteredNotices);
        setFetchingNotices(false);
    });
    useEffect(() => {
        void fetchNotices();
    }, [debouncedSearchTerm]);
    useEffect(() => {
        updateSearch(searchTerm);
    }, [searchTerm]);
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        const { success } = yield api.post('affidavit-automation/verify-notices', {
            noticeIds: verifiedNotices
        });
        if (success) {
            setShowVerifyNoticesPublicationSuccessModal(true);
            setOpen(false);
        }
    });
    const handleNoticeCheckboxChange = (e) => {
        if (e.target.checked) {
            setVerifiedNotices([...verifiedNotices, e.target.name]);
        }
        else {
            const updatedVerifiedNotices = verifiedNotices.filter(verifiedNotice => verifiedNotice !== e.target.name);
            setVerifiedNotices(updatedVerifiedNotices);
        }
    };
    const handleHeaderCheckChange = (e) => {
        // When the checkbox at the header is checked, all the currently *displayed* notices are added to the verified notices
        if (e.target.checked) {
            const updatedVerifiedNotices = [
                ...verifiedNotices,
                ...notices.map(n => n.id).filter(n => !verifiedNotices.includes(n))
            ];
            setVerifiedNotices(updatedVerifiedNotices);
        }
        else {
            const noticeIds = notices.map(n => n.id);
            const updatedVerifiedNotices = verifiedNotices.filter(verifiedNotice => !noticeIds.includes(verifiedNotice));
            setVerifiedNotices(updatedVerifiedNotices);
        }
    };
    return (React.createElement(CancelOrSubmitModal, { setOpen: setOpen, body: "We will start processing affidavits once you confirm that notices published as scheduled.", header: `Verify Notice Publication`, width: "w-64", maxWidth: "max-w-3xl", id: id, primaryButtonText: "Verify Notices", tertiaryButtonText: "Cancel", onSubmit: handleSubmit, disablePrimaryButton: false, showLoadingSpinner: true },
        React.createElement("div", { className: "flex-col space-y-6 py-6" },
            React.createElement(TextField, { type: "text", id: "notice-search", labelText: "", placeholder: "Search for notice...", value: searchTerm, onChange: (value) => setSearchTerm(value), suffix: React.createElement(SearchIcon, { className: "pointer-events-none text-gray-800" }) }),
            React.createElement(Alert, { status: "warning", id: "publication-certification", icon: React.createElement(ExclamationCircle, { className: "text-yellow-700 h-5 w-5" }) },
                "By checking the box next to a notice below, you are hereby certifying to Column that the notice ran in print in",
                ' ',
                activeOrganization.data().name,
                " on the date(s) listed."),
            React.createElement("table", { className: "min-w-full divide-y divide-grey-150" },
                React.createElement("thead", { className: "h-16 table w-full" },
                    React.createElement("tr", { className: "text-left font-medium bg-gray-50 uppercase text-gray-650 text-sm tracking-wider" },
                        React.createElement("th", { className: "pl-12 font-medium" },
                            React.createElement("input", { type: "checkbox", checked: !!notices.length &&
                                    notices
                                        .map(n => n.id)
                                        .every(notice => verifiedNotices.includes(notice)), onChange: handleHeaderCheckChange, className: "form-checkbox text-blue-500 transition duration-150 ease-in-out mr-3 -mt-1 cursor-pointer" }),
                            "Notice Name",
                            ' ',
                            verifiedNotices.length ? `(${verifiedNotices.length})` : ''),
                        React.createElement("th", { className: "font-medium pr-4 flex justify-end items-center h-full" },
                            "publication dates",
                            ' ',
                            React.createElement(CTooltip, { classes: "flex justify-start items-start", customPosition: "top-0 -mt-16 -ml-64", helpText: `Past publication dates for which this notice was scheduled to run.` },
                                React.createElement(QuestionMarkRoundedIcon, { className: "text-white h-4 w-4 ml-1" }))))),
                React.createElement("tbody", { className: "block h-64 overflow-y-scroll hide-scrollbar divide-y divide-gray-200" }, fetchingNotices ? (React.createElement("tr", { className: "h-full flex justify-center items-center" },
                    React.createElement("td", null,
                        React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 mr-2" })))) : (notices.map(notice => {
                    const publicationDates = transformPublicationDates(notice);
                    return (React.createElement("tr", { className: "table w-full h-20 text-sm leading-6 font-medium", key: notice.id },
                        React.createElement("td", null,
                            React.createElement("div", { className: "flex pl-12" },
                                React.createElement("input", { id: "notice-confirm-publication", name: notice.id, type: "checkbox", checked: verifiedNotices.includes(notice.id), onChange: handleNoticeCheckboxChange, className: "form-checkbox text-blue-500 transition duration-150 ease-in-out mr-3 mt-1 cursor-pointer" }),
                                React.createElement("div", null,
                                    React.createElement("div", { className: "text-grey-500" }, notice.noticename),
                                    React.createElement("div", { className: "text-gray-600" },
                                        notice.noticetext.slice(0, 20),
                                        "...")))),
                        React.createElement("td", { className: "pr-4 flex h-full justify-end items-center gap-1" },
                            publicationDates
                                .slice(0, NUMBER_OF_PUBLICATION_DATES_TO_SHOW)
                                .map(publicationDate => (React.createElement(Badge, { key: `${notice.id}-${publicationDate}` }, publicationDate))),
                            publicationDates.length >
                                NUMBER_OF_PUBLICATION_DATES_TO_SHOW && (React.createElement(Badge, null,
                                "+",
                                publicationDates.length -
                                    NUMBER_OF_PUBLICATION_DATES_TO_SHOW)))));
                })))))));
}
export default PublicationVerificationModal;
