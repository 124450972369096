import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
export const UNVERIFIED_NOTICES_TAB = 'unverified-notices-tab';
export const VERIFIED_NOTICES_TAB = 'verified-notices-tab';
export const PREVIEW_TAB = 'preview-tab';
/**
 * UI component for the buttons in the drawer
 */
function DrawerTabButton({ disabledTooltip, disabled, isActive, label, onClick }) {
    const drawerTabButton = (React.createElement("button", { className: classNames('ml-6 inline-block text-base text-black text-left py-6 focus:outline-none font-medium border-b-2 border-transparent border-b-2', {
            'hover:border-b-2 hover:border-blue-600 hover:text-blue-950': !disabled,
            'cursor-not-allowed text-grey-400': disabled,
            'border-blue-600 text-blue-950': isActive
        }), onClick: () => onClick(), disabled: disabled }, label));
    if (disabled && disabledTooltip) {
        return React.createElement(Tooltip, { title: disabledTooltip }, drawerTabButton);
    }
    return drawerTabButton;
}
/**
 * Parses key features from the event
 * @param affidavitUploadDay input data
 * @returns
 */
const parseAffidavitUploadDay = (affidavitUploadDay) => {
    const { noticesValidated, noticesNotValidated } = affidavitUploadDay;
    return {
        numberNotValidated: (noticesNotValidated === null || noticesNotValidated === void 0 ? void 0 : noticesNotValidated.length) || 0,
        numberValidated: (noticesValidated === null || noticesValidated === void 0 ? void 0 : noticesValidated.length) || 0
    };
};
/**
 * Control the currently visible tab on the affidavit affidavit upload event drawer
 */
export default function AffidavitUploadDrawerTabs({ setAffidavitUploadDayDrawerTab, affidavitUploadDayDrawerTab, affidavitUploadDay }) {
    const { numberValidated, numberNotValidated } = parseAffidavitUploadDay(affidavitUploadDay);
    return (React.createElement("div", { id: "affidavit-upload-drawer-tabs" },
        React.createElement(DrawerTabButton, { disabled: false, isActive: affidavitUploadDayDrawerTab === PREVIEW_TAB, label: "Preview", onClick: () => setAffidavitUploadDayDrawerTab(PREVIEW_TAB) }),
        React.createElement(DrawerTabButton, { disabledTooltip: `You do not have any verified notices. Use the preview tab to upload the e-edition for ${affidavitUploadDay.publishingDateString}`, isActive: affidavitUploadDayDrawerTab === VERIFIED_NOTICES_TAB, disabled: numberValidated === 0, onClick: () => setAffidavitUploadDayDrawerTab(VERIFIED_NOTICES_TAB), label: `Verified Notices (${numberValidated})` }),
        React.createElement(DrawerTabButton, { disabledTooltip: "All notices have been verified.", isActive: affidavitUploadDayDrawerTab === UNVERIFIED_NOTICES_TAB, disabled: numberNotValidated === 0, onClick: () => setAffidavitUploadDayDrawerTab(UNVERIFIED_NOTICES_TAB), label: `Unverified Notices (${numberNotValidated})` })));
}
