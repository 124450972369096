import React from 'react';
import { XIcon } from 'icons/index';
/**
 * Header for the activity log. Conditionall shows an "X" icon on mobile to close the preview if it has been loaded.
 */
function ActivityLogHeader({ setShowPreviewOnMobile }) {
    return (React.createElement("div", { className: "row w-full row-span-1 col-span-4 flex justify-between items-center" },
        React.createElement("h2", { className: "text-gray-800 text-4xl font-medium" }, "Activity"),
        setShowPreviewOnMobile && (React.createElement(XIcon, { className: "text-gray-800 md:hidden h-6 w-6", onClick: () => setShowPreviewOnMobile(false) }))));
}
export default ActivityLogHeader;
