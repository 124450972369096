var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { ColumnButton } from 'components/ColumnButton';
import { TextField } from 'components/TextField';
import LoadingState from 'components/LoadingState';
import { XIcon } from 'icons';
import TemplateLayoutSettings from './TemplateSettingsUpdateForm';
import FormInputWrapper from './TemplateSettingsFormInputWrapper';
import UpdateCard from './TemplateSettingsUpdateCard';
import { getTemplateStylesFromURL, forkTemplateStyles } from '../../templateSettingsUtils';
/**
 * Form component to update the configuration settings of a newspaper pagination template
 */
export default function TemplateUpdateForm({ closeUpdateForm, template }) {
    const [templateStyles, setTemplateStyles] = useState();
    const [bulkTemplateStyles, setBulkTemplateStyles] = useState();
    const [edited, setEdited] = useState(false);
    const { fullPageTemplate } = template.data();
    /**
     * Loads template and bulk template styles
     */
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            try {
                setTemplateStyles(yield getTemplateStylesFromURL(template.data().downloadUrl));
                if (fullPageTemplate) {
                    setBulkTemplateStyles(yield getTemplateStylesFromURL(fullPageTemplate));
                }
            }
            catch (err) {
                console.log(err);
            }
        }))();
    }, [template === null || template === void 0 ? void 0 : template.id]);
    const [templateName, setTemplateName] = useState(template.data().name || '');
    const [saving, setSaving] = useState(false);
    const updateTemplateProperties = () => __awaiter(this, void 0, void 0, function* () {
        if (!templateStyles)
            return;
        setSaving(true);
        try {
            const templateUpdate = {
                downloadUrl: yield forkTemplateStyles(template.data().downloadUrl, templateStyles),
                styles: null,
                name: templateName
            };
            if (fullPageTemplate && bulkTemplateStyles) {
                templateUpdate.fullPageTemplate = yield forkTemplateStyles(fullPageTemplate, bulkTemplateStyles);
            }
            yield template.ref.update(templateUpdate);
        }
        catch (err) {
            setSaving(false);
            return;
        }
        setSaving(false);
        closeUpdateForm();
    });
    return (React.createElement("div", { className: "fixed overflow-scroll z-100 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out" },
        React.createElement("div", { className: "bg-gray-80 h-screen" },
            React.createElement("div", { className: "flex justify-between py-4 px-6 bg-white border border-grey-100 sticky top-0" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement(ColumnButton, { startIcon: React.createElement(XIcon, { className: "h-6 w-6" }), size: "md", onClick: closeUpdateForm }),
                    React.createElement("span", { className: "pl-4 text-gray-800 font-semibold text-xl" }, "Edit Template")),
                React.createElement(ColumnButton, { primary: true, buttonText: "Save", size: "lg", onClick: updateTemplateProperties, disabled: saving || !edited })),
            React.createElement(UpdateCard, { header: "Template Details", subheader: "Configure basic details about your template." },
                React.createElement(FormInputWrapper, { className: "sm:col-span-6" },
                    React.createElement(TextField, { id: "template-name", labelText: "Template Name", value: templateName, onChange: newValue => {
                            setEdited(true);
                            setTemplateName(newValue);
                        } }))),
            templateStyles ? (React.createElement(TemplateLayoutSettings, { header: "Update Template Settings", subheader: "Configure top-level properties on your template.", styles: templateStyles, onUpdate: (key, value) => {
                    setEdited(true);
                    setTemplateStyles(Object.assign(Object.assign({}, templateStyles), { [key]: value }));
                } })) : (React.createElement(LoadingState, null)),
            bulkTemplateStyles && (React.createElement(TemplateLayoutSettings, { header: "Update Bulk Template Settings", subheader: "Configure properties on your bulk template.", styles: bulkTemplateStyles, onUpdate: (key, value) => {
                    setEdited(true);
                    setBulkTemplateStyles(Object.assign(Object.assign({}, bulkTemplateStyles), { [key]: value }));
                } })),
            React.createElement("div", { className: "pb-20 w-full bg-gray-100" }))));
}
