import React from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToTimeString } from 'lib/types/deadlines';
import { Day } from 'lib/enums';
import { ArrowDownIcon, DeleteIcon } from 'icons';
import { timeStringToDate } from './helpers';
import { getSelectedWeekdaysEnums } from './WeekdaySelector';
import DatePickerTextField from './DatePickerTextField';
const getSelectedWeekdays = (deadlines) => {
    return deadlines.filter((deadline) => deadline.publish);
};
const AdvertisingDeadlinesTable = ({ deadlines, setDeadlines, setSelectedDayEnumValues }) => {
    const handleDeadlineTimeChange = (value, dayEnum) => {
        const newDeadlines = deadlines.map(day => {
            // eslint-disable-next-line no-param-reassign
            if (day.dayEnum === dayEnum)
                day.deadline.time = value;
            return day;
        });
        setDeadlines(newDeadlines);
    };
    const handleRowDelete = (d) => {
        const newDeadlines = [...deadlines];
        newDeadlines[d.dayEnum - 1].publish = false;
        setSelectedDayEnumValues(getSelectedWeekdaysEnums(newDeadlines));
    };
    const handleDeadlineDayChange = (deadlineDay, dayEnum) => {
        const newDeadlines = deadlines.map((day) => {
            // eslint-disable-next-line no-param-reassign
            if (day.dayEnum === dayEnum)
                day.deadline.dayEnum = deadlineDay;
            return day;
        });
        setDeadlines(newDeadlines);
    };
    const options = Day.items().map(day => {
        return {
            value: day.value,
            label: day.label
        };
    });
    return (React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "PUBLISHING DAY"),
                React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "AD DEADLINE DAY"),
                React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "AD DEADLINE TIME"),
                React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-center" }, "ACTIONS"))),
        React.createElement("tbody", { className: "bg-white divide-y divide-gray-200" }, getSelectedWeekdays(deadlines).map((day, i) => {
            return (React.createElement("tr", { key: day.dayEnum },
                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, Day.by_value(day.dayEnum).label),
                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                    React.createElement("div", { className: "relative", style: { maxWidth: 150, minWidth: 120 } },
                        React.createElement("select", { id: `deadlineday${i}`, value: day.deadline.dayEnum, onChange: (e) => {
                                handleDeadlineDayChange(e.target.value, day.dayEnum);
                            }, className: "appearance-none w-full bg-white hover:border-gray-500 px-4 py-2 rounded leading-tight focus:outline-none focus:shadow-outline" }, options.map(option => (React.createElement("option", { key: option.value, value: option.value }, option.label)))),
                        React.createElement("div", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700" },
                            React.createElement(ArrowDownIcon, { className: "h-4 w-4" })))),
                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" },
                    React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                        React.createElement(KeyboardTimePicker, { TextFieldComponent: DatePickerTextField, id: `deadlinetime${i}`, placeholder: "08:00 AM", mask: "__:__ _M", value: timeStringToDate(day.deadline.time), onChange: date => {
                                if (!date)
                                    return;
                                handleDeadlineTimeChange(dateToTimeString(date), day.dayEnum);
                            } }))),
                React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900" },
                    React.createElement("div", { onClick: () => handleRowDelete(day), className: "flex items-center justify-center cursor-pointer" },
                        React.createElement(DeleteIcon, { alt: "trash" })))));
        })),
        React.createElement(React.Fragment, null,
            React.createElement("style", null, `.MuiPickersClockPointer-animateTransform {
            transition-duration: 0s !important;
          }`))));
};
export default AdvertisingDeadlinesTable;
