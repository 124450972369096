import { ArrowDownIcon } from 'icons';
import React from 'react';
const DatePickerTextField = function (props) {
    return (React.createElement("div", Object.assign({ color: "primary", className: `relative mt-1 block w-full pl-3 pr-10 py-2 text-base leading-6 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5`, style: { maxWidth: 150 }, onClick: e => {
            e.preventDefault();
            props.InputProps.endAdornment.props.children.props.onClick();
        }, id: props.id, disabled: props.disabled }, props.inputProps),
        props.value,
        React.createElement("div", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700" },
            React.createElement(ArrowDownIcon, { className: "h-4 w-4" }))));
};
export default DatePickerTextField;
