/**
 * This function can be used to construct new Enums. At the most basic level, it requires an object
 * mapping Enum Item keys to their data. For enums where we add additional properties to items, this
 * function requires type arguments defining both the EnumItems input data as well as the ItemsData
 * defining those additional properties.
 *
 * ### Standard Enum Construction
 * ```typescript
 * const StandardEnumData = {
 *   item_1: {
 *     value: 1,
 *     key: 'item_1',
 *     label: 'Item 1',
 *     childItemKeys: ['item_2'],
 *     isRootItem: true
 *   },
 *   item_2: {
 *     value: 2,
 *     label: 'Item 2',
 *     key: 'item_2'
 *   },
 * };
 * const StandardEnum = getEnum(StandardEnumData);
 * ```
 *
 * ### Complex Enum Construction
 * ```typescript
 * type ComplexEnumItemType = EnumInputItem<{
 *   message: string;
 *   priority?: number;
 * }>;
 *
 * const ComplexEnumData = {
 *   item_1: {
 *     value: 1,
 *     key: 'item_1',
 *     label: 'Item 1',
 *     message: 'This is Item 1'
 *   },
 *   item_2: {
 *     value: 2,
 *     label: 'Item 2',
 *     key: 'item_2',
 *     message: 'This is Item 2',
 *     priority: 1
 *   },
 * };
 *
 * type ComplexEnumItems = typeof ComplexEnumData;
 *
 * const ComplexEnum = getEnum<ComplexEnumItems, ComplexEnumItemType>(ComplexEnumData);
 * ```
 */
export const getEnum = (enumData) => {
    /**
     * Here we add the `children()` method to each input item, creating the necessary output items
     * that other functions on the Enum, as well as direct access by key, will provide.
     */
    const itemsByKey = Object.entries(enumData).reduce((acc, [key, inputData]) => {
        const children = () => {
            if (!inputData.childItemKeys) {
                return [];
            }
            return inputData.childItemKeys.map(childKey => itemsByKey[childKey]);
        };
        const outputData = Object.assign(Object.assign({}, inputData), { children });
        return Object.assign(Object.assign({}, acc), { [key]: outputData });
    }, {});
    /**
     * Aside from `itemsByKey`, which we use to populate the Enum for direct access by key, we lazy
     * generate other internal data only when we need it.
     */
    let _items_cache;
    const items = () => {
        if (!_items_cache) {
            _items_cache = Object.values(itemsByKey).filter(item => item.value);
        }
        return _items_cache;
    };
    /**
     * Aside from `itemsByKey`, which we use to populate the Enum for direct access by key, we lazy
     * generate other internal data only when we need it.
     */
    let _items_by_label;
    const by_label = label => {
        if (label === null || label === undefined) {
            return undefined;
        }
        if (!_items_by_label) {
            _items_by_label = items().reduce((acc, currItem) => (Object.assign(Object.assign({}, acc), { [currItem.label]: currItem })), {});
        }
        return _items_by_label[label];
    };
    /**
     * Aside from `itemsByKey`, which we use to populate the Enum for direct access by key, we lazy
     * generate other internal data only when we need it.
     */
    let _items_by_value;
    const by_value = value => {
        if (value === null || value === undefined) {
            return undefined;
        }
        const searchValue = typeof value === 'number' ? value : parseInt(value, 10);
        if (!_items_by_value) {
            _items_by_value = items().reduce((acc, currItem) => (Object.assign(Object.assign({}, acc), { [currItem.value]: currItem })), {});
        }
        return _items_by_value[searchValue];
    };
    const by_key = key => {
        var _a;
        if (key === null || key === undefined) {
            return undefined;
        }
        return (_a = itemsByKey[key]) !== null && _a !== void 0 ? _a : undefined;
    };
    const rootItems = () => {
        return items().filter(item => !!item.isRootItem);
    };
    return Object.assign({ by_label,
        by_value,
        by_key,
        items,
        rootItems }, itemsByKey);
};
