import { useState, useEffect } from 'react';
import { ENV, PROD, DEMO } from '../constants';
const BoundedProgress = ({ timeout, InProgress, Fallback }) => {
    const [showProgress, setShowProgress] = useState(true);
    useEffect(() => {
        const id = setTimeout(() => {
            setShowProgress(false);
            if (ENV === DEMO || ENV === PROD) {
                console.warn('Progress appeared for longer than allowed timeout');
            }
        }, timeout);
        return () => {
            clearTimeout(id);
        };
    }, []);
    if (!showProgress)
        return Fallback;
    return InProgress;
};
export default BoundedProgress;
