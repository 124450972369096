import React, { useEffect, useState } from 'react';
import StateSelectDropdown from 'components/StateSelectDropdown';
import RegisterOrganizationInput from './RegisterOrganizationInput';
import * as validators from './validators';
export const EMPTY_ADDRESS = {
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: {
        id: 0,
        label: ''
    },
    zipCode: '',
    phone: '',
    validation: {
        valid: false
    }
};
/**
 * Common form elements between all organization registration flows.
 */
const RegisterOrganizationAddressForm = ({ handleInputChanged, nameFieldText, namePlaceholderText }) => {
    const [name, setName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState({
        id: 0,
        label: ''
    });
    const [zipCode, setZipCode] = useState('');
    const [phone, setPhone] = useState('');
    const isZipValid = !zipCode || validators.zipAdvertiser(zipCode);
    const isPhoneValid = !phone || validators.phone(phone);
    const runValidators = () => {
        if (!isZipValid || !isPhoneValid || !name) {
            return {
                valid: false
            };
        }
        const allRequiredFilled = [
            addressLine1,
            city,
            state.label,
            zipCode,
            phone,
            name
        ].every(x => x && x.length > 0);
        if (!allRequiredFilled) {
            return { valid: false };
        }
        return {
            valid: true
        };
    };
    const address = {
        name,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        phone,
        validation: runValidators()
    };
    // When any address field changes, file a composite onChange event
    useEffect(() => {
        handleInputChanged(address);
    }, [name, addressLine1, addressLine2, city, state, zipCode, phone]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex mt-3 space-x-1" },
            React.createElement(RegisterOrganizationInput, { id: "name", required: true, autoComplete: "organization", value: name, onChange: setName, placeholder: namePlaceholderText, fieldName: nameFieldText }),
            React.createElement("div", { className: "w-4 flex-grow-0" }),
            React.createElement(RegisterOrganizationInput, { id: 'phone', required: true, autoComplete: 'tel', value: phone, placeholder: 'Phone', onChange: val => {
                    if (val.length <= validators.MAX_PHONE_LENGTH) {
                        setPhone(val);
                    }
                }, valid: isPhoneValid, errorMessage: 'Phone number must be 10 digits.', fieldName: "Phone number" })),
        React.createElement("div", { className: "flex mt-3 space-x-1" },
            React.createElement(RegisterOrganizationInput, { id: 'address-line-1', required: true, autoComplete: 'address-line1', value: addressLine1, placeholder: 'Address line 1', styleProps: {
                    borderConflict: 'b',
                    rounded: 't'
                }, onChange: setAddressLine1, fieldName: "Address line 1" }),
            React.createElement("div", { className: "w-4 flex-grow-0" }),
            React.createElement(RegisterOrganizationInput, { id: 'address-line-2', autoComplete: 'address-line2', value: addressLine2, placeholder: 'Address Line 2', styleProps: {
                    rounded: 'b'
                }, onChange: setAddressLine2, fieldName: "Address line 2" })),
        React.createElement("div", { className: "flex mt-3 space-x-1" },
            React.createElement(RegisterOrganizationInput, { id: 'city', required: true, autoComplete: 'address-level2', value: city, placeholder: 'City', styleProps: {
                    borderConflict: 'b',
                    rounded: 't'
                }, onChange: setCity, fieldName: "City" }),
            React.createElement("div", { className: "w-4" }),
            React.createElement("div", { className: "w-full block w-6/12" },
                React.createElement("div", null,
                    React.createElement("p", { className: "text-gray-800 font-medium text-sm leading-5 mb-2" }, "State *")),
                React.createElement(StateSelectDropdown, { onChange: value => setState(value), value: state })),
            React.createElement("div", { className: "w-4" }),
            React.createElement(RegisterOrganizationInput, { id: 'zipCode', required: true, autoComplete: 'postal-code', value: zipCode, placeholder: 'Zip', onChange: val => {
                    if (val.length <= validators.MAX_ZIP_LENGTH) {
                        setZipCode(val);
                    }
                }, valid: isZipValid, errorMessage: 'Zip must be 5 digits.', fieldName: "Zip code" }))));
};
export default RegisterOrganizationAddressForm;
