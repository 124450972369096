var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { exists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import LoadingState from 'components/LoadingState';
/**
 * Represents a single row in the notice list table
 */
function NoticeListTableRow({ notice }) {
    const [open, setOpen] = useState(false);
    return (React.createElement("div", { className: "border-b px-4" },
        React.createElement("div", { className: "flex justify-between w-full py-5" },
            React.createElement("div", { className: "text-grey-400 font-semibold w-64" }, notice.data().referenceId),
            React.createElement("div", { className: "flex items-center space-x-4" },
                React.createElement("a", { className: "font-medium text-blue-950", target: "_blank", href: `/notice/${notice.id}`, rel: "noopener noreferrer" }, "View Notice"),
                !open && (React.createElement(KeyboardArrowRight, { onClick: () => setOpen(true), className: "cursor-pointer text-gray-500 hover:text-gray-600" })),
                open && (React.createElement(KeyboardArrowDown, { onClick: () => setOpen(false), className: "cursor-pointer text-gray-500 hover:text-gray-600" })))),
        open && (React.createElement("div", { className: "mb-5" },
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: notice.data().confirmedHtml || notice.data().unusedConfirmedHtml
                } })))));
}
/**
 * Display a list of notices in the affidavit automation drawer
 */
export default function NoticeList({ noticeRefs }) {
    const [notices, setNotices] = useState();
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            setNotices(undefined);
            try {
                const notices = yield Promise.all(noticeRefs.map(ref => ref.get()));
                setNotices(notices.filter(exists));
            }
            catch (err) {
                logAndCaptureException(err, 'Unable to load notice list');
            }
        }))();
    }, 
    // NOTE: be careful changing this effect as you can easily trigger an infinite read loop
    // if you use simply (noticeRefs) or (noticeRefs.map(ref => ref.id))
    [noticeRefs.map(ref => ref.id).join(',')]);
    if (!notices)
        return React.createElement(LoadingState, null);
    return (React.createElement("div", { className: "flex flex-col" }, notices.map(notice => (React.createElement(NoticeListTableRow, { notice: notice, key: notice.id })))));
}
