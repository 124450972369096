import React, { useEffect, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { CheckCircleOutlined, CancelOutlined } from '@material-ui/icons';
import { NoticeType, State } from 'lib/enums';
import { exists } from 'lib/types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ScrollStep from './ScrollStep';
const styles = (theme) => createStyles({
    stepContainer: {
        flexWrap: 'nowrap',
        backgroundColor: theme.palette.primary.main,
        height: 268,
        width: '100%'
    },
    substep: {
        border: theme.palette.grey.scrollFormBorder
    },
    text: {
        color: 'white'
    },
    bigButton: {
        color: '#2A394A',
        fontSize: '1rem !important',
        backgroundColor: 'white',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: `#e2e8f0 !important;`
        },
        position: 'relative',
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(2),
            left: 0
        },
        width: '100%',
        height: 80,
        minWidth: 240,
        border: '1px #cbd5e0 solid',
        borderRadius: '0.375rem',
        textTransform: 'unset'
    },
    headingRow: {
        height: 70,
        marginTop: 25,
        marginBottom: 38,
        alignItems: 'center',
        justifyContent: 'center'
    }
});
const BigButton = ({ id, classes, StartIcon, children, onClick, disabled }) => {
    return (React.createElement(Button, { id: id, size: "large", startIcon: React.createElement(StartIcon, null), onClick: onClick, className: classes.bigButton, disabled: disabled }, children));
};
const ContentPreparedStep = ({ id, activeStepId, classes, push, onChange, next, onDisabledStepClick, newspaper, placement }) => {
    var _a;
    const [madeChoice, setMadeChoice] = useState(false);
    const handleNo = () => {
        setMadeChoice(true);
        next();
    };
    const handleYes = () => {
        var _a, _b;
        let redir;
        if (placement.noticeType !== NoticeType.custom.value) {
            redir = `/form/${(_a = placement.draft) === null || _a === void 0 ? void 0 : _a.id}/${placement.noticeType}`;
        }
        else {
            redir = `/file/${(_b = placement.original) === null || _b === void 0 ? void 0 : _b.id}`;
        }
        push(redir);
    };
    useEffect(() => {
        const isValid = !!madeChoice;
        onChange(isValid);
    }, [madeChoice, activeStepId]);
    useEffect(() => {
        const isValid = !!madeChoice;
        onChange(isValid);
    }, []);
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, onDisabledStepClick: onDisabledStepClick, title: 'Would you like some help writing your notice?', caption: 'We can automatically generate your notice content for you if you answer a few simple questions.' },
        React.createElement("div", { className: "grid grid-cols-6 gap-6" },
            React.createElement("div", { className: "col-span-3" },
                React.createElement(BigButton, { id: "content-not-prepared", StartIcon: CheckCircleOutlined, classes: classes, onClick: handleYes, disabled: exists(newspaper) &&
                        !((_a = State.by_value(newspaper.data().state)) === null || _a === void 0 ? void 0 : _a.hasTypeform) }, "Yes")),
            React.createElement("div", { className: "col-span-3" },
                React.createElement(BigButton, { id: "content-yes-prepared", StartIcon: CancelOutlined, classes: classes, onClick: handleNo }, "No")))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps, { push })(withStyles(styles)(ContentPreparedStep));
