var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment-timezone';
import { getDeadlineOverrideKeyFromDate } from '../types/deadlines';
import { firestoreTimestampOrDateToDate, firstNoticePublicationDate, getNoticeTypeFromNoticeData } from '../helpers';
import { InvoiceStatus, NoticeType } from '../enums';
export const disableNonPublishingDays = (day, publishingDayEnumValues, overrides) => {
    const publishingDayOverride = overrides && overrides[getDeadlineOverrideKeyFromDate(day)];
    if (publishingDayOverride) {
        return !publishingDayOverride.publish;
    }
    return !publishingDayEnumValues.includes(day.getDay() + 1);
};
export const getRelevantDeadline = (publicationDate, deadlines, overrides) => {
    const publishingDayOverride = overrides && overrides[getDeadlineOverrideKeyFromDate(publicationDate)];
    if (publishingDayOverride) {
        return publishingDayOverride;
    }
    const m = moment(publicationDate).clone();
    const publicationDayOfWeek = m.day();
    // enums days of week are off by one from moment days of week
    const relevantDeadline = deadlines.find(day => day.dayEnum === publicationDayOfWeek + 1);
    return relevantDeadline;
};
export const getDeadlineTimeForPaper = (publicationDate, deadlines, newspaperTimezone, notice, newspaper) => {
    var _a;
    const relevantDeadline = getRelevantDeadline(publicationDate, deadlines, (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.deadlineOverrides);
    const deadlineDayOfWeek = relevantDeadline.deadline.dayEnum - 1;
    const m = moment(publicationDate).clone();
    const publicationDayOfWeek = m.day();
    let startOfDeadlineDay;
    // e.g. publication on Sunday (day 0), deadline on Saturday (day 6)
    if (publicationDayOfWeek - deadlineDayOfWeek < 0) {
        startOfDeadlineDay = moment(publicationDate)
            .clone()
            .tz(newspaperTimezone)
            .subtract(1, 'week')
            .add(deadlineDayOfWeek - publicationDayOfWeek, 'd')
            .startOf('day');
    }
    else {
        // e.g. publication on Monday (day 1), deadline on Sunday (day 0)
        startOfDeadlineDay = moment(publicationDate)
            .clone()
            .tz(newspaperTimezone)
            .subtract(publicationDayOfWeek - deadlineDayOfWeek, 'day')
            .startOf('day');
    }
    const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.publicationOffsetDays) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(noticeType.publicationOffsetDays, 'days');
    }
    if (relevantDeadline.weeks) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(relevantDeadline.weeks, 'weeks');
    }
    if (notice.noticeType === NoticeType.display_ad.value &&
        (relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset)) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset, 'hours');
    }
    const deadlineHoursFromStartOfDay = parseInt(relevantDeadline.deadline.time.split(':')[0], 10);
    const deadlineMinutes = parseInt(relevantDeadline.deadline.time.split(':')[1], 10);
    const deadlineTimeForPaper = startOfDeadlineDay
        .add(deadlineHoursFromStartOfDay, 'hours')
        .add(deadlineMinutes, 'minutes');
    return deadlineTimeForPaper.clone();
};
export const getIsAfterPublishingDeadline = (publicationDate, deadlines, newspaperTimezone, notice, newspaper) => {
    if (!deadlines || !publicationDate)
        return true;
    const currentTimeForPaper = moment(Date.now()).tz(newspaperTimezone);
    const deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, newspaperTimezone, notice, newspaper);
    return currentTimeForPaper.isAfter(deadlineTimeForPaper);
};
export const getIsAfterPublishingDeadlineSimplified = (notice, newspaper, date) => {
    // If no date is specified, choose the first publication date
    const publicationDate = date
        ? firestoreTimestampOrDateToDate(date)
        : firstNoticePublicationDate(notice);
    const { deadlines, iana_timezone } = newspaper.data();
    if (!deadlines || !publicationDate)
        return true;
    const currentTimeForPaper = moment(Date.now()).tz(iana_timezone);
    const deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, iana_timezone, notice.data(), newspaper);
    return currentTimeForPaper.isAfter(deadlineTimeForPaper);
};
export const isNoticeAfterPublicationDeadline = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield notice.data().newspaper.get();
    const firstPublication = notice.data().publicationDates[0];
    const { deadlines, iana_timezone } = newspaperSnap.data();
    return getIsAfterPublishingDeadline(firstPublication.toDate(), deadlines, iana_timezone, notice.data(), newspaperSnap);
});
export const canCancelNoticeWithoutSupport = (invoice, isPublisher) => {
    try {
        return (isPublisher ||
            (invoice
                ? [
                    InvoiceStatus.payment_failed.value,
                    InvoiceStatus.paid.value,
                    InvoiceStatus.unpaid.value
                ].includes(invoice.data().status)
                : true));
    }
    catch (err) {
        return false;
    }
};
export const publishingDayEnumValuesFromDeadlines = (deadlines) => {
    if (!deadlines)
        return [];
    return deadlines.filter(day => day.publish).map(day => day.dayEnum);
};
// MAX_WEEKS_TO_TRY is changed here to 20 instead of 3 because in some circumstances, the original start date
// of a notice + 3 weeks is before the current date. Thus, when a user tries to add run dates on notices that
// started more than 3 weeks ago, the edit flow stops working. The fix here is to change MAX_WEEKS_TO_TRY to
// a much larger number (in this case, 20 weeks)
const MAX_WEEKS_TO_TRY = 20;
export const getClosestFuturePublishingDay = (deadlines, newspaperTimezone, notice, newspaper, initialStartDate = new Date()) => {
    let startDate = initialStartDate;
    const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.publicationOffsetDays)
        startDate = moment(startDate)
            .add(noticeType.publicationOffsetDays, 'days')
            .toDate();
    /**
     * Returns the first available date in the week on which the notice can be
     * published. If there is no available day, returns null.
     */
    const findFirstAvailableDateInWeek = (firstAvailableDate) => {
        const { restrictedPublicationDays } = noticeType || {};
        // Construct a list of all the days in the coming week.
        // If the notice type has restricted publication days, filter
        // the candidates to only include those.
        const nextWeekNonRestrictedDays = [0, 1, 2, 3, 4, 5, 6]
            .filter(ind => {
            if (!restrictedPublicationDays) {
                return true;
            }
            return restrictedPublicationDays.includes(ind);
        })
            .map(ind => {
            return moment(firstAvailableDate).day(ind);
        });
        // Determine which days are actual publishing days.
        // An actual publishing day is either a day which explicitly has a publish=true
        // override or is a scheduled day without a publish=false override
        const possiblePublishingDays = nextWeekNonRestrictedDays.filter(d => getNewspaperPublishedOnDate(newspaper, d.toDate()));
        // Consider each of the possible publishing days for next week
        for (const publishingDay of possiblePublishingDays) {
            // ignore deadlines that are before the start date
            if (publishingDay.isBefore(moment(startDate))) {
                continue;
            }
            const deadlinePassed = getIsAfterPublishingDeadline(publishingDay.toDate(), deadlines, newspaperTimezone, notice, newspaper);
            if (!deadlinePassed) {
                return publishingDay;
            }
        }
        return null;
    };
    let nextPublishingDay = null;
    for (let i = 0; i < MAX_WEEKS_TO_TRY; i++) {
        nextPublishingDay = findFirstAvailableDateInWeek(moment(startDate).add(i, 'weeks').toDate());
        if (nextPublishingDay)
            break;
    }
    if (!nextPublishingDay)
        throw new Error(`Unable to find next publishing day for paper: ${newspaper.data().name}`);
    const nextDate = nextPublishingDay.toDate();
    nextDate.setHours(12, 0, 0, 0);
    return nextDate;
};
export const getNewspaperPublishedOnDate = (newspaperSnap, dateToTest) => {
    var _a, _b;
    const { deadlines } = newspaperSnap.data();
    const publishingDayEnumValues = publishingDayEnumValuesFromDeadlines(deadlines).sort();
    // Since Moment days are 0-indexed, we compare that Moment day + 1 to the Day enum value
    const isRegularPublishingDay = publishingDayEnumValues.includes(moment(dateToTest).day() + 1);
    const deadlineOverrides = newspaperSnap.data().deadlineOverrides || {};
    const overrideKey = getDeadlineOverrideKeyFromDate(dateToTest);
    const hasPositiveOverride = ((_a = deadlineOverrides[overrideKey]) === null || _a === void 0 ? void 0 : _a.publish) === true;
    const hasNegativeOverride = ((_b = deadlineOverrides[overrideKey]) === null || _b === void 0 ? void 0 : _b.publish) === false;
    const publishedOnRegularDay = isRegularPublishingDay && !hasNegativeOverride;
    return hasPositiveOverride || publishedOnRegularDay;
};
export const getClosestPastPublishingDayForNewspaper = (newspaperSnap, options = {
    maxWeeks: 2,
    startingDate: moment().toDate()
}) => {
    for (let i = 0; i < options.maxWeeks * 7; i++) {
        const dateToTest = moment(options.startingDate).subtract(i, 'd').toDate();
        const newspaperPublishedOnDate = getNewspaperPublishedOnDate(newspaperSnap, dateToTest);
        if (newspaperPublishedOnDate) {
            return dateToTest;
        }
    }
    // If no past date was found in the given time frame, return null.
    return null;
};
export const dateObjectToDay = (date) => {
    const m = moment(date);
    return m.format('MMMM Do');
};
export const getDeadlineString = (publicationDate, deadlines, newspaperTimezone, notice, newspaper) => {
    const t = moment(Date.now());
    const newspaperTime = t.tz(newspaperTimezone).format('z');
    const deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, newspaperTimezone, notice, newspaper);
    return `${moment(deadlineTimeForPaper).format('dddd, MMMM Do, h:mm a')} ${newspaperTime}`;
};
export const dateObjectToDayEnum = (date) => moment(date).day() + 1;
