import React, { useState, useRef, useEffect } from 'react';
import { ArrowDownIcon, XIcon } from 'icons';
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClick) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClick();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
const TailWindMultiSelect = ({ options, onChange, placeholder, id, className, innerHeight }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [search, setSearch] = useState('');
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowOptions(false));
    return (React.createElement(React.Fragment, null,
        React.createElement("style", null, `
            .top-100 {top: 100%}
            .bottom-100 {bottom: 100%}
            .max-h-select {
                max-height: 300px;
            }
        `),
        React.createElement("div", { className: `${className} w-full flex flex-col items-center mx-auto`, ref: wrapperRef },
            React.createElement("div", { className: "w-full", onClick: e => {
                    e.preventDefault();
                    setShowOptions(true);
                } },
                React.createElement("div", { className: "flex flex-col items-center relative" },
                    React.createElement("div", { className: "w-full  svelte-1l8159u" },
                        React.createElement("div", { className: 'flex border border-gray-200 bg-white rounded svelte-1l8159u border border-gray-300 focus:shadow-outline-blue focus:border-blue-300' },
                            React.createElement("div", { className: "flex flex-auto flex-wrap", style: { minHeight: innerHeight === 'large' ? 50 : 36 } },
                                Object.entries(options || {}).map(([key, value]) => {
                                    return value ? (React.createElement("div", { className: "flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-gray-700 bg-gray-100 border border-gray-300" },
                                        React.createElement("div", { className: "text-xs font-normal leading-none max-w-full flex-initial" }, key),
                                        React.createElement("div", { className: "flex flex-auto flex-row-reverse", onClick: () => onChange(Object.assign(Object.assign({}, options), { [key]: false })) },
                                            React.createElement("div", null,
                                                React.createElement(XIcon, { className: "feather feather-x cursor-pointer hover:text-gray-400 rounded-full w-4 h-4 ml-2" }))))) : null;
                                }),
                                React.createElement("div", { className: "flex-1" },
                                    React.createElement("input", { id: id, onChange: (e) => setSearch(e.target.value), placeholder: Object.values(options || {}).find((o) => o)
                                            ? ''
                                            : placeholder, className: "bg-transparent p-1 px-2 appearance-none outline-none h-full w-full placeholder-gray-500 text-gray-900 text-sm" }))),
                            React.createElement("div", { className: "text-gray-300 w-8 py-1 px-1 border-l flex items-center border-gray-200 svelte-1l8159u" },
                                React.createElement("button", { className: "cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none flex items-center justify-center", onClick: e => {
                                        e.preventDefault();
                                        setShowOptions(!showOptions);
                                    } },
                                    React.createElement(ArrowDownIcon, { className: "h-5 w-5" }))))),
                    React.createElement("div", { className: "absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj" }, showOptions && (React.createElement("div", { className: "flex flex-col w-full" }, Object.entries(options || {})
                        .map(([key, value]) => {
                        if (value)
                            return false;
                        if (search &&
                            !key.toLowerCase().includes(search.toLowerCase()))
                            return false;
                        return value ? (React.createElement("div", { className: "cursor-pointer w-full border-gray-100 border-b hover:bg-blue-100", onClick: () => onChange(Object.assign(Object.assign({}, options), { [key]: !value })) },
                            React.createElement("div", { className: "flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative border-blue-600" },
                                React.createElement("div", { className: "w-full items-center flex" },
                                    React.createElement("div", { className: "mx-2 leading-6  " },
                                        key,
                                        " "))))) : (React.createElement("div", { className: "cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-blue-100", onClick: () => onChange(Object.assign(Object.assign({}, options), { [key]: !value })) },
                            React.createElement("div", { className: "flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-blue-100" },
                                React.createElement("div", { className: "w-full items-center flex" },
                                    React.createElement("div", { className: "mx-2 leading-6  " },
                                        key,
                                        " ")))));
                    })
                        .filter(Boolean)))))))));
};
export default TailWindMultiSelect;
