var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import AuthButton from 'routes/auth/AuthButton';
import LoginWrapperWithLogo from 'routes/login/LoginWrapperWithLogo';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnButton } from 'components/ColumnButton';
const ForgotPassword = ({ push }) => {
    const [email, setEmail] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false);
    const [error, setError] = useState('');
    const [submissionDisabled, setSubmissionDisabled] = useState(false);
    return (React.createElement(LoginWrapperWithLogo, null,
        React.createElement("div", null,
            React.createElement("p", { className: "mt-6 text-center text-sm leading-5 text-gray-900" }, resetSuccess
                ? 'Check your email for next steps.'
                : `Enter your email address that you used to register.
      We'll send you an email with a link to reset your password.`),
            !resetSuccess && (React.createElement("form", { className: "mt-8", onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    e.preventDefault();
                    if (submissionDisabled)
                        return;
                    setSubmissionDisabled(true);
                    try {
                        const data = yield api.post('users/send-password-reset-email', {
                            email
                        });
                        if (data.success) {
                            setResetSuccess(true);
                        }
                        else {
                            setError('Email not found. Please enter correct email.');
                        }
                    }
                    catch (err) {
                        getErrorReporter().logAndCaptureError(err, 'Error triggering password reset', { email });
                    }
                    setSubmissionDisabled(false);
                }) },
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("input", { name: "email", id: "email", type: "email", autoComplete: "email", value: email || '', onChange: e => setEmail(e.target.value), className: "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 shadow-sm", placeholder: "Email address", onFocus: () => setError('') })),
                    error && React.createElement("p", { className: "text-red-400 sm:text-sm" }, error)),
                React.createElement("div", null,
                    React.createElement("div", { className: "mt-6" },
                        React.createElement(AuthButton, null, "Reset my password")),
                    React.createElement("div", { className: "mt-6" },
                        React.createElement(ColumnButton, { onClick: () => {
                                push('/login');
                            }, buttonText: resetSuccess ? 'Sign in' : 'Back to sign in' }))))))));
};
export default connect(null, { push })(ForgotPassword);
