import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
const AFFIDAVIT_TEMPLATE_FIELDS = {
    firstPageImg: `Image of the first page of the affidavit`,
    PUBLICATION_NAME: `Name of the newspaper`,
    PUBLICATION_ADDRESS: `Address of the newspaper`,
    PUBLICATION_ADDRESS_LINE_2: `Secondary address line for newspaper`,
    PUBLICATION_NUMBER: `Phone number of the newspaper`,
    AGENT_NAME: `Name of the affidavit signatory from affidavit settings`,
    AGENT_ROLE: `Role of the affidavit signatory from affidavit settings`,
    COMMISSION_EXPIRATION_DATE: `Date of expiration of the commission from affidavit settings`,
    CUSTOMER_NAME: `Name of the person placing the notice`,
    CUSTOMER_ADDRESS: `Address of the person placing the notice`,
    CUSTOMER_ADDRESS_LINE_2: `Secondary address line for customer`,
    CUSTOMER_CITY: `City of the customer`,
    CUSTOMER_STATE: `State of the customer`,
    CUSTOMER_ZIP_CODE: `Zipcode of the customer`,
    CUSTOMER_PHONE: `Phone number of the person placing the notice`,
    ORGANIZATION_NAME: `Name of the organization of the person placing the notice`,
    ORGANIZATION_ADDRESS: `Address of the organization of the person placing the notice`,
    ORGANIZATION_ADDRESS_LINE_2: `Secondary address line of the person placing the notice`,
    ORGANIZATION_CITY: `City of the person placing the notice`,
    ORGANIZATION_STATE: `State of the person placing the notice`,
    ORGANIZATION_ZIP_CODE: `Zip code of the person placing the notice`,
    ORGANIZATION_PHONE: `Phone number of the organization of the person placing the notice`,
    PUBLICATION_FREQUENCY: `How often does this paper publish`,
    CITY: `City of the organization of the filer`,
    COUNTY: `County of the org of the filer`,
    COUNTY_UPPER: `Upper case of the county of the org of the filer`,
    STATE: `State of the filer`,
    STATE_UPPER: `Upper case of the state of the filer`,
    NUMBER_RUNS: `How many runs does this notice have`,
    FIRST_RUN: `Date of the first run`,
    LAST_RUN: `Date of the last run with format “3 Feb 2023” {{FORMAT_DATE (GET_INDEX (LAST dates) 0) "M/D/YY"}}: Date of the last run with format “2/3/2023”`,
    INTERMEDIATE_DATES: `What are the dates between the first and last run`,
    PUBLICATION_FEE: `What is the total cost of the notice, not including processing fee`,
    CURRENT_DATE: `What is the current date`,
    NOTICE_DESCRIPTOR: `Either "legal notice" or "public notice depending on template type`,
    NOTICE_ID: `What is the internal column ID of the notice`,
    NOTICE_NAME: `What is the "name" the filer gave to the notice`,
    NOTICE_TYPE: `What type of notice is this`,
    PUBLISHER_ID: `What is the internal Column ID of the newspaper (aka ad number or order number)`,
    totalPages: `How many pages is the ad`,
    IN_APP_AMOUNT: `What was invoiced in the app`,
    dates: `Date objects of the publication dates`,
    SUBMISSION_DATE: `Date notice was confirmed`,
    customerOrganizationID: `Custom ID of the customer organization associated with the person that placed the notice`,
    customerID: `Custom ID of the customer associated with the person that placed the notice`,
    noticeText: `Text of the notice that is being rendered for the affidavit (without styling)`,
    sanitizedHtml: `A sanitized version of the HTML associated with the notice that doesn’t have CSS styles`,
    confirmedHtml: `The confirmed HTML associated with the actual rendered noice`,
    LINES: `Number of lines in a notice`,
    COLUMN_INCHES: `Number of column inches  in a notice`
};
/**
 * Customized MCE editor to enable effective editing of affidavits
 */
export default function AffidavitMCEEditor({ activeOrganization, affidavitHTML, setAffidavitHTML }) {
    const logoURL = activeOrganization.data().darkLogo || activeOrganization.data().lightLogo;
    const templates = [
        {
            title: 'Newspaper Logo',
            description: 'Pulls from the logo attached to the organization',
            content: `<img src="${logoURL}" />`
        },
        ...Object.keys(AFFIDAVIT_TEMPLATE_FIELDS).map(key => ({
            title: AFFIDAVIT_TEMPLATE_FIELDS[key],
            description: AFFIDAVIT_TEMPLATE_FIELDS[key],
            content: `{{${key}}}`
        }))
    ];
    return (React.createElement(Editor, { onEditorChange: newValue => setAffidavitHTML(newValue), initialValue: affidavitHTML, init: {
            innerHeight: 500,
            outerHeight: 500,
            plugins: 'image template',
            toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | image template',
            height: 1000,
            templates
        } }));
}
