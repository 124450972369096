import React from 'react';
import { Select, MenuItem, InputBase, FormControl, InputLabel, TextField } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
const styles = (theme) => createStyles({
    dropdownStyle: {
        borderRadius: theme.spacing(1, 1, 1, 1)
    },
    label: {
        color: 'white !important',
        fontSize: 18,
        fontWeight: 500,
        width: 'max-content'
    }
});
export const EI = withStyles(theme => ({
    root: {
        borderRadius: theme.spacing(1, 1, 1, 1),
        'label + &': {
            color: 'white',
            marginTop: theme.spacing(3)
        },
        '& label': {
            color: 'white'
        },
        '& .MuiSelect-icon': {
            color: 'white'
        },
        '&.Mui-disabled': {
            '& .MuiSelect-icon': {
                visibility: 'hidden'
            }
        }
    },
    input: {
        borderRadius: theme.spacing(1, 1, 1, 1),
        position: 'relative',
        color: '#1a202c',
        backgroundColor: '#cbd5e0',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            color: '#1a202c',
            backgroundColor: '#cbd5e0'
        }
    }
}))(InputBase);
export const EI2 = withStyles(theme => ({
    root: {
        'label + &': {
            color: 'white',
            marginTop: theme.spacing(3)
        },
        '& label': {
            color: 'white'
        },
        '& .MuiSelect-icon': {
            color: 'white'
        },
        '&.Mui-disabled': {
            '& .MuiSelect-icon': {
                visibility: 'hidden'
            }
        },
        '& input': {
            borderRadius: theme.spacing(1, 1, 1, 1),
            position: 'relative',
            color: '#1a202c',
            backgroundColor: '#cbd5e0',
            padding: '10px 26px 10px 12px',
            '&:focus': {
                color: '#1a202c',
                backgroundColor: '#cbd5e0'
            }
        }
    }
}))(TextField);
export const EInput = withStyles(styles)(({ value, label, onChange, classes, InputProps, disabled }) => {
    const uid = `${value}_${Math.round(Math.random() * 100)}`;
    const label_id = `${uid}_label`;
    return (React.createElement(FormControl, { style: { width: '100%', borderRadius: '8px' } },
        React.createElement(InputLabel, { id: label_id, htmlFor: uid, className: classes.label, shrink: true }, label),
        React.createElement(EI2, { id: uid, onChange: onChange, disabled: disabled, InputProps: InputProps, value: value })));
});
const ES = ({ values, value, label, onChange, classes, disabled, id }) => {
    const uid = `${value}_${Math.round(Math.random() * 100)}`;
    const label_id = `${uid}_label`;
    return (React.createElement(FormControl, { style: { width: '100%', borderRadius: '8px' } },
        React.createElement(InputLabel, { id: label_id, htmlFor: uid, className: classes.label }, label),
        React.createElement(Select, { disabled: disabled, id: id || uid, "data-testid": id, labelId: label_id, MenuProps: { classes: { paper: classes.dropdownStyle } }, value: value, onChange: onChange, input: React.createElement(EI, null), style: { borderRadius: '8px' } }, values.map(({ val, label }) => (React.createElement(MenuItem, { value: val, key: val }, label))))));
};
export const ESelect = withStyles(styles, { withTheme: true })(ES);
