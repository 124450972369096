var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { InputAccessories, getAccessibilityLinks } from '../InputAccessories';
import { getInputTypeValidationConfig } from '../helpers/inputValidation';
import { useValidationChecks } from './hooks/useValidationChecks';
const paddingMap = {
    small: 'p-2',
    standard: 'p-3'
};
const fontSizeMap = {
    small: 'text-sm',
    standard: 'text-md'
};
export function TextField(_a) {
    var { id, errorText, noteText, size = 'standard', onChange, onFocus, onBlur, validationMessages } = _a, otherProps = __rest(_a, ["id", "errorText", "noteText", "size", "onChange", "onFocus", "onBlur", "validationMessages"]);
    const [focus, setFocus] = useState(false);
    const [nativeErrorMessage, setNativeErrorMessage] = useState('');
    const inputRef = React.createRef();
    const { showValidationMessage, clearValidationMessageIfValid } = useValidationChecks({
        inputRef,
        validationMessages,
        setNativeErrorMessage
    });
    const textSize = fontSizeMap[size];
    const errorMessage = errorText || nativeErrorMessage;
    const wrapperClasses = classNames(`flex ${textSize} rounded-md border border-gray-300`, {
        'border-blue-300 shadow-outline-blue': focus && !errorMessage,
        'border-red-600 shadow-outline-red': errorMessage,
        'bg-grey-50 text-grey-300': otherProps.disabled
    });
    function handleOnFocus(event) {
        setFocus(true);
        if (onFocus) {
            onFocus(event);
        }
    }
    function handleOnBlur(event) {
        setFocus(false);
        showValidationMessage();
        if (onBlur) {
            onBlur(event);
        }
    }
    function handleOnChange(value) {
        clearValidationMessageIfValid();
        if (onChange) {
            onChange(value);
        }
    }
    const accessibilityLinks = getAccessibilityLinks({
        id,
        noteText,
        errorMessage
    });
    const { type, value, placeholder, max, min, step, prefix, suffix, required, disabled } = otherProps;
    return (React.createElement(InputAccessories, Object.assign({ id: id, errorText: errorMessage, noteText: noteText }, otherProps),
        React.createElement("div", { className: wrapperClasses },
            React.createElement(TextInput, { id: id, type: type, inputRef: inputRef, required: required, disabled: disabled, size: size, value: value, placeholder: placeholder, prefix: prefix, suffix: suffix, max: max, min: min, step: step, accessibilityLinks: accessibilityLinks, onChange: handleOnChange, onFocus: handleOnFocus, onBlur: handleOnBlur }))));
}
function TextInput(_a) {
    var { id, inputRef, type = 'text', placeholder, size = 'standard', value, disabled, required, prefix, suffix, onChange, onFocus, onBlur, accessibilityLinks } = _a, otherProps = __rest(_a, ["id", "inputRef", "type", "placeholder", "size", "value", "disabled", "required", "prefix", "suffix", "onChange", "onFocus", "onBlur", "accessibilityLinks"]);
    const inputTypeConfig = getInputTypeValidationConfig(type);
    const inputType = type === 'currency' ? 'number' : type;
    const inputClasses = classNames(`${paddingMap[size]} w-full rounded-md focus:outline-none`, {
        'pl-0': prefix,
        'pr-0': suffix
    });
    function handleOnChange(e) {
        e.target.value = inputTypeConfig.format
            ? inputTypeConfig.format(e.target.value)
            : e.target.value;
        onChange && onChange(e.target.value);
    }
    const inputProps = Object.assign(Object.assign(Object.assign(Object.assign({ id, ref: inputRef, name: id, type: inputType, value: inputTypeConfig.format && value ? inputTypeConfig.format(value) : value, disabled,
        required }, otherProps), inputTypeConfig.props), accessibilityLinks), { placeholder, onChange: handleOnChange, onFocus,
        onBlur, className: inputClasses });
    return (React.createElement(React.Fragment, null,
        React.createElement(InputSection, { size: size }, prefix),
        React.createElement("input", Object.assign({}, inputProps)),
        React.createElement(InputSection, { size: size }, suffix)));
}
function InputSection({ children, size }) {
    if (!children) {
        return null;
    }
    return React.createElement("div", { className: paddingMap[size] }, children);
}
