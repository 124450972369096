import React, { useState } from 'react';
import { DeleteIcon, PencilIcon } from 'icons';
import { ColumnButton } from 'components/ColumnButton';
import { centsToExtendedCurrency } from '../ratesTable/ratesTableSettingsUtils';
import SettingsTableIcon from '../../SettingsTableIcon';
import RemoveFeeModal from './RemoveFeeModal';
/**
 * Table component for display rates and handling pagination
 */
export default function AdditionalFeesTable({ setEditedAdditionalFeeIndex, setEditedAdditionalFee, activeOrganization, additionalFees }) {
    const [feeToRemove, setFeeToRemove] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const PAGE_SIZE = 10;
    const additionalFeeTableSlice = additionalFees.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    const totalPages = Math.ceil(additionalFees.length / PAGE_SIZE);
    return (React.createElement(React.Fragment, null,
        feeToRemove && (React.createElement(RemoveFeeModal, { activeOrganization: activeOrganization, feeToRemove: feeToRemove, onClose: () => setFeeToRemove(undefined) })),
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
            React.createElement("thead", { className: "h-16" },
                React.createElement("tr", { className: "text-left bg-gray-50 uppercase text-gray-650 text-sm tracking-wider" },
                    React.createElement("th", { className: "pl-12 font-medium" }, "Fee Description"),
                    React.createElement("th", { className: "font-medium" }, "Fee Value"),
                    React.createElement("th", { className: "font-medium" }, "Apply Per Run"),
                    React.createElement("th", { className: "font-medium" },
                        React.createElement("div", { className: "flex pr-12" },
                            React.createElement("div", { className: "flex-1" }),
                            React.createElement("div", null, "Actions"))))),
            React.createElement("tbody", { className: "divide-y divide-gray-200 rounded-b-lg" }, additionalFeeTableSlice.map(additionalFee => (React.createElement("tr", { className: "hover:bg-gray-50 h-20 text-sm leading-6 font-medium text-gray-650", key: additionalFee.description },
                React.createElement("td", { className: "pl-12" }, additionalFee.description),
                React.createElement("td", null, centsToExtendedCurrency(additionalFee.amount)),
                React.createElement("td", null, additionalFee.perRun ? 'Yes' : 'No'),
                React.createElement("td", null,
                    React.createElement("div", { className: "flex mr-10" },
                        React.createElement("div", { className: "flex-1" }),
                        React.createElement(SettingsTableIcon, { onClick: () => {
                                setEditedAdditionalFeeIndex(additionalFees.indexOf(additionalFee));
                                setEditedAdditionalFee(additionalFee);
                            }, icon: React.createElement(PencilIcon, { className: "w-5 h-5 text-grey-400" }) }),
                        React.createElement(SettingsTableIcon, { onClick: () => setFeeToRemove(additionalFee), icon: React.createElement(DeleteIcon, { className: "text-grey-400" }) })))))))),
        React.createElement("footer", { className: "bg-white pr-5 py-0.5 border-t border-b border-gray-300 rounded-b-md" },
            React.createElement("div", { className: "pl-6 py-3.5 flex justify-between" },
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Previous", disabled: pageNumber === 0, onClick: () => setPageNumber(pageNumber - 1) })),
                React.createElement("div", { className: "py-2.5 leading-6 text-sm font-medium text-grey-400" },
                    "Page ",
                    pageNumber + 1,
                    " of ",
                    totalPages),
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Next", disabled: pageNumber === totalPages - 1 || !totalPages, onClick: () => setPageNumber(pageNumber + 1) }))))));
}
