var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { includeSearchParams } from 'utils/urls';
import LoadingState from 'components/LoadingState';
import { OccupationType } from 'lib/enums';
import api from 'api';
import { exists } from 'lib/types';
import { PUBLISHER_CAN_REGISTER } from '../../../constants';
import AuthActions from '../../../redux/auth';
import newspaper from './newspaper.svg';
import others from './gov-law-person.svg';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import StepsCounter from '../StepsCounter';
import backgroundImg from '../backgroundImg.svg';
import RegistrationHeader from '../RegistrationHeader';
const mapStateToProps = (state) => ({
    user: state.auth.user ? state.auth.user : null
});
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
const Welcome = ({ user, push }) => {
    const [occupationType, setOccupationType] = useState();
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [responseError, setResponseError] = useState('');
    const [loadingPubReg, setLoadingPubReg] = useState(false);
    const formRef = useRef();
    /**
     * The Function Flashes the error message for 5 secs
     * @param message:string Message to flash
     */
    const flashResponseError = (message) => {
        setResponseError(message);
        setTimeout(() => {
            setResponseError('');
        }, 5000);
    };
    /* flashResponseError ENDS */
    const nextButtonClicked = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (occupationType === 'newspaper') {
            yield newspaperSignup();
        }
        else
            occupations();
    });
    const newspaperSignup = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!PUBLISHER_CAN_REGISTER)
            throw new Error('Request From Unknown Origin not accepted');
        if (!exists(user))
            return;
        setLoadingPubReg(true);
        const response = yield api.post('users/assign-occupation', {
            email: user.data().email,
            occupation: OccupationType.publishing.value
        });
        if (response.success) {
            push(includeSearchParams('/register/organization'));
            setLoadingPubReg(false);
        }
        else {
            setLoadingPubReg(false);
            console.error(response.error);
            flashResponseError(`Whoops, we're having trouble creating the account. Please refresh and try again. If that doesn't help email help@column.us and we will assist you!`);
        }
    });
    useEffect(() => {
        if (window.location.href.includes('florida')) {
            void newspaperSignup();
        }
    }, []);
    const occupations = () => {
        push(includeSearchParams('/register/occupations'));
    };
    useEffect(() => {
        if (occupationType)
            setDisableSubmit(false);
    }, [occupationType]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-gray-50", style: {
                backgroundImage: `url(${backgroundImg})`,
                width: '100vw',
                height: '100vh'
            } },
            React.createElement(RegistrationHeader, null),
            React.createElement("div", { className: "min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 -mt-12" }, loadingPubReg || !PUBLISHER_CAN_REGISTER ? (React.createElement(LoadingState, null)) : (React.createElement("div", { className: "max-w-md w-full" },
                React.createElement("div", null,
                    React.createElement(StepsCounter, { maxStep: '3', stepCount: '1', isStepDone: Boolean(occupationType) }),
                    React.createElement("p", { className: "mt-8 text-gray-900 text-center text-2xl leading-8 font-semibold", id: "welcome-text" }, "How do you plan to use Column?"),
                    React.createElement("p", { className: "mt-4 text-center text-sm font-medium text-gray-600", id: "welcome-text" }, "Tell us a bit about yourself so we can personalize your experience on Column.")),
                React.createElement("div", { ref: formRef, className: "mt-10" },
                    React.createElement("div", { className: "mt-6 grid grid-cols-1 gap-y-6 gap-x-2 sm:grid-cols-6" },
                        React.createElement("label", { role: "presentation", tabIndex: 0, onKeyDown: e => {
                                if (e.key === 'Enter')
                                    setOccupationType('others');
                            }, style: { height: 200 }, htmlFor: "others", className: `outline-none focus:border-blue-500 rounded-md shadow-sm sm:col-span-3 bg-white border cursor-pointer p-5
              ${occupationType === 'others' ? 'border-blue-500' : ''}
              ` },
                            React.createElement("img", { src: others, alt: "others", className: `m-auto`, style: { minHeight: 100 } }),
                            React.createElement("p", { id: "advertiser-flow", className: "text-center mb-1 leading-4 pt-3 font-medium" }, "I want to place a notice in a newspaper"),
                            React.createElement("input", { id: "others", type: "checkbox", className: `hidden`, name: "occupation", onClick: () => {
                                    setOccupationType('others');
                                } })),
                        React.createElement("label", { role: "presentation", tabIndex: 0, onKeyDown: e => {
                                if (e.key === 'Enter')
                                    setOccupationType('newspaper');
                            }, style: { height: 200 }, htmlFor: "newspaper", className: `outline-none focus:border-blue-500 rounded-md shadow-sm sm:col-span-3 bg-white border cursor-pointer p-5
              ${occupationType === 'newspaper' ? 'border-blue-500' : ''}
              ` },
                            React.createElement("img", { src: newspaper, alt: "newspaper", className: `m-auto`, style: { minHeight: 100 } }),
                            React.createElement("p", { id: "newspaper-flow", className: "text-center mb-1 leading-4 font-medium" }, "I work at a newspaper"),
                            React.createElement("input", { id: "newspaper", type: "checkbox", className: `hidden`, name: "occupation", onClick: () => {
                                    setOccupationType('newspaper');
                                } })))),
                React.createElement("p", { className: `mt-3 text-center text-red-500 text-xs${responseError ? '' : 'hidden'}` }, responseError))))),
        React.createElement(AdvertiserRegistrationFooter, { id: "choose-to-register", nextButtonText: "Continue", onNextButtonClick: nextButtonClicked, disableNextButton: disableSubmit, hideBackButton: true }),
        React.createElement("style", null, `
      #newspaper-flow {
        padding-top: 1.125rem;
      }
      `)));
};
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
