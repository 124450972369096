var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import { dateToAbbrev } from 'lib/helpers';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import api from 'api';
import { ExternalLinkIcon } from 'icons';
import { Collections } from 'lib/constants';
import Firebase from 'EnoticeFirebase';
import { InvoiceStatus, NoticeStatusType } from 'lib/enums';
import { getDistributeFeeSettings, invoiceDataToDBPricingObject } from 'lib/pricing';
import { getFirebaseContext } from 'utils/firebase';
import { filerOrgHasCustomerOrgWithBulkPaymentEnabled, userHasCustomerWithBulkPaymentEnabled } from 'lib/notice/customer';
const PAGE_SIZE = 10;
const NoticeTable = ({ notices, previousPage, pageNumber, nextPage, hasMore, userId }) => {
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [invoiceAmounts, setInvoiceAmounts] = useState();
    useEffect(() => {
        setInvoiceAmounts([]);
        if (notices) {
            void (() => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c;
                const amounts = [];
                for (const notice of notices) {
                    const invoice = yield ((_a = notice.data().invoice) === null || _a === void 0 ? void 0 : _a.get());
                    const newspaperSnap = yield notice.data().newspaper.get();
                    if (!exists(invoice))
                        return;
                    const { inAppLineItems, convenienceFeePct, affidavitFeeInCents, inAppTaxPct, disableMinimumConvenieceFee } = invoice.data();
                    const rate = yield notice.data().rate.get();
                    const DBPricingObject = invoiceDataToDBPricingObject(inAppLineItems, convenienceFeePct, affidavitFeeInCents, inAppTaxPct, getDistributeFeeSettings(newspaperSnap, rate), disableMinimumConvenieceFee, (_b = rate.data()) === null || _b === void 0 ? void 0 : _b.finalLineItemPricing, (_c = rate.data()) === null || _c === void 0 ? void 0 : _c.rateType);
                    amounts.push(DBPricingObject.total);
                }
                setInvoiceAmounts(amounts);
            }))();
        }
    }, [notices]);
    const getReceiptUrl = (invoice) => __awaiter(void 0, void 0, void 0, function* () {
        return invoice.data().status === InvoiceStatus.refunded.value
            ? invoice.data().receipt_pdf
            : yield Firebase.storage()
                .ref('')
                .child(`/${invoice.data().receipt_pdf}`)
                .getDownloadURL();
    });
    const openInvoicePDF = (invoice) => __awaiter(void 0, void 0, void 0, function* () {
        if (!invoice)
            return;
        const invoiceSnap = yield invoice.get();
        if (!exists(invoiceSnap)) {
            return;
        }
        const url = invoiceSnap.data().receipt_pdf
            ? yield getReceiptUrl(invoiceSnap)
            : (invoiceSnap.data().invoice_pdf || '').startsWith('https://')
                ? invoiceSnap.data().invoice_pdf
                : yield Firebase.storage()
                    .ref('')
                    .child(`/${invoiceSnap.data().invoice_pdf}`)
                    .getDownloadURL();
        window.open(url);
    });
    return (React.createElement(React.Fragment, null,
        showDownloadModal && (React.createElement(NoticeDownloadModal, { userId: userId, close: () => setShowDownloadModal(false) })),
        React.createElement("div", { className: "py-2 align-middle inline-block min-w-full mt-2" },
            React.createElement("div", { className: "flex justify-between mb-5" },
                React.createElement("div", { className: "flex flex-col justify-end flex-1 text-gray-800 font-bold text-xl mt-2 -mb-2" }, "Notices"),
                React.createElement("div", null,
                    React.createElement("button", { className: "bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center", onClick: () => setShowDownloadModal(true) },
                        React.createElement("svg", { className: "fill-current w-4 h-4 mr-2", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" },
                            React.createElement("path", { d: "M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" })),
                        React.createElement("span", null, "Download")))),
            React.createElement("div", { className: "shadow overflow-hidden border border-gray-200 sm:rounded-lg" },
                React.createElement("table", { id: "notices-table", className: "min-w-full divide-y divide-gray-200" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Publication Date(s)"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Notice Name"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Total"),
                            React.createElement("th", { className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, "Receipt"))),
                    React.createElement("tbody", { className: "bg-white divide-y divide-gray-200" }, notices.map((notice, i) => (React.createElement("tr", { key: notice.id },
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, (notice.data().publicationDates || [])
                            .map(ts => dateToAbbrev(ts.toDate()))
                            .join(', ')),
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, notice.data().referenceId),
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, invoiceAmounts
                            ? `$${Math.abs(invoiceAmounts[i] / 100).toFixed(2)}`
                            : ''),
                        React.createElement("td", { className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700 tooltip" }, React.createElement(React.Fragment, null,
                            React.createElement(ExternalLinkIcon, { className: "w-6 h-6 cursor-pointer", onClick: () => openInvoicePDF(notice.data().invoice) }),
                            React.createElement("span", { className: "tooltiptext normal-case mt-24 -ml-12" }, "Open invoice PDF in a new tab")))))))),
                React.createElement("nav", { className: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" },
                    React.createElement("div", { className: "hidden sm:block" },
                        React.createElement("p", { className: "text-sm leading-5 text-gray-700" },
                            "Showing results",
                            React.createElement("span", { className: "font-medium mx-1" }, pageNumber * PAGE_SIZE + 1),
                            "to",
                            React.createElement("span", { className: "font-medium mx-1" }, pageNumber * PAGE_SIZE + notices.length))),
                    React.createElement("div", { className: "flex-1 flex justify-between sm:justify-end" },
                        React.createElement("div", { className: `cursor-${pageNumber > 0 ? 'pointer' : 'not-allowed'} relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => pageNumber > 0 && previousPage() }, "Previous"),
                        React.createElement("div", { className: `cursor-${hasMore ? 'pointer' : 'not-allowed'} ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`, onClick: () => hasMore && nextPage() }, "Next")))))));
};
const EmptyNotices = () => (React.createElement("div", { className: "flex w-full h-full flex-col justify-center" },
    React.createElement("div", { className: "text-center text-xl text-medium" }, "Notices will appear here once your notices are invoiced")));
const NoticeDownloadModal = ({ close, userId }) => {
    const [loadingNotices, setLoadingNotices] = useState(false);
    const [period, setPeriod] = useState('lastMonth');
    const [error, setError] = useState('');
    const downloadNotices = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingNotices(true);
        try {
            const response = yield api.post(`documents/bulk-invoice/download`, {
                userId,
                period
            });
            if (response.url)
                window.open(response.url);
            else
                setError('No available notices in the given period');
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to download bulk invoice CSV', {
                userId,
                timePeriod: period
            });
            setError('There was an error downloading your CSV. Our support team has been notified and will be in touch!');
        }
        setLoadingNotices(false);
    });
    return (React.createElement("div", { className: "fixed z-10 inset-0 overflow-y-auto" },
        React.createElement("div", { className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity" },
                React.createElement("div", { className: "absolute inset-0 bg-gray-500 opacity-75" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" },
                React.createElement("div", null,
                    React.createElement("div", { className: "mt-3 text-center sm:mt-5" },
                        React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900", id: "modal-headline" }, "Download Notices"),
                        React.createElement("div", { className: "my-5" },
                            React.createElement("div", { className: "col-span-6 sm:col-span-3" },
                                React.createElement("div", { className: "mb-2 text-left block text-sm font-medium leading-5 text-gray-700" }, "Download notices from"),
                                React.createElement("select", { value: period, onChange: e => setPeriod(e.target.value), id: "country", className: "mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" },
                                    React.createElement("option", { value: "lastMonth" }, "Previous Month"),
                                    React.createElement("option", { value: "week" }, "Previous 7 Days"),
                                    React.createElement("option", { value: "currMonth" }, "Current Month")))))),
                error && (React.createElement("div", { className: "my-5" },
                    React.createElement("div", { className: "text-red" }, error))),
                loadingNotices ? (React.createElement("div", { className: "flex justify-center mt-3 w-full" },
                    React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" }))) : (React.createElement("div", { className: "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense" },
                    React.createElement("span", { className: "flex w-full rounded-md shadow-sm sm:col-start-2" },
                        React.createElement("button", { type: "button", className: "shadow inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5", onClick: () => downloadNotices() }, "Download")),
                    React.createElement("span", { className: "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1" },
                        React.createElement("button", { type: "button", className: "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5", onClick: () => close() }, "Cancel"))))))));
};
export const shouldLoadBulkNoticesAndInvoices = (user, organization) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const ctx = getFirebaseContext();
    return ((user &&
        (user.data().bulkPaymentEnabled ||
            ((_a = organization === null || organization === void 0 ? void 0 : organization.data()) === null || _a === void 0 ? void 0 : _a.bulkPaymentEnabled))) ||
        (yield filerOrgHasCustomerOrgWithBulkPaymentEnabled(ctx, organization)) ||
        (yield userHasCustomerWithBulkPaymentEnabled(ctx, user)));
});
const Notices = ({ user, organization }) => {
    const [pageNumber, setPageNumber] = useState(0);
    const [notices, setNotices] = useState([]);
    const [allNotices, setAllNotices] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const loadNotices = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setPageNumber(0);
        let query;
        const shadowUserId = user.data().shadowUser;
        if (exists(organization)) {
            query = getFirebaseContext()
                .userNoticesRef()
                .where('isWithinBulkInvoice', '==', true)
                .where('filedBy', '==', organization.ref)
                .orderBy('createTime', 'desc');
        }
        // User is being shadowed
        else if (shadowUserId) {
            const shadowUserRef = Firebase.firestore()
                .collection(Collections.users)
                .doc(shadowUserId);
            query = getFirebaseContext()
                .userNoticesRef()
                .where('isWithinBulkInvoice', '==', true)
                .where('filer', '==', shadowUserRef)
                .orderBy('createTime', 'desc');
        }
        else {
            query = getFirebaseContext()
                .userNoticesRef()
                .where('isWithinBulkInvoice', '==', true)
                .where('filer', '==', user.ref)
                .orderBy('createTime', 'desc');
        }
        let noticesSnaps = (yield query.get()).docs;
        noticesSnaps = noticesSnaps.filter(n => n.data().invoice &&
            n.data().noticeStatus !== NoticeStatusType.cancelled.value);
        setAllNotices(noticesSnaps);
        setNotices(noticesSnaps.slice(pageNumber * PAGE_SIZE, pageNumber * PAGE_SIZE + PAGE_SIZE));
        setHasMore(pageNumber < Math.ceil(noticesSnaps.length / PAGE_SIZE) - 1);
        setLoading(false);
    });
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            if (yield shouldLoadBulkNoticesAndInvoices(user, organization)) {
                yield loadNotices();
            }
        }))();
    }, [user, organization]);
    useEffect(() => {
        setNotices(allNotices.slice(pageNumber * PAGE_SIZE, pageNumber * PAGE_SIZE + PAGE_SIZE));
        setHasMore(pageNumber < Math.ceil(allNotices.length / PAGE_SIZE) - 1);
    }, [pageNumber]);
    return (React.createElement(React.Fragment, null,
        loading && notices && React.createElement(LoadingState, null),
        notices.length && !loading ? (React.createElement(NoticeTable, { userId: user.id, notices: notices, pageNumber: pageNumber, hasMore: hasMore, nextPage: () => __awaiter(void 0, void 0, void 0, function* () {
                setPageNumber(pageNumber < Math.ceil(allNotices.length / PAGE_SIZE) - 1
                    ? pageNumber + 1
                    : pageNumber);
            }), previousPage: () => __awaiter(void 0, void 0, void 0, function* () {
                setPageNumber(pageNumber !== 0 ? pageNumber - 1 : pageNumber);
            }) })) : (React.createElement(EmptyNotices, null))));
};
export default Notices;
