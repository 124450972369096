var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import EToast from 'components/EToast';
import { OccupationType } from 'lib/enums';
import { exists } from 'lib/types';
import { getRedirect } from 'utils/urls';
import LoadingState from 'components/LoadingState';
import TailwindModal from 'components/TailwindModal';
import AdvertisersInvitesForm from 'routes/invites/AdvertisersInvitesForm';
import { BROWSER, BROWSERS } from '../../../constants';
import CreateInvitesFormContainer from '../../invites/CreateInvitesFormContainer';
import RatesTables from '../../settings/publisher/rates/old/RatesTables';
import { isAdvertiserOrganization } from '../helpers';
const INVITE_MEMBERS = 'invite-members';
const SET_RATES = 'set-rates';
const styles = (theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    organizationName: {
        fontWeight: 500
    },
    grid: {
        marginTop: theme.spacing(8),
        display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    button: {
        marginTop: theme.spacing(1)
    },
    invitationFormWrapper: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
});
const mapStateToProps = (state) => ({
    user: state.auth.user ? state.auth.user.data() : {}
});
const PostOrgRegistration = ({ classes, user, push }) => {
    var _a, _b;
    const { t } = useTranslation();
    const [step, setStep] = useState();
    const [activeOrganization, setActiveOrganization] = useState();
    // show a stepper in the middle of the screen with "invite members!"
    const [engagedWithForm, setEngagedWithForm] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [InviteFormHeader, setInviteFormHeader] = useState(t('member-settings.invite-header'));
    const [showInviteForm, setShowInviteForm] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            toastMessage && setToastMessage('');
        }, 3000);
        return () => clearTimeout(timer);
    }, [toastMessage]);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        yield (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref.update({
            postRegistrationComplete: true
        }));
    });
    useEffect(() => {
        activeOrganization && step === INVITE_MEMBERS && setShowInviteForm(true);
    }, [step]);
    useEffect(() => {
        let organizationUnsub;
        const setActive = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!user.organization)
                return;
            if (user.activeOrganization) {
                organizationUnsub = user.activeOrganization.onSnapshot(snapshot => {
                    if (exists(snapshot)) {
                        setActiveOrganization(snapshot);
                    }
                    else {
                        setActiveOrganization(undefined);
                    }
                });
            }
            else if (user.organization) {
                const org = yield user.organization.get();
                if (exists(org)) {
                    setActiveOrganization(org);
                }
                else {
                    setActiveOrganization(undefined);
                }
            }
        });
        void setActive();
        return () => {
            organizationUnsub && organizationUnsub();
        };
    }, [user && user.organization]);
    useEffect(() => {
        if (!exists(activeOrganization))
            return;
        let activeRegistrationStep;
        activeRegistrationStep =
            user.occupation === OccupationType.publishing.value &&
                activeOrganization &&
                step !== INVITE_MEMBERS
                ? SET_RATES
                : INVITE_MEMBERS;
        if (isAdvertiserOrganization(user.occupation) &&
            activeOrganization &&
            activeOrganization.data().postRegistrationComplete)
            activeRegistrationStep = INVITE_MEMBERS;
        setStep(activeRegistrationStep);
        if (activeOrganization &&
            user.activeOrganization &&
            activeOrganization.data().postRegistrationComplete) {
            push(`${getRedirect()}&activeOrg=${activeOrganization.id}`);
        }
    }, [
        activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id,
        (_a = user.activeOrganization) === null || _a === void 0 ? void 0 : _a.id,
        (_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.postRegistrationComplete
    ]);
    if (!step)
        return React.createElement(LoadingState, null);
    return (React.createElement(React.Fragment, null,
        activeOrganization && step === SET_RATES && (React.createElement(React.Fragment, null,
            React.createElement(Box, { mb: 1 },
                React.createElement(Typography, { variant: "h5" }, "Set your rates")),
            React.createElement(RatesTables, { activeOrganization: activeOrganization }),
            React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", m: 2 },
                React.createElement(Button, { id: "finish-rate-submit", type: "submit", className: classes.button, variant: "contained", color: "primary", onClick: () => setStep(INVITE_MEMBERS) }, "Finish Setting Rates")))),
        showInviteForm &&
            activeOrganization &&
            (isAdvertiserOrganization(user.occupation) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "w-full items-center" },
                    React.createElement(AdvertisersInvitesForm, { organization: activeOrganization, user: user, onSkipForNowClick: handleClick })))) : (React.createElement(TailwindModal, { header: InviteFormHeader, close: () => handleClick(), noExitOutsideModal: true },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "w-full items-center" },
                        React.createElement(CreateInvitesFormContainer, { setEngagedWithForm: setEngagedWithForm, organization: activeOrganization, user: user, setToastMessage: setToastMessage, engagedWithForm: engagedWithForm, onSkipForNowClick: handleClick, setInviteFormHeader: setInviteFormHeader })))))),
        toastMessage && (React.createElement(EToast, { message: toastMessage, type: "success", close: () => setToastMessage('') }))));
};
export default connect(mapStateToProps, { push })(withStyles(styles)(PostOrgRegistration));
