var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import Firebase from 'EnoticeFirebase';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Box, Table, TableBody, TableHead, TableRow, TableCell, Button, SvgIcon } from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import moment from 'moment';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
const styles = (theme) => createStyles({
    tableSection: {
        width: '100%'
    },
    noteIcon: {
        WebkitTransform: 'rotate(90deg)',
        msTransform: 'rotate(90deg)',
        transform: 'rotate(90deg)',
        color: '#4A5568'
    },
    memoText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3C4257',
        marginLeft: '15px'
    },
    rowFlex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '15px'
    },
    pdfBox: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    subtotalAmtBox: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    subtotalBox: {
        flexGrow: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    pdfButton: {
        border: '0.5px solid #DADADA',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        fontSize: '13px',
        height: theme.spacing(4)
    },
    tableHeadText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: '#1A1F36'
    },
    tableBodyText: {
        fontSize: '14px',
        lineHeight: '16px',
        color: '#3C4257'
    },
    rowFlexEnd: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#3C4257'
    },
    taxBox: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
});
const InvoiceTable = ({ classes, invoice, invoicePricingData }) => {
    const { appliedBalance, netTotal, processingFee, taxFee, subtotal, inAppLineItems, currency } = invoicePricingData;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.tableSection },
            React.createElement(Box, { ml: 2, mr: 2, mt: 2 },
                React.createElement(Box, { style: { whiteSpace: 'pre-line' }, ml: 2 },
                    React.createElement(NoteIcon, { className: classes.noteIcon }),
                    React.createElement("span", { className: classes.memoText }, invoice.data().customMemo)),
                React.createElement(Box, { mt: 2, mb: 2 },
                    React.createElement(ItemsTable, { classes: classes, lineItems: inAppLineItems, currency: currency }),
                    React.createElement(Box, { ml: 2, className: `${classes.rowFlex} ${classes.tableBodyText}` },
                        React.createElement(Box, { className: classes.pdfBox },
                            React.createElement(Button, { className: classes.pdfButton, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    return window.open(yield Firebase.storage()
                                        .ref('')
                                        .child(`/${invoice.data().invoice_pdf}`)
                                        .getDownloadURL());
                                }), startIcon: React.createElement(SvgIcon, { fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: "currentColor", style: { fill: 'none' } },
                                    React.createElement("path", { d: "M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" })) }, "PDF")),
                        React.createElement(Box, { ml: 2, className: classes.subtotalBox }, "Subtotal"),
                        React.createElement(Box, { mr: 2, className: classes.subtotalAmtBox }, `${currency.symbol}${(subtotal / 100).toFixed(2)}`)),
                    React.createElement(React.Fragment, null,
                        React.createElement(Box, { className: classes.rowFlexEnd },
                            React.createElement(Box, { mr: 6 }, `Tax(${invoice.data().inAppTaxPct}%)`),
                            React.createElement(Box, { mr: 2 }, `${currency.symbol}${(taxFee / 100).toFixed(2)}`)),
                        !!processingFee && typeof processingFee === 'number' && (React.createElement(Box, { mt: 2, className: classes.rowFlexEnd },
                            React.createElement(Box, { mr: 6 }, `Processing Fee`),
                            React.createElement(Box, { mr: 2 }, `${currency.symbol}${(processingFee / 100).toFixed(2)}`))),
                        appliedBalance ? (React.createElement(Box, { mt: 2, className: classes.rowFlexEnd },
                            React.createElement(Box, { mr: 4.25 }, `Applied Balance`),
                            React.createElement(Box, { mr: 2 }, `-${currency.symbol}${(appliedBalance / 100).toFixed(2)}`))) : ('')),
                    React.createElement(Box, { mt: 2, mb: 4, className: classes.rowFlexEnd },
                        React.createElement(Box, { mr: 5 }, "Amount due"),
                        React.createElement(Box, { mr: 2 }, `${currency.symbol}${(netTotal / 100).toFixed(2)}`)))))));
};
const ItemsTable = ({ classes, lineItems, currency }) => {
    const [rows, setRows] = useState();
    const getItemDescription = (item) => {
        const date = moment(firestoreTimestampOrDateToDate(item.date)).format('MM/DD/YYYY');
        return `${date}: Custom Notice`;
    };
    useEffect(() => {
        if (lineItems) {
            let total = 0;
            const rows = [];
            lineItems.forEach((item) => {
                const itemAmount = item.refund ? -1 * item.amount : item.amount;
                total += itemAmount;
                const rowObj = {
                    description: item.description || getItemDescription(item),
                    quantity: 1,
                    price: (item.amount / 100).toFixed(2),
                    total: (total / 100).toFixed(2),
                    refund: item.refund
                };
                rows.push(rowObj);
            });
            setRows(rows);
        }
    }, [lineItems]);
    if (!rows)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Table, null,
        React.createElement(TableHead, null,
            React.createElement(TableRow, { className: classes.tableHeadText },
                React.createElement(TableCell, null, "DESCRIPTION"),
                React.createElement(TableCell, { align: "right" }, "QTY"),
                React.createElement(TableCell, { align: "right" }, "PRICE"),
                React.createElement(TableCell, { align: "right" }, "TOTAL"))),
        React.createElement(TableBody, { className: classes.tableBodyText }, rows &&
            rows.map((row) => (React.createElement(TableRow, { key: row.description },
                React.createElement(TableCell, { component: "th", scope: "row" }, row.description),
                React.createElement(TableCell, { align: "right" }, row.quantity),
                React.createElement(TableCell, { align: "right" },
                    row.refund ? '-' : '',
                    currency.symbol,
                    row.price),
                React.createElement(TableCell, { align: "right" },
                    currency.symbol,
                    row.total)))))));
};
export default withStyles(styles)(InvoiceTable);
