import React from 'react';
import ColumnSelect from 'components/select/ColumnSelect';
const MultipleChoiceQuestionInput = ({ question, value, onChange }) => {
    return (React.createElement("div", null,
        React.createElement("p", { className: "mb-1 text-sm" }, question.prompt),
        React.createElement(ColumnSelect, { allowUndefined: true, options: question.choices.map(choice => {
                var _a;
                return ({
                    value: choice.value,
                    label: (_a = choice.label) !== null && _a !== void 0 ? _a : choice.value
                });
            }), value: value || '', onChange: v => {
                onChange({
                    varName: question.varName,
                    value: v
                });
            } })));
};
export default MultipleChoiceQuestionInput;
