var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import InputMask from 'react-input-mask';
import api from 'api';
import * as EmailValidator from 'email-validator';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import * as validators from '../../../../../register/organization/validators';
import PayInvoiceButton from '../buttons/PayInvoiceButton';
import { elavonCardTokenizerInjector } from './helpers/ElavonCardTokenizerInjector';
export function ElavonCardCheckoutForm({ payInvoiceData, invoicePricingData, handleSuccessfulPayment }) {
    // Payment-processing related state
    const [tokenizerLoaded, setTokenizerLoaded] = useState(false);
    const [loadingTokenizer, setLoadingTokenizer] = useState(false);
    const [requestingSessionToken, setRequestingSessionToken] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [triedToPay, setTriedToPay] = useState(false);
    const loading = requestingSessionToken || processingPayment || !tokenizerLoaded;
    const [sessionToken, setSessionToken] = useState('');
    const [paymentDetailsToken, setPaymentDetailsToken] = useState('');
    const [err, setErr] = useState('');
    // Payment details
    const [creditCardNumber, setCreditCardNumber] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [cvc, setCvc] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // Customer details
    const [email, setEmail] = useState('');
    const { invoice } = payInvoiceData;
    const invoiceId = invoice.id;
    const requestSessionToken = () => __awaiter(this, void 0, void 0, function* () {
        setRequestingSessionToken(true);
        try {
            const response = yield api.post(`charges/create-session`, {
                invoiceId
            });
            setSessionToken(response.token);
        }
        catch (err) {
            logAndCaptureException(err, 'Unable to get payment session token for Elavon', { invoiceId });
            setErr('Unable to start payment session. Please contact help@column.us');
        }
        setRequestingSessionToken(false);
    });
    useEffect(() => {
        const loadElavonTokenizer = () => __awaiter(this, void 0, void 0, function* () {
            if (tokenizerLoaded || loadingTokenizer)
                return;
            setLoadingTokenizer(true);
            try {
                yield elavonCardTokenizerInjector();
                setTokenizerLoaded(true);
            }
            catch (e) {
                logAndCaptureMessage('Checkout.js did not load successfully to pay Elavon invoice!', {
                    invoiceId
                });
                setErr('Something went wrong. Please refresh the page and try again. Contact help@column.us if this issue persists.');
            }
            setLoadingTokenizer(false);
        });
        void loadElavonTokenizer();
        void requestSessionToken();
    }, []);
    useEffect(() => {
        void processElavonCharge();
    }, [paymentDetailsToken]);
    useEffect(() => {
        if (triedToPay)
            void validateInput();
    }, [
        creditCardNumber,
        expirationDate,
        cvc,
        firstName,
        lastName,
        email,
        triedToPay
    ]);
    const validateInput = () => {
        if (!creditCardNumber ||
            !expirationDate ||
            !cvc ||
            !firstName ||
            !lastName ||
            !email) {
            setErr('Please check that all payment fields are complete.');
            return false;
        }
        if (!validators.creditCard(creditCardNumber)) {
            setErr('Please enter a valid credit card number.');
            return false;
        }
        if (!validators.cvc(cvc)) {
            setErr('Please enter a valid cvc code.');
            return false;
        }
        if (!validators.expirationDate(expirationDate)) {
            setErr('Credit card expiration date must be valid and in the future.');
            return false;
        }
        if (!EmailValidator.validate(email)) {
            setErr('Please check that the email entered is correct.');
            return false;
        }
        setErr('');
        return true;
    };
    const handleTokenizePaymentResponse = {
        onError(error) {
            logAndCaptureMessage(`Tokenization Error response from Checkout.js: ${JSON.stringify(error)}`, { invoiceId });
            setErr(error);
            setProcessingPayment(false);
            void requestSessionToken();
        },
        onDeclined(response) {
            const errorMessage = response.ssl_token_response || response.errorMessage;
            if (errorMessage) {
                setErr(`Failed to process payment: ${errorMessage}  Please try again.`);
            }
            else {
                logAndCaptureMessage(`Unexpected tokenization decline response from Checkout.js: ${JSON.stringify(response)}`, { invoiceId });
                setErr('Failed to process payment. Please try again.');
            }
            setProcessingPayment(false);
            void requestSessionToken();
        },
        onApproval(response) {
            const { ssl_token } = response;
            if (!ssl_token) {
                logAndCaptureMessage('Token not found after Elavon tokenization', {
                    invoiceId
                });
                setProcessingPayment(false);
                return;
            }
            setPaymentDetailsToken(ssl_token);
        }
    };
    const processElavonCharge = () => __awaiter(this, void 0, void 0, function* () {
        if (!paymentDetailsToken)
            return;
        const transactionData = {
            amountInCents: invoicePricingData.totalAmount,
            customerEmail: email,
            invoiceId,
            paymentMethod: 'card',
            paymentToken: paymentDetailsToken
        };
        const { paymentGatewayResult, columnPaymentResult } = yield api.post(`charges/create-charge`, transactionData);
        const { isSuccessful: isChargeSuccessful, responseMessage: gatewayResponseMessage } = paymentGatewayResult;
        const columnResponseMessage = columnPaymentResult === null || columnPaymentResult === void 0 ? void 0 : columnPaymentResult.errorMessage;
        // Everything worked!
        if (isChargeSuccessful && (columnPaymentResult === null || columnPaymentResult === void 0 ? void 0 : columnPaymentResult.columnPaymentSuccess)) {
            handleSuccessfulPayment();
            return;
        }
        if (!isChargeSuccessful) {
            setErr(`The transaction could not be processed: ${gatewayResponseMessage} Contact help@column.us if you need further assistance.`);
        }
        else if (!(columnPaymentResult === null || columnPaymentResult === void 0 ? void 0 : columnPaymentResult.columnPaymentSuccess)) {
            logAndCaptureException(new Error(columnResponseMessage || 'Undetermined error message'), 'Elavon invoice was likely paid in Elavon but not marked as paid in Column', { invoiceId });
            setErr('Something went wrong. Please contact help@column.us to make sure your payment is processed.');
        }
        // Cleanup at end of failure state
        void requestSessionToken();
        setProcessingPayment(false);
    });
    const tokenizePaymentDetails = () => __awaiter(this, void 0, void 0, function* () {
        const tokenizeParams = {
            ssl_txn_auth_token: sessionToken,
            ssl_card_number: creditCardNumber,
            ssl_exp_date: expirationDate,
            ssl_get_token: 'Y',
            ssl_first_name: firstName,
            ssl_last_name: lastName,
            ssl_cvv2cvc2: cvc
        };
        // Checkout.js is loaded through an appended script so the 'as any' is unfortunately necessary
        if (window.ConvergeEmbeddedPayment) {
            window.ConvergeEmbeddedPayment.pay(tokenizeParams, handleTokenizePaymentResponse);
        }
        else {
            logAndCaptureMessage('Checkout.js did not load successfully to pay Elavon invoice!', {
                invoiceId
            });
            setErr('Something went wrong. Please refresh the page and try again. Contact help@column.us if this issue persists.');
            setProcessingPayment(false);
        }
    });
    const initializeElavonPayment = () => {
        setProcessingPayment(true);
        setTriedToPay(true);
        const inputsValid = validateInput();
        if (!inputsValid) {
            setProcessingPayment(false);
            return;
        }
        setErr('');
        setPaymentDetailsToken('');
        void tokenizePaymentDetails();
    };
    const inputClasses = 'p-2 block w-full sm:text-sm rounded-md focus:outline-none';
    return (React.createElement("form", { onSubmit: e => {
            e.preventDefault();
            void initializeElavonPayment();
        } },
        React.createElement("div", { id: "paymentDetails" },
            React.createElement("div", { className: "bg-white border border-gray-400 rounded-md" },
                React.createElement("input", { "data-private": true, placeholder: "Card number *", maxLength: 16, className: inputClasses, onChange: e => setCreditCardNumber(e.target.value), value: creditCardNumber, autoComplete: "cc-number" })),
            React.createElement("div", { className: "flex mt-2" },
                React.createElement("div", { "data-private": true, className: "mr-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement(InputMask, { mask: "99/99", className: inputClasses, id: "expirationDate", value: expirationDate, name: "expirationDate", onChange: e => setExpirationDate(e.target.value), placeholder: "Expiration date *", autoComplete: "cc-exp" })),
                React.createElement("div", { className: "ml-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { "data-private": true, placeholder: "CVC *", maxLength: 4, className: inputClasses, onChange: e => setCvc(e.target.value), value: cvc, autoComplete: "cc-csc" }))),
            React.createElement("div", { className: "flex mt-2" },
                React.createElement("div", { className: "mr-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { placeholder: "First name *", className: inputClasses, onChange: e => setFirstName(e.target.value), value: firstName, autoComplete: "cc-given-name" })),
                React.createElement("div", { className: "ml-1 flex-1 bg-white border border-gray-400 rounded-md" },
                    React.createElement("input", { placeholder: "Last name *", className: inputClasses, onChange: e => setLastName(e.target.value), value: lastName, autoComplete: "cc-last-name" }))),
            React.createElement("div", { className: "bg-white border border-gray-400 rounded-md my-2" },
                React.createElement("input", { placeholder: "Email *", className: inputClasses, onChange: e => setEmail(e.target.value), value: email, autoComplete: "email" }))),
        React.createElement(PayInvoiceButton, { loading: loading, type: 'submit', disabled: !sessionToken || loading, id: "pay-invoice-elavon" }),
        err && (React.createElement(Box, { mt: 1 },
            React.createElement(Typography, { color: "error", variant: "caption" }, err)))));
}
