import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import RegisterOrganization from './RegisterOrganization';
import AuthActions from '../../../redux/auth';
const mapStateToProps = (state) => ({
    user: state.auth.user ? state.auth.user : null,
    userAuth: state.auth.userAuth
});
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(RegisterOrganization);
