import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import AuthActions from '../../redux/auth';
import Login from './Login';
const mapStateToProps = (state) => ({
    orgContext: state.auth.orgContext,
    authError: state.auth.error,
    userAuth: state.auth.userAuth
});
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
export const LoginWithoutRouter = connect(mapStateToProps, mapDispatchToProps)(Login);
