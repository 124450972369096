var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { htmlToIndesignHtml, shouldPreserveLongSequencesForNewspaper } from '../indesign/helpers';
import { dateTimeLikeToDate } from '../date';
import { EHandlebars } from './shared';
import { DateParseError, UnknownDateFormat } from '../errors/NoticePreviewErrors';
import { getNoticeTypeFromNoticeData } from '../helpers';
import pricingHelpers from '../pricing';
const generateV1Footer = (footerFormatString, notice) => {
    if (!footerFormatString)
        return '';
    const formatFooterString = (match) => {
        // {{***}} -> ***
        const footerTag = match.slice(2, -1);
        const formatType = footerTag.split(' ')[0];
        if (formatType === 'SQUASH') {
            let monthFormat;
            let dayFormat;
            let yearFormat;
            try {
                // ...(***)... -> ***
                monthFormat = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('month', footerTag);
            }
            try {
                // ...[***]... -> ***
                dayFormat = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('day', footerTag);
            }
            try {
                // ...|***|... -> ***
                yearFormat = footerTag.match(/\|.*?\|/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('year', footerTag);
            }
            const firstDate = notice.publicationDates.map(dateTimeLikeToDate)[0];
            let currentYear = firstDate.getFullYear();
            let currentMonth = firstDate.getMonth();
            let formattedString = `${moment(firstDate).format(monthFormat)}`;
            for (const timestamp of notice.publicationDates) {
                const date = dateTimeLikeToDate(timestamp);
                if (date.getFullYear() !== currentYear) {
                    formattedString += `${moment(firstDate).format(yearFormat)}, `;
                }
                if (date.getMonth() !== currentMonth) {
                    formattedString += moment(date).format(monthFormat);
                }
                formattedString += moment(date).format(dayFormat);
                currentYear = date.getFullYear();
                currentMonth = date.getMonth();
            }
            const lastDate = dateTimeLikeToDate(notice.publicationDates[notice.publicationDates.length - 1]);
            formattedString += moment(lastDate).format(yearFormat);
            return formattedString;
        }
        if (formatType === 'DATE') {
            let format;
            let separator;
            try {
                // ...(***)... -> ***
                format = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('date', footerTag);
            }
            try {
                // ...[***]... -> ***
                separator = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('separator', footerTag);
            }
            return (notice.publicationDates || [])
                .map(t => `${moment(dateTimeLikeToDate(t)).format(format)}`)
                .join(separator);
        }
        throw new UnknownDateFormat(match);
    };
    return footerFormatString.replace(/{{.*?}}/g, formatFooterString);
};
export const addFooterXML = (footer) => {
    return `<?xml version="1.0" encoding="UTF-8"?><dynamic-footer xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${footer}</dynamic-footer>`;
};
export const createCustomFooter = (dynamicFooter, customId, dbPricing, oldCustomId) => {
    let customFooter = dynamicFooter || '';
    // reset footer
    if (oldCustomId) {
        customFooter = customFooter.replace(oldCustomId, '#'.repeat(6));
    }
    // replace in custom ID
    if (customId) {
        customFooter = customFooter.replace('#'.repeat(6), customId);
    }
    // replace in for total price
    if (dbPricing === null || dbPricing === void 0 ? void 0 : dbPricing.subtotal) {
        customFooter = customFooter.replace('*'.repeat(6), `${(dbPricing.subtotal / 100).toFixed(2)}`);
    }
    return customFooter;
};
export const generateFormattedFooter = (ctx, notice, DOMparser) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    if (!notice.newspaper)
        return '';
    if (!notice.publicationDates)
        return '';
    const newspaper = yield notice.newspaper.get();
    if (!newspaper.data())
        return '';
    const footerFormatString = notice.publicationDates.length === 1 && ((_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.oneRunFooter)
        ? (_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.oneRunFooter
        : (_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.footerFormatString;
    if (!footerFormatString)
        return '';
    let footer;
    const isV1Footer = footerFormatString.slice(0, 3) === 'V1:';
    if (isV1Footer) {
        footer = generateV1Footer(footerFormatString.slice(3, footerFormatString.length), notice);
    }
    else {
        const compiled = EHandlebars.compile(footerFormatString);
        let filerName = '';
        let filerOrgName = '';
        if (notice.filer) {
            const filer = yield notice.filer.get();
            filerName = ((_d = filer.data()) === null || _d === void 0 ? void 0 : _d.name) || '';
        }
        if (notice.filedBy) {
            const filerOrg = yield notice.filedBy.get();
            filerOrgName = ((_e = filerOrg.data()) === null || _e === void 0 ? void 0 : _e.name) || '';
        }
        let price = '';
        if (notice.displayParams) {
            price = pricingHelpers
                .getUIPricingObject(yield pricingHelpers.createDBPricingObjectFromData(ctx, notice, notice.displayParams))
                .subtotal.toFixed(2);
        }
        const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
        footer = compiled({
            dates: notice.publicationDates,
            price: price || '#.##',
            noticeType: noticeType || 'Public Notice',
            filerOrgName,
            filerName
        });
    }
    const fixedFooter = createCustomFooter(footer, notice.customId, notice.pricing);
    const preserveLongSequences = yield shouldPreserveLongSequencesForNewspaper(newspaper);
    return htmlToIndesignHtml(fixedFooter, DOMparser, {
        isFirstPHeading: false,
        preserveLongSequences
    }, {});
});
