var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { safeStringify } from 'lib/utils/stringify';
import { Toast } from 'components/Toast';
import { affidavitsAreManagedByColumn } from '../affidavitSettingsUtils';
import BasicAffidavitSettingsHeader from './BasicAffidavitSettingsHeader';
import AffidavitLayoutSettings from './AffidavitLayoutSettings';
import { getColumnNotarizedAffidavitHTMLFromActiveOrganization, getNewspaperAffidavitHTMLFromActiveOrganization, updateColumnNotarizedAffidavit, parseErrorsFromAffidavitHTML, removeCustomAffidavit, updateCustomAffidavit } from './basicAffidavitSettingsUtils';
import AffidavitSignatorySettings, { getAffidavitSignatorySettingsFromActiveOrganization } from './AffidavitSignatorySettings';
/**
 * Controls core settings on affidavits like:
 * 1) Custom affidavit HTML
 * 2) Signatory settings
 * 3) Signatory frequency
 */
export default function BasicAffidavitSettings({ activeOrganization, user }) {
    const [affidavitHTML, setAffidavitHTML] = useState('');
    const [columnNotarizedAffidavitHTML, setColumnNotarizedAffidavitHTML] = useState('');
    const [successMessage, setSuccessMessage] = useState();
    const [editedHTML, setEditedHTML] = useState(false);
    const [saving, setSaving] = useState(false);
    const [affidavitSettings, setAffidavitSettings] = useState(getAffidavitSignatorySettingsFromActiveOrganization(activeOrganization));
    // Subscribe to the active organization and update the affidavit HTML
    useEffect(() => {
        const unsub = activeOrganization.ref.onSnapshot((snapshot) => __awaiter(this, void 0, void 0, function* () {
            if (!exists(snapshot))
                return;
            const affidavitHTML = yield getNewspaperAffidavitHTMLFromActiveOrganization(snapshot);
            setAffidavitHTML(affidavitHTML);
            if (affidavitsAreManagedByColumn(activeOrganization)) {
                const columnNotarizedAffidavitHTML = yield getColumnNotarizedAffidavitHTMLFromActiveOrganization(snapshot);
                setColumnNotarizedAffidavitHTML(columnNotarizedAffidavitHTML);
            }
        }));
        return () => unsub && unsub();
    }, [activeOrganization.id]);
    const paperAffidavitErrors = useMemo(() => parseErrorsFromAffidavitHTML(affidavitHTML, {
        isColumnManaged: false
    }), [affidavitHTML]);
    const columnAffidavitErrors = useMemo(() => parseErrorsFromAffidavitHTML(columnNotarizedAffidavitHTML, {
        isColumnManaged: true
    }), [columnNotarizedAffidavitHTML]);
    const errors = paperAffidavitErrors.concat(columnAffidavitErrors);
    if (!affidavitHTML)
        return React.createElement(LoadingState, null);
    const edited = editedHTML ||
        safeStringify(affidavitSettings) !==
            safeStringify(getAffidavitSignatorySettingsFromActiveOrganization(activeOrganization));
    return (React.createElement(React.Fragment, null,
        successMessage && (React.createElement(Toast, { headerText: successMessage.header, bodyText: "Your affidavit settings have been saved successfully and will be applied to future notices.", close: () => setSuccessMessage(undefined), status: "success" })),
        React.createElement("div", { className: "bg-gray-50 flex flex-col", style: { maxHeight: 790 } },
            React.createElement(BasicAffidavitSettingsHeader, { updateCustomAffidavit: () => __awaiter(this, void 0, void 0, function* () {
                    setSaving(true);
                    yield updateCustomAffidavit(activeOrganization, affidavitHTML);
                    if (affidavitsAreManagedByColumn(activeOrganization)) {
                        yield updateColumnNotarizedAffidavit(activeOrganization, columnNotarizedAffidavitHTML);
                    }
                    yield activeOrganization.ref.update(affidavitSettings);
                    setEditedHTML(false);
                    setSaving(false);
                    setSuccessMessage({
                        header: 'Affidavit Settings Saved',
                        body: 'Your affidavit settings have been saved and will be applied to all future notices.'
                    });
                    setTimeout(() => setSuccessMessage(undefined), 5000);
                }), removeCustomAffidavit: () => removeCustomAffidavit(activeOrganization), activeOrganization: activeOrganization, saving: saving, errors: errors, edited: edited }),
            React.createElement("div", { className: "flex-1 overflow-scroll" },
                React.createElement(AffidavitSignatorySettings, { affidavitsManagedByColumn: affidavitsAreManagedByColumn(activeOrganization), setAffidavitSettings: setAffidavitSettings, activeOrganization: activeOrganization, affidavitSettings: affidavitSettings }),
                React.createElement(AffidavitLayoutSettings, { activeOrganization: activeOrganization, setAffidavitHTML: newHTML => {
                        setAffidavitHTML(newHTML);
                        setEditedHTML(true);
                    }, affidavitHTML: affidavitHTML, errors: paperAffidavitErrors, user: user }),
                affidavitsAreManagedByColumn(activeOrganization) && (React.createElement(AffidavitLayoutSettings, { isColumnNotarizedAffidavit: true, activeOrganization: activeOrganization, setAffidavitHTML: newHTML => {
                        setColumnNotarizedAffidavitHTML(newHTML);
                        setEditedHTML(true);
                    }, affidavitHTML: columnNotarizedAffidavitHTML, errors: columnAffidavitErrors, user: user }))))));
}
