var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormHelperText, FormControl, Grid } from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { exists } from 'lib/types';
import { Collections } from 'lib/constants';
import { ESelect, EInput } from 'components/ColorColumnFormComponents';
import Firebase from 'EnoticeFirebase';
const styles = (theme) => createStyles({
    tabRow: {
        marginBottom: theme.spacing(1)
    },
    loadingContainer: {
        height: '60vh'
    },
    loadingGrid: {
        height: '100%'
    }
});
function ForceInteger(props) {
    const { inputRef, onChange } = props, other = __rest(props, ["inputRef", "onChange"]);
    return (React.createElement(NumberFormat, Object.assign({}, other, { getInputRef: inputRef, onValueChange: values => {
            onChange({
                target: {
                    value: values.value
                }
            });
        }, placeholder: "RATE CODE", format: "######" })));
}
const RateSelect = ({ setRateCode, rateCode, rateCodeError, setRateCodeError, rate, disabled, placementActions, isDefaultRate, rateDescriptionOverride, isPublisher }) => {
    var _a, _b;
    const [showCheckmark, setShowCheckMark] = useState(false);
    const [rates, setRates] = useState([]);
    /*
      We only show the white checkmark after the user has selected a rate
      that is valid, and different than the default rate.
    */
    useEffect(() => {
        if (rate && !isDefaultRate && rateCode) {
            setShowCheckMark(true);
        }
    }, [rate]);
    useEffect(() => {
        var _a;
        setRateCode((rate === null || rate === void 0 ? void 0 : rate.data()) ? (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.code : rateCode);
    }, [rate]);
    useEffect(() => {
        if (rateCodeError.length || (rateCode && rateCode.length !== 6)) {
            setShowCheckMark(false);
        }
    }, [rateCodeError, rateCode]);
    useEffect(() => {
        var _a, _b;
        if (!((_b = (_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.id))
            return;
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            var _c;
            const ratesQuery = yield Firebase.firestore()
                .collection(Collections.rates)
                .where('organization', '==', (_c = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _c === void 0 ? void 0 : _c.organization)
                .get();
            const allRates = ratesQuery.docs;
            const activeRates = allRates.filter(r => !r.data().archived);
            setRates(activeRates);
        }))();
    }, [(_b = (_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.id]);
    const handleClearRate = () => {
        placementActions.setDefaultRateOverride(false);
        setRateCode('');
        setShowCheckMark(false);
    };
    const renderNumberInput = () => {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(FormControl, { style: { width: '100%' } },
                    React.createElement(EInput, { style: {
                            position: 'relative',
                            'pointer-events': disabled ? 'none' : ''
                        }, disabled: disabled, label: rate
                            ? `Current Rate: ${rateDescriptionOverride || ((_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description)}`
                            : 'Current Rate: n/a', onChange: (e) => {
                            setRateCodeError('');
                            setRateCode(e.target.value);
                        }, value: rateCode, InputProps: {
                            inputComponent: ForceInteger,
                            disableUnderline: true
                        } }),
                    showCheckmark && (React.createElement("div", null,
                        React.createElement(Cancel, { style: {
                                color: 'white',
                                opacity: 0.5,
                                top: '50%',
                                position: 'absolute',
                                right: '10px'
                            }, onClick: handleClearRate }),
                        React.createElement(CheckCircle, { style: {
                                color: 'white',
                                top: '50%',
                                position: 'absolute',
                                right: '-35px'
                            } })))),
                rateCodeError !== '' && (React.createElement(FormHelperText, { error: rateCodeError !== '' }, rateCodeError)))));
    };
    const renderDropdownInput = () => (React.createElement(ESelect, { disabled: disabled, values: rates.map(rate => ({
            label: rate.data().description,
            val: rate.id
        })), label: "Select Rate", value: exists(rate) ? rate.id : '', onChange: (e) => {
            const chosenRate = rates.find(rate => rate.id === e.target.value);
            if (chosenRate)
                setRateCode(`${chosenRate.data().code}`);
        } }));
    return (React.createElement(Grid, { container: true, direction: "row", style: { flexWrap: 'nowrap', position: 'relative' } },
        !isPublisher && renderNumberInput(),
        isPublisher && renderDropdownInput()));
};
export default withStyles(styles, { withTheme: true })(RateSelect);
