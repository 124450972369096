import React from 'react';
import { ColumnButton } from 'components/ColumnButton';
import { DeleteIcon } from 'icons';
import { affidavitsAreManagedByColumn } from '../affidavitSettingsUtils';
/**
 * Header component with action buttons to update affidavit HTML.
 * Don't allow removal of custom affidavits if Column is managing affidavits.
 */
export default function BasicAffidavitSettingsHeader({ removeCustomAffidavit, updateCustomAffidavit, activeOrganization, errors, saving, edited }) {
    return (React.createElement("div", { className: "bg-white flex items-center pl-7 pr-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "w-full font-medium" },
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" },
                "Configure the signatory information and layout of affidavits for",
                ' ',
                activeOrganization.data().name,
                ".")),
        React.createElement("div", { className: "flex space-x-4 ml-12" },
            !affidavitsAreManagedByColumn(activeOrganization) && (React.createElement(ColumnButton, { secondary: true, buttonText: "Reset", size: "lg", startIcon: React.createElement(DeleteIcon, null), onClick: removeCustomAffidavit })),
            React.createElement(ColumnButton, { id: "update-affidavit-settings", buttonText: "Save", onClick: updateCustomAffidavit, disabled: errors.length > 0 || !edited || saving, primary: true, size: "lg" }))));
}
