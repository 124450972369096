import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import React, { useState } from 'react';
import { ColumnButton } from 'components/ColumnButton';
import { uploadMethodToUploadMethodDescription } from './automatedAffidavitSettingsUtils';
/**
 * Shows metadata about one of the PDFs uploaded for that publishing day. Can
 * be expanded to show the actual PDF preview
 */
const PDFUploadPreview = ({ totalUploads, pdfUpload, startOpen }) => {
    // whether or not to show the PDF preview in this row
    const [open, setOpen] = useState(startOpen);
    // when there is just 1 pdf we can take up more space
    const PDF_UPLOAD_ROW_HEIGHT = totalUploads === 1 ? '80vh' : '70vh';
    return (React.createElement("div", { className: "py-4 flex flex-col", style: {
            height: open ? PDF_UPLOAD_ROW_HEIGHT : 'auto'
        } },
        React.createElement("div", { className: "pb-4 flex" },
            React.createElement("div", { className: "flex-1" },
                React.createElement("div", { className: "pb-1" }, pdfUpload.pdfName),
                React.createElement("div", { className: "flex items-center text-xs text-grey-400" },
                    React.createElement("a", { className: "text-blue-600 underline", href: pdfUpload.pdfUrl, target: "_blank", rel: "noopener noreferrer" }, "Download PDF"),
                    React.createElement("span", { className: "mx-2" }, "|"),
                    React.createElement("span", null,
                        "Uploaded ",
                        pdfUpload.uploadDate.toLocaleDateString(),
                        ' ',
                        uploadMethodToUploadMethodDescription(pdfUpload.uploadMethod)))),
            totalUploads > 1 && (React.createElement("div", null,
                !open && (React.createElement(KeyboardArrowRight, { onClick: () => setOpen(true), className: "cursor-pointer text-gray-500 hover:text-gray-600" })),
                open && (React.createElement(KeyboardArrowDown, { onClick: () => setOpen(false), className: "cursor-pointer text-gray-500 hover:text-gray-600" }))))),
        open && (React.createElement("div", { className: "flex-1" },
            React.createElement("iframe", { title: "E-Edition PDF Preview", src: `${pdfUpload.pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`, frameBorder: "0", height: "100%", width: "100%" })))));
};
const EmptyEditionPreview = ({ setShowEEditionUploadModal, uploadDay }) => {
    return (React.createElement("div", { className: "h-full flex flex-col items-center mt-24" },
        React.createElement("div", null,
            React.createElement("img", { className: "h-64", src: "https://enotice-production.imgix.net/custom-documents/permalink/0ec9.8b27b-buddy-11.gif" })),
        React.createElement("div", { className: "text-xl text-blue-800" }, "No e-edition"),
        React.createElement("div", { className: "text-sm leading-6 text-grey-400 text-center" },
            "Click on the button below to upload the e-edition for",
            React.createElement("br", null),
            uploadDay.publishingDateString),
        React.createElement("div", { className: "mt-4" },
            React.createElement(ColumnButton, { buttonText: "Upload e-edition", onClick: () => setShowEEditionUploadModal({
                    uploadDay,
                    show: true
                }), primary: true }))));
};
/**
 * Displays a list of PDF previews of the files that were uploaded
 */
export default function AutomatedAffidavitSettingsPDFPreview({ setShowEEditionUploadModal, uploadDay }) {
    if (!uploadDay.pdfsUploaded.length)
        return (React.createElement(EmptyEditionPreview, { setShowEEditionUploadModal: setShowEEditionUploadModal, uploadDay: uploadDay }));
    return (React.createElement("div", { id: "automated-affidavit-settings-pdf-preview" }, uploadDay.pdfsUploaded
        .sort((a, b) => a.pdfName.localeCompare(b.pdfName))
        .map((pdfUpload, idx) => (React.createElement(PDFUploadPreview, { totalUploads: uploadDay.pdfsUploaded.length, startOpen: idx === 0, pdfUpload: pdfUpload, key: pdfUpload.pdfName })))));
}
