import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, Grid, FormHelperText } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { TimezoneType, State } from 'lib/enums';
import { BROWSER, BROWSERS } from '../../../constants';
import AdvertisingDeadlinesForm from '../../settings/publisher/deadlines/AdvertisingDeadlinesForm';
import FormError from '../../errors/FormError';
import SelectDropdown from '../../placeScroll/SelectDropdown';
const BasicDetails = ({ name, setName, circulationSize, setCirculationSize, county, setCounty, address, setAddress, city, setCity, state, setState, zipCode, setZipCode, phone, setPhone, timezone, setTimezone, stateError, renderFooter }) => {
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    useEffect(() => {
        if (phone) {
            setIsPhoneValid(!phone.includes('_'));
        }
        else
            setIsPhoneValid(true);
    }, [phone]);
    const handleZipCodeInput = (newValue) => {
        setZipCode(newValue);
    };
    const handleCirculationSizeInput = (newValue) => {
        setCirculationSize(newValue);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "px-4 py-5 sm:p-6" },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("div", { className: "flex-auto w-12/12" },
                    React.createElement(FormControl, { required: true, fullWidth: true },
                        React.createElement("input", { className: "appearance-none rounded relative block w-12/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "name", required: true, autoComplete: "none", value: name, onChange: e => setName(e.target.value), placeholder: "Name of newspaper *" }))),
                React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                    React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                        React.createElement("input", { min: "0", className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "circulationSize", "data-testid": "circulation-size", type: "number", required: true, autoComplete: "none", value: circulationSize || '', onChange: e => handleCirculationSizeInput(e.target.value), placeholder: "Circulation size *" })),
                    React.createElement("div", { className: "w-3 flex-grow-0" }),
                    React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                        React.createElement("input", { className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "county", required: true, autoComplete: "none", value: county, onChange: e => setCounty(e.target.value), placeholder: "County *" }))),
                React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                    React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                        React.createElement("input", { className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "address", required: true, autoComplete: "none", value: address, onChange: e => setAddress(e.target.value), placeholder: "Address *" })),
                    React.createElement("div", { className: "w-3 flex-grow-0" }),
                    React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                        React.createElement("input", { className: "appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5", id: "city", required: true, autoComplete: "none", value: city, onChange: e => setCity(e.target.value), placeholder: "City *" }))),
                React.createElement("div", { className: "flex w-12/12 mt-3" },
                    React.createElement("div", { className: "flex-grow flex-1" },
                        React.createElement(SelectDropdown, { id: "state", onChange: (selected) => {
                                if (selected) {
                                    setState(selected.id);
                                }
                            }, placeholder: "State *", options: State.items().map((state) => ({
                                id: state.value,
                                label: state.label
                            })), selected: State.items().filter(item => {
                                return item.value === parseInt(state, 10)
                                    ? { id: item.value, label: item.label }
                                    : '';
                            }), value: State.items().filter(item => {
                                return item.value === parseInt(state, 10)
                                    ? { id: item.value, label: item.label }
                                    : '';
                            }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true, maxHeight: 150 })),
                    React.createElement("div", { className: "w-3 flex-grow-0" }),
                    React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                        React.createElement("input", { className: `relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5`, id: "zipCode", required: true, value: zipCode, name: "zipCode", autoComplete: "", onChange: e => handleZipCodeInput(e.target.value), placeholder: "Zip *" }))),
                React.createElement("div", { className: "flex w-12/12 flex-row mt-3" },
                    React.createElement("div", { className: "flex-grow flex-1" },
                        React.createElement(SelectDropdown, { id: "timezone-select-button", onChange: (selected) => {
                                if (selected) {
                                    setTimezone(selected.id);
                                }
                            }, placeholder: "Timezone *", options: TimezoneType.items().map(tz => ({
                                id: tz.logical,
                                label: tz.label
                            })), selected: TimezoneType.items().filter(item => {
                                return item.logical === timezone
                                    ? { id: item.logical, label: item.label }
                                    : '';
                            }), value: TimezoneType.items().filter(item => {
                                return item.logical === timezone
                                    ? { id: item.logical, label: item.label }
                                    : '';
                            }), borderRadius: 4, borderColor: '#e2e8f0', placeholderText: '#a0aec0', required: true, noShadow: true, maxHeight: 120 })),
                    React.createElement("div", { className: "w-3 flex-grow-0" }),
                    React.createElement(FormControl, { required: true, className: "flex-grow flex-1" },
                        React.createElement(InputMask, { mask: "(999) 999-9999", className: `appearance-none rounded relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 
            ${isPhoneValid
                                ? 'focus:shadow-outline-blue focus:border-blue-300'
                                : 'border-red-400'}
            `, id: "phone", required: true, autoComplete: "none", value: phone, name: "phone", onChange: e => setPhone(e.target.value), placeholder: "Phone *" }))),
                React.createElement(Grid, { container: true, justify: "center" }, stateError && React.createElement(FormError, { error: stateError })))),
        renderFooter(null, null)));
};
const advertisingStyles = (theme) => createStyles({
    container: {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: BROWSER === BROWSERS.ie ? 'block' : 'flex'
    }
});
const AdvertisingDeadlines = ({ deadlines, setDeadlines, stateError, advertiserEditingEnabled, setAdvertiserEditingEnabled }) => (React.createElement(React.Fragment, null,
    React.createElement(AdvertisingDeadlinesForm, { deadlines: deadlines, setDeadlines: setDeadlines, advertiserEditingEnabled: advertiserEditingEnabled, setAdvertiserEditingEnabled: setAdvertiserEditingEnabled }),
    stateError !== '' && (React.createElement(FormHelperText, { error: stateError !== '' }, stateError))));
export const BasicDetailsStep = BasicDetails;
export const AdvertisingDeadlinesStep = withStyles(advertisingStyles)(AdvertisingDeadlines);
