import { phoneFormattersByRegion, formatPhone } from './phoneFormat';
export const VALIDATION_CHECK_EVENT = 'validation-check';
/**
 * Dispatches a custom validation event to child fields of the given form
 * Child fields can listen to the validation event to determine when to show a validation status message
 * @param toForm
 */
export function dispatchValidationEvent({ toForm }) {
    const event = new Event(VALIDATION_CHECK_EVENT);
    const formElements = Array.from(toForm.elements);
    formElements.forEach(element => {
        element.dispatchEvent(event);
    });
}
export function getInputTypeValidationConfig(type, region = 'us') {
    if (type === 'currency') {
        return {
            props: {
                min: '0',
                step: '0.01'
            }
        };
    }
    if (type === 'tel') {
        const phoneFormat = phoneFormattersByRegion[region];
        return {
            props: {
                pattern: phoneFormattersByRegion[region].validRegex
            },
            format: (value) => formatPhone(value, phoneFormat)
        };
    }
    return {};
}
const validityStatuses = [
    'badInput',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'typeMismatch',
    'valueMissing'
];
export function getCustomValidationMessage(validity, validationMessages) {
    const currentStatus = validityStatuses.find(status => validity[status]);
    return currentStatus ? validationMessages[currentStatus] : null;
}
