import React from 'react';
import { Box, Typography, createStyles, withStyles } from '@material-ui/core';
import { cdnIfy } from 'lib/helpers';
const styles = () => createStyles({
    checkBox: {
        background: '#FFFFFF',
        border: '1px solid #DADADA',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 3px rgba(230, 235, 241, 0.25)',
        borderRadius: '4px',
        width: '100%'
    },
    checkText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: '#3C4257' // gives the check box dark gray text
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
});
function PayWithCheckInfo({ invoiceNumber, invoicePDFPath, classes }) {
    const url = invoicePDFPath ? cdnIfy(invoicePDFPath, { useImgix: true }) : '';
    return (React.createElement(Box, { className: `${classes.checkBox} ${classes.flex}` },
        React.createElement(Box, { m: 2 },
            React.createElement(Typography, { className: classes.checkText },
                "If you would like to pay by check, please write a check made out to \"Column Software PBC\" with ",
                React.createElement("b", null, invoiceNumber),
                " in the memo line , include a printed copy of the\u00A0",
                React.createElement("a", { href: `${url}`, target: "_blank", style: {
                        color: '#5469D4' /* the url link to Invoice PDF is given a violet color */
                    }, rel: "noreferrer" }, "Invoice PDF"),
                ", and mail to:",
                React.createElement("br", null),
                " ",
                React.createElement("br", null),
                "Column Software PBC ",
                React.createElement("br", null),
                " PO Box 208098 ",
                React.createElement("br", null),
                " Dallas, TX 75320-8098",
                ' ',
                React.createElement("br", null),
                " ",
                React.createElement("br", null)))));
}
export default withStyles(styles)(PayWithCheckInfo);
