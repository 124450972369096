import { createReducer, createActions } from 'reduxsauce';
import { OccupationType } from '../lib/enums';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    login: ['email', 'password', 'redirect'],
    anonymousLogin: [],
    logout: [],
    setUser: ['user'],
    startAuth: [],
    endAuth: [],
    resetPassword: ['email'],
    setUserAuth: ['userAuth'],
    loginToken: ['token'],
    register: [],
    setOrganization: ['organization'],
    setActiveOrganization: ['activeOrganization'],
    setAvailableOrganizations: ['availableOrganizations'],
    showAllOrgsNotices: ['showAllOrgsNotices'],
    setOrgContext: ['orgContext'],
    logoutSuccess: [],
    setAuthError: ['error'],
    setShowPasswordReset: ['showPasswordReset']
});
export const AuthTypes = Types;
export default Creators;
export const INITIAL_STATE = {
    previousUser: null,
    user: null,
    userAuth: null,
    isPublisher: false,
    organization: null,
    activeOrganization: null,
    availableOrganizations: [],
    error: '',
    orgContext: null,
    showPasswordReset: false,
    loading: true
};
export const authSelector = (state) => state.auth;
/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_USER]: (state, { user }) => (Object.assign(Object.assign({}, state), { isPublisher: user.data().occupation === OccupationType.publishing.value, alwaysAllowAffidavitDownload: user.data().alwaysAllowAffidavitDownload || false, previousUser: state.user, user })),
    [Types.SET_USER_AUTH]: (state, { userAuth }) => (Object.assign(Object.assign({}, state), { userAuth })),
    [Types.START_AUTH]: state => (Object.assign(Object.assign({}, state), { loading: true })),
    [Types.END_AUTH]: state => (Object.assign(Object.assign({}, state), { loading: false })),
    [Types.SET_ORGANIZATION]: (state, { organization }) => (Object.assign(Object.assign({}, state), { organization })),
    [Types.SET_AVAILABLE_ORGANIZATIONS]: (state, { availableOrganizations }) => (Object.assign(Object.assign({}, state), { availableOrganizations })),
    [Types.SET_ACTIVE_ORGANIZATION]: (state, { activeOrganization }) => (Object.assign(Object.assign({}, state), { activeOrganization })),
    [Types.SHOW_ALL_ORGS_NOTICES]: (state, { showAllOrgsNotices }) => (Object.assign(Object.assign({}, state), { showAllOrgsNotices })),
    [Types.SET_AUTH_ERROR]: (state, { error }) => (Object.assign(Object.assign({}, state), { error })),
    [Types.LOGOUT_SUCCESS]: () => INITIAL_STATE,
    [Types.SET_ORG_CONTEXT]: (state, { orgContext }) => (Object.assign(Object.assign({}, state), { orgContext })),
    [Types.SET_SHOW_PASSWORD_RESET]: (state, { showPasswordReset }) => (Object.assign(Object.assign({}, state), { showPasswordReset }))
});
