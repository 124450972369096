import React from 'react';
import { ColumnButton } from 'components/ColumnButton';
import { UploadIcon } from 'icons';
/**
 * Header component with action buttons to update header and footer info.
 */
export default function BasicLayoutSettingsHeader({ updateBasicLayoutSettings, activeOrganization, updateEnabled }) {
    return (React.createElement("div", { className: "bg-white flex items-center pl-7 pr-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "w-full font-medium" },
            React.createElement("p", { className: "text-xl text-blue-800" }, "Basic Layout Settings"),
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" },
                "Configure the headers and footers that appear for",
                ' ',
                activeOrganization.data().name,
                ".")),
        React.createElement("div", { className: "flex space-x-4" },
            React.createElement(ColumnButton, { id: "template-settings-save", buttonText: "Update", startIcon: React.createElement(UploadIcon, null), disabled: !updateEnabled, onClick: updateBasicLayoutSettings, size: "lg", primary: true }))));
}
