var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { NoticeStatusType, ELabels, InvoiceStatus } from 'lib/enums';
import { exists } from 'lib/types';
import { Help } from '@material-ui/icons';
import { BillingStatusIcon, ProfileIcon, CalendarIcon, InvoiceIcon, ReceiptCheck, Identification } from 'icons/index';
import { getAccountNumberForNotice, getCustomer, getCustomerName, getCustomerOrganization, getCustomerOrganizationName } from 'lib/notice/customer';
import { getOpenInvitesAssociatedWithEmail, isPastDueInNewspaperTimezone } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { TextField } from '@material-ui/core';
import api from 'api';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import Tooltip from '../../components/Tooltip';
const BasicInfoRow = ({ Icon, heading, caption, id, showEdit, showPill, pillText, pillClasses, headingLink, filerName, notice, invoice }) => {
    const [editMode, setEditMode] = useState();
    const [orderNumber, setOrderNumber] = useState((heading === null || heading === void 0 ? void 0 : heading.toString()) || '');
    const [currentOrderNumber, setCurrentOrderNumber] = useState();
    const updateNotice = (notice, invoice) => __awaiter(void 0, void 0, void 0, function* () {
        yield notice.ref.update({
            customId: orderNumber
        });
        if (exists(invoice)) {
            yield api.post(`documents/${notice.id}/regenerate`, {
                docType: 'INVOICE'
            });
            // we need to update the order number in the invoice memo too
            let customMemo = invoice.data().customMemo || '';
            customMemo = customMemo.replace(`Order Number: ${currentOrderNumber}`, `Order Number: ${orderNumber}`);
            yield invoice.ref.update({
                customMemo
            });
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("article", { className: "grid grid-cols-12 mb-2", id: id },
            React.createElement("section", { className: "col-span-2 p-3 text-center" },
                React.createElement(Icon, { className: "m-auto w-auto" })),
            React.createElement("section", { className: "col-span-10 pr-5" },
                React.createElement("div", { className: "flex inline-block" },
                    React.createElement("h5", { className: "mb-1 text-gray-500" }, caption),
                    showEdit && (React.createElement("div", { className: `inline-block items-center px-2 text-base font-medium text-primary-500 cursor-pointer`, onClick: () => {
                            if (editMode &&
                                currentOrderNumber !== orderNumber &&
                                exists(notice)) {
                                void updateNotice(notice, invoice);
                            }
                            else {
                                setCurrentOrderNumber(orderNumber);
                            }
                            setEditMode(!editMode);
                        }, "data-testid": "edit-button" }, editMode ? 'Save' : 'Edit'))),
                React.createElement("div", null,
                    (!editMode || (editMode && id !== 'notice-custom-id')) && (React.createElement("div", { className: `font-medium text-gray-600 inline-block ${headingLink
                            ? `${filerName ? 'underline' : 'hover:underline'} cursor-pointer hover:blue-800`
                            : ''}`, id: `${id}-sub-heading`, onClick: () => headingLink && window.open(headingLink) }, heading)),
                    editMode && caption === ELabels.publisher_id.label && (React.createElement(TextField, { id: "order-number", className: "text-base text-gray-800", value: orderNumber, onChange: e => setOrderNumber(e.target.value) })),
                    showPill && (React.createElement("span", { className: `ml-3 inline-block items-center px-2 py-0.5 rounded text-xs font-medium text-white uppercase ${pillClasses}` }, pillText)))))));
};
const BasicInfo = ({ notice, invoice, invoiceOverdue, setInvoiceOverdue, newspaper, billingStatus, isPublisher, filer, isInvoicedOutsideColumn }) => {
    var _a, _b, _c;
    const [filerName, setFilerName] = useState('');
    const [invoiceDueDate, setInvoiceDueDate] = useState('');
    const [customerId, setCustomerId] = useState();
    const [accountNumber, setAccountNumber] = useState('');
    const enableOrderNumberEditing = useBooleanFlag(LaunchDarklyFlags.ENABLE_ORDER_NUMBER_EDITING) &&
        isPublisher;
    useEffect(() => {
        const fetchFilerName = () => __awaiter(void 0, void 0, void 0, function* () {
            const { email } = filer.data();
            const { filedBy } = notice.data();
            const ctx = getFirebaseContext();
            const customer = yield getCustomer(ctx, filer, newspaper);
            const userName = getCustomerName(customer, filer, true);
            const filedBySnap = yield (filedBy === null || filedBy === void 0 ? void 0 : filedBy.get());
            const customerOrganization = yield getCustomerOrganization(ctx, filedBySnap, newspaper);
            const customerOrganizationName = getCustomerOrganizationName(customerOrganization, customer);
            setCustomerId(customer === null || customer === void 0 ? void 0 : customer.id);
            if (filedBy) {
                /**
                 * Show the user's name as 'User Pending Registration' if
                 * they have been invited to the advertising org and do not have
                 * a name saved
                 */
                const pendingInvite = (yield getOpenInvitesAssociatedWithEmail(ctx, email, filedBy.id))[0];
                if (exists(pendingInvite) && !userName) {
                    setFilerName(`User Pending Registration${pendingInvite.data().organizationName
                        ? `- ${pendingInvite.data().organizationName}`
                        : ''}`);
                    return;
                }
            }
            if (customerOrganizationName) {
                setFilerName(`${userName} - ${customerOrganizationName}`);
                return;
            }
            return setFilerName(userName);
        });
        if (filer && exists(filer)) {
            void fetchFilerName();
        }
    }, [filer]);
    useEffect(() => {
        const getInvoiceDueDate = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!(invoice === null || invoice === void 0 ? void 0 : invoice.data())) {
                setInvoiceDueDate('Awaiting Invoice Creation');
            }
            else if (invoice.data().isWithinBulkInvoice)
                setInvoiceDueDate('Monthly Invoice');
            else if (invoice.data().invoiceOutsideColumn) {
                setInvoiceDueDate('Invoiced Outside Column');
            }
            else {
                const dueDate = moment(invoice.data().due_date * 1000);
                setInvoiceOverdue(isPastDueInNewspaperTimezone(dueDate, newspaper.data().iana_timezone, moment()) &&
                    // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
                    ![
                        InvoiceStatus.paid.value,
                        InvoiceStatus.partially_refunded.value
                    ].includes(invoice.data().status) &&
                    !invoice.data().void);
                setInvoiceDueDate(dueDate.format('MMM D'));
            }
        });
        if (notice && exists(notice)) {
            void getInvoiceDueDate();
        }
    }, [invoice]);
    useEffect(() => {
        const fetchAndSetAccountNumber = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(notice))
                return;
            const fetchedAccountNumber = yield getAccountNumberForNotice(getFirebaseContext(), notice);
            if (fetchedAccountNumber) {
                setAccountNumber(fetchedAccountNumber.id);
            }
        });
        void fetchAndSetAccountNumber();
    }, [notice.id]);
    const pattern = new RegExp('(\\b, \\b)(?!.*\\b\\1\\b)', 'i');
    const dates = notice
        .data()
        .publicationDates.map((fbDate) => {
        const m = moment(fbDate.toDate()).tz(newspaper.data().iana_timezone || 'America/Chicago');
        return m.format('MMMM D');
    })
        .join(', ')
        .replace(pattern, ', and ');
    return (React.createElement("div", { className: "shadow md:shadow-none grid gap-2 border rounded bg-white py-6" },
        isPublisher && (React.createElement(BasicInfoRow, { heading: filerName, id: "notice-filer", caption: "Filer Name", Icon: ProfileIcon, headingLink: `/settings/organization/?tab=customers&customerId=${customerId}`, filerName: true })),
        !isPublisher && (React.createElement(BasicInfoRow, { id: "notice-publisher", heading: newspaper.data().name, caption: "Newspaper", Icon: ProfileIcon, headingLink: newspaper.data().hyperlinkPaperName
                ? newspaper.data().website
                : undefined })),
        React.createElement(BasicInfoRow, { heading: dates, id: "notice-dates", caption: `Publication Date${dates.includes(',') ? 's' : ''}`, Icon: CalendarIcon }),
        React.createElement("span", null,
            React.createElement(BasicInfoRow, { heading: React.createElement("section", { className: "flex" },
                    React.createElement("span", { className: "w-max mr-2" },
                        notice.data().noticeStatus ===
                            NoticeStatusType.cancelled.value &&
                            !((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.refund_id)
                            ? NoticeStatusType.cancelled.label
                            : billingStatus,
                        isInvoicedOutsideColumn &&
                            (!invoice
                                ? ' '
                                : ((_b = invoice.data()) === null || _b === void 0 ? void 0 : _b.amount_paid) &&
                                    ` ($${(((_c = invoice.data()) === null || _c === void 0 ? void 0 : _c.amount_paid) / 100).toFixed(2)})`)),
                    !isPublisher && isInvoicedOutsideColumn ? (React.createElement("span", null,
                        React.createElement(Tooltip, { classes: "inline-block flex align-middle font-normal mt-1 text-gray-600 w-64 z-50 absolute", helpText: 'This publisher handles invoices outside of Column.', position: "bottom" },
                            React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" })))) : (!isPublisher &&
                        billingStatus === 'Awaiting Invoice Creation' && (React.createElement("span", null,
                        React.createElement(Tooltip, { classes: "inline-block flex align-middle font-normal mt-1 text-gray-600 ml-2 w-64 z-50 absolute", helpText: 'You will be notified once the publisher creates an invoice. No action is required in the meantime.', position: "bottom" },
                            React.createElement(Help, { fontSize: "small", className: "p-0.5 pt-0" })))))), id: "notice-payment-status", caption: "Status", Icon: BillingStatusIcon })),
        React.createElement(BasicInfoRow, { id: "invoice-due", heading: invoiceDueDate, caption: `Invoice Due${isPublisher ? ' by Advertiser' : ''}`, Icon: InvoiceIcon, showPill: invoiceOverdue &&
                (invoice === null || invoice === void 0 ? void 0 : invoice.data().status) !== InvoiceStatus.initiated.value, pillClasses: 'bg-red-400', pillText: 'Past Due' }),
        notice.data().customId && (React.createElement(BasicInfoRow, { id: "notice-custom-id", heading: notice.data().customId, caption: ELabels.publisher_id.label, Icon: ReceiptCheck, showEdit: enableOrderNumberEditing, notice: notice, invoice: invoice })),
        accountNumber && (React.createElement(BasicInfoRow, { id: ELabels.publisher_customer_id.key, heading: accountNumber, caption: ELabels.publisher_customer_id.label, Icon: Identification }))));
};
export default BasicInfo;
