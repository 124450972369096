import React, { useEffect, useState } from 'react';
import { DeleteIcon, PencilIcon } from 'icons';
import { ColumnButton } from 'components/ColumnButton';
import DeleteTemplateModal from './DeleteTemplateModal';
import TemplateStatusChip from './TemplateStatusChip';
/**
 * Wrapper around icons in tables
 */
function TableIcon({ disabled = false, onClick, icon }) {
    return (React.createElement("button", { disabled: disabled, className: "p-3 hover:bg-gray-200 rounded-md", onClick: e => {
            e.stopPropagation();
            onClick();
        } }, icon));
}
/**
 * Table component listing all templates for a publisher. This component
 * will reset pagination when filters change.
 */
export default function TemplatesSettingsTable({ activeOrganization, setDrawerTemplate, setEditedTemplate, templatesFilter, templates }) {
    const [templateToDelete, setTemplateToDelete] = React.useState();
    const [pageNumber, setPageNumber] = useState(0);
    // Reset page number when filter changes
    useEffect(() => {
        setPageNumber(0);
    }, [templatesFilter]);
    const PAGE_SIZE = 5;
    const templatesSlice = templates.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    const totalPages = Math.ceil(templates.length / PAGE_SIZE);
    return (React.createElement(React.Fragment, null,
        templateToDelete && (React.createElement(DeleteTemplateModal, { closeModal: () => setTemplateToDelete(undefined), template: templateToDelete })),
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200" },
            React.createElement("thead", { className: "h-16 font-medium text-left bg-gray-50 uppercase text-gray-650 text-sm tracking-wider" },
                React.createElement("tr", null,
                    React.createElement("th", { className: "pl-7" }, "Template Name"),
                    React.createElement("th", { className: "text-right pr-12" }, "Actions"))),
            React.createElement("tbody", null, templatesSlice.map((template, index) => {
                var _a, _b, _c;
                return (React.createElement("tr", { key: index, className: "hover:bg-gray-50 cursor-pointer h-20 text-sm leading-6 font-medium text-gray-650", onClick: () => {
                        setDrawerTemplate(template);
                    } },
                    React.createElement("td", { className: "pl-7" },
                        React.createElement("span", { className: "mr-2" }, template.data().name),
                        template.id === ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id) && (React.createElement(TemplateStatusChip, null))),
                    React.createElement("td", { className: "whitespace-no-wrap text-sm leading-5 text-right pr-12", id: "edit-customer-table-button" },
                        React.createElement(TableIcon, { onClick: () => {
                                setEditedTemplate(template);
                            }, icon: React.createElement(PencilIcon, { className: "w-5 h-5", color: "#667A8C" }) }),
                        React.createElement(TableIcon, { disabled: template.id === ((_b = activeOrganization.data().adTemplate) === null || _b === void 0 ? void 0 : _b.id), onClick: () => setTemplateToDelete(template), icon: React.createElement(DeleteIcon, { color: template.id === ((_c = activeOrganization.data().adTemplate) === null || _c === void 0 ? void 0 : _c.id)
                                    ? '#C8D0D6'
                                    : '#667A8C' }) }))));
            }))),
        React.createElement("footer", { className: "bg-white pr-5 py-0.5 border-t border-b border-gray-300 rounded-b-md" },
            React.createElement("div", { className: "pl-6 py-3.5 flex justify-between" },
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Previous", disabled: pageNumber === 0, onClick: () => setPageNumber(pageNumber - 1) })),
                React.createElement("div", { className: "py-2.5 leading-6 text-sm font-medium text-grey-400" },
                    "Page ",
                    pageNumber + 1,
                    " of ",
                    totalPages),
                React.createElement("div", { className: "flex" },
                    React.createElement(ColumnButton, { buttonText: "Next", disabled: pageNumber === totalPages - 1, onClick: () => setPageNumber(pageNumber + 1) }))))));
}
