var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, FormControl, FormHelperText, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { customerFetchMethods, customerValidationState } from 'types/customers';
import ScrollStep from './ScrollStep';
import { fetchAccountNumber, fetchPublisherCustomer } from './helpers';
import { CustomerValidationMessage } from './CustomerValidationMessage';
import { useLoading } from './hooks/useLoading';
// Messages to show if we are displaying the account number field
const getCustomerValidationMessageAndColor = ({ customerValidationValue, accountNumber, verifiedAccountNumber, newspaperName }) => {
    const yellowMessage = `We couldn't find an account with ${newspaperName} that matches your information in Column. If you believe you have an account with ${newspaperName}, please enter your account number.`;
    switch (customerValidationValue) {
        case customerValidationState.PUBLISHER_CUSTOMER_FOUND:
            return {
                message: `We found account #${accountNumber} associated with your ${accountNumber === verifiedAccountNumber ? newspaperName : 'Column'}  account. If you believe this is incorrect, please enter a different account number.`,
                color: 'green'
            };
        case customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN:
            return { message: yellowMessage, color: 'yellow' };
        case customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO:
            return { message: yellowMessage, color: 'yellow' };
        case customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID:
            return {
                message: `There is no account #${accountNumber} registered with ${newspaperName}. Please check again or skip account number.`,
                color: 'red'
            };
        default:
            console.error('Invalid customer validation state value');
            return { message: undefined, color: undefined };
    }
};
const AccountInformation = ({ id, activeStepId, next, previous, onDisabledStepClick, noticeState, filerRef, placement, placementActions }) => {
    var _a, _b, _c;
    const newspaperSnap = noticeState.newspaper;
    const [accountNumberOnFile, setAccountNumberOnFile] = useState(null);
    const [verifiedAccountNumber, setVerifiedAccountNumber] = useState(null);
    const [accountNumberEditedByAdvertiser, setAccountNumberEditedByAdvertiser] = useState(false);
    const [fetchPubCustIfNoAcctNum, setFetchPubCustIfNoAcctNum] = useState(false);
    const [loadingPublisherCustomer, fetchAndSetPublisherCustomerWithLoading] = useLoading();
    const [loadingAccountNumber, fetchAndSetAccountNumberWithLoading] = useLoading();
    const [customerValidationValue, setCustomerValidationValue] = useState();
    const eraseAccountNumberAndProceed = () => {
        placementActions.setAccountNumber(null);
        setAccountNumberEditedByAdvertiser(false);
        setCustomerValidationValue(null);
        next();
    };
    const fetchAndSetPublisherCustomer = () => __awaiter(void 0, void 0, void 0, function* () {
        const filerSnap = yield (filerRef === null || filerRef === void 0 ? void 0 : filerRef.get());
        const fetchResults = yield fetchPublisherCustomer({
            accountNumber: placement.accountNumber || null,
            verifiedAccountNumber,
            customerInfo: (filerSnap === null || filerSnap === void 0 ? void 0 : filerSnap.data()) || null,
            newspaperSnap
        });
        if (fetchResults.success) {
            setCustomerValidationValue(customerValidationState.PUBLISHER_CUSTOMER_FOUND);
            if (fetchResults.method === customerFetchMethods.FROM_CUSTOMER_INFO) {
                placementActions.setAccountNumber(`${fetchResults.customer.CustomerID}`);
                setVerifiedAccountNumber(`${fetchResults.customer.CustomerID}`);
            }
            if (fetchResults.method === customerFetchMethods.FROM_ACCOUNT_NUMBER) {
                setVerifiedAccountNumber(placement.accountNumber);
            }
            if (fetchResults.method === customerFetchMethods.FROM_CUSTOMER_INFO ||
                fetchResults.method === customerFetchMethods.ALREADY_VERIFIED) {
                setAccountNumberEditedByAdvertiser(false);
            }
        }
        else if (fetchResults.reason ===
            customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN) {
            setCustomerValidationValue(customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN);
        }
        else if (fetchResults.reason ===
            customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID) {
            setCustomerValidationValue(customerValidationState.INVALID_PUBLISHER_CUSTOMER_ID);
        }
        else if (fetchResults.reason ===
            customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO) {
            setCustomerValidationValue(customerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO);
        }
    });
    const fetchAndSetAccountNumber = () => __awaiter(void 0, void 0, void 0, function* () {
        const fetchResults = yield fetchAccountNumber({
            noticeSnap: (noticeState === null || noticeState === void 0 ? void 0 : noticeState.notice) || null,
            filerRef
        });
        if (fetchResults.success) {
            placementActions.setAccountNumber(fetchResults.accountNumber);
            setAccountNumberOnFile(fetchResults.accountNumber);
            setCustomerValidationValue(customerValidationState.PUBLISHER_CUSTOMER_FOUND);
        }
        else {
            placementActions.setAccountNumber(null);
            setAccountNumberOnFile(null);
            setCustomerValidationValue(customerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN);
            setFetchPubCustIfNoAcctNum(true);
        }
        setAccountNumberEditedByAdvertiser(false);
    });
    useEffect(() => {
        var _a, _b, _c;
        if (!((_c = (_b = (_a = noticeState === null || noticeState === void 0 ? void 0 : noticeState.notice) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.newspaper) === null || _c === void 0 ? void 0 : _c.id)) {
            return;
        }
        if (!accountNumberEditedByAdvertiser) {
            void fetchAndSetAccountNumberWithLoading(fetchAndSetAccountNumber);
        }
    }, [filerRef === null || filerRef === void 0 ? void 0 : filerRef.id, (_c = (_b = (_a = noticeState === null || noticeState === void 0 ? void 0 : noticeState.notice) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.newspaper) === null || _c === void 0 ? void 0 : _c.id]);
    useEffect(() => {
        if (!fetchPubCustIfNoAcctNum) {
            return;
        }
        void fetchAndSetPublisherCustomerWithLoading(() => __awaiter(void 0, void 0, void 0, function* () { return yield fetchAndSetPublisherCustomer(); }));
    }, [fetchPubCustIfNoAcctNum]);
    const stepComplete = !loadingPublisherCustomer &&
        !loadingAccountNumber &&
        customerValidationValue ===
            customerValidationState.PUBLISHER_CUSTOMER_FOUND;
    const readyToVerify = !loadingPublisherCustomer &&
        !loadingAccountNumber &&
        !customerValidationValue &&
        !!placement.accountNumber;
    const handleVerifyOrNext = () => __awaiter(void 0, void 0, void 0, function* () {
        if (stepComplete) {
            next();
        }
        else if (readyToVerify) {
            yield fetchAndSetPublisherCustomerWithLoading(() => __awaiter(void 0, void 0, void 0, function* () { return yield fetchAndSetPublisherCustomer(); }));
        }
    });
    return (React.createElement(ScrollStep, { id: id, activeStepId: activeStepId, next: handleVerifyOrNext, previous: previous, canSkip: true, skip: eraseAccountNumberAndProceed, skipText: 'Skip account number', onDisabledStepClick: onDisabledStepClick, title: 'Provide Account Information', caption: `Provide your ${newspaperSnap.data().name} account number. If you don't have an account with ${newspaperSnap.data().name}, skip this step.`, complete: stepComplete || readyToVerify, nextText: stepComplete ? 'Next' : 'Verify' },
        React.createElement(Grid, { item: true, style: { zIndex: 1000, maxWidth: '100%' } },
            React.createElement(Box, null,
                React.createElement(FormControl, { required: true, fullWidth: true },
                    React.createElement("div", { className: "col-span-6 sm:col-span-3" },
                        React.createElement("input", { id: "account-number-advertiser", placeholder: "Account Number", className: "form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", value: placement.accountNumber || '', onChange: (e) => {
                                placementActions.setAccountNumber(e.target.value);
                                const editedByAdvertiser = !!e.target.value &&
                                    e.target.value !== accountNumberOnFile &&
                                    e.target.value !== verifiedAccountNumber;
                                setAccountNumberEditedByAdvertiser(editedByAdvertiser);
                                if (editedByAdvertiser || !e.target.value) {
                                    setCustomerValidationValue(null);
                                }
                            } })))),
            React.createElement(Grid, { item: true },
                React.createElement("div", null,
                    React.createElement(FormHelperText, { id: "account-information-advertiser-helptext" },
                        "Account numbers only apply for billable customers with",
                        ' ',
                        newspaperSnap.data().name,
                        ". If you forget your account number or want to create an account with ",
                        newspaperSnap.data().name,
                        ", please contact",
                        ' ',
                        newspaperSnap.data().supportEmail
                            ? newspaperSnap.data().supportEmail
                            : 'the newspaper',
                        ".")))),
        customerValidationValue && (React.createElement(CustomerValidationMessage, { customerValidationMessage: getCustomerValidationMessageAndColor({
                customerValidationValue,
                accountNumber: placement.accountNumber,
                verifiedAccountNumber,
                newspaperName: newspaperSnap.data().name
            }).message, messageColor: getCustomerValidationMessageAndColor({
                customerValidationValue,
                accountNumber: placement.accountNumber,
                verifiedAccountNumber,
                newspaperName: newspaperSnap.data().name
            }).color }))));
};
const mapStateToProps = (state) => ({
    placement: state.placement
});
export default connect(mapStateToProps)(AccountInformation);
