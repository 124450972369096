var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { InvalidRoundedIcon } from 'icons';
import CTooltip from 'components/Tooltip';
import { ColumnButton } from 'components/ColumnButton';
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);
const styles = (theme) => createStyles({
    stepContainer: {
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        backgroundColor: 'white'
    },
    stepEnabled: {
        width: '100%'
    },
    stepFullyDisabled: {
        pointerEvents: 'none',
        opacity: 0.3,
        width: '100%'
    },
    spinner: {
        color: 'white',
        marginRight: '10px'
    },
    disabledButton: {
        color: 'white'
    },
    headingText: {
        color: theme.palette.grey.scrollFormHeading
    },
    infoIcon: {
        color: theme.palette.primary.main,
        opacity: 1,
        pointerEvents: 'auto'
    },
    iconButton: {
        paddingBottom: 0,
        paddingTop: 0,
        marginLeft: theme.spacing(1)
    },
    tooltip: {
        opacity: 1
    }
});
const ScrollStepContainer = ({ id, activeStepId, complete, next, nextText, nextTooltipText, previous, edit, editText, editTooltipText, canSkip, skip, skipText, skipTooltipText, onDisabledStepClick, classes, children, overrideStyles, title, caption, info, onHoldText }) => {
    const [nextLoading, setNextLoading] = useState(false);
    const isActive = id === activeStepId;
    return (React.createElement("div", { className: "border-gray-300 border rounded-md", onClick: () => {
            onDisabledStepClick && onDisabledStepClick(id);
        }, id: id },
        React.createElement("div", { className: isActive
                ? classes.stepEnabled
                : `${classes.stepFullyDisabled} .disabled-scroll-step` },
            React.createElement("div", { className: "content-center bg-white rounded-md shadow" },
                (title || caption) && (React.createElement("div", { className: "w-full mb-2" },
                    React.createElement("div", { className: "flex-col border-b border-gray-300" },
                        title && (React.createElement(Grid, { item: true, container: true, direction: "row" },
                            React.createElement("div", { className: `${!caption ? `py-6` : `pt-6`} px-4 bg-transparent text-right sm:pl-6` },
                                React.createElement("span", { className: "inline-flex rounded-md" },
                                    React.createElement("div", { className: "font-medium text-lg text-gray-800" }, title),
                                    info && (React.createElement("div", { className: "inline-block" },
                                        React.createElement(Grid, { item: true },
                                            React.createElement(HtmlTooltip, { title: info, className: classes.tooltip, leaveDelay: 500, interactive: true },
                                                React.createElement(IconButton, { size: "small", className: classes.iconButton },
                                                    React.createElement(InfoIcon, { className: classes.infoIcon })))))))))),
                        caption && (React.createElement(Box, { "data-testid": "scroll-step-caption" },
                            React.createElement("div", { className: "px-8 pb-6 bg-transparent sm:pl-6" },
                                React.createElement("span", { className: "inline-flex rounded-md text-gray-700" },
                                    React.createElement("div", { className: "font-medium text-sm" }, caption)))))))),
                React.createElement("div", { className: `${!overrideStyles ? `px-8 py-6` : ''}` }, children),
                (previous || next) && (React.createElement("div", { className: "py-2 bg-gray-100 mt-2 text-right w-full rounded-b-md border-t border-gray-300" },
                    React.createElement(Grid, { container: true, direction: "row", justify: previous ? 'space-between' : 'flex-end' },
                        React.createElement(Box, { className: "flex flex-row justify-start" },
                            previous && (React.createElement(Box, { mx: 1 },
                                React.createElement("div", { className: "py-3 bg-transparent text-right sm:pl-6" },
                                    React.createElement("span", { className: "inline-flex rounded-md" },
                                        React.createElement(ColumnButton, { secondary: true, size: "lg", onClick: () => {
                                                void previous();
                                            }, buttonText: "Previous" }))))),
                            edit && (React.createElement(Box, { ml: 1 },
                                React.createElement(CTooltip, { helpText: editTooltipText || '', position: "right" },
                                    React.createElement("div", { className: "pr-6 py-3 bg-transparent text-right" },
                                        React.createElement("span", { className: "inline-flex rounded-md shadow-sm" },
                                            React.createElement(ColumnButton, { tertiary: true, id: `${id}-edit-button`, size: "lg", onClick: () => {
                                                    void edit();
                                                }, buttonText: editText || 'Edit' }))))))),
                        (next || skip || onHoldText) && (React.createElement(Box, { className: "flex flex-row justify-end" },
                            skip && (React.createElement(Box, { mx: 1 },
                                React.createElement(CTooltip, { helpText: skipTooltipText || '', position: "right", classes: "text-left" },
                                    React.createElement("div", { className: "py-3 bg-transparent text-right" },
                                        React.createElement("span", { className: "inline-flex rounded-md shadow-sm" },
                                            React.createElement(ColumnButton, { secondary: true, size: "lg", id: `${id}-skip-button`, onClick: () => {
                                                    void skip();
                                                }, disabled: !canSkip, buttonText: skipText || 'Skip' })))))),
                            onHoldText && (React.createElement(CTooltip, { classes: 'text-left max-w-4', customPosition: "top-0 -mt-16 -ml-16", helpText: onHoldText },
                                React.createElement(InvalidRoundedIcon, { className: "w-4 mt-3 -mr-2 text-red-550" }))),
                            next && (React.createElement(Box, { ml: 1 },
                                React.createElement(CTooltip, { helpText: nextTooltipText || '', customPosition: "-ml-24 text-left" },
                                    React.createElement("div", { className: "pr-6 py-3 bg-transparent text-right" },
                                        React.createElement("span", { className: "inline-flex rounded-md shadow-sm" },
                                            React.createElement(ColumnButton, { primary: true, size: "lg", id: `${id}-button`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    setNextLoading(true);
                                                    yield next();
                                                    setNextLoading(false);
                                                }), buttonText: nextText || 'Next', disabled: !complete, startIcon: nextLoading ? (React.createElement(CircularProgress, { className: classes.spinner, size: 12 })) : undefined }))))))))))))),
        React.createElement("style", null, `
          .disabled-scroll-step {
            pointer-events: none;
            opacity: 0.3;
            width: 100%;
          }
        `)));
};
export default withStyles(styles)(ScrollStepContainer);
