var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import Dwolla from 'react-dwolla-iav';
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { logAndCaptureException } from 'utils';
const DwollaIav = ({ environment, customerToken, onClose }) => {
    const [showContinue, setShowContinue] = useState('');
    const [pageLoad, setPageLoad] = useState();
    const dwollaIavConfig = {
        backButton: true,
        customerToken,
        environment,
        fallbackToMicroDeposits: true,
        microDeposits: true,
        subscriber: ({ currentPage }) => {
            setPageLoad(currentPage);
        }
    };
    const addFundingSource = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield api.post('dwolla/add-bank', {
            fundingSourceId: data,
            verification: pageLoad === 'SuccessIAV'
        });
        if (response.success) {
            onClose();
        }
    });
    const onSuccess = (data) => {
        try {
            setShowContinue(data);
        }
        catch (err) {
            console.warn('Failed to update showContinue state:', err);
        }
    };
    const onError = (err) => {
        logAndCaptureException(err, 'Failed to add Dwolla funding source', {
            customerToken
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: `${pageLoad && 'hidden'} w-full flex justify-center` },
            React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10 mr-2 mt-8" })),
        React.createElement("div", { className: `${!pageLoad && 'hidden'}` },
            React.createElement(FreeformCModal, { header: '', setOpen: !pageLoad ? undefined : () => onClose(), noExitOutsideModal: true },
                React.createElement(React.Fragment, null,
                    React.createElement(Dwolla, { onSuccess: onSuccess, onError: onError, dwollaConfig: dwollaIavConfig }),
                    showContinue && (React.createElement("button", { type: "button", className: "px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => addFundingSource(showContinue) }, "Continue")))))));
};
export default DwollaIav;
