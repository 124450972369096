var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import { InviteStatus, OccupationType } from 'lib/enums';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import InviteActionModalHeader from 'components/invitesComponent/InviteActionModalHeader';
import { exists } from 'lib/types';
import { safeStringify } from 'lib/utils/stringify';
import { acceptInvitesHelper, declineInviteHelper, transformInvitesToActionCardInvites } from './helpers';
import { getFirebaseContext } from '../../../utils/firebase';
const JoinOrganizationModal = ({ user, authActions, showRequests }) => {
    const ctx = getFirebaseContext();
    const inviteFromSession = sessionStorage.getItem('inviteId');
    const [invites, setInvites] = useState();
    const [transformedInvites, setTransformedInvites] = useState();
    const pendingInvites = transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.filter(invite => invite.userInvite.data().status === InviteStatus.pending.value);
    const [showModal, setShowModal] = useState(false);
    const getInvites = () => {
        return ctx
            .invitesRef()
            .where('email', '==', user.data().email)
            .where('organizationId', '>', '')
            .onSnapshot(result => {
            setInvites(result.docs);
        });
    };
    const getInvitedOrgData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (invites === null || invites === void 0 ? void 0 : invites.length) {
            let pendingInvites = invites.slice();
            if (inviteFromSession) {
                const [inviteFromSessionSnap] = pendingInvites.filter(invite => invite.id === inviteFromSession);
                if (exists(inviteFromSessionSnap)) {
                    // Shuffle the clicked-on invite to the top of the invites modal
                    pendingInvites = pendingInvites.filter(invite => invite.id !== inviteFromSessionSnap.id);
                    pendingInvites.unshift(inviteFromSessionSnap);
                }
            }
            const transformedInvites = yield transformInvitesToActionCardInvites(pendingInvites);
            setTransformedInvites(transformedInvites);
            const updatedShowModal = ((_a = transformedInvites.filter(invite => invite.userInvite.data().status === InviteStatus.pending.value)) === null || _a === void 0 ? void 0 : _a.length) > 0;
            /**
             * If the user previously had pending invites but now has none
             * and does not have an occupation set,
             * update their occupation to individual
             */
            const refreshedUserSnap = yield user.ref.get();
            if (showModal &&
                !updatedShowModal &&
                exists(refreshedUserSnap) &&
                !refreshedUserSnap.data().occupation) {
                yield refreshedUserSnap.ref.update({
                    occupation: OccupationType.individual.value
                });
            }
            setShowModal(updatedShowModal);
        }
    });
    const acceptInvite = (inviteSnap) => __awaiter(void 0, void 0, void 0, function* () {
        const { organizationId, email } = inviteSnap.data();
        try {
            yield acceptInvitesHelper(ctx, [inviteSnap], user, authActions);
        }
        catch (err) {
            logAndCaptureException(err, 'Failed to join organization from invite', {
                userEmail: email || '',
                orgId: organizationId || '',
                inviteId: inviteSnap.id || ''
            });
        }
    });
    const declineInvite = (invite) => __awaiter(void 0, void 0, void 0, function* () {
        if (inviteFromSession === invite.ref.id) {
            sessionStorage.removeItem('inviteId');
        }
        yield declineInviteHelper(invite);
    });
    const snoozeAllInvites = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length)) {
            return;
        }
        try {
            yield Promise.all(pendingInvites.map((invite) => __awaiter(void 0, void 0, void 0, function* () {
                if (inviteFromSession === invite.userInvite.id) {
                    sessionStorage.removeItem('inviteId');
                }
                const inviteSnap = invite.userInvite;
                yield inviteSnap.ref.update({
                    status: InviteStatus.snoozed.value
                });
            })));
        }
        catch (err) {
            logAndCaptureException(err, 'Error snoozing all invites', {
                userId: user.id
            });
        }
        setShowModal(false);
    });
    const acceptAllInvites = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length)) {
            return;
        }
        try {
            yield acceptInvitesHelper(ctx, pendingInvites.map(invite => invite.userInvite), user, authActions);
        }
        catch (err) {
            logAndCaptureException(err, 'Error accepting all invites', {
                userId: user.id
            });
        }
    });
    useEffect(() => {
        const invitesUnsub = getInvites();
        return () => {
            invitesUnsub();
        };
    }, []);
    useEffect(() => {
        void getInvitedOrgData();
    }, [safeStringify(invites)]);
    useEffect(() => {
        if (!(pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length)) {
            showRequests(true);
        }
        else
            showRequests(false);
    }, [pendingInvites]);
    if (!showModal)
        return null;
    return (React.createElement(CancelOrSubmitModal, { setOpen: setShowModal, tertiaryButtonText: 'Skip for now', primaryButtonText: 'Accept all', backgroundStyle: 'bg-gray-50', showLoadingSpinner: true, onSubmit: acceptAllInvites, overrideExitOutsideModal: snoozeAllInvites, overrideTertiaryClose: snoozeAllInvites },
        React.createElement("div", { id: "invite-modal-container", className: "text-center" },
            React.createElement("div", { style: {
                    clipPath: 'circle()'
                }, id: "invite-modal-icon", className: "flex justify-center mb-6 h-20 w-20 inline-flex overflow-hidden" },
                React.createElement("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/cfcf.171eb-giphy%20(16).gif", style: {
                        transform: 'scale(1.4)'
                    } })),
            (pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length) ? (React.createElement(React.Fragment, null,
                React.createElement(InviteActionModalHeader, { id: "invites-exist-header", title: "You have pending team invites", subtitle: "Accept invites to manage notices, invoices, & affidavits for your organization." }),
                React.createElement("div", { id: "invites-exist-modal-body relative", className: "pb-4" }, transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.map((invite, index) => {
                    return invite.userInvite.data().status ===
                        InviteStatus.pending.value ? (React.createElement("div", { key: `user-invite-${invite.userInvite.id}` },
                        React.createElement(InviteActionCard, { invite: invite, onAcceptClick: () => acceptInvite(invite.userInvite), onDeclineClick: () => declineInvite(invite.userInvite), index: index, className: 'rounded-md border my-6', type: 'invite' }))) : (React.createElement(React.Fragment, null));
                })))) : (React.createElement(InviteActionModalHeader, { id: "invites-cleared-header", title: "Pending invites cleared", titleEndIcon: React.createElement("span", { role: "img", "aria-label": "Closed mailbox with lowered flag" }, "\uD83D\uDCED"), subtitle: "You have either accepted or declined all of your pending invites. We will notify you when you get new ones." })))));
};
export default JoinOrganizationModal;
