import React, { useState, useEffect } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';
import TemplateSettingsHeader from './TemplateSettingsHeader';
import TemplateSettingsDrawer from './templateSettingsDrawer';
import TemplatesSettingsTable from './TemplateTable';
import TemplateUpdateForm from './templateSettingsUpdateForm';
/**
 * Settings page for newspaper templates
 */
export default function AdTemplateSettings({ activeOrganization }) {
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [templatesFilter, setTemplatesFilter] = useState('');
    const [drawerTemplate, setDrawerTemplate] = useState();
    const [editedTemplate, setEditedTemplate] = useState();
    useEffect(() => {
        const unsubscribe = getFirebaseContext()
            .adTemplatesRef()
            .where('organization', '==', activeOrganization.ref)
            .onSnapshot(snapshot => {
            setTemplates(snapshot.docs);
            setLoading(false);
        });
        return () => unsubscribe();
    }, [activeOrganization.id]);
    const filteredTemplates = templates === null || templates === void 0 ? void 0 : templates.filter(template => !templatesFilter ||
        template
            .data()
            .name.toLowerCase()
            .includes(templatesFilter.toLowerCase())).sort((a, b) => {
        var _a;
        if (a.id === ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id)) {
            return -1;
        }
        return a.data().name.localeCompare(b.data().name);
    });
    if (loading)
        return React.createElement(LoadingState, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white sm:rounded-lg border border-gray-300 shadow" },
            React.createElement(TemplateSettingsHeader, { activeOrganization: activeOrganization, setTemplatesFilter: setTemplatesFilter, setEditedTemplate: setEditedTemplate, templatesFilter: templatesFilter }),
            React.createElement(TemplatesSettingsTable, { activeOrganization: activeOrganization, setDrawerTemplate: setDrawerTemplate, setEditedTemplate: setEditedTemplate, templatesFilter: templatesFilter, templates: filteredTemplates })),
        drawerTemplate && (React.createElement(TemplateSettingsDrawer, { activeOrganization: activeOrganization, setEditedTemplate: setEditedTemplate, drawerTemplate: drawerTemplate, closeDrawer: () => setDrawerTemplate(undefined) })),
        editedTemplate && (React.createElement(TemplateUpdateForm, { closeUpdateForm: () => setEditedTemplate(undefined), template: editedTemplate }))));
}
