import { createReducer, createActions } from 'reduxsauce';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setError: ['data']
});
export const ErrorTypes = Types;
export default Creators;
const INITIAL_STATE = {
    error: ''
};
/* ------------- Reducer ------------- */
const handlers = {
    [Types.SET_ERROR]: (state, payload) => {
        const { error } = payload.data;
        return Object.assign(Object.assign({}, state), { error });
    }
};
export const reducer = createReducer(INITIAL_STATE, handlers);
