import React from 'react';
import { TextField } from 'components/TextField';
import LayoutSettingsInputWrapper from './LayoutSettingsInputWrapper';
import SettingsCard from './SettingsCard';
/**
 * Control the headers and footers of notices
 */
export default function LayoutSettingsForm({ setLayoutSettings, layoutSettings }) {
    return (React.createElement(SettingsCard, { header: "Headers and Footers", subheader: React.createElement("div", null,
            "Control what appears at the top and bottom of your notices. For more details on how you can configure headers and footers, see the instructions",
            ' ',
            React.createElement("a", { className: "underline", href: "https://www.notion.so/column/Headers-Footers-7ddc956a6c6a4c7b92bb56d71dd57b2c", target: "_blank", rel: "noopener noreferrer" }, "here.")) },
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement(TextField, { id: "header", labelText: "Header", value: layoutSettings.headerFormatString, onChange: newValue => {
                    setLayoutSettings(Object.assign(Object.assign({}, layoutSettings), { headerFormatString: newValue }));
                } })),
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement(TextField, { id: "footer", labelText: "Footer", value: layoutSettings.footerFormatString, onChange: newValue => {
                    setLayoutSettings(Object.assign(Object.assign({}, layoutSettings), { footerFormatString: newValue }));
                } })),
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement(TextField, { id: "one-run-header", labelText: "One Run Header", value: layoutSettings.oneRunHeader, onChange: newValue => {
                    setLayoutSettings(Object.assign(Object.assign({}, layoutSettings), { oneRunHeader: newValue }));
                } })),
        React.createElement(LayoutSettingsInputWrapper, null,
            React.createElement(TextField, { id: "one-run-footer", labelText: "One Run Footer", value: layoutSettings.oneRunFooter, onChange: newValue => {
                    setLayoutSettings(Object.assign(Object.assign({}, layoutSettings), { oneRunFooter: newValue }));
                } }))));
}
