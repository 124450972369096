import React from 'react';
import { Route } from 'react-router';
import Frame from '../layouts/Frame';
const WithFrame = (props) => {
    const { exact, path, component, hideFrame, noFramePadding } = props;
    const WithFrame = (Component) => (props) => {
        return (React.createElement(Frame, Object.assign({ noFramePadding: noFramePadding }, props),
            React.createElement(Component, Object.assign({}, props))));
    };
    const ExtendedComponent = hideFrame ? component : WithFrame(component);
    return (React.createElement(Route, { exact: exact, path: path, render: () => React.createElement(ExtendedComponent, null) }));
};
export default WithFrame;
