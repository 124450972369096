import { isEqual } from 'lodash';
/**
 * Attemmpt to determin which fields changed between two snapshots. This is
 * likely to have some false positives for complex object fields.
 */
export const keysChanged = (before, after) => {
    const beforeData = before.data();
    const afterData = after.data();
    const beforeKeys = new Set(Object.keys(beforeData));
    const afterKeys = new Set(Object.keys(afterData));
    const removedKeys = new Set([...beforeKeys].filter(k => !afterKeys.has(k)));
    const addedKeys = new Set([...afterKeys].filter(k => !beforeKeys.has(k)));
    const commonKeys = new Set([...beforeKeys].filter(k => afterKeys.has(k)));
    const changedKeys = [...addedKeys, ...removedKeys];
    for (const k of commonKeys) {
        if (!isEqual(beforeData[k], afterData[k])) {
            changedKeys.push(k);
        }
    }
    return changedKeys;
};
