var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from '../../components/CButton';
const ConfirmNoticeWithoutProof = ({ setOpen, newspaper }) => {
    const [confirmed, setConfirmed] = useState(false);
    const HELP_LINK = 'https://help.column.us/article/53-how-do-i-process-notices-that-havent-been-formatted';
    const MODAL_DESC = (React.createElement("p", { className: "text-sm font-normal" },
        "The advertiser opted to not use auto-layout for this notice. By clicking Continue, you acknowledge that ",
        newspaper.data().name,
        " will manually format the content of this public notice as necessary for publication. You will be prompted to upload your formatted notice upon clicking \"Create Invoice.\"",
        React.createElement("br", null),
        "Need help? Click",
        ' ',
        React.createElement("a", { href: HELP_LINK, rel: "noopener noreferrer", target: "_blank", className: "text-blue-600" }, "here"),
        "."));
    const buttonStyles = 'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-3/6';
    return (React.createElement(FreeformCModal, { header: "Notice Submitted Without Proof", body: MODAL_DESC },
        React.createElement("div", { className: "flex justify-center md:justify-start" },
            React.createElement(CButton, { id: "confirm-receipt-without-proof", disabled: confirmed, className: buttonStyles, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setConfirmed(true);
                    yield new Promise(resolve => setTimeout(resolve, 2000));
                    setOpen(false);
                }) },
                confirmed ? (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-gray-200 h-6 w-6 align-middle inline-block" })) : (React.createElement(React.Fragment, null)),
                React.createElement("span", { className: confirmed ? 'ml-2 inline-block' : '' }, "Continue")))));
};
export default ConfirmNoticeWithoutProof;
