/**
 * These types will be used to trace status for both invites and joinRequests
 */
export const InviteStatusTypeData = {
    pending: {
        value: 1,
        label: 'Pending',
        key: 'pending'
    },
    /**
     * Snoozed invites do not appear in the Join Org modal
     * when a user logs in- they live in the notifications drawer,
     * where they can be accepted/ declined at a later date.
     */
    snoozed: {
        value: 2,
        label: 'Snoozed',
        key: 'snoozed'
    },
    accepted: {
        value: 3,
        label: 'Accepted',
        key: 'accepted'
    },
    declined: {
        value: 4,
        label: 'Declined',
        key: 'declined'
    }
};
