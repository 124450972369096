var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import CancelOrSubmitModal from 'components/modals/CancelOrSubmitModal';
import InfoCard from 'components/InfoCard';
import api from 'api';
import { logAndCaptureException } from 'utils';
const downloadOptions = [
    {
        id: 'appliesToPast',
        appliesToPast: true,
        header: 'Apply to existing & future affidavits',
        body: 'Save and apply these changes to current notices without affidavits, as well as future notices.'
    },
    {
        id: 'noAppliesToPast',
        appliesToPast: false,
        header: 'Apply to future affidavits only',
        body: "Save and apply these changes to future notices only (existing affidavits won't be affected)."
    }
];
const AffidavitSettingsSaveModal = ({ setOpen, activeOrganization, publicationFrequency, signatoryName, signatoryRole, commissionExpirationDate, onSuccessfulSave }) => {
    const [appliesToPast, setAppliesToPast] = useState(true);
    const [saving, setSaving] = useState(false);
    const radioOption = (option) => {
        return (React.createElement("div", { key: option.id, className: `relative flex items-start border justify-center ${!!option.appliesToPast === appliesToPast
                ? 'border-blue-900'
                : 'border-gray-300'} rounded-md bg-white`, onClick: () => {
                if (!saving) {
                    setAppliesToPast(!!option.appliesToPast);
                }
            } },
            React.createElement("div", { className: "sm:flex-column sm:items-start w-11/12 px-4.5 py-3.5" },
                React.createElement("h3", { id: `${option.id}-description`, className: `${!!option.appliesToPast === appliesToPast
                        ? 'text-blue-900'
                        : 'text-gray-900'} text-md` }, option.header),
                React.createElement("span", { className: "text-gray-600 text-xs" }, option.body)),
            React.createElement("div", { className: "flex flex-col items-center w-1/12 py-4 pl-0.5 pr-4 py-10 h-24" },
                React.createElement("input", { id: option.id, "aria-describedby": `${option.id}-button`, name: option.id, disabled: saving, type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: option.appliesToPast ? appliesToPast : !appliesToPast, onChange: () => setAppliesToPast(option.appliesToPast) }))));
    };
    const loadingMessage = appliesToPast ? (React.createElement(InfoCard, { message: "Just a moment, we're applying changes to current notices." })) : null;
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        setSaving(true);
        try {
            yield activeOrganization.ref.update({
                publicationFrequency,
                signatoryName,
                signatoryRole,
                commissionExpirationDate
            });
            if (appliesToPast) {
                const { success, error } = yield api.get(`organizations/${activeOrganization.id}/affidavits/regenerate`);
                if (!success) {
                    throw new Error(error);
                }
            }
            setSaving(false);
            setOpen(false);
            onSuccessfulSave();
        }
        catch (err) {
            setOpen(false);
            logAndCaptureException(err, 'An error occurred when updating affidavit information for organization ', {
                organizationId: activeOrganization.id
            });
        }
    });
    return (React.createElement("div", null,
        React.createElement(CancelOrSubmitModal, { setOpen: setOpen, body: "Confirm how you want to save these changes.", header: "Save Changes", noExitOutsideModal: true, disableOverflow: true, onSubmit: () => {
                void handleSave();
            }, tertiaryButtonText: "Cancel", primaryButtonText: "Save", loadingMessage: loadingMessage, showLoadingSpinner: true },
            React.createElement("div", { className: "flex flex-col w-full my-8" },
                React.createElement("fieldset", { className: "space-y-5 text-sm font-medium" }, downloadOptions.map(option => radioOption(option)))))));
};
export default AffidavitSettingsSaveModal;
