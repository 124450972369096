var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { DEFAULT_COLUMN_FEE_PCT } from 'lib/pricing';
import { rateCodeGenerator } from 'lib/helpers';
import { ColumnButton } from 'components/ColumnButton';
import { PlusCircleIcon, SearchIcon } from 'icons';
import { TextField } from 'components/TextField';
import { RateType } from 'lib/enums';
/**
 * Header component for the rates settings page
 */
export default function RatesSettingsHeader({ activeOrganization, setEditedRateData, setRatesFilter, ratesFilter }) {
    const [submitting, setSubmitting] = useState(false);
    const addRate = () => __awaiter(this, void 0, void 0, function* () {
        setSubmitting(true);
        yield setEditedRateData({
            organization: activeOrganization.ref,
            rateType: RateType.column_inch.value,
            description: `New Rate ${new Date().toLocaleDateString()}`,
            enotice_fee_pct: DEFAULT_COLUMN_FEE_PCT,
            isGovernment: false,
            roundOff: 0,
            rate_0: 500,
            rate_1: 500,
            rate_2: 500,
            minimum: 100,
            archived: false,
            code: rateCodeGenerator()
        });
        setSubmitting(false);
    });
    return (React.createElement("div", { className: "w-full flex items-center font-medium pl-7 pr-5 py-5 border-b border-gray-300" },
        React.createElement("div", { className: "flex-1" },
            React.createElement("p", { className: "text-xl text-blue-800" }, "Rates"),
            React.createElement("p", { className: "text-sm leading-6 text-grey-400" }, "Configure the cost of notices in your paper.")),
        React.createElement("div", { className: "w-1/3 mr-5" },
            React.createElement(TextField, { id: 'affidavit-files-search', labelText: '', placeholder: "Search", size: "small", value: ratesFilter, onChange: setRatesFilter, prefix: React.createElement(SearchIcon, { className: "w-5 text-grey-300", "aria-hidden": "true" }) })),
        React.createElement("div", null,
            React.createElement("div", { className: "w-32 mt-1" },
                React.createElement(ColumnButton, { startIcon: React.createElement(PlusCircleIcon, null), buttonText: "Add Rate", disabled: submitting, onClick: addRate, fullWidth: true, primary: true })))));
}
