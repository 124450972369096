var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useMemo } from 'react';
import { OccupationType } from 'lib/enums';
import { exists } from 'lib/types';
import { ArrowDownIcon, MailOpenIcon, DocumentDownloadIcon, FilterIcon, ArrowDownCircle, BalanceIcon, ArrowUpCircle } from 'icons';
import PostRegistrationModal from 'components/modals/PostRegistrationModal';
import { debounce } from 'lodash';
import EToast from 'components/EToast';
import { ColumnButton } from 'components/ColumnButton';
import SuccessModal from 'components/modals/SuccessModal';
import { getAffidavitSettingsForNewspaper } from 'lib/pricing/affidavits';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import JoinOrganizationModal from 'components/modals/JoinOrganizationModals/JoinOrganizationModal';
import { DEFAULT_NOTICE_FILTERS, getNumActiveFilters } from 'utils/noticeFilter';
import { PartyEmoji } from 'gifs';
import PlaceNoticeButton from 'components/PlaceNoticeButton';
import { userIsAdmin, userIsBilling } from 'utils/permissions';
import JoinOrganizationRequestModal from 'components/modals/JoinOrganizationModals/JoinOrganizationRequestModal';
import YourNoticesTable from './YourNoticesTable';
import BulkDownloadModal from './BulkDownloadModal';
import BulkAffidavitDownload from './BulkAffidavitDownloadModal';
import BulkAffidavitUpload from './BulkAffidavitUploadModal';
import EEditionUploader from './EEditionUploaderModal';
import DwollaPasswordResetModal from '../../settings/publisher/DwollaPasswordResetModal';
import { ACTIVE_NOTICES_TAB, ARCHIVED_NOTICES_TAB } from './types';
import ExportNoticesModal from './ExportNoticesModal';
import NoticesFilterDialog from './NoticesFilterDialog';
const DropdownItem = ({ id, onClick, children }) => (React.createElement("a", { href: "#", id: id, className: "flex items-center py-3 px-4 font-medium text-md text-gray-750 hover:bg-gray-100 hover:text-gray-900", role: "menuitem", onClick: onClick }, children));
const views = {
    active: { name: 'Active', index: 0 },
    archived: { name: 'Archived', index: 1 },
    drafts: { name: 'Drafts', index: 2 }
};
const TabPanel = props => {
    const { children, value, tabIndex } = props;
    return React.createElement("div", { hidden: value !== tabIndex },
        " ",
        children);
};
const ActionButtons = ({ isPublisher, tab, showAllOrgsNotices, activeOrganization, noticesFilterValue, setShowExportEmailModal, setShowExportCsvModal, setShowExportPdfModal, setShowBulkDownloadModal, setShowBulkAffidavitDownloadModal, setShowBulkAffidavitUploadModal, setNoticesFilterValue, setShowEUploaderModal, showEUploaderButton }) => {
    const [showExportMenu, setShowExportMenu] = useState(false);
    // By using a string here instead of multiple booleans, we can make showing
    // these menus mutually exclusive
    const [showMenu, setShowMenu] = useState();
    const showFilterMenu = showMenu === 'filter';
    const showMoreActionsMenu = showMenu === 'more-actions';
    const toggleMenu = (menu) => {
        if (showMenu === menu) {
            setShowMenu(undefined);
        }
        else {
            setShowMenu(menu);
        }
    };
    const numFiltersActive = getNumActiveFilters(noticesFilterValue);
    const handleClick = (e, value) => {
        e.preventDefault();
        switch (value) {
            case 'showBulkAffidavitDownloadModal':
                setShowBulkAffidavitDownloadModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
                break;
            case 'showBulkAffidavitUploadModal':
                setShowBulkAffidavitUploadModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
                break;
            case 'showBulkNoticeDownloadModal':
                setShowBulkDownloadModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
                break;
            case 'showExportCsvModal':
                setShowExportCsvModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
                break;
            case 'showExportEmailModal':
                setShowExportEmailModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
                break;
            case 'showEUploaderModal':
                setShowEUploaderModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
                break;
            default:
                setShowExportPdfModal(true);
                setShowExportMenu(false);
                setShowMenu(undefined);
        }
    };
    const actionButtonClassName = 'bg-white border-2 focus:outline-none hover:border-blue-400 p-2 px-4 rounded text-gray-700 font-semibold items-center inline-flex';
    const showFilters = isPublisher && tab === ACTIVE_NOTICES_TAB;
    const showMoreActionsButton = isPublisher && tab === ACTIVE_NOTICES_TAB;
    const showAdvertiserExport = !isPublisher && !showAllOrgsNotices && tab === ACTIVE_NOTICES_TAB;
    const renderMoreActionsButton = () => {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "relative inline-block text-left", id: "more-actions" },
                React.createElement("div", null,
                    React.createElement("button", { type: "button", onClick: () => toggleMenu('more-actions'), className: actionButtonClassName, id: "more-actions-menu", "aria-expanded": "true", "aria-haspopup": "true" },
                        React.createElement("span", { className: "" }, "More"),
                        React.createElement(ArrowDownIcon, { className: "ml-2" }))),
                showMoreActionsMenu && (React.createElement("div", { className: "origin-top-right absolute right-0 mt-1 w-56 z-10 rounded-md shadow-md bg-white border border-gray-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none pt-4 pb-1", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "more-actions-menu" },
                    React.createElement("div", { className: "py-1", role: "none" },
                        React.createElement("div", { className: "uppercase text-gray-500 text-xs font-bold mb-3 px-4" }, "more actions"),
                        React.createElement(DropdownItem, { id: "download-notice", onClick: (_e) => handleClick(_e, 'showBulkNoticeDownloadModal') },
                            React.createElement(DocumentDownloadIcon, { className: "w-5 h-5", strokeWidth: "1.25", style: {
                                    marginLeft: '-2px',
                                    marginRight: '6px'
                                } }),
                            "Download notices"),
                        !((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.affidavitDisabled) && (React.createElement(React.Fragment, null,
                            React.createElement(DropdownItem, { id: "download-affidavits", onClick: (_e) => handleClick(_e, 'showBulkAffidavitDownloadModal') },
                                React.createElement(ArrowDownCircle, { className: "w-4 h-4 mr-2" }),
                                "Download affidavits"),
                            React.createElement(DropdownItem, { id: "upload-affidavits", onClick: (_e) => handleClick(_e, 'showBulkAffidavitUploadModal') },
                                React.createElement(BalanceIcon, { className: "w-4 h-4 mr-2" }),
                                "Upload affidavits"))),
                        showEUploaderButton && (React.createElement(DropdownItem, { id: "upload-e-edition", onClick: (_e) => handleClick(_e, 'showEUploaderModal') },
                            React.createElement(ArrowUpCircle, { className: "h-4 w-4 inline-block mr-2" }),
                            "Upload e-edition"))))))));
    };
    return (React.createElement("div", { id: "export-dropdown", className: `flex items-center gap-2` },
        showFilters && (React.createElement("div", { className: "relative inline-block text-left" },
            React.createElement("div", null,
                React.createElement("button", { type: "button", className: actionButtonClassName, id: "filters-options-menu", "aria-expanded": "true", "aria-haspopup": "true", onClick: () => toggleMenu('filter') },
                    React.createElement("span", { className: "mr-2" }, "Filter"),
                    numFiltersActive > 0 && (React.createElement("div", { className: "text-xs text-blue-500 bg-blue-100 rounded-full px-2 py-px mr-1 whitespace-nowrap" },
                        React.createElement("span", null,
                            numFiltersActive,
                            " active"),
                        React.createElement("span", { className: "ml-2 cursor-pointer hover:text-blue-700", onClick: e => {
                                e.stopPropagation();
                                setNoticesFilterValue(DEFAULT_NOTICE_FILTERS);
                                setShowMenu(undefined);
                            } }, "\u2715"))),
                    React.createElement(FilterIcon, { className: "h-3" }))),
            showFilterMenu && (React.createElement(NoticesFilterDialog, { className: "absolute right-0", value: noticesFilterValue, onChange: v => {
                    setNoticesFilterValue(v);
                    setShowMenu(undefined);
                } })))),
        showMoreActionsButton ? renderMoreActionsButton() : React.createElement(React.Fragment, null),
        showAdvertiserExport && (React.createElement("div", null,
            React.createElement(ColumnButton, { buttonText: "Export", onClick: () => setShowExportMenu(!showExportMenu), endIcon: React.createElement(ArrowDownIcon, null) }),
            showExportMenu && (React.createElement("div", { className: "border rounded-md origin-top-right absolute right-0 w-56 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "affidavit-options-menu", style: {
                    marginRight: '70px',
                    marginTop: '6px',
                    borderColor: '#E8ECF0'
                } },
                React.createElement("div", { className: "p-1.5", role: "none" },
                    React.createElement(DropdownItem, { id: "export-csv", onClick: (_e) => handleClick(_e, 'showExportCsvModal') },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(DocumentDownloadIcon, { class: "w-4 h-4", strokeWidth: "2" })),
                        React.createElement("span", { className: "pl-3 font-medium" }, "Export as CSV")),
                    React.createElement(DropdownItem, { id: "export-pdf", onClick: (_e) => handleClick(_e, 'showExportPdfModal') },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(DocumentDownloadIcon, { class: "w-4 h-4", strokeWidth: "2" })),
                        React.createElement("span", { className: "pl-3 font-medium" }, "Export as PDF")),
                    React.createElement(DropdownItem, { id: "export-csv", onClick: (_e) => handleClick(_e, 'showExportEmailModal') },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(MailOpenIcon, { class: "w-4 h-4", strokeWidth: "2" }),
                            React.createElement("span", { className: "pl-3 font-medium" }, "Send to email"))))))))));
};
const YourNoticesTableView = ({ user, activeOrganization, userAuth, userSnap, showAllOrgsNotices, tab, search, currentPage, notices, total, showUserOnlyNotices, fetching, noticesActions, authActions, pageSize, sort, noticesFilterValue }) => {
    var _a, _b;
    const [openBulkDownloadModal, setShowBulkDownloadModal] = useState(false);
    const isPublisher = user.data().occupation === OccupationType.publishing.value ||
        user.data().occupation === OccupationType.press_association_manager.value;
    const [searchValue, setSearchValue] = useState(search);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [showExportPdfModal, setShowExportPdfModal] = useState(false);
    const [showExportCsvModal, setShowExportCsvModal] = useState(false);
    const [showExportEmailModal, setShowExportEmailModal] = useState(false);
    const [showExportSuccessModal, setShowExportSuccessModal] = useState(false);
    const [showBulkAffidavitDownloadModal, setShowBulkAffidavitDownloadModal] = useState(false);
    const [showBulkAffidavitUploadModal, setShowBulkAffidavitUploadModal] = useState(false);
    const [successModalBody, setSuccessModalBody] = useState();
    const [message, setMessage] = useState();
    const [successToast, setSuccessToast] = useState();
    const [showEUploaderButton, setShowEUploaderButton] = useState(false);
    const [showEUploaderModal, setShowEUploaderModal] = useState(false);
    const [showEUploadSuccessModal, setShowEUploadSuccessModal] = useState(false);
    const [showRequests, setShowRequests] = useState(false);
    // Modal for password reset that appears when newspaper user add Dwolla
    // payment method. Not shown when shadowing.
    const isShadowing = !!user.data().shadowUser;
    const passwordResetModalValue = ((_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwolla) === null || _b === void 0 ? void 0 : _b.dwollaFundingSource) &&
        (userIsAdmin(user) || userIsBilling(user)) &&
        !user.data().dwollaPasswordSecurityEnabled;
    const [openPasswordResetModal, setOpenPasswordResetModal] = useState(!isShadowing && passwordResetModalValue);
    const updateSearch = useMemo(() => debounce(value => {
        if (value !== search) {
            noticesActions.setCurrentPage(0);
        }
        noticesActions.setSearch(value);
    }, 400), []);
    useEffect(() => {
        const updateBasedOnAffidavitSettings = () => __awaiter(void 0, void 0, void 0, function* () {
            const newspaperAffidavitSettings = yield getAffidavitSettingsForNewspaper(activeOrganization);
            if ((newspaperAffidavitSettings === null || newspaperAffidavitSettings === void 0 ? void 0 : newspaperAffidavitSettings.affidavitsManagedByColumn) &&
                getBooleanFlag(LaunchDarklyFlags.SHOW_E_EDITION_UPLOADER)) {
                setShowEUploaderButton(true);
            }
        });
        if (exists(activeOrganization)) {
            void updateBasedOnAffidavitSettings();
        }
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    useEffect(() => {
        updateSearch(searchValue);
    }, [searchValue]);
    useEffect(() => {
        if (!!showBulkAffidavitUploadModal || !!showBulkAffidavitDownloadModal) {
            setMessage('');
        }
    }, [showBulkAffidavitDownloadModal, showBulkAffidavitUploadModal]);
    useEffect(() => {
        setSelectedTabIndex(tab === ACTIVE_NOTICES_TAB ? 0 : tab === ARCHIVED_NOTICES_TAB ? 1 : 2);
    }, [tab]);
    const actionButtons = (React.createElement(ActionButtons, { isPublisher: isPublisher, showAllOrgsNotices: showAllOrgsNotices, tab: tab, activeOrganization: activeOrganization, noticesFilterValue: noticesFilterValue, setShowExportCsvModal: setShowExportCsvModal, setShowExportEmailModal: setShowExportEmailModal, setShowExportPdfModal: setShowExportPdfModal, setShowBulkDownloadModal: setShowBulkDownloadModal, setShowBulkAffidavitDownloadModal: setShowBulkAffidavitDownloadModal, setShowBulkAffidavitUploadModal: setShowBulkAffidavitUploadModal, setNoticesFilterValue: noticesActions.setNoticesFilterValue, showEUploaderButton: showEUploaderButton, setShowEUploaderModal: setShowEUploaderModal }));
    return (React.createElement("div", { className: "pb-10" },
        React.createElement("header", { className: "flex justify-between px-8" },
            React.createElement("div", { className: "-mt-2" },
                React.createElement("h1", { className: "text-3xl font-medium leading-tight text-gray-900" }, "My Notices")),
            React.createElement("div", null,
                React.createElement(PlaceNoticeButton, null))),
        React.createElement("main", null,
            React.createElement("div", { className: "max-w-7xl mx-auto sm:px-6 lg:px-8" },
                React.createElement("div", { className: "px-4 py-4 sm:px-0" },
                    React.createElement("div", { className: "rounded-lg" },
                        React.createElement("div", { id: "active-notices-table" },
                            React.createElement(TabPanel, { value: selectedTabIndex, tabIndex: views.active.index },
                                React.createElement(YourNoticesTable, { id: "active-notices-table", notices: fetching ? [] : notices || [], loading: fetching, title: "Active", isPublisher: isPublisher, setCurrentPage: noticesActions.setCurrentPage, currentPage: currentPage, sort: sort, setSort: noticesActions.setSort, actions: actionButtons, total: total, setPageSize: noticesActions.setPageSize, setTab: noticesActions.setTab, setSearchValue: setSearchValue, rowsPerNoticeTablePage: pageSize, setShowUserOnlyNotices: noticesActions.setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue }))),
                        React.createElement("div", { id: "archived-notices-table" },
                            React.createElement(TabPanel, { value: selectedTabIndex, tabIndex: views.archived.index },
                                React.createElement(YourNoticesTable, { id: "archived-notices-table", notices: fetching ? [] : notices || [], loading: fetching, title: "Archived", isPublisher: isPublisher, setCurrentPage: noticesActions.setCurrentPage, currentPage: currentPage, sort: sort, setSort: noticesActions.setSort, actions: actionButtons, total: total, setPageSize: noticesActions.setPageSize, setTab: noticesActions.setTab, setSearchValue: setSearchValue, rowsPerNoticeTablePage: pageSize, setShowUserOnlyNotices: noticesActions.setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue }))),
                        React.createElement("div", { id: "draft-notices-table" },
                            React.createElement(TabPanel, { value: selectedTabIndex, tabIndex: views.drafts.index },
                                React.createElement(YourNoticesTable, { id: "draft-notices-table", notices: fetching ? [] : notices || [], loading: fetching, title: "Drafted", isPublisher: isPublisher, setCurrentPage: noticesActions.setCurrentPage, currentPage: currentPage, sort: sort, setSort: noticesActions.setSort, actions: actionButtons, total: total, setPageSize: noticesActions.setPageSize, setTab: noticesActions.setTab, setSearchValue: setSearchValue, rowsPerNoticeTablePage: pageSize, setShowUserOnlyNotices: noticesActions.setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue }))),
                        React.createElement(PostRegistrationModal, null),
                        openBulkDownloadModal && (React.createElement(BulkDownloadModal, { setOpen: setShowBulkDownloadModal, activeOrganization: activeOrganization })),
                        openPasswordResetModal && (React.createElement(DwollaPasswordResetModal, { fromMainView: true, user: userSnap, userAuth: userAuth, passwordUpdateSuccess: () => {
                                setOpenPasswordResetModal(false);
                                setSuccessToast('Success! Your password has been changed.');
                            } })),
                        showBulkAffidavitUploadModal && (React.createElement(BulkAffidavitUpload, { setOpen: setShowBulkAffidavitUploadModal, activeOrganization: activeOrganization, setMessage: setMessage, user: userSnap.ref })),
                        showBulkAffidavitDownloadModal && (React.createElement(BulkAffidavitDownload, { setOpen: setShowBulkAffidavitDownloadModal, activeOrganization: activeOrganization, setMessage: setMessage })),
                        showExportCsvModal && (React.createElement(ExportNoticesModal, { setOpen: setShowExportCsvModal, onSuccessfulExport: setShowExportSuccessModal, setSuccessModalBody: setSuccessModalBody, user: userSnap, reportType: "csv" })),
                        showExportPdfModal && (React.createElement(ExportNoticesModal, { setOpen: setShowExportPdfModal, onSuccessfulExport: setShowExportSuccessModal, setSuccessModalBody: setSuccessModalBody, user: userSnap, reportType: "pdf" })),
                        showExportEmailModal && (React.createElement(ExportNoticesModal, { setOpen: setShowExportEmailModal, onSuccessfulExport: setShowExportSuccessModal, setSuccessModalBody: setSuccessModalBody, user: userSnap, reportType: "email" })),
                        showExportSuccessModal && (React.createElement(SuccessModal, { setOpen: setShowExportSuccessModal, gif: React.createElement("img", { src: 'https://enotice-production.imgix.net/custom-documents/permalink/bb78.9cbe2-report-downloaded.gif', style: {
                                    clipPath: 'circle()',
                                    width: '104px',
                                    height: '104px'
                                } }), header: "Report Exported", body: successModalBody, footer: React.createElement("span", null,
                                "Here's what you can do next:",
                                ' ',
                                React.createElement("a", { href: "/place", className: "text-blue-900" }, "Place a new notice")) })),
                        React.createElement(JoinOrganizationModal, { user: userSnap, authActions: authActions, showRequests: value => {
                                setShowRequests(value);
                            } }),
                        showRequests && (React.createElement(JoinOrganizationRequestModal, { user: userSnap, activeOrganization: activeOrganization })),
                        message && (React.createElement(EToast, { message: message, close: () => setMessage(''), id: 'affidavit-messages', type: "success" })),
                        successToast && (React.createElement(EToast, { message: successToast, close: () => setSuccessToast(''), type: "success" })),
                        showEUploaderModal && (React.createElement(EEditionUploader, { setOpen: setShowEUploaderModal, activeOrganization: activeOrganization, id: "e-edition-uploader-modal", setShowEUploadSuccessModal: setShowEUploadSuccessModal })),
                        showEUploadSuccessModal && (React.createElement(SuccessModal, { setOpen: setShowEUploadSuccessModal, gif: React.createElement("img", { style: { backgroundColor: '#FFF0BF', height: '104px' }, className: "rounded-full py-4.5 px-3", src: PartyEmoji }), header: "E-edition submitted", body: "Your e-edition was successfully submitted. Affidavit automation will start right away." }))))))));
};
export default YourNoticesTableView;
