var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * TODO (APP-36): This function *may* need to be refactored so that more than one file can be uploaded for a draft
 */
export const refreshDraftFiles = (ctx, draftRef, filesToAttach) => __awaiter(void 0, void 0, void 0, function* () {
    const fileCollectionRef = ctx.userNoticeFilesRef(draftRef);
    const fileCollectionSnap = yield fileCollectionRef.get();
    const oldFileSnaps = fileCollectionSnap.docs;
    if (oldFileSnaps && oldFileSnaps.length) {
        for (const oldFileSnap of oldFileSnaps) {
            yield oldFileSnap.ref.delete();
        }
    }
    for (const fileToAttach of filesToAttach) {
        yield fileCollectionRef.add(fileToAttach);
    }
});
export const addDraftFilesToNotice = (ctx, draftRef, noticeRef) => __awaiter(void 0, void 0, void 0, function* () {
    const draftFilesCollectionRef = ctx.userNoticeFilesRef(draftRef);
    const draftFilesCollectionSnap = yield draftFilesCollectionRef.get();
    const noticeFilesCollectionRef = ctx.userNoticeFilesRef(noticeRef);
    const noticeFilesCollectionSnap = yield noticeFilesCollectionRef.get();
    if (noticeFilesCollectionSnap.docs) {
        for (const noticeFileSnap of noticeFilesCollectionSnap.docs) {
            yield noticeFileSnap.ref.delete();
        }
    }
    if (draftFilesCollectionSnap.docs) {
        for (const draftFileSnap of draftFilesCollectionSnap.docs) {
            yield noticeFilesCollectionRef.add(draftFileSnap.data());
            yield draftFileSnap.ref.delete();
        }
    }
});
export const getNoticeFilesAndSetOnDraft = (ctx, noticeRef, draftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const draftFilesCollectionRef = ctx.userNoticeFilesRef(draftRef);
    const noticeFilesCollectionRef = ctx.userNoticeFilesRef(noticeRef);
    const noticeFilesCollectionSnap = yield noticeFilesCollectionRef.get();
    const filesArray = [];
    for (const fileSnap of noticeFilesCollectionSnap.docs) {
        yield draftFilesCollectionRef.add(fileSnap.data());
        filesArray.push(fileSnap.data());
    }
    return filesArray;
});
export const getFilesDataFromNoticeOrDraft = (ctx, noticeOrDraft) => __awaiter(void 0, void 0, void 0, function* () {
    const filesCollectionRef = ctx.userNoticeFilesRef(noticeOrDraft);
    const filesCollectionSnap = yield filesCollectionRef.get();
    const filesArray = [];
    for (const fileSnap of filesCollectionSnap.docs) {
        filesArray.push(fileSnap.data());
    }
    return filesArray;
});
