import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, Typography, Button, LinearProgress, Fade } from '@material-ui/core';
import AdornedButton from '../AdornedButton';
const styles = (theme) => createStyles({
    root: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(10)
    },
    headerItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2)
    },
    buttonRow: {
        marginTop: theme.spacing(2)
    },
    subheader: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    footer: {
        position: 'fixed',
        zIndex: 10,
        background: 'white',
        bottom: 0,
        width: '100vw',
        left: 0
    },
    buttonWrapper: {
        marginLeft: 60,
        marginBottom: theme.spacing(2)
    },
    button: {
        backgroundColor: '#2D9BDB',
        '&:hover': {
            backgroundColor: '#24415B'
        }
    }
});
const FormStep = ({ classes, title, description, children, forward, back, stepComplete, forwardLabel, saving, id }) => (React.createElement("div", { className: classes.root, id: id },
    React.createElement(ValidatorForm, { onSubmit: () => forward() },
        React.createElement(Grid, { item: true, className: classes.headerItem },
            React.createElement(Grid, { container: true, alignItems: "center", spacing: 2 },
                React.createElement(Grid, { item: true },
                    React.createElement("div", { className: "text-3xl text-gray-800 font-normal" }, title)))),
        description && (React.createElement(Grid, { item: true, className: `${classes.headerItem} ${classes.subheader}` },
            React.createElement(Grid, { container: true, alignItems: "center", spacing: 2 },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "h6", className: classes.header }, description))))),
        children,
        React.createElement(Grid, { className: classes.footer },
            React.createElement(Fade, { in: saving, style: { transitionDelay: '3000ms' }, unmountOnExit: true },
                React.createElement(LinearProgress, null)),
            React.createElement("div", { className: classes.buttonWrapper },
                React.createElement("div", { className: classes.buttonRow },
                    React.createElement(AdornedButton, { id: "next", type: "submit", variant: "contained", color: "primary", className: classes.button, disabled: !stepComplete || saving, loading: !!saving }, forwardLabel || 'Next'),
                    React.createElement(Button, { disabled: !back, className: classes.backButton, onClick: () => back && back() }, "Back")))))));
export default withStyles(styles, { withTheme: true })(FormStep);
