import { ELAVON } from '../constants';
/* TODO (APP-99): redefine (or remove) this function when we have better typing for finalized invoices */
export const isFinalizedEInvoice = (invoice) => {
    return !!(invoice.data().customer_email ||
        invoice.data().amount_paid ||
        invoice.data().finalized_at ||
        invoice.data().void ||
        invoice.data().voided_at);
};
// The function isPaywayInvoice() is used in both SCNG and BANG integrations
export const isPaywayInvoice = (invoice) => {
    return !!invoice.data().paywayToken;
};
export const isElavonInvoice = (invoice) => {
    return invoice.data().paymentGateway === ELAVON;
};
