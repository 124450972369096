import classNames from 'classnames';
import React from 'react';
import { Label } from '../Label';
export function InputAccessories({ id, labelText, children, noteText, errorText, required, disabled }) {
    const labelMarkup = (React.createElement("div", { className: classNames('pb-2', {
            'text-grey-400': !!disabled
        }) },
        React.createElement(Label, { id: id, required: required }, labelText)));
    const noteTextMarkup = noteText ? (React.createElement(InputNote, { id: id }, noteText)) : null;
    const errorMarkup = errorText ? (React.createElement(InlineError, { id: id }, errorText)) : null;
    return (React.createElement("div", null,
        labelMarkup,
        children,
        noteTextMarkup,
        errorMarkup));
}
function InputNote({ id, children }) {
    return (React.createElement("div", { id: getNoteId(id), role: "note", className: "pt-2 text-xs text-grey-400" }, children));
}
function InlineError({ id, children }) {
    return (React.createElement("div", { id: getErrorId(id), role: "alert", className: "pt-2 text-xs text-red-600" }, children));
}
export function getAccessibilityLinks({ id, noteText, errorMessage }) {
    const accessibilityLinks = {};
    if (noteText) {
        accessibilityLinks['aria-describedby'] = getNoteId(id);
    }
    if (errorMessage) {
        accessibilityLinks['aria-errormessage'] = getErrorId(id);
        accessibilityLinks['aria-invalid'] = true;
    }
    return accessibilityLinks;
}
export function getErrorId(id) {
    return `${id}-error`;
}
export function getNoteId(id) {
    return `${id}-note`;
}
