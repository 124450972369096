import React from 'react';
export function Label({ children, id, required }) {
    const requiredMarkup = required ? (React.createElement("span", { className: "text-grey-400" }, "*")) : null;
    return (React.createElement("label", { id: getLabelId(id), htmlFor: id, className: "text-sm font-medium" },
        children,
        " ",
        requiredMarkup));
}
function getLabelId(id) {
    return `${id}-label`;
}
