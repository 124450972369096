import React from 'react';
import { ELAVON, PAYWAY, STRIPE } from 'lib/constants';
import StripeCardCheckoutForm from './checkoutForms/StripeCardCheckoutForm';
import PaywayCheckoutForm from './checkoutForms/PaywayCardCheckoutForm';
import { ElavonCardCheckoutForm } from './checkoutForms/ElavonCardCheckoutForm';
export function PayInvoiceCreditCard({ payInvoiceData, invoicePricingData, disableSavedCards, handleSuccessfulPayment }) {
    const { gateway } = payInvoiceData;
    return (React.createElement(React.Fragment, null,
        gateway === STRIPE && (React.createElement(StripeCardCheckoutForm, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, disableSavedCards: disableSavedCards, handleSuccessfulPayment: handleSuccessfulPayment })),
        gateway === PAYWAY && (React.createElement(PaywayCheckoutForm, { payInvoiceData: payInvoiceData, handleSuccessfulPayment: handleSuccessfulPayment })),
        gateway === ELAVON && (React.createElement(ElavonCardCheckoutForm, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, handleSuccessfulPayment: handleSuccessfulPayment }))));
}
export default PayInvoiceCreditCard;
