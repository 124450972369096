import React, { useEffect } from 'react';
const InviteWarningModal = ({ onConfirmClick, setInviteFormHeader, organization }) => {
    useEffect(() => {
        if (setInviteFormHeader)
            setInviteFormHeader('Permissions warning');
    }, [setInviteFormHeader]);
    const handleClick = (e) => {
        if (setInviteFormHeader)
            setInviteFormHeader('Invite members to your organization');
        onConfirmClick(e);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mb-4 font-regular text-large text-gray-700" },
            `If you add new members to ${organization.data().name}, they will also gain access to the other organizations 
      you have access to on Column.`,
            React.createElement("br", null),
            React.createElement("br", null),
            "If you want to restrict new members\u2019 access to one newspaper, please select that newspaper in the top-left corner of your screen, visit My Organization settings, and invite new members from there.",
            React.createElement("br", null),
            React.createElement("br", null),
            "Click ",
            React.createElement("b", null, "Confirm"),
            " to proceed with inviting new members to all of your organizations."),
        React.createElement("button", { onClick: e => handleClick(e), className: "bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-full md:w-32" }, "Confirm")));
};
export default InviteWarningModal;
