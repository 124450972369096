import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorIcon from '@material-ui/icons/Error';
import { getColumnInches } from 'lib/pricing';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { RateType } from 'lib/enums';
import { getCurrencySymbol } from 'lib/helpers';
const styles = (theme) => createStyles({
    modalCloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    error: {
        '& .MuiSvgIcon-root': {
            height: '1.5em',
            width: '1.5em'
        }
    }
});
const NoticePreviewContainer = ({ classes, theme, price, displayParameters, rate, hidePricing, columnsWide, setColumnsWide, error, newspaper, disableColumnWidthControls }) => {
    var _a, _b, _c, _d;
    const previewImgs = displayParameters.imgs;
    const [magnify, setMagnify] = useState(false);
    const currencySymbol = getCurrencySymbol(newspaper.data().currency);
    const columnsImageWidthMap = {
        1: '180px',
        2: '300px',
        3: '400px'
    };
    const inches = rate.rateType === RateType.inch.value
        ? {
            unit: RateType.inch.plural,
            value: displayParameters.area
        }
        : {
            unit: RateType.column_inch.plural,
            value: getColumnInches(displayParameters.height, columnsWide, rate.roundOff)
        };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { className: classes.card, style: {
                height: 500,
                minWidth: 300,
                overflowY: 'scroll'
            } },
            error ? (React.createElement(CardHeader, { style: {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    paddingLeft: 0
                }, subheader: React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, direction: "row" },
                        React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justify: "center", xs: 2, className: classes.error },
                            React.createElement(ErrorIcon, null)),
                        React.createElement(Grid, { xs: true }, error),
                        ' ')) })) : (React.createElement(CardHeader, { style: {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white'
                }, title: hidePricing ? '' : `Estimated Price: ${currencySymbol}${price}`, subheader: `${displayParameters.words} ${RateType.word_count.plural} - ${displayParameters.lines} ${RateType.line.plural} - ${inches.value.toFixed(2)} ${inches.unit}` })),
            !error && (React.createElement(CardContent, null,
                React.createElement(Box, { mb: 1 },
                    React.createElement(Grid, { container: true, direction: "row", justify: "center" }, previewImgs && (React.createElement(React.Fragment, null,
                        !disableColumnWidthControls && (React.createElement(Grid, { item: true },
                            React.createElement(InputLabel, { htmlFor: "columns-simple", style: { fontSize: 14 } }, "Columns Wide"),
                            React.createElement(Select, { value: columnsWide, onChange: e => {
                                    setColumnsWide(e.target.value);
                                }, style: { fontSize: 14 } },
                                !((_b = (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.linerMinColumns) !== null && _b !== void 0 ? _b : 0 > 1) && (React.createElement(MenuItem, { value: 1 }, "One")),
                                !((_d = (_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.linerMinColumns) !== null && _d !== void 0 ? _d : 0 > 2) && (React.createElement(MenuItem, { value: 2 }, "Two")),
                                React.createElement(MenuItem, { value: 3 }, "Three")))),
                        React.createElement(Grid, { item: true },
                            React.createElement(IconButton, { onClick: () => setMagnify(true), className: classes.zoomIcon },
                                ' ',
                                React.createElement(ZoomInIcon, { className: classes.addButton, fontSize: "small" }))))))),
                React.createElement(Grid, { container: true, direction: "row", justify: "center", alignItems: "center" }),
                React.createElement(Grid, { container: true, direction: "row", justify: "center", alignItems: "center" }, displayParameters.lines <= 1 ? (React.createElement("div", null,
                    ' ',
                    "To generate preview, enter more than one line of text.",
                    ' ')) : (React.createElement(Grid, { item: true }, previewImgs && (React.createElement(Box, { mr: 1, mb: 1 },
                    React.createElement("img", { style: {
                            width: columnsImageWidthMap[columnsWide],
                            maxWidth: '100%'
                        }, className: classes.previewImg, src: previewImgs[0], alt: "notice-preview" }))))))))),
        ' ',
        magnify && (React.createElement(ImagePreviewModal, { setOpenModal: setMagnify, jpgURLs: error ? [] : previewImgs })),
        React.createElement("style", null, `
        .MuiTypography-colorTextSecondary {
          color: white;
          font-size: 14px;
        }
      `)));
};
export default withStyles(styles, { withTheme: true })(NoticePreviewContainer);
