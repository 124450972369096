import { useEffect } from 'react';
import { getCustomValidationMessage, VALIDATION_CHECK_EVENT } from '../../helpers/inputValidation';
export function useValidationChecks({ inputRef, validationMessages, setNativeErrorMessage }) {
    useEffect(() => {
        if (inputRef.current) {
            const inputElement = inputRef.current;
            // Display the validation status for this input when it received a custom validation check event
            inputElement.addEventListener(VALIDATION_CHECK_EVENT, showValidationMessage);
            return () => {
                inputElement.removeEventListener(VALIDATION_CHECK_EVENT, showValidationMessage);
            };
        }
    }, [Boolean(inputRef.current), showValidationMessage]);
    function getValidationMessage() {
        if (inputRef.current && !inputRef.current.validity.valid) {
            const customValidationMessage = validationMessages
                ? getCustomValidationMessage(inputRef.current.validity, validationMessages)
                : null;
            const nativeValidationMessage = inputRef.current.validationMessage;
            return customValidationMessage || nativeValidationMessage;
        }
    }
    function showValidationMessage() {
        const currentValidationMessage = getValidationMessage();
        setNativeErrorMessage(currentValidationMessage || '');
    }
    function clearValidationMessageIfValid() {
        if (inputRef.current) {
            const isValid = inputRef.current.checkValidity();
            if (isValid) {
                setNativeErrorMessage('');
            }
        }
    }
    return { showValidationMessage, clearValidationMessageIfValid };
}
