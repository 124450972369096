export var FileType;
(function (FileType) {
    FileType["WORD_DOC"] = "word-doc";
    FileType["DOCX"] = "docx";
    FileType["PDF"] = "pdf";
    FileType["CSV"] = "csv";
    FileType["TEXT"] = "text";
    FileType["EXCEL"] = "excel";
    FileType["TIF"] = "tif";
    FileType["TIFF"] = "tiff";
    FileType["PNG"] = "png";
    FileType["JPG"] = "jpg";
    FileType["JPEG"] = "jpeg";
    FileType["RTF"] = "rtf";
    FileType["IDML"] = "idml";
    FileType["HTML"] = "html";
    FileType["HEIC"] = "heic";
    FileType["HEIF"] = "heif";
    FileType["ZIP"] = "zip";
})(FileType || (FileType = {}));
export var Extensions;
(function (Extensions) {
    Extensions["pdf"] = "pdf";
    Extensions["csv"] = "csv";
    Extensions["xlsx"] = "excel";
    Extensions["xls"] = "excel";
    Extensions["doc"] = "word-doc";
    Extensions["docx"] = "docx";
    Extensions["txt"] = "text";
    Extensions["tif"] = "tif";
    Extensions["png"] = "png";
    Extensions["jpg"] = "jpg";
    Extensions["jpeg"] = "jpeg";
    Extensions["rtf"] = "rtf";
    Extensions["zip"] = "zip";
})(Extensions || (Extensions = {}));
