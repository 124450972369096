var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCustomer } from '../notice/customer';
import { exists } from '../types';
const DEFAULT_AFFIDAVIT_FEE_IN_CENTS = 500;
/**
 * Returns the reconciliation settings affiliated with the newspaper. Goes in precendence
 * order of:
 * 1. What is set on the newspaper (if anything)
 * 2. What is set on the parent (if anything)
 * 3. Returns undefined if no settings on either
 */
export const getAffidavitSettingsForNewspaper = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    if (!exists(newspaperSnap))
        return undefined;
    if ((_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.affidavitReconciliationSettings) {
        return (_b = newspaperSnap.data()) === null || _b === void 0 ? void 0 : _b.affidavitReconciliationSettings;
    }
    const parentSnap = yield ((_d = (_c = newspaperSnap.data()) === null || _c === void 0 ? void 0 : _c.parent) === null || _d === void 0 ? void 0 : _d.get());
    if ((_e = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _e === void 0 ? void 0 : _e.affidavitReconciliationSettings) {
        return (_f = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _f === void 0 ? void 0 : _f.affidavitReconciliationSettings;
    }
    return undefined;
});
/**
 * Returns the reconciliation settings affiliated with the notice. Goes in precendence
 * order of:
 * 1. What is set on the notice (if anything)
 * 2. What is set on the customer (if anything)
 * 3. What is set on the newspaper
 */
export const getAffidavitSettingsForNotice = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield notice.data().newspaper.get();
    const filer = yield notice.data().filer.get();
    if (!exists(newspaper))
        throw new Error(`No newspaper linked to notice: ${notice.id}`);
    const newspaperLevelAffidavitSettings = yield getAffidavitSettingsForNewspaper(newspaper);
    // If the newspaper doesn't do anything with verification, don't do anything!
    if (!newspaperLevelAffidavitSettings)
        return;
    // Allow for notice-specific settings for affidavit reconciliation
    const noticeLevelAffidavitSettings = notice.data().affidavitReconciliationSettings || {};
    // Allow for customer-specific settings to override newspaper settings for affidavit reconciliation
    const customerSnap = yield getCustomer(ctx, filer, newspaper);
    const customerLevelAffidavitSettings = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().affidavitReconciliationSettings;
    return Object.assign(Object.assign(Object.assign({}, newspaperLevelAffidavitSettings), customerLevelAffidavitSettings), noticeLevelAffidavitSettings);
});
const getAffidavitFeeIsNumber = (affidavitFeeInCents) => {
    return typeof affidavitFeeInCents === 'number';
};
const getAffidavitFeeInCentsFromSettings = (reconciliationSettings) => {
    if (reconciliationSettings === null || reconciliationSettings === void 0 ? void 0 : reconciliationSettings.affidavitsManagedByColumn) {
        const { automatedAffidavitFeeInCents } = reconciliationSettings;
        const hasSpecifiedFee = getAffidavitFeeIsNumber(automatedAffidavitFeeInCents);
        return hasSpecifiedFee
            ? automatedAffidavitFeeInCents
            : DEFAULT_AFFIDAVIT_FEE_IN_CENTS;
    }
    return 0;
};
export const getAffidavitFeeInCentsForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const reconciliationSettings = yield getAffidavitSettingsForNotice(ctx, noticeSnap);
    return getAffidavitFeeInCentsFromSettings(reconciliationSettings);
});
/*
 * This returns the affidavit fee with the following order of precedence:
 *   1. Customer
 *   2. Newspaper (or default affidavit fee)
 *
 * This is the only time we should call this function and not use the notice's
 * affidavit fee because it is not yet set at this point in the flow.  Otherwise,
 * we should always call `getAffidavitFeeInCentsForNotice` or
 * `getAffidavitSettingsForNotice`.
 */
export const getInitialAffidavitFeeInCents = (ctx, newspaperSnap, filerSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperAffidavitSettings = yield getAffidavitSettingsForNewspaper(newspaperSnap);
    let customerAffidavitSettings;
    if (exists(filerSnap)) {
        const customerSnap = yield getCustomer(ctx, filerSnap, newspaperSnap);
        customerAffidavitSettings = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().affidavitReconciliationSettings;
    }
    const compositeAffidavitSettings = Object.assign(Object.assign({}, newspaperAffidavitSettings), customerAffidavitSettings);
    const initialAffidavitFeeInCents = getAffidavitFeeInCentsFromSettings(compositeAffidavitSettings);
    return initialAffidavitFeeInCents;
});
