var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { exists } from 'lib/types';
import Firebase, { Firestore } from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import moment from 'moment';
import { State, OrganizationType } from 'lib/enums';
import EToast from 'components/EToast';
const SurveyModal = ({ fromMainView, setFloridaDataSurveyModal, user, activeOrganization }) => {
    const [disableAuditRequestModal, setDisableAuditRequestModal] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const inputTextStyle = 'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';
    const [radioButtonSelected, setRadioButtonSelected] = useState(-1);
    const [displayAuditRequested, setDisplayAuditRequested] = useState(false);
    const [auditLink, setAuditLink] = useState('');
    const [successToast, setSuccessToast] = useState();
    const [openSurveyModal, setOpenSurveyModal] = useState(false);
    const shouldShowPublisherSurvey = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!exists(activeOrganization) ||
            ((_b = (_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.email) === null || _b === void 0 ? void 0 : _b.includes('@column.us')))
            return false;
        if (activeOrganization.data().isGovernmentPublisher) {
            return false;
        }
        if (!activeOrganization.data().showModalPrematurely &&
            moment().year() <= 2021)
            return false;
        const surveys = yield Firebase.firestore()
            .collection(Collections.surveys)
            .where('newspaper', '==', activeOrganization.ref)
            .where('response.timeSubmitted', '>=', moment().startOf('quarter').toDate())
            .get();
        if (activeOrganization.data().state !== State.florida.value ||
            activeOrganization.data().organizationType !==
                OrganizationType.newspaper.value ||
            surveys.docs.length > 0)
            return false;
        return true;
    });
    const goBack = () => __awaiter(void 0, void 0, void 0, function* () {
        setDisplayAuditRequested(false);
    });
    useEffect(() => {
        setDisableAuditRequestModal(auditLink === '');
    }, [auditLink]);
    useEffect(() => {
        const setModal = () => __awaiter(void 0, void 0, void 0, function* () {
            const showSurvey = yield shouldShowPublisherSurvey();
            setOpenSurveyModal(showSurvey);
        });
        void setModal();
    }, [activeOrganization, successToast]);
    const populateResponse = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!user || !activeOrganization) {
            return;
        }
        const response = {
            user: user.ref,
            auditLink,
            timeSubmitted: Firestore.FieldValue.serverTimestamp(),
            holdsPeriodicalPermit: radioButtonSelected === 2,
            holdsPeriodicalPermitAndInFiscallyConstrainedCounty: radioButtonSelected === 3
        };
        yield Firebase.firestore()
            .collection(Collections.surveys)
            .add({
            newspaper: activeOrganization.ref,
            quarter: moment().startOf('quarter').toDate(),
            response
        });
        setSuccessToast("Confirmed! You're all set.");
    });
    if (!openSurveyModal && !successToast) {
        return null;
    }
    return (React.createElement("div", null,
        openSurveyModal && displayAuditRequested && (React.createElement(FreeformCModal, { header: 'Audit requested', setOpen: fromMainView || !setFloridaDataSurveyModal
                ? undefined
                : () => setFloridaDataSurveyModal(), customStyle: 'px-2', noExitOutsideModal: true, width: '30%', maxWidth: 'sm:max-w-2xl' },
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    void populateResponse();
                } },
                React.createElement("div", { className: "overflow-y-auto py-2 mt-2 px-2" },
                    React.createElement("div", { className: "text-gray-700 text-sm font-medium -mt-3" }, "Please provide a link or QR code of your audit results."),
                    React.createElement("fieldset", { className: "space-y-5 text-gray-700 text-sm font-medium" },
                        React.createElement("legend", { className: "sr-only" }, "Notifications"),
                        React.createElement("div", { className: "flex items-center h-5" },
                            React.createElement("input", { id: "auditLink", "aria-describedby": "audit-link", name: "comments", type: "text", placeholder: "Link to audit results", className: inputTextStyle, onChange: e => {
                                    setAuditLink(e.target.value);
                                } }))),
                    React.createElement("div", { className: "inline-flex" },
                        React.createElement("button", { className: `mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${disableAuditRequestModal
                                ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                                : 'bg-blue-500'}`, type: "submit", disabled: disableAuditRequestModal },
                            React.createElement("span", { className: "flex" }, " Confirm and Submit ")),
                        React.createElement("button", { className: `mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`, type: "button", onClick: goBack },
                            React.createElement("span", { className: "flex" }, " Go Back "))))))),
        openSurveyModal && !displayAuditRequested && (React.createElement(FreeformCModal, { header: 'Quarterly check-in', setOpen: fromMainView || !setFloridaDataSurveyModal
                ? undefined
                : () => setFloridaDataSurveyModal(), customStyle: 'px-2', noExitOutsideModal: true, width: '30%', maxWidth: 'sm:max-w-2xl' },
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    void populateResponse();
                } },
                React.createElement("div", { className: "overflow-y-auto py-2 mt-2 px-2" },
                    React.createElement("div", { className: "text-gray-700 text-sm font-medium -mt-3" }, "Before uploading your notices, please complete your quarterly check-in."),
                    React.createElement("fieldset", { className: "space-y-5 text-gray-700 text-sm font-medium" },
                        React.createElement("legend", { className: "sr-only" }, "Florida Data Questionnaire"),
                        React.createElement("div", { className: "relative flex items-start" },
                            React.createElement("div", { className: "flex items-center h-5" },
                                React.createElement("input", { id: "questionOne", "aria-describedby": "questionOne-description", name: "questionOne", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === 1, onChange: () => {
                                        setRadioButtonSelected(1);
                                        setIsDisabled(false);
                                    } })),
                            React.createElement("div", { className: "ml-3" },
                                React.createElement("span", { id: "questionOne-description", onClick: () => {
                                        setRadioButtonSelected(1);
                                        setIsDisabled(false);
                                    } },
                                    "Our newspaper has a certified audit that the newspaper has a combined number of print copies and monthly online visitors exceeding 10% of the county households for notices affecting a county or the city households where city notices are concerned. See",
                                    ' ',
                                    React.createElement("a", { href: "http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/0050.html", target: "_blank", rel: "noopener noreferrer", className: "text-blue-500" }, "50.011(1)(a)"),
                                    "."))),
                        React.createElement("div", { className: "relative flex items-start" },
                            React.createElement("div", { className: "flex items-center h-5" },
                                React.createElement("input", { id: "questionTwo", "aria-describedby": "questionTwo-description", name: "questionTwo", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === 2, onChange: () => {
                                        setRadioButtonSelected(2);
                                        setIsDisabled(false);
                                    } })),
                            React.createElement("div", { className: "ml-3" },
                                React.createElement("span", { id: "questionTwo-description", onClick: () => {
                                        setRadioButtonSelected(2);
                                        setIsDisabled(false);
                                    } },
                                    "Our newspaper holds a US Postal Service periodicals permit. See",
                                    ' ',
                                    React.createElement("a", { href: "http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/0050.html", target: "_blank", rel: "noopener noreferrer", className: "text-blue-500" }, "50.011(1)(b)"),
                                    "."))),
                        React.createElement("div", { className: "relative flex items-start" },
                            React.createElement("div", { className: "flex items-center h-5" },
                                React.createElement("input", { id: "questionThree", "aria-describedby": "questionThree-description", name: "questionThree", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === 3, onChange: () => {
                                        setRadioButtonSelected(3);
                                        setIsDisabled(false);
                                    } })),
                            React.createElement("div", { className: "ml-3" },
                                React.createElement("span", { id: "questionThree-description", onClick: () => {
                                        setRadioButtonSelected(3);
                                        setIsDisabled(false);
                                    } },
                                    "Our newspaper publishes public notices in a fiscally constrained county and currently holds a periodicals permit, in which case the audience thresholds do not apply. See",
                                    ' ',
                                    React.createElement("a", { href: "http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/0050.html", target: "_blank", rel: "noopener noreferrer", className: "text-blue-500" }, "50.011(2)"),
                                    ".")))),
                    React.createElement("div", null,
                        React.createElement("button", { className: `mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${isDisabled
                                ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                                : 'bg-blue-500'}`, type: "submit", disabled: isDisabled },
                            React.createElement("span", { className: "flex" }, "Confirm and Submit"))))))),
        successToast && (React.createElement(EToast, { message: successToast, close: () => setSuccessToast(''), type: "success" }))));
};
export default SurveyModal;
